import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getContractPartItems(data) {
    return await request('/api/contract-part-items', data);
}

export async function addContractPartItem(data) {
    return await request('/api/contract-part-items',  transformRequestData(data), HttpMethod.POST);
}

export async function editContractPartItem(data) {
    return await request('/api/contract-part-items/' + data.id,  transformRequestData(data), HttpMethod.PUT);
}

export async function deleteContractPartItem(id) {
    return await request('/api/contract-part-items/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        productServiceId: data.productService ? data.productService.id : null
    }
}

