import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import ProjectTimeLogForm from "../../../Components/Forms/Pages/Project/ProjectTimeLog/ProjectTimeLogForm";
import DrawerContext from "../../../Context/DrawerContext";
import {editProjectTimeLog} from "../../../Services/Project/ProjectTimeLogService";
import LinearProgress from '@mui/material/LinearProgress';
import SnackbarContext from "../../../Context/SnackbarContext";
import { getProjectList } from "../../../Services/Project/ProjectService";
import { getProjectTaskCategories, getProjectTaskCategory } from "../../../Services/Project/ProjectTaskCategoryService";
import {getEmployees} from "../../../Services/User/UserService";
import {useSelector} from "react-redux";

const formRules = {
    'dateLogged': {required: true},'hourLogged': {required: true},
    'project': {required: true}, 'name': {required: true},
}

const EditProjectTimeLog = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? {...props.data[0], dateLogged: props.data[0].dateLogged || new Date()} : undefined
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [projects, setProjects] = useState([]);
    const [taskCategories, setTaskCategories] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [projectTaskCategories, setProjectTaskCategories] = useState([]);
    const [billable, setBillable] = useState(false);
    const auth = useSelector((state) => state.auth.user)

    useEffect(() => {
        setDrawerTitle(strings.pages.project.projectTimeLog.editProjectTimeLog.pageTitle);
    });

    useEffect(() => {
        fetch();
        fetchProjects();
        fetchEmployees();
    },[])

    useEffect(() => {
        setBillable(props.data[0].billable)
    }, [props.data])

    const fetch = () => {
        getProjectTaskCategory(props?.project || props?.data[0]?.project.id).then(response => {
            if (!response || !response.ok){
                return;
            }
            setProjectTaskCategories(response.data.result)

        })

        if(props.data[0].project.id){
            getProjectTaskCategories(props.data[0].project.id).then(response => {
                if (!response || !response.ok) {
                    return;
                }

                setTaskCategories(response.data.result);
            })
        }
    }

    const fetchProjects = (term = '') => {
        getProjectList({term}).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setProjects(response.data.result);
        })
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        editProjectTimeLog(data).then(response => {
            setShowLoader(false);

            if(!response){
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }
            if (!response.ok) {
                showMessage(response.response.data.message, 'error', 5000);
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    const handleProjectChange = (newValue) => {
        setValue('project', newValue);

        if(!newValue){
            setValue('taskCategory', null);
            setValue('billable', false);
            setBillable(false);
            setTaskCategories([]);
        }
        
        getProjectTaskCategories(newValue?.id).then(response => {
            if (!response || !response.ok) {
                return;
            }

            setTaskCategories(response.data.result);
        });

        if(getValues()['taskCategory']){
            getProjectTaskCategory(getValues()['project'].id).then(response => {
                if (!response || !response.ok){
                    return;
                }
                setProjectTaskCategories(response.data.result);
                
                updateBillableField(getValues()['taskCategory'], response.data.result);
            });
        }
    }

    const handleTaskCategoryChange = (newValue) => {
        if(!newValue){
            setValue('billable', false);
            setBillable(false);
        }

        if(!props.project){
            getProjectTaskCategory(getValues()['project'].id).then(response => {
                if (!response || !response.ok){
                    return;
                }
                setProjectTaskCategories(response.data.result);
                
                updateBillableField(newValue, response.data.result);
            })
            return;
        }

        updateBillableField(newValue, projectTaskCategories)
    }

    const fetchEmployees = (term = '') => {
        getEmployees({term}).then(response => {
            setEmployees(response.data.result.map(u => u.user));
        })
    }

    const updateBillableField = (newValue, projectTaskCategories) => {
        let matchTaskCategory = projectTaskCategories.filter(p => p.taskCategory.id == newValue?.id);

        setBillable(undefined);

        setValue('taskCategory', newValue);
        setValue('billable', matchTaskCategory[0]?.billable);

        setBillable(matchTaskCategory[0]?.billable);
    }

    const changeEmployee = (employee) => {
        setValue('employeeUser', employee);
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <ProjectTimeLogForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                projects = {projects} billable={billable} onChangeProjectsFilter={fetchProjects}
                errors={errors} data={data} form={form} control={control}
                handleTaskCategoryChange={ newValue => handleTaskCategoryChange(newValue)}
                onChangeEmployeeFilter={fetchEmployees} changeEmployee={changeEmployee}
                taskCategories={taskCategories} employees={employees} isOwner={auth.isOwner}
                handleProjectChange={(newValue) => handleProjectChange(newValue)}
                handleSubmitSave={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditProjectTimeLog;