import React, {useContext, useEffect, useState} from 'react';
import { FormProvider } from 'react-hook-form';
import strings from "../../../../localization";
import Button from '@mui/material/Button';
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import AutoCompleteControl from "../../../Controls/Inputs/AutoCompleteControl";
import TablePageContext from "../../../../Context/TablePageContext";
import PageState from "../../../../Constants/Base/PageState";
import SelectControl from "../../../Controls/Inputs/SelectControl";
import QuillEditor from '../../../Controls/Inputs/QuillEditor';

const BoardIssueForm = (props) => {
    const {watch, formRules, form, values, data, onSubmit, onCancel, errors, setValue, control, assignees, issueTypes, assignToMe, issuePriorities} = props;

    const [descriptionData, setDescriptionData] = useState('');

    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    
    
    useEffect(() => {
		setDescriptionData(values['description']);
	}, []);

    useEffect(() => {
        setValue('description', descriptionData);
    }, [descriptionData])

    return (
        <FormProvider {...form}>
            <form className = 'form' action = '#'>
                <TextFieldControl
                    name = 'name'
                    className='mt-8 mui-shifted-label-input'
                    control = {data}
                    rules = {formRules['name']}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    error = {Boolean(errors.name)}
                    label = {strings.forms.board.boardIssue.name}
                    placeholder = {strings.forms.board.boardIssue.placeholder.name}
                    disabled={isPageStateViewDetails()}
                    multiline
                />
                <p className='font-description'>{strings.forms.board.boardIssue.description}</p>
                <div id='board-issue-description-editor' className='mb-10'>
                    <QuillEditor value={descriptionData} onChange={setDescriptionData} defaultValue={values['description']} />
                </div>
                <AutoCompleteControl
                    name='assignee'
                    className='mt-0 mb-0 mui-shifted-label-input'
                    nameKey={'fullName'}
                    valueKey={'id'}
                    control = {control}
                    value={values['assignee']}
                    setValue={setValue}
                    rules = {formRules['assignee']}
                    error = {Boolean(errors.assignee)}
                    helperText={errors.assignee && strings.forms.common.thisFieldIsRequired}
                    label = {strings.forms.board.boardIssue.assignee}
                    placeholder = {strings.forms.board.boardIssue.placeholder.assignee}
                    options = {assignees}
                    disabled={isPageStateViewDetails()}
                />
                <p className = 'assign-issue-to-me' onClick = {assignToMe}>{strings.forms.board.boardIssue.assignToMe}</p>
                <AutoCompleteControl
                    name='issueType'
                    nameKey={'name'}
                    valueKey={'id'}
                    className='margin-shifted-label-select mui-shifted-label-input'
                    control = {control}
                    value={values['issueType']}
                    setValue={setValue}
                    rules = {formRules['issueType']}
                    error = {Boolean(errors.issueType)}
                    helperText={errors.issueType && strings.forms.common.thisFieldIsRequired}
                    label = {strings.forms.board.boardIssue.issueType}
                    placeholder = {strings.forms.board.boardIssue.placeholder.issueType}
                    options = {issueTypes}
                    disabled={isPageStateViewDetails()}
                />
                <SelectControl
                    value={values['priority']}
                    className='margin-shifted-label-select mui-shifted-label-input'
                    setValue={setValue}
                    rules={formRules['priority']}
                    name='priority'
                    control={control}
                    error={Boolean(errors.priority)}
                    helperText={errors.priority && strings.forms.common.thisFieldIsRequired}
                    label = {strings.forms.board.boardIssue.priority}
                    options={issuePriorities}
                    nameKey={'name'}
                    valueKey={'type'}
                    disabled={isPageStateViewDetails()}
                />
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.forms.common.save}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.forms.common.cancel}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default BoardIssueForm;
