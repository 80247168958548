import strings from "../../localization";

const IsArchivedType = {
    ARCHIVED: 1,
    NOT_ARCHIVED: 2,
}

export function getIsArchivedTypes() {
    return [
        {
            id: IsArchivedType.ARCHIVED,
            name: getIsArchivedTypeString(IsArchivedType.ARCHIVED)
        },
        {
            id: IsArchivedType.NOT_ARCHIVED,
            name: getIsArchivedTypeString(IsArchivedType.NOT_ARCHIVED)
        },

    ];
}

export function getIsArchivedTypeString(isArchived) {
    switch(isArchived) {
        case IsArchivedType.ARCHIVED: return strings.constants.workspaces.linkedIssueType.archived;
        case IsArchivedType.NOT_ARCHIVED: return  strings.constants.workspaces.linkedIssueType.notArchived;
    }
}

export default IsArchivedType;