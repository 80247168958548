import strings from "../../localization";

export const CheckListScheduledCheckStatus = {
    PENDING: 1,
    IN_PROGRESS: 2,
    DONE: 3,
    CANCELED: 4,
}

export function getCheckListScheduledCheckStatus() {
    return [
        {
            value: CheckListScheduledCheckStatus.PENDING,
            name: getCheckListScheduledCheckStatusString(CheckListScheduledCheckStatus.PENDING)
        },
        {
            value: CheckListScheduledCheckStatus.IN_PROGRESS,
            name: getCheckListScheduledCheckStatusString(CheckListScheduledCheckStatus.IN_PROGRESS)
        },
        {
            value: CheckListScheduledCheckStatus.DONE,
            name: getCheckListScheduledCheckStatusString(CheckListScheduledCheckStatus.DONE)
        },
        {
            value: CheckListScheduledCheckStatus.CANCELED,
            name: getCheckListScheduledCheckStatusString(CheckListScheduledCheckStatus.CANCELED)
        }
    ];
}

export function getCheckListScheduledCheckStatusString(scheduledCheckStatus) {
    switch(Number(scheduledCheckStatus)) {
        case CheckListScheduledCheckStatus.PENDING: return strings.pages.checkList.scheduleCheck.scheduledCheckStatus.pending;
        case CheckListScheduledCheckStatus.IN_PROGRESS: return strings.pages.checkList.scheduleCheck.scheduledCheckStatus.inProgress;
        case CheckListScheduledCheckStatus.DONE: return strings.pages.checkList.scheduleCheck.scheduledCheckStatus.done;
        case CheckListScheduledCheckStatus.CANCELED: return strings.pages.checkList.scheduleCheck.scheduledCheckStatus.canceled;
    }
}

export default CheckListScheduledCheckStatus;