import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getProjectTaskCategory(projectId) {
    return await request(`/api/project-task-categories/all`, {projectId});
}

export async function getProjectTaskCategories(projectId){
    return await request('/api/project-task-categories', {projectId});
}

export async function updateProjectTaskCategory(data) {
    return await request(`/api/project-task-categories/${data.projectId}/${data.taskCategoryId}`, data, HttpMethod.PUT);
}