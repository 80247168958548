import React, {useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import CompanyDocumentItemType, {getCompanyDocumentItemTypes} from "../../../../../Constants/CompanyDocumentManagement/CompanyDocumentItem/CompanyDocumentItemType";
import {getCompanyDocumentReferenceValueTypes} from "../../../../../Constants/CompanyDocumentManagement/CompanyDocumentItem/CompanyDocumentReferenceValueType";
import {getCompanyDocumentReferenceTypes} from "../../../../../Constants/CompanyDocumentManagement/CompanyDocumentItem/CompanyDocumentReferenceType";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";

const CompanyDocumentTemplateItemForm = ({
                         onSubmit,
                         onCancel,
                         data,
                         form,
                         errors,
                         values,
                         setValue,
                         formRules
                     }) => {

    const [localValue, setLocalValue] = useState(values)

    const newValues = (attr, value) => {
        let v = {}
        v[attr] = value;
        setLocalValue({...localValue, ...v})
        setValue(attr, value)
    }
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;


    return (
        <FormProvider {...form}>
            <form id='company-document-template-item-form' className='form' action="#">

                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.companyDocument.companyDocumentTemplateItem.name}
                    disabled={isPageStateViewDetails()}
                />
                <SelectControl
                    setValue={newValues}
                    name='type'
                    useValue
                    value={values['type']}
                    control={data}
                    label={strings.forms.companyDocument.companyDocumentTemplateItem.type}
                    options={getCompanyDocumentItemTypes()}
                    nameKey={'name'}
                    valueKey={'value'}
                    disabled={isPageStateViewDetails()}
                />
                {
                    ((localValue?.type?.value === CompanyDocumentItemType.REFERENCE) || (localValue?.type === CompanyDocumentItemType.REFERENCE)) &&
                    <React.Fragment>
                        <SelectControl
                            value={values['referenceType']}
                            setValue={newValues}
                            name='referenceType'
                            control={data}
                            label={strings.forms.companyDocument.companyDocumentTemplateItem.referenceType}
                            options={getCompanyDocumentReferenceTypes()}
                            nameKey={'name'}
                            valueKey={'value'}
                            disabled={isPageStateViewDetails()}
                        />
                        {
                            localValue?.referenceType &&
                            <SelectControl
                                value={values['referenceValueType']}
                                setValue={setValue}
                                name='referenceValueType'
                                control={data}
                                label={strings.forms.companyDocument.companyDocumentTemplateItem.referenceValueType}
                                options={getCompanyDocumentReferenceValueTypes(localValue.referenceType.value || localValue.referenceType)}
                                nameKey={'name'}
                                valueKey={'value'}
                                disabled={isPageStateViewDetails()}
                            />
                        }
                    </React.Fragment>
                }

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default CompanyDocumentTemplateItemForm;