import strings from "../../localization";

export const EmployeeStatus = {
    CURRENT: 1,
    EX_EMPLOYEE: 2,
    RESIGNATION: 3
}

export function getEmployeeStatuses() {
    return[
        { id: EmployeeStatus.CURRENT, name: getEmployeeStatusString(EmployeeStatus.CURRENT)},
        { id: EmployeeStatus.EX_EMPLOYEE, name: getEmployeeStatusString(EmployeeStatus.EX_EMPLOYEE)},
        { id: EmployeeStatus.RESIGNATION, name: getEmployeeStatusString(EmployeeStatus.RESIGNATION)}
    ];
}

export function getEmployeeStatusString(employeeStatus) {
    switch(employeeStatus){
        case EmployeeStatus.CURRENT:
            return strings.constants.hr.employeeStatus.current;
        case EmployeeStatus.EX_EMPLOYEE:
            return strings.constants.hr.employeeStatus.exEmployee;
        case EmployeeStatus.RESIGNATION:
            return strings.constants.hr.employeeStatus.resignation;
    }
}

export default EmployeeStatus;
