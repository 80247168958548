import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import strings from "../../../localization";
import {useDispatch, useSelector} from "react-redux";
import {
    deletePipelineStage,
    getPipelineStages,
    reorderPipelineStage
} from "../../../Services/Company/PipelineStageService";
import {Card, Drawer, IconButton, Paper} from "@mui/material";
import PageState from "../../../Constants/Base/PageState";
import DrawerWrapper from "../../../Components/Layout/DrawerWrapper";
import AddPipelineStage from "./AddPipelineStage";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {reorder, sortByColumnOrder} from "../../../Util/DnDUtil";
import EditPipelineStage from "./EditPipelineStage";
import {getPipelineStageTypeClass, getPipelineStageTypeString} from "../../../Constants/Company/PaymentStageType";
import { hasPermission } from "../../../Util/PermissionUtil";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import LoaderContext from "../../../Context/LoaderContext";
import { useContext } from "react";
import YesNoDialog, {YesNoDialogResult} from "../../../Components/Dialogs/YesNoDialog";
import SnackbarContext from "../../../Context/SnackbarContext";

const PipelineStageList = () => {

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth)
    const [pageState, setPageState] = useState(PageState.View);
    const [drawerTitle, setDrawerTitle] = useState('');
    const [pipelineStages, setPipelineStages] = useState([]);
    const [selectedStage, setSelectedStage] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const {showMessage} = useContext(SnackbarContext);
    const {loading, setLoading} = useContext(LoaderContext);
    const {id: pipelineId} = useParams();
    const permissionGroup = AppPermissions.PIPE_LINE_STAGE.GROUP;

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.administration.pipelineStage.pipelineStageList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.ADMINISTRATION},
                {label: strings.pages.administration.pipeline.pipelineList.pageTitle},
                {label: strings.pages.administration.pipelineStage.pipelineStageList.pageTitle},
            ],        
        }));
        fetch();
    }, [])

    const fetch = () => {
        setPageState(PageState.View)
        setLoading(true);
        getPipelineStages({
            pipelineId: pipelineId
        }).then(response => {
            setLoading(false);
            if (!response || !response.ok) {
                setPipelineStages([]);
                return;
            }

            setPipelineStages(sortByColumnOrder(response.data));
        });
    }

    const renderPipelineStages = () => {

        let result = [];

        for (let stage of pipelineStages) {
            result.push(
                <Draggable
                    key={stage.id}
                    draggableId={'stage-' + stage.id}
                    index={result.length}
                >
                    {(provided, snapshot) => (
                        <div
                            className="card-wrapper"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Card key={'pipeline-stage-' + result.length} className='stage-card'>
                                <div className="header" style={{backgroundColor: stage.color ? stage.color : 'black'}}>
                                    <div className="title">{stage.name}</div>
                                </div>
                                <div className="padded-content">
                                    <div className="description">
                                        {stage.description}
                                    </div>
                                    <div className='footer'>
                                        <div className="left">
                                            { 
                                                hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].EDIT, auth.permissions) &&
                                                <IconButton onClick={() => handleEdit(stage)}>
                                                    <img src="/images/pipeline-stage-edit.svg" loading="lazy"/>
                                                </IconButton>
                                            }
                                            {
                                                hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].DELETE, auth.permissions) &&
                                                <IconButton onClick={() => handleShowDeleteDialog(stage)}>
                                                    <img src="/images/pipeline-stage-delete.svg"/>
                                                </IconButton>
                                            }
                                        </div>
                                        <div className={`right ${getPipelineStageTypeClass(stage.type)}`}>
                                            {getPipelineStageTypeString(stage.type)}
                                        </div>
                                    </div>
                                </div>
                               
                            </Card>
                        </div>
                    )}
                </Draggable>
            )
        }

        return result;
    }

    const handleAdd = () => {
        setPageState(PageState.Add);
        setDrawerTitle(strings.pages.administration.pipelineStage.pipelineStageList.addPipelineStage)
    }

    const handleEdit = (stage) => {
        setSelectedStage(stage)
        setPageState(PageState.Edit)
        setDrawerTitle(strings.pages.administration.pipelineStage.pipelineStageList.editPipelineStage)
    }

    const handleShowDeleteDialog = (stage) => {
        setSelectedStage(stage)
        setShowDeleteDialog(true);
    }

    const handleDeleteDialogResult = (result, payload) => {
        if (result === YesNoDialogResult.YES) {
            deletePipelineStage(payload.id).then(response => {
                if(!response || !response.ok) {
                    setShowDeleteDialog(false);
                    showMessage(strings.components.tablePage.errorDeletingItem, 'error')
                    return;
                }
                showMessage(strings.components.tablePage.confirmDelete,'success')
                fetch();
            })
        }
        setShowDeleteDialog(false);

    }

    const isDrawerOpen = () => {
        return pageState !== PageState.View;
    }

    const onDragEnd = (result) => {
        if(!result.source || !result.destination) {
            return;
        }

        const stages = reorder(pipelineStages, result.source.index, result.destination.index);

        reorderPipelineStage({
            stages: stages
        }).then(response => {

        })

        setPipelineStages(stages);
    }

    return <React.Fragment>
        <Drawer id='drawer' anchor='right' open={isDrawerOpen()} onClose={() => setPageState(PageState.View)}>
            <DrawerWrapper onBack={() => setPageState(PageState.View)} title={drawerTitle}>
                {
                    pageState === PageState.Add && 
                    <AddPipelineStage pipelineId={pipelineId}
                                      onCancel={() => setPageState(PageState.View)} onFinish={() => fetch()}/>
                }
                {
                    pageState === PageState.Edit &&
                    <EditPipelineStage data={selectedStage}
                                      onCancel={() => setPageState(PageState.View)} onFinish={() => fetch()}/>
                }

            </DrawerWrapper>
        </Drawer>
        <div className="w-100 d-flex justify-end">
            {
                hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].ADD, auth.permissions) &&
                 <IconButton className='plus-button mui-rounded-button mb-5 mr-3' onClick={() => handleAdd()}>
                    <img src='/images/table-page/add-cross.svg'/>
                    <div className="font-15">
                        {strings.pages.administration.pipelineStage.pipelineStageList.addPipelineStage}
                    </div>
                </IconButton>
            }
        </div>
           
            {
                hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].VIEW, auth.permissions) &&
                <div id='pipeline-stage-page' className="ml-3">
                    <div className='droppable-container-wrapper'>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable  droppableId='droppable-container' direction="horizontal">
                                {(provided, snapshot) => (
                                    <div className="droppable-container"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {renderPipelineStages()}
                                        {
                                            <YesNoDialog show={showDeleteDialog}
                                                         payload={selectedStage}
                                                         handleResult={handleDeleteDialogResult}
                                                         title={strings.components.tablePage.confirmDelete}
                                                         text={strings.components.tablePage.confirmDeleteMessage}/>

                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>

                </div>
        }
    </React.Fragment>
}

export default PipelineStageList;
