import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import LinearProgress from '@mui/material/LinearProgress';
import {addCompanyDocumentTemplateItem} from "../../../Services/CompanyDocumentManagement/CompanyDocumentTemplateItemService";
import CompanyDocumentTemplateItemForm
    from "../../../Components/Forms/Pages/CompanyDocument/CompanyDocumentTemplateItem/CompanyDocumentTemplateItemForm";
import {getCompanyDocumentItemTypes} from "../../../Constants/CompanyDocumentManagement/CompanyDocumentItem/CompanyDocumentItemType";
import SnackbarContext from "../../../Context/SnackbarContext";

const formRules = {
    'name': {required: true},
    'type': {required: true},
    'referenceType': {required: false},
    'referenceValueType': {required: false},
}

const AddCompanyDocumentTemplateItem = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm();
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const companyDocumentItemTypes = getCompanyDocumentItemTypes()

    useEffect(() => {
        setDrawerTitle(strings.pages.companyDocument.companyDocumentTemplateItem.addCompanyDocumentTemplateItem.pageTitle);
    });

    const onSubmit = (data) => {

        setShowLoader(true);

        addCompanyDocumentTemplateItem({
            companyDocumentTemplateId: props.companyDocumentTemplateId,
            ...data}).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <CompanyDocumentTemplateItemForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                companyDocumentItemTypes={companyDocumentItemTypes}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default AddCompanyDocumentTemplateItem;
