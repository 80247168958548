import strings from "../../localization";

const InvoiceType = {
    ALL: -1,
    INVOICE: 1,
    PRO_INVOICE: 2,
    ADVANCE_INVOICE: 3,
    STORNO: 4,
    CREDIT_NOTE: 5,
}

export function getInvoiceTypes() {
    return [
        {
            id: InvoiceType.INVOICE,
            name: getInvoiceTypeString(InvoiceType.INVOICE)
        },
        {
            id: InvoiceType.PRO_INVOICE,
            name: getInvoiceTypeString(InvoiceType.PRO_INVOICE)
        },
        {
            id: InvoiceType.ADVANCE_INVOICE,
            name: getInvoiceTypeString(InvoiceType.ADVANCE_INVOICE)
        },
        {
            id: InvoiceType.STORNO,
            name: getInvoiceTypeString(InvoiceType.STORNO)
        },
        {
            id: InvoiceType.CREDIT_NOTE,
            name: getInvoiceTypeString(InvoiceType.CREDIT_NOTE)
        }
    ];
}

export function getInvoiceTypesWithAll() {
    return [
        {
            id: InvoiceType.ALL,
            name: getInvoiceTypeString(InvoiceType.ALL)
        },
        {
            id: InvoiceType.INVOICE,
            name: getInvoiceTypeString(InvoiceType.INVOICE)
        },
        {
            id: InvoiceType.PRO_INVOICE,
            name: getInvoiceTypeString(InvoiceType.PRO_INVOICE)
        },
        {
            id: InvoiceType.ADVANCE_INVOICE,
            name: getInvoiceTypeString(InvoiceType.ADVANCE_INVOICE)
        },
        {
            id: InvoiceType.STORNO,
            name: getInvoiceTypeString(InvoiceType.STORNO)
        }
    ];
}

export function getInvoiceTypeString(paymentType) {
    switch(paymentType) {
        case InvoiceType.INVOICE: return strings.constants.finance.invoiceType.invoice;
        case InvoiceType.PRO_INVOICE: return strings.constants.finance.invoiceType.proInvoice;
        case InvoiceType.ADVANCE_INVOICE: return strings.constants.finance.invoiceType.advanceInvoice;
        case InvoiceType.ALL: return strings.constants.finance.invoiceType.all;
        case InvoiceType.STORNO: return strings.constants.finance.invoiceType.storno;
        case InvoiceType.CREDIT_NOTE: return strings.constants.finance.invoiceType.creditNote;
    }
}

export default InvoiceType;