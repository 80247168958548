import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllProjectResourceTypes(data) {
    return await request('/api/project-resource-types/all', data);
}

export async function getProjectResourceTypeList(data) {
    return await request('/api/project-resource-types', data);
}

export async function getProjectResourceType(id) {
    return await request('/api/project-resource-types/' + id);
}

export async function deleteProjectResourceType(id) {
    return await request('/api/project-resource-types/' + id, {}, HttpMethod.DELETE);
}

export async function editProjectResourceType(data) {
    return await request('/api/project-resource-types/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addProjectResourceType(data) {
    return await request('/api/project-resource-types', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        parentId: data.parent ? data.parent.id : null,
    }
}
