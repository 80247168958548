import LeaveRequestForm from "../../../Components/Forms/Pages/HR/LeaveRequest/LeaveRequestForm";
import LinearProgress from '@mui/material/LinearProgress';
import {Paper} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DrawerContext from "../../../Context/DrawerContext"
import TablePageContext from "../../../Context/TablePageContext";
import strings from "../../../localization";
import { addLeaveRequest } from "../../../Services/HR/LeaveRequestService";
import {getAllLeaveTypes} from "../../../Services/HR/LeaveTypeService";
import SnackbarContext from "../../../Context/SnackbarContext";

const formRules = {
    'leaveType': {required: true},
    'fromDate': {required: true},
    'toDate': {required: true},
}

const AddLeaveRequest = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const {showMessage} = useContext(SnackbarContext)
    const form = useForm({
        defaultValues: {
            approved: true,
            toDate: null,
            fromDate: null
        }
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [leaveTypes, setLeaveTypes] = useState([]);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.leaveRequest.addLeaveRequest.pageTitle);
        fetch();
    }, []);

    const fetch = () => {
        getAllLeaveTypes().then(response => {

            if(!response || !response.ok) {
                setLeaveTypes([]);
                return;
            }

            setLeaveTypes(response.data);
        })
    }

    const onSubmit = (data) => {
        setShowLoader(true);

        addLeaveRequest(data).then(response => {
            setShowLoader(false);

            if(!response){
                showMessage(strings.commonMessages.errorAdding, 'error');
                return;
            }
            if (!response.ok) {          
                showMessage(strings.error.LeaveAndLeaveRequestHandler[response.response.data.message], 'error', 5000);  
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress />
            }
            <LeaveRequestForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                errors={errors} data={data} form={form} control={control}
                leaveTypes={leaveTypes}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()} />
        </Paper>
    </div>
}

export default AddLeaveRequest;