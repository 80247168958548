import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getDocumentComments(data){
    return await request('/api/document-comments/' + data.documentId , data)
}

export async function addDocumentComment(data) {
    return await request('/api/document-comments', data, HttpMethod.POST);
}

export async function editDocumentComment(data) {
    return await request('/api/document-comments/' + data.id, transformData(data), HttpMethod.PUT);
}

export async function deleteDocumentComment(id) {
    return await request('/api/document-comments/' + id, {}, HttpMethod.DELETE);
}
function transformData(data){
    return {
        ...data,
        documentId: data?.document?.id
    };
}