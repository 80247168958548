import React, {useEffect, useState} from "react";
import {
    addCandidateRecord, deleteCandidateRecord,
    editCandidateRecord,
    getAllCandidateRecords
} from "../../../Services/HR/CandidateRecordService";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import CommentPageContext from "../../../Context/CommentPageContext";
import CommentPage from "../../../Components/DataGrid/CommentPage";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../../Slices/PageSlice";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import Modules from "../../../Constants/Base/Modules";

const CandidateRecord = (props) => {

    const dispatch = useDispatch();
    const [selected, setSelected] = useState(null)
    const [editMode, setEditMode] = useState(null)
    const [candidateRecords, setCandidateRecords] = useState([]);

    const permissionGroup = AppPermissions.CANDIDATE_RECORDS.GROUP;

    const value = {
        selected, setSelected,
        editMode, setEditMode,
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.hr.candidate.candidateList.records,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.pages.hr.candidate.candidateDetails.pageTitle},
                {label: strings.pages.hr.candidate.candidateList.records},
            ],        
        }));
    }, [])

    useEffect(() => {
        fetch();
    }, [props.candidate])

    const fetch = () => {
        if(!props.candidate) {
            return;
        }
        getAllCandidateRecords(props.candidate.id).then(response => {
            if(!response || !response.ok) {
                setCandidateRecords([]);
                return;
            }
            setCandidateRecords(response.data)
        })
    }

    const add = ({content}) => addCandidateRecord({content, candidateId: props.candidate.id});

    const edit = ({entity, content}) => editCandidateRecord({...entity, content});


    return <CommentPageContext.Provider value={value}>
        <CommentPage permissionGroup={permissionGroup} entities={candidateRecords} deleteItem={deleteCandidateRecord} onAdd={add} onFinish={fetch} onEdit={edit}/>
    </CommentPageContext.Provider>
}

export default CandidateRecord;