import { Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BusinessTripForm from "../../../Components/Forms/Pages/HR/BusinessTrip/BusinessTripDailyWageForm";
import { useDispatch, useSelector } from "react-redux";
import SnackbarContext from "../../../Context/SnackbarContext";
import { useForm } from "react-hook-form";
import { changeBreadcrumbs } from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import Modules from "../../../Constants/Base/Modules";
import {
  updateBusinessTripDailyWages,
  getBusinessTripDailyWages,
} from "../../../Services/HR/BusinessTripService";
import { getCurrencies } from "../../../Util/SiteDataUtil";

const BusinessTrip = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { showMessage } = useContext(SnackbarContext);
  const form = useForm({
    defaultValues: {
      internalHrOnly: false,
    },
  });
  const siteData = useSelector((state) => state.siteData);
  const {
    data,
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = form;

  useEffect(() => {
    dispatch(
      changeBreadcrumbs({
        title: strings.pages.hr.settings.businessTrip,
        hierarchy: [
          { label: strings.navigation.managmentTag },
          { label: Modules.HR },
          { label: strings.pages.hr.settings.settings },
          { label: strings.pages.hr.settings.businessTrip },
        ],
      })
    );
    fetch();
  }, []);

  const fetch = () => {
    getBusinessTripDailyWages().then((response) => {
      if (!response || !response.ok) {
        return;
      }
      reset(response.data.entity);
    });
  };

  const save = (data) => {
    updateBusinessTripDailyWages(data).then((response) => {
      if (!response || !response.ok) {
        showMessage(strings.commonMessages.errorAdding, "error");
        return;
      }

      showMessage(strings.commonMessages.saved, "success");
      fetch();
    });
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={4}>
        <Paper className="paper rounded-2">
          <BusinessTripForm
            control={control}
            values={getValues()}
            setValue={setValue}
            data={data}
            form={form}
            onSubmit={handleSubmit(save)}
            currencies={getCurrencies(siteData)}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default BusinessTrip;
