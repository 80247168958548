
const DeadlineType = {
    ONE: 1,
    THREE: 3,
    FIVE: 5,
    TEN: 10,
    FIFTEEN: 15,
    THIRTY: 30,
    FORTY_FIVE: 45,
    SIXTY: 60,
}

export function getDeadlineTypes() {
    return [
        {
            id: DeadlineType.ONE,
        },
        {
            id: DeadlineType.THREE,
        },
        {
            id: DeadlineType.FIVE,
        },
        {
            id: DeadlineType.TEN,
        },
        {
            id: DeadlineType.FIFTEEN,
        },
        {
            id: DeadlineType.THIRTY,
        },
        {
            id: DeadlineType.FORTY_FIVE,
        },
        {
            id: DeadlineType.SIXTY,
        },
    ];
}


export default DeadlineType;