import {useEffect, useState, useCallback, useContext, useRef} from 'react';
import strings from "../../../localization";
import {changePageSizeState} from "../../../Slices/PageSlice";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import PageState from "../../../Constants/Base/PageState";
import {useDispatch, useSelector} from "react-redux";
import {setFavoriteBoards as setFavoriteBoardsRedux} from "../../../Slices/AuthSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import DrawerContext from "../../../Context/DrawerContext";
import YesNoDialog, {YesNoDialogResult} from "../../../Components/Dialogs/YesNoDialog";
import {useForm} from "react-hook-form";
import {FormProvider} from "react-hook-form";
import TextSearchControl from "../../../Components/Controls/Inputs/TextSearchControl";
import {IconButton, Collapse, Grid, Drawer, Chip, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { debounce } from 'lodash';
import DrawerWrapper from "../../../Components/Layout/DrawerWrapper";
import { BoardLogo } from '../../../Util/AvatarUtil';
import { renderColumnPipline } from '../../../Components/DataGrid/ValueCellRender';
import AppPermissions from '../../../Constants/Permissions/Permissions';
import { hasPermission } from '../../../Util/PermissionUtil';
import AddBoard from './AddBoard';
import EditBoard from './EditBoard';
import { useLocation, useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { getBoardsList, deleteBoard, favoriteBoard, getFavoriteBoards } from '../../../Services/Workspaces/BoardsService';
import LoaderContext from "../../../Context/LoaderContext";
import SnackbarContext from '../../../Context/SnackbarContext';
import {formatQueryObject} from "../../../Util/UrlUtil";
import { Delete, Edit } from '@mui/icons-material';


const BoardsList = () => {
    const {id: workspaceId} = useParams();
    const { search, state } = useLocation();
    const {loading, setLoading} = useContext(LoaderContext);
    const { showMessage } = useContext(SnackbarContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const changeLocation = useRef(false);
    const [drawerTitle, setDrawerTitle] = useState('');
    const value = {drawerTitle, setDrawerTitle}
    const [showDelete, setShowDelete] = useState(false);
    const [selectedBoard, setSelectedBoard] = useState(null);
    const [boardAnchor, setBoardAnchor] = useState(null);
    const [allBoards, setAllBoards] = useState([]);
    const [favoriteBoards, setFavoriteBoards] = useState([]);
    const [pageState, setPageState] = useState(0);
    const form = useForm({
            defaultValues: {
                term: '',
            }
    });
    const {data, watch, setValue, getValues} = form;
    const [filter, setFilter] = useState({});
    const [collapseState, setCollapseState] = useState({
        favorites: true,
        all: true,
    });
    let watchValues = ['term'];
    const permissionGroup = AppPermissions.BOARDS;
    const permissionGroupPipe = AppPermissions.PIPE_LINE_STAGE;

    useEffect(() => {
            const subscription = watch((data) => {
                if(!updatedFilter){
                    return;
                }

                if(changeLocation.current){
                    return;
                }

                debounceSetFilter({term: data.term})
            });

            return () => {
                subscription.unsubscribe();
            }
    }, [watch, updatedFilter, filter]);

    const debounceSetFilter = useCallback(
            debounce((newValue) =>{
                    setSearchParams(formatQueryObject(newValue), {state: state});
                    setFilter(newValue)
            }, 800),
    []);

    const populateFilters = (params) => {
        form.reset();
        setValue('term', params.term);
        setFilter({term: params.term});

        changeLocation.current = false;
    }

    useEffect(() => {
        if(!searchParams.toString()){
                return;
        }

        populateFilters({term: searchParams.get('term')});
        setUpdatedFilter(true);
    },[]);


    useEffect(() => {
        const onLocationChange = () => {
            changeLocation.current = true;
            populateFilters({term: new URLSearchParams(window.location.search).get('term')});
        }

        window.addEventListener('popstate', onLocationChange);

        return () => {
                window.removeEventListener('popstate', onLocationChange);
        }
    },[]);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.navigation.boards,
            hierarchy:[
                {label: strings.navigation.homeTag},
                {label: strings.navigation.workspaces, route: '/workspaces'},
                {label: strings.navigation.boards},
            ],        
        }));
    }, [])
    const fetchBoards= () => {
        setLoading(true);
        getBoardsList({
            ...filter,
            workspace: workspaceId
        }).then(response => {
            if(!response || !response.ok){
                return;
            }

            let allBoardsTmp = [];
            let favoriteBoardsTmp = [];

            for(const board of response.data){
                let tmpBoard = {
                    ...board[0],
                    totalIssues: board.totalIssues,
                    myIssues: board.myIssues,
                    isFavorite: board?.isFavorite,
                }
                allBoardsTmp.push(tmpBoard);
                if(tmpBoard.isFavorite){
                    favoriteBoardsTmp.push(tmpBoard);
                }
            }

            setFavoriteBoards(favoriteBoardsTmp);
            setAllBoards(allBoardsTmp);
            setLoading(false);
        });
    }

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetchBoards();
    },[filter, updatedFilter]);

    const isDrawerOpen = () => {
            return pageState !== PageState.View;
    }

    const handleCollapse = (type) => {
        setCollapseState((prev) => ({...prev, [type]: !prev[type]}));
    }

    const handleChangeLogo = (event, boardId) => {
        event.stopPropagation();

        navigate(`/workspaces/${workspaceId}/boards/${boardId}/change-logo`);
    }

    const handleEdit = (event) => {
		event.stopPropagation();

        setPageState(PageState.Edit)
        setBoardAnchor(null);
    }

    const handleDelete = (event) => {
		event.stopPropagation();

        setShowDelete(true);
        setBoardAnchor(null);
    }

    const handleDeleteDialogResult = (result) => {
        if(result === YesNoDialogResult.NO){
            setShowDelete(false);
            setSelectedBoard(null);

            return;
        }

        if(result === YesNoDialogResult.YES){
            setShowDelete(false);

            deleteBoard(selectedBoard.id).then(response => {
                if (!response || !response.ok) {
                        showMessage(strings.components.tablePage.errorDeletingItem, 'error')
                        setShowDelete(false);
                        setSelectedBoard(null);
                        onFinish();

                        return;
                }
                showMessage(strings.components.tablePage.itemDeleted, 'success')
                setShowDelete(false);
                setSelectedBoard(null);
                onFinish();
            });

            return;
        }
    }

    const goToPipeline = (board) => {
        if(!hasPermission(auth.user, permissionGroupPipe.GROUP, permissionGroupPipe.VIEW, auth.permissions)) {
            return;
        }
        navigate('/pipelines/' + board.pipeline.id);
    }

    const handleMyIssues = (event, board) => {
		event.stopPropagation();

        if(!hasPermission(auth.user, permissionGroup.GROUP, permissionGroup.VIEW, auth.permissions)) {
            return;
        }
        navigate({
                pathname: `/workspaces/${workspaceId}/boards/${board.id}`, 
                search: `assignee=${auth.user.id}`,
            },
            {state: {board: board}}
        );
    }

    const handleTotalIssues = (event, board) => {
		event.stopPropagation();

		if(!hasPermission(auth.user, permissionGroup.GROUP, permissionGroup.VIEW, auth.permissions)) {
			return;
		}
		navigate(`/workspaces/${workspaceId}/boards/${board.id}`, {state: {board: board}});
    }

    const onFinish = () => {
            setPageState(PageState.View);

            fetchFavoriteBoards();
            fetchBoards();
            setSelectedBoard(null);
    }

    const onCancel = () => {
            setPageState(PageState.View);
            setSelectedBoard(null);
    }

    const handleFavorite = (event, boardId) => {
		event.stopPropagation();

        setLoading(true);
        favoriteBoard(boardId).then(response => {
            if(!response || !response.ok){
                return;
            }

            fetchFavoriteBoards();
            fetchBoards();
        });
    }

    const fetchFavoriteBoards = () => {
            getFavoriteBoards().then(response => {
                if(!response || !response.ok) {
                        return;
                }

                dispatch(setFavoriteBoardsRedux(response.data));
            });
    }

    const hasEditPermission = () => {
        return hasPermission(auth.user, permissionGroup.GROUP, permissionGroup.EDIT, auth.permissions);
    }

    const handleCloseBoardOptions = (event) => {
		event.stopPropagation();

        setBoardAnchor(null);
        setSelectedBoard(null);
    }

    const handleOpenBoardOptions = (event, board) => {
		event.stopPropagation();

        setSelectedBoard(board);
        setBoardAnchor(event.currentTarget);
    }
    
    const lineTitle = (title, type) => {
        return (
            <div className = 'title-line-container'>
                <div className = 'title-text'>
                    <p>{title}</p>
                </div>
                <img src = '/images/arrow-open.svg' onClick = {() => handleCollapse(type)} className = {!collapseState[type] ? 'rotate-collapse-arrow' : ''} loading = 'lazy' />
                <div className = 'title-line'>
                </div>
            </div>
        );
    }

    const renderBoard = (board) => {
        return (
            <Grid item xs = {12}>
                <div className = 'board'>
                    <div className = 'board-content' onClick = {(event) => handleTotalIssues(event, board)}>
                        <div className = 'board-header'>
                            <BoardLogo changeLogo = {(e) => handleChangeLogo(e, board.id)} hasPermission = {hasEditPermission()} board = {board} />
                            <div className = 'board-text'>
                                <div className = 'board-title-container'>
								<Tooltip title={board.name}>
                                    <p className = {hasPermission(auth.user, permissionGroup.GROUP, permissionGroup.VIEW, auth.permissions) ? 'one-liner cursor-pointer' : 'one-liner'}>
										{board.name}
                                    </p>
									</Tooltip>
                                </div>
                            </div>
							<div className='board-options'>
								<img className = 'favorite-star' src = {board?.isFavorite ? '/images/boards-star-yellow.svg' : '/images/boards-star-outline.svg'} 
									loading = 'lazy' onClick = {(event) => handleFavorite(event, board.id)} /> 
								<IconButton onClick = {(e) => handleOpenBoardOptions(e, board)}>
									<img src = '/images/more-vertical.svg' />
								</IconButton> 
								<Menu className = 'board-dropdown-options' anchorEl={boardAnchor} id={"basic-menu-" + board.id} open={Boolean(boardAnchor)} onClose={(event) => handleCloseBoardOptions(event)}>
									{
										hasPermission(auth.user, permissionGroup.GROUP, permissionGroup.EDIT, auth.permissions) &&
										<MenuItem onClick = {(event) => handleEdit(event)}>
													<ListItemIcon>
														<img src="/images/table-page/edit.svg" />
													</ListItemIcon>
													<ListItemText>{strings.components.actionCell.edit}</ListItemText>
											</MenuItem>
									}
									{
										hasPermission(auth.user, permissionGroup.GROUP, permissionGroup.DELETE, auth.permissions) &&
										<MenuItem onClick={event => handleDelete(event)}>
													<ListItemIcon>
														<img src="/images/delete-red.svg" />
													</ListItemIcon>
													<ListItemText>{strings.components.actionCell.delete}</ListItemText>
											</MenuItem>
									}
								</Menu>
							</div> 
                        </div>
                            <div className = 'board-info-container'>
                                <p className='quick-links-label'>Quick links</p>
                                <div className = {hasPermission(auth.user, permissionGroup.GROUP, permissionGroup.VIEW, auth.permissions) ? 'board-issues-container permission' : 'board-issues-container'} onClick = {(event) => handleMyIssues(event, board)}>
                                    <p>My issues</p>
                                    <Chip label = {board.myIssues}
                                        className = {hasPermission(auth.user, permissionGroup.GROUP, permissionGroup.VIEW, auth.permissions) ? '' : 'no-permission'}
                                    />
                                </div>
								<div className = {hasPermission(auth.user, permissionGroup.GROUP, permissionGroup.VIEW, auth.permissions) ? 'board-total-container permission' : 'board-total-container'} onClick = {(event) => handleTotalIssues(event, board)}>
                                    <p>Total issues</p>
                                    <Chip label = {board.totalIssues}  
                                        className = {hasPermission(auth.user, permissionGroup.GROUP, permissionGroup.VIEW, auth.permissions) ? '' : 'no-permission'}
                                    />
                                </div>
                            </div>
                    </div>
                </div>
            </Grid>
        );
    }

    const renderBoards = (boards) => {
        return boards.map((board) => renderBoard(board));
    }


    return(
        <DrawerContext.Provider value = {value}>
            <YesNoDialog show={showDelete}
                                     payload={selectedBoard}
                                     handleResult={handleDeleteDialogResult}
                                     title={strings.components.tablePage.confirmDelete}
                                     text={strings.pages.boards.boardsList.deleteDialogMessage}
            />
            <div className={'boards-page-header'}>
                <div className='header top-header'>
                    <div className={'filter-container left-filter'}>
                        <div className={'search-container'}>
                                <FormProvider {...form}>                          
                                        <div className='filter-item'>
                                                <TextSearchControl
                                                        name='term'
                                                        control={data}
                                                        defaultValue=''
                                                        margin="normal"
                                                        placeholder = {'Search'}
                                                />
                                        </div>
                                </FormProvider>
                        </div>
                        <IconButton onClick={fetchBoards}>
                                <img src='/images/table-page/reload.svg' />
                        </IconButton>
                    </div>
                    <div className={'filter-container right-filter'}>
                            {
                                hasPermission(auth.user, permissionGroup.GROUP, permissionGroup.ADD, auth.permissions) &&
                                <IconButton className='add-button' onClick={() =>setPageState(PageState.Add)} disabled={false}>
                                        <img src='/images/table-page/add-cross.svg'/>
                                        {strings.pages.boards.boardsList.addButtonText}
                                </IconButton>
                            }
                    </div>
                </div>
            </div>
            <div className = 'boards-container'>
                {lineTitle('Favorites', 'favorites')}
                <Collapse in = {collapseState.favorites} timeout = 'auto' unmountOnExit>
                    <Grid container spacing = {2}>
                        {renderBoards(favoriteBoards)}
                    </Grid>
                </Collapse>
                {lineTitle('All boards', 'all')}
                <Collapse in = {collapseState.all} timeout = 'auto' unmountOnExit>
                    <Grid container spacing = {2}>
                        {renderBoards(allBoards)}
                    </Grid>
                </Collapse>
            </div>
            <Drawer id='drawer' anchor='right' open={isDrawerOpen()} onClose={() => setPageState(PageState.View)}>
                    <DrawerWrapper onBack={() => setPageState(PageState.View)} title={drawerTitle} viewDetails={pageState === PageState.ViewDetails}>
                        {
                            pageState === PageState.Add && <AddBoard onFinish = {onFinish} onCancel = {onCancel} pipeline = {state?.workspace?.pipeline} />
                        }
                        {
                            pageState === PageState.Edit && <EditBoard onFinish = {onFinish} onCancel = {onCancel} board = {selectedBoard} />
                        }
                    </DrawerWrapper>
            </Drawer>
        </DrawerContext.Provider>
    );
}

export default BoardsList;

