import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { changeBreadcrumbs } from "../../../Slices/BreadcrumbsSlice";
import { getCurrentCompany } from "../../../Services/Company/CompanyService";
import TextSearchControl from "../../../Components/Controls/Inputs/TextSearchControl";
import {
  deleteBusinessTrip,
  getBusinessTripByCompany,
} from "../../../Services/HR/BusinessTripService";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { Drawer } from "@mui/material";
import strings from "../../../localization";
import AddBusinessTrip from "./AddBusinessTrip";
import Modules from "../../../Constants/Base/Modules";
import DrawerWrapper from "../../../Components/Layout/DrawerWrapper";
import LoaderContext from "../../../Context/LoaderContext";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SnackbarContext from "../../../Context/SnackbarContext";

const BusinessTripList = () => {
  const { showMessage } = useContext(SnackbarContext);
  const { setLoading } = useContext(LoaderContext);
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [company, setCompany] = useState();
  const [openBusinessTripData, setOpenBusinessTripData] = useState(false);
  const [businessTripList, setBusinessTripList] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [filteredBusinessTripList, setFilteredBusinessTripList] =
    useState(businessTripList);

  const handleSearchInputChange = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);

    const filteredList = businessTripList.filter((trip) =>
      trip.documentName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredBusinessTripList(filteredList);
  };

  const openForm = () => {
    setShowForm(true);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  useEffect(() => {
    dispatch(
      changeBreadcrumbs({
        title: strings.pages.hr.businessTrip.businessTrip.pageTitle,
        hierarchy: [
          { label: strings.navigation.managmentTag },
          { label: Modules.HR },
          { label: strings.pages.hr.businessTrip.businessTrip.pageTitle },
        ],
      })
    );
  }, []);

  const fetchBusinessTripList = () => {
    getBusinessTripByCompany(company).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      setBusinessTripList(response.data.entity);
      setFilteredBusinessTripList(response.data.entity);
    });
  };

  const fetchCurrentCompany = () => {
    setLoading(true);
    getCurrentCompany().then((response) => {
      if (!response || !response.ok) {
        return;
      }
      setLoading(false);
      setCompany(response.data.id);
      setOpenBusinessTripData(true);
    });
  };

  useEffect(() => {
    fetchCurrentCompany();
  }, []);

  useEffect(() => {
    fetchBusinessTripList();
  }, [showForm, openBusinessTripData]);

  const onCancel = () => {
    closeForm();
  };

  const onFinish = () => {
    closeForm();
  };

  const onDelete = (id) => {
    deleteBusinessTrip(id).then((response) => {
      if (!response || !response.ok) {
        showMessage(strings.commonMessages.errorDeleting, "error");
        return;
      }
      showMessage(strings.commonMessages.deleteSuccessful, "success");
      setBusinessTripList((prevList) =>
        prevList.filter((businessTrip) => businessTrip.id !== id)
      );
    });
  };
  return (
    <div id="business-trip-list-page">
      <div className="search-bar">
        <div id="searchfield-container">
          <Paper>
            <IconButton>
              <img src="/images/search.svg" />
            </IconButton>
            <InputBase
              placeholder="Search"
              value={searchText}
              onChange={handleSearchInputChange}
            />
          </Paper>
        </div>
        <div className={"filter-container right-filter  add-button-container"}>
          <IconButton className="add-button" onClick={openForm}>
            <img src="/images/table-page/add-cross.svg" />
            {strings.pages.hr.businessTrip.addBusinessTrip.pageTitle}
          </IconButton>
        </div>
      </div>

      <div className="business-trip-details-info">
        <div className="business-trip-details">
          {filteredBusinessTripList.map((businessTrip) => (
            <div className="business-trip-item">
              <div className="business-trip-elements">
                <Link to={`/business-trip-details/${businessTrip.id}`}>
                  <div className="business-trip-details">
                    <strong>
                      {
                        strings.pages.hr.businessTrip.businessTripForm
                          .documentName
                      }
                      {": "}
                    </strong>
                    {businessTrip.documentName}
                  </div>
                </Link>
                <div>
                  <IconButton onClick={() => onDelete(businessTrip.id)}>
                    <img src="/images/delete-blue.svg" />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Drawer id="drawer" anchor="right" open={showForm}>
          <DrawerWrapper
            onBack={() => closeForm()}
            title={strings.pages.hr.businessTrip.businessTripForm.pageTitle}
          >
            <AddBusinessTrip
              onCancel={() => onCancel()}
              onFinish={() => onFinish()}
            />
          </DrawerWrapper>
        </Drawer>
      </div>
    </div>
  );
};

export default BusinessTripList;
