import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    addDocumentComment,
    deleteDocumentComment, editDocumentComment,
    getDocumentComments
} from "../../../Services/DocumentManagement/DocumentCommentService";
import {useLocation} from "react-router-dom";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import CommentPageContext from "../../../Context/CommentPageContext";
import CommentPage from "../../../Components/DataGrid/CommentPage";
import strings from "../../../localization";

const DocumentComment = (props) => {

    const dispatch = useDispatch();
    const [selected, setSelected] = useState(null)
    const [editMode, setEditMode] = useState(null)
    const [comments, setComments] = useState([]);
    const {state} = useLocation();

    const permissionGroup = AppPermissions.DOCUMENT_COMMENT.GROUP;

    const value = {
        selected, setSelected,
        editMode, setEditMode,
    }

    useEffect(() => {
        fetch();
    }, [state.document])

    const fetch = () => {
        if(!state.document) {
            return;
        }
        getDocumentComments({documentId: state.document.id}).then(response => {
            if(!response || !response.ok) {
                setComments([]);
                return;
            }
            setComments(response.data)
        })
    }

    const add = ({content}) => addDocumentComment({content, documentId: state.document.id});

    const edit = ({entity, content}) => editDocumentComment({...entity, content})


    return <CommentPageContext.Provider value={value}>
        <CommentPage permissionGroup={permissionGroup} entities={comments} deleteItem={deleteDocumentComment} onAdd={add} onFinish={fetch} onEdit={edit}/>
    </CommentPageContext.Provider>
}

export default DocumentComment;