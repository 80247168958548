import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function syncEInvoicesOut() {
    return await request('/api/e-invoice/sync/out', {}, HttpMethod.POST);
}

export async function syncEInvoicesIn() {
    return await request('/api/e-invoice/sync/in', {}, HttpMethod.POST);
}

export async function syncEInvoiceOut(id) {
    return await request('/api/e-invoice/sync/out/' + id, {}, HttpMethod.POST);
}

export async function syncEInvoiceIn(id) {
    return await request('/api/e-invoice/sync/in/' + id, {}, HttpMethod.POST);
}

export async function acceptEInvoiceIn(id, data) {
    return await request('/api/e-invoice/accept/in/' + id, data, HttpMethod.PATCH);
}

export async function rejectEInvoiceIn(id, data) {
    return await request('/api/e-invoice/reject/in/' + id, data, HttpMethod.PATCH);
}

export async function sendOutEInvoice(id) {
    return await request('/api/e-invoice/send/out/' + id, {}, HttpMethod.POST)
}

export async function cancelOutEInvoice(id, data) {
    return await request('/api/e-invoice/cancel/out/' + id, data, HttpMethod.POST)
}

export async function stornoOutEInvoice(id, data) {
    return await request('/api/e-invoice/storno/out/' + id, data, HttpMethod.POST)
}