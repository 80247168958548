import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function addBoard(data) {
  return await request('/api/boards', transformRequestData(data), HttpMethod.POST);
}

export async function getBoardsList(data){
    return await request('/api/boards', data);
}

export async function getBoardLogo(id) {
  return await request(`/api/boards-logo/${id}`, {});
}

export async function getBoard(id) {
  return await request('/api/boards/' + id, {});
}

export async function changeBoardLogo(id, data) {
    return await request(`/api/boards-logo/${id}`, data, HttpMethod.PATCH);
}

export async function editBoard(id, data) {
  return await request('/api/boards/' + id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteBoard(id) {
  return await request('/api/boards/'+ id, {}, HttpMethod.DELETE);
}

export async function favoriteBoard(id) {
  return await request('/api/boards-favorite/' + id, {});
}

export async function getFavoriteBoards(data) {
  return await request('/api/boards-favorite', data);
}

export async function getBoardKeyExists(data){
  return await request('/api/boards-key-exists', data);
}

function transformRequestData(data) {

  return {
    ...data,
    pipelineId: data.pipeline ? data.pipeline.id : null,
    projectId: data.project ? data.project.id : null,
  }
}
