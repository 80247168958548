import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import {Paper} from "@mui/material";
import SnackbarContext from "../../../Context/SnackbarContext";
import BoardIssueForm from '../../../Components/Forms/Pages/Boards/BoardIssueForm';
import { useParams } from 'react-router-dom';
import { WorkspacesIssueTypesValues } from '../../../Constants/Workspaces/WorkspacesIssueTypes';
import { BoardIssuePriorityValues, IssuePriorities } from '../../../Constants/Workspaces/BoardIssuePriority';
import { addBoardIssue } from '../../../Services/Workspaces/BoardIssueService';
import { useSelector } from 'react-redux';

const formRules = {
	'name': {required: true},
	'issueType': {required: true},
	'assignee': {required: true},
	'priority': {required: true},
}

const AddBoardIssue = (props) => {
	const {issueTypes, pipelineStage, assignees, onFinish, onCancel} = props;
	const auth = useSelector((state) => state.auth);
	const {boardId} = useParams();
	const {setDrawerTitle} = useContext(DrawerContext);
	const { showMessage } = useContext(SnackbarContext);
	const [showLoader, setShowLoader] = useState(false);
	const form = useForm({
		defaultValues: {
			name: '',
			description: '',
			assignee: {id: -1, firstName: 'Unassigned', lastName: '', fullName: 'Unassigned'},
			issueType: issueTypes.find((type) => type.type === WorkspacesIssueTypesValues.TASK && type.isDefault),
			priority: IssuePriorities[2],
			reporter: auth?.user,
			pipelineStage: pipelineStage,
			isArchived: 2,
		}
	});
	const {data, handleSubmit, getValues, setValue, formState: {errors}, control, watch, clearErrors} = form;

	useEffect(() => {
			setDrawerTitle(strings.pages.boards.boardView.addBoardIssue.pageTitle);
	},[]);

	useEffect(() => {
			const subscription = watch((value) => {});
			return () => subscription.unsubscribe();
	}, [watch]);

	const onSubmit = (data) => {
		setShowLoader(true);

		if(props.description){
			data={...data, description: props.description}
		}

		addBoardIssue(boardId, data).then(response => {
			setShowLoader(false);

			if(!response || !response.ok) {
					showMessage(strings.commonMessages.errorAdding, 'error');
					onFinish();
					return;
			}

			showMessage(strings.commonMessages.addingSuccessful, 'success');
			onFinish();
		});
	}

	const handleAssignToMe = () => {
		setValue('assignee', auth?.user);
		clearErrors('assignee');
	}


	return (
		<div className = 'drawer-form'>
			<Paper className = 'paper'>
				{
					showLoader &&
					<LinearProgress />
				}
				<BoardIssueForm 
					formRules = {formRules}
					form = {form}
					values = {getValues()}
					setValue = {setValue}
					data = {data}
					errors = {errors}
					onSubmit = {handleSubmit(onSubmit)}
					onCancel = {() => onCancel()}
					control = {control}
					issueTypes = {issueTypes}
					assignees = {assignees}
					assignToMe = {handleAssignToMe}
					issuePriorities = {IssuePriorities}
				/>
			</Paper>
		</div>
	);
}

export default AddBoardIssue;
