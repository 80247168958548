import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllPartners() {
    return await request('/api/partners/all');
}

export async function getAllPartnerContacts() {
    return await request('/api/partner-contacts/all');
}

export async function getPartnerContactList(data) {
    return await request('/api/partner-contacts', data);
}

export async function getPartnerContact(id) {
    return await request('/api/partner-contacts/' + id);
}

export async function deletePartnerContact(id) {
    return await request('/api/partner-contacts/' + id, {}, HttpMethod.DELETE);
}

export async function editPartnerContact(data) {
    return await request('/api/partner-contacts/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addPartnerContact(data) {
    return await request('/api/partner-contacts', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        partnerId: data.partner ? data.partner.id : null,
    }
}
