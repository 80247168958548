import LinearProgress from '@mui/material/LinearProgress';
import {Paper} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DrawerContext from "../../../Context/DrawerContext"
import strings from "../../../localization";
import SnackbarContext from '../../../Context/SnackbarContext';
import PresenceForm from '../../../Components/Forms/Pages/HR/Presence/PresenceForm';
import { editPresence } from '../../../Services/HR/PresenceService';

const formRules = {
    'start': {required: true},
    'end': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}}
}

const EditPresence = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? {
            ...props.data[0],
            start: new Date(props.data[0].start) || NaN,
            end: new Date(props.data[0].end) || NaN
        } : undefined,
        mode: "onTouched"
    });
    const {data, control, handleSubmit, getValues, setValue, trigger, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.presence.editPresence.pageTitle);
    },[]);


    const onSubmit = (data) => {
        setShowLoader(true);

        editPresence(data).then(response => {
            setShowLoader(false);

            if(!response){
                showMessage(strings.commonMessages.errorEditing, 'error');
                if (strings.error.PresenceHandler[response.response.data.message]) {
                    showMessage(strings.error.PresenceHandler[response.response.data.message], 'error');
                }
                props.onFinish();
                return;
            }
            if (!response.ok) {
                showMessage(response.response.data.message, 'error', 5000);
                if (strings.error.PresenceHandler[response.response.data.message]) {
                    showMessage(strings.error.PresenceHandler[response.response.data.message], 'error');
                }
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <PresenceForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue} trigger={trigger}
                errors={errors} data={data} form={form} control={control}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()} />
        </Paper>
    </div>
}

export default EditPresence;