import React, {useContext} from "react";
import { FormProvider } from "react-hook-form";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import Button from "@mui/material/Button";

const CustomerForm = ({
                          onSubmit,
                          onCancel,
                          data,
                          form,
                          errors,
                          values,
                          setValue,
                          formRules,
})=>{
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    console.log(data)
    return (
        <FormProvider {...form}>
            <form id="customer-form" className='form' action="#">
                <TextFieldControl
                    name='firstName'
                    rules={formRules['firstName']}
                    control={data}
                    error={Boolean(errors.name)}
                   helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.crm.customer.firstName}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='lastName'
                    control={data}
                    label={strings.forms.crm.customer.lastName}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='email'
                    control={data}
                    label={strings.forms.crm.customer.email}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}
export default CustomerForm;