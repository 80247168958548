import strings from "../../localization";

const UserCompanyAccessStatus = {
    PENDING: 1,
    ACTIVE: 2,
    CANCELED: 3
}

export function getUserCompanyAccessStatuses() {
    return [
        {
            id: UserCompanyAccessStatus.PENDING,
            name: getUserCompanyAccessStatusString(UserCompanyAccessStatus.PENDING)
        },
        {
            id: UserCompanyAccessStatus.ACTIVE,
            name: getUserCompanyAccessStatusString(UserCompanyAccessStatus.ACTIVE)
        },
        {
            id: UserCompanyAccessStatus.CANCELED,
            name: getUserCompanyAccessStatusString(UserCompanyAccessStatus.CANCELED)
        }
    ];
}

export function getUserCompanyAccessStatusString(userType) {
    switch(userType) {
        case UserCompanyAccessStatus.PENDING: return strings.userCompanyAccessStatus.pending;
        case UserCompanyAccessStatus.ACTIVE: return strings.userCompanyAccessStatus.active;
        case UserCompanyAccessStatus.CANCELED: return strings.userCompanyAccessStatus.canceled;
    }
}

export default UserCompanyAccessStatus;
