import React, {useContext, useState} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import QuillComment from "../../../../Controls/Inputs/QuillComment";
import {IconButton, InputLabel} from "@mui/material";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import CheckBoxControl from "../../../../Controls/Inputs/CheckBoxControl";

const AddQuestionOptionForm = ({
                             onSubmit,
                             data,
                             form,
                             errors,
                             values,
                             setValue,
                             formRules,
                             control
                         }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;


    return (
        <FormProvider {...form}>
            <form id='question-add-form'
                  onSubmit={(e) => {
                    e.preventDefault();
                }
                } className='form' action="#">
                <TextFieldControl
                    name='option'
                    className='margin-shifted-label mui-shifted-label-input'
                    rules={formRules['option']}
                    control={control}
                    error={Boolean(errors.question)}
                    helperText={errors.question && errors.question.message}
                    label={strings.forms.companyDocument.companyDocumentTemplateQuestion.question}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default AddQuestionOptionForm;