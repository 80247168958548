import HttpMethod from "../../Constants/Base/HttpMethod";
import {makeParametersList, request} from "../../Base/HTTP";
import axios from "axios";

export async function getAllProjects() {
    return await request('/api/projects/all');
}

export async function getProjectTimeLogList(data) {
    return await request('/api/project-time-log', data);
}

export async function addProjectTimeLog(data) {
    return await request('/api/project-time-log', transformRequestData(data), HttpMethod.POST);
}

export async function editProjectTimeLog(data) {
    return await request('/api/project-time-log/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteProjectTimeLog(id) {
    return await request('/api/project-time-log/' + id, {}, HttpMethod.DELETE);
}

export async function viewProjectTimeLogPDF(data = {}) {
    window.open(process.env.REACT_APP_BASE_URL + `api/project-time-log/view`, makeParametersList(data));
}

export function downloadProjectTimeLogPDF(data) {
    request('/api/project-time-log/download', {...data, 'render-ino': true}, HttpMethod.GET, {responseType: 'blob'}).then(res => {
        const newBlob = new Blob([res.data], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(newBlob);
        window.open(downloadUrl);
    });
}

export async function getProjectTimeLogStatistic(data){
    return await request('/api/project-time-log/statistic', data);
}

function transformRequestData(data) {
    return {
        ...data,
        billable: !!data.billable,
        projectId: data.project ? data.project.id : null,
        taskCategoryId: data.taskCategory ? data.taskCategory.id : null,
        employeeUserId: data.employeeUser ? data.employeeUser.id : null
    }
}
