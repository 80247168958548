import LinearProgress from '@mui/material/LinearProgress';
import React,{ useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import {Paper} from "@mui/material";
import TeamForm from "../../../Components/Forms/Pages/HR/Team/TeamForm";
import DrawerContext from "../../../Context/DrawerContext"
import strings from "../../../localization";
import { addTeam } from "../../../Services/HR/TeamService";
import { getOrganisationUnits } from '../../../Services/HR/OrganisationUnitService';
import SnackbarContext from '../../../Context/SnackbarContext';

const formRules = {
    'name': {required: true},
    'organisationUnit': {required: true}
}

const AddTeam = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const {showMessage} = useContext(SnackbarContext);
    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [oragnisationUnit, setOrganisationUnit] = useState([]);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.team.addTeam.pageTitle);
    });

    useEffect(() => {
        fetch();
    }, [])

    const onSubmit = (data) => {
        setShowLoader(true);

        addTeam(data).then(response => {
            setShowLoader(false);

            if(!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const fetch = (term = '') => {
        getOrganisationUnits({term}).then(response =>{

            if (!response || !response.ok){
                return;
            }
            setOrganisationUnit(response.data.result);
        })
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <TeamForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                organisationUnits={oragnisationUnit} onChangeUserFilter={fetch}
                errors={errors} data={data} form={form} control={control}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()} />
        </Paper>
    </div>
}

export default AddTeam;