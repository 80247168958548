import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageSizeState from "../../Constants/Base/PageSizeState";
import { changeBreadcrumbs } from "../../Slices/BreadcrumbsSlice";
import { changePageSizeState } from "../../Slices/PageSlice";
import strings from "../../localization";
import LinearProgress from "@mui/material/LinearProgress";
import { Grid, Paper, Tab, Tabs } from "@mui/material";
import EditUserForm from "../../Components/Forms/Pages/User/EditUserForm";
import { useForm } from "react-hook-form";
import { changeSignature, changeUserPassword, editUser } from "../../Services/User/UserService";
import { setUser } from "../../Slices/AuthSlice";
import ProfileTab from "../../Constants/User/ProfileTab";
import ChangePasswordForm from "../../Components/Forms/Pages/User/ChangePasswordForm";
import SignatureForm from "../../Components/Forms/Pages/User/SignatureForm";
import ChangeProfileImage from "./ChangeProfileImage";
import SnackbarContext from "../../Context/SnackbarContext";
import Modules from "../../Constants/Base/Modules";
import PublicLink from "../HR/Settings/PublicLink";
import WorkingHours from "./WorkingHours";
import { useNavigate } from 'react-router-dom';

const formRules = {
    'email': { required: true },
    'firstName': { required: true },
    'lastName': { required: true },
}

const Profile = (props) => {
    const { activeTab = ProfileTab.PROFILE } = props;

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user)
    const navigate = useNavigate();
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: user
    });
    const { data, handleSubmit, getValues, setValue, setError, formState: { errors } } = form;
    const [showLoader, setShowLoader] = useState(false);
    const [signature, setSignature] = useState(null);
    const [isSignatureExists, setSignatureExists] = useState(false);
    const sigPad = React.createRef();

    const changeActiveTab = (tab) =>{
        if(tab === ProfileTab.PROFILE){
            navigate('/profile')
        }
        if(tab === ProfileTab.CHANGE_PASSWORD){
            navigate('/profile/change-password')
        }

        if(tab === ProfileTab.SIGNATURE){
            navigate('/profile/signature')
        }

        if(tab === ProfileTab.LOGO){
            navigate('/profile/profile-image')
        }

        if(tab === ProfileTab.WORKING_HOURS){
            navigate('/profile/working-hours')
        }

        if(tab === ProfileTab.PUBLIC_LINK){
            navigate('/profile/public-link')
        }
    }


    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
    }, [])

    useEffect(() => {
        const tabEntry = { label: '' };
        switch (activeTab) {
            case ProfileTab.PROFILE:
                tabEntry.label = strings.profile.editProfile;
                break;
            case ProfileTab.CHANGE_PASSWORD:
                tabEntry.label = strings.profile.changePassword;
                break;
            case ProfileTab.SIGNATURE:
                tabEntry.label = strings.profile.signature;
                break;
            case ProfileTab.LOGO:
                tabEntry.label = strings.profile.profileImage;
                break;
            case ProfileTab.WORKING_HOURS:
                tabEntry.label = strings.profile.workingHours.name;
                break;
            case ProfileTab.PUBLIC_LINK:
                tabEntry.label = strings.profile.publicLink.name;
                break;
        }

        dispatch(changeBreadcrumbs({
            title: tabEntry.label,
            hierarchy: [
                { label: Modules.USER_PROFILE },
                tabEntry
            ],
        }));
    }, [activeTab])

    const onSubmit = (data) => {
        setShowLoader(true);
        editUser(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                return;
            }
            showMessage(strings.commonMessages.editingSuccessful, 'success');
            dispatch(setUser({ ...user, ...response.data.user }));
        });

    }

    const onCancel = () => {
        props.onCancel();
    }

    const changePassword = (data) => {
        if (data.newPassword != data.newPasswordRepeat) {
            setError('newPassword', strings.profile.passwordsDoNotMatch);
            return;
        }

        changeUserPassword(data).then(response => {
            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                return;
            }
            showMessage(strings.commonMessages.editingSuccessful, 'success');
        });
    }

    const keyPressChangePassword = (event) => {
        if (event.key != 'Enter') {
            return;
        }
        changePassword()
    }

    const clearSignature = () => {
        setSignature(null);
        setSignatureExists(false);

        changeSignature({
            signature: signature,
            isSignatureExist: isSignatureExists
        }).then(response => {
            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                return;
            }
            setValue('isSignatureExist', false);
            dispatch(setUser({ ...user, ...response.data.user }));
            showMessage(strings.commonMessages.editingSuccessful, 'success');
        })
    }

    const addSignature = (signature, isSignatureExist) => {
        if (signature === "") {
            return
        }
        changeSignature({
            signature: signature,
            isSignatureExist: isSignatureExist
        }).then(response => {
            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                return;
            }
            [{ name: 'isSignatureExist', value: true },
            { name: 'signature', value: signature }].forEach(({ name, value }) => setValue(name, value));

            dispatch(setUser({ ...user, ...response.data.user }));
            showMessage(strings.commonMessages.editingSuccessful, 'success');
        })
    }

    return (<div id='profile'>
        <div className='tab-container mb-12 ml-3'>
            <Paper className='paper'>
                <Tabs variant="scrollable" value={activeTab} onChange={(e, tab) => changeActiveTab(tab)}>
                    <Tab label={strings.profile.profile} />
                    <Tab label={strings.profile.changePassword} />
                    <Tab label={strings.profile.signature} />
                    <Tab label={strings.profile.profileImage} />
                    <Tab label={strings.profile.workingHours.name} />
                    <Tab label={strings.profile.publicLink.name} />
                </Tabs>
            </Paper>
        </div>

        <Grid id='page' item md={12} justifyContent="center" container
            alignItems="center">
            <Grid item xs={5} className='centered-flex'>
                {activeTab === ProfileTab.PROFILE &&
                    <div className='page-form-container'>
                        <Paper className='paper rounded-2'>
                            {
                                showLoader &&
                                <LinearProgress />
                            }
                            <EditUserForm
                                formRules={formRules}
                                values={getValues()}
                                setValue={setValue}
                                errors={errors} data={data} form={form}
                                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()} />
                        </Paper>
                    </div>
                }
                {activeTab === ProfileTab.CHANGE_PASSWORD &&
                    <div className='page-form-container'>
                        <Paper className='paper rounded-2'>
                            <ChangePasswordForm
                                values={getValues()}
                                setValue={setValue}
                                errors={errors} data={data} form={form}
                                onSubmit={handleSubmit(changePassword)} onCancel={() => onCancel()}
                                keyPress={keyPressChangePassword}
                            />
                        </Paper>
                    </div>
                }

                {activeTab === ProfileTab.SIGNATURE &&
                    <div className='page-form-container'>
                        <Paper className='paper rounded-2'>
                            <SignatureForm
                                sigPad={sigPad}
                                onClear={clearSignature}
                                onSubmit={addSignature}
                                data={getValues()}
                            />
                        </Paper>
                    </div>
                }
            </Grid>
        </Grid>
        {
            activeTab === ProfileTab.LOGO &&
            <div className='page-form-container'>
                <ChangeProfileImage />
            </div>
        }
        {
            activeTab === ProfileTab.WORKING_HOURS &&
            <div className="working-hours">
                <WorkingHours />
            </div>
        }
        {
            activeTab === ProfileTab.PUBLIC_LINK && <PublicLink />
        }

    </div>);
}

export default Profile;
