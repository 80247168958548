import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import {
	IconButton,
	Modal,
	Paper,
	Collapse,
	Menu,
	MenuItem,
	Button,
	ListItemIcon,
	ListItemText,
	Grid,
	TextField,
	Drawer,
	Icon,
	Tooltip
} from "@mui/material";
import {Edit, Delete} from "@mui/icons-material";
import { renderTypeIcon, renderPriorityIcon } from '../../../Components/DataGrid/ValueCellRender';
import { UserAvatar } from '../../../Util/AvatarUtil';
import { getDateDistance, dateToString } from '../../../Util/DateUtil';
import strings from "../../../localization";
import { formatPriorityName, IssuePriorities } from '../../../Constants/Workspaces/BoardIssuePriority';
import AutoCompleteControl from '../../../Components/Controls/Inputs/AutoCompleteControl';
import SelectControl from '../../../Components/Controls/Inputs/SelectControl';
import {FormProvider, useForm} from "react-hook-form";
import {
	updateBoardIssue,
	addIssueComment,
	getIssueComments,
	editIssueComment,
	deleteIssueComment,
	editBoardIssue
} from '../../../Services/Workspaces/BoardIssueService';
import QuillEditor, {isEmpty} from "../../../Components/Controls/Inputs/QuillEditor";
import YesNoDialog, {YesNoDialogResult} from "../../../Components/Dialogs/YesNoDialog";
import SnackbarContext from "../../../Context/SnackbarContext";
import {getSizeDescription, isFileVideo} from '../../../Util/FileUtil';
import { BoardIssueFileTypeValues, BoardIssueFileTypeNames } from '../../../Constants/Workspaces/BoardIssueFileType.js';
import ReferenceType from '../../../Constants/DocumentManagement/ReferenceType';
import {addDocument, deleteDocument, getDocuments, getDocument} from "../../../Services/DocumentManagement/DocumentService";
import {getFileUrlFromAws} from "../../../Util/AWSClientUtil";
import {useSelector} from "react-redux";
import { useDropzone } from 'react-dropzone';
import PageState from "../../../Constants/Base/PageState";
import {formatIssueName } from "../../../Constants/Workspaces/WorkspacesIssueTypes";
import { useNavigate } from 'react-router-dom';
import AppPermissions from '../../../Constants/Permissions/Permissions';
import { hasPermission } from '../../../Util/PermissionUtil';
import { getProjectTimeLogList } from '../../../Services/Project/ProjectTimeLogService';
import { renderNumber } from '../../../Util/RenderDataUtil';
import AddProjectTimeLog from '../../Project/ProjectTimeLog/AddProjectTimeLog';
import DrawerWrapper from '../../../Components/Layout/DrawerWrapper';
import PdfPreview from '../../../Components/Pdf/PdfPreview';
import { addLinkedIssue, deleteLinkedIssue, getLinkedIssue } from '../../../Services/Workspaces/LinkedIssueService';
import { formatLinkedIssueTypeName, LinkedIssueTypes } from '../../../Constants/Workspaces/LinkedIssueType';
import AddBoardIssue from './AddBoardIssue';
import LoaderContext from "../../../Context/LoaderContext";

const formRules = {
    'linkedIssueType': {required: true},
    'linkedIssue': {required: true}
}

const BoardIssueView = ({
		openSelectedIssue,
		setOpenSelectedIssue,
		assignees,
		issueTypes,
		user,
		pipelineStages,
		fetchIssues,
		board,
		onFinish,
		boardIssues
	}) => {
	const {setLoading} = useContext(LoaderContext);
	const [issueDetails, setIssueDetails] = useState(true);
	const companyS3Settings = useSelector((state) => state.auth.companyS3Settings);
    const auth = useSelector((state) => state.auth);
	const [viewChooseAssignee, setViewChooseAssignee] = useState(false);
	const [viewChoosePriority, setViewChoosePriority] = useState(false);
	const [viewChooseType, setViewChooseType] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElLinkedIssue, setAnchorElLinkedIssue] = useState(null);
	const [editorData, setEditorData] = useState('');
	const [commentAnchor, setCommentAnchor] = useState(null);
	const [sendData, setSendData] = useState(null);
	const [comments, setComments] = useState([]);
	const [commentData, setCommentData] = useState('');
	const [selectedComment, setSelectedComment] = useState(null);
	const [selectedLinkedIssue, setSelectedLinkedIssue] = useState(null);
	const [editMode, setEditMode] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showImage, setShowImage] = useState(null);
	const [showVideo, setShowVideo] = useState(null);
	const [showLoader, setShowLoader] = useState(false);
	const { showMessage } = useContext(SnackbarContext);
	const [files, setFiles] = useState([]);
	const [hoverFile, setHoverFile] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const noUpdate = useRef(true);
	const open = Boolean(anchorEl);
	const openLinkedIssue = Boolean(anchorElLinkedIssue);
	const chooseFile = useRef(null);
	const [commentsActivity, setCommentsActivity] = useState(true);
	const [timeLogActivity, setTimeLogActivity] = useState(false);
	const [timeLogDetails, setTimeLogDetails] = useState(true);
	const [timeLogHours, setTimeLogHours] = useState(0);
	const navigate = useNavigate();

	const [pageState, setPageState] = useState(PageState.View);
    const [activePdf, setActivePdf] = useState(null);
	const [showFile, setShowFile] = useState(null);
	const [linkedIssues, setLinkedIssues] = useState({});
	const [showAddLinkedIssueForm, setShowAddLinkedIssueForm] = useState(false);
	const [linkedIssueFormData, setLinkedIssueFormData] = useState([]);

	const form = useForm({
		defaultValues: {
			assignee: openSelectedIssue.assignee,
			priority: openSelectedIssue.priority,
		}
	});

	const [editDescription, setEditDescription] = useState(false);
	const [descriptionData, setDescriptionData] = useState('');
    const [drawerTitle, setDrawerTitle] = useState('');

	const onDrop = useCallback(acceptedFiles => {
		uploadFile(acceptedFiles);
	}, []);

	const {getRootProps, isDragActive} = useDropzone({onDrop})

	const {watch, setValue, getValues, control, handleSubmit, formState: {errors}} = form;

	useEffect(() => {
		const subscription = watch((value) => {
			let tmpValue = {};
			if(value.priority?.type && value.priority.type !== openSelectedIssue.priority) tmpValue.priority = value.priority.type;
			if(value.assignee?.id && value.assignee.id !== openSelectedIssue.assignee?.id) tmpValue.assignee = value.assignee;
			if(value.issueType?.id && value.issueType.id !== openSelectedIssue.issueType.id) tmpValue.issueType = value.issueType;
			if(tmpValue.priority || tmpValue.assignee || tmpValue.issueType){
				setSendData(tmpValue);
			}
		});

		return () => subscription.unsubscribe();
	},[watch, openSelectedIssue]);

	useEffect(() => {
		setDescriptionData(openSelectedIssue.description);
	}, [openSelectedIssue.description]);

	useEffect(() => {
        if (pageState === PageState.View) {
			setDrawerTitle(null);
        }
    }, [pageState])

	const updateIssue = (issueForUpdate, data) => {
		updateBoardIssue(issueForUpdate.id, data).then(response => {
			noUpdate.current = true;
			if(!response || !response.ok) return;

			setSendData(null);
			if(!response.data.issue.assignee){
				response.data.issue.assignee = {'id': -1, 'fullName': 'Unassigned'}
			}

			if(selectedLinkedIssue) {
				setSelectedLinkedIssue(null);
				fetchLinkedIssues();
			}

			if(openSelectedIssue.id === response.data.issue.id) {
				setOpenSelectedIssue(response.data.issue);
			}

			fetchIssues();
		});
	}

	useEffect(() => {
		if(!noUpdate.current) {
			updateIssue(openSelectedIssue, sendData);
		}
		noUpdate.current = false;
	},[sendData]);

	useEffect(() => {
		fetchIssueComments();
		fetchFiles();
		fetchLinkedIssues();
	}, [openSelectedIssue]);


	useEffect(() => {
		fetchLinkedIssues();
	}, [boardIssues])

	const fetchLinkedIssues = () => {

		let linkedIssuesFormData = boardIssues.filter((issue) => issue.id !== openSelectedIssue.id);
		setLinkedIssueFormData(linkedIssuesFormData);

		getLinkedIssue({issue: openSelectedIssue.id}).then(response => {
			setLoading(false);

			if(!response || !response.ok) {
				return;
			}

			let linkedIssues = {};

			response.data.result.map(issue => {
				let key = formatLinkedIssueTypeName(issue.linkedIssueType);

				if (key in linkedIssues) {
					linkedIssues[key].push(issue);
					return;
				}

				linkedIssues[key] = [issue];
			});

			setLinkedIssues({total: response.data.total, issues: linkedIssues});

			let uniqueLinkedIssuesFormData = [].concat(
				linkedIssuesFormData.filter(obj1 => response.data.result.every(obj2 => obj1.id !== obj2.linkedIssue.id)),
				response.data.result.filter(obj2 => linkedIssuesFormData.every(obj1 => obj2.linkedIssue.id !== obj1.id))
			);

			setLinkedIssueFormData(uniqueLinkedIssuesFormData);

		});

	}

	const fetchIssueComments = () => {
		getIssueComments(openSelectedIssue.id).then( response => {
			if(!response || !response.ok) return;
			setComments(response.data.comments);
		});
	}

	const handleEditIssueComment = () => {
		editIssueComment(selectedComment.id, {
			content: commentData,
		}).then(response => {
			if(!response || !response.ok) return;
			fetchIssueComments();
			setEditMode(false);
			setSelectedComment(null);
		});
	}

	const handlViewAssignToMe = () => {
		if(getValues('assignee')?.id === user.id) return;
		setValue('assignee', user);
		setOpenSelectedIssue((prev) => ({...prev, assignee: user}));
	}
	const handleClose = (e, value) => {
		setViewChooseAssignee(false);
		setViewChoosePriority(false);
		setViewChooseType(false);
	}

	const renderPipelineStageMenu = () => {
		return (
			pipelineStages.map((stage) => 
				<MenuItem 
					onClick = {(e) => handleChangePipelineStage(e, stage)}
					key = {'pipeline-stage-menu-' + stage.id}
				>{stage.name}
				</MenuItem>
			)
		)
	}

	const handleCloseMenu = (e) => {
		e.stopPropagation();

		setSelectedLinkedIssue(null);
		setAnchorEl(null);
		setAnchorElLinkedIssue(null);
	}

	const handleChangePipelineStage = (e, stage) => {
		e.stopPropagation();

		handleCloseMenu(e);

		if(selectedLinkedIssue) {
			if(stage.id !== selectedLinkedIssue.pipelineStage.id) {
				updateIssue(selectedLinkedIssue, {pipelineStage: stage});
			}
			return;
		}

		if(stage.id !== openSelectedIssue.pipelineStage.id){
			updateIssue(openSelectedIssue, {pipelineStage: stage});
		}
	}

	const saveIssueComment = () => {
		addIssueComment(openSelectedIssue.id,
			{content: editorData}).then( response => {
			if(!response || !response.ok){
				showMessage(strings.commonMessages.errorAdding, 'error');
				return;
			}
			setEditorData('');
			fetchIssueComments();
		});
	}

	const handleOpenCommentOptions = (e, comment) => {
		setSelectedComment(comment);
		setCommentAnchor(e.currentTarget);
	}

	const handleCloseCommentOptions = () => {
		setCommentAnchor(null);
	}

	const handleOpenEdit = () => {
		setEditMode(true);
		setCommentAnchor(null);
	}

	const handleDeleteComment = () => {
		setCommentAnchor(null);
		setShowDeleteDialog(true);
	}

	const handleDeleteLinkedIssue = (e, linkedIssue) => {
		e.stopPropagation();

		setSelectedLinkedIssue(linkedIssue);
		setShowDeleteDialog(true);
	}

	const handleDeleteDialogResult = (result, payload) => {
		if (result === YesNoDialogResult.NO || result === YesNoDialogResult.CANCEL) {
			setShowDeleteDialog(false);
			setSelectedLinkedIssue(null);
			setSelectedComment(null);
			setSelectedFile(null);
			return;
		}

		if(selectedComment && selectedComment.id){
			deleteIssueComment(selectedComment.id).then(response => {
				if (!response || !response.ok) {
					showMessage(strings.components.tablePage.errorDeletingItem, 'error')
					setShowDeleteDialog(false);
					setSelectedComment(null);
					return;
				}

				showMessage(strings.components.tablePage.itemDeleted, 'success')
				setShowDeleteDialog(false);
				setSelectedComment(null);
				updateIssue(openSelectedIssue, {});
				fetchIssueComments();
			})
		}

		if(selectedFile && selectedFile.id){
			deleteDocument(selectedFile.id).then(response => {
				if (!response || !response.ok) {
					showMessage(strings.components.tablePage.errorDeletingItem, 'error')
					setShowDeleteDialog(false);
					setSelectedFile(null);
					return;
				}

				showMessage(strings.components.tablePage.itemDeleted, 'success')
				setShowDeleteDialog(false);
				setSelectedFile(null);
				updateIssue(openSelectedIssue, {});
				fetchFiles();
			})
		}

		if(selectedLinkedIssue && selectedLinkedIssue.id){
			deleteLinkedIssue(selectedLinkedIssue.id).then(response => {
				if (!response || !response.ok) {
					showMessage(strings.components.tablePage.errorDeletingItem, 'error')
					setShowDeleteDialog(false);
					setSelectedLinkedIssue(null);
					return;
				}

				showMessage(strings.components.tablePage.itemDeleted, 'success')
				setShowDeleteDialog(false);
				setSelectedLinkedIssue(null);
				updateIssue(openSelectedIssue, {});
				fetchLinkedIssues();
			})
		}

		setShowDeleteDialog(false);
	}

	const handleClickFile = (e, file) => {
		if((e.target.nodeName === 'IMG' && !file?.extension ) || file?.extension === 'jpg' || file?.extension === 'jpeg' || file?.extension === 'png'){
			setShowImage(e.target.currentSrc);
			setShowFile(file);
		}

		if(isFileVideo(file)){
			setShowVideo(file.src)
			setShowFile(file);
		}

		if(file?.extension === 'pdf') {
        	getDocument(file).then((response) => {
				setShowFile(file);
				setActivePdf(response);
			});
		}
	}
	
	const handleCloseViewModal = () => {
		setShowImage(null);
		setShowVideo(null);
		setShowFile(null);
		setActivePdf(null);
	}

	const saveDescription = () => {
		setShowLoader(true);

		openSelectedIssue={...openSelectedIssue, description: descriptionData};

		editBoardIssue(openSelectedIssue.id, openSelectedIssue).then(response => {
			setShowLoader(false);

			if(!response || !response.ok) {
				showMessage(strings.commonMessages.errorSaving, 'error');
				return;
			}
			
			setOpenSelectedIssue(response.data.entity);
			setEditDescription(false);

			showMessage(strings.commonMessages.saved, 'success');
			fetchIssues();
		});
	}

	const renderComments = () => {
		return(
			comments.map((comment) => 
				<div className = 'issue-comment-container' key = {'issue-comment-' + comment.id}>
					<UserAvatar user = {comment.user} size = {40} />
					<div className = 'w-100'>
						<p className = 'issue-comment-date'>{dateToString(new Date(comment.dateCreated))}</p>
						<div className = 'issue-comment-content'>
							<IconButton onClick = {(e) => handleOpenCommentOptions(e, comment)}>
								<img src = '/images/more-horizontal.svg' />
							</IconButton>
							<Menu className = 'issue-comment-options' anchorEl={commentAnchor} id={"basic-menu-" + comment.id} open={Boolean(commentAnchor)} onClose={handleCloseCommentOptions}>
								{
									<MenuItem onClick={handleOpenEdit}>
												<ListItemIcon>
														<Edit/>
												</ListItemIcon>
												<ListItemText>{strings.components.actionCell.edit}</ListItemText>
										</MenuItem>
								}
								{
									<MenuItem onClick={handleDeleteComment}>
												<ListItemIcon>
														<Delete/>
												</ListItemIcon>
												<ListItemText>{strings.components.actionCell.delete}</ListItemText>
										</MenuItem>
								}
							</Menu>
							<p className = 'issue-comment-user'>{comment.user.fullName}</p>
							{ selectedComment?.id === comment.id && editMode ? 
								<div className = 'issue-comment-edit'>
									<QuillEditor value={commentData} onChange={setCommentData} defaultValue={comment.content} />
									<Button variant="contained" color="primary" onClick={handleEditIssueComment} disabled={isEmpty(commentData)}>
											{ strings.common.save }
									</Button>
									<Button variant="contained" color="secondary" onClick={ () => setEditMode(false) } >
											{ strings.common.cancel }
									</Button>
								</div>
								:
								<div onClick = {handleClickFile} className = 'issue-comment' dangerouslySetInnerHTML={{ __html: comment.content }}/>
							}
						</div>
					</div>
				</div>
			)
		);
	}

	const handleAddFileClick = () => {
		chooseFile.current.click();
	}

	const handleFileUpload = async(e) => {
		const uploadedFile = e.target.files[0];
		const data = {file: uploadedFile, name: uploadedFile?.name, referenceId: openSelectedIssue.id , referenceType: ReferenceType.ISSUEFILE, documentSize: getSizeDescription(uploadedFile.size).replace(/[()]/g,'')}

		addDocument(data).then(response => {
			if (!response || !response.ok) {
				setShowLoader(false);
				showMessage(strings.commonMessages.errorAdding, 'error');
				if (strings.error.DocumentHandler[response.response.data.message]) {
					showMessage(strings.error.DocumentHandler[response.response.data.message], 'error');
				}
				return;
			}
			updateIssue(openSelectedIssue, {});
			fetchFiles();

			showMessage(strings.commonMessages.addingSuccessful, 'success');
		});
	}
	const fetchFiles = () => {
		getDocuments({
			referenceType: ReferenceType.ISSUEFILE,
			referenceId: openSelectedIssue.id,
		}).then(async(response) => {
			setShowLoader(false);
			if (!response || !response.ok) {
				return;
			}

			const formatedFiles = [];

			for(let file of response.data.result){
				const fileUrl = await getFileUrlFromAws(companyS3Settings, file.key);
				formatedFiles.push({...file, src: fileUrl});
			}
			setFiles(formatedFiles);
		})
	}
	
	const handleDeleteFile = (e, file) => {
		e.stopPropagation();

		setShowDeleteDialog(true);
		setSelectedFile(file);
	}

	const handleHoverFile = (file) => {
		setHoverFile(file.id);
	}

	const formatFileSize = (size) => {
		return size.replace(/\s/g, '').toLowerCase();
	}

	const downloadFile = async(e, file) => {
		e.stopPropagation();

		const response = await getDocument({id: file.id});
		if(!response) {
			return;
		}

		const link = document.createElement("a");
		link.href = response.url;
		link.download = response.name;
		link.click();
	}

	const formatLinkedIssuesPipelineStages = (linkedIssuesContainer) => {
		let linkedIssuesPipelineStages = {};
	
		linkedIssues.issues[linkedIssuesContainer].forEach(entity => {
			let pipelineStageName = entity.linkedIssue.pipelineStage.name;
			if (!linkedIssuesPipelineStages[pipelineStageName]) {
				linkedIssuesPipelineStages[pipelineStageName] = [];
			}
	
			linkedIssuesPipelineStages[pipelineStageName].push(entity);
		});
	
		return Object.keys(linkedIssuesPipelineStages).map(pipelineStage => 
			`${pipelineStage}: ${linkedIssuesPipelineStages[pipelineStage].length}`
			).join(', ');
	};

	const renderLinkedIssuesContainer = () => {
		return Object.keys(linkedIssues.issues).map(container => (
			<div>
				<div className='linked-issues-container-info'>
					<div className='linked-issue-container-info-title'>{container}</div>
					<div className='linked-issues-container-info-progress'>({formatLinkedIssuesPipelineStages(container)})</div>
				</div>
				<div className='linked-issues-container-issues'>
					{ renderLinkedIssues(container) }
				</div> 
			</div>
		));
	}

	const handlePipelineStageLinkedIssue = (e, linkedIssue) => {
		e.stopPropagation();

		setAnchorElLinkedIssue(e.currentTarget); 
		setSelectedLinkedIssue(linkedIssue); 
	}

	const handleOpenLinkedIssue = (e, issue) => {
		e.stopPropagation();

		setLoading(true);
		handleCancelLinkedIssueForm();

		if(!issue.assignee) {
			issue.assignee = {'id': -1, 'fullName': 'Unassigned'}
		}

		setValue('assignee', issue.assignee);
		setValue('priority', issue.priority);

		setOpenSelectedIssue(issue);	
	}
 
	const renderLinkedIssues = (containerName) => {
		return linkedIssues.issues[containerName].map(entity => (
			<div className='issue' onClick={(e) => handleOpenLinkedIssue(e, entity.linkedIssue)}>
				<div className='issue-name'>
					<p>{entity.linkedIssue.issueId}</p>
					{entity.linkedIssue.name}
					</div>
				{renderPriorityIcon(entity.linkedIssue.priority)}

				{entity.linkedIssue?.assignee?.id !== -1 && <UserAvatar user = {entity.linkedIssue?.assignee} size = {30} />}
			
				<div className='linked-issue-view-priority'>
					<IconButton 
						style={{backgroundColor: entity.linkedIssue.pipelineStage.color}}
						onClick = {(e) => { handlePipelineStageLinkedIssue(e, entity.linkedIssue); }}
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}>
						<p>{entity.linkedIssue.pipelineStage.name}</p>
						<img 
							src = '/images/arrow-open.svg' 
							className = {selectedLinkedIssue?.id !== entity.linkedIssue.id ? 'rotate-collapse-arrow' : ''}	
						/>
					</IconButton>

					<Menu  
					 	id={"basic-menu-" + entity.linkedIssue.id}
						className = 'issue-pipelinestage-menu'
						anchorEl={anchorElLinkedIssue}
						open={openLinkedIssue}
						onClose = {handleCloseMenu}
					>
						{renderPipelineStageMenu()}
					</Menu>
				</div>

				<IconButton className = 'linked-issue-remove-button' onClick = {(e) => handleDeleteLinkedIssue(e, entity)}>
					<img src = '/images/remove-fill.svg' />
				</IconButton>
			</div>
		));
	}

	const renderFiles = () => {
		return files.map((file, index) => (
			<Grid 
				item 
				key = {`board-issue-file-${index}`} 
				className = 'file-preview-container'
			>
				<div className = 'file-preview-container-a'
					onClick = {(e) => handleClickFile(e, file)}
					onMouseEnter = {() => handleHoverFile(file)}
					onMouseLeave = {() => setHoverFile(null)}
				>
					{file.documentType === BoardIssueFileTypeValues.IMAGE ?
						<img className = 'image-preview' src = {file.src} />
						:
						<div className = 'file-preview-file'>
							<img className = 'file-preview-icon' src = '/images/file-icon.svg' />
						</div>
					}
					
					{hoverFile === file.id &&
						<div>
							<IconButton className = 'file-preview-remove-container' onClick = {(e) => handleDeleteFile(e, file)}>
								<img src = '/images/remove-fill.svg' />
							</IconButton>
							<div className = 'file-preview-info-container'>
								<div className='info-labels' onClick={(e) => e.stopPropagation()}>
									<Tooltip title={file.name}>
										<p className='file-name'>{file.name}</p>
									</Tooltip>
									<div className = 'file-preview-info-file-type'>
										<p>{BoardIssueFileTypeNames[file.documentType]}</p>
										<p> | </p>
										<p>{formatFileSize(file.documentSize)}</p>
									</div>
								</div>
								<Icon className='tp-icon csv-icon' onClick = {(e) => downloadFile(e, file)}>
									<img src='/images/table-page/download-csv.svg' />
								</Icon>
							</div>
						</div>
					}
				</div>
			</Grid>
		));
	}

	const uploadFile = async (dragFiles) => {
		if(dragFiles.length !== 0){
			dragFiles.map((uploadedFile) => {
				const data = {file: uploadedFile, name: uploadedFile?.name, referenceId: openSelectedIssue.id , referenceType: ReferenceType.ISSUEFILE, documentSize: getSizeDescription(uploadedFile.size).replace(/[()]/g,'')}
				addDocument(data).then(response => {
					if (!response || !response.ok) {
						setShowLoader(false);
						showMessage(strings.commonMessages.errorAdding, 'error');
						if (strings.error.DocumentHandler[response.response.data.message]) {
							showMessage(strings.error.DocumentHandler[response.response.data.message], 'error');
						}
						return;
					}
					updateIssue(openSelectedIssue, {});
					fetchFiles();
					showMessage(strings.commonMessages.addingSuccessful, 'success');
				});
			});
		}
    }

	const handleCloseIssue = () => {
		let issueName = document.getElementById("issue-name").innerText.trim();

		if(issueName != openSelectedIssue.name.trim()){

			openSelectedIssue.name = issueName;
			
			setOpenSelectedIssue(openSelectedIssue);

			editBoardIssue(openSelectedIssue.id, openSelectedIssue).then(response => {
				if(!response || !response.ok) {
					showMessage(strings.commonMessages.errorEditing, 'error');
					return;
				}

				onFinish();
				showMessage(strings.commonMessages.editingSuccessful, 'success');
			});
		}
		setOpenSelectedIssue(null);
	}

	const handleEditDescription = (event) => {
		event.stopPropagation();

		if(event.target.nodeName === 'IMG' && !editDescription){
			setShowImage(event.target.currentSrc);
			return;
		}

		if(event.target.nodeName === 'A') {
			return;
		}

		setEditDescription(true);
	};

	const handleCancelEditDescription = (event) => {
		event.stopPropagation();

		setEditDescription(false);
	};

	const handleActivity = () => {
		if(board?.project) {
			setCommentsActivity(!commentsActivity);
			setTimeLogActivity(!timeLogActivity);
	
			if(!timeLogActivity) {
				fetchProjectTimeLogHours();
			}
		}
	}

	const handleGoToTimeLog = () => {
		let date = new Date(openSelectedIssue.dateCreated);

		if(!hasPermission(auth.user, AppPermissions.PROJECT_TIME_LOG.GROUP, AppPermissions.PROJECT_TIME_LOG.VIEW, auth.permissions)) {
			return;
		}

		navigate({
				pathname: `/project-time-log`, 
				search: `year=${date.getFullYear()}&month=${date.getMonth() + 1}&project=${board.project.id}`
			},
		);
	}

	const fetchProjectTimeLogHours = () => {
		let date = new Date(openSelectedIssue.dateCreated);

		getProjectTimeLogList({year: date.getFullYear(), month: date.getMonth() + 1, project: board.project.id}).then((response) => {
			let hours = 0;
			for(let item of response.data.result) {
				hours += parseFloat(item.hourLogged);
			}
			setTimeLogHours(renderNumber(hours));
		});
	}

	const addProjectTimeLog = () => {
		setPageState(PageState.Add);
		setDrawerTitle(strings.pages.boards.boardView.activity.addProjectTimeLog)
	}

	const handleCreateLinkedIssue = () => {
		setPageState(PageState.Custom);
		setDrawerTitle(strings.pages.boards.boardView.addBoardIssue.pageTitle)
	}

	const isDrawerOpen = () => {
		return pageState !== PageState.View;
	}

	const submitProjectTimeLog = (closeDrawer = true) => {
		fetchProjectTimeLogHours();
        if (closeDrawer) {
            setPageState(PageState.View);
        }
    }

	const submitLinkedIssue = (data) => {
		addLinkedIssue({...data, issue: openSelectedIssue}).then((response) => {
			if(!response || !response.ok) { 
				return;
			}

			updateIssue(openSelectedIssue, {});
			handleCancelLinkedIssueForm();
			fetchLinkedIssues();
		});
	}

	const handleCancelLinkedIssueForm = () => {
		form.resetField('linkedIssue');
		form.resetField('linkedIssueType');

		setShowAddLinkedIssueForm(false);
	}

	const formatAssignees = (id) => {
		return assignees.filter((assignee) => assignee.id !== id);
	}

	const onSubmitCreateIssue = () => {
		setPageState(PageState.View);
		fetchIssues();
	}

	return (
		<Modal
			open={Boolean(openSelectedIssue)}
			onClose={handleCloseIssue}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			id = 'board-issue-view'>
			<Paper
				{...getRootProps({
					onClick: event => event.stopPropagation()
				})}
				onClick={() => editDescription ? setEditDescription(false) : null}
				>
				<YesNoDialog show={showDeleteDialog}
										 payload={selectedComment}
										 handleResult={handleDeleteDialogResult}
										 title={strings.components.tablePage.confirmDelete}
										 text={strings.components.tablePage.confirmDeleteMessage}
				/>

				{ isDragActive &&
					<div>
						<div className={'board-issue-drag-and-drop background'} />
						<div className={'board-issue-drag-and-drop text'}>{strings.forms.board.boardIssue.dropFiles}</div>
					</div>
				}

				<img className = 'view-issue-close' src = '/images/close-issue-view.svg' onClick = {handleCloseIssue} />
				<div className = 'view-issue-info' >
					<div className='view-issue'>
						<div className = 'view-issue-description'>
							<div className='view-issue-id-name'>
								<p className = 'view-issue-id'>{openSelectedIssue.issueId}</p>
								<p id='issue-name' contentEditable="true" className = 'view-issue-name' suppressContentEditableWarning={true}>{openSelectedIssue.name}</p>
							</div>
							<div className = 'view-issue-type'>
								{renderTypeIcon(openSelectedIssue.issueType.type)}
								{!viewChooseType ?
									<p className = 'cursor-pointer' onClick = {() => setViewChooseType(true)}>{formatIssueName(openSelectedIssue.issueType.type)}</p>
									:
									<AutoCompleteControl
										value = {getValues('issueType')}
										name = 'issueType'
										setValue = {setValue}
										control = {control}
										className = 'mui-shifted-label-input autocomplete-board-view'
										nameKey = {'name'}
										options = {issueTypes}
										valueKey = {'id'}
										openOnFocus = {true}
										onClose = {handleClose}
									/>
								}
							</div>

							<div className={editDescription ? 'view-issue-description-content' : 'view-issue-description-content hover'} onClick={handleEditDescription}>
								<p className ='view-issue-description-title'>{strings.pages.boards.boardView.issueView.description}</p>
								<div id={editDescription ? 'board-issue-description-editor' : 'board-issue-description-editor-read-only'}>
									<QuillEditor value={descriptionData} disabled={!editDescription} onChange={setDescriptionData} defaultValue={openSelectedIssue.description} />
									{ editDescription &&
										<div className='rdw-editor-buttons-container'>
											<div className='action-buttons'>
												<Button variant="contained" color="primary" className="rounded-input" onClick={saveDescription}>
													{ strings.common.save }
												</Button>
												<Button variant="contained" color="secondary" className="rounded-input" onClick={handleCancelEditDescription}>
													{strings.forms.common.cancel}
												</Button>
											</div>
										</div>
									}
								</div>
							</div>
						</div>

						<div className = 'view-issue-files-section'>
							<div className = 'view-issue-files-section-controls-container'>
								<div className = 'view-issue-files-count'>
									<p>{strings.pages.boards.boardView.issueView.files}</p>
									<p>( {files?.length} )</p>
								</div>
							</div>
							<Grid container spacing = {2} className = 'view-issue-files-container'>
								<div className = 'view-issue-files-controls' onClick = {handleAddFileClick}>
									<div className='view-issue-upload-file'>
										<img src='/images/create-issue.svg'/>
										<div className='view-issue-upload-file-label'>Upload file</div>
									</div>
									<input
										type = 'file'
										ref = {chooseFile}
										onChange = {handleFileUpload}
									/>
								</div>
								{renderFiles()}
							</Grid>
						</div>

						<div className='view-issue-linked-section'>
							<div className = 'view-issue-linked-header'>
								<p>{strings.pages.boards.boardView.issueView.linkedIssues}</p>
								<p>( {linkedIssues ? linkedIssues.total : 0} )</p>
								<div className='view-issue-linked-section-buttons'>
									<IconButton onClick = {() => setShowAddLinkedIssueForm(true) }>
										<img src='/images/plus.svg' />
									</IconButton>
								</div>
							</div>
							<div className='linked-issues-container'>
								{ linkedIssues.issues && renderLinkedIssuesContainer() }
							</div>
							{ showAddLinkedIssueForm &&
								<div className='view-issue-linked-add-section'>
									<div className='add-linked-issue-form'>
										<FormProvider {...form}>
											<SelectControl
												value = {getValues('linkedIssueType')}
												rules={formRules['linkedIssueType']}
												setValue={setValue}
												name='linkedIssueType'
												control={control}
												error={Boolean(errors.linkedIssueType)}
												helperText={errors.linkedIssueType && strings.forms.common.thisFieldIsRequired}
												options={LinkedIssueTypes}
												nameKey={'name'}
												valueKey={'type'}
												className='add-linked-issue-select-type'
											/>
											<AutoCompleteControl
												value = {getValues('linkedIssue')}
												name='linkedIssue'
												control={control}
												setValue={setValue}
												options={linkedIssueFormData}
												nameKey={'name'}
												valueKey={'id'}
												rules={formRules['linkedIssue']}
												error={Boolean(errors.linkedIssue)}
												helperText={errors.linkedIssue && strings.forms.common.thisFieldIsRequired}
												placeholder={ strings.pages.boards.boardView.issueView.searchForIssues }
												className = 'mui-shifted-label-input autocomplete-board-view add-linked-issue-autocomplete'
											/>
											<Button variant="contained" color="secondary" className='rounded-input' onClick={handleCancelLinkedIssueForm}>
												{ strings.common.cancel }
											</Button>
											<Button variant="contained" color="primary" className='rounded-input' onClick={handleSubmit(submitLinkedIssue)}>
												<img className = 'mr-2' src = '/images/disk.svg' />
												{ strings.common.save }
											</Button>
										</FormProvider>
									</div>
									<div className='create-linked-issue-button' onClick={handleCreateLinkedIssue}>
										<img src='/images/plus.svg' className = 'mr-2' />
										{ strings.pages.boards.boardView.issueView.createLinkedIssue }
									</div>
								</div>
							}
						</div>

						<div className='view-issue-activity-buttons'>
							<div>{ strings.pages.boards.boardView.activity.activity }</div>
							<Button variant="contained" color={commentsActivity ? "primary" : "secondary"}  className={board?.project ? 'button-comment' : 'radius-10'} onClick={handleActivity}>
								{ strings.pages.boards.boardView.activity.comments }
							</Button>
							{board?.project &&
								<Button variant="contained" color={timeLogActivity ? "primary" : "secondary"} className='button-timelog' onClick={handleActivity}>
									{ strings.pages.boards.boardView.activity.timeLog }
								</Button>
							}
						</div>

						{ commentsActivity &&
							<div>
								<div className = 'view-issue-comment-section'  id='board-issue-description-editor'>
									<QuillEditor value={editorData} onChange={setEditorData} className='mh-100'/>
									<Button variant="contained" color="primary" className="rounded-input centered-flex-row pl-3 py-2" onClick={saveIssueComment} disabled={isEmpty(editorData)}>
										<img src="/images/disk.svg" className="pr-2"/>
										{ strings.common.save }
									</Button>
									<div className = 'issue-comments'>
										{renderComments()}
									</div>
								</div>
							</div>
						}
						{ timeLogActivity &&
							<div className='view-issue-timelog-section'>
								<div
									className = 'view-issue-timelog-colapse-project'>
									<div className='view-issue-timelog-section-button'>
										<p>{strings.pages.boards.boardView.issueView.project}</p>
										<p className='project-name'>{board?.project.name}</p>
										<img src = '/images/arrow-open.svg' onClick = {() => setTimeLogDetails(!timeLogDetails)} className = {!timeLogDetails ? 'rotate-collapse-arrow' : ''} loading = 'lazy' />
									</div>
									<Collapse in = {timeLogDetails} unmountOnExit timeout = {0}>
										<div className='view-issue-timelog-content'>
											<div className='view-issue-timelog-label'>Hours</div>
											<div className='view-issue-timelog-hours'>{timeLogHours}</div>
										</div>
									</Collapse>
								</div>
								<div className = 'view-issue-timelog-buttons'>
									<Button variant="contained" color="primary" className="rounded-input" onClick={addProjectTimeLog}>
										<img src='/images/table-page/add-cross.svg' className='mr-2'/>
										{strings.pages.boards.boardView.activity.addProjectTimeLog}
									</Button>
									<Button variant="contained" color="secondary" className="rounded-input" onClick={handleGoToTimeLog}>
										{strings.pages.boards.boardView.activity.goToTimeLog}
									</Button>
								</div>
							</div>
						}
						<Modal
							open={Boolean(showImage) || Boolean(showFile) || Boolean(showVideo)}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
							id = 'image-view-modal'
						>
							<div className = 'image-view-modal-container' onClick={ showImage || showVideo ? handleCloseViewModal : null}>
								<div className = 'image-view-modal-options'>
									<div className ='buttons-container'>
										<div className='image-view-info-container'>
											<img src='/images/editor-image.svg' />
											{ showFile && <div>
												<div className='image-view-info-name'>{showFile.name}</div>
												<div className='image-view-info-description'>
													{BoardIssueFileTypeNames[showFile.documentType]} | {formatFileSize(showFile.documentSize)}
												</div>
											</div> }
										</div>
										{ showFile && <Icon onClick = {(e) => downloadFile(e, showFile)}>
											<img src='/images/table-page/download-csv.svg' />
										</Icon> }
										<Icon onClick={handleCloseViewModal}>
											<img src = '/images/drawer-wrapper-cross.svg' />
										</Icon>
									</div>
								</div>
								{ showImage && <img className='image-view' src = {showImage} onClick={(e) => e.stopPropagation()} /> }
								{ showVideo &&
									<video id='video' className='image-view' controls preload="auto" muted loop playsInline={true} autoPlay={true}>
										<source src={showVideo+'#t=0.001'}></source>
									</video>
								}
								{ activePdf && <PdfPreview pdf={activePdf}/> }
							</div>
						</Modal>
						<Drawer id='drawer' anchor='right' open={isDrawerOpen()} onClose={() => setPageState(PageState.View)}>
							<DrawerWrapper onBack={() => setPageState(PageState.View)}
										   title={drawerTitle} viewDetails={pageState === PageState.ViewDetails}>
								{
									pageState === PageState.Add &&
									<AddProjectTimeLog
										projectObject={board.project}
										project={null}
										onCancel={() => setPageState(PageState.View)}
										onFinish={(closeDrawer) => submitProjectTimeLog(closeDrawer)}
									/>
								}
								{
									pageState === PageState.Custom &&
									<AddBoardIssue
										onFinish = {onSubmitCreateIssue}
										onCancel={() => setPageState(PageState.View)}
										issueTypes = {issueTypes}
										assignees = {formatAssignees(auth.user.id)}
										pipelineStage = {openSelectedIssue.pipelineStage}
									/>
								}
							</DrawerWrapper>
						</Drawer>

					</div>
					<div className = 'view-issue-details'>
						<div 
							className = {issueDetails ? 'view-issue-colapse-container open' : 'view-issue-colapse-container'}>
							<p>{strings.pages.boards.boardView.issueView.details}</p>
							<img src = '/images/arrow-open.svg' onClick = {() => setIssueDetails(!issueDetails)} className = {!issueDetails ? 'rotate-collapse-arrow' : ''} loading = 'lazy' />
						</div>
						<Collapse in = {issueDetails} unmountOnExit timeout = {0}>
							<FormProvider {...form}>
								<form className = 'form' action = '#'>
									<div className = 'view-issue-details-content'>
										<div className = 'view-issue-details-user'>
											<p>{strings.pages.boards.boardView.issueView.assignee}</p>
											{openSelectedIssue?.assignee?.id!==-1 && <UserAvatar user = {openSelectedIssue?.assignee} size = {30} />}
											{!viewChooseAssignee ?
													<p className = 'cursor-pointer' onClick = {() => setViewChooseAssignee(true)}> {openSelectedIssue?.assignee?.fullName}</p>
													:
													<AutoCompleteControl
													value = {openSelectedIssue.assignee}
													name = 'assignee'
													setValue = {setValue}
													control = {control}
													className = 'mui-shifted-label-input autocomplete-board-view'
													nameKey = {'fullName'}
													options = {assignees}
													valueKey = {'id'}
													openOnFocus = {true}
													onClose = {handleClose}
												/>
											}
										</div>
										{	!(getValues('assignee')?.id === user.id) &&
											<span onClick = {handlViewAssignToMe} className = 'assign-issue-to-me'>{strings.forms.board.boardIssue.assignToMe}</span>
										}
										<div className = 'view-issue-details-priority'>
											<p>{strings.pages.boards.boardView.issueView.priority}</p>
											{renderPriorityIcon(openSelectedIssue.priority)}
											{!viewChoosePriority ?
												<p className = 'cursor-pointer' onClick = {() => setViewChoosePriority(true)}>{formatPriorityName(openSelectedIssue.priority)}</p>
												:
												<SelectControl
														value={getValues('priority')}
														className='mui-shifted-label-input autocomplete-board-view'
														setValue={setValue}
														name='priority'
														control={control}
														options={IssuePriorities}
														nameKey={'name'}
														valueKey={'type'}
														onClose = {handleClose}
														defaultOpen = {true}
												/>
											}
										</div>
										<div className = 'view-issue-details-user-reporter'>
											<p>{strings.pages.boards.boardView.issueView.reporter}</p>
											<UserAvatar user = {openSelectedIssue?.reporter} size = {30} />
											<p>{openSelectedIssue.reporter.fullName}</p>
										</div>
									</div>
								</form>
							</FormProvider>
						</Collapse>
						<IconButton 
							onClick = {(e) => setAnchorEl(e.currentTarget) }
							aria-controls={open ? 'basic-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
						>
							<p>{openSelectedIssue.pipelineStage.name}</p>
							<img 
								src = '/images/arrow-open.svg' 
								className = {!anchorEl ? 'rotate-collapse-arrow' : ''}	
							/>
						</IconButton>
						<Menu  
							className = 'issue-pipelinestage-menu'
							anchorEl={anchorEl}
							open={open}
							onClose = {handleCloseMenu}
							sx = {{
								'.MuiPaper-root': {
									maxWidth: `${anchorEl?.clientWidth}px !important`,
									width: `${anchorEl?.clientWidth}px !important`,
								}
							}}
						>
							{renderPipelineStageMenu()}
						</Menu>
						<div className = 'view-issue-details-time'>
							<p>{strings.pages.boards.boardView.issueView.created} {getDateDistance(openSelectedIssue.dateCreated)}</p>
							<p>{strings.pages.boards.boardView.issueView.updated} {getDateDistance(openSelectedIssue.dateUpdated)}</p>
						</div>
					</div>
				</div>
			</Paper>
		</Modal>
	);
}

export default BoardIssueView;
