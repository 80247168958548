import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import TablePageContext from "../../../Context/TablePageContext";
import LinearProgress from '@mui/material/LinearProgress';
import TaskCategoryForm from "../../../Components/Forms/Pages/Project/TaskCateogry/TaskCategoryForm";
import { addTaskCategory } from "../../../Services/Project/TaskCategoryService";
import SnackbarContext from "../../../Context/SnackbarContext";

const formRules = {
    'name': {required: true},
    'color': {required: true}
}

const AddTaskCategory = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm();
    const {data, handleSubmit, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setDrawerTitle(strings.pages.project.taskCategory.addTaskCategory.pageTitle);
    });

    const onSubmit = (data) => {

        setShowLoader(true);

        addTaskCategory(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <TaskCategoryForm
                formRules={formRules}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default AddTaskCategory;