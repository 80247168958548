import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import { ResourceTypes } from "../../../../../Constants/Project/ProjectResourceType";
import AutoCompleteControl from '../../../../Controls/Inputs/AutoCompleteControl';


const ProjectResourceForm = ({
                         onSubmit,
                         onCancel,
                         data,
                         form,
                         errors,
                         projectResourceTypes,
                         currencies,
                         projects,
                         values,
                         setValue,
                         formRules,
                         control,
                         onChangeProjectFilter,
                         invoices,
                         invoiceDirection,
                         onChangeInvoiceFilter,
                     }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;



    return (
        <FormProvider {...form}>
            <form id='project-resource-form' className='form' action="#">

                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.project.projectResource.name}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='description'
                    rules={formRules['description']}
                    control={data}
                    error={Boolean(errors.description)}
                    helperText={errors.description && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.project.projectResource.description}
                    disabled={isPageStateViewDetails()}
                />

                <DateTimeControl
                    name='date'
                    value={values['date']}
                    setValue={setValue}
                    label={strings.forms.project.projectResource.date}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='price'
                    rules={formRules['price']}
                    type={'number'}
                    control={data}
                    error={Boolean(errors.price)}
                    helperText={errors.price && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.project.projectResource.price}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={values['currency']}
                    setValue={setValue}
                    rules={formRules['currency']}
                    name='currency'
                    control={control}
                    error={Boolean(errors.currency)}
                    helperText={errors.currency && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.project.projectResource.currency}
                    options={currencies}
                    nameKey={'code'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    value={values['projectResourceType']}
                    setValue={setValue}
                    name='projectResourceType'
                    rules={formRules['projectResourceType']}
                    control={control}
                    error={Boolean(errors.projectResourceType)}
                    helperText={errors.projectResourceType && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.project.projectResource.projectResourceType}
                    options={projectResourceTypes}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                <SelectControl
                    value={values['type']}
                    setValue={setValue}
                    rules={formRules['type']}
                    name='type'
                    control={control}
                    error={Boolean(errors.type)}
                    helperText={errors.type && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.project.projectResource.type}
                    options={ResourceTypes}
                    nameKey={'name'}
                    valueKey={'type'}
                    disabled={isPageStateViewDetails()}
                />
                <AutoCompleteControl
                    key = {invoiceDirection}
                    value={values['invoice']}
                    setValue={setValue}
                    name='invoice'
                    rules={formRules['invoice']}
                    control={control}
                    error={Boolean(errors.invoice)}
                    helperText={errors.invoice && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.project.projectResource.invoice}
                    options={invoices}
                    nameKey={'invoiceNumber'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails() || !invoiceDirection}
                    onChange={onChangeInvoiceFilter}
                    onOpen={() => onChangeInvoiceFilter('')}
                />
                <AutoCompleteControl
                    value={values['project']}
                    setValue={setValue}
                    name='project'
                    rules={formRules['project']}
                    control={control}
                    error={Boolean(errors.project)}
                    helperText={errors.project && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.project.projectResource.project}
                    options={projects}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onChangeProjectFilter}
                    onOpen={() =>onChangeProjectFilter('')}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default ProjectResourceForm;
