import {useForm} from "react-hook-form";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {IconButton, InputBase, Paper} from "@mui/material";
import strings from "../../../../../localization";
import DrawerContext from "../../../../../Context/DrawerContext";
import SnackbarContext from "../../../../../Context/SnackbarContext";
import TagForm from "./TagForm";
import { getTagByReferenceTypeAndReferenceId, deleteTagReference, editTagReference, addTagReference } from "../../../../../Services/DocumentManagement/TagReferenceService";

const formRules = {
    'name': {required: true},
    'color': {required: true}
}

const Tag = (props) => {

    const [searchParams, setSearchParams] = useState();
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);

    const form = useForm({
        defaultValues: {
            name: '',
            color: '',
        }
    })

    const {data, handleSubmit, getValues, setValue, watch, formState: {errors}} = form;
    const [entityForEdit, setEntityForEdit] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [tags, setTags] = useState([]);
    const [filteredTags, setFilteredTags] = useState([]);
    const [editTagForms, setEditTagForms] = useState([]);
    const [loadingTags, setLoadingTags] = useState(false);

    useEffect(() => {
    }, [watch().color]);
          
    useEffect(() => {
        setDrawerTitle(strings.components.tags.tagsTitle);
        fetchTags(); 
    }, [])

    useEffect(() => {
        searchParams ? searchTag(searchParams) : setFilteredTags(tags);
    }, [tags])

    const fetchTags = () => {
        let data = { "referenceType": props.referenceType, "referenceId": props.data[0].id }
        setLoadingTags(true);

        getTagByReferenceTypeAndReferenceId(data).then(response => {
            if (!response || !response.ok){
                return
            }
           
            setEditTagForms([]);

            response.data.entity.map((entity) => {
                setEditTagForms(oldArray => [...oldArray, { "tagId": entity.id, "edit": false }])
            });

            setTags(response.data.entity);

            setLoadingTags(false);
        });
    }

    const onSubmit = (data) => {

        data = {...data, 'id': entityForEdit ? entityForEdit.id : null, "referenceType": props.referenceType, "referenceId": props.data[0].id }
        
        if(entityForEdit) 
        {
            editTagReference(data).then((response) => {

                if (!response || !response.ok) {
                    showMessage(strings.commonMessages.errorEditing, 'error');
                    return;
                }

                onCancel();
                fetchTags();
                showMessage(strings.commonMessages.editingSuccessful, 'success');
            });
            return;
        } 

        addTagReference(data).then((response) => {

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                return;
            }
            
            onCancel();
            fetchTags();
            showMessage(strings.commonMessages.editingSuccessful, 'success');
        });
        return;
    }

    const addNewTag = () => {
        onCancel();
        setShowForm(true);
    }

    const deleteSelectedTag = (id) => {
        deleteTagReference(id).then((response) => {
            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                return;
            }
            onCancel();
            fetchTags();
            showMessage(strings.commonMessages.editingSuccessful, 'success');
        });
    }

    const onCancel = () => {
        form.reset();
        setEntityForEdit(null);
        setShowForm(false);
        editTagForms.map((tag) => {
            tag.edit = false;
        });
    }

    const editSelectedTag = (entity) => {
        onCancel();
        editTagForms.map((tag) => {
            if(tag.tagId == entity.id) {
                tag.edit = true;
            }
        });
        
        form.setValue('name', entity.tag.name)
        form.setValue('color', entity.tag.color)
        setEntityForEdit(entity);
    }

    const renderTags = () => {
        let result = [];
        let entityNumber = 0;

        for (let entity of filteredTags) {
            result.push(
                <Fragment>
                <div className='tag'>
                    <div className='tag-color' style={{backgroundColor: entity.tag.color }}/>
                    <div className='tag-name'>{entity.tag.name}</div>
                    <div className='tag-edit-delete-actions'>
                        <IconButton onClick={() => editSelectedTag(entity)}>
                            <img src='/images/table-page/edit.svg'/>
                        </IconButton>
                        <IconButton onClick={() => deleteSelectedTag(entity.id)}>
                            <img src='/images/table-page/delete.svg'/>
                        </IconButton>
                    </div>
                </div>
                { editTagForms[entityNumber]['edit'] && renderForm() }
                </Fragment>
            )
            entityNumber ++;
        }
        
        return result;
    }

    const renderForm = () => {
        return <TagForm
            formRules={formRules}
            values={getValues()}
            setValue={setValue}
            errors={errors} data={data} form={form}
            onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()} /> 
    }

    const searchTag = (name) => {
        const filteredTags = tags.filter(
            (entity) => entity.tag.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
        );
        setSearchParams(name);
        setFilteredTags(filteredTags);
        setEditTagForms([]);
        filteredTags.map((entity) => {
            setEditTagForms(oldArray => [...oldArray, { "tagId": entity.id, "edit": false }])
        });
      };

    return <div className='tags'>
        <Paper className='paper'>
            <div className='search'>
                <Paper>
                    <IconButton>
                        <img src = '/images/search.svg' />
                    </IconButton>
                    <InputBase
                        value={searchParams}
                        placeholder = {"Search"}
                        onChange={(e) => searchTag(e.target.value)}/>
                        
                </Paper>
                <div className='tag-text-under-search'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
            </div>
            
            { showForm && renderForm() }

            <div className='tags-container'>
                {
                    ( tags && !loadingTags ) &&
                    renderTags()
                }
                <div className='tag-add-remove-all-actions'>
                    <IconButton className='tag-add' onClick={ addNewTag }>
                        <img src='/images/table-page/add-cross.svg'/>
                        <div className='tag-add-text'>{strings.components.tags.addTag}</div>
                    </IconButton>
                </div>
            </div>
        </Paper>
    </div>
}

export default Tag;