import React, {useContext, useState, useEffect} from "react";
import {FormProvider} from "react-hook-form";
import strings from "../../../../../localization";
import Button from '@mui/material/Button';
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";

const LeaveRequestForm = ({
                              onSubmit,
                              onCancel,
                              data,
                              form,
                              errors,
                              formRules,
                              values,
                              setValue,
                              leaveTypes,
                              control,
                          }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const [fromDate, setFromDate] = useState(values['fromDate']);
    const [additionalRules, setAdditionalRules] = useState({});

    useEffect(() => {
        changeAdditionalRules(fromDate);
    },[fromDate]);

    useEffect(() => {
        setFromDate(values['fromDate']);
    }, [values['fromDate']]);

    const changeFromDate = (date) => {
        setValue('fromDate', date);
        setFromDate(date);
    }

    const changeAdditionalRules = (date) => {
        const dateCopy = new Date(date?.getTime() || NaN);
        if(!dateCopy){
            setAdditionalRules({});
            return; 
        }
        dateCopy.setHours(0);
        dateCopy.setMinutes(0);
        setAdditionalRules({min: {value: dateCopy, message: strings.forms.hr.leaveRequest.dateError}});
    }
    return (
        <FormProvider {...form}>
            <form id="leave-request-form" className='form' action="#">
                <DateTimeControl
                    data={data}
                    rules={formRules['fromDate']}
                    form={form}
                    error={Boolean(errors.fromDate)}
                    helperText={errors.fromDate && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.leaveRequest.fromDate}
                    name={'fromDate'}
                    value={values['fromDate']}
                    setValue={(name, value) => changeFromDate(value)}
                    disabled={isPageStateViewDetails()}
                />
                <DateTimeControl
                    data={data}
                    rules={{...formRules['toDate'], ...additionalRules}}
                    form={form}
                    error={Boolean(errors.toDate)}
                    helperText={errors.toDate && errors.toDate.message}
                    label={strings.forms.hr.leaveRequest.toDate}
                    name={'toDate'}
                    value={values['toDate']}
                    setValue={setValue}
                    minDate={fromDate}
                    disabled={isPageStateViewDetails()}
                />
                <AutoCompleteControl
                    value={values['leaveType']}                
                    setValue={setValue}
                    name='leaveType'
                    rules={formRules['leaveType']}
                    control={control}
                    error={Boolean(errors.leaveType)}
                    helperText={errors.leaveType && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.job.leaveType}
                    options={leaveTypes}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default LeaveRequestForm;