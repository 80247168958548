import React, {useState, useEffect} from 'react';
import {Paper, Modal, IconButton} from "@mui/material";
import { useForm } from "react-hook-form";
import strings from "../../../localization";
import Button from "@mui/material/Button";
import { getCheckListTemplateQuestionOptions ,
    addCheckListTemplateQuestionOption,
    editCheckListTemplateQuestionOption,
    deleteCheckListTemplateQuestionOption
} from "../../../Services/CheckList/CheckListTemplateQuestionOptionService";
import {hasPermission} from "../../../Util/PermissionUtil";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {useSelector} from "react-redux";
import AddQuestionOptionModal from "../../CompanyDocument/CompanyDocumentTemplateQuestion/AddQuestionOptionModal";
import EditQuestionOptionModal from "../../CompanyDocument/CompanyDocumentTemplateQuestion/EditQuestionOptionModal";
const AddQuestionOptionsModal = ({showModal, closeModal, submit, question}) => {

    const [options, setOptions] = useState([]);
    const auth = useSelector((state) => state.auth)
    const permissionGroup = AppPermissions.CHECK_LIST_TEMPLATE_QUESTION.GROUP;
    const [handleShowAddModal, setHandleShowAddModal] = useState(null);
    const [handleShowEditModal, setHandleShowEditModal] = useState(null);
    const [editQuestionOption, setEditQuestionOption] = useState(null);

    const form = useForm();

    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;

    useEffect(() => {
        fetchOptions()
    }, [])


    const handleCloseModal = (prop) => {
        closeModal(prop)
    }

    const onSave = () => {
        closeModal()
    }

    const fetchOptions = () => {
        const data = {questionId: question?.id}
        getCheckListTemplateQuestionOptions(data).then(response => {
            setOptions(response.data.result)
        })
    }

    const onDelete = (id) => {
        deleteCheckListTemplateQuestionOption(id).then(response => {
            fetchOptions()
        })
    }

    const onEdit = (questionOption) => {
        setEditQuestionOption(questionOption);
        setHandleShowEditModal(true);
    }

    const onAddOption = () => {
        setHandleShowAddModal(true);
    }

    const handleCloseAddModal = () => {
        setHandleShowAddModal(false);
    }

    const handleCloseEditModal = () => {
        setHandleShowEditModal(false);
    }

    const renderOptions = () => {
        let result = [];
        for(let object of options){
            result.push(
                <div className='question-option-component' key={object.id}>
                    <div className='question-option-info'>
                        <div className='question-option-name'>{object.option}</div>
                    </div>
                    <div className='question-button-container'>
                        { hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].EDIT, auth.permissions) &&
                            <IconButton className='button' onClick={() => onEdit(object)}>
                                <img src="/images/edit-blue.svg" />
                            </IconButton>
                        }
                        { hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].DELETE, auth.permissions) &&
                            <IconButton className='button' onClick={() => onDelete(object.id)}>
                                <img src="/images/delete-blue.svg" />
                            </IconButton>
                        }
                    </div>
                </div>
            )
        }
        return result;
    }

    const submitOptionHandler = (data) => {
        addCheckListTemplateQuestionOption(data).then(response => {
            fetchOptions();
        })
        setHandleShowAddModal(false);
    }

    const submitEditOptionHandler = (data) => {
        editCheckListTemplateQuestionOption(data).then(response => {
            fetchOptions();
        })
        setHandleShowEditModal(false);
        setEditQuestionOption(null);
    }

    const handleClosePaper = (prop) => {
        closeModal(prop);
    }


    return(
        <Modal
            open={Boolean(showModal)}
            onClose={handleClosePaper}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            id='add-edit-question-modal-option'>
            <div className={"paper-space"}>
                <Paper>
                    <div className="add-edit-question-option">
                        <img className='add-edit-question-modal-close' src='/images/close-issue-view.svg' onClick = {handleClosePaper} />
                        <div className='add-edit-question-modal-title'>
                            {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateQuestion.addQuestionOptions}
                        </div>
                        <div className="header">
                            <IconButton className='new-option-button' onClick={() => {onAddOption()}}>
                                <img src='/images/new-step-icon.svg'/>
                                <div className='new-text'>
                                    {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateQuestion.newQuestionOption}
                                </div>
                            </IconButton>
                        </div>
                        <div className="options-container">
                            {options && renderOptions()}
                        </div>
                        <div className="footer">
                            <div className="save-button">
                                <Button variant="contained" color="primary" onClick={onSave}>
                                    {strings.forms.common.save}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <AddQuestionOptionModal
                        showModal={handleShowAddModal}
                        closeModal={handleCloseAddModal}
                        question={question}
                        submit={submitOptionHandler}>
                    </AddQuestionOptionModal>
                    {!(editQuestionOption === null) && <EditQuestionOptionModal
                        showModal={handleShowEditModal}
                        closeModal={handleCloseEditModal}
                        questionOption={editQuestionOption}
                        submit={submitEditOptionHandler}>
                    </EditQuestionOptionModal>}
                </Paper>
            </div>
        </Modal>
    );
}


export default AddQuestionOptionsModal;