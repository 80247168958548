import {FormProvider} from "react-hook-form";
import strings from "../../../../localization";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import React, {useContext} from "react";
import Button from "@mui/material/Button";
import TablePageContext from "../../../../Context/TablePageContext";
import PageState from "../../../../Constants/Base/PageState";
import SelectControl from "../../../Controls/Inputs/SelectControl";
import DateFormats from "../../../../Constants/Company/DateFormats";
import NumberFormats from "../../../../Constants/Company/NumberFormats";
import {Grid} from '@mui/material';

const LocalisationSettingsForm = ({
                            form,
                            values,
                            setValue,
                            control,
                            formRules,
                            errors,
                            onSubmit,
                        }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='localisation-settings-form' className='form' action="#">
                <Grid container spacing = {3}>
                    <Grid item xs = {12}>
                        <SelectControl
                            value={values['dateFormat']}
                            className = {'mui-shifted-label-input'}
                            rules={formRules['dateFormat']}
                            setValue={setValue}
                            name='dateFormat'
                            control={control}
                            error={Boolean(errors.type)}
                            helperText={errors.type && strings.forms.common.thisFieldIsRequired}
                            label={strings.forms.company.localisationSettings.dateFormat}
                            options={DateFormats.toSelect}
                            nameKey={'key'}
                            valueKey={'value'}
                            disabled={isPageStateViewDetails()}
                        />
                    </Grid>
                    <Grid item xs = {12}>
                        <SelectControl
                            value={values['numberFormat']}
                            className = {'mui-shifted-label-input'}
                            rules={formRules['numberFormat']}
                            setValue={setValue}
                            name='numberFormat'
                            control={control}
                            error={Boolean(errors.type)}
                            helperText={errors.type && strings.forms.common.thisFieldIsRequired}
                            label={strings.forms.company.localisationSettings.numberFormat}
                            options={NumberFormats.toSelect}
                            nameKey={'key'}
                            valueKey={'value'}
                            disabled={isPageStateViewDetails()}
                        />
                    </Grid>
                {
                    !isPageStateViewDetails() && 
                        <Grid item xs = {12} className = 'submit-btn-item'>
                            <div className='submit-container'>
                                <Button variant="contained" color="primary" onClick={onSubmit}>
                                    {strings.forms.common.save}
                                </Button>
                            </div>
                        </Grid>
                }
                </Grid>
            </form>
        </FormProvider>
    )
}


export default LocalisationSettingsForm;
