import {Avatar} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getUserProfileImage} from "../Services/User/UserService";
import {Business} from "@mui/icons-material";
import {getCompanyLogo} from "../Services/Company/CompanyService";
import {getWorkspaceLogo} from "../Services/Workspaces/WorkspacesService";
import {getBoardLogo} from "../Services/Workspaces/BoardsService";
import {getTeamLogo} from '../Services/HR/TeamService';
import { useSelector } from "react-redux";

export const UserAvatar = ({user, size = 35}) => {
    const [image, setImage] = useState();
    const reloadImages = useSelector((state) => state.navigation.reloadImages)
    const stringAvatar = (firstName, lastName) => {

        return {
            children: `${firstName.trim()[0]}${lastName.trim()[0]}`,
        };
    }
    useEffect(() => {
        if (user) {
            getUserProfileImage(user.id).then(response => {
                if(!response || !response.ok) {
                    return;
                }
                setImage(response.data.userProfileImage);
            });
        }
    }, [user, reloadImages]);



    if(!user) {
        return ''
    }

    if(!image) {
        return <Avatar
            {...stringAvatar(user.firstName, user.lastName)}
            sx={{ width: size, height: size }}
        />
    }

    return <Avatar
        sx={{ width: size, height: size }}
        src={image} />
}

export const CompanyAvatar = ({company, height = 50, width = 50, componentWidth=50, options = {variant: 'circular'}}) => {
    const [image, setImage] = useState();
    const reloadImages = useSelector((state) => state.navigation.reloadImages)

    useEffect(() => {
        if (company) {
            getCompanyLogo(company.id).then(response => {
                if(!response || !response.ok) {
                    return;
                }
                setImage(response.data.logo);
            });
        }
    }, [company, reloadImages]);


    if(!company) {
        return <Business fontSize={'small'}/>
    }

    if(!componentWidth){
        componentWidth=width;
    }

    if(!image) {
        if(company.name?.length <= 2) {
            return company.name?.toUpperCase()
        }

        return <Avatar
            className="company-logo"
            children={company.name?.substring(0, 2).toUpperCase()}
            sx={{ width, height }}
            imgProps={{style: { width, height: 'unset' }}}
        />
    }

    return <Avatar
        className="company-logo"
        variant={options.variant}
        sx={{ width: componentWidth, height }}
        imgProps={{style: {width, height: 'unset'}}}
        src={image} />
}

export const WorkspaceLogo = ({workspace, size = 35}) => {
    const [image, setImage] = useState();
    const reloadImages = useSelector((state) => state.navigation.reloadImages)
    const stringAvatar = (name) => {
        return {
            children: `${name[0]}`,
        };
    }
    useEffect(() => {
        if (workspace) {
            getWorkspaceLogo(workspace.id).then(response => {
                if(!response || !response.ok) {
                    return;
                }
                setImage(response.data.workspaceLogo);
            });
        }
    }, [workspace, reloadImages]);



    if(!workspace) {
        return ''
    }

    if(!image) {
        return <Avatar
            {...stringAvatar(workspace.name)}
            sx={{ width: size, height: size }}
        />
    }

    return <Avatar
        sx={{ width: size, height: size }}
        src={image} />
}

export const TeamLogo = ({team, size = 35}) => {
    const [image, setImage] = useState();
    const reloadImages = useSelector((state) => state.navigation.reloadImages)
    const stringAvatar = (name) => {
        return {
            children: `${name[0]}`,
        };
    }
    useEffect(() => {
        if (team) {
            getTeamLogo(team.id).then(response => {
                if(!response || !response.ok) {
                    return;
                }
                setImage(response.data.logo);
            });
        }
    }, [team, reloadImages]);

    if(!team) {
        return ''
    }

    if(!image) {
        return <Avatar
            {...stringAvatar(team.name)}
            sx={{ width: size, height: size }}
        />
    }

    return <Avatar
        sx={{ width: size, height: size }}
        src={image} />
}

export const BoardLogo = ({board, changeLogo, hasPermission, size = 40}) => {
    const [image, setImage] = useState();
    const reloadImages = useSelector((state) => state.navigation.reloadImages)
    const stringAvatar = (name) => {
        if(!name){
            return;
        }

        return {
            children: `${name[0]}`,
        };
    }
    useEffect(() => {
        getBoardLogo(board.id).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setImage(response.data.boardLogo);
        });
    }, [reloadImages, board]);

    if(!image) {
        return <Avatar
            onClick = {hasPermission && changeLogo}
            {...stringAvatar(board.name)}
            sx={{ width: size, height: size, cursor: hasPermission ? 'pointer' : 'auto'}}
        />
    }

    return <Avatar
        onClick = {hasPermission && changeLogo}
        sx={{ width: size, height: size, cursor: hasPermission ? 'pointer' : 'auto'}}
        src={image} />
}

export const FavoriteBoardLogo = ({board, size = 26}) => {
    const [image, setImage] = useState();
    const reloadImages = useSelector((state) => state.navigation.reloadImages)
    const stringAvatar = (name) => {
        return {
            children: `${name[0]}`,
        };
    }
    useEffect(() => {
        getBoardLogo(board.id).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setImage(response.data.boardLogo);
        });
    }, [reloadImages, board]);

    if(!image) {
        return <Avatar
            {...stringAvatar(board.name)}
            sx={{ width: size, height: size}}
        />
    }

    return <Avatar
        sx={{ width: size, height: size}}
        src={image} />
}

export const UserAvatarGroupContainer = ({users, onClickMore, hasPermission, max = 5, size = 38}) => {
    const stringAvatar = () => {
        const left = users.length - max;
        return {
            children: `+${left}`,
        };
    }

    return (
        <div id = 'avatar-group'>
        {
          users.slice(0, max).map((user) => (
            <UserAvatar user = {user} size = {size} key = {'user-avatar-group-' + user.id}/>
          ))
        }
        {
            users.length >= max &&
            <Avatar 
                {...stringAvatar()}
                sx = {{width: size, height: size}}
                className = {hasPermission ? 'avatar-more has-edit-permission' : 'avatar-more'}
                onClick = {onClickMore}
            />
        }
        </div>
    )
}
