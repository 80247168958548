import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import strings from "../../../localization";
import {useNavigate, useParams} from "react-router-dom";
import {Grid, Paper, Tab, Tabs} from "@mui/material";
import {ic_alternate_email} from 'react-icons-kit/md/ic_alternate_email'
import {ic_call} from 'react-icons-kit/md/ic_call'
import {ic_location_on} from 'react-icons-kit/md/ic_location_on'
import {Icon} from "react-icons-kit";
import {dateToString} from "../../../Util/DateUtil";
import DocumentList from "../../Document/Document/DocumentList";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {getUser} from "../../../Services/User/UserService";
import EmployeeTab from "./EmployeeTab";
import EmployeeRecord from "./EmployeeRecord";
import EmployeeStatistic from "./EmployeeStatistic";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import { hasPermission } from "../../../Util/PermissionUtil";
import { useSelector } from "react-redux";
import {getOneUserCompanyAccess, getUserCompanyAccess} from "../../../Services/User/UserCompanyAccessService";

const EmployeeDetails = ({activeTab = EmployeeTab.RECORD}) => {
    const {id: employeeId} = useParams();
    const auth = useSelector((state) => state.auth)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [employee, setEmployee] = useState(null);

    const permissionGroup = AppPermissions.EMPLOYEE_RECORDS;

    useEffect(() => {

        dispatch(changePageSizeState(PageSizeState.SHORT));
        fetch();

    }, [])

    const fetch = () => {

        getOneUserCompanyAccess(employeeId).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setEmployee(response.data.entity);
        })

    }

    const checkTabPermission = () => {
        if(activeTab === EmployeeTab.RECORD) {
            return hasPermission(auth.user, permissionGroup.GROUP, permissionGroup.VIEW, auth.permissions) ? EmployeeTab.RECORD : false;
        }

        return activeTab;
    }

    const changeActiveTab = (tab) =>{
        if(tab === EmployeeTab.RECORD){
            navigate(`/employee/${employee.id}/record`)
        }
        if(tab === EmployeeTab.FILES){
            navigate(`/employee/${employee.id}/files/${ReferenceType.USER}`)
        }
        if(tab === EmployeeTab.STATISTIC){
            navigate(`/employee/${employee.user.id}/statistic`)
        } 
    }

    return <div id='candidate-details-page'>
        {
            employee &&
            <Paper className='paper'>
                <Grid container>
                    <Grid item xs={6}>
                        <div className='base-info'>
                            <h1>{employee.user.firstName} {employee.user.lastName} {dateToString(new Date(employee.user.birthDate), {prefix: '(', suffix: ')'})}</h1>
                            <p><Icon size={25} icon={ic_alternate_email}/>
                                <a target='_blank' href={'mailto:' + employee.user.email}> {employee.user.email}</a>
                                <Icon size={25} icon={ic_call}/>
                                <a target='_blank' href={'tel:' + employee.user.phoneNumber}> {employee.user.phoneNumber}</a>
                                <Icon size={25} icon={ic_location_on}/>
                                {employee.user.address}
                            </p>
                            <p>{employee.user.description}</p>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        }

        <div id="hr-settings">
            <div className='tab-container'>
                <Paper className='paper'>
                    <Tabs variant="scrollable" value={checkTabPermission()} onChange={(e, tab) => changeActiveTab(tab)}>
                        <Tab disabled = {!hasPermission(auth.user, permissionGroup.GROUP, permissionGroup.VIEW, auth.permissions)} label={strings.pages.user.employeeDetails.record} />
                        <Tab label={strings.pages.user.employeeDetails.files}
                            disabled = {!hasPermission(auth.user, AppPermissions.EMPLOYEE.GROUP, AppPermissions.EMPLOYEE.DOCUMENTS, auth.permissions)}
                        />
                        <Tab label={strings.pages.user.employeeDetails.statistic}
                            disabled = {!hasPermission(auth.user, AppPermissions.EMPLOYEE.GROUP, AppPermissions.EMPLOYEE.VIEW, auth.permissions)}
                        />
                    </Tabs>
                </Paper>
            </div>
        </div>

        {
            activeTab === EmployeeTab.RECORD && employee &&
            <EmployeeRecord employee={employee}/>
        }
        {
            activeTab === EmployeeTab.FILES &&
            <DocumentList />
        }
        {
            activeTab === EmployeeTab.STATISTIC && employee &&
            <EmployeeStatistic employee={employee.user}/>
        }
    </div>
}

export default EmployeeDetails;
