import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getRolePermissions(roleId) {
    return await request('/api/role-permissions/' + roleId);
}

export async function updateRolePermissions(data) {
    return await request('/api/role-permissions',data, HttpMethod.POST);
}

export async function updateAllRolePermissions(data) {
    return await request('/api/role-permissions-all',data, HttpMethod.POST);
}
