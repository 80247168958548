import React from "react";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import TablePage from "../../../Components/DataGrid/TablePage";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import PageState from "../../../Constants/Base/PageState";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import strings from "../../../localization";
import {deleteCandidateSource, getCandidateSourceList} from "../../../Services/HR/CandidateSourceService";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import {changePageSizeState} from "../../../Slices/PageSlice";
import AddCandidateSource from "./AddCandidateSource";
import EditCandidateSoruce from "./EditCandidateSource";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Modules from "../../../Constants/Base/Modules";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [
    {field: 'name', headerName: strings.pages.hr.candidateSource.candidateSourceList.name, width: 250}
];

const filters = []

const CandidateSourceList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.CANDIDATE_SOURCE;
    const permissionGroup = AppPermissions.CANDIDATE_SOURCE.GROUP;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));

        dispatch(changeBreadcrumbs({
            title: strings.pages.hr.candidateSource.candidateSourceList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.pages.hr.settings.settings},
                {label: strings.pages.hr.candidateSource.candidateSourceList.pageTitle},
            ],        
        }));
        
        return () => {
            setTableData({})
        }
    }, []);

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter]);

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        })

        getCandidateSourceList({
            ...filter,
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        });
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteCandidateSource} tableDescription={columns}
                   tableData={tableData} filter={filter} filters={filters}
                   referenceType={referenceType}
                   permissionGroup={permissionGroup}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditCandidateSoruce data={selectedItems}
                                                  onFinish={() => onFinish()} onCancel={() => onCancel()}/>}
                   addPage={<AddCandidateSource onFinish={() => onFinish()} onCancel={() => onCancel()}/>}
                   hideBreadcrumbs
                   addButtonText={strings.components.tablePage.addButton.candidateSource}/>
    </TablePageContext.Provider>
}

export default CandidateSourceList;
