import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import strings from "../../../localization";
import {useParams} from "react-router-dom";
import {Grid, Paper, Tab, Tabs} from "@mui/material";
import DocumentList from "../../Document/Document/DocumentList";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import { getProject } from "../../../Services/Project/ProjectService";
import ProjectDetailsTab from "../../../Constants/Project/ProjectDetailsTab";
import ProjectRecord from "./ProjectRecord";
import ProjectStatistic from "./ProjectStatistic";
import ProjectTimeLogList from "../ProjectTimeLog/ProjectTimeLogList";
import ProjectAccessList from "./ProjectAccessList";
import ProjectTaskCategoryList from "./ProjectTaskCategoryList";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import { hasPermission } from "../../../Util/PermissionUtil";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import { useNavigate } from 'react-router-dom';

const ProjectDetails = ({activeTab = ProjectDetailsTab.TIME_LOG}) => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const {id} = useParams();
    const [project, setProject] = useState(null);
    const permissionGroup = AppPermissions.PROJECT.GROUP;
    const tabPermissions = [
        {disabled: !hasPermission(auth.user, AppPermissions.PROJECT_TIME_LOG.GROUP, AppPermissions[AppPermissions.PROJECT_TIME_LOG.GROUP].VIEW, auth.permissions)},
        {disabled: !hasPermission(auth.user, AppPermissions.PROJECT_RECORDS.GROUP, AppPermissions[AppPermissions.PROJECT_RECORDS.GROUP].VIEW, auth.permissions)},
        {disabled: !hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].DOCUMENTS, auth.permissions)},
        {disabled: !hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].STATISTIC, auth.permissions)},
        {disabled: !hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].ACCESS, auth.permissions)},
        {disabled: !hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].TASK_CATEGORY, auth.permissions)},        
    ]

    const changeActiveTab = (tab) => {
        if(tab === ProjectDetailsTab.TIME_LOG){
            navigate(`/project-details/${id}/project-time-log`);
        }

        if(tab === ProjectDetailsTab.RECORD){
            navigate(`/project-details/${id}/project-record`);
        }

        if(tab === ProjectDetailsTab.FILES){
            navigate(`/project-details/${id}/project-files/${ReferenceType.PROJECT}`);
        }

        if(tab === ProjectDetailsTab.PROJECT_ACCESS){
            navigate(`/project-details/${id}/project-access`);
        }

        if(tab === ProjectDetailsTab.STATISTIC){
            navigate(`/project-details/${id}/project-statistic`);
        }

        if(tab === ProjectDetailsTab.PROJECT_TASK_CATEGORIES){
            navigate(`/project-details/${id}/task-categories`);
        }
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.project.details,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.PROJECTS},
                {label: strings.pages.project.details},
            ],        
        }));
    }, [])

    useEffect(() => {
        fetch();
    },[]);

    const fetch = () => {
        getProject(id).then(response => {
            if (!response || !response.ok){
                return;
            }
            setProject(response.data.entity)
        });
    }

    return <div id='project-details-page'>
        {
            project &&
            <div className='clear-paper rounded-2 mb-5 p-4'>
                <Grid container>
                    <Grid item xs={10}>
                        <div className='base-info'>
                            <h1>{project.name}</h1>
                            <p>{project.description}</p>
                        </div>
                    </Grid>
                    <Grid item xs={2} className='d-flex align-center'>
                        <div className='additional-info'>
                            {project.projectCategory &&
                            <p>
                                <span>{strings.pages.project.project.projectDetails.projectCategory}</span>: {project.projectCategory.name}
                            </p>}
                        </div>
                    </Grid>
                </Grid>
            </div>
        }

        <div id="project-settings">
            <div className='tab-container mb-5'>
                <Paper className='paper'>
                    <Tabs variant="scrollable" value={activeTab} onChange={(e, tab) => changeActiveTab(tab)}>
                        <Tab disabled={tabPermissions[ProjectDetailsTab.TIME_LOG].disabled} label={strings.pages.project.project.projectDetails.timeLog}/>
                        <Tab disabled={tabPermissions[ProjectDetailsTab.RECORD].disabled} label={strings.pages.project.project.projectDetails.record}/>
                        <Tab disabled={tabPermissions[ProjectDetailsTab.FILES].disabled} label={strings.pages.project.project.projectDetails.files}/>
                        <Tab disabled={tabPermissions[ProjectDetailsTab.STATISTIC].disabled} label={strings.pages.project.project.projectDetails.statistic}/>
                        <Tab disabled={tabPermissions[ProjectDetailsTab.PROJECT_ACCESS].disabled} label={strings.pages.project.project.projectDetails.projectAccess}/>
                        <Tab disabled={tabPermissions[ProjectDetailsTab.PROJECT_TASK_CATEGORIES].disabled} label={strings.pages.project.project.projectDetails.projectTaskCategories}/>
                    </Tabs>
                </Paper>
            </div>
        </div>
        {
            hasPermission(auth.user, AppPermissions.PROJECT_TIME_LOG.GROUP, AppPermissions[AppPermissions.PROJECT_TIME_LOG.GROUP].VIEW, auth.permissions) &&
            activeTab === ProjectDetailsTab.TIME_LOG && project &&
            <ProjectTimeLogList projectObject={project} project={project.id}/>
        }
        {
            hasPermission(auth.user, AppPermissions.PROJECT_RECORDS.GROUP, AppPermissions[AppPermissions.PROJECT_RECORDS.GROUP].VIEW, auth.permissions) &&
            activeTab === ProjectDetailsTab.RECORD &&
            <ProjectRecord project={project}/>
        }
        {
            hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].DOCUMENTS, auth.permissions) &&
            activeTab === ProjectDetailsTab.FILES &&
            <DocumentList referenceType={ReferenceType.PROJECT} id={project?.id}/>
        }
        {
            hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].STATISTIC, auth.permissions) &&
            activeTab === ProjectDetailsTab.STATISTIC &&
            <ProjectStatistic project={project}/>
        }
        {
            hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].ACCESS, auth.permissions) &&
            activeTab === ProjectDetailsTab.PROJECT_ACCESS  &&
            <ProjectAccessList project={project}/>
        }
        {
            hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].TASK_CATEGORY, auth.permissions) &&
            activeTab === ProjectDetailsTab.PROJECT_TASK_CATEGORIES  &&
            <ProjectTaskCategoryList project={project}/>
        }
        </div>
}

export default ProjectDetails;
