import React, {useState, useEffect} from "react";
import {Paper, Tab, Tabs} from "@mui/material";
import strings from "../../../localization";
import {FinanceSettingsTab} from "../../../Constants/Finance/FinanceSettingsTab";
import BankAccountList from "../BankAccount/BankAccountList";
import InvoiceSettings from "../../Company/Settings/InvoiceSettings";
import TransactionCategoryList from "../TransactionCategory/TransactionCategoryList";
import BusinessYearList from "../../Administartion/BusinessYear/BusinessYearList";
import { useSelector, useDispatch } from "react-redux";
import { hasPermission } from "../../../Util/PermissionUtil";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {changePageSizeState} from "../../../Slices/PageSlice";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import Modules from "../../../Constants/Base/Modules";
import { useNavigate } from 'react-router-dom';
import ContractStatusList from "../ContractStatus/ContractStatusList";
import ContractPartStatusList from "../ContractPartStatus/ContractPartStatusList";

const FinanceSettings = ({activeTab = FinanceSettingsTab.BANK_ACCOUNTS}) => {
    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const changeActiveTab = (tab) =>{
        if(tab === FinanceSettingsTab.BANK_ACCOUNTS){
            navigate('/finance-settings/bank-accounts')
        }
        if(tab === FinanceSettingsTab.INVOICE_SETTINGS){
            navigate('/finance-settings/invoice-settings')
        }
        if(tab === FinanceSettingsTab.TRANSACTION_CATEGORY){
            navigate('/finance-settings/transaction-categories')
        }
        if(tab === FinanceSettingsTab.BUSINESS_YEAR){
            navigate('/finance-settings/business-years')
        }
        if(tab === FinanceSettingsTab.CONTRACT_STATUS){
            navigate('/finance-settings/contract-status')
        }
        if(tab === FinanceSettingsTab.CONTRACT_PART_STATUS){
            navigate('/finance-settings/contract-part-status')
        }
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: hasPermission(auth.user, AppPermissions.BANK_ACCOUNT.GROUP, AppPermissions.BANK_ACCOUNT.VIEW_LIST, auth.permissions) ? strings.pages.finance.settings.bankAccounts : strings.pages.finance.settings.settings,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.FINANCE},
                {label: strings.pages.finance.settings.settings},
                {label: strings.pages.finance.settings.bankAccounts},
                {label: strings.pages.finance.settings.contractStatus},
                {label: strings.pages.finance.settings.contractPartStatus},
            ],        
        }));
    }, [])

    return (
        <div id="finance-settings">
            <div className='tab-container ml-3'>
                <Paper className='paper'>
                    <Tabs variant="scrollable" value={activeTab} onChange={(e, tab) => changeActiveTab(tab)}>
                        <Tab label={strings.pages.finance.settings.bankAccounts}
                            disabled={!hasPermission(auth.user, AppPermissions.BANK_ACCOUNT.GROUP,
                                AppPermissions.BANK_ACCOUNT.VIEW_LIST, auth.permissions)}
                            />
                        <Tab label={strings.pages.finance.settings.invoiceSettings}
                            disabled={!hasPermission(auth.user, AppPermissions.INVOICE_SETTINGS.GROUP,
                                AppPermissions.INVOICE_SETTINGS.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.finance.settings.transactionCategories}
                            disabled={!hasPermission(auth.user, AppPermissions.TRANSACTION_CATEGORY.GROUP,
                                AppPermissions.TRANSACTION_CATEGORY.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.finance.settings.businessYear}
                        disabled={!hasPermission(auth.user, AppPermissions.BUSINESS_YEAR.GROUP,
                            AppPermissions.BUSINESS_YEAR.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.finance.settings.contractStatus}
                             disabled={!hasPermission(auth.user, AppPermissions.CONTRACT_STATUS.GROUP,
                                 AppPermissions.CONTRACT_STATUS.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.finance.settings.contractPartStatus}
                             disabled={!hasPermission(auth.user, AppPermissions.CONTRACT_PART_STATUS.GROUP,
                                 AppPermissions.CONTRACT_PART_STATUS.VIEW_LIST, auth.permissions)}
                        />
                    </Tabs>
                </Paper>
            </div>

            {
                activeTab === FinanceSettingsTab.BANK_ACCOUNTS &&
                <BankAccountList/>
            }
            {
                activeTab === FinanceSettingsTab.INVOICE_SETTINGS &&
                <InvoiceSettings/>
            }
            {
                activeTab === FinanceSettingsTab.TRANSACTION_CATEGORY &&
                <TransactionCategoryList/>
            }
            {
                activeTab === FinanceSettingsTab.BUSINESS_YEAR &&
                <BusinessYearList/>
            }
            {
                activeTab === FinanceSettingsTab.CONTRACT_STATUS &&
                <ContractStatusList/>
            }
            {
                activeTab === FinanceSettingsTab.CONTRACT_PART_STATUS &&
                <ContractPartStatusList/>
            }
        </div>
    );
}

export default FinanceSettings;
