import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getCompanyDocumentTemplate(id) {
    return await request('/api/company-document-templates/' + id);
}

export async function getCompanyDocumentTemplates(data) {
    return await request('/api/company-document-templates', data);
}

export async function getAllCompanyDocumentTemplates() {
    return await request('/api/company-document-templates/all');
}

export async function addCompanyDocumentTemplate(data) {
    return await request('/api/company-document-templates', transformRequestData(data), HttpMethod.POST);
}

export async function editCompanyDocumentTemplate(data) {
    return await request('/api/company-document-templates/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteCompanyDocumentTemplate(id) {
    return await request('/api/company-document-templates/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        companyDocumentCategoryId: data.companyDocumentCategory ? data.companyDocumentCategory.id : null
    }
}