import { IconButton } from '@mui/material';
import React, { Fragment} from 'react';
import { useSelector } from 'react-redux';
import AppPermissions from '../../../Constants/Permissions/Permissions';
import { hasPermission} from "../../../Util/PermissionUtil";
import { getCompanyDocumentQuestionTypeString } from "../../../Constants/CompanyDocumentManagement/CompanyDocumentItem/CompanyDocumentQuestionType";
import ReactQuill, {Quill} from 'react-quill';

const CompanyDocumentTemplateQuestion = ({data, onEdit, onDelete, onSelected, permissionGroup}) => {

    const auth = useSelector((state) => state.auth)


    const renderQuestionData = () => {
        let result = [];
        for(let object of data){
            result.push(
                <div className='question-component' key={object.id}>
                    <div className='question-info'  onClick={() => onSelected(object)}>
                        <div className='question-name'>{object.question}</div>
                        <div className='question-update-info'>
                            <div className='text-template' dangerouslySetInnerHTML={{__html: object.description}}>

                            </div>
                        </div>
                    </div>
                    <div className='question-button-container'>
                        <div className="question-type">
                            {getCompanyDocumentQuestionTypeString(object.type)}
                        </div>
                        { hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].EDIT, auth.permissions) &&
                            <IconButton className='button' onClick={() => onEdit(object)}>
                                <img src="/images/edit-blue.svg" />
                            </IconButton>
                        }
                        { hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].DELETE, auth.permissions) &&
                            <IconButton className='button' onClick={() => onDelete(object.id)}>
                                <img src="/images/delete-blue.svg" />
                            </IconButton>
                        }
                    </div>
                </div>
            )
        }
        return result;
    }

    return (
        <Fragment>
            { data && renderQuestionData() }
        </Fragment>
    );
};

export default CompanyDocumentTemplateQuestion;
