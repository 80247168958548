import React, {useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {LinearProgress, Paper, Switch} from "@mui/material";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import {getAllCompanyAccess} from "../../../Services/User/UserCompanyAccessService";
import {getPipelineAccess, updatePipelineAccess} from "../../../Services/Company/PipelineAccessService";
import {useLocation} from "react-router-dom";
import { getClasses } from "../../../Util/SwitchUtil";
import Modules from "../../../Constants/Base/Modules";
import LoaderContext from "../../../Context/LoaderContext";

const PipelineAccessList = () => {
    const {state: {pipeline}} = useLocation();
    const dispatch = useDispatch();

    const [users, setUsers] = useState([])
    const [pipelineAccesses, setPipelineAccesses] = useState()
    const {loading, setLoading} = useContext(LoaderContext);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.administration.pipeline.pipelineAccesses.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.ADMINISTRATION},
                {label: strings.pages.administration.pipeline.pipelineList.pageTitle},
                {label: strings.pages.administration.pipeline.pipelineAccesses.pageTitle},
            ],        
        }));
    }, [])

    useEffect(() => {
        fetchPipelineAccesses();
    }, [])

    useEffect(() => {
        if (pipelineAccesses) {
            fetchUsers();
        }
    }, [pipelineAccesses])

    const getPipelineAccessAllowed = (userId) => {
        let result = false;
        for(let pipelineAccess of pipelineAccesses) {
            if(pipelineAccess.user?.id === userId) {
                result = pipelineAccess.allowed;
                break;
            }
        }
        return result;
    }

    const fetchUsers = () => {
        setLoading(true);

        getAllCompanyAccess().then(response => {
            if(!response || !response.ok) {
                return;
            }
            let users = response.data.filter(employee => !employee.owner).map(employee => ({...employee.user, allowed: getPipelineAccessAllowed(employee.user.id)}));
            setUsers(users)
            setLoading(false)
        });

    }

    const fetchPipelineAccesses = () => {
        if(!pipeline) {
            return;
        }
        setLoading(true);
        getPipelineAccess(pipeline.id).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setPipelineAccesses(response.data.result);
            setLoading(false);

        });
    }

    const accessChange = (user) => {

        setLoading(true);
        let allowed = false;
        for(let u of users) {
            if(u.id == user.id) {
                u.allowed = !u.allowed;
                allowed = u.allowed;
            }
        }

        let data = {
            pipelineId: pipeline.id,
            userId: user.id,
            allowed
        }

        updatePipelineAccess(data).then(response =>{
            if(!response || !response.ok) {
                return;
            }
            let replaced = 0;
            let copyAccesses = [...pipelineAccesses].map(a => {
                if (a.id === response.data.entity.id) {
                    a = response.data.entity;
                    replaced++;
                }
                return a;
            });
            if (!replaced) {
                copyAccesses.push(response.data.entity);
            }
            setPipelineAccesses(copyAccesses);
        });

        setLoading(false);
    }

    const renderElements = (elements) => {
        let result = [];
        for(let element of elements) {
            result.push(
                <div className='element-container' key={'element-' + result.length }>
                    <label>{ element.fullName }</label>
                    <div className='checkbox-container'>
                        <Switch
                            className={getClasses(loading, element.allowed)}
                            name='tos'
                            disabled={loading}
                            checked={element.allowed}
                            onChange={() => accessChange(element)}
                            inputProps={{
                                'aria-label': 'secondary checkbox',
                            }}
                        />
                    </div>
                </div>
            )
        }
        return result;
    }



    return (
        <div className='page-form-container'>
            <Paper className='paper mx-4'>
                <div className='paper-checkbox'>
                    <div className='paper-checkbox-container'>
                        <div className='elements'>
                            { renderElements(users) }
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    );
}

export default PipelineAccessList;