import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllTransactionCategories() {
    return await request('/api/transaction_categories/all');
}

export async function getTransactionCategoryList(data) {
    return await request('/api/transaction_categories', data);
}

export async function getTransactionCategory(id) {
    return await request('/api/transaction_categories/' + id);
}

export async function deleteTransactionCategory(id) {
    return await request('/api/transaction_categories/' + id, {}, HttpMethod.DELETE);
}

export async function editTransactionCategory(data) {
    return await request('/api/transaction_categories/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addTransactionCategory(data) {
    return await request('/api/transaction_categories', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        parentId: data.parent ? data.parent.id : null,
    }
}
