import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import TransactionCategoryForm from "../../../Components/Forms/Pages/Finance/TransactionCategory/TransactionCategoryForm";
import DrawerContext from "../../../Context/DrawerContext";
import {
    editTransactionCategory,
    getTransactionCategoryList
} from "../../../Services/Finance/TransactionCategoryService";
import LinearProgress from '@mui/material/LinearProgress';
import SnackbarContext from "../../../Context/SnackbarContext";
import { filterDropdownItem } from "../../../Util/DropdownUtil";


const formRules = {
    'name': {required: true}
}

const EditTransactionCategory = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, handleSubmit, getValues, control, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [transactionCategories, setTransactionCategories] = useState(0);

    useEffect(() => {
        setDrawerTitle(strings.pages.finance.transactionCategory.editTransactionCategory.pageTitle);
    });

    useEffect(() => {
        fetch(); 
    },[])

    const fetch = (term = '') => {
        getTransactionCategoryList({term}).then(response => {
            if (!response || !response.ok) {
                return;
            }

            setTransactionCategories(filterDropdownItem(response.data.result, props.data[0]?.id));
        });
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        editTransactionCategory(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <TransactionCategoryForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                control={control}
                onChangeParentFilter={fetch}
                transactionCategories = {transactionCategories}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditTransactionCategory;