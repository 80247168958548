import store from "../store";

export const isCompanyValid = (company) => {

    if(!company) {
        return false;
    }

    if(!company.name || !company.registrationNumber || !company.registrationNumber
        || !company.taxNumber || !company.address || !company.zipCode || !company.zipCode
        || !company.country || !company.currency || !company.city) {
        return false;
    }

    return true;
}

export const isCompanyS3SettingsExists = () => {
    let { companyS3Settings } = store.getState().auth;
    return !companyS3Settings || Object.keys(companyS3Settings).find(key => companyS3Settings[key].length===0)
}