export const EInvoiceOutStatus = {
    NEW: 'New',
    DRAFT: 'Draft',
    SENT: 'Sent',
    PAID: 'Paid',
    MISTAKE: 'Mistake',
    OVER_DUE: 'OverDue',
    ARCHIVED: 'Archived',
    SENDING: 'Sending',
    DELETED: 'Deleted',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
    CANCELLED: 'Cancelled',
    STORNO: 'Storno',
    UNKNOWN: 'Unknown'
}