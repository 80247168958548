import {Pie} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Grid} from "@mui/material";

const calculatePercentage = (value, total) => parseFloat((value/total*100).toFixed(2));

const noData = {
    id: 'noData',
    afterDraw: function (chart) {
        if (chart.data.datasets[0]?.data.reduce((total, value) => total + value, 0) === 0) {
            let ctx = chart.ctx;
            let width = chart.width;
            let height = chart.height;
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.font = "30px Plus Jakarta Display";
            ctx.fillText("No data to display", width / 2, height / 2);
            ctx.restore();
        }
    },
}


const PieChart = ({inputData, options: newOptions}) => {
    const options = {
        plugins: {
            // https://stackoverflow.com/a/52044118
            datalabels: {
                formatter: (value, ctx) => {
                    let dataArr = inputData.datasets[0]?.data;
                    let sum = dataArr.reduce((total, value) => total + value, 0);
                    let percentage = calculatePercentage(value, sum) || 0;
                    if ((sum > 0 && percentage === 0) || sum === 0) {
                        return '';
                    }
                    return `${value}h\n(${percentage}%)`;

                },
            },
            tooltip: {
                // https://stackoverflow.com/a/49717859
                callbacks: {
                    label: (context) => {
                        let data = context.dataset.data;
                        let label = context.label;
                        let currentValue = context.raw;
                        let total = 0;

                        for( let i = 0; i < data.length; i++ ){
                            total += data[i];
                        }
                        const percentage = calculatePercentage(currentValue, total) || 0;

                        return `${label}: ${currentValue}h (${percentage}%)`;
                    }
                }
            },
            legend: {
                display: false,
            },
        },
    };
    const [tmpData, setTmpData] = useState(null);
    const [showOptions, setShowOptions] = useState(() => {
        return inputData.labels.map((label, index) => true);
    });


    useEffect(() => {
        setTmpData(inputData);
    }, [inputData]);

    const changeShow = (e) => {
        const idArr = e.target.id.split('-');
        const id = parseInt(idArr[idArr.length - 1]);

        let showTmp = [...showOptions];
        showTmp[id] = !showTmp[id];

        const dataRes = tmpData.datasets[0]?.data.map((d, index) => showTmp[index] ? inputData.datasets[0]?.data[index] : 0);
        setTmpData((prev) => ({
            ...prev,
            datasets: [
                    {
                        ...prev.datasets[0],
                        data: dataRes,
                    }
                ] 
            }),
        )
        setShowOptions(showTmp);
    }


    return <React.Fragment>
            <div className = 'chart-legend'>
                {
                    inputData && inputData.labels.map((label, index) => 
                        <FormControlLabel 
                            key = {'piechart-checkbox' + index}
                            className = 'one-liner'
                            control = {
                            <Checkbox 
                                id = {`${label}-${index}`}
                                size = {'small'}
                                checked = {showOptions[index]}
                                sx = {{
                                    color: inputData.datasets[0]?.backgroundColor[index],
                                    '&.Mui-checked': {
                                        color: inputData.datasets[0]?.backgroundColor[index],
                                    }
                                }}
                                disabled = {Object.keys(showOptions).length === 1 || inputData.datasets[0]?.data.reduce((total, value) => total + value, 0) === 0}
                                onChange = {changeShow} 
                            />
                        } 
                            label = {label} 
                        />
                    )
                }
            </div>
            { tmpData && 
                <div className = 'chart'>
                    <Grid container alignItems = 'center' justifyContent = 'center'>
                        <Grid item xs = {6} className = 'grid-pie-chart'>
                            <Pie 
                                plugins={[ChartDataLabels, noData]} 
                                data={tmpData}
                                options={{...options, ...newOptions}}
                            />
                        </Grid>
                    </Grid>
                </div>
            }
        </React.Fragment>
};

export default PieChart;
