import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getTaskTemplateStages(data){
    return await request('/api/task-template/stages', data);
}

export async function addTaskTemplate(data){
    return await request('/api/task-templates', transformRequestData(data), HttpMethod.POST);
}

export async function updateTaskTemplateStage(data){
    return await request('/api/task-template/stages', data, HttpMethod.PUT);
}

export async function editTaskTemplate(data){
    return await request('/api/task-templates/' + data.id , transformRequestData(data), HttpMethod.PUT);
}

export async function getAllTaskTemplates(){
    return await request('/api/task-templates/all', {});
}

export async function deleteTaskTemplate(id){
    return await request('/api/task-templates/' + id, {}, HttpMethod.DELETE)
}

function transformRequestData(data){
    return{
        ...data,
        pipelineId: data.pipeline ? data.pipeline.id : null,
        pipelineStageId: data.pipelineStage ? data.pipelineStage.id : null
    }
}