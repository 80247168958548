import React, { useEffect, useState, useContext } from "react";
import { IconButton } from "@mui/material";
import {
  getDecisionByBusinessTrip,
  getCalculationByBusinessTrip,
  deleteDecision,
  deleteCalculation,
  getBusinessTripDailyWages,
} from "../../../Services/HR/BusinessTripService";
import { getCurrentCompany } from "../../../Services/Company/CompanyService";
import { getAllUserCompanyAccess } from "../../../Services/User/UserCompanyAccessService";
import { useParams } from "react-router-dom";
import { Drawer } from "@mui/material";
import AddDecision from "../BusinessTripDecision/AddDecision";
import AddCalculation from "../BusinessTripCalculation/AddCalculation";
import { Paper } from "@mui/material";
import { Tab } from "@mui/material";
import { Tabs } from "@mui/material";
import { changeBreadcrumbs } from "../../../Slices/BreadcrumbsSlice";
import { useDispatch } from "react-redux";
import DecisionList from "../BusinessTripDecision/DecisionList";
import CalculationList from "../BusinessTripCalculation/CalculationList";
import AddTravelWarrant from "../BusinessTripTravelWarrant/AddTravelWarrant";
import TravelWarrantList from "../BusinessTripTravelWarrant/TravelWarrantList";
import DrawerWrapper from "../../../Components/Layout/DrawerWrapper";
import strings from "../../../localization";
import Modules from "../../../Constants/Base/Modules";
import LoaderContext from "../../../Context/LoaderContext";
import SnackbarContext from "../../../Context/SnackbarContext";

const BusinessTripInformation = () => {
  const dispatch = useDispatch();
  const { setLoading } = useContext(LoaderContext);
  const { showMessage } = useContext(SnackbarContext);
  const [showDecisionDetails, setShowDecisionDetails] = useState(true);
  const [showTravelWarrantDetails, setshowTravelWarrantDetails] =
    useState(false);
  const [showCalculationDetails, setShowCalculationDetails] = useState(false);

  const [disableCalculation, setdisableCalculation] = useState(true);
  const [disableTravelWarrant, setdisableTravelWarrant] = useState(true);
  const [disableDecision, setdisableDecision] = useState(false);

  const [showDecisionForm, setShowDecisionForm] = useState(false);
  const [showTravelWarrantForm, setShowTravelWarrantForm] = useState(false);
  const [showCalculationForm, setShowCalculationForm] = useState(false);

  const [decisionData, setDecisionData] = useState([]);
  const [calculationData, setCalculationData] = useState([]);

  const [deleted, setDeleted] = useState(false);
  const [edited, setEdited] = useState(false);

  const [users, setUsers] = useState([]);
  const [company, setCompany] = useState();

  const [domesticCurrency, setDomesticCurrency] = useState();

  const [foreignDailyWageCurrency, setForeignDailyWageCurrency] = useState();
  const [domesticDailyWage, setDomesticDailyWage] = useState(0);
  const [foreignDailyWage, setForeignDailyWage] = useState(0);
  const businessTrip = useParams("id");
  const id = parseInt(businessTrip.id);

  const onDeleteDecision = () => {
    decisionData.map((decision) => {
      deleteDecision(decision.id).then((response) => {
        if (!response || !response.ok) {
          showMessage(strings.commonMessages.errorDeleting, "error");
          return;
        }
        setDeleted(!deleted);
        showMessage(strings.commonMessages.deleteSuccessful, "success");
      });
    });
    calculationData.map((calculation) => {
      deleteCalculation(calculation.id).then((response) => {
        if (!response || !response.ok) {
          return;
        }
        setDeleted(!deleted);
      });
    });
  };
  const onDeleteTravelWarrant = () => {
    deleteDecision(decisionData[1].id).then((response) => {
      if (!response || !response.ok) {
        showMessage(strings.commonMessages.errorDeleting, "error");
        return;
      }
      setDeleted(!deleted);
      showMessage(strings.commonMessages.deleteSuccessful, "success");
    });
    calculationData.map((calculation) => {
      deleteCalculation(calculation.id).then((response) => {
        if (!response || !response.ok) {
          return;
        }
        setDeleted(!deleted);
      });
    });
  };
  const onDeleteCalculation = () => {
    setDeleted(!deleted);
  };

  const onEdit = (id) => {
    setEdited(!edited);
  };

  const fetchCompany = () => {
    getCurrentCompany().then((response) => {
      if (!response || !response.ok) {
        return;
      }
      setCompany(response.data.id);
      setDomesticCurrency(response.data.currency);
    });
  };

  const fetchUsers = () => {
    getAllUserCompanyAccess(company).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      response.data.map((data) => setUsers(data.user));
    });
  };

  const getDecisionData = (id) => {
    getDecisionByBusinessTrip(id).then((response) => {
      if (!response || !response.ok) {
        return;
      }
      setDecisionData(response.data);
    });
  };

  const getCalculationData = (id) => {
    getCalculationByBusinessTrip(id).then((response) => {
      if (!response || !response.ok) {
        return;
      }
      setCalculationData(response.data);
    });
  };

  const getBusinessTripDailyWage = (company) => {
    getBusinessTripDailyWages(company).then((response) => {
      setForeignDailyWage(response.data.entity.foreignDailyWage);
      setDomesticDailyWage(response.data.entity.domesticDailyWage);
      setForeignDailyWageCurrency(
        response.data.entity.foreignDailyWageCurrency
      );
    });
  };

  useEffect(() => {
    dispatch(
      changeBreadcrumbs({
        title: strings.pages.hr.businessTrip.businessTrip.pageTitle,
        hierarchy: [
          { label: strings.navigation.managmentTag },
          { label: Modules.HR },
          { label: strings.pages.hr.businessTrip.businessTrip.pageTitle },
        ],
      })
    );
  }, []);

  useEffect(() => {
    fetchCompany();
    fetchUsers();
    getDecisionData(id);
    getCalculationData(id);
    getBusinessTripDailyWage(company);
  }, [
    deleted,
    edited,
    showDecisionForm,
    showTravelWarrantForm,
    showCalculationForm,
  ]);

  useEffect(() => {
    setLoading(true);
    getDecisionByBusinessTrip(id).then((response) => {
      setLoading(false);
    });
  }, [showDecisionDetails]);

  useEffect(() => {
    setLoading(true);
    getDecisionByBusinessTrip(id).then((response) => {
      setLoading(false);
    });
  }, [showTravelWarrantDetails]);

  useEffect(() => {
    setLoading(true);
    getCalculationByBusinessTrip(id).then((response) => {
      setLoading(false);
    });
  }, [showCalculationDetails]);

  const openDecisionForm = () => {
    setShowDecisionForm(true);
  };
  const closeDecisionForm = () => {
    setShowDecisionForm(false);
  };

  const openDecisionDetails = () => {
    setdisableDecision(false);
    setdisableCalculation(true);
    setdisableTravelWarrant(true);
    setshowTravelWarrantDetails(false);
    setShowCalculationDetails(false);
    setShowDecisionDetails(true);
  };

  const openCalculationDetails = () => {
    setdisableCalculation(false);
    setdisableTravelWarrant(true);
    setdisableDecision(true);
    setshowTravelWarrantDetails(false);
    setShowDecisionDetails(false);
    setShowCalculationDetails(true);
  };

  const openTravelWarrantDetails = () => {
    setdisableTravelWarrant(false);
    setdisableDecision(true);
    setdisableCalculation(true);
    setShowCalculationDetails(false);
    setShowDecisionDetails(false);
    setshowTravelWarrantDetails(true);
  };

  const openCalculationForm = () => {
    setShowCalculationForm(true);
  };
  const closeCalculationForm = () => {
    setShowCalculationForm(false);
  };
  const openTravelWarrantForm = () => {
    setShowTravelWarrantForm(true);
  };
  const closeTravelWarrantForm = () => {
    setShowTravelWarrantForm(false);
  };

  return (
    <div id="business-trip-list-page">
      <div>
        <div id="hr-settings">
          <div className="tab-container mb-12 mx-3">
            <Paper className="paper">
              <Tabs variant="scrollable">
                <Tab
                  onClick={openDecisionDetails}
                  label={
                    strings.pages.hr.businessTrip.businessTripInformation
                      .decision
                  }
                  className={!disableDecision ? "disabled" : ""}
                />
                <Tab
                  onClick={openTravelWarrantDetails}
                  label={
                    strings.pages.hr.businessTrip.businessTripInformation
                      .travelWarrant
                  }
                  className={!disableTravelWarrant ? "disabled" : ""}
                  disabled={decisionData.length == 0}
                />
                <Tab
                  onClick={openCalculationDetails}
                  label={
                    strings.pages.hr.businessTrip.businessTripInformation
                      .calculation
                  }
                  className={!disableCalculation ? "disabled" : ""}
                  disabled={decisionData.length < 2}
                />
              </Tabs>
            </Paper>
          </div>
        </div>

        <Drawer id="drawer" anchor="right" open={showCalculationForm}>
          <DrawerWrapper
            onBack={() => closeCalculationForm()}
            title={strings.pages.hr.businessTrip.addCalculation.pageTitle}
          >
            <AddCalculation
              onFinish={() => closeCalculationForm()}
              travelWarrant={decisionData[1]}
            />
          </DrawerWrapper>
        </Drawer>

        <Drawer id="drawer" anchor="right" open={showDecisionForm}>
          <DrawerWrapper
            onBack={() => closeDecisionForm()}
            title={strings.pages.hr.businessTrip.addDecision.pageTitle}
          >
            <AddDecision
              onFinish={() => closeDecisionForm()}
              domesticCurrency={domesticCurrency}
              fetchUsers={fetchUsers}
              users={users}
              foreignDailyWage={foreignDailyWage}
              domesticDailyWage={domesticDailyWage}
              foreignDailyWageCurrency={foreignDailyWageCurrency}
            />
          </DrawerWrapper>
        </Drawer>

        <Drawer id="drawer" anchor="right" open={showTravelWarrantForm}>
          <DrawerWrapper
            onBack={() => closeTravelWarrantForm()}
            title={strings.pages.hr.businessTrip.addTravelWarrant.pageTitle}
          >
            <AddTravelWarrant
              travelWarrant={decisionData[0]}
              onFinish={() => closeTravelWarrantForm()}
            />
          </DrawerWrapper>
        </Drawer>

        {showDecisionDetails && (
          <div>
            <div className="add-button-container">
              <IconButton
                className="add-button"
                onClick={openDecisionForm}
                disabled={decisionData.length}
              >
                <img src="/images/table-page/add-cross.svg" />
                {strings.pages.hr.businessTrip.addDecision.pageTitle}
              </IconButton>
            </div>

            {decisionData.length == 0 ? null : decisionData.length == 1 ? (
              <DecisionList
                decision={decisionData[0]}
                onDelete={onDeleteDecision}
                onEdit={onEdit}
              />
            ) : (
              <DecisionList
                decision={decisionData[1]}
                onDelete={onDeleteDecision}
                onEdit={onEdit}
                travelWarrant={decisionData[0]}
              />
            )}
          </div>
        )}
        {showTravelWarrantDetails && (
          <div>
            <div className="add-button-container">
              <IconButton
                className="add-button"
                onClick={openTravelWarrantForm}
                disabled={decisionData.length > 1}
              >
                <img src="/images/table-page/add-cross.svg" />
                {strings.pages.hr.businessTrip.addTravelWarrant.pageTitle}
              </IconButton>
            </div>
            {decisionData.length > 1 ? (
              <TravelWarrantList
                travelWarrant={decisionData[0]}
                onDelete={onDeleteTravelWarrant}
                onFinish={() => closeTravelWarrantForm()}
              />
            ) : null}
          </div>
        )}
        {showCalculationDetails && (
          <div>
            <div className="add-button-container">
              <IconButton
                className="add-button"
                onClick={openCalculationForm}
                disabled={calculationData.length > 0}
              >
                <img src="/images/table-page/add-cross.svg" />
                {strings.pages.hr.businessTrip.addCalculation.pageTitle}
              </IconButton>
            </div>
            {calculationData.length > 0 ? (
              <CalculationList
                calculation={calculationData[0]}
                onDelete={onDeleteCalculation}
                onFinish={() => closeCalculationForm()}
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessTripInformation;
