import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import LinearProgress from '@mui/material/LinearProgress';
import CompanyDocumentTemplateItemForm
    from "../../../Components/Forms/Pages/CompanyDocument/CompanyDocumentTemplateItem/CompanyDocumentTemplateItemForm";
import {editCompanyDocumentTemplateItem} from "../../../Services/CompanyDocumentManagement/CompanyDocumentTemplateItemService";
import {useLocation} from "react-router-dom";
import {getCompanyDocumentItemTypes} from "../../../Constants/CompanyDocumentManagement/CompanyDocumentItem/CompanyDocumentItemType";
import {getCompanyDocumentReferenceTypes} from "../../../Constants/CompanyDocumentManagement/CompanyDocumentItem/CompanyDocumentReferenceType";
import {getCompanyDocumentReferenceUserValueTypes} from "../../../Constants/CompanyDocumentManagement/CompanyDocumentItem/CompanyDocumentReferenceValueTypes/CompanyDocumentReferenceUserValueType";
import SnackbarContext from "../../../Context/SnackbarContext";

const formRules = {
    'name': {required: true},
    'type': {required: true},
    'referenceType': {required: false},
    'referenceValueType': {required: false},
}
const EditCompanyDocumentTemplateItem = (props) => {

    const { state } = useLocation();
    const companyDocumentItemTypes = getCompanyDocumentItemTypes();
    const companyDocumentReferenceTypes = getCompanyDocumentReferenceTypes();
    const companyDocumentReferenceValueTypes = getCompanyDocumentReferenceUserValueTypes();

    const findType = (item, collection, attr) => collection.find(t => item[attr] === t.value)

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? {
            ...props.data[0],
            type: findType(props.data[0], companyDocumentItemTypes, 'type'),
            referenceType: findType(props.data[0], companyDocumentReferenceTypes, 'referenceType'),
            referenceValueType: findType(props.data[0], companyDocumentReferenceValueTypes, 'referenceValueType'),
        } : undefined
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);



    useEffect(() => {
        setDrawerTitle(strings.pages.companyDocument.companyDocumentTemplateItem.editCompanyDocumentTemplateItem.pageTitle);
    });

    const onSubmit = (data) => {

        setShowLoader(true);

        editCompanyDocumentTemplateItem({
            companyDocumentTemplateId: state.template,
            ...data}).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <CompanyDocumentTemplateItemForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditCompanyDocumentTemplateItem;