import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";

const BankReportForm = ({
                         onSubmit,
                         onCancel,
                         data,
                         form,
                         errors,
                         bankData,
                         bankAccountsData,
                         currenciesData,
                         businessYearsData,
                         values,
                         setValue,
                         formRules,
                         control,

                         onChangeBankAccountFilter
                     }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='bank-report-form' className='form' action="#">

                <AutoCompleteControl
                    name='bank'
                    label={strings.forms.finance.bankReport.bank}
                    rules={formRules['bank']}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={setValue}
                    control={control}
                    options={bankData}
                    value={values['bank']}
                    error={Boolean(errors.bank)}
                    helperText={errors.bank && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    name='bankAccount'
                    rules={formRules['bankAccount']}
                    label={strings.forms.finance.bankReport.bankAccount}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={setValue}
                    onChange={onChangeBankAccountFilter}
                    onOpen={() =>onChangeBankAccountFilter('')}
                    control={control}
                    options={bankAccountsData}
                    value={values['bankAccount']}
                    error={Boolean(errors.bankAccount)}
                    helperText={errors.bankAccount && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={values['currency']}
                    setValue={setValue}
                    rules={formRules['currency']}
                    name='currency'
                    control={control}
                    error={Boolean(errors.currency)}
                    helperText={errors.currency && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.bankReport.currency}
                    options={currenciesData}
                    nameKey={'code'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={values['businessYear']}
                    setValue={setValue}
                    rules={formRules['businessYear']}
                    name='businessYear'
                    control={control}
                    error={Boolean(errors.businessYear)}
                    helperText={errors.businessYear && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.bankReport.businessYear}
                    options={businessYearsData}
                    nameKey={'year'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='reportId'
                    rules={formRules['reportId']}
                    control={data}
                    error={Boolean(errors.reportId)}
                    helperText={errors.reportId && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.bankReport.report}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='reportNumber'
                    rules={formRules['reportNumber']}
                    control={data}
                    error={Boolean(errors.reportNumber)}
                    helperText={errors.reportNumber && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.bankReport.reportNumber}
                    disabled={isPageStateViewDetails()}
                />

                <DateTimeControl
                    data={data}
                    label={strings.forms.finance.bankReport.reportDate}
                    name={'reportDate'}
                    value={values['reportDate']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />


                <TextFieldControl
                    name='amountBefore'
                    rules={formRules['amountBefore']}
                    control={data}
                    type='number'
                    error={Boolean(errors.amountBefore)}
                    helperText={errors.amountBefore && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.bankReport.amountBefore}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='amountAfter'
                    rules={formRules['amountAfter']}
                    control={data}
                    type='number'
                    error={Boolean(errors.amountAfter)}
                    helperText={errors.amountAfter && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.bankReport.amountAfter}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='income'
                    rules={formRules['income']}
                    control={data}
                    type='number'
                    error={Boolean(errors.income)}
                    helperText={errors.income && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.bankReport.income}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='outcome'
                    rules={formRules['outcome']}
                    control={data}
                    type='number'
                    error={Boolean(errors.outcome)}
                    helperText={errors.outcome && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.bankReport.outcome}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }

            </form>
        </FormProvider>
    );
}

export default BankReportForm;