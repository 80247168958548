import React, {useContext, useEffect, useState} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import strings from '../../../../../localization';
import FileUploadComponent from '../../../../Controls/FileUploadComponent';
import SelectControl from '../../../../Controls/Inputs/SelectControl';
import TextFieldControl from '../../../../Controls/Inputs/TextFieldControl';
import TablePageContext from "../../../../../Context/TablePageContext";
import {toBase64} from "../../../../../Util/ImageUtil";
import Button from '@mui/material/Button';
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from '../../../../Controls/Inputs/AutoCompleteControl';

const DocumentForm = ({   
                          onSubmit,
                          onCancel,
                          data,
                          setValue,
                          values,
                          form,
                          errors,
                          formRules,
                          documentStatuses,
                          documentCategories,
                          onChangeDocumentCategoryFilter,
                          onChangeDocumentStatusFilter
                      }) => {
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [files, setFiles] = useState([]);
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const isPageStateAdd = () => pageState === PageState.Add;

    const uploadImage = async (files) => {

        setDisabledSubmit(true)

        const file = files[0];

        setValue('file', file);
        setDisabledSubmit(false);
        if (!form.getValues().name || form.getValues().name === '') {
            setValue('name', files[0].name);
        }
    }

    return(
        <FormProvider {...form}>
            <form id="document-form" className='drawer-form' action = "#">

                    <TextFieldControl
                        name='name'
                        rules={formRules['name']}
                        control={data}
                        value={values}
                        error={Boolean(errors.name)}
                        helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                        label={strings.forms.document.document.name}
                        disabled={isPageStateViewDetails()}
                    />

                    <AutoCompleteControl
                        value={values['documentCategory']}
                        setValue={setValue}
                        name='documentCategory'
                        label={strings.forms.document.document.documentCategory}
                        options={documentCategories}
                        nameKey={'name'}
                        valueKey={'id'}
                        disabled={isPageStateViewDetails()}
                        onChange={onChangeDocumentCategoryFilter}
                        onOpen={() =>onChangeDocumentCategoryFilter('')}
                    />

                    <AutoCompleteControl
                        value={values['documentStatus']}
                        setValue={setValue}
                        name='documentStatus'
                        label={strings.forms.document.document.documentStatus}
                        options={documentStatuses}
                        nameKey={'name'}
                        valueKey={'id'}
                        disabled={isPageStateViewDetails()}
                        onChange={onChangeDocumentStatusFilter}
                        onOpen={() =>onChangeDocumentStatusFilter('')}
                    />

                    {
                        isPageStateAdd() &&
                        <FileUploadComponent hideUploadButton={true} onDrop={uploadImage} files = {files} setFiles = {setFiles} />
                    }

                    {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" disabled={disabledSubmit} onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
                
            </form>
        </FormProvider>
    );
}

export default DocumentForm;
