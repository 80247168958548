import strings from "../../localization";

const PaymentType = {
    INCOME: 1,
    OUTCOME: 2
}

export function getPaymentTypes() {
    return [
        {
            id: PaymentType.INCOME,
            name: getPaymentTypeString(PaymentType.INCOME)
        },
        {
            id: PaymentType.OUTCOME,
            name: getPaymentTypeString(PaymentType.OUTCOME)
        }
    ];
}

export function getPaymentTypeString(paymentType) {
    switch(paymentType) {
        case PaymentType.INCOME: return strings.constants.finance.paymentType.income;
        case PaymentType.OUTCOME: return strings.constants.finance.paymentType.outcome;
    }
}

export default PaymentType;