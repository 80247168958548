import React from "react";
import {useEffect, useState } from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import {deleteProject, getProjectList} from "../../../Services/Project/ProjectService";
import EditProject from "./EditProject";
import AddProject from "./AddProject";
import {formatColumnDate, formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppPermissions from "../../../Constants/Permissions/Permissions";
import { getPartners, getPartner } from "../../../Services/CRM/PartnerService";
import {fetchOptions} from '../../../Services/Base/FilterOptionsService';
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [
    {field: 'name', headerName: strings.pages.project.project.projectList.name, width: 250},
    {field: 'startDate', headerName: strings.pages.project.project.projectList.startDate, valueFormatter: (params) => formatColumnDate(params), width: 250},
    {field: 'endDate', headerName: strings.pages.project.project.projectList.endDate, valueFormatter: (params) => formatColumnDate(params), width: 250},
    {field: 'projectCategory', headerName: strings.pages.project.project.projectList.projectCategory,
        valueFormatter: (params) => formatColumnObject(params, 'name'), width: 250},
    {field: 'partner', headerName: strings.pages.project.project.projectList.partner,
        valueFormatter: (params) => formatColumnObject(params, 'name'), width: 250},
];

const ProjectList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState({
        ...TablePageOptionDefaults,
        showYearFilter: true,
        showMonthFilter: true
    });
    const referenceType = ReferenceType.PROJECT;
    const permissionGroup = AppPermissions.PROJECT.GROUP;
    const [filterOptions, setFilterOptions] = useState({});

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    const fetchPartners = async (term, filterFetch) => {
        return fetchOptions('partner', searchParams, getPartners, getPartner, setFilterOptions, term, filterFetch);
    }

    const fetchFilters = async() => {
        const partners = await fetchPartners();

        setFilterOptions({
            partner: partners,
            fetched: 1,
        });
    }

    const filters = [
        {
            name: 'partner',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'partner',
            label: strings.pages.project.project.projectList.partner,
            onChange: (term) => fetchPartners(term, true),
        }
    ]
    

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.project.project.projectList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.PROJECTS},
                {label: strings.pages.project.project.projectList.pageTitle},
            ],        
        }));

        fetchFilters();

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const handleView = (project) => {
        navigate(`/project-details/${project.id}/project-time-log`);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getProjectList({
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteProject} tableDescription={columns}
                   tableData={tableData}
                   filter={filter} filters={filters} filterOptions={filterOptions}
                   permissionGroup={permissionGroup}
                   referenceType={referenceType} handleView={handleView}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditProject data={ selectedItems }
                                          onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addPage={<AddProject onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addButtonText={strings.components.tablePage.addButton.project}/>
    </TablePageContext.Provider>;
}

export default ProjectList;
