import React from 'react';
import strings from '../../../../localization';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import { Link } from 'react-router-dom';

const ResetPasswordRequestForm = ({
                       onSubmit,
                       errors,
                       data,
                       form,
                       formRules,
                   }) => (
    <FormProvider {...form}>
        <form className='form' onSubmit={ onSubmit } action = "#">

            <TextFieldControl
                name='email'
                control={data}
                defaultValue=''
                rules={formRules['email']}
                fullWidth
                margin="normal"
                error={ Boolean(errors.email) }
                helperText={errors.email && errors.email.message}
                label={ strings.forms.resetPasswordRequest.email }
                placeholder={strings.forms.resetPasswordRequest.emailPlaceholder}
            />

            <div className='send'>
                <Button variant="contained" color="primary" onClick={ onSubmit }>
                    { strings.forms.resetPasswordRequest.send }
                </Button>
            </div>
            <div className='log-in'>
                <div className='text'>{strings.forms.resetPasswordRequest.haveAnAccount}</div>
                <Link to={'/login'}>{strings.forms.resetPasswordRequest.login}</Link>
            </div>
        </form>
    </FormProvider>
);

export default ResetPasswordRequestForm;
