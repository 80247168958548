import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getDocumentCategory(id) {
    return await request('/api/document-categories/' + id);
}

export async function getDocumentCategories(data) {
    return await request('/api/document-categories', data);
}

export async function getAllDocumentCategories() {
    return await request('/api/document-categories/all');
}

export async function addDocumentCategory(data) {
    return await request('/api/document-categories', transformRequestData(data), HttpMethod.POST);
}

export async function editDocumentCategory(data) {
    return await request('/api/document-categories/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteDocumentCategory(id) {
    return await request('/api/document-categories/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        parentId: data.parent ? data.parent.id : null
    }
}