import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getNonWorkingDaysList(data) {
    return await request('/api/non-working-days', data);
}

export async function getNonWorkingDays(id) {
    return await request('/api/non-working-days/' + id);
}

export async function deleteNonWorkingDays(id) {
    return await request('/api/non-working-days/' + id, {}, HttpMethod.DELETE);
}

export async function editNonWorkingDays(data) {
    return await request('/api/non-working-days/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addNonWorkingDays(data) {
    return await request('/api/non-working-days', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data
    }
}

