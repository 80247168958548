import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getSpaceFoldersList(id) {
    return await request('/api/space-folders', transformRequestFoldersListData(id));
}

export async function addSpaceFolder(data) {
    return await request('/api/space-folder', transformRequestData(data), HttpMethod.POST);
}

export async function editSpaceFolder(data) {
    return await request('/api/space-folder/' + data.id, data, HttpMethod.PUT);
}

export async function deleteSpaceFolder(id) {
    return await request('/api/space-folder/' + id, {}, HttpMethod.DELETE);
}

function transformRequestFoldersListData(id) {
    return {
        parent: id ? id : null,
    }
}

function transformRequestData(data) {
    return {
        ...data,
        parentId: data.parent?.id ? data.parent.id : data.parent,
    }
}