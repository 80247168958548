import React, {useContext, useState} from "react";
import {useForm} from "react-hook-form";
import {Grid, Paper} from "@mui/material";
import strings from "../../localization";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import ResetPasswordRequestForm from "../../Components/Forms/Pages/User/ResetPasswordRequestForm";
import {addProjectCategory} from "../../Services/Project/ProjectCategoryService";
import {resetPasswordRequest} from "../../Services/User/UserService";
import {useNavigate} from "react-router-dom";
import SnackbarContext from "../../Context/SnackbarContext";
import ValidationPatters from "../../Constants/Base/ValidationPatters";

const formRules = {
    'email': {required: { value: true, message: strings.forms.common.thisFieldIsRequired},
        pattern: { value: ValidationPatters.EMAIL, message: strings.forms.common.emailFormatError }},
}

const ResetPasswordRequest = () => {


    const form = useForm();
    const { data, handleSubmit, formState: { errors } } = form;
    const navigate = useNavigate();
    const { showMessage } = useContext(SnackbarContext);

    const dispatch = useDispatch();
    dispatch(changePageSizeState(PageSizeState.FULL_NO_HEADER));

    const onSubmit = (data) => {
        resetPasswordRequest(data).then(response => {

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorSent, 'error');
                return;
            }

            showMessage(strings.commonMessages.sent, 'success');
            navigate('/login');
        });
    }

    return <div id='reset-password-request-page'>
        <div className="color-overlay"></div>
        <Paper>

            <h5>{ strings.pages.resetPasswordRequest.resetPassword }</h5>

            <ResetPasswordRequestForm formRules={formRules} errors={ errors } data={ data } form={ form } onSubmit={ handleSubmit(onSubmit) }/>
        </Paper>
    </div>
};
export default ResetPasswordRequest;
