import React, {useContext, useState} from "react";
import { FormProvider } from "react-hook-form";
import strings from "../../../../../localization";
import Button from '@mui/material/Button';
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import CheckBoxControl from "../../../../Controls/Inputs/CheckBoxControl";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";
import QuillEditor from "../../../../Controls/Inputs/QuillEditor";

const CandidateForm = ({
                            onSubmit,
                            onCancel,
                            data,
                            form,
                            errors,
                            values,
                            setValue,
                            formRules,
                            jobs,
                            candidateSources,
                            candidateStatuses,
                            users,
                            control,
                            onChangeJobFilter,
                            onChangeCandidateSource,
                            onChangeCandidateStatuses
                    }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const [editorData, setEditorData] = useState('');

    return (
        <FormProvider {...form}>
            <form id='candidate-form' className='form' action="#">
                <TextFieldControl
                    name='firstName'
                    rules={formRules['firstName']}
                    control={data}
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.firstName}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='lastName'
                    rules={formRules['lastName']}
                    control={data}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.lastName}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='email'
                    rules={formRules['email']}
                    control={data}
                    error={Boolean(errors.email)}
                    helperText={errors.email && strings.forms.common.emailFormatError}
                    label={strings.forms.hr.candidate.email}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='location'
                    rules={formRules['location']}
                    control={data}
                    error={Boolean(errors.location)}
                    helperText={errors.location && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.location}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='phone'
                    rules={formRules['phone']}
                    control={data}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.phone}
                    disabled={isPageStateViewDetails()}
                />
                <QuillEditor
                    name='description'
                    className='large-editor'
                    form={form}
                    value={editorData}
                    onChange={setEditorData}
                    placeholder={'Description'}
                />
                <TextFieldControl
                    name='currentPosition'
                    rules={formRules['currentPosition']}
                    control={data}
                    error={Boolean(errors.currentPosition)}
                    helperText={errors.currentPosition && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.currentPosition}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='currentCompany'
                    rules={formRules['currentCompany']}
                    control={data}
                    error={Boolean(errors.currentCompany)}
                    helperText={errors.currentCompany && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.currentCompany}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='currentSalary'
                    rules={formRules['currentSalary']}
                    control={data}
                    type='number'
                    error={Boolean(errors.currentSalary)}
                    helperText={errors.currentSalary && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.currentSalary}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='expectedSalary'
                    rules={formRules['expectedSalary']}
                    control={data}
                    type='number'
                    error={Boolean(errors.expectedSalary)}
                    helperText={errors.expectedSalary && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.expectedSalary}
                    disabled={isPageStateViewDetails()}
                />
                <DateTimeControl
                    data={data}
                    label={strings.forms.hr.candidate.birthday}
                    name={'birthday'}
                    value={values['birthday']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='education'
                    rules={formRules['education']}
                    control={data}
                    error={Boolean(errors.education)}
                    helperText={errors.education && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.education}
                    disabled={isPageStateViewDetails()}
                />
                <AutoCompleteControl
                    value={values['job']}
                    setValue={setValue}
                    name='job'
                    control={control}
                    label={strings.forms.hr.candidate.job}
                    options={jobs}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onChangeJobFilter}
                    onOpen={() =>onChangeJobFilter('')}
                />
                <AutoCompleteControl
                    value={values['candidateSource']}
                    setValue={setValue}
                    name='candidateSource'
                    control={control}
                    label={strings.forms.hr.candidate.candidateSource}
                    options={candidateSources}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onChangeCandidateSource}
                    onOpen={() =>onChangeCandidateSource('')}
                />
                <AutoCompleteControl
                    value={values['candidateStatus']}
                    setValue={setValue}
                    name='candidateStatus'
                    control={control}
                    label={strings.forms.hr.candidate.candidateStatus}
                    options={candidateStatuses}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onChangeCandidateStatuses}
                    onOpen={() =>onChangeCandidateStatuses('')}
                /> 
                <AutoCompleteControl
                    value={values['responsibleHrManager']}
                    setValue={setValue}
                    name='responsibleHrManager'
                    control={control}
                    label={strings.forms.hr.candidate.responsibleHrManager}
                    options={users}
                    rules={formRules['responsibleHrManager']}
                    error={Boolean(errors.responsibleHrManager)}
                    helperText={errors.responsibleHrManager && strings.forms.common.thisFieldIsRequired}
                    nameKey={'fullName'}
                    valueKey={'id'}
                /> 
                <CheckBoxControl
                    name={'noPreviousExperience'}
                    control={data}
                    label={strings.forms.hr.candidate.noPreviousExperience}
                    value={values['noPreviousExperience']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default CandidateForm;