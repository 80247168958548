import React, {useContext, useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";


const ProductServiceForm = ({
                                onSubmit,
                                onCancel,
                                data,
                                form,
                                errors,
                                values,
                                setValue,
                                formRules,
                                control,
                                employees,
                                partners,
                                templates,
                                locations,
                                onChangeEmployeeFilter,
                                onChangePartnerFilter,
                            }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    const [selectedEmployee, setSelectedEmployee] = useState(values['employee']);
    const [selectedPartner, setSelectedPartner] = useState(values['partner']);
    const [selectedTemplate, setSelectedTemplate] = useState(values['template']);
    const [selectedLocation, setSelectedLocation] = useState(values['location']);
    const [fromDate, setFromDate] = useState(values['fromDate']);

    useEffect(() => {
        setSelectedPartner(values['partner'])
    }, [values['partner']])

    useEffect(() => {
        setSelectedEmployee(values['employee'])
    }, [values['employee']])

    useEffect(() => {
        setSelectedTemplate(values['template'])
    }, [values['template']])

    useEffect(() => {
        setSelectedLocation(values['location'])
    }, [values['location']])

    useEffect(() => {
        setFromDate(values['dateFrom']);
    }, [values['dateFrom']]);

    const changeFromDate = (date) => {
        setValue('dateFrom', date);
        setFromDate(date);
    }

    const changeEmployee = (employee) => {
        setValue('employee', employee);
        setSelectedEmployee(employee)
    }

    const changePartner = (partner) => {
        setValue('partner', partner);
        setSelectedPartner(partner);
    }

    const changeTemplate = (template) => {
        setValue('template', template);
        setSelectedTemplate(template);
    }

    const changeLocation = (location) => {
        setValue('location', location);
        setSelectedLocation(location);
    }

    return (
        <FormProvider {...form}>
            <form id='product-service-form' className='form' action="#">

                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.checkList.scheduleCheckFrom.name}
                    disabled={isPageStateViewDetails()}
                />

                <div className='header-row-item'>
                    <DateTimeControl
                        data={data}
                        label={strings.forms.checkList.scheduleCheckFrom.dateFrom}
                        name={'dateFrom'}
                        value={values['dateFrom']}
                        setValue={(name, value) => changeFromDate(value)}
                        disabled={isPageStateViewDetails()}
                    />
                </div>

                <div className='header-row-item'>
                    <DateTimeControl
                        data={data}
                        label={strings.forms.checkList.scheduleCheckFrom.dateTo}
                        name={'dateTo'}
                        value={values['dateTo']}
                        setValue={setValue}
                        minDate={fromDate}
                        disabled={isPageStateViewDetails()}
                    />
                </div>

                <AutoCompleteControl
                    name='employee'
                    label={strings.forms.checkList.scheduleCheckFrom.employee}
                    rules={formRules['employee']}
                    nameKey={'fullName'}
                    valueKey={'id'}
                    setValue={(name, value) => changeEmployee(value)}
                    onChange={onChangeEmployeeFilter}
                    onOpen={() =>onChangeEmployeeFilter('')}
                    options={employees}
                    value={values['employee']}
                    error={Boolean(errors.employee)}
                    helperText={errors.employee && strings.forms.common.thisFieldIsRequired}
                    control={control}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    name='partner'
                    label={strings.forms.checkList.scheduleCheckFrom.partner}
                    rules={formRules['partner']}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={(name, value) => changePartner(value)}
                    onChange={onChangePartnerFilter}
                    onOpen={() =>onChangePartnerFilter('')}
                    options={partners}
                    value={values['partner']}
                    control={control}
                    error={Boolean(errors.partner)}
                    helperText={errors.partner && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    name='template'
                    label={strings.forms.checkList.scheduleCheckFrom.template}
                    rules={formRules['template']}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={(name, value) => changeTemplate(value)}
                    onChange={onChangePartnerFilter}
                    onOpen={() =>onChangePartnerFilter('')}
                    options={templates}
                    value={values['template']}
                    control={control}
                    error={Boolean(errors.template)}
                    helperText={errors.template && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    name='location'
                    label={strings.forms.checkList.scheduleCheckFrom.location}
                    rules={formRules['location']}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={(name, value) => changeLocation(value)}
                    onChange={onChangePartnerFilter}
                    onOpen={() =>onChangePartnerFilter('')}
                    options={locations}
                    value={values['location']}
                    control={control}
                    error={Boolean(errors.location)}
                    helperText={errors.location && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default ProductServiceForm;
