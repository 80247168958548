import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getBoardIssues(data) {
  return await request('/api/board-issue', data);
}

export async function addBoardIssue(boardId, data) {
  return await request('/api/board-issue/' + boardId, transformRequestData(data), HttpMethod.POST);
}

export async function editBoardIssue(issueId, data) {
  return await request('/api/board-issue/' + issueId, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteBoardIssue(issueId) {
  return await request('/api/board-issue/' + issueId, {}, HttpMethod.DELETE);
}

export async function updateIssueStage(data) {
  return await request('/api/board-issue-stage', data, HttpMethod.PUT);
}

export async function updateBoardIssue(id, data) {
  return await request('/api/board-issue/' + id, transformUpdateData(data), HttpMethod.PATCH);
}

export async function addIssueComment(id, data) {
  return await request('/api/issue-comment/' + id, data, HttpMethod.POST);
}

export async function getIssueComments(id) {
  return await request('/api/issue-comment/' + id, {});
}

export async function editIssueComment(id, data) {
  return await request('/api/issue-comment/' + id, data, HttpMethod.PUT);
}

export async function deleteIssueComment(id) {
  return await request('/api/issue-comment/' + id, {}, HttpMethod.DELETE);
}

export async function archiveIssue(id,data){
  return await request('/api/board-issue/archive/' + id, transformRequestData(data), HttpMethod.PUT);
}

function transformRequestData(data) {

  return {
    ...data,
    pipelineStageId: data?.pipelineStage ? data.pipelineStage.id : null,
    assigneeId: data?.assignee ? data.assignee.id : null,
    issueTypeId: data?.issueType ? data.issueType.id : null,
    priority: data?.priority?.type ? data.priority.type : data?.priority,
    isArchived: data?.isArchived ? data.isArchived : null,
  }
}

function transformUpdateData(data) {

  return {
    pipelineStageId: data.pipelineStage ? data.pipelineStage.id : null,
    assigneeId: data.assignee ? data.assignee.id : null,
    priority: data.priority ? data.priority : null,
    issueTypeId: data.issueType ? data.issueType.id : null,
  }
}
