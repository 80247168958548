import React, { useContext, useEffect, useState } from "react";
import { getCurrentCompany } from "../../../Services/Company/CompanyService";
import { addBusinessTrip } from "../../../Services/HR/BusinessTripService";
import { useForm } from "react-hook-form";
import { Paper } from "@mui/material";
import SnackbarContext from "../../../Context/SnackbarContext";
import BusinessTripForm from "../../../Components/Forms/Pages/HR/BusinessTrip/BusinessTripForm";
import strings from "../../../localization";
import LinearProgress from "@mui/material/LinearProgress";

const AddBusinessTrip = ({ onFinish, onCancel }) => {
  const { showMessage } = useContext(SnackbarContext);
  const form = useForm();
  const {
    data,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = form;
  const [company, setCompany] = useState();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    fetchCompany();
  }, []);

  const fetchCompany = () => {
    getCurrentCompany().then((response) => {
      if (!response || !response.ok) {
        return;
      }
      setCompany(response.data);
    });
  };

  const onSubmit = (data) => {
    setShowLoader(true);
    const businessTripData = { ...data, company: company };
    addBusinessTrip(businessTripData).then((response) => {
      setShowLoader(false);
      if (!response || !response.ok) {
        showMessage(strings.commonMessages.errorAdding, "error");
        onFinish();
        return;
      }

      onFinish();
      showMessage(strings.commonMessages.addingSuccessful, "success");
    });
  };

  return (
    <div className="drawer-form">
      <Paper className="paper">
        {showLoader && <LinearProgress />}
        <BusinessTripForm
          values={getValues()}
          setValue={setValue}
          data={data}
          errors={errors}
          form={form}
          control={control}
          onSubmit={handleSubmit(onSubmit)}
          onCancel={() => onCancel()}
        />
      </Paper>
    </div>
  );
};

export default AddBusinessTrip;
