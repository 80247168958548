import {FormProvider} from "react-hook-form";
import strings from "../../../../localization";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import React, {useContext, useEffect, useState} from "react";
import SelectControl from "../../../Controls/Inputs/SelectControl";
import Button from "@mui/material/Button";
import TablePageContext from "../../../../Context/TablePageContext";
import PageState from "../../../../Constants/Base/PageState";
import AutoCompleteControl from "../../../Controls/Inputs/AutoCompleteControl";
import {Grid} from '@mui/material';
import QuillEditor from "../../../Controls/Inputs/QuillEditor";
import ActionTextField from '../../../Controls/Inputs/ActionTextField';
import AutoCompleteTextField from "../../../Controls/Inputs/AutoCompleteTextField";

const BaseSettingsForm = ({
                              data,
                              form,
                              formRules,
                              errors,
                              values,
                              setValue,
                              currencies,
                              countries,
                              employees,
                              onSubmit,
                              control,
                              cities,
                              onRegistrationSearch,
                              onTaxSearch,
                              companies,
                              onNameChange,
                          }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const [editorData, setEditorData] = useState('');

    const changeValue = (name, value) => {
        setValue(name, value);
        form.trigger(name);
    }

    return (
        <FormProvider {...form}>
            <form id='base-settings-form' className='form' action="#">
                <Grid container spacing = {3}>
                    <Grid item xs = {6}>
                        <AutoCompleteTextField
                            value={values['name']}
                            className = {'base-settings-form-name mui-shifted-label-input'}
                            setValue={setValue}
                            name='name'
                            rules={formRules['name']}
                            control={control}
                            error={Boolean(errors.name)}
                            helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                            placeholder={strings.forms.company.baseSettings.enterName}
                            label={strings.forms.company.baseSettings.name}
                            options={companies}
                            nameKey={'name'}
                            valueKey={'id'}
                            disabled={isPageStateViewDetails()}
                            onChange={onNameChange}
                        />
                    </Grid>
                    <Grid item xs = {6} className='base-settings-form-registration-number'>
                        <ActionTextField
                            name='registrationNumber'
                            control={control}
                            className = {'mui-shifted-label-input'}
                            label={strings.forms.company.baseSettings.registrationNumber}
                            placeholder = {'Enter number'}
                            disabled={isPageStateViewDetails()}
                            type={'number'}
                            rules={formRules['registrationNumber']}
                            onClick={onRegistrationSearch}
                            value={values['registrationNumber']}
                            setValue={setValue}
                            error={Boolean(errors.registrationNumber)}
                            helperText={errors.registrationNumber && errors.registrationNumber.message}

                        />
                    </Grid>
                    <Grid item xs = {6} className='base-settings-form-registration-number'>
                        <ActionTextField
                            name='taxNumber'
                            control={control}
                            className = {'mui-shifted-label-input'}
                            label={strings.forms.company.baseSettings.taxNumber}
                            placeholder = {'Enter number'}
                            disabled={isPageStateViewDetails()}
                            type={'number'}
                            rules={formRules['taxNumber']}
                            onClick={onTaxSearch}
                            value={values['taxNumber']}
                            setValue={setValue}
                            error={Boolean(errors.taxNumber)}
                            helperText={errors.taxNumber && errors.taxNumber.message}
                        />
                    </Grid>
                    <Grid item xs = {6}>
                        <SelectControl
                            className = {'mui-shifted-label-input'}
                            control={control}
                            rules={formRules['currency']}
                            setValue={changeValue}
                            value={values['currency']}
                            name='currency'
                            label={strings.forms.company.baseSettings.currency}
                            options={currencies}
                            nameKey={'code'}
                            valueKey={'id'}
                            error={Boolean(errors && errors.currency)}
                            helperText={errors.currency && errors.currency.message}
                            disabled={isPageStateViewDetails()}
                        />
                    </Grid>
                    <Grid item xs = {6}
                        className = {'auto-complete'}
                    >
                        <AutoCompleteControl
                            className = {'mui-shifted-label-input'}
                            control={control}
                            rules={formRules['country']}
                            setValue={changeValue}
                            value={values['country']}
                            name='country'
                            label={strings.forms.company.baseSettings.country}
                            options={countries}
                            nameKey={'name'}
                            valueKey={'id'}
                            error={Boolean(errors && errors.country)}
                            helperText={errors.country && errors.country.message}
                            disabled={isPageStateViewDetails()}
                        />
                    </Grid>
                    <Grid item xs = {6}
                        className = {'auto-complete'}
                    >
                        <AutoCompleteControl
                            className = {'mui-shifted-label-input'}
                            control={control}
                            rules={formRules['city']}
                            setValue={changeValue}
                            value={values['city']}
                            name='city'
                            label={strings.forms.company.baseSettings.city}
                            options={cities}
                            nameKey={'name'}
                            valueKey={'id'}
                            error={Boolean(errors && errors.city)}
                            helperText={errors.city && errors.city.message}
                            disabled={isPageStateViewDetails()}
                        />

                    </Grid>
                    <Grid item xs = {6}> 
                        <TextFieldControl
                            className = {'mui-shifted-label-input'}
                            control={control}
                            name='address'
                            value={values['address']}
                            setValue={setValue}
                            rules={formRules['address']}
                            error={Boolean(errors && errors.address)}
                            helperText={errors.address && errors.address.message}
                            label={strings.forms.company.baseSettings.address}
                            disabled={isPageStateViewDetails()}
                            placeholder = {'Enter address'}
                        />
                    </Grid>
                    <Grid item xs = {6} sx = {{ height: '74px'}}>
                        <Grid container spacing = {2}>
                            <Grid item xs = {6}>
                                <TextFieldControl
                                    className = {'mui-shifted-label-input'}
                                    control={control}
                                    name='zipCode'
                                    rules={formRules['zipCode']}
                                    error={Boolean(errors && errors.zipCode)}
                                    helperText={errors.zipCode && errors.zipCode.message}
                                    label={strings.forms.company.baseSettings.zipCode}
                                    disabled={isPageStateViewDetails()}
                                    placeholder = {'Enter zip code'}
                                />
                            </Grid>
                            <Grid item xs = {6}>
                                <TextFieldControl
                                    className = {'mui-shifted-label-input'}
                                    control={control}
                                    name='activityCode'
                                    rules={formRules['activityCode']}
                                    error={Boolean(errors && errors.activityCode)}
                                    helperText={errors.activityCode && errors.activityCode.message}
                                    label={strings.forms.company.baseSettings.activityCode}
                                    disabled={isPageStateViewDetails()}
                                    placeholder = {'Enter code'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs = {6}>
                        <TextFieldControl
                            control={control}
                            className = {'mui-shifted-label-input'}
                            name='activity'
                            rules={formRules['activity']}
                            error={Boolean(errors && errors.activity)}
                            helperText={errors.activity && errors.activity.message}
                            label={strings.forms.company.baseSettings.activity}
                            disabled={isPageStateViewDetails()}
                            placeholder = {'Enter activity'}
                        />
                    </Grid>
                    <Grid item xs = {6}>
                        <TextFieldControl
                            control={control}
                            className = {'mui-shifted-label-input'}
                            name='phone'
                            rules={formRules['phone']}
                            error={Boolean(errors && errors.phone)}
                            helperText={errors.phone && errors.phone.message}
                            label={strings.forms.company.baseSettings.phone}
                            disabled={isPageStateViewDetails()}
                            placeholder = {'Enter phone'}
                        />
                    </Grid>
                    <Grid item xs = {6}>
                        <TextFieldControl
                            className = {'mui-shifted-label-input'}
                            control={control}
                            type='email'
                            name='email'
                            rules={formRules['email']}
                            error={Boolean(errors && errors.email)}
                            helperText={errors.email && errors.email.message}
                            label={strings.forms.company.baseSettings.email}
                            disabled={isPageStateViewDetails()}
                            placeholder = {'Enter email'}
                        />
                    </Grid>
                    <Grid item xs = {6}>
                        <TextFieldControl
                            control={control}
                            className = {'mui-shifted-label-input'}
                            name='website'
                            rules={formRules['website']}
                            error={Boolean(errors && errors.website)}
                            helperText={errors.website && errors.website.message}
                            label={strings.forms.company.baseSettings.website}
                            disabled={isPageStateViewDetails()}
                            placeholder = {'Enter website'}
                        />
                    </Grid>
                    <Grid item xs = {6}
                          className = {'auto-complete'}>
                        <AutoCompleteControl
                            className = {'mui-shifted-label-input'}
                            control={control}
                            rules={formRules['responsiblePerson']}
                            setValue={changeValue}
                            value={values['responsiblePerson']}
                            name='responsiblePerson'
                            label={strings.forms.company.baseSettings.responsiblePerson}
                            options={employees}
                            nameKey={'fullName'}
                            valueKey={'id'}
                            error={Boolean(errors && errors.responsiblePerson)}
                            helperText={errors.responsiblePerson && errors.responsiblePerson.message}
                            disabled={isPageStateViewDetails()}
                        />

                    </Grid>
                    <Grid>
                        <div className='mw-700 ml25'>
                        <QuillEditor
                            name='about'
                            className='large-editor'
                            form={form}
                            value={editorData}
                            onChange={setEditorData}
                            placeholder={'About'}
                        />
                        </div>
                    </Grid>

                    {
                        !isPageStateViewDetails() && 
                        <Grid item xs = {12} className = 'submit-btn-item'>
                            <div className='submit-container'>
                                <Button variant="contained" color="primary" onClick={onSubmit} disabled={Object.keys(errors).length>0}>
                                    {strings.forms.common.save}
                                </Button>
                            </div>
                        </Grid>
                    }
                </Grid>
            </form>
        </FormProvider>
    )
}


export default BaseSettingsForm;
