import {useEffect} from "react";
import {useQuery} from "../../Util/RouterUtil";
import {activateUser} from "../../Services/User/UserService";
import {useNavigate} from "react-router-dom";

const ActivateUser = () => {

    const query = useQuery();
    const navigate = useNavigate();

    useEffect(() => {
        activate();
    }, []);

    const activate = () => {
        const token = query.get('token');

        activateUser(token).then(response => {

            if(!response || !response.ok) {
                navigate('/registration');
                return;
            }

            navigate('/login');
        });
    }

    return ''
}

export default ActivateUser;