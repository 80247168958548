const ModulePermission = {
    FINANCE: 1,
    HR: 2,
    PROJECT_MANAGEMENT: 3,
    DOCUMENT: 4,
    COMPANY_DOCUMENT: 5,
    CRM: 6,
    ASSET_MANAGEMENT: 7,
    ADMINISTRATION: 8,
    WORKSPACES: 9,
    HOME: 10, 
    DASHBOARD: 11,
    CALENDAR: 12,
    SPACES: 13,
    PRODUCT: 14,
    CHECK_LIST: 15,
};

export default ModulePermission;
