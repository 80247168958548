import React, {useState} from 'react';
import {Paper, Modal} from "@mui/material";
import CreateCompanyDocument from "./CreateCompanyDocument";


const ChooseTemplateModal = ({showModal, closeModal, selectedFolder}) => {
    const handleCloseModal = (prop) => {
        closeModal(prop)
    }

    return(
        <Modal
            open={Boolean(showModal)}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            id="choose-template-modal">
            <Paper>
                <CreateCompanyDocument selectedFolder={selectedFolder}></CreateCompanyDocument>
            </Paper>
        </Modal>
    );

}


export default ChooseTemplateModal;