import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { downloadDecision } from "../../../Services/HR/BusinessTripService";
import { Drawer } from "@mui/material";
import DrawerWrapper from "../../../Components/Layout/DrawerWrapper";
import EditDecision from "./EditDecision";
import strings from "../../../localization";

const DecisionList = ({ decision, onEdit, onDelete, travelWarrant }) => {
  const [showForm, setShowForm] = useState(false);

  const onFinish = () => {
    setShowForm(false);
  };

  const openForm = () => {
    setShowForm(true);
  };

  const download = (id) => {
    downloadDecision(id).then((response) => {
      if (!response || !response.ok) {
        return;
      }
    });
  };

  return (
    <div className="business-trip-details">
      <div className="business-trip-item">
        <div className="business-trip-elements">
          <div className="business-trip-details">
            <strong>
              {strings.pages.hr.businessTrip.businessTripForm.documentName}
              {": "}
            </strong>
            {decision.business_trip.documentName}
          </div>

          <div className="icons-decision">
            <IconButton onClick={() => download(decision.business_trip.id)}>
              <img src="/images/download-pdf-blue.svg" />
            </IconButton>
            <IconButton onClick={() => openForm()}>
              <img src="/images/edit-blue.svg" />
            </IconButton>
            {showForm && (
              <Drawer id="drawer" anchor="right" open={openForm}>
                <DrawerWrapper
                  onBack={() => onFinish()}
                  title={strings.pages.hr.businessTrip.editDecision.pageTitle}
                >
                  <EditDecision
                    decision={decision}
                    travelWarrant={travelWarrant}
                    onCancel={() => onFinish()}
                    onEdit={onEdit}
                    onFinish={() => onFinish()}
                  />
                </DrawerWrapper>
              </Drawer>
            )}
            <IconButton onClick={() => onDelete()}>
              <img src="/images/delete-blue.svg" />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DecisionList;
