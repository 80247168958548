import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";


export async function getAllProjectRecords(projectId){
    return await request('/api/project-records/' + projectId);
}

export async function addProjectRecord(data){
    return await request('/api/project-records', data, HttpMethod.POST);
}

export async function editProjectRecord(data){
    return await request('/api/project-records/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteProjectRecord(id){
    return await request('/api/project-records/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data){
    return {
        ...data,
        projectId: data.project ? data.project.id : null,
    }
}