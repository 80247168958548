import strings from "../../localization";

const Modules = {
    HR: strings.navigation.hr,
    PRODUCT: strings.navigation.product,
    FINANCE: strings.navigation.finance,
    CRM: strings.navigation.crm,
    ADMINISTRATION: strings.navigation.administration,
    DOCUMENTS: strings.navigation.documents,
    PROJECTS: strings.navigation.project,
    FILES: strings.navigation.files,
    ASSETS: strings.navigation.assets,
    USER_PROFILE:  strings.navigation.userProfile,
    WORKSPACES: strings.navigation.workspaces,
    SPACES: strings.navigation.spaces,
    CHECK_LIST: strings.navigation.checkList,
}

export default Modules;
