import {FormProvider} from "react-hook-form";
import strings from "../../../../localization";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import React, {useContext} from "react";
import Button from "@mui/material/Button";
import TablePageContext from "../../../../Context/TablePageContext";
import PageState from "../../../../Constants/Base/PageState";
import {Grid} from '@mui/material';

const S3SettingsForm = ({
                            form,
                            formRules,
                            errors,
                            onSubmit,
                        }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='s3-settings-form' className='form' action="#">
                <Grid container spacing = {3}>
                    <Grid item xs = {12}>
                        <TextFieldControl
                            name='version'
                            className = {'mui-shifted-label-input'}
                            rules={formRules['version']}
                            error={Boolean(errors && errors.version)}
                            helperText={errors.version && errors.version.message}
                            label={strings.forms.company.s3Settings.version}
                            disabled={isPageStateViewDetails()}
                            placeholder = {'Enter version'}
                        />
                    </Grid>
                    <Grid item xs = {12}>
                        <TextFieldControl
                            className = {'mui-shifted-label-input'}
                            name='region'
                            rules={formRules['region']}
                            error={Boolean(errors && errors.region)}
                            helperText={errors.region && errors.region.message}
                            label={strings.forms.company.s3Settings.region}
                            disabled={isPageStateViewDetails()}
                            placeholder = {'Enter region'}
                        />
                    </Grid>
                    <Grid item xs = {12}>
                        <TextFieldControl
                            className = {'mui-shifted-label-input'}
                            name='s3key'
                            rules={formRules['s3key']}
                            error={Boolean(errors && errors.s3key)}
                            helperText={errors.s3key && errors.s3key.message}
                            label={strings.forms.company.s3Settings.s3key}
                            disabled={isPageStateViewDetails()}
                            placeholder = {'Enter key'}
                        />
                    </Grid>
                    <Grid item xs = {12}>
                        <TextFieldControl
                            className = {'mui-shifted-label-input'}
                            name='secret'
                            rules={formRules['secret']}
                            error={Boolean(errors && errors.secret)}
                            helperText={errors.secret && errors.secret.message}
                            label={strings.forms.company.s3Settings.secret}
                            disabled={isPageStateViewDetails()}
                            placeholder = {'Enter secret'}
                        />
                    </Grid>
                    <Grid item xs = {12}>
                        <TextFieldControl
                            className = {'mui-shifted-label-input'}
                            name='bucket'
                            rules={formRules['bucket']}
                            error={Boolean(errors && errors.bucket)}
                            helperText={errors.bucket && errors.bucket.message}
                            label={strings.forms.company.s3Settings.bucket}
                            disabled={isPageStateViewDetails()}
                            placeholder = {'Enter bucket'}
                        />
                    </Grid>
                    <Grid item xs = {12}>
                        <TextFieldControl
                            className = {'mui-shifted-label-input'}
                            name='url'
                            rules={formRules['url']}
                            error={Boolean(errors && errors.url)}
                            helperText={errors.url && errors.url.message}
                            label={strings.forms.company.s3Settings.url}
                            disabled={isPageStateViewDetails()}
                            placeholder = {'Enter URL'}
                        />
                    </Grid>
                {
                    !isPageStateViewDetails() && 
                        <Grid item xs = {12} className = 'submit-btn-item'>
                            <div className='submit-container'>
                                <Button variant="contained" color="primary" onClick={onSubmit}>
                                    {strings.forms.common.save}
                                </Button>
                            </div>
                        </Grid>
                }
                </Grid>
            </form>
        </FormProvider>
    )
}


export default S3SettingsForm;
