import React, { useContext } from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import {deleteInviteUserCompanyAccess, getUserCompanyAccess} from "../../../Services/User/UserCompanyAccessService";
import {getUserCompanyAccessStatusString} from "../../../Constants/User/UserCompanyAccessStatus";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import {useNavigate, useSearchParams} from "react-router-dom";
import {returnColumnBoolean} from "../../../Components/DataGrid/ValueCellRender";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import { inviteUser } from "../../../Services/User/UserService";
import SnackbarContext from "../../../Context/SnackbarContext";
import YesNoDialog from "../../../Components/Dialogs/YesNoDialog";
import { YesNoDialogResult } from "../../../Components/Dialogs/YesNoDialog";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const userValues = (params, key) => params.row.user[key];
const candidateValues = (params, key) => params.row.user.candidate ? params.row.user.candidate[key] : '';

const tableDescription = [
    {field: 'email', headerName: strings.pages.administration.user.userList.email, valueGetter: params => userValues(params, 'email'), width: 250},
    {field: 'firstName', headerName: strings.pages.administration.user.userList.firstName, valueGetter: params => userValues(params, 'firstName'), width: 250},
    {field: 'lastName', headerName: strings.pages.administration.user.userList.lastName, valueGetter: params => userValues(params, 'lastName'), width: 250},
    {field: 'status', headerName: strings.pages.administration.user.userList.status, valueFormatter: params => getUserCompanyAccessStatusString(params.value), width: 250},
    {field: 'userPosition', headerName: strings.pages.administration.user.userList.userPosition, valueFormatter: params => formatColumnObject(params, 'name'), width: 250},
    {field: 'team', headerName: strings.pages.administration.user.userList.team, valueFormatter: params => formatColumnObject(params, 'name'), width: 150},
    {field: 'candidate', headerName: strings.pages.administration.user.userList.candidate, valueGetter: params => candidateValues(params, 'fullName')},
    {field: 'organisationUser', headerName: strings.pages.administration.user.userList.organisationUser, renderCell: params => returnColumnBoolean(params), width: 150, align: 'center' },
    {field: 'owner', headerName: strings.pages.administration.user.userList.owner, renderCell: returnColumnBoolean, width: 80, align: 'center'},
    {field: 'billableHours', headerName: strings.pages.administration.user.userList.billableHours, renderCell: params => returnColumnBoolean(params), width: 120, align: 'center'},
];

const filters = []

const UserList = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const [showInviteDialog, setShowInviteDialog ]= useState(false);
    const { showMessage } = useContext(SnackbarContext);
    const referenceType = ReferenceType.USER;
    const permissionGroup = AppPermissions.USER.GROUP;
    const navigate = useNavigate()

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog, navigate,
        updatedFilter, setUpdatedFilter
    }

    const additionalMenuItems = [
        {handleClick: (id, item) => handleRoles(id, item), text: strings.pages.administration.user.userList.roles, src: '/images/table-page/roles.svg'},
        {handleClick: (id, item) => handleInvite(id, item), text: strings.pages.administration.user.userList.invite, src: "/images/table-page/invite.svg"}
    ]

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.administration.user.userList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.ADMINISTRATION},
                {label: strings.pages.administration.user.userList.pageTitle},
            ],        
        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getUserCompanyAccess({
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    const handleRoles = (id, userAccess) => {
        navigate('/user-roles', {state: {user: userAccess.user}})
    }

    const handleInvite = (id) => {
        setShowInviteDialog(true)
    }

    const handleDialogResult = (result, payload)=>{
        setShowInviteDialog(false)
        if(result === YesNoDialogResult.YES){
            inviteUser(payload[0]).then(response => {
                if (!response || !response.ok) {
                    showMessage(strings.commonMessages.errorInvite, 'error');
                    return;
                }

                showMessage(strings.commonMessages.inviteSuccesful, 'success');
            });
        }
        
    }

    return <TablePageContext.Provider value={value}>
        <YesNoDialog show={showInviteDialog}
                     payload={selectionModel}
                     handleResult={handleDialogResult}
                     title={strings.components.tablePage.confirmSendInvite}
                     text={strings.components.tablePage.confirmSendInviteMessage}
                     negativeText={strings.components.tablePage.cancel}
                     positiveText={strings.components.tablePage.send}/>


        <TablePage onFinish={() => onFinish()} deleteItem={deleteInviteUserCompanyAccess} tableDescription={columns}
                   tableData={tableData} filter={filter} filters={filters}
                   additionalMenuItems={additionalMenuItems}
                   referenceType={referenceType}
                   permissionGroup={permissionGroup}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditUser data={ selectedItems }
                                          onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addPage={<AddUser onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addButtonText={strings.components.tablePage.addButton.user}
        />
    </TablePageContext.Provider>;
}

export default UserList;
