import LinearProgress from '@mui/material/LinearProgress';
import {Paper} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DrawerContext from "../../../Context/DrawerContext"
import strings from "../../../localization";
import { editLeaveRequest } from '../../../Services/HR/LeaveRequestService';
import LeaveRequestForm from '../../../Components/Forms/Pages/HR/LeaveRequest/LeaveRequestForm';
import {getAllLeaveTypes} from "../../../Services/HR/LeaveTypeService";
import SnackbarContext from '../../../Context/SnackbarContext';

const formRules = {
    'leaveType': {required: true},
    'fromDate': {required: true},
    'toDate': {required: true},
}

const EditLeaveRequest = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? {
            ...props.data[0],
            fromDate: new Date(props.data[0].fromDate) || NaN,
            toDate: new Date(props.data[0].toDate) || NaN,
            leaveType: props.data[0].type,
        } : undefined,
        mode: "onTouched"
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [leaveTypes, setLeaveTypes] = useState([]);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.leaveRequest.editLeaveRequest.pageTitle);
        fetch();     
    }, []);

    const fetch = () => {
        getAllLeaveTypes().then(response => {

            if(!response || !response.ok) {
                setLeaveTypes([]);
                return;
            }

            setLeaveTypes(response.data);
        })
    }

    const onSubmit = (data) => {
        setShowLoader(true);

        editLeaveRequest(data).then(response => {
            setShowLoader(false);

            if(!response){
                showMessage(strings.commonMessages.errorEditing, 'error');
                return;
            }
            if (!response.ok) {          
                showMessage(response.response.data.message, 'error', 5000);
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });

        
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <LeaveRequestForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                leaveTypes={leaveTypes}
                errors={errors} data={data} form={form} control={control}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()} />
        </Paper>
    </div>
}

export default EditLeaveRequest;