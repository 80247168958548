import {Controller} from "react-hook-form";
import React, {useState, useEffect} from "react";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

const ActionTextField = (props) => {
    const [value, setValue] = useState('');

    const onKeyDown = (evt) => {
        if (props.type && props.type.toLowerCase() === 'number') {
            const value = evt.target.value;
            evt.key === ',' && evt.preventDefault();
            evt.key === '.' && value.endsWith('.') && evt.preventDefault();
        }

    }


    return <Controller
        {...props}
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
        rules={props.rules}
        render={({ field }) =>
            <div className = {'action-text-field ' + props.className}>
                <InputLabel className = {props.error ? 'action-field-label-error' : ''}>{props.label}</InputLabel>
                <Paper className = {props.error ? 'action-field-error' : ''}>
                    <InputBase
                        {...field}
                        placeholder = {props.placeholder}
                        onKeyDown={onKeyDown}
                        type = {props.type}
                        color = {'error'}
                        disabled = {props.disabled}
                    />
                    {!props.disabled &&
                    <IconButton onClick = {props.onClick}>
                        <img src = '/images/form-search.svg' />
                    </IconButton>
                    }
                </Paper>
                {props.error && 
                    <FormHelperText>{props.helperText}</FormHelperText>
                }
            </div>
        }
    />
}

export default ActionTextField;
