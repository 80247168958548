import {useEffect, useState, useRef, useContext} from "react";
import { getDaysOfWeekShort } from "../../Util/DateUtil";
import { getFullDayEntries, getPartDayEntries, renderCanvasBackground, renderFullDayEntries, renderPartDayEntries } from "./CalendarByDay";
import { organizeEntries } from "../../Util/CalendarUtil";
import strings from "../../localization";

const DateComparison = {
    PREV_MONTH: -1,
    SAME: 0,
    NEXT_MONTH: 1,
}

const CalendaByWeek = ({
                        date,
                        data,
                        nextMonthData,
                        prevMonthData,
                        today,
                        calendarEvent,
}) =>{
    const [selectedDate, setSelectedDate] = useState();
    const [weekDays, setWeekDays] = useState([{}]);

    const mondayCanvasRef = useRef();
    const tuesdayCanvasRef = useRef();
    const wednsdayCanvasRef = useRef();
    const thursdayCanvasRef = useRef();
    const fridayCanvasRef = useRef();
    const saturdayCanvasRef = useRef();
    const sundayCanvasRef = useRef();

    const fullDayRef= useRef();

    useEffect(()=>{
        setTimeout(()=>{
            setSelectedDate(new Date(date.year, date.month - 1, date.day));      
        }, 10)
    },[date.day, date.month, date.year]);  

    useEffect(()=>{
        if(!selectedDate){
            return;
        }
        getWeekDays();
    },[selectedDate?.getTime()])

    const getDateComparison = (month, year) =>{

        if(year > date.year){return DateComparison.NEXT_MONTH}
        if(year < date.year){return DateComparison.PREV_MONTH}

        if(month > date.month){return DateComparison.NEXT_MONTH}
        if(month < date.month){return DateComparison.PREV_MONTH}

        return DateComparison.SAME;
    }

    const getWeekDays = ()=>{
        const current = new Date(selectedDate.getTime());
       

        const days= new Array(); 
        current.setDate((current.getDate() - current.getDay() + 1));

        for (let i = 0; i < 7; i++) {

            const currentDay = current.getDate();
            const currentMonth =  current.getMonth() + 1;
            const currentYear =  current.getFullYear();

            let assignData; 

            switch (getDateComparison(currentMonth, currentYear)) {
                case DateComparison.SAME:
                    assignData = data
                    break;

                case DateComparison.PREV_MONTH:
                    assignData = prevMonthData
                    break;    
        
                case DateComparison.NEXT_MONTH:
                    assignData = nextMonthData
                    break;
            }

            days.push(
                {   
                    selectedDate: new Date(currentYear, currentMonth - 1, currentDay),
                    date: {
                        day: currentDay,
                        month: currentMonth,
                        year: currentYear,
                    },
                    dayName: getDaysOfWeekShort()[current.getDay()],
                    data: assignData,
                    current: currentDay === today.day && currentMonth === today.month && currentYear === today.year,
                }
            ); 
            current.setDate(current.getDate() + 1);
        }

        days.forEach((day, index)=>{
            day.fullDayEntries = getFullDayEntries(day.data, day.selectedDate);

            const partDayEntries = getPartDayEntries(day.data, day.selectedDate);
            const organizedEntries = organizeEntries(partDayEntries);
            day.partDayEntries = organizedEntries;
            day.index = index
        })

        setWeekDays(days);
    }

    const renderHeader = () =>{
        return weekDays.map(day =>{
            return <div key={day.dayName} className={`header-day ${day.current? "current": ""}`}>
                <div className="day-name">
                    {day.dayName}
                </div>
                <div className="day-number">
                    {day?.date?.day}
                </div>
            </div>
        })
    }

    return <div className="calendar-by-week-container">
                <div className='calendar-container-header'>
                    {renderHeader()}
                </div>
                <div className="full-day-event-section"> 
                    <div className="time-section">
                        {strings.components.calendar.allDay}
                    </div>
                    <div className="event-section"> 
                        {
                            weekDays && weekDays.map((day, i) =>{
                                if(i === 0){
                                    return <div key={i} ref={fullDayRef} className="day-in-week">
                                                {renderFullDayEntries(day.fullDayEntries, () => calendarEvent(day.date, day.data), true, true, fullDayRef )}
                                            </div>
                                }
                                return <div key={i} className="day-in-week">
                                    {renderFullDayEntries(day.fullDayEntries, () => calendarEvent(day.date, day.data), true, true, fullDayRef)}
                                </div>
                            })               
                        }                 
                    </div>                                                                 
                </div>  

                <div className="day"> 
                    {renderCanvasBackground(true)}

                    {
                       weekDays && <div className="event-section">
                        <div className="monday-canvas canvas" ref={mondayCanvasRef}> 
                            {renderPartDayEntries(weekDays[0]?.partDayEntries, weekDays[0]?.selectedDate, mondayCanvasRef, () => calendarEvent(weekDays[0].date, weekDays[0].data), true)}     
                        </div>

                        <div className="tuesday-canvas canvas" ref={tuesdayCanvasRef}> 
                            {renderPartDayEntries(weekDays[1]?.partDayEntries, weekDays[1]?.selectedDate, tuesdayCanvasRef, () => calendarEvent(weekDays[1].date, weekDays[1].data), true)}     
                        </div>

                        <div className="wednsday-canvas canvas" ref={wednsdayCanvasRef}> 
                            {renderPartDayEntries(weekDays[2]?.partDayEntries, weekDays[2]?.selectedDate, wednsdayCanvasRef, () => calendarEvent(weekDays[2].date, weekDays[2].data), true)}     
                        </div>

                        <div className="thursday-canvas canvas" ref={thursdayCanvasRef}> 
                            {renderPartDayEntries(weekDays[3]?.partDayEntries, weekDays[3]?.selectedDate, thursdayCanvasRef, () => calendarEvent(weekDays[3].date, weekDays[3].data), true)}     
                        </div>

                        <div className="friday-canvas canvas" ref={fridayCanvasRef}> 
                            {renderPartDayEntries(weekDays[4]?.partDayEntries, weekDays[4]?.selectedDate, fridayCanvasRef, () => calendarEvent(weekDays[4].date, weekDays[4].data), true)}     
                        </div>

                        <div className="saturday-canvas canvas" ref={saturdayCanvasRef}> 
                            {renderPartDayEntries(weekDays[5]?.partDayEntries, weekDays[5]?.selectedDate, saturdayCanvasRef, () => calendarEvent(weekDays[5].date, weekDays[5].data), true)}     
                        </div>  

                        <div className="sunday-canvas canvas" ref={sundayCanvasRef}> 
                            {renderPartDayEntries(weekDays[6]?.partDayEntries, weekDays[6]?.selectedDate, sundayCanvasRef, () => calendarEvent(weekDays[6].date, weekDays[6].data), true)}     
                        </div> 
                    </div>} 
                </div>                                                                 
            </div>
   
}

export default CalendaByWeek;