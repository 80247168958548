import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import LinearProgress from '@mui/material/LinearProgress';
import ProjectResourceForm from "../../../Components/Forms/Pages/Project/ProjectResource/ProjectResourceForm";
import {getCurrencies} from "../../../Util/SiteDataUtil";
import {getAllProjectResourceTypes} from "../../../Services/Project/ProjectResourceTypeService";
import {useSelector} from "react-redux";
import {editProjectResources} from "../../../Services/Project/ProjectResourceService";
import SnackbarContext from "../../../Context/SnackbarContext";
import { getProjectList } from "../../../Services/Project/ProjectService";
import { ResourceTypes } from "../../../Constants/Project/ProjectResourceType";
import {getInvoices} from "../../../Services/Finance/InvoiceService";

const formRules = {
    'name': {required: true},
    'description': {required: true},
    'price': {required: true},
    'projectResourceType': {required: true},
    'project': {required: true},
    'currency': {required: true},
    'type': {required: true},
    'invoice': {required: true},
}

const EditProjectResourxex = (props) => {

    const siteData = useSelector((state) => state.siteData)
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? 
            {...props.data[0], type: ResourceTypes.find((t) => t.type ===  props.data[0]?.type)}
            : 
            undefined
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}, watch} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [projectResourceTypes, setProjectResourceTypes] = useState([]);
    const [projects, setProjects] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [invoiceDirection, setInvoiceDirection] = useState(null);
    const [project, setProject] = useState(null);
    const watchData = watch(['invoice', 'type']);

    useEffect(() => {
        setDrawerTitle(strings.pages.project.projectResource.editProjectResource.pageTitle);
    });

    useEffect(() => {
        fetch();
        fetchProjects();
    }, [])

    useEffect(() => {
        const subscription = watch((value) => {});
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if(watchData[0]?.project){
            setProject(watchData[0].project);
            setValue('project', watchData[0]?.project);
        }

        if(watchData[1]){
            setInvoiceDirection(watchData[1].type);
        }else{
            setInvoiceDirection(null);
        }
    },[watchData]);

    useEffect(() => {
        if(!invoiceDirection){
            return;
        }

        fetchInvoices();
    },[invoiceDirection]);

    const fetch = () => {
        getAllProjectResourceTypes({}).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setProjectResourceTypes(response.data)
        })
    };

    const fetchProjects = (term = '') => {
        getProjectList({term}).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setProjects(response.data.result);
        })
    }

    const fetchInvoices = (term = '') => {
        getInvoices({term, invoiceDirection}).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setInvoices(response.data.result);
        })
    };


    const onSubmit = (data) => {

        setShowLoader(true);

        editProjectResources(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <ProjectResourceForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                currencies={getCurrencies(siteData)}
                projectResourceTypes={projectResourceTypes}
                projects={projects} 
                onChangeProjectFilter={fetchProjects}
                errors={errors} 
                data={data} 
                form={form} 
                control={control}
                onSubmit={handleSubmit(onSubmit)} 
                onCancel={() => onCancel()}
                invoiceDirection = {invoiceDirection}
                invoices = {invoices}
                onChangeInvoiceFilter={fetchInvoices}
            />
        </Paper>
    </div>
}

export default EditProjectResourxex;
