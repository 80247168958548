import { getDaysOfWeek } from "../../Util/DateUtil"
import {
    getBirthdays,
    getNonWorkingDays,
    getOrganisationUserContract,
    getContracts,
    getLeaves,
    getContractParts,
    getOrganisationUserContractParts,
    getProjects,
    getOrganisationUserContractPartDescription,
    getOrganisationUserContractDescription,
    getProjectDescription,
    getContractPartsDescription,
    getContractDescription,
    getBirthdayDescription,
    getLeaveDesription,
    getNonWorkingDayDescription,
    getCustomEventDescriptionDay,
    getPartDayCustomEvent,
    getFullDayCustomEvent,
    getScheduledChecks,
    getScheduledCheckDescription,
    getScheduledChecksPending,
    getScheduledChecksInProgress,
    getScheduledChecksDone,
    getScheduledChecksCancelled
} from "./CalendarContent";
import { Divider } from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {CalendarContent, ClassFromContent, SourceFromContent} from "../../Constants/Company/CalendarContent"
import Badge from "./Badge";
import strings from "../../localization";
import { organizeEntries } from "../../Util/CalendarUtil";


const CalendarByDay = ({
    date,
    data,
    calendarEvent,
}) => {
    const [fullDayEntries, setFullDayEntries] = useState([]);
    const [partDayEntries, setPartDayEntries] = useState([]);
    const [selectedDate, setSelectedDate] = useState();
    const canvasRef = useRef();  
    const dayStart =  new Date(date.year, date.month - 1, date.day, 0, 0 );


    useEffect(()=>{
        setTimeout(()=>{
            setSelectedDate(new Date(date.year, date.month - 1, date.day));      
        }, 10)
    },[date.day, date.month, date.year]);  
     
    useEffect(()=>{
        if(!selectedDate){
            return;
        }

        setFullDayEntries(getFullDayEntries(data, selectedDate));
        const newPartDayEntries = getPartDayEntries(data, selectedDate, dayStart)
        const organizedEntries = organizeEntries(newPartDayEntries);
        setPartDayEntries(organizedEntries);

    },[selectedDate?.getTime()])


    return <div className='calendar-by-day-container'>
                {<div className='calendar-container-header'>
                    {getDaysOfWeek()[selectedDate?.getDay()]}
                </div>}

                <div className="full-day-event"> 
                    <div className="time">
                        {strings.components.calendar.allDay}
                    </div>  
                    <div className="event"> 
                        {renderFullDayEntries(fullDayEntries, ()=>calendarEvent(date, data), true)}
                    </div>                                                                 
                </div>   

                <div className="day">                   
                    {renderCanvasBackground()}
                    <div className="event-canvas" ref={canvasRef}> 
                        {renderPartDayEntries(partDayEntries, dayStart, canvasRef, ()=>calendarEvent(date, data), true)}
                    </div>  
                </div>
            </div>
}

export const getFullDayEntries = (data, selectedDate) =>{
    const isWeekend = day => day === 6 || day === 0;
    const fullDayEntries = [];

    getBirthdays(data, {date: selectedDate}).forEach(birthday => {
        fullDayEntries.push({
            content: birthday,
            type: CalendarContent.BIRTHDAY,
            description: getBirthdayDescription(birthday),
        })
    });

    getLeaves(data, {date: selectedDate}, isWeekend(selectedDate.getDay())).forEach(leave => {
        fullDayEntries.push({
            content: leave,
            type: CalendarContent.LEAVE,
            description: getLeaveDesription(leave),
        })
    })

    getNonWorkingDays(data, {date: selectedDate}).forEach(nonWorking =>{
        fullDayEntries.push({
            content: nonWorking,
            type: CalendarContent.NON_WORKING_DAY,
            description: getNonWorkingDayDescription(nonWorking),
        })
    });

    getProjects(data, {date: selectedDate})?.forEach(project => {
        fullDayEntries.push({
            content: project,
            type: CalendarContent.PROJECT,
            description: getProjectDescription(project),   
        })
    });

    getFullDayCustomEvent(data, {date: selectedDate}).forEach(customEvent => {
            fullDayEntries.push({
                content: customEvent,
                type: CalendarContent.CustomEvent,
                description: getCustomEventDescriptionDay(customEvent),
            })
    });

    getContracts(data, {date: selectedDate}).forEach(contract =>{
        fullDayEntries.push({
            content: contract,
            type: CalendarContent.CONTRACT,
            description: getContractDescription(contract),
        })
    });

    getContractParts(data, {date: selectedDate}).forEach(contract => {
        fullDayEntries.push({
            content: contract,
            type: CalendarContent.CONTRACT_PART,
            description: getContractPartsDescription(contract),
        })
    });

    getOrganisationUserContract(data, {date: selectedDate}).forEach(contract =>{
        fullDayEntries.push({
            content: contract,
            type: CalendarContent.ORGANISATION_CONTRACT,
            description: getOrganisationUserContractDescription(contract),
        })
    });

    getOrganisationUserContractParts(data, {date: selectedDate}).forEach(contract =>{
        fullDayEntries.push({
            content: contract,
            type: CalendarContent.ORGANISATION_CONTRACT_PART,
            description: getOrganisationUserContractPartDescription(contract),
        })
    })

    getScheduledChecksPending(data, {date: selectedDate}).forEach(scheduledCheck =>{
        fullDayEntries.push({
            content: scheduledCheck,
            type: CalendarContent.SCHEDULED_CHECK_PENDING,
            description: getScheduledCheckDescription(scheduledCheck),
        })
    });
    getScheduledChecksInProgress(data, {date: selectedDate}).forEach(scheduledCheck =>{
        fullDayEntries.push({
            content: scheduledCheck,
            type: CalendarContent.SCHEDULED_CHECK_IN_PROGRESS,
            description: getScheduledCheckDescription(scheduledCheck),
        })
    });

    getScheduledChecksDone(data, {date: selectedDate}).forEach(scheduledCheck =>{
        fullDayEntries.push({
            content: scheduledCheck,
            type: CalendarContent.SCHEDULED_CHECK_DONE,
            description: getScheduledCheckDescription(scheduledCheck),
        })
    });

    getScheduledChecksCancelled(data, {date: selectedDate}).forEach(scheduledCheck =>{
        fullDayEntries.push({
            content: scheduledCheck,
            type: CalendarContent.SCHEDULED_CHECK_CANCELLED,
            description: getScheduledCheckDescription(scheduledCheck),
        })
    });

    return fullDayEntries;
}

export const getPartDayEntries = (data, selectedDate) =>{
    const isSameDay = date => date.getDay() === selectedDate.getDay() &&
                                 date.getMonth() === selectedDate.getMonth() &&
                                 date.getYear() === selectedDate.getYear();

    const dayEnd = new Date(selectedDate.getTime());
    dayEnd.setHours(23);
    dayEnd.setMinutes(59);

    const partDayEntries = [];

    let customEventsPartDay = [];
    customEventsPartDay = getPartDayCustomEvent(data.customEvents);

    for (let customEvent of customEventsPartDay){
        partDayEntries.push({
            type: CalendarContent.CustomEvent,
            description: getCustomEventDescriptionDay(customEvent),
            startDate: customEvent.startDate,
            endDate: customEvent.endDate,
        })
    }
    return partDayEntries;
}

export const getTopOffset = (start, dayStart) =>{
    return (new Date(start)?.getTime() - new Date(dayStart)?.getTime()) / 24 / 60 / 60 / 10;
}

export const getHeight = (start, end) =>{
     return (new Date(end)?.getTime() - new Date(start)?.getTime()) / 24 / 60 / 60 / 10;
} 

export const renderFullDayEntries = (fullDayEntries, onClick = ()=>{}, popover = false, messageStyled = false, parentRef) =>{
    if(!fullDayEntries){
        return <></>
    }
    return fullDayEntries.map(entry => 
                                <Badge className={ClassFromContent[entry.type]}
                                        src={SourceFromContent[entry.type]}
                                        message={entry.description}
                                        onClick={onClick}
                                        popover={popover}
                                        messageStyled={messageStyled}
                                        parentRef={parentRef}
                                        width={85}
                                       />)
}

export const renderPartDayEntries = (partDayEntries, dayStart, canvasRef, onClick = ()=>{}, popover = false) => {
    if(!partDayEntries){
        return <></>
    }
    return  partDayEntries.map(entry => {
        return <Badge src={SourceFromContent[entry.type]}
                      className={ClassFromContent[entry.type]}
                      left={entry.left}
                      width={entry.width}
                      top={getTopOffset(entry.startDate, dayStart)}
                      height={getHeight(entry.startDate, entry.endDate)}
                      message={entry.description}
                      parentRef={canvasRef}
                      styled
                      onClick={onClick}
                      popover={popover}/>
    })
}

const getHoursInDay = ()=>{
    return Array(24).fill().map((v, i) => (`${i<10? '0':''}${i + ":00"}`));
}

export const renderCanvasBackground = (weekLines = false) =>{
    return getHoursInDay().map( hour => {
        return <div key={hour} className="canvas-background-hour"> 
                    <div className="time-event-section">
                        <div className="time">
                            {hour} 
                        </div>
                        <div className="event">
                            {weekLines && new Array(7).fill(1).map((v,i) =>{
                                return <div key = {i} className="canvas-vertical-line"/>
                            })}
                        </div>
                    </div>                             
                        <Divider></Divider>                                            
                </div>                        
        })
}

export default CalendarByDay;