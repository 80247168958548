import React, {useContext, useEffect, useState} from "react";
import {TreeView} from "@mui/lab";
import {Folder, FolderOpen} from "@mui/icons-material";
import ReferenceType, {HiddenReferenceType} from "../../../Constants/DocumentManagement/ReferenceType";
import {deleteFileFolder, getFileFolders, getFileReferences} from "../../../Services/Files/FileFolderService";
import DocumentList from "../../Document/Document/DocumentList";
import strings from "../../../localization";
import {Box, Drawer, Grid, Menu, MenuItem, Paper, Typography} from "@mui/material";
import DrawerContext from "../../../Context/DrawerContext";
import DrawerWrapper from "../../../Components/Layout/DrawerWrapper";
import AddFileFolder from "./AddFileFolder";
import PageState from "../../../Constants/Base/PageState";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import {useDispatch, useSelector} from "react-redux";
import EditFileFolder from "./EditFileFolder";
import StyledTreeItem, {ShowLess, ShowMore} from "../../../Components/Tree/StyledTreeItem";
import {getReferenceTypeCount} from "../../../Services/Files/ReferenceTypeService";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import { hasPermission } from "../../../Util/PermissionUtil";
import YesNoDialog, {YesNoDialogResult} from "../../../Components/Dialogs/YesNoDialog";
import SnackbarContext from "../../../Context/SnackbarContext";
import ForumIcon from '@mui/icons-material/Forum';
import { DotSmallIcon } from "../../../Util/ImageUtil";

const FileFolder = (props) => {
    const defaultData = {
        id: 'root', 
        name: 'ROOT',
        children: [], 
        endIcon: <ShowMore />, 
        collapseIcon: <ShowLess />, 
        openMenu: (e) => handleContextMenu(e),
        onClick: (child, {expanded}) => expanded.length>0 ? setExpanded([]): setExpanded(['root'])
    }
    const children = Object.keys(ReferenceType).map((key, i) => ({key, id: `root-${key}`, name: strings.constants.referenceTypes[key], referenceType: ReferenceType[key], children: [], onClick: (child, params) =>fetchByReference(child, params), endIcon: <ShowMore />, collapseIcon: <ShowLess />}));

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth)
    const [data, setData] = useState({...defaultData});
    const [selectedItem, setSelectedItem] = useState({});
    const [expanded, setExpanded] = useState(['root']);
    const [contextMenu, setContextMenu] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [page, setPage] = useState(PageState.View);
    const { innerWidth: width, innerHeight: height } = window;
    const [drawerTitle, setDrawerTitle] = useState('');
    const [referenceTypeCount, setReferenceTypeCount] = useState({})
    const permissionGroup = AppPermissions.FILE_FOLDER.GROUP;
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const { showMessage } = useContext(SnackbarContext);

    const value = {drawerTitle, setDrawerTitle}
    const isDrawerOpen = () => page !== PageState.View

    useEffect(() => {
        dispatch(changeBreadcrumbs({
            title: strings.pages.files.fileFolder.fileFolderList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.FILES},
                {label: strings.pages.files.fileFolder.fileFolderList.pageTitle}
            ],
            submenu: true,
        }));
    })

    useEffect(() => {
        getReferenceTypeCount().then(response => {
            if (response?.ok) {
                setReferenceTypeCount(response.data)
            }
        })
        if (data.children.length === 0) {
            fetchFolders();
        }
    },[]);


    const fetchFolders = () => {
        let newChildren = [...children]
        getFileFolders({}).then(response => {
            if (!response?.ok) {
                return;
            }
            response.data.forEach(folder => newChildren.unshift(folderItem(folder)))

            setData({...data, children: newChildren})
        });
    };

    const fetchFolderByParent = (parent, params) => {
        let newData = {...params.data};
        const expandedIndex = params.expanded.indexOf(parent.id)
        if (expandedIndex !== -1) {
            let newExpanded = [...params.expanded]
            newExpanded.splice(expandedIndex, 1);
            setExpanded(newExpanded)

            return;
        }

        const index = newData.children.indexOf(parent);
        getFileFolders({parentId: parent.folder.id}).then(response => {
            if (response?.ok) {
                parent.children = response.data.map(element => folderItem(element));
                newData.children[index] = parent;
                if (parent.children.length > 0) {
                    setExpanded([...params.expanded, parent.id]);
                }
                setData(newData);
            }
        });
    }

    const folderItem = (folder) => {
        return {id: `folder-${folder.id}`,
            name: folder.name,
            referenceType: HiddenReferenceType.FILE_FOLDER,
            openMenu: (e) => handleContextMenu(e, folder),
            children: [],
            onClick: (child, params) => {fetchFolderByParent(child, params); setSelectedItem({referenceType: HiddenReferenceType.FILE_FOLDER, referenceId: folder.id})},
            endIcon: folder.child_count > 0 && <Folder />,
            collapseIcon: <FolderOpen />,
            folder
        }
    }

    const fetchByReference = (child, params) => {
        setSelectedItem({});
        let newData = {...params.data};
        const expandedIndex = params.expanded.indexOf(child.id)
        if (expandedIndex !== -1) {
            let newExpanded = [...params.expanded]
            newExpanded.splice(expandedIndex, 1);
            setExpanded(newExpanded)
            return;
        }
        const index = newData.children.indexOf(child);
        getFileReferences(child).then(response => {
            if (response) {
                child.children = response.map(element => ({...element, reference: true, onClick: () => setSelectedItem({referenceType: element.referenceType, referenceId: element.entity.id})}));
                newData.children[index] = child;
                setData(newData);
                setExpanded([...params.expanded, child.id]);
            }
        });
    }

    const handleContextMenu = (event, folder) => {
        event.preventDefault();
        setContextMenu(contextMenu === null ? {mouseX: event.clientX - 2, mouseY: event.clientY - 4} : null);
        setSelectedFolder(folder)
    }
    const handleClose = () => {
        setContextMenu(null);
    };

    const checkNode = (node) => {
        return node.children?.length > 0 || node.folder || node.reference || referenceTypeCount[node.key] > 0;
    }

    const getNodeStyle = (node) => {
        let style = [];
        const disabledNode = 'disabled-tree-item';
        const parentNode = 'parent-tree-item';
        const childNode = 'child-tree-item';

        if(!checkNode(node)){
            style.push(disabledNode);
        }

        if(node.children){
            style.push(parentNode);
        }else{
            style.push(childNode);
        }

        return style.join(' ');
    }

    const isLastNode = (node) => {
        return !node.children;
    }

    const renderTree = (nodes) => (
        <StyledTreeItem 
            key={nodes.id} 
            nodeId={nodes.id} 
            labelText={nodes.name}
            labelIcon = {isLastNode(nodes) && DotSmallIcon} 
            onClick={() => nodes.onClick(nodes, {data, expanded})}
            endIcon={checkNode(nodes) && nodes.endIcon}
            expandIcon={nodes.endIcon}
            collapseIcon={nodes.collapseIcon} 
            className = {getNodeStyle(nodes)}
            openMenu = {nodes.openMenu}
        >
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </StyledTreeItem>
    );

    const editFolder = () => {
        setPage(PageState.Edit)
        handleClose();
    }

    const deleteFolder = () => {
        setShowDeleteDialog(true)
        handleClose();
    }

    const addFolder = () => {
        setPage(PageState.Add)
        handleClose();
    }

    const handleEdit = (item) => {
        setPage(PageState.View);
        if (item) {
            let newData = {...data};
            let selectedItem = searchTree(newData, item.id);
            selectedItem.name = item.name;
            setData(newData);
        }
    }

    const handleAdd = (item) => {
        setPage(PageState.View);
        if (item) {
            let newData = {...data};
            if (item.parent) {
                let selectedItem = searchTree(newData, item.parent.id);
                selectedItem.endIcon = <Folder/>;
                selectedItem.children.unshift(folderItem(item))
            } else {
                newData.children.unshift(folderItem(item))
            }
            setData(newData);
        }
    }

    const handleDelete = (result, item) => {
        if (result === YesNoDialogResult.NO || result === YesNoDialogResult.CANCEL) {
            setShowDeleteDialog(false);
            return;
        }
        if (!item) {
            setShowDeleteDialog(false);
            return;
        }
        deleteFileFolder(item.id).then(response => {
            if (!response || !response.ok) {
                showMessage(strings.components.tablePage.errorDeletingItem, 'error')
                setShowDeleteDialog(false);

                return;
            }
            showMessage(strings.components.tablePage.itemDeleted, 'success')
            let newData = {...data};
            searchTree(newData, item.id, true);
            let parent = searchTree(newData, item.parent?.id);
            if (parent && item.parent?.id) {
                parent.children = parent.children.filter(child => child.folder.id !== item.id);
            }
            setData(newData);
            setShowDeleteDialog(false);
        })

    }

    function searchTree(element, id, removeItem = false){
        if(element?.folder?.id === id){
            return element;
        }else if (element.children != null){
            let i;
            let result = null;
            for(i=0; result == null && i < element.children.length; i++){
                result = searchTree(element.children[i], id);
            }
            if (result && removeItem) {
                element.children.splice(element.children.indexOf(result), 1);
            }
            return result;
        }
        return null;
    }

    return (<DrawerContext.Provider value={value}>
            <YesNoDialog show={showDeleteDialog}
                         payload={selectedFolder}
                         handleResult={handleDelete}
                         title={strings.components.tablePage.confirmDelete}
                         text={strings.components.tablePage.confirmDeleteMessage}/>

            <Grid container spacing={1} id={'folder-container'}>
                <Grid item xs={3}>
                    <Paper>
                        {   hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].VIEW, auth.permissions) &&
                            <TreeView 
                                aria-label="rich object" 
                                expanded={expanded} 
                                defaultExpanded={expanded} 
                                sx={{ flexGrow: 1, width: '100%',height: height - 55, overflowY: 'auto' , overflowX: 'hidden'}}
                            >
                                {renderTree(data)}
                            </TreeView>
                        }
                        <Menu
                            open={contextMenu !== null}
                            onClose={handleClose}
                            anchorReference="anchorPosition"
                            anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
                            {
                                hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].ADD, auth.permissions) &&
                                <MenuItem onClick={addFolder}>{strings.pages.files.fileFolder.fileFolderList.addFolder}</MenuItem>
                            }
                            {
                                hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].EDIT, auth.permissions) && selectedFolder && (
                                    <Box>
                                        <MenuItem onClick={editFolder}>{strings.pages.files.fileFolder.fileFolderList.editFolder}</MenuItem>
                                    </Box>)
                            }
                            {
                                hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].DELETE, auth.permissions) && selectedFolder && (
                                    <Box>
                                        <MenuItem onClick={deleteFolder}>{strings.pages.files.fileFolder.fileFolderList.deleteFolder}</MenuItem>
                                    </Box>)
                            }
                        </Menu>
                    </Paper>
                </Grid>
                <Grid item xs={9}>
                    {selectedItem?.referenceId && <DocumentList referenceType={selectedItem?.referenceType} id={selectedItem?.referenceId}/>}
                </Grid>
            </Grid>
            <Drawer id='drawer' anchor='right' open={isDrawerOpen()} onClose={() => setPage(PageState.View)}>
                <DrawerWrapper onBack={() => setPage(PageState.View)} title={drawerTitle}>
                    {
                        page === PageState.Add &&
                        <AddFileFolder parent={selectedFolder} onCancel={() => setPage(PageState.View)} onFinish={handleAdd}/>
                    }
                    {
                        page === PageState.Edit &&
                        <EditFileFolder folder={selectedFolder} onCancel={() => setPage(PageState.View)} onFinish={handleEdit}/>
                    }
                </DrawerWrapper>
            </Drawer>

        </DrawerContext.Provider>
    );
}

export default FileFolder;
