import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getCandidates(data) {
    return await request('/api/candidate', data);
}

export async function getAllCandidates() {
    return await request('/api/candidate/all');
}

export async function getCandidate(id) {
    return await request('/api/candidate/' + id);
}

export async function getJobCandidates(data) {
    return await request('/api/job/candidate', data);
}

export async function addCandidate(data) {
    return await request('/api/candidate', transformRequestData(data), HttpMethod.POST);
}

export async function getAllUsers(data) {
    return await request('/api/users/employees/all', data);
}

export async function getAllJobs() {
    return await request('/api/job/all');
}

export async function getAllCandidateSources() {
    return await request('/api/candidate-source/all');
}

export async function updateCandidateStage(data) {
    return await request('/api/candidate/stage', data, HttpMethod.PATCH);
}

export async function editCandidate(data) {
    return await request('/api/candidate/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteCandidate(id) {
    return await request('/api/candidate/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        jobId: data.job ? data.job.id : null,
        responsibleHrManagerId: data.responsibleHrManager ? data.responsibleHrManager.id : null,
        candidateSourceId: data.candidateSource ? data.candidateSource.id : null,
        candidateStatusId: data.candidateStatus ? data.candidateStatus.id : null
    }
}