import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState, useContext} from "react";
import PageState from "../../../Constants/Base/PageState";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import strings from "../../../localization";
import TablePage from "../../../Components/DataGrid/TablePage";
import {
    deleteInvoice,
    getInvoices,
    duplicateInvoice,
    viewInvoice,
    viewInoInvoice, downloadInvoice, downloadInoInvoice
} from "../../../Services/Finance/InvoiceService";
import * as React from "react";
import {
    renderColumnInvoiceStatus, renderColumnLink,
    renderColumnNumber,
    renderColumnOverdue, renderEColumnInvoiceStatus
} from "../../../Components/DataGrid/ValueCellRender";
import {
    formatColumnConstant,
    formatColumnDate,
    formatColumnObject
} from "../../../Components/DataGrid/ValueFormatter";
import InvoiceType, {getInvoiceTypes, getInvoiceTypeString} from "../../../Constants/Finance/InvoiceType";
import {getInvoiceStatuses} from "../../../Constants/Finance/InvoiceStatus";
import {getPartner, getPartners} from "../../../Services/CRM/PartnerService";
import {useNavigate, useSearchParams} from "react-router-dom";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import SnackbarContext from "../../../Context/SnackbarContext";
import StatisticComponent from "../../../Components/DataGrid/StatisticComponent";
import {renderNumberWithLabel} from "../../../Util/RenderDataUtil";
import {getCurrentYear} from "../../../Util/DateUtil";
import InvoiceDirection from "../../../Constants/Finance/InvoiceDirection";
import {IconButton, Tooltip} from "@mui/material";
import {
    sendOutEInvoice,
    syncEInvoiceIn,
    syncEInvoiceOut,
    syncEInvoicesIn,
    syncEInvoicesOut
} from "../../../Services/Finance/EInvoiceService";
import {EInvoiceOutStatus} from "../../../Constants/Finance/EInvoiceOutStatus";
import {EInvoiceInStatus} from "../../../Constants/Finance/EInvoiceInStatus";
import CancelInvoice from "./CancelInvoice";
import StornoInvoice from "./StornoInvoice";
import {hasPermission} from '../../../Util/PermissionUtil';
import {fetchOptions} from '../../../Services/Base/FilterOptionsService';
import PartnerType from "../../../Constants/CRM/PartnerType";
import {id} from "date-fns/locale";
import partnerList from "../../CRM/Partner/PartnerList";
import {isInoInvoice} from "../../../Util/InvoiceUtil";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import AcceptRejectInvoice from "./AcceptRejectInvoice";


const InvoiceList = ({invoiceDirection}, props) => {

    const tableDescription = [

        {
            field: 'eInvoiceStatus', headerName: strings.pages.finance.invoice.invoiceList.eInvoiceStatus, width: 120,
            renderCell: (params) => renderEColumnInvoiceStatus(params),
            align: 'center'
        },
        {
            field: 'eInvoiceControls',
            headerName: strings.pages.finance.invoice.invoiceList.eInvoiceControls, width: 150,
            renderCell: (params) => renderEInvoiceControls(params),
            align: 'center'
        },
        {
            field: 'invoiceStatus', headerName: strings.pages.finance.invoice.invoiceList.invoiceStatus, width: 120,
            renderCell: (params) => renderColumnInvoiceStatus(params)
        },
        {field: 'invoiceNumber', headerName: strings.pages.finance.invoice.invoiceList.invoiceNumber, width: 130},
        {
            field: 'paidAmount', headerName: strings.pages.finance.invoice.invoiceList.paidAmount, width: 110,
            renderCell: (params) => renderColumnNumber(params),
        },
        {
            field: 'invoiceType', headerName: strings.pages.finance.invoice.invoiceList.invoiceType, width: 105,
            valueFormatter: (params) => formatColumnConstant(params, getInvoiceTypeString),
        },
        {
            field: 'partner',
            headerName: strings.pages.finance.invoice.invoiceList.partner,
            valueFormatter: (params) => formatColumnObject(params, 'name'),
            width: 200
        },
        {
            field: 'project',
            headerName: strings.pages.finance.invoice.invoiceList.project,
            renderCell: (params) => renderColumnLink(
                hasPermission(auth.user, projectPermissionGroup.GROUP, projectPermissionGroup.VIEW, auth.permissions),
                formatColumnObject(params, 'name'),
                `/project-details/${params.value?.id}/project-time-log`,
                {project: params.value}
            ),
            width: 200
        },
        {
            field: 'invoiceDate', headerName: strings.pages.finance.invoice.invoiceList.invoiceDate, width: 105,
            valueFormatter: (params) => formatColumnDate(params),
        },
        {
            field: 'paymentDueDate', headerName: strings.pages.finance.invoice.invoiceList.paymentDueDate, width: 145,
            renderCell: (params) => formatColumnDate(params),
        },
        {
            field: 'paymentOverdue', headerName: strings.pages.finance.invoice.invoiceList.paymentOverdue, width: 145,
            renderCell: (params) => renderColumnOverdue(params),
        },
        {
            field: 'lastPaymentDate', headerName: strings.pages.finance.invoice.invoiceList.lastPaymentDate, width: 150,
            valueFormatter: (params) => formatColumnDate(params),
        },
        {
            field: 'amount', headerName: strings.pages.finance.invoice.invoiceList.amount, width: 100,
            renderCell: (params) => renderColumnNumber(params),
        },
        {
            field: 'taxAmount', headerName: strings.pages.finance.invoice.invoiceList.taxAmount, width: 110,
            renderCell: (params) => renderColumnNumber(params),
        },
        {
            field: 'total', headerName: strings.pages.finance.invoice.invoiceList.total, width: 110,
            renderCell: (params) => renderColumnNumber(params),
        },
        {
            field: 'currency',
            headerName: strings.pages.finance.invoice.invoiceList.currency,
            valueFormatter: (params) => formatColumnObject(params, 'code'),
            width: 90
        },
        {
            field: 'exchangeRate', headerName: strings.pages.finance.invoice.invoiceList.exchangeRate, width: 110,
            renderCell: (params) => renderColumnNumber(params),
        },
    ];


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState({...FilterDefaults, year: getCurrentYear()});
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(false);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState({
        ...TablePageOptionDefaults,
        showYearFilter: true,
        showMonthFilter: true
    });
    const [filterOptions, setFilterOptions] = useState({});
    const referenceType = ReferenceType.INVOICE;
    const permissionGroup = AppPermissions.INVOICE.GROUP;
    const projectPermissionGroup = AppPermissions.PROJECT;
    const [customPage, setCustomPage] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const {showMessage} = useContext(SnackbarContext);
    const [resetForm, setResetForm] = useState(false);

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    const fetchPartners = async (term, filterFetch) => {
        return fetchOptions('partner', searchParams, getPartners, getPartner, setFilterOptions, term, filterFetch);
    }

    const fetchFilters = async() => {
        const partners = await fetchPartners();

        setFilterOptions({
            invoiceType: getInvoiceTypes(),
            invoiceStatus: getInvoiceStatuses(),
            partner: partners,
            fetched: 1,
        });
    }

    const filters = [
        {
            name: 'invoiceType',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'invoiceType',
            label: strings.pages.finance.invoice.invoiceList.invoiceType,
            showSelect: true
        },
        {
            name: 'invoiceStatus',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'invoiceStatus',
            label: strings.pages.finance.invoice.invoiceList.invoiceStatus,
            showSelect: true
        },
        {
            name: 'partner',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'partner',
            label: strings.pages.finance.invoice.invoiceList.partner,
            onChange: (term) => fetchPartners(term, true),
        }
    ]

    useEffect(() => {
        const title = invoiceDirection === InvoiceDirection.OUT ? strings.pages.finance.invoice.invoiceList.invoices : strings.pages.finance.invoice.invoiceList.bills
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: title,
            hierarchy: [
                {label: strings.navigation.managmentTag},
                {label: Modules.FINANCE},
                {label: title},
            ],
        }));

        if(!searchParams.toString()){
            setSearchParams({year: getCurrentYear()}, {replace: true});
            setFilter({...FilterDefaults, year: getCurrentYear()});
            setResetForm(true);
        }

    }, [invoiceDirection])

    useEffect(() => {
        fetchFilters();
    },[]);

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter, invoiceDirection])

    const additionalMenuItems = [
        {
            handleClick: (id, item) => handleInvoiceDuplicate(id, item),
            text: strings.pages.finance.invoice.invoiceList.duplicate,
            src: "/images/table-page/duplicate.svg"
        },
    ]

    const getAdditionalMenuItems = () => {

        let result = [
            {
                handleClick: (id, item) => handleInvoiceDuplicate(id, item),
                text: strings.pages.finance.invoice.invoiceList.duplicate,
                src: "/images/table-page/duplicate.svg"
            }
        ]

        if(invoiceDirection === InvoiceDirection.OUT) {

            result.push({
                handleClick: (id, item) => handleInvoicePDFView(id, item),
                text: strings.pages.finance.invoice.invoiceList.download,
                src: "/images/table-page/download-pdf.svg"
            })
        }

        return result
    }

    const renderEInvoiceControls = (params) => {

        if(isInoInvoice(params.row, auth.user)) {
            return ''
        }

        return <React.Fragment>
            {
                canSyncEInvoice(params.row) &&
                <Tooltip title={strings.pages.finance.invoice.invoiceList.syncEInvoice}>
                    <img onClick={() => handleEInvoiceSync(params.row)} src={'/images/icons/grey/reload.svg'}/>
                </Tooltip>
            }

            {
                canAcceptEInvoice(params.row) &&
                <Tooltip title={strings.pages.finance.invoice.invoiceList.accept}>
                    <img onClick={() => handleAcceptEInvoice(params.row)} src={'/images/icons/grey/accept.svg'}/>
                </Tooltip>
            }

            {
                canRejectEInvoice(params.row) &&
                <Tooltip title={strings.pages.finance.invoice.invoiceList.reject}>
                    <img onClick={() => handleRejectEInvoice(params.row)} src={'/images/icons/grey/reject.svg'}/>
                </Tooltip>
            }

            {
                canSendEInvoice(params.row) &&
                <Tooltip title={strings.pages.finance.invoice.invoiceList.send}>
                    <img onClick={() => handleSendEInvoice(params.row)} src={'/images/icons/grey/send.svg'}/>
                </Tooltip>
            }

            {
                canCancelEInvoice(params.row) &&
                <Tooltip title={strings.pages.finance.invoice.invoiceList.cancel}>
                    <img onClick={() => handleCancelEInvoice(params.row)} src={'/images/icons/grey/cancel.svg'}/>
                </Tooltip>
            }

            {
                canStornoEInvoice(params.row) &&
                <Tooltip title={strings.pages.finance.invoice.invoiceList.storno}>
                    <img onClick={() => handleStornoEInvoice(params.row)} src={'/images/icons/grey/storno.svg'}/>
                </Tooltip>
            }

        </React.Fragment>
    }

    const canStornoEInvoice = (invoice) => {

        if(invoiceDirection === InvoiceDirection.IN) {
            return false;
        }

        return invoice?.eInvoiceStatus === EInvoiceOutStatus.SENT ||
            invoice?.eInvoiceStatus === EInvoiceOutStatus.APPROVED || invoice?.eInvoiceStatus === EInvoiceOutStatus.REJECTED
    }

    const canSyncEInvoice = (invoice) => {
        return (invoiceDirection === InvoiceDirection.OUT && invoice.eInvoiceOutId)
            || (invoiceDirection === InvoiceDirection.IN && invoice.eInvoiceInId);
    }

    const canAcceptEInvoice = (invoice) => {
        if(invoiceDirection === InvoiceDirection.OUT) {
            return false;
        }

        return invoice?.eInvoiceStatus === EInvoiceInStatus.RECEIVED || invoice?.eInvoiceStatus === EInvoiceInStatus.SEEN
    }

    const canRejectEInvoice = (invoice) => {

        if(invoiceDirection === InvoiceDirection.OUT) {
            return false;
        }

        return invoice?.eInvoiceStatus === EInvoiceInStatus.RECEIVED || invoice?.eInvoiceStatus === EInvoiceInStatus.SEEN
    }

    const canSendEInvoice = (invoice) => {

        if (invoiceDirection === InvoiceDirection.IN) {
            return false;
        }

        return invoice?.eInvoiceStatus === EInvoiceOutStatus.NEW
    }

    const canCancelEInvoice = (invoice) => {

        if(invoiceDirection === InvoiceDirection.IN) {
            return false;
        }

        return invoice?.eInvoiceStatus === EInvoiceOutStatus.DRAFT
    }

    const handleEInvoiceSync = (invoice) => {

        if (invoice.invoiceDirection === InvoiceDirection.OUT) {
            syncEInvoiceOut(invoice.id).then(response => {
                fetch()
            })
        } else {
            syncEInvoiceIn(invoice.id).then(response => {
                fetch()
            })
        }
    }

    const handleAcceptEInvoice = (invoice) => {
        setPageState(PageState.Custom)
        setCustomPage(<AcceptRejectInvoice accept={true} invoice={invoice} onFinish={onFinish} onCancel={onCancel}/>)
    }

    const handleRejectEInvoice = (invoice) => {
        setPageState(PageState.Custom)
        setCustomPage(<AcceptRejectInvoice accept={true} invoice={invoice} onFinish={onFinish} onCancel={onCancel}/>)
    }

    const handleSendEInvoice = (invoice) => {
        sendOutEInvoice(invoice.id).then(response => {
            fetch()
        })
    }

    const handleCancelEInvoice = (invoice) => {
        setPageState(PageState.Custom)
        setCustomPage(<CancelInvoice invoice={invoice} onFinish={onFinish} onCancel={onCancel}/>)
    }

    const handleStornoEInvoice = (invoice) => {
        setPageState(PageState.Custom)
        setCustomPage(<StornoInvoice invoice={invoice} onFinish={onFinish} onCancel={onCancel}/>)
    }

    const handleInvoiceDuplicate = (id, item) => {

        setShowLoader(true);

        duplicateInvoice(item).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            const invoice = response.data.entity;
            navigate('/create-invoice', {
                state: {id: invoice.id, invoiceDirection: invoice.invoiceDirection, pageState: PageState.Edit}
            });
        });

    }

    const handleInvoicePDFView = (id, item) => {
        setShowLoader(true);

         if(!isInoInvoice(item, auth)) {
              downloadInvoice(id);
         }else{
             downloadInoInvoice(id);
         }
    }

    useEffect(() => {

        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));

        fetchPartners();
        fetch();

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        const title = invoiceDirection === InvoiceDirection.OUT ? strings.pages.finance.invoice.invoiceList.invoices : strings.pages.finance.invoice.invoiceList.bills
        setColumns([...tableDescription]);
        dispatch(changeBreadcrumbs({
            title: title,
            hierarchy: [
                {label: strings.navigation.managmentTag},
                {label: Modules.FINANCE},
                {label: title},
            ],
        }));
        fetch();
    }, [filter, invoiceDirection])



    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const handleEdit = (props) => {
        navigate('/create-invoice', {
            state: {id: props, invoiceDirection: invoiceDirection, pageState: PageState.Edit}
        });
    }

    const handleView = (props) => {
        navigate('/create-invoice', {
            state: {id: props.id, invoiceDirection: invoiceDirection, pageState: PageState.ViewDetails}
        });
    }

    const handleAdd = () => {
        navigate('/create-invoice', {
            state: {invoiceDirection: invoiceDirection, pageState: PageState.Add}
        });
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getInvoices({
            ...filter,
            invoiceDirection: invoiceDirection
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    const calculateStatistics = (data) => {

        let amount = {
            'RSD': 0,
            'EUR': 0,
            'USD': 0
        }

        let tax = {
            'RSD': 0,
            'EUR': 0,
            'USD': 0
        }

        let total = {
            'RSD': 0,
            'EUR': 0,
            'USD': 0
        }

        let paidAmount = {
            'RSD': 0,
            'EUR': 0,
            'USD': 0
        }

        let balance = {
            'RSD': 0,
            'EUR': 0,
            'USD': 0
        }

        for (let item of data) {
            amount[item.currency.code] += parseFloat(item.amount);
            tax[item.currency.code] += parseFloat(item.taxAmount);
            total[item.currency.code] += item.total;
            paidAmount[item.currency.code] += item.paidAmount;
            balance[item.currency.code] += item.total - item.paidAmount;
        }

        return [
            {
                title: strings.pages.finance.invoice.invoiceList.amount,
                size: 4,
                data: [
                    {value: renderNumberWithLabel(amount.RSD, 'RSD: ')},
                    {value: renderNumberWithLabel(amount.EUR, 'EUR: ')},
                    {value: renderNumberWithLabel(amount.USD, 'USD: ')}
                ]
            },
            {
                title: strings.pages.finance.invoice.invoiceList.taxAmount,
                size: 4,
                data: [
                    {value: renderNumberWithLabel(tax.RSD, 'RSD: ')},
                    {value: renderNumberWithLabel(tax.EUR, 'EUR: ')},
                    {value: renderNumberWithLabel(tax.USD, 'USD: ')}
                ]
            },
            {
                title: strings.pages.finance.invoice.invoiceList.total,
                size: 4,
                data: [
                    {value: renderNumberWithLabel(total.RSD, 'RSD: ')},
                    {value: renderNumberWithLabel(total.EUR, 'EUR: ')},
                    {value: renderNumberWithLabel(total.USD, 'USD: ')}
                ]
            },
            {
                title: strings.pages.finance.invoice.invoiceList.paidAmount,
                size: 4,
                data: [
                    {value: renderNumberWithLabel(paidAmount.RSD, 'RSD: ')},
                    {value: renderNumberWithLabel(paidAmount.EUR, 'EUR: ')},
                    {value: renderNumberWithLabel(paidAmount.USD, 'USD: ')}
                ]
            },
            {
                title: strings.pages.finance.invoice.invoiceList.balance,
                size: 4,
                data: [
                    {value: renderNumberWithLabel(balance.RSD, 'RSD: ')},
                    {value: renderNumberWithLabel(balance.EUR, 'EUR: ')},
                    {value: renderNumberWithLabel(balance.USD, 'USD: ')}
                ]
            }

        ]
    }

    const renderStatisticComponent = () => {
        return <div id="dashboard">
            <StatisticComponent data={calculateStatistics(tableData.data)}/>
        </div>
    }

    const handleSync = (invoiceDirection) => {

        if (invoiceDirection === InvoiceDirection.OUT) {
            syncEInvoicesOut().then(response => {
                fetch()
            })
        } else {
            syncEInvoicesIn().then(response => {
                fetch()
            })
        }
    }

    const renderControls = () => {
        return <IconButton className='add-button' onClick={() => handleSync(invoiceDirection)}>
            {
                strings.pages.finance.invoice.invoiceList.syncEInvoice
            }
        </IconButton>
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteInvoice} tableDescription={columns}
                   tableData={tableData} filter={filter}
                   referenceType={referenceType}
                   permissionGroup={permissionGroup}
                   additionalMenuItems={getAdditionalMenuItems()}
                   customPage={customPage}
                   handleEdit={handleEdit} handleAdd={handleAdd}
                   filters={filters} filterOptions={filterOptions}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={handleEdit}
                   handleView={handleView}
                   resetForm = {resetForm}
                   setResetForm = {setResetForm}
                   controls={renderControls()}
                   underBreadcrumbsSlot={renderStatisticComponent()}
                   addButtonText={invoiceDirection === InvoiceDirection.OUT ? strings.components.tablePage.addButton.invoice : strings.components.tablePage.addButton.bill}
        />
    </TablePageContext.Provider>;

}

export default InvoiceList;
