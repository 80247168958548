import strings from "../../localization";

const OrganisationUserContractType = {
    CONTRACT: 1,
    ANNEX: 2,
    BONUS: 3,
    TRANSPORTATION_COSTS: 4,
    BUSINESS_TRIP: 5,
}

export function getOrganisationUserContractTypes() {
    return [
        {
            id: OrganisationUserContractType.CONTRACT,
            name: getOrganisationUserContractTypeString(OrganisationUserContractType.CONTRACT)
        },
        {
            id: OrganisationUserContractType.ANNEX,
            name: getOrganisationUserContractTypeString(OrganisationUserContractType.ANNEX)
        },
        {
            id: OrganisationUserContractType.BONUS,
            name: getOrganisationUserContractTypeString(OrganisationUserContractType.BONUS)
        },
        {
            id: OrganisationUserContractType.TRANSPORTATION_COSTS,
            name: getOrganisationUserContractTypeString(OrganisationUserContractType.TRANSPORTATION_COSTS)
        },
        {
            id: OrganisationUserContractType.BUSINESS_TRIP,
            name: getOrganisationUserContractTypeString(OrganisationUserContractType.BUSINESS_TRIP)
        },

    ];
}

export function getOrganisationUserContractTypeString(organisationUserContractType) {
    switch(organisationUserContractType) {
        case OrganisationUserContractType.CONTRACT: return strings.constants.hr.organisationUserContractType.CONTRACT
        case OrganisationUserContractType.ANNEX: return strings.constants.hr.organisationUserContractType.ANNEX
        case OrganisationUserContractType.BONUS: return strings.constants.hr.organisationUserContractType.BONUS
        case OrganisationUserContractType.TRANSPORTATION_COSTS: return strings.constants.hr.organisationUserContractType.TRANSPORTATION_COSTS
        case OrganisationUserContractType.BUSINESS_TRIP: return strings.constants.hr.organisationUserContractType.BUSINESS_TRIP
    }
}

export default OrganisationUserContractType;