import {createSlice} from "@reduxjs/toolkit";

export const breadcrumbsDefault = {
    title: '',
    hierarchy: [],
    submenu: false
}

export const breadcrumbsSlice = createSlice({
    name: 'breadcrumbs',
    initialState: {
        data: breadcrumbsDefault,
    },
    reducers: {
        changeBreadcrumbs: (state, action) => {
            state.data = action.payload;
        },
    }
});

export const { changeBreadcrumbs } = breadcrumbsSlice.actions

export default breadcrumbsSlice.reducer
