import {useDispatch, useSelector} from "react-redux";
import React, {useContext, useEffect} from "react";
import strings from "../../../localization";
import { Grid, Paper} from "@mui/material";
import SnackbarContext from "../../../Context/SnackbarContext";
import { getHRSettings, updateHRSettings } from "../../../Services/HR/HRSettingsService";
import HRBaseSettingsForm from "../../../Components/Forms/Pages/HR/HRBaseSettings/HRBaseSettingsForm";
import { useForm } from "react-hook-form";
import Modules from "../../../Constants/Base/Modules";
import { changeBreadcrumbs } from "../../../Slices/BreadcrumbsSlice";

const HRBaseSettings = () => {

    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch();
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: {
            internalHrOnly: false
        }
    });
    const {data, control, handleSubmit, getValues, setValue, reset, formState: {errors}} = form;

    useEffect(() => {
        dispatch(changeBreadcrumbs({
            title: strings.pages.hr.settings.baseSettings,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.pages.hr.settings.settings},
                {label: strings.pages.hr.settings.baseSettings},
            ],        
        }));
        fetch();
    }, []);

    const fetch = () => {
        getHRSettings(auth.company.id).then(response => {
            if(!response || !response.ok) {
                return;
            }
            reset(response.data.entity)
        })
    }

    const save = (data) => {
        updateHRSettings(data).then(response => {

            if (!response || !response.ok){
                showMessage(strings.commonMessages.errorAdding, 'error');
                return;
                
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            fetch();
        });
    }

    return <Grid container justifyContent="center"
                 alignItems="center">
                <Grid item xs={4}>
                    <Paper className='paper rounded-2'>
                            <HRBaseSettingsForm 
                                control={control}
                                values={getValues()} setValue={setValue}
                                data={data} form={form}
                                onSubmit={handleSubmit(save)}
                            />
                    </Paper>
                </Grid>
            </Grid>
}

export default HRBaseSettings;