import React, { useEffect, useState } from 'react';
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Drawer} from "@mui/material";
import {getCurrencyNumber, getPercentNumber, renderNumber} from "../../../../../Util/RenderDataUtil";
import strings from "../../../../../localization";
import {getAmountWithDiscount, getAmountWithVAT} from "../../../../../Util/InvoiceUtil";
import DrawerContext from "../../../../../Context/DrawerContext";
import PageState from "../../../../../Constants/Base/PageState";
import DrawerWrapper from "../../../../../Components/Layout/DrawerWrapper";
import EditInvoiceItem from '../../../../../Pages/Finance/Invoice/EditInvoiceItem';
import InvoiceDirection from "../../../../../Constants/Finance/InvoiceDirection";



const InvoiceItemsTable = ({invoiceData, invoiceId, deleteItem, setInvoiceData}) =>{
    const [selectedItem, setSelectedItem] = useState(null);
    const [drawerTitle, setDrawerTitle] = useState('');
    const [pageState, setPageState] = useState(0);
    const value = {drawerTitle, setDrawerTitle}

    useEffect(() => {
        if(invoiceId == -1){
            invoiceData.items = [];
        }

    }
    ,[invoiceId])

    const editItem = (item) => {
        setSelectedItem(item);
        setPageState(PageState.Edit);
    }

    const isDrawerOpen = () => {
        return pageState !== PageState.View;
    }

    const onFinish = (data) => {
        const newInvoiceItems = invoiceData.items.map((item) => item.id === data.id ? {...item, ...data} : item );
        setInvoiceData({...invoiceData, items: newInvoiceItems});
        setPageState(PageState.View);
    }

    const onCancel = () => {
        setSelectedItem(null);
        setPageState(PageState.View);
    }

    const isTableEmpty = () => {
        if(!invoiceData?.items.length){
            return true;
        }

        return !invoiceData.items.find((item) => !item.deleted);
    }

    const renderItems = (invoiceData, deleteItem) => {

        let cells = [];
        let i = 1;

        if (!invoiceData.items) {
            return '';
        }

        for (let item of invoiceData.items.filter(item => !item.deleted)) {

            cells.push(
                <TableRow key={'invoice-item-' + i}>
                    <TableCell>
                        {i}
                    </TableCell>
                    <TableCell>
                        {item.name}
                    </TableCell>
                    <TableCell>
                        {item.unit ? item.unit.symbol : ''}
                    </TableCell>
                    <TableCell>
                        {renderNumber(item.quantity)}
                    </TableCell>
                    <TableCell>
                        {getCurrencyNumber(item.price, invoiceData.currency ? invoiceData.currency : undefined)}
                    </TableCell>
                    <TableCell>
                        {getCurrencyNumber(item.price * item.quantity, invoiceData.currency ? invoiceData.currency : undefined)}
                    </TableCell>
                    <TableCell>
                        {getPercentNumber(item.discount)}
                    </TableCell>
                    <TableCell>
                        {getCurrencyNumber(getAmountWithDiscount(item), invoiceData.currency ? invoiceData.currency : undefined)}
                    </TableCell>
                    <TableCell>
                        {(item.tax.value ? item.tax.value : item.tax)  + '%'}
                    </TableCell>
                    <TableCell>
                        {getCurrencyNumber(getAmountWithVAT(item), invoiceData.currency ? invoiceData.currency : undefined)}
                    </TableCell>
                    <TableCell>
                        <IconButton onClick={() => editItem(item)}>
                            <img src="/images/table-page/edit.svg" />
                        </IconButton>
                        <IconButton onClick={() => deleteItem(item)}>
                            <img src="/images/table-page/delete.svg" />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );

            i = i + 1;
        }

        return cells;
    }
                               
    return(
        <DrawerContext.Provider value = {value}>
            <div id="invoice-items-table">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {strings.forms.finance.invoice.invoiceItemsTable.no}
                                </TableCell>
                                <TableCell>
                                    {strings.forms.finance.invoice.invoiceItemsTable.name}
                                </TableCell>
                                <TableCell>
                                    {strings.forms.finance.invoice.invoiceItemsTable.unit}
                                </TableCell>
                                <TableCell>
                                    {strings.forms.finance.invoice.invoiceItemsTable.quantity}
                                </TableCell>
                                <TableCell>
                                    {strings.forms.finance.invoice.invoiceItemsTable.price}
                                </TableCell>
                                <TableCell>
                                    {strings.forms.finance.invoice.invoiceItemsTable.amount}
                                </TableCell>
                                <TableCell>
                                    {strings.forms.finance.invoice.invoiceItemsTable.discount}
                                </TableCell>
                                <TableCell>
                                    {strings.forms.finance.invoice.invoiceItemsTable.amountWithDiscount}
                                </TableCell>
                                <TableCell>
                                    {strings.forms.finance.invoice.invoiceItemsTable.vat}
                                </TableCell>
                                <TableCell>
                                    {strings.forms.finance.invoice.invoiceItemsTable.amountWithVat}
                                </TableCell>
                                <TableCell>

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {!isTableEmpty() ?
                            <TableBody>
                                {renderItems(invoiceData, deleteItem)}
                            </TableBody>
                            :
                            <>
                            </>
                        }
                    </Table>
                        {isTableEmpty() &&
                            <div className = 'empty-invoice-items-table'>
                                <p>{strings.pages.finance.invoice.createInvoice.noRows}</p>
                            </div>
                        }
                </TableContainer>
            </div>
            <Drawer id='drawer' anchor='right' open={isDrawerOpen()} onClose={() => setPageState(PageState.View)}>
                <DrawerWrapper onBack={() => setPageState(PageState.View)} title={drawerTitle} viewDetails={pageState === PageState.ViewDetails}>
                    {
                        pageState === PageState.Edit && <EditInvoiceItem 
                                                            invoiceItem = {selectedItem} 
                                                            invoiceDirection = {invoiceData?.invoiceDirection}
                                                            currency = {invoiceData?.currency} 
                                                            onCancel = {onCancel} 
                                                            onFinish = {onFinish} 
                                                        />
                    }
                </DrawerWrapper>
            </Drawer>
        </DrawerContext.Provider>
    );
};

export default InvoiceItemsTable;
