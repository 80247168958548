import { useEffect } from "react";
import { useDispatch } from "react-redux";
import PageSizeState from "../Constants/Base/PageSizeState";
import { breadcrumbsDefault, changeBreadcrumbs } from "../Slices/BreadcrumbsSlice";
import { changePageSizeState } from "../Slices/PageSlice";

const Default = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs(breadcrumbsDefault))
    }, [])
    return ''
}
export default Default;