import { FormProvider } from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import Button from "@mui/material/Button";
import React from "react";
import SelectControl from "../../../../Controls/Inputs/SelectControl";

const BusinessTripForm = ({
  onSubmit,
  control,
  data,
  form,
  values,
  setValue,
  currencies,
}) => {
  return (
    <FormProvider {...form}>
      <form
        id="base-settings-form"
        className="form px-12 mui-shifted-label-input"
        action="#"
      >
        <SelectControl
          value={values["foreignDailyWageCurrency"]}
          setValue={setValue}
          name="foreignDailyWageCurrency"
          control={control}
          label={
            strings.pages.hr.businessTrip.businessTripForm
              .foreignDailyWageCurrency
          }
          options={currencies}
          nameKey={"code"}
          valueKey={"id"}
          className="mt-18 mb-1"
        />
        <TextFieldControl
          name="foreignDailyWage"
          control={data}
          type="number"
          label={
            strings.pages.hr.businessTrip.businessTripForm.foreignDailyWage
          }
          className="mt-10 mb-1"
        />
        <TextFieldControl
          name="domesticDailyWage"
          control={data}
          type="number"
          label={
            strings.pages.hr.businessTrip.businessTripForm.domesticDailyWage
          }
          className="mt-10 mb-1"
        />

        <div className="submit-container">
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            className="mt-1 mb-12"
          >
            {strings.forms.common.save}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default BusinessTripForm;
