import CompanyAccountType from "../Constants/Permissions/CompanyAccountType";
import ModulePermission from "../Constants/Permissions/ModulePermission";
import {AppPermissions} from "../Constants/Permissions/ModulePermission";

export const isFreeAccount = (company) => {
    if(!company || !company.accountSettings || !company.accountSettings.active) {
        return false;
    }

    return company.accountSettings.accountType === CompanyAccountType.FREE;
}

export const canAccess = (
                        company, 
                        module, 
                        user = undefined,
                        group = undefined, 
                        permission = undefined, 
                        permissions = [],
                        accessGroup = undefined,
                        accessId = null,
                    ) => {

    let hasAccess = hasModuleAccess(company, module);

    if(user && group && permission) {
        hasAccess = hasAccess && hasPermission(user, group, permission, permissions);
    }

    if(user && accessId && accessGroup){
        hasAccess = hasAccess && userHasAccess(accessGroup, accessId, user);
    }

    return hasAccess;
}

const userHasAccess = (accessGroup, accessId, user) => {
    if(user?.isOwner) {
        return true;
    }
    
    if(user.permissions[accessGroup] && user.permissions[accessGroup]['VIEW_ALL']){
        return true;
    }

    return user.accesses[accessGroup][accessId];
}

const hasModuleAccess = (company, module) => {

    if(!company || !company.accountSettings || !company.accountSettings.active || !company.accountSettings.trialUsed) {
        return false;
    }

    if(company.accountSettings.accountType === CompanyAccountType.TRIAL) {
        return true;
    }

    switch (module) {
        case ModulePermission.ASSET_MANAGEMENT: return company.accountSettings.assetManagementModule
        case ModulePermission.CRM: return company.accountSettings.crmModule
        case ModulePermission.COMPANY_DOCUMENT: return company.accountSettings.companyDocumentModule
        case ModulePermission.HR: return company.accountSettings.hrModule
        case ModulePermission.PRODUCT: return company.accountSettings.productModule
        case ModulePermission.DOCUMENT: return company.accountSettings.documentModule
        case ModulePermission.FINANCE: return company.accountSettings.financeModule
        case ModulePermission.PROJECT_MANAGEMENT: return company.accountSettings.projectManagementModule
        case ModulePermission.WORKSPACES: return company.accountSettings.workspacesModule
        case ModulePermission.ADMINISTRATION: return company.accountSettings.administrationModule
        case ModulePermission.DASHBOARD: return company.accountSettings.dashboardModule
        case ModulePermission.CALENDAR: return company.accountSettings.calendarModule
        case ModulePermission.HOME: return company.accountSettings.homeModule
        case ModulePermission.SPACES: return company.accountSettings.spacesModule

        default: return false
    }
}

export const hasPermission = (user, group, permission, permissions) => {
    if(user?.isOwner) {
        return true;
    }

    if(!group || !permission || !permissions || !permissions[group]) {
        return false;
    }

    const module = permissions[group].MODULE;

    return permissions.MODULE[module] && permissions[group][permission];
}
