import React from 'react'
import NumberFormat from 'react-number-format';
import store from "../store";
import NumberFormats from "../Constants/Company/NumberFormats";

export function renderNumber(value, prefix = '', suffix = '') {

    let number = Number(value);

    if(isNaN(number)) {
        number = 0;
    }

    let numberFormat = NumberFormats.getOrDefault([store?.getState()?.auth?.localisationSettings?.numberFormat]);

    return <NumberFormat prefix={ prefix } suffix={suffix} isNumericString={true}
     value={number.toFixed(2)} displayType={'text'} thousandSeparator={numberFormat.thousand} decimalSeparator={numberFormat.decimal}/>
}

export function getCurrencyNumber(value, currency) {

    return renderNumber(value, currency ? currency.code + ' ' : '');
}

export function getPercentNumber(value) {

    return renderNumber(value, '', '%');
}

export function renderNumberWithLabel(value, label = '', suffix = '') {
    return <span>
        {label}{renderNumber(value, '', suffix)}
    </span>
}