import React from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {formatColumnDate, formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {useNavigate, useSearchParams} from 'react-router-dom';
import AppPermissions from "../../../Constants/Permissions/Permissions";
import { deleteDeal, getDeals } from "../../../Services/CRM/DealService";
import { renderColumnColorChip, renderColumnNumber } from "../../../Components/DataGrid/ValueCellRender";
import AddDeal from "./AddDeal";
import EditDeal from "./EditDeal";
import ViewType from "../../../Constants/Finance/ViewType";
import { Button, ButtonGroup} from "@mui/material";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [
    {field: 'name', headerName: strings.pages.crm.deal.dealList.name, width: 200},
    {field: 'description', headerName: strings.pages.crm.deal.dealList.description, width: 200},
    {
        field: 'amount', headerName: strings.pages.crm.deal.dealList.amount,
        renderCell: (params) => renderColumnNumber(params),
        width: 130
    },
    {
        field: 'closeDate', headerName: strings.pages.crm.deal.dealList.closeDate,
        valueFormatter: (params) => formatColumnDate(params),
        width: 100
    },
    {
        field: 'partner', headerName: strings.pages.crm.deal.dealList.partner,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 200
    },
    {
        field: 'pipeLine', headerName: strings.pages.crm.deal.dealList.pipeLine,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 200
    },
    {
        field: 'pipeLineStage', headerName: strings.pages.crm.deal.dealList.pipeLineStage,
        renderCell: (params) => renderColumnColorChip(params,'name'),
        width: 200
    },
    {
        field: 'dealType', headerName: strings.pages.crm.deal.dealList.dealType,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 200
    },
    {
        field: 'dealPriority', headerName: strings.pages.crm.deal.dealList.dealPriority,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 200
    },
    {
        field: 'dealSource', headerName: strings.pages.crm.deal.dealList.dealSource,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 200
    },
    {
        field: 'currency', headerName: strings.pages.crm.deal.dealList.currency,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 150
    },
    {
        field: 'dealOwner', headerName: strings.pages.crm.deal.dealList.dealOwner,
        valueFormatter: (params) => formatColumnObject(params, 'fullName'),
        width: 200
    }
]

const filters = [];

export const renderToggleButtons = (viewType, setViewType) => {
    return <ButtonGroup className="toggle-button-group">
                <Button onClick={() =>setViewType(ViewType.TABLE)} 
                        className={`button left ${viewType === ViewType.TABLE? 'active':''}`}>
                {
                    viewType === ViewType.TABLE? <img src="/images/deal-list-white.svg"/> : <img src="/images/deal-list-black.svg"/>     
                }
                </Button>
                <Button onClick={() =>setViewType(ViewType.PIPELINE)}
                        className={`button right ${viewType === ViewType.PIPELINE? 'active':''}`}>
                {
                    viewType === ViewType.PIPELINE? <img src="/images/deal-pipeline-white.svg"/> : <img src="/images/deal-pipeline-black.svg"/>     
                }
                </Button>
            </ButtonGroup>
}

const DealList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.DEAL;
    const permissionGroup = AppPermissions.DEAL.GROUP;
    const dealPage = useState(true);
    const [viewType, setViewType] = useState(ViewType.TABLE);

    const value = {
        tablePageOptions, setTablePageOptions,
        navigate,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        updatedFilter, setUpdatedFilter
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.crm.deal.dealList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.CRM},
                {label: strings.pages.crm.deal.dealList.pageTitle},
            ],        
        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    useEffect(() => {
        view();
    }, [viewType])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getDeals({
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }
            
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total,
            });
        })
    }

    const handleView = (deal) => {
        navigate(`/deal-details/${deal.id}/record`);
    }

    const handlePipeline = () => {
        navigate('/deal-pipelines')
    }

    const view = () => {
        if (viewType === ViewType.PIPELINE){
            return handlePipeline();
        }
        return ViewType.TABLE
    }
    
   

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteDeal} tableDescription={columns}
                   tableData={tableData} dealPage={dealPage} renderToggleButtons={() => renderToggleButtons(viewType, setViewType)}
                   handleView={handleView}
                   filter={filter} filters={filters}
                   permissionGroup={permissionGroup}
                   referenceType={referenceType}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditDeal data={ selectedItems }
                                          onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                    addPage={<AddDeal onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                    addButtonText={strings.components.tablePage.addButton.deal}/>
    </TablePageContext.Provider>;
}

export default DealList;
