import React, {useContext, useEffect, useState} from "react";
import {Grid, Paper} from "@mui/material";
import {getWorkspaceLogo, changeWorkspaceLogo} from "../../../Services/Workspaces/WorkspacesService";
import strings from "../../../localization";
import FileUploadComponent from "../../../Components/Controls/FileUploadComponent";
import AvatarProfileControl from "../../../Components/Controls/AvatarProfileControl";
import {toBase64} from "../../../Util/ImageUtil";
import SnackbarContext from "../../../Context/SnackbarContext";
import { ImageFormats } from "../../../Constants/FileFormat";
import { useDispatch } from 'react-redux';
import {changePageSizeState} from "../../../Slices/PageSlice";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";


const ChangeWorkspaceLogo = ({workspaceId}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [workspaceLogo, setWorkspaceLogo] = useState()
    const [editor, setEditor] = useState()
    const [scaleValue, setScaleValue] = useState(1)
    const {showMessage} = useContext(SnackbarContext);
    const [files, setFiles] = useState([]);
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.workspaces.workspacesSettings.changeLogo,
            hierarchy:[
                {label: strings.navigation.homeTag},
                {label: strings.navigation.workspaces},
                {label: strings.pages.workspaces.workspacesSettings.pageTitle},
                {label: strings.pages.workspaces.workspacesSettings.changeLogo}, 
            ],        
        }));
        fetchData();
    },[]);

    const uploadLogo = async (files) => {
        const logo = await toBase64(files[0]);
        setLoading(true);
        changeWorkspaceLogo(workspaceId,{ logo }).then(response =>{
            setWorkspaceLogo(response.data?.workspaceLogo);
            showMessage(strings.commonMessages.editingSuccessful, 'success');
            setLoading(false);
            setChanged(false);
            setScaleValue(1);
            setFiles([]);
        });
    }

    const fetchData = () => {
        getWorkspaceLogo(workspaceId).then(response => {
            setWorkspaceLogo(response?.data?.workspaceLogo)
        });
    }

    const onCrop = () => {
        if(!workspaceLogo){
            showMessage(strings.commonMessages.errorEditing, 'error');
        }else{
            const img = editor.getImage().toDataURL();
                let imageLogo;
                fetch(img)
                    .then(res => res.blob())
                    .then(blob => (imageLogo = window.URL.createObjectURL(blob)));
                
                changeWorkspaceLogo(workspaceId, { logo: img }).then(response =>{
                    setWorkspaceLogo(response.data?.workspaceLogo);
                    setScaleValue(1)
                    setChanged(false);
                    setFiles([]);
                    showMessage(strings.commonMessages.editingSuccessful, 'success');
                });
        }
    }

    const onScaleChange = (scaleValueEvent) => {
        const scaleValue = parseFloat(scaleValueEvent.target.value);
        setScaleValue(scaleValue)
        setChanged(true);
    }

        return(
            <Grid id='page' item md = { 12 } justifyContent="center" container
                  alignItems="center">

                <Grid item xs={5}>
                    <Paper className='paper upload-logo-container'>
                        <FileUploadComponent upload= { uploadLogo } formats={ImageFormats} loading={loading} files = {files} setFiles = {setFiles} />
                        <AvatarProfileControl
                            imageSrc={ workspaceLogo }
                            setEditorRef={setEditor}
                            onCrop={onCrop}
                            scaleValue={scaleValue}
                            onScaleChange={onScaleChange}
                            disableSave = {!changed}
                            setChanged = {setChanged}
                            sliderValue = {scaleValue}
                            />
                    </Paper>
                </Grid>
            </Grid>
        );
}


export default ChangeWorkspaceLogo;
