import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";
import { dateAsUTCDate } from "../../Util/DateUtil";

export async function getPresenceList(data){
    return await request('/api/presence', data);
}

export async function addPresence(data){
    return await request('/api/presence', transformRequestData(data), HttpMethod.POST);
}

export async function editPresence(data){
    return await request('/api/presence/' + data.id , transformRequestData(data), HttpMethod.PUT);
}

export async function deletePresence(id){
    return await request('/api/presence/' + id, {}, HttpMethod.DELETE);
}

export async function changeStatePresenceRequest(data) {
    return await request('/api/presence/change-state/' + data.id, data, HttpMethod.PATCH);
}

export async function checkIn(data) {
    return await(request('/api/presence/check-in', transformRequestData(data), HttpMethod.POST));
}

export async function checkOut(data) {
    return await(request('/api/presence/check-out', transformRequestData(data), HttpMethod.PATCH));
}

export async function getIsCheckIn() {
    return await(request('/api/presence/is-check-in'));
}

function transformRequestData(data) {

    return {
        ...data,
        start: data.start ? dateAsUTCDate(data.start) : undefined,
        end: data.end ? dateAsUTCDate(data.end) : undefined
    }
}