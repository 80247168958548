import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import {Paper} from "@mui/material";
import SnackbarContext from "../../../Context/SnackbarContext";
import SpaceForm from '../../../Components/Forms/Pages/Document/Spaces/SpaceForm';
import { addSpace } from '../../../Services/DocumentManagement/SpaceService';

const formRules = {
	'name': {required: true}
};

const AddSpace = (props) => {
	const {onFinish, onCancel} = props;

	const {setDrawerTitle} = useContext(DrawerContext);
	const { showMessage } = useContext(SnackbarContext);
	const [showLoader, setShowLoader] = useState(false);

	const form = useForm({
		defaultValues: {
			name: '',
            visible: true,
		}
	});

	const {data, handleSubmit, getValues, setValue, formState: {errors}, control} = form;

	useEffect(() => {
        setDrawerTitle(strings.pages.space.addDocument.pageTitle);
	}, []);

	const onSubmit = (data) => {
        let spaceData = {...data, parent: props.parent}
        addSpace(spaceData).then(response => {
            setShowLoader(false);

        	if(!response || !response.ok) {
        			showMessage(strings.commonMessages.errorAdding, 'error');
        			onFinish();
        			return;
        	}

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            onFinish();
        });
	}

	return (
		<div className = 'drawer-form'>
			<Paper className = 'paper'>
				{
					showLoader && <LinearProgress />
				}
				<SpaceForm 
					formRules = {formRules}
					form = {form}
					values = {getValues()}
					setValue = {setValue}
					data = {data}
					errors = {errors}
					onSubmit = {handleSubmit(onSubmit)}
					onCancel = {() => onCancel()}
					control = {control}
					isOwner = {true}
				/>
			</Paper>
		</div>
	);
}

export default AddSpace;
