import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";

const AddCompanyDocumentForm = ({
                             onSubmit,
                             formData,
                             form,
                             errors,
                             values,
                             setValue,
                             formRules
                         }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='tree-view-add-edit-template-form'
                  onSubmit={(e) => e.preventDefault()}
                  className='form' action="#">
                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={formData}
                    error={Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                    label={strings.forms.document.spaces.name}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='description'
                    multiline
                    rows={3}
                    rules={formRules['description']}
                    label={strings.forms.administration.pipelineStage.description}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default AddCompanyDocumentForm;