import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import DateTimeControl from '../../../../Controls/Inputs/DateTimeControl';
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import {getMonths, getYears} from "../../../../../Util/DateUtil";

const OrganisationUserContractPartForm = ({
                                            onSubmit,
                                            onCancel,
                                            data,
                                            form,
                                            errors,
                                            values,
                                            setValue,
                                            formRules,
                                            control
                                    }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='organisation-user-contract-part-form' className='form' action="#">
                <SelectControl
                    value={values['year']}
                    rules={formRules['year']}
                    setValue={setValue}
                    name='year'
                    control={control}
                    label={strings.forms.hr.organisationUserContractPart.year}
                    options={getYears()}
                    error={Boolean(errors.year)}
                    helperText={errors.year && strings.forms.common.thisFieldIsRequired}
                    nameKey={'value'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                <SelectControl
                    value={values['month']}
                    rules={formRules['month']}
                    setValue={setValue}
                    name='month'
                    control={control}
                    label={strings.forms.hr.organisationUserContractPart.month}
                    options={getMonths()}
                    error={Boolean(errors.month)}
                    helperText={errors.month && strings.forms.common.thisFieldIsRequired}
                    nameKey={'value'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='net'
                    rules={formRules['net']}
                    control={data}
                    type='number'
                    error={Boolean(errors.net)}
                    helperText={errors.net && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.organisationUserContractPart.net}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='gross'
                    rules={formRules['gross']}
                    control={data}
                    type='number'
                    error={Boolean(errors.gross)}
                    helperText={errors.gross && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.organisationUserContractPart.gross}
                    disabled={isPageStateViewDetails()}
                />
                <DateTimeControl
                    data={data}
                    label={strings.forms.hr.organisationUserContractPart.paymentDate}
                    rules={formRules['paymentDate']}
                    form={form}
                    error={Boolean(errors.paymentDate)}
                    helperText={errors.paymentDate && strings.forms.common.thisFieldIsRequired}
                    name={'paymentDate'}
                    value={values['paymentDate']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() &&
                    <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default OrganisationUserContractPartForm;