import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import CheckBoxControl from "../../../../Controls/Inputs/CheckBoxControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from '../../../../Controls/Inputs/AutoCompleteControl';

const ProjectTimeLogForm = ({
                                onSubmit,
                                onCancel,
                                control,
                                data,
                                form,
                                errors,
                                projects,
                                values,
                                setValue,
                                formRules,
                                taskCategories,
                                employees,
                                handleProjectChange,
                                handleSubmitSave,
                                handleTaskCategoryChange,
                                onChangeEmployeeFilter,
                                billable,
                                onChangeProjectsFilter,
                                isOwner,
                                changeEmployee,
                            }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const isPageStateEdit = () => pageState === PageState.Edit;

    return (
        <FormProvider {...form}>
            <form id='project-time-log-form' className='form' action="#">

                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.project.projectTimeLog.projectTimeLogForm.name}
                    disabled={isPageStateViewDetails()}
                />

                <DateTimeControl
                    data={data}
                    rules={formRules['dateLogged']}
                    form={form}
                    error={Boolean(errors.dateLogged)}
                    helperText={errors.dateLogged && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.project.projectTimeLog.projectTimeLogForm.dateLogged}
                    name={'dateLogged'}
                    value={values['dateLogged']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='hourLogged'
                    rules={formRules['hourLogged']}
                    type='number'
                    control={data}
                    error={Boolean(errors.hourLogged)}
                    helperText={errors.hourLogged && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.project.projectTimeLog.projectTimeLogForm.hoursLogged}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    value={values['project']}
                    setValue={(name,e) => handleProjectChange(e)}
                    rules={formRules['project']}
                    name='project'
                    control={control}
                    label={strings.forms.project.projectTimeLog.projectTimeLogForm.project}
                    error={Boolean(errors.project)}
                    helperText={errors.project && strings.forms.common.thisFieldIsRequired}
                    options={projects}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onChangeProjectsFilter}
                    onOpen={() =>onChangeProjectsFilter('')}
                />

                <AutoCompleteControl
                    value={values['taskCategory']}
                    setValue={(name,e) => handleTaskCategoryChange(e)}
                    name='taskCategory'
                    label={strings.forms.project.projectTimeLog.projectTimeLogForm.taskCategory}
                    options={taskCategories}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                {isOwner &&
                    <AutoCompleteControl
                        name='employeeUser'
                        label={strings.forms.finance.transactionForm.employee}
                        rules={formRules['employeeUser']}
                        nameKey={'fullName'}
                        valueKey={'id'}
                        setValue={(name, value) => changeEmployee(value)}
                        onChange={onChangeEmployeeFilter}
                        onOpen={() =>onChangeEmployeeFilter('')}
                        options={employees}
                        value={values['employeeUser']}
                        error={Boolean(errors.employee)}
                        helperText={errors.employee && strings.forms.common.thisFieldIsRequired}
                        control={control}
                        disabled={isPageStateViewDetails()}
                    />
                }

                <CheckBoxControl
                    name={'billable'}
                    control={control}
                    label={strings.forms.project.projectTimeLog.projectTimeLogForm.billable}
                    value={billable}
                    form={form}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        {
                            !isPageStateEdit() && <Button variant="contained" color="primary" onClick={onSubmit}>
                                {strings.forms.common.saveAndClose}
                            </Button>
                        }
                        <Button variant="contained" color="primary" onClick={handleSubmitSave}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }

            </form>
        </FormProvider>
    );
}

export default ProjectTimeLogForm;