import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import ProductServiceForm from "../../../Components/Forms/Pages/Product/ProductServiceForm";
import DrawerContext from "../../../Context/DrawerContext";
import {editProductService} from "../../../Services/Product/ProductServiceService";
import LinearProgress from '@mui/material/LinearProgress';
import { getUnits, getCurrencies, getTaxRatesSelectOptions } from '../../../Util/SiteDataUtil';
import SnackbarContext from "../../../Context/SnackbarContext";
import {getProductServiceCategoryList} from "../../../Services/Product/ProductServiceCategoryService";
import referenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {getSizeDescription} from "../../../Util/FileUtil";
import {addDocument} from "../../../Services/DocumentManagement/DocumentService";


const formRules = {
    'name': {required: true}, 'publicName': {required: true},
    'price': {required: true}, 'tax': {required: true}, 
    'currency': {required: true}, 'unit': {required: true},
    'type': {required: true},
}

const EditProductService = (props) => {

    const siteData = useSelector((state) => state.siteData)
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, control, watch, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [productServiceCategory,setProductServiceCategory] = useState([]);


    useEffect(() => {
        setDrawerTitle(strings.pages.product.productService.editProductService.pageTitle);
    });

    useEffect( () => {
        fetchCategory()
    },[])

    const fetchCategory = () => {
        getProductServiceCategoryList({}).then(response => {
            console.log(response)
            if (!response || !response.ok) {
                return;
            }
            setProductServiceCategory(response.data.result);
        })
    }

    const onSubmit = (data) => {
        const images = getValues('images');
        data['images'] = null;

        setShowLoader(true);

        editProductService(data).then(response => {

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            if(images) {

                let files = images.getAll('images');
                let result = {};

                for (let file of files) {
                    result.name = file.name;
                    result.file = file;

                    const docData = {
                        ...result,
                        referenceId: response.data.entity.id,
                        referenceType: referenceType.PRODUCT_SERVICE,
                        documentSize: getSizeDescription(result.file.size).replace(/[()]/g, '')
                    }

                    addDocument(docData).then(response => {

                        if (!response || !response.ok) {
                            showMessage(strings.commonMessages.errorAdding, 'error');
                            if (strings.error.DocumentHandler[response.response.data.message]) {
                                showMessage(strings.error.DocumentHandler[response.response.data.message], 'error');
                            }
                            props.onFinish();
                            return response;
                        }

                        showMessage(strings.commonMessages.addingSuccessful, 'success');
                        props.onFinish();
                    });
                }
            }
            setShowLoader(false);

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <ProductServiceForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                currencies={getCurrencies(siteData)}
                units={ getUnits(siteData) }
                taxesOptions={ getTaxRatesSelectOptions(siteData) }
                errors={errors} data={data} form={form} control={control}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}
                productServiceCategory={productServiceCategory}
                watch={watch}/>
        </Paper>
    </div>
}

export default EditProductService;