import strings from "../../../localization";

const CompanyDocumentItemType = {
    STRING: 1,
    INTEGER: 2,
    DOUBLE: 3,
    DATE: 4,
    REFERENCE: 5
}

export function getCompanyDocumentItemTypes() {
    return [
        {
            value: CompanyDocumentItemType.STRING,
            name: getCompanyDocumentItemTypeString(CompanyDocumentItemType.STRING)
        },
        {
            value: CompanyDocumentItemType.INTEGER,
            name: getCompanyDocumentItemTypeString(CompanyDocumentItemType.INTEGER)
        },
        {
            value: CompanyDocumentItemType.DOUBLE,
            name: getCompanyDocumentItemTypeString(CompanyDocumentItemType.DOUBLE)
        },
        {
            value: CompanyDocumentItemType.DATE,
            name: getCompanyDocumentItemTypeString(CompanyDocumentItemType.DATE)
        },
        {
            value: CompanyDocumentItemType.REFERENCE,
            name: getCompanyDocumentItemTypeString(CompanyDocumentItemType.REFERENCE)
        }
    ];
}

export function getCompanyDocumentItemTypeString(contractType) {
    switch(contractType) {
        case CompanyDocumentItemType.STRING: return strings.companyDocumentItemType.string;
        case CompanyDocumentItemType.INTEGER: return strings.companyDocumentItemType.integer;
        case CompanyDocumentItemType.DOUBLE: return strings.companyDocumentItemType.double;
        case CompanyDocumentItemType.DATE: return strings.companyDocumentItemType.date;
        case CompanyDocumentItemType.REFERENCE: return strings.companyDocumentItemType.reference;
    }
}

export default CompanyDocumentItemType;