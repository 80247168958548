import React, {useContext, useEffect, useState} from "react";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import SnackbarContext from "../../../Context/SnackbarContext";
import {useSelector} from "react-redux";
import {Paper} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import CustomerForm from "../../../Components/Forms/Pages/CRM/Customer/CustomerForm";
import strings from "../../../localization";
import {addCustomer, editCustomer, getCustomers} from "../../../Services/CRM/CustomerService";

const formRules = {
    'firstName' : {required: true},
    'lastName' : {required: true},
}
const EditCustomer = (props) => {
    const auth = useSelector((state) => state.auth)
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
            defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setDrawerTitle(strings.pages.crm.customer.editCustomer.pageTitle);
    });

    const onSubmit = (data) => {
        editCustomer(data).then(response => {
            setShowLoader(true);

            if (!response || !response.ok){
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <CustomerForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                errors={errors}
                data={data}
                form={form}
                control={control}
                onSubmit={handleSubmit(onSubmit)}
                onCancel={() => onCancel()}
            />
        </Paper>
    </div>
}
export default EditCustomer;