import {FormProvider} from "react-hook-form";
import strings from "../../../../localization";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import React, {useContext} from "react";
import Button from "@mui/material/Button";
import SelectControl from "../../../Controls/Inputs/SelectControl";
import {getDeadlineTypes} from "../../../../Constants/Finance/DeadlineType";
import TablePageContext from "../../../../Context/TablePageContext";
import PageState from "../../../../Constants/Base/PageState";
import AutoCompleteControl from "../../../Controls/Inputs/AutoCompleteControl";

const InvoiceSettingsForm = ({
                                 form,
                                 onSubmit,
                                 accounts,
                                 values,
                                 setValue,

                                 onChangeAccountFilter
                             }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='invoice-settings-form' className='form' action="#">
                <TextFieldControl
                    name='eInvoiceApiKey'
                    label={strings.forms.company.invoiceSettings.eInvoiceApiKey}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='invoicePlace'
                    label={strings.forms.company.invoiceSettings.invoicePlace}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='invoiceNumberPattern'
                    label={strings.forms.company.invoiceSettings.invoiceNumberPattern}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='comment'
                    label={strings.forms.company.invoiceSettings.comment}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='inoComment'
                    label={strings.forms.company.invoiceSettings.inoComment}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    name='account'
                    label={strings.forms.company.invoiceSettings.account}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={setValue}
                    onChange={onChangeAccountFilter}
                    onOpen={() =>onChangeAccountFilter('')}
                    options={accounts}
                    value={values['account']}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    name='inoAccount'
                    label={strings.forms.company.invoiceSettings.inoAccount}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={setValue}
                    onChange={onChangeAccountFilter}
                    onOpen={() =>onChangeAccountFilter('')}
                    options={accounts}
                    value={values['inoAccount']}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={values['deadline']}
                    setValue={setValue}
                    name='deadline'
                    label={strings.forms.company.invoiceSettings.deadline}
                    options={getDeadlineTypes()}
                    nameKey={'id'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    )
}


export default InvoiceSettingsForm;