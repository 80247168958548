import React, {useState} from 'react';
import {Modal, Paper} from "@mui/material";
import strings from "../../../localization";
import NewTemplateForm from "../../../Components/Forms/Pages/CompanyDocument/CompanyDocumentTemplate/NewTemplateForm";
import {useForm} from "react-hook-form";

const formRules = {
    'name': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
};

const EditCheckListTemplate = ({template, showModal, closeModal, onSubmit}) => {

    const form = useForm({
        defaultValues: {
            name: template?.name,
            description: template?.description,
        }
    });

    const {formData, handleSubmit, getValues, setValue, setError, formState: {errors}} = form;

    const handleCloseModal = (prop) => {
        closeModal(prop)
        form.reset()
    }

    const submitHandler = (data) => {
        handleCloseModal();
        const editData = {id: template.id, ...data}
        onSubmit(editData);
    }

    return (
        <div>
            <Modal
                open={Boolean(showModal)}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id='add-edit-template-modal'>
                <Paper>
                    <img className='add-edit-template-modal-close' src='/images/close-issue-view.svg' onClick = {handleCloseModal} />
                    <div className='add-edit-template-modal-title'>{strings.pages.companyDocument.companyDocumentTemplate.templateDetails}</div>
                    <NewTemplateForm
                        formRules={formRules}
                        values={getValues()}
                        setValue={setValue}
                        errors={errors} formData={formData} form={form}
                        onSubmit={handleSubmit(submitHandler)}/>
                </Paper>
            </Modal>
        </div>
    );
}

export default EditCheckListTemplate;




