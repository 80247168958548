import React, { useContext } from "react"
import PageState from "../../../../../Constants/Base/PageState";
import TablePageContext from "../../../../../Context/TablePageContext"
import strings from "../../../../../localization";
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";

const OrganisationUnitForm = ({
                                    onSubmit,
                                    onCancel,
                                    data,
                                    form,
                                    errors,
                                    values,
                                    setValue,
                                    formRules,
                                    parents,
                                    onChangeUserFilter
                            }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='organisation-unit-form' className='form' action="#">
                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.organisationUnit.name}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    name='parent'
                    label={strings.forms.hr.organisationUnit.parent}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={setValue}
                    onChange={onChangeUserFilter}
                    onOpen={() =>onChangeUserFilter('')}
                    control={data}
                    options={parents}
                    value={values['parent']}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default OrganisationUnitForm;