import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllInventoryCategories() {
    return await request('/api/inventory-categories/all');
}

export async function getInventoryCategories(data) {
  return await request('/api/inventory-categories', data);
}

export async function getAllInventoryTypes() {
    return await request('/api/inventory-types/all');
}

export async function getInventoryTypeList(data) {
    return await request('/api/inventory-types', data);
}

export async function getInventoryType(id) {
    return await request('/api/inventory-types/' + id);
}

export async function deleteInventoryType(id) {
    return await request('/api/inventory-types/' + id, {}, HttpMethod.DELETE);
}

export async function editInventoryType(data) {
    return await request('/api/inventory-types/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addInventoryType(data) {
    return await request('/api/inventory-types', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        categoryId: data.category ? data.category.id : null,
    }
}
