import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getCompanyDocumentFolders(parent) {
    return await request('/api/company-document-folders', transformParentRequest(parent));
}

export async function addCompanyDocumentFolder(data) {
    return await request('/api/company-document-folders', transformRequestData(data), HttpMethod.POST);
}

export async function editCompanyDocumentFolder(data) {
    return await request('/api/company-document-folders/' + data.id, data, HttpMethod.PUT);
}

export async function deleteCompanyDocumentFolder(id) {
    return await request('/api/company-document-folders/'+ id, {}, HttpMethod.DELETE);
}

function transformRequestData(data){
    return {
        name: data.name ? data.name : null,
        parent: data.parent ? data.parent.id : null
    }
}

function transformParentRequest(parent){
    return {
        parent: parent ? parent : null
    }
}
