import TextField from "@mui/material/TextField";
import {Controller} from "react-hook-form";
import React from "react";
import { useState } from "react";
import { InputAdornment } from "@mui/material";

const ValidationInputControll = (props) => {

    const statusIcon = {
        endAdornment: (
            <InputAdornment position="start">
                {props.validInput? <img src={'/images/green-check-icon.svg'} loading={'lazy'} /> : <img src={'/images/red-cross-icon.svg'} loading={'lazy'} />}
            </InputAdornment>
        ),
    }

    return <Controller
        {...props}
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
        rules={props.rules}
        render={({ field }) =>
            <TextField {...field}
                       InputLabelProps={{ ...field, shrink: true }}
                       size={props.size ? props.size : 'small'}
                       fullWidth={props.fullWidth ? props.fullWidth : true}
                       type={props.type}
                       margin={props.margin ? props.margin : 'normal'}
                       error={props.error}
                       helperText={props.helperText}
                       label={props.label}
                       disabled={props.disabled}
                       placeholder={props.placeholder}
                       className={props.className}
                       InputProps={statusIcon}
                       onChange={e =>  {props.onChange(e.target.value)}}
            />}
    />
}

export default ValidationInputControll;