import React, {useEffect, useState} from "react";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import CommentPageContext from "../../../Context/CommentPageContext";
import CommentPage from "../../../Components/DataGrid/CommentPage";
import {
    addDealRecord,
    deleteDealRecord,
    editDealRecord,
    getAllDealRecords
} from "../../../Services/CRM/DealRecordService";
import strings from "../../../localization";
import {useDispatch} from "react-redux";
import { changeBreadcrumbs } from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";

const DealRecord = (props) => {

    const dispatch = useDispatch();
    const [selected, setSelected] = useState(null)
    const [editMode, setEditMode] = useState(null)
    const [dealRecords, setDealRecords] = useState([]);

    const permissionGroup = AppPermissions.DEAL_RECORDS.GROUP;

    const value = {
        selected, setSelected,
        editMode, setEditMode,
    }

    useEffect(() => {
        dispatch(changeBreadcrumbs({
            title: strings.pages.crm.deal.dealList.records,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.CRM},
                {label: strings.pages.crm.deal.dealList.pageTitle},
                {label: strings.pages.crm.deal.dealDetails.pageTitle},
                {label: strings.pages.crm.deal.dealList.records},
            ],        
        }));
    }, [])

    useEffect(() => {
        fetch();
    }, [props.deal])

    const fetch = () => {
        if(!props.deal) {
            return;
        }
        getAllDealRecords(props.deal.id).then(response => {
            if(!response || !response.ok) {
                setDealRecords([]);
                return;
            }
            setDealRecords(response.data)
        })
    }

    const add = ({content}) => addDealRecord({content, dealId: props.deal.id});

    const edit = ({entity, content}) => editDealRecord({...entity, content});


    return <CommentPageContext.Provider value={value}>
        <CommentPage permissionGroup={permissionGroup} entities={dealRecords} deleteItem={deleteDealRecord} onAdd={add} onFinish={fetch} onEdit={edit}/>
    </CommentPageContext.Provider>
}

export default DealRecord;