import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import {Paper} from "@mui/material";
import { editEmployee } from "../../../Services/User/UserService";
import EmployeeForm from "../../../Components/Forms/Pages/HR/Employee/EmployeeForm";
import { getTeamList } from "../../../Services/HR/TeamService";
import { getUserPositions } from "../../../Services/Company/UserPositionService";
import ValidationPatters from "../../../Constants/Base/ValidationPatters";
import SnackbarContext from "../../../Context/SnackbarContext";

const formRules = {
    'email': {required: { value: false},
        pattern: { value: ValidationPatters.EMAIL }},
}

const EditEmployee = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? {...props.data[0], ...props.data[0].user, employeeStatus: {id: props.data[0].employeeStatus},
            lastRaise: props.data[0].lastRaise || NaN, nextRaise: props.data[0].nextRaise || NaN} : undefined
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [teams, setTeams] = useState(0);
    const [userPositions, setUserPositions] = useState(0);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.employee.editEmployee.pageTitle);
    })

    useEffect(() => {
        fetchTeams();
        fetchUserPositions();
    }, [])

    const fetchTeams = (term='') => {
        getTeamList({term}).then(response => {
            if (!response || !response.ok){
                return;
            }
            setTeams(response.data.result)
        })
    }

    const fetchUserPositions = (term='') => {
        getUserPositions({term}).then(response => {
            if (!response || !response.ok){
                return;
            }
            setUserPositions(response.data.result)
        });
    }  
        
    const onSubmit = (data) => {
        setShowLoader(true);

        editEmployee(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok){
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress />
            }
            <EmployeeForm
                formRules={formRules} onChangeTeamFilter={fetchTeams}
                values={getValues()} setValue={setValue}
                data={data} errors={errors} form={form}
                teams={teams} userPositions={userPositions} onChangeUserPositionFilter={fetchUserPositions}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}
            />
        </Paper>
    </div>
}

export default EditEmployee;
