import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getJobStatus(data) {
    return await request('/api/job-status', data);
}

export async function getJobStatusId(id) {
    return await request('/api/job-status/' + id);
}

export async function addJobStatus(data) {
    return await request('/api/job-status', data, HttpMethod.POST);
}

export async function editJobStatus(data) {
    return await request('/api/job-status/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteJobStatus(id) {
    return await request('/api/job-status/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
    }
}
