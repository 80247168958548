import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getLeaveTypes(data) {
    return await request('/api/leave-types', data);
}

export async function getLeaveType(id) {
    return await request('/api/leave-types/' + id);
}

export async function getAllLeaveTypes() {
    return await request('/api/leave-types/all');
}

export async function addLeaveType(data) {
    return await request('/api/leave-types', transformRequestData(data), HttpMethod.POST);
}

export async function editLeaveType(data) {
    return await request('/api/leave-types/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteLeaveType(id) {
    return await request('/api/leave-types/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
    }
}
