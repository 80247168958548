import React, {useContext, useEffect, useState} from 'react'
import strings from "../../../localization";
import { Button, Grid, Paper, Snackbar} from "@mui/material";
import {useLocation} from "react-router-dom";
import {
    editCompanyDocumentContent,
    getCompanyDocument,
    downloadCompanyDocumentPDF
} from "../../../Services/CompanyDocumentManagement/CompanyDocumentService";
import SnackbarContext from "../../../Context/SnackbarContext";
import { useDispatch } from 'react-redux';
import Modules from '../../../Constants/Base/Modules';
import { changeBreadcrumbs } from '../../../Slices/BreadcrumbsSlice';
import QuillEditor from '../../../Components/Controls/Inputs/QuillEditor';

const DocumentContent = (props) => {

    const { state } = useLocation();
    const dispatch = useDispatch();

    const { showMessage } = useContext(SnackbarContext);

    const [content, setContent] = useState('')
    const [companyDocument, setCompanyDocument] = useState(state?.companyDocument)


    useEffect(() => {
        setCompanyDocument(state?.companyDocument);
        dispatch(changeBreadcrumbs({
            title: strings.pages.companyDocument.companyDocument.companyDocumentList.content,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.DOCUMENTS},
                {label: strings.pages.companyDocument.companyDocument.companyDocumentList.pageTitle},
                {label: strings.pages.companyDocument.companyDocument.companyDocumentList.content},
            ],        
        }));
    }, [])

    useEffect(() => {
        fetchData();
    }, [companyDocument])

    const fetchData = () => {
        getCompanyDocument(companyDocument.id).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setContent(response.data.entity?.content || '')
        })
    }

    const download = () => {
        downloadCompanyDocumentPDF(companyDocument.id);
    }


    const submit = () => {
        editCompanyDocumentContent({
            id: companyDocument.id,
            content: content
        }).then(response => {
            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.templateContent.templateContentSaved, 'success');
        });
    }

    return (
        <Grid className='template-content' item md={ 12 }>
            <div className='editor-container'>
                <div className='header'>
                    <Button variant="contained" color="primary" className='rounded-input centered-flex-row py-3 px-2 mb-3 mr-3' onClick={ () => download() } >
                        <img className = 'mr-2' src = '/images/download-white.svg' />
                        { strings.common.download }
                    </Button>
                    <Button variant="contained" color="primary" className='rounded-input centered-flex-row py-3 px-2 mb-3' onClick={ () => submit() } >
                        <img className = 'mr-2' src = '/images/disk.svg' />
                        { strings.common.save }
                    </Button>
                </div>
                <Paper className='paper'>
                     <QuillEditor className='large-editor' value={content} onChange={setContent}/>
                </Paper>
            </div>
        </Grid>
    );

}

export default DocumentContent;
