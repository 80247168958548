import React, {useContext} from "react";
import { FormProvider } from "react-hook-form";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import Button from '@mui/material/Button';
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";

const DealForm = ({
                    onSubmit,
                    onCancel,
                    data,
                    form,
                    errors,
                    values,
                    setValue,
                    formRules,
                    dealTypes,
                    dealPriority,
                    currencies,
                    partners,
                    pipelines,
                    control,
                    onPipeLineChange,
                    pipeLineStages,
                    dealSources,
                    onChangePartnersFilter,
                    onChangeDealTypesFilter,
                    onChangeDealPriorityFilter,
                    onChangeDealSourcesFilter
                }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id="deal-form" className='form' action="#">
                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.crm.deal.name}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='description'
                    control={data}
                    label={strings.forms.crm.deal.description}
                    disabled={isPageStateViewDetails()}
                    multiline={true}
                    rows={4}
                />
                <TextFieldControl
                    name='amount'
                    control={data}
                    type='number'
                    label={strings.forms.crm.deal.amount}
                    disabled={isPageStateViewDetails()}
                />
                <DateTimeControl
                    data={data}
                    label={strings.forms.crm.deal.closeDate}
                    name={'closeDate'}
                    value={values['closeDate']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />
                <AutoCompleteControl
                    value={values['partner']}
                    setValue={setValue}
                    name='partner'
                    control={control}
                    options={partners}
                    label={strings.forms.crm.deal.partner}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onChangePartnersFilter}
                    onOpen={() =>onChangePartnersFilter('')}
                />
                <AutoCompleteControl
                    value={values['pipeLine']}
                    name='pipeLine'
                    control={control}
                    setValue={(name, e) => onPipeLineChange(e)}
                    options={pipelines}
                    label={strings.forms.crm.deal.pipeLine}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                <AutoCompleteControl
                    value={values['pipeLineStage']}
                    setValue={setValue}
                    name='pipeLineStage'
                    control={control}
                    options={pipeLineStages}
                    label={strings.forms.crm.deal.pipeLineStage}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                <AutoCompleteControl
                    value={values['dealType']}
                    setValue={setValue}
                    name='dealType'
                    control={control}
                    options={dealTypes}
                    label={strings.forms.crm.deal.dealType}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onChangeDealTypesFilter}
                    onOpen={() =>onChangeDealTypesFilter('')}
                />
                <AutoCompleteControl
                    value={values['dealPriority']}
                    setValue={setValue}
                    name='dealPriority'
                    control={control}
                    options={dealPriority}
                    label={strings.forms.crm.deal.dealPriority}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onChangeDealPriorityFilter}
                    onOpen={() =>onChangeDealPriorityFilter('')}
                />
                <SelectControl
                    value={values['currency']}
                    setValue={setValue}
                    name='currency'
                    options={currencies}
                    control={control}
                    label={strings.forms.crm.deal.currency}
                    nameKey={'code'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                <AutoCompleteControl
                    value={values['dealSource']}
                    setValue={setValue}
                    name='dealSource'
                    control={control}
                    options={dealSources}
                    label={strings.forms.crm.deal.dealSource}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onChangeDealSourcesFilter}
                    onOpen={() =>onChangeDealSourcesFilter('')}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default DealForm;