import {FormProvider} from "react-hook-form";
import React, {useContext} from 'react';
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import {getPipelineTypes} from "../../../../../Constants/Company/PipelineType";
import Button from "@mui/material/Button";

const PipelineForm = ({
                          form,
                          formRules,
                          errors,
                          onSubmit,
                          values,
                          setValue,
                          control,
                          onCancel
                      }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='pipeline-form' className='form' action="#">
                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    error={Boolean(errors && errors.name)}
                    helperText={errors.name && errors.name.message}
                    label={strings.forms.administration.pipeline.name}
                    disabled={isPageStateViewDetails()}
                />
                <SelectControl
                    value={values['type']}
                    setValue={setValue}
                    control={control}
                    rules={formRules['type']}
                    name='type'
                    label={strings.forms.administration.pipeline.type}
                    options={getPipelineTypes()}
                    nameKey={'name'}
                    valueKey={'id'}
                    error={Boolean(errors && errors.type)}
                    helperText={errors.type && errors.type.message}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>

                }
            </form>
        </FormProvider>
    )
}


export default PipelineForm;