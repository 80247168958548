import React, {useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {Paper, Switch} from "@mui/material";
import { getWorkspaceAccess, updateWorkspaceAccess } from "../../../Services/Workspaces/WorkspaceAccessService";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import {getAllCompanyAccess} from "../../../Services/User/UserCompanyAccessService";
import { getClasses } from "../../../Util/SwitchUtil";
import Modules from "../../../Constants/Base/Modules";
import LoaderContext from "../../../Context/LoaderContext";

const WorkspaceAccess = ({workspaceId}) => {

    const dispatch = useDispatch();

    const [users, setUsers] = useState([])
    const [workspaceAccesses, setWorkspaceAccesses] = useState([]);
    const {setLoading} = useContext(LoaderContext);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.workspaces.workspacesSettings.access,
            hierarchy: [
                {label: strings.navigation.homeTag},
                {label: strings.navigation.workspaces},
                {label: strings.pages.workspaces.workspacesSettings.pageTitle},
                {label: strings.pages.workspaces.workspaceAccess.pageTitle}
            ]
        }));

        fetchWorkspaceAccesses();
    },[]);

    useEffect(() => {
        fetchUsers();
    }, [workspaceAccesses])

    const getWorkspaceAccessAllowed = (userId) => {
        let result = false;
        for(let workspaceAccess of workspaceAccesses) {
            if(workspaceAccess.user?.id === userId) {
                result = workspaceAccess.allowed;
                break;
            }
        }
        return result;
    }

    const fetchUsers = () => {
        setLoading(true);
        getAllCompanyAccess().then(response => {
            if(!response || !response.ok) {
                return;
            }
            let users = response.data.filter(employee => !employee.owner).map(employee => ({...employee.user, allowed: getWorkspaceAccessAllowed(employee.user.id)}));
            setUsers(users)
        }).finally(setLoading(false));
    }

    const fetchWorkspaceAccesses = () => {
        if(!workspaceId) {
            return;
        }

        setLoading(true);
        getWorkspaceAccess(workspaceId).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setWorkspaceAccesses(response.data.result)
        }).finally(() => setLoading(false));
    }

    const accessChange = (user) => {
        let allowed = false;
        for(let u of users) {
            if(u.id === user.id) {
                u.allowed = !u.allowed;
                allowed = u.allowed;
            }
        }

        let data = {
            workspaceId,
            userId: user.id,
            allowed
        }

        updateWorkspaceAccess(data).then(response =>{
            if(!response || !response.ok) {
                return;
            }

            let newAccess = true;
            const tmpAccesses = workspaceAccesses.map(access => {
                if (access.id === response.data.entity.id) {
                    newAccess = false;
                    return response.data.entity;
                }
                return access;
            });

            if(newAccess) tmpAccesses.push(response.data.entity);

            setWorkspaceAccesses(tmpAccesses);
        });
    }
    
    const renderElements = () => {
        let result = [];
        for(let user of users) {
            result.push(
                <div className='element-container' key={'element-' + result.length }>
                    <label>{ user.fullName }</label>
                    <div className='checkbox-container'>
                          <Switch
                            className={`${getClasses(false, user.allowed)} mb-1`}
                            checked={user.allowed}
                            onChange={() => accessChange(user)}
                        />
                    </div>
                </div>
            )
        }
        return result;
    }
    return (
        <Paper className='paper rounded-2'>
            <div className='paper-checkbox'>
                <div className='paper-checkbox-container'>
                    <div className='elements'>
                        { renderElements() }
                    </div>
                </div>
            </div>
        </Paper>
    );
}

export default WorkspaceAccess;
