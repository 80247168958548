import strings from "../../localization";

export const ProductType = {
    MATERIAL: 1,
    PRODUCT: 2,
    SEMIFINISHED: 3
}

export function getProductTypes() {
    return [
        {
            value: ProductType.MATERIAL,
            name: getProductTypeString(ProductType.MATERIAL)
        },
        {
            value: ProductType.PRODUCT,
            name: getProductTypeString(ProductType.PRODUCT)
        },
        {
            value: ProductType.SEMIFINISHED,
            name: getProductTypeString(ProductType.SEMIFINISHED)
        }
    ];
}

export function getProductTypeString(productType) {
    switch(productType) {
        case ProductType.MATERIAL: return strings.pages.product.productType.material;
        case ProductType.PRODUCT: return strings.pages.product.productType.product;
        case ProductType.SEMIFINISHED: return strings.pages.product.productType.semiFinished;
    }
}

export default ProductType;
