import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";
import AdditionalHoursStatus from "../../Constants/HR/AdditionalHoursStatus";

export async function getAdditionalHours(data) {
    return await request('/api/additional-hours', data);
}

export async function addAdditionalHours(data) {
    return await request('/api/additional-hours', transforRequestData(data), HttpMethod.POST);
}

export async function editAddtionalHours(data) {
    return await request('/api/additional-hours/' + data.id, transforRequestData(data), HttpMethod.PUT);
}

export async function deleteAdditionalHours(id) {
    return await request('/api/additional-hours/' + id, {}, HttpMethod.DELETE);
}

export async function changeStatusAdditionalHours(data) {
    return await request('/api/additional-hours/change-status/' + data.id, data, HttpMethod.PATCH);
}

function transforRequestData(data) {
    return {
        ...data,
        user: data.user ? data.user.id : null,
        organisationUserContract: data.organisationUserContract ? data.organisationUserContract.id : null,
        status: data.status.id ? data.status.id : AdditionalHoursStatus.PENDING,
    }
}