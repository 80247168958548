import React from "react"
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import { FormProvider } from "react-hook-form";
import Button from "@mui/material/Button";
import CheckBoxControl from "../../../../Controls/Inputs/CheckBoxControl";


const WooCommerceSettingsForm = ({
                onSubmit,
                data,
                form,
                values,
                setValue,
}) => {
    return (
        <FormProvider {...form}>
            <form id="woo-commerce-settings-form" className='form px-12 mui-shifted-label-input' action="#">
                <TextFieldControl
                    name = 'apiKey'
                    control = {data}
                    label = {strings.forms.product.wooCommerceSettings.wooCommerceSettingsFrom.apiKey}
                    className = "mt-12 mb-1"
                />
                <TextFieldControl
                    name = 'apiSecret'
                    control = {data}
                    label = {strings.forms.product.wooCommerceSettings.wooCommerceSettingsFrom.apiSecret}
                    className = "mt-12 mb-1"
                />
                <TextFieldControl
                    name = 'apiUrl'
                    control = {data}
                    label = {strings.forms.product.wooCommerceSettings.wooCommerceSettingsFrom.apiUrl}
                    className = "mt-12 mb-1"
                />
                <CheckBoxControl
                    name='enabled'
                    control={data}
                    label={strings.forms.product.wooCommerceSettings.wooCommerceSettingsFrom.enabled}
                    value={values['enabled']}
                    setValue={setValue}
                />
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit} className="mt-1 mb-12">
                        {strings.forms.common.save}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
}
export default WooCommerceSettingsForm;
