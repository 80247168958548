import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from '../../../../Controls/Inputs/AutoCompleteControl';

const CompanyDocumentForm = ({
                         onSubmit,
                         onCancel,
                         data,
                         form,
                         errors,
                         companyDocumentCategories,
                         companyDocumentTemplates,
                         values,
                         setValue,
                         formRules
                     }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='company-document-form' onSubmit={(e) => e.preventDefault()} className='form' action="#">

                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.companyDocument.companyDocument.name}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='description'
                    rules={formRules['description']}
                    control={data}
                    error={Boolean(errors.description)}
                    helperText={errors.description && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.companyDocument.companyDocument.description}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    value={values['companyDocumentCategory']}
                    setValue={setValue}
                    name='companyDocumentCategory'
                    control={data}
                    label={strings.forms.companyDocument.companyDocument.companyDocumentCategory}
                    options={companyDocumentCategories}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    value={values['companyDocumentTemplate']}
                    setValue={setValue}
                    name='companyDocumentTemplate'
                    control={data}
                    label={strings.forms.companyDocument.companyDocument.companyDocumentTemplate}
                    options={companyDocumentTemplates}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.forms.common.save}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.forms.common.cancel}
                    </Button>
                </div>
                }
            </form>
        </FormProvider>
    );
}

export default CompanyDocumentForm;