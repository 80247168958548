import {FormProvider} from "react-hook-form";
import strings from "../../../../localization";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import React, {useContext} from "react";
import Button from "@mui/material/Button";
import SelectControl from "../../../Controls/Inputs/SelectControl";
import TablePageContext from "../../../../Context/TablePageContext";
import PageState from "../../../../Constants/Base/PageState";
import AutoCompleteControl from "../../../Controls/Inputs/AutoCompleteControl";

const BankAccountForm = ({
                             form,
                             formRules,
                             errors,
                             onSubmit,
                             values,
                             setValue,
                             banks,
                             currencies,
                             control
                         }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='s3-settings-form' className='form' action="#">
                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    error={Boolean(errors && errors.name)}
                    helperText={errors.name && errors.name.message}
                    label={strings.forms.company.bankAccount.name}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='account'
                    label={strings.forms.company.bankAccount.account}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='iban'
                    label={strings.forms.company.bankAccount.iban}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    name='bank'
                    rules={formRules['bank']}
                    label={strings.forms.company.bankAccount.bank}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={setValue}
                    control={control}
                    options={banks}
                    error={Boolean(errors && errors.bank)}
                    helperText={errors.bank && errors.bank.message}
                    value={values['bank']}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={values['currency']}
                    setValue={setValue}
                    rules={formRules['currency']}
                    name='currency'
                    label={strings.forms.company.bankAccount.currency}
                    options={currencies}
                    nameKey={'code'}
                    valueKey={'id'}
                    control={control}
                    error={Boolean(errors && errors.currency)}
                    helperText={errors.currency && errors.currency.message}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                    </div>

                }
            </form>
        </FormProvider>
    )
}


export default BankAccountForm;