import strings from "../../localization";

const AdditionalHoursStatus = {
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3
}

export function getAdditionalHoursStatus() {
    return[
        { id: AdditionalHoursStatus.PENDING, name:getAdditionalHoursStatusString(AdditionalHoursStatus.PENDING)},
        { id: AdditionalHoursStatus.APPROVED, name:getAdditionalHoursStatusString(AdditionalHoursStatus.APPROVED)},
        { id: AdditionalHoursStatus.REJECTED, name:getAdditionalHoursStatusString(AdditionalHoursStatus.REJECTED)}
    ];
}

export function getAdditionalHoursStatusString(additionalHoursStatus) {
    switch(additionalHoursStatus){
        case AdditionalHoursStatus.PENDING:
            return strings.constants.hr.additionalHoursStatus.PENDING;
        case AdditionalHoursStatus.APPROVED:
            return strings.constants.hr.additionalHoursStatus.APPROVED;
        case AdditionalHoursStatus.REJECTED:
            return strings.constants.hr.additionalHoursStatus.REJECTED;
    }
}

export default AdditionalHoursStatus;