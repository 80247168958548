import Button from '@mui/material/Button';
import { Drawer, Paper } from "@mui/material";
import strings from '../../localization';
import IconButton from "@mui/material/IconButton";
import { Popper, ClickAwayListener } from "@mui/material";
import { useContext, useEffect, useState } from 'react';
import {
  addNewWorkingHoursSchedule,
  getWokringHours,
  makeDefaultButton,
  deleteWorkingHoursSchedule,
  editWorkingHours,
  changeDefaultWorkingRange
} from '../../Services/User/WorkingHoursService';
import { getWorkingHoursRange, saveWorkingHoursRange } from '../../Services/User/WorkingHoursRangeService';
import WorkingHoursRange from './WorkingHoursRange';
import PageState from '../../Constants/Base/PageState';
import SnackbarContext from '../../Context/SnackbarContext';
import DrawerWrapper from '../../Components/Layout/DrawerWrapper';
import WorkingHoursForm from '../../Components/Forms/Pages/User/WorkingHoursForm';
import { useForm } from 'react-hook-form';

const WorkingHours = () => {
  const [anchor, setAnchor] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dataHours, setDataHours] = useState([]);
  const [dataRange, setDataRange] = useState([]);
  const [activeWorkingHours, setActiveWorkingHours] = useState(null);
  const [title, setTitle] = useState('');
  const { showMessage } = useContext(SnackbarContext);
  const [page, setPage] = useState(PageState.View);
  const form = useForm({
    defaultValues: { title: '', description: '' }
  });
  const { data, handleSubmit, getValues, setValue, setError, formState: { errors } } = form;
  const [isDisabled, setIsDisabled] = useState(false);
  const [endDisabled, setEndDisabled] = useState(false);

  const isDrawerOpen = () => page === PageState.Edit;

  useEffect(() => {
    setTitle(strings.profile.workingHours.editWorkingHours);
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (activeWorkingHours) {
      setValue('title', activeWorkingHours.name);
      setValue('description', activeWorkingHours.description);
    }

  }, [activeWorkingHours])

  const fetchEmpty = () => {

    getWokringHours().then(response => {
      if (!response || !response.ok) {
        return;
      }
      setDataHours(response.data.result);
    })
    fetch(dataHours.id)
    getWorkingHoursRange(dataHours.id).then(response => {
      if (!response || !response.ok) {
        return;
      }
      setDataRange(response.data.result);
    })
  }

  const fetch = (id) => {
    getWokringHours().then(response => {
      if (!response || !response.ok) {
        return;
      }
      setDataHours(response.data.result);
      if(id){
        const defaultHours = response.data.result.find(item => item.id === id);
        setActiveWorkingHours(defaultHours);
        getWorkingHoursRange(defaultHours.id).then(response => {
          if (!response || !response.ok) {
            return;
          }
          setDataRange(response.data.result);
        })

      }else{
      const defaultHours = response.data.result.find(item => item.isDefault);
        setActiveWorkingHours(defaultHours);
        getWorkingHoursRange(defaultHours?.id).then(response => {
          if (!response || !response.ok) {
            return;
          }
          setDataRange(response.data.result);
        })
      }
    })
  }

  const disabled = () => {
    return !(!isDisabled && !endDisabled);
  }

  const getActiveWorkingHoursRange = (id) => {
    getWorkingHoursRange(id).then(response => {
      if (!response || !response.ok) {
        return;
      }
      setDataRange(response.data.result);
    })
  }

  const makeWorkingHoursDefault = (id) => {
    makeDefaultButton(id).then((response) => {
      if (!response || !response.ok) {
        return;
      }
    }).finally(fetch)
  }

  const deleteHours = (workingHours) => {
    if(workingHours.isDefault){
      changeDefaultWorkingRange(workingHours.id).finally(fetchEmpty,setIsOpen(false))
    }else{
      deleteWorkingHoursSchedule(workingHours.id).finally(fetchEmpty, setIsOpen(false))
    }
  }

  const renderWorkingHourButtons = (data) => {
    if (!data.length) {
      return;
    }
    const defaultWorkingHour = data.find(item => item.isDefault);

    if (defaultWorkingHour) {
      data.splice(data.indexOf(defaultWorkingHour), 1);
      data.unshift(defaultWorkingHour);
    }

    return dataHours.map((item, index) => {
      return <Button className={activeWorkingHours?.id === item.id ? 'active-button-border' : ''} onClick={() => {
        setActiveWorkingHours(item)
        getActiveWorkingHoursRange(item.id)
      }} variant="outlined" startIcon={<div className='icon-working-hours'><img src="/images/working-hours.svg" /> {item.isDefault && <img className='star-default' src='/images/star-schedule.svg' />}</div>} key={`schedule-index-${index}`}>{item.name}</Button>
    })
  }

  const openPopup = (event) => {
    setAnchor(event.currentTarget);
    setIsOpen(true);
  }

  const PopperMenu = () => {

    return <div className='popper-tab'>
      <Button className='popper-buttons' onClick={() => {
        makeWorkingHoursDefault(activeWorkingHours.id);
        setIsOpen(false);
      }}>
        <img src='/images/star-default-working-hours.svg' className='image-tab' />
        <p className='p-tab'>{strings.profile.workingHours.default}</p>
      </Button>
      <Button className='popper-buttons' onClick={() => {
        setPage(PageState.Edit);
        setIsOpen(false);
      }}>
        <img src='/images/edit-working-hours.svg' className='image-tab' />
        <p className='p-tab'>{strings.profile.workingHours.edit}</p>
      </Button>
      <Button className='popper-buttons' onClick={() => deleteHours(activeWorkingHours)}>
        <img src='/images/table-page/delete.svg' className='image-tab' />
        <p className='p-tab'>{strings.profile.workingHours.delete}</p>
      </Button>
    </div>
  }

  const fetchNewSchedule = () => {
    const startTime= new Date();
    startTime.setHours(9);
    startTime.setMinutes(0);
    const endTime = new Date();
    endTime.setHours(17);
    endTime.setMinutes(0);
    addNewWorkingHoursSchedule({ title: strings.profile.workingHours.title, description: strings.profile.workingHours.description, 'timeFrom': startTime , 'timeTo':endTime}).then(response => {
      if (!response || !response.ok) {
        return;
      }
      setActiveWorkingHours(response.data.entity)
      fetch(response.data.entity.id)
    })
  }

  const onFinish = () => {
    fetch(activeWorkingHours.id);
    setPage(PageState.View);
  }

  const onCancel = () => {
    setPage(PageState.View);
  }

  const onSubmit = (data) => {
    editWorkingHours(activeWorkingHours.id, data).then(response => {

      if (!response || !response.ok) {
        showMessage(strings.commonMessages.errorEditing, 'error');
        onFinish();
        return;
      }

      showMessage(strings.commonMessages.editingSuccessful, 'success');
      onFinish();
    });
  }

  const saveRange = () => {
    saveWorkingHoursRange({ dataRange: dataRange, id: activeWorkingHours.id }).then(response => {
      if (!response || !response.ok) {
        showMessage(strings.commonMessages.errorSaving, 'error');
        onFinish();
        return;
      }

      showMessage(strings.commonMessages.saved, 'success');
      onFinish();
    })
  }

  return <div>
    <div className='working-hours-buttons'>{renderWorkingHourButtons(dataHours)}
      <Button className='working-hours-buttons' variant="outlined" startIcon={<img src="/images/add-new-schedule.svg" />} onClick={fetchNewSchedule}>
        {strings.profile.workingHours.btnSchedule}
      </Button>
    </div>
    <Paper>
      <div className='working-hours-dots'>
        <IconButton onClick={openPopup}>
          <img src='/images/schedule-dots.svg' />
        </IconButton>
      </div>

      <div className='working-hours-title'>
        <h2>{activeWorkingHours?.name}</h2>
        <p>{activeWorkingHours?.description}</p>
      </div>
      <WorkingHoursRange dataRange={dataRange} setDataRange={setDataRange} activeWorkingHours={activeWorkingHours} setActiveWorkingHours={setActiveWorkingHours} isDisabled={isDisabled} setIsDisabled={setIsDisabled} endDisabled={endDisabled} setEndDisabled={setEndDisabled} />
      <div className='btn-save-container'>
        <Button onClick={saveRange} className='btn-save' disabled={disabled()}>{strings.forms.common.saveChanges}</Button>
      </div>
      {
        isOpen && <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Popper open={isOpen} anchorEl={anchor} placement='bottom-start'>
            {PopperMenu()}
          </Popper>
        </ClickAwayListener>
      }
    </Paper >
    <Drawer id='drawer' anchor='right' open={isDrawerOpen()} onClose={() => setPage(PageState.View)}>

      <DrawerWrapper title={title} onBack={() => setPage(PageState.View)}>
        {
          <WorkingHoursForm
            values={getValues()}
            setValue={setValue}
            errors={errors}
            data={data}
            form={form}
            onSubmit={handleSubmit(onSubmit)}
            onCancel={onCancel}
          />
        }
      </DrawerWrapper>

    </Drawer>
  </div >
}

export default WorkingHours;