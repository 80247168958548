import React, {useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import DateTimeControl from '../../../../Controls/Inputs/DateTimeControl';
import { getOrganisationUserContractTypes } from '../../../../../Constants/HR/OrganisationUserContractType';
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import {getPaymentMonths} from "../../../../../Constants/HR/PaymentMonth";
import AutoCompleteControl from '../../../../Controls/Inputs/AutoCompleteControl';

const OrganisationUserContractForm = ({
                                            onSubmit,
                                            onCancel,
                                            data,
                                            form,
                                            errors,
                                            values,
                                            setValue,
                                            formRules,
                                            currencies,
                                            users,
                                            control,
                                            trigger,
                                    }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const [startDate, setStartDate] = useState(values['startDate']);
    const [additionalRules, setAdditionalRules] = useState({});

    useEffect(() => {
        if(form.formState.isSubmitted){
            trigger('endDate');
        }
    }, [additionalRules, values['endDate']])

    useEffect(() => {
        setStartDate(values['startDate']);
    }, [values['startDate']])

    useEffect(() => {
        changeAdditionalRules(startDate);
    },[startDate])

    const changeStartDate = (date) => {
        setValue('startDate', date);
        setStartDate(date);
    }

    const changeAdditionalRules = (date) => {
        if(!date){
            setAdditionalRules({});
            return;
        }
        setAdditionalRules({min: {value: date, message: strings.forms.hr.presence.dateTimeErorr}});
    }


    return (
        <FormProvider {...form}>
            <form id='organisation-user-contract-form' className='form' action="#">
                <AutoCompleteControl
                    name='user'
                    label={strings.forms.hr.organisationUserContract.employee}
                    nameKey={'fullName'}
                    valueKey={'id'}
                    setValue={setValue}
                    control={control}
                    options={users}
                    value={values['user']}
                    rules={formRules['user']}
                    error={Boolean(errors.user)}
                    helperText={errors.user && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.organisationUserContract.name}
                    disabled={isPageStateViewDetails()}
                />
                <SelectControl
                    value={values['type']}
                    rules={formRules['type']}
                    setValue={setValue}
                    name='type'
                    control={control}
                    error={Boolean(errors.type)}
                    helperText={errors.type && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.organisationUserContract.type}
                    options={getOrganisationUserContractTypes()}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                <DateTimeControl
                    data={data}
                    name={'startDate'}
                    rules={formRules['startDate']}
                    label={strings.forms.hr.organisationUserContract.startDate}
                    form={form}
                    error={Boolean(errors.startDate)}
                    helperText={errors.startDate && strings.forms.common.thisFieldIsRequired}
                    value={startDate}
                    setValue={(name, value) => changeStartDate(value)}
                    disabled={isPageStateViewDetails()}
                />
                <DateTimeControl
                    data={data}
                    rules={{...formRules['endDate'], ...additionalRules}}
                    form={form}
                    error={Boolean(errors.endDate)}
                    helperText={errors.endDate && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.organisationUserContract.endDate}
                    name={'endDate'}
                    value={values['endDate']}
                    minDate={startDate}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='net'
                    rules={formRules['net']}
                    control={data}
                    type='number'
                    error={Boolean(errors.net)}
                    helperText={errors.net && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.organisationUserContract.net}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='gross'
                    rules={formRules['gross']}
                    control={data}
                    type='number'
                    error={Boolean(errors.gross)}
                    helperText={errors.gross && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.organisationUserContract.gross}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    name='currency'
                    label={strings.forms.hr.organisationUserContract.currency}
                    nameKey={'code'}
                    valueKey={'id'}
                    setValue={setValue}
                    control={control}
                    options={currencies}
                    value={values['currency']}
                    rules={formRules['currency']}
                    error={Boolean(errors.currency)}
                    helperText={errors.currency && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='paymentDay'
                    rules={formRules['paymentDay']}
                    control={data}
                    type='number'
                    error={Boolean(errors.paymentDay)}
                    helperText={errors.paymentDay && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.organisationUserContract.paymentDay}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={values['paymentMonth']}
                    rules={formRules['paymentMonth']}
                    setValue={setValue}
                    name='paymentMonth'
                    control={control}
                    error={Boolean(errors.paymentMonth)}
                    helperText={errors.type && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.organisationUserContract.paymentMonth}
                    options={getPaymentMonths()}
                    nameKey={'key'}
                    valueKey={'value'}
                    disabled={isPageStateViewDetails()}
                />


                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default OrganisationUserContractForm;