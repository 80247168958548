import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllVendors(data) {
    return await request('/api/vendors/all', data);
}

export async function getVendors(data) {
  return await request('/api/vendors', data);
}

export async function getVendorList(data) {
    return await request('/api/vendors', data);
}

export async function getVendor(id) {
    return await request('/api/vendors/' + id);
}

export async function deleteVendor(id) {
    return await request('/api/vendors/' + id, {}, HttpMethod.DELETE);
}

export async function editVendor(data) {
    return await request('/api/vendors/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addVendor(data) {
    return await request('/api/vendors', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        parentId: data.parent ? data.parent.id : null,
    }
}
