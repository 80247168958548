import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import {Paper} from "@mui/material";
import SnackbarContext from "../../../Context/SnackbarContext";
import BoardIssueForm from '../../../Components/Forms/Pages/Boards/BoardIssueForm';
import { BoardIssuePriorityValues, IssuePriorities } from '../../../Constants/Workspaces/BoardIssuePriority';
import { editBoardIssue } from '../../../Services/Workspaces/BoardIssueService';
import { useSelector } from 'react-redux';

const formRules = {
	'name': {required: true},
	'issueType': {required: true},
	'assignee': {required: true},
	'priority': {required: true},
}

const EditBoardIssue = (props) => {
	const {issue, assignees, issueTypes, onFinish, onCancel} = props;
	const auth = useSelector((state) => state.auth);
	const {setDrawerTitle} = useContext(DrawerContext);
	const { showMessage } = useContext(SnackbarContext);
	const [showLoader, setShowLoader] = useState(false);
	const form = useForm({
		defaultValues: {
				name: issue.name,
				description: issue?.description,
				assignee: issue.assignee ? issue.assignee : {id: -1, firstName: 'Unassigned', lastName: '', fullName: 'Unassigned'},
				issueType: issue.issueType,
				priority: issue.priority,
				reporter: issue.reporter,
				pipelineStage: issue.pipelineStage,
				isArchived: issue.isArchived,
		}
	});
	const {data, handleSubmit, getValues, setValue, formState: {errors}, control, watch, clearErrors} = form;

	useEffect(() => {
		setDrawerTitle(strings.pages.boards.boardView.editBoardIssue.pageTitle);
	},[]);

	useEffect(() => {
		const subscription = watch((value) => {});
		return () => subscription.unsubscribe();
	}, [watch]);

	const onSubmit = (data) => {
		setShowLoader(true);

		editBoardIssue(issue.id, data).then(response => {
			setShowLoader(false);

			if(!response || !response.ok) {
				showMessage(strings.commonMessages.errorEditing, 'error');
				onFinish();
				return;
			}

			showMessage(strings.commonMessages.editingSuccessful, 'success');
			onFinish();
		});
	}

	const handleAssignToMe = () => {
		setValue('assignee', auth?.user);
		clearErrors('assignee');
	}


	return (
		<div className = 'drawer-form'>
			<Paper className = 'paper'>
				{
					showLoader &&
					<LinearProgress />
				}
				<BoardIssueForm 
					formRules = {formRules}
					form = {form}
					values = {getValues()}
					setValue = {setValue}
					data = {data}
					errors = {errors}
					onSubmit = {handleSubmit(onSubmit)}
					onCancel = {() => onCancel()}
					control = {control}
					issueTypes = {issueTypes}
					assignees = {assignees}
					assignToMe = {handleAssignToMe}
					issuePriorities = {IssuePriorities}
				/>
			</Paper>
		</div>
	);
}

export default EditBoardIssue;
