import React from 'react';
import strings from '../../../../localization';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import {Link} from "react-router-dom";
import { InputAdornment } from '@mui/material';
import { useState } from 'react';

const LoginForm = ({
                       onSubmit,
                       form,
                       errors,
                       formRules
                   }) => 
{
    const [passwordShown, setPasswordShown] = useState(false);

    const adornment = {
        endAdornment: (
          <InputAdornment position="end" onClick={()=>setPasswordShown(prevValue=>!prevValue)}>
            {passwordShown? <img src={'/images/eye-cross-icon.svg'} loading={'lazy'} /> : <img src={'/images/eye-open-icon.svg'} loading={'lazy'} />}
          </InputAdornment>
        ),
      }
      
    return  <FormProvider {...form}>
                <form id='login-form' className='form' onSubmit={onSubmit}>

                    <TextFieldControl
                        name='email'
                        defaultValue=''
                        rules={formRules['email']}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.email)}
                        helperText={errors.email && errors.email.message}
                        label={strings.forms.login.email}
                        placeholder={strings.forms.login.emailPlaceholder}
                    />
                    <div className='password-input'>
                        <TextFieldControl                   
                            name='password'
                            defaultValue=''
                            rules={formRules['password']}
                            fullWidth
                            type={passwordShown? "text":"password"}
                            margin="normal"
                            error={Boolean(errors.password)}
                            helperText={errors.password && errors.password.message}
                            label={strings.forms.login.password}
                            inputProps={adornment}
                            placeholder={strings.forms.login.passwordPlaceholder}
                        />
                    </div>
                    

                    <div className='reset-password'>
                        <Link to={'/reset-password-request'}>{strings.forms.login.forgotPassword}</Link>
                    </div>  

                    <div className='submit'>
                        <Button type='submit' variant="contained" color="primary">
                            {strings.forms.login.login}
                        </Button>
                    </div>

                    <div className='link-to'>
                        <div className='text'>{strings.forms.login.haveAnAccountQuestion}</div>
                        <Link to={'/registration'}>{strings.forms.login.signUp}</Link>
                    </div>
                
                </form>
            </FormProvider>
};

export default LoginForm;
