import strings from "../../../localization";
import {changePageSizeState} from "../../../Slices/PageSlice";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import PageState from "../../../Constants/Base/PageState";
import React, {useEffect, useState} from "react";
import TablePage from "../../../Components/DataGrid/TablePage";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    renderColumnPipline,
    renderColumnWorkspaceLogo, renderValueWithTooltip,
} from "../../../Components/DataGrid/ValueCellRender";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {hasPermission} from '../../../Util/PermissionUtil';
import EditWorkspace from './EditWorkspace';
import AddWorkspace from './AddWorkspace';
import { getWorkspacesList, deleteWorkspace} from "../../../Services/Workspaces/WorkspacesService";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";

const WorkspacesList = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState({
        ...TablePageOptionDefaults,
    });
    const permissionGroup = AppPermissions.WORKSPACES.GROUP;
    const permissionGroupPipe = AppPermissions.PIPE_LINE_STAGE.GROUP;
    const referenceType = ReferenceType.WORKSPACES;

    const value = {
        tablePageOptions, 
        setTablePageOptions,
        selectionModel, 
        setSelectionModel,
        selectedItems, 
        setSelectedItems,
        filter, 
        setFilter, 
        pageState, 
        setPageState, 
        showSnackbar, 
        setShowSnackbar,
        messageSnackbar, 
        setMessageSnackbar, 
        alertType, 
        setAlertType, 
        showDeleteDialog, 
        setShowDeleteDialog,
        navigate,
        updatedFilter, 
        setUpdatedFilter
    }

    const tableDescription = [
        {
            field: 'workspaceImage',  
            headerName: strings.pages.workspaces.workspacesList.image, 
            width: 100,
            renderCell: (params) => renderColumnWorkspaceLogo(params),
            align: 'center'
        },
        {
            field: 'name', 
            headerName: strings.pages.workspaces.workspacesList.name, 
            width: 300,
            renderCell: (params) => renderValueWithTooltip(params)
        },
        {
            field: 'description', 
            headerName: strings.pages.workspaces.workspacesList.description, 
            width: 200,
            renderCell: (params) => renderValueWithTooltip(params)
        },
        {
            field: 'pipeline', 
            headerName: strings.pages.workspaces.workspacesList.pipline, 
            headerAlign: 'center',
            width: 300,
            align: 'center',
            renderCell: (params) => renderColumnPipline(params, hasPermission(auth.user, permissionGroupPipe, AppPermissions[permissionGroupPipe].VIEW, auth.permissions)),
        },
    ]

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.navigation.workspaces,
            hierarchy:[
                {label: strings.navigation.homeTag},
                {label: strings.navigation.workspaces},
            ],        
        }));

        setColumns([...tableDescription]);
        
        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter) {
            return;
        }

        fetch();
    }, [filter, updatedFilter]);

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        })

        getWorkspacesList({
            ...filter
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        });
    }

    const handleViewDetails = (workspace) => {
        navigate(`/workspaces/${workspace.id}/boards`, {state: {workspace: workspace}});
    }

    const onCellCLick = (params) => {
        goToPipeline(params);
        if(params.field!='action' && params.field!='tag' && params.field!='pipeline'){
            navigate(`/workspaces/${params.row.id}/boards`, {state: {workspace: params.row}});
        }
    }

    const goToPipeline = (params) => {
        if(params.field === 'pipeline'){
            if(!hasPermission(auth.user, permissionGroupPipe, AppPermissions[permissionGroupPipe].VIEW, auth.permissions)) return;
            const pipeline = params.value;
            navigate('/pipelines/' + pipeline.id);
        }
    }

    const hasEditPermission = () => {
        return hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].EDIT, auth.permissions);
    }

    const additionalMenuItems = [
        {
            handleClick: (id, item) => handleSettings(id, item),
            text: strings.pages.workspaces.workspacesList.workspaceSettings,
            src: '/images/table-page/settings.svg'
        },
    ]

    const handleSettings = (id, item) => {
        navigate(`/workspace-settings/${id}/issue-types`)
    }

    return (
        <TablePageContext.Provider 
            value={value}
        >
            <TablePage 
                onFinish={() => onFinish()} 
                tableDescription={columns}
                handleView={handleViewDetails}
                tableData={tableData}
                permissionGroup={permissionGroup}
                onCancel={() => onCancel()}
                addButtonText={strings.components.tablePage.addButton.workspaces}
                hideDocuments = {true}
                hideView={true}
                onCellClick = {onCellCLick}
                filters = {[]}
                editPage = {<EditWorkspace data = {selectedItems} onCancel = {onCancel} onFinish = {() => onFinish()} />}
                deleteItem = {deleteWorkspace}
                addPage={<AddWorkspace onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                additionalMenuItems={hasEditPermission() && additionalMenuItems} referenceType={ referenceType }
                tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
            />
        </TablePageContext.Provider>
    );
}

export default WorkspacesList;
