import { IconButton, Tooltip } from '@mui/material';
import React, {Fragment, useContext, useState} from 'react';
import { useSelector } from 'react-redux';
import AppPermissions from '../../Constants/Permissions/Permissions';
import strings from '../../localization';
import { UserAvatarGroupContainer } from '../../Util/AvatarUtil';
import { dateToString } from '../../Util/DateUtil';
import { hasPermission } from '../../Util/PermissionUtil';
import YesNoDialog, { YesNoDialogResult } from '../Dialogs/YesNoDialog';
import SnackbarContext from "../../Context/SnackbarContext";
import {CopyToClipboard} from "react-copy-to-clipboard/src";

const SpaceCart = ({data, onEdit, onDelete, onSelected, onExport, permissionGroup}) => {

    const auth = useSelector((state) => state.auth)

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedSpace, setSelectedSpace] = useState(null);
    const { showMessage } = useContext(SnackbarContext);


    const handleDeleteDialogResult = (result, payload) => {
        if (result === YesNoDialogResult.YES) {
            onDelete(selectedSpace);
        };

        setShowDeleteDialog(false);
        setSelectedSpace(null);
    }

    const handleShowDeleteDialog = (id) => {
        setShowDeleteDialog(true);
        setSelectedSpace(id);
    }

    const onCopy = () =>{
        showMessage(strings.commonMessages.copied, 'success');
    }

    const onClick = (e,object) =>{
        if(e.target.id !== 'copyLink'){
            onSelected(object)
        }
    }

    const renderData = () => {
        let result = [];
        for(let object of data){

            let users = [{id: object.owner.id, firstName: object.owner.firstName, lastName: object.owner.lastName, fullName: object.owner.fullName}];

            if(object?.updatedByUser && (object.owner.id !== object.updatedByUser.id)) {
                users.push(object.updatedByUser); 
            }

            result.push(
                <div className='space-cart-component'>
                    <div className='cart-label-line-left'/>
                        <div className='cart-info'  onClick={(e) => onClick(e,object)}>
                            <div className='cart-name'>{object.name}</div>
                            <div className='avatar-info-container'>
                                <UserAvatarGroupContainer
                                    users = {users}
                                    max = {3}
                                />
                                <div className='create-update-info'>
                                    <div className='text'>Created by: <span>{object.owner.fullName}</span></div>
                                    { object.updatedByUser?.fullName && <div>Last updated: {dateToString(new Date(object.dateUpdated))} by <span>{object.updatedByUser.fullName}</span></div> }
                                        <CopyToClipboard onCopy={onCopy} text={process.env.REACT_APP_FRONTEND_BASE_URL+'space/'+object?.publicLink}>
                                            <div className='space-link'>
                                                <span id='copyLink' className='link-background'><img id='copyLink' src='/images/link-blue.svg' className='link-icon'/></span>
                                                <div id='copyLink' className='link-text'>{strings.pages.space.copyLink.copyLink}</div>
                                            </div>
                                        </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    <div className='cart-button-container'>
                    { onExport && <IconButton className='button' onClick={() => onExport(object)}>
                        <Tooltip title={strings.components.spaces.exportToPDF}>
                            <img src="/images/download-pdf-blue.svg"/>
                        </Tooltip>
                    </IconButton> }
                    { hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].EDIT, auth.permissions) &&
                        <IconButton className='button' onClick={() => onEdit(object)}>
                            <img src="/images/edit-blue.svg" />
                        </IconButton>
                    }
                    { hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].DELETE, auth.permissions) &&
                        <IconButton className='button' onClick={() => handleShowDeleteDialog(object.id)}>
                            <img src="/images/delete-blue.svg" />
                        </IconButton>
                    }
                    </div>
                </div>
            )
        }
        return result;
    }

    return (
        <Fragment> 
            <YesNoDialog show={showDeleteDialog}
                payload={selectedSpace}
                handleResult={handleDeleteDialogResult}
                title={strings.components.tablePage.confirmDelete}
                text={strings.components.tablePage.confirmDeleteMessage}/>
            { data && renderData() }
        </Fragment>
  );
};

export default SpaceCart;
