import {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import {useDispatch, useSelector} from "react-redux";
import {getCashFlowData, recalculateCashFlowData} from "../../../Services/Finance/CashFlowService";
import {
    dateToString,
    getCurrentMonth,
    getCurrentYear,
    getDaysOfMonth,
    getMonths,
    getYears,
    getDaysOfMonthLabels,
} from "../../../Util/DateUtil";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Tab,
    Tabs,
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {isWeekend} from "date-fns";
import {renderNumber} from "../../../Util/RenderDataUtil";
import ContractType from "../../../Constants/Finance/ContractType";
import Button from "@mui/material/Button";
import ScrollContainer from "react-indiana-drag-scroll";
import SelectControl from "../../../Components/Controls/Inputs/SelectControl";
import * as React from "react";
import {useForm} from "react-hook-form";
import {FormProvider} from "react-hook-form";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {ToggleButtonGroup} from "@mui/lab";
import {Reorder, ShowChart} from "@mui/icons-material";
import ViewType from "../../../Constants/Finance/ViewType";
import {lineBackgroundColors, lineBorderColors, lineBaseOptions} from "../../../Util/ChartUtil";
import {Line} from "react-chartjs-2";
import LoaderContext from "../../../Context/LoaderContext";
import { useContext } from "react";
import Modules from "../../../Constants/Base/Modules";
import LineChart from '../../../Components/Charts/LineChart';
import { monthShortLabels } from "../../../Util/ChartUtil";

const getYearObject = (years, year) => {
    return years.find(x => x.value === year);
}

const getMonthObject = (months, month) => {
    return months.find(x => x.id === month);
}

export const CashFlow = () => {
    const years = getYears(1);
    const months = getMonths();
    const currentYear = getCurrentYear();
    const currentMonth = getCurrentMonth();
    const permissionGroup = AppPermissions.CASH_FLOW.VIEW;
    const auth = useSelector((state) => state.auth)
    const {loading, setLoading} = useContext(LoaderContext);
    const [viewType, setViewType] = useState(ViewType.TABLE);

    const [expanded, setExpanded] = useState({});

    const dispatch = useDispatch();
    const [cashFlowData, setCashFlowData] = useState({
        days: []
    });
    const [cashFlowChartData, setCashFlowChartData] = useState();
    const [currentResult, setCurrentResult] = useState([]);
    const [filter, setFilter] = useState({
        year: getYearObject(years, currentYear),
        month: getMonthObject(months, currentMonth)
    });


    const form = useForm({
        defaultValues: {
            year: filter.year,
            month: filter.month
        }
    });
    const {watch, setValue} = form;

    useEffect(() => {
        calculateCurrentValues();
    }, [cashFlowData])


    useEffect(() => {
        const newData = {
            labels: cashFlowData.days.map(d => dateToString(d)),
            datasets: [
                {
                    label: 'RSD',
                    data: currentResult.map(item => item.currentValues['RSD']),
                    backgroundColor: [
                        lineBackgroundColors[0],
                    ],
                    borderColor: [
                        lineBorderColors[0],
                    ],
                    borderWidth: 1,
                },
                {
                    label: 'EUR',
                    data: currentResult.map(item => item.currentValues['EUR']),
                    backgroundColor: [
                        lineBackgroundColors[1],
                    ],
                    borderColor: [
                        lineBorderColors[1],
                    ],
                    borderWidth: 1,
                },
                {
                    label: 'USD',
                    data: currentResult.map(item => item.currentValues['USD']),
                    backgroundColor: [
                        lineBackgroundColors[2],
                    ],
                    borderColor: [
                        lineBorderColors[2],
                    ],
                    borderWidth: 1,
                },
            ],
        };

        setCashFlowChartData(newData)

    }, [currentResult, cashFlowData.days])


    useEffect(() => {
        const subscription = watch((data) => setFilter({
            ...filter,
            year: data.year ? data.year : undefined,
            month: data.month ? data.month : undefined
        }));
    }, [watch]);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.finance.cashFlow.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.FINANCE},
                {label: strings.pages.finance.cashFlow.pageTitle},
            ],        
        }));
        fetch();
    }, [])

    useEffect(() => {
        fetch();
    }, [filter])

    const fetch = () => {
        setLoading(true);
        
        getCashFlowData({
            year: filter.year?.value,
            month: filter.month?.id
        }).then(response => {
            setCashFlowData({
                ...response.data,
                days: getDaysOfMonth(filter.year?.value, filter.month?.id)
            });
            setLoading(false);
        });
    }

    const recalculateCashFlow = () => {
        setLoading(true);
        recalculateCashFlowData({
            year: filter.year.value
        }).then(response => {
            fetch();
        });
    }

    const refreshDays = () => {
        setCashFlowData({
            ...cashFlowData,
            days: getDaysOfMonth(filter.year.value, filter.month.id)
        });
    }

    const changeMonth = (direction) => {
        let month = filter.month.id;
        let year = filter.year.value;
        let lastYear = direction === 'left' ? currentYear - 99 : currentYear + 2;

        if(!month || !year){
            return;
        }

        if (direction === 'left') {
            year = month === 1 ? year - 1 : year;
            month = month === 1 ? 12 : month - 1;
        }

        if (direction === 'right') {
            year = month === 12 ? year + 1 : year;
            month = month === 12 ? 1 : month + 1;
        }

        if (year === lastYear) {
            year = currentYear;
            month = currentMonth;
        }


        setFilter({
            ...filter,
            year: getYearObject(years, year),
            month: getMonthObject(months, month)
        });
    }


    const changeYear = (direction) => {
        let month = filter.month.id;
        let year = filter.year.value;
        let lastYear = direction === 'left' ? currentYear - 99 : currentYear + 2;

        if(!month || !year){
            return;
        }

        year = direction === 'left' ? year - 1 : year + 1;

        if (year === lastYear) {
            year = currentYear;
            month = currentMonth;
        }

        setFilter({
            ...filter,
            year: getYearObject(years, year),
            month: getMonthObject(months, month)
        });
    }


    const renderHeader = () => {

        return <>
            <Paper sx = {{backgroundColor:'inherit'}} elevation = {0}>
                <div className='header'>
                    <div className='left'>
                        <div className = 'cash-flow-tab-container'>
                            <Paper>
                                <Tabs 
                                    value={viewType} 
                                    onChange={(e, value) => setViewType(value)} 
                                >
                                    <Tab value = {1} icon = {<img className = {viewType === 1 ? 'active-tab-icon' : ''} src = {'/images/view-table.svg'} />} />
                                    <Tab value = {2} icon = {<img className = {viewType === 2 ? 'active-tab-icon' : ''} src = {'/images/view-chart.svg'} />} />
                                </Tabs>
                            </Paper>
                        </div>
                    </div>
                    <div className='center'>
                        <div className = 'filter-container'>
                            <IconButton disabled={!filter.year || !filter.month} onClick={() => changeYear('left')}>
                                <ChevronLeftIcon/>
                            </IconButton>
                            <h6>{filter.year.value}</h6>
                            <IconButton disabled={!filter.year || !filter.month} onClick={() => changeYear('right')}>
                                <ChevronRightIcon/>
                            </IconButton>
                        </div>
                        <div className = 'filter-container'>
                            <IconButton disabled={!filter.year || !filter.month} onClick={() => changeMonth('left')}>
                                <ChevronLeftIcon/>
                            </IconButton>
                            <h6>{filter.month.value}</h6>
                            <IconButton disabled={!filter.year || !filter.month} onClick={() => changeMonth('right')}>
                                <ChevronRightIcon/>
                            </IconButton>
                        </div>
                    </div>
                    <div className='right'>
                        <Button variant="contained" color="primary"
                                onClick={() => recalculateCashFlow()}
                        >
                            {strings.pages.finance.cashFlow.recalculate}
                        </Button>
                    </div>

                </div>
            </Paper>
        </>
    }

    const renderTableHeader = () => {
        let result = [
            <TableCell key={'days-cell-description'} className='description fixed' align = {'center'}>
                {strings.pages.finance.cashFlow.description}
            </TableCell>
        ];

        cashFlowData.days.forEach((date, index) =>  {
            result.push(
                <TableCell key={'days-cell-' + index} align = {'center'}>
                    {dateToString(date)}
                </TableCell>
            )
        });

        return (<TableRow className='table-header'>
            {result}
        </TableRow>);
    }

    const getTransactionByDateOrDescription = (date, description) => {

        let result = [];

        for (let transaction of cashFlowData.transactions) {

            const tableDate = dateToString(date);
            const transactionDate = dateToString(new Date(transaction.transaction.paymentDate));

            if (transaction.name === description && tableDate === transactionDate) {
                result.push(transaction);
            }
        }

        return result;
    }

    const getDescriptionColumn = () => {

        let result = [];

        if (cashFlowData.transactions) {
            for (let item of cashFlowData.transactions) {

                let index = result.indexOf(item.name);

                if (index < 0) {
                    result.push(item.name);
                }
            }
        }

        if (cashFlowData.contractParts) {
            for (let item of cashFlowData.contractParts) {

                let index = result.indexOf(item.name);

                if (index < 0) {
                    result.push(item.name);
                }
            }
        }

        if (cashFlowData.organisationUserContractParts) {
            for (let item of cashFlowData.organisationUserContractParts) {

                let index = result.indexOf(item.name);

                if (index < 0) {
                    result.push(item.name);
                }
            }
        }

        return result;
    }

    const getContractPartsByDateOrDescription = (date, description) => {

        let result = [];

        for (let contractPart of cashFlowData.contractParts) {

            const tableDate = dateToString(date);
            const contractDate = contractPart.contractPart.actualPaymentDate ? contractPart.contractPart.actualPaymentDate :
                contractPart.contractPart.paymentDate
            const contractPartDate = dateToString(new Date(contractDate));

            if (contractPart.name === description && tableDate === contractPartDate) {
                result.push(contractPart);
            }
        }

        return result;
    }

    const getOrganisationUserContractPartsByDateOrDescription = (date, description) => {
        let result = [];

        for (let contractPart of cashFlowData.organisationUserContractParts) {

            const tableDate = dateToString(date);
            const contractDate = contractPart.organisationUserContractPart.actualPaymentDate ? contractPart.organisationUserContractPart.actualPaymentDate :
                contractPart.organisationUserContractPart.paymentDate
            const contractPartDate = dateToString(new Date(contractDate));

            if (contractPart.name === description && tableDate === contractPartDate) {
                result.push(contractPart);
            }
        }

        return result;
    }

    const getTransactionByDate = (date) => {

        if (!cashFlowData.transactions) {
            return [];
        }

        let result = [];

        for (let transaction of cashFlowData.transactions) {

            const tableDate = dateToString(date);
            const transactionDate = dateToString(new Date(transaction.transaction.paymentDate));

            if (tableDate === transactionDate) {
                result.push(transaction);
            }
        }

        return result;
    }

    const getContractPartByDate = (date) => {

        if (!cashFlowData.contractParts) {
            return [];
        }

        let result = [];

        for (let contractPart of cashFlowData.contractParts) {

            const tableDate = dateToString(date);
            const transactionDate = dateToString(new Date(contractPart.contractPart.paymentDate));

            if (tableDate === transactionDate) {
                result.push(contractPart);
            }
        }

        return result;
    }

    const getOrganisationUserContractPartByDate = (date) => {
        if (!cashFlowData.organisationUserContractParts) {
            return [];
        }

        let result = [];

        for (let contractPart of cashFlowData.organisationUserContractParts) {

            const tableDate = dateToString(date);
            const transactionDate = dateToString(new Date(contractPart.organisationUserContractPart.paymentDate));

            if (tableDate === transactionDate) {
                result.push(contractPart);
            }
        }

        return result;
    }

    const renderDateAndDescription = (date, description) => {

        let result = [];
        let totalValues = {
            'RSD': 0,
            'EUR': 0,
            'USD': 0,
        }
        let hasValues = {
            'RSD': false,
            'EUR': false,
            'USD': false,
        }

        for (let transaction of getTransactionByDateOrDescription(date, description)) {

            const value = transaction.transaction.income - transaction.transaction.outcome;
            let className = value > 0 ? 'value plus' : 'value minus'
            totalValues[transaction.transaction.currency.code] += value;
            hasValues[transaction.transaction.currency.code] = true;

            result.push(
                <Tooltip title={transaction.transaction.description}>
                    <div className={className}>
                        <p>{renderNumber(value)} {transaction.transaction.currency.code}</p>
                    </div>
                </Tooltip>
            )
        }

        for (let contractPart of getContractPartsByDateOrDescription(date, description)) {

            const step = contractPart.contractPart.contract.contractType === ContractType.CUSTOMER_CONTRACT ? 1 : -1;
            const amount = contractPart.contractPart.actualAmount ? contractPart.contractPart.actualAmount : contractPart.contractPart.amount;
            const value = amount * step;
            totalValues[contractPart.contractPart.contract.currency.code] += value;
            hasValues[contractPart.contractPart.contract.currency.code] = true;

            let className = value > 0 ? 'value plus' : 'value minus'

            result.push(
                <Tooltip title={contractPart.contractPart.contract.description}>
                    <div className={className}>
                        <p>{renderNumber(value)} {contractPart.contractPart.contract.currency.code}</p>
                    </div>
                </Tooltip>
            )
        }

        for (let contractPart of getOrganisationUserContractPartsByDateOrDescription(date, description)) {

            const step = -1;
            const value = contractPart.organisationUserContractPart.gross * step;
            totalValues[contractPart.organisationUserContractPart.organisationUserContract.currency.code] += value;
            hasValues[contractPart.organisationUserContractPart.organisationUserContract.currency.code] = true;

            let className = value > 0 ? 'value plus' : 'value minus'

            result.push(
                <Tooltip title={contractPart.organisationUserContractPart.organisationUserContract.name}>
                    <div className={className}>
                        <p>{renderNumber(value)} {contractPart.organisationUserContractPart.organisationUserContract.currency.code}</p>
                    </div>
                </Tooltip>
            )
        }

        if(!expanded[description]) {
            let classNameRSD = totalValues['RSD'] > 0 ? 'value plus' : 'value minus'
            let classNameEUR = totalValues['EUR'] > 0 ? 'value plus' : 'value minus'
            let classNameUSD = totalValues['USD'] > 0 ? 'value plus' : 'value minus'

            return <TableCell className={'data-cell'}>
                <div>
                    {
                        hasValues['RSD'] &&
                        <Tooltip title={description}>
                            <div className={classNameRSD}>
                                <p>{renderNumber(totalValues['RSD'])} RSD</p>
                            </div>
                        </Tooltip>
                    }
                    {
                        hasValues['EUR'] &&
                        <Tooltip title={description}>
                            <div className={classNameEUR}>
                                <p>{renderNumber(totalValues['EUR'])} EUR</p>
                            </div>
                        </Tooltip>
                    }
                    {
                        hasValues['USD'] &&
                        <Tooltip title={description}>
                            <div className={classNameUSD}>
                                <p>{renderNumber(totalValues['USD'])} USD</p>
                            </div>
                        </Tooltip>
                    }
                </div>
            </TableCell>;
        }
        else {
            return <TableCell className={'data-cell'}>
                <div>
                    {result}
                </div>
            </TableCell>;
        }
    }


    const calculateCurrentValues = () => {
        let result = [];

        let currentValues = {
            'RSD': 0,
            'USD': 0,
            'EUR': 0
        }

        if (cashFlowData && cashFlowData.cashFlowCalculationAmount) {

            for (let amount of cashFlowData.cashFlowCalculationAmount) {
                currentValues[amount.account.currency.code] += amount.total;
            }
        }

        if (cashFlowData && cashFlowData.cashFlowContractPartAmount) {

            for (let amount of cashFlowData.cashFlowContractPartAmount) {
                currentValues[amount.currency.code] += amount.total;
            }
        }

        for (let date of cashFlowData.days) {

            let transitions = getTransactionByDate(date);
            let contractParts = getContractPartByDate(date);
            let organisationUserContractParts = getOrganisationUserContractPartByDate(date);

            for (let transaction of transitions) {

                const value = transaction.transaction.income - transaction.transaction.outcome;
                currentValues[transaction.transaction.currency.code] += value;
            }

            for (let contractPart of contractParts) {

                const step = contractPart.contractPart.contract.contractType === ContractType.CUSTOMER_CONTRACT ? 1 : -1;
                currentValues[contractPart.contractPart.contract.currency.code] += step * contractPart.contractPart.amount;
            }

            for (let contractPart of organisationUserContractParts) {

                const step = -1;
                currentValues[contractPart.organisationUserContractPart.organisationUserContract.currency.code] +=
                    step * contractPart.organisationUserContractPart.gross;
            }

            const classNameRSD = currentValues['RSD'] >= 0 ? 'value plus' : 'value minus'
            const classNameEUR = currentValues['EUR'] >= 0 ? 'value plus' : 'value minus'
            const classNameUSD = currentValues['USD'] >= 0 ? 'value plus' : 'value minus'

            result.push({currentValues: {...currentValues}, classNameEUR, classNameRSD, classNameUSD})

        }
        setCurrentResult(result)
    }

    const renderCurrentValues = () => {


        return <TableRow className='cash-flow-row'>
            <TableCell className='description fixed' align = {'center'}>
                {strings.pages.finance.cashFlow.currentValues}
            </TableCell>
            {
                currentResult.map((item, index) => (
                    <TableCell key={'data-cell-' + index} className='data-cell'>
                        <div className={item.classNameRSD}>
                            <p>{renderNumber(item.currentValues['RSD'])} RSD</p>
                        </div>
                        <div className={item.classNameEUR}>
                            <p>{renderNumber(item.currentValues['EUR'])} EUR</p>
                        </div>
                        <div className={item.classNameUSD}>
                            <p>{renderNumber(item.currentValues['USD'])} USD</p>
                        </div>
                    </TableCell>
                ))
            }
        </TableRow>
    }

    const renderData = () => {

        const descriptions = getDescriptionColumn();
        let result = [];

        if (!descriptions) {
            return result;
        }

        for (let description of descriptions) {
            let row = [
                <TableCell key={'description-' + result.length} className='description fixed'
                onClick={() => setExpanded({
                    ...expanded,
                    [description]: !expanded[description]
                })} align = {'center'}>
                    { expanded[description] ? '-' : '+'} {description}
                </TableCell>
            ];

            for (let date of cashFlowData.days) {
                row.push(renderDateAndDescription(date, description));
            }

            const className = 'cash-flow-row table-row-' + result.length % 2;

            result.push(
                <TableRow className={className}>
                    {row}
                </TableRow>
            );
        }

        return result;
    }

    const renderTable = () => {
        return (
            filter.year?.value && filter.month?.id &&
            <ScrollContainer>
                <TableContainer component = {Paper}>
                    <Table>
                        <TableHead>
                            {renderTableHeader()}
                        </TableHead>
                        <TableBody>
                            {renderCurrentValues()}
                            {renderData()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ScrollContainer>
        )
    }

    const view = () => {
        if (viewType === ViewType.CHART) {
            return ( 
                <div className = 'line-chart-container'>
                    <LineChart
                        labels={getDaysOfMonthLabels(getDaysOfMonth(filter.year.value, filter.month.id).length, false)}
                        inputData = {cashFlowChartData.datasets}
                        options = {{
                            maintainAspectRatio: false,
                        }}
                    />
                </div>
            );
        }
        return renderTable()
    }

    return (
        // hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup], auth.permissions) &&
        <div id='cash-flow'>
            {renderHeader()}
            {view()}
        </div>
    );
}

export default CashFlow;
