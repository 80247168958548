import {createContext} from "react";
import PageState from "../Constants/Base/PageState";

const CommentPageContext = createContext({
    selected: null,
    setSelected: () => {},
    editMode: false,
    setEditMode: () => {},
});

export default CommentPageContext;

