import {useDispatch, useSelector} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import strings from "../../../localization";
import {
    getCompanyS3Settings,
    updateCompanyS3Settings
} from "../../../Services/Company/CompanyService";
import {Alert, Grid, Paper, Snackbar} from "@mui/material";
import S3SettingsForm from "../../../Components/Forms/Pages/Company/S3SettingsForm";
import {useForm} from "react-hook-form";
import SnackbarContext from "../../../Context/SnackbarContext";
import {setCompanyS3Settings} from "../../../Slices/AuthSlice";

const formRules = {
    'version': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'region': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    's3key': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'secret': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'bucket': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'url': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}}
}

const S3Settings = () => {

    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch();
    const form = useForm();
    const {handleSubmit, reset, setValue, formState: {errors}} = form;
    const { showMessage } = useContext(SnackbarContext);

    useEffect(() => {
        dispatch(changeBreadcrumbs({
            title: strings.pages.company.settings.s3settings,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.ADMINISTRATION},
                {label: strings.pages.company.company},
                {label: strings.pages.company.settings.s3settings},
            ],        
        }));

        fetch();
    }, []);


    fetch = () => {
        getCompanyS3Settings(auth.company.id).then(response => {
            if(!response || !response.ok) {
                return;
            }

            reset(response.data.entity);
        })
    }

    const save = (data) => {
        updateCompanyS3Settings(data).then(response => {
            if(!response || !response.ok) {
                showMessage(strings.commonMessages.errorSaving, 'error');
                return;
            }
            dispatch(setCompanyS3Settings(response.data.entity))
            showMessage(strings.commonMessages.saved, 'success');
        });
    }

    return (
        <Grid 
            container 
            justifyContent="center"
            alignItems="center"
            className = 'company-form-container'
        >
            <Grid item xs={12} className = 'form-container s3-settings'>
                <S3SettingsForm
                    onSubmit={handleSubmit(save)}
                    errors={errors} form={form} formRules={formRules}
                />
            </Grid>
        </Grid>
    )
}

export default S3Settings;
