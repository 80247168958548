import React, { useEffect, useState, useRef } from "react";
import {
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuState from "../../Constants/Base/MenuState";
import { useDispatch, useSelector } from "react-redux";
import strings from "../../localization";
import { canAccess, isFreeAccount } from "../../Util/PermissionUtil";
import { isCompanyValid } from "../../Util/CompanyUtil";
import ModulePermission from "../../Constants/Permissions/ModulePermission";
import AppPermissions from "../../Constants/Permissions/Permissions";
import NavigationIcon, {
  DotSmallIcon,
  ShowLessIcon,
  ShowMoreIcon,
} from "../../Util/ImageUtil";

const getNavigationClass = (state) => {
  if (state === MenuState.SHORT) {
    return "navigation-content-container short";
  }

  return "navigation-content-container";
};

const Module = {
  dashboard: 0,
  calendar: 1,
  workspaces: 2,
  hr: 3,
  finance: 4,
  crm: 5,
  administration: 6,
  companyDocument: 7,
  project: 8,
  documentManagement: 9,
  assetManagement: 10,
  startTrial: 11,
  settings: 12,
  spaces: 13,
  product: 14,
  checkList: 15,
  home: 16,
};

const ModuleLinks = {
  hr: [
    "/presences",
    "/employees",
    "/organisation-user-contracts",
    "/teams",
    "/organisation-units",
    "/jobs",
    "/candidates",
    "/additional-hours",
    "/leave-requests",
    "/leaves",
    "/hr-settings",
  ],
  product: ["/product-services"],
  finance: [
    "/out/invoices",
    "/in/invoices",
    "/cash-flow",
    "/contracts",
    "/manage-bank-reports",
    "/bank-reports",
    "/transactions",
    "/finance-settings/bank-accounts",
  ],
  crm: [
    "/deal-pipelines",
    "/partners",
    "/partner-contacts",
    "/crm-settings/deal-type",
  ],
  administration: [
    "/company-settings",
    "/users",
    "/roles",
    "/pipelines",
    "/asset-management-locations",
  ],
  companyDocument: ["/company-documents", "/company-document-templates"],
  project: [
    "/project",
    "/project-resource",
    "/project-time-log",
    "/project-settings/project-categories",
  ],
  checkList: ["/check-list-template"],
  documentManagement: [
    "/file-folders",
    "/document-category",
    "/document-status",
  ],
  assetManagement: [
    "/assets",
    "/asset-types",
    "/asset-categories",
    "/inventories",
    "/stock-changes",
    "/inventory-types",
    "/inventory-categories",
    "/vendors",
  ],
};

const Navigation = () => {
  const [submenu, setSubmenu] = useState({});
  const [activeHoverMenu, setActiveHoverMenu] = useState(null);
  const [hoverMenu, setHoverMenu] = useState(null);
  const [changes, setChanges] = useState(0);
  const menuState = useSelector((state) => state.navigation.menuState);
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const [companyValid, setCompanyValid] = useState(
    isCompanyValid(auth.company)
  );
  const company = useSelector((state) => state.auth.company);

  const isFull = () => menuState === MenuState.FULL;
  const isShort = () => menuState === MenuState.SHORT;

  const popperBorders = useRef(null);

  useEffect(() => {
    Object.keys(ModuleLinks).forEach((module) => {
      if (ModuleLinks[module].indexOf(location.pathname) >= 0) {
        setSubmenu({ [module]: true });
      }
    });
  }, [changes]);

  useEffect(() => {
    const checkHover = (event) => {
      const path = event
        .composedPath()
        .filter((el, index) => el.classList?.contains("navigation"));

      if (path.length > 0) {
        popperBorders.current = path[0].parentElement.getBoundingClientRect();
      }

      if (
        popperBorders.current?.top > event.y ||
        popperBorders.current?.bottom < event.y ||
        popperBorders.current?.right < event.x
      ) {
        popperBorders.current = null;
        setHoverMenu(null);
        setActiveHoverMenu(null);
      }
    };
    document.addEventListener("mouseover", checkHover);

    return () => {
      document.removeEventListener("mouseover", checkHover);
    };
  }, []);

  const urlChange = () => {
    setChanges((oldValue) => oldValue + 1);
  };

  useEffect(() => {
    setCompanyValid(isCompanyValid(auth.company));
  }, [auth.company]);

  const isCurrentPath = (path) => {
    return location.pathname === path;
  };

  const handleSubmenu = (name) => {
    if (
      ["home", "dashboard", "calendar", "workspaces", "spaces"].includes(name)
    ) {
      setSubmenu({});
      return;
    }

    if (menuState === MenuState.SHORT) {
      return;
    }
    setSubmenu({ [name]: !submenu[name] });
  };

  const changeHoverMenu =
    (value = null) =>
    (event) => {
      setHoverMenu(value);
      setActiveHoverMenu(value ? event?.currentTarget : null);
    };

  const NavLink = ({ link = "", label = "" }) => {
    const isActive = isCurrentPath(link);

    const changeHoverMenu = (value = null) => (event) => {
        setHoverMenu(value);
        setActiveHoverMenu(value? event?.currentTarget: null)
    }

    const NavLink = ({link= '', label = ''}) => {

        const isActive = isCurrentPath(link);

        return <Link to={link} onClick={urlChange}
                className={isActive ? 'navigation-link active' : 'navigation-link'}>
            <ListItem className='navigation-item'>
                <ListItemIcon className='navigation-icon'>
                    <div className="dot-container">
                        <DotSmallIcon active={isActive} isShort={isShort()}/>
                    </div>                                           
                </ListItemIcon>
                <ListItemText inset primary={label}
                                className={isShort() ? 'navigation-text-dark' : 'navigation-text'}/>
            </ListItem>
        </Link>

    }

    const HrMenu = () => {
        return  <List className='submenu'>
            {
                canAccess(auth.company, ModulePermission.HR, auth.user, AppPermissions.PRESENCE.GROUP,
                    AppPermissions.PRESENCE.VIEW_LIST, auth.permissions) && 
                    <NavLink link="/presences" label={strings.navigation.presence} />
            }
        
            {
                canAccess(auth.company, ModulePermission.HR, auth.user, AppPermissions.EMPLOYEE.GROUP,
                    AppPermissions.EMPLOYEE.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/employees" label={strings.navigation.employees} />
            }
            {
                canAccess(auth.company, ModulePermission.HR, auth.user, AppPermissions.ORGANISATION_USER_CONTRACT.GROUP,
                    AppPermissions.ORGANISATION_USER_CONTRACT.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/organisation-user-contracts" label={strings.navigation.employeeContracts} />
            }
            {
                canAccess(auth.company, ModulePermission.HR, auth.user, AppPermissions.TEAM.GROUP,
                    AppPermissions.TEAM.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/teams" label={strings.navigation.team} />
            }
            {
                canAccess(auth.company, ModulePermission.HR, auth.user, AppPermissions.ORGANISATION_UNIT.GROUP,
                    AppPermissions.ORGANISATION_UNIT.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/organisation-units" label={strings.navigation.organisationUnit} />
            }
            
            {
                canAccess(auth.company, ModulePermission.HR, auth.user, AppPermissions.JOB.GROUP,
                    AppPermissions.JOB.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/jobs" label={strings.navigation.job} />
            }
            {
                canAccess(auth.company, ModulePermission.HR, auth.user, AppPermissions.CANDIDATE.GROUP,
                    AppPermissions.CANDIDATE.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/candidates" label={strings.navigation.candidate} />
            }
            
            {
                canAccess(auth.company, ModulePermission.HR, auth.user, AppPermissions.ADDITIONAL_HOURS.GROUP,
                    AppPermissions.ADDITIONAL_HOURS.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/additional-hours" label={strings.navigation.additionalHours} />
            }
            {
                canAccess(auth.company, ModulePermission.HR, auth.user, AppPermissions.LEAVE_REQUEST.GROUP,
                    AppPermissions.LEAVE_REQUEST.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/leave-requests" label={strings.navigation.leaveRequest} />
            }
            {
                canAccess(auth.company, ModulePermission.HR, auth.user, AppPermissions.LEAVE.GROUP,
                    AppPermissions.LEAVE.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/leaves" label={strings.navigation.leave} />
            }
            
            {
                canAccess(auth.company, ModulePermission.HR, auth.user, AppPermissions.HR_SETTINGS.GROUP,
                    AppPermissions.HR_SETTINGS.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/hr-settings" label={strings.navigation.settings} />
            }
            
        </List>
    }
    const ProductMenu = () => {
        return <List className='submenu'>
            {
                        (canAccess(auth.company, ModulePermission.PRODUCT, auth.user, AppPermissions.PRODUCTS_AND_SERVICES.GROUP,
                            AppPermissions.PRODUCTS_AND_SERVICES.VIEW_LIST, auth.permissions) || isFreeAccount(auth.company)) &&
                        <NavLink link="/product-services" label={strings.navigation.productServices} />
            }
            {
                (canAccess(auth.company, ModulePermission.PRODUCT, auth.user, AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.GROUP,
                    AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.VIEW_LIST, auth.permissions) || isFreeAccount(auth.company)) &&
                <NavLink link="/product-services-category" label={strings.navigation.productServicesCategory} />
            }
            {
                (canAccess(auth.company, ModulePermission.PRODUCT, auth.user, AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.GROUP,
                    AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.VIEW_LIST, auth.permissions) || isFreeAccount(auth.company)) &&
                <NavLink link="/product-catalog" label={strings.navigation.productCatalog} />
            }
            {
                (canAccess(auth.company, ModulePermission.PRODUCT, auth.user, AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.GROUP,
                    AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.VIEW_LIST, auth.permissions) || isFreeAccount(auth.company)) &&
                <NavLink link="/attributes" label={strings.navigation.attribute} />
            }
            {
                (canAccess(auth.company, ModulePermission.PRODUCT, auth.user, AppPermissions.PRODUCT_SETTINGS.GROUP,
                    AppPermissions.PRODUCT_SETTINGS.VIEW_LIST, auth.permissions) || isFreeAccount(auth.company)) &&
                <NavLink link="/product-settings" label={strings.navigation.productSettings} />
            }
        </List>
    }

    const FinanceMenu = () => {
        return <List className='submenu'>
        {
            (canAccess(auth.company, ModulePermission.FINANCE) || isFreeAccount(auth.company)) &&
            <React.Fragment>
                {
                    (canAccess(auth.company, ModulePermission.FINANCE, auth.user, AppPermissions.INVOICE.GROUP,
                        AppPermissions.INVOICE.VIEW_LIST, auth.permissions) || isFreeAccount(auth.company)) &&
                        <NavLink link="/out/invoices" label={strings.navigation.invoices} />
                }
            </React.Fragment>
        }
        {
            canAccess(auth.company, ModulePermission.FINANCE, auth.user) &&
            <React.Fragment>
                {
                    canAccess(auth.company, ModulePermission.FINANCE, auth.user, AppPermissions.BILL.GROUP,
                        AppPermissions.BILL.VIEW_LIST, auth.permissions) &&
                        <NavLink link="/in/invoices" label={strings.navigation.bills} />
                }
                {
                canAccess(auth.company, ModulePermission.FINANCE, auth.user, AppPermissions.CASH_FLOW.GROUP,
                    AppPermissions.CASH_FLOW.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/cash-flow" label={strings.navigation.cashFlow} />
                }
                
                {
                    canAccess(auth.company, ModulePermission.FINANCE, auth.user, AppPermissions.CONTRACT.GROUP,
                        AppPermissions.CONTRACT.VIEW_LIST, auth.permissions) &&
                        <NavLink link="/contracts" label={strings.navigation.contracts} />
                }
                
                {
                    canAccess(auth.company, ModulePermission.FINANCE, auth.user, AppPermissions.UPLOAD_BANK_REPORT.GROUP,
                        AppPermissions.UPLOAD_BANK_REPORT.VIEW_LIST, auth.permissions) &&
                        <NavLink link="/manage-bank-reports" label={strings.navigation.uploadBankReport} />
                }
                {
                    canAccess(auth.company, ModulePermission.FINANCE, auth.user, AppPermissions.BANK_REPORT.GROUP,
                        AppPermissions.BANK_REPORT.VIEW_LIST, auth.permissions) &&
                        <NavLink link="/bank-reports" label={strings.navigation.bankReport} />
                }
                {
                    canAccess(auth.company, ModulePermission.FINANCE, auth.user, AppPermissions.TRANSACTION.GROUP,
                        AppPermissions.TRANSACTION.VIEW_LIST, auth.permissions) &&
                        <NavLink link="/transactions" label={strings.navigation.transactions} />
                }      
                
                {
                    (canAccess(auth.company, ModulePermission.FINANCE, auth.user, AppPermissions.FINANCE_SETTINGS.GROUP,
                        AppPermissions.FINANCE_SETTINGS.VIEW_LIST, auth.permissions)) &&
                        <NavLink link="/finance-settings/bank-accounts" label={strings.navigation.settings} />
                }
                
            </React.Fragment>
        }
    </List>
    }

    const AdministrationMenu = () => {
        return <List className='submenu'>
        {
        canAccess(auth.company, ModulePermission.ADMINISTRATION, auth.user, AppPermissions.COMPANY_SETTINGS.GROUP,
            AppPermissions.COMPANY_SETTINGS.VIEW_LIST, auth.permissions) &&
            <NavLink link="/company-settings" label={strings.navigation.company} />
        }
        {
            !isFreeAccount(auth.company) &&
            <React.Fragment>
                {
                    canAccess(auth.company, ModulePermission.ADMINISTRATION, auth.user, AppPermissions.USER.GROUP,
                        AppPermissions.USER.VIEW_LIST, auth.permissions) &&
                        <NavLink link="/users" label={strings.navigation.user} />
                }
                {
                    canAccess(auth.company, ModulePermission.ADMINISTRATION, auth.user, AppPermissions.ROLE.GROUP,
                        AppPermissions.ROLE.VIEW_LIST, auth.permissions) &&
                        <NavLink link="/roles" label={strings.navigation.role} />
                }
                {   
                    canAccess(auth.company, ModulePermission.ADMINISTRATION, auth.user, AppPermissions.PIPE_LINE.GROUP,
                        AppPermissions.PIPE_LINE.VIEW_LIST, auth.permissions) &&
                        <NavLink link="/pipelines" label={strings.navigation.pipeline} />

                }
                {
                    canAccess(auth.company, ModulePermission.ADMINISTRATION, auth.user, AppPermissions.ASSET_LOCATION.GROUP,
                        AppPermissions.ASSET_LOCATION.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/asset-management-locations" label={strings.navigation.location} />
                }
            </React.Fragment>
        }
    </List>
    }

    const CRMMenu = () => {
        return <List className='submenu'>
        {
            (canAccess(auth.company, ModulePermission.CRM) || isFreeAccount(auth.company)) &&
            <React.Fragment>
                
                {
                    (canAccess(auth.company, ModulePermission.CRM, auth.user, AppPermissions.DEAL.GROUP,
                        AppPermissions.DEAL.VIEW_LIST, auth.permissions) || isFreeAccount(auth.company)) &&
                        <NavLink link="/deal-pipelines" label={strings.navigation.deals} />
                }
                
                {
                    (canAccess(auth.company, ModulePermission.CRM, auth.user, AppPermissions.PARTNER.GROUP,
                        AppPermissions.PARTNER.VIEW_LIST, auth.permissions) || isFreeAccount(auth.company)) &&
                        <NavLink link="/partners" label={strings.navigation.partners} />
                }
            </React.Fragment>
        }
        {
            canAccess(auth.company, ModulePermission.CRM) &&
            <React.Fragment>
                {
                    canAccess(auth.company, ModulePermission.CRM, auth.user, AppPermissions.PARTNER_CONTACT.GROUP,
                        AppPermissions.PARTNER_CONTACT.VIEW_LIST, auth.permissions) &&
                        <NavLink link="/partner-contacts" label={strings.navigation.partnerContacts} />
                }
            </React.Fragment>
        }
        {
            canAccess(auth.company, ModulePermission.CRM) &&
            <React.Fragment>
                {
                    canAccess(auth.company, ModulePermission.CRM, auth.user, AppPermissions.CUSTOMER.GROUP,
                        AppPermissions.CUSTOMER.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/customer" label={strings.navigation.customer} />
                }
            </React.Fragment>
        }
        {
            (canAccess(auth.company, ModulePermission.CRM, auth.user, AppPermissions.CRM_SETTINGS.GROUP,
                AppPermissions.CRM_SETTINGS.VIEW_LIST, auth.permissions)) &&
                <NavLink link="/crm-settings/deal-type" label={strings.navigation.settings} />
        }
     </List >
    }

    const CompanyDocumentMenu = () => {
        return <List className='submenu'>
        {
            canAccess(auth.company, ModulePermission.COMPANY_DOCUMENT, auth.user, AppPermissions.COMPANY_DOCUMENT.GROUP,
                AppPermissions.COMPANY_DOCUMENT.VIEW_LIST, auth.permissions) &&
                <NavLink link="/company-documents" label={strings.navigation.documents} />
        }
        {
            canAccess(auth.company, ModulePermission.COMPANY_DOCUMENT, auth.user, AppPermissions.COMPANY_DOCUMENT_TEMPLATE.GROUP,
                AppPermissions.COMPANY_DOCUMENT_TEMPLATE.VIEW_LIST, auth.permissions) &&
                <NavLink link="/company-document-templates" label={strings.navigation.documentTemplates} />
        }

        
    </List>
    }

    const ProjectMenu = () => {
        return <List className='submenu'>
        {
            canAccess(auth.company, ModulePermission.PROJECT_MANAGEMENT, auth.user, AppPermissions.PROJECT.GROUP,
                    AppPermissions.PROJECT.VIEW_LIST, auth.permissions) &&
                <NavLink link="/project" label={strings.navigation.project} />
        }
        {
            canAccess(auth.company, ModulePermission.PROJECT_MANAGEMENT, auth.user, AppPermissions.PROJECT_RESOURCE.GROUP,
                AppPermissions.PROJECT_RESOURCE.VIEW_LIST, auth.permissions) &&
                <NavLink link="/project-resource" label={strings.navigation.projectResource} />
        }
        
        {
            canAccess(auth.company, ModulePermission.PROJECT_MANAGEMENT, auth.user, AppPermissions.PROJECT_TIME_LOG.GROUP,
                AppPermissions.PROJECT_TIME_LOG.VIEW_LIST, auth.permissions) &&
                <NavLink link="/project-time-log" label={strings.navigation.projectTimeLog} />
        }
        
        {
            canAccess(auth.company, ModulePermission.PROJECT_MANAGEMENT, auth.user, AppPermissions.PROJECT_SETTINGS.GROUP,
                AppPermissions.PROJECT_SETTINGS.VIEW_LIST, auth.permissions) &&
                <NavLink link="/project-settings/project-categories" label={strings.navigation.settings} />
        }
        
    </List>
    }

    const CheckListMenu = () => {
        return <List className='submenu'>
            {
                (canAccess(auth.company, ModulePermission.CHECK_LIST, auth.user, AppPermissions.CHECK_LIST.GROUP,
                    AppPermissions.CHECK_LIST.VIEW_LIST, auth.permissions) || isFreeAccount(auth.company)) &&
                <NavLink link="/check-list-template" label={strings.navigation.checkListTemplate}/>
            }
            {
                (canAccess(auth.company, ModulePermission.CHECK_LIST, auth.user, AppPermissions.CHECK_LIST_SCHEDULED_CHECK.GROUP,
                    AppPermissions.CHECK_LIST_SCHEDULED_CHECK.VIEW_LIST, auth.permissions) || isFreeAccount(auth.company)) &&
                <NavLink link="/check-list-scheduled-checks" label={strings.navigation.checkListScheduledCheks}/>
            }
        </List>
    }

    const DocumentManagementMenu = () => {
        return <List className='submenu'>
        {
            canAccess(auth.company, ModulePermission.DOCUMENT, auth.user, AppPermissions.FILE_FOLDER.GROUP,
                AppPermissions.FILE_FOLDER.VIEW_LIST, auth.permissions) &&
                <NavLink link="/file-folders" label={strings.navigation.fileFolders} />
        }
        {
            canAccess(auth.company, ModulePermission.DOCUMENT, auth.user, AppPermissions.DOCUMENT_CATEGORY.GROUP,
                AppPermissions.DOCUMENT_CATEGORY.VIEW_LIST, auth.permissions) &&
                <NavLink link="/document-category" label={strings.navigation.fileCategory} />
        }
        {
            canAccess(auth.company, ModulePermission.DOCUMENT, auth.user, AppPermissions.DOCUMENT_STATUS.GROUP,
                AppPermissions.DOCUMENT_STATUS.VIEW_LIST, auth.permissions) &&
                <NavLink link="/document-status" label={strings.navigation.fileStatus} />
        }
    </List>
    }

    const AssetManagementMenu = () => {
        return <List className='submenu'>
            {
                canAccess(auth.company, ModulePermission.ASSET_MANAGEMENT, auth.user, AppPermissions.ASSET.GROUP,
                    AppPermissions.ASSET.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/assets" label={strings.navigation.assets} />
            }
            {
                canAccess(auth.company, ModulePermission.ASSET_MANAGEMENT, auth.user, AppPermissions.ASSET_TYPE.GROUP,
                    AppPermissions.ASSET_TYPE.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/asset-types" label={strings.navigation.assetTypes} />
            }
            {
                canAccess(auth.company, ModulePermission.ASSET_MANAGEMENT, auth.user, AppPermissions.ASSET_CATEGORY.GROUP,
                    AppPermissions.ASSET_CATEGORY.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/asset-categories" label={strings.navigation.assetCategories} />
            }
            
            {
                canAccess(auth.company, ModulePermission.ASSET_MANAGEMENT, auth.user, AppPermissions.INVENTORY.GROUP,
                    AppPermissions.INVENTORY.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/inventories" label={strings.navigation.inventory} />
            }
            {
                canAccess(auth.company, ModulePermission.ASSET_MANAGEMENT, auth.user, AppPermissions.STOCK_CHANGE.GROUP,
                    AppPermissions.STOCK_CHANGE.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/stock-changes" label={strings.navigation.stockChange} />
            }
            {
                canAccess(auth.company, ModulePermission.ASSET_MANAGEMENT, auth.user, AppPermissions.INVENTORY_TYPE.GROUP,
                    AppPermissions.INVENTORY_TYPE.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/inventory-types" label={strings.navigation.inventoryType} />
            }
            {
                canAccess(auth.company, ModulePermission.ASSET_MANAGEMENT, auth.user, AppPermissions.INVENTORY_CATEGORY.GROUP,
                    AppPermissions.INVENTORY_CATEGORY.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/inventory-categories" label={strings.navigation.inventoryCategory} />
            }
            
            {
                canAccess(auth.company, ModulePermission.ASSET_MANAGEMENT, auth.user, AppPermissions.VENDOR.GROUP,
                    AppPermissions.VENDOR.VIEW_LIST, auth.permissions) &&
                    <NavLink link="/vendors" label={strings.navigation.vendors} />
            }
        </List>

    }
    const getMenuItems = () => {

        return (
            <React.Fragment>
                <div className="home-tag">
                    {strings.navigation.homeTag}
                </div>
                {
                   !company?.accountSettings?.trialUsed &&
                    <Link to={'/start-trial'}  className={isCurrentPath('/start-trial') ? 'navigation-link active' : 'navigation-link'}>
                        <ListItem className='navigation-item' component="div" 
                        onMouseOver={() =>setHoverMenu(Module.startTrial)}
                        onMouseLeave={() =>setHoverMenu()}>
                        <ListItemIcon className='navigation-icon'>
                            <NavigationIcon
                                icon="/images/icons/grey/dashboard-grey.svg"
                                activeIcon="/images/icons/blue/dashboard-blue.svg"
                                isActive={() => isCurrentPath('/start-trial') || hoverMenu === Module.startTrial}
                            />
                        </ListItemIcon>
                        <ListItemText inset primary={strings.navigation.startTrial} className='navigation-text main-item'/>
                    </ListItem>
                    </Link>
                }
                {
                    canAccess(auth.company, ModulePermission.HOME) &&
                    canAccess(auth.company, ModulePermission.HOME, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.HOME, auth.permissions) &&
                    <Link to={'/home'} className={isCurrentPath('/home') ? 'navigation-link active' : 'navigation-link'}>
                        <ListItem className='navigation-item' component="div"
                                  onMouseOver={() =>setHoverMenu(Module.home)}
                                  onMouseLeave={() =>setHoverMenu()}
                                  onClick={() => handleSubmenu('home')}
                        >
                            <ListItemIcon className='navigation-icon'>
                                <NavigationIcon
                                    icon="/images/icons/grey/home-white.svg"
                                    activeIcon="/images/icons/blue/home-blue.svg"
                                    isActive={() => isCurrentPath('/home') || hoverMenu === Module.home}
                                />
                            </ListItemIcon>
                            <ListItemText inset primary={strings.navigation.home} className='navigation-text main-item'/>
                        </ListItem>
                    </Link>
                }
                {
                canAccess(auth.company, ModulePermission.DASHBOARD) &&
                canAccess(auth.company, ModulePermission.DASHBOARD, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.DASHBOARD, auth.permissions) &&
                <Link to={'/dashboard'} className={isCurrentPath('/dashboard') ? 'navigation-link active' : 'navigation-link'}>
                    <ListItem className='navigation-item' component="div" 
                        onMouseOver={() =>setHoverMenu(Module.dashboard)}
                        onMouseLeave={() =>setHoverMenu()}
                        onClick={() => handleSubmenu('dashboard')}
                    >
                        <ListItemIcon className='navigation-icon'>
                            <NavigationIcon
                                icon="/images/icons/grey/dashboard-grey.svg"
                                activeIcon="/images/icons/blue/dashboard-blue.svg"
                                isActive={() => isCurrentPath('/dashboard') || hoverMenu === Module.dashboard}
                            />
                        </ListItemIcon>
                        <ListItemText inset primary={strings.navigation.dashboard} className='navigation-text main-item'/>
                    </ListItem>
                </Link>
                }
                {
                canAccess(auth.company, ModulePermission.CALENDAR) &&
                canAccess(auth.company, ModulePermission.CALENDAR, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.CALENDAR, auth.permissions) &&
                <Link to={'/calendar'} className={isCurrentPath('/calendar') ? 'navigation-link active' : 'navigation-link'}>
                    <ListItem className='navigation-item' component="div"
                        onMouseOver={() =>setHoverMenu(Module.calendar)}
                        onMouseLeave={() =>setHoverMenu()}                 
                        onClick={() => handleSubmenu('calendar')}
                    >
                    <ListItemIcon className='navigation-icon'>
                            <NavigationIcon
                                icon="/images/icons/grey/calendar-grey.svg"
                                activeIcon="/images/icons/blue/calendar-blue.svg"
                                isActive={() => isCurrentPath('/calendar') || hoverMenu === Module.calendar}
                            />
                        </ListItemIcon>
                        <ListItemText inset primary={strings.navigation.calendar} className='navigation-text main-item'/>
                    </ListItem>
                </Link>
                }
                {
                canAccess(auth.company, ModulePermission.WORKSPACES) &&
                canAccess(auth.company, ModulePermission.WORKSPACES, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.WORKSPACES, auth.permissions) &&
                <Link to={'/workspaces'} className={isCurrentPath('/workspaces') ? 'navigation-link active' : 'navigation-link'}>
                    <ListItem className='navigation-item' component="div"
                        onMouseOver={() =>setHoverMenu(Module.workspaces)}
                        onMouseLeave={() =>setHoverMenu()}
                        onClick={() => handleSubmenu('workspaces')}
                    >                    
                    <ListItemIcon className='navigation-icon'>
                            <NavigationIcon
                                icon="/images/icons/grey/workspaces-grey.svg"
                                activeIcon="/images/icons/blue/workspaces-blue.svg"
                                isActive={() => isCurrentPath('/workspaces') || hoverMenu === Module.workspaces}
                            />
                        </ListItemIcon>
                        <ListItemText inset primary={strings.navigation.workspaces} className='navigation-text main-item'/>
                    </ListItem>
                </Link>
                }
                {
                canAccess(auth.company, ModulePermission.SPACES) &&
                canAccess(auth.company, ModulePermission.SPACES, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.SPACES, auth.permissions) &&
                <Link to={'/spaces'} className={isCurrentPath('/spaces') ? 'navigation-link active' : 'navigation-link'}>
                    <ListItem className='navigation-item' component="div"
                        onMouseOver={() =>setHoverMenu(Module.spaces)}
                        onMouseLeave={() =>setHoverMenu()}
                        onClick={() => handleSubmenu('spaces')}>                    
                    <ListItemIcon className='navigation-icon'>
                            <NavigationIcon
                                icon="/images/icons/grey/spaces-grey.svg"
                                activeIcon="/images/icons/blue/spaces-blue.svg"
                                isActive={() => isCurrentPath('/spaces') || hoverMenu === Module.spaces}
                            />
                        </ListItemIcon>
                        <ListItemText inset primary={strings.navigation.spaces} className='navigation-text main-item'/>
                    </ListItem>
                </Link>
                }
                <div className="managment-tag">
                    {strings.navigation.managmentTag}
                </div>
                {
                   !company?.accountSettings?.trialUsed &&
                    <Link to={'/company-settings'}  className={isCurrentPath('/company-settings') ? 'navigation-link active' : 'navigation-link'}>
                        <ListItem className='navigation-item' component="div" 
                        onMouseOver={() =>setHoverMenu(Module.settings)}
                        onMouseLeave={() =>setHoverMenu()}>
                        <ListItemIcon className='navigation-icon'>
                            <NavigationIcon
                                icon="/images/icons/grey/administration-grey.svg"
                                activeIcon="/images/icons/blue/administration-blue.svg"
                                isActive={() => isCurrentPath('/company-settings') || hoverMenu === Module.settings}
                            />
                        </ListItemIcon>
                        <ListItemText inset primary={strings.navigation.settings} className='navigation-text main-item'/>
                    </ListItem>
                    </Link>
                }
                {
                    canAccess(auth.company, ModulePermission.HR) &&
                    canAccess(auth.company, ModulePermission.HR, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.HR, auth.permissions) &&
                    <React.Fragment>
                        <ListItem className={'navigation-item navigation-link' + (submenu.hr ? " active": "")}
                                onClick={() => handleSubmenu('hr')} component="div"
                                onMouseOver={changeHoverMenu(Module.hr)}
                        >
                            <ListItemIcon className='navigation-icon'>
                                <NavigationIcon
                                    icon="/images/icons/grey/hr-grey.svg"
                                    activeIcon="/images/icons/blue/hr-blue.svg"
                                    isActive={() => submenu.hr || hoverMenu === Module.hr}
                                />
                            </ListItemIcon>
                            <ListItemText inset primary={strings.navigation.hr} className='navigation-text main-item'/>
                            {submenu.hr ? <ShowLessIcon /> : <ShowMoreIcon />}
                        </ListItem>
                        {
                            isShort() &&
                            <Popper open={hoverMenu === Module.hr} anchorEl={activeHoverMenu} placement={'right-end'}>
                                <div className="navigation fixed">
                                    <span className='section-title'>{strings.navigation.hr}</span>
                                    {HrMenu()}
                                </div>
                            </Popper>
                        }
                        <Collapse in={submenu.hr} timeout="auto" unmountOnExit>
                            {HrMenu()}
                        </Collapse>
                    </React.Fragment>
                }
                {
                    canAccess(auth.company, ModulePermission.PRODUCT) &&
                    canAccess(auth.company, ModulePermission.PRODUCT, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.PRODUCT, auth.permissions) &&
                    <React.Fragment>
                        <ListItem className={'navigation-item navigation-link' + (submenu.product ? " active": "")}
                                  onClick={() => handleSubmenu('product')} component="div"
                                  onMouseOver={changeHoverMenu(Module.product)}
                        >
                            <ListItemIcon className='navigation-icon'>
                                <NavigationIcon
                                    icon="/images/icons/grey/product-grey.svg"
                                    activeIcon="/images/icons/blue/product-blue.svg"
                                    isActive={() => submenu.product || hoverMenu === Module.product}
                                />
                            </ListItemIcon>
                            <ListItemText inset primary={strings.navigation.product} className='navigation-text main-item'/>
                            {submenu.product ? <ShowLessIcon /> : <ShowMoreIcon />}
                        </ListItem>
                        {
                            isShort() &&
                            <Popper open={hoverMenu === Module.product} anchorEl={activeHoverMenu} placement={'right-end'}>
                                <div className="navigation fixed">
                                    <span className='section-title'>{strings.navigation.product}</span>
                                    {ProductMenu()}
                                </div>
                            </Popper>
                        }
                        <Collapse in={submenu.product} timeout="auto" unmountOnExit>
                            {ProductMenu()}
                        </Collapse>
                    </React.Fragment>
                }
                {
                    canAccess(auth.company, ModulePermission.FINANCE) &&
                    canAccess(auth.company, ModulePermission.FINANCE, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.FINANCE, auth.permissions) &&
                    <React.Fragment>
                        <ListItem className={'navigation-item navigation-link' + (submenu.finance ? " active": "") } 
                                  onClick={() => handleSubmenu('finance')} component="div"
                                  onMouseOver={changeHoverMenu(Module.finance)}>  
                            <ListItemIcon className='navigation-icon'>
                                <NavigationIcon
                                    icon="/images/icons/grey/finance-grey.svg"
                                    activeIcon="/images/icons/blue/finance-blue.svg"
                                    isActive={() => submenu.finance || hoverMenu === Module.finance}
                                />
                            </ListItemIcon>
                            <ListItemText inset primary={strings.navigation.finance} className='navigation-text main-item'/>
                            {submenu.finance ? <ShowLessIcon /> : <ShowMoreIcon />}
                        </ListItem>
                        {
                            isShort() &&
                            <Popper open={hoverMenu === Module.finance} anchorEl={activeHoverMenu} placement={'right-end'}>
                                <div className="navigation fixed">
                                    <span className='section-title'>{strings.navigation.finance}</span>
                                    {FinanceMenu()}
                                </div>
                            </Popper>
                        }
                        <Collapse in={submenu.finance} timeout="auto" unmountOnExit>
                            {FinanceMenu()}
                        </Collapse>

                    </React.Fragment>
                }

                {
                    canAccess(auth.company, ModulePermission.CRM) &&
                    canAccess(auth.company, ModulePermission.CRM, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.CRM, auth.permissions) &&
                    <React.Fragment>
                        <ListItem className={'navigation-item navigation-link' + (submenu.crm ? " active": "") }
                                  onClick={() => handleSubmenu('crm')} component="div"
                                  onMouseOver={changeHoverMenu(Module.crm)}>  
                            <ListItemIcon className='navigation-icon'>
                                <NavigationIcon
                                    icon="/images/icons/grey/crm-grey.svg"
                                    activeIcon="/images/icons/blue/crm-blue.svg"
                                    isActive={() => submenu.crm || hoverMenu === Module.crm}
                                />
                        </ListItemIcon>
                            <ListItemText inset primary={strings.navigation.crm} className='navigation-text main-item'/>
                            {submenu.crm ? <ShowLessIcon /> : <ShowMoreIcon />}
                        </ListItem>
                        {
                            isShort() && 
                            <Popper open={hoverMenu === Module.crm} anchorEl={activeHoverMenu} placement={'right-start'}>
                                <div className="navigation fixed">
                                    <span className='section-title'>{strings.navigation.crm}</span>
                                    {CRMMenu()}
                                </div>
                            </Popper>
                        }
                        <Collapse in={submenu.crm} timeout="auto" unmountOnExit>
                            {CRMMenu()}
                        </Collapse>

                    </React.Fragment>
                }
                {
                canAccess(auth.company, ModulePermission.ADMINISTRATION) &&
                canAccess(auth.company, ModulePermission.ADMINISTRATION, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.ADMINISTRATION, auth.permissions) &&
                <ListItem className={'navigation-item navigation-link' + (submenu.administration ? " active": "")}
                          onClick={() => handleSubmenu('administration')} component="div"
                          onMouseOver={changeHoverMenu(Module.administration)}>                    

                    <ListItemIcon className='navigation-icon'>
                        <NavigationIcon
                            icon="/images/icons/grey/administration-grey.svg"
                            activeIcon="/images/icons/blue/administration-blue.svg"
                            isActive={() => submenu.administration || hoverMenu === Module.administration}
                        />
                    </ListItemIcon>
                    <ListItemText inset primary={strings.navigation.administration} className='navigation-text main-item'/>
                    {submenu.administration ? <ShowLessIcon /> : <ShowMoreIcon />}
                </ListItem>}
                {
                    isShort() && 
                    <Popper open={hoverMenu === Module.administration} anchorEl={activeHoverMenu} placement={'right-start'}>
                        <div className="navigation fixed">
                            <span className='section-title'>{strings.navigation.administration}</span>
                            {AdministrationMenu()}
                        </div>
                    </Popper>
                }
                <Collapse in={submenu.administration} timeout="auto" unmountOnExit>
                    {AdministrationMenu()}
                </Collapse>

                {
                    canAccess(auth.company, ModulePermission.COMPANY_DOCUMENT) &&
                    canAccess(auth.company, ModulePermission.COMPANY_DOCUMENT, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.COMPANY_DOCUMENT, auth.permissions) &&
                    <React.Fragment>
                        <ListItem className={'navigation-item navigation-link' + (submenu.companyDocument ? " active": "")}
                                  onClick={() => handleSubmenu('companyDocument')} component="div"
                                  onMouseOver={changeHoverMenu(Module.companyDocument)}>                    
                            <ListItemIcon className='navigation-icon'>
                               <NavigationIcon
                                    icon="/images/icons/grey/documents-grey.svg"
                                    activeIcon="/images/icons/blue/documents-blue.svg"
                                    isActive={() => submenu.companyDocument || hoverMenu === Module.companyDocument}
                                />
                            </ListItemIcon>
                            <ListItemText inset primary={strings.navigation.documents}
                                          className='navigation-text main-item'/>
                            {submenu.companyDocument ? <ShowLessIcon /> : <ShowMoreIcon />}
                        </ListItem>
                        {
                            isShort() && 
                            <Popper open={hoverMenu === Module.companyDocument} anchorEl={activeHoverMenu} placement={'right-start'}>
                                <div className="navigation fixed">
                                    <span className='section-title'>{strings.navigation.companyDocument}</span>
                                    {CompanyDocumentMenu()}
                                </div>
                            </Popper>
                        }
                        <Collapse in={submenu.companyDocument} timeout="auto" unmountOnExit>
                            {CompanyDocumentMenu()}
                        </Collapse>

                    </React.Fragment>
                }
                {
                    canAccess(auth.company, ModulePermission.PROJECT_MANAGEMENT) &&
                    canAccess(auth.company, ModulePermission.PROJECT_MANAGEMENT, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.PROJECT_MANAGEMENT, auth.permissions) &&
                    <React.Fragment>
                        <ListItem className={'navigation-item navigation-link' + (submenu.project ? " active": "")}
                                  onClick={() => handleSubmenu('project')} component="div"
                                  onMouseOver={changeHoverMenu(Module.project)}>  
                            <ListItemIcon className='navigation-icon'>
                                <NavigationIcon
                                    icon="/images/icons/grey/projects-grey.svg"
                                    activeIcon="/images/icons/blue/projects-blue.svg"
                                    isActive={() => submenu.project || hoverMenu === Module.project}
                                />
                            </ListItemIcon>
                            <ListItemText inset primary={strings.navigation.project} className='navigation-text main-item'/>
                            {submenu.project ? <ShowLessIcon /> : <ShowMoreIcon />}
                        </ListItem>
                        {
                            isShort() && 
                            <Popper open={hoverMenu === Module.project} anchorEl={activeHoverMenu} placement={'right-start'}>
                                <div className="navigation fixed">
                                    <span className='section-title'>{strings.navigation.project}</span>
                                    {ProjectMenu()}
                                </div>
                            </Popper>
                        }
                        <Collapse in={submenu.project} timeout="auto" unmountOnExit>
                            {ProjectMenu()}
                        </Collapse>
                    </React.Fragment>
                }
                {
                    canAccess(auth.company, ModulePermission.DOCUMENT) &&
                    canAccess(auth.company, ModulePermission.DOCUMENT, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.DOCUMENT, auth.permissions) &&
                    <React.Fragment>
                        <ListItem className={'navigation-item navigation-link' + (submenu.documentManagement ? " active": "")}
                                  onClick={() => handleSubmenu('documentManagement')} component="div"
                                  onMouseOver={changeHoverMenu(Module.documentManagement)}>  
                            <ListItemIcon className='navigation-icon'>
                                <NavigationIcon
                                    icon="/images/icons/grey/files-grey.svg"
                                    activeIcon="/images/icons/blue/files-blue.svg"
                                    isActive={() => submenu.documentManagement || hoverMenu === Module.documentManagement}
                                />
                            </ListItemIcon>
                            <ListItemText inset primary={strings.navigation.files}
                                          className='navigation-text main-item'/>
                            {submenu.documentManagement ? <ShowLessIcon /> : <ShowMoreIcon />}
                        </ListItem>
                        {
                            isShort() &&
                            <Popper open={hoverMenu === Module.documentManagement} anchorEl={activeHoverMenu} placement={'right-start'}>
                                <div className="navigation fixed">
                                    <span className='section-title'>{strings.navigation.documentManagement}</span>
                                    {DocumentManagementMenu()}
                                </div>
                            </Popper>
                        }
                        <Collapse in={submenu.documentManagement} timeout="auto" unmountOnExit>
                            {DocumentManagementMenu()}
                        </Collapse>

                    </React.Fragment>
                }
                {
                    canAccess(auth.company, ModulePermission.CHECK_LIST) &&
                    canAccess(auth.company, ModulePermission.CHECK_LIST, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.CHECK_LIST, auth.permissions) &&
                    <React.Fragment>
                        <ListItem className={'navigation-item navigation-link' + (submenu.checkList ? " active": "")}
                                  onClick={() => handleSubmenu('checkList')} component="div"
                                  onMouseOver={changeHoverMenu(Module.checkList)}
                        >
                            <ListItemIcon className='navigation-icon'>
                                <NavigationIcon
                                    icon="/images/icons/grey/check-list-grey.svg"
                                    activeIcon="/images/icons/blue/check-list-blue.svg"
                                    isActive={() => submenu.checkList || hoverMenu === Module.checkList}
                                />
                            </ListItemIcon>
                            <ListItemText inset primary={strings.navigation.checkList} className='navigation-text main-item'/>
                            {submenu.checkList ? <ShowLessIcon /> : <ShowMoreIcon />}
                        </ListItem>
                        {
                            isShort() &&
                            <Popper open={hoverMenu === Module.checkList} anchorEl={activeHoverMenu} placement={'right-end'}>
                                <div className="navigation fixed">
                                    <span className='section-title'>{strings.navigation.checkList}</span>
                                    {CheckListMenu()}
                                </div>
                            </Popper>
                        }
                        <Collapse in={submenu.checkList} timeout="auto" unmountOnExit>
                            {CheckListMenu()}
                        </Collapse>
                    </React.Fragment>
                }
                {
                    canAccess(auth.company, ModulePermission.ASSET_MANAGEMENT) &&
                    canAccess(auth.company, ModulePermission.ASSET_MANAGEMENT, auth.user, AppPermissions.MODULE.GROUP, AppPermissions.MODULE.ASSET_MANAGEMENT, auth.permissions) &&
                    <React.Fragment>
                        <ListItem className={'navigation-item navigation-link' + (submenu.assetManagement ? " active": "")}
                                  onClick={() => handleSubmenu('assetManagement')} component="div"
                                  onMouseOver={changeHoverMenu(Module.assetManagement)}>
                            <ListItemIcon className='navigation-icon'>
                                <NavigationIcon
                                    icon="/images/icons/grey/assets-grey.svg"
                                    activeIcon="/images/icons/blue/assets-blue.svg"
                                    isActive={() => submenu.assetManagement || hoverMenu === Module.assetManagement}
                                />
                            </ListItemIcon>
                            <ListItemText inset primary={strings.navigation.assetManagement}
                                          className='navigation-text main-item'/>
                            {submenu.assetManagement ? <ShowLessIcon /> : <ShowMoreIcon />}
                        </ListItem>
                        {
                            isShort() && 
                            <Popper open={hoverMenu === Module.assetManagement} anchorEl={activeHoverMenu} placement={'right'}>
                                <div className="navigation fixed">
                                    <span className='section-title'>{strings.navigation.assetManagement}</span>
                                    {AssetManagementMenu()}
                                </div>
                            </Popper>
                        }
                        <Collapse in={submenu.assetManagement} timeout="auto" unmountOnExit>
                            {AssetManagementMenu()}
                        </Collapse>

                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
    return (
      <Link
        to={link}
        onClick={urlChange}
        className={isActive ? "navigation-link active" : "navigation-link"}
      >
        <ListItem className="navigation-item">
          <ListItemIcon className="navigation-icon">
            <div className="dot-container">
              <DotSmallIcon active={isActive} isShort={isShort()} />
            </div>
          </ListItemIcon>
          <ListItemText
            inset
            primary={label}
            className={isShort() ? "navigation-text-dark" : "navigation-text"}
          />
        </ListItem>
      </Link>
    );
  };

  const HrMenu = () => {
    return (
      <List className="submenu">
        {canAccess(
          auth.company,
          ModulePermission.HR,
          auth.user,
          AppPermissions.PRESENCE.GROUP,
          AppPermissions.PRESENCE.VIEW_LIST,
          auth.permissions
        ) && <NavLink link="/presences" label={strings.navigation.presence} />}

        {canAccess(
          auth.company,
          ModulePermission.HR,
          auth.user,
          AppPermissions.EMPLOYEE.GROUP,
          AppPermissions.EMPLOYEE.VIEW_LIST,
          auth.permissions
        ) && <NavLink link="/employees" label={strings.navigation.employees} />}
        {canAccess(
          auth.company,
          ModulePermission.HR,
          auth.user,
          AppPermissions.ORGANISATION_USER_CONTRACT.GROUP,
          AppPermissions.ORGANISATION_USER_CONTRACT.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/organisation-user-contracts"
            label={strings.navigation.employeeContracts}
          />
        )}
        {canAccess(
          auth.company,
          ModulePermission.HR,
          auth.user,
          AppPermissions.TEAM.GROUP,
          AppPermissions.TEAM.VIEW_LIST,
          auth.permissions
        ) && <NavLink link="/teams" label={strings.navigation.team} />}
        {canAccess(
          auth.company,
          ModulePermission.HR,
          auth.user,
          AppPermissions.ORGANISATION_UNIT.GROUP,
          AppPermissions.ORGANISATION_UNIT.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/organisation-units"
            label={strings.navigation.organisationUnit}
          />
        )}

        {canAccess(
          auth.company,
          ModulePermission.HR,
          auth.user,
          AppPermissions.JOB.GROUP,
          AppPermissions.JOB.VIEW_LIST,
          auth.permissions
        ) && <NavLink link="/jobs" label={strings.navigation.job} />}
        {canAccess(
          auth.company,
          ModulePermission.HR,
          auth.user,
          AppPermissions.CANDIDATE.GROUP,
          AppPermissions.CANDIDATE.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink link="/candidates" label={strings.navigation.candidate} />
        )}

        {canAccess(
          auth.company,
          ModulePermission.HR,
          auth.user,
          AppPermissions.ADDITIONAL_HOURS.GROUP,
          AppPermissions.ADDITIONAL_HOURS.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/additional-hours"
            label={strings.navigation.additionalHours}
          />
        )}
        {canAccess(
          auth.company,
          ModulePermission.HR,
          auth.user,
          AppPermissions.LEAVE_REQUEST.GROUP,
          AppPermissions.LEAVE_REQUEST.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/leave-requests"
            label={strings.navigation.leaveRequest}
          />
        )}
        {canAccess(
          auth.company,
          ModulePermission.HR,
          auth.user,
          AppPermissions.LEAVE.GROUP,
          AppPermissions.LEAVE.VIEW_LIST,
          auth.permissions
        ) && <NavLink link="/leaves" label={strings.navigation.leave} />}

        {canAccess(
          auth.company,
          ModulePermission.HR,
          auth.user,
          AppPermissions.HR_SETTINGS.GROUP,
          AppPermissions.HR_SETTINGS.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink link="/hr-settings" label={strings.navigation.settings} />
        )}
        {canAccess(
          auth.company,
          ModulePermission.HR,
          auth.user,
          AppPermissions.BUSINESS_TRIP.GROUP,
          AppPermissions.BUSINESS_TRIP.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/hr-businessTrip"
            label={strings.navigation.businessTrip}
          />
        )}
      </List>
    );
  };
  const ProductMenu = () => {
    return (
      <List className="submenu">
        {(canAccess(
          auth.company,
          ModulePermission.PRODUCT,
          auth.user,
          AppPermissions.PRODUCTS_AND_SERVICES.GROUP,
          AppPermissions.PRODUCTS_AND_SERVICES.VIEW_LIST,
          auth.permissions
        ) ||
          isFreeAccount(auth.company)) && (
          <NavLink
            link="/product-services"
            label={strings.navigation.productServices}
          />
        )}
        {(canAccess(
          auth.company,
          ModulePermission.PRODUCT,
          auth.user,
          AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.GROUP,
          AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.VIEW_LIST,
          auth.permissions
        ) ||
          isFreeAccount(auth.company)) && (
          <NavLink
            link="/product-services-category"
            label={strings.navigation.productServicesCategory}
          />
        )}
        {(canAccess(
          auth.company,
          ModulePermission.PRODUCT,
          auth.user,
          AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.GROUP,
          AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.VIEW_LIST,
          auth.permissions
        ) ||
          isFreeAccount(auth.company)) && (
          <NavLink
            link="/product-catalog"
            label={strings.navigation.productCatalog}
          />
        )}
        {(canAccess(
          auth.company,
          ModulePermission.PRODUCT,
          auth.user,
          AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.GROUP,
          AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.VIEW_LIST,
          auth.permissions
        ) ||
          isFreeAccount(auth.company)) && (
          <NavLink link="/attributes" label={strings.navigation.attribute} />
        )}
      </List>
    );
  };

  const FinanceMenu = () => {
    return (
      <List className="submenu">
        {(canAccess(auth.company, ModulePermission.FINANCE) ||
          isFreeAccount(auth.company)) && (
          <React.Fragment>
            {(canAccess(
              auth.company,
              ModulePermission.FINANCE,
              auth.user,
              AppPermissions.INVOICE.GROUP,
              AppPermissions.INVOICE.VIEW_LIST,
              auth.permissions
            ) ||
              isFreeAccount(auth.company)) && (
              <NavLink
                link="/out/invoices"
                label={strings.navigation.invoices}
              />
            )}
          </React.Fragment>
        )}
        {canAccess(auth.company, ModulePermission.FINANCE, auth.user) && (
          <React.Fragment>
            {canAccess(
              auth.company,
              ModulePermission.FINANCE,
              auth.user,
              AppPermissions.BILL.GROUP,
              AppPermissions.BILL.VIEW_LIST,
              auth.permissions
            ) && (
              <NavLink link="/in/invoices" label={strings.navigation.bills} />
            )}
            {canAccess(
              auth.company,
              ModulePermission.FINANCE,
              auth.user,
              AppPermissions.CASH_FLOW.GROUP,
              AppPermissions.CASH_FLOW.VIEW_LIST,
              auth.permissions
            ) && (
              <NavLink link="/cash-flow" label={strings.navigation.cashFlow} />
            )}

            {canAccess(
              auth.company,
              ModulePermission.FINANCE,
              auth.user,
              AppPermissions.CONTRACT.GROUP,
              AppPermissions.CONTRACT.VIEW_LIST,
              auth.permissions
            ) && (
              <NavLink link="/contracts" label={strings.navigation.contracts} />
            )}

            {canAccess(
              auth.company,
              ModulePermission.FINANCE,
              auth.user,
              AppPermissions.UPLOAD_BANK_REPORT.GROUP,
              AppPermissions.UPLOAD_BANK_REPORT.VIEW_LIST,
              auth.permissions
            ) && (
              <NavLink
                link="/manage-bank-reports"
                label={strings.navigation.uploadBankReport}
              />
            )}
            {canAccess(
              auth.company,
              ModulePermission.FINANCE,
              auth.user,
              AppPermissions.BANK_REPORT.GROUP,
              AppPermissions.BANK_REPORT.VIEW_LIST,
              auth.permissions
            ) && (
              <NavLink
                link="/bank-reports"
                label={strings.navigation.bankReport}
              />
            )}
            {canAccess(
              auth.company,
              ModulePermission.FINANCE,
              auth.user,
              AppPermissions.TRANSACTION.GROUP,
              AppPermissions.TRANSACTION.VIEW_LIST,
              auth.permissions
            ) && (
              <NavLink
                link="/transactions"
                label={strings.navigation.transactions}
              />
            )}

            {canAccess(
              auth.company,
              ModulePermission.FINANCE,
              auth.user,
              AppPermissions.FINANCE_SETTINGS.GROUP,
              AppPermissions.FINANCE_SETTINGS.VIEW_LIST,
              auth.permissions
            ) && (
              <NavLink
                link="/finance-settings/bank-accounts"
                label={strings.navigation.settings}
              />
            )}
          </React.Fragment>
        )}
      </List>
    );
  };

  const AdministrationMenu = () => {
    return (
      <List className="submenu">
        {canAccess(
          auth.company,
          ModulePermission.ADMINISTRATION,
          auth.user,
          AppPermissions.COMPANY_SETTINGS.GROUP,
          AppPermissions.COMPANY_SETTINGS.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/company-settings"
            label={strings.navigation.company}
          />
        )}
        {!isFreeAccount(auth.company) && (
          <React.Fragment>
            {canAccess(
              auth.company,
              ModulePermission.ADMINISTRATION,
              auth.user,
              AppPermissions.USER.GROUP,
              AppPermissions.USER.VIEW_LIST,
              auth.permissions
            ) && <NavLink link="/users" label={strings.navigation.user} />}
            {canAccess(
              auth.company,
              ModulePermission.ADMINISTRATION,
              auth.user,
              AppPermissions.ROLE.GROUP,
              AppPermissions.ROLE.VIEW_LIST,
              auth.permissions
            ) && <NavLink link="/roles" label={strings.navigation.role} />}
            {canAccess(
              auth.company,
              ModulePermission.ADMINISTRATION,
              auth.user,
              AppPermissions.PIPE_LINE.GROUP,
              AppPermissions.PIPE_LINE.VIEW_LIST,
              auth.permissions
            ) && (
              <NavLink link="/pipelines" label={strings.navigation.pipeline} />
            )}
            {canAccess(
              auth.company,
              ModulePermission.ADMINISTRATION,
              auth.user,
              AppPermissions.ASSET_LOCATION.GROUP,
              AppPermissions.ASSET_LOCATION.VIEW_LIST,
              auth.permissions
            ) && (
              <NavLink
                link="/asset-management-locations"
                label={strings.navigation.location}
              />
            )}
          </React.Fragment>
        )}
      </List>
    );
  };

  const CRMMenu = () => {
    return (
      <List className="submenu">
        {(canAccess(auth.company, ModulePermission.CRM) ||
          isFreeAccount(auth.company)) && (
          <React.Fragment>
            {(canAccess(
              auth.company,
              ModulePermission.CRM,
              auth.user,
              AppPermissions.DEAL.GROUP,
              AppPermissions.DEAL.VIEW_LIST,
              auth.permissions
            ) ||
              isFreeAccount(auth.company)) && (
              <NavLink
                link="/deal-pipelines"
                label={strings.navigation.deals}
              />
            )}

            {(canAccess(
              auth.company,
              ModulePermission.CRM,
              auth.user,
              AppPermissions.PARTNER.GROUP,
              AppPermissions.PARTNER.VIEW_LIST,
              auth.permissions
            ) ||
              isFreeAccount(auth.company)) && (
              <NavLink link="/partners" label={strings.navigation.partners} />
            )}
          </React.Fragment>
        )}
        {canAccess(auth.company, ModulePermission.CRM) && (
          <React.Fragment>
            {canAccess(
              auth.company,
              ModulePermission.CRM,
              auth.user,
              AppPermissions.PARTNER_CONTACT.GROUP,
              AppPermissions.PARTNER_CONTACT.VIEW_LIST,
              auth.permissions
            ) && (
              <NavLink
                link="/partner-contacts"
                label={strings.navigation.partnerContacts}
              />
            )}
          </React.Fragment>
        )}

        {canAccess(
          auth.company,
          ModulePermission.CRM,
          auth.user,
          AppPermissions.CRM_SETTINGS.GROUP,
          AppPermissions.CRM_SETTINGS.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/crm-settings/deal-type"
            label={strings.navigation.settings}
          />
        )}
      </List>
    );
  };

  const CompanyDocumentMenu = () => {
    return (
      <List className="submenu">
        {canAccess(
          auth.company,
          ModulePermission.COMPANY_DOCUMENT,
          auth.user,
          AppPermissions.COMPANY_DOCUMENT.GROUP,
          AppPermissions.COMPANY_DOCUMENT.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/company-documents"
            label={strings.navigation.documents}
          />
        )}
        {canAccess(
          auth.company,
          ModulePermission.COMPANY_DOCUMENT,
          auth.user,
          AppPermissions.COMPANY_DOCUMENT_TEMPLATE.GROUP,
          AppPermissions.COMPANY_DOCUMENT_TEMPLATE.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/company-document-templates"
            label={strings.navigation.documentTemplates}
          />
        )}
      </List>
    );
  };

  const ProjectMenu = () => {
    return (
      <List className="submenu">
        {canAccess(
          auth.company,
          ModulePermission.PROJECT_MANAGEMENT,
          auth.user,
          AppPermissions.PROJECT.GROUP,
          AppPermissions.PROJECT.VIEW_LIST,
          auth.permissions
        ) && <NavLink link="/project" label={strings.navigation.project} />}
        {canAccess(
          auth.company,
          ModulePermission.PROJECT_MANAGEMENT,
          auth.user,
          AppPermissions.PROJECT_RESOURCE.GROUP,
          AppPermissions.PROJECT_RESOURCE.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/project-resource"
            label={strings.navigation.projectResource}
          />
        )}

        {canAccess(
          auth.company,
          ModulePermission.PROJECT_MANAGEMENT,
          auth.user,
          AppPermissions.PROJECT_TIME_LOG.GROUP,
          AppPermissions.PROJECT_TIME_LOG.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/project-time-log"
            label={strings.navigation.projectTimeLog}
          />
        )}

        {canAccess(
          auth.company,
          ModulePermission.PROJECT_MANAGEMENT,
          auth.user,
          AppPermissions.PROJECT_SETTINGS.GROUP,
          AppPermissions.PROJECT_SETTINGS.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/project-settings/project-categories"
            label={strings.navigation.settings}
          />
        )}
      </List>
    );
  };

  const CheckListMenu = () => {
    return (
      <List className="submenu">
        {(canAccess(
          auth.company,
          ModulePermission.CHECK_LIST,
          auth.user,
          AppPermissions.CHECK_LIST.GROUP,
          AppPermissions.CHECK_LIST.VIEW_LIST,
          auth.permissions
        ) ||
          isFreeAccount(auth.company)) && (
          <NavLink
            link="/check-list-template"
            label={strings.navigation.checkListTemplate}
          />
        )}
        {(canAccess(
          auth.company,
          ModulePermission.CHECK_LIST,
          auth.user,
          AppPermissions.CHECK_LIST_SCHEDULED_CHECK.GROUP,
          AppPermissions.CHECK_LIST_SCHEDULED_CHECK.VIEW_LIST,
          auth.permissions
        ) ||
          isFreeAccount(auth.company)) && (
          <NavLink
            link="/check-list-scheduled-checks"
            label={strings.navigation.checkListScheduledCheks}
          />
        )}
      </List>
    );
  };

  const DocumentManagementMenu = () => {
    return (
      <List className="submenu">
        {canAccess(
          auth.company,
          ModulePermission.DOCUMENT,
          auth.user,
          AppPermissions.FILE_FOLDER.GROUP,
          AppPermissions.FILE_FOLDER.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/file-folders"
            label={strings.navigation.fileFolders}
          />
        )}
        {canAccess(
          auth.company,
          ModulePermission.DOCUMENT,
          auth.user,
          AppPermissions.DOCUMENT_CATEGORY.GROUP,
          AppPermissions.DOCUMENT_CATEGORY.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/document-category"
            label={strings.navigation.fileCategory}
          />
        )}
        {canAccess(
          auth.company,
          ModulePermission.DOCUMENT,
          auth.user,
          AppPermissions.DOCUMENT_STATUS.GROUP,
          AppPermissions.DOCUMENT_STATUS.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/document-status"
            label={strings.navigation.fileStatus}
          />
        )}
      </List>
    );
  };

  const AssetManagementMenu = () => {
    return (
      <List className="submenu">
        {canAccess(
          auth.company,
          ModulePermission.ASSET_MANAGEMENT,
          auth.user,
          AppPermissions.ASSET.GROUP,
          AppPermissions.ASSET.VIEW_LIST,
          auth.permissions
        ) && <NavLink link="/assets" label={strings.navigation.assets} />}
        {canAccess(
          auth.company,
          ModulePermission.ASSET_MANAGEMENT,
          auth.user,
          AppPermissions.ASSET_TYPE.GROUP,
          AppPermissions.ASSET_TYPE.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink link="/asset-types" label={strings.navigation.assetTypes} />
        )}
        {canAccess(
          auth.company,
          ModulePermission.ASSET_MANAGEMENT,
          auth.user,
          AppPermissions.ASSET_CATEGORY.GROUP,
          AppPermissions.ASSET_CATEGORY.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/asset-categories"
            label={strings.navigation.assetCategories}
          />
        )}

        {canAccess(
          auth.company,
          ModulePermission.ASSET_MANAGEMENT,
          auth.user,
          AppPermissions.INVENTORY.GROUP,
          AppPermissions.INVENTORY.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink link="/inventories" label={strings.navigation.inventory} />
        )}
        {canAccess(
          auth.company,
          ModulePermission.ASSET_MANAGEMENT,
          auth.user,
          AppPermissions.STOCK_CHANGE.GROUP,
          AppPermissions.STOCK_CHANGE.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/stock-changes"
            label={strings.navigation.stockChange}
          />
        )}
        {canAccess(
          auth.company,
          ModulePermission.ASSET_MANAGEMENT,
          auth.user,
          AppPermissions.INVENTORY_TYPE.GROUP,
          AppPermissions.INVENTORY_TYPE.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/inventory-types"
            label={strings.navigation.inventoryType}
          />
        )}
        {canAccess(
          auth.company,
          ModulePermission.ASSET_MANAGEMENT,
          auth.user,
          AppPermissions.INVENTORY_CATEGORY.GROUP,
          AppPermissions.INVENTORY_CATEGORY.VIEW_LIST,
          auth.permissions
        ) && (
          <NavLink
            link="/inventory-categories"
            label={strings.navigation.inventoryCategory}
          />
        )}

        {canAccess(
          auth.company,
          ModulePermission.ASSET_MANAGEMENT,
          auth.user,
          AppPermissions.VENDOR.GROUP,
          AppPermissions.VENDOR.VIEW_LIST,
          auth.permissions
        ) && <NavLink link="/vendors" label={strings.navigation.vendors} />}
      </List>
    );
  };
  const getMenuItems = () => {
    return (
      <React.Fragment>
        <div className="home-tag">{strings.navigation.homeTag}</div>
        {!company?.accountSettings?.trialUsed && (
          <Link
            to={"/start-trial"}
            className={
              isCurrentPath("/start-trial")
                ? "navigation-link active"
                : "navigation-link"
            }
          >
            <ListItem
              className="navigation-item"
              component="div"
              onMouseOver={() => setHoverMenu(Module.startTrial)}
              onMouseLeave={() => setHoverMenu()}
            >
              <ListItemIcon className="navigation-icon">
                <NavigationIcon
                  icon="/images/icons/grey/dashboard-grey.svg"
                  activeIcon="/images/icons/blue/dashboard-blue.svg"
                  isActive={() =>
                    isCurrentPath("/start-trial") ||
                    hoverMenu === Module.startTrial
                  }
                />
              </ListItemIcon>
              <ListItemText
                inset
                primary={strings.navigation.startTrial}
                className="navigation-text main-item"
              />
            </ListItem>
          </Link>
        )}
        {canAccess(auth.company, ModulePermission.HOME) &&
          canAccess(
            auth.company,
            ModulePermission.HOME,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.HOME,
            auth.permissions
          ) && (
            <Link
              to={"/home"}
              className={
                isCurrentPath("/home")
                  ? "navigation-link active"
                  : "navigation-link"
              }
            >
              <ListItem
                className="navigation-item"
                component="div"
                onMouseOver={() => setHoverMenu(Module.home)}
                onMouseLeave={() => setHoverMenu()}
                onClick={() => handleSubmenu("home")}
              >
                <ListItemIcon className="navigation-icon">
                  <NavigationIcon
                    icon="/images/icons/grey/home-white.svg"
                    activeIcon="/images/icons/blue/home-blue.svg"
                    isActive={() =>
                      isCurrentPath("/home") || hoverMenu === Module.home
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.home}
                  className="navigation-text main-item"
                />
              </ListItem>
            </Link>
          )}
        {canAccess(auth.company, ModulePermission.DASHBOARD) &&
          canAccess(
            auth.company,
            ModulePermission.DASHBOARD,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.DASHBOARD,
            auth.permissions
          ) && (
            <Link
              to={"/dashboard"}
              className={
                isCurrentPath("/dashboard")
                  ? "navigation-link active"
                  : "navigation-link"
              }
            >
              <ListItem
                className="navigation-item"
                component="div"
                onMouseOver={() => setHoverMenu(Module.dashboard)}
                onMouseLeave={() => setHoverMenu()}
                onClick={() => handleSubmenu("dashboard")}
              >
                <ListItemIcon className="navigation-icon">
                  <NavigationIcon
                    icon="/images/icons/grey/dashboard-grey.svg"
                    activeIcon="/images/icons/blue/dashboard-blue.svg"
                    isActive={() =>
                      isCurrentPath("/dashboard") ||
                      hoverMenu === Module.dashboard
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.dashboard}
                  className="navigation-text main-item"
                />
              </ListItem>
            </Link>
          )}
        {canAccess(auth.company, ModulePermission.CALENDAR) &&
          canAccess(
            auth.company,
            ModulePermission.CALENDAR,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.CALENDAR,
            auth.permissions
          ) && (
            <Link
              to={"/calendar"}
              className={
                isCurrentPath("/calendar")
                  ? "navigation-link active"
                  : "navigation-link"
              }
            >
              <ListItem
                className="navigation-item"
                component="div"
                onMouseOver={() => setHoverMenu(Module.calendar)}
                onMouseLeave={() => setHoverMenu()}
                onClick={() => handleSubmenu("calendar")}
              >
                <ListItemIcon className="navigation-icon">
                  <NavigationIcon
                    icon="/images/icons/grey/calendar-grey.svg"
                    activeIcon="/images/icons/blue/calendar-blue.svg"
                    isActive={() =>
                      isCurrentPath("/calendar") ||
                      hoverMenu === Module.calendar
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.calendar}
                  className="navigation-text main-item"
                />
              </ListItem>
            </Link>
          )}
        {canAccess(auth.company, ModulePermission.WORKSPACES) &&
          canAccess(
            auth.company,
            ModulePermission.WORKSPACES,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.WORKSPACES,
            auth.permissions
          ) && (
            <Link
              to={"/workspaces"}
              className={
                isCurrentPath("/workspaces")
                  ? "navigation-link active"
                  : "navigation-link"
              }
            >
              <ListItem
                className="navigation-item"
                component="div"
                onMouseOver={() => setHoverMenu(Module.workspaces)}
                onMouseLeave={() => setHoverMenu()}
                onClick={() => handleSubmenu("workspaces")}
              >
                <ListItemIcon className="navigation-icon">
                  <NavigationIcon
                    icon="/images/icons/grey/workspaces-grey.svg"
                    activeIcon="/images/icons/blue/workspaces-blue.svg"
                    isActive={() =>
                      isCurrentPath("/workspaces") ||
                      hoverMenu === Module.workspaces
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.workspaces}
                  className="navigation-text main-item"
                />
              </ListItem>
            </Link>
          )}
        {canAccess(auth.company, ModulePermission.SPACES) &&
          canAccess(
            auth.company,
            ModulePermission.SPACES,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.SPACES,
            auth.permissions
          ) && (
            <Link
              to={"/spaces"}
              className={
                isCurrentPath("/spaces")
                  ? "navigation-link active"
                  : "navigation-link"
              }
            >
              <ListItem
                className="navigation-item"
                component="div"
                onMouseOver={() => setHoverMenu(Module.spaces)}
                onMouseLeave={() => setHoverMenu()}
                onClick={() => handleSubmenu("spaces")}
              >
                <ListItemIcon className="navigation-icon">
                  <NavigationIcon
                    icon="/images/icons/grey/spaces-grey.svg"
                    activeIcon="/images/icons/blue/spaces-blue.svg"
                    isActive={() =>
                      isCurrentPath("/spaces") || hoverMenu === Module.spaces
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.spaces}
                  className="navigation-text main-item"
                />
              </ListItem>
            </Link>
          )}
        <div className="managment-tag">{strings.navigation.managmentTag}</div>
        {!company?.accountSettings?.trialUsed && (
          <Link
            to={"/company-settings"}
            className={
              isCurrentPath("/company-settings")
                ? "navigation-link active"
                : "navigation-link"
            }
          >
            <ListItem
              className="navigation-item"
              component="div"
              onMouseOver={() => setHoverMenu(Module.settings)}
              onMouseLeave={() => setHoverMenu()}
            >
              <ListItemIcon className="navigation-icon">
                <NavigationIcon
                  icon="/images/icons/grey/administration-grey.svg"
                  activeIcon="/images/icons/blue/administration-blue.svg"
                  isActive={() =>
                    isCurrentPath("/company-settings") ||
                    hoverMenu === Module.settings
                  }
                />
              </ListItemIcon>
              <ListItemText
                inset
                primary={strings.navigation.settings}
                className="navigation-text main-item"
              />
            </ListItem>
          </Link>
        )}
        {canAccess(auth.company, ModulePermission.HR) &&
          canAccess(
            auth.company,
            ModulePermission.HR,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.HR,
            auth.permissions
          ) && (
            <React.Fragment>
              <ListItem
                className={
                  "navigation-item navigation-link" +
                  (submenu.hr ? " active" : "")
                }
                onClick={() => handleSubmenu("hr")}
                component="div"
                onMouseOver={changeHoverMenu(Module.hr)}
              >
                <ListItemIcon className="navigation-icon">
                  <NavigationIcon
                    icon="/images/icons/grey/hr-grey.svg"
                    activeIcon="/images/icons/blue/hr-blue.svg"
                    isActive={() => submenu.hr || hoverMenu === Module.hr}
                  />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.hr}
                  className="navigation-text main-item"
                />
                {submenu.hr ? <ShowLessIcon /> : <ShowMoreIcon />}
              </ListItem>
              {isShort() && (
                <Popper
                  open={hoverMenu === Module.hr}
                  anchorEl={activeHoverMenu}
                  placement={"right-end"}
                >
                  <div className="navigation fixed">
                    <span className="section-title">
                      {strings.navigation.hr}
                    </span>
                    {HrMenu()}
                  </div>
                </Popper>
              )}
              <Collapse in={submenu.hr} timeout="auto" unmountOnExit>
                {HrMenu()}
              </Collapse>
            </React.Fragment>
          )}
        {canAccess(auth.company, ModulePermission.PRODUCT) &&
          canAccess(
            auth.company,
            ModulePermission.PRODUCT,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.PRODUCT,
            auth.permissions
          ) && (
            <React.Fragment>
              <ListItem
                className={
                  "navigation-item navigation-link" +
                  (submenu.product ? " active" : "")
                }
                onClick={() => handleSubmenu("product")}
                component="div"
                onMouseOver={changeHoverMenu(Module.product)}
              >
                <ListItemIcon className="navigation-icon">
                  <NavigationIcon
                    icon="/images/icons/grey/product-grey.svg"
                    activeIcon="/images/icons/blue/product-blue.svg"
                    isActive={() =>
                      submenu.product || hoverMenu === Module.product
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.product}
                  className="navigation-text main-item"
                />
                {submenu.product ? <ShowLessIcon /> : <ShowMoreIcon />}
              </ListItem>
              {isShort() && (
                <Popper
                  open={hoverMenu === Module.product}
                  anchorEl={activeHoverMenu}
                  placement={"right-end"}
                >
                  <div className="navigation fixed">
                    <span className="section-title">
                      {strings.navigation.product}
                    </span>
                    {ProductMenu()}
                  </div>
                </Popper>
              )}
              <Collapse in={submenu.product} timeout="auto" unmountOnExit>
                {ProductMenu()}
              </Collapse>
            </React.Fragment>
          )}
        {canAccess(auth.company, ModulePermission.FINANCE) &&
          canAccess(
            auth.company,
            ModulePermission.FINANCE,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.FINANCE,
            auth.permissions
          ) && (
            <React.Fragment>
              <ListItem
                className={
                  "navigation-item navigation-link" +
                  (submenu.finance ? " active" : "")
                }
                onClick={() => handleSubmenu("finance")}
                component="div"
                onMouseOver={changeHoverMenu(Module.finance)}
              >
                <ListItemIcon className="navigation-icon">
                  <NavigationIcon
                    icon="/images/icons/grey/finance-grey.svg"
                    activeIcon="/images/icons/blue/finance-blue.svg"
                    isActive={() =>
                      submenu.finance || hoverMenu === Module.finance
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.finance}
                  className="navigation-text main-item"
                />
                {submenu.finance ? <ShowLessIcon /> : <ShowMoreIcon />}
              </ListItem>
              {isShort() && (
                <Popper
                  open={hoverMenu === Module.finance}
                  anchorEl={activeHoverMenu}
                  placement={"right-end"}
                >
                  <div className="navigation fixed">
                    <span className="section-title">
                      {strings.navigation.finance}
                    </span>
                    {FinanceMenu()}
                  </div>
                </Popper>
              )}
              <Collapse in={submenu.finance} timeout="auto" unmountOnExit>
                {FinanceMenu()}
              </Collapse>
            </React.Fragment>
          )}

        {canAccess(auth.company, ModulePermission.CRM) &&
          canAccess(
            auth.company,
            ModulePermission.CRM,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.CRM,
            auth.permissions
          ) && (
            <React.Fragment>
              <ListItem
                className={
                  "navigation-item navigation-link" +
                  (submenu.crm ? " active" : "")
                }
                onClick={() => handleSubmenu("crm")}
                component="div"
                onMouseOver={changeHoverMenu(Module.crm)}
              >
                <ListItemIcon className="navigation-icon">
                  <NavigationIcon
                    icon="/images/icons/grey/crm-grey.svg"
                    activeIcon="/images/icons/blue/crm-blue.svg"
                    isActive={() => submenu.crm || hoverMenu === Module.crm}
                  />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.crm}
                  className="navigation-text main-item"
                />
                {submenu.crm ? <ShowLessIcon /> : <ShowMoreIcon />}
              </ListItem>
              {isShort() && (
                <Popper
                  open={hoverMenu === Module.crm}
                  anchorEl={activeHoverMenu}
                  placement={"right-start"}
                >
                  <div className="navigation fixed">
                    <span className="section-title">
                      {strings.navigation.crm}
                    </span>
                    {CRMMenu()}
                  </div>
                </Popper>
              )}
              <Collapse in={submenu.crm} timeout="auto" unmountOnExit>
                {CRMMenu()}
              </Collapse>
            </React.Fragment>
          )}
        {canAccess(auth.company, ModulePermission.ADMINISTRATION) &&
          canAccess(
            auth.company,
            ModulePermission.ADMINISTRATION,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.ADMINISTRATION,
            auth.permissions
          ) && (
            <ListItem
              className={
                "navigation-item navigation-link" +
                (submenu.administration ? " active" : "")
              }
              onClick={() => handleSubmenu("administration")}
              component="div"
              onMouseOver={changeHoverMenu(Module.administration)}
            >
              <ListItemIcon className="navigation-icon">
                <NavigationIcon
                  icon="/images/icons/grey/administration-grey.svg"
                  activeIcon="/images/icons/blue/administration-blue.svg"
                  isActive={() =>
                    submenu.administration ||
                    hoverMenu === Module.administration
                  }
                />
              </ListItemIcon>
              <ListItemText
                inset
                primary={strings.navigation.administration}
                className="navigation-text main-item"
              />
              {submenu.administration ? <ShowLessIcon /> : <ShowMoreIcon />}
            </ListItem>
          )}
        {isShort() && (
          <Popper
            open={hoverMenu === Module.administration}
            anchorEl={activeHoverMenu}
            placement={"right-start"}
          >
            <div className="navigation fixed">
              <span className="section-title">
                {strings.navigation.administration}
              </span>
              {AdministrationMenu()}
            </div>
          </Popper>
        )}
        <Collapse in={submenu.administration} timeout="auto" unmountOnExit>
          {AdministrationMenu()}
        </Collapse>

        {canAccess(auth.company, ModulePermission.COMPANY_DOCUMENT) &&
          canAccess(
            auth.company,
            ModulePermission.COMPANY_DOCUMENT,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.COMPANY_DOCUMENT,
            auth.permissions
          ) && (
            <React.Fragment>
              <ListItem
                className={
                  "navigation-item navigation-link" +
                  (submenu.companyDocument ? " active" : "")
                }
                onClick={() => handleSubmenu("companyDocument")}
                component="div"
                onMouseOver={changeHoverMenu(Module.companyDocument)}
              >
                <ListItemIcon className="navigation-icon">
                  <NavigationIcon
                    icon="/images/icons/grey/documents-grey.svg"
                    activeIcon="/images/icons/blue/documents-blue.svg"
                    isActive={() =>
                      submenu.companyDocument ||
                      hoverMenu === Module.companyDocument
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.documents}
                  className="navigation-text main-item"
                />
                {submenu.companyDocument ? <ShowLessIcon /> : <ShowMoreIcon />}
              </ListItem>
              {isShort() && (
                <Popper
                  open={hoverMenu === Module.companyDocument}
                  anchorEl={activeHoverMenu}
                  placement={"right-start"}
                >
                  <div className="navigation fixed">
                    <span className="section-title">
                      {strings.navigation.companyDocument}
                    </span>
                    {CompanyDocumentMenu()}
                  </div>
                </Popper>
              )}
              <Collapse
                in={submenu.companyDocument}
                timeout="auto"
                unmountOnExit
              >
                {CompanyDocumentMenu()}
              </Collapse>
            </React.Fragment>
          )}
        {canAccess(auth.company, ModulePermission.PROJECT_MANAGEMENT) &&
          canAccess(
            auth.company,
            ModulePermission.PROJECT_MANAGEMENT,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.PROJECT_MANAGEMENT,
            auth.permissions
          ) && (
            <React.Fragment>
              <ListItem
                className={
                  "navigation-item navigation-link" +
                  (submenu.project ? " active" : "")
                }
                onClick={() => handleSubmenu("project")}
                component="div"
                onMouseOver={changeHoverMenu(Module.project)}
              >
                <ListItemIcon className="navigation-icon">
                  <NavigationIcon
                    icon="/images/icons/grey/projects-grey.svg"
                    activeIcon="/images/icons/blue/projects-blue.svg"
                    isActive={() =>
                      submenu.project || hoverMenu === Module.project
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.project}
                  className="navigation-text main-item"
                />
                {submenu.project ? <ShowLessIcon /> : <ShowMoreIcon />}
              </ListItem>
              {isShort() && (
                <Popper
                  open={hoverMenu === Module.project}
                  anchorEl={activeHoverMenu}
                  placement={"right-start"}
                >
                  <div className="navigation fixed">
                    <span className="section-title">
                      {strings.navigation.project}
                    </span>
                    {ProjectMenu()}
                  </div>
                </Popper>
              )}
              <Collapse in={submenu.project} timeout="auto" unmountOnExit>
                {ProjectMenu()}
              </Collapse>
            </React.Fragment>
          )}
        {canAccess(auth.company, ModulePermission.DOCUMENT) &&
          canAccess(
            auth.company,
            ModulePermission.DOCUMENT,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.DOCUMENT,
            auth.permissions
          ) && (
            <React.Fragment>
              <ListItem
                className={
                  "navigation-item navigation-link" +
                  (submenu.documentManagement ? " active" : "")
                }
                onClick={() => handleSubmenu("documentManagement")}
                component="div"
                onMouseOver={changeHoverMenu(Module.documentManagement)}
              >
                <ListItemIcon className="navigation-icon">
                  <NavigationIcon
                    icon="/images/icons/grey/files-grey.svg"
                    activeIcon="/images/icons/blue/files-blue.svg"
                    isActive={() =>
                      submenu.documentManagement ||
                      hoverMenu === Module.documentManagement
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.files}
                  className="navigation-text main-item"
                />
                {submenu.documentManagement ? (
                  <ShowLessIcon />
                ) : (
                  <ShowMoreIcon />
                )}
              </ListItem>
              {isShort() && (
                <Popper
                  open={hoverMenu === Module.documentManagement}
                  anchorEl={activeHoverMenu}
                  placement={"right-start"}
                >
                  <div className="navigation fixed">
                    <span className="section-title">
                      {strings.navigation.documentManagement}
                    </span>
                    {DocumentManagementMenu()}
                  </div>
                </Popper>
              )}
              <Collapse
                in={submenu.documentManagement}
                timeout="auto"
                unmountOnExit
              >
                {DocumentManagementMenu()}
              </Collapse>
            </React.Fragment>
          )}
        {canAccess(auth.company, ModulePermission.CHECK_LIST) &&
          canAccess(
            auth.company,
            ModulePermission.CHECK_LIST,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.CHECK_LIST,
            auth.permissions
          ) && (
            <React.Fragment>
              <ListItem
                className={
                  "navigation-item navigation-link" +
                  (submenu.checkList ? " active" : "")
                }
                onClick={() => handleSubmenu("checkList")}
                component="div"
                onMouseOver={changeHoverMenu(Module.checkList)}
              >
                <ListItemIcon className="navigation-icon">
                  <NavigationIcon
                    icon="/images/icons/grey/check-list-grey.svg"
                    activeIcon="/images/icons/blue/check-list-blue.svg"
                    isActive={() =>
                      submenu.checkList || hoverMenu === Module.checkList
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.checkList}
                  className="navigation-text main-item"
                />
                {submenu.checkList ? <ShowLessIcon /> : <ShowMoreIcon />}
              </ListItem>
              {isShort() && (
                <Popper
                  open={hoverMenu === Module.checkList}
                  anchorEl={activeHoverMenu}
                  placement={"right-end"}
                >
                  <div className="navigation fixed">
                    <span className="section-title">
                      {strings.navigation.checkList}
                    </span>
                    {CheckListMenu()}
                  </div>
                </Popper>
              )}
              <Collapse in={submenu.checkList} timeout="auto" unmountOnExit>
                {CheckListMenu()}
              </Collapse>
            </React.Fragment>
          )}
        {canAccess(auth.company, ModulePermission.ASSET_MANAGEMENT) &&
          canAccess(
            auth.company,
            ModulePermission.ASSET_MANAGEMENT,
            auth.user,
            AppPermissions.MODULE.GROUP,
            AppPermissions.MODULE.ASSET_MANAGEMENT,
            auth.permissions
          ) && (
            <React.Fragment>
              <ListItem
                className={
                  "navigation-item navigation-link" +
                  (submenu.assetManagement ? " active" : "")
                }
                onClick={() => handleSubmenu("assetManagement")}
                component="div"
                onMouseOver={changeHoverMenu(Module.assetManagement)}
              >
                <ListItemIcon className="navigation-icon">
                  <NavigationIcon
                    icon="/images/icons/grey/assets-grey.svg"
                    activeIcon="/images/icons/blue/assets-blue.svg"
                    isActive={() =>
                      submenu.assetManagement ||
                      hoverMenu === Module.assetManagement
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={strings.navigation.assetManagement}
                  className="navigation-text main-item"
                />
                {submenu.assetManagement ? <ShowLessIcon /> : <ShowMoreIcon />}
              </ListItem>
              {isShort() && (
                <Popper
                  open={hoverMenu === Module.assetManagement}
                  anchorEl={activeHoverMenu}
                  placement={"right"}
                >
                  <div className="navigation fixed">
                    <span className="section-title">
                      {strings.navigation.assetManagement}
                    </span>
                    {AssetManagementMenu()}
                  </div>
                </Popper>
              )}
              <Collapse
                in={submenu.assetManagement}
                timeout="auto"
                unmountOnExit
              >
                {AssetManagementMenu()}
              </Collapse>
            </React.Fragment>
          )}
      </React.Fragment>
    );
  };
  return (
    <Drawer variant="permanent" id="navigation">
      <div className={getNavigationClass(menuState)}>
        <div className="menu-header-container">
          <div className="logo-container">
            <Link to={"/"}>
              {isFull() && (
                <img src={"/images/managevio-brain.svg"} loading={"lazy"} />
              )}
              {isShort() && <img src={"/images/brain.svg"} loading={"lazy"} />}
            </Link>
          </div>
        </div>
        <List component="nav" onMouseLeave={changeHoverMenu()}>
          {getMenuItems()}
        </List>
      </div>
    </Drawer>
  );
};
export default Navigation;
