import React from "react";
import {useEffect, useState} from "react";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import PageState from "../../../Constants/Base/PageState";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {formatColumnDate, formatColumnMonth, formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import {useDispatch} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {
    deleteOrganisationUserContractPart,
    getOrganisationUserContractPartList
} from "../../../Services/HR/OrganisationUserContractPartService";
import {changePageSizeState} from "../../../Slices/PageSlice";
import AddOragnisationUserContractPart from "./AddOragnisationUserContractPart";
import EditOrganisatioUserContractPart from "./EditOrganisationUserContractPart";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {renderColumnNumber} from "../../../Components/DataGrid/ValueCellRender";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import { changeBreadcrumbs } from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import { getOrganisationUserContract } from "../../../Services/HR/OrganisationUserContractService";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [
    {
        field: 'year',
        headerName: strings.pages.hr.organisationUserContractPart.organisationUserContractPartList.year,
        width: 100
    },
    {
        field: 'month',
        headerName: strings.pages.hr.organisationUserContractPart.organisationUserContractPartList.month,
        width: 100,
        valueFormatter: (params) => formatColumnMonth(params),
    },
    {
        field: 'net',
        headerName: strings.pages.hr.organisationUserContractPart.organisationUserContractPartList.net,
        renderCell: (params) => renderColumnNumber(params),
        width: 150
    },
    {
        field: 'gross',
        headerName: strings.pages.hr.organisationUserContractPart.organisationUserContractPartList.gross,
        renderCell: (params) => renderColumnNumber(params),
        width: 150
    },
    {
        field: 'paymentDate',
        headerName: strings.pages.hr.organisationUserContractPart.organisationUserContractPartList.paymentDate,
        renderCell: (params) => formatColumnDate(params),
        width: 150
    },
    {
        field: 'currency',
        headerName: strings.pages.hr.organisationUserContractPart.organisationUserContractPartList.currency,
        valueFormatter: (params) => formatColumnObject(params, 'code'),
        width: 100
    }
];

const filters = [];

const OrganisationUserContractPartList = () => {

    const dispatch = useDispatch();
    const {id: employeeContractId} = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState({
        ...TablePageOptionDefaults,
        showYearFilter: true,
        showMonthFilter: true
    });
    const referenceType = ReferenceType.ORGANISATION_USER_CONTRACT_PART;
    const permissionGroup = AppPermissions.ORGANISATION_USER_CONTRACT_PART.GROUP;
    const [organisationUserContract, setOrganisationUserContract] = useState({});

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.hr.organisationUserContractPart.organisationUserContractPartList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.pages.hr.organisationUserContract.organisationUserContractList.pageTitle},
                {label: strings.pages.hr.organisationUserContractPart.organisationUserContractPartList.pageTitle},
            ],        
        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(()=>{
        getOrganisationUserContract(employeeContractId).then(response =>{
            if(!response || !response.ok){
                return;
            }
            setOrganisationUserContract(response.data?.entity)
        })
    }, [employeeContractId])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter]);

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });
        getOrganisationUserContractPartList({
            organisationUserContractId: employeeContractId,
            ...filter,

        }).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteOrganisationUserContractPart}
                   tableDescription={columns}
                   tableData={tableData} filter={filter} filters={filters}
                   referenceType={referenceType}
                   permissionGroup={permissionGroup}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditOrganisatioUserContractPart data={selectedItems}
                                                              onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addPage={<AddOragnisationUserContractPart
                       organisationUserContract={organisationUserContract} onCancel={() => onCancel()}
                       onFinish={() => onFinish()}/>}
                    addButtonText={strings.components.tablePage.addButton.employeeContractPart}/>
    </TablePageContext.Provider>;
}

export default OrganisationUserContractPartList;
