import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";

const ContractPartItemForm = ({
                                onSubmit,
                                onCancel,
                                data,
                                form,
                                values,
                                setValue,
                                control,
                                formRules,
                                errors,
                    
                                onChangeProductServiceFilter,
                                productServices,
                            }) => {

    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;


    return(
            
        <FormProvider {...form}>
            <form id="contract-part-form" className='form' action = "#">
                <AutoCompleteControl
                    name={'productService'}
                    rules = {formRules['productService']}
                    className = 'mui-shifted-label-input margin-shifted-label-autoselect mt-8'
                    nameKey={'name'}
                    valueKey={'id'}
                    control = {control}
                    value={values['productService']}
                    setValue={setValue}
                    error={Boolean(errors?.productService)}
                    helperText={errors.productService && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.contractPartItem.contractPartItemForm.productService}
                    placeholder={strings.forms.finance.contractPartItem.contractPartItemForm.placeholder.productService}
                    options = {productServices}
                    onChange={onChangeProductServiceFilter}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='quantity'
                    type = 'number'
                    className = 'mui-shifted-label-input'
                    rules={formRules['quantity']}
                    control={data}
                    error={Boolean(errors.quantity)}
                    helperText={errors.quantity && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.contractPartItem.contractPartItemForm.quantity}
                    placeholder={strings.forms.finance.contractPartItem.contractPartItemForm.placeholder.quantity}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={ onSubmit }>
                            { strings.forms.common.save }
                        </Button>
                        <Button variant="contained" color="secondary" onClick={ onCancel }>
                            { strings.forms.common.cancel }
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );

}

export default ContractPartItemForm;
