import { request } from "../../Base/HTTP"
import HttpMethod from "../../Constants/Base/HttpMethod"

export const getWokringHours = async (data) => {
    return await request('/api/working-hours', data);
}

export const addNewWorkingHoursSchedule = async (data) => {
    return await request('/api/working-hours-new-schedule', data, HttpMethod.POST);
}

export const makeDefaultButton = async (id) => {
    return await request(`/api/working-hours-default/${id}`, {}, HttpMethod.PATCH);
}

export const deleteWorkingHoursSchedule = async (id) => {
    return await request(`/api/working-hours/${id}`, {}, HttpMethod.DELETE);
}

export const editWorkingHours = async (id, data) => {
    return await request(`/api/working-hours/${id}`, transformEditData(data), HttpMethod.PUT);
}

export const changeDefaultWorkingRange = async (id) => {
    return await request(`/api/working-hours-default-change/${id}`, HttpMethod.GET);
}

const transformEditData = (data) => {
    return {
        ...data,
        name: data.title,
    }
}