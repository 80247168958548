import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getOrganisationUnits(data){
    return await request('/api/organisation-unit', data);
}

export async function addOrganisationUnit(data){
    return await request('/api/organisation-unit', transformRequestData(data), HttpMethod.POST);
}

export async function editOrganisationUnit(data){
    return await request('/api/organisation-unit/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteOrganisationUnit(id){
    return await request('/api/organisation-unit/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data){
    return {
        ...data,
        parentId: data.parent ? data.parent.id : null,
    }
}