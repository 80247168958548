import { useState } from 'react';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const DropDownMenu = ({expanded, onExpandEvent, currentState, config, onChange}) => {
    const [key,value] = Object.entries(currentState)[0];

    const getCurrentValue = () => {
        if(key === 'fontSize' && !value){
            currentState[key] = 12;

            return currentState[key];
        }

        if(key === 'fontFamily' && !value){
            return config?.options[0];
        }

        if(key === 'blockType' && !value){
            return 'List';
        }

        return value;
    }

    return(
        <div 
            className={`rdw-dropdown-wrapper rdw-${key}-dropdown`}
            aria-label="rdw-dropdown"
            aria-haspopup="true"
            aria-expanded = {expanded}
            onClick = {onExpandEvent}
        >
            <div className="rdw-dropdown-selectedtext" title="Block Type">
                <span>{getCurrentValue()}</span>
                <img src = '/images/arrow-down-small.svg' className = {expanded ? 'rotate-collapse-arrow' : ''} />
            </div>
            {expanded && 
                <ul className='rdw-dropdown-optionwrapper'>
                    {config?.options.map((option) => (
                        <li 
                            className='rdw-dropdown-default'
                            onClick={() => onChange(option)}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
}

const editorOptions = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'embedded', 'image', 'remove'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
        bold:{
            icon: '/images/editor-bold.svg',
        },
        italic:{
            icon: '/images/editor-italic.svg',
        },
        underline:{
            icon: '/images/editor-underline.svg',
        },
        strikethrough:{
            icon: '/images/editor-strikethrough.svg',
        },
        monospace:{
            icon: '/images/editor-monospace.svg',
        },
        superscript:{
            icon: '/images/editor-superscript.svg',
        },
        subscript:{
            icon: '/images/editor-subscript.svg',
        },
    },
    blockType: {
        inDropdown: true, 
        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
        component: DropDownMenu,
    },
    fontSize: {
        inDropdown: true,
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        component: DropDownMenu,
    },
    fontFamily: {
        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
        component: DropDownMenu,
    },
    list: {
        inDropdown: false,
        options: ['unordered', 'ordered', 'indent', 'outdent'],
        unordered: {
            icon: '/images/editor-list-unordered.svg',
        },
        ordered: {
            icon: '/images/editor-list-ordered.svg',
        },
        indent: {
            icon: '/images/editor-list-indent.svg',
        },
        outdent: {
            icon: '/images/editor-list-outdent.svg'
        }
    },
    textAlign: {
        inDropdown: false,
        options: ['left', 'center', 'right', 'justify'],
        left: {
            icon: '/images/editor-textalign-left.svg'
        },
        center: {
            icon: '/images/editor-textalign-center.svg'
        },
        right: {
            icon: '/images/editor-textalign-right.svg'
        },
        justify: {
            icon: '/images/editor-textalign-justify.svg'
        },
    },
    link:{
        inDropdown: false,
        options: ['link', 'unlink'],
        link: {
            icon: '/images/editor-link.svg',
        },
        unlink: {
            icon: '/images/editor-unlink.svg',
        },
    },
    embedded: {
        icon: '/images/editor-embedded.svg'
    },
    image: {
        icon: '/images/editor-image.svg'
    },
    remove: {
        icon: '/images/editor-remove.svg'
    }
}


const EditorInput = ({editorState, setEditor, toolbarOptions = editorOptions, readOnly = false}) => {
    return(
        <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(value) => {
                setEditor(value)
            }}
            readOnly={readOnly}
            toolbar= {toolbarOptions}
        />
    );
}


export default EditorInput;
