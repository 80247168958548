import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TablePageContext from "../../../../Context/TablePageContext";
import PageState from "../../../../Constants/Base/PageState";
import strings from "../../../../localization";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import DateTimeControl from "../../../Controls/Inputs/DateTimeControl";


const EditUserForm = ({
                      onSubmit,
                      onCancel,
                      data,
                      form,
                      errors,
                      values,
                      setValue,
                      formRules
                  }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='user-form' className='form px-12' action="#">

                <TextFieldControl
                    className='mt-18 mb-8 mui-shifted-label-input'
                    name='email'
                    type={'email'}
                    rules={formRules['email']}
                    control={data}
                    error={Boolean(errors.email)}
                    helperText={errors.email && strings.forms.common.thisFieldIsRequired}
                    label={strings.profile.email}
                    disabled={true}
                />

                <TextFieldControl
                    className='mb-8 mui-shifted-label-input'
                    name='firstName'
                    rules={formRules['firstName']}
                    control={data}
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName && strings.forms.common.thisFieldIsRequired}
                    label={strings.profile.firstName}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    className='mb-8 mui-shifted-label-input'
                    name='lastName'
                    rules={formRules['lastName']}
                    control={data}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName && strings.forms.common.thisFieldIsRequired}
                    label={strings.profile.lastName}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    className='mb-8 mui-shifted-label-input'
                    name='personalNumber'
                    rules={formRules['personalNumber']}
                    control={data}
                    label={strings.profile.personalNumber}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    className='mb-8 mui-shifted-label-input'
                    name='phoneNumber'
                    rules={formRules['phoneNumber']}
                    control={data}
                    error={Boolean(errors.phoneNumber)}
                    helperText={errors.phoneNumber && strings.forms.common.thisFieldIsRequired}
                    label={strings.profile.phoneNumber}
                    disabled={isPageStateViewDetails()}
                />

                <DateTimeControl
                    className='mb-8 mui-shifted-label-input'
                    data={data}
                    label={strings.profile.birthDay}
                    name={'birthDate'}
                    value={values['birthDate']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    className='mb-8 mui-shifted-label-input'
                    name='address'
                    rules={formRules['address']}
                    control={data}
                    error={Boolean(errors.adress)}
                    helperText={errors.adress && strings.forms.common.thisFieldIsRequired}
                    label={strings.profile.address}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" className='mb-8' onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default EditUserForm;