import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAttributes(id) {
    return await request('/api/attributes/' + id);
}

export async function getAttributesList(data) {
    return await request('/api/attributes', data);
}

export async function addAttribute(data) {
    return await request('/api/attribute', data, HttpMethod.POST);
}

export async function editAttribute(data) {
    return await request('/api/attribute/' + data.id, data, HttpMethod.PUT);
}

export async function deleteAttribute(id) {
    return await request('/api/attribute/' + id, {}, HttpMethod.DELETE);
}

export  async function connectAttribute(id){
    return await request('/api/attribute/connect/' + id, {}, HttpMethod.PATCH);
}

export async function disconnectAttribute(id){
    return await request('api/attribute/disconnect/' + id, {}, HttpMethod.PATCH);
}