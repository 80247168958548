import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getDeals(data){
    return await request('/api/deals', data);
}

export async function getDeal(id) {
    return await request('/api/deal/' + id);
}

export async function addDeal(data){
    return await request('/api/deals', formatRequestData(data), HttpMethod.POST);
}

export async function editDeal(data){
    return await request('/api/deal/' + data.id, formatRequestData(data), HttpMethod.PUT);
}

export async function deleteDeal(id){
    return await request('/api/deal/' + id, {}, HttpMethod.DELETE);
}

export async function getDealStages(data){
    return await request('/api/deals/stages', data);
}

export async function getDealStagesBySlug(data){
    return await request('/api/deals/stages/' + data.slug, data);
}

export async function updateDealStage(data){
    return await request('/api/deals/stages', data, HttpMethod.PUT);
}

function formatRequestData(data){
    return {
        ...data,
        pipeLine: data.pipeLine ? data.pipeLine.id : null,
        pipeLineStage: data.pipeLineStage ? data.pipeLineStage.id : null,
        currency: data.currency ? data.currency.id : null,
        partner: data.partner ? data.partner.id : null,
        dealType: data.dealType ? data.dealType.id : null,
        dealPriority: data.dealPriority ? data.dealPriority.id : null,
        dealSource: data.dealSource ? data.dealSource.id : null
    }
}
