
import React from "react";
import { Quill } from "react-quill";
import TemplateEditorQuestionSlider from "./TemplateEditorQuestionSlider";



const icons = Quill.import('ui/icons');
icons['bold'] = '<img src ="/images/editor-bold.svg" />';
icons['italic'] = '<img src ="/images/editor-italic.svg" />';
icons['underline'] = '<img src ="/images/editor-underline.svg" />';
icons['strike'] = '<img src ="/images/editor-strikethrough.svg" />';
icons['color'] = '<img src = "/images/editor-monospace.svg" />';
icons['list']['ordered'] = '<img src = "/images/editor-list-ordered.svg" />';
icons['list']['bullet'] = '<img src = "/images/editor-list-unordered.svg" />';
icons['indent']['+1'] = '<img src = "/images/editor-list-indent.svg" />';
icons['indent']['-1'] = '<img src = "/images/editor-list-outdent.svg" />';
icons['align'][''] = '<img src = "/images/editor-textalign-left.svg" />';
icons['align']['center'] = '<img src = "/images/editor-textalign-center.svg" />';
icons['align']['justify'] = '<img src = "/images/editor-textalign-justify.svg" />';
icons['align']['right'] = '<img src = "/images/editor-textalign-right.svg" />';
icons['link'] = '<img src = "/images/editor-link.svg" />';
icons['blockquote'] = '<img src = "/images/editor-quote.svg" />';
icons['image'] = '<img src = "/images/editor-image.svg" />';
icons['undo'] = '<img src = "/images/editor-undo.svg" />';
icons['redo'] = '<img src = "/images/editor-redo.svg" />';

export const modules = {
    toolbar: {
        container: "#toolbar"
    },
    history: {
        delay: 500,
        maxStack: 100,
        userOnly: true
    }
};


const EditorToolbar = (props) =>{

    const clickQuestionHandler = (question) => {
        props.clickQuestion(question);
    }

    const clickUndo = () => {
        props.undoHandler();
    }

    const clickRedo = () => {
        props.redoHandler();
    }

    return (
    <div id="toolbar">
            <span className="ql-formats">
                <select className="ql-header" defaultValue="0">
                    <option value="0">Normal</option>
                    <option value="1">Heading 1</option>
                    <option value="2">Heading 2</option>
                    <option value="3">Heading 3</option>
                    <option value="4">Heading 4</option>
                    <option value="5">Heading 5</option>
                    <option value="6">Heading 6</option>
                </select>
            </span>
            <span className="ql-formats">
                <button className="ql-bold" />
                <button className="ql-italic" />
                <button className="ql-underline" />
                <button className="ql-strike" />
                <select className="ql-color" />
            </span>
            <span className="ql-formats">
                <select className="ql-size" defaultValue="medium">
                    <option value="small">Size 1</option>
                    <option value="medium">Size 2</option>
                    <option value="large">Size 3</option>
                    <option value="huge">Size 4</option>
                </select>
                <select className="ql-font" defaultValue="Sans Serif">
                    <option value="sans-serif">Sans Serif</option>
                    <option value="serif">Serif</option>
                    <option value="monospace">Monospace</option>
                </select>
            </span>
            <span className="ql-formats">
                <button className="ql-list" value="bullet" />
                <button className="ql-list" value="ordered" />
                <button className="ql-indent" value="-1" />
                <button className="ql-indent" value="+1" />
                <button className="ql-align"/>
                <button className="ql-align" value="center"/>
                <button className="ql-align" value="right"/>
                <button className="ql-align" value="justify"/>
            </span>
            <span className="ql-formats">
                <button className="ql-link"/>
                <button className="ql-blockquote"/>
                <button className="ql-image"/>
            </span>
            <span className="ql-formats">
                <button className="ql-undo" onClick={clickUndo}/>
                <button className="ql-redo" onClick={clickRedo}/>
            </span>
            <TemplateEditorQuestionSlider questions={props.questions} clickQuestion={clickQuestionHandler}></TemplateEditorQuestionSlider>
    </div>
)};

export default EditorToolbar;