import strings from "../../localization";

export const ProductServiceType = {
    PRODUCT: 1,
    SERVICE: 2,
}

export function getProductServiceTypes() {
    return [
        {
            value: ProductServiceType.PRODUCT,
            name: getProductServiceTypeString(ProductServiceType.PRODUCT)
        },
        {
            value: ProductServiceType.SERVICE,
            name: getProductServiceTypeString(ProductServiceType.SERVICE)
        }
    ];
}

export function getProductServiceTypeString(productServiceType) {
    switch(productServiceType) {
        case ProductServiceType.PRODUCT: return strings.constants.finance.productServiceType.product;
        case ProductServiceType.SERVICE: return strings.constants.finance.productServiceType.service;
    }
}

export default ProductServiceType;
