import React, {useState, useEffect} from "react";
import {Paper, Tab, Tabs} from "@mui/material";
import strings from "../../../localization";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../Util/PermissionUtil";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import { CRMSettingsTab } from "../../../Constants/CRM/CRMSettingsTab";
import DealTypeList from "../DealType/DealTypeList";
import DealPriorityList from "../DealPriority/DealPriorityList";
import DealSourceList from "../DealSource/DealSourceList";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import { useNavigate } from 'react-router-dom';

const CRMSettings = ({activeTab = CRMSettingsTab.DEAL_TYPE}) => {
    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const changeActiveTab = (tab) =>{
        if(tab === CRMSettingsTab.DEAL_TYPE){
            navigate('/crm-settings/deal-type')
        }
        if(tab === CRMSettingsTab.DEAL_PRIORITY){
            navigate('/crm-settings/deal-priority')
        }
        if(tab === CRMSettingsTab.DEAL_SOURCE){
            navigate('/crm-settings/deal-source')
        }
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: hasPermission(auth.user, AppPermissions.DEAL_TYPE.GROUP, AppPermissions.DEAL_TYPE.VIEW_LIST, auth.permissions) ? strings.pages.crm.dealType.dealTypeList.pageTitle : strings.pages.crm.settings.settings,

            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.CRM},
                {label: strings.pages.crm.settings.settings},
                {label: strings.pages.crm.dealType.dealTypeList.pageTitle},
            ],        
        }));
    }, [])

    return (
        <div id="crm-settings">
            <div className='tab-container ml-3'>
                <Paper className='paper'>
                    <Tabs variant="scrollable" value={activeTab} onChange={(e, tab) => changeActiveTab(tab)}>
                        <Tab label={strings.pages.crm.settings.dealType}
                            disabled={!hasPermission(auth.user, AppPermissions.DEAL_TYPE.GROUP,
                                AppPermissions.DEAL_TYPE.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.crm.settings.dealPriority}
                            disabled={!hasPermission(auth.user, AppPermissions.DEAL_PRIORITY.GROUP,
                                AppPermissions.DEAL_PRIORITY.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.crm.settings.dealSource}
                            disabled={!hasPermission(auth.user, AppPermissions.DEAL_SOURCE.GROUP,
                                AppPermissions.DEAL_SOURCE.VIEW_LIST, auth.permissions)}
                        />
                    </Tabs>
                </Paper>
            </div>

            {
                activeTab === CRMSettingsTab.DEAL_TYPE &&
                <DealTypeList/>
            }
            {
                activeTab === CRMSettingsTab.DEAL_PRIORITY &&
                <DealPriorityList/>
            }
            {
                activeTab === CRMSettingsTab.DEAL_SOURCE &&
                <DealSourceList/>
            }
            
        </div>
    );
}

export default CRMSettings;
