import {Bar} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import {barBackgroundColors, barBaseOptions, barBorderColors} from "../../Util/ChartUtil";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';


const BarChart = ({labels, inputData, options: newOptions}) => {
    const [data, setData] = useState(null);
    const [tmpData, setTmpData] = useState(null);
    const [showOptions, setShowOptions] = useState(() => {
        let res = {};
        inputData.forEach((data) => res[data.label] = true);

        return res;
    });

    useEffect(() => {

        if(!inputData) {
            return;
        }

        let datasets = [];

        for(let item of inputData) {

            datasets.push(
                {
                    label: item.label,
                    data: item.data,
                    backgroundColor: [
                        barBackgroundColors[datasets.length],
                    ],
                    borderColor: [
                        barBorderColors[datasets.length],
                    ],
                    borderWidth: 1,
                },
            )
        }

        setData({
            labels: labels,
            datasets: datasets
        });

        setTmpData({
            labels: labels,
            datasets: datasets
        });

    }, [inputData, labels]);

    useEffect(() => {
        if(Object.keys(showOptions).length > 0 && data){
            setTmpData((prev) => {
                let res = data.datasets.filter((set) => showOptions[set.label]);

                return {
                    labels: prev.labels,
                    datasets: res,
                }

            });
        }
        
    },[showOptions, data]);

    return <React.Fragment>
            <div className = 'chart-legend'>
                {
                    data && data.datasets.map((set, index) => 
                        <FormControlLabel 
                            key = {'barchart-checkbox' + index}
                            className = 'one-liner'
                            control = {
                            <Checkbox 
                                size = {'small'}
                                checked = {showOptions[set.label] !== undefined ? showOptions[set.label] : true}
                                id = {set.label}
                                sx = {{
                                    color: set.borderColor[0],
                                    '&.Mui-checked': {
                                        color: set.borderColor[0],
                                    }
                                }}
                                disabled = {Object.keys(showOptions).length === 1}
                                onChange = {(e) => 
                                    setShowOptions((prev) => ({...prev, [e.target.id]: !prev[e.target.id]}))
                                } 
                            />
                        } 
                            label = {set.label} 
                        />
                    )
                }
            </div>
       { data && 
        <div className = 'chart'>
            <Bar data={tmpData} options={{...barBaseOptions, ...newOptions}}/> 
        </div>
        }
    </React.Fragment>
}

export default BarChart;
