import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import CheckBoxControl from '../../../../Controls/Inputs/CheckBoxControl';
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";

const ContractPartForm = ({
                                onSubmit,
                                onCancel,
                                data,
                                form,
                                values,
                                setValue,
                                control,
                                formRules,
                                errors,
                                onChangeContractPartStatusFilter,
                                contractPartStatuses
                            }) => {

    const {pageState} = useContext(TablePageContext);
    const isPageStateAdd = () => pageState === PageState.Add;
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return(
            
        <FormProvider {...form}>
            <form id="contract-part-form" className='form' action = "#">

                <TextFieldControl
                    name='description'
                    rules={formRules['description']}
                    control={data}
                    error={Boolean(errors.description)}
                    helperText={errors.description && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.contractPart.contractPartForm.description}
                    disabled={isPageStateViewDetails()}
                />

                <div className='header-row'>
                    <div className='header-row-item'>
                        <DateTimeControl
                            data={data}
                            label={strings.forms.finance.contractPart.contractPartForm.startDate}
                            name={'startDate'}
                            value={values['startDate']}
                            setValue={setValue}
                            disabled={isPageStateViewDetails()}
                        />
                    </div>

                    <div className='header-row-item'>
                        <DateTimeControl
                            data={data}
                            label={strings.forms.finance.contractPart.contractPartForm.endDate}
                            name={'endDate'}
                            value={values['endDate']}
                            setValue={setValue}
                            disabled={isPageStateViewDetails()}
                        />
                    </div>
                </div>

                <div className='header-row'>
                    <div className='header-row-item'>
                        <DateTimeControl
                            data={data}
                            label={strings.forms.finance.contractPart.contractPartForm.paymentDate}
                            name={'paymentDate'}
                            value={values['paymentDate']}
                            setValue={setValue}
                            disabled={isPageStateViewDetails()}
                        />
                    </div>

                    <div className='header-row-item'>
                        <DateTimeControl
                            data={data}
                            label={strings.forms.finance.contractPart.contractPartForm.actualPaymentDate}
                            name={'actualPaymentDate'}
                            value={values['actualPaymentDate']}
                            setValue={setValue}
                            disabled={isPageStateAdd() || isPageStateViewDetails()}
                            
                        />
                    </div>

                    <div className='header-row-item'>
                        <DateTimeControl
                            data={data}
                            label={strings.forms.finance.contractPart.contractPartForm.invoiceDate}
                            name={'invoiceDate'}
                            value={values['invoiceDate']}
                            setValue={setValue}
                            disabled={isPageStateViewDetails()}
                            
                        />
                    </div>
                </div>

                <TextFieldControl
                    name='amount'
                    rules={formRules['amount']}
                    control={data}
                    type="number"
                    error={Boolean(errors.amount)}
                    helperText={errors.amount && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.contractPart.contractPartForm.amount}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='actualAmount'
                    control={data}
                    type="number"
                    label={strings.forms.finance.contractPart.contractPartForm.actualAmount}
                    disabled={isPageStateAdd() || isPageStateViewDetails()}
                />
                
                <CheckBoxControl
                    name={'paid'}
                    control={data}
                    label={strings.forms.finance.contractPart.contractPartForm.paid}
                    value={values['paid']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />
                <AutoCompleteControl
                    name='contractPartStatus'
                    label={strings.forms.finance.contractPart.contractPartForm.contractPartStatus}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={setValue}
                    onChange={onChangeContractPartStatusFilter}
                    onOpen={() =>onChangeContractPartStatusFilter('')}
                    control={control}
                    options={contractPartStatuses}
                    value={values['contractPartStatus']}
                    rules={formRules['contractPartStatus']}
                    error={Boolean(errors.contractPartStatus)}
                    helperText={errors.contractPartStatus && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={ onSubmit }>
                            { strings.forms.common.save }
                        </Button>
                        <Button variant="contained" color="secondary" onClick={ onCancel }>
                            { strings.forms.common.cancel }
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );

}

export default ContractPartForm;
