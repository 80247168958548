import PaymentType from "../Constants/Finance/PaymentType";
import InvoiceDirection from "../Constants/Finance/InvoiceDirection";

export function getAmountWithDiscount(item) {

    const discount = item.discount ? item.discount : 0;

    return parseFloat(item.price) * parseFloat(item.quantity) * (100 - parseFloat(discount)) / 100;
}

export function getAmountWithVAT(item) {
    let tax = item.tax.value ? item.tax.value : item.tax;
    return getAmountWithDiscount(item) * (1 + (parseFloat(tax) / 100))
}

export function getTotal(items) {

    if(!items || items.length === 0) {
        return 0;
    }

    let value = 0;

    for(let item of items) {
        value = value + getAmountWithDiscount(item);
    }

    return value;
}

export function getVAT(items) {

    if(!items || items.length === 0) {
        return 0;
    }

    let value = 0;


    for(let item of items) {
        let tax = item.tax.value ? item.tax.value : item.tax;
        value = value + getAmountWithDiscount(item) * (parseFloat(tax) / 100);
    }

    return value;
}

export function getTotalWithVAT(items) {
    return getTotal(items) + getVAT(items);
}

export function getInvoiceDirectionFromPaymentType(paymentType){
    if(paymentType === PaymentType.INCOME){
        return InvoiceDirection.OUT;
    }
    if(paymentType === PaymentType.OUTCOME){
        return InvoiceDirection.IN;
    }
    return null;
}

export function isInoInvoice(item, user){

    if(!item || !item.partner || !item.country) {
        return false
    }

    return !(item.partner.country.code === user.company.country.code);
}