import React, {useContext, useEffect, useState} from "react";
import {Grid, Paper} from "@mui/material";
import strings from "../../../localization";
import FileUploadComponent from "../../../Components/Controls/FileUploadComponent";
import AvatarProfileControl from "../../../Components/Controls/AvatarProfileControl";
import {toBase64} from "../../../Util/ImageUtil";
import SnackbarContext from "../../../Context/SnackbarContext";
import { ImageFormats } from "../../../Constants/FileFormat";
import { useParams } from 'react-router-dom';
import {useDispatch} from "react-redux";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {changePageSizeState} from "../../../Slices/PageSlice";
import {getBoardLogo, changeBoardLogo} from '../../../Services/Workspaces/BoardsService';

const BoardChangeLogo = () => {
	const dispatch = useDispatch();
	const {boardId} = useParams();
	const [loading, setLoading] = useState(false);
	const [boardLogo, setBoardLogo] = useState()
	const [editor, setEditor] = useState()
	const [scaleValue, setScaleValue] = useState(1)
	const {showMessage} = useContext(SnackbarContext);
	const [files, setFiles] = useState([]);
	const [changed, setChanged] = useState(false);
	
	useEffect(() => {
			fetchData();
	},[]);

	const uploadLogo = async (files) => {
			const logo = await toBase64(files[0]);
			setLoading(true);
			setBoardLogo(logo);
			showMessage(strings.commonMessages.editingSuccessful, 'success');
			setLoading(false);
			setChanged(false);
			setScaleValue(1);
			setFiles([]);
			changeBoardLogo(boardId ,{ logo }).then(response =>{
					setBoardLogo(response.data?.boardLogo);
					showMessage(strings.commonMessages.editingSuccessful, 'success');
					setLoading(false);
					setChanged(false);
					setScaleValue(1);
					setFiles([]);
			});
	}

	const fetchData = () => {
	    getBoardLogo(boardId).then(response => {
	        setBoardLogo(response?.data?.boardLogo)
	    });
	}
	useEffect(() => {
			dispatch(changePageSizeState(PageSizeState.SHORT));
			dispatch(changeBreadcrumbs({
					title: strings.navigation.boards,
					hierarchy: [
							{label: strings.navigation.homeTag},
							{label: strings.navigation.workspaces},
							{label: strings.navigation.boards},
							{label: strings.pages.boards.changeBoardLogo.pageTitle}
					]
			}));
	},[]);

	const onCrop = () => {
			if(!boardLogo){
					showMessage(strings.commonMessages.errorEditing, 'error');
			}else{
					const img = editor.getImage().toDataURL();
							let imageLogo;
							fetch(img)
									.then(res => res.blob())
									.then(blob => (imageLogo = window.URL.createObjectURL(blob)));
							
							changeBoardLogo(boardId, { logo: img }).then(response =>{
									setBoardLogo(response.data?.boardLogo);
									setScaleValue(1)
									setChanged(false);
									setFiles([]);
									showMessage(strings.commonMessages.editingSuccessful, 'success');
							});
			}
	}

	const onScaleChange = (scaleValueEvent) => {
			const scaleValue = parseFloat(scaleValueEvent.target.value);
			setScaleValue(scaleValue)
			setChanged(true);
	}

			return(
					<Grid id='page' item md = { 12 } justifyContent="center" container
								alignItems="center">

							<Grid item xs={5}>
									<Paper className='paper upload-logo-container'>
											<FileUploadComponent upload= { uploadLogo } formats={ImageFormats} loading={loading} files = {files} setFiles = {setFiles} />
											<AvatarProfileControl
													imageSrc={ boardLogo }
													setEditorRef={setEditor}
													onCrop={onCrop}
													scaleValue={scaleValue}
													onScaleChange={onScaleChange}
													disableSave = {!changed}
													setChanged = {setChanged}
													sliderValue = {scaleValue}
													/>
									</Paper>
							</Grid>
					</Grid>
			);
}


export default BoardChangeLogo;
