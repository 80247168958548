import React from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import {activeBusinessYear, getBusinessYears} from "../../../Services/Finance/BusinessYearService";
import EditBusinessYear from "./EditBusinessYear";
import AddBusinessYear from "./AddBusinessYear";
import {formatColumnDate} from "../../../Components/DataGrid/ValueFormatter";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {useNavigate, useSearchParams} from "react-router-dom";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import {returnColumnBoolean} from "../../../Components/DataGrid/ValueCellRender";

const tableDescription = [
    {field: 'year', headerName: strings.pages.administration.businessYear.businessYearList.year, width: 100},
    {
        field: 'active',
        headerName: strings.pages.administration.businessYear.businessYearList.active,
        renderCell: (params) => returnColumnBoolean(params),
        width: 100
    },
    {
        field: 'startDate',
        headerName: strings.pages.administration.businessYear.businessYearList.startDate,
        valueFormatter: formatColumnDate,
        width: 100
    },
    {
        field: 'endDate',
        headerName: strings.pages.administration.businessYear.businessYearList.endDate,
        valueFormatter: formatColumnDate,
        width: 100
    },
];

const filters = []

const BusinessYearList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.BUSINESS_YEAR;
    const permissionGroup = AppPermissions.BUSINESS_YEAR.GROUP;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    const additionalMenuItems = [
        {
            handleClick: (id, item) => handleItems(id, item),
            text: strings.pages.administration.businessYear.businessYearList.businessYearStartValue,
            src: "/images/table-page/year-start.svg"
        },
        {
            handleClick: (id, item) => handleActive(id, item),
            text: strings.pages.administration.businessYear.businessYearList.active,
            src: "/images/icons/grey/accept.svg",
            hide: (item) => {
                return item.active
            }
        },
    ]

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.administration.businessYear.businessYearList.pageTitle,
            hierarchy: [
                {label: strings.navigation.managmentTag},
                {label: Modules.FINANCE},
                {label: strings.pages.finance.settings.settings},
                {label: strings.pages.administration.businessYear.businessYearList.pageTitle},
            ],
        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if (!updatedFilter) {
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    const handleItems = (template, item) => {
        navigate({pathname: `/business-year-start-values/${item.id}`, search: `?year=${item.year}`});
    }

    const handleActive = (id, item) => {
        activeBusinessYear(id).then(() => {
            fetch()
        })
    }

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getBusinessYears({
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} tableDescription={columns}
                   tableData={tableData} filter={filter} filters={filters}
                   permissionGroup={permissionGroup}
                   referenceType={referenceType}
                   additionalMenuItems={additionalMenuItems}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditBusinessYear data={ selectedItems }
                                                  onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addPage={<AddBusinessYear onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   hideBreadcrumbs
                   addButtonText={strings.components.tablePage.addButton.businessYear}/>
    </TablePageContext.Provider>;
}

export default BusinessYearList;
