import React, {useContext, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux";
import SnackbarContext from "../../../Context/SnackbarContext";
import {useForm} from "react-hook-form";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import {
    getWooCommerceSettings,
    updateWooCommentSettings
} from "../../../Services/Product/ProductSettingsService/WooCommerceSettingsService";
import {Grid, Paper} from "@mui/material";
import WooCommerceSettingsForm
    from "../../../Components/Forms/Pages/Product/WooCommerceSettings/WooCommerceSettingsForm";
import Modules from "../../../Constants/Base/Modules";

const WooCommerceSettings = () => {
    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch();
    const { showMessage} = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: {
            enabled: true
        }
    });
    const {data, control, handleSubmit, getValues, setValue, reset ,formState: {errors}} = form;

    useEffect(() => {
        dispatch(changeBreadcrumbs({
            title: strings.pages.product.wooCommerce.wooCommerceSettings,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.PRODUCT},
                {label: strings.pages.product.wooCommerce.productSettings},
                {label: strings.pages.product.wooCommerce.wooCommerceSettings},
            ],
        }));
        fetch();
    }, []);

    const fetch = () => {
        getWooCommerceSettings(auth.company.id).then(response => {
            if(!response || !response.ok) {
                return;
            }
            reset(response.data.entity)
        })
    }

    const save = (data) => {
        updateWooCommentSettings(data).then(response => {
            if(!response || !response.ok){
                showMessage(strings.commonMessages.errorAdding,'error');
                return;
            }
            showMessage(strings.commonMessages.addingSuccessful,'success');
            fetch();
        })
    }

    return <Grid container justifyContent="center"
                 alignItems="center">
        <Grid item xs={4}>
            <Paper className='paper rounded-2'>
                <WooCommerceSettingsForm
                    control={control}
                    values={getValues()} setValue={setValue}
                    data={data} form={form}
                    onSubmit={handleSubmit(save)}
                />
            </Paper>
        </Grid>
    </Grid>
}
export default WooCommerceSettings;