import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import React, { useContext } from "react";
import { getAdditionalHoursStatus } from "../../../../../Constants/HR/AdditionalHoursStatus";
import { FormProvider } from "react-hook-form";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import Button from "@mui/material/Button";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";
import { useSelector } from "react-redux";
import AppPermissions from "../../../../../Constants/Permissions/Permissions";
import { hasPermission } from "../../../../../Util/PermissionUtil";

const AdditionalHoursForm = ({
  onSubmit,
  onCancel,
  data,
  form,
  errors,
  values,
  setValue,
  formRules,
  organisationUserContracts,
  control,
  onChangeUserContractFilter,
}) => {
  const { pageState } = useContext(TablePageContext);
  const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
  const auth = useSelector((state) => state.auth);

  return (
    <FormProvider {...form}>
      <form id="additional-hours-form" className="form" action="#">
        <DateTimeControl
          data={data}
          rules={formRules["startDate"]}
          form={form}
          error={Boolean(errors.startDate)}
          helperText={
            errors.startDate && strings.forms.common.thisFieldIsRequired
          }
          label={strings.forms.hr.additionalHours.startDate}
          name={"startDate"}
          value={values["startDate"]}
          setValue={setValue}
          disabled={isPageStateViewDetails()}
        />
        <DateTimeControl
          data={data}
          rules={formRules["endDate"]}
          form={form}
          error={Boolean(errors.endDate)}
          helperText={
            errors.endDate && strings.forms.common.thisFieldIsRequired
          }
          label={strings.forms.hr.additionalHours.endDate}
          name={"endDate"}
          value={values["endDate"]}
          setValue={setValue}
          disabled={isPageStateViewDetails()}
        />
        <TextFieldControl
          name="hours"
          rules={formRules["hours"]}
          control={data}
          error={Boolean(errors.hours)}
          helperText={errors.hours && strings.forms.common.thisFieldIsRequired}
          label={strings.forms.hr.additionalHours.hours}
          disabled={isPageStateViewDetails()}
        />
        {hasPermission(
          auth.user,
          AppPermissions.ADDITIONAL_HOURS.GROUP,
          AppPermissions.ADDITIONAL_HOURS.RESOLVE,
          auth.permissions
        ) && (
          <SelectControl
            value={values["status"]}
            setValue={setValue}
            rules={formRules["status"]}
            name="status"
            control={control}
            error={Boolean(errors.status)}
            helperText={
              errors.status && strings.forms.common.thisFieldIsRequired
            }
            label={strings.forms.hr.additionalHours.status}
            options={getAdditionalHoursStatus()}
            nameKey={"name"}
            valueKey={"id"}
            disabled={isPageStateViewDetails()}
          />
        )}
        <AutoCompleteControl
          name="organisationUserContract"
          label={strings.forms.hr.additionalHours.organisationUserContract}
          nameKey={"name"}
          valueKey={"id"}
          setValue={setValue}
          control={control}
          options={organisationUserContracts}
          value={values["organisationUserContract"]}
          rules={formRules["organisationUserContract"]}
          error={Boolean(errors.organisationUserContract)}
          helperText={
            errors.organisationUserContract &&
            strings.forms.common.thisFieldIsRequired
          }
          disabled={isPageStateViewDetails()}
          onChange={onChangeUserContractFilter}
          onOpen={() => onChangeUserContractFilter("")}
        />
        {!isPageStateViewDetails() && (
          <div className="submit-container">
            <Button variant="contained" color="primary" onClick={onSubmit}>
              {strings.forms.common.save}
            </Button>
            <Button variant="contained" color="secondary" onClick={onCancel}>
              {strings.forms.common.cancel}
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default AdditionalHoursForm;
