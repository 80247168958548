import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {LinearProgress, Paper} from "@mui/material";
import strings from "../../localization";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {useNavigate} from "react-router-dom";
import {registration} from "../../Services/User/UserService";
import RegistrationForm from "../../Components/Forms/Pages/User/RegistrationForm";
import ValidationPatters from "../../Constants/Base/ValidationPatters";
import SnackbarContext from "../../Context/SnackbarContext";

const formRules = {
    'email': {required: { value: true, message: strings.forms.common.thisFieldIsRequired},
        pattern: { value: ValidationPatters.EMAIL, message: strings.forms.common.emailFormatError }},
    'firstName': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
    'lastName': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
    'password': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
    'passwordRepeat': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
}

const Registration = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const form = useForm();
    const {data, handleSubmit, formState: {errors}, setError} = form;

    const [showLoader, setShowLoader] = useState(false)
    const {showMessage} = useContext(SnackbarContext);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.FULL_NO_HEADER));
    }, [])

    const onSubmit = (data) => {
        setShowLoader(true)
        if(data.password !== data.passwordRepeat) {
            setError('passwordRepeat', {passwordsDoNotMatch: true, message: strings.profile.passwordsDoNotMatch});
            setShowLoader(false)
            return;
        }

        registration(data).then(response => {

            setShowLoader(false)

            if (!response || !response.ok) {
                showMessage(strings.pages.user.registration.userAlreadyExists, 'error')
                return;
            }

            navigate('/verify-email', {state: {data: data}});
        });
    }

    return <div id='registration-page'>
        <div className="color-overlay"></div>      

        <Paper>
            <div className="loader">
                {showLoader && <div className="loader-background">
                    {<LinearProgress/>}
                </div>}
            </div>  
                <img className='logo' src={'/images/managevio-brain.svg'} loading={'lazy'}/>
                <h5>{strings.pages.user.registration.signUp}</h5>
                <RegistrationForm formRules={formRules} errors={errors} data={data} form={form} onSubmit={handleSubmit(onSubmit)}/>
                
        </Paper>
    </div>
};
export default Registration;
