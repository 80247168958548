import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";
import {isNullOrUndef} from "chart.js/helpers";

export async function getContract(id) {
    return await request('/api/contracts/' + id);
}

export async function getContracts(data) {
    return await request('/api/contracts', data);
}

export async function addContract(data) {
    return await request('/api/contracts', transformRequestData(data), HttpMethod.POST);
}

export async function editContract(data) {
    return await request('/api/contracts/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function generateContractParts(data) {
    return await request(`/api/contracts/${data.id}/generate-parts`, data, HttpMethod.POST);
}

export async function deleteContract(id) {
    return await request('/api/contracts/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        partnerId: data && data.partner ? data.partner.id : -1,
        currencyId: data && data.currency ? data.currency.id : -1,
        transactionCategoryId: data && data.transactionCategory ? data.transactionCategory.id : -1,
        contractType: data && data.contractType ? data.contractType.id : null,
        tax: data && data.tax ? data.tax.value : 0,
        paymentMonth: data.paymentMonth ? data.paymentMonth.value : null,
        canReturnTax: isNullOrUndef(data.canReturnTax) ? false : data.canReturnTax,
        projectId: data && data.project ? data.project.id : -1,
        contractStatusId: data && data.contractStatus ? data.contractStatus.id : -1,
    }
}

function transformGenerateInvoiceData(data) {
    return {
        ...data,
        contractId: data.contract ? data.contract.id : undefined,
    }
}