import React, { useContext } from "react";
import { FormProvider } from "react-hook-form";
import strings from "../../../../../localization";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import Button from '@mui/material/Button';
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";

const LeaveTypeForm = ({
    onSubmit,
    onCancel,
    data,
    form,
    errors,
    formRules
}) => {
    const { pageState } = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id="leave-type-form" className='form' action="#">
                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.leaveType.name}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='subtractDays'
                    rules={formRules['subtractDays']}
                    control={data}
                    type='number'
                    error={Boolean(errors.subtractDays)}
                    helperText={errors.subtractDays && errors.subtractDays.message}
                    label={strings.forms.hr.leaveType.subtractDays}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default LeaveTypeForm;