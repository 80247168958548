import React, {useContext} from 'react';
import strings from "../../../../../localization";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import Button from '@mui/material/Button';
import { FormProvider } from 'react-hook-form';
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import SelectControl from '../../../../Controls/Inputs/SelectControl';
import AutoCompleteControl from '../../../../Controls/Inputs/AutoCompleteControl';

const TeamForm = ({
                       onSubmit,
                       onCancel,
                       data,
                       setValue,
                       values,
                       form,
                       errors,
                       formRules,
                       organisationUnits,
                       control,
                       onChangeUserFilter

                   }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id="team-form" className='form' action = "#">

                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.team.name}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='description'
                    control={data}
                    label={strings.forms.hr.team.description}
                    disabled={isPageStateViewDetails()}
                    multiline={true}
                    rows={4}
                />

                <AutoCompleteControl
                    name='organisationUnit'
                    label={strings.forms.hr.team.organisationUnit}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={setValue}
                    control={control}
                    options={organisationUnits}
                    value={values['organisationUnit']}
                    rules={formRules['organisationUnit']}
                    error={Boolean(errors.organisationUnit)}
                    helperText={errors.organisationUnit && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                    onChange={onChangeUserFilter}
                    onOpen={() =>onChangeUserFilter('')}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={ onSubmit }>
                            { strings.forms.common.save }
                        </Button>
                        <Button variant="contained" color="secondary" onClick={ onCancel }>
                            { strings.forms.common.cancel }
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default TeamForm;