import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getContractStatuses(data) {
    return await request('/api/contract-status', data);
}

export async function addContractStatus(data) {
    return await request('/api/contract-status', transformRequestData(data), HttpMethod.POST);
}

export async function editContractStatus(data) {
    return await request('/api/contract-status/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteContractStatus(id) {
    return await request('/api/contract-status/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
    }
}
