import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllDealRecords(dealId) {
    return await request('/api/deal-records/' + dealId);
}

export async function addDealRecord(data) {
    return await request('/api/deal-records', data, HttpMethod.POST);
}

export async function editDealRecord(data) {
    return await request('/api/deal-records/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteDealRecord(id) {
    return await request('/api/deal-records/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        dealId: data?.deal?.id
    }
}