import strings from "../../localization";

export const ContractType = {
    CUSTOMER_CONTRACT: 1,
    SUPPLIER_CONTRACT: 2,
}

export function getContractTypes() {
    return [
        {
            id: ContractType.CUSTOMER_CONTRACT,
            name: getContractTypeString(ContractType.CUSTOMER_CONTRACT)
        },
        {
            id: ContractType.SUPPLIER_CONTRACT,
            name: getContractTypeString(ContractType.SUPPLIER_CONTRACT)
        }
    ];
}

export function getContractTypeString(contractType) {
    switch(contractType) {
        case ContractType.CUSTOMER_CONTRACT: return strings.constants.finance.contractType.customerContract;
        case ContractType.SUPPLIER_CONTRACT: return strings.constants.finance.contractType.supplierContract;
    }
}

export default ContractType;
