import {Button, Drawer, IconButton, LinearProgress, Paper} from "@mui/material";
import React, {useCallback, useEffect, useReducer, useState} from "react";
import {dateToString, getCurrentDay, getCurrentMonth, getCurrentYear, getDaysOfMonth} from "../../Util/DateUtil";
import strings from "../../localization";
import {getCalendarData} from "../../Services/Company/CalendarService";
import CalendarEventList from "./CalendarEventList";
import DrawerWrapper from "../Layout/DrawerWrapper";
import PageState from "../../Constants/Base/PageState";
import DrawerContext from "../../Context/DrawerContext";
import EventContext from "../../Context/EventContext";
import { useDispatch } from "react-redux";
import { changeBreadcrumbs } from "../../Slices/BreadcrumbsSlice";
import CalendarTab from "../../Constants/Company/CalendarTab";
import { Tabs, Tab } from "@mui/material";
import CalendarByMonth from "./CalendarByMonth";
import CalendarByDay from "./CalendarByDay";
import CalendarByWeek from "./CalendarByWeek";
import { useContext } from "react";
import LoaderContext from "../../Context/LoaderContext";
import {useForm} from "react-hook-form";
import AddEvent from "../../Pages/Calendar/AddEvent";
import EditCustomEvent from "../../Pages/Calendar/EditCustomEvent";
import pageState from "../../Constants/Base/PageState";
import {addEvent, deleteEvent} from "../../Services/Calendar/EventService";
import SnackbarContext from "../../Context/SnackbarContext";
import YesNoDialog, {YesNoDialogResult} from "../Dialogs/YesNoDialog";

const ReducerActions = {
    INCREASE_DAY: 1,
    DECREASE_DAY: 2,
    INCREASE_WEEK: 3,
    DECREASE_WEEK: 4,
    INCREASE_MONTH: 5,
    DECREASE_MONTH: 6,
    TODAY: 7,
    SET_DAY: 8,
    SET_MONTH: 9,
    SET_YEAR: 10,
}

const reducerToday = {
    day: getCurrentDay(),
    month: getCurrentMonth(),
    year: getCurrentYear(),
}

const getUniqueEventKey = (data) =>{
    if(!data){
        return ""
    }
   return JSON.stringify(data);
}

const reducer = (state, action)=>{
    switch (action.type) {
        case ReducerActions.INCREASE_DAY:
            if(state.day === getDaysOfMonth(state.year, state.month).length){
                if(state.month === 12){
                    return { day: 1, month: 1, year: state.year + 1}
                }
                return {...state, day: 1, month: state.month + 1}
            }
            return {...state, day: state.day + 1}

        case ReducerActions.DECREASE_DAY:
            if(state.day === 1){
                if(state.month === 1){
                    return {day: getDaysOfMonth(state.year - 1, 12).length, month: 12, year: state.year - 1}
                }
                return { day: getDaysOfMonth(state.year, state.month - 1).length, month: state.month - 1, year: state.year}
            }
            return {...state, day:state.day - 1}
    
        case ReducerActions.INCREASE_WEEK:
            const daysRemaining = getDaysOfMonth(state.year, state.month).length - state.day;
            if(daysRemaining < 7){
                if(state.month === 12){
                    return {day: 7 - daysRemaining, month: 1, year: state.year + 1}
                }
                return {...state, day: 7 - daysRemaining, month: state.month + 1}
            }
            return {...state, day: state.day + 7}

        case ReducerActions.DECREASE_WEEK:
            const daysExtra = state.day - 7;
            if(daysExtra >  0){
                return {...state, day: daysExtra}
            }
            if(state.month === 1){
                return {day: getDaysOfMonth(state.year - 1, 12).length + daysExtra, month: 12, year: state.year - 1 }
            }
            return {...state, day: getDaysOfMonth(state.year, state.month - 1).length + daysExtra , month: state.month - 1}

        case ReducerActions.INCREASE_MONTH:
            if(state.month === 12){
                return {day: 1, month: 1, year: state.year + 1}
            }
            return {...state, day: 1, month: state.month + 1}
        
        case ReducerActions.DECREASE_MONTH:
            if(state.month === 1){
                return {day: 1, month: 12, year: state.year - 1}
            }
            return {...state, day: 1, month: state.month - 1}

        case ReducerActions.TODAY:
            return reducerToday;

        case ReducerActions.SET_DAY: 
            return {...state, day: action.value}

        case ReducerActions.SET_MONTH: 
            return {...state, month: action.value}

        case ReducerActions.SET_YEAR: 
            return {...state, year: action.value}

        default:
            return state;
    }
}

const Calendar = () => {
    const {loading, setLoading} = useContext(LoaderContext)
    const [activeTab, setActiveTab] = useState(CalendarTab.MONTH)
    const [selectedDate, selectedDateDispatch] = useReducer(reducer, reducerToday);
    const [calendarData, setCalendarData] = useState({});
    const [nextMonthData, setNextMonthData] = useState({});
    const [prevMonthData, setPrevMonthData] = useState({});
    const [customEvent, setCustomEvent] = useState(null);
    const [userAccess, setUserAccess] = useState([]);
    const [customEventForDelete, setCustomEventForDelete] = useState(false);
    const [selectedItems, setSelectedItems] = useState(null);
    const [eventDate, setEventDate] = useState();
    const [eventData, setEventData] = useState();
    const dispatch = useDispatch();
    const {showMessage} = useContext(SnackbarContext);
    const [page, setPage] = useState(PageState.View);
    const [drawerTitle, setDrawerTitle] = useState('');
    const value = {drawerTitle, setDrawerTitle};
    const form = useForm({});
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const formRules = {
        'name': {required: true},
        'description': {required: true},
    }
    const isDrawerOpen = () => page !== PageState.View;

    useEffect(() => {
        dispatch(changeBreadcrumbs({
            title: strings.components.calendar.pageTitle,
            hierarchy:[
                {label: strings.navigation.homeTag},
                {label: strings.components.calendar.pageTitle}
            ],        
        }));
        fetch();
    }, []);

    useEffect(() => {
        fetch();
    }, [selectedDate.month, selectedDate.year]);

    useEffect(() =>{
        fetch();
        deleteCustomEvent();

        },[customEventForDelete]);

    const fetch = async () => {
        setLoading(true);
        const[prevMonth, currentMonth, nextMonth] = await Promise.all([
            getPrevMonthData(),
            getCurrentMonthData(),
            getNextMonthData(),

    ])
        setLoading(false);
        setCalendarData(currentMonth.data);
        setPrevMonthData(prevMonth.data);
        setNextMonthData(nextMonth.data);
    }

    const getCurrentMonthData = () =>{
        return getCalendarData({
            year: selectedDate.year,
            month: selectedDate.month
        })
    }

    const getNextMonthData = () =>{
        const targetMonth = selectedDate.month === 12 ? 1 : selectedDate.month + 1;
        const targetYear = targetMonth === 1? selectedDate.year + 1 : selectedDate.year;

        return getCalendarData({
            year: targetYear,
            month: targetMonth
        })
    }

    const getPrevMonthData = () =>{
        const targetMonth = selectedDate.month === 1 ? 12 : selectedDate.month - 1;
        const targetYear = targetMonth === 12? selectedDate.year - 1 : selectedDate.year;

        return getCalendarData({
            year: targetYear,
            month: targetMonth
        })
    }

    const goLeft = () => {
        if(CalendarTab.DAY === activeTab){
            selectedDateDispatch({type: ReducerActions.DECREASE_DAY})
            return;
        }
        if(CalendarTab.WEEK === activeTab){
            selectedDateDispatch({type: ReducerActions.DECREASE_WEEK})
            return;
        }
        if(CalendarTab.MONTH === activeTab){
            selectedDateDispatch({type: ReducerActions.DECREASE_MONTH})
            return;
        }       
    }

    const goRight = () => {
        if(CalendarTab.DAY === activeTab){
            selectedDateDispatch({type: ReducerActions.INCREASE_DAY})
            return;
        }
        if(CalendarTab.WEEK === activeTab){
            selectedDateDispatch({type: ReducerActions.INCREASE_WEEK})
            return;
        }
        if(CalendarTab.MONTH === activeTab){
            selectedDateDispatch({type: ReducerActions.INCREASE_MONTH})
            return;
        }       
    }

    const today = () => {
        selectedDateDispatch({type: ReducerActions.TODAY})
    }

    const generateSelectedDateString = () =>{
        const date = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day);

        if(activeTab === CalendarTab.MONTH || activeTab === CalendarTab.WEEK){
            return dateToString(date, {formatString: "MMMM yyyy"} )
        }
        return dateToString(date, {formatString: "dd MMMM yyyy"});
    }

    const handleDeleteDialogResult = (result, payload) => {
        if (result === YesNoDialogResult.YES) {
            if(customEventForDelete){
                deleteEvent(customEventForDelete.id).then(response => {

                    if (!response || !response.ok){
                        showMessage(strings.commonMessages.errorDeleting, 'error');
                        return;
                    }
                    showMessage(strings.components.tablePage.itemDeleted, 'success');
                    setCustomEventForDelete(null);
                    setPage(pageState.View);
                });

            }
        };

        setShowDeleteDialog(false);
    }

    const deleteCustomEvent = () => {
        if(customEventForDelete){
            setShowDeleteDialog(true);
        }
    }
    const calendarEvent = (date, data) => {
        if (date === null || data === null) {
            return;
        }
        setEventDate(date);
        setEventData(data);
        setPage(PageState.ViewDetails)
    }

    const renderCalendarByDay = () =>{
        return <CalendarByDay key={getUniqueEventKey(calendarData)}
                              date={selectedDate}
                              calendarEvent={calendarEvent}
                              data={calendarData} />
    }

    const renderCalendarByWeek = () =>{
        return <CalendarByWeek  key={getUniqueEventKey(calendarData)}
                                date={selectedDate}
                                calendarEvent={calendarEvent}
                                data = {calendarData}
                                nextMonthData = {nextMonthData}
                                prevMonthData = {prevMonthData}
                                today = {reducerToday}/>
    }

    const renderCalendarByMonth = () =>{
        return <CalendarByMonth date={selectedDate}
                                onDayClick={calendarEvent}
                                data={calendarData} />
    }

    const onFinish = () => {
        fetch();
        setPage(pageState.View);
    }

    return <div>
            <YesNoDialog show={showDeleteDialog}
                         payload={customEventForDelete}
                         handleResult={handleDeleteDialogResult}
                         title={strings.components.tablePage.confirmDelete}
                         text={strings.components.tablePage.confirmDeleteEventMessage}/>
            <EventContext.Provider value={{customEvent,setCustomEvent, customEventForDelete,setCustomEventForDelete}}>
                    <DrawerContext.Provider value={value}>
                        <div id="calendar">

                            <Paper className={'main-paper'}>
                                <div className='header'>
                                    <div className='tab-container mt-4'>
                                        <Tabs value={ activeTab } onChange={(e, tab) => {setActiveTab(tab)}}>
                                            <Tab className="day-tab" label={strings.components.calendar.day}/>
                                            <Tab className="week-tab" label={strings.components.calendar.week}/>
                                            <Tab className="month-tab" label={strings.components.calendar.month}/>
                                        </Tabs>
                                    </div>
                                    <div className="date-control">
                                        <img className="left control" src={'/images/date-select-left.svg'} onClick={goLeft} />
                                        <div className='label' >
                                            {generateSelectedDateString()}
                                        </div>
                                        <img className="right control" src={'/images/date-select-right.svg'} onClick={goRight} />
                                    </div>
                                    <IconButton className='add-button' onClick={() => setPage(PageState.Add)}>
                                        <img src='/images/table-page/add-cross-blue.svg'/>
                                        {strings.components.tablePage.addButton.addEvent}
                                    </IconButton>
                                    <div className='today' onClick={() => today()}>
                                        { strings.components.calendar.today }
                                    </div>
                                </div>

                                {activeTab === CalendarTab.DAY && renderCalendarByDay()}

                                {activeTab === CalendarTab.WEEK && renderCalendarByWeek()}

                                {activeTab === CalendarTab.MONTH && renderCalendarByMonth()}

                            </Paper>
                            <Drawer id='drawer' anchor='right' open={isDrawerOpen()} onClose={() => setPage(PageState.View)}>
                                    <DrawerWrapper onBack={() => setPage(PageState.View)} addButton={page === PageState.ViewDetails} buttonText={strings.components.tablePage.addButton.addEvent} onClick={() => setPage(PageState.Add)} title={drawerTitle}>
                                        {
                                            (!customEvent && page === PageState.ViewDetails) &&
                                            <CalendarEventList calendarData={eventData}
                                                               date={eventDate}
                                            />
                                        }
                                        {
                                            (!customEvent && page === PageState.Add) && <AddEvent onFinish={() => onFinish()} setPage={setPage}/>
                                        }

                                        {
                                            customEvent &&
                                            <EditCustomEvent data={customEvent} userAccess={userAccess} setPage={setPage} onFinish={() => onFinish()}/>
                                        }
                                        {
                                            customEventForDelete && deleteCustomEvent
                                        }
                                    </DrawerWrapper>
                            </Drawer>
                        </div>
                    </DrawerContext.Provider>
            </EventContext.Provider>
    </div>

}

 export default Calendar;