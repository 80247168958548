import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Grid, Paper, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import TaskDeatilsTab from "../../../Constants/TaskManagement/TaskDetailsTab";
import strings from "../../../localization";
import DocumentList from "../../Document/Document/DocumentList";

const TaskTemplateDetails = () => {
    const [activeTab, setActiveTab] = useState(TaskDeatilsTab.FILES);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if(!location.state.taskTemplate){
            navigate('/');
        }
    },[]);

    return <div id='task-template-details-page'>
        {
            <Paper className='paper'>
                <Grid container>
                    <Grid item xs={6}>
                        <div className='base-info'>
                            <h1>{location.state.taskTemplate.name}</h1>
                            <h2>{location.state.taskTemplate.pipeline?.name}</h2>
                            <p>{location.state.taskTemplate.pipelineStage?.name}</p>
                        </div>
                    </Grid>
                </Grid>

                <CKEditor
                    disabled={true}
                    editor={ DecoupledEditor }
                    data={location.state.taskTemplate.description}
                />
                </Paper>
        }
        <div className='tab-container'>
            <Paper className='paper'>
                <Tabs variant="scrollable" value={activeTab} onChange={(e, tab) => setActiveTab(tab)}>
                    <Tab label={strings.pages.taskManagement.taskTemplates.taskTemplateDetails.files}/>
                </Tabs>
            </Paper>
        </div>
        {
            activeTab === TaskDeatilsTab.FILES &&
            <DocumentList referenceType={ReferenceType.TASK_TEMPLATE} id={location.state.taskTemplate.id}/>
        }
        </div>
}

export default TaskTemplateDetails;