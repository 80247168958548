


import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import ScheduleCheckForm from "../../../Components/Forms/Pages/CheckList/ScheduleCheck/ScheduleCheckForm";
import DrawerContext from "../../../Context/DrawerContext";
import { editCheckListScheduledCheck} from "../../../Services/CheckList/CheckListScheduledCheckService";
import LinearProgress from '@mui/material/LinearProgress';
import { getUnits, getCurrencies, getTaxRatesSelectOptions } from '../../../Util/SiteDataUtil';
import SnackbarContext from "../../../Context/SnackbarContext";
import {getEmployees} from "../../../Services/User/UserService";
import {getPartners} from "../../../Services/CRM/PartnerService";
import {getCheckListTemplates} from "../../../Services/CheckList/CheckListTemplateService";
import {getAssetManagementLocations} from "../../../Services/AssetManagement/AssetManagementLocationService";


const formRules = {
    'name': {required: true},
    'dateTo': {required: true},
    'dateFrom': {required: true},
    'location': {required: true},
    'partner': {required: true},
    'template': {required: true},
}

const EditScheduledCheck = (props) => {

    const siteData = useSelector((state) => state.siteData)
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, control, watch, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [partners, setPartners] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [locations, setLocations] = useState([]);




    useEffect(() => {
        setDrawerTitle(strings.pages.checkList.scheduleCheck.pageTitle);
    });

    useEffect( () => {
        fetchEmployees();
        fetchPartners();
        fetchTemplates();
        fetchLocations();
    },[])

    const fetchEmployees = (term = '') => {
        getEmployees({term}).then(response => {
            setEmployees(response.data.result.map(u => u.user));
        })
    }

    const fetchPartners = (term = '') => {
        getPartners({term}).then(response => {
            setPartners(response.data.result);
        })
    }

    const fetchTemplates = (term = '') => {
        getCheckListTemplates({term}).then(response => {
            setTemplates(response.data.result);
        })
    }

    const fetchLocations = (term = '') => {
        getAssetManagementLocations({term}).then(response => {
            setLocations(response.data.result);
        })
    }


    const onSubmit = (data) => {

        setShowLoader(true);

        editCheckListScheduledCheck(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish(response.data.entity);
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <ScheduleCheckForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                errors={errors} data={data} form={form} control={control}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}
                partners={partners}
                employees={employees}
                templates={templates}
                locations={locations}/>
        </Paper>
    </div>
}

export default EditScheduledCheck;