import { cloneDeep } from 'lodash';


//https://stackoverflow.com/questions/11311410/visualization-of-calendar-events-algorithm-to-layout-events-with-maximum-width
export const organizeEntries = (inputEntries, bufferMinutes = 10) => {
    const bufferTime = bufferMinutes * 60 * 1000;

    const collision = (e1, e2) => {
        return new Date(e1.endDate).getTime() >= new Date(e2.startDate).getTime() - bufferTime && new Date(e1.startDate).getTime() <= new Date(e2.endDate).getTime() + bufferTime;
    }

    const packEntries = (columns, blockWidth) => {

        for (let i = 0; i < columns.length; i++) {
    
            let column = columns[i]
    
            for (let j = 0; j < column.length; j++) {
                
                let entry = column[j];
                let columnSpan = expandEntry(entry, i, columns)
                entry.left = (i * 100) / columns.length; 
                entry.width = blockWidth * (columnSpan / columns.length) - 0.3;
            }
            
        }
    }
    
    const expandEntry = (entry, iColumn, columns) =>{
        let columnSpan = 1;
    
        for (let i = iColumn + 1; i < columns.length; i++) {
    
            let column = columns[i];
    
            for (let j = 0; j < column.length; j++) {
    
                if(collision(entry, column[j])){
                    return columnSpan;
                }
            }
    
            columnSpan++;
        }
    
        return columnSpan;
    }

    let blockWidth = 100;
    const entries = cloneDeep(inputEntries)
    let columns = [];
    let lastEntryEnding = null;

    entries.sort((a, b) => {
        if(new Date(a.startDate).getTime() < new Date(b.startDate).getTime()) {return -1;}
        if(new Date(a.startDate).getTime() > new Date(b.startDate).getTime()) {return 1;}

        if(new Date(a.endDate).getTime() < new Date(b.endDate).getTime()) {return -1;}
        if(new Date(a.endDate).getTime() > new Date(b.endDate).getTime()) {return 1;}

        return 0;
    });

    entries.forEach(entry => {
        if(lastEntryEnding !== null && new Date(entry.startDate).getTime() - bufferTime >= lastEntryEnding){
            packEntries(columns, blockWidth);
            columns = [];
            lastEntryEnding = null;
        }

        let placed = false;
        for (let i = 0; i < columns.length; i++) {

            let column = columns[i];

            if(!collision(column[column.length - 1], entry)){
                column.push(entry);
                placed = true;
                break;
            }        
        }

        if(!placed){
            columns.push([entry]);
        }

        if(lastEntryEnding === null || new Date(entry.endDate).getTime() - bufferTime > lastEntryEnding)
        {
            lastEntryEnding = new Date(entry?.endDate)?.getTime();
        }
    });

    if(columns.length > 0){
        packEntries(columns, blockWidth);
    }  

    return entries;
}

