import strings from "../../localization";

export const LinkedIssueTypeNames = {
    1: strings.constants.workspaces.linkedIssueType.isBlockedBy,
    2: strings.constants.workspaces.linkedIssueType.blocks,
    3: strings.constants.workspaces.linkedIssueType.isClonedBy,
    4: strings.constants.workspaces.linkedIssueType.clones,
    5: strings.constants.workspaces.linkedIssueType.isDuplicatedBy,
    6: strings.constants.workspaces.linkedIssueType.duplicates,
    7: strings.constants.workspaces.linkedIssueType.relatesTo,
}

export const LinkedIssueTypeValues = {
    'IS_BLOCKED_BY': 1,
    'BLOCKS': 2,
    'IS_CLONED_BY': 3,
    'CLONES': 4,
    'IS_DUPLICATED_BY': 5,
    'DUPLICATES': 6,
    'RELATES_TO': 7,
}

export function getLinkedIssuesRelations(type) {
    switch (type) {
        case LinkedIssueTypeValues.IS_BLOCKED_BY:
            return { primaryLinkedIssueType: LinkedIssueTypeValues.IS_BLOCKED_BY, foreignLinkedIssueType: LinkedIssueTypeValues.BLOCKS };
        case LinkedIssueTypeValues.BLOCKS:
            return { primaryLinkedIssueType: LinkedIssueTypeValues.BLOCKS, foreignLinkedIssueType: LinkedIssueTypeValues.IS_BLOCKED_BY };
        case LinkedIssueTypeValues.IS_CLONED_BY:
            return { primaryLinkedIssueType: LinkedIssueTypeValues.IS_CLONED_BY, foreignLinkedIssueType: LinkedIssueTypeValues.CLONES };
        case LinkedIssueTypeValues.CLONES:
            return { primaryLinkedIssueType: LinkedIssueTypeValues.CLONES, foreignLinkedIssueType: LinkedIssueTypeValues.IS_CLONED_BY };
        case LinkedIssueTypeValues.IS_DUPLICATED_BY:
            return { primaryLinkedIssueType: LinkedIssueTypeValues.IS_DUPLICATED_BY, foreignLinkedIssueType: LinkedIssueTypeValues.DUPLICATES };
        case LinkedIssueTypeValues.DUPLICATES:
            return { primaryLinkedIssueType: LinkedIssueTypeValues.DUPLICATES, foreignLinkedIssueType: LinkedIssueTypeValues.IS_DUPLICATED_BY };
        default:
            return { primaryLinkedIssueType: LinkedIssueTypeValues.RELATES_TO, foreignLinkedIssueType: LinkedIssueTypeValues.RELATES_TO };
    }
}

export function formatLinkedIssueTypeName(type) {
    const typeName = LinkedIssueTypeNames[type];
    return typeName;
}

export const LinkedIssueTypes = [
    {
        name: formatLinkedIssueTypeName(LinkedIssueTypeValues.IS_BLOCKED_BY),
        type: LinkedIssueTypeValues.IS_BLOCKED_BY,
    },
    {
        name: formatLinkedIssueTypeName(LinkedIssueTypeValues.BLOCKS),
        type: LinkedIssueTypeValues.BLOCKS,
    },
    {
        name: formatLinkedIssueTypeName(LinkedIssueTypeValues.IS_CLONED_BY),
        type: LinkedIssueTypeValues.IS_CLONED_BY,
    },
    {
        name: formatLinkedIssueTypeName(LinkedIssueTypeValues.CLONES),
        type: LinkedIssueTypeValues.CLONES,
    },
    {
        name: formatLinkedIssueTypeName(LinkedIssueTypeValues.IS_DUPLICATED_BY),
        type: LinkedIssueTypeValues.IS_DUPLICATED_BY,
    },
    {
        name: formatLinkedIssueTypeName(LinkedIssueTypeValues.DUPLICATES),
        type: LinkedIssueTypeValues.DUPLICATES,
    },
    {
        name: formatLinkedIssueTypeName(LinkedIssueTypeValues.RELATES_TO),
        type: LinkedIssueTypeValues.RELATES_TO,
    },
];