import HttpMethod from "../../Constants/Base/HttpMethod";
import { request } from "../../Base/HTTP";
import { dateToString } from "../../Util/DateUtil";

export async function resetPasswordRequest(data) {
  return await request("/api/users/reset-password", data, HttpMethod.POST);
}

export async function registration(data) {
  return await request("/api/users", data, HttpMethod.POST);
}

export async function resendEmail(data) {
  return await request("/api/users/resend-email", data, HttpMethod.POST);
}

export async function activateUser(token) {
  return await request("/api/users/activate/" + token, {}, HttpMethod.PATCH);
}

export async function editUser(data) {
  return await request("/api/users", data, HttpMethod.PUT);
}

export async function changeUserPassword(data) {
  return await request("/api/users/change-password", data, HttpMethod.PUT);
}

export async function confirmUserPassword(data) {
  return await request(
    "/api/users/reset-password-confirm",
    data,
    HttpMethod.POST
  );
}

export async function getUser(id) {
  return await request("/api/managment-users/" + id);
}

export async function getEmployees(data) {
  return await request("/api/users/employees", data);
}

export async function getEmployeeByUser(userId) {
  return await request("/api/users/employees-user/" + userId);
}

export async function getAllEmployees() {
  return await request("/api/users/employees/all");
}

export async function editEmployee(data) {
  return await request(
    "/api/users/employees/" + data.id,
    transfromEmployeeData(data),
    HttpMethod.PUT
  );
}

export async function getUsers(data) {
  return await request("/api/managment-users", data);
}

export async function addUser(data) {
  return await request(
    "/api/managment-users",
    transformRequestData(data),
    HttpMethod.POST
  );
}

export async function editUserManagment(data) {
  return await request(
    "/api/managment-users/" + data.id,
    transformRequestData(data),
    HttpMethod.PUT
  );
}

export async function deleteUser(id) {
  return await request("/api/managment-users/" + id, {}, HttpMethod.DELETE);
}

export async function switchCompany(id) {
  return await request("/api/users/switch/" + id, {}, HttpMethod.PATCH);
}

export async function changeSignature(data) {
  return await request("/api/users/signature", data, HttpMethod.PATCH);
}

export async function changeUserProfileImage(data) {
  return await request("/api/users/profile-image", data, HttpMethod.PATCH);
}

export async function getUserProfileImage(id, data = {}) {
  return await request(`/api/users/profile-image/${id}`, data);
}

export async function getEmployeeStatistic(id, year) {
  return await request("/api/users/statistic/" + id + "/" + year);
}

export async function getEmployeeMinutesPerDay(id, data) {
  return await request("/api/users/minutes-per-day/" + id, data);
}

export async function inviteUser(id) {
  return await request(`/api/managment-users/${id}/invite`, {}, HttpMethod.PUT);
}

export async function changeUserPreference(data) {
  return await request("/api/users/preference", data, HttpMethod.POST);
}

function transformRequestData(data) {
  return {
    ...data,
    userPositionId: data.userPosition ? data.userPosition.id : null,
    teamId: data.team ? data.team.id : undefined,
    candidateId: data.candidate ? data.candidate.id : undefined,
  };
}

function transfromEmployeeData(data) {
  return {
    ...data,
    employeeStatus: data.employeeStatus ? data.employeeStatus.id : null,
    userPositionId: data.userPosition ? data.userPosition.id : null,
    teamId: data.team ? data.team.id : undefined,
  };
}
