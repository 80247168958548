import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";


export async function getCheckListCategories(parent) {
    return await request('/api/check-list-categories', transformParentRequest(parent));
}

export async function getAllCheckListCategories(data) {
    return await request('/api/check-list-categories/all', data);
}

export async function addCheckListCategory(data) {
    return await request('/api/check-list-categories', transformRequestData(data), HttpMethod.POST);
}

export async function editCheckListCategory(data) {
    return await request('/api/check-list-categories/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteCheckListCategory(id) {
    return await request('/api/check-list-categories/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        parentId: data.parent ? data.parent.id : null
    }
}

function transformParentRequest(parent){
    return {
        parent: parent ? parent : null
    }
}