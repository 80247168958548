import React from "react";
import {Paper, Tab, Tabs} from "@mui/material";
import strings from "../../../localization";
import {HRSettingsTab} from "../../../Constants/HR/HRSettingsTab";
import UserPositionList from "../UserPosition/UserPositionList";
import JobStatusList from "../JobStatus/JobStatusList";
import JobContractTypeList from "../JobContractType/JobContractTypeList";
import CandidateSourceList from "../CandidateSource/CandidateSourceList";
import LeaveTypeList from "../LeaveType/LeaveTypeList";
import NonWorkingDaysList from "../NonWorkingDays/NonWorkingDaysList";
import CandidateStatusList from "../CandidateStatus/CandidateStatusList";
import {useSelector} from "react-redux";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {hasPermission} from "../../../Util/PermissionUtil";
import HRBaseSettings from "./HRBaseSettings";
import { useNavigate } from "react-router-dom";
import BusinessTrip from "./BusinessTrip";


const HRSettings = ({activeTab = HRSettingsTab.BASE_SETTINGS}) => {
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const changeActiveTab = (tab) =>{
        if(tab === HRSettingsTab.BASE_SETTINGS){
            navigate(`/hr-settings`)
        }
        if(tab === HRSettingsTab.USER_POSITION){
            navigate('/hr-settings/user-position')
        }
        if(tab === HRSettingsTab.JOB_STATUS){
            navigate('/hr-settings/job-statuses')
        }
        if(tab === HRSettingsTab.JOB_CONTRACT_TYPE){
            navigate('/hr-settings/job-contract-types')
        }
        if(tab === HRSettingsTab.CANDIDATE_SOURCE){
            navigate('/hr-settings/candidate-sources')
        }
        if(tab === HRSettingsTab.LEAVE_TYPE){
            navigate('/hr-settings/leave-types')
        }
        if(tab === HRSettingsTab.NON_WORKING_DAY){
            navigate('/hr-settings/non-working-days')
        }
        if(tab === HRSettingsTab.CANDIDATE_STATUS){
            navigate('/hr-settings/candidate-statuses')
        }
        if(tab === HRSettingsTab.BUSINESS_TRIP){
            navigate('/hr-settings/business-trip')
        }
        
    }

    return (
        <div id="hr-settings">
            <div className='tab-container mb-12 mx-3'>
                <Paper className='paper'>
                    <Tabs variant="scrollable" value={activeTab} onChange={(e, tab) => changeActiveTab(tab)}>
                        <Tab label={strings.pages.hr.settings.baseSettings}/>
                        <Tab label={strings.pages.hr.settings.userPosition}
                             disabled={!hasPermission(auth.user, AppPermissions.USER_POSITION.GROUP,
                                 AppPermissions.USER_POSITION.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.hr.settings.jobStatus}
                             disabled={!hasPermission(auth.user, AppPermissions.JOB_STATUS.GROUP,
                                 AppPermissions.JOB_STATUS.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.hr.settings.JobContractType}
                             disabled={!hasPermission(auth.user, AppPermissions.JOB_CONTRACT_TYPE.GROUP,
                                 AppPermissions.JOB_CONTRACT_TYPE.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.hr.settings.candidateSource}
                             disabled={!hasPermission(auth.user, AppPermissions.CANDIDATE_SOURCE.GROUP,
                                 AppPermissions.CANDIDATE_SOURCE.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.hr.settings.leaveType}
                             disabled={!hasPermission(auth.user, AppPermissions.LEAVE_TYPE.GROUP,
                                 AppPermissions.LEAVE_TYPE.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.hr.settings.nonWorkingDays}
                             disabled={!hasPermission(auth.user, AppPermissions.NON_WORKING_DAYS.GROUP,
                                 AppPermissions.NON_WORKING_DAYS.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.hr.settings.candidateStatus}
                             disabled={!hasPermission(auth.user, AppPermissions.CANDIDATE_STATUS.GROUP,
                                 AppPermissions.CANDIDATE_STATUS.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.hr.settings.businessTrip}
                             disabled={!hasPermission(auth.user, AppPermissions.BUSINESS_TRIP.GROUP,
                                 AppPermissions.BUSINESS_TRIP.VIEW_LIST, auth.permissions)}
                        />
                    </Tabs>
                </Paper>
            </div>

            {
                activeTab === HRSettingsTab.BASE_SETTINGS &&
                <HRBaseSettings/>
            }

            {
                activeTab === HRSettingsTab.USER_POSITION &&
                <UserPositionList/>
            }
            {
                activeTab === HRSettingsTab.JOB_STATUS &&
                <JobStatusList/>
            }
            {
                activeTab === HRSettingsTab.JOB_CONTRACT_TYPE &&
                <JobContractTypeList/>
            }
            {
                activeTab === HRSettingsTab.CANDIDATE_SOURCE &&
                <CandidateSourceList/>
            }
            {
                activeTab === HRSettingsTab.LEAVE_TYPE &&
                <LeaveTypeList/>
            }
            {
                activeTab === HRSettingsTab.NON_WORKING_DAY &&
                <NonWorkingDaysList/>
            }
            {
                activeTab === HRSettingsTab.CANDIDATE_STATUS &&
                <CandidateStatusList/>
            }
            {
                activeTab === HRSettingsTab.BUSINESS_TRIP &&
                <BusinessTrip/>
            }
        </div>
    );
}

export default HRSettings;
