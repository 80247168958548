import React, { useContext} from "react";
import {useEffect, useState} from "react";
import {Paper} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import AutoCompleteControl from "../../../Components/Controls/Inputs/AutoCompleteControl";
import strings from "../../../localization";
import Button from "@mui/material/Button";
import DrawerContext from "../../../Context/DrawerContext";
import PageState from "../../../Constants/Base/PageState";
import pageState from "../../../Constants/Base/PageState";

const GenerateInvoiceFromContract = (props) => {

    const [showLoader, setShowLoader] = useState(false);
    const {setDrawerTitle} = useContext(DrawerContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    useEffect(() => {
        setDrawerTitle(strings.pages.finance.contract.generateInvoice.pageTitle);
    });

    const getPartsForContract = (id) =>{
         return props.parts?.filter(x => x.contract.id === id)
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
                <AutoCompleteControl
                    name='contractPart'
                    value={props.value}
                    setValue={props.setValue}
                    label={strings.forms.finance.contract.contractForm.contractPart}
                    options={getPartsForContract(props.id)}
                    getOptionLabel={(option) => option.description}
                    nameKey={'description'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={ props.onSubmit }>
                        { strings.forms.common.save }
                    </Button>
                    <Button variant="contained" color="secondary" onClick={ props.onCancel }>
                        { strings.forms.common.cancel }
                    </Button>
                </div>
        </Paper>
    </div>
}

export default GenerateInvoiceFromContract;
