import React, {useState, useEffect} from 'react';
import { Grid } from '@mui/material';
import { Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import {getDocuments} from "../../../Services/DocumentManagement/DocumentService";
import {getProductImage} from "../../../Services/Product/ProductServiceService";
import {renderNumberWithLabel} from "../../../Util/RenderDataUtil";

const ProductCard = (props) => {
    const [link, setLink] = useState("")

    useEffect(() => {
        getLink(props.product.id).then(result => {
            setLink(result)
            }
        )
    }, [])

    const getLink = async (referenceId) => {
        const response = await getDocuments({referenceType: 8, referenceId: referenceId})
        return await getProductImage(response.data.result[0])
    }

    const renderPrice = () => {
        if(props.product.currency.id === 178){
            return renderNumberWithLabel(parseFloat(props.product.price), 'RSD: ')
        }
        if(props.product.currency.id === 23){
            return renderNumberWithLabel(parseFloat(props.product.price), 'USD: ')
        }
        if(props.product.currency.id === 122){
            return renderNumberWithLabel(parseFloat(props.product.price), 'EUR: ')
        }
    }

    return(
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Card className="card" sx={{ maxWidth: 345, minHeight: 320 }}>
                <CardMedia
                    sx={{ height: 180 }}
                    image={link}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {props.product.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {
                            renderPrice()
                        }
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default ProductCard;