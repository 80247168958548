import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";
import { saveAs } from 'file-saver';

export async function addSpace(data) {
    return await request('/api/space', transformData(data), HttpMethod.POST);
}

export async function editSpace(data) {
    return await request('/api/space/' + data.id, data, HttpMethod.PUT);
}

export async function deleteSpace(id) {
    return await request('/api/space/' + id, {}, HttpMethod.DELETE);
}

export async function getSpaces(data) {
    return await request('/api/spaces', data);
}

export async function getSpaceByPublicLink(publicLink) {
    return await request('/api/spaces/byPublicLink/' + publicLink);
}

export function downloadSpacePDF(id) {
    request('/api/space/download/' + id, {}, HttpMethod.GET, {responseType: 'blob'}).then(res => {
        const newBlob = new Blob([res.data], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(newBlob);
        saveAs(downloadUrl, res.headers['file-name'])
    });
}

function transformData(data) {
    return {
        ...data,
        parentId: data.parent.id ? data.parent.id : null,
        description: data.description ? data.description : null
    }
}