import React, {useEffect} from 'react';
import {Paper, Modal} from "@mui/material";
import { useForm } from "react-hook-form";
import strings from "../../../localization";
import AddQuestionOptionForm
    from "../../../Components/Forms/Pages/CompanyDocument/CompanyDocumentTemplateQuestion/AddQuestionOptionForm";

const formRules = {
    'option': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
};

const AddQuestionOptionModal = ({showModal, closeModal, submit, question}) => {

    const form = useForm();

    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;

    const submitHandler = (data, e) => {
        e.preventDefault();
        const requestData = {questionId: question?.id, ...data}
        submit(requestData);
        form.reset()
    }

    const handleCloseModal = (prop) => {
        closeModal(prop)
        form.reset()
    }

    return(
        <Modal
            open={Boolean(showModal)}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            id='add-edit-question-option-modal'>
            <Paper>
                <img className='add-edit-question-modal-close' src='/images/close-issue-view.svg' onClick = {handleCloseModal} />
                <div className='add-edit-question-modal-title'>
                    {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateQuestion.addQuestionOption}
                </div>
                <AddQuestionOptionForm
                    control={control}
                    data={data}
                    formRules={formRules}
                    values={getValues()}
                    setValue={setValue}
                    errors={errors} form={form}
                    onSubmit={handleSubmit(submitHandler)}/>
            </Paper>
        </Modal>
    );
}


export default AddQuestionOptionModal;