export const ResourceTypeValues = {
    Cost: 1,
    Income: 2,
}

export const ResourceTypeNames = {
    1: 'Cost',
    2: 'Income',
}

export const ResourceTypes = [
    {
        name: 'Income',
        type: ResourceTypeValues.Income 
    },
    {
        name: 'Cost',
        type: ResourceTypeValues.Cost
    },
]
