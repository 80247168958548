import React from 'react';
import Button from "@mui/material/Button";
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import { useDispatch } from 'react-redux';

const ChangePasswordForm = ({
                              form,
                              onSubmit,
                              values,
                              setValue,
                              errors,
                              data,
                              keyPress
                          }) => {                 
    return <FormProvider {...form}>
        <form id='change-password-form' className='form px-12' action="#">
            <TextFieldControl
                className='mb-8 mt-18 mui-shifted-label-input'
                name='oldPassword'
                type={'password'}
                control={data}
                error={Boolean(errors.oldPassword)}
                helperText={errors.oldPassword && strings.forms.common.thisFieldIsRequired}
                label={strings.changePasswordForm.oldPassword}
                onKeyPress={keyPress}
            />

            <TextFieldControl
                className='mb-8 mui-shifted-label-input'
                name='newPassword'
                type={'password'}
                control={data}
                error={Boolean(errors.newPassword)}
                helperText={errors.newPassword && strings.forms.common.thisFieldIsRequired}
                label={strings.changePasswordForm.newPassword}
                onKeyPress={keyPress}
            />

            <TextFieldControl
                className='mb-8 mui-shifted-label-input'
                name='newPasswordRepeat'
                type={'password'}
                control={data}
                error={Boolean(errors.newPasswordRepeat)}
                helperText={errors.newPasswordRepeat && strings.forms.common.thisFieldIsRequired}
                label={strings.changePasswordForm.newPasswordRepeat}
                onKeyPress={keyPress}
            />

            <div className='submit-container'>
                <Button variant="contained" color="primary" className='mb-8' onClick={onSubmit}>
                    {strings.changePasswordForm.changePassword}
                </Button>
            </div>
        </form>
    </FormProvider>
                          };

export default ChangePasswordForm;