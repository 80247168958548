import React, {useEffect, useState, useRef} from 'react';
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import strings from "../../../../../localization";
import {getInvoiceTypes} from "../../../../../Constants/Finance/InvoiceType";
import {FormProvider} from "react-hook-form";
import PageState from "../../../../../Constants/Base/PageState";
import { addDays } from 'date-fns';
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";
import { fetchExchangeRate } from "../../../../../Services/Finance/CurrencyService";
import { useSelector } from 'react-redux';
import { IconButton } from "@mui/material";
import ReferenceType from "../../../../../Constants/DocumentManagement/ReferenceType";

const getAccount = (account, partner, accounts) => {

    if (!partner) {
        return account;
    }

    for (let acc of accounts) {
        if (partner.currency.code === acc.currency.code) {

            return acc;
        }
    }

    return account;
}

const formRules = {
    'invoiceType': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
}

const checkItems = (items) => {
    const result = items?.filter((item) => !item.deleted);

    return result?.length > 0;
}

const InvoiceHeaderForm = ({
                               control,
                               data,
                               partners,
                               accounts,
                               isInoInvoice,
                               contracts,
                               pageState,
                               contractParts,
                               projects,
                               values,
                               setValue,
                               form,
                               deadline,
                               companyCurrencyCode,
                               country,
                               refresh,
                               onChangePartnerFilter,
                               onChangeAccountFilter,
                               onChangeContractFilter,
                               onChangeContractPartFilter,
                               onChangeProjectFilter,
                           }) => {

    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const [selectedContract, setSelectedContract] = useState(values['contract']);
    const [selectedContractPart, setSelectedContractPart] = useState(values['contractPart']);
    const [selectedPartner, setSelectedPartner] = useState(values['partner']);
    const [invoiceCurrencyCode, setInvoiceCurrencyCode] = useState('');
    const checkExchangeRate = useRef(false);
    const siteData = useSelector((state) => state.siteData);
     
    useEffect(() => {
        setValue('paymentDueDate', addDays(values['invoiceDate'], deadline) )
    }, [values['invoiceDate']])

    useEffect(() => {
        setSelectedContractPart(values['contractPart'])
    }, [values['contractPart']])

    useEffect(() => {
        setSelectedContract(values['contract'])
        onChangeContractPartFilter();
    }, [values['contract']])

    useEffect(() => {
        setSelectedPartner(values['partner']);
        if(!isInoInvoice){
            setValue('currency', values['partner']?.currency);
        }
    }, [values['partner']])

    useEffect(() => {
        setInvoiceCurrencyCode(values['currency']?.code);
    },[values['currency']]);

    useEffect(() => {
        setValue('issuePlace', country)
        onChangeContractFilter();
    },[values['partner']]);

    useEffect(()=>{
        if(!values['exchangeRateDate'] || !companyCurrencyCode || !invoiceCurrencyCode || !isInoInvoice){
            return;
        }

        if(values['exchangeRate'] && !checkExchangeRate.current && pageState !== PageState.Add){
            checkExchangeRate.current = true;;
            return;
        }

        fetchExchangeRate(invoiceCurrencyCode, companyCurrencyCode, values['exchangeRateDate']).then(
            (response)=>{
            if(!response || !response.ok){
               return;
            }

            setValue('exchangeRate', response.data.middleRate.toFixed(2));
        })

    },[values['exchangeRateDate'], companyCurrencyCode, invoiceCurrencyCode]);


    const changePartner = (partner) => {
        setValue('partner', partner);
        setValue('project', null);
        setSelectedPartner(partner);
        changeContract(null);
    }

    const changeContractPart = (contractPart) => {
        setValue('contractPart', contractPart);
        setSelectedContractPart(contractPart)
    }

    const changeContract = (contract) => {
        setValue('contract', contract);
        setSelectedContract(contract);
        changeContractPart(null)
    }

    const handleClear = () => {
        form.reset();
    }
    const handleRefresh = () => {
        refresh();
    }

    return (
        <FormProvider {...form}>
            <form id="invoice-header-form" action="#">

                <div className='header-row'>
                    {
                        <div className='header-row-item'>
                            <SelectControl
                                value={values['invoiceType']}
                                className='mui-shifted-label-input-invoice'
                                useValue
                                setValue={setValue}
                                rules={formRules['invoiceType']}
                                control={data}
                                name='invoiceType'
                                label={strings.forms.finance.invoice.invoiceHeaderForm.invoiceType}
                                options={getInvoiceTypes()}
                                nameKey={'name'}
                                valueKey={'id'}
                                disabled={isPageStateViewDetails()}
                            />
                        </div>
                    }

                    <div className='header-row-item'>
                        <AutoCompleteControl
                            name='partner'
                            label={strings.forms.finance.invoice.invoiceHeaderForm.partner}
                            className='mui-shifted-label-input-invoice corrected-label-margin'
                            nameKey={'name'}
                            valueKey={'id'}
                            setValue={(name, value) => changePartner(value)}
                            onChange={onChangePartnerFilter}
                            onOpen={() =>onChangePartnerFilter('')}
                            control={control}
                            options={partners}
                            value={values['partner']}
                            disabled={isPageStateViewDetails()}
                            placeholder={strings.forms.finance.invoice.invoiceHeaderForm.placeholder.partner}
                            quickAdd={true}
                            referenceType={ReferenceType.PARTNER}
                        />

                    </div>
                    <div className='header-row-item'>
                        <AutoCompleteControl
                            name='account'
                            label={strings.forms.finance.invoice.invoiceHeaderForm.account}
                            className='mui-shifted-label-input-invoice corrected-label-margin'
                            nameKey={'name'}
                            valueKey={'id'}
                            setValue={setValue}
                            onChange={onChangeAccountFilter}
                            onOpen={() =>onChangeAccountFilter('')}
                            control={control}
                            options={accounts}
                            value={values['account']}
                            disabled={isPageStateViewDetails()}
                            placeholder={strings.forms.finance.invoice.invoiceHeaderForm.placeholder.account}
                            quickAdd={true}
                            referenceType={ReferenceType.BANK_ACCOUNT}
                        />
                    </div>

                    <div className='header-row-item'>
                        <TextFieldControl
                            name='invoiceNumber'
                            className='mui-shifted-label-input-invoice corrected-label-margin'
                            control={data}
                            label={strings.forms.finance.invoice.invoiceHeaderForm.invoiceNumber}
                            disabled={isPageStateViewDetails()}
                            placeholder={strings.forms.finance.invoice.invoiceHeaderForm.placeholder.invoiceNumber}
                        />
                    </div>
                    <div className='header-row-item'>
                        <TextFieldControl
                            name='issuePlace'
                            className='mui-shifted-label-input-invoice corrected-label-margin'
                            control={data}
                            label={strings.forms.finance.invoice.invoiceHeaderForm.invoicePlace}
                            disabled={isPageStateViewDetails()}
                            placeholder={strings.forms.finance.invoice.invoiceHeaderForm.placeholder.invoicePlace}
                        />
                    </div>
                    <div className = 'header-form-controls'>
                        <IconButton onClick={handleClear} >
                            <img src='/images/table-page/clear.svg' />
                        </IconButton>
                        <IconButton onClick={handleRefresh}>
                            <img src='/images/table-page/reload.svg' />
                        </IconButton>
                    </div>
                </div>

                <div className='header-row'>
                    <div className='header-row-item'>
                        <DateTimeControl
                            className='mui-shifted-label-input-invoice corrected-label-margin'
                            data={data}
                            label={strings.forms.finance.invoice.invoiceHeaderForm.invoiceDate}
                            name={'invoiceDate'}
                            value={values['invoiceDate']}
                            setValue={setValue}
                            disabled={isPageStateViewDetails()}
                            disableFuture={true}
                        />
                    </div>
                    <div className='header-row-item'>
                        <DateTimeControl
                            data={data}
                            label={strings.forms.finance.invoice.invoiceHeaderForm.deliveryDate}
                            className='mui-shifted-label-input-invoice corrected-label-margin'
                            name={'deliveryDate'}
                            value={values['deliveryDate']}
                            setValue={setValue}
                            disabled={isPageStateViewDetails()}
                            disableFuture={true}
                        />
                    </div>
                    <div className='header-row-item'>
                        <DateTimeControl
                            data={data}
                            label={strings.forms.finance.invoice.invoiceHeaderForm.deadlineDate}
                            className='mui-shifted-label-input-invoice corrected-label-margin'
                            name={'paymentDueDate'}
                            value={values['paymentDueDate']}
                            setValue={setValue}
                            disabled={isPageStateViewDetails()}
                        />
                    </div>
                    {
                        isInoInvoice &&
                        <div className = 'exchange-container'>
                            <div className = 'exchange-currency-container'>
                                <div className='header-row-item'>
                                    <TextFieldControl
                                        name='exchangeRate'
                                        className='mui-shifted-label-input-invoice corrected-label-margin'
                                        type={'number'}
                                        control={data}
                                        label={strings.forms.finance.invoice.invoiceHeaderForm.exchangeRate}
                                        disabled={isPageStateViewDetails()}
                                        placeholder={strings.forms.finance.invoice.invoiceHeaderForm.placeholder.exchangeRate}
                                    />
                                </div>
                                <div className = 'header-row-item'>
                                    <SelectControl
                                        value={values['currency']}
                                        className='mui-shifted-label-input-invoice'
                                        useValue
                                        setValue={setValue}
                                        rules={formRules['currency']}
                                        control={data}
                                        name='currency'
                                        label={strings.forms.finance.invoice.invoiceHeaderForm.currency}
                                        options={siteData.data.currencies}
                                        nameKey={'code'}
                                        valueKey={'id'}
                                        disabled={isPageStateViewDetails() || checkItems(values['items'])}
                                    />
                                </div>
                            </div>
                            <div className='header-row-item exchange-date'>
                                <DateTimeControl
                                    data={data}
                                    label={strings.forms.finance.invoice.invoiceHeaderForm.exchangeRateDate}
                                    className='mui-shifted-label-input-invoice corrected-label-margin'
                                    name={'exchangeRateDate'}
                                    value={values['exchangeRateDate']}
                                    setValue={setValue}
                                    disabled={isPageStateViewDetails()}
                                    disableFuture={true}
                                />
                            </div>
                        </div>
                    }
                </div>
                {
                    values['partner'] &&
                    <div className='header-row'>
                        <div className='header-row-item'>
                            <AutoCompleteControl
                                name='contract'
                                className='mui-shifted-label-input-invoice corrected-label-margin'
                                label={strings.forms.finance.invoice.invoiceHeaderForm.contract}
                                nameKey={'name'}
                                valueKey={'id'}
                                setValue={(name,value) => changeContract(value)}
                                onChange={onChangeContractFilter}
                                onOpen={() => onChangeContractFilter()}
                                control={control}
                                options={contracts}
                                value={selectedContract}
                                disabled={isPageStateViewDetails()}
                                placeholder={strings.forms.finance.invoice.invoiceHeaderForm.placeholder.contract}
                                quickAdd={true}
                                partner={values['partner']}
                                referenceType={ReferenceType.CONTRACT}
                            />
                        </div>
                        {
                            values['contract'] &&
                            <div className='header-row-item'>
                                <AutoCompleteControl
                                    name='contractPart'
                                    className='mui-shifted-label-input-invoice corrected-label-margin'
                                    label={strings.forms.finance.invoice.invoiceHeaderForm.contractPart}
                                    nameKey={'description'}
                                    valueKey={'id'}
                                    setValue={(name, value) => changeContractPart(value)}
                                    onChange={onChangeContractPartFilter}
                                    onOpen={() => onChangeContractPartFilter()}
                                    control={control}
                                    options={contractParts}
                                    value={selectedContractPart}
                                    disabled={isPageStateViewDetails()}
                                    placeholder={strings.forms.finance.invoice.invoiceHeaderForm.placeholder.contractPart}
                                    quickAdd={true}
                                    contractId={values['contract'].id}
                                    referenceType={ReferenceType.CONTRACT_PART}
                                />
                            </div>
                        }
                        <div className='header-row-item'>
                            <AutoCompleteControl
                                name='project'
                                className='mui-shifted-label-input-invoice corrected-label-margin'
                                label={strings.forms.finance.invoice.invoiceHeaderForm.project}
                                nameKey={'name'}
                                valueKey={'id'}
                                setValue={setValue}
                                onChange={onChangeProjectFilter}
                                onOpen={() => onChangeProjectFilter()}
                                control={control}
                                options={projects}
                                value={values['project']}
                                disabled={isPageStateViewDetails()}
                                placeholder={strings.forms.finance.invoice.invoiceHeaderForm.placeholder.project}
                                quickAdd={true}
                                referenceType={ReferenceType.PROJECT}
                            />
                        </div>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default InvoiceHeaderForm;
