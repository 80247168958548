import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";
import DateFormats from "../../Constants/Company/DateFormats";
import store from "../../store";

export async function getCompanyDocument(id) {
    return await request('/api/company-documents/' + id);
}

export async function getCompanyDocuments(data) {
    return await request('/api/company-documents', data);
}

export async function addCompanyDocument(data) {
    return await request('/api/company-documents', data, HttpMethod.POST);
}

export async function editCompanyDocument(data) {
    return await request('/api/company-documents/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function editCompanyDocumentContent(data) {
    return await request('/api/company-documents/content/' + data.id, data, HttpMethod.PATCH);
}

export async function deleteCompanyDocument(id) {
    return await request('/api/company-documents/' + id, {}, HttpMethod.DELETE);
}

export async function getCompanyDocumentFillData() {
    return await request('/api/company-documents/fill-data');
}

export async function companyDocumentFill(id, data) {
    return await request('/api/company-documents/fill/' + id, data, HttpMethod.POST);
}

export async function viewCompanyDocumentPDF(id) {
    window.open(process.env.REACT_APP_BASE_URL + 'api/company-documents/view/' + id);
}

export async function downloadCompanyDocumentPDF(id) {
    window.open(process.env.REACT_APP_BASE_URL + 'api/company-documents/download/' + id);
}

export async function showPdfDocument(id) {
    return await request('api/company-documents/show/' + id, {}, HttpMethod.GET, {responseType: 'blob'}).then(res => {
        const newBlob = new Blob([res.data]);
        return {url: window.URL.createObjectURL(newBlob), name: res.headers['file-name']};
    });
}

function transformRequestData(data) {
    return {
        ...data,
        companyDocumentCategoryId: data.companyDocumentCategory ? data.companyDocumentCategory.id : null,
        companyDocumentTemplateId: data.companyDocumentTemplate ? data.companyDocumentTemplate.id : null,
    }
}