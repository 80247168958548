import React, {useContext, useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import {useDispatch} from "react-redux";
import {
    Box,
    Button,
    Card,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Modal,
    Paper
} from "@mui/material";
import { UserAvatar} from "../../../Util/AvatarUtil";
import {dateToString} from "../../../Util/DateUtil";
import {
    addProjectRecord, deleteProjectRecord,
    editProjectRecord,
    getAllProjectRecords
} from "../../../Services/Project/ProjectRecordService";
import Modules from "../../../Constants/Base/Modules";
import QuillEditor, { isEmpty } from "../../../Components/Controls/Inputs/QuillEditor";
import YesNoDialog, {YesNoDialogResult} from "../../../Components/Dialogs/YesNoDialog";
import SnackbarContext from "../../../Context/SnackbarContext";

const toolbarConfig = {
    items: [
        'heading', '|',
        'fontfamily', 'fontsize', '|',
        'alignment', '|',
        'fontColor', 'fontBackgroundColor', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
        'link', '|',
        'outdent', 'indent', '|',
        'bulletedList', 'numberedList', 'todoList', '|',
        'code', 'codeBlock', '|',
        'insertTable', '|',
        'uploadImage', 'blockQuote', '|',
        'undo', 'redo'
    ],
    shouldNotGroupWhenFull: true
}

const ProjectRecord = (props) => {

    const dispatch = useDispatch();

    const [records, setRecords] = useState([]);
    const [editorData, setEditorData] = useState('');
    const [recordData, setRecordData] = useState('');
    const expandOptionsIcon = () => <img src='/images/more-vertical.svg'/>;
    const [recordAnchor, setRecordAnchor] = useState(null);
    const [recordToEdit, setRecordToEdit] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const { showMessage } = useContext(SnackbarContext);



    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.project.projectRecord.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.PROJECTS},
                {label: strings.pages.project.details},
                {label: strings.pages.project.projectRecord.pageTitle},
            ],        
        }));
        fetch();
    }, [])
    
    useEffect(() => {
        fetch();
    }, [props.project])

    const fetch = () => {
        
        if(!props.project){
            return;
        }

        getAllProjectRecords(props.project.id).then(response => {

            if(!response || !response.ok){
                setRecords([]);
                return;
            }

            setRecords(response.data)
        })
    }

    const save = () => {
        addProjectRecord({
            content: editorData,
            projectId: props.project.id
        }).then(response => {
            setEditorData('')
            fetch();
        })
    }
    const handleOpenFolderOptions = (event, data) => {
        event.stopPropagation();
        setRecordAnchor(event.currentTarget);
        setRecordToEdit(data);
    }

    const handelEdit = () =>{
        setEditMode(true);
        setRecordAnchor(null);
    }

    const handelDelete = (record) =>{
        setRecordAnchor(null);
        setShowDeleteDialog(true);
    }


    const handleEditRecord = () => {
        let data = {...recordToEdit, 'content':recordData}
        editProjectRecord(data).then(response => {
            if(!response || !response.ok) return;
                fetch();
                setEditMode(false);
                setRecordToEdit(null);
        })
    }

    const handleDeleteDialogResult = (result) => {
        if (result === YesNoDialogResult.NO || result === YesNoDialogResult.CANCEL) {
            setShowDeleteDialog(false);
            setRecordToEdit(null);
            return;
        }

        if (recordToEdit && recordToEdit.id) {
            deleteProjectRecord(recordToEdit.id).then(response => {
                if (!response || !response.ok) {
                    showMessage(strings.components.tablePage.errorDeletingItem, 'error')
                    setShowDeleteDialog(false);
                    setRecordToEdit(null);
                    return;
                }

                showMessage(strings.components.tablePage.itemDeleted, 'success')
                setShowDeleteDialog(false);
                setRecordToEdit(null);
                fetch();
            })
        }
        setShowDeleteDialog(false);
    }

    const renderRecords = () => {

        let result = [];

        for (let record of records) {
            result.push(
                <Card key={'record-' + result.length} className='record-card'>
                <div className='header'>
                    <div className='header-user'>
                        <UserAvatar user={record.user}/>
                        <h3>{record.user.fullName}</h3>
                        <span>{dateToString(new Date(record.dateCreated))}</span>
                        { expandOptionsIcon &&
                            <IconButton className='expand-options-icon' onClick={(e) => {handleOpenFolderOptions(e, record)}}>
                                <Box component={expandOptionsIcon} color="inherit" sx={{ mr: 1 }} />
                            </IconButton>
                        }
                    </div>

                    <Menu className = 'board-dropdown-options' anchorEl={recordAnchor} id={"basic-menu-" + record.id} open={Boolean(recordAnchor)} onClose={() => {setRecordAnchor(null); setRecordToEdit(null)}}>
                        {
                            <MenuItem onClick={()=>handelEdit(record)}>
                                <ListItemIcon>
                                    <img src="/images/table-page/edit.svg" />
                                </ListItemIcon>
                                <ListItemText>{strings.components.actionCell.edit}</ListItemText>
                            </MenuItem>
                        }
                        {
                            <MenuItem onClick={()=>handelDelete(record)}>
                                <ListItemIcon>
                                    <img src="/images/delete-red.svg" />
                                </ListItemIcon>
                                <ListItemText>{strings.components.actionCell.delete}</ListItemText>
                            </MenuItem>
                        }
                    </Menu>
                </div>
                    { (record.id === recordToEdit?.id && editMode) ?
                        <div className='record-edit'>
                            <QuillEditor className='large-editor mh-250' value={recordData} onChange={setRecordData} defaultValue={record.content} />
                            <Button variant="contained" color="primary" onClick={handleEditRecord} disabled={isEmpty(recordData)}>
                                { strings.common.save }
                            </Button>
                            <Button variant="contained" color="secondary" onClick={ () => setEditMode(false) } >
                                { strings.common.cancel }
                            </Button>
                        </div>
                        :
                        <div dangerouslySetInnerHTML={{ __html: record.content }}/>
                    }
            </Card>
            )
        }

        return result;
    }

    const handleCloseModul = () => {
        setShowDeleteDialog(false);
        setRecordToEdit(null);
    }

    return <Grid id='project-record' container justifyContent="center"
                 alignItems="center">
        <Grid item xs={11}>
            <Paper className='paper'>
                <div className='editor-container mb-2'>
                    <QuillEditor className='large-editor mh-250' value={editorData} onChange={setEditorData}/>
                </div>
                <Button variant="contained" color="primary" className="rounded-input centered-flex-row pl-3 py-2" onClick={ () => save()} disabled={isEmpty(editorData)}>
                    <img src="/images/disk.svg" className="pr-2"/>
                    { strings.common.save }
                </Button>
                {
                    records && records.length > 0 &&
                    <div className='records-container'>
                        {renderRecords()}
                    </div>
                }
            </Paper>
            <Modal
                open={Boolean(showDeleteDialog)}
                onClose={handleCloseModul}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id='add-edit-folder-modal'>
                  <YesNoDialog show={showDeleteDialog}
                                 payload={recordToEdit}
                                 handleResult={handleDeleteDialogResult}
                                 title={strings.components.tablePage.confirmDelete}
                                 text={strings.components.tablePage.confirmDeleteMessage}
                    />

            </Modal>
        </Grid>
    </Grid>
}

export default ProjectRecord;