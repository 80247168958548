import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import {Paper} from "@mui/material";
import SnackbarContext from "../../../Context/SnackbarContext";
import SpaceForm from '../../../Components/Forms/Pages/Document/Spaces/SpaceForm';
import { editSpace } from '../../../Services/DocumentManagement/SpaceService';
import { useSelector } from 'react-redux';

const formRules = {
    'name': {required: true},
};

const EditSpace = (props) => {

    const {onFinish, onCancel} = props;

    const auth = useSelector((state) => state.auth)

	const {setDrawerTitle} = useContext(DrawerContext);
	const { showMessage } = useContext(SnackbarContext);
	const [showLoader, setShowLoader] = useState(false);

	const form = useForm({
        defaultValues: 
        {
            name: props.space?.name,
            visible: props.space?.visible,
        }
	});

	const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;

	useEffect(() => {
		setDrawerTitle(strings.pages.space.editDocument.pageTitle);
	}, []);

	const onSubmit = (data) => {
		setShowLoader(true);

        let spaceData = {...data, id: props.space.id}

        editSpace(spaceData).then(response => {
            setShowLoader(false);

        	if(!response || !response.ok) {
        			showMessage(strings.commonMessages.errorEditing, 'error');
        			onFinish();
        			return;
        	}

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            onFinish();
        });
	}

    const isOwner = () => {
        return props.space.owner.id === auth.user.id ? true : false;
    }

	return (
        <div className = 'drawer-form'>
            <Paper className = 'paper'>
                {
                    showLoader && <LinearProgress />
                }
                <SpaceForm 
                    formRules = {formRules}
                    form = {form}
                    values = {getValues()}
                    setValue = {setValue}
                    data = {data}
                    errors = {errors}
                    onSubmit = {handleSubmit(onSubmit)}
                    onCancel = {() => onCancel()}
                    isOwner = {isOwner()}
                />
            </Paper>
        </div>
	);

}

export default EditSpace;
