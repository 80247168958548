import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getProductServiceCategory(id) {
    return await request('/api/product-service-categories/' + id);
}

export async function getProductServiceCategoryList(data) {
    return await request('/api/product-service-categories', data);
}

export async function getProductServiceCategoryByParent(data) {
    return await request('/api/product-service-categories', {parentId: data});
}

export async function addProductServiceCategory(data) {
    return await request('/api/product-service-categories', transformRequestData(data), HttpMethod.POST);
}

export async function editProductServiceCategory(data) {
    return await request('/api/product-service-categories/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteProductServiceCategory(id) {
    return await request('/api/product-service-categories/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        parentId: data.parent ? data.parent.id : null,
    }
}