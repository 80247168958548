import strings from "../../localization";

const PaymentMonth = {
    LAST_MONTH: 'LAST_MONTH',
    CURRENT_MONTH:'CURRENT_MONTH',
    NEXT_MONTH: 'NEXT_MONTH',
}
export default PaymentMonth;

export const getPaymentMonths = () => {
    return Object.keys(PaymentMonth).map((key) =>({key: strings.constants.hr.paymentMonth[key], value: key }))
}