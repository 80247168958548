import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import {
    connectProduct,
    deleteProductService, disconnectProduct, getProductService,
    getProductServiceList,
} from "../../../Services/Product/ProductServiceService";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {
    formatColumnConstant,
    formatColumnObject,
    renderWooCommerceConnectDisconnect
} from "../../../Components/DataGrid/ValueFormatter";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import AddProductService from "./AddProductService";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import EditProductService from "./EditProductService";
import {renderColumnNumber, renderColumnPercent} from "../../../Components/DataGrid/ValueCellRender";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCurrencies } from '../../../Util/SiteDataUtil';
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import {getProductServiceTypes, getProductServiceTypeString} from "../../../Constants/Products/ProductServiceType";
import {getProductTypes, getProductTypeString} from "../../../Constants/Products/ProductType";
import {getWooCommerceSettings} from "../../../Services/Product/ProductSettingsService/WooCommerceSettingsService";
import Button from "@mui/material/Button";
import {IconButton, Tooltip} from "@mui/material";

const tableDescription = [
    {
        field: 'name', headerName: strings.pages.product.productService.productServiceList.name,
        width: 200,
    },
    {
        field: 'publicName', headerName: strings.pages.product.productService.productServiceList.publicName,
        width: 200,
    },
    {
        field: 'sku', headerName: strings.pages.product.productService.productServiceList.sku,
        width: 200,
    },
    {
        field: 'publicInoName', headerName: strings.pages.product.productService.productServiceList.publicInoName,
        width: 200,
    },
    {
        field: 'description', headerName: strings.pages.product.productService.productServiceList.description,
        width: 200,
    },
    {
        field: 'price', headerName: strings.pages.product.productService.productServiceList.price,
        width: 100,
        renderCell: (params) => renderColumnNumber(params)
    },
    {
        field: 'tax', headerName: strings.pages.product.productService.productServiceList.tax,
        width: 100,
        renderCell: (params) => renderColumnPercent(params)
    },
    {
        field: 'taxValue', headerName: strings.pages.product.productService.productServiceList.taxValue,
        width: 100,
        renderCell: (params) => renderColumnNumber(params)
    },
    {
        field: 'priceWithTax', headerName: strings.pages.product.productService.productServiceList.priceWithTax,
        width: 120,
        renderCell: (params) => renderColumnNumber(params)
    },
    {
        field: 'currency',
        headerName: strings.pages.product.productService.productServiceList.currency,
        valueFormatter: (params) => formatColumnObject(params, 'code'),
        width: 90
    },
    {
        field: 'unit',
        headerName: strings.pages.product.productService.productServiceList.unit,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 100
    },
    {
        field: 'type',
        headerName: strings.pages.product.productService.productServiceList.type,
        valueFormatter: (params) => formatColumnConstant(params, getProductServiceTypeString),
        width: 100
    },
    {
        field: 'productType',
        headerName: strings.pages.product.productService.productServiceList.productType,
        valueFormatter: (params) => formatColumnConstant(params, getProductTypeString),
        width: 150
    },
    {
        field: 'productServiceCategory',
        headerName: strings.pages.product.productService.productServiceList.productServiceCategory,
        valueFormatter: (params) => formatColumnObject(params,'name'),
        width: 200
    },

];

const filters = [
    {
        name: 'currency',
        nameKey: 'code',
        valueKey: 'id',
        optionsName: 'currency',
        label: strings.pages.product.productService.productServiceList.currency,
        showSelect: true
    },
    {
        name: 'type',
        nameKey: 'name',
        valueKey: 'value',
        optionsName: 'type',
        label: strings.pages.product.productService.productServiceList.type,
        showSelect: true
    },
    {
        name: 'productType',
        nameKey: 'name',
        valueKey: 'value',
        optionsName: 'productType',
        label: strings.pages.product.productService.productServiceList.productType,
        showSelect: true
    },
]

const ProductServiceList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const siteData = useSelector((state) => state.siteData);
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.PRODUCT_SERVICE;
    const [filterOptions, setFilterOptions] = useState({});
    const [enabled, setEnabled] = useState(false);
    const permissionGroup = AppPermissions.PRODUCTS_AND_SERVICES.GROUP;
    const auth = useSelector((state) => state.auth);

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    const additionalMenuItems = [
        {
            handleClick: (id, item) => handleCompositeProduct(id, item),
            text: strings.pages.product.productService.productServiceList.compositeProduct,
            src: "/images/table-page/items.svg"
        },
        enabled && {
            handleClick: (id, item) => handleConnectDisconnectProduct(id, item),
            text: (item) => renderText(item),
            src: (item) => renderIcon(item),
        }
    ];

    function handleConnectDisconnectProduct(id, item) {
        if (item.externalId !== undefined && item.externalId !== null) {
            handleWooCommerceDisconnect(item);
            return;
        }
        handleWooCommerceConnect(item);
    }

    function renderText (item){
            const data = tableData.data.find((dataItem) => dataItem.id === item.id);
            if (data && data.sku === undefined && false) {
                return null;
            }
            if (data && data.externalId !== undefined && data.externalId !== null) {
                return strings.pages.product.productService.productServiceList.disconnect;
            }
                return strings.pages.product.productService.productServiceList.connect;
        }

    function renderIcon (item){
        const data = tableData.data.find((dataItem) => dataItem.id === item.id);
        if (data && data.sku === undefined) {
            return null;
        }
        if (data && data.externalId === undefined) {
            return "/images/table-page/WooCommerce/woo-commerce-connected.svg";
        }
            return "/images/table-page/WooCommerce/woo-commerce-disconnected.svg";
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.product.productService.productServiceList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.PRODUCT},
                {label: strings.pages.product.productService.productServiceList.pageTitle},
            ],
        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }
        fetch();
    }, [filter, updatedFilter])

    useEffect(() => {
        if(!Object.keys(siteData.data).length){
            return;
        }

        setFilterOptions({
            currency: getCurrencies(siteData),
            type: getProductServiceTypes(),
            productType: getProductTypes(),
        })
    },[siteData])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        getWooCommerceSettings(auth.company.id).then(response => {
            if(!response || !response.ok){
                return;
            }
            setEnabled(response.data.entity.enabled);
        });
        setTableData({
            ...tableData,
            loading: true
        });

        getProductServiceList({
            ...filter
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    const handleCompositeProduct = (id, item) => {
        navigate(`/composite-product/${item.id}`);
    }

    const handleWooCommerceDisconnect = (product) => {
        disconnectProduct(product).then(response => {
            fetch()
        })
    }

    const handleWooCommerceConnect = (product) => {
        connectProduct(product).then(response => {
            fetch()
        })
    }

    return <TablePageContext.Provider value={value}>
                <TablePage tableDescription={columns} deleteItem={deleteProductService}
                    tableData={tableData} onFinish={onFinish}
                    filter={filter} filters={filters} filterOptions={filterOptions}
                    referenceType={referenceType}
                    permissionGroup={permissionGroup} additionalMenuItems={additionalMenuItems}
                    tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                    editPage={<EditProductService data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                    addPage={<AddProductService onCancel={() => onCancel()} onFinish={() => onFinish() }
                    />}
                    addButtonText={strings.components.tablePage.addButton.productService}
                />
    </TablePageContext.Provider>;
}

export default ProductServiceList;
