import {FormProvider} from "react-hook-form";
import React, {useContext} from 'react';
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import Button from "@mui/material/Button";
import {getPipelineStageTypes} from "../../../../../Constants/Company/PaymentStageType";

const PipelineStageForm = ({
                               form,
                               formRules,
                               errors,
                               onSubmit,
                               values,
                               setValue,
                               onCancel,
                               control
                           }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='pipeline-form' className='form' action="#">
                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    error={Boolean(errors && errors.name)}
                    helperText={errors.name && errors.name.message}
                    label={strings.forms.administration.pipelineStage.name}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='description'
                    multiline
                    rows={3}
                    rules={formRules['description']}
                    label={strings.forms.administration.pipelineStage.description}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='color'
                    rules={formRules['color']}
                    type={'color'}
                    control={control}
                    error={Boolean(errors.color)}
                    helperText={errors.color && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.crm.dealPriority.color}
                    disabled={isPageStateViewDetails()}
                />
                <SelectControl
                    value={values['type']}
                    setValue={setValue}
                    rules={formRules['type']}
                    control={control}
                    name='type'
                    label={strings.forms.administration.pipelineStage.type}
                    options={getPipelineStageTypes()}
                    nameKey={'name'}
                    valueKey={'id'}
                    error={Boolean(errors && errors.type)}
                    helperText={errors.type && errors.type.message}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>

                }
            </form>
        </FormProvider>
    )
}


export default PipelineStageForm;