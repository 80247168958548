import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Paper } from "@mui/material";
import { getCurrentCompany } from "../../../Services/Company/CompanyService";
import { updateDecision } from "../../../Services/HR/BusinessTripService";
import { getAllUserCompanyAccess } from "../../../Services/User/UserCompanyAccessService";
import { useParams } from "react-router-dom";
import { getAllCurrencies } from "../../../Services/Base/CurrencyService";
import BusinessTripDecisionForm from "../../../Components/Forms/Pages/HR/BusinessTrip/BusinessTripDecisionForm";
import SnackbarContext from "../../../Context/SnackbarContext";
import strings from "../../../localization";

const formRules = {
  amountOfDailyWageInCountry: {
    required: {
      value: true,
    },
  },
  domesticCurrency: {
    required: {
      value: true,
    },
  },
  amountOfDailyWageInCountry: {
    required: {
      value: true,
    },
  },
  freeAccommodationAndFood: {
    required: {
      value: true,
    },
  },
  meansOfTransport: {
    required: {
      value: true,
    },
  },
  reasonToTravel: {
    required: {
      value: true,
    },
  },
  travelDestination: {
    required: {
      value: true,
    },
  },
  tripDuration: {
    required: {
      value: true,
    },
  },
  companyUser: {
    required: {
      value: true,
    },
  },
};

const EditDecision = ({
  decision,
  travelWarrant,
  onEdit,
  onFinish,
  onCancel,
}) => {
  const { showMessage } = useContext(SnackbarContext);
  const [currencies, setCurrencies] = useState([0]);

  const form = useForm({
    defaultValues: decision
      ? {
          reasonToTravel: decision.reasonToTravel || undefined,
          tripDuration: decision.tripDuration || undefined,
          travelDestination: decision.travelDestination || undefined,
          dayOfDeparture: decision.dayOfDeparture || undefined,
          meansOfTransport: decision.meansOfTransport || undefined,
          freeAccommodationAndFood:
            decision.freeAccommodationAndFood || undefined,
          domesticCurrency: decision.domesticCurrency || undefined,
          foreignCurrency: decision.foreignCurrency || undefined,
          amountOfDailyWageInCountry:
            decision.amountOfDailyWageInCountry || undefined,
          amountOfDailyWageAbroad:
            decision.amountOfDailyWageAbroad || undefined,
          advancePaymentForeignCurrency:
            decision.advancePaymentForeignCurrency || undefined,
          advancePaymentDomesticCurrency:
            decision.advancePaymentDomesticCurrency || undefined,
          companyUser: decision.company_user || undefined,
          domesticCurrency: { code: decision.domesticCurrency } || undefined,
          foreignCurrency: { code: decision.foreignCurrency } || undefined,
        }
      : undefined,
  });
  const {
    data,
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = form;

  const [showLoader, setShowLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [company, setCompany] = useState();
  const businessTripId = useParams("id");
  const id = parseInt(businessTripId.id);

  useEffect(() => {
    getAllCurrencies().then((response) => {
      setCurrencies(response.data.result);
    });
    fetchCompany();
    fetchUsers();
  }, []);

  const fetchCompany = () => {
    getCurrentCompany().then((response) => {
      if (!response || !response.ok) {
        return;
      }
      setCompany(response.data.id);
    });
  };

  const fetchUsers = () => {
    getAllUserCompanyAccess(company).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      response.data.map((data) => setUsers(data.user));
    });
  };

  const onSubmit = (data) => {
    setShowLoader(true);
    console.log(decision);

    const decisionData = {
      ...data,
      id: decision.id,
      businessTrip: id,
      domesticCurrency: data.domesticCurrency.code,
      foreignCurrency: data.foreignCurrency.code,
    };

    let travelWarrantData;

    if (travelWarrant) {
      travelWarrantData = {
        ...data,
        id: travelWarrant.id,
        businessTrip: id,
        domesticCurrency: data.domesticCurrency.code,
        foreignCurrency: data.foreignCurrency.code,
      };
    }

    updateDecision(decisionData).then((response) => {
      setShowLoader(false);
      if (!response || !response.ok) {
        showMessage(strings.commonMessages.errorEditing, "error");
        return;
      }

      showMessage(strings.commonMessages.editingSuccessful, "success");
      onEdit();
      onFinish();
    });

    if (travelWarrant) {
      updateDecision(travelWarrantData).then((response) => {
        setShowLoader(false);
        if (!response || !response.ok) {
          showMessage(strings.commonMessages.errorEditing, "error");
          return;
        }
      });
    }
  };

  return (
    <div className="drawer-form">
      <Paper className="paper">
        <BusinessTripDecisionForm
          values={getValues()}
          setValue={setValue}
          formRules={formRules}
          users={users}
          data={data}
          errors={errors}
          form={form}
          control={control}
          onSubmit={handleSubmit(onSubmit)}
          onCancel={() => onCancel()}
          fetchUsers={fetchUsers}
          currencies={currencies}
          title={strings.pages.hr.businessTrip.editDecision.pageTitle}
        />
      </Paper>
    </div>
  );
};

export default EditDecision;
