import {Paper} from "@mui/material";
import strings from "../../localization";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { resendEmail } from "../../Services/User/UserService";
import { useContext } from "react";
import SnackbarContext from "../../Context/SnackbarContext";


const VerifyEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState(location.state?.data?.email);
    const {showMessage} = useContext(SnackbarContext);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.FULL_NO_HEADER));
        if(!location.state?.data){
            navigate('/');
        }
    }, [])
     

    const handleSendAgain = () =>{
        resendEmail(location.state.data).then(
            response =>{
                if (!response || !response.ok) {
                    showMessage(strings.pages.user.verifyEmail.error, 'error')
                    if (strings.error.VerifyEmailHandler[response.response.data.message]) {
                        showMessage(strings.error.VerifyEmailHandler[response.response.data.message], 'error');
                    }
                    return
                }
                showMessage(strings.pages.user.verifyEmail.success, 'success')
            }
        )
        
    }

    return <div id='verify-email'>
        <div className="color-overlay"></div>
        <Paper>
            <h5 className="mb-9"> {strings.pages.user.verifyEmail.header}</h5>
            <div className="font-16"> {strings.pages.user.verifyEmail.description}</div>
            <div className="bold font-16 mb-9">{email}</div>
            <div className="form">
                <div className='blue-notification mb-9'>
                {strings.pages.user.verifyEmail.checkEmail}
                </div>

                <div className='link-to'>
                    <div className='text font-14'> {strings.pages.user.verifyEmail.didNotRecive} </div>
                    <div onClick={handleSendAgain} className="link-style font-14"> {strings.pages.user.verifyEmail.sendAgain}</div>
                </div>
            </div>      
        </Paper>
    </div>
};
export default VerifyEmail;
