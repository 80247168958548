import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ReactQuill from "react-quill";
import {IconButton} from "@mui/material";
import strings from "../../../localization";

const TemplateEditorQuestionSlider = (props) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    ReactQuill.Quill.register(TemplateEditorQuestionSlider, true);


    const clickQuestionHandler = (question) => {
        props.clickQuestion(question);
    }

    const renderQuestions = () => {
        if(!Array.isArray(props.questions)) { return ''}

            return props.questions.map((question) =>
                <div className="question-toolbar-button" key={question.id}>
                    <IconButton className='question-button' onClick={(e) => {clickQuestionHandler(question)}
                    }>
                        <div className='button-text'>{question.question}</div>
                    </IconButton>
                </div>
            )
    }

    return (
        <div className="question-slider">
            <Box sx={{bgcolor: 'background.paper' }}>
                <Tabs
                    sx={{ height: '100%' }}
                    value={false}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >
                    {props.questions.length > 0 ? renderQuestions() :
                        <div className={"no-question-label"}><span>
                            {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateQuestion.noQuestions}
                        </span></div>}
                </Tabs>
            </Box>
        </div>
    )

}

export default TemplateEditorQuestionSlider;