import strings from "../../localization";

export const CheckListQuestionType = {
    TEXT: 1,
    NUMBER: 2,
    DATE: 3,
    SELECT: 4,
    RADIO: 5,
}

export function getCheckListQuestionTypes() {
    return [
        {
            value: CheckListQuestionType.TEXT,
            name: getCheckListQuestionTypeString(CheckListQuestionType.TEXT)
        },
        {
            value: CheckListQuestionType.NUMBER,
            name: getCheckListQuestionTypeString(CheckListQuestionType.NUMBER)
        },
        {
            value: CheckListQuestionType.DATE,
            name: getCheckListQuestionTypeString(CheckListQuestionType.DATE)
        },
        {
            value: CheckListQuestionType.SELECT,
            name: getCheckListQuestionTypeString(CheckListQuestionType.SELECT)
        },
        {
            value: CheckListQuestionType.RADIO,
            name: getCheckListQuestionTypeString(CheckListQuestionType.RADIO)
        }
    ];
}

export function getCheckListQuestionTypeString(questionType) {
    switch(Number(questionType)) {
        case CheckListQuestionType.TEXT: return strings.companyDocumentQuestionType.text;
        case CheckListQuestionType.NUMBER: return strings.companyDocumentQuestionType.number;
        case CheckListQuestionType.DATE: return strings.companyDocumentQuestionType.date;
        case CheckListQuestionType.SELECT: return strings.companyDocumentQuestionType.select;
        case CheckListQuestionType.RADIO: return strings.companyDocumentQuestionType.radio;
    }
}

export default CheckListQuestionType;