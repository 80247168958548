import React from 'react';
import strings from "../../../../../localization";
import {Button, CircularProgress} from "@mui/material";
import InvoiceDirection from "../../../../../Constants/Finance/InvoiceDirection";
import PageState from '../../../../../Constants/Base/PageState';
import LoadingButton from '@mui/lab/LoadingButton';

const InvoiceControls = ({
                             data,
                             save,
                             canSave,
                             view,
                             download,
                             viewIno,
                             downloadIno,
                             newInvoice,
                             pageState,
                             invoiceDirection,
                             isInoInvoice,
                             loadingRef,
                             invoiceId,
                         }) => {
    return (
        <div className='controls'>
            <div className='left'>
                <Button variant="contained" color="primary" onClick={newInvoice}
                        disabled={ invoiceId === -1 || pageState === PageState.ViewDetails}>
                    {strings.common.new}
                </Button>
            </div>
            <div className='right'>
                <LoadingButton loading={loadingRef?.current} loadingPosition="end" loadingIndicator={<CircularProgress color="inherit" size={16} />}
                    variant="contained" color="primary" onClick={save} disabled={!canSave}>
                        {strings.common.save}
                </LoadingButton>
                {
                    invoiceDirection === InvoiceDirection.OUT &&
                    <React.Fragment>
                        <Button variant="contained" color="primary" disabled={invoiceId === -1}
                                onClick={view}>
                            {strings.common.view}
                        </Button>
                        <Button variant="contained" color="primary" disabled={invoiceId === -1}
                                onClick={download}>
                            {strings.common.download}
                        </Button>
                    </React.Fragment>
                }
                {
                    isInoInvoice && invoiceDirection === InvoiceDirection.OUT &&
                    <React.Fragment>
                        <Button variant="contained" color="primary" disabled={invoiceId === -1}
                                onClick={viewIno}>
                            {strings.common.viewIno}
                        </Button>
                        <Button variant="contained" color="primary" disabled={invoiceId === -1} onClick={downloadIno}>
                            {strings.common.downloadIno}
                        </Button>
                    </React.Fragment>
                }
            </div>
        </div>
    )
};

export default InvoiceControls;
