import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllPipelineStages(data) {
    return await request('/api/pipeline-stages/all', data);
}

export async function getPipelineStage(id) {
    return await request('/api/pipeline-stages/' + id);
}

export async function getPipelineStages(data) {
    return await request('/api/pipeline-stages', data);
}

export async function addPipelineStage(data) {
    return await request('/api/pipeline-stages', transformRequestData(data), HttpMethod.POST);
}

export async function reorderPipelineStage(data) {
    return await request('/api/pipeline-stages/reorder', data, HttpMethod.POST);
}

export async function editPipelineStage(data) {
    return await request('/api/pipeline-stages/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deletePipelineStage(id) {
    return await request('/api/pipeline-stages/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        type: data.type ? data.type.id : null,
    }
}