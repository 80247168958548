import {isNullOrUndef} from "chart.js/helpers";
import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function addEvent(data) {
    return await request('/api/customEvent', transformRequestData(data), HttpMethod.POST);
}

export async function getEvent(id) {
    return await request('/api/customEvent/'+id ,HttpMethod.GET);
}

export async function editEvent(data){
    return await  request('/api/customEvent/' + data.id, transformRequestData(data) ,HttpMethod.PUT);
}

export async function deleteEvent(id){
    return await request('/api/customEvent/' + id,{}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data
    }

}