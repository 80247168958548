import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getCompanyDocumentTemplateItem(id) {
    return await request('/api/company-document-template-items/' + id);
}

export async function getCompanyDocumentTemplateItems(data) {
    return await request('/api/company-document-template-items/' + data.companyDocumentTemplateId, data);
}

export async function getAllCompanyDocumentTemplateItems(data) {
    return await request('/api/company-document-template-items/all', data);
}

export async function addCompanyDocumentTemplateItem(data) {
    return await request('/api/company-document-template-items', transformRequestData(data), HttpMethod.POST);
}

export async function editCompanyDocumentTemplateItem(data) {
    return await request('/api/company-document-template-items/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteCompanyDocumentTemplateItem(id) {
    return await request('/api/company-document-template-items/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        type: data.type ? data.type.value || data.type : null,
        referenceType: data.referenceType ? data.referenceType.value || data.referenceType : null,
        referenceValueType: data.referenceValueType ? data.referenceValueType.value || data.referenceValueType : null,
    }
}