import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import {Controller} from "react-hook-form";
import React from "react";
import { useEffect, useRef, useMemo } from 'react';
import strings from "../../../localization";

const icons = ReactQuill.Quill.import('ui/icons');
icons['bold'] = '<img src ="/images/editor-bold.svg" />';
icons['italic'] = '<img src ="/images/editor-italic.svg" />';
icons['underline'] = '<img src ="/images/editor-underline.svg" />';
icons['strike'] = '<img src ="/images/editor-strikethrough.svg" />';
icons['color'] = '<img src = "/images/editor-monospace.svg" />';
icons['list']['ordered'] = '<img src = "/images/editor-list-ordered.svg" />';
icons['list']['bullet'] = '<img src = "/images/editor-list-unordered.svg" />';
icons['link'] = '<img src = "/images/editor-link.svg" />';
icons['undo'] = '<img src = "/images/editor-undo.svg" />';
icons['redo'] = '<img src = "/images/editor-redo.svg" />';


const QuillComment = ({
                         name='',
                         value = '',
                         onChange = ()=>{},
                         defaultValue = '',
                         disabled = false,
                         theme = QuillThemes.snow,
                         className = '',
                         form,
                         placeholder='',
                         label = ''
                     })=>{


    const editor = useRef(null);

    useEffect(()=>{
        onChange(defaultValue);
    },[])



    const defaultModules = useMemo(() => ({
        history: {
            delay: 1000,
            maxStack: 100,
            userOnly: false
        },
        toolbar: {
            container: [
                ["bold", "italic", "underline", "strike", {color: []}],
                [{ list:  "bullet" }, { list:  "ordered" }],
                ["link"],
                ["undo", "redo"],
            ],
            handlers: {
                'undo': () => {
                    editor.current.editor.history.undo()
                },
                'redo': () => {
                    editor.current.editor.history.redo()
                }
            },
        }
    }),[]);

    if(form){
        return <Controller
            name={name}
            control={form.control}
            render={({field}) =>
                <ReactQuill {...field}
                            ref={editor}
                            className={className}
                            readOnly={disabled}
                            value={field.value}
                            onChange={field.onChange}
                            theme={theme}
                            modules={defaultModules}
                            placeholder={strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateQuestion.placeholders.explanation}

                />}
        />
    }
    else{

        return <ReactQuill
            ref={editor}
            className={className}
            readOnly={disabled}
            value={value}
            onChange={onChange}
            theme={theme}
            modules={defaultModules}
            placeholder="Enter comment"
        />
    }
}

export default QuillComment;

//Takes the control value that is passed into the editor
export const isEmpty = (value) =>{
    return !value || value === '<p><br></p>';
}

export const QuillThemes = {
    snow: 'snow',
    bubble: 'bubble',
}
