import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import {Paper} from "@mui/material";
import SnackbarContext from "../../../Context/SnackbarContext";
import EditInvoiceItemsForm from '../../../Components/Forms/Pages/Finance/Invoice/EditInvoiceItemsForm';

const formRules = {
    'name': {required: true},
    'quantity': {required: true},
    'price': {required: true},
    'tax': {required: true},
}

const EditInvoiceItem = ({invoiceItem, invoiceDirection, onCancel, onFinish, currency}) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const [showLoader, setShowLoader] = useState(false);
    const form = useForm({
        defaultValues: {
            id: invoiceItem.id,
            name: invoiceItem.name,
            quantity: invoiceItem.quantity,
            price: invoiceItem.price,
            discount: invoiceItem.discount,
            tax: invoiceItem.tax,
        }
    });

    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;

    useEffect(() => {
        setDrawerTitle(strings.pages.finance.invoice.editInvoiceItem.pageTitle);
    },[]);

    const onSubmit = (data) => {
        onFinish(data);
    }

    return (
        <div className = 'drawer-form'>
            <Paper className = 'paper'>
                {
                    showLoader &&
                        <LinearProgress />
                }
                <EditInvoiceItemsForm
                    formRules = {formRules}
                    errors = {errors}
                    values = {getValues()}
                    form = {form}
                    setValue = {setValue}
                    onSubmit = {handleSubmit(onSubmit)}
                    onCancel = {() => onCancel()}
                    currency = {currency.code}
                />
            </Paper>
        </div>
    );
}

export default EditInvoiceItem;
