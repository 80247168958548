import TextField from "@mui/material/TextField";
import {Controller} from "react-hook-form";
import {dateAsUTCDate} from "../../../Util/DateUtil";
import React, { useEffect, useState } from "react";
import { isNumberFloat } from "../../../Util/DataValidation";

const TextFieldControl = (props) => {

    const [isFieldSelected, setIsFieldSelected] = useState(false);

    const onKeyDown = (evt) => {
        if (props.type && props.type.toLowerCase() === 'number') {
            const value = evt.target.value;
            evt.key === ',' && evt.preventDefault();
            evt.key === '.' && value.endsWith('.') && evt.preventDefault();
        }
    }

    const onNumberInput = (evt) => {
        if(evt.nativeEvent.data !== '.' ){
            evt.target.value = evt.target.value.replace(/[a-zA-Z]/g, '');
        }
    }

    return <Controller
        {...props}
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
        rules={props.rules}
        render={({field}) =>
            <TextField {...field}
                       id={'text-field-control-' + props.name}
                       onKeyPress={props?.onKeyPress}
                       onKeyDown={onKeyDown}
                       onInput={isNumberFloat(props.type, field.value) && !props.onInput ? onNumberInput : props.onInput}
                       InputLabelProps={{...field, shrink: true}}
                       size={props.size ? props.size : 'small'}
                       fullWidth={props.fullWidth ? props.fullWidth : true}
                       type={props.type}
                       margin={props.margin ? props.margin : 'normal'}
                       error={props.error}
                       helperText={props.helperText}
                       label={props.label}
                       disabled={props.disabled}
                       multiline={props.multiline}
                       rows={props.rows}
                       maxRows={props.maxRows}
                       autoFocus = {props.autoFocus}
                       InputProps={{
                            ...props.inputProps,
                            onClick: () => setIsFieldSelected(true),
                            onBlur: () => setIsFieldSelected(false),
                            value: (!isNumberFloat(props.type, field.value)) ? field.value : Number(field.value).toFixed(2)
                            &&  (isNumberFloat(props.type, field.value) && isFieldSelected) ? field.value : Number(field.value).toFixed(2)
                       }}
                       inputProps={(props.type && props.type.toLowerCase() === 'number') ?
                                     {...props.inputProps,
                                       step: 1}
                                    : {...props.inputProps}}
                       placeholder={props.placeholder}
                       className={props?.className}
            />}
    />
}

export default TextFieldControl;