export const EInvoiceInStatus = {
    NEW: 'New',
    SEEN: 'Seen',
    REMINDED: 'Reminded',
    RE_REMINDED: 'ReNotified',
    RECEIVED: 'Received',
    DELETED: 'Deleted',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
    CANCELLED: 'Cancelled',
    STORNO: 'Storno',
    UNKNOWN: 'Unknown'
}