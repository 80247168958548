import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {CompanyAvatar} from "../../../Util/AvatarUtil";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import {Box, InputLabel} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useEffect, useState} from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
    },
};

const MultipleSelectCheckmarks = (props) => {

    const [value, setValue] = useState(props.value);

    useEffect(()=>{
        setValue(props.value);
    },[props.value])

    const onKeyDown = (evt) => {
        if (props.type && props.type.toLowerCase() === 'name') {
            const value = evt.target.value;
            evt.key === ',' && evt.preventDefault();
            evt.key === '.' && value.endsWith('.') && evt.preventDefault();
        }
    }

    const handleChange = (e) => {
        props.setSearch(e.target.value)
    }

    const handleSelectAll = () => {
        props.setSelectAll(!props.selectAll);
        props.setIsSelectAll(true);
    }

    return <FormControl className={props.filter ? 'w-150' : 'w-200'}>
        <InputLabel id="demo-simple-select-label" error={props.error}>{props.label}</InputLabel>
                <Select
                    id="demo-multiple-checkbox"
                    multiple
                    SelectDisplayProps={{ style: { padding: 0} }}
                    style={{ height: 39, fontSize: 14, borderRadius: 8}}
                    size="small"
                    value={value}
                    onChange={props.handleChange}
                    IconComponent={ExpandMoreIcon}
                    label={props.label}
                    renderValue={(selected) => {
                        return (
                            <Box sx={{ display: "flex", gap: 1, alignItems: 'center'}}>
                                {props.showAvatar &&
                                    <div className='icon-background'>
                                        <CompanyAvatar company={props.defaultValue} width={41} componentWidth={51}
                                                       height={37} options={{variant: 'rounded'}}/>
                                    </div>
                                }
                                <div className={props.filter ? 'select-value filter-padding-10' : 'select-value'}>
                                    {selected.length > 1 ? selected.filter(item => item.id !== -1).map((x) => x ? x.name : '').join(', ') : selected.map((x) => x ? x.name : '').join(', ')}
                                </div>
                            </Box>
                        );
                    }}
                    MenuProps={MenuProps}
                    defaultValue={props.defaultValue}
                >
                    {props.showSearch &&
                        <div id='search-company'>
                            <Paper className='rounded10'>
                                <div className='search-input'>
                                    <IconButton>
                                        <img src='/images/search.svg'/>
                                    </IconButton>
                                    <input
                                        className='search'
                                        placeholder={'Search'}
                                        onKeyDown={onKeyDown}
                                        value={props.search}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Paper>
                            <div className='select-all-style'>
                                <Checkbox
                                    checked={props.selectAll}
                                    onChange={handleSelectAll}
                                    sx={{
                                        color: '#40B6BE',
                                        '&.Mui-checked': {
                                            color: '#40B6BE',
                                        }
                                    }}
                                />
                                <div className='select-all-text'>Select all</div>
                            </div>
                        </div>
                    }

                    {props.options?.map((variant) => (
                        <MenuItem key={variant.id} value={variant}  className={'company-item'}
                        sx={{'&:focus': {
                                background: '#dcdcdc'
                        }}}>
                            <Checkbox
                                checked={props.selected.findIndex((item) => item?.id === variant?.id) >= 0}
                                sx = {{
                                    color: '#40B6BE',
                                    '&.Mui-checked': {
                                        color: '#40B6BE',
                                    }
                                }}
                            />
                            {props.showAvatar &&
                                <div
                                    className={props.selected.findIndex((item) => item?.id === variant?.id) >= 0 ? 'company-avatar-container selected-company' : 'company-avatar-container'}>
                                    <div className="company-avatar">
                                        <CompanyAvatar company={variant} width={35} componentWidth={35} height={35}
                                                       options={{variant: 'rounded'}}/>
                                    </div>
                                </div>
                            }
                            <ListItemText inset primary={variant.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
}

export default MultipleSelectCheckmarks;
