import {useSelector} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import strings from "../../../localization";
import {Paper} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import {getBanks, getCurrencies, getUnits} from "../../../Util/SiteDataUtil";
import {addBackAccount} from "../../../Services/Company/BankAccountService";
import BankAccountForm from "../../../Components/Forms/Pages/Company/BankAccountForm";
import SnackbarContext from "../../../Context/SnackbarContext";

const formRules = {
    'name': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'bank': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'currency': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
}

const AddBankAccount = (props) => {

    const siteData = useSelector((state) => state.siteData)
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setDrawerTitle(strings.pages.finance.bankAccount.addBankAccount.pageTitle);
    });

    const onSubmit = (data) => {

        setShowLoader(true);

        addBackAccount(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish(response.data.entity);
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <BankAccountForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                currencies={getCurrencies(siteData)}
                banks={ getBanks(siteData) }
                errors={errors} data={data} form={form} control={control}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default AddBankAccount;