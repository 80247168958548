import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getLeaveRequestList(data) {
    return await request('/api/leave-requests', data);
}

export async function getLeaveRequest(id) {
    return await request('/api/leave-requests/' + id);
}

export async function deleteLeaveRequest(id) {
    return await request('/api/leave-requests/' + id, {}, HttpMethod.DELETE);
}

export async function editLeaveRequest(data) {
    return await request('/api/leave-requests/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addLeaveRequest(data) {
    return await request('/api/leave-requests', transformRequestData(data), HttpMethod.POST);
}

export async function changeStateLeaveRequest(data) {
    return await request('/api/leave-requests/change-state/' + data.id, data, HttpMethod.PATCH);
}

function transformRequestData(data) {

    return {
        ...data,
        leaveTypeId: data.leaveType ? data.leaveType.id : null
    }
}

