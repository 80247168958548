import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";
import {isNullOrUndef} from "chart.js/helpers";

export async function getAllAssetTypes(data) {
    return await request('/api/asset-types/all', data);
}

export async function getAssetTypeList(data) {
    return await request('/api/asset-types', data);
}

export async function getAssetType(id) {
    return await request('/api/asset-types/' + id);
}

export async function deleteAssetType(id) {
    return await request('/api/asset-types/' + id, {}, HttpMethod.DELETE);
}

export async function editAssetType(data) {
    return await request('/api/asset-types/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addAssetType(data) {
    return await request('/api/asset-types', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        parentId: data.parent ? data.parent.id : null,
        vendorId: data.vendor ? data.vendor.id : null,
        assetCategoryId: data.assetCategory ? data.assetCategory.id : null,
        tax: data && data.tax ? data.tax : 0,
        canReturnTax: isNullOrUndef(data.canReturnTax) ? false : data.canReturnTax
    }
}
