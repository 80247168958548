import {
    Autocomplete,
    Box,
    FormControl,
    TextField
} from "@mui/material"
import { useCallback, useEffect, useState, } from "react";
import React from "react";
import { Controller } from "react-hook-form";
import { debounce } from 'lodash';
import ClearIcon from "@mui/icons-material/Clear";

const EventAutoCompleteControl = (props) => {
    const [value, setValue] = useState(null);
    const [data, setData] = useState({options: [], loading: !props.options || props.options.length === 0});

    useEffect(() => {
        setValue(props.value || null);
    }, [props.value])

    useEffect(() => {
        setData(value => ({...value, loading: true, options: []}));
        debouncedOptions(props.options);

    },[props.options])

    const debouncedSave = useCallback(
        debounce((newValue) =>{
            if(props.onChange){
                props?.onChange(newValue)
            }
        }, 800),
        []);

    const debouncedOpen = useCallback(
        debounce((newValue) =>{
            if (props?.onOpen) {
                setData(value => ({...value, loading: true}));
                Promise.resolve(props?.onOpen()).then();
            }
        }),
        []);

    const debouncedOptions = useCallback(
        debounce((newValue) =>{
            setData(value => ({loading: false, options: newValue?.length >= 0? newValue: []}));
        }, 1500),
        []);

    const updateValue = (newValue) => {
        debouncedSave(newValue);
    };

    const changeValue = (value) => {
        setValue(value);
        props.setValue(props.name, value)
    }

    const onOpen = () => {
        debouncedOpen()
    }
    return <Box sx={{ minWidth: 120 }} className={'mt-0'}>
        {   props.control &&

            <FormControl fullWidth>

                <Controller
                    rules={props.rules}
                    name={props.name}
                    control={props.control}
                    render={({field}) => AutocompleteWithChipComponent({props, field: {}, changeValue, updateValue, options: data.options,inputValue: props.inputValue, nameKey: props.nameKey, value, onOpen, loading: data.loading})
                    }
                />

        </FormControl>
        }
            </Box>
}

export default EventAutoCompleteControl;

const AutocompleteWithChipComponent = (props) => {

    return <Autocomplete {...props.field}
                multiple
                loading={props.loading}
                id="tags-standard"
                options={props.loading? [] : props.options}
                getOptionLabel={(option) => option[props.nameKey]}
                value={props.value || []}
                         renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={props.label}
                        placeholder="Invites"
                        onKeyDown={props?.onKeyDown}
                        onChange={e=> props.updateValue(e.target.value)}
                        helperText={props.helperText}
                        InputProps={{...params.InputProps,disableUnderline:true}}
                        autoFocus = {props.openOnFocus}
                    />
            )}
                         isOptionEqualToValue={(option, value) =>
                             option && value && option[props.nameKey || 'fullName'] === value[props.nameKey || 'fullName']
                         }
                      onChange={(event, value) => {
                          if (props.field?.onChange) {
                              props.field.onChange(() => props.changeValue(value));
                              return
                          }
                          props.changeValue(value);
                      }}

                onOpen={props.onOpen}
                         clearIcon={props.showClear && <ClearIcon fontSize="small" />}

    />
}