import React, { useEffect, useState } from "react";
import strings from "../../../localization";
import TablePageContext, {
  FilterDefaults,
  TableDataDefaults,
  TablePageOptionDefaults,
} from "../../../Context/TablePageContext";
import TablePage from "../../../Components/DataGrid/TablePage";
import { deletePartner } from "../../../Services/CRM/PartnerService";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import AddCustomer from "./AddCustomer"; // Import the AddCustomer component
import PageState from "../../../Constants/Base/PageState";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {
  deleteCustomer,
  getCustomers,
} from "../../../Services/CRM/CustomerService";
import { changePageSizeState } from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import { changeBreadcrumbs } from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import EditCustomer from "./EditCustomer";

const tableDescription = [
  {
    field: "firstName",
    headerName: strings.pages.crm.customer.customerList.firstName,
    width: 250,
  },
  {
    field: "lastName",
    headerName: strings.pages.crm.customer.customerList.lastName,
    width: 250,
  },
  {
    field: "email",
    headerName: strings.pages.crm.customer.customerList.email,
    width: 250,
  },
];

const filters = [];

const CustomerList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [updatedFilter, setUpdatedFilter] = useState(
    !Boolean(searchParams.toString())
  );
  const [filterOptions, setFilterOptions] = useState({});
  const siteData = useSelector((state) => state.siteData);
  const [columns, setColumns] = useState([]);
  const [pageState, setPageState] = useState(PageState.View);
  const [filter, setFilter] = useState(FilterDefaults);
  const [tableData, setTableData] = useState(TableDataDefaults);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedItems, setSelectedItems] = useState(null);
  const [tablePageOptions, setTablePageOptions] = useState(
    TablePageOptionDefaults
  );
  const referenceType = ReferenceType.PARTNER;
  const permissionGroup = AppPermissions.PARTNER.GROUP;

  const value = {
    tablePageOptions,
    setTablePageOptions,
    navigate,
    selectionModel,
    setSelectionModel,
    selectedItems,
    setSelectedItems,
    filter,
    setFilter,
    pageState,
    setPageState,
    showDeleteDialog,
    setShowDeleteDialog,
    updatedFilter,
    setUpdatedFilter,
  };

  useEffect(() => {
    setColumns([...tableDescription]);
    dispatch(changePageSizeState(PageSizeState.SHORT));

    dispatch(
      changeBreadcrumbs({
        title: strings.pages.crm.customer.customerList.pageTitle,
        hierarchy: [
          { label: strings.navigation.managmentTag },
          { label: Modules.CRM },
          { label: strings.pages.crm.customer.customerList.pageTitle },
        ],
      })
    );

    return () => {
      setTableData({});
    };
  }, []);

  console.log(strings);
  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (!updatedFilter) {
      return;
    }

    fetch();
  }, [filter, updatedFilter]);

  const fetch = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    getCustomers({ ...filter }).then((response) => {
      if (!response || !response.ok) {
        return;
      }

      setTableData({
        loading: false,
        data: response.data.result,
        total: response.data.total,
      });
    });
  };

  const onFinish = () => {
    fetch();
    setPageState(PageState.View);
  };

  const onCancel = () => {
    setPageState(PageState.View);
  };

  return (
    <TablePageContext.Provider value={value}>
      <TablePage
        onFinish={() => onFinish()}
        deleteItem={deleteCustomer}
        tableDescription={columns}
        tableData={tableData}
        permissionGroup={permissionGroup}
        referenceType={referenceType}
        filter={filter}
        filters={filters}
        filterOptions={filterOptions}
        tagPage={<Tag referenceType={referenceType} data={selectedItems} />}
        addPage={
          <AddCustomer
            onCancel={() => onCancel()}
            onFinish={() => onFinish()}
          />
        }
        editPage={
          <EditCustomer
            data={selectedItems}
            onCancel={() => onCancel()}
            onFinish={() => onFinish()}
          />
        }
        addButtonText={strings.components.tablePage.addButton.customer}
      />
    </TablePageContext.Provider>
  );
};

export default CustomerList;
