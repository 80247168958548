import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getWorkspaceAccess(workspaceId){
    return await request('/api/workspace-access', {workspaceId});
}

export async function updateWorkspaceAccess(data) {
    return await request('/api/workspace-access', data, HttpMethod.PUT);
}

export async function getUsersWithWorkspaceAccess(workspaceId){
    return await request('/api/workspace-access-users', {workspaceId});
}
