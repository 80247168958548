import React, { useContext } from "react"
import { FormProvider } from "react-hook-form";
import PageState from "../../../../../Constants/Base/PageState";
import TablePageContext from "../../../../../Context/TablePageContext";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import Button from '@mui/material/Button';
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";

const BusinessYearStartValueForm = ({
                                        onSubmit,
                                        onCancel,
                                        data,
                                        form,
                                        errors,
                                        values,
                                        setValue,
                                        formRules,
                                        bankAccounts,
                                        control
                                    }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id="business-year-start-value-form" className='form' action="#">
                <TextFieldControl
                    name='amount'
                    type={'number'}
                    rules={formRules['amount']}
                    control={data}
                    error={Boolean(errors.amount)}
                    helperText={errors.amount && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.administration.businessYearStartValue.amount}
                    disabled={isPageStateViewDetails()}
                />
                <SelectControl
                    value={values['bankAccount']}
                    setValue={setValue}
                    rules={formRules['bankAccount']}
                    name='bankAccount'
                    control={control}
                    label={strings.forms.administration.businessYearStartValue.bankAccount}
                    options={bankAccounts}
                    nameKey={'name'}
                    valueKey={'id'}
                    error={Boolean(errors.bankAccount)}
                    helperText={errors.bankAccount && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default BusinessYearStartValueForm;