export const CalendarContent = {
    "BIRTHDAY": 1,
    "NON_WORKING_DAY": 2,
    "LEAVE": 3,
    "ORGANISATION_CONTRACT": 4,
    "ORGANISATION_CONTRACT_PART": 5,
    "CONTRACT": 6,
    "CONTRACT_PART": 7,
    "PROJECT": 8,
    "CustomEvent": 9,
    "SCHEDULED_CHECK_PENDING": 10,
    "SCHEDULED_CHECK_IN_PROGRESS": 11,
    "SCHEDULED_CHECK_DONE": 12,
    "SCHEDULED_CHECK_CANCELLED": 13,
}

export const CalendarContentClass = {
    "BIRTHDAY": 'birthday',
    "NON_WORKING_DAY": 'non-working-day',
    "LEAVE": 'leave',
    "ORGANISATION_CONTRACT": 'organisation-contract',
    "ORGANISATION_CONTRACT_PART": 'organisation-contract-part',
    "CONTRACT": 'contract',
    "CONTRACT_PART": 'contract-part',
    "PROJECT": 'project',
    "CUSTOM_EVENT": 'custom-event',
    "SCHEDULED_CHECK_PENDING": 'schedule-check-pending',
    "SCHEDULED_CHECK_IN_PROGRESS": 'schedule-check-pending',
    "SCHEDULED_CHECK_DONE": 'schedule-check-pending',
    "SCHEDULED_CHECK_CANCELLED": 'schedule-check-pending',
}

export const ClassFromContent = {
    1: CalendarContentClass.BIRTHDAY,
    2: CalendarContentClass.NON_WORKING_DAY,
    3: CalendarContentClass.LEAVE,
    4: CalendarContentClass.ORGANISATION_CONTRACT,
    5: CalendarContentClass.ORGANISATION_CONTRACT_PART,
    6: CalendarContentClass.CONTRACT,
    7: CalendarContentClass.CONTRACT_PART,
    8: CalendarContentClass.PROJECT,
    9: CalendarContentClass.CUSTOM_EVENT,
    10: CalendarContentClass.SCHEDULED_CHECK_PENDING,
    11: CalendarContentClass.SCHEDULED_CHECK_IN_PROGRESS,
    12: CalendarContentClass.SCHEDULED_CHECK_DONE,
    13: CalendarContentClass.SCHEDULED_CHECK_CANCELLED,
}

export const CalendarContentSource = {
    "BIRTHDAY": '/images/calendar-icons/birthday.svg',
    "NON_WORKING_DAY": "/images/calendar-icons/non-working.svg",
    "LEAVE": "/images/calendar-icons/leave.svg",
    "ORGANISATION_CONTRACT": "/images/calendar-icons/organisation-contract.svg",
    "ORGANISATION_CONTRACT_PART": "/images/calendar-icons/organisation-contract-part.svg",
    "CONTRACT": "/images/calendar-icons/contract.svg",
    "CONTRACT_PART": "/images/calendar-icons/contract-part.svg",
    "PROJECT": '/images/calendar-icons/project.svg',
    "CUSTOM_EVENT": '/images/calendar-icons/custom-event.svg',
    "SCHEDULED_CHECK_PENDING": '/images/calendar-icons/scheduled-check-pending.svg',
    "SCHEDULED_CHECK_IN_PROGRESS": '/images/calendar-icons/scheduled-check-in-progress.svg',
    "SCHEDULED_CHECK_DONE": '/images/calendar-icons/scheduled-check-done.svg',
    "SCHEDULED_CHECK_CANCELLED": '/images/calendar-icons/scheduled-check-cancelled.svg',
}

export const SourceFromContent = {
    1: CalendarContentSource.BIRTHDAY,
    2: CalendarContentSource.NON_WORKING_DAY,
    3: CalendarContentSource.LEAVE,
    4: CalendarContentSource.ORGANISATION_CONTRACT,
    5: CalendarContentSource.ORGANISATION_CONTRACT_PART,
    6: CalendarContentSource.CONTRACT,
    7: CalendarContentSource.CONTRACT_PART,
    8: CalendarContentSource.PROJECT,
    9: CalendarContentSource.CUSTOM_EVENT,
    10: CalendarContentSource.SCHEDULED_CHECK_PENDING,
    11: CalendarContentSource.SCHEDULED_CHECK_IN_PROGRESS,
    12: CalendarContentSource.SCHEDULED_CHECK_DONE,
    13: CalendarContentSource.SCHEDULED_CHECK_CANCELLED,
}

export const DrawerContentSource = {
    "BIRTHDAY": '/images/calendar-icons/drawer/birthday.svg',
    "NON_WORKING_DAY": "/images/calendar-icons/drawer/non-working.svg",
    "LEAVE": "/images/calendar-icons/drawer/leave.svg",
    "ORGANISATION_CONTRACT": "/images/calendar-icons/drawer/organisation-contract.svg",
    "ORGANISATION_CONTRACT_PART": "/images/calendar-icons/drawer/organisation-contract-part.svg",
    "CONTRACT": "/images/calendar-icons/drawer/contract.svg",
    "CONTRACT_PART": "/images/calendar-icons/drawer/contract-part.svg",
    "PROJECT": '/images/calendar-icons/drawer/project.svg',
    "CUSTOM_EVENT": '/images/calendar-icons/drawer/custom.svg',
    "SCHEDULED_CHECK": '/images/calendar-icons/drawer/scheduled-check.svg',
    "SCHEDULED_CHECK_PENDING": '/images/calendar-icons/scheduled-check-pending.svg',
    "SCHEDULED_CHECK_IN_PROGRESS": '/images/calendar-icons/scheduled-check-in-progress.svg',
    "SCHEDULED_CHECK_DONE": '/images/calendar-icons/scheduled-check-done.svg',
    "SCHEDULED_CHECK_CANCELLED": '/images/calendar-icons/scheduled-check-cancelled.svg',
}