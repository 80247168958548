import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from '../../../../Controls/Inputs/AutoCompleteControl';

const PartnerContactForm = ({
                         onSubmit,
                         onCancel,
                         data,
                         form,
                         errors,
                         partners,
                         values,
                         setValue,
                         formRules,
                         control,
                         onChangePartnersFilter
                     }) => {

    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;


    return (
        <FormProvider {...form}>
            <form id='partner-contact-form' className='form' action="#">

                <TextFieldControl
                    name='firstName'
                    rules={formRules['firstName']}
                    control={data}
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.crm.partnerContactForm.firstName}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='lastName'
                    rules={formRules['lastName']}
                    control={data}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.crm.partnerContactForm.lastName}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='email'
                    rules={formRules['email']}
                    control={data}
                    error={Boolean(errors.email)}
                    helperText={errors.email && strings.forms.common.emailFormatError}
                    label={strings.forms.crm.partnerContactForm.email}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='phone'
                    rules={formRules['phone']}
                    control={data}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.crm.partnerContactForm.phone}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='skype'
                    control={data}
                    error={Boolean(errors.skype)}
                    helperText={errors.skype && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.crm.partnerContactForm.skype}
                    disabled={isPageStateViewDetails()}
                />

                 <TextFieldControl
                    name='linkedin'
                    rules={formRules['linkedin']}
                    control={data}
                    error={Boolean(errors.linkedin)}
                    helperText={errors.linkedin && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.crm.partnerContactForm.linkedin}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    value={values['partner']}
                    rules={formRules['partner']}
                    setValue={setValue}
                    name='partner'
                    label={strings.forms.crm.partnerContactForm.partner}
                    error={Boolean(errors.partner)}
                    helperText={errors.partner && strings.forms.common.thisFieldIsRequired}
                    options={partners}
                    control={control}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onChangePartnersFilter}
                    onOpen={() =>onChangePartnersFilter('')}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }

            </form>
        </FormProvider>
    );
}

export default PartnerContactForm;