import React, { useContext, useEffect, useState } from "react";
import TablePageContext from "../../../../Context/TablePageContext";
import PageState from "../../../../Constants/Base/PageState";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import { FormProvider } from "react-hook-form";
import Button from "@mui/material/Button";
import CheckBoxControl from "../../../Controls/Inputs/CheckBoxControl";
import FileUploadComponent from "../../../Controls/FileUploadComponent";
import { toBase64 } from "../../../../Util/ImageUtil";
import EventAutoCompleteControl from "../../../Controls/Inputs/EventAutoCompleteControl";
import EventDateTimeControl from "../../../Controls/Inputs/EventDateTimeControl";

const AddEventForm = ({
  onSubmit,
  onCancel,
  control,
  form,
  data,
  users,
  errors,
  values,
  setValue,
  setInputValue,
  formRules,
  register,
  allDay,
  trigger,
}) => {
  const { pageState } = useContext(TablePageContext);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
  const [imagePreview, setImagePreview] = useState(values["image"]);
  const [files, setFiles] = useState([]);
  const [startDate, setStartDate] = useState(values["startDate"]);
  const [additionalRules, setAdditionalRules] = useState({});

  useEffect(() => {
    if (form.formState.isSubmitted) {
      trigger("endDate");
    }
  }, [additionalRules, values["endDate"]]);

  useEffect(() => {
    setStartDate(values["startDate"]);
    setValue("endDate", new Date(values["endDate"]));
  }, [values["startDate"]]);

  useEffect(() => {
    changeAdditionalRules(startDate);
  }, [startDate]);

  const changeStartDate = (date) => {
    setValue("startDate", date);
    setStartDate(date);
  };

  const changeAdditionalRules = (date) => {
    if (!date) {
      setAdditionalRules({});
      return;
    }
    setAdditionalRules({
      min: { value: date, message: strings.forms.hr.presence.dateTimeErorr },
    });
  };

  const uploadLogo = async (files) => {
    setDisabledSubmit(true);
    const file = files[0];
    setValue("file", file);
    setDisabledSubmit(false);
  };

  useEffect(() => {
    if (values["files"]) {
      setFiles(values["files"]);
      setValue("file", null);
    }
  }, [values["files"]]);

  return (
    <FormProvider {...form}>
      <form id="addEvent-form" className="form" action="#">
        <TextFieldControl
          name="name"
          rules={formRules["name"]}
          control={control}
          error={Boolean(errors.name)}
          helperText={errors.name && strings.forms.common.thisFieldIsRequired}
          label={strings.forms.calendar.addEvent.name}
        />
        <TextFieldControl
          name="description"
          rules={formRules["description"]}
          control={control}
          error={Boolean(errors.description)}
          helperText={
            errors.description && strings.forms.common.thisFieldIsRequired
          }
          label={strings.forms.calendar.addEvent.description}
          multiline={true}
          rows={4}
        />
        <div className="dateBox" title="Date">
          <CheckBoxControl
            name={"allDay"}
            label={strings.forms.calendar.addEvent.allDay}
            control={data}
            value={values["allDay"]}
            setValue={setValue}
            ref={register}
          />
          <div>
            <div>
              <EventDateTimeControl
                data={data}
                form={form}
                rules={formRules["startDate"]}
                label={strings.forms.calendar.addEvent.startDate}
                name={"startDate"}
                value={startDate}
                setValue={(name, value) => changeStartDate(value)}
                dateTime
                allDay={allDay}
              />
            </div>

            <div>
              <EventDateTimeControl
                data={data}
                form={form}
                rules={{ ...formRules["endDate"], ...additionalRules }}
                error={Boolean(errors.endDate)}
                helperText={errors.endDate && errors.endDate.message}
                label={strings.forms.calendar.addEvent.endDate}
                name={"endDate"}
                value={values["endDate"]}
                minDate={startDate}
                minDateTime={startDate}
                setValue={setValue}
                dateTime
                allDay={allDay}
              />
            </div>
          </div>
        </div>
        <TextFieldControl
          name="location"
          control={control}
          error={Boolean(errors.location)}
          label={strings.forms.calendar.addEvent.location}
        />
        <TextFieldControl
          name="url"
          control={control}
          error={Boolean(errors.url)}
          label={strings.forms.calendar.addEvent.url}
        />
        <div className="eventAutoComplete">
          <EventAutoCompleteControl
            value={values["users"]}
            setValue={setValue}
            inputValue={values["inputValue"]}
            setInputValue={setInputValue}
            name="users"
            label={strings.forms.calendar.addEvent.addInvites}
            control={control}
            options={users}
            getOptionLabel={(option) => option.fullName}
            nameKey={"fullName"}
            valueKey={"id"}
          />
        </div>
        <div>
          {!isPageStateViewDetails() && (
            <FileUploadComponent
              hideUploadButton={false}
              onDrop={uploadLogo}
              files={files}
              setFiles={setFiles}
            />
          )}
          <div className="logo">
            {imagePreview && <img src={imagePreview} width={200} />}
          </div>
        </div>
        {!isPageStateViewDetails() && (
          <div className="submit-container">
            <Button
              variant="contained"
              color="primary"
              className="btn1"
              onClick={onSubmit}
            >
              {strings.forms.common.save}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="btn1"
              onClick={onCancel}
            >
              {strings.forms.common.cancel}
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
};
export default AddEventForm;
