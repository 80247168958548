import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getCustomers(data){
    return await request('/api/customers', data);
}

export async function addCustomer(data) {
    return await request('/api/customers', data, HttpMethod.POST);
}

export async function editCustomer(data) {
    return await request('/api/customers/'+ data.id, data, HttpMethod.PUT);
}

export async function deleteCustomer(id) {
    return await request('/api/customers/' + id, {}, HttpMethod.DELETE);
}