import React, {useContext, useEffect, useState} from "react";
import DrawerContext from "../../Context/DrawerContext";
import SnackbarContext from "../../Context/SnackbarContext";
import {useForm} from "react-hook-form";
import strings from "../../localization";
import {addEvent} from "../../Services/Calendar/EventService";
import AddEventForm from "../../Components/Forms/Pages/Calendar/AddEventForm";
import PageState from "../../Constants/Base/PageState";
import {getUsers} from "../../Services/User/UserService";
import {addDocument} from "../../Services/DocumentManagement/DocumentService";
import {Paper} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

const formRules = {
    'name': {required: true},
    'description': {required: true},
}

const AddEvent = (props) => {

    const form = useForm({
        defaultValues: {
            startDate: new Date(),
            endDate: new Date(),
            location: " ",
            url: " ",
            allDay: false,
        }
    });
    const {data, control, watch, handleSubmit,register, getValues, setValue, trigger, formState: {errors}} = form;
    const {setDrawerTitle} = useContext(DrawerContext);
    const {showMessage} = useContext(SnackbarContext);
    const [users, setUsers] = useState([]);
    const [ userId, setUserId ] = useState(-1);
    const [showLoader, setShowLoader] = useState(false);
    const allDay = watch("allDay");

    useEffect(() => {
        setDrawerTitle(strings.forms.calendar.addEvent.pageTitle);
    });

    useEffect(() => {
        const subscription = watch((value) => {});
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        setValue('allDay', allDay);
    }, [allDay]);

    useEffect(() => {
        fetch().then();
    }, [])


    const fetch = async () => {
        const users = await getUsers();
        fetchUsers();

        setUsers(users);
    }

    const fetchUsers = (term = '') => {
        getUsers({term}).then(response => {
            setUsers(response.data.result);
        });
    }


    const onSubmit = (data) => {
        setShowLoader(true);
        addEvent(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok){
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            if(data.file) {

                data = {file: data.file, name: data.file.name, documentSize: data.file.size, referenceId: response.data.entity.id, referenceType: 64}

                addDocument(data).then(response => {
                    setShowLoader(false);
                    if (!response || !response.ok) {
                        showMessage(strings.commonMessages.errorAdding, 'error');
                        if (strings.error.DocumentHandler[response.response.data.message]) {
                            showMessage(strings.error.DocumentHandler[response.response.data.message], 'error');
                        }
                        props.onFinish();
                        return response;

                    }

                    showMessage(strings.commonMessages.addingSuccessful, 'success');
                    props.onFinish();
                });
            }
            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
        props.setPage(PageState.View)

    }

    const onCancel = () => {
        props.setPage(PageState.View)
    }

    const onDataChange = (item) => {
        if(item === null){
            setUserId(-1);
            return;
        }
        setUserId(item?.id);
    }

    return<div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress />
            }
            <AddEventForm
                form={form} formRules={formRules} errors={errors} watch={watch}
                control={control} data={data} values={getValues()} setValue={setValue}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}
                users={users} onDataChange={onDataChange} allDay={allDay} trigger={trigger}
            />
        </Paper>
    </div>
}
export default AddEvent;