import strings from "../../localization";

const PartnerLegalType = {
    PRIVATE: 1,
    PUBLIC: 2
}

export function getPartnerLegalTypes() {
    return [
        {
            id: PartnerLegalType.PRIVATE,
            name: getPartnerLegalTypeString(PartnerLegalType.PRIVATE)
        },
        {
            id: PartnerLegalType.PUBLIC,
            name: getPartnerLegalTypeString(PartnerLegalType.PUBLIC)
        }
    ];
}

export function getPartnerLegalTypeString(partnerLegalType) {
    switch(partnerLegalType) {
        case PartnerLegalType.PRIVATE: return strings.constants.crm.partnerLegalType.private;
        case PartnerLegalType.PUBLIC: return strings.constants.crm.partnerLegalType.public;
        default: return ''
    }
}

export default PartnerLegalType;