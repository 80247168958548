import {createContext} from "react";
import PageState from "../Constants/Base/PageState";

const TablePageContext = createContext({
    page: 1,
    pageStatus: PageState.View,
    showSnackbar: false,
    messageSnackbar: '',
    alertType: 'success',
    setPage: () => {},
    setPageStatus: () => {},
    setShowSnackbar: () => {},
    setMessageSnackbar: () => {},
    setAlertType: () => {},
});


export default TablePageContext;

export const FilterDefaults = {
    page: 1,
    perPage: parseInt(process.env.REACT_APP_ROWS_PER_PAGE_DEFAULT),
    term: ''
}

export const TableDataDefaults = {
    data: [],
    total: 0,
    loading: false
};

export const TablePageOptionDefaults = {
    showYearFilter: false,
    showMonthFilter: false,
    showActionAdd: true,
    showActionEdit: true,
    showActionView: true,
    showActionDelete: true,
    showActionDocument: true,
    showTagView: true,
    showTagBookmark: true,
    showConnectDisconnect: true,
}

export const filterState = {
    show: 1,
    close: 2
}
