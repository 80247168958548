import React from "react";
import {  FolderIcon } from "../../../Util/ImageUtil";
import {addCompanyDocumentFolder, deleteCompanyDocumentFolder, editCompanyDocumentFolder} from "../../../Services/CompanyDocumentManagement/CompanyDocumentFolderService";
import {getCompanyDocumentFolders} from "../../../Services/CompanyDocumentManagement/CompanyDocumentFolderService";
import TreeViewList from "../../../Components/Tree/TreeViewList";
import strings from "../../../localization";

const CompanyDocumentFileFolder = (props) =>  {


    const expandHandler = (parent) => {
        if(parent === null) {
            props.onSelectedFolderChange(null)
        }else{
            props.onSelectedFolderChange(parent.id)
        }
    }

    return (
        <TreeViewList
            onExpand={expandHandler}
            folderIcon={FolderIcon}
            getFolders={getCompanyDocumentFolders}
            addFolder={addCompanyDocumentFolder}
            addStringModal={strings.components.treeViewList.createNewFolder}
            addStringButton={strings.components.treeViewList.addFolder}
            editFolder={editCompanyDocumentFolder}
            deleteFolder={deleteCompanyDocumentFolder}
            permissionGroup={props.permissionGroup}
            addItemString={strings.components.treeViewList.createNewFolder}
            editItemString={strings.components.treeViewList.editFolder}>
        </TreeViewList>
    );

}

export default CompanyDocumentFileFolder;