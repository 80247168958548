import strings from "../../localization";

const BillableType = {
    BILLABLE: 1,
    BILLDISABLE: 2,
}

export function getBillableTypes() {
    return [
        {
            id: BillableType.BILLABLE,
            name: getBillableTypeString(BillableType.BILLABLE)
        },
        {
            id: BillableType.BILLDISABLE,
            name: getBillableTypeString(BillableType.BILLDISABLE)
        },
        
    ];
}

export function getBillableTypeString(billableType) {
    switch(billableType) {
        case BillableType.BILLABLE: return strings.constants.project.projectTimeLog.billable;
        case BillableType.BILLDISABLE: return strings.constants.project.projectTimeLog.notBillable;
    }
}

export default BillableType;