import React from "react";
import { FormProvider } from "react-hook-form";
import strings from "../../../../../localization";
import Button from "@mui/material/Button";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";

const BusinessTripTravelWarrantForm = ({
  onSubmit,
  onCancel,
  fetchUsers,
  control,
  form,
  values,
  setValue,
  users,
  data,
  currencies,
  formRules,
}) => {
  return (
    <FormProvider {...form}>
      <form id="business-trip-list-page" className="form" action="#">
        <TextFieldControl
          name="reasonToTravel"
          label={strings.pages.hr.businessTrip.travelWarrantForm.reasonToTravel}
          disabled={true}
        />
        <TextFieldControl
          name="travelDestination"
          label={
            strings.pages.hr.businessTrip.travelWarrantForm.travelDestination
          }
          disabled={true}
        />
        <DateTimeControl
          label={strings.pages.hr.businessTrip.travelWarrantForm.dayOfDeparture}
          name={"dayOfDeparture"}
          value={values["dayOfDeparture"]}
          setValue={setValue}
          control={data}
          disabled={true}
        />
        <TextFieldControl
          name="tripDuration"
          type="number"
          label={strings.pages.hr.businessTrip.travelWarrantForm.tripDuration}
          control={data}
          disabled={true}
        />
        <TextFieldControl
          name="meansOfTransport"
          label={
            strings.pages.hr.businessTrip.travelWarrantForm.meansOfTransport
          }
          control={data}
          disabled={true}
        />
        <TextFieldControl
          name="freeAccommodationAndFood"
          label={
            strings.pages.hr.businessTrip.travelWarrantForm
              .freeAccommodationAndFood
          }
          control={data}
          disabled={true}
        />
        <div className="currencies">
          <AutoCompleteControl
            className="currency-wrapper"
            value={values["domesticCurrency"]}
            setValue={setValue}
            name="domesticCurrency"
            control={control}
            options={currencies}
            nameKey={"code"}
            valueKey={"id"}
            disabled={true}
          />
          <TextFieldControl
            name="amountOfDailyWageInCountry"
            type="number"
            label={
              strings.pages.hr.businessTrip.travelWarrantForm
                .amountOfDailyWageInCountry
            }
            control={data}
            disabled={true}
          />
        </div>

        <div className="currencies">
          <AutoCompleteControl
            className="currency-wrapper"
            value={values["foreignCurrency"]}
            setValue={setValue}
            name="foreignCurrency"
            control={control}
            options={currencies}
            nameKey={"code"}
            valueKey={"id"}
            disabled={true}
          />
          <TextFieldControl
            name="amountOfDailyWageAbroad"
            type="number"
            label={
              strings.pages.hr.businessTrip.travelWarrantForm
                .amountOfDailyWageAbroad
            }
            control={data}
            disabled={true}
          />
        </div>

        <TextFieldControl
          name="advancePaymentDomesticCurrency"
          type="number"
          label={
            strings.pages.hr.businessTrip.travelWarrantForm
              .advancePaymentInDomesticCurrency
          }
          control={data}
          disabled={true}
        />

        <TextFieldControl
          name="advancePaymentForeignCurrency"
          type="number"
          label={
            strings.pages.hr.businessTrip.travelWarrantForm
              .advancePaymentInForeignCurrency
          }
          control={data}
          disabled={true}
        />

        <AutoCompleteControl
          value={values["companyUser"]}
          setValue={setValue}
          name="companyUser"
          control={control}
          label={strings.pages.hr.businessTrip.travelWarrantForm.employee}
          options={[users]}
          nameKey={"fullName"}
          valueKey={"id"}
          onChange={fetchUsers}
          disabled={true}
        />

        <div className="submit-container">
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.forms.common.save}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.forms.common.cancel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default BusinessTripTravelWarrantForm;
