import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAttributeValues(id) {
    return await request('/api/attribute-values/' + id);
}

export async function getAttributeValuesList(data) {
    return await request('/api/attribute-values', data);
}

export async function addAttributeValue(data) {
    return await request('/api/attribute-value', data, HttpMethod.POST);
}

export async function editAttributeValue(data) {
    return await request('/api/attribute-value/' + data.id, data, HttpMethod.PUT);
}

export async function deleteAttributeValue(id) {
    return await request('/api/attribute-value/' + id, {}, HttpMethod.DELETE);
}

export  async function connectAttributeValue(id){
    return await request('/api/attribute-value/connect/' + id, {}, HttpMethod.PATCH);
}

export  async function disconnectAttributeValue(id){
    return await request('/api/attribute-value/disconnect/' + id, {}, HttpMethod.PATCH);
}