import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getRole(id) {
    return await request('/api/roles/' + id);
}

export async function getAllRoles() {
    return await request('/api/roles/all');
}

export async function getRoles(data) {
    return await request('/api/roles', data);
}

export async function addRole(data) {
    return await request('/api/roles', transformRequestData(data), HttpMethod.POST);
}

export async function editRole(data) {
    return await request('/api/roles/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteRole(id) {
    return await request('/api/roles/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return data;
}