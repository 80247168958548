import React from "react";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import PageState from "../../../Constants/Base/PageState";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import strings from "../../../localization";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import {changePageSizeState} from "../../../Slices/PageSlice";
import {formatColumnDate, formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import {deleteLeave, getLeaveList} from "../../../Services/HR/LeaveService";
import AddLeave from "./AddLeave";
import EditLeave from "./EditLeave";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getLeaveTypes, getLeaveType } from "../../../Services/HR/LeaveTypeService";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {getCurrentMonth, getCurrentYear} from "../../../Util/DateUtil";
import {fetchOptions} from '../../../Services/Base/FilterOptionsService';
import { getEmployees, getEmployeeByUser } from "../../../Services/User/UserService";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import { renderColumnNumber } from "../../../Components/DataGrid/ValueCellRender";

const tableDescription = [
    {
        field: 'fromDate', headerName: strings.pages.hr.leave.leaveList.fromDate, width: 100,
        valueFormatter: (params) => formatColumnDate(params),
    },
    {
        field: 'toDate', headerName: strings.pages.hr.leave.leaveList.toDate, width: 100,
        valueFormatter: (params) => formatColumnDate(params),
    },
    {
        field: 'type', headerName: strings.pages.hr.leave.leaveList.type, width: 100,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
    },
    {
        field: 'subtractDays', headerName: strings.pages.hr.leaveType.leaveTypeList.days, width: 60, 
        renderCell: (params) => renderColumnNumber(params),
        align: 'center'},
    {
        field: 'employeeUser',
        headerName: strings.pages.hr.leave.leaveList.employeeUser,
        valueFormatter: (params) => formatColumnObject(params, 'fullName'),
        width: 200
    }
];


const LeaveList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(false);
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState({...FilterDefaults, year: getCurrentYear(), month: getCurrentMonth()});
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState({
        ...TablePageOptionDefaults,
        showYearFilter: true,
        showMonthFilter: true
    });
    const referenceType = ReferenceType.LEAVE;
    const [filterOptions, setFilterOptions] = useState({});
    const permissionGroup = AppPermissions.LEAVE.GROUP;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(()=>  {
        setTableData({
            ...tableData,
            loading: true
        })
    },[])

    useEffect(() => {

        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));

        dispatch(changeBreadcrumbs({
            title: strings.pages.hr.leave.leaveList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.pages.hr.leave.leaveList.pageTitle},
            ],        
        }));

        if(!searchParams.toString()){
            setSearchParams({year: getCurrentYear(), month: getCurrentMonth()}, {replace: true});
        }

        fetchFilters();

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    const fetchEmployees = async (term, filterFetch) => {
        return fetchOptions('employee', searchParams, getEmployees, getEmployeeByUser, setFilterOptions, term, filterFetch, 'user');
    }

    const fetchLeaveTypes = async (term, filterFetch) => {
        return fetchOptions('leaveType', searchParams, getLeaveTypes, getLeaveType, setFilterOptions, term, filterFetch);
    }

    const fetchFilters = async () => {
        const employees = await fetchEmployees();
        const leaveTypes = await fetchLeaveTypes();
        
        setFilterOptions({
            employee: employees,
            leaveType: leaveTypes, 
            fetched: 1,
        });
    }

    const filters = [
        {
            name: 'type',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'leaveType',
            label: strings.pages.hr.leave.leaveList.type,
            onChange: (term) => fetchLeaveTypes(term, true),
        },
        {
            name: 'employee',
            nameKey: 'fullName',
            valueKey: 'id',
            optionsName: 'employee',
            label: strings.pages.hr.leave.leaveList.employeeUser,
            onChange: (term) => fetchEmployees(term, true),
        }
    ]


    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getLeaveList({
            ...filter
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }
    return <TablePageContext.Provider value={value}>
    <TablePage onFinish={() => onFinish()} deleteItem={deleteLeave} tableDescription={columns}
               tableData={tableData} filter={filter} filters={filters}
               filterOptions={filterOptions} showDeletingError={true}
               referenceType={referenceType}
               permissionGroup={permissionGroup}
               tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
               editPage={<EditLeave data={ selectedItems }
                                      onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
               addPage={<AddLeave onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
               addButtonText={strings.components.tablePage.addButton.leave}
            />
    </TablePageContext.Provider>
}

export default LeaveList;
