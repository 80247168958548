import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function addCustomEventUserAccess(data) {
    return await request('/api/customEventUserAccess', transformRequestData(data), HttpMethod.POST);
}

export async function getByCustomEvent(data){
    return await request('/api/customEventUserAccess/' + data.id, {}, HttpMethod.GET);
}

function transformRequestData(data) {
    return {
        ...data,
    }
}