import React, {useContext, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {
    downloadInoInvoice,
    downloadInvoice,
    getInvoice,
    getInvoiceCreateData, saveInvoice,
    viewInoInvoice,
    viewInvoice
} from "../../../Services/Finance/InvoiceService";
import {dateToString, getCurrentYear} from "../../../Util/DateUtil";
import {useLocation} from "react-router-dom";
import InvoiceType, {getInvoiceTypes} from "../../../Constants/Finance/InvoiceType";
import InvoiceDirection from "../../../Constants/Finance/InvoiceDirection";
import {
    Accordion, AccordionDetails,
    AccordionSummary, Box,
    Grid, LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import strings from "../../../localization";
import InvoiceControls from "../../../Components/Forms/Pages/Finance/Invoice/InvoiceControls";
import EditorInput from "../../../Components/Controls/Inputs/EditorInput";
import InvoiceHeaderForm from "../../../Components/Forms/Pages/Finance/Invoice/InvoiceHeaderForm";
import InvoiceItemsForm from "../../../Components/Forms/Pages/Finance/Invoice/InvoiceItemsForm";
import {useForm} from "react-hook-form";
import InvoiceItemsTable from "../../../Components/Forms/Pages/Finance/Invoice/InvoiceItemsTable";
import InvoiceTotal from "../../../Components/Forms/Pages/Finance/Invoice/InvoiceTotal";
import {EditorState} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {convertFromHTML, convertToHTML} from 'draft-convert';
import {getInvoiceSettings} from "../../../Services/Company/CompanyService";
import PageState from "../../../Constants/Base/PageState";
import SnackbarContext from "../../../Context/SnackbarContext";
import {getPartners} from "../../../Services/CRM/PartnerService";
import {getBankAccounts} from "../../../Services/Company/BankAccountService";
import {getProductServiceList} from "../../../Services/Product/ProductServiceService";
import {getProjectList} from "../../../Services/Project/ProjectService";
import {getContracts} from "../../../Services/Finance/ContractService";
import {getContractParts} from "../../../Services/Finance/ContractPartService";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import invoiceDirection from "../../../Constants/Finance/InvoiceDirection";
import {isInoInvoice} from "../../../Util/InvoiceUtil";
import PartnerLegalType from "../../../Constants/CRM/PartnerLegalType";

const ShowMoreIcon = ({expanded}) => {
    return(
        <div className = 'show-more-icon-container'>
            <p>{expanded ? 
                strings.pages.finance.invoice.createInvoice.showLess 
                : 
                strings.pages.finance.invoice.createInvoice.showMore}
            </p>
            <img src = '/images/arrow-open.svg' />
        </div>
    );
}

const CreateInvoice = (props) => {
    const location = useLocation();

    const {showMessage} = useContext(SnackbarContext);
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const [invoiceExpanded, setInvoiceExpanded] = useState(false);
    const [billExpanded, setBillExpanded] = useState(false);

    const [siteData, setSiteData] = useState({ partners: [],
        accounts: [],
        invoiceTypes: [],
        company: undefined,
        productServices: [],
        contracts: [],
        contractParts: [],
        taxes: [],
        projects: []
    });

    const [pageState, setPageState] = useState(PageState.View);
    const [invoiceId, setInvoiceId] = useState(location.state.id ? location.state.id : -1);

    const [companyCurrencyCode, setCompanyCurrencyCode] = useState('');

    const [invoiceData, setInvoiceData] = useState({
        invoiceDate: new Date(),
        deliveryDate: new Date(),
        exchangeRateDate: new Date(),
        paymentDueDate: new Date(),
        invoiceType: InvoiceType.INVOICE,
        items: [],
        account: undefined,
        invoiceDirection: location.state.invoiceDirection ?
            location.state.invoiceDirection : InvoiceDirection.OUT,
        currency: undefined,
    });

    let form = useForm({mode: 'onBlur'});
    const {data, handleSubmit, watch, reset, getValues, setValue, control, formState: {errors}} = form;

    const [editor, setEditor] = useState(EditorState.createEmpty());

    const [responseInvoiceNumber, setResponseInvoiceNumber] = useState({})
    const [deadline, setDeadline] = useState(0);

    const loadingRef = useRef(false);
    const saveRef = useRef(false);

    useEffect(() => {
        const title = location.state?.invoiceDirection === InvoiceDirection.OUT ? strings.pages.finance.invoice.invoiceList.invoices : strings.pages.finance.invoice.invoiceList.bills
        dispatch(changeBreadcrumbs({
            title: title,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.FINANCE},
                {label: title},
                {label: location.state?.pageState === PageState.Add ? strings.forms.finance.invoice.invoiceHeaderForm.add : strings.forms.finance.invoice.invoiceHeaderForm.edit}
            ],        
        }));
    }, [location.state?.invoiceDirection])

    useEffect(() => {

        if (location.state.pageState) {
            setPageState(location.state.pageState)
        }
    }, [])

    useEffect(() => {
        fetch();
    }, [data])

    useEffect(()=>{
        if(siteData){
            setCompanyCurrencyCode(siteData?.company?.currency?.code)
        }
    }, [siteData])

    useEffect(() => {
        const subscription = watch((data) => {
            setInvoiceData(currentInvoiceData => ({
                ...currentInvoiceData,
                ...data,
            }))
        });
    }, [watch]);

    const setInvoiceNumber = (data, invoiceNumber, pattern) => {

        if (invoiceData.invoiceDirection === InvoiceDirection.IN) {
            return '';
        }

        let result = pattern;
        result = result.replace('${number}', invoiceNumber);
        result = result.replace('${year}', getCurrentYear());
        data.invoiceNumber = result;
        return data;
    }

    const  setInvoicePlace = () => {

        let data = invoiceData;

        if(data.partner && data.partner.country.name && invoiceData.invoiceDirection === invoiceDirection.IN){
                data.issuePlace = data.partner.country.name;
        }

       return data.issuePlace;
    }

    const fetch = () => {
        loadingRef.current = true;
        const empty = async () => {};
        empty().then(() => {
            if (invoiceId <= 0) {
                return;
            }

            getInvoice(invoiceId).then(response => {

                if (!response || !response.ok) {
                    return;
                }

                setInvoiceData(transformResponse(response.data.entity));
                onInvoiceDataChange(transformResponse(response.data.entity));
            })
        }).finally(() => {
            fetchFilters().then();
            loadingRef.current = false;
        });

    }

     const fetchForAutocomplete = async (term = '', getFunction = async (data) => {}, keyInState,updateState = false) => {
        setSiteData(currentSiteData => ({
            ...currentSiteData,
            [keyInState]: []
        }));
        let response = await getFunction({term});
         if (updateState) {
             setSiteData(currentSiteData => ({
                 ...currentSiteData,
                 [keyInState]: response.data.result
             }));
         }
         return response.data.result;
     }

    const fetchPartners = async (term = '', updateState = true) => {
        return await fetchForAutocomplete(term, getPartners, 'partners', updateState);
    }

    const fetchAccounts = async (term = '', updateState = true) => {
        return await fetchForAutocomplete(term, getBankAccounts, 'accounts', updateState);
    }

    const fetchProducts = async (term = '', updateState = true) => {
        return await fetchForAutocomplete(term, getProductServiceList, 'productServices', updateState);
    }

    const fetchProjects = async (term = '', updateState = true) => {
        return await fetchForAutocomplete(term, ({term}) => getProjectList({term}), 'projects', updateState);
    }

    const fetchContracts = async (term = '', updateState = true) => {
        return await fetchForAutocomplete(term, ({term}) => getContracts({term, partner: getValues()?.partner?.id}), 'contracts', updateState);
    }

    const fetchContractParts = async (term = '', updateState = true) => {
        return await fetchForAutocomplete(term, ({term}) => getContractParts({term, contractId: getValues()?.contract?.id}), 'contractParts', updateState);
    }

    const fetchFilters = async () => {
        let invoiceNumber = -1;

        const promises = [
            fetchPartners('', false),
            fetchAccounts('', false),
            fetchProducts('', false),
            fetchProjects('', false),
            getInvoiceCreateData('', false)
        ]


        const responses = await Promise.all(promises);
        const partners = responses[0];
        const accounts = responses[1];
        const productServices = responses[2];
        const projects = responses[3];
        const invoiceDataResponse = responses[4];

        if (!invoiceDataResponse || !invoiceDataResponse.ok) {
            return;
        }

        let data = invoiceData;

        if (invoiceDataResponse.data.accounts && invoiceDataResponse.data.accounts.length > 0) {
            data.account = invoiceDataResponse.data.accounts[0];
        }

        data.invoiceType = getInvoiceTypes()[0];

        if (invoiceDataResponse.data.company) {
            data.company = invoiceDataResponse.data.company;
        }

        if (invoiceData.invoiceDirection === InvoiceDirection.OUT) {
            invoiceNumber = invoiceDataResponse.data.nextInvoiceOrderNumber;
            setResponseInvoiceNumber(setInvoiceNumber(data, invoiceNumber, '${number}/${year}'))
            data = setInvoiceNumber(data, invoiceNumber, '${number}/${year}');
        }
        

        setSiteData(currentSiteData => ({
            ...currentSiteData,
            partners,
            accounts,
            productServices,
            projects,
            invoiceTypes: getInvoiceTypes(),
            company: invoiceDataResponse.data.company,
            taxes: invoiceDataResponse.data.taxes,
            units: invoiceDataResponse.data.units,
        }));
        if (invoiceId <= 0) {
            setInvoiceData(data)
            onInvoiceDataChange(data);

            getInvoiceSettings().then(response => {

                if (!response || !response.ok) {
                    return
                }

                if (response.data.entity?.account && !isInoInvoice(invoiceData, auth)) {
                    data.account = response.data.entity.account;
                }

                if (response.data.entity?.inoAccount && isInoInvoice(invoiceData, auth)) {
                    data.account = response.data.entity.inoAccount;
                }

                if (response.data.entity?.invoicePlace && invoiceData.invoiceDirection === InvoiceDirection.OUT) {
                    data.issuePlace = response.data.entity.invoicePlace;
                }

                if (response.data.entity?.invoiceNumberPattern && invoiceData.invoiceDirection === InvoiceDirection.OUT) {
                    data = setInvoiceNumber(data, invoiceNumber, response.data.entity?.invoiceNumberPattern)
                }

                if (response.data.entity?.deadline) {
                    let date = new Date();
                    date.setDate(date.getDate() + parseInt(response.data.entity.deadline));
                    data.paymentDueDate = date;
                    setDeadline(parseInt(response.data.entity.deadline));
                }

                if(response.data.entity?.currency){
                    data.currency = response.data.entity.currency;
                }

                setInvoiceData(data);
                onInvoiceDataChange(data);
            })

        }
        return data;
    }


    const onInvoiceDataChange = (data) => {
        setValue('invoiceNumber', data.invoiceNumber);
        setValue('invoiceDate', data.invoiceDate);
        setValue('deliveryDate', data.deliveryDate);
        setValue('exchangeRate', data.exchangeRate);
        setValue('exchangeRateDate', data.exchangeRateDate);
        setValue('paymentDueDate', data.paymentDueDate);
        setValue('invoiceType', data.invoiceType);
        setValue('issuePlace', data.issuePlace);
        setValue('partner', data.partner);
        setValue('account', data.account);
        setValue('contract', data.contract);
        setValue('contractPart', data.contractPart);
        setValue('project', data.project);
        setValue('items', data.items);
        setValue('invoiceDirection', data.invoiceDirection);
        setValue('currency', data.currency);
    }

    const transformResponse = (data) => {
        if (data.comment) {
            const convertedState = convertFromHTML(data.comment)
            setEditor(EditorState.createWithContent(convertedState))
        }

        return {
            ...data,
            invoiceDate: data.invoiceDate ? new Date(data.invoiceDate) : null,
            deliveryDate: data.deliveryDate ? new Date(data.deliveryDate) : null,
            paymentDueDate: data.paymentDueDate ? new Date(data.paymentDueDate) : null,
            dateCreated: data.dateCreated ? new Date(data.dateCreated) : null,
            exchangeRateDate: data.exchangeRateDate ? new Date(data.exchangeRateDate) : null,
            invoiceType: data.invoiceType ? getInvoiceTypes().find(x => x.id === data.invoiceType) : -1,
            items: data.items
        }
    }

    const view = () => {
        viewInvoice(invoiceId);
    }

    const download = () => {
        downloadInvoice(invoiceId);
    }

    const viewIno = () => {
        viewInoInvoice(invoiceId);
    }

    const downloadIno = () => {
        downloadInoInvoice(invoiceId);
    }

    const save = () => {

        saveRef.current = true;

        const data = {
            ...invoiceData,
            comment: editor ? convertToHTML(editor.getCurrentContent()) : ''
        };

        saveInvoice({...data, id: invoiceId}).then(response => {

            if (!response || !response.ok) {
                showMessage(strings.pages.finance.invoice.createInvoice.errorSaving, 'error');
                return;
            }

            showMessage(strings.pages.finance.invoice.createInvoice.saved, 'success');

            setInvoiceData(transformResponse(response.data.entity));
            setInvoiceId(response.data.entity.id);

            let invoiceNumber = response.data.entity.invoiceOrderNumber + 1

            setResponseInvoiceNumber(setInvoiceNumber(data, invoiceNumber, '${number}/${year}'));
        }).finally(() => saveRef.current = false)
    }

    const newInvoice = () => {

        setInvoiceId(-1)

        setEditor(null)

        if (invoiceData.invoiceDirection === InvoiceDirection.OUT) {
            setValue('invoiceNumber', responseInvoiceNumber.invoiceNumber)
        } else {
            setValue('invoiceNumber', '')
        }

        setValue('contract', undefined)
        setValue('contractPart', undefined)
        setValue('partner', undefined)
        setValue('invoiceType', undefined)
        setValue('exchangeRate', undefined);
        setValue('items', [])
        setValue('invoiceDate', new Date());
        setValue('deliveryDate', new Date());
        setValue('paymentDueDate', new Date());
        setValue('currency', undefined);
        setValue('issuePlace', '');
    }

    const addItems = (data) => {
        if(data.productService){
            addInvoiceItem(data);

            return;
        }
        addBillItem(data);
    }

    const addBillItem = (data) =>{
        let items = invoiceData.items ? invoiceData.items : [];
        const compare = `${data.name}`
        const item = items.find(x => (x.name.toLocaleLowerCase() === compare.toLocaleLowerCase()));
        
        if(item){
            item.name = data.name;
            item.quantity = data.quantity;
            item.price = data.price;
            item.tax = data.tax;
            item.unit =  data.unit;
            item.deleted = false;

            setInvoiceData(old => ({
                ...old,
                items: items
            }));

            return;
        } 
        
        items.push(data);

        setInvoiceData(old => ({
            ...old,
            items: items
        }));
    }

    const addInvoiceItem = (data) =>{
        let items = invoiceData.items ? invoiceData.items : [];
        let item = items.find(x => x.productService && x.productService.id === data.productService.id);

        if(item) {
            item.name =  data.productService?.name;
            item.price = data.productService?.price;
            item.discount =  data.productService?.discount;
            item.quantity = data.quantity;
            item.tax = data.productService?.tax;
            item.productService = data.productService;
            item.unit = data.productService?.unit;
            item.deleted = false;

            setInvoiceData(currentInvoiceData => ({
                ...currentInvoiceData,
                items: items
            }));

            return;
        }

        const newItem = {
            name: data.productService?.name,
            price: data.productService?.price,
            discount: data.productService?.discount,
            quantity: data.quantity,
            tax: data.productService?.tax,
            productService: data.productService,
            unit: data.productService?.unit,
        }

        items.push(newItem);

        setInvoiceData(currentInvoiceData => ({
            ...currentInvoiceData,
            items: items
        }));
    }

    const deleteItem = (item) => {
        let items = invoiceData.items ? invoiceData.items : [];
        const index = items.indexOf(item);

        if (index >= 0) {
            items[index].deleted = true;
            items[index].price = 0;
            if (!items[index].id) {
                items.splice(index, 1);
            }
        }

        setInvoiceData(currentInvoiceData => ({
            ...currentInvoiceData,
            items: items
        }));
    }

    const canAddItems = () => {
        if (pageState === PageState.Add || pageState === PageState.Edit) {
            let result = Boolean(invoiceData && invoiceData.invoiceType &&
                invoiceData.invoiceDate && invoiceData.issuePlace && invoiceData.deliveryDate &&
                invoiceData.paymentDueDate &&
                invoiceData.partner && invoiceData.account);

            if (isInoInvoice(invoiceData, auth)) {
                return result && invoiceData.exchangeRate && invoiceData.exchangeRateDate && invoiceData.currency;
            }

            return result;
        }
    }

    const canSaveInvoice = () => {
        if(pageState === PageState.Add || pageState === PageState.Edit){
            if(invoiceData.partner?.partnerLegalType === PartnerLegalType.PUBLIC)
            {
                return canAddItems() && invoiceData.contract && invoiceData.items && invoiceData.items.filter(i => !i.deleted).length > 0;
            }
            else{
                return canAddItems() && invoiceData.items && invoiceData.items.filter(i => !i.deleted).length > 0;
            }
        }
    }

    const renderCompanyInfo = () => {

        const address = siteData.company ? siteData.company.address : '';
        const city = siteData.company && siteData.company.city ? siteData.company.city.name : '';
        const zipCode = siteData.company ? siteData.company.zipCode : '';
        const cityZipCode = zipCode ? (city + ', ' + zipCode) : '';
        const country = siteData.company && siteData.company.country ? siteData.company.country.name : '';
        const cityZipCodeCountry = cityZipCode ? (cityZipCode + ', ' + country) : '';
        const taxNumber = siteData.company ? siteData.company.taxNumber : '';
        const registrationNumber = siteData.company ? siteData.company.registrationNumber : '';
        const bankAccount = invoiceData.account ? invoiceData.account.iban : '';
        const swift = invoiceData.account && invoiceData.account.bank ? invoiceData.account.bank.swift : '';
        const phone = siteData.company ? siteData.company.phone : '';
        const email = siteData.company ? siteData.company.email : '';
        const website = siteData.company ? siteData.company.website : '';

        return (
            <div className='company-info'>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.address}:</p><p>{address}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.city}:</p><p>{cityZipCodeCountry}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.taxNumber}:</p><p>{taxNumber}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.registrationNumber}:</p><p>{registrationNumber}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.bankAccount}:</p><p>{bankAccount}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.swift}:</p><p>{swift}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.phone}:</p><p>{phone}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.email}:</p><p>{email}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.website}:</p><p>{website}</p>
                </div>
            </div>
        )
    }

    const renderPartnerInfo = () => {

        const address = invoiceData.partner ? invoiceData.partner.address : '';
        const city = invoiceData.partner ? invoiceData.partner.city : '';
        const zipCode = invoiceData.partner ? invoiceData.partner.zipCode : '';
        const cityZipCode = zipCode ? (city + ', ' + zipCode) : '';
        const country = invoiceData.partner && invoiceData.partner.country ? invoiceData.partner.country.name : '';
        const cityZipCodeCountry = cityZipCode ? (cityZipCode + ', ' + country) : '';
        const taxNumber = invoiceData.partner ? invoiceData.partner.taxNumber : '';
        const registrationNumber = invoiceData.partner ? invoiceData.partner.registrationNumber : '';

        return (
            <div className='partner-info'>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.address}:</p><p>{address}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.city}:</p><p>{cityZipCodeCountry}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.taxNumber}:</p><p>{taxNumber}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.registrationNumber}:</p><p>{registrationNumber}</p>
                </div> 
            </div>
        )
    }

    const renderInvoiceData = () => {

        const dateFormat = getDateFormat();

        const invoiceNumber = invoiceData ? invoiceData.invoiceNumber : '';
        const invoiceDate = invoiceData && invoiceData.invoiceDate ?
            dateToString(invoiceData.invoiceDate, dateFormat) : '';
        const issuePlace = invoiceData ? invoiceData.issuePlace : '';
        const deliveryDate = invoiceData && invoiceData.deliveryDate ?
            dateToString(invoiceData.deliveryDate, dateFormat) : '';
        const paymentDueDate = invoiceData && invoiceData.paymentDueDate ?
            dateToString(invoiceData.paymentDueDate, dateFormat) : '';

        return (
            <div className='invoice-info'>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.invoiceNumber}:</p><p>{invoiceNumber}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.invoiceDate}:</p><p>{invoiceDate}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.invoicePlace}:</p><p>{issuePlace}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.deliveryDate}:</p><p>{deliveryDate}</p>
                </div>
                <div className = 'info-data'>
                    <p>{strings.pages.finance.invoice.createInvoice.dueDate}:</p><p>{paymentDueDate}</p>
                </div>
            </div>
        )
    }

    const renderInoInvoice = () => {
        return (
            <TableBody>
                <Accordion expanded = {invoiceExpanded} onClick = {() => setInvoiceExpanded(!invoiceExpanded)}>
                    <AccordionSummary
                        expandIcon = {<ShowMoreIcon expanded = {invoiceExpanded} />}
                    >
                        <TableRow className='invoice-title'>
                            <TableCell className='invoice-title-left'>
                                <h1>{siteData.company ? siteData.company.name : ''}</h1>
                                <h1>{strings.pages.finance.invoice.createInvoice.invoice}</h1>
                            </TableCell>
                            <TableCell className='invoice-title-right'>
                                <h2>{strings.pages.finance.invoice.createInvoice.billTo}</h2>
                                <h1>{invoiceData.partner ? invoiceData.partner.name : ''}</h1>
                            </TableCell>
                        </TableRow>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableRow className='invoice-header'>
                            <TableCell>
                                {renderCompanyInfo()}
                                {renderInvoiceData()}
                            </TableCell>
                            <TableCell>
                                {renderPartnerInfo()}
                            </TableCell>
                        </TableRow>
                    </AccordionDetails>
                </Accordion>
            </TableBody>);
    }

    const getDateFormat = () => {
        return isInoInvoice(invoiceData, auth) ? 'dd/MM/yyyy' : 'dd.MM.yyyy.'
    }

    const renderDomesticInvoice = () => {
        return (
            <TableBody>
                <Accordion expanded = {invoiceExpanded} onClick = {() => setInvoiceExpanded(!invoiceExpanded)}>
                    <AccordionSummary
                        expandIcon = {<ShowMoreIcon expanded = {invoiceExpanded} />}
                    >
                        <TableRow className='invoice-title'>
                            <TableCell className='invoice-title-left'>
                                <h1>{strings.pages.finance.invoice.createInvoice.invoice}</h1>
                                <h1>{siteData.company ? siteData.company.name : ''}</h1>
                            </TableCell>
                            <TableCell className='invoice-title-right'>
                                <h2>{strings.pages.finance.invoice.createInvoice.domesticBillTo}</h2>
                                <h1>{invoiceData.partner ? invoiceData.partner.name : ''}</h1>
                            </TableCell>
                        </TableRow>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableRow className='invoice-header'>
                            <TableCell>
                                {renderCompanyInfo()}
                                {renderInvoiceData()}
                            </TableCell>
                            <TableCell>
                                {renderPartnerInfo()}
                            </TableCell>
                        </TableRow>
                    </AccordionDetails>
                </Accordion>
            </TableBody>);
    }

    return (<React.Fragment>
            {
                (loadingRef.current || saveRef.current) &&
                <Box>
                    <LinearProgress/>
                </Box>
            }
            <Grid id='create-invoice' className={loadingRef.current || saveRef.current? 'all-readonly': ''}>
                <Paper className='crate-invoice-page invoice-controls'>
                    <InvoiceControls loadingRef={saveRef} data={invoiceData} save={handleSubmit(save)} isInoInvoice={isInoInvoice(invoiceData, auth)}
                                     canSave={canSaveInvoice()} newInvoice={() => newInvoice()} pageState={pageState}
                                     invoiceDirection={invoiceData.invoiceDirection} invoiceId={invoiceId}
                                     view={view} viewIno={viewIno} download={download} downloadIno={downloadIno}/>
                </Paper>
                <Paper className='crate-invoice-page invoice-header-form-container'>
                    <InvoiceHeaderForm
                        control={control} pageState={pageState}
                        values={getValues()} setValue={setValue}
                        errors={errors} data={data} form={form} deadline={deadline}
                        invoiceTypes={siteData.invoiceTypes} partners={siteData.partners}
                        accounts={siteData.accounts} isInoInvoice={isInoInvoice(invoiceData, auth)}
                        onChangePartnerFilter={fetchPartners}
                        onChangeAccountFilter={fetchAccounts}
                        onChangeProjectFilter={fetchProjects}
                        onChangeContractFilter={fetchContracts}
                        onChangeContractPartFilter={fetchContractParts}
                        contracts={siteData.contracts} contractParts={siteData.contractParts}
                        projects={siteData.projects}
                        companyCurrencyCode={companyCurrencyCode}
                        country={setInvoicePlace()}
                        refresh = {() => fetch()}
                    />
                </Paper>
                {
                    invoiceData.partner &&
                    <Paper className='crate-invoice-page invoice'>
                        <TableContainer>
                            <Table>
                                {
                                    isInoInvoice(invoiceData, auth) ? renderInoInvoice() : renderDomesticInvoice()
                                }
                            </Table>
                        </TableContainer>

                    </Paper>
                }
                <Paper
                    className={canAddItems() ? 'crate-invoice-page invoice-item-add' : 'crate-invoice-page invoice-item-add disabled'}>
                    <InvoiceItemsForm 
                        siteData={siteData} 
                        invoiceData={invoiceData} 
                        addItems={addItems} 
                        invoiceId={invoiceId}
                        pageState={pageState} 
                        onChangeProductFilter={fetchProducts}
                        setValue={setValue}
                    />
                </Paper>
                <div className = 'invoice-items-total-container'>
                    <Paper
                        className={canAddItems() ? 'crate-invoice-page invoice-items-table' : 'crate-invoice-page invoice-items-table disabled'}>
                        <InvoiceItemsTable 
                            invoiceData={invoiceData} 
                            invoiceId={invoiceId} 
                            deleteItem={deleteItem}
                            setInvoiceData = {setInvoiceData}
                        />
                    </Paper>
                    <Paper
                        className={canAddItems() ? 'crate-invoice-page invoice-items-total' : 'crate-invoice-page invoice-items-total disabled'}>
                        <InvoiceTotal invoiceData={invoiceData} invoiceId={invoiceId}/>
                    </Paper>
                </div>
                <Paper
                    className={canAddItems() ? 'crate-invoice-page' : 'crate-invoice-page disabled'}
                    id='invoice-comment-editor'
                >
                    <EditorInput
                        editorState = {editor}
                        setEditor = {setEditor}
                    />
                </Paper>
            </Grid>
        </React.Fragment>
    );
}

export default CreateInvoice;
