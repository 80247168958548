import { Button } from "@mui/material";
import { FormProvider } from "react-hook-form";
import strings from "../../../../localization";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";

const formRules = {
    'title': { required: true }
};


const WorkingHoursForm = ({ values, setValue, data, errors, form, onSubmit, onCancel }) => {

    return <FormProvider {...form}>
        <form id="working-hours-form" className='form px-12' action="#">
            <TextFieldControl
                className='mt-18 mb-8 mui-shifted-label-input'
                name='title'
                rules={formRules['title']}
                control={data}
                error={Boolean(errors.title)}
                helperText={errors.title && strings.forms.common.thisFieldIsRequired}
                label={strings.profile.title}
                values={values.title}
            />

            <TextFieldControl
                className='mb-8 mui-shifted-label-input'
                name='description'
                control={data}
                error={Boolean(errors.description)}
                label={strings.profile.description}
                values={values.description}

            />

            <div className='submit-container'>
                <Button variant="contained" color="primary" className='mb-8' onClick={onSubmit}>
                    {strings.forms.common.save}
                </Button>
                <Button variant="contained" color="primary" className='mb-8' onClick={onCancel}>
                    {strings.forms.common.cancel}
                </Button>
            </div>

        </form>
    </FormProvider>
}

export default WorkingHoursForm;