const PageState = {
    View: 0,
    Add: 1,
    Edit: 2,
    ViewDetails: 3,
    Delete: 4,
    Restore: 5,
    Custom: 5,
    Tag: 6,
};

export default PageState;
