import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import PartnerContactForm from "../../../Components/Forms/Pages/CRM/PartnerContact/PartnerContactForm";
import DrawerContext from "../../../Context/DrawerContext";
import { addPartnerContact } from "../../../Services/CRM/PartnerContactService";
import LinearProgress from '@mui/material/LinearProgress';
import ValidationPatters from "../../../Constants/Base/ValidationPatters";
import SnackbarContext from "../../../Context/SnackbarContext";
import { getPartners } from "../../../Services/CRM/PartnerService";

const formRules = {
    'partner': {required: true}
}

const AddPartnerContact = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({});
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [partners, setPartners] = useState(0);

    useEffect(() => {
        setDrawerTitle(strings.pages.crm.partnerContact.addPartnerContact.pageTitle);
    });

    useEffect(() => {
        fetchPartners(); 
    },[])

    const fetchPartners = (term = '') => {
        if(!props.partner){
            getPartners({
                term
            }).then(response =>{
                if (!response || !response.ok){
                    return
                }
                setPartners(response.data.result)
            });
        }else{
            setPartners([props.partner])
            setValue('partner', props.partner)
        }

    }

    const onSubmit = (data) => {

        setShowLoader(true);

        addPartnerContact(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <PartnerContactForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                partners = {partners} control={control}
                errors={errors} data={data} form={form} onChangePartnersFilter={fetchPartners}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default AddPartnerContact;