import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import ContractPartForm from "../../../Components/Forms/Pages/Finance/ContractPart/ContractPartForm";
import DrawerContext from "../../../Context/DrawerContext";
import {addContractPart} from "../../../Services/Finance/ContractPartService";
import LinearProgress from '@mui/material/LinearProgress';
import SnackbarContext from "../../../Context/SnackbarContext";
import {getContractPartStatuses} from "../../../Services/Finance/ContractPartStatusService";


const formRules = {
    'description': {required: true}, 'startDate': {required: true}, 'endDate': {required: true}, 
    'paymentDate': {required: true}, 'actualPaymentDate': {required: true}, 
    'amount': {required: true}, 'actualAmount': {required: true},
}

const AddContractPart = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: {
            startDate: new Date(),
            endDate: new Date(),
            paymentDate: new Date(),
            actualPaymentDate: new Date(),
            invoiceDate: new Date(),
        }
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [contractPartStatuses, setContractPartStatuses] = useState(0);


    useEffect(() => {
        setDrawerTitle(strings.pages.finance.contractPart.addContractPart.pageTitle);
    });

    useEffect(() => {
        fetchContractPartStatuses();
    },[])

    const onSubmit = (data) => {

        setShowLoader(true);
        addContractPart({
            ...data,
            contract: props.contractId,
        }).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish(response.data.entity);
        });
    }

    const fetchContractPartStatuses = (term = '') => {
        getContractPartStatuses({term}).then(response => {
            if(!response || !response.ok){
                return;
            }
            setContractPartStatuses(response.data.result);
        })
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            
            <ContractPartForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                errors={errors} data={data} form={form}
                onChangeContractPartStatusFilter={fetchContractPartStatuses}
                contractPartStatuses={contractPartStatuses}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default AddContractPart;