import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import {Paper} from "@mui/material";
import SnackbarContext from "../../../Context/SnackbarContext";
import WorkspaceForm from '../../../Components/Forms/Pages/Workspaces/WorkspaceForm';
import {getPipelines} from "../../../Services/Company/PipelineService";
import { editWorkspace } from "../../../Services/Workspaces/WorkspacesService";
import { PipelineType } from '../../../Constants/Company/PipelineType';
import {setFavoriteBoards} from "../../../Slices/AuthSlice";
import { getFavoriteBoards} from "../../../Services/Workspaces/BoardsService";
import { useDispatch } from 'react-redux';

const formRules = {
    'name': {required: true},
    'pipeline': {required: true},
}

const EditWorkspace = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const [showLoader, setShowLoader] = useState(false);
    const dispatch = useDispatch();
    const [pipelines, setPipelines] = useState([]);
    const form = useForm({
        defaultValues: props.data && props.data.length ? props.data[0] : undefined
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;

    useEffect(() => {
        setDrawerTitle(strings.pages.workspaces.editWorkspaces.pageTitle);
    },[]);

    const onSubmit = (data) => {
        setShowLoader(true);

        editWorkspace(data).then(response => {

            if(!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }
            fetchFavoriteBoards();

            setShowLoader(false);
            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const fetchFavoriteBoards = () => {
        getFavoriteBoards().then(response => {
            if(!response || !response.ok) {
                return;
            }

            dispatch(setFavoriteBoards(response.data));
        });
    }

    const fetchPipelines = (term = '') => {
        getPipelines({
            term: term,
            type: PipelineType.TASK,
        }).then(response => {
            setPipelines(response.data?.result);
        });
    }

    return (
        <div className = 'drawer-form'>
            <Paper className = 'paper'>
                {
                    showLoader &&
                        <LinearProgress />
                }
                <WorkspaceForm 
                    formRules = {formRules}
                    form = {form}
                    values = {getValues()}
                    data = {data}
                    errors = {errors}
                    onSubmit = {handleSubmit(onSubmit)}
                    onCancel = {() => props.onCancel()}
                    setValue = {setValue}
                    pipelines = {pipelines}
                    control = {control}
                    onChangePipelineFilter = {fetchPipelines}
                />
            </Paper>
        </div>
    );
}

export default EditWorkspace;
