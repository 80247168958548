import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import strings from "../../../localization";
import {useForm} from "react-hook-form";
import {Paper} from "@mui/material";
import { addCandidate } from "../../../Services/HR/CandidateService";
import AddApplicationForm from "../../../Components/Forms/Pages/HR/Job/AddApplicationForm";
import ValidationPatters from "../../../Constants/Base/ValidationPatters";
import SnackbarContext from "../../../Context/SnackbarContext";
import {addDocument} from "../../../Services/DocumentManagement/DocumentService";
import { getSizeDescription } from '../../../Util/FileUtil';

const formRules = {
    'firstName': {required: true},
    'lastName': {required: true},
    'email': {required: {value: true}, pattern: { value: ValidationPatters.EMAIL}},
    'phone': {required: true},
}

const AddApplication = (props) => {
    const {showMessage} = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: {
            birthday: NaN,
            noPreviousExperience: false
        }
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if(props.job){
            setValue('job', props.job);
        }
    }, [])

    const onSubmit = (data) => {
        setShowLoader(true);

        if(data.files){
            addCandidate(data).then(response => {
                setShowLoader(false);

                if (!response || !response.ok){
                    showMessage(strings.pages.hr.job.addApplication.alreadySubmitted, 'error')
                    return;
                }

                for(let file of data.files){

                    data = {...data, file: file, referenceId: response.data.entity.id, referenceType: 30, name: file.name, documentSize: getSizeDescription(file.size).replace(/[()]/g,''), company: props.job.company.id}

                    addDocument(data).then(response => {
                        setShowLoader(false);

                        if (!response || !response.ok) {
                            showMessage(strings.commonMessages.errorAdding, 'error');
                            if (strings.error.DocumentHandler[response.response.data.message]) {
                                showMessage(strings.error.DocumentHandler[response.response.data.message], 'error');
                            }
                            onFinish();
                            return response;
                        }

                        showMessage(strings.commonMessages.addingSuccessful, 'success');
                        onFinish();
                    });
                }


                showMessage(strings.commonMessages.addingSuccessful, 'success');
                props.onFinish();
            })
        }
        else{
            setShowLoader(false);
            showMessage(strings.pages.hr.job.addApplication.fileRequired, 'error');
            return;
        }
    }

    const onCancel = () =>{
        props.onCancel();
    }

    const onFinish = () => {
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress />
            }
            <AddApplicationForm
                formRules={formRules}
                values={getValues()} setValue={setValue}
                data={data} errors={errors} form={form} control={control}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}
                onFinish={() => onFinish()} jobApplication={true}
            />
        </Paper>
    </div>
}

export  default AddApplication;