import PaymentType from "./PaymentType";
import strings from "../../localization";

const TransactionType = {
    TRANSACTION: 1,
    TAX_PAYMENT: 2,
    TAX_RETURN: 3
}

export function getTransactionTypeByPaymentType(paymentType) {
    if(paymentType === PaymentType.INCOME) {
        return [
            {
                id: TransactionType.TRANSACTION,
                name: getTransactionTypeString(TransactionType.TRANSACTION)
            },
            {
                id: TransactionType.TAX_RETURN,
                name: getTransactionTypeString(TransactionType.TAX_RETURN)
            }
        ]
    }
    if(paymentType === PaymentType.OUTCOME) {
        return [
            {
                id: TransactionType.TRANSACTION,
                name: getTransactionTypeString(TransactionType.TRANSACTION)
            },
            {
                id: TransactionType.TAX_PAYMENT,
                name: getTransactionTypeString(TransactionType.TAX_PAYMENT)
            },
        ]
    }
    return [];
}

export function getTransactionTypes() {
    return [
        {
            id: TransactionType.TRANSACTION,
            name: getTransactionTypeString(TransactionType.TRANSACTION)
        },
        {
            id: TransactionType.TAX_PAYMENT,
            name: getTransactionTypeString(TransactionType.TAX_PAYMENT)
        },
        {
            id: TransactionType.TAX_RETURN,
            name: getTransactionTypeString(TransactionType.TAX_RETURN)
        }
    ];
}

export function getTransactionTypeString(transactionType) {
    switch(transactionType) {
        case TransactionType.TRANSACTION: return strings.constants.finance.transactionType.transaction;
        case TransactionType.TAX_PAYMENT: return strings.constants.finance.transactionType.taxPayment;
        case TransactionType.TAX_RETURN: return strings.constants.finance.transactionType.taxReturn;
    }
}

export default TransactionType;