import {useDispatch, useSelector} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import strings from "../../../localization";
import {
    getCompanyLocalisationSettings,
    updateCompanyLocalisationSettings
} from "../../../Services/Company/CompanyService";
import {Alert, Grid, Paper, Snackbar} from "@mui/material";
import {useForm} from "react-hook-form";
import SnackbarContext from "../../../Context/SnackbarContext";
import LocalisationSettingsForm from "../../../Components/Forms/Pages/Company/LocalisationSettingsForm";
import DateFormats from "../../../Constants/Company/DateFormats";
import NumberFormats from "../../../Constants/Company/NumberFormats";
import {setLocalisationSettings} from "../../../Slices/AuthSlice";

const formRules = {
    'dateFormat': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'numberFormat': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
}

const LocalisationSettings = () => {

    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch();
    const form = useForm( {
        defaultValues: {dateFormat: DateFormats.default, numberFormat: NumberFormats.default}
    });
    const {handleSubmit, reset, setValue, formState: {errors}, control, getValues} = form;
    const { showMessage } = useContext(SnackbarContext);

    useEffect(() => {
        dispatch(changeBreadcrumbs({
            title: strings.pages.company.settings.localisationSettings,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.ADMINISTRATION},
                {label: strings.pages.company.company},
                {label: strings.pages.company.settings.localisationSettings},
            ],        
        }));

        fetch();
    }, []);


    fetch = () => {
        getCompanyLocalisationSettings(auth.company.id).then(response => {
            if(!response || !response.ok) {
                return;
            }

            reset(response.data.entity);
        })
    }

    const save = (data) => {
        updateCompanyLocalisationSettings(data).then(response => {
            if(!response || !response.ok) {
                showMessage(strings.commonMessages.errorSaving, 'error');
                return;
            }
            dispatch(setLocalisationSettings(response.data.entity))
            showMessage(strings.commonMessages.saved, 'success');
        });
    }

    return (
        <Grid 
            container 
            justifyContent="center"
            alignItems="center"
            className = 'company-form-container'
        >
            <Grid item xs={12} className = 'form-container localisation-settings'>
                <LocalisationSettingsForm
                    onSubmit={handleSubmit(save)}
                    control={control}
                    values={getValues()}
                    setValue={setValue}
                    errors={errors} form={form} formRules={formRules}
                />
            </Grid>
        </Grid>
    )
}

export default LocalisationSettings;
