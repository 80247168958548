import React from 'react';
import strings from '../../../../localization';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import { useState } from 'react';
import { InputAdornment } from '@mui/material';
import { Link } from 'react-router-dom';

const ResetPasswordForm = ({
                              onSubmit,
                              errors,
                              data,
                              form,
                              formRules
                          }) => 
{
    const [passwordShown, setPasswordShown] = useState(false);
    const [repeatPasswordShown, setrepeatPasswordShown] = useState(false);

    const passwordAdornment = {
        endAdornment: (
            <InputAdornment position="end" onClick={()=>setPasswordShown(prevValue=>!prevValue)}>
            {passwordShown? <img src={'/images/eye-cross-icon.svg'} loading={'lazy'} /> : <img src={'/images/eye-open-icon.svg'} loading={'lazy'} />}
            </InputAdornment>
        ),
    }
    const repeatPasswordAdornment = {
        endAdornment: (
            <InputAdornment position="end" onClick={()=>setrepeatPasswordShown(prevValue=>!prevValue)}>
            {repeatPasswordShown? <img src={'/images/eye-cross-icon.svg'} loading={'lazy'} /> : <img src={'/images/eye-open-icon.svg'} loading={'lazy'} />}
            </InputAdornment>
        ),
    }
    return <FormProvider {...form}>
            <form id='reset-password-form' className='form' onSubmit={ onSubmit } action = "#">

                <div className='password-input'>
                     <TextFieldControl
                    name='password'
                    control={data}
                    defaultValue=''
                    rules={formRules['password']}
                    fullWidth
                    type={passwordShown? "text":"password"}
                    margin="normal"
                    error={ Boolean(errors.password) }
                    helperText={errors.password && errors.password.message}
                    label={ strings.forms.resetPassword.newPassword }
                    inputProps={passwordAdornment}
                    placeholder={strings.forms.resetPassword.passwordPlaceholder}
                />
                </div>
               
                <div className='password-input'>
                    <TextFieldControl
                    name='passwordRepeat'
                    control={data}
                    defaultValue=''
                    rules={formRules['passwordRepeat']}
                    fullWidth
                    type={repeatPasswordShown? "text":"password"}
                    margin="normal"
                    error={ Boolean(errors.passwordRepeat) }
                    helperText={errors.passwordRepeat && errors.passwordRepeat.message}
                    label={ strings.forms.resetPassword.reEnterNewPassword }
                    inputProps={repeatPasswordAdornment}
                    placeholder={strings.forms.resetPassword.passwordPlaceholder}
                />
                </div>

                

                <div className='reset-password'>
                    <Button variant="contained" color="primary" onClick={ onSubmit }>
                        { strings.forms.resetPassword.resetPassword }
                    </Button>
                </div>
                <div className='log-in'>
                    <div className='text'>{strings.forms.resetPassword.haveAnAccount}</div>
                    <Link to={'/login'}>{strings.forms.resetPassword.login}</Link>
                </div>
            </form>
        </FormProvider>
};

export default ResetPasswordForm;
