import React from "react";

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


export const DotSmallIcon = ({
    active = false,
    isShort,
}) => {   
       return <div className={isShort ? `dot-s-dark ${active? 'active':''}` : `dot-s ${active? 'active':''}`}></div>
}

export const FolderIcon = () => <img src="/images/folder-document.svg" className="document-folder-icon" />
export const CategoryIcon = () => <img src="/images/icons/blue/category-blue.svg" className="document-folder-icon" />
export const CategoryDot = () => <img src="/images/icons/grey/dot-grey.svg" className="document-folder-icon" />
export const CrossSign = () => <img className={'add-subfolder'} src='/images/table-page/turquoise-add-cross.svg'/>
export const ShowLessIcon = () => <img src="/images/show-less.svg" className="show-less"/>
export const ShowMoreIcon = () => <img src="/images/show-more.svg" className="show-more"/>
export const BackButton = () => <img src="/images/back-button.svg" />


const NavigationIcon = ({icon= '', activeIcon= '', isActive = () => false}) => {

    // We need this because we need to hide icon. Otherwise we are constantly fetch images
    return <>
        <img src={icon} loading='lazy' className={isActive()? 'd-none':''}/>
        <img src={activeIcon} loading='lazy'  className={isActive()? '':'d-none'}/>
    </>
}

export default NavigationIcon;