const TransactionRelationType = {
    PARTNER: 1,
    CONTRACT: 2,
    CONTRACT_PART: 3,
    INVOICE: 4,
    EMPLOYEE: 5,
    TAX: 6,
    TRANSACTION_TYPE: 7,
    CAN_RETURN_TAX: 8,
    TRANSACTION_CATEGORY: 9
}

export default TransactionRelationType;
