import React, {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import {deleteInventoryCategory, getInventoryCategoryList} from "../../../Services/AssetManagement/InventoryCategoryService";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import AddInventoryCategory from "./AddInventoryCategory";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import EditInventoryCategory from "./EditInventoryCategory";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [
    {
        field: 'name', headerName: strings.pages.assetManagement.inventoryCategory.inventoryCategoryList.name,
        width: 250,
    },
    {
        field: 'parent',
        headerName: strings.pages.assetManagement.inventoryCategory.inventoryCategoryList.parent,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 250
    }
];

const filters = []

const InventoryCategoryList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.INVETORY_CATEGORY;
    const permissionGroup = AppPermissions.INVENTORY_CATEGORY.GROUP;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.assetManagement.inventoryCategory.inventoryCategoryList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.ASSETS},
                {label: strings.pages.assetManagement.inventoryCategory.inventoryCategoryList.pageTitle}
            ],        
        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter]);
    

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getInventoryCategoryList({
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteInventoryCategory} tableDescription={columns}
                   tableData={tableData} filter={filter} filters={filters}
                   referenceType={referenceType} permissionGroup={permissionGroup}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditInventoryCategory data={ selectedItems }
                   onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addPage={<AddInventoryCategory onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addButtonText={strings.components.tablePage.addButton.inventoryCategory}
                   />
    </TablePageContext.Provider>;
}

export default InventoryCategoryList;
