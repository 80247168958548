import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getCompany(id) {
    return await request('/api/companies/' + id);
}

export async function getCurrentCompany() {
    return await request('/api/companies/current');
}

export async function updateCompany(data) {
    return await request('/api/companies', transformRequestData(data), HttpMethod.PUT);
}

export async function changeLogo(data) {
    return await request('/api/companies/logo', data, HttpMethod.PATCH);
}

export async function startCompanyFreeTrial() {
    return await request('/api/companies/start-trial', {}, HttpMethod.PATCH);
}

export async function getCompanyLogo(id) {
    return await request(`/api/companies/${id}/logo`);
}

export async function getCompanyS3Settings() {
    return await request('/api/company/s3');
}

export async function updateCompanyS3Settings(data) {
    return await request('/api/company/s3', transformRequestData(data), HttpMethod.PUT);
}

export async function getCompanyLocalisationSettings() {
    return await request('/api/company/localisation');
}

export async function updateCompanyLocalisationSettings(data) {
    return await request('/api/company/localisation', transformLocalisationRequestData(data), HttpMethod.PUT);
}

export async function getInvoiceSettings() {
    return await request('/api/company/invoice-settings');
}

export async function updateInvoiceSettings(data) {
    return await request('/api/company/invoice-settings', transformRequestDataInvoiceSettings(data), HttpMethod.PUT);
}

export async function getCompanyByName(name) {
    return await request('/api/company_get_by_name/' + name);
}

export async function getCompanyByRegistrationNumber(number) {
    return await request('/api/company_get_by_registration_number/' + number);
}

export async function getCompanyByTaxNumber(number) {
    return await request('/api/company_get_by_tax_number/' + number);
}

export async function getCompanyAccounts(number) {
    return await request('/api/company_get_accounts/' + number);
}

function transformRequestDataInvoiceSettings(data) {
    return {
        ...data,
        deadline: data.deadline ? data.deadline.id : null,
        account: data.account ? data.account.id : -1,
        inoAccount: data.inoAccount ? data.inoAccount.id : -1,
    }
}

function transformRequestData(data) {
    return {
        ...data,
        countryId: data.country ? data.country.id : null,
        cityId: data.city ? data.city.id : null,
        currencyId: data.currency ? data.currency.id : null,
        responsiblePersonId: data.responsiblePerson ? data.responsiblePerson.id : null,
    }
}
function transformLocalisationRequestData(data) {
    return {
        ...data,
        dateFormat: data.dateFormat?.value,
        numberFormat: data.numberFormat?.value,
    }
}
