import {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {changePageSizeState} from "../../../Slices/PageSlice";
import WorkspacesSettingsTab from "../../../Constants/Workspaces/WorkspacesSettingsTab";
import {Paper, Tab, Tabs} from "@mui/material";
import ChangeWorkspaceLogo from './ChangeWorkspaceLogo';
import WorkspaceAccess from './WorkspaceAccess';
import {useParams, useLocation} from "react-router-dom";
import WorkspaceIssueType from './WorkspaceIssueType';
import { useNavigate } from 'react-router-dom';

const WorkspaceSettings = (props) => {
    const {id: workspaceId} = useParams();
    const { activeTab = WorkspacesSettingsTab.ISSUETYPE } = props;
    const state = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const changeActiveTab = (tab) =>{
        if(tab === WorkspacesSettingsTab.ISSUETYPE){
            navigate(`/workspace-settings/${workspaceId}/issue-types`)
        }

        if(tab === WorkspacesSettingsTab.LOGO){
            navigate(`/workspace-settings/${workspaceId}/logo`)
        }

        if(tab === WorkspacesSettingsTab.ACCESS){
            navigate(`/workspace-settings/${workspaceId}/workspace-access`)
        }
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.workspaces.workspacesSettings.issueType,
            hierarchy: [
                {label: strings.navigation.homeTag},
                {label: strings.navigation.workspaces},
                {label: strings.pages.workspaces.workspacesSettings.pageTitle},
                {label: strings.pages.workspaces.workspacesSettings.issueType},
            ]
        }));
    },[]);


    return (
        <div id = 'workspaces-settings'>
            <div className='tab-container'>
                <Paper className='paper'>
                    <Tabs variant="scrollable" value={ activeTab } onChange={ (e, tab) => changeActiveTab(tab) }>
                        <Tab label={ strings.pages.workspaces.workspacesSettings.issueType}></Tab>
                        <Tab label={ strings.pages.workspaces.workspacesSettings.logo }></Tab>
                        <Tab label={ strings.pages.workspaces.workspacesSettings.access }></Tab>
                    </Tabs>
                </Paper>
            </div>
        {
            activeTab === WorkspacesSettingsTab.ISSUETYPE &&
            <div className='page-form-container'>
                 <WorkspaceIssueType  workspaceId = {workspaceId} />
            </div>
        }
        {
            activeTab === WorkspacesSettingsTab.LOGO &&
            <div className='page-form-container'>
                <ChangeWorkspaceLogo workspaceId={workspaceId} />
            </div>
        }
        {
            activeTab === WorkspacesSettingsTab.ACCESS &&
            <div className='page-form-container'>
                <WorkspaceAccess workspaceId={workspaceId} />
            </div>
        }
        </div>
    );
};

export default WorkspaceSettings;
