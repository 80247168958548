import {request} from './HTTP';
import HttpMethod from "../Constants/Base/HttpMethod";
import {setAuth, setCompanyS3Settings, setIsCheckIn, setLocalisationSettings, setPermissions} from "../Slices/AuthSlice";
import {isCompanyValid} from "../Util/CompanyUtil";
import {getUserAccesses} from "../Util/UserUtil";
import {getCompanyS3Settings} from "../Services/Company/CompanyService";
import store from "../store";
import { getIsCheckIn } from '../Services/HR/PresenceService';
import { changeMenuState } from "../Slices/NavigationSlice";

/** OAUTH **/

export async function login(username, password) {

    clearUserData();

    let data = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        grant_type: 'password',
        username: username,
        password: password
    };

    return await request('/oauth/v2/token', data, HttpMethod.POST).then((response) => {
        const responseLogin = response.response || response;
        if (!responseLogin || !responseLogin.ok) {
            return {responseLogin};
        }

        setTokenToLocalStorage(responseLogin.data.token, responseLogin.data.token);

        return request('/api/users/current').then((response) => {

            if(response.data.user) {
                setUserToLocalStorage({
                    ...response.data.user,
                    isOwner: response.data.isOwner,
                    permissions: response.data.permissions,
                    accesses: getUserAccesses(response.data.accesses),
                });
            }

            return {response, responseLogin};
        });
    }).then(({response, responseLogin}) => {
        if (!responseLogin || !responseLogin.ok) {
            return {responseLogin};
        }

        const {data} = response;
        store.dispatch(setAuth({
            ...data.user,
            isOwner: data.isOwner,
            permissions: data.permissions,
            accesses: getUserAccesses(data.accesses),
        }));
        store.dispatch(setPermissions(data.permissions));

        if(isCompanyValid(data.user.company)) {
            getCompanyS3Settings(data.user.company.id).then(response => {
                if (response?.ok) {
                    store.dispatch(setCompanyS3Settings(response.data.entity))
                }
            }).catch(() => {
                store.dispatch(setCompanyS3Settings(undefined))
                store.dispatch(setLocalisationSettings())
            })
            getIsCheckIn().then(response => {
                if(!response.ok || !response){
                    return;
                }
                store.dispatch(setIsCheckIn(response.data.entity))
            })
        }
        else {
            store.dispatch(setCompanyS3Settings(undefined))
            store.dispatch(setLocalisationSettings())
        }
        return {response, responseLogin};
    });
}

export async function unlock(username, password) {

    clearUserDataLock();

    let data = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        grant_type: 'password',
        username: username,
        password: password
    };

    return await request('/oauth/v2/token', data, HttpMethod.GET).then((response) => {

            if (!response.ok) {
                return response;
            }

            setTokenToLocalStorage(response.data.access_token, response.data.refresh_token);

            return response;
        }
    );
}

export function logout() {
    clearUserData();
}

export function lock() {
    clearUserDataLock();
}

/** LOCAL STORAGE  **/

export function setUserToLocalStorage(user) {
    localStorage.setItem('user', JSON.stringify(user));
}

export function getUserFromLocalStorage() {

    let user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
}

export function setTokenToLocalStorage(access_token, refresh_token) {
    localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, access_token);
    localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN_KEY, refresh_token);
}

export function getRefreshToken() {
    return localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_KEY);
}

export function getToken() {
    return localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
}

export function clearUserData() {
    localStorage.removeItem('user');
    clearUserDataLock();
}

export function clearUserDataLock() {
    localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
    localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN_KEY);
}

export function isUserLoggedIn() {
    return getUserFromLocalStorage() != null && getToken();
}

export function isUserLocked() {
    return getUserFromLocalStorage() && !getToken();
}
