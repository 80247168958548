import React from "react";
import { FormProvider } from "react-hook-form";
import strings from "../../../../../localization";
import Button from "@mui/material/Button";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";

const BusinessTripForm = ({ onSubmit, onCancel, form }) => {
  return (
    <FormProvider {...form}>
      <form id="candidate-form" className="form" action="#">
        <TextFieldControl
          name="documentName"
          label={strings.pages.hr.businessTrip.businessTripForm.documentName}
        />

        <div className="submit-container">
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.forms.common.save}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.forms.common.cancel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default BusinessTripForm;
