import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";
import { formatIssueName } from '../../Constants/Workspaces/WorkspacesIssueTypes';

export async function getIssueTypeList(workspaceId, data){
    return await request('/api/issue-type/' + workspaceId, data);
}

export async function addIssueType(workspaceId, data){
    return await request('/api/issue-type/' + workspaceId, transformRequestData(data), HttpMethod.POST);
}

export async function editIssueType(id, data){
    return await request('/api/issue-type/' + id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteIssueType(id) {
  return await request('/api/issue-type/'+ id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        name: data.name ? data.name : formatIssueName(data.issueType.type),
        issueType: data.issueType.type,
    }
}
