import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getUserCompanyAccess(data) {
    return await request('/api/user-company-access', data, HttpMethod.GET);
}

export async function getOneUserCompanyAccess(id) {
    return await request('/api/user-company-access/' + id);
}

export async function getAllCompanyAccess(data = {}) {
    return await request('/api/user-company-access/all', data);
}

export async function getUserCompanyAccessById(id) {
    return await request('/api/user-company-access/' + id);
}

export async function getAllUserCompanyAccess(data = {}) {
    return await request('/api/user-company-access/all/user', data);
}

export async function acceptInviteUserCompanyAccess(data) {
    return await request('/api/user-company-access/invite/accept', data, HttpMethod.PUT);
}

export async function deleteInviteUserCompanyAccess(id) {
    return await request('/api/user-company-access/' + id, {}, HttpMethod.DELETE);
}

export async function userCompanyAccessGetByToken(token) {
    return await request('/api/user-company-access/token/' + token);
}

export async function validatePublicLink(publicLink){
    return await request('/api/users/validate-public-link/' + publicLink);
}

export async function savePublicLink(publicLink){
    return await request('/api/users/save-public-link/' + publicLink, {} , HttpMethod.PUT);
}

export async function getCurrentEmployee() {
    return await request("/api/users/current-employee");
  }
