import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getBusinessYears(data) {
    return await request('/api/business-years', data);
}

export async function addBusinessYear(data) {
    return await request('/api/business-years', transformRequestData(data), HttpMethod.POST);
}

export async function editBusinessYear(data) {
    return await request('/api/business-years/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function activeBusinessYear(id) {
    return await request('/api/business-years/active/' + id, {}, HttpMethod.PUT);
}

function transformRequestData(data) {
    return {
        ...data,
    }
}