import LinearProgress from '@mui/material/LinearProgress';
import {Paper} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DrawerContext from "../../../Context/DrawerContext"
import strings from "../../../localization";
import CandidateSourceForm from '../../../Components/Forms/Pages/HR/CandidateSource/CandidateSourceForm';
import { addCandidateSource } from '../../../Services/HR/CandidateSourceService';
import SnackbarContext from '../../../Context/SnackbarContext';

const formRules = {
    'name': {required: true}
}

const AddCandidateSource = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm();
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    
    useEffect(() => {
        setDrawerTitle(strings.pages.hr.candidateSource.addCandidateSource.pageTitle);
    });

    const onSubmit = (data) => {
        setShowLoader(true);

        addCandidateSource(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok){
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress />
            }
            <CandidateSourceForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()} />
        </Paper>
    </div>
}

export default AddCandidateSource;