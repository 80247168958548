import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getCompositeProduct(id) {
    return await request('/api/composite_product/' + id);
}

export async function getCompositeProductList(data) {
    return await request('/api/composite_product', data);
}

export async function addCompositeProduct(data) {
    return await request('/api/composite_product', transformRequestData(data), HttpMethod.POST);
}

export async function editCompositeProduct(data) {
    return await request('/api/composite_product/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteCompositeProduct(id) {
    return await request('/api/composite_product/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        productId: data.product ? data.product.id : null,
    }
}