import React from "react";
import { FormProvider } from "react-hook-form";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import Button from '@mui/material/Button';
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import { getEmployeeStatuses } from "../../../../../Constants/HR/EmployeeStatus";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import CheckBoxControl from "../../../../Controls/Inputs/CheckBoxControl";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";

const EmployeeForm = ({
                    onSubmit,
                    onCancel,
                    data,
                    form,
                    errors,
                    values,
                    setValue,
                    formRules,
                    userPositions,
                    teams,
                    control,
                    onChangeUserPositionFilter,
                    onChangeTeamFilter
                }) => {
    return (
        <FormProvider {...form}>
            <form id="job-form" className='form' action="#">
                <TextFieldControl
                    name='firstName'
                    control={data}
                    label={strings.forms.hr.employee.firstName}
                />
                <TextFieldControl
                    name='lastName'
                    control={data}
                    label={strings.forms.hr.employee.lastName}
                />
                 <TextFieldControl
                    name='personalNumber'
                    control={data}
                    label={strings.forms.hr.employee.personalNumber}
                />
                <TextFieldControl
                    name='email'
                    control={data}
                    rules={formRules['email']}
                    error={Boolean(errors.email)}
                    helperText={errors.email && strings.forms.common.emailFormatError}
                    label={strings.forms.hr.employee.email}
                />
                <TextFieldControl
                    name='phoneNumber'
                    control={data}
                    label={strings.forms.hr.employee.phoneNumber}
                />
                <TextFieldControl
                    name='address'
                    control={data}
                    label={strings.forms.hr.employee.address}
                />

                <DateTimeControl
                    data={data}
                    label={strings.forms.hr.employee.nextRaise}
                    name={'nextRaise'}
                    value={values['nextRaise']}
                    setValue={setValue}
                />

                <DateTimeControl
                    data={data}
                    label={strings.forms.hr.employee.lastRaise}
                    name={'lastRaise'}
                    value={values['lastRaise']}
                    setValue={setValue}
                />

                <DateTimeControl
                    data={data}
                    label={strings.forms.hr.employee.birthDate}
                    name={'birthDate'}
                    value={values['birthDate']}
                    setValue={setValue}
                />

                <SelectControl
                    value={values['employeeStatus']}
                    setValue={setValue}
                    name='employeeStatus'
                    control={data}
                    label={strings.forms.hr.employee.employeeStatus}
                    options={getEmployeeStatuses()}
                    nameKey={'name'}
                    valueKey={'id'}
                />


                <AutoCompleteControl
                    value={values['userPosition']}
                    setValue={setValue}
                    name='userPosition'
                    control={data}
                    label={strings.forms.hr.employee.userPosition}
                    options={userPositions}
                    nameKey={'name'}
                    valueKey={'id'}
                    onChange={onChangeUserPositionFilter}
                    onOpen={() =>onChangeUserPositionFilter('')}
                />

                <AutoCompleteControl
                    value={values['team']}
                    setValue={setValue}
                    name='team'
                    control={data}
                    label={strings.forms.hr.employee.team}
                    options={teams}
                    nameKey={'name'}
                    valueKey={'id'}
                    onChange={onChangeTeamFilter}
                    onOpen={() =>onChangeTeamFilter('')}
                />
                <TextFieldControl
                    name='bankAccount'
                    control={data}
                    label={strings.forms.hr.employee.bankAccount}
                />
                <CheckBoxControl
                    name={'billableHours'}
                    control={data}
                    label={strings.forms.hr.employee.billableHours}
                    value={values['billableHours']}
                    setValue={setValue}
                />

                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.forms.common.save}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.forms.common.cancel}
                    </Button>
                </div>
                
            </form>
        </FormProvider>
    );
}

export default EmployeeForm;
