import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import TablePageContext from "../../../Context/TablePageContext";
import LinearProgress from '@mui/material/LinearProgress';
import { addLeave } from "../../../Services/HR/LeaveService";
import LeaveForm from "../../../Components/Forms/Pages/HR/Leave/LeaveForm";
import {getAllLeaveTypes} from "../../../Services/HR/LeaveTypeService";
import {getAllEmployees} from "../../../Services/User/UserService";
import SnackbarContext from "../../../Context/SnackbarContext";

const formRules = {
    'employee': {required: true},
    'fromDate': {required: true},
    'toDate': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'leaveType': {required: true}
}

const AddLeave = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: {
            toDate: null,
            fromDate: null
        }
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [leaveTypes, setLeaveTypes] = useState([]);
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.leave.addLeave.pageTitle);
        fetch();
    }, []);

    const fetch = () => {
        getAllLeaveTypes().then(response => {

            if(!response || !response.ok) {
                setLeaveTypes([]);
                return;
            }

            setLeaveTypes(response.data);
        });

        getAllEmployees().then(response => {

            if(!response || !response.ok) {
                setLeaveTypes([]);
                return;
            }

            setEmployees(response.data);
        });
    }
    
    const onSubmit = (data) => {
        setShowLoader(true);

        addLeave(data).then(response => {
            setShowLoader(false);

            if(!response){
                showMessage(strings.commonMessages.errorAdding, 'error');
                return;
            }
            if (!response.ok) {          
                showMessage(strings.error.LeaveAndLeaveRequestHandler[response.response.data.message], 'error', 5000);  
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <LeaveForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                leaveTypes={leaveTypes}
                employees={employees}
                errors={errors} data={data} form={form} control={control}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default AddLeave;