import strings from "../../../localization";
import { editBusinessYearStartValue } from "../../../Services/Finance/BusinessYearStartValueService";
import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import DrawerContext from "../../../Context/DrawerContext";
import LinearProgress from '@mui/material/LinearProgress';
import { getBankAccounts } from "../../../Services/Company/BankAccountService";
import BusinessYearStartValueForm from "../../../Components/Forms/Pages/Administration/BusinessYearStartValue/BusinessYearStartValueForm";
import SnackbarContext from "../../../Context/SnackbarContext";

const formRules = {
    'bankAccount': {required: true},
    'amount': {required: true}
}

const EditBusinessYearStartValue = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [bankAccounts, setBankAccounts] = useState(0);

    useEffect(() => {
        setDrawerTitle(strings.pages.administration.bussinesYearStartValue.editBussinesYearStartValue.pageTitle);
    })

    useEffect(() => {
        fetch();
    }, [])

    const onSubmit = (data) => {
        setShowLoader(true);

        editBusinessYearStartValue({
            ...data
        }).then(response => {
            setShowLoader(false);
            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const fetch = () => {
        getBankAccounts().then(response => {
            if (!response || !response.ok){
                return;
            }

            setBankAccounts(response.data.result);
        })
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <BusinessYearStartValueForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                errors={errors} data={data} form={form} control={control}
                bankAccounts={bankAccounts}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditBusinessYearStartValue;