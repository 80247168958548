import React, {useContext} from "react";
import { FormProvider } from "react-hook-form";
import strings from "../../../../../localization";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import Button from '@mui/material/Button';
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";

const UserPositionForm = ({
                            onSubmit,
                            onCancel,
                            data,
                            values,
                            setValue,
                            form,
                            errors,
                            formRules,
                            parents,
                            onChangeUserFilter
                        }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='user-position-form' className='form' action="#">
                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.userPosition.name}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    name='parent'
                    label={strings.forms.hr.userPosition.parent}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={setValue}
                    control={data}
                    options={parents}
                    value={values['parent']}
                    disabled={isPageStateViewDetails()}
                    onChange={onChangeUserFilter}
                    onOpen={() =>onChangeUserFilter('')}
                /> 

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={ onSubmit }>
                            { strings.forms.common.save }
                        </Button>
                        <Button variant="contained" color="secondary" onClick={ onCancel }>
                            { strings.forms.common.cancel }
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default UserPositionForm;