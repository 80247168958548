import React, {useContext, useEffect, useState} from 'react';
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import SnackbarContext from "../../../Context/SnackbarContext";
import strings from "../../../localization";
import {acceptEInvoiceIn, cancelOutEInvoice, rejectEInvoiceIn} from "../../../Services/Finance/EInvoiceService";
import {Paper} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import CancelInvoiceForm from "../../../Components/Forms/Pages/Finance/Invoice/CancelInvoiceForm";
import AcceptRejectInvoiceFrom from "../../../Components/Forms/Pages/Finance/Invoice/AcceptRejectInvoiceFrom";

const AcceptRejectInvoice = ({accept, invoice, onFinish, onCancel}) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const {showMessage} = useContext(SnackbarContext);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setDrawerTitle(accept ? strings.pages.finance.invoice.acceptRejectInvoice.pageTitleAccept
        : strings.pages.finance.invoice.acceptRejectInvoice.pageTitleReject);
    });

    const onSubmit = (data) => {

        if(accept) {
            acceptEInvoiceIn(invoice.id, data).then(response => {
                onFinish()
            })
        }
        else {
            rejectEInvoiceIn(invoice.id, data).then(response => {
                onFinish()
            })
        }
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }

            <AcceptRejectInvoiceFrom
                errors={errors} data={data} form={form}
                onCancel={onCancel}
                onSubmit={handleSubmit(onSubmit)}
            />
        </Paper>
    </div>
}

export default AcceptRejectInvoice