import strings from "../../../localization";

const CompanyDocumentReferenceType = {
    USER: 1,
    PARTNER: 2,
    COMPANY: 3
}

export function getCompanyDocumentReferenceTypes() {
    return [
        {
            value: CompanyDocumentReferenceType.COMPANY,
            name: getCompanyDocumentReferenceTypeString(CompanyDocumentReferenceType.COMPANY)
        },
        {
            value: CompanyDocumentReferenceType.USER,
            name: getCompanyDocumentReferenceTypeString(CompanyDocumentReferenceType.USER)
        },
        {
            value: CompanyDocumentReferenceType.PARTNER,
            name: getCompanyDocumentReferenceTypeString(CompanyDocumentReferenceType.PARTNER)
        }
    ];
}

export function getCompanyDocumentReferenceTypeString(contractType) {
    switch(contractType) {
        case CompanyDocumentReferenceType.USER: return strings.companyDocumentReferenceType.user;
        case CompanyDocumentReferenceType.COMPANY: return strings.companyDocumentReferenceType.company;
        case CompanyDocumentReferenceType.PARTNER: return strings.companyDocumentReferenceType.partner;
    }
}

export default CompanyDocumentReferenceType;