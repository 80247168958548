import LinearProgress from '@mui/material/LinearProgress';
import React,{ useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import {Paper} from "@mui/material";
import DrawerContext from "../../../Context/DrawerContext"
import strings from "../../../localization";
import SnackbarContext from '../../../Context/SnackbarContext';
import PresenceForm from '../../../Components/Forms/Pages/HR/Presence/PresenceForm';
import { addPresence } from '../../../Services/HR/PresenceService';

const formRules = {
    'start': {required: true},
    'end': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}}
}

const AddPresence = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const {showMessage} = useContext(SnackbarContext);
    const form = useForm({mode: "onTouched"});
    const {data, control, handleSubmit, getValues, trigger, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.presence.addPresence.pageTitle);
    },[]);

    const onSubmit = (data) => {
        setShowLoader(true);

        addPresence(data).then(response => {
            setShowLoader(false);

            if(!response){
                showMessage(strings.commonMessages.errorGenerating, 'error');
                return;
            }
            if (!response.ok) {
                showMessage(response.response.data.message, 'error')
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <PresenceForm
                formRules={formRules}
                values={getValues()} trigger={trigger}
                setValue={setValue} control={control}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()} />
        </Paper>
    </div>
}

export default AddPresence;