import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";
import {
    getCompanyDocumentQuestionTypeString
} from "../../Constants/CompanyDocumentManagement/CompanyDocumentItem/CompanyDocumentQuestionType";


export async function getCheckListTemplateQuestions(data) {
    return await request('/api/check-list-template-questions', data);
}

export async function addCheckListTemplateQuestion(data) {
    return await request('/api/check-list-template-question', transformRequestData(data), HttpMethod.POST);
}

export async function deleteCheckListTemplateQuestion(id) {
    return await request('/api/check-list-template-question/' + id, {}, HttpMethod.DELETE);
}

export async function editCheckListTemplateQuestion(data) {
    return await request('/api/check-list-template-question/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

function transformRequestData(data) {
    let dataType;
    if(data.type?.value){
        dataType = data.type;
    }else{
        dataType = {name: getCompanyDocumentQuestionTypeString(data.type), value: data.type}
    }
    return {
        ...data,
        description: data.explanation ? data.explanation : null,
        type: dataType.value
    }
}
