import {Box, TextField} from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider }  from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import React, {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import store from "../../../store";
import DateFormats from "../../../Constants/Company/DateFormats";
import {dateAsUTCDate} from "../../../Util/DateUtil";
import enLocale from 'date-fns/locale/en-GB';

const CalendarIcon = () => {
    return(
        <img src='/images/calendar-add.svg' />
    );
}

const DateTimeControl = ({data, value, setValue, size, label, name, disabled, rules, minDateTime, minDate, form, className = '', ...props}) => {

    const [date, setDate] = useState(value);

    useEffect(() => {
        if(props.dateTime){
            setDate(new Date(value))
            return;
        }
        setDate(value)

    }, [value])

    const changeDate = (date) => {
        let newValue = dateAsUTCDate(date)
        setValue(name, newValue);
        setDate(newValue);
    }

    const changeDateTime = (date) => {
        if(!date){
            date = '';
        }
        
        setValue(name, date);
        setDate(date);
    }
    return (<Controller
        name={name}
        control={data}
        rules={rules}
        render={({ field }) =>
            <Box className={`data-picker-box ${className}`}>
                <LocalizationProvider locale={enLocale}  dateAdapter={AdapterDateFns}>
                    {
                        !props.dateTime &&
                        <DatePicker
                            disableFuture={props.disableFuture}
                            fullWidth
                            disabled={disabled}
                            label={label}
                            value={date}
                            minDate={minDate}
                            inputFormat={DateFormats.getOrDefault(store.getState()?.auth?.localisationSettings?.dateFormat)}
                            mask={DateFormats.mask(store.getState()?.auth?.localisationSettings?.dateFormat)}
                            onChange={(date) => {
                                if (field?.onChange) {
                                    field.onChange(() => changeDate(date));
                                    return
                                }
                                changeDate(date);
                            }}
                            renderInput={(params) => <TextField {...params} error={props.error} helperText={props.helperText} size={size ? size : 'small'}/>}
                            components={{
                                OpenPickerIcon: CalendarIcon
                            }}
                        />
                    }
                    {
                        props.dateTime && 
                        <DateTimePicker
                            fullWidth
                            label={label}
                            value={date}
                            minDateTime={minDateTime}
                            inputFormat={DateFormats.getOrDefault(store.getState()?.auth?.localisationSettings?.dateFormat) + ' HH:mm'}
                            mask={DateFormats.mask(store.getState()?.auth?.localisationSettings?.dateFormat) + ' HH:mm'}
                            onChange={(date) => {
                                if (field?.onChange) {
                                    field.onChange(() => changeDateTime(date));
                                    return
                                }
                                changeDateTime(date);
                            }}
                            disableFuture={props.disableFuture}
                            ampm={false}
                            ampmInClock={false}
                            disabled={disabled}
                            renderInput={(params) => <TextField {...params} error={props.error} helperText={props.helperText} size={size ? size : 'small'}/>}
                            dateRangeIcon={<img src='/images/calendar-add.svg' />}
                        />
                    }

                </LocalizationProvider>
            </Box>
        }
    >
    </Controller>
    )

}

export default DateTimeControl;
