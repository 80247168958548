import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import CheckBoxControl from "../../../../Controls/Inputs/CheckBoxControl";
import AutoCompleteControl from '../../../../Controls/Inputs/AutoCompleteControl';

const UserForm = ({
                      onSubmit,
                      onCancel,
                      data,
                      form,
                      errors,
                      values,
                      setValue,
                      userPositions,
                      formRules,
                      teams,
                      candidates,
                      watch,
                      onCandidateChange
                  }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const isPageStateAdd = () => pageState === PageState.Add;
    const saveAndInvite = true;

    return (
        <FormProvider {...form}>
            <form id='user-form' className='form' action="#">

                <TextFieldControl
                    name='email'
                    type={'email'}
                    rules={formRules['email']}
                    control={data}
                    error={Boolean(errors.email)}
                    helperText={errors.email && strings.forms.common.emailFormatError}
                    label={strings.forms.administration.user.email}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='firstName'
                    rules={formRules['firstName']}
                    control={data}
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.administration.user.firstName}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='lastName'
                    rules={formRules['lastName']}
                    control={data}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.administration.user.lastName}
                    disabled={isPageStateViewDetails()}
                />

                <CheckBoxControl
                    name={'organisationUser'}
                    control={data}
                    label={strings.forms.administration.user.organisationUser}
                    value={values['organisationUser']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />

                {
                    watch('organisationUser') &&
                    <AutoCompleteControl
                        value={values['userPosition']}
                        setValue={setValue}
                        name='userPosition'
                        control={data}
                        label={strings.forms.administration.user.userPosition}
                        options={userPositions}
                        nameKey={'name'}
                        valueKey={'id'}
                        disabled={isPageStateViewDetails()}
                    />
                }

                <AutoCompleteControl
                    value={values['team']}
                    setValue={setValue}
                    name='team'
                    control={data}
                    label={strings.forms.administration.user.team}
                    options={teams}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    value={values['candidate']}
                    setValue={setValue}
                    name='candidate'
                    control={data}
                    label={strings.forms.administration.user.candidate}
                    options={candidates}
                    nameKey={'fullName'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onCandidateChange}
                />

                <CheckBoxControl
                    name={'billableHours'}
                    control={data}
                    label={strings.forms.hr.employee.billableHours}
                    value={values['billableHours']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={() => onSubmit()}>
                            {strings.forms.common.save}
                        </Button>
                        {
                            isPageStateAdd() &&
                            <Button variant="contained" color="primary" onClick={() => onSubmit(saveAndInvite)}>
                                {strings.forms.common.saveAndInvite}
                            </Button>
                        }
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default UserForm;