import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";


export async function getCompanyDocumentTemplateQuestionOptions(data) {
    return await request('/api/company-document-template-question-options', data);
}

export async function addCompanyDocumentTemplateQuestionOption(data) {
    return await request('/api/company-document-template-question-option', data, HttpMethod.POST);
}

export async function deleteCompanyDocumentTemplateQuestionOption(id) {
    return await request('/api/company-document-template-question-option/' + id, {}, HttpMethod.DELETE);
}

export async function editCompanyDocumentTemplateQuestionOption(data) {
    return await request('/api/company-document-template-question-option/' + data.id, data, HttpMethod.PUT);
}

