import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import {
    cancelCheckList,
    deleteCheckListScheduledCheck,
    getCheckListScheduledChecks
} from "../../../Services/CheckList/CheckListScheduledCheckService";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {
    formatColumnConstant,
    formatColumnDate,
    formatColumnGeoMap,
    formatColumnObject
} from "../../../Components/DataGrid/ValueFormatter";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import AddScheduleCheck from "./AddScheduleCheck";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import EditScheduleCheck from "./EditScheduleCheck";
import {
    CheckListScheduledCheckStatus,
    getCheckListScheduledCheckStatusString
} from "../../../Constants/CheckList/CheckListScheduledCheckStatus";
import {getPartners, getPartner} from "../../../Services/CRM/PartnerService";
import {getEmployees, getEmployeeByUser} from "../../../Services/User/UserService";
import {getAssetManagementLocations, getAssetManagementLocation} from "../../../Services/AssetManagement/AssetManagementLocationService";
import {fetchOptions} from "../../../Services/Base/FilterOptionsService";
import { getCheckListScheduledCheckStatus} from "../../../Constants/CheckList/CheckListScheduledCheckStatus";
import YesNoDialog, {YesNoDialogResult} from "../../../Components/Dialogs/YesNoDialog";
import SnackbarContext from "../../../Context/SnackbarContext";
import {Button} from "@mui/material";


const tableDescription = [
    {
        field: 'name', headerName: strings.pages.checkList.table.name,
        width: 200,
    },
    {
        field: 'status', headerName: strings.pages.checkList.table.status,
        width: 200,
        valueFormatter: (params) => formatColumnConstant(params, getCheckListScheduledCheckStatusString)
    },
    {
        field: 'map', headerName: strings.pages.checkList.table.map,
        width: 200,
        renderCell: (params) => formatColumnGeoMap(params)
    },
    {
        field: 'dateFrom', headerName: strings.pages.checkList.table.dateFrom,
        valueFormatter: (params) => formatColumnDate(params),
        width: 100
    },
    {
        field: 'dateTo', headerName: strings.pages.checkList.table.dateTo,
        valueFormatter: (params) => formatColumnDate(params),
        width: 100
    },
    {
        field: 'partner', headerName: strings.pages.checkList.table.partner,
        width: 200,
        valueFormatter: (params) => formatColumnObject(params, 'name')
    },
    {
        field: 'location', headerName: strings.pages.checkList.table.location,
        width: 200,
        valueFormatter: (params) => formatColumnObject(params, 'name')
    },
    {
        field: 'template', headerName: strings.pages.checkList.table.checkListTemplate,
        width: 200,
        valueFormatter: (params) => formatColumnObject(params, 'name')
    },
];

const filters = [

]

const CheckListScheduledCheckList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const siteData = useSelector((state) => state.siteData);
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.CHECK_LIST_SCHEDULED_CHECK;
    const [filterOptions, setFilterOptions] = useState({});
    const permissionGroup = AppPermissions.CHECK_LIST_SCHEDULED_CHECK.GROUP;
    const {showMessage} = useContext(SnackbarContext);
    const [itemForCancel, setItemForCancel] = useState({});

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    const controlColumn = {
        field: 'control',
        headerName: strings.pages.hr.presence.presenceList.controls,
        width: 100,
        renderCell: params => formatColumnControl(params),
        align: 'center'
    };

    const formatColumnControl = (params) => {
        if(params.row.status === CheckListScheduledCheckStatus.CANCELED || params.row.status === CheckListScheduledCheckStatus.DONE) {
            return ''
        }

        return <div className='submit-container'>
            <Button variant="contained" size="small" color="secondary" className='table-button' onClick={() => handleItems(params.row.id)}>
                {strings.pages.checkList.scheduleCheck.scheduleCheckList.cancel}
            </Button>
        </div>
    }


    const additionalMenuItems = [
        {handleClick: (id, item) => handleDoCheck(id, item), text: strings.pages.checkList.table.doCheck, src: '/images/table-page/content.svg'},

    ]

    const handleDoCheck = (id, scheduledCheck) => {
        navigate('/check-list-forms', {state: {template: scheduledCheck.template, id: id, scheduledCheck: scheduledCheck}})
    }
    
    const handleItems = (id) => {
        setShowCancelDialog(true);
        setItemForCancel(id);
    }

    const handleCancelDialogResult = (result) => {
        if (result === YesNoDialogResult.NO || result === YesNoDialogResult.CANCEL) {
            setShowCancelDialog(false);
            return;
        }

        cancelCheckList(itemForCancel).then(response => {
            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorCancel, 'error');
                setShowCancelDialog(false);
                setSelectionModel([]);
                onFinish();
                return;
            }

            showMessage(strings.components.tablePage.checkListCancelled, 'success')
            setShowCancelDialog(false);
            setSelectionModel([]);
            onFinish();
        })
    }

    const fetchPartners = async (term, filterFetch) => {
        return fetchOptions('partner', searchParams, getPartners, getPartner, setFilterOptions, term, filterFetch);
    }

    const fetchEmployees = async (term, filterFetch) => {
        return fetchOptions('employee', searchParams, getEmployees, getEmployeeByUser, setFilterOptions, term, filterFetch, 'user');
    }

    const fetchLocations = async (term, filterFetch) => {
        return fetchOptions('location', searchParams, getAssetManagementLocations, getAssetManagementLocation, setFilterOptions, term, filterFetch);
    }

    const fetchFilters = async() => {
        const partners = await fetchPartners();
        const employees = await fetchEmployees();
        const locations = await fetchLocations();

        setFilterOptions({
            status: getCheckListScheduledCheckStatus(),
            partner: partners,
            employee: employees,
            location: locations,
            fetched: 1,
        });
    }

    const filters = [
        {
            name: 'partner',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'partner',
            label: strings.pages.checkList.table.partner,
            onChange: (term) => fetchPartners(term, true),
        },
        {
            name: 'employee',
            nameKey: 'fullName',
            valueKey: 'id',
            optionsName: 'employee',
            label: strings.pages.checkList.table.employee,
            onChange: (term) => fetchEmployees(term, true),
        },
        {
            name: 'location',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'location',
            label: strings.pages.checkList.table.location,
            onChange: (term) => fetchLocations(term, true),
        },
        {
            name: 'status',
            nameKey: 'name',
            valueKey: 'value',
            optionsName: 'status',
            label: strings.pages.checkList.table.status,
            showSelect: true
        }
    ]


    useEffect(() => {
        setColumns([controlColumn,...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.checkList.scheduleCheck.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.CHECK_LIST},
                {label: strings.pages.checkList.scheduleCheck.pageTitle},
            ],
        }));

        fetchFilters();

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }
        fetch();
    }, [filter, updatedFilter])

    useEffect(() => {
        if(!Object.keys(siteData.data).length){
            return;
        }

    },[siteData])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getCheckListScheduledChecks({
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    return <TablePageContext.Provider value={value}>
        <TablePage tableDescription={columns} deleteItem={deleteCheckListScheduledCheck}
                   tableData={tableData} onFinish={onFinish}
                   filter={filter} filters={filters} filterOptions={filterOptions}
                   referenceType={referenceType}
                   permissionGroup={permissionGroup} additionalMenuItems={additionalMenuItems}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditScheduleCheck data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                   addPage={<AddScheduleCheck onCancel={() => onCancel()} onFinish={() => onFinish() }
                   />}
                   addButtonText={strings.components.tablePage.addButton.scheduleCheck}
        />
        <YesNoDialog show={showCancelDialog}
                            payload={selectionModel}
                            handleResult={handleCancelDialogResult}
                            title={strings.components.tablePage.confirmCancel}
                            text={strings.components.tablePage.confirmCancelMessage}/>
    </TablePageContext.Provider>;
}

export default CheckListScheduledCheckList;
