import React, {useState, useEffect, useContext} from 'react';
import {changePageContent, changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import Modules from "../../../Constants/Base/Modules";
import {useDispatch, useSelector} from "react-redux";
import YesNoDialog, {YesNoDialogResult} from "../../../Components/Dialogs/YesNoDialog";
import TreeViewList from "../../../Components/Tree/TreeViewList";
import {CategoryDot, CategoryIcon} from "../../../Util/ImageUtil";
import {
    addCheckListCategory, deleteCheckListCategory, editCheckListCategory, getCheckListCategories
} from "../../../Services/CheckList/CheckListCategoryService";
import {hasPermission} from "../../../Util/PermissionUtil";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {IconButton, InputBase, Modal, Paper} from "@mui/material";
import CheckListCategoryTemplates from "./CheckListCategoryTemplates";
import NewTemplateForm from "../../../Components/Forms/Pages/CompanyDocument/CompanyDocumentTemplate/NewTemplateForm";
import EditCompanyDocumentTemplate from "../../CompanyDocument/CompanyDocumentTemplate/EditCompanyDocumentTemplate";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import SnackbarContext from "../../../Context/SnackbarContext";
import LoaderContext from "../../../Context/LoaderContext";
import {useForm} from "react-hook-form";
import {
    addCheckListTemplate, deleteCheckListTemplate, editCheckListTemplate, getCheckListTemplates
} from "../../../Services/CheckList/CheckListTemplateService";

const formRules = {
    'name': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
};


const CheckListTemplateList = ({createDocument = false, onCreate}) => {

    const dispatch = useDispatch();

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const permissionGroupTemplate = AppPermissions.CHECK_LIST_TEMPLATE.GROUP;
    const permissionGroupCategory = AppPermissions.CHECK_LIST_CATEGORY.GROUP;
    const [handleShowModal, setHandleShowModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [filteredCategories, setFilteredCategories] = useState(null);
    const [dataFetchCategory, setDataFetchCategory] = useState(null);
    const {showMessage} = useContext(SnackbarContext);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedEditTemplate, setSelectedEditTemplate] = useState(null);
    const {setLoading} = useContext(LoaderContext);
    const [filterName, setFilterName] = useState(null)
    const [changeTree, setChangeTree] = useState(null)
    const [editState, setEditState] = useState(false);


    const auth = useSelector((state) => state.auth)

    const form = useForm({});
    const {formData, handleSubmit, getValues, setValue, setError, formState: {errors}} = form;

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.checkList.title,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.CHECK_LIST},
                {label: strings.pages.checkList.checkListTemplate.pageTitle},
            ],
            submenu: true,
        }));
    }, [])

    const expandHandler = (selectedCategory) => {
        setLoading(true);
        setSelectedCategory(selectedCategory);
        setDataFetchCategory(null);

        const data = { "checkListCategoryId": selectedCategory?.id}
        if(selectedCategory !== null) {
            getCheckListTemplates(data).then(response => {
                    setFilteredCategories(response.data.result);
                    setLoading(false);
                }
            )
        }else{
            setFilteredCategories(null);
            setLoading(false);
        }
    }

    const searchTemplate = (name) => {
        setFilterName(name)
    }

    const handleCreateTemplate = () => {
        setHandleShowModal(true)
    }

    const handleCloseModal = () => {
        setHandleShowModal(false);
        form.reset();
    }

    const submitHandler = (data) => {
        data = {...data, "checkListCategory": dataFetchCategory ? dataFetchCategory : selectedCategory}
        let currentTemplate;
        addCheckListTemplate(data).then( response => {
            currentTemplate = response.data?.entity;
            data = { "checkListCategoryId": dataFetchCategory?.id ? dataFetchCategory?.id : selectedCategory?.id}
            getCheckListTemplates(data).then(response => {
                setFilteredCategories(response.data.result);
            })
        })
        handleCloseModal();
    };

    const changeCategoryHandler = (category) => {
        if(category === dataFetchCategory){setDataFetchCategory(null); return}
        setDataFetchCategory(category)
    }

    const onDeleteTemplate = (templateId) => {
        setShowDeleteDialog(true);
        setSelectedTemplate(templateId);
    }

    const onEditTemplate = (template) => {
        setSelectedEditTemplate(template);
        setShowEditModal(true)
    }

    const handleDeleteDialogResult = (result, templateId) => {
        if (result === YesNoDialogResult.NO || result === YesNoDialogResult.CANCEL) {
            setShowDeleteDialog(false);
            return;
        }
        if(templateId) {
            deleteCheckListTemplate(templateId).then(response => {
                const data = {"checkListCategoryId": dataFetchCategory?.id ? dataFetchCategory?.id : selectedCategory?.id}
                getCheckListTemplates(data).then(response => {
                    setFilteredCategories(response.data.result);
                })
            })

            showMessage(strings.components.tablePage.itemDeleted, 'success')
            setShowDeleteDialog(false);
            setSelectedTemplate(null);
        }
    }

    const submitEditTemplateHandler = (data) => {
        setSelectedEditTemplate(null);
        editCheckListTemplate(data).then(response => {
            const data = {"checkListCategoryId": dataFetchCategory?.id ? dataFetchCategory?.id : selectedCategory?.id}
            getCheckListTemplates(data).then(response => {
                setFilteredCategories(response.data.result);
            })
        })
    }

    const onCreateHandler = (template) => {
        onCreate(template);
    }

    const onChangeTree = (item) => {
        setChangeTree(item);
    }



    return (
        <div>
            <TreeViewList
                onExpand={expandHandler}
                folderIcon={CategoryIcon}
                childIcon={CategoryDot}
                getFolders={getCheckListCategories}
                addFolder={addCheckListCategory}
                editFolder={editCheckListCategory}
                deleteFolder={deleteCheckListCategory}
                changeState={onChangeTree}
                editOutside={editState}
                permissionGroup={permissionGroupCategory}
                addStringButton={strings.pages.companyDocument.companyDocumentCategory.addCategory}
                addStringModal={strings.pages.companyDocument.companyDocumentCategory.createNewCategory}
                addItemString={strings.components.treeViewList.createNewCategory}
                editItemString={strings.components.treeViewList.editCategory}
            ></TreeViewList>
            <YesNoDialog show={showDeleteDialog}
                         payload={selectedTemplate}
                         handleResult={handleDeleteDialogResult}
                         title={strings.components.tablePage.confirmDelete}
                         text={strings.components.tablePage.confirmDeleteMessage}/>
            <div id={'templates'}>
                <div className='template-position mt-600'>
                <div className="content">

                    {
                        hasPermission(auth.user, permissionGroupTemplate, AppPermissions[permissionGroupTemplate].VIEW, auth.permissions) &&
                        <div className="templates-list">
                            <div className='header'>
                                <div className={'filter-container'}>
                                    <div id = 'searchfield-container'>
                                        <Paper>
                                            <IconButton>
                                                <img src = '/images/search.svg'/>
                                            </IconButton>
                                            <InputBase
                                                placeholder = {"Search"}
                                                onChange={(e) => searchTemplate(e.target.value)}
                                            />
                                        </Paper>
                                    </div>
                                </div>
                                <div className="w-100 d-flex justify-end">
                                    {
                                        hasPermission(auth.user, permissionGroupTemplate, AppPermissions[permissionGroupTemplate].ADD, auth.permissions) &&
                                        !createDocument && <IconButton className='plus-button mui-rounded-button mb-1' disabled={(dataFetchCategory) ? false : true} onClick={() => handleCreateTemplate()}>
                                            <img src='/images/table-page/add-cross.svg'/>
                                            <div className="font-15">
                                                {strings.pages.companyDocument.companyDocumentTemplate.newTemplate}
                                            </div>
                                        </IconButton>
                                    }
                                </div>
                            </div>
                            <CheckListCategoryTemplates
                                category={selectedCategory}
                                permissionGroup={permissionGroupTemplate}
                                filteredCategories={filteredCategories}
                                setHandleShowModal={setHandleShowModal}
                                selectedCategory={selectedCategory}
                                dataFetchCategory={dataFetchCategory}
                                changeCategory={changeCategoryHandler}
                                filterName={filterName}
                                onDeleteTemplate={onDeleteTemplate}
                                onEditTemplate={onEditTemplate}
                                changeTree={changeTree}
                                editState={editState}
                                setEditState={setEditState}
                                createDocument={createDocument}
                                onCreate={onCreateHandler}
                            ></CheckListCategoryTemplates>
                            <div>
                                <Modal
                                    open={Boolean(handleShowModal)}
                                    onClose={handleCloseModal}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    id='add-edit-template-modal'>
                                    <Paper>
                                        <img className='add-edit-template-modal-close' src='/images/close-issue-view.svg' onClick = {handleCloseModal} />
                                        <div className='add-edit-template-modal-title'>{strings.pages.companyDocument.companyDocumentTemplate.templateDetails}</div>
                                        <NewTemplateForm
                                            formRules={formRules}
                                            values={getValues()}
                                            setValue={setValue}
                                            errors={errors} formData={formData} form={form}
                                            onSubmit={handleSubmit(submitHandler)}/>
                                    </Paper>
                                </Modal>
                            </div>
                            {selectedEditTemplate && <EditCompanyDocumentTemplate onSubmit={submitEditTemplateHandler} showModal={showEditModal} closeModal={() => setShowEditModal(false)} template={selectedEditTemplate}></EditCompanyDocumentTemplate>}
                        </div> }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default CheckListTemplateList;