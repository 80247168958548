import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from '../../../../Controls/Inputs/AutoCompleteControl';

const ManageBankReportForm = ({
                         data,
                         form,
                         values,
                         setValue,
                         banks,
                         control,
                         onDataChange,
                     }) => {
    const {pageState} = useContext(TablePageContext);

    return (
        <FormProvider {...form}>
            <form id='manage-bank-report-form' className='form' action="#">

                <AutoCompleteControl
                    value={values['bank']}
                    setValue={(name,id) => onDataChange(id)}
                    name='bank'
                    label={strings.pages.finance.bankReport.bankReportList.bank}
                    control={control}
                    options={banks}
                    nameKey={'name'}
                    valueKey={'id'}
                />

            </form>
        </FormProvider>
    );
}

export default ManageBankReportForm;