import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllInventoryLocations() {
    return await request('/api/asset-management-locations/all');
}

export async function getInventoryLocations(data) {
  return await request('/api/asset-management-locations', data);
}

export async function getAllInventoryTypes() {
    return await request('/api/inventory-types/all');
}

export async function getInventoryTypes(data) {
    return await request('/api/inventory-types', data);
}

export async function getAllInventories() {
    return await request('/api/inventories/all');
}

export async function getInventoryList(data) {
    return await request('/api/inventories', data);
}

export async function getInventory(id) {
    return await request('/api/inventories/' + id);
}

export async function deleteInventory(id) {
    return await request('/api/inventories/' + id, {}, HttpMethod.DELETE);
}

export async function editInventory(data) {
    return await request('/api/inventories/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addInventory(data) {
    return await request('/api/inventories', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        locationId: data.location ? data.location.id : null,
        typeId: data.type ? data.type.id : null,
    }
}
