export const BoardIssueFileTypeValues = {
    'OTHER': 1,
    'IMAGE': 2,
    'PDF': 3,
}

export const BoardIssueFileTypeNames = {
    1: 'File',
    2: 'Image',
    3: 'Pdf',
}
