import React from "react";
import {MenuItem} from "@mui/material";

export const getDropdownOptions = (array, label, valueKey = 'id') => {
    if(!array){
        return;
    }

    const groups = array.reduce((groups, item) => {
        let key = item?.parent?.id? item?.parent?.id : 'root';
        return {
            ...groups,
            [key]: [...(groups[key] || []), item]
        }
    }, {'root': []});

    let result = [
        <MenuItem key={'menu-option-select-'+label + 1} value={-1}>Select option</MenuItem>
    ];

    if(!groups['root']) {
        return result;
    }

    for (let rootItem of groups['root']) {
        result.push(<MenuItem key={'menu-option-' + label + '-' + result.length+1} value={rootItem[valueKey]} divider={!!groups[rootItem.id]}>{rootItem[label]}</MenuItem>)
        if (groups[rootItem.id]) {
            for (let item of groups[rootItem.id]) {
                result.push(<MenuItem key={'menu-option-' + label + '-' + result.length+1} value={item[valueKey]} className={'sub-item'}>{item[label]}</MenuItem>)
            }
        }
    }

    return result;
}

export const filterDropdownItem = (response, selectedItem) => {

    return response.filter(r => r && r.id != selectedItem);
}
