import * as React from 'react';
import {useState, useEffect} from "react";
import {hasPermission} from "../../Util/PermissionUtil";
import AppPermissions from "../../Constants/Permissions/Permissions";
import {useSelector} from "react-redux";
import {Popover, Tooltip, Typography} from '@mui/material';
import strings from '../../localization';

const ActionCell = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverText, setPopoverText] = useState('');
    const auth = useSelector((state) => state.auth)

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlerClick = (handler) => {
        handleClose();
        handler(props.id, props.item);
    }

    const handleDocumentClick = (handler) => {
        handleClose();
        handler(props.id, props.refereceneType)
    }
    return <React.Fragment>
            {
                <Popover className="hover-popover-table-page"
                         sx={{pointerEvents: 'none',}}
                         anchorOrigin={{ vertical: 'top',horizontal: 'right', }}
                         open={anchorEl}
                         anchorEl={anchorEl}
                         onClose={handleClose} 
                         disableAutoFocus>
                         <Typography>  {popoverText} </Typography>
                </Popover>
            }
            {
                props.tablePageOptions.showActionView && !props.hideView &&
                hasPermission(auth.user, props.permissionGroup, AppPermissions[props.permissionGroup].VIEW, auth.permissions) &&
                <Tooltip title={strings.components.tablePage.popover.view}>
                    <img onClick={() => handlerClick(props.handleView)}
                         src='/images/table-page/view.svg'/>
                </Tooltip>
            }
            {
                props.tablePageOptions.showActionEdit && props.editPage &&
                (hasPermission(auth.user, props.permissionGroup, AppPermissions[props.permissionGroup].EDIT_MY, auth.permissions) ||
                hasPermission(auth.user, props.permissionGroup, AppPermissions[props.permissionGroup].EDIT, auth.permissions)) &&
                <Tooltip title={strings.components.tablePage.popover.edit}>
                    <img onClick={() => handlerClick(props.handleEdit)}
                         src='/images/table-page/edit.svg'/>
                </Tooltip>
            }
            {
                props.tablePageOptions.showActionDelete && props.deleteItem &&
                (hasPermission(auth.user, props.permissionGroup, AppPermissions[props.permissionGroup].DELETE_MY, auth.permissions) ||
                hasPermission(auth.user, props.permissionGroup, AppPermissions[props.permissionGroup].DELETE, auth.permissions)) &&
                <Tooltip title={strings.components.tablePage.popover.delete}>
                    <img onClick={() => handlerClick(props.handleDelete)}
                         src='/images/table-page/delete.svg'/>
                </Tooltip>
            }
            {
                props.tablePageOptions.showActionDocument && !props.hideDocuments &&
                hasPermission(auth.user, props.permissionGroup, AppPermissions[props.permissionGroup].DOCUMENTS, auth.permissions) &&
                <Tooltip title={strings.components.tablePage.popover.files}>
                    <img onClick={() => handlerClick(props.handleDocuments)}
                         src='/images/table-page/file.svg'/>
                </Tooltip>
            }
            {/*{*/}
            {/*    props.additionalMenuItems && props.additionalMenuItems.map((menuItem, i) => (*/}
            {/*        (!menuItem.hide || (typeof menuItem.hide == 'function' && !menuItem.hide(props.item))) &&*/}
            {/*        <Tooltip title={menuItem.text}>*/}
            {/*            <img onClick={() => handlerClick(menuItem.handleClick)}*/}
            {/*                 src={menuItem.src? menuItem.src : ""}/>*/}
            {/*        </Tooltip>*/}
            {/*    ))*/}
            {/*}*/}
        {
            props.additionalMenuItems && props.additionalMenuItems.map((menuItem, i) => (
                (!menuItem.hide || (typeof menuItem.hide === 'function' && !menuItem.hide(props.item))) && (
                    <Tooltip key={i} title={typeof menuItem.text === 'function' ? menuItem.text(props.item) : menuItem.text}>
                        <img
                            onClick={() => handlerClick(menuItem.handleClick)}
                            src={typeof menuItem.src === 'function' ? menuItem.src(props.item) : menuItem.src}
                        />
                    </Tooltip>
                )
            ))
        }
    </React.Fragment>
}

export default ActionCell;