import React, {useState, useEffect, useRef} from 'react';
import {IconButton, Modal, Paper, Step, StepLabel, Stepper} from "@mui/material";
import { getCheckListTemplateSteps} from "../../../Services/CheckList/CheckListTemplateStepService";
import {
    getCheckListTemplateQuestions
} from "../../../Services/CheckList/CheckListTemplateQuestionService";
import {
    getCheckListTemplateQuestionOptions
} from "../../../Services/CheckList/CheckListTemplateQuestionOptionService";
import {useLocation, useNavigate} from "react-router-dom";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import Modules from "../../../Constants/Base/Modules";
import {useDispatch} from "react-redux";
import {BackButton} from "../../../Util/ImageUtil";
import TextFieldControl from "../../../Components/Controls/Inputs/TextFieldControl";
import {useForm} from "react-hook-form";
import DateTimeControl from "../../../Components/Controls/Inputs/DateTimeControl";
import {doCheckCheckListScheduledCheck} from "../../../Services/CheckList/CheckListScheduledCheckService";
import {
    CompanyDocumentQuestionType,
} from "../../../Constants/CompanyDocumentManagement/CompanyDocumentItem/CompanyDocumentQuestionType";
import { CheckListScheduledCheckStatus } from "../../../Constants/CheckList/CheckListScheduledCheckStatus";
import { editCheckListScheduledCheck } from "../../../Services/CheckList/CheckListScheduledCheckService";
import SelectControl from "../../../Components/Controls/Inputs/SelectControl";
import {FormProvider} from "react-hook-form";
import RadioButtonControl from "../../../Components/Controls/Inputs/RadioButtonControl";
import {getCheckListScheduledCheckAnswers} from "../../../Services/CheckList/CheckListScheduledCheckAnswerService";


const formRules = {
    'required': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
};

const CheckListForms = ({template}) => {

    const dispatch = useDispatch();
    const [steps, setSteps] = useState([]);
    const [activeStepIndex, setActiveStepIndex] = useState(1);
    const [completedSteps, setCompletedSteps] = useState([]);
    const [stepQuestions, setStepQuestion] = useState([]);
    const [currentStepQuestions, setCurrentStepQuestion] = useState([]);
    const location = useLocation();
    const [handleShowModal, setHandleShowModal] = useState(true);
    const navigate = useNavigate();
    const basicData = useRef({data: null});
    const [questionOptions, setQuestionOptions] = useState({});
    const [backDisabled, setBackDisabled] = useState(true);
    const [answers, setAnswers] = useState([]);

    const [userLocation, setUserLocation] = useState({
        latitude: null,
        longitude: null,
        error: null,
    })

    useEffect(() => {
        retrieveUserLocation()
    }, [])


    const retrieveUserLocation = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {

                    console.log(position)

                    setUserLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        error: null,
                    });
                },
                (error) => {
                    setUserLocation((prevState) => ({
                        ...prevState,
                        error: error.message,
                    }));
                }
            );
        }
    }


    const form = useForm({
    });

    const {data, control, handleSubmit, getValues, setValue, formState: {errors}, reset} = form;
    const getAnswers = () => {
        const data = {scheduledCheckId: location.state?.id};

        getCheckListScheduledCheckAnswers(data).then(response => {
            setAnswers(response.data.result);
            response.data.result.forEach(answer => {
            setAnswer(answer)
            })
        })
    }

    const setAnswer = (answer) => {
        if(answer.textAnswer){
            setValue(answer.name, answer.textAnswer);
        }
        if(answer.numberAnswer){
            setValue(answer.name, answer.numberAnswer);
        }
        if(answer.dateAnswer){
            setValue(answer.name, answer.dateAnswer);
        }
        if(answer.optionAnswer){
            setValue(answer.name, answer.optionAnswer);
        }
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.checkList.doCheck.doCheck,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.CHECK_LIST},
                {label: strings.pages.checkList.doCheck.scheduledCheck},
            ],
        }));
        getAnswers();
        if(location.state?.scheduledCheck.status === CheckListScheduledCheckStatus.PENDING) {
            const sendData = {id: location.state?.id, status: CheckListScheduledCheckStatus.IN_PROGRESS}
            editCheckListScheduledCheck(sendData).then(response => {
                }
            )
        }
    }, [])

    useEffect(() => {
        fetchSteps()
    }, [])

    useEffect(() => {
        for(let question of currentStepQuestions){
            if(question.type === CompanyDocumentQuestionType.SELECT ||
                question.type === CompanyDocumentQuestionType.RADIO){
                fetchQuestionOptions(question)
            }
        }
    }, [currentStepQuestions])

    const transformFormData = (data) => {
        let transformedData = {...data};
        stepQuestions.forEach(question =>{

            if(question.type === CompanyDocumentQuestionType.SELECT){
                const selectValue = transformedData[question.code]?.option;
                transformedData[question.code] = {...question, answer: selectValue}
            }
            if(question.type === CompanyDocumentQuestionType.TEXT){
                transformedData[question.code] = {...question, answer: transformedData[question.code]}
            }
            if(question.type === CompanyDocumentQuestionType.DATE){
                transformedData[question.code] = {...question, answer: transformedData[question.code]}
            }
            if(question.type === CompanyDocumentQuestionType.NUMBER){
                transformedData[question.code] = {...question, answer: transformedData[question.code]}
            }
            if(question.type === CompanyDocumentQuestionType.RADIO){
                transformedData[question.code] = {...question, answer: transformedData[question.code]}
            }
        })
        return {data: transformedData};
    }

    const submitForm = (submitedData) => {

        const transformedData = transformFormData(submitedData);
        const data = {
            ...transformedData,
            latitude: userLocation?.latitude,
            longitude: userLocation?.longitude,
            checkListTemplateId: location.state?.template.id,
            checkListCategoryId: location.state?.template.checkListCategory.id,
            id: location.state?.id}

        if(validate(form.getValues())) {
            doCheckCheckListScheduledCheck(data).then(response => {
                const data = {id: location.state?.id, status: CheckListScheduledCheckStatus.DONE}
                editCheckListScheduledCheck(data).then( response => {
                    navigate('/check-list-scheduled-checks');
                    }
                )
            })
        }
    }

    const fetchSteps = () => {
        const data = {'checkListTemplateId': location.state?.template.id}
        getCheckListTemplateSteps(data).then(response => {
            setSteps(response.data.result)
            for(let step of response.data.result){
                fetchAllStepQuestions(step)
            }
            if(response.data.result.length > 0){
                fetchStepQuestions(response.data.result[activeStepIndex - 1])
            }
        })
    }

    const fetchAllStepQuestions = (step) => {
        const data = {'step': step.id}
        getCheckListTemplateQuestions(data).then(response => {
            for(let question of response.data.result){
                if(!stepQuestions.find(item => item.code === question.code)) {
                    setStepQuestion(stepQuestions => [...stepQuestions, question])
                }
            }
        })
    }

    const fetchStepQuestions = (step) => {
        const data = {'step': step.id}
        getCheckListTemplateQuestions(data).then(response => {
            setCurrentStepQuestion(response.data.result)
        })
    }
    const nextStep = () => {
        setBackDisabled(false);
        if(steps.length > activeStepIndex){
            setCompletedSteps([...completedSteps, activeStepIndex])
            setActiveStepIndex(active => active+1)
            fetchStepQuestions(steps[activeStepIndex])
            return
        }
        if(steps.length === activeStepIndex){
            handleSubmit(submitForm)();
        }
    }

    const backStep = () => {
        if(activeStepIndex <= 1) {return }
        if((activeStepIndex - 1) === 1) {
            setBackDisabled(true);
        }
        setCompletedSteps(completedSteps.splice(-1))
        setActiveStepIndex(active => active-1)
        fetchStepQuestions(steps[activeStepIndex-2])
    }

    const fetchQuestionOptions = (question) => {
        const data = {questionId: question.id}
        getCheckListTemplateQuestionOptions(data).then(response => {
            setQuestionOptions((questionOptions) => {
                return {
                    ...questionOptions,
                    [question.code]: response.data.result,
                };
            });
        })
    }

    const renderFormField = (question) => {
        if(question.type == CompanyDocumentQuestionType.TEXT){
            return (
                <div className="form-field" key={question.code}>
                    <TextFieldControl
                        name={question.code}
                        className='generated-form-field'
                        control={control}
                        error={Boolean(errors[question.code])}
                        helperText={errors[question.code] && errors[question.code].message}
                        label={question.question}
                        disabled={location.state?.scheduledCheck.status === CheckListScheduledCheckStatus.DONE
                                  ? true : false}
                    />
                </div>
            );
        }
        if(question.type == CompanyDocumentQuestionType.NUMBER){
            return (
                <div className="form-field" key={question.code}>
                    <TextFieldControl
                        name={question.code}
                        className='generated-form-field'
                        control={control}
                        type="number"
                        error={Boolean(errors[question.code])}
                        helperText={errors[question.code] && errors[question.code].message}
                        label={question.question}
                        disabled={location.state?.scheduledCheck.status === CheckListScheduledCheckStatus.DONE
                            ? true : false}
                    />
                </div>
            );
        }
        if(question.type == CompanyDocumentQuestionType.DATE){
            return(
                <div className="form-field" key={question.code}>
                    <DateTimeControl
                        className="generated-form-field"
                        data={control}
                        label={question.question}
                        value={getValues(question.code)}
                        name={question.code}
                        error={Boolean(errors[question.code])}
                        helperText={errors[question.code] && errors[question.code].message}
                        setValue={setValue}
                        disabled={location.state?.scheduledCheck.status === CheckListScheduledCheckStatus.DONE
                            ? true : false}
                    />
                </div>
            )
        }
        if(question.type == CompanyDocumentQuestionType.SELECT){
            return (
                <div className="form-field" key={question.code}>
                    <SelectControl
                        className='generated-form-field'
                        setValue={setValue}
                        value={getValues(question.code)}
                        control={control}
                        name={question.code}
                        error={Boolean(errors[question.code])}
                        helperText={errors[question.code] && errors[question.code].message}
                        label={question.question}
                        options={questionOptions[question.code]}
                        nameKey={'option'}
                        valueKey={'option'}
                        disabled={location.state?.scheduledCheck.status === CheckListScheduledCheckStatus.DONE
                            ? true : false}
                    />
                </div>
            )
        }
        if(question.type == CompanyDocumentQuestionType.RADIO){
            return (
                <div className="form-field" key={question.code}>
                    <RadioButtonControl
                        className='generated-form-field'
                        value={getValues(question.code)}
                        setValue={setValue}
                        control={control}
                        name={question.code}
                        error={Boolean(errors[question.code])}
                        helperText={errors[question.code] && errors[question.code].message}
                        label={question.question}
                        options={questionOptions[question.code]}
                        disabled={location.state?.scheduledCheck.status === CheckListScheduledCheckStatus.DONE
                            ? true : false}
                    />
                </div>
            )
        }
    }

    const createFormFields = () => {
        let formFields = [];

        for(let question of currentStepQuestions) {
            formFields.push(
                renderFormField(question)
            )
            if(question.type == CompanyDocumentQuestionType.DATE && !getValues(question.code)){
                const date = new Date();
                setValue(question.code, date)
            }
        }
        return formFields;
    }

    const renderForm = () => {
        return (
            createFormFields()
        )
    }

    const handleCloseModal = () => {
        navigate(-1);
        form.reset();
    }

    const submitHandler = (data) => {
        basicData.current.data = data;
        setHandleShowModal(false);
    }


    const validate = (data) => {
        let indexError = -1;

        stepQuestions.forEach((question) => {
            if (question.required && !data[question.code]) {
                form.setError(question.code, { type: "required", message: strings.forms.common.thisFieldIsRequired });
                const newIndexError = steps.findIndex(step => step.id === question.checkListTemplateStep.id);
                indexError = (indexError === -1 || indexError > newIndexError) ? newIndexError : indexError;
            } else {
                form.clearErrors(question.code);
            }
        });
        if(indexError !== -1){
            onStepClick(indexError+1)
            return false;
        }
        return true;
    }

    const onStepClick = (index) => {
        if(index === 1){
            setBackDisabled(true);
        }else{
            setBackDisabled(false);
        }
        setActiveStepIndex(index);
        fetchStepQuestions(steps[index-1]);
        handleSubmit(nextStep);
    }

    return (
        <div className="form-page">
            <Paper sx={{ broderRadius: 10}} className="stepper">
                <div className="template-stepper">
                    <Stepper activeStep={1} alternativeLabel={true}>
                        {steps.map((step, index) => {
                            index = index + 1;
                            return <Step key={step.name} onClick={() => onStepClick(index)} active={(index === activeStepIndex)} completed={(index < activeStepIndex)}>
                                <StepLabel>{step.name}</StepLabel>
                            </Step>
                        })}
                    </Stepper>
                </div>
            </Paper>

            <Paper className="form">
                <div className="form-header">
                    <button className="back-button-btn" onClick={backStep} disabled={backDisabled}>
                        <div className="back-button" ><div className="back-button-icon"><BackButton></BackButton></div>
                            <div className="back-text">
                                {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateStep.back}
                            </div>
                        </div>
                    </button>
                    <div className="step-name"></div>
                </div>

                <div className="form-container">

                    <FormProvider {...form}>
                        <form className="generated-form" onSubmit={(e) => e.preventDefault()}>
                            {
                                userLocation && userLocation.latitude && userLocation.longitude &&
                                <div>
                                    <a href={`https://www.google.com/maps?q=${userLocation.latitude},${userLocation.longitude}&hl=en`} target={'_blank'}>Location</a>
                                </div>
                            }

                            {stepQuestions && renderForm()}
                        </form>
                    </FormProvider>
                </div>
                <div className="form-footer">
                    {(location.state?.scheduledCheck.status != CheckListScheduledCheckStatus.DONE) &&
                    <IconButton className='next-button' onClick={nextStep}>
                        <div className='next-text'>
                            {
                                (activeStepIndex !== steps.length) ?
                                strings.pages.checkList.viewCheckAnswers.next
                                : strings.pages.checkList.viewCheckAnswers.submit
                            }
                        </div>
                    </IconButton>
                    }
                    {(location.state?.scheduledCheck.status === CheckListScheduledCheckStatus.DONE) &&
                        (activeStepIndex !== steps.length) &&
                        <IconButton className='next-button' onClick={nextStep}>
                            <div className='next-text'>
                                {strings.pages.checkList.viewCheckAnswers.next}
                            </div>
                        </IconButton>
                    }
                </div>
            </Paper>
            <div>
            </div>
        </div>
    );
}

export default CheckListForms;