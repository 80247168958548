import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";


export async function getCheckListScheduledCheckAnswers(data) {
    return await request('/api/check-list-scheduled-check-answer', data);
}

export async function getAllCheckListScheduledCheckAnswers(data) {
    return await request('/api/check-list-scheduled-check-answer/all', data);
}




