import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getUserRoles(userId) {
    return await request('/api/user-roles/' + userId);
}

export async function updateUserRole(data) {
    return await request('/api/user-roles/' + data.userId + '/' + data.roleId,
        data, HttpMethod.POST);
}