import React from "react";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Paper, Tab, Tabs} from "@mui/material";
import TeamTab from "../../../Constants/HR/TeamTab";
import {changePageSizeState} from "../../../Slices/PageSlice";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useParams} from "react-router-dom";
import ChangeTeamLogo from "./ChangeTeamLogo";
import Modules from "../../../Constants/Base/Modules";

const TeamSettings = (props) => {
    const {id: teamId} = useParams();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(TeamTab.LOGO);


    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.hr.team.teamList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.pages.hr.team.teamList.pageTitle},
                {label: strings.pages.hr.team.teamSettings.pageTitle},
            ],        
        }));
    }, [])

    return (<div id='team'>
        <div className='tab-container'>
            <Paper className='paper'>
                <Tabs variant="scrollable" value={ activeTab } onChange={ (e, tab) => setActiveTab(tab) }>
                    <Tab label={ strings.pages.hr.team.teamSettings.logo }></Tab>
                </Tabs>
            </Paper>
        </div>

        {
            activeTab === TeamTab.LOGO &&
            <div className='page-form-container'>
                <ChangeTeamLogo teamId={teamId} />
            </div>
        }

    </div>);
}

export default TeamSettings;