import React, {useContext, useEffect, useState} from "react";
import DrawerContext from "../../Context/DrawerContext";
import SnackbarContext from "../../Context/SnackbarContext";
import {useForm} from "react-hook-form";
import strings from "../../localization";
import {getUsers} from "../../Services/User/UserService";
import {editEvent} from "../../Services/Calendar/EventService";
import {
    addDocument,
    deleteDocument,
    editDocument,
    getDocuments
} from "../../Services/DocumentManagement/DocumentService";
import AddEventForm from "../../Components/Forms/Pages/Calendar/AddEventForm";
import EventContext from "../../Context/EventContext";
import {getByCustomEvent} from "../../Services/Calendar/CustomEventUserAccessService";
import {Paper} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

const formRules = {
    'name': {required: true},
    'description': {required: true},
}

const EditCustomEvent = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const [names, setNames] = useState([]);
    const {showMessage} = useContext(SnackbarContext);
    const form = useForm({
        defaultValues:
                props.data ? { ...props.data,
                    description: props.data.description,
                } : undefined
    });
    const {data, control, watch, handleSubmit,register, getValues, setValue, trigger, formState: {errors}} = form;
    const [users, setUsers] = useState([]);
    const [userAccesses, setUserAccesses] = useState([]);
    const [files, setFiles] = useState([]);
    const {customEvent,setCustomEvent} = useContext(EventContext);
    const allDay = watch("allDay");

    useEffect(() => {
        setDrawerTitle(strings.forms.calendar.editEvent.pageTitle);
        return () => {setCustomEvent(null)}
    },[]);

    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setValue('allDay', allDay);
    }, [allDay]);

    useEffect(() => {
        fetch();
        fetchUserAccesses()
        fetchDocuments()

    }, []);


    const fetch = async () => {
        const users = await getUsers();
        fetchUsers();
    }

    const fetchUsers = (term = '') => {
        getUsers({term}).then(response => {
            setUsers(response.data.result);
        });
    }

    const fetchUserAccesses = () => {
        getByCustomEvent(customEvent).then(response => {
            setValue('users', response.data.result.map(r => r.user));
            trigger('users')
        });
    }

    const fetchDocuments = () => {
        getDocuments({
            referenceType: 64,
            referenceId: customEvent.id,
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }
            setFiles(response.data.result);
            setValue('files',response.data.result);
        })
    }

    const onSubmit = (data) => {
        setShowLoader(true);
        editEvent(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok){
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            if(data.file){
                data = {file: data.file, name: data.file.name, documentSize: data.file.size, referenceId: response.data.entity.id, referenceType: 64}
                addDocument(data).then(response => {
                    setShowLoader(false);

                    if (!response || !response.ok) {
                        showMessage(strings.commonMessages.errorAdding, 'error');
                        if (strings.error.DocumentHandler[response.response.data.message]) {
                            showMessage(strings.error.DocumentHandler[response.response.data.message], 'error');
                        }
                        props.onFinish();
                        return response;
                    }

                    showMessage(strings.commonMessages.editingSuccessful, 'success');
                    props.onFinish();
                });
                for(let file of files){
                    deleteDocument(file.id).then(response=> {
                    })
                }
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            setCustomEvent(null);
            props.onFinish();
        });
    }

    const onCancel = () => {
        setCustomEvent(null)
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress />
            }
            <AddEventForm
                form={form} formRules={formRules} errors={errors} watch={watch}
                control={control} data={data}
                values={getValues()} setValue={setValue} names={names}
                setInputValue={setNames} inputValue={names}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}
                users={users}
                userAccesses={userAccesses}
                customEvent={customEvent}
                allDay={allDay} trigger={trigger}
            />
        </Paper>
    </div>

}
export default EditCustomEvent;