import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import {
    deleteProductServiceCategory, getProductServiceCategory,
    getProductServiceCategoryList
} from "../../../Services/Product/ProductServiceCategoryService";
import AddProductServiceCategory from "./AddProductServiceCategory";
import EditProductServiceCategory from "./EditProductServiceCategory";

const tableDescription = [
    {
        field: 'name', headerName: strings.pages.product.productServiceCategory.productServiceCategoryList.name,
        width: 200,
    },
    {
        field: 'description', headerName: strings.pages.product.productServiceCategory.productServiceCategoryList.description,
        width: 200,
    },
    {
        field: 'parent',
        headerName: strings.pages.product.productServiceCategory.productServiceCategoryList.parent,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 250
    },

];

const filters = []

const ProductServiceCategoryList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const siteData = useSelector((state) => state.siteData);
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.PRODUCTS_AND_SERVICES_CATEGORY;
    const [filterOptions, setFilterOptions] = useState({});
    const permissionGroup = AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.GROUP;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.product.productServiceCategory.productServiceCategoryList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.PRODUCT},
                {label: strings.pages.product.productServiceCategory.productServiceCategoryList.pageTitle},
            ],
        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    useEffect(() => {
        if(!Object.keys(siteData.data).length){
            return;
        }
    },[siteData])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getProductServiceCategoryList({
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }


            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    return <TablePageContext.Provider value={value}>
        <TablePage tableDescription={columns} deleteItem={deleteProductServiceCategory}
                   tableData={tableData} onFinish={onFinish}
                   filter={filter} filters={filters} filterOptions={filterOptions}
                   referenceType={referenceType}
                   permissionGroup={permissionGroup}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditProductServiceCategory data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                   addPage={<AddProductServiceCategory onCancel={() => onCancel()} onFinish={() => onFinish()}
                   />}
                   addButtonText={strings.components.tablePage.addButton.productServiceCategory}
        />
    </TablePageContext.Provider>;
}

export default ProductServiceCategoryList;
