import strings from "../../localization";

const PipelineStageType = {
    TO_DO: 1,
    IN_PROGRESS: 2,
    DONE: 3,
}

export function getPipelineStageTypes() {
    return [
        {
            id: PipelineStageType.TO_DO,
            name: getPipelineStageTypeString(PipelineStageType.TO_DO)
        },
        {
            id: PipelineStageType.IN_PROGRESS,
            name: getPipelineStageTypeString(PipelineStageType.IN_PROGRESS)
        },
        {
            id: PipelineStageType.DONE,
            name: getPipelineStageTypeString(PipelineStageType.DONE)
        }
    ];
}

export function getPipelineStageTypeString(pipelineType) {
    switch(pipelineType) {
        case PipelineStageType.TO_DO: return strings.constants.company.pipelineStageType.todo;
        case PipelineStageType.IN_PROGRESS: return strings.constants.company.pipelineStageType.inProgress;
        case PipelineStageType.DONE: return strings.constants.company.pipelineStageType.done;
    }
}

export function getPipelineStageTypeClass(pipelineType) {
    switch(pipelineType) {
        case PipelineStageType.TO_DO: return 'todo';
        case PipelineStageType.IN_PROGRESS: return 'in-progress';
        case PipelineStageType.DONE: return 'done';
    }
}

export default PipelineStageType;