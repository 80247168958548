import React, {useContext, useEffect, useState} from "react";
import { FormProvider } from "react-hook-form";
import { getJobPaymentFrequency } from "../../../../../Constants/HR/JobPaymentFrequency";
import strings from "../../../../../localization";
import CheckBoxControl from "../../../../Controls/Inputs/CheckBoxControl";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import Button from '@mui/material/Button';
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";
import QuillEditor from "../../../../Controls/Inputs/QuillEditor";
import {useForm} from "react-hook-form";

const JobForm = ({
                    onSubmit,
                    onCancel,
                    data,
                    form,
                    errors,
                    values,
                    setValue,
                    formRules,
                    jobStatuses,
                    jobContractTypes,
                    userPositions,
                    currencies,
                    partners,
                    pipelines,
                    internalHrOnly,
                    control
                }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const [editorData, setEditorData] = useState('');
    const [selectionProcessData, setSelectionProcessData] = useState('');
    const [afterSubmitData, setAfterSubmitData] = useState('');

    return (
        <FormProvider {...form}>
            <form id="job-form" className='form' action="#">
                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.job.name}
                    disabled={isPageStateViewDetails()}
                />
                <QuillEditor
                    name='description'
                    className='large-editor'
                    form={form}
                    value={editorData}
                    onChange={setEditorData}
                    placeholder={'Description'}
                />
                <TextFieldControl
                    name='headCount'
                    rules={formRules['headCount']}
                    control={data}
                    type='number'
                    error={Boolean(errors.headCount)}
                    helperText={errors.headCount && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.job.headCount}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='location'
                    rules={formRules['location']}
                    control={data}
                    error={Boolean(errors.location)}
                    helperText={errors.location && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.job.location}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='minGrossSalary'
                    rules={formRules['minGrossSalary']}
                    control={data}
                    type='number'
                    error={Boolean(errors.minGrossSalary)}
                    helperText={errors.minGrossSalary && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.job.minGrossSalary}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='minNetSalary'
                    rules={formRules['minNetSalary']}
                    control={data}
                    type='number'
                    error={Boolean(errors.minNetSalary)}
                    helperText={errors.minNetSalary && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.job.minNetSalary}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='maxGrossSalary'
                    rules={formRules['maxGrossSalary']}
                    control={data}
                    type='number'
                    error={Boolean(errors.maxGrossSalary)}
                    helperText={errors.maxGrossSalary && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.job.maxGrossSalary}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='maxNetSalary'
                    rules={formRules['maxNetSalary']}
                    control={data}
                    type='number'
                    error={Boolean(errors.maxNetSalary)}
                    helperText={errors.maxNetSalary && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.job.maxNetSalary}
                    disabled={isPageStateViewDetails()}
                />
                <SelectControl
                    value={values['paymentFrequency']}
                    setValue={setValue}
                    rules={formRules['paymentFrequency']}
                    name='paymentFrequency'
                    control={control}
                    error={Boolean(errors.paymentFrequency)}
                    helperText={errors.paymentFrequency && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.job.paymentFrequency}
                    options={getJobPaymentFrequency()}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                <AutoCompleteControl
                    value={values['jobStatus']}
                    setValue={setValue}
                    name='jobStatus'
                    control={control}
                    label={strings.forms.hr.job.jobStatus}
                    options={jobStatuses}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                <AutoCompleteControl
                    value={values['jobContractType']}
                    setValue={setValue}
                    name='jobContractType'
                    control={control}
                    label={strings.forms.hr.job.jobContractType}
                    options={jobContractTypes}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    value={values['userPosition']}
                    setValue={setValue}
                    name='userPosition'
                    control={control}
                    label={strings.forms.hr.job.userPosition}
                    options={userPositions}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={values['currency']}
                    setValue={setValue}
                    name='currency'
                    control={control}
                    label={strings.forms.hr.job.currency}
                    options={currencies}
                    nameKey={'code'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !internalHrOnly &&
                    <AutoCompleteControl
                        value={values['partner']}
                        setValue={setValue}
                        name='partner'
                        control={control}
                        label={strings.forms.hr.job.partner}
                        options={partners}
                        nameKey={'name'}
                        valueKey={'id'}
                        disabled={isPageStateViewDetails()}
                    />
                }
                <AutoCompleteControl
                    value={values['pipeline']}
                    setValue={setValue}
                    rules={formRules['pipeline']}
                    name='pipeline'
                    control={control}
                    error={Boolean(errors.pipeline)}
                    helperText={errors.pipeline && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.job.pipeline}
                    options={pipelines}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                <CheckBoxControl
                    name={'isRemote'}
                    control={data}
                    label={strings.forms.hr.job.isRemote}
                    value={values['isRemote']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />
                <QuillEditor
                    name='selectionProcess'
                    className='large-editor'
                    form={form}
                    value={selectionProcessData}
                    onChange={setSelectionProcessData}
                    placeholder={'Selection process'}
                />
                <QuillEditor
                    name='afterSubmit'
                    className='large-editor'
                    form={form}
                    value={afterSubmitData}
                    onChange={setAfterSubmitData}
                    placeholder={'After submit'}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default JobForm;