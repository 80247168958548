import { FormProvider } from 'react-hook-form';
import strings from "../../../../localization";
import Button from '@mui/material/Button';
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import AutoCompleteControl from "../../../Controls/Inputs/AutoCompleteControl";

const BoardForm = (props) => {
    const {formRules, form, values, data, onSubmit, onCancel, errors, setValue, pipelines, control, onChangePipelineFilter, onChangeProjectFilter, projects} = props;

    return (
        <FormProvider {...form}>
            <form className = 'form' action = '#'>
                <TextFieldControl
                    name = 'name'
                    control = {data}
                    rules = {formRules['name']}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    error = {Boolean(errors.name)}
                    label = {strings.forms.workspaces.name}
                />
                <TextFieldControl
                    name = 'description'
                    control = {data}
                    label = {strings.forms.workspaces.description}
                    rules = {formRules['description']}
                    helperText={errors.description && strings.forms.common.thisFieldIsRequired}
                    error = {Boolean(errors.description)}
                />
                <AutoCompleteControl
                    name='pipeline'
                    nameKey={'name'}
                    valueKey={'id'}
                    control = {control}
                    value={values['pipeline']}
                    setValue={setValue}
                    rules = {formRules['pipeline']}
                    error = {Boolean(errors.pipeline)}
                    helperText={errors.pipeline && strings.forms.common.thisFieldIsRequired}
                    label = {strings.forms.workspaces.pipeline}
                    options = {pipelines}
                    onChange={onChangePipelineFilter}
                    onOpen={() =>onChangePipelineFilter('')}
                />
                <AutoCompleteControl
                    name='project'
                    nameKey={'name'}
                    valueKey={'id'}
                    control = {control}
                    value={values['project']}
                    setValue={setValue}
                    rules = {formRules['project']}
                    error = {Boolean(errors.project)}
                    helperText={errors.project && strings.forms.common.thisFieldIsRequired}
                    label = {strings.forms.board.project}
                    options = {projects}
                    onChange={onChangeProjectFilter}
                    onOpen={() =>onChangeProjectFilter('')}
                />
                <TextFieldControl
                    name = 'boardKey'
                    control = {data}
                    rules = {formRules['boardKey']}
                    helperText={errors.boardKey && errors.boardKey.message}
                    error = {Boolean(errors.boardKey)}
                    label = {strings.forms.workspaces.boardKey}
                />
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.forms.common.save}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.forms.common.cancel}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default BoardForm;
