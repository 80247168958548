import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getDealPriorityList(data){
    return await request('/api/deal-priority', data);
}

export async function addDealPriority(data){
    return await request('/api/deal-priority', data, HttpMethod.POST);
}

export async function editDealPriority(data){
    return await request('/api/deal-priority/' + data.id, data, HttpMethod.PUT);
}

export async function deleteDealPriority(id){
    return await request('/api/deal-priority/' + id, {}, HttpMethod.DELETE);
}

