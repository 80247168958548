import React, { useContext, useEffect, useState } from 'react';
import {
  getEmployeeStatistic,
} from '../../../Services/User/UserService';
import { Grid, LinearProgress, Paper } from '@mui/material';
import strings from '../../../localization';
import LineChart from '../../../Components/Charts/LineChart';
import { monthLabels, monthShortLabels } from '../../../Util/ChartUtil';
import PresanceByDayChart from './PresanceByDayChart';
import Modules from '../../../Constants/Base/Modules';
import { changeBreadcrumbs } from '../../../Slices/BreadcrumbsSlice';
import { useDispatch } from 'react-redux';
import { getCurrentYear, getYears } from '../../../Util/DateUtil';
import SelectControl from '../../../Components/Controls/Inputs/SelectControl';
import LoaderContext from "../../../Context/LoaderContext";

const options = {
  scales: {
    y: {
      ticks: {
        stepSize: 60,
        callback: (value) => {
          let hours = Math.floor(value / 60);
          return `${hours}h`;
        },
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (context) => {
          let label = context.dataset.label;
          let currentValue = context.raw;
          let hours = Math.floor(currentValue / 60);
          let minutes = currentValue % 60;
          minutes = minutes > 9 ? minutes : `0${minutes}`;

          return `${label}: ${hours}:${minutes} `;
        },
      },
    },
    legend: {
      display: false,
    }
  },
};

const EmployeeStatistic = ({ employee }) => {
  const [overview, setOverview] = useState(null);
  const dispatch = useDispatch();
  const {loading, setLoading} = useContext(LoaderContext)

  const [selectedYear, setSelectedYear] = useState(getCurrentYear());

  useEffect(() => {
    setLoading(true);
    getEmployeeStatistic(employee.id, selectedYear).then((response) => {
      if (!response || !response.ok) {
        return '';
      }

      setOverview({
        data: response.data,
      });
    }).finally(() => setLoading(false));
  }, [employee, selectedYear]);

  useEffect(()=>{
    dispatch(changeBreadcrumbs({
      title: strings.pages.hr.employee.records,
      hierarchy:[
          {label: strings.navigation.managmentTag},
          {label: Modules.HR},
          {label: strings.pages.user.employeeDetails.pageTitle},
          {label: strings.pages.hr.employee.statistic},
      ],        
  }));
  },[]);

  const yearChangeHandler = (year) => {
    if (!year) {
        return;
    }
    setSelectedYear(year)
}

  return (
    <div className='container'>
      {!overview && <LinearProgress />}
      {overview && (
        <React.Fragment>
          <Grid container>
            <Grid item xs={3}>
              <div className={'filter-container-year'}>
                <SelectControl
                  defaultValue={selectedYear}
                  value={selectedYear}
                  setValue={(name, value) => yearChangeHandler(value?.id)}
                  label={strings.pages.user.employeeStatistic.yearLabel}
                  options={getYears()}
                  nameKey={'value'}
                  valueKey={'id'}
                />
              </div>
            </Grid>
          </Grid>
          <div className='graphs'>
            <Grid container>
              <Grid item xs={6}>
                <div className = 'graph h-100'>
                  <div className='title'>{strings.pages.user.employeeStatistic.presence}</div>
                  <LineChart
                    labels={monthShortLabels}
                    options={options}
                    inputData={[
                      {
                        label:
                          strings.pages.user.employeeStatistic.presenceByMonth,
                        data: overview.data.presenceByMonth,
                      },
                      {
                        label:
                          strings.pages.user.employeeStatistic
                            .presenceByMonthAveragePerMonth,
                        data: overview.data.presenceByMonthAveragePerMonth,
                      },
                      {
                        label:
                          strings.pages.user.employeeStatistic
                            .presenceByMonthAveragePerYear,
                        data: overview.data.presenceByMonthAveragePerYear,
                      },
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className = 'graph'>
                  <PresanceByDayChart employee={employee}/>
                </div>
                </Grid>
              <Grid item xs={6}>
                <div className = 'graph'>
                  <div className = 'title'>{strings.pages.user.employeeStatistic.totalWorkingDays}</div>
                  <LineChart
                    labels={monthShortLabels}
                    inputData={[
                      {
                        label:
                          strings.pages.user.employeeStatistic.totalWorkingDays,
                        data: overview.data.workingDays,
                      },
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className = 'graph'>
                  <div className = 'title'>{strings.pages.user.employeeStatistic.totalWorkingHours}</div>
                  <LineChart
                    labels={monthShortLabels}
                    inputData={[
                      {
                        label:
                          strings.pages.user.employeeStatistic.totalWorkingHours,
                        data: overview.data.workingHours,
                      },
                      {
                        label: strings.pages.user.employeeStatistic.timeLogged,
                        data: overview.data.timeLogged,
                      },
                      {
                        label:
                          strings.pages.user.employeeStatistic.billableTimeLogged,
                        data: overview.data.billableTimeLogged,
                      },
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className = 'graph'>
                  <div className = 'title'>{strings.pages.user.employeeStatistic.payment}</div>
                  <LineChart
                    labels={monthShortLabels}
                    inputData={[
                      {
                        label: strings.pages.user.employeeStatistic.net,
                        data: overview.data.net,
                      },
                      {
                        label: strings.pages.user.employeeStatistic.gross,
                        data: overview.data.gross,
                      },
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className = 'graph'>
                  <div className = 'title'>{strings.pages.user.employeeStatistic.paymentPerHour}</div>
                  <LineChart
                    labels={monthShortLabels}
                    inputData={[
                      {
                        label:
                          strings.pages.user.employeeStatistic.hourRatePerMonth,
                        data: overview.data.hourRatePerMonth,
                      },
                      {
                        label:
                          strings.pages.user.employeeStatistic
                            .hourRateAverageOfHourRatePerYear,
                        data: overview.data.hourRateAverageOfHourRatePerYear,
                      },
                    ]}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default EmployeeStatistic;
