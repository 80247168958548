import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Alert, Paper, Snackbar} from "@mui/material";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import TablePageContext from "../../../Context/TablePageContext";
import LinearProgress from '@mui/material/LinearProgress';
import {addPipelineStage} from "../../../Services/Company/PipelineStageService";
import PipelineStageForm from "../../../Components/Forms/Pages/Administration/PipelineStage/PipelineStageForm";
import SnackbarContext from "../../../Context/SnackbarContext";

const formRules = {
    'name': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'type': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'color': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}}
}

const AddStagePipeline = (props) => {

    const form = useForm();
    const {data,control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const { showMessage } = useContext(SnackbarContext);

    const onSubmit = (data) => {

        setShowLoader(true);

        addPipelineStage({
            ...data,
            pipelineId: props.pipelineId
        }).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }
            
            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <PipelineStageForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                errors={errors} data={data} form={form} control={control}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default AddStagePipeline;