import { Breadcrumbs, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const BreadcrumbsNavigation = (props)=>{
    const breadcrumbs = useSelector(state => state.breadcrumbs.data);
    const navigate = useNavigate();
    const displayHierarchy = props.hierarchy || breadcrumbs.hierarchy
    const displayTitle = props.title || breadcrumbs.title;

    if(!displayTitle && displayHierarchy.length === 0){
        return <></>
    }

    const getTitleStyle = () => {
        if(breadcrumbs.submenu){
            return 'ml-300'
        }
        else{
            return 'ml-4';
        }
    }

    const handleClick = (item, index) => {
        if(index === breadcrumbs.hierarchy.length - 1){
            return;
        }

        if(index === 0){
            navigate('/dashboard');
        }

        if(item.route){
            navigate(item.route);
        }
    }

    const getStyles = (index) => {
        if(index === 0){
            return 'bold-tag breadcrumbs-hover';
        }

        if(index === breadcrumbs.hierarchy.length - 1){
            return '';
        }

        return 'breadcrumbs-hover';
    }

    return(
        <div id={"breadcrumbs"} className={getTitleStyle()}>
            <div className="page-title">
                {displayTitle}
            </div>
            <Breadcrumbs separator={<img className='separator' src={'/images/breadcrumbs-separator.svg'} loading={'lazy'}/>} >
            {
                displayHierarchy.map((item, index) => {                 
                    return <Typography key={"label-" + index} className={getStyles(index)} onClick = {() => handleClick(item, index)}>
                        {item.label} 
                        </Typography>
                })  
            }
            </Breadcrumbs>
        </div>
    )
    
    
}

export default BreadcrumbsNavigation;
