import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { Box, IconButton, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';

export function ShowLess() {
    return (
        <img src ='/images/show-less.svg' />
    );
}

export function ShowMore() {
    return (
        <img src ='/images/show-more.svg' />
    );
}

function TransitionComponent(props) {
    return (
            <Collapse {...props} />
    );
}

const StyledTreeItemRoot = styled((props) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
    },
}));

const StyledTreeItem = (props) =>  {
  const {
    labelIcon: LabelIcon,
    expandOptionsIcon: ExpandOptionsIcon,
    onEdit,
    labelText,
    openMenu,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
    {...other}

      label={
        <Box 
            sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}
            onContextMenu = {openMenu}
        >
            {LabelIcon && <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />}
            <Typography sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                {labelText}
            </Typography>
            { ExpandOptionsIcon && 
                <IconButton className='expand-options-icon' onClick={(e) => {props.onEdit(e)}}>
                    <Box component={ExpandOptionsIcon} color="inherit" sx={{ mr: 1 }} />
				</IconButton>  
            }
        </Box>
      }
    />
  );
}

export default StyledTreeItem;
