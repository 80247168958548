import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllAssetCategories(data) {
    return await request('/api/asset-categories/all', data);
}

export async function getAssetCategories(data) {
  return await request('/api/asset-categories', data);
}

export async function getAssetCategoryList(data) {
    return await request('/api/asset-categories', data);
}

export async function getAssetCategory(id) {
    return await request('/api/asset-categories/' + id);
}

export async function deleteAssetCategory(id) {
    return await request('/api/asset-categories/' + id, {}, HttpMethod.DELETE);
}

export async function editAssetCategory(data) {
    return await request('/api/asset-categories/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addAssetCategory(data) {
    return await request('/api/asset-categories', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        parentId: data.parent ? data.parent.id : null,
    }
}
