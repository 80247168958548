import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getDealTypes(data){
    return await request('/api/deal-types', data);
}

export async function addDealType(data){
    return await request('/api/deal-types', data, HttpMethod.POST);
}

export async function editDealType(data){
    return await request('/api/deal-type/' + data.id, data, HttpMethod.PUT);
}

export async function deleteDealType(id){
    return await request('/api/deal-type/' + id, {}, HttpMethod.DELETE);
}

