import {Grid, Paper} from "@mui/material";

const StatisticComponent = ({data, className=''}) => {

    const renderItemData = (data) => {

        let result = [];

        if(!data) {
            return result;
        }

        for(let item of data) {
            result.push(<div className="data" key={'statistic-item-data-' + result.length}>{item.value}</div>)
        }

        return result;
    }

    const renderItems = (data) => {

        let result = []

        if(!data) {
            return result;
        }

        for(let item of data) {
            result.push(<Grid key={'statistic-item-' + result.length} item xs={item.size ? item.size : 2}>
                <div className='statistic-overview-item'>
                    <div className="title">{ item.title ? item.title : '' }</div>
                    {renderItemData(item.data)}
                </div>
            </Grid>)
        }

        return result
    }

    return <div id='statistic-overview'>
        <Grid container spacing={1} wrap={'nowrap'} className={className}>
            {renderItems(data)}
        </Grid>
    </div>

}

export default StatisticComponent