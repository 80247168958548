import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";
import {GetObjectCommand, S3Client} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import store from "../../store";

export function downloadDocument(document) {
    let { companyS3Settings } = store.getState().auth;

    const config ={
        apiVersion: companyS3Settings.version,
        region: companyS3Settings.region,
        endpoint: companyS3Settings.url,
        credentials: {
            accessKeyId: companyS3Settings.s3key,
            secretAccessKey: companyS3Settings.secret
        }
    };
    const client = new S3Client(config);
    const fullPath = `${document.key}`;
    const command = new GetObjectCommand({Bucket: companyS3Settings.bucket, Key: fullPath});
    getSignedUrl(client, command, {expiresIn: 3600}).then(link => window.open(link));
}



export async function getDocument({id}){
    return await request(`/api/documents/${id}`, {}, HttpMethod.GET, {responseType: 'blob'}).then(res => {
        const newBlob = new Blob([res.data]);
        return {url: window.URL.createObjectURL(newBlob), name: res.headers['file-name']};
    });
}

export async function getDocuments(data){
    return await request('/api/documents/' + data.referenceType + '/' + data.referenceId , data)
}

export async function addDocument(data) {
    return await request('/api/documents', trasformFormData(data), HttpMethod.POST);
}

export async function editDocument(data) {
    return await request('/api/documents/' + data.id, transformData(data), HttpMethod.PUT);
}

export async function deleteDocument(id) {
    return await request('/api/documents/' + id, {}, HttpMethod.DELETE);
}

function trasformFormData(data){
    let formData = new FormData()
    Object.keys(data).forEach((key) => {
        formData.set(key, data[key]);
    })
    formData.set('documentStatus', data.documentStatus ? data.documentStatus.id : null);
    formData.set('documentCategory',data.documentCategory ? data.documentCategory.id : null);

    return formData;
}

function transformData(data) {
    return {
        ...data,
        documentStatusId: data.documentStatus ? data.documentStatus.id : null,
        documentCategoryId: data.documentCategory ? data.documentCategory.id : null,
    }
}