import React,{ useContext, useEffect, useState } from "react";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import PageState from "../../../Constants/Base/PageState";
import strings from "../../../localization";
import { changeStatusAdditionalHours, deleteAdditionalHours, getAdditionalHours } from "../../../Services/HR/AdditionalHoursService";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import { changePageSizeState } from "../../../Slices/PageSlice";
import {
    formatColumnConstant,
    formatColumnDate,
    formatColumnObject
} from "../../../Components/DataGrid/ValueFormatter";
import {useDispatch, useSelector} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import AddAdditionalHours from "./AddAdditionalHours";
import EditAdditionalHours from "./EditAdditionalHours";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import { useNavigate, useSearchParams } from "react-router-dom";
import AdditionalHoursStatus, {getAdditionalHoursStatusString} from "../../../Constants/HR/AdditionalHoursStatus";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import { hasPermission } from "../../../Util/PermissionUtil";
import { Button } from "@mui/material";
import SnackbarContext from "../../../Context/SnackbarContext";
import { renderColumnAdditionalHoursStatus, renderColumnNumber } from "../../../Components/DataGrid/ValueCellRender";

const tableDescription = [
    {
        field: 'startDate', headerName: strings.pages.hr.additionalHours.additionalHoursList.startDate,
        valueFormatter: (params) => formatColumnDate(params),
        width: 150
    },
    {
        field: 'endDate', headerName: strings.pages.hr.additionalHours.additionalHoursList.endDate,
        valueFormatter: (params) => formatColumnDate(params),
        width: 150
    },
    {
        field: 'status', headerName: strings.pages.hr.additionalHours.additionalHoursList.status, width: 150,
        valueGetter: (params) => getAdditionalHoursStatusString(params.value),
        renderCell: (params) => renderColumnAdditionalHoursStatus(params),
        align: 'left'
    },
    {
        field: 'hours', headerName: strings.pages.hr.additionalHours.additionalHoursList.hours,
        renderCell: (params) => renderColumnNumber(params),
        width: 150},
    {
        field: 'organisationUserContract', headerName: strings.pages.hr.additionalHours.additionalHoursList.organisationUserContract,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 250},
    {
        field: 'user', headerName: strings.pages.hr.additionalHours.additionalHoursList.user,
        valueFormatter: (params) => formatColumnObject(params, 'fullName'),
        width: 250},
]

const filters = []

const AdditionalHoursList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.ADDITIONAL_HOURS;
    const permissionGroup = AppPermissions.ADDITIONAL_HOURS.GROUP;
    const auth = useSelector((state) => state.auth)
    const {showMessage} = useContext(SnackbarContext);

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar, showMessage,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(() => {

        setColumns([controlColumn, ...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.hr.additionalHours.additionalHoursList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.pages.hr.additionalHours.additionalHoursList.pageTitle},
            ],        
        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const controlColumn = {
        field: 'control',
        headerName: strings.pages.hr.additionalHours.additionalHoursList.controls,
        width: 200,
        renderCell: params => formatColumnControl(params),
        align: 'center'
    };

    const formatColumnControl = (params) => {

        if(!hasPermission(auth.user, permissionGroup, AppPermissions.ADDITIONAL_HOURS.RESOLVE, auth.permissions)) {
            return '';
        }

        if(params.row.status !== AdditionalHoursStatus.PENDING) {
            return ''
        }

        return <div className='submit-container'>
            <Button variant="contained" size="small" color="primary" className='table-button' onClick={() => changeStatus(params.row.id, AdditionalHoursStatus.APPROVED)}>
                {strings.pages.hr.additionalHours.additionalHoursList.approve}
            </Button>
            <Button variant="contained" size="small" color="secondary" className='table-button' onClick={() => changeStatus(params.row.id, AdditionalHoursStatus.REJECTED)}>
                {strings.pages.hr.additionalHours.additionalHoursList.rejecte}
            </Button>
        </div>
    }

    const changeStatus = (id, status) => {
        changeStatusAdditionalHours({
            id,
            status
        }).then(response => {

            if(!response || !response.ok) {
                return;
            }

            showMessage(strings.commonMessages.saved)
            fetch();
        });
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getAdditionalHours({
            ...filter
        }).then(response => {
            if (!response || !response.ok){
                return;
            }
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteAdditionalHours} tableDescription={columns}
                   tableData={tableData} filter={filter} filters={filters} showDeletingError={true}
                   referenceType={referenceType}
                   permissionGroup={permissionGroup}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditAdditionalHours data={ selectedItems }
                                          onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addPage={<AddAdditionalHours onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addButtonText={strings.components.tablePage.addButton.additionalHours}/>
                
    </TablePageContext.Provider>;
}

export default AdditionalHoursList;
