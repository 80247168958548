import React, {useContext} from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import {
    addContract,
    deleteContract,
    generateContractParts,
    getContracts
} from "../../../Services/Finance/ContractService";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {
    formatColumnConstant,
    formatColumnDate,
    formatColumnObject
} from "../../../Components/DataGrid/ValueFormatter";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import AddContract from "./AddContract";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import EditContract from "./EditContract";
import {
    renderColumnNumber,
    renderColumnPercent,
    returnColumnBoolean
} from "../../../Components/DataGrid/ValueCellRender";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getPartners, getPartner} from "../../../Services/CRM/PartnerService";
import contractType, {ContractType, getContractTypes} from '../../../Constants/Finance/ContractType';
import {getContractTypeString} from "../../../Constants/Finance/ContractType";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import SnackbarContext from "../../../Context/SnackbarContext";
import {getCurrentYear, getYears} from "../../../Util/DateUtil";
import {fetchOptions} from '../../../Services/Base/FilterOptionsService';
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import {generateInvoice, getContractParts} from "../../../Services/Finance/ContractPartService";
import DrawerContext from "../../../Context/DrawerContext";

import {useForm} from "react-hook-form";
import GenerateInvoiceFromContract from "./GenerateInvoiceFromContract";


const tableDescription = [

    {field: 'name', headerName: strings.pages.finance.contract.contractList.name, width: 200},
    {field: 'contractNumber', headerName: strings.pages.finance.contract.contractList.contractNumber, width: 200},
    {
        field: 'startDate',
        headerName: strings.pages.finance.contract.contractList.startDate,
        valueFormatter: (params) => formatColumnDate(params),
        width: 100
    },
    {
        field: 'endDate',
        headerName: strings.pages.finance.contract.contractList.endDate,
        valueFormatter: (params) => formatColumnDate(params),
        width: 100
    },
    {field: 'description', headerName: strings.pages.finance.contract.contractList.description, width: 200},
    {
        field: 'contractType', headerName: strings.pages.finance.contract.contractList.contractType, width: 160,
        valueFormatter: (params) => formatColumnConstant(params, getContractTypeString)
    },
    {
        field: 'partner',
        headerName: strings.pages.finance.contract.contractList.partner,
        width: 200,
        valueFormatter: (params) => formatColumnObject(params, 'name')
    },
    {
        field: 'transactionCategory',
        headerName: strings.pages.finance.contract.contractList.transactionCategory,
        width: 200,
        valueFormatter: (params) => formatColumnObject(params, 'name')
    },
    {
        field: 'project',
        headerName: strings.pages.finance.contract.contractList.project,
        width: 200,
        valueFormatter: (params) => formatColumnObject(params, 'name')
    },
    {
        field: 'contractStatus',
        headerName: strings.pages.finance.contract.contractList.contractStatus,
        width: 200,
        valueFormatter: (params) => formatColumnObject(params, 'name')
    },
    {field: 'contractPartCount', headerName: strings.pages.finance.contract.contractList.contractPartCount, width: 160},
    {
        field: 'contractPartAmount',
        headerName: strings.pages.finance.contract.contractList.contractPartAmount,
        width: 180,
        renderCell: (params) => renderColumnNumber(params)
    },
    {
        field: 'contractPartActualAmount',
        headerName: strings.pages.finance.contract.contractList.actualAmount,
        width: 120,
        renderCell: (params) => renderColumnNumber(params)
    },
    {
        field: 'tax',
        headerName: strings.pages.finance.contract.contractList.tax,
        width: 90,
        renderCell: (params) => renderColumnPercent(params)
    },
    {
        field: 'currency',
        headerName: strings.pages.finance.contract.contractList.currency,
        valueFormatter: (params) => formatColumnObject(params, 'code'),
        width: 90
    },
    {
        field: 'canReturnTax', headerName: strings.pages.finance.contract.contractList.canReturnTax,
        width: 120,
        renderCell: (params) => returnColumnBoolean(params)
    },
    {
        field: 'owner',
        headerName: strings.pages.finance.contract.contractList.owner,
        valueFormatter: (params) => formatColumnObject(params, 'fullName'),
        width: 200
    },
];

const ContractList = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(false);
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState({...FilterDefaults, year: getCurrentYear()});
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState({
        ...TablePageOptionDefaults,
        showYearFilter: true,
        showMonthFilter: true,
    });
    const [filterOptions, setFilterOptions] = useState({});
    const referenceType = ReferenceType.CONTRACT;
    const permissionGroup = AppPermissions.CONTRACT.GROUP;
    const { showMessage } = useContext(SnackbarContext);
    const [showLoader, setShowLoader] = useState(false);
    const [customPage, setCustomPage] = useState(null);
    const [parts, setParts] = useState([]);
    const {id: contractId} = useParams();

    const form = useForm();
    const {data, watch, setValue, getValues, control} = form;


    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    const additionalMenuItems = [
        {handleClick: (id,item) => handleItems(id, item.name), text: strings.pages.finance.contractPart.contractPartList.contractPart, src: "/images/table-page/contract-parts.svg"},
        {handleClick: (id, item) => handleGenerateParts(item), text: strings.pages.finance.contractPart.contractPartList.generateParts, src: "/images/table-page/contract-generate-parts.svg"},
        {handleClick: (id, item) => handleGenerateInvoice(id,item), text: strings.pages.finance.contract.contractList.generateInvoice, src: "/images/table-page/contract-generate-parts.svg"},
    ]

    useEffect(()=>  {
        setTableData({
            ...tableData,
            loading: true
        })
    },[])

    useEffect(()=>{
        setValue('contractPart', null)
        getParts();
    },[pageState])

    const fetchPartners = async (term, filterFetch) => {
        return fetchOptions('partner', searchParams, getPartners, getPartner, setFilterOptions, term, filterFetch);
    }

    const fetchFilters = async() => {
        const partners = await fetchPartners();

        setFilterOptions({
            contractType: getContractTypes(),
            partner: partners,
            fetched: 1,
        });
    }

    const filters = [
        {
            name: 'partner',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'partner',
            label: strings.pages.finance.contract.contractList.partner,
            onChange: (term) => fetchPartners(term, true),
        },
        {
            name: 'contractType',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'contractType',
            label: strings.pages.finance.contract.contractList.contractType,
            showSelect: true
        }
    ]

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.finance.contract.contractList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.FINANCE},
                {label: strings.pages.finance.contract.contractList.pageTitle},
            ],        
        }));

        if(!searchParams.toString()){
            setSearchParams({year: getCurrentYear()}, {replace: true});
        }

        fetchFilters();

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    const handleItems = (template, name) => {
        navigate(`/contract-parts/${template}/${name}`)
    }

    const onCancel = () => {
        setValue('contractPart', null)
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getContracts({
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    const getParts = () => {
        getContractParts().then(response => {
            setParts(response.data.result);
        })
    }

    const onSubmit = () => {
        setShowLoader(true);
        generateInvoice(getValues('contractPart')).then((response) => {

            if(!response){
                showMessage(strings.commonMessages.errorGenerating, 'error');
                return;
            }
            if (!response.ok) {
                showMessage(response.response.data.message, 'error', 5000);
                return;
            }

            navigate({
                pathname: '/out/invoices',
                search: `year=${getCurrentYear()}`
            });

        });
    }

    const handleGenerateInvoice = (id,item) => {
        setCustomPage(<GenerateInvoiceFromContract onSubmit={onSubmit} onCancel={onCancel} id={id} parts={parts} value={getValues('contractPart')} setValue={setValue}/>)
        setPageState(PageState.Custom);
    }

    const handleGenerateParts = (contract) => {
        generateContractParts({...contract}).then(response => {
            if (!response.ok) {
                showMessage(strings.error.ContractHandler[response.response.data.message], 'error');
                return;
            }
            handleItems(response.data.entity.id, response.data.entity.name)
        });
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteContract}
                   tableDescription={columns} tableData={tableData}
                   filter={filter} filters={filters} filterOptions={filterOptions}
                   permissionGroup={permissionGroup}
                   referenceType={referenceType}
                   additionalMenuItems={additionalMenuItems}
                   plusYears={1}
                   onCancel={() => onCancel()}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditContract data={selectedItems} onFinish={() => onFinish()}
                                           onCancel={() => onCancel()}/>}
                   addPage={<AddContract onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   customPage={customPage}
                   addButtonText={strings.components.tablePage.addButton.contract}
        />
    </TablePageContext.Provider>;
}

export default ContractList;
