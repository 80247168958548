import strings from "../../../localization";
import {changePageSizeState} from "../../../Slices/PageSlice";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import PageState from "../../../Constants/Base/PageState";
import React, {useEffect, useState} from "react";
import TablePage from "../../../Components/DataGrid/TablePage";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    renderIssueTypeColumn,
} from "../../../Components/DataGrid/ValueCellRender";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import EditWorkspaceIssueType from './EditWorkspaceIssueType';
import AddWorkspaceIssueType from './AddWorkspaceIssueType';
import { getIssueTypeList, deleteIssueType} from "../../../Services/Workspaces/IssueTypeService";

const WorkspaceIssueType = ({workspaceId}) => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState({
        ...TablePageOptionDefaults,
    });
    const permissionGroup = AppPermissions.WORKSPACES.GROUP;

    const value = {
        tablePageOptions, 
        selectionModel, 
        setTablePageOptions,
        setSelectionModel,
        selectedItems, 
        setSelectedItems,
        filter, 
        setFilter, 
        pageState, 
        setPageState, 
        showSnackbar, 
        setShowSnackbar,
        messageSnackbar, 
        setMessageSnackbar, 
        alertType, 
        setAlertType, 
        showDeleteDialog, 
        setShowDeleteDialog,
        navigate,
        updatedFilter,
        setUpdatedFilter
    }

    const tableDescription = [
        {
            field: 'name',  
            headerName: strings.pages.workspaces.workspacesSettings.name, 
            width: 150,
        },
        {
            field: 'type',  
            headerName: strings.pages.workspaces.workspacesSettings.type, 
            renderCell: (params) => renderIssueTypeColumn(params.row),
            width: 105,
        },
    ]

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.workspaces.workspacesSettings.issueType,
            hierarchy:[
                {label: strings.navigation.homeTag},
                {label: strings.navigation.workspaces},
                {label: strings.pages.workspaces.workspacesSettings.pageTitle},
                {label: strings.pages.workspaces.workspacesSettings.issueType},
            ],        

        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter]);

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const fetch = (term = '') => {
        setTableData({
            ...tableData,
            loading: true
        })

        getIssueTypeList(workspaceId, {
            ...filter,
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }
            const tempTypes = response.data.result.map((type) => 
                ({...type, canDelete: !type.isDefault}));
            setTableData({
                loading: false,
                data: tempTypes,
                total: response.data.total
            });
        });
    }


    return (
        <TablePageContext.Provider 
            value={value}
        >
            <TablePage 
                onFinish={() => onFinish()} 
                tableDescription={columns}
                tableData={tableData}
                permissionGroup={permissionGroup}
                filter={filter} 
                onCancel={() => onCancel()}
                addButtonText={strings.components.tablePage.addButton.issueType}
                hideDocuments = {true}
                filters = {[]}
                deleteItem = {deleteIssueType}
                checkDelete = {true}
                addPage={
                    <AddWorkspaceIssueType 
                        onCancel={() => onCancel()} 
                        onFinish={() => onFinish()}
                        workspaceId = {workspaceId}
                    />
                }
                editPage = {
                    <EditWorkspaceIssueType
                        data = {selectedItems} 
                        onCancel = {onCancel} 
                        onFinish = {() => onFinish()} 
                    />
                }
            />
        </TablePageContext.Provider>
    )
}


export default WorkspaceIssueType;
