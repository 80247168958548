import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import InventoryTypeForm from "../../../Components/Forms/Pages/AssetManagement/InventoryType/InventoryTypeForm";
import DrawerContext from "../../../Context/DrawerContext";
import {
  editInventoryType,
  getInventoryCategories,
} from '../../../Services/AssetManagement/InventoryTypeService';
import LinearProgress from '@mui/material/LinearProgress';
import SnackbarContext from "../../../Context/SnackbarContext";

const formRules = {
    'name': {required: true}, 'category': {required: true},
}

const EditInventoryType = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [inventoryCategories, setInventoryCategories] = useState(0);

    useEffect(() => {
        setDrawerTitle(strings.pages.assetManagement.inventoryType.editInventoryType.pageTitle);
    });

    useEffect(() => {
        fetchInventoryCategories(); 
    },[])

    const fetchInventoryCategories = (term = "") => {
        getInventoryCategories({term}).then((response) => {
          if (!response || !response.ok) {
            return;
          }
          setInventoryCategories(response.data.result);
        });
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        editInventoryType(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <InventoryTypeForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                inventoryCategories = {inventoryCategories}
                errors={errors} data={data} form={form} control={control}
                onCategoryChangeFilter={fetchInventoryCategories}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditInventoryType;