import * as React from 'react';
import {useState} from "react";
import {hasPermission} from "../../Util/PermissionUtil";
import AppPermissions from "../../Constants/Permissions/Permissions";
import {useSelector} from "react-redux";
import {Popover, Tooltip, Typography} from '@mui/material';
import strings from '../../localization';
import { useEffect } from 'react';
import { getTagByReferenceTypeAndReferenceId } from '../../Services/DocumentManagement/TagReferenceService';
import TablePageContext from "../../Context/TablePageContext";
import { useContext } from 'react';

const TagCell = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverText, setPopoverText] = useState('');
    const auth = useSelector((state) => state.auth)
    const [tags, setTags] = useState([]);

    const { pageState } = useContext(TablePageContext);

    useEffect(() => {
        let data = { "referenceType": props.referenceType, "referenceId": props.item.id }
        getTagByReferenceTypeAndReferenceId(data).then(response => {

            if (!response || !response.ok) {
                return;
            }
            
            setTags(response.data.entity)
        });
    }, [pageState])

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlerClick = (handler) => {
        handleClose();
        handler(props.id, props.item);
    }

    const handleDocumentClick = (handler) => {
        handleClose();
        handler(props.id, props.refereceneType)
    }

    const renderTags = () => {
        let result = [];

        for (let entity of tags) {
            result.push(
                <Tooltip title={entity.tag.name}>
                    <div style={{backgroundColor: entity.tag.color}} />
                </Tooltip>
            )
        }

        return result;
    }

    return <React.Fragment>
            {
                <Popover className="hover-popover-table-page"
                         sx={{pointerEvents: 'none',}}
                         anchorOrigin={{ vertical: 'top',horizontal: 'right', }}
                         open={anchorEl}
                         anchorEl={anchorEl}
                         onClose={handleClose} 
                         disableAutoFocus>
                         <Typography>  {popoverText} </Typography>
                </Popover>
            }
            {
                props.tablePageOptions.showTagBookmark &&
                hasPermission(auth.user, props.permissionGroup, AppPermissions[props.permissionGroup].BOOKMARK, auth.permissions) &&
                <div className='tags-table-page'>
                    { tags && renderTags() }
                </div>
            }
            {
                props.tablePageOptions.showTagBookmark &&
                hasPermission(auth.user, props.permissionGroup, AppPermissions[props.permissionGroup].BOOKMARK, auth.permissions) &&
                <Tooltip title={strings.components.tablePage.popover.bookmark}>
                    <img onClick={() => handlerClick(props.handleBookmark)}
                         src='/images/table-page/bookmark.svg'/>
                </Tooltip>
            }
    </React.Fragment>
}

export default TagCell;