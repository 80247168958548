import React from "react";
import { useEffect, useState } from "react";
import {
  formatColumnDate,
  formatColumnObject,
} from "../../../Components/DataGrid/ValueFormatter";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import strings from "../../../localization";
import { useDispatch } from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import { changeBreadcrumbs } from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import { changePageSizeState } from "../../../Slices/PageSlice";
import TablePageContext, {
  FilterDefaults,
  TableDataDefaults,
  TablePageOptionDefaults,
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import {
  deleteCandidate,
  getCandidates,
} from "../../../Services/HR/CandidateService";
import AddCandidate from "./AddCandidate";
import EditCandidate from "./EditCandidate";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {
  renderColumnColorChip,
  renderColumnNumber,
  returnColumnBoolean,
} from "../../../Components/DataGrid/ValueCellRender";
import {
  getAllJobs,
  getJobList,
  getJob,
} from "../../../Services/HR/JobService";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import { getAllPipelineStages } from "../../../Services/Company/PipelineStageService";
import PipelineType from "../../../Constants/Company/PipelineType";
import { getCurrentMonth, getCurrentYear } from "../../../Util/DateUtil";
import { fetchOptions } from "../../../Services/Base/FilterOptionsService";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [
  {
    field: "alreadyApplied",
    headerName: strings.pages.hr.candidate.candidateList.alreadyApplied,
    width: 130,
    align: "center",
    renderCell: (params) => returnColumnBoolean(params),
  },
  {
    field: "noPreviousExperience",
    headerName: strings.pages.hr.candidate.candidateList.noPreviousExperience,
    width: 180,
    align: "center",
    renderCell: (params) => returnColumnBoolean(params),
  },
  {
    field: "firstName",
    headerName: strings.pages.hr.candidate.candidateList.firstName,
    width: 150,
  },
  {
    field: "lastName",
    headerName: strings.pages.hr.candidate.candidateList.lastName,
    width: 150,
  },
  {
    field: "email",
    headerName: strings.pages.hr.candidate.candidateList.email,
    width: 250,
  },
  {
    field: "dateCreated",
    headerName: strings.pages.hr.candidate.candidateList.dateCreated,
    valueFormatter: (params) => formatColumnDate(params),
    width: 150,
  },
  {
    field: "job",
    headerName: strings.pages.hr.candidate.candidateList.job,
    valueFormatter: (params) => formatColumnObject(params, "name"),
    width: 200,
  },
  {
    field: "location",
    headerName: strings.pages.hr.candidate.candidateList.location,
    width: 200,
  },
  {
    field: "phone",
    headerName: strings.pages.hr.candidate.candidateList.phone,
    width: 150,
  },
  {
    field: "description",
    headerName: strings.pages.hr.candidate.candidateList.description,
    width: 200,
  },
  {
    field: "currentPosition",
    headerName: strings.pages.hr.candidate.candidateList.currentPosition,
    width: 200,
  },
  {
    field: "currentCompany",
    headerName: strings.pages.hr.candidate.candidateList.currentCompany,
    width: 200,
  },
  {
    field: "currentSalary",
    headerName: strings.pages.hr.candidate.candidateList.currentSalary,
    renderCell: (params) => renderColumnNumber(params),
    width: 150,
  },
  {
    field: "expectedSalary",
    headerName: strings.pages.hr.candidate.candidateList.expectedSalary,
    renderCell: (params) => renderColumnNumber(params),
    width: 150,
  },
  {
    field: "education",
    headerName: strings.pages.hr.candidate.candidateList.education,
    width: 200,
  },
  {
    field: "birthday",
    headerName: strings.pages.hr.candidate.candidateList.birthday,
    valueFormatter: (params) => formatColumnDate(params),
    width: 100,
  },
  {
    field: "candidateStatus",
    headerName: strings.pages.hr.candidate.candidateList.candidateStatus,
    renderCell: (params) => renderColumnColorChip(params, "name"),
    width: 200,
  },
  {
    field: "candidateSource",
    headerName: strings.pages.hr.candidate.candidateList.candidateSource,
    valueFormatter: (params) => formatColumnObject(params, "name"),
    width: 200,
  },
  {
    field: "responsibleHrManager",
    headerName: strings.pages.hr.candidate.candidateList.responsibleHrManager,
    valueFormatter: (params) => formatColumnObject(params, "fullName"),
    width: 200,
  },
  {
    field: "pipelineStage",
    headerName: strings.pages.hr.candidate.candidateList.pipelineStage,
    renderCell: (params) => renderColumnColorChip(params, "name"),
    width: 200,
  },
];

const CandidateList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [updatedFilter, setUpdatedFilter] = useState(false);
  const [columns, setColumns] = useState([]);
  const [pageState, setPageState] = useState(PageState.View);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [filter, setFilter] = useState({
    ...FilterDefaults,
    year: getCurrentYear(),
    month: getCurrentMonth(),
  });
  const [tableData, setTableData] = useState(TableDataDefaults);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedItems, setSelectedItems] = useState(null);
  const [tablePageOptions, setTablePageOptions] = useState({
    ...TablePageOptionDefaults,
    showYearFilter: true,
    showMonthFilter: true,
  });
  const referenceType = ReferenceType.CANDIDATE;
  const [filterOptions, setFilterOptions] = useState({});
  const permissionGroup = AppPermissions.CANDIDATE.GROUP;

  const value = {
    tablePageOptions,
    setTablePageOptions,
    selectionModel,
    setSelectionModel,
    selectedItems,
    setSelectedItems,
    filter,
    setFilter,
    pageState,
    setPageState,
    showSnackbar,
    setShowSnackbar,
    messageSnackbar,
    setMessageSnackbar,
    alertType,
    setAlertType,
    showDeleteDialog,
    setShowDeleteDialog,
    navigate,
    updatedFilter,
    setUpdatedFilter,
  };

  useEffect(() => {
    setTableData({
      ...tableData,
      loading: true,
    });
  }, []);

  useEffect(() => {
    setColumns([...tableDescription]);
    dispatch(changePageSizeState(PageSizeState.SHORT));
    dispatch(
      changeBreadcrumbs({
        title: strings.pages.hr.businessTrip.pageTitle,
        hierarchy: [
          { label: strings.navigation.managmentTag },
          { label: Modules.HR },
          { label: strings.pages.hr.candidate.candidateList.pageTitle },
        ],
      })
    );

    if (!searchParams.toString()) {
      setSearchParams(
        { year: getCurrentYear(), month: getCurrentMonth() },
        { replace: true }
      );
    }

    fetchFilters();

    return () => {
      setTableData({});
    };
  }, []);

  useEffect(() => {
    if (!updatedFilter) {
      return;
    }

    fetch();
  }, [filter, updatedFilter]);

  const fetchJobs = async (term, filterFetch) => {
    return fetchOptions(
      "job",
      searchParams,
      getJobList,
      getJob,
      setFilterOptions,
      term,
      filterFetch
    );
  };

  const fetchFilters = async () => {
    const jobs = await fetchJobs();
    const pipelineStages = await getAllPipelineStages({
      type: PipelineType.HR,
    });

    setFilterOptions({
      job: jobs,
      pipelineStage: pipelineStages.data,
      fetched: 1,
    });
  };

  const filters = [
    {
      name: "job",
      nameKey: "name",
      valueKey: "id",
      optionsName: "job",
      label: strings.pages.hr.candidate.candidateList.job,
      onChange: (term) => fetchJobs(term, true),
    },
    {
      name: "pipelineStage",
      nameKey: "name",
      valueKey: "id",
      optionsName: "pipelineStage",
      label: strings.pages.hr.candidate.candidateList.pipelineStage,
    },
  ];

  const onCancel = () => {
    setPageState(PageState.View);
  };

  const onFinish = () => {
    fetch();
    setPageState(PageState.View);
  };

  const handleView = (candidate) => {
    navigate(`/candidate-details/${candidate.id}/record`);
  };

  const fetch = () => {
    setTableData({
      ...tableData,
      loading: true,
    });

    getCandidates({
      ...filter,
    }).then((response) => {
      if (!response || !response.ok) {
        return;
      }
      setTableData({
        loading: false,
        data: response.data.result,
        total: response.data.total,
      });
    });
  };

  return (
    <TablePageContext.Provider value={value}>
      <TablePage
        onFinish={() => onFinish()}
        deleteItem={deleteCandidate}
        tableDescription={columns}
        tableData={tableData}
        permissionGroup={permissionGroup}
        filter={filter}
        filters={filters}
        filterOptions={filterOptions}
        referenceType={referenceType}
        handleView={handleView}
        tagPage={<Tag referenceType={referenceType} data={selectedItems} />}
        editPage={
          <EditCandidate
            data={selectedItems}
            onCancel={() => onCancel()}
            onFinish={() => onFinish()}
          />
        }
        addPage={
          <AddCandidate
            onCancel={() => onCancel()}
            onFinish={() => onFinish()}
          />
        }
        addButtonText={strings.components.tablePage.addButton.cantidate}
      />
    </TablePageContext.Provider>
  );
};

export default CandidateList;
