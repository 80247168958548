export const BasePermissions = {
    VIEW_LIST: 'VIEW_LIST',
    VIEW: 'VIEW',
    VIEW_ALL: 'VIEW_ALL',
    ADD: 'ADD',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
    DOCUMENTS: 'DOCUMENTS',
    VIEW_MY: 'VIEW_MY',
    EDIT_MY: 'EDIT_MY',
    DELETE_MY: 'DELETE_MY',
    ADD_MY: 'ADD_MY',
    RESOLVE: 'RESOLVE',
    BOOKMARK: 'BOOKMARK',
}

export const ModulePermissions = {
    HOME: 'HOME',
    DASHBOARD: 'DASHBOARD',
    CALENDAR: 'CALENDAR',
    WORKSPACES: 'WORKSPACES',
    HR: 'HR',
    PRODUCT: 'PRODUCT',
    FINANCE: 'FINANCE',
    CRM: 'CRM',
    ADMINISTRATION: 'ADMINISTRATION',
    COMPANY_DOCUMENT: 'COMPANY_DOCUMENT',
    PROJECT_MANAGEMENT: 'PROJECT_MANAGEMENT',
    CHECK_LIST: 'CHECK_LIST',
    DOCUMENT: 'DOCUMENT',
    ASSET_MANAGEMENT: 'ASSET_MANAGEMENT',
    SPACES: 'SPACES',
};

const AppPermissions = {

    TEAM: {
        MODULE: 'HR',
        GROUP: 'TEAM',
        ...BasePermissions
    },
    ORGANISATION_UNIT: {
        MODULE: 'HR',
        GROUP: 'ORGANISATION_UNIT',
        ...BasePermissions
    },
    EMPLOYEE: {
        MODULE: 'HR',
        GROUP: 'EMPLOYEE',
        ...BasePermissions
    },
    ORGANISATION_USER_CONTRACT: {
        MODULE: 'HR',
        GROUP: 'ORGANISATION_USER_CONTRACT',
        ...BasePermissions
    },
    ORGANISATION_USER_CONTRACT_PART: {
        MODULE: 'HR',
        GROUP: 'ORGANISATION_USER_CONTRACT_PART',
        ...BasePermissions
    },
    JOB: {
        MODULE: 'HR',
        GROUP: 'JOB',
        ...BasePermissions
    },
    CANDIDATE: {
        MODULE: 'HR',
        GROUP: 'CANDIDATE',
        ...BasePermissions
    },
    ADDITIONAL_HOURS: {
        MODULE: 'HR',
        GROUP: 'ADDITIONAL_HOURS',
        ...BasePermissions
    },
    LEAVE_REQUEST: {
        MODULE: 'HR',
        GROUP: 'LEAVE_REQUEST',
        ...BasePermissions
    },
    LEAVE: {
        MODULE: 'HR',
        GROUP: 'LEAVE',
        ...BasePermissions
    },
    CANDIDATE_SOURCE: {
        MODULE: 'HR',
        GROUP: 'CANDIDATE_SOURCE',
        ...BasePermissions
    },
    CANDIDATE_STATUS: {
        MODULE: 'HR',
        GROUP: 'CANDIDATE_STATUS',
        ...BasePermissions
    },
    BUSINESS_TRIP: {
        MODULE: 'HR',
        GROUP: 'BUSINESS_TRIP',
        ...BasePermissions
    },
    JOB_CONTRACT_TYPE: {
        MODULE: 'HR',
        GROUP: 'JOB_CONTRACT_TYPE',
        ...BasePermissions
    },
    JOB_STATUS: {
        MODULE: 'HR',
        GROUP: 'JOB_STATUS',
        ...BasePermissions
    },
    NON_WORKING_DAYS: {
        MODULE: 'HR',
        GROUP: 'NON_WORKING_DAYS',
        ...BasePermissions
    },
    USER_POSITION: {
        MODULE: 'HR',
        GROUP: 'USER_POSITION',
        ...BasePermissions
    },
    LEAVE_TYPE: {
        MODULE: 'HR',
        GROUP: 'LEAVE_TYPE',
        ...BasePermissions
    },
    ROLE: {
        MODULE: 'ADMINISTRATION',
        GROUP: 'ROLE',
        ...BasePermissions
    },
    ROLE_PERMISSIONS: {
        MODULE: 'ADMINISTRATION',
        GROUP: 'ROLE_PERMISSIONS',
        ...BasePermissions
    },
    USER: {
        MODULE: 'ADMINISTRATION',
        GROUP: 'USER',
        ...BasePermissions
    },
    ASSET_LOCATION: {
        MODULE: 'ADMINISTRATION',
        GROUP: 'ASSET_LOCATION',
        ...BasePermissions
    },
    PRODUCT: {
        MODULE: 'PRODUCT',
        GROUP: 'PRODUCT',
        ...BasePermissions
    },
    PRODUCT_SETTINGS: {
        MODULE: 'PRODUCT',
        GROUP: 'PRODUCT_SETTINGS',
        ...BasePermissions
    },
    PRODUCTS_AND_SERVICES: {
        MODULE: 'PRODUCT',
        GROUP: 'PRODUCTS_AND_SERVICES',
        ...BasePermissions
    },
    PRODUCTS_AND_SERVICES_CATEGORY: {
        MODULE: 'PRODUCT',
        GROUP: 'PRODUCTS_AND_SERVICES_CATEGORY',
        ...BasePermissions
    },
    ATTRIBUTES: {
        MODULE: 'PRODUCT',
        GROUP: 'ATTRIBUTES',
        ...BasePermissions
    },
    ATTRIBUTES_VALUE: {
        MODULE: 'PRODUCT',
        GROUP: 'ATTRIBUTES_VALUE',
        ...BasePermissions
    },
    COMPOSITE_PRODUCT: {
        MODULE: 'PRODUCT',
        GROUP: 'COMPOSITE_PRODUCT',
        ...BasePermissions
    },
    BILL: {
        MODULE: 'FINANCE',
        GROUP: 'BILL',
        ...BasePermissions
    },
    CASH_FLOW: {
        MODULE: 'FINANCE',
        GROUP: 'CASH_FLOW',
        ...BasePermissions
    },
    CONTRACT: {
        MODULE: 'FINANCE',
        GROUP: 'CONTRACT',
        ...BasePermissions
    },
    CONTRACT_PART: {
        MODULE: 'FINANCE',
        GROUP: 'CONTRACT_PART',
        ...BasePermissions
    },
    UPLOAD_BANK_REPORT: {
        MODULE: 'FINANCE',
        GROUP: 'UPLOAD_BANK_REPORT',
        ...BasePermissions
    },
    BANK_REPORT: {
        MODULE: 'FINANCE',
        GROUP: 'BANK_REPORT',
        ...BasePermissions
    },
    BANK_ACCOUNT: {
        MODULE: 'FINANCE',
        GROUP: 'BANK_ACCOUNT',
        ...BasePermissions
    },
    CONTRACT_STATUS: {
        MODULE: 'FINANCE',
        GROUP: 'CONTRACT_STATUS',
        ...BasePermissions
    },
    CONTRACT_PART_STATUS: {
        MODULE: 'FINANCE',
        GROUP: 'CONTRACT_PART_STATUS',
        ...BasePermissions
    },
    TRANSACTION: {
        MODULE: 'FINANCE',
        GROUP: 'TRANSACTION',
        ...BasePermissions
    },
    TRANSACTION_CATEGORY: {
        MODULE: 'FINANCE',
        GROUP: 'TRANSACTION_CATEGORY',
        ...BasePermissions
    },
    INVOICE: {
        MODULE: 'FINANCE',
        GROUP: 'INVOICE',
        ...BasePermissions
    },
    INVOICE_SETTINGS: {
        MODULE: 'FINANCE',
        GROUP: 'INVOICE_SETTINGS',
        ...BasePermissions
    },
    BUSINESS_YEAR: {
        MODULE: 'FINANCE',
        GROUP: 'BUSINESS_YEAR',
        ...BasePermissions
    },
    BUSINESS_YEAR_START_VALUE: {
        MODULE: 'FINANCE',
        GROUP: 'BUSINESS_YEAR_START_VALUE',
        ...BasePermissions
    },
    PARTNER: {
        MODULE: 'CRM',
        GROUP: 'PARTNER',
        ...BasePermissions
    },
    PARTNER_CONTACT: {
        MODULE: 'CRM',
        GROUP: 'PARTNER_CONTACT',
        ...BasePermissions
    },
    CUSTOMER: {
        MODULE: 'CRM',
        GROUP: 'CUSTOMER',
        ...BasePermissions
    },
    PIPE_LINE: {
        MODULE: 'ADMINISTRATION',
        GROUP: 'PIPE_LINE',
        ...BasePermissions
    },
    PIPE_LINE_STAGE: {
        MODULE: 'ADMINISTRATION',
        GROUP: 'PIPE_LINE_STAGE',
        ...BasePermissions
    },
    COMPANY_DOCUMENT: {
        MODULE: 'COMPANY_DOCUMENT',
        GROUP: 'COMPANY_DOCUMENT',
        ...BasePermissions
    },
    COMPANY_DOCUMENT_FOLDER: {
        MODULE: 'COMPANY_DOCUMENT',
        GROUP: 'COMPANY_DOCUMENT_FOLDER',
        ...BasePermissions
    },
    COMPANY_DOCUMENT_CATEGORY: {
        MODULE: 'COMPANY_DOCUMENT',
        GROUP: 'COMPANY_DOCUMENT_CATEGORY',
        ...BasePermissions
    },
    COMPANY_DOCUMENT_TEMPLATE: {
        MODULE: 'COMPANY_DOCUMENT',
        GROUP: 'COMPANY_DOCUMENT_TEMPLATE',
        ...BasePermissions
    },
    COMPANY_DOCUMENT_TEMPLATE_ITEM: {
        MODULE: 'COMPANY_DOCUMENT',
        GROUP: 'COMPANY_DOCUMENT_TEMPLATE_ITEM',
        ...BasePermissions,
    },
    FILE_FOLDER: {
        MODULE: 'DOCUMENT',
        GROUP: 'FILE_FOLDER',
        ...BasePermissions
    },
    DOCUMENT: {
        MODULE: 'DOCUMENT',
        GROUP: 'DOCUMENT',
        ...BasePermissions
    },
    DOCUMENT_CATEGORY: {
        MODULE: 'DOCUMENT',
        GROUP: 'DOCUMENT_CATEGORY',
        ...BasePermissions
    },
    DOCUMENT_STATUS: {
        MODULE: 'DOCUMENT',
        GROUP: 'DOCUMENT_STATUS',
        ...BasePermissions
    },
    DOCUMENT_COMMENT: {
        MODULE: 'DOCUMENT',
        GROUP: 'DOCUMENT_COMMENT',
        ...BasePermissions
    },
    EMPLOYEE_RECORDS: {
        MODULE: 'HR',
        GROUP: 'EMPLOYEE_RECORDS',
        ...BasePermissions
    },
    CANDIDATE_RECORDS: {
        MODULE: 'HR',
        GROUP: 'CANDIDATE_RECORDS',
        ...BasePermissions
    },
    DEAL_RECORDS: {
        MODULE: 'CRM',
        GROUP: 'DEAL_RECORDS',
        ...BasePermissions
    },
    ASSET: {
        MODULE: 'ASSET_MANAGEMENT',
        GROUP: 'ASSET',
        ...BasePermissions
    },
    ASSET_CATEGORY: {
        MODULE: 'ASSET_MANAGEMENT',
        GROUP: 'ASSET_CATEGORY',
        ...BasePermissions
    },
    ASSET_TYPE: {
        MODULE: 'ASSET_MANAGEMENT',
        GROUP: 'ASSET_TYPE',
        ...BasePermissions
    },
    INVENTORY: {
        MODULE: 'ASSET_MANAGEMENT',
        GROUP: 'INVENTORY',
        ...BasePermissions
    },
    INVENTORY_CATEGORY: {
        MODULE: 'ASSET_MANAGEMENT',
        GROUP: 'INVENTORY_CATEGORY',
        ...BasePermissions
    },
    INVENTORY_TYPE: {
        MODULE: 'ASSET_MANAGEMENT',
        GROUP: 'INVENTORY_TYPE',
        ...BasePermissions
    },
    STOCK_CHANGE: {
        MODULE: 'ASSET_MANAGEMENT',
        GROUP: 'STOCK_CHANGE',
        ...BasePermissions
    },
    VENDOR: {
        MODULE: 'ASSET_MANAGEMENT',
        GROUP: 'VENDOR',
        ...BasePermissions
    },
    PROJECT: {
        MODULE: 'PROJECT_MANAGEMENT',
        GROUP: 'PROJECT',
        ACCESS: 'ACCESS',
        TASK_CATEGORY: 'TASK_CATEGORY',
        STATISTIC: 'STATISTIC',
        ...BasePermissions
    },
    PROJECT_CATEGORY: {
        MODULE: 'PROJECT_MANAGEMENT',
        GROUP: 'PROJECT_CATEGORY',
        ...BasePermissions
    },
    PROJECT_RESOURCE: {
        MODULE: 'PROJECT_MANAGEMENT',
        GROUP: 'PROJECT_RESOURCE',
        ...BasePermissions
    },
    PROJECT_RESOURCE_TYPE: {
        MODULE: 'PROJECT_MANAGEMENT',
        GROUP: 'PROJECT_RESOURCE_TYPE',
        ...BasePermissions
    },
    PROJECT_TIME_LOG: {
        MODULE: 'PROJECT_MANAGEMENT',
        GROUP: 'PROJECT_TIME_LOG',
        ...BasePermissions
    },
    TASK_CATEGORY: {
        MODULE: 'PROJECT_MANAGEMENT',
        GROUP: 'TASK_CATEGORY',
        ...BasePermissions
    },
    FINANCE_SETTINGS: {
        MODULE: 'FINANCE',
        GROUP: 'FINANCE_SETTINGS',
        ...BasePermissions
    },
    HR_SETTINGS: {
        MODULE: 'HR',
        GROUP: 'HR_SETTINGS',
        ...BasePermissions
    },
    PROJECT_SETTINGS: {
        MODULE: 'PROJECT_MANAGEMENT',
        GROUP: 'PROJECT_SETTINGS',
        ...BasePermissions
    },
    HOME:{
        MODULE: 'HOME',
        GROUP: 'HOME',
        ...BasePermissions
    },
    DASHBOARD:{
        MODULE: 'DASHBOARD',
        GROUP: 'DASHBOARD',
        ...BasePermissions
    },
    CALENDAR: {
        MODULE: 'CALENDAR',
        GROUP: 'CALENDAR',
        ...BasePermissions
    },
    WORKSPACES:{
        MODULE: 'WORKSPACES',
        GROUP: 'WORKSPACES',
        ...BasePermissions
    },
    BOARDS: {
        MODULE: 'WORKSPACES',
        GROUP: 'BOARDS',
        ...BasePermissions
    },
    CRM_SETTINGS: {
        MODULE: 'CRM',
        GROUP: 'CRM_SETTINGS',
        ...BasePermissions
    },
    DEAL_TYPE:{
        MODULE: 'CRM',
        GROUP: 'DEAL_TYPE',
        ...BasePermissions
    },
    DEAL_SOURCE:{
        MODULE: 'CRM',
        GROUP: 'DEAL_SOURCE',
        ...BasePermissions
    },
    DEAL_PRIORITY:{
        MODULE: 'CRM',
        GROUP: 'DEAL_PRIORITY',
        ...BasePermissions
    },
    DEAL:{
        MODULE: 'CRM',
        GROUP:'DEAL',
        ...BasePermissions
    },
    MODULE:{
        MODULE: 'MODULE',
        GROUP:'MODULE',
        ...ModulePermissions
    },
    COMPANY_SETTINGS:{
        MODULE: 'ADMINISTRATION',
        GROUP: 'COMPANY_SETTINGS',
        ...BasePermissions
    },
    PROJECT_RECORDS:{
        MODULE: 'PROJECT_MANAGEMENT',
        GROUP: 'PROJECT_RECORDS',
        ...BasePermissions
    },
    TASK:{
        MODULE: 'PROJECT_MANAGEMENT',
        GROUP: 'TASK',
        ...BasePermissions
    },
    PRESENCE:{
        MODULE: 'HR',
        GROUP: 'PRESENCE',
        ...BasePermissions
    },
    SPACES: {
        MODULE: 'SPACES',
        GROUP: 'SPACES',
        ...BasePermissions
    },
    SPACE_FOLDERS: {
        MODULE: 'SPACES',
        GROUP: 'SPACE_FOLDERS',
        ...BasePermissions
    },
    CHECK_LIST: {
        MODULE: 'CHECK_LIST',
        GROUP: 'CHECK_LIST',
    },
    CHECK_LIST_CATEGORY: {
        MODULE: 'CHECK_LIST',
        GROUP: 'CHECK_LIST_CATEGORY',
    },
    CHECK_LIST_TEMPLATE: {
        MODULE: 'CHECK_LIST',
        GROUP: 'CHECK_LIST_TEMPLATE',
    },
    CHECK_LIST_TEMPLATE_QUESTION: {
        MODULE: 'CHECK_LIST',
        GROUP: 'CHECK_LIST_TEMPLATE_QUESTION',
    },
    CHECK_LIST_SCHEDULED_CHECK: {
        MODULE: 'CHECK_LIST',
        GROUP: 'CHECK_LIST_SCHEDULED_CHECK',
        ...BasePermissions
    }
}

export const AppAccesses = {
    WORKSPACES: 'WORKSPACES',
}

export default AppPermissions
