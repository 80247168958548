import React, {useContext, useEffect} from "react";
import { Paper } from "@mui/material";
import strings from '../../localization';
import {
    getBirthdays,
    getContractParts,
    getContracts,
    getLeaves,
    getNonWorkingDays,
    getOrganisationUserContract,
    getOrganisationUserContractParts,
    getProjects,
    getBirthdayDescription,
    getLeaveDesription,
    getNonWorkingDayDescription,
    getOrganisationUserContractDescription,
    getOrganisationUserContractPartDescription,
    getContractDescription,
    getContractPartsDescription,
    getContractPartsDescriptionExtra,
    getProjectDescription,
    getCustomEvents,
    getCustomEventDescription,
    getScheduledCheckDescription, getScheduledChecks
} from './CalendarContent';
import { dateToString } from "../../Util/DateUtil";
import { DrawerContentSource } from "../../Constants/Company/CalendarContent";
import EventContext from "../../Context/EventContext";
import DrawerContext from "../../Context/DrawerContext";

const CalendarEventList = (props) => {
    const {day: currentDay, month : currentMonth, year: currentYear} = props.date;
    const day = { date: new Date(currentYear, currentMonth - 1, currentDay)}
    const dateString = dateToString(day.date);
    const birthdays = getBirthdays(props.calendarData, day);
    const nonWorkingDays = getNonWorkingDays(props.calendarData, day);
    const organisationUserContracts = getOrganisationUserContract(props.calendarData, day);
    const contracts = getContracts(props.calendarData, day);
    const contractParts = getContractParts(props.calendarData, day);
    const organisationUserContractParts = getOrganisationUserContractParts(props.calendarData, day);
    const projects = getProjects(props.calendarData, day);
    const leaves = getLeaves(props.calendarData, day, day.date.getDay() === 6 || day.date.getDay()  === 0);
    const customEvents = getCustomEvents(props.calendarData, day);
    const scheduledChecks = getScheduledChecks(props.calendarData, day);
    const {customEvent, setCustomEvent} = useContext(EventContext);
    const {customEventForDelete, setCustomEventForDelete} = useContext(EventContext);
    const {setDrawerTitle} = useContext(DrawerContext);


    useEffect(() => {
        setDrawerTitle();
    });

    const renderContent = (
        {
            className = '', 
            src=DrawerContentSource.CUSTOM_EVENT,
            title = '',
            description = '',
            descriptionSecundLine = '',
            date = '',
            renderTop = true,
            key,
    }) =>{
        return <div key={key} className={`content ${className}`}>
            {renderTop && <div className="top">
                <img className="icon" src={src}/>
                <div className="title">{title}</div>
            </div>}
            <div className="middle">
                <div className="description">{description}</div>
                {descriptionSecundLine && <div className="description secund-line">{descriptionSecundLine}</div>}
                <div className="date">{date}</div>
            </div>         
        </div>

    }

    const renderLeaves = () => leaves.map(
        (leave, index) => renderContent({
            className: 'leave',
            src: DrawerContentSource.LEAVE,
            title: strings.components.calendarEvent.leaves,
            description: getLeaveDesription(leave),
            date: `${dateToString(new Date(leave.fromDate))} - ${dateToString(new Date(leave.toDate))}`,
            renderTop: index === 0,
            key: leave.id
        })
    )

    const renderNonWorkingDays = () => nonWorkingDays.map(
        (day, index) => renderContent({
            className: "non-working-day",
            src: DrawerContentSource.NON_WORKING_DAY,
            title: strings.components.calendarEvent.nonWorkingDays,
            description: getNonWorkingDayDescription(day),
            date: dateString,
            renderTop: index === 0,
            key: day.id
        })
    )

    const renderBirthdays = () => birthdays.map(
        (birthday, index) => renderContent({
            className: "birthday",
            src: DrawerContentSource.BIRTHDAY,
            title: strings.components.calendarEvent.birthday,
            description: getBirthdayDescription(birthday),
            date: dateString,
            renderTop: index === 0,
            key: birthday.id,
        })
    )

    const renderProjects = () => projects?.map(
        (project, index) => renderContent({
            className: "project",
            src: DrawerContentSource.PROJECT,
            title: strings.components.calendarEvent.projects,
            description: getProjectDescription(project),
            date: dateString,
            renderTop: index === 0,
            key: project.key,
        })
    )

    const renderContracts = () => contracts.map(
        (contract, index) => renderContent({
            className: "contract",
            src: DrawerContentSource.CONTRACT,
            title: strings.components.calendarEvent.contracts,
            description: getContractDescription(contract),
            date: dateString,
            renderTop: index === 0,
            key: contract.id,
        })
    )

    const renderContractParts = () => contractParts.map(
        (contract, index) => renderContent(
            {
                className: "contract-part",
                src: DrawerContentSource.CONTRACT_PART,
                title: strings.components.calendarEvent.contractParts,
                description: getContractPartsDescription(contract),
                descriptionSecundLine: getContractPartsDescriptionExtra(contract),
                date: `${strings.components.calendarEvent.paymentDate} ${dateToString(new Date(contract.paymentDate))}`,
                renderTop: index === 0,
                key: contract.id,
            }
        )
    )
    
    const renderOrganisationUserContract = () => organisationUserContracts.map(
        (contract, index) => renderContent({
            className: "organisation-user-contract",
            src: DrawerContentSource.ORGANISATION_CONTRACT,
            title: strings.components.calendarEvent.organisationUserContracts,
            description: getOrganisationUserContractDescription(contract),
            date: `${dateToString(new Date(contract.dateCreated))} - ${dateString}`,
            renderTop: index === 0,
            key: contract.id,
        })
    )

    const renderOrganisationUserContractParts = () => organisationUserContractParts.map(
        (contract, index) => renderContent({
            className: "organisation-user-contract-parts",
            src: DrawerContentSource.ORGANISATION_CONTRACT_PART,
            title: strings.components.calendarEvent.organisationUserContractParts,
            description: getOrganisationUserContractPartDescription(contract),
            date: `${dateToString(new Date(contract.dateCreated))} - ${dateString}`, 
            renderTop: index === 0,
            key: contract.id,
        })
    )

    const renderCustomEvents = () => customEvents.map(
        (customEvent, index) => renderContent({
            className: "customEvent",
            src: DrawerContentSource.CUSTOM_EVENT,
            title: strings.components.calendarEvent.customEvents,
            description: getCustomEventDescription(customEvent, setCustomEvent,customEventForDelete,setCustomEventForDelete),
            date: dateString,
            renderTop: index === 0,
            key: customEvent.id,
        })
    )

    const renderScheduledChecks = () => scheduledChecks.map(
        (scheduleCheck, index) => renderContent({
            className: "schedule-check-pending",
            src: DrawerContentSource.SCHEDULED_CHECK,
            title: strings.components.calendarEvent.ScheduleChecks,
            description: getScheduledCheckDescription(scheduleCheck),
            date: dateString,
            renderTop: index === 0,
            key: scheduleCheck.id,
        })
    )

    const isNoData = () => {
        if(birthdays.length===0 && contractParts.length===0 && contracts.length===0 &&
            customEvents.length===0 && organisationUserContractParts.length===0 &&
            organisationUserContracts.length===0  && leaves.length === 0 && nonWorkingDays.length === 0
            && scheduledChecks.length === 0){

            if(projects){
                return false;
            }
            return true;
        }
        return false;
    }


    return <div>
            { isNoData()  &&
               <div className='no-data-calendar'>
                   <img src='/images/table-page/no-data.svg'/>
                   <div className='title'>{strings.components.calendar.noData.title}</div>
                   <div className='text'>{strings.components.calendar.noData.text}</div>
               </div>

            }
            { !isNoData() &&
                <div className='drawer-form'>
                    <Paper className='paper'>
                        {
                            renderCustomEvents()
                        }
                        {
                            renderProjects()
                        }
                        {
                            renderBirthdays()
                        }
                        {
                            renderLeaves()
                        }
                        {
                            renderNonWorkingDays()
                        }
                        {
                            renderContracts()
                        }
                        {
                            renderContractParts()
                        }
                        {
                            renderOrganisationUserContract()
                        }
                        {
                            renderOrganisationUserContractParts()
                        }
                        {
                            renderScheduledChecks()
                        }
                    </Paper>
                </div>
            }
    </div>

}

export default CalendarEventList;