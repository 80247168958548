import React, {useContext, useEffect, useState} from "react";
import LoginForm from "../../Components/Forms/Pages/User/LoginForm";
import {useForm} from "react-hook-form";
import {Button, Grid, LinearProgress, Paper} from "@mui/material";
import strings from "../../localization";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {login} from "../../Base/OAuth";
import {useNavigate} from "react-router-dom";
import ValidationPatters from "../../Constants/Base/ValidationPatters";
import SnackbarContext from "../../Context/SnackbarContext";
import {isCompanyValid} from "../../Util/CompanyUtil";

const formRules = {
    'email': {required: { value: true, message: strings.forms.common.thisFieldIsRequired},
        pattern: { value: ValidationPatters.EMAIL, message: strings.forms.common.emailFormatError }},
    'password': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
}

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const form = useForm();
    const {handleSubmit, formState: {errors}} = form;

    const [showLoader, setShowLoader] = useState(false)
    const {showMessage} = useContext(SnackbarContext);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.FULL_NO_HEADER));
    }, [])

    const onSubmit = (data) => {

        setShowLoader(true)

        login(data.email, data.password).then(({response, responseLogin} ) => {

            setShowLoader(false)

            if (!responseLogin || !responseLogin.ok) {
                showMessage(strings.pages.login.wrongUsernameOrPassword, 'error')
                return;
            }

            if(isCompanyValid(response.data.user.company)) {
                navigate('/');
            } else {
                navigate('/company-settings');
            }
        })
    }

    return <div id='login-page'>     
        <div className="color-overlay"></div>
        
        <Paper>
            <div className="loader">
                {showLoader && <div className="loader-background">
                    {<LinearProgress/>}
                </div>}
            </div>           
            
            <img className='logo' src={'/images/managevio-brain.svg'} loading={'lazy'}/>

            <h5>{strings.pages.login.login}</h5>
            <LoginForm formRules={formRules} errors={errors} form={form} onSubmit={handleSubmit(onSubmit)}/>
        </Paper>
        
    </div>
};
export default Login;
