import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import {deleteCompositeProduct, getCompositeProductList} from "../../../Services/Product/CompositeProductService";
import AddCompositeProduct from "./AddCompositeProduct";
import EditCompositeProduct from "./EditCompositeProduct";

const tableDescription = [
    {
        field: 'product',
        headerName: strings.pages.product.compositeProduct.compositeProductList.product,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 300
    },
    {
        field: 'quantity', headerName: strings.pages.product.compositeProduct.compositeProductList.quantity,
        width: 300,
    },
];

const filters = []

const CompositeProductList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const siteData = useSelector((state) => state.siteData);
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.COMPOSITE_PRODUCT;
    const [filterOptions, setFilterOptions] = useState({});
    const permissionGroup = AppPermissions.COMPOSITE_PRODUCT.GROUP;
    const {id: productId} = useParams();


    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.product.compositeProduct.compositeProductList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.PRODUCT},
                {label: strings.pages.product.compositeProduct.compositeProductList.pageTitle},
            ],
        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    useEffect(() => {
        if(!Object.keys(siteData.data).length){
            return;
        }

        setFilterOptions({})
    },[siteData])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });
        getCompositeProductList({
            ...filter,
            parentId: productId,
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }



    return <TablePageContext.Provider value={value}>
        <TablePage tableDescription={columns} deleteItem={deleteCompositeProduct}
                   tableData={tableData} onFinish={onFinish}
                   filter={filter} filters={filters} filterOptions={filterOptions}
                   referenceType={referenceType}
                   permissionGroup={permissionGroup}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditCompositeProduct data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()} productId={productId}/> }
                   addPage={<AddCompositeProduct onCancel={() => onCancel()} onFinish={() => onFinish()} productId={productId}/>}
                   addButtonText={strings.components.tablePage.addButton.compositeProduct}
        />
    </TablePageContext.Provider>;
}

export default CompositeProductList;
