import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {getFinanceStatistics} from "../../Services/Statistic/StatisticService";
import {getCurrentYear, getYears} from "../../Util/DateUtil";
import {dataToListOfValues, lineBackgroundColors, lineBorderColors, barBackgroundColors, barBorderColors, monthShortLabels, barBaseOptions, lineBaseOptions} from "../../Util/ChartUtil";
import {renderNumber} from "../../Util/RenderDataUtil";
import {Grid, LinearProgress, List, Paper} from "@mui/material";
import strings from "../../localization";
import {isFreeAccount} from "../../Util/PermissionUtil";
import { Bar, Line } from 'react-chartjs-2';
import { changeBreadcrumbs } from "../../Slices/BreadcrumbsSlice";
import LineChart from '../../Components/Charts/LineChart';
import BarChart from '../../Components/Charts/BarChart';
import MultipleSelectCheckmarks from "../../Components/Controls/Inputs/MultiSelectControl";
import {getAllUserCompanyAccess} from "../../Services/User/UserCompanyAccessService";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const FinanceDashboard = () => {

    const [year, setYear] = useState(getCurrentYear());

    const dispatch = useDispatch();
    const [overview, setOverview] = useState({ data: {}});
    const auth = useSelector((state) => state.auth);

    const company = useSelector((state) => state.auth.company);
    const [companies, setCompanies] = useState([]);
    const [selected, setSelected] = React.useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [searchParams, setSearchParams] = useState('');
    const [isSelectAll, setIsSelectAll] =useState(false);

    useEffect(()=>{
        setCompanies([]);
        fetchUserCompanies();
    },[searchParams])

    useEffect(()=>{
        if(selectAll) {
            setSelected(companies);
        }else if(isSelectAll) {
            setSelected([]);
        }else {
            setSelected(selected);
        }
        setIsSelectAll(false)
    },[selectAll])

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.statistic.dashboard.finance,
            hierarchy:[
                {label: strings.navigation.homeTag},
                {label: strings.pages.statistic.dashboard.pageTitle},
                {label: strings.pages.statistic.dashboard.finance}
            ],        
        }));
    }, []);

    useEffect(() => {
        fetch();
    }, [year, selected]);

    const overView = (response) => {
        setOverview({
            data: response.data,
            invoiceDataDefaultCurrency: {
                labels: monthShortLabels,
                datasets: [
                    {
                        label: 'RSD',
                        data: dataToListOfValues(response.data.dataExchange),
                        backgroundColor: [
                            lineBackgroundColors[0],
                        ],
                        borderColor: [
                            lineBorderColors[0],
                        ],
                        borderWidth: 1,
                    },
                ]
            },
            billDataDefaultCurrency: {
                labels: monthShortLabels,
                datasets: [
                    {
                        label: 'RSD',
                        data: dataToListOfValues(response.data.dataBillExchange),
                        backgroundColor: [
                            lineBackgroundColors[0],
                        ],
                        borderColor: [
                            lineBorderColors[0],
                        ],
                        borderWidth: 1,
                    },
                ]
            },
            dataInvoiceTaxOutPerCurrency: {
                labels: monthShortLabels,
                datasets: [
                    {
                        label: 'RSD',
                        data: dataToListOfValues(response.data.invoiceTaxOutDataRSD),
                        backgroundColor: [
                            barBackgroundColors[0],
                        ],
                        borderColor: [
                            barBorderColors[0],
                        ],
                        borderWidth: 1,
                    }
                ],
            },
            dataInvoiceTaxInPerCurrency: {
                labels: monthShortLabels,
                datasets: [
                    {
                        label: 'RSD',
                        data: dataToListOfValues(response.data.invoiceTaxInDataRSD),
                        backgroundColor: [
                            lineBackgroundColors[0],
                        ],
                        borderColor: [
                            lineBorderColors[0],
                        ],
                        borderWidth: 1,
                    }
                ],

            },
            dataBillPerCurrency: {
                labels: monthShortLabels,
                datasets: [
                    {
                        label: 'RSD',
                        data: dataToListOfValues(response.data.dataBillRSD),
                        backgroundColor: [
                            barBackgroundColors[0],
                        ],
                        borderColor: [
                            barBorderColors[0],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'EUR',
                        data: dataToListOfValues(response.data.dataBillEUR),
                        backgroundColor: [
                            barBackgroundColors[1],
                        ],
                        borderColor: [
                            barBorderColors[1],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'USD',
                        data: dataToListOfValues(response.data.dataBillUSD),
                        backgroundColor: [
                            barBackgroundColors[2],
                        ],
                        borderColor: [
                            barBorderColors[2],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'CHF',
                        data: dataToListOfValues(response.data.dataBillCHF),
                        backgroundColor: [
                            barBackgroundColors[3],
                        ],
                        borderColor: [
                            barBorderColors[3],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'USD',
                        data: dataToListOfValues(response.data.dataBillGBP),
                        backgroundColor: [
                            barBackgroundColors[4],
                        ],
                        borderColor: [
                            barBorderColors[4],
                        ],
                        borderWidth: 1,
                    },
                ],

            },
            dataInvoicePerCurrency: {
                labels: monthShortLabels,
                datasets: [
                    {
                        label: 'RSD',
                        data: dataToListOfValues(response.data.dataRSD),
                        backgroundColor: [
                            barBackgroundColors[0],
                        ],
                        borderColor: [
                            barBorderColors[0],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'EUR',
                        data: dataToListOfValues(response.data.dataEUR),
                        backgroundColor: [
                            barBackgroundColors[1],
                        ],
                        borderColor: [
                            barBorderColors[1],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'USD',
                        data: dataToListOfValues(response.data.dataUSD),
                        backgroundColor: [
                            barBackgroundColors[2],
                        ],
                        borderColor: [
                            barBorderColors[2],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'CHF',
                        data: dataToListOfValues(response.data.dataCHF),
                        backgroundColor: [
                            barBackgroundColors[3],
                        ],
                        borderColor: [
                            barBorderColors[3],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'GBP',
                        data: dataToListOfValues(response.data.dataGBP),
                        backgroundColor: [
                            barBackgroundColors[4],
                        ],
                        borderColor: [
                            barBorderColors[4],
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            dataIncomePerCurrency: {
                labels: monthShortLabels,
                datasets: [
                    {
                        label: 'RSD',
                        data: dataToListOfValues(response.data.dataIncomeRSD),
                        backgroundColor: [
                            barBackgroundColors[0],
                        ],
                        borderColor: [
                            barBorderColors[0],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'EUR',
                        data: dataToListOfValues(response.data.dataIncomeEUR),
                        backgroundColor: [
                            barBackgroundColors[1],
                        ],
                        borderColor: [
                            barBorderColors[1],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'USD',
                        data: dataToListOfValues(response.data.dataIncomeUSD),
                        backgroundColor: [
                            barBackgroundColors[2],
                        ],
                        borderColor: [
                            barBorderColors[2],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'CHF',
                        data: dataToListOfValues(response.data.dataIncomeCHF),
                        backgroundColor: [
                            barBackgroundColors[3],
                        ],
                        borderColor: [
                            barBorderColors[3],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'GBP',
                        data: dataToListOfValues(response.data.dataIncomeGBP),
                        backgroundColor: [
                            barBackgroundColors[4],
                        ],
                        borderColor: [
                            barBorderColors[4],
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            dataOutcomePerCurrency: {
                labels: monthShortLabels,
                datasets: [
                    {
                        label: 'RSD',
                        data: dataToListOfValues(response.data.dataOutcomeRSD),
                        backgroundColor: [
                            barBackgroundColors[0],
                        ],
                        borderColor: [
                            barBorderColors[0],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'EUR',
                        data: dataToListOfValues(response.data.dataOutcomeEUR),
                        backgroundColor: [
                            barBackgroundColors[1],
                        ],
                        borderColor: [
                            barBorderColors[1],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'USD',
                        data: dataToListOfValues(response.data.dataOutcomeUSD),
                        backgroundColor: [
                            barBackgroundColors[2],
                        ],
                        borderColor: [
                            barBorderColors[2],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'CHF',
                        data: dataToListOfValues(response.data.dataOutcomeCHF),
                        backgroundColor: [
                            barBackgroundColors[3],
                        ],
                        borderColor: [
                            barBorderColors[3],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'GBP',
                        data: dataToListOfValues(response.data.dataOutcomeGBP),
                        backgroundColor: [
                            barBackgroundColors[4],
                        ],
                        borderColor: [
                            barBorderColors[4],
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            dataContractAmountCustomerPerCurrency: {
                labels: monthShortLabels,
                datasets: [
                    {
                        label: 'RSD',
                        data: dataToListOfValues(response.data.dataContractAmountCustomerRSD),
                        backgroundColor: [
                            barBackgroundColors[0],
                        ],
                        borderColor: [
                            barBorderColors[0],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'EUR',
                        data: dataToListOfValues(response.data.dataContractAmountCustomerEUR),
                        backgroundColor: [
                            barBackgroundColors[1],
                        ],
                        borderColor: [
                            barBorderColors[1],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'USD',
                        data: dataToListOfValues(response.data.dataContractAmountCustomerUSD),
                        backgroundColor: [
                            barBackgroundColors[2],
                        ],
                        borderColor: [
                            barBorderColors[2],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'CHF',
                        data: dataToListOfValues(response.data.dataContractAmountCustomerCHF),
                        backgroundColor: [
                            barBackgroundColors[3],
                        ],
                        borderColor: [
                            barBorderColors[3],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'GBP',
                        data: dataToListOfValues(response.data.dataContractAmountCustomerGBP),
                        backgroundColor: [
                            barBackgroundColors[4],
                        ],
                        borderColor: [
                            barBorderColors[4],
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            dataContractAmountSupplierPerCurrency: {
                labels: monthShortLabels,
                datasets: [
                    {
                        label: 'RSD',
                        data: dataToListOfValues(response.data.dataContractAmountSupplierRSD),
                        backgroundColor: [
                            barBackgroundColors[0],
                        ],
                        borderColor: [
                            barBorderColors[0],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'EUR',
                        data: dataToListOfValues(response.data.dataContractAmountSupplierEUR),
                        backgroundColor: [
                            barBackgroundColors[1],
                        ],
                        borderColor: [
                            barBorderColors[1],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'USD',
                        data: dataToListOfValues(response.data.dataContractAmountSupplierUSD),
                        backgroundColor: [
                            barBackgroundColors[2],
                        ],
                        borderColor: [
                            barBorderColors[2],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'CHF',
                        data: dataToListOfValues(response.data.dataContractAmountSupplierCHF),
                        backgroundColor: [
                            barBackgroundColors[3],
                        ],
                        borderColor: [
                            barBorderColors[3],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'GBP',
                        data: dataToListOfValues(response.data.dataContractAmountSupplierGBP),
                        backgroundColor: [
                            barBackgroundColors[4],
                        ],
                        borderColor: [
                            barBorderColors[4],
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            dataContractActualAmountSupplierPerCurrency: {
                labels: monthShortLabels,
                datasets: [
                    {
                        label: 'RSD',
                        data: dataToListOfValues(response.data.dataContractActualAmountSupplierRSD),
                        backgroundColor: [
                            barBackgroundColors[0],
                        ],
                        borderColor: [
                            barBorderColors[0],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'EUR',
                        data: dataToListOfValues(response.data.dataContractActualAmountSupplierEUR),
                        backgroundColor: [
                            barBackgroundColors[1],
                        ],
                        borderColor: [
                            barBorderColors[1],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'USD',
                        data: dataToListOfValues(response.data.dataContractActualAmountSupplierUSD),
                        backgroundColor: [
                            barBackgroundColors[2],
                        ],
                        borderColor: [
                            barBorderColors[2],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'CHF',
                        data: dataToListOfValues(response.data.dataContractActualAmountSupplierCHF),
                        backgroundColor: [
                            barBackgroundColors[3],
                        ],
                        borderColor: [
                            barBorderColors[3],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'GBP',
                        data: dataToListOfValues(response.data.dataContractActualAmountSupplierGBP),
                        backgroundColor: [
                            barBackgroundColors[4],
                        ],
                        borderColor: [
                            barBorderColors[4],
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            dataContractActualAmountCustomerPerCurrency: {
                labels: monthShortLabels,
                datasets: [
                    {
                        label: 'RSD',
                        data: dataToListOfValues(response.data.dataContractActualAmountCustomerRSD),
                        backgroundColor: [
                            barBackgroundColors[0],
                        ],
                        borderColor: [
                            barBorderColors[0],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'EUR',
                        data: dataToListOfValues(response.data.dataContractActualAmountCustomerEUR),
                        backgroundColor: [
                            barBackgroundColors[1],
                        ],
                        borderColor: [
                            barBorderColors[1],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'USD',
                        data: dataToListOfValues(response.data.dataContractActualAmountCustomerUSD),
                        backgroundColor: [
                            barBackgroundColors[2],
                        ],
                        borderColor: [
                            barBorderColors[2],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'CHF',
                        data: dataToListOfValues(response.data.dataContractActualAmountCustomerCHF),
                        backgroundColor: [
                            barBackgroundColors[3],
                        ],
                        borderColor: [
                            barBorderColors[3],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'GBP',
                        data: dataToListOfValues(response.data.dataContractActualAmountCustomerGBP),
                        backgroundColor: [
                            barBackgroundColors[4],
                        ],
                        borderColor: [
                            barBorderColors[4],
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            dataOrganisationUserContractNetCurrency: {
                labels: monthShortLabels,
                datasets: [
                    {
                        label: 'RSD',
                        data: dataToListOfValues(response.data.organisationUserContractNetDataRSD),
                        backgroundColor: [
                            barBackgroundColors[0],
                        ],
                        borderColor: [
                            barBorderColors[0],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'EUR',
                        data: dataToListOfValues(response.data.organisationUserContractNetDataEUR),
                        backgroundColor: [
                            barBackgroundColors[1],
                        ],
                        borderColor: [
                            barBorderColors[1],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'USD',
                        data: dataToListOfValues(response.data.organisationUserContractNetDataUSD),
                        backgroundColor: [
                            barBackgroundColors[2],
                        ],
                        borderColor: [
                            barBorderColors[2],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'CHF',
                        data: dataToListOfValues(response.data.organisationUserContractNetDataCHF),
                        backgroundColor: [
                            barBackgroundColors[3],
                        ],
                        borderColor: [
                            barBorderColors[3],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'GBP',
                        data: dataToListOfValues(response.data.organisationUserContractNetDataGBP),
                        backgroundColor: [
                            barBackgroundColors[4],
                        ],
                        borderColor: [
                            barBorderColors[4],
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            dataOrganisationUserContractGrossPerCurrency: {
                labels: monthShortLabels,
                datasets: [
                    {
                        label: 'RSD',
                        data: dataToListOfValues(response.data.organisationUserContractGrossDataRSD),
                        backgroundColor: [
                            barBackgroundColors[0],
                        ],
                        borderColor: [
                            barBorderColors[0],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'EUR',
                        data: dataToListOfValues(response.data.organisationUserContractGrossDataEUR),
                        backgroundColor: [
                            barBackgroundColors[1],
                        ],
                        borderColor: [
                            barBorderColors[1],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'USD',
                        data: dataToListOfValues(response.data.organisationUserContractGrossDataUSD),
                        backgroundColor: [
                            barBackgroundColors[2],
                        ],
                        borderColor: [
                            barBorderColors[2],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'CHF',
                        data: dataToListOfValues(response.data.organisationUserContractGrossDataCHF),
                        backgroundColor: [
                            barBackgroundColors[3],
                        ],
                        borderColor: [
                            barBorderColors[3],
                        ],
                        borderWidth: 1,
                    },
                    {
                        label: 'GBP',
                        data: dataToListOfValues(response.data.organisationUserContractGrossDataGBP),
                        backgroundColor: [
                            barBackgroundColors[4],
                        ],
                        borderColor: [
                            barBorderColors[4],
                        ],
                        borderWidth: 1,
                    },
                ],
            }
        })
    }

    const fetch = () => {
        const ids = [];

        if(selected.length !== 0){
            for (let company of selected) {
                ids.push(company?.id);
            }
        }

        if(firstLoad){
            ids.push(company?.id);
            setSelected([company]);
            setFirstLoad(false);
        }

        getFinanceStatistics({
            year: year,
            companiesId: ids,
            term: searchParams.toString()
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            overView(response)
        })
    }

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;

        let duplicateRemoved = [];
        let isDuplicate = false;

        if(value.length!==0) {
            value.forEach((item) => {
                if (duplicateRemoved.findIndex((o) => o?.id === item?.id) >= 0) {
                    duplicateRemoved = duplicateRemoved.filter((x) => x?.id === item?.id);
                    isDuplicate = true;
                } else if(!item){
                    return;
                }else {
                    duplicateRemoved.push(item);
                }
            });
        }
        if(duplicateRemoved.length === companies.length){
                setSelectAll(true);
            }
            else {
                setSelectAll(false);
            }

        if(isDuplicate){
            setSelected([]);
            return;
        }

       setSelected(duplicateRemoved);
    };

    const fetchUserCompanies = () =>{
        if(!auth){
            return;
        }

        getAllUserCompanyAccess({userId: auth.id, term: searchParams}).then(response => {
            if(!response || !response.ok) {
                return;
            }
            let companiesList = [];
            let duplicateRemoved = [];

            for(let companyAccess of response.data){
                companiesList.push(companyAccess.company);
            }

            duplicateRemoved =  companiesList.map(e => e['id'])
                .map((e, i, final) => final.indexOf(e) === i && i)
                .filter((e) => companiesList[e]).map(e => companiesList[e]);
            setCompanies(duplicateRemoved);

            if(companiesList.length === 1){
                if(duplicateRemoved.length === companies.length){
                    setSelectAll(true);
                }
                else {
                    setSelectAll(false);
                }

                setSelected(duplicateRemoved);
            }
        })
    }

    return (
        <div className='container'>
            <div className="year-flex">
                <div className="year-select">
                    <img src={'/images/year-select-left.svg'} 
                        className="control" onClick={() => setYear(year => --year)} loading={'lazy'}/>
                    <div className="value"> {year} </div>
                    <img src={'/images/year-select-right.svg'}
                            className="control" onClick={() => setYear(year => ++year)} loading={'lazy'}/>
                </div>
            </div>
            <div className='company-flex'>
                <div className='company-select'>
                    <MultipleSelectCheckmarks
                        value={selected}
                        setValue={setCompanies}
                        name={'company'}
                        nameKey={'name'}
                        valueKey={'id'}
                        options={companies}
                        handleChange={handleChange}
                        selected={selected}
                        defaultValue={selected[0]}
                        type={'name'}
                        search={searchParams}
                        setSearch={setSearchParams}
                        selectAll={selectAll}
                        setSelectAll={setSelectAll}
                        setIsSelectAll={setIsSelectAll}
                        showSearch={true}
                        showAvatar={true}
                        className={'w-200'}
                        expandMoreIcon={ExpandMoreIcon}
                    />
                </div>
            </div>

            <div id='statistic-overview'>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <div className='statistic-overview-item'>
                            <div className="title">{strings.pages.statistic.financeDashboard.invoices + '(' + getCurrentYear() + ')'}</div>
                            <div className="main">RSD: { renderNumber(overview.data.invoiceTotalExchange ? overview.data.invoiceTotalExchange.value : 0) }</div>
                            <div className="data-flex">
                                <div className="data">RSD: { renderNumber(overview.data.invoiceTotalRSD ? overview.data.invoiceTotalRSD.value : 0) }</div>
                                <div className="data">EUR: { renderNumber(overview.data.invoiceTotalEUR ? overview.data.invoiceTotalEUR.value : 0) }</div>
                                <div className="data">USD: { renderNumber(overview.data.invoiceTotalUSD ? overview.data.invoiceTotalUSD.value : 0) }</div>
                                <div className="data">CHF: { renderNumber(overview.data.invoiceTotalCHF ? overview.data.invoiceTotalCHF.value : 0) }</div>
                                <div className="data">GBP: { renderNumber(overview.data.invoiceTotalGBP ? overview.data.invoiceTotalGBP.value : 0) }</div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div className='statistic-overview-item'>
                            <div className="title">{strings.pages.statistic.financeDashboard.invoicesLast12Months}</div>
                            <div className="main">RSD: { renderNumber(overview.data.invoiceTotalLast12Exchange ? overview.data.invoiceTotalLast12Exchange.value : 0) }</div>
                            <div className="data-flex">
                                <div className="data">RSD: { renderNumber(overview.data.invoiceTotalLast12RSD ? overview.data.invoiceTotalLast12RSD.value : 0) }</div>
                                <div className="data">EUR: { renderNumber(overview.data.invoiceTotalLast12EUR ? overview.data.invoiceTotalLast12EUR.value : 0) }</div>
                                <div className="data">USD: { renderNumber(overview.data.invoiceTotalLast12USD ? overview.data.invoiceTotalLast12USD.value : 0) }</div>
                                <div className="data">CHF: { renderNumber(overview.data.invoiceTotalLast12CHF ? overview.data.invoiceTotalLast12CHF.value : 0) }</div>
                                <div className="data">GBP: { renderNumber(overview.data.invoiceTotalLast12GBP ? overview.data.invoiceTotalLast12GBP.value : 0) }</div>
                            </div>
                        </div>
                    </Grid>
                    {
                        !isFreeAccount(auth.company) &&
                        <React.Fragment>
                            <Grid item xs={2}>
                                <div className='statistic-overview-item'>
                                    <div className="title">{strings.pages.statistic.financeDashboard.tax}</div>
                                    <div className="main"/>
                                    <div className="data-flex">
                                        <div className="data">{strings.pages.statistic.financeDashboard.out}: { renderNumber(overview.data.invoiceTaxOutTotalRSD ? overview.data.invoiceTaxOutTotalRSD.value : 0) }</div>
                                        <div className="data">{strings.pages.statistic.financeDashboard.in}: { renderNumber(overview.data.invoiceTaxInTotalRSD ? overview.data.invoiceTaxInTotalRSD.value : 0) }</div>
                                        <div className="data"/>
                                    </div>
                                    {/*<div className="data">EUR: { renderNumber(overview.data.billTotalEUR ? overview.data.billTotalEUR.value : 0) }</div>*/}
                                    {/*<div className="data">USD: { renderNumber(overview.data.billTotalUSD ? overview.data.billTotalUSD.value : 0) }</div>*/}
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className='statistic-overview-item'>
                                    <div className="title">{strings.pages.statistic.financeDashboard.bills}</div>
                                    <div className="main">RSD: { renderNumber(overview.data.billTotalExchange ? overview.data.billTotalExchange.value : 0) }</div>
                                    <div className="data-flex">
                                        <div className="data">RSD: { renderNumber(overview.data.billTotalRSD ? overview.data.billTotalRSD.value : 0) }</div>
                                        <div className="data">EUR: { renderNumber(overview.data.billTotalEUR ? overview.data.billTotalEUR.value : 0) }</div>
                                        <div className="data">USD: { renderNumber(overview.data.billTotalUSD ? overview.data.billTotalUSD.value : 0) }</div>
                                        <div className="data">CHF: { renderNumber(overview.data.billTotalCHF ? overview.data.billTotalCHF.value : 0) }</div>
                                        <div className="data">GBP: { renderNumber(overview.data.billTotalGBP ? overview.data.billTotalGBP.value : 0) }</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className='statistic-overview-item'>
                                    <div className="title">{strings.pages.statistic.financeDashboard.income}</div>
                                    <div className="main"/>
                                    <div className="data-flex">
                                        <div className="data">RSD: { renderNumber(overview.data.incomeTotalRSD ? overview.data.incomeTotalRSD.value : 0) }</div>
                                        <div className="data">EUR: { renderNumber(overview.data.incomeTotalEUR ? overview.data.incomeTotalEUR.value : 0) }</div>
                                        <div className="data">USD: { renderNumber(overview.data.incomeTotalUSD ? overview.data.incomeTotalUSD.value : 0) }</div>
                                        <div className="data">CHF: { renderNumber(overview.data.incomeTotalCHF ? overview.data.incomeTotalCHF.value : 0) }</div>
                                        <div className="data">GBP: { renderNumber(overview.data.incomeTotalGBP ? overview.data.incomeTotalGBP.value : 0) }</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className='statistic-overview-item'>
                                    <div className="title">{strings.pages.statistic.financeDashboard.outcome}</div>
                                    <div className="main"/>
                                    <div className="data-flex">
                                        <div className="data">RSD: { renderNumber(overview.data.outcomeTotalRSD ? overview.data.outcomeTotalRSD.value : 0) }</div>
                                        <div className="data">EUR: { renderNumber(overview.data.outcomeTotalEUR ? overview.data.outcomeTotalEUR.value : 0) }</div>
                                        <div className="data">USD: { renderNumber(overview.data.outcomeTotalUSD ? overview.data.outcomeTotalUSD.value : 0) }</div>
                                        <div className="data">CHF: { renderNumber(overview.data.outcomeTotalCHF ? overview.data.outcomeTotalCHF.value : 0) }</div>
                                        <div className="data">GBP: { renderNumber(overview.data.outcomeTotalGBP ? overview.data.outcomeTotalGBP.value : 0) }</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className='statistic-overview-item'>
                                    <div className="title">{strings.pages.statistic.financeDashboard.contractAmountCustomer}</div>
                                    <div className="data-flex">
                                        <div className="data">RSD: { renderNumber(overview.data.contractAmountCustomerTotalRSD ? overview.data.contractAmountCustomerTotalRSD.value : 0) }</div>
                                        <div className="data">EUR: { renderNumber(overview.data.contractAmountCustomerTotalEUR ? overview.data.contractAmountCustomerTotalEUR.value : 0) }</div>
                                        <div className="data">USD: { renderNumber(overview.data.contractAmountCustomerTotalUSD ? overview.data.contractAmountCustomerTotalUSD.value : 0) }</div>
                                        <div className="data">CHF: { renderNumber(overview.data.contractAmountCustomerTotalCHF ? overview.data.contractAmountCustomerTotalCHF.value : 0) }</div>
                                        <div className="data">GBP: { renderNumber(overview.data.contractAmountCustomerTotalGBP ? overview.data.contractAmountCustomerTotalGBP.value : 0) }</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className='statistic-overview-item'>
                                    <div className="title">{strings.pages.statistic.financeDashboard.contractAmountSupplier}</div>
                                    <div className="data-flex">
                                        <div className="data">RSD: { renderNumber(overview.data.contractAmountSupplierTotalRSD ? overview.data.contractAmountSupplierTotalRSD.value : 0) }</div>
                                        <div className="data">EUR: { renderNumber(overview.data.contractAmountSupplierTotalEUR ? overview.data.contractAmountSupplierTotalEUR.value : 0) }</div>
                                        <div className="data">USD: { renderNumber(overview.data.contractAmountSupplierTotalUSD ? overview.data.contractAmountSupplierTotalUSD.value : 0) }</div>
                                        <div className="data">CHF: { renderNumber(overview.data.contractAmountSupplierTotalCHF ? overview.data.contractAmountSupplierTotalCHF.value : 0) }</div>
                                        <div className="data">GBP: { renderNumber(overview.data.contractAmountSupplierTotalGBP ? overview.data.contractAmountSupplierTotalGBP.value : 0) }</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className='statistic-overview-item'>
                                    <div className="title">{strings.pages.statistic.financeDashboard.contractActualAmountCustomer}</div>
                                    <div className="data-flex">
                                        <div className="data">RSD: { renderNumber(overview.data.contractActualAmountCustomerTotalRSD ? overview.data.contractActualAmountCustomerTotalRSD.value : 0) }</div>
                                        <div className="data">EUR: { renderNumber(overview.data.contractActualAmountCustomerTotalEUR ? overview.data.contractActualAmountCustomerTotalEUR.value : 0) }</div>
                                        <div className="data">USD: { renderNumber(overview.data.contractActualAmountCustomerTotalUSD ? overview.data.contractActualAmountCustomerTotalUSD.value : 0) }</div>
                                        <div className="data">CHF: { renderNumber(overview.data.contractActualAmountCustomerTotalCHF ? overview.data.contractActualAmountCustomerTotalCHF.value : 0) }</div>
                                        <div className="data">GBP: { renderNumber(overview.data.contractActualAmountCustomerTotalGBP ? overview.data.contractActualAmountCustomerTotalGBP.value : 0) }</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className='statistic-overview-item'>
                                    <div className="title">{strings.pages.statistic.financeDashboard.contractActualAmountSupplier}</div>
                                    <div className="data-flex">
                                        <div className="data">RSD: { renderNumber(overview.data.contractActualAmountSupplierTotalRSD ? overview.data.contractActualAmountSupplierTotalRSD.value : 0) }</div>
                                        <div className="data">EUR: { renderNumber(overview.data.contractActualAmountSupplierTotalEUR ? overview.data.contractActualAmountSupplierTotalEUR.value : 0) }</div>
                                        <div className="data">USD: { renderNumber(overview.data.contractActualAmountSupplierTotalUSD ? overview.data.contractActualAmountSupplierTotalUSD.value : 0) }</div>
                                        <div className="data">CHF: { renderNumber(overview.data.contractActualAmountSupplierTotalCHF ? overview.data.contractActualAmountSupplierTotalCHF.value : 0) }</div>
                                        <div className="data">GBP: { renderNumber(overview.data.contractActualAmountSupplierTotalGBP ? overview.data.contractActualAmountSupplierTotalGBP.value : 0) }</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className='statistic-overview-item'>
                                    <div className="title">{strings.pages.statistic.financeDashboard.employeeContractsNet}</div>
                                    <div className="data-flex">
                                        <div className="data">RSD: { renderNumber(overview.data.organisationUserContractNetTotalRSD ? overview.data.organisationUserContractNetTotalRSD.value : 0) }</div>
                                        <div className="data">EUR: { renderNumber(overview.data.organisationUserContractNetTotalEUR ? overview.data.organisationUserContractNetTotalEUR.value : 0) }</div>
                                        <div className="data">USD: { renderNumber(overview.data.organisationUserContractNetTotalUSD ? overview.data.organisationUserContractNetTotalUSD.value : 0) }</div>
                                        <div className="data">CHF: { renderNumber(overview.data.organisationUserContractNetTotalCHF ? overview.data.organisationUserContractNetTotalCHF.value : 0) }</div>
                                        <div className="data">GBP: { renderNumber(overview.data.organisationUserContractNetTotalGBP ? overview.data.organisationUserContractNetTotalGBP.value : 0) }</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className='statistic-overview-item'>
                                    <div className="title">{strings.pages.statistic.financeDashboard.employeeContractsGross}</div>
                                    <div className="data-flex">
                                        <div className="data">RSD: { renderNumber(overview.data.organisationUserContractGrossTotalRSD ? overview.data.organisationUserContractGrossTotalRSD.value : 0) }</div>
                                        <div className="data">EUR: { renderNumber(overview.data.organisationUserContractGrossTotalEUR ? overview.data.organisationUserContractGrossTotalEUR.value : 0) }</div>
                                        <div className="data">USD: { renderNumber(overview.data.organisationUserContractGrossTotalUSD ? overview.data.organisationUserContractGrossTotalUSD.value : 0) }</div>
                                        <div className="data">CHF: { renderNumber(overview.data.organisationUserContractGrossTotalCHF ? overview.data.organisationUserContractGrossTotalCHF.value : 0) }</div>
                                        <div className="data">GBP: { renderNumber(overview.data.organisationUserContractGrossTotalGBP ? overview.data.organisationUserContractGrossTotalGBP.value : 0) }</div>
                                    </div>
                                </div>
                            </Grid>
                        </React.Fragment>
                    }

                </Grid>
            </div>

            <div className='graphs'>
                <Grid container spacing={1}>
                    <Grid item xs={6} >
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.financeDashboard.invoicesPerCurrency}</div>
                            {
                                !overview.dataInvoicePerCurrency &&
                                <LinearProgress/>
                            }
                            {
                                overview.dataInvoicePerCurrency &&
                                <BarChart inputData={overview.dataInvoicePerCurrency.datasets} labels = {monthShortLabels} />
                            }
                        </div>    
                    </Grid>
                    <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.financeDashboard.invoicesInDefaultCurrency}</div>
                            {
                                !overview.invoiceDataDefaultCurrency &&
                                <LinearProgress/>
                            }
                            {
                                overview.invoiceDataDefaultCurrency &&
                                <LineChart labels = {overview.invoiceDataDefaultCurrency.labels} inputData={overview.invoiceDataDefaultCurrency.datasets} options={lineBaseOptions}/>
                            }
                        </div>
                    </Grid>
                    { !isFreeAccount(auth.company) &&
                    <React.Fragment>

                    <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.financeDashboard.taxOut}</div>
                            {
                                !overview.dataInvoiceTaxOutPerCurrency &&
                                <LinearProgress/>
                            }
                            {
                                overview.dataInvoiceTaxOutPerCurrency &&
                                <BarChart inputData={overview.dataInvoiceTaxOutPerCurrency.datasets} labels = {monthShortLabels} />
                            }
                        </div>
                    </Grid>       
                        
                    <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.financeDashboard.taxIn}</div>
                            {
                                !overview.dataInvoiceTaxInPerCurrency &&
                                <LinearProgress/>
                            }
                            {
                                overview.dataInvoiceTaxInPerCurrency &&
                                <LineChart labels = {overview.dataInvoiceTaxInPerCurrency.labels} inputData={overview.dataInvoiceTaxInPerCurrency.datasets} options={lineBaseOptions}/>
                            }
                        </div>
                    </Grid>         
                        
                    <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.financeDashboard.billsPerCurrency}</div>
                            {
                                !overview.dataBillPerCurrency &&
                                <LinearProgress/>
                            }
                            {
                                overview.dataBillPerCurrency &&
                                <BarChart inputData={overview.dataBillPerCurrency.datasets} labels = {monthShortLabels} />
                            }
                        </div>
                    </Grid>         
                        
                    <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.financeDashboard.billsDefaultCurrency}</div>
                            {
                                !overview.billDataDefaultCurrency &&
                                <LinearProgress/>
                            }
                            {
                                
                                overview.billDataDefaultCurrency &&
                                <LineChart labels = {overview.billDataDefaultCurrency.labels} inputData={overview.billDataDefaultCurrency.datasets} options={lineBaseOptions}/>
                            }
                        </div>
                    </Grid>
                        
                    <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.financeDashboard.income}</div>
                            {
                                !overview.dataIncomePerCurrency &&
                                <LinearProgress/>
                            }
                            {
                                overview.dataIncomePerCurrency &&
                                <BarChart inputData={overview.dataIncomePerCurrency.datasets} labels = {monthShortLabels} />
                            }
                        </div>
                    </Grid>    
                       
                    <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.financeDashboard.outcome}</div>
                            {
                                !overview.dataOutcomePerCurrency &&
                                <LinearProgress/>
                            }
                            {
                                overview.dataOutcomePerCurrency &&
                                <BarChart inputData={overview.dataOutcomePerCurrency.datasets} labels = {monthShortLabels} />
                            }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.financeDashboard.contractAmountCustomer}</div>
                            {
                                !overview.dataContractAmountCustomerPerCurrency &&
                                <LinearProgress/>
                            }
                            {
                                overview.dataContractAmountCustomerPerCurrency &&
                                <BarChart inputData={overview.dataContractAmountCustomerPerCurrency.datasets} labels = {monthShortLabels} />
                            }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.financeDashboard.contractAmountSupplier}</div>
                            {
                                !overview.dataContractAmountSupplierPerCurrency &&
                                <LinearProgress/>
                            }
                            {
                                overview.dataContractAmountSupplierPerCurrency &&
                                <BarChart inputData={overview.dataContractAmountSupplierPerCurrency.datasets} labels = {monthShortLabels} />
                            }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.financeDashboard.contractActualAmountCustomer}</div>
                            {
                                !overview.dataContractActualAmountCustomerPerCurrency &&
                                <LinearProgress/>
                            }
                            {
                                overview.dataContractActualAmountCustomerPerCurrency &&
                                <BarChart inputData={overview.dataContractActualAmountCustomerPerCurrency.datasets} labels = {monthShortLabels} />
                            }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.financeDashboard.contractActualAmountSupplier}</div>
                            {
                                !overview.dataContractActualAmountSupplierPerCurrency &&
                                <LinearProgress/>
                            }
                            {
                                overview.dataContractActualAmountSupplierPerCurrency &&
                                <BarChart inputData={overview.dataContractActualAmountSupplierPerCurrency.datasets} labels = {monthShortLabels} />
                            }
                        </div>
                    </Grid>
                    <Grid item xs={6} >
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.financeDashboard.employeeContractsNet}</div>
                            {
                                !overview.dataOrganisationUserContractNetCurrency &&
                                <LinearProgress/>
                            }
                            {
                                overview.dataOrganisationUserContractNetCurrency &&
                                <BarChart inputData={overview.dataOrganisationUserContractNetCurrency.datasets} labels = {monthShortLabels} />
                            }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.financeDashboard.employeeContractsGross}</div>
                            {
                                !overview.dataOrganisationUserContractGrossPerCurrency &&
                                <LinearProgress/>
                            }
                            {
                                overview.dataOrganisationUserContractGrossPerCurrency &&
                                <BarChart inputData={overview.dataOrganisationUserContractGrossPerCurrency.datasets} labels = {monthShortLabels} />
                            }
                        </div>
                    </Grid>
                    </React.Fragment>
                    }
                </Grid>

            </div>
        </div>
    );
}

export default FinanceDashboard;
