import LinearProgress from '@mui/material/LinearProgress';
import {Paper} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DrawerContext from "../../../Context/DrawerContext"
import UserPositionForm from "../../../Components/Forms/Pages/HR/UserPosition/UserPositionForm";
import strings from "../../../localization";
import { editUserPosition, getUserPositions } from "../../../Services/Company/UserPositionService";
import { filterDropdownItem } from '../../../Util/DropdownUtil';
import SnackbarContext from '../../../Context/SnackbarContext';

const formRules = {
    'name': {required: true}
}

const EditUserPosition = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [parents, setParents] = useState([]);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.userPosition.editUserPosition.pageTitle);
    });

    useEffect(() => {
        fetch();
    }, [])

    const fetch = (term = '') =>{

        getUserPositions({term}).then(response => {
            if (!response || !response.ok){
                return;
            }

            setParents(filterDropdownItem(response.data.result, props.data[0]?.id));
        });
        }

    const onSubmit = (data) => {
        setShowLoader(true);

        editUserPosition(data).then(response => {
            setShowLoader(false);

            if(!response || !response.ok){
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress />
            }
            <UserPositionForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                parents={parents} onChangeUserFilter={fetch}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()} />
        </Paper>
    </div>
}

export default EditUserPosition;