import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllAssetManagementLocations() {
    return await request('/api/asset-management-locations/all');
}

export async function getAssetManagmentLocations(data) {
  return await request('/api/asset-management-locations', data);
}

export async function getAllAssetTypes(data) {
    return await request('/api/asset-types/all', data);
}

export async function getAssetTypes(data){
    return await request('/api/asset-types', data);
}

export async function getAllAssets() {
    return await request('/api/assets/all');
}

export async function getAssetList(data) {
    return await request('/api/assets', data);
}

export async function getAsset(id) {
    return await request('/api/assets/' + id);
}

export async function deleteAsset(id) {
    return await request('/api/assets/' + id, {}, HttpMethod.DELETE);
}

export async function editAsset(data) {
    return await request('/api/assets/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addAsset(data) {
    return await request('/api/assets', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        locationId: data.location ? data.location.id : null,
        typeId: data.type ? data.type.id : null,
        userId: data.user ? data.user.id : null,
    }
}
