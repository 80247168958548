import { FormProvider } from 'react-hook-form';
import strings from "../../../../../localization";
import Button from '@mui/material/Button';
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";

const EditInvoiceItemsForm = (props) => {
    const {formRules, form, data, onSubmit, onCancel, errors, currency} = props;

    return (
        <FormProvider {...form}>
            <form className = 'form' action = '#'>
                <TextFieldControl
                    name = 'name'
                    className='mui-shifted-label-input mt-8 margin-shifted-label-first'
                    control = {data}
                    rules = {formRules['name']}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    error = {Boolean(errors.name)}
                    label = {strings.forms.finance.invoice.invoiceItemsTable.name}
                    placeholder = {strings.forms.finance.invoice.invoiceItemsTable.placeholder.name}
                />
                <TextFieldControl
                    name = 'quantity'
                    className='margin-shifted-label mui-shifted-label-input'
                    control = {data}
                    rules = {formRules['quantity']}
                    helperText={errors.quantity && strings.forms.common.thisFieldIsRequired}
                    error = {Boolean(errors.quantity)}
                    label = {strings.forms.finance.invoice.invoiceItemsTable.quantity}
                    type = {'number'}
                    placeholder = {strings.forms.finance.invoice.invoiceItemsTable.placeholder.quantity}
                />
                <TextFieldControl
                    name = 'price'
                    className='margin-shifted-label mui-shifted-label-input'
                    control = {data}
                    rules = {formRules['price']}
                    helperText={errors.price && strings.forms.common.thisFieldIsRequired}
                    error = {Boolean(errors.price)}
                    label = {`${strings.forms.finance.invoice.invoiceItemsTable.price} (${currency})`}
                    type = {'number'}
                    placeholder = {strings.forms.finance.invoice.invoiceItemsTable.placeholder.price}
                />
                <TextFieldControl
                    name = 'discount'
                    className='margin-shifted-label mui-shifted-label-input'
                    control = {data}
                    rules = {formRules['discount']}
                    helperText={errors.discount && strings.forms.common.thisFieldIsRequired}
                    error = {Boolean(errors.discount)}
                    label = {`${strings.forms.finance.invoice.invoiceItemsTable.discount} (%)`}
                    type = {'number'}
                    placeholder = {strings.forms.finance.invoice.invoiceItemsTable.placeholder.discount}
                />
                <TextFieldControl
                    name = 'tax'
                    className='margin-shifted-label-last mui-shifted-label-input'
                    control = {data}
                    rules = {formRules['tax']}
                    helperText={errors.vat && strings.forms.common.thisFieldIsRequired}
                    error = {Boolean(errors.tax)}
                    label = {`${strings.forms.finance.invoice.invoiceItemsTable.vat} (%)`}
                    type = {'number'}
                    placeholder = {strings.forms.finance.invoice.invoiceItemsTable.placeholder.vat}
                />
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.forms.common.save}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.forms.common.cancel}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default EditInvoiceItemsForm;
