import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";

const BusinessYearForm = ({
                         onSubmit,
                         onCancel,
                         data,
                         form,
                         errors,
                         values,
                         setValue,
                         formRules
                     }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='business-year-form' className='form' action="#">

                <TextFieldControl
                    name='year'
                    rules={formRules['year']}
                    control={data}
                    error={Boolean(errors.year)}
                    helperText={errors.year && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.administration.businessYear.year}
                    disabled={isPageStateViewDetails()}
                />

                <DateTimeControl
                    data={data}
                    label={strings.forms.administration.businessYear.startDate}
                    name={'startDate'}
                    value={values['startDate']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />

                <DateTimeControl
                    data={data}
                    label={strings.forms.administration.businessYear.endDate}
                    name={'endDate'}
                    value={values['endDate']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default BusinessYearForm;