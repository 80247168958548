const PER_PAGE = parseInt(process.env.REACT_APP_ROWS_PER_PAGE_DEFAULT);
const PAGE = 1;

export const formatQueryObject = (queries) => {
    let queryObject = {...queries};

    if(parseInt(queryObject?.perPage) === PER_PAGE && parseInt(queryObject?.page) === PAGE){
        delete queryObject.perPage;
        delete queryObject.page;
    }

    for(const [key, value] of Object.entries(queryObject)){
        if(!value) {
            delete queryObject[key];
        }
    }

    return queryObject;
}
