import {Paper} from "@mui/material";
import strings from "../../localization";
import { Button } from "@mui/material";
import { startCompanyFreeTrial } from "../../Services/Company/CompanyService";
import { useDispatch } from "react-redux";
import { getUserFromLocalStorage } from "../../Base/HTTP";
import { setCompany, setUser } from "../../Slices/AuthSlice";
import { setUserToLocalStorage } from "../../Base/OAuth";
import {isCompanyValid} from "../../Util/CompanyUtil";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SnackbarContext from "../../Context/SnackbarContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { breadcrumbsDefault, changeBreadcrumbs } from "../../Slices/BreadcrumbsSlice";



const StartTrial = () => {  
    const auth = useSelector((state) => state.auth)
    const [companyValid, setCompanyValid] = useState(isCompanyValid(auth.company));
    const dispatch = useDispatch();
    const {showMessage} = useContext(SnackbarContext);
    const navigate = useNavigate();
    const company = useSelector((state) => state.auth.company);

    if(company?.accountSettings?.trialUsed){
        navigate('/');
    }

    const start = ()=> { 
        if(!companyValid){
            showMessage(strings.pages.user.startTrial.errorMessage, 'error')
            return;
        }
        startCompanyFreeTrial().then(response => {
            let user = getUserFromLocalStorage();
            user.company = response.data;
            setUserToLocalStorage(user);
            dispatch(setUser(user));
            dispatch(setCompany(response.data));
        });  

        navigate('/');
    }

    useEffect(()=>{
        dispatch(changeBreadcrumbs(breadcrumbsDefault));
    },[])


    return <div className='start-trial-page user-pages-paper centered-flex w-100 h-100'>
        <img src="/images/trial-background.jpg" className="vw-100 vh-100 overflow-hidden"/>
        <Paper className="mb-18">
            <h5 className="mb-12"> {strings.pages.user.startTrial.startTrial}</h5>
            <img src="/images/trial-logo.svg"/>
            <div className="form">
                <div className='submit'>
                            <Button onClick={start} type='submit' variant="contained" color="primary">
                                {strings.pages.user.startTrial.start}
                            </Button>
                        </div>
            </div>      
        </Paper>
    </div>
};
export default StartTrial;
