import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getBusinessYearStartValues(data) {
    return await request('/api/business-year-start-values', data);
}

export async function addBusinessYearStartValue(data) {
    return await request('/api/business-year-start-values', transformRequestData(data), HttpMethod.POST);
}

export async function editBusinessYearStartValue(data) {
    return await request('/api/business-year-start-values/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteBusinessYearStartValue(id) {
    return await request('/api/business-year-start-values/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        bankAccountId: data.bankAccount ? data.bankAccount.id : -1,
    }
}
