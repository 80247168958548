import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getBankAccount(id) {
    return await request('/api/bank-accounts/' + id);
}

export async function getBankAccounts(data) {
    return await request('/api/bank-accounts', data);
}

export async function getAllBankAccounts() {
    return await request('/api/bank-accounts/all');
}

export async function addBackAccount(data) {
    return await request('/api/bank-accounts', transformRequestData(data), HttpMethod.POST);
}

export async function editBackAccount(data) {
    return await request('/api/bank-accounts/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteBankAccount(id) {    
    return await request('/api/bank-accounts/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        bankId: data.bank ? data.bank.id : null,
        currencyId: data.currency ? data.currency.id : null
    }
}