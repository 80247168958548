import {Autocomplete, Box, CircularProgress, FormControl, TextField} from "@mui/material"
import { useCallback, useEffect, useState, } from "react";
import React from "react";
import { Controller } from "react-hook-form";
import strings from "../../../localization";
import { debounce } from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';

const AutoCompleteTextField = (props) => {
    const [value, setValue] = useState(null);
    const [open , setOpen] = useState(false);
    const [data, setData] = useState({options: [], loading: !props.options || props.options.length === 0});

    useEffect(() => {
        if(props.value){
            setValue({[props.name]: props.value[props.name] ? props.value[props.name] : props.value});
        }
    }, [props.value])

    useEffect(() => {
        setData(value => ({loading: false, options: props.options}));
    },[props.options])

    const debouncedSave = useCallback(
        debounce((newValue) =>{
            if(props.onChange){
                props?.onChange(newValue)
            }
        }, 800),
    []);

    const updateValue = (newValue) => {
        if(newValue.length > 2){
            setOpen(true);
            setData(value => ({...value, loading: true}));
            debouncedSave(newValue);
        }else{
            setOpen(false);
        }
    };

    const changeValue = (value) => {
        setValue(value);
        setOpen(false);
        props.setValue(props.name, value);
    }

    const onKeyDown = (e) => {
        if((e.key === 'Enter' || e.keyCode === 13) && e.target.value){
            setValue({[props.name]: e.target.value});
            setOpen(false);
            props.setValue(props.name, {[props.name]: e.target.value});
        }
    }

    const onClose = (e) => {
        if(e.target.value){
            setValue({[props.name]: e.target.value});
            setOpen(false);
            props.setValue(props.name, {[props.name]: e.target.value});
        }
    }

    const AutocompleteComponent = ({props, field, changeValue, updateValue, options, value, onOpen, loading}) => {
        return <Autocomplete {...field}
            loading={loading}
            className={'autocomplete text-autocomplete'}
            disabled={props.disabled}
            size={props.size ? props.size : 'small'}
            options={loading ? [] : options}
            getOptionLabel={(option) => option[props.nameKey] ? option[props.nameKey] : ''}
            onClose = {onClose}
            filterOptions={(option) => option}
            renderInput={params => 
            <TextField
                {...params}
                error={props.error}
                placeholder = {props.placeholder}
                label={props.label}
                onKeyDown={onKeyDown}
                onChange={e => updateValue(e.target.value)}
                helperText={props.helperText}
                inputProps={{...params.inputProps}}
                InputLabelProps = {{shrink: true}}
                />}
            onChange={(event, value) => {
                if (field?.onChange && value) {
                    field.onChange(() => changeValue(value));
                    return
                }
                changeValue(value);
            }}
            open = {open}
            value={value}
            clearIcon={props.showClear && <ClearIcon fontSize="small" />}
            isOptionEqualToValue={(option, value) =>
                option && value && option[props.valueKey || 'name'] === value[props.valueKey || 'name']
            }
            noOptionsText={strings.components.autoComplete.noOptions}
            renderOption = {(props, option) => {
                return (
                    option.name &&
                    <li {...props} key = {`company-list-${option.id}`}>
                      {option.name}
                    </li>
                );
            }}
        />
    }


    return <Box sx={{ minWidth: 120 }} className={'select-box ' + props.className}>
        {   props.control &&
            <FormControl fullWidth>

                <Controller
                    rules={props.rules}
                    name={props.name}
                    control={props.control}
                    render={({field}) => AutocompleteComponent({props, field, changeValue, updateValue, options: data.options, value, loading: data.loading})
                    }
                />
            </FormControl>
        }

        {
            !props.control &&
            AutocompleteComponent({props, field: {}, changeValue, updateValue, options: data.options, value, loading: data.loading})
        }
    </Box>
}   

export default AutoCompleteTextField;

