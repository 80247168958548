import React, {useContext} from "react";
import { FormProvider } from "react-hook-form";
import strings from "../../../../../localization";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import Button from '@mui/material/Button';
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";

const NonWorkingDaysForm = ({
                                onSubmit,
                                onCancel,
                                data,
                                form,
                                errors,
                                values,
                                setValue,
                                formRules
                            }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id="leave-type-form" className='form' action = "#">
                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.nonWorkingDay.name}
                    disabled={isPageStateViewDetails()}
                />
                <DateTimeControl
                    data={data}
                    rules={formRules['fromDate']}
                    form={form}
                    error={Boolean(errors.fromDate)}
                    helperText={errors.fromDate && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.nonWorkingDay.fromDate}
                    name={'fromDate'}
                    value={values['fromDate']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />
                <DateTimeControl
                    data={data}
                    rules={formRules['toDate']}
                    form={form}
                    error={Boolean(errors.toDate)}
                    helperText={errors.toDate && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.nonWorkingDay.toDate}
                    name={'toDate'}
                    value={values['toDate']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='type'
                    rules={formRules['type']}
                    control={data}
                    error={Boolean(errors.type)}
                    helperText={errors.type && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.nonWorkingDay.type}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={ onSubmit }>
                            { strings.forms.common.save }
                        </Button>
                        <Button variant="contained" color="secondary" onClick={ onCancel }>
                            { strings.forms.common.cancel }
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default NonWorkingDaysForm;