import ReferenceType from "../../Constants/DocumentManagement/ReferenceType";
import {getAllEmployees, getUsers} from "../User/UserService";
import {getAllUserPositions} from "../Company/UserPositionService";
import {getAllTeams} from "../HR/TeamService";
import {
    getAllOrganisationUserContracts, getOrganisationUserContractPartList,
} from "../HR/OrganisationUserContractPartService";
import {getPartners} from "../CRM/PartnerService";
import {getInvoices} from "../Finance/InvoiceService";
import {getAllContracts, getContractParts} from "../Finance/ContractPartService";
import {getTransactionCategoryList} from "../Finance/TransactionCategoryService";
import {getAllBankAccounts} from "../Company/BankAccountService";
import {getBusinessYears} from "../Finance/BusinessYearService";
import {getAllRoles} from "../User/RoleService";
import {getAllCompanyDocumentCategories} from "../CompanyDocumentManagement/CompanyDocumentCategoryService";
import {getAllCompanyDocumentTemplates} from "../CompanyDocumentManagement/CompanyDocumentTemplateService";
import {getCompanyDocuments} from "../CompanyDocumentManagement/CompanyDocumentService";
import {getAllProjectCategories, getAllProjects} from "../Project/ProjectService";
import {getAllDocumentCategories} from "../DocumentManagement/DocumentCategoryService";
import {getAllLeaveTypes} from "../HR/LeaveTypeService";
import {getProjectResources} from "../Project/ProjectResourceService";
import {getAdditionalHours} from "../HR/AdditionalHoursService";
import {getAllCompanyDocumentTemplateItems} from "../CompanyDocumentManagement/CompanyDocumentTemplateItemService";
import {getDocumentStatuses} from "../DocumentManagement/DocumentStatusService";
import {getAllBankReports} from "../Finance/BankReportService";
import {getAllCandidateSources, getCandidates} from "../HR/CandidateService";
import {getJobList} from "../HR/JobService";
import {getLeaveList} from "../HR/LeaveService";
import {getLeaveRequestList} from "../HR/LeaveRequestService";
import {getNonWorkingDaysList} from "../HR/NonWorkingDaysService";
import {getCandidateStatus} from "../HR/CandidateStatusService";
import {getOrganisationUnits} from "../HR/OrganisationUnitService";
import {getJobStatus} from "../HR/JobStatusService";
import {getJobContractTypes} from "../HR/JobContractTypeService";
import {getProductServiceList} from "../Product/ProductServiceService";
import {getBusinessYearStartValues} from "../Finance/BusinessYearStartValueService";
import {getTaskCategories} from "../Project/TaskCategoryService";
import {dateToString} from "../../Util/DateUtil";

import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";
import {getAssetList} from "../AssetManagement/AssetService";
import {getAssetCategoryList} from "../AssetManagement/AssetCategoryService";
import {getAssetTypeList} from "../AssetManagement/AssetTypeService";
import {getAssetManagementLocationList} from "../AssetManagement/AssetManagementLocationService";
import {getInventoryList} from "../AssetManagement/InventoryService";
import {getInventoryTypeList} from "../AssetManagement/InventoryTypeService";
import {getInventoryCategoryList} from "../AssetManagement/InventoryCategoryService";
import {getStockChangeList} from "../AssetManagement/StockChangeService";
import {getPartnerContactList} from "../CRM/PartnerContactService";
import {getPipelines} from "../Company/PipelineService";
import {getVendorList} from "../AssetManagement/VendorService";
import { getDealTypes } from "../CRM/DealTypeService";
import { getDealPriorityList } from "../CRM/DealPriorityService";
import { getDeals } from "../CRM/DealService";
import {getUserCompanyAccess} from "../User/UserCompanyAccessService";
import {getAllTransactions} from "../Finance/TransactionService";
import {getProjectTimeLogList} from "../Project/ProjectTimeLogService";
import { getAllTasks } from "../TaskManagement/TaskServices";
import { getDealSources } from "../CRM/DealSourceService";
import { getAllTaskTemplates } from "../TaskManagement/TaskTemplateServices";
import { getPresenceList } from "../HR/PresenceService";

export async function getFileFolderById(id) {
    return await request('/api/file-folders/' + id);
}

export async function getFileFolders(data) {
    return await request('/api/file-folders', data);
}

export async function addFileFolder(data) {
    return await request('/api/file-folders', transformRequestData(data), HttpMethod.POST);
}

export async function editFileFolder(data) {
    return await request('/api/file-folders/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteFileFolder(id) {
    return await request('/api/file-folders/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        parentId: data.parent ? data.parent.id : null
    }
}


export async function getFileReferences({referenceType}) {
    const filters = {}

    switch (referenceType) {
        case ReferenceType.USER:
            return await getUserCompanyAccess(filters).then(response => response.data.result.map(entity => generateBody(entity, {name: entity.user?.fullName, referenceType})))
        case ReferenceType.USER_POSITION:
            return await getAllUserPositions(filters).then(response => response.data.map(position => generateBody(position, {referenceType})))
        case ReferenceType.JOB_STATUS:
            return await getJobStatus(filters).then(response => response.data.result.map(status => generateBody(status, {referenceType})))
        case ReferenceType.JOB_CONTRACT_TYPE:
            return await getJobContractTypes(filters).then(response => response.data.result.map(contract => generateBody(contract, {referenceType})));
        case ReferenceType.TEAM:
            return await getAllTeams(filters).then(response => response.data.map(team => generateBody(team, {referenceType})))
        case ReferenceType.ORGANISATION_USER_CONTRACT:
            return await getAllOrganisationUserContracts(filters).then(response => response.data.map(contract => generateBody(contract, {referenceType})))
        case ReferenceType.PRODUCT_SERVICE:
            return await getProductServiceList(filters).then(response => response.data.result.map(service => generateBody(service, {referenceType})))
        case ReferenceType.PARTNER:
            return await getPartners(filters).then(response => response.data.result.map(partner => generateBody(partner, {referenceType})))
        case ReferenceType.INVOICE:
            return await getInvoices(filters).then(response => response.data.result.map(invoice => generateBody(invoice, {referenceType, name: `${invoice.invoiceNumber} (${dateToString(new Date(invoice.invoiceDate))})`})))
        case ReferenceType.CONTRACT:
            return await getAllContracts(filters).then(response => response.data.map(contract => generateBody(contract, {referenceType})))
        case ReferenceType.TRANSACTION:
            return await getAllTransactions(filters).then(response => response.data.map(entity => generateBody(entity, {name: entity.partnerName, referenceType})))
        case ReferenceType.TRANSACTION_CATEGORY:
            return await getTransactionCategoryList(filters).then(response => response.data.result.map(entity => generateBody(entity, {referenceType})))
        case ReferenceType.BANK_ACCOUNT:
            return await getAllBankAccounts(filters).then(response => response.data.map(entity => generateBody(entity, {referenceType})))
        case ReferenceType.BUSINESS_YEAR:
            return await getBusinessYears(filters).then(response => response.data.result.map(businessYear => generateBody(businessYear, {name: businessYear.year, referenceType})))
        case ReferenceType.ROLE:
            return await getAllRoles(filters).then(response => response.data.entities.map(role => generateBody(role, {referenceType})))
        case ReferenceType.COMPANY_DOCUMENT_CATEGORY:
            return await getAllCompanyDocumentCategories(filters).then(response => response.data.map(category => generateBody(category, {referenceType})))
        case ReferenceType.COMPANY_DOCUMENT_TEMPLATE:
            return await getAllCompanyDocumentTemplates(filters).then(response => response.data.map(template => generateBody(template, {referenceType})))
        case ReferenceType.COMPANY_DOCUMENT:
            return await getCompanyDocuments(filters).then(response => response.data.result.map(document => generateBody(document, {referenceType})))
        case ReferenceType.PROJECT:
            return await getAllProjects(filters).then(response => response.data.map(project => generateBody(project, {referenceType})))
        case ReferenceType.PROJECT_CATEGORY:
            return await getAllProjectCategories(filters).then(response => response.data.map(category => generateBody(category, {referenceType})))
        case ReferenceType.DOCUMENT_CATEGORY:
            return await getAllDocumentCategories(filters).then(response => response.data.map(category => generateBody(category, {referenceType})))
        case ReferenceType.CONTRACT_PART:
            return await getContractParts(filters).then(response => response.data.result.map(entity => generateBody(entity, {name: `${dateToString(new Date(entity.paymentDate))} (${entity.contract?.name})`, referenceType})))
        case ReferenceType.LEAVE_TYPE:
            return await getAllLeaveTypes(filters).then(response => response.data.map(status => generateBody(status, {referenceType})))
        case ReferenceType.PROJECT_RESOURCE:
            return await getProjectResources(filters).then(response => response.data.result.map(resource => generateBody(resource, {referenceType})))
        case ReferenceType.ADDITIONAL_HOURS:
            return await getAdditionalHours(filters).then(response => response.data.result.map(entity => generateBody(entity, {referenceType, name: `${dateToString(new Date(entity.startDate))} - ${dateToString(new Date(entity.endDate))} (${entity.hours}h)`})))
        case ReferenceType.COMPANY_DOCUMENT_TEMPLATE_ITEM:
            return await getAllCompanyDocumentTemplateItems(filters).then(response => response.data.map(entity => generateBody(entity, {referenceType})))
        case ReferenceType.DOCUMENT_STATUS:
            return await getDocumentStatuses(filters).then(response => response.data.result.map(status => generateBody(status, {referenceType})))
        case ReferenceType.BANK_REPORT:
            return await getAllBankReports(filters).then(response => response.data.map(entity => generateBody(entity, {referenceType, name: `${entity.bank?.name} (${entity.bankAccount?.account})`})))
        case ReferenceType.CANDIDATE:
            return await getCandidates(filters).then(response => response.data.result.map(entity => generateBody(entity, {referenceType, name: `${entity.firstName} ${entity.lastName}`})))
        case ReferenceType.CANDIDATE_SOURCE:
            return await getAllCandidateSources(filters).then(response => response.data.map(status => generateBody(status, {referenceType})))
        case ReferenceType.JOB:
            return await getJobList(filters).then(response => response.data.result.map(status => generateBody(status, {referenceType})))
        case ReferenceType.LEAVE:
            return await getLeaveList(filters).then(response => response.data.result.map(entity => generateBody(entity, {name: `(${entity.employeeUser?.fullName}) ${dateToString(new Date(entity.fromDate))} - ${dateToString(new Date(entity.toDate))}`,referenceType})))
        case ReferenceType.LEAVE_REQUEST:
            return await getLeaveRequestList(filters).then(response => response.data.result.map(entity => generateBody(entity, {name: `(${entity.user?.fullName}) ${dateToString(new Date(entity.fromDate))} - ${dateToString(new Date(entity.toDate))}`,referenceType})))
        case ReferenceType.NON_WORKING_DAY:
            return await getNonWorkingDaysList(filters).then(response => response.data.result.map(day => generateBody(day, {referenceType})))
        case ReferenceType.ORGANISATION_USER_CONTRACT_PART:
            return await getOrganisationUserContractPartList(filters).then(response => response.data.result.map(entity => generateBody(entity, {name: `${dateToString(new Date(entity.paymentDate))} (${entity.organisationUserContract?.name})`, referenceType})))
        case ReferenceType.EMPLOYEE:
            return await getUserCompanyAccess(filters).then(response => response.data.result.map(entity => generateBody(entity, {name: entity.user?.fullName, referenceType: ReferenceType.USER})))
        case ReferenceType.CANDIDATE_STATUS:
            return await getCandidateStatus(filters).then(response => response.data.result.map(status => generateBody(status, {referenceType})))
        case ReferenceType.ORGANISATION_UNIT:
            return await getOrganisationUnits(filters).then(response => response.data.result.map(unit => generateBody(unit, {referenceType})))
        case ReferenceType.BUSINESS_YEAR_START_VALUE:
            return await getBusinessYearStartValues(filters).then(response => response.data.result.map(entity => generateBody(entity, {name: entity.businessYear?.year, referenceType})))
        case ReferenceType.TASK_CATEGORY:
            return await getTaskCategories(filters).then(response => response.data.result.map(entity => generateBody(entity, {referenceType})))
        case ReferenceType.ASSET:
            return await getAssetList(filters).then(response => response.data.result.map(entity => generateBody(entity, {referenceType, name: `${entity.serialNumber} (${entity.type?.name})`})))
        case ReferenceType.ASSET_CATEGORY:
            return await getAssetCategoryList(filters).then(response => response.data.result.map(entity => generateBody(entity, {referenceType})))
        case ReferenceType.ASSET_TYPE:
            return await getAssetTypeList(filters).then(response => response.data.result.map(entity => generateBody(entity, {referenceType})))
        case ReferenceType.ASSET_MANAGEMENT_LOCATION:
            return await getAssetManagementLocationList(filters).then(response => response.data.result.map(entity => generateBody(entity, {referenceType})))
        case ReferenceType.INVETORY:
            return await getInventoryList(filters).then(response => response.data.result.map(entity => generateBody(entity, {referenceType, name: `${entity.value} (${entity.type?.name})`})))
        case ReferenceType.INVETORY_TYPE:
            return await getInventoryTypeList(filters).then(response => response.data.result.map(entity => generateBody(entity, {referenceType})))
        case ReferenceType.INVETORY_CATEGORY:
            return await getInventoryCategoryList(filters).then(response => response.data.result.map(entity => generateBody(entity, {referenceType})))
        case ReferenceType.STOCK_CHANGE:
            return await getStockChangeList(filters).then(response => response.data.result.map(entity => generateBody(entity, {name: `${entity.price} (${entity.type})`, referenceType})))
        case ReferenceType.PARTNER_CONTACT:
            return await getPartnerContactList(filters).then(response => response.data.result.map(entity => generateBody(entity, {referenceType, name: `${entity.firstName} ${entity.lastName}`})))
        case ReferenceType.PIPE_LINE:
            return await getPipelines(filters).then(response => response.data.result.map(entity => generateBody(entity, {referenceType})))
        case ReferenceType.VENDOR:
            return await getVendorList(filters).then(response => response.data.result.map(entity => generateBody(entity, {referenceType})))
        case ReferenceType.DEAL_TYPE:
            return await getDealTypes(filters).then(response => response.data.result.map(dealType => generateBody(dealType, {referenceType})))
        case ReferenceType.DEAL_PRIORITY:
            return await getDealPriorityList(filters).then(response => response.data.result.map(dealPriority => generateBody(dealPriority, {referenceType})))
        case ReferenceType.DEAL:
            return await getDeals(filters).then(response => response.data.result.map(deal => generateBody(deal, {referenceType})))
        case ReferenceType.DEAL_SOURCE:
            return await getDealSources(filters).then(response => response.data.result.map(dealSource => generateBody(dealSource, {referenceType})))
        case ReferenceType.TASK:
            return await getAllTasks(filters).then(response => response.data.map(task => generateBody(task, {referenceType})))
        case ReferenceType.TASK_TEMPLATE:
            return await getAllTaskTemplates(filters).then(response => response.data.map(taskTemplate => generateBody(taskTemplate, {referenceType})))
        case ReferenceType.PRESENCE:
            return await getPresenceList(filters).then(response => response.data.result.map(presence => generateBody(presence, {referenceType})))
        default:
            break
    }
}

const generateBody = (entity, {referenceType, name}) => {
    return {id: `${referenceType}-${entity.id}`, name: name? name: entity.name || entity.id, referenceType, entity}
}