import React from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch, useSelector} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import PageState from "../../../Constants/Base/PageState";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import {deleteTeam, getTeamList} from "../../../Services/HR/TeamService";
import AddTeam from "./AddTeam";
import EditTeam from "./EditTeam";
import {useNavigate, useSearchParams} from "react-router-dom";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {
    renderColumnTeamLogo,
} from "../../../Components/DataGrid/ValueCellRender";
import {hasPermission} from '../../../Util/PermissionUtil';
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [
    {
        field: 'teamLogo',  
        headerName: strings.pages.hr.team.teamList.image, 
        width: 100,
        renderCell: (params) => renderColumnTeamLogo(params.row),
        align: 'center'
    },
    {field: 'name', headerName: strings.pages.hr.team.teamList.name, width: 250},
    {field: 'description', headerName: strings.pages.hr.team.teamList.description, width: 250},
    {
        field: 'organisationUnit', headerName: strings.pages.hr.team.teamList.organisationUnit,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 200
    }
];

const TeamList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const auth = useSelector((state) => state.auth);
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.TEAM;
    const permissionGroup = AppPermissions.TEAM.GROUP;

    const filters = []

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    const additionalMenuItems = [
        {
            handleClick: (id, item) => handleSettings(id, item),
            text: strings.pages.hr.team.teamList.teamSettings,
            src: '/images/table-page/settings.svg'
        },
    ]

    useEffect(() => {
        
        dispatch(changeBreadcrumbs({
            title: strings.pages.hr.team.teamList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.pages.hr.team.teamList.pageTitle},
            ],        
        }));
        dispatch(changePageSizeState(PageSizeState.SHORT));

        setColumns([...tableDescription]);

        return () => {
            setTableData({})
        }
    }, []);

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter]);

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        })

        getTeamList({
            ...filter
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    const handleSettings = (id, team) => {
        navigate('/team-settings/' + id)
    }

    const hasEditPermission = () => {
        return hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].EDIT, auth.permissions);
    }

    return <TablePageContext.Provider value={value}>
        <TablePage 
            onFinish={() => onFinish()} 
            deleteItem={deleteTeam} 
            tableDescription={columns}
            tableData={tableData} 
            filter={filter} 
            filters={filters}
            referenceType={referenceType}
            permissionGroup={permissionGroup}
            additionalMenuItems={hasEditPermission() && additionalMenuItems}
            tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
            editPage={<EditTeam data={selectedItems} onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
            addPage={<AddTeam onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
            addButtonText={strings.components.tablePage.addButton.team}/>
    </TablePageContext.Provider>;
}

export default TeamList;
