import React, {useContext, useState} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import TablePageContext from "../../../../Context/TablePageContext";
import PageState from "../../../../Constants/Base/PageState";
import QuillEditor from "../../../Controls/Inputs/QuillEditor";

const AttributeForm = ({
                                onSubmit,
                                onCancel,
                                data,
                                taxesOptions,
                                form,
                                errors,
                                currencies,
                                units,
                                values,
                                setValue,
                                formRules,
                                control,
                                watch,
                                productServiceCategory,
                            }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const [editorData, setEditorData] = useState('');

    return (
        <FormProvider {...form}>
            <form id='product-service-form' className='form' action="#">

                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.productService.productServiceForm.name}
                    disabled={isPageStateViewDetails()}
                />

                <QuillEditor
                    name='description'
                    className='large-editor mh-250'
                    form={form}
                    value={editorData}
                    onChange={setEditorData}
                    placeholder={'Description'}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default AttributeForm;
