import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllInventories() {
    return await request('/api/inventories/all');
}

export async function getInventories(data) {
  return await request('/api/inventories', data);
}

export async function getAllStockChanges() {
    return await request('/api/stock-changes/all');
}

export async function getStockChangeList(data) {
    return await request('/api/stock-changes', data);
}

export async function getStockChange(id) {
    return await request('/api/stock-changes/' + id);
}

export async function deleteStockChange(id) {
    return await request('/api/stock-changes/' + id, {}, HttpMethod.DELETE);
}

export async function editStockChange(data) {
    return await request('/api/stock-changes/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addStockChange(data) {
    return await request('/api/stock-changes', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        inventoryId: data.inventory ? data.inventory.id : null,
    }
}
