import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import PartnerContactForm from "../../../Components/Forms/Pages/CRM/PartnerContact/PartnerContactForm";
import DrawerContext from "../../../Context/DrawerContext";
import { editPartnerContact } from "../../../Services/CRM/PartnerContactService";
import LinearProgress from '@mui/material/LinearProgress';
import ValidationPatters from "../../../Constants/Base/ValidationPatters";
import SnackbarContext from "../../../Context/SnackbarContext";
import { getPartners } from "../../../Services/CRM/PartnerService";

const formRules = {
    'partner': {required: true}
}

const EditPartnerContact = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [partners, setPartners] = useState(0);

    useEffect(() => {
        setDrawerTitle(strings.pages.crm.partnerContact.editPartnerContact.pageTitle);
    });

    useEffect(() => {
        fetchPartners(); 
    },[])

    const fetchPartners = (term = '') => {
        getPartners({
            term
        }).then(response =>{
            if (!response || !response.ok){
                return
            }
            setPartners(response.data.result)
        });
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        editPartnerContact(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <PartnerContactForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                partners = {partners}
                errors={errors} data={data} form={form} onChangePartnersFilter={fetchPartners}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditPartnerContact;