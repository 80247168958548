import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import {Paper} from "@mui/material";
import DealForm from "../../../Components/Forms/Pages/CRM/Deal/DealForm";
import { getCurrencies } from "../../../Util/SiteDataUtil";
import { getPartners } from "../../../Services/CRM/PartnerService";
import { getPipelines } from "../../../Services/Company/PipelineService";
import { getPipelineStages } from "../../../Services/Company/PipelineStageService";
import { getDealTypes } from "../../../Services/CRM/DealTypeService";
import { getDealPriorityList } from "../../../Services/CRM/DealPriorityService";
import { editDeal } from "../../../Services/CRM/DealService";
import { useSelector } from "react-redux";
import SnackbarContext from "../../../Context/SnackbarContext";
import { getDealSources } from '../../../Services/CRM/DealSourceService';
import PartnerType from '../../../Constants/CRM/PartnerType';

const formRules = {
    'name': {required: true},
}

const EditDeal = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const siteData = useSelector((state) => state.siteData);
    const [partners, setPartners] = useState(0);
    const [pipeLines, setPipeLines] = useState(0);
    const [pipeLineStages, setPipeLineStages] = useState(0);
    const [dealTypes, setDealTypes] = useState(0);
    const [dealPriority, setDealPriority] = useState(0);
    const [currencies, setCurrency] = useState(0);
    const [dealSources, setDealSources] = useState([]);

    useEffect(() => {
        setDrawerTitle(strings.pages.crm.deal.editDeal.pageTitle);
    });

    useEffect(() => {
        fetch();
        fetchPartners();
        fetchDealTypes();
        fetchDealPriority();
        fetchDealSources();
    }, [])

    const fetch = () => {
        getPipelines().then(response =>{
            if (!response || !response.ok){
                return
            }
            setPipeLines(response.data.result)
        });
        
        if(props.data[0].pipeLine){
            getPipelineStages({pipelineId: props.data[0].pipeLine.id}).then(response => {
                if (!response || !response.ok){
                    return
                }
                setPipeLineStages(response.data)
                
            })
        }

        setCurrency(getCurrencies(siteData));
    }

    const fetchPartners = (term = '') => {
        getPartners({
            partnerType: PartnerType.CUSOMTER,
            term
        }).then(response =>{
            if (!response || !response.ok){
                return
            }
            setPartners(response.data.result)
        });
    }

    const fetchDealTypes = (term = '') => {
        getDealTypes({term}).then(response =>{
            if (!response || !response.ok){
                return
            }
            setDealTypes(response.data.result)
        });
    }

    const fetchDealPriority = (term = '') => {
        getDealPriorityList({term}).then(response =>{
            if (!response || !response.ok){
                return
            }
            setDealPriority(response.data.result)
        });
    }

    const fetchDealSources = (term = '') => {
        getDealSources({term}).then(response => {
            if (!response || !response.ok){
                setDealSources([]);
            }
            setDealSources(response.data.result);
        });
    }

    const onSubmit = (data) => {
        setShowLoader(true);
        
        editDeal(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok){
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    const onPipeLineChange = (newValue) => {
        
        let values = getValues();
        
        if(newValue){
            getPipelineStages({pipelineId: newValue?.id}).then(response => {
                if(!response.ok || !response){
                    return;
                }
                setPipeLineStages(response.data)
            })
            setValue('pipeLine',newValue)
        }else{
            setValue('pipeLine',null)
            setValue('pipeLineStage',null)
            setPipeLineStages([])
        }

        if(newValue !== values['pipeLine']){
            setValue('pipeLineStage',null)
        }
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress />
            }
            <DealForm
                control={control} formRules={formRules}
                values={getValues()} setValue={setValue}
                data={data} errors={errors} form={form}
                pipelines={pipeLines} pipeLineStages={pipeLineStages}
                dealTypes={dealTypes} dealPriority={dealPriority}
                currencies={currencies} partners={partners} dealSources={dealSources}
                onPipeLineChange={(newValue)=> onPipeLineChange(newValue)}
                onChangePartnersFilter={fetchPartners} onChangeDealTypesFilter={fetchDealTypes}
                onChangeDealPriorityFilter={fetchDealPriority} onChangeDealSourcesFilter={fetchDealSources}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}
            />
        </Paper>
    </div>
}

export default EditDeal;