import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import CheckBoxControl from "../../../../Controls/Inputs/CheckBoxControl";
import {getContractTypes} from '../../../../../Constants/Finance/ContractType';
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import {getPaymentMonths} from "../../../../../Constants/HR/PaymentMonth";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";

const StornoInvoiceForm = ({
                               onSubmit,
                               onCancel,
                               data,
                               form,
                               formRules,
                               errors,
                           }) => {

    return (
        <FormProvider {...form}>
            <form id="storno-invoice-form" className='form' action="#">

                <TextFieldControl
                    name='stornoNumber'
                    control={data}
                    label={strings.forms.finance.invoice.stornoInvoiceForm.stornoNumber}
                />

                <TextFieldControl
                    name='stornoComment'
                    rules={formRules['stornoComment']}
                    control={data}
                    multiline={true}
                    rows={4}
                    maxRows={4}
                    error={Boolean(errors.stornoComment)}
                    helperText={errors.stornoComment && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.invoice.stornoInvoiceForm.stornoComment}
                />

                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.forms.common.save}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.forms.common.cancel}
                    </Button>
                </div>

            </form>
        </FormProvider>
    );
}

export default StornoInvoiceForm;