import React from 'react'
import { useState, useEffect } from 'react';
import {Grid, IconButton} from "@mui/material";
import strings from '../../localization';

const DrawerWrapper = (props) => {
    const [title, setTitle] = useState()
    const { children } = props;

    useEffect(() => {
        
        if(!props.title){
            setTitle("")
            return;
        }

        if(!props.viewDetails){
            setTitle(props.title);
            return;
        }     
        
        let noEditTitle = (props.title.replace(strings.common.edit, ""));
        noEditTitle = noEditTitle.trim(); 
        const capitalizedTitle = noEditTitle.charAt(0).toUpperCase() + noEditTitle.slice(1);
        setTitle(capitalizedTitle)
      },[props.title])
    
    return <Grid className='drawer-wrapper'>
        <div className='drawer-wrapper-header'>
            <div className='drawer-wrapper-button-header'>
                <div className='cross-button' onClick={ () => props.onBack() }>
                    <img src='/images/drawer-wrapper-cross.svg' loading='lazy'/>
                </div>
                { props.addButton &&
                    <div className='button'>
                        <IconButton className='add-button' onClick={props.onClick}>
                            <img src='/images/table-page/add-cross-blue.svg'/>
                            {props.buttonText}
                        </IconButton>
                    </div>
                }
            </div>
            <h2>{title}</h2>
        </div>
        <div className='drawer-wrapper-content'>
            { children }
        </div>
    </Grid>
}

export default DrawerWrapper;
