import React, { Fragment } from "react";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import strings from "../../localization";
import DashboardTab from "../../Constants/Company/DashboardTab";
import {Paper, Tab, Tabs} from "@mui/material";
import FinanceDashboard from "./FinanceDashboard";
import { hasPermission } from "../../Util/PermissionUtil";
import AppPermissions from "../../Constants/Permissions/Permissions";
import HRDashboard from "./HRDashboard";

const Dashboard = () => {

    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(DashboardTab.FINANCE);
    const auth = useSelector((state) => state.auth);
    const permissionGroup = AppPermissions.DASHBOARD.GROUP;

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
    }, [])

    return (
        hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].VIEW, auth.permissions) &&
        <div id="dashboard">
            <div id='company-settings'>
                <div className='tab-container'>
                    <Paper className='paper'>
                        <Tabs variant="scrollable" value={ activeTab } onChange={(e, tab) => setActiveTab(tab)}>
                            <Tab className="left" label={strings.pages.statistic.dashboard.finance}/>
                            <Tab className="right" label={strings.pages.statistic.dashboard.hr}/>
                        </Tabs>
                    </Paper>
                </div>

                {
                    activeTab === DashboardTab.FINANCE &&
                    <FinanceDashboard/>
                }
                {
                    activeTab === DashboardTab.HR &&
                    <HRDashboard/>
                }
            </div>
        </div>
    );
}

export default Dashboard;
