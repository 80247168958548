import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import {Controller} from "react-hook-form";
import React, {useContext} from "react";
import { useEffect, useRef, useMemo } from 'react';
import AutoLinks from 'quill-auto-links';
import strings from "../../../localization";
import SnackbarContext from "../../../Context/SnackbarContext";

const icons = ReactQuill.Quill.import('ui/icons');
icons['bold'] = '<img src ="/images/editor-bold.svg" />';
icons['italic'] = '<img src ="/images/editor-italic.svg" />';
icons['underline'] = '<img src ="/images/editor-underline.svg" />';
icons['strike'] = '<img src ="/images/editor-strikethrough.svg" />';
icons['color'] = '<img src = "/images/editor-monospace.svg" />';
icons['list']['ordered'] = '<img src = "/images/editor-list-ordered.svg" />';
icons['list']['bullet'] = '<img src = "/images/editor-list-unordered.svg" />';
icons['indent']['+1'] = '<img src = "/images/editor-list-indent.svg" />';
icons['indent']['-1'] = '<img src = "/images/editor-list-outdent.svg" />';
icons['align'][''] = '<img src = "/images/editor-textalign-left.svg" />';
icons['align']['center'] = '<img src = "/images/editor-textalign-center.svg" />';
icons['align']['justify'] = '<img src = "/images/editor-textalign-justify.svg" />';
icons['align']['right'] = '<img src = "/images/editor-textalign-right.svg" />';
icons['link'] = '<img src = "/images/editor-link.svg" />';
icons['blockquote'] = '<img src = "/images/editor-quote.svg" />';
icons['image'] = '<img src = "/images/editor-image.svg" />';
icons['undo'] = '<img src = "/images/editor-undo.svg" />';
icons['redo'] = '<img src = "/images/editor-redo.svg" />';

ReactQuill.Quill.register('modules/autoLinks', AutoLinks);

const QuillEditor = ({
    name='',
    value = '',
    onChange = ()=>{},
    defaultValue = '',
    disabled = false,
    theme = QuillThemes.snow,
    className = 'mh-250',
    form,
    placeholder='',
    })=>{

    const editor = useRef();
    const { showMessage } = useContext(SnackbarContext);
    const maxFileSize = 1024*1024;

    useEffect(()=>{
        onChange(defaultValue);
    },[])

    const defaultModules = useMemo(() => ({
        autoLinks: true,
        history: {
            delay: 1000,
            maxStack: 100,
            userOnly: true
        },
        toolbar: {
            container: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike", {color: []}],
                [{ 'size': ['small', false, 'large', 'huge'] }, {font: []}],
                [{ list:  "bullet" }, { list:  "ordered" }, { indent:  "-1" }, { indent:  "+1" }, { align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ["link", "blockquote", "image"],
                ["undo", "redo"],
            ],
            handlers: {
                'undo': () => {
                    editor.current.getEditor().history.undo();
                },
                'redo': () => {
                    editor.current.getEditor().history.redo();
                }
            },
        }
    }),[]);

    const largeFileError = () =>{
        showMessage(strings.error.File[ 'file-too-large'], 'error');
        return;
    }

    if(form){
        return <Controller
            name={name}
            control={form.control}
            render={({field}) =>
                <ReactQuill {...field}
                            className={className}
                            readOnly={disabled}
                            value={field.value}
                            onChange={(e) => e.length > maxFileSize ? largeFileError() : field.onChange(e)}
                            theme={theme}
                            modules={defaultModules}
                            placeholder={placeholder}
                            ref={editor}

                />}
        />
    }
        return <ReactQuill
            className={className}
            readOnly={disabled}
            value={value}
            onChange={(e) => e.length > maxFileSize ? largeFileError() : onChange(e)}
            theme={theme}
            modules={defaultModules}

        />

}

export default QuillEditor;

//Takes the control value that is passed into the editor
export const isEmpty = (value) =>{
    return !value || value === '<p><br></p>';
}

export const QuillThemes = {
    snow: 'snow',
    bubble: 'bubble',
}
