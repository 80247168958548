import React from "react";
import {Button} from "@mui/material";
import AvatarEditor from 'react-avatar-editor';
import {Slider} from "@mui/material";

const AvatarProfileControl = ({ imageSrc, onCrop, setEditorRef, scaleValue, onScaleChange, disableSave, setChanged, sliderValue }) => {

    const handleDrag = () => {
        if(imageSrc) {
            setChanged(true);
        }
    }

    return (
        <div className='centered-flex-column'>
            <div>
                <AvatarEditor
                    className="avatar-editor"
                    image = { imageSrc }
                    border = { 30 } 
                    scale = { scaleValue }
                    ref = { setEditorRef }
                    color={[238,241,243]} 
                    onMouseMove = {handleDrag}
                />
                <div className="w-100 mb-12 mt-2">
                    <Slider min={1} max={10} step={1} onChange={imageSrc && onScaleChange} value = {sliderValue} />
                </div>
            </div>
            
            <div className="d-flex w-100">
                <Button variant="contained" color="primary" className="rounded-button w-100" onClick={onCrop} disabled = {disableSave} >Save</Button>
            </div>
        </div>
    );
}

export default AvatarProfileControl;

