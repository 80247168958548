import React, {useCallback, useContext, useEffect, useState} from "react";
import strings from '../../localization';
import {Grid, Paper} from "@mui/material";
import {changeUserProfileImage, getUserProfileImage} from "../../Services/User/UserService";
import AvatarProfileControl from "../../Components/Controls/AvatarProfileControl";
import FileUploadComponent from "../../Components/Controls/FileUploadComponent";
import {toBase64} from "../../Util/ImageUtil";
import SnackbarContext from "../../Context/SnackbarContext";
import { ImageFormats } from "../../Constants/FileFormat";
import {useDispatch, useSelector} from "react-redux";
import { changeImages } from "../../Slices/NavigationSlice";

const ChangeProfileImage = (props) => {

    const dispatch = useDispatch();
    const [userProfileImage, setUserProfileImage] = useState()
    const [editor, setEditor] = useState()
    const [loading, setLoading] = useState(false);
    const {showMessage} = useContext(SnackbarContext);
    const [scaleValue, setScaleValue] = useState(1)
    const user = useSelector((state) => state.auth.user)
    const [files, setFiles] = useState([]);
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        fetchData();
    })

    const uploadProfileImage = async (files) => {
        setLoading(true);
        const userProfileImage = await toBase64(files[0]);
        changeUserProfileImage({ userProfileImage: userProfileImage }).then(response =>{
            setUserProfileImage(response.data.user.userProfileImage);
            showMessage(strings.commonMessages.editingSuccessful, 'success');
            dispatch(changeImages({}));
            setChanged(false);
            setScaleValue(1);
            setFiles([]);
        }).finally(()=> setLoading(false));
    }

    const fetchData = useCallback(() => {
        getUserProfileImage(user.id).then(response => {
            setUserProfileImage(response.data.userProfileImage)
        });
    })

    const onCrop = () => {
        if(!userProfileImage){
            showMessage(strings.commonMessages.errorEditing, 'error');
        }else{
            const img = editor.getImage().toDataURL();
                let userProfileImage;
                fetch(img)
                    .then(res => res.blob())
                    .then(blob => (userProfileImage = window.URL.createObjectURL(blob)));
                
                changeUserProfileImage({ userProfileImage: img }).then(response =>{
                    setUserProfileImage(response.data.user.userProfileImage)
                    setScaleValue(1)
                    showMessage(strings.commonMessages.editingSuccessful, 'success');
                    setChanged(false);
                    setFiles([]);
                    dispatch(changeImages({}));
                });
        }
    }

    const onScaleChange = (scaleValueEvent) => {
        const scaleValue = parseFloat(scaleValueEvent.target.value);
        setScaleValue(scaleValue)
        setChanged(true);
    }

        return(
            <Grid id='page' item md = { 12 } justifyContent="center" container
                  alignItems="center">

                <Grid item xs={5}>
                    <Paper className='paper upload-logo-container rounded-2'>
                        <FileUploadComponent upload= { uploadProfileImage } formats={ImageFormats} loading={loading} files = {files} setFiles = {setFiles} />
                        <AvatarProfileControl
                            imageSrc={ userProfileImage }
                            setEditorRef={setEditor}
                            onCrop={onCrop}
                            scaleValue={scaleValue}
                            onScaleChange={onScaleChange}
                            disableSave = {!changed}
                            setChanged = {setChanged}
                            sliderValue = {scaleValue}
                            />
                    </Paper>
                </Grid>

            </Grid>
        );
}


export default ChangeProfileImage;
