import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import strings from "../../../localization";
import {useLocation, useNavigate} from "react-router-dom";
import {getJobCandidates, updateCandidateStage} from "../../../Services/HR/CandidateService";
import {Card, Grid, IconButton} from "@mui/material";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {reorder,reorderDoubleList,sortByColumnOrder} from "../../../Util/DnDUtil";
import { changeBreadcrumbs } from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import { getPipelineStageTypeClass, getPipelineStageTypeString } from "../../../Constants/Company/PaymentStageType";
import LoaderContext from "../../../Context/LoaderContext";
import { useContext } from "react";

const JobCandidates = (params) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [sortedCandidates, setSortedCandidates] = useState({});
    const {loading, setLoading} = useContext(LoaderContext);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.hr.job.jobCandidates.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.pages.hr.job.jobList.pageTitle},
                {label: strings.pages.hr.job.jobCandidates.pageTitle},
            ],        
        }));
        fetch();

        return () => {
            setData({})
            setSortedCandidates({})
        }
    }, [])

    const fetch = () => {
        setLoading(true);
        getJobCandidates({
            jobId: location.state.jobId
        }).then(response => {
            setLoading(false);
            if(!response || !response.ok) {
                setData({});
                return;
            }
            setData(response.data);
            setSortedCandidates(sortByColumnOrder(response.data.candidates))
        });
    }

    const renderStages = () => {

        let result = [];

        if(!data || !data.stages) {
            return result;
        }

        for(let stage of data.stages) {
            result.push(
                <Droppable key={'stage-' + stage.id}  droppableId={'' + stage.id} >
                    {(provided, snapshot) => (
                        <div
                            className='stage-container'
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <div style={{backgroundColor: stage.color ? stage.color : 'black'}} className={'header'}>
                            <div className="title">{stage.name}</div>
                                <div className={`type ${getPipelineStageTypeClass(stage.type)}`}>
                                    {getPipelineStageTypeString(stage.type)}
                                </div>
                            </div>
                            <div className='content'>
                                {renderStage(stage)}
                            </div>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            )
        }


        return result;
    }

    const renderStage = (stage) => {

        let result = [];
        if(sortedCandidates.length > 0){
            for(let candidate of sortedCandidates) {
                if(!candidate.pipelineStage || stage.id !== candidate.pipelineStage.id) {
                    continue;
                }

                result.push(
                    <Draggable
                        key={"candidate-" + candidate.id}
                        draggableId={'' + candidate.id}
                        index={result.length}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <Card className='stage-card'>
                                    <h3>{candidate.firstName} {candidate.lastName}</h3>
                                    <div></div>
                                    <a target='_blank' href={'mailto:' + candidate.email}>{candidate.email}</a>
                                    <div className="control"> 
                                        <IconButton className='card-button' onClick={() => handleView(candidate)}>
                                            <img src="/images/table-page/view-blue.svg" />
                                        </IconButton>
                                    </div>
                                </Card>
                            </div>
                        )}
                    </Draggable>
                )
            }
        }

        return result;
    }

    const onDragEnd = ({source, destination}) => {
        if(!destination || !source)return;

        const toStage = data.stages.find(s => s.id == destination.droppableId);
        const fromStage = data.stages.find(s => s.id == source.droppableId);

        let draggableCandidates = [];
        for(let candidate of sortedCandidates){
            if(candidate.pipelineStage.id == source.droppableId){
                draggableCandidates.push(candidate);
            }
        }

        if(source.droppableId === destination.droppableId){
            let candidates = reorder(draggableCandidates, source.index, destination.index);

            let compareCandidates = sortedCandidates.filter(x => !candidates.includes(x));

            let modifiedCandidates = [...candidates, ...compareCandidates];

            updateCandidateStage({
                stageId: source.droppableId,
                candidates
            }).then(response => {
                if (!response || !response.ok){
                    return;
                }
                fetch();
            });
            setSortedCandidates(modifiedCandidates);

            return;
        }

        const sourceItems = [...draggableCandidates];

        const destItems = sortedCandidates.filter(x => x.pipelineStage.id == destination.droppableId);

        const otherItems = sortedCandidates.filter(x => x.pipelineStage.id != toStage.id && x.pipelineStage.id != fromStage.id);

        let destinationList = reorderDoubleList(sourceItems, destItems, source.index, destination.index);
        destinationList.forEach(item => item.pipelineStage = toStage);

        setSortedCandidates([...sourceItems, ...destItems, ...otherItems]);

        updateCandidateStage({
            stageId: destination.droppableId,
            candidates: destinationList
        }).then(response => {
            if(!response || !response.ok){
                return;
            }
            fetch();
        });
    }

    const handleView = (candidate) => {
        navigate(`/candidate-details/${candidate.id}/record`, {state: {candidate: candidate}})
    }

    return <React.Fragment>
        <div className='job-candidates-header clear-paper rounded-2 mx-3 p-3 mb-6'>
            <Grid container>
                <Grid item xs={2}>
                    {
                        data && data.job &&
                        <div className={'job-details'}>
                            <h1>{data.job.name}</h1>

                            <p>{strings.pages.hr.job.jobCandidates.jobType}</p>
                            <h3>{data.job.isRemote? strings.pages.hr.job.jobCandidates.remote : strings.pages.hr.job.jobCandidates.notRemote }</h3>

                            <p>{strings.pages.hr.job.jobCandidates.salaryMin}</p>
                            <h3>{data.job.minGrossSalary}</h3>
                        </div>
                    }
                </Grid>
                <Grid item xs={10} className='d-flex align-end'>
                    {
                        data && data.job &&
                        <div className={'job-details'}>
                            <p>{strings.pages.hr.job.jobCandidates.salaryMax}</p>
                            <h3>{data.job.maxGrossSalary}</h3>
                        </div>
                    }
                </Grid>
            </Grid>
        </div>

        <div className='job-pipeline-wrapper mx-3' >
            <DragDropContext onDragEnd={onDragEnd}>
                <div className='stages-wrapper'>
                    {renderStages()}
                </div>
            </DragDropContext>
        </div>
    </React.Fragment>
}

export default JobCandidates;
