import React, { useCallback, useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { FormProvider } from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";

const TransactionSplitForm = ({
  onSubmit,
  onCancel,
  data,
  form,
  values,
  setValue,
  control,
  formRules,
  errors,
}) => {
  const { pageState } = useContext(TablePageContext);
  const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

  const [inputs, setInputs] = useState([
    {
      value: 0,
      name: "value-1",
    },
  ]);

  const [removed, setRemoved] = useState(0);

  useEffect(() => {
    inputs.map((element) => {
      setValue(`${element.name}`, element.value);
    });
  }, [inputs]);

  const getInputs = () => {
    return inputs.map((element, i) => {
      return (
        <div className="transaction-amount-part">
          <TextFieldControl
            name={element.name}
            type={"number"}
            rules={formRules["value"]}
            control={data}
            label={
              strings.forms.finance.transactionSplitForm
                .transactionAmountInput + ` - ${i + 1}`
            }
            error={Boolean(errors && errors[element.name])}
            helperText={
              errors[element.name] && strings.forms.common.thisFieldIsRequired
            }
            disabled={isPageStateViewDetails()}
            className="amount"
            defaultValue={element.value}
            onInput={(e) => onInput(e, element)}
          />
          <Button
            className="add-amount-button"
            onClick={(e) => removeInput(e, element)}
          >
            <img src="/images/remove-icon-red.svg" />
          </Button>
        </div>
      );
    });
  };

  const plusInput = () => {
    setInputs([
      ...inputs,
      { value: 0, name: `value-${inputs.length + removed + 1}` },
    ]);
  };

  const removeInput = (e, element) => {
    setInputs(inputs.filter((item) => item.name !== element.name));
    setValue(`${element.name}`, undefined);
    setRemoved(removed + 1);
  };

  const onInput = (e, element) => {
    setValue(`${element.name}`, e.target.value);
    element.value = e.target.value;
  };

  return (
    <FormProvider {...form}>
      <form id="transaction-split-form" className="form" action="#">
        {getInputs()}

        <div className="split-transaction">
          <Button className="split-button" onClick={plusInput}>
            <img src="/images/table-page/add-cross-blue.svg" />
            {strings.forms.finance.transactionSplitForm.addButton}
          </Button>
        </div>

        {!isPageStateViewDetails() && (
          <div className="submit-container">
            <Button variant="contained" color="primary" onClick={onSubmit}>
              {strings.forms.common.save}
            </Button>
            <Button variant="contained" color="secondary" onClick={onCancel}>
              {strings.forms.common.cancel}
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default TransactionSplitForm;
