import {Controller} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {Box, FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import {getDropdownOptions} from "../../../Util/DropdownUtil";

const SelectControl = (props) => {

    const [value, setValue] = useState(props.value && props.value[props.valueKey] ? props.value[props.valueKey] : -1)

    const changeValue = (newValue) => {
        props.setValue(props.name, getValue(newValue))
        setValue(newValue);
        if(props.valueChangeHandler){
            props.valueChangeHandler(newValue)
        }
    }

    useEffect(() => {
        if((props.value && !(props.value instanceof Object)) || props.value === 0) {
            setValue(props.value)
            return;
        }

        setValue(props.value && props.value[props.valueKey] ? props.value[props.valueKey] : -1)
    }, [props.value])

    const getValue = (value) => {
        if(!props.valueKey || !props.options) {
            return value;
        }
        return  props.options.find(x => x[props.valueKey] === value)
    }

    return <Box sx={{ minWidth: 120 }} className={'select-box ' + props.className}>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" error={props.error}>{props.label}</InputLabel>
            {
                props.control &&
                <Controller
                    rules={props.rules}
                    name={props.name}
                    render={({field}) =>
                        <Select {...field}
                                disabled={props.disabled}
                                defaultOpen = {props.defaultOpen}
                                value={value}
                                size={props.size ? props.size : 'small'}
                                label={props.label}
                                error={props.error}
                                onClose = {props.onClose}
                                onChange={e => {
                                    if (field?.onChange) {
                                        field.onChange(() => changeValue(e.target.value));
                                        return
                                    }
                                    changeValue(e.target.value);
                                }}
                        >
                            {getDropdownOptions(props.options, props.nameKey, props.valueKey)}

                        </Select>}
                />
            }
            {
                !props.control &&
                <Select
                        disabled={props.disabled}
                        value={value}
                        size={props.size ? props.size : 'small'}
                        label={props.label}
                        error={props.error}
                        onClose = {props.onClose}
                        onChange={e => changeValue(e.target.value)}
                        defaultOpen = {props.defaultOpen}
                >
                    {getDropdownOptions(props.options, props.nameKey, props.valueKey)}
                </Select>
            }

            {
                props.error &&
                <FormHelperText>{props.helperText}</FormHelperText>
            }
        </FormControl>
    </Box>
}

export default SelectControl;
