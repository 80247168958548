import React, {useCallback, useContext, useEffect, useState} from "react";
import {Grid, Paper} from "@mui/material";
import {changeTeamLogo, getTeamLogo} from "../../../Services/HR/TeamService";
import strings from "../../../localization";
import FileUploadComponent from "../../../Components/Controls/FileUploadComponent";
import AvatarProfileControl from "../../../Components/Controls/AvatarProfileControl";
import {toBase64} from "../../../Util/ImageUtil";
import SnackbarContext from "../../../Context/SnackbarContext";
import { ImageFormats } from "../../../Constants/FileFormat";

const ChangeTeamLogo = ({teamId}) => {
    const [loading, setLoading] = useState(false);
    const [teamLogo, setTeamLogo] = useState()
    const [editor, setEditor] = useState()
    const [scaleValue, setScaleValue] = useState(1)
    const {showMessage} = useContext(SnackbarContext);
    const [files, setFiles] = useState([]);
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        fetchData();
    })

    const uploadLogo = async (files) => {
        const logo = await toBase64(files[0]);
        setLoading(true);
        changeTeamLogo(teamId,{ logo }).then(response =>{
            setTeamLogo(response.data.team.logo);
            showMessage(strings.commonMessages.editingSuccessful, 'success');
            setLoading(false);
            setChanged(false);
            setScaleValue(1);
            setFiles([]);
        });
    }

    const fetchData = useCallback(() => {
        getTeamLogo(teamId).then(response => {
            setTeamLogo(response?.data?.logo)
        });
    })

    const onCrop = () => {
        if(!teamLogo){
            showMessage(strings.commonMessages.errorEditing, 'error');
        }else{
            const img = editor.getImage().toDataURL();
                let imageLogo;
                fetch(img)
                    .then(res => res.blob())
                    .then(blob => (imageLogo = window.URL.createObjectURL(blob)));
                
                changeTeamLogo(teamId, { logo: img }).then(response =>{
                    setTeamLogo(response.data.team.logo)
                    setScaleValue(1)
                    setChanged(false);
                    setFiles([]);
                    showMessage(strings.commonMessages.editingSuccessful, 'success');
                });
        }
    }

    const onScaleChange = (scaleValueEvent) => {
        const scaleValue = parseFloat(scaleValueEvent.target.value);
        setScaleValue(scaleValue)
        setChanged(true);
    }

        return(
            <Grid 
                id='page' 
                md = { 12 } 
                container
                justifyContent="center" 
                alignItems="center">
                <Grid item xs={5}>

                    <Paper className='paper upload-logo-container'>
                        <FileUploadComponent upload= { uploadLogo } formats={ImageFormats} loading={loading} files = {files} setFiles = {setFiles} />
                        <AvatarProfileControl
                            imageSrc={ teamLogo }
                            setEditorRef={setEditor}
                            onCrop={onCrop}
                            scaleValue={scaleValue}
                            onScaleChange={onScaleChange}
                            disableSave = {!changed}
                            setChanged = {setChanged}
                            sliderValue = {scaleValue}
                            />
                    </Paper>
                </Grid>

            </Grid>
        );
}


export default ChangeTeamLogo;
