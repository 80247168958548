import React, {useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from '../../../../Controls/Inputs/AutoCompleteControl';
import PartnerLegalType from "../../../../../Constants/CRM/PartnerLegalType";
import AutoCompleteTextField from '../../../../Controls/Inputs/AutoCompleteTextField';
import ActionTextField from '../../../../Controls/Inputs/ActionTextField';

const PartnerForm = ({
                         onSubmit,
                         onCancel,
                         data,
                         watch,
                         form,
                         errors,
                         currencies,
                         countries,
                         partnerTypes,
                         values,
                         setValue,
                         formRules,
                         control,
                         onNameChange,
                         companies,
                         onRegistrationSearch,
                         onTaxSearch,
                         bankAccounts,
                         partnerLegalTypes
                     }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='partner-form' className='form' action="#">
                <AutoCompleteTextField
                    value={values['name']}
                    className='mt-8 mb-4 mui-shifted-label-input'
                    setValue={setValue}
                    name='name'
                    rules={formRules['name']}
                    control={control}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.crm.partner.name}
                    placeholder={strings.forms.crm.partner.placeholder.name}
                    options={companies}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onNameChange}
                />
                <ActionTextField
                    name='registrationNumber'
                    control={data}
                    label={strings.forms.crm.partner.registrationNumber}
                    placeholder={strings.forms.crm.partner.placeholder.number}
                    disabled={isPageStateViewDetails()}
                    type={'number'}
                    onClick={onRegistrationSearch}
                    value={values['registrationNumber']}
                    setValue={setValue}
                    error={Boolean(errors.registrationNumber)}
                    helperText={strings.forms.crm.partner.error.registrationNumber}
                />
                <ActionTextField
                    name='taxNumber'
                    className='margin-shifted-label'
                    control={data}
                    label={strings.forms.crm.partner.taxNumber}
                    placeholder={strings.forms.crm.partner.placeholder.number}
                    disabled={isPageStateViewDetails()}
                    type={'number'}
                    onClick={onTaxSearch}
                    value={values['taxNumber']}
                    setValue={setValue}
                    error={Boolean(errors.taxNumber)}
                    helperText={strings.forms.crm.partner.error.taxNumber}
                />
                <TextFieldControl
                    name='website'
                    className='margin-shifted-label mui-shifted-label-input'
                    control={data}
                    label={strings.forms.crm.partner.website}
                    disabled={isPageStateViewDetails()}
                    placeholder={strings.forms.crm.partner.placeholder.website}
                />
                <SelectControl
                    value={values['partnerType']}
                    setValue={setValue}
                    className='margin-shifted-label mui-shifted-label-input'
                    name='partnerType'
                    label={strings.forms.crm.partner.partnerType}
                    options={partnerTypes}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                <SelectControl
                    value={values['partnerLegalType']}
                    setValue={setValue}
                    name='partnerLegalType'
                    rules={formRules['partnerLegalType']}
                    className='margin-shifted-label mui-shifted-label-input'
                    label={strings.forms.crm.partner.partnerLegalType}
                    options={partnerLegalTypes}
                    nameKey={'name'}
                    control={control}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    error={Boolean(errors.partnerLegalType)}
                    helperText={errors.partnerLegalType && strings.forms.common.thisFieldIsRequired}
                />
                {
                    (watch('partnerLegalType')?.id === PartnerLegalType.PUBLIC ||
                        values['partnerLegalType'] === PartnerLegalType.PUBLIC) &&
                    <TextFieldControl
                        name='jbkjs'
                        className='margin-shifted-label mui-shifted-label-input'
                        control={data}
                        label={strings.forms.crm.partner.jbkjs}
                        disabled={isPageStateViewDetails()}
                        placeholder={strings.forms.crm.partner.placeholder.jbkjs}
                    />
                }

                <TextFieldControl
                    name='description'
                    className='margin-shifted-label mui-shifted-label-input'
                    control={data}
                    label={strings.forms.crm.partner.description}
                    disabled={isPageStateViewDetails()}
                    multiline
                    rows={5}
                    placeholder={strings.forms.crm.partner.placeholder.description}
                />

                {!bankAccounts?.length > 0 ?
                    <TextFieldControl
                        name='account'
                        className='margin-shifted-label mui-shifted-label-input'
                        control={data}
                        label={strings.forms.crm.partner.account}
                        disabled={isPageStateViewDetails()}
                        placeholder={strings.forms.crm.partner.placeholder.account}
                    />
                    :
                    <SelectControl
                        value={values['account']}
                        className='margin-shifted-label mui-shifted-label-input'
                        setValue={setValue}
                        rules={formRules['account']}
                        name='account'
                        control={control}
                        error={Boolean(errors.account)}
                        helperText={errors.account && strings.forms.common.thisFieldIsRequired}
                        label={strings.forms.crm.partner.account}
                        options={bankAccounts}
                        nameKey={'account'}
                        valueKey={'account'}
                        disabled={isPageStateViewDetails()}
                    />
                }
                <SelectControl
                    value={values['currency']}
                    className='margin-shifted-label-select mui-shifted-label-input'
                    setValue={setValue}
                    rules={formRules['currency']}
                    name='currency'
                    control={control}
                    error={Boolean(errors.currency)}
                    helperText={errors.currency && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.crm.partner.currency}
                    options={currencies}
                    nameKey={'code'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />
                <AutoCompleteControl
                    value={values['country']}
                    className='margin-shifted-label-select mui-shifted-label-input'
                    setValue={setValue}
                    name='country'
                    rules={formRules['country']}
                    control={control}
                    error={Boolean(errors.country)}
                    helperText={errors.country && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.crm.partner.country}
                    options={countries}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    placeholder={strings.forms.crm.partner.placeholder.country}
                />
                <TextFieldControl
                    name='city'
                    className='margin-shifted-label mui-shifted-label-input'
                    control={data}
                    label={strings.forms.crm.partner.city}
                    disabled={isPageStateViewDetails()}
                    placeholder={strings.forms.crm.partner.placeholder.city}
                />

                <TextFieldControl
                    name='address'
                    className='margin-shifted-label mui-shifted-label-input'
                    control={data}
                    label={strings.forms.crm.partner.address}
                    disabled={isPageStateViewDetails()}
                    placeholder={strings.forms.crm.partner.placeholder.address}
                />

                <TextFieldControl
                    name='zipCode'
                    className='margin-shifted-label-last mui-shifted-label-input'
                    control={data}
                    label={strings.forms.crm.partner.zipCode}
                    disabled={isPageStateViewDetails()}
                    placeholder={strings.forms.crm.partner.placeholder.zipCode}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default PartnerForm;
