import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import BankReportForm from "../../../Components/Forms/Pages/Finance/BankReport/BankReportForm";
import DrawerContext from "../../../Context/DrawerContext";
import {editBankReport} from "../../../Services/Finance/BankReportService";
import LinearProgress from '@mui/material/LinearProgress';
import SnackbarContext from "../../../Context/SnackbarContext";
import {getSiteData} from "../../../Services/Base/DefaultService";
import {getAllBankAccounts, getBankAccounts} from "../../../Services/Company/BankAccountService";
import {getBusinessYears} from "../../../Services/Finance/BusinessYearService";

const formRules = {
    'bank': {required: true}, 'bankAccount': {required: true}, 'currency': {required: true}, 'businessYear': {required: true}, 
    'reportId': {required: true}, 'reportNumber': {required: true}, 'reportDate': {required: true}, 'amountBefore': {required: true}, 
    'amountAfter': {required: true}, 'income': {required: true}, 'outcome': {required: true}, 
}

const EditBankReport = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    
    const [bankData, setBankData] = useState([]);
    const [bankAccountsData, setBankAccountsData] = useState([]);
    const [currenciesData, setCurrenciesData] = useState([]);
    const [businessYearsData, setBusinessYears] = useState([]);

    useEffect(() => {
        setDrawerTitle(strings.pages.finance.bankReport.editBankReport.pageTitle);
    });

    useEffect(() => {
        fetch().then();
    },[])

    const fetch = async () => {
        const siteData = await getSiteData();
        const businessYearsResponse = await getBusinessYears({});
        fetchBankAccounts();

        setBankData(siteData.data.banks);
        setCurrenciesData(siteData.data.currencies);
        setBusinessYears(businessYearsResponse.data.result);
    }


    const fetchBankAccounts = (term = '') => {
        getBankAccounts({term}).then(response => {
            setBankAccountsData(response.data.result);
        });
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        editBankReport(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <BankReportForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                bankData = {bankData}
                bankAccountsData = {bankAccountsData}
                currenciesData = {currenciesData}
                businessYearsData = {businessYearsData}
                onChangeBankAccountFilter={fetchBankAccounts}
                errors={errors} data={data} form={form} control={control}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditBankReport;