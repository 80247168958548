import React, {useContext, useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch, useSelector} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {formatColumnObject, formatColumnDateTime} from "../../../Components/DataGrid/ValueFormatter";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import { useNavigate, useSearchParams } from "react-router-dom";
import { returnColumnBoolean } from "../../../Components/DataGrid/ValueCellRender";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import { changeStatePresenceRequest, deletePresence, getPresenceList } from "../../../Services/HR/PresenceService";
import EditPresence from "./EditPresence";
import AddPresence from "./AddPresence";
import { getEmployees, getEmployeeByUser } from "../../../Services/User/UserService";
import { getChangeRequestedTypes } from "../../../Constants/HR/PresenceChangeRequestedType";
import { hasPermission } from "../../../Util/PermissionUtil";
import { Button } from "@mui/material";
import SnackbarContext from "../../../Context/SnackbarContext";
import Modules from "../../../Constants/Base/Modules";
import {fetchOptions} from '../../../Services/Base/FilterOptionsService';
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [
    {
        field: 'start', headerName: strings.pages.hr.presence.presenceList.start,
        renderCell: (params) => formatColumnDateTime(params),
        width: 200
    },
    {
        field: 'end', headerName: strings.pages.hr.presence.presenceList.end,
        renderCell: (params) => formatColumnDateTime(params),
        width: 200
    },
    {
        field: 'changeRequested', headerName: strings.pages.hr.presence.presenceList.changeRequested,
        renderCell: (params) => returnColumnBoolean(params),
        align: 'center',
        width: 150
    },
    {
        field: 'changeApproved', headerName: strings.pages.hr.presence.presenceList.changeApproved,
        renderCell: (params) => returnColumnBoolean(params),
        align: 'center',
        width: 150
    },
    {
        field: 'newStart', headerName: strings.pages.hr.presence.presenceList.newStart,
        renderCell: (params) => formatColumnDateTime(params),
        width: 200
    },
    {
        field: 'newEnd', headerName: strings.pages.hr.presence.presenceList.newEnd,
        renderCell: (params) => formatColumnDateTime(params),
        align: 'center',
        width: 200
    },
    {
        field: 'newDeleted', headerName: strings.pages.hr.presence.presenceList.newDeleted,
        renderCell: (params) => returnColumnBoolean(params),
        align: 'center',
        width: 100
    },
    {
        field: 'user', headerName: strings.pages.hr.presence.presenceList.employee,
        valueFormatter: (params) => formatColumnObject(params, 'fullName'),
        width: 200
    },
]

const PresenceList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [filterOptions, setFilterOptions] = useState({});
    const [tablePageOptions, setTablePageOptions] = useState({
        ...TablePageOptionDefaults,
        showYearFilter: true,
        showMonthFilter: true
    });
    const referenceType = ReferenceType.PRESENCE;
    const permissionGroup = AppPermissions.PRESENCE.GROUP;
    const auth = useSelector((state) => state.auth);
    const {showMessage} = useContext(SnackbarContext);

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.hr.presence.presenceList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.pages.hr.presence.presenceList.pageTitle},
            ],        
        }));

        setColumns([controlColumn,...tableDescription]);

        fetchFilters();

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    const fetchEmployees = async (term, filterFetch) => {
        return fetchOptions('employee', searchParams, getEmployees, getEmployeeByUser, setFilterOptions, term, filterFetch, 'user');
    }

    const fetchFilters = async () => {
        const employees = await fetchEmployees();
        
        setFilterOptions({
            employee: employees,
            changeRequested: getChangeRequestedTypes(),
            fetched: 1,
        });
    }

    const filters = [
        {
            name: 'employee',
            nameKey: 'fullName',
            valueKey: 'id',
            optionsName: 'employee',
            label: strings.pages.hr.presence.presenceList.employee,
            onChange: (term) => fetchEmployees(term, true),
        },
        {
            name: 'changeRequested',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'changeRequested',
            label: strings.pages.hr.presence.presenceList.changeRequested,
            showSelect: true
        },
    ]
    

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getPresenceList({
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    const controlColumn = {
        field: 'control',
        headerName: strings.pages.hr.presence.presenceList.controls,
        width: 200,
        renderCell: params => formatColumnControl(params),
        align: 'center'
    };

    const formatColumnControl = (params) => {

        if(!hasPermission(auth.user, permissionGroup, AppPermissions.PRESENCE.RESOLVE, auth.permissions)) {
            return '';
        }

        if(params.row.changeApproved !== null && params.row.changeApproved !== undefined) {
            return ''
        }

        return <div className='submit-container'>
            <Button variant="contained" size="small" color="primary" className='table-button' onClick={() => changeState(params.row.id, true)}>
                {strings.pages.hr.leaveRequest.leaveRequestList.approve}
            </Button>
            <Button variant="contained" size="small" color="secondary" className='table-button' onClick={() => changeState(params.row.id, false)}>
                {strings.pages.hr.leaveRequest.leaveRequestList.decline}
            </Button>
        </div>
    }

    const changeState = (id, approved) => {

        changeStatePresenceRequest({
            id,
            approved
        }).then(response => {

            if(!response || !response.ok) {
                return;
            }

            showMessage(strings.commonMessages.saved)
            fetch();
        });
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deletePresence}
                   showDeletingError={true} tableDescription={columns}
                   tableData={tableData} filter={filter} filters={filters} filterOptions={filterOptions}
                   referenceType={referenceType} permissionGroup={permissionGroup}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditPresence data={ selectedItems }
                   onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addPage={<AddPresence onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addButtonText={strings.components.tablePage.addButton.presance}
                   />
    </TablePageContext.Provider>;
}

export default PresenceList;
