import React, {useContext, useEffect, useRef, useState} from "react";
import {Document, Page} from 'react-pdf';
import strings from "../../localization";
import DrawerContext from "../../Context/DrawerContext";
import LinearProgress from "@mui/material/LinearProgress";
import {AppBar, Box, Grid, IconButton, Menu, Paper, Toolbar, Tooltip, Typography} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from 'file-saver';
import {ZoomIn, ZoomOut} from "@mui/icons-material";


const PdfPreview = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);

    const [url, setUrl] = useState(null);
    const [name, setName] = useState('');
    const [numPages, setNumPages] = useState(1);
    const currentPage = useRef();
    const pdfViewer = useRef();
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        setDrawerTitle(strings.pages.document.document.pdfPreview.pageTitle);
    }, []);

    useEffect(() => {
        if (props.pdf) {
            const {name, url} = props.pdf
            setUrl(url)
            setName(name)
            setShowLoader(true)
        }
    }, [props.pdf])

    const handleDownload = () => {
        saveAs(url, name);
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setShowLoader(false);
    }

    const handleScroll = (e) => {
        const {scrollTop, scrollHeight} = e.target;
        const pageSize = scrollHeight / numPages;
        const page = Math.round(scrollTop / pageSize)+1;
        if (page != currentPage.current.textContent) {
            currentPage.current.textContent = page;
        }
    }

    const zoomIn = () => {
        let zoom = Number(pdfViewer.current.style.zoom) || 1;
        if (zoom+0.1 > 5) {
            pdfViewer.current.style.zoom = 5;
            return;
        }
        pdfViewer.current.style.zoom = zoom+0.1
    }

    const zoomOut = () => {
        let zoom = Number(pdfViewer.current.style.zoom) || 1;
        if (zoom-0.1 < 1) {
            pdfViewer.current.style.zoom = 1;
            return;
        }
        pdfViewer.current.style.zoom = zoom-0.1
    }

    const clearName = () => {
        if (name.length < 28) {
            return name;
        }
        return `${name.substr(0, 10)}....${name.substr(-10)}`
    }


    return <div className='drawer-form pdf-preview-drawer'>
        <Paper className='paper'>
            {
                showLoader && <LinearProgress/>
            }
            <div key={'drawer-pdf-preview'}>
                <AppBar position={"relative"}>
                    <Toolbar>
                        <Grid container>
                            <Grid item xs={4}>
                                <Tooltip title={name} placement="top-start">
                                    <Typography>{clearName()}</Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={'text-center'}><span ref={currentPage}>1</span>/{numPages}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Tooltip title={strings.pages.document.document.pdfPreview.zoomIn} placement="top">
                                        <IconButton onClick={ zoomIn} color="info">
                                            <ZoomIn/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={strings.pages.document.document.pdfPreview.zoomOut} placement="top">
                                        <IconButton onClick={ zoomOut} color="info">
                                            <ZoomOut/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={strings.pages.document.document.pdfPreview.download} placement="top">
                                        <IconButton onClick={ () => handleDownload()} color="info">
                                            <DownloadIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <div className={'pdf-content'} key={'pdf-content'}>
                    <Document inputRef={pdfViewer} file={{url}} onLoadSuccess={onDocumentLoadSuccess} loading={''} className='pdf-preview' onScroll={(e) => handleScroll(e)} key={'pdf-preview'}>
                        {Array.apply(null, Array(numPages))
                            .map((x, i)=>i+1)
                            .map(page => <Page pageNumber={page} loading={''} key={'page-'+page} wrap>
                                {
                                    page<numPages && <hr/>
                                }
                            </Page>)}
                    </Document>
                </div>
            </div>
        </Paper>
    </div>

}

export default PdfPreview;