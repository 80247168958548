import React, {useState} from 'react'
import SignaturePad from 'react-signature-canvas'
import {Button} from "@mui/material";
import strings from "../../../../localization";

const SignatureForm = ({
    onSubmit,
    disabledSubmit,
    data,
    onClear,
    sigPad
}) => {

    const [disableSave, setDisableSave] = useState(true);

    const onSubmitSig = () => {
        let isSignatureExist
        if (data.isSignatureExist) {
            return
        }
        isSignatureExist = !sigPad.current.isEmpty()
        onSubmit( sigPad.current.getTrimmedCanvas().toDataURL('image/png'), isSignatureExist)
        setDisableSave(true);
    }

    const checkIsChanged = (e) => {
        setDisableSave(false);
    }

    const onClearPad = () => {
        if (data.isSignatureExist) {
            onClear()
        } else {
            sigPad.current.clear()
            onClear()
        }

        setDisableSave(true);
    }

    return (
        <form onSubmit={ onSubmitSig } action="#" className='px-4'>
            <div className='submit-container d-flex-column' style={{maxWidth: '600px'}} >
                <div className='blue-dashed-border rounded-3 signature-container' onMouseUp = {checkIsChanged}>
                    { data.isSignatureExist
                        ?
                            <img src={data.signature} loading={'lazy'} />
                        :
                            <SignaturePad
                                canvasProps={{width: 600, height: 200, className: 'sigCanvas'}}
                                ref={sigPad}
                            /> 
                    }

                </div>
                
                <div className='submit-container d-flex-column'>
                    <Button variant="contained" color="primary" className='mx-0' onClick={ onSubmitSig } disabled={disableSave}>
                        { strings.signatureForm.save }
                    </Button>
                    <Button variant="contained" color="secondary" className='mx-0' style={{marginLeft: "5px"}} onClick={ onClearPad }>
                        { strings.signatureForm.clear }
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default SignatureForm;
