import React, {useEffect, useRef, useState} from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";

export const TemplateQuillEditor = ({
                           name='',
                           value = '',
                           onChange = ()=>{},
                           defaultValue = '',
                           disabled = false,
                           className = '',
                           form,
                           placeholder='',
                           questions,
                       })=> {
    const editor = useRef(null);

    const clickQuestionHandler = (question) => {
        const cursorPosition = editor.current.editor.getSelection().index;
        editor.current.editor.insertText(cursorPosition, "[" + question.code + "]");
    }

    const onChangeHandler = (props) => {
        onChange(props);
    }

    const onUndoHandler = () => {
        editor.current.editor.history.undo();
    }

    const onRedoHandler = () => {
        editor.current.editor.history.redo();
    }


    return (
        <div className="text-editor">
            <EditorToolbar questions={questions} undoHandler={onUndoHandler} redoHandler={onRedoHandler} clickQuestion={clickQuestionHandler}/>
            <ReactQuill
                ref={editor}
                className="large-template-editor"
                readOnly={disabled}
                value={value}
                onChange={onChangeHandler}
                theme="snow"
                modules={modules}
            />
        </div>
    );
};

export default TemplateQuillEditor;