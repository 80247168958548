import React, {useState, useEffect, useContext} from 'react';
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import Modules from "../../../Constants/Base/Modules";
import {useDispatch, useSelector} from "react-redux";
import YesNoDialog, {YesNoDialogResult} from "../../../Components/Dialogs/YesNoDialog";
import TreeViewList from "../../../Components/Tree/TreeViewList";
import {CategoryDot, CategoryIcon} from "../../../Util/ImageUtil";
import {hasPermission} from "../../../Util/PermissionUtil";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {Drawer, IconButton, InputBase, Modal, Paper} from "@mui/material";
import LoaderContext from "../../../Context/LoaderContext";
import {useForm} from "react-hook-form";
import { addProductService, deleteProductService, editProductService, getProductService, getProductServiceList, getProductImage }
    from "../../../Services/Product/ProductServiceService";
import{ addProductServiceCategory, deleteProductServiceCategory,
        editProductServiceCategory, getProductServiceCategoryByParent}
    from "../../../Services/Product/ProductServiceCategoryService";
import { Grid } from '@mui/material';
import { Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import ProductCard from "./ProductCard";
import {getCurrentYear} from "../../../Util/DateUtil";
import {renderNumber} from "../../../Util/RenderDataUtil";
import { fetchExchangeRate } from "../../../Services/Finance/CurrencyService";
import { getCurrentDate } from "../../../Util/DateUtil";
import DrawerContext from "../../../Context/DrawerContext";
import PageState from "../../../Constants/Base/PageState";
import DrawerWrapper from "../../../Components/Layout/DrawerWrapper";
import TablePageContext from "../../../Context/TablePageContext";
import AddProductService from "./AddProductService";

const formRules = {
    'name': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
};


const ProductCatalog = () => {

    const dispatch = useDispatch();
    const [showEditModal, setShowEditModal] = useState(false);
    const permissionGroupTemplate = AppPermissions.PRODUCT.GROUP;
    const permissionGroupCategory = AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.GROUP;
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [dataFetchCategory, setDataFetchCategory] = useState(null);
    const [selectedEditTemplate, setSelectedEditTemplate] = useState(null);
    const {setLoading} = useContext(LoaderContext);
    const [changeTree, setChangeTree] = useState(null);
    const [editState, setEditState] = useState(false);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [totalValue, setTotalValue] = useState(0);
    const [drawerTitle, setDrawerTitle] = useState('');
    const value = {drawerTitle, setDrawerTitle}

    const [pageState, setPageState] = useState(PageState.View);


    const auth = useSelector((state) => state.auth)

    const form = useForm({});
    const {formData, handleSubmit, getValues, setValue, setError, formState: {errors}} = form;

    const handleAdd = () => {
        setPageState(PageState.Add)
    }

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        if(selectedCategory) {
            fetch(selectedCategory);
        }
        setPageState(PageState.View);
    }

    const isDrawerOpen = () => {
        return pageState !== PageState.View;
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.product.productCatalog.title,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.PRODUCT},
                {label: strings.pages.product.productCatalog.title},
            ],
        }));
    }, [])

    const fetch = (data) => {
        getProductServiceList(data).then(response => {
            setProducts(response.data.result);
            setFilteredProducts(response.data.result);
            setLoading(false);
        })
    }

    const expandHandler = (selectedCategory) => {
        setLoading(true);
        setSelectedCategory(selectedCategory);
        setDataFetchCategory(null);

        const data = { "productServiceCategoryId": selectedCategory?.id}
        if(selectedCategory !== null) {
            fetch(data);
        }else{
            setFilteredProducts([]);
            setLoading(false);
        }

        setLoading(false);
    }

    useEffect(() => {
        calculateTotalValue();
    }, [filteredProducts])

    const searchProducts = (name) => {
        let filteredProducts = null;
        if(products !== null) {
            filteredProducts = products.filter(
                (product) => product.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
            );
        }
        setFilteredProducts(filteredProducts);
    }

    const onChangeTree = (item) => {
        setChangeTree(item);
    }

    const renderCards = () => {
        let result = [];

        for(let product of filteredProducts){
            result.push(
                <ProductCard key={product.id} product={product}></ProductCard>
            )
        }
        return result;
    }

    const calculateTotalValue = async () => {
        let totalValue = 0;
        for(let product of filteredProducts){
            if(product.currency.id !== 178){
                const exRate = await fetchExchangeRate(product.currency.code, 'RSD', getCurrentDate());
                totalValue += parseFloat(product.price) * exRate.data.middleRate;
            }else {
                totalValue += parseFloat(product.price);
            }
        }
        setTotalValue(totalValue);
    }

    const handleCreateProduct = () => {
        setPageState(PageState.Add)
    }

    const renderStatistic = () => {
        return (
            <div className='statistic-container'>
                <div className='statistic-overview-item'>
                    <div className="title">Items</div>
                    <div className="main">{filteredProducts.length}</div>
                </div>
                <div className='statistic-overview-item'>
                    <div className="title">Total quantity</div>
                    <div className="main">{filteredProducts.length}</div>
                </div>
                <div className='statistic-overview-item'>
                    <div className="title">Total value</div>
                    <div className="main">RSD {renderNumber(totalValue.toString(), '', '')}</div>
                </div>
            </div>
        );
    }

    return (
    <DrawerContext.Provider value={value}>
            <div>
                <div id={'templates'}>
                    <div className="content">
                        <TreeViewList
                            onExpand={expandHandler}
                            folderIcon={CategoryIcon}
                            childIcon={CategoryDot}
                            getFolders={getProductServiceCategoryByParent}
                            addFolder={addProductServiceCategory}
                            editFolder={editProductServiceCategory}
                            deleteFolder={deleteProductServiceCategory}
                            changeState={onChangeTree}
                            editOutside={editState}
                            permissionGroup={permissionGroupCategory}
                            addStringButton={strings.pages.companyDocument.companyDocumentCategory.addCategory}
                            addStringModal={strings.pages.companyDocument.companyDocumentCategory.createNewCategory}
                            addItemString={strings.components.treeViewList.createNewCategory}
                            editItemString={strings.components.treeViewList.editCategory}
                            tableFormatResponse={true}
                        ></TreeViewList>
                        {
                            hasPermission(auth.user, permissionGroupTemplate, AppPermissions[permissionGroupTemplate].VIEW, auth.permissions) &&
                            <div className="templates-list">
                                <div className='header'>
                                    <div className={'filter-container'}>
                                        <div id = 'searchfield-container'>
                                            <Paper>
                                                <IconButton>
                                                    <img src = '/images/search.svg'/>
                                                </IconButton>
                                                <InputBase
                                                    placeholder = {"Search"}
                                                    onChange={(e) => searchProducts(e.target.value)}
                                                />
                                            </Paper>
                                        </div>
                                    </div>
                                    <div className="w-100 d-flex justify-end">
                                        {
                                            hasPermission(auth.user, permissionGroupTemplate, AppPermissions[permissionGroupTemplate].ADD, auth.permissions) &&
                                            <IconButton className='plus-button mui-rounded-button mb-1' disabled={selectedCategory === null} onClick={() => handleCreateProduct()}>
                                                <img src='/images/table-page/add-cross.svg'/>
                                                <div className="font-15">
                                                    {strings.pages.product.productCatalog.addProduct}
                                                </div>
                                            </IconButton>
                                        }
                                    </div>
                                </div>
                                    {
                                        selectedCategory && filteredProducts && renderStatistic()
                                    }
                                <div className='catalog-container'>
                                    <Grid container spacing={2}>
                                        {
                                            filteredProducts && selectedCategory && renderCards()
                                        }
                                    </Grid>
                                </div>
                                <Drawer id='drawer' anchor='right' open={isDrawerOpen()} onClose={() => setPageState(PageState.View)}>
                                    <DrawerWrapper onBack={() => setPageState(PageState.View)} title={drawerTitle}
                                                   viewDetails={pageState === PageState.ViewDetails}>
                                        {
                                            pageState === PageState.Add && <AddProductService onCancel={() => onCancel()} onFinish={() => onFinish() }/>
                                        }
                                    </DrawerWrapper>
                                </Drawer>
                            </div> }
                    </div>
                </div>
            </div>
    </DrawerContext.Provider>
    );

}

export default ProductCatalog;