import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import AssetManagementLocationForm from "../../../Components/Forms/Pages/AssetManagement/AssetManagementLocation/AssetManagementLocationForm";
import DrawerContext from "../../../Context/DrawerContext";
import {
  editAssetManagementLocation,
  getAssetManagementLocations,
} from '../../../Services/AssetManagement/AssetManagementLocationService';
import LinearProgress from '@mui/material/LinearProgress';
import SnackbarContext from "../../../Context/SnackbarContext";
import { filterDropdownItem } from "../../../Util/DropdownUtil";

const formRules = {
    'name': {required: true}
}

const EditAssetManagementLocation = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [assetManagementLocations, setAssetManagementLocations] = useState(0);

    useEffect(() => {
        setDrawerTitle(strings.pages.administration.location.editLocation.pageTitle);
    });

    useEffect(() => {
        fetchParent(); 
    },[])

    const fetchParent = (term = "") => {
        getAssetManagementLocations({term}).then((response) => {
          if (!response || !response.ok) {
            return;
          }
          setAssetManagementLocations(
            filterDropdownItem(response.data.result, props.data[0]?.id)
          );
        });
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        editAssetManagementLocation(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <AssetManagementLocationForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                assetManagementLocations = {assetManagementLocations}
                errors={errors} data={data} form={form}
                onParentChangeFilter={fetchParent}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditAssetManagementLocation;