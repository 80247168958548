import React from "react";
import { FormProvider } from "react-hook-form";
import strings from "../../../../../localization";
import Button from "@mui/material/Button";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";

const BusinessTripDecisionForm = ({
  onSubmit,
  onCancel,
  fetchUsers,
  formRules,
  control,
  form,
  errors,
  values,
  setValue,
  users,
  data,
  currencies,
}) => {
  return (
    <FormProvider {...form}>
      <form id="business-trip-list-page" action="#">
        <TextFieldControl
          name="reasonToTravel"
          rules={formRules["reasonToTravel"]}
          label={strings.pages.hr.businessTrip.decisionForm.reasonToTravel}
          helperText={
            errors.reasonToTravel && strings.forms.common.thisFieldIsRequired
          }
          error={Boolean(errors.reasonToTravel)}
        />
        <TextFieldControl
          name="travelDestination"
          label={strings.pages.hr.businessTrip.decisionForm.travelDestination}
          rules={formRules["travelDestination"]}
          helperText={
            errors.travelDestination && strings.forms.common.thisFieldIsRequired
          }
          error={Boolean(errors.travelDestination)}
        />
        <DateTimeControl
          name="dayOfDeparture"
          label={strings.pages.hr.businessTrip.decisionForm.dayOfDeparture}
          value={values["dayOfDeparture"]}
          setValue={setValue}
          control={data}
          rules={formRules["dayOfDeparture"]}
          helperText={
            errors.dayOfDeparture && strings.forms.common.thisFieldIsRequired
          }
          error={Boolean(errors.dayOfDeparture)}
        />
        <TextFieldControl
          name="tripDuration"
          type="number"
          rules={formRules["tripDuration"]}
          label={strings.pages.hr.businessTrip.decisionForm.tripDuration}
          control={data}
          helperText={
            errors.tripDuration && strings.forms.common.thisFieldIsRequired
          }
          error={Boolean(errors.tripDuration)}
        />
        <TextFieldControl
          name="meansOfTransport"
          label={strings.pages.hr.businessTrip.decisionForm.meansOfTransport}
          rules={formRules["tripDuration"]}
          control={data}
          helperText={
            errors.meansOfTransport && strings.forms.common.thisFieldIsRequired
          }
          error={Boolean(errors.meansOfTransport)}
        />
        <TextFieldControl
          name="freeAccommodationAndFood"
          label={
            strings.pages.hr.businessTrip.decisionForm.freeAccommodationAndFood
          }
          rules={formRules["freeAccommodationAndFood"]}
          control={data}
          helperText={
            errors.freeAccommodationAndFood &&
            strings.forms.common.thisFieldIsRequired
          }
          error={Boolean(errors.freeAccommodationAndFood)}
        />

        <div className="currencies">
          <AutoCompleteControl
            className="currency-wrapper"
            value={values["domesticCurrency"]}
            setValue={setValue}
            name="domesticCurrency"
            control={control}
            options={currencies}
            nameKey={"code"}
            valueKey={"id"}
            rules={formRules["domesticCurrency"]}
            helperText={
              errors.domesticCurrency &&
              strings.forms.common.thisFieldIsRequired
            }
            error={Boolean(errors.domesticCurrency)}
          />
          <TextFieldControl
            name="amountOfDailyWageInCountry"
            type="number"
            label={
              strings.pages.hr.businessTrip.decisionForm
                .amountOfDailyWageInCountry
            }
            control={data}
          />
        </div>

        <div className="currencies">
          <AutoCompleteControl
            className="currency-wrapper"
            value={values["foreignCurrency"]}
            setValue={setValue}
            name="foreignCurrency"
            control={control}
            options={currencies}
            nameKey={"code"}
            valueKey={"id"}
            rules={formRules["foreignCurrency"]}
            helperText={
              errors.foreignCurrency && strings.forms.common.thisFieldIsRequired
            }
            error={Boolean(errors.foreignCurrency)}
          />
          <TextFieldControl
            name="amountOfDailyWageAbroad"
            type="number"
            label={
              strings.pages.hr.businessTrip.decisionForm.amountOfDailyWageAbroad
            }
            control={data}
          />
        </div>

        <TextFieldControl
          name="advancePaymentDomesticCurrency"
          type="number"
          label={
            strings.pages.hr.businessTrip.decisionForm
              .advancePaymentInDomesticCurrency
          }
          rules={formRules["advancePaymentDomesticCurrency"]}
          control={data}
        />

        <TextFieldControl
          name="advancePaymentForeignCurrency"
          type="number"
          label={
            strings.pages.hr.businessTrip.decisionForm
              .advancePaymentInForeignCurrency
          }
          control={data}
        />

        <AutoCompleteControl
          value={values["companyUser"]}
          setValue={setValue}
          name="companyUser"
          control={control}
          label={strings.pages.hr.businessTrip.decisionForm.employee}
          options={[users]}
          nameKey={"fullName"}
          valueKey={"id"}
          onChange={fetchUsers}
          rules={formRules["companyUser"]}
          helperText={
            errors.companyUser && strings.forms.common.thisFieldIsRequired
          }
          error={Boolean(errors.companyUser)}
        />

        <div className="submit-container">
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.forms.common.save}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.forms.common.cancel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default BusinessTripDecisionForm;
