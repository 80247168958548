import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import strings from "../../../localization";
import {useParams, useNavigate} from "react-router-dom";
import {Grid, Paper, Tab, Tabs} from "@mui/material";
import {dateToString} from "../../../Util/DateUtil";
import DocumentList from "../../Document/Document/DocumentList";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import DealDetailsTab from "../../../Constants/CRM/DealDetailsTab";
import DealRecord from "./DealRecord";
import {getDeal} from "../../../Services/CRM/DealService";
import {renderNumber} from "../../../Util/RenderDataUtil";
import {hasPermission} from "../../../Util/PermissionUtil";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import PartnerContacts from "./PartnerContacts";

const DealDetails = ({activeTab = DealDetailsTab.RECORD}) => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const {id: dealId} = useParams();
    const [deal, setDeal] = useState(null);

    const changeActiveTab = (tab) => {
        if(tab === DealDetailsTab.RECORD){
            navigate(`/deal-details/${dealId}/record`);
        }

        if(tab === DealDetailsTab.FILES){
            navigate(`/deal-details/${dealId}/files/${ReferenceType.DEAL}`);
        }

        if(tab === DealDetailsTab.CONTACT){
            navigate(`/deal-details/${dealId}/contacts/${deal.partner.id}`);
        }
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        fetch();
    }, [])

    const fetch = () => {
        getDeal(dealId).then(response => {
            if (!response || !response.ok) {
                return;
            }

            setDeal(response.data.entity);
        });
    }

    return <div id='candidate-details-page'>
        {
            deal &&
            <Paper className='paper'>
                <Grid container>
                    <Grid item xs={6}>
                        <div className='base-info'>
                            <h1>{deal.name} {dateToString(new Date(deal.closeDate, {prefix: '(', suffix: ')'}))}</h1>
                            <p>{renderNumber(deal.amount)}</p>
                            <p>{deal.description}</p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className='additional-info'>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        }

        <div id="hr-settings">
            <div className='tab-container'>
                <Paper className='paper'>
                    <Tabs variant="scrollable" value={activeTab} onChange={(e, tab) => changeActiveTab(tab)}>
                        <Tab label={strings.pages.crm.deal.dealDetails.record}
                             disabled={!hasPermission(auth.user, AppPermissions.DEAL_RECORDS.GROUP,
                                 AppPermissions.DEAL_RECORDS.VIEW, auth.permissions)}
                        />
                        <Tab label={strings.pages.crm.deal.dealDetails.files}
                             disabled={!hasPermission(auth.user, AppPermissions.DEAL.GROUP,
                                 AppPermissions.DEAL.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.crm.deal.dealDetails.contacts}
                             disabled={!hasPermission(auth.user, AppPermissions.PARTNER_CONTACT.GROUP,
                                 AppPermissions.PARTNER_CONTACT.VIEW_LIST, auth.permissions)}
                        />
                    </Tabs>
                </Paper>
            </div>
        </div>

        {
            activeTab === DealDetailsTab.RECORD &&
            <DealRecord deal={deal}/>
        }
        {
            activeTab === DealDetailsTab.FILES &&
            <DocumentList />
        }
        {
            activeTab === DealDetailsTab.CONTACT &&
            <PartnerContacts deal={deal}/>
        }
    </div>
}

export default DealDetails;
