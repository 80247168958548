import { request } from "../../Base/HTTP"
import HttpMethod from "../../Constants/Base/HttpMethod"


export const getWorkingHoursRange = async (id) => {
    return await request(`/api/working-hours-range/${id}`);
}

export const saveWorkingHoursRange = async (data) => {
    return await request('/api/working-hours-range-save', data, HttpMethod.POST);
}

export const deleteWorkingHoursRange = async (id, data) => {
    return await request(`/api/working-hours-range-delete/${id}`,data, HttpMethod.DELETE);
}

export const addWorkingHoursAdditionalRange = async (data) => {
    return await request(`/api/working-hours-additional-range-add`, data, HttpMethod.POST);
}

export const getWorkingHoursRangeByWeekDay = async  (id, data) => {
    return await request(`/api/working-hours-range-by-week-day/${id}`, data, HttpMethod.GET);
}

export const changeDefault = async (id, data) => {
    return await request(`/api/change-default/${id}`, data, HttpMethod.POST);
}

export const saveDefaultWorkingRange = async (id,data) => {
    return await request(`/api/save-default-working-hour/${id}`, data, HttpMethod.POST);
}
export const copyWorkingHourRange = async (data) => {
    return await request(`/api/working-hour-range/copy`,data,HttpMethod.PUT)
}
