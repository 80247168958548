import React, {useContext, useEffect, useState} from "react";
import MenuState from "../../Constants/Base/MenuState";
import {Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip} from "@mui/material";
import strings from "../../localization";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {clearUserData, clearUserDataLock, setUserToLocalStorage} from "../../Base/OAuth";
import {CompanyAvatar, UserAvatar, FavoriteBoardLogo} from "../../Util/AvatarUtil";
import {getAllUserCompanyAccess} from "../../Services/User/UserCompanyAccessService";
import {setAuth, setCompany, setIsCheckIn, setUser, setFavoriteBoards} from "../../Slices/AuthSlice";
import {switchCompany, changeUserPreference} from "../../Services/User/UserService";
import Button from "@mui/material/Button";
import {startCompanyFreeTrial} from "../../Services/Company/CompanyService";
import { getFavoriteBoards } from "../../Services/Workspaces/BoardsService";
import {getUserFromLocalStorage} from "../../Base/HTTP";
import SnackbarContext from "../../Context/SnackbarContext";
import { checkIn, checkOut, getIsCheckIn } from "../../Services/HR/PresenceService";
import TimeElapsedCounter from "../Counter/TimeElapsedCounter";
import store from "../../store";
import { isCompanyValid } from "../../Util/CompanyUtil";
import { changeMenuState } from "../../Slices/NavigationSlice";
import { hasPermission } from "../../Util/PermissionUtil";
import AppPermissions from "../../Constants/Permissions/Permissions";

const getHeaderClass = (menuState) => {

    if(menuState === MenuState.SHORT) {
        return 'short';
    }

    return '';
}

const Header = () => {
    const menuState = useSelector((state) => state.navigation.menuState)
    const favoriteBoards = useSelector((state) => state.auth.favoriteBoards);
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
    const [favoriteBoardsMenuAnchorEl, setFavoriteBoardsMenuAnchorEl] = useState(null);
    const [presanceAnchorEl, setPresanceAnchorEl] = useState(null);
    const [companies, setCompanies] = useState([]);
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);
    const company = useSelector((state) => state.auth.company);
    const { showMessage } = useContext(SnackbarContext);
    const isCheckIn = useSelector((state) => state.auth.isCheckIn);
    const [stopInterval, setStopInterval] = useState(false);
    const [checkInTime, setCheckInTime] = useState(null);
    const [hoverFavoriteBoard, setHoverFavoriteBoard] = useState(null);
    const permissionGroupBoards = AppPermissions.BOARDS;

    useEffect(()=>{
        if(!user){
            return;
        }
        getAllUserCompanyAccess({userId: user.id}).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setCompanies(response.data)
        })
    },[user]);

    useEffect(() => {
        if(!user) {
            return;
        }

        if(!hasPermission(auth.user, permissionGroupBoards.GROUP, permissionGroupBoards.VIEW, auth.permissions) || !hasPermission(auth.user, permissionGroupBoards.GROUP, permissionGroupBoards.VIEW_LIST, auth.permissions)){
            return;
        }

        getFavoriteBoards().then(response => {
            if(!response || !response.ok) {
                return;
            }

            dispatch(setFavoriteBoards(response.data));
        });
    },[user]);

    const handleClick = event => {
        setProfileMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setProfileMenuAnchorEl(null);
    };

    const logout = () => {
        handleClose();
        clearUserData();

        navigate('/');
    }

    const lock = () => {
        handleClose();
        clearUserDataLock();
        navigate('/lock');
    }

    const profile = () => {
        handleClose();
        navigate('/profile');
    }

    
    const handlePresanceMenuClose = () => {
        setPresanceAnchorEl(null);
    }

    const handlePresanceMenuClick = (event)=>{
        setPresanceAnchorEl(event.currentTarget);
    }

    const switchToSelectedCompany = (company) => {

        switchCompany(company.id).then(response => {

            let data = {
                ...response.data.user,
                isOwner: response.data.isOwner,
                permissions: response.data.permissions
            }

            setUserToLocalStorage(data);
            dispatch(setAuth(data));
            navigate('/');
        })
        handleClose();
    }

    const isCurrentCompany = (company) => {
        if(!user || !user.company) {
            return false;
        }

        return user.company.id === company.id;
    }

    const renderMenuCompanies = () => {
        let result = [];
        for(let userCompanyAccess of companies) {
            result.push(     
                <Tooltip title={userCompanyAccess.company.name}>
                    <div>
                        <MenuItem key={'company-' + userCompanyAccess.id}
                                className={'company-item'}
                                onClick={ () => switchToSelectedCompany(userCompanyAccess.company) }
                                disabled={isCurrentCompany(userCompanyAccess.company)}>
                            <div className='company-icon'><CompanyAvatar company={userCompanyAccess.company} width={30} height={30}/></div>
                            <div className="company-name">{userCompanyAccess.company.name}</div>           
                        </MenuItem> 
                    </div>
                </Tooltip>              
            )
        }
        return result;
    }

    const fetchIsCheckIn = () => {
        getIsCheckIn().then(response => {
            if(!response.ok || !response){
                return;
            }
            store.dispatch(setIsCheckIn(response.data.entity))
        })
    }

    useEffect(() => {
        if(!checkInTime && isCheckIn) {
            setCheckInTime(isCheckIn?.start);
        }
    },[isCheckIn]);

    const checkingIn = (data) => {
        data = Object.create(data);
        data.start = new Date()
        setCheckInTime(data.start);
        setStopInterval(false);

        checkIn(data).then(response => {
            if(!response || !response.ok){
                setCheckInTime(null);
                setStopInterval(true);
                showMessage(strings.checkInOutMessages.errorCheckIn, 'error');
                return;
            }
            store.dispatch(setIsCheckIn(response.data.entity))
            showMessage(strings.checkInOutMessages.checkIn, 'success');
        })
    }

    const checkingOut = (data) => {
        data = Object.create(data);
        data.end = new Date()
        setCheckInTime(null);
        setStopInterval(true);
        
        checkOut(data).then(response => {
            if(!response || !response.ok){
                setCheckInTime(isCheckIn.start);
                setStopInterval(false);
                showMessage(strings.checkInOutMessages.errorCheckOut, 'error');
                return;
            }
            fetchIsCheckIn();
            showMessage(strings.checkInOutMessages.checkOut, 'success');
        })
    }

    const isFull = () => menuState === MenuState.FULL

    const changeNavigationState = (state) => {
        dispatch(changeMenuState(state));

        changeUserPreference({navigationState: state}).then(response => {
            if(!response || !response.ok){
                return;
            }
            dispatch(setUser({...user, ...response.data.user}));
        });
    }

    const handleFavoriteBoardsMenu = (e) => {
        setFavoriteBoardsMenuAnchorEl(e.currentTarget);
    }

    const handleCloseFavoritesBoardsMenu = () => {
        setFavoriteBoardsMenuAnchorEl(null);
    }

    const handleFavoriteBoardHover = (e, favoriteBoard) => {
        setHoverFavoriteBoard(favoriteBoard.id);
    }

    const handleFavoriteBoardClick = (e, favoriteBoard) => {
        navigate(`/workspaces/${favoriteBoard.board.workspace.id}/boards/${favoriteBoard.board.id}`);
        handleCloseFavoritesBoardsMenu();
    }

    const renderFavoriteBoards = () => {
        if(!favoriteBoards?.length){
            return (
                <MenuItem className = 'favorite-boards-no-options'>
                    {strings.header.noOptions}
                </MenuItem>
            );
        }

        return favoriteBoards.map((favoriteBoard) => (
            <MenuItem
                key = {`favorite-boards-${favoriteBoard.id}`}
                onMouseEnter= {(e) => handleFavoriteBoardHover(e, favoriteBoard)}
                onMouseLeave = {() => setHoverFavoriteBoard(null)}
                onClick = {(e) => handleFavoriteBoardClick(e, favoriteBoard)}
            >
                <FavoriteBoardLogo board = {favoriteBoard.board} />
                <div className = 'favorite-board-name-workspace'>
                    <p>{favoriteBoard.board.name}</p>
                    <p>{strings.header.workspace}: {favoriteBoard.board.workspace.name}</p>
                </div>
                {hoverFavoriteBoard === favoriteBoard.id &&
                    <div className = 'favorite-board-arrow-container'>
                        <img src = '/images/arrow-right.svg' />
                    </div>
                }
            </MenuItem>
        ));
    }

    return <div id='header' className={getHeaderClass(menuState) }>
            <div className = 'header-left'>
                <div className={"collapse-button-container"}>
                    {
                        isFull() &&
                        <IconButton size='small' onClick={ () => changeNavigationState(MenuState.SHORT)}>
                            <img src="/images/save-state-menu.svg" loading="lazy"/>
                        </IconButton>
                    }

                    {
                        !isFull() &&
                        <IconButton size='small' onClick={ () => changeNavigationState(MenuState.FULL)}>
                            <img src="/images/cancel-state-menu.svg" loading="lazy"/>
                        </IconButton>
                    }
                </div>
                {(hasPermission(auth.user, permissionGroupBoards.GROUP, permissionGroupBoards.VIEW, auth.permissions) && hasPermission(auth.user, permissionGroupBoards.GROUP, permissionGroupBoards.VIEW_LIST, auth.permissions)) &&
                    <div className = 'favorite-boards-menu-container'>
                        <Button variant='contained'
                            onClick = {handleFavoriteBoardsMenu}
                        >
                            <img src = '/images/boards-star-full.svg' />
                            {strings.header.favoriteBoards}
                            <img className = {Boolean(favoriteBoardsMenuAnchorEl) ? 'favorite-boards-collapse-arrow rotate-collapse-arrow' : 'favorite-boards-collapse-arrow'} src = '/images/show-less.svg' />
                        </Button>
                        <Menu
                            id='favorite-boards-menu'
                            anchorEl={favoriteBoardsMenuAnchorEl}
                            open={ Boolean(favoriteBoardsMenuAnchorEl) }
                            onClose={ () => handleCloseFavoritesBoardsMenu() }
                        >
                            {favoriteBoards && renderFavoriteBoards()}
                        </Menu>
                    </div>
                }
            </div>

        <div className="account-container">
            {
                isCompanyValid(company) && 
                <img
                    className='presance-clock' 
                    src= {checkInTime ? "/images/clock-icon-active.svg" : "/images/clock-icon-inactive.svg"} 
                    onClick={(event) => handlePresanceMenuClick(event)}/>
            }
            <Menu
            id='presance-menu'
            anchorEl={presanceAnchorEl}
            open={Boolean(presanceAnchorEl)}
            onClose={handlePresanceMenuClose}>
                {checkInTime ? 
                    <div className="checked-in">
                        <TimeElapsedCounter date = {checkInTime} stop={stopInterval} />
                        <Button  variant="contained" color="primary" onClick={checkingOut}>
                            {strings.checkInOutMessages.checkOut}
                        </Button>
                    </div>
                    :
                    <div className="checked-out">
                        <label>{`00:00:00`}</label>
                        <Button  variant="contained" color="primary" onClick={checkingIn}>
                            {strings.checkInOutMessages.checkIn}
                        </Button> 
                    </div>}
            </Menu>
            
            <div className="company-user-container"  >             
                <div className="company"  onClick={handleClick}>
                    <CompanyAvatar company={company} width={50} height={35} options={{variant: 'rounded'}}/>
                </div>
                <div className="user"  onClick={handleClick}>
                    <UserAvatar user={user} size={50}/>
                </div>
                <Menu
                id='company-user-menu'
                anchorEl={profileMenuAnchorEl}
                open={ Boolean(profileMenuAnchorEl) }
                onClose={ () => handleClose() }
                >
                    <div className="user-info">
                        <div className="full-name">{user?.fullName}</div>
                        <div className="email">{user?.email}</div>
                    </div>            
                    <MenuItem onClick={ () => profile() }>
                        <ListItemText inset primary={ strings.header.profile }/>
                    </MenuItem>
                    <MenuItem onClick={ () => lock() }>
                        <ListItemText inset primary={ strings.header.lock }/>
                    </MenuItem>
                    <MenuItem onClick={ () => logout() }>
                        <ListItemText inset primary={ strings.header.logout }/>
                    </MenuItem>
                    <Divider/>
                    <div className="company-label">{strings.header.companyProfiles}</div>
                    {renderMenuCompanies()}
                </Menu>
            </div>       
        </div>                        
 
    </div>       
};
export default Header;
