import React, {useState, useEffect, useRef} from 'react';
import {IconButton, Modal, Paper, Step, StepLabel, Stepper} from "@mui/material";
import {
    getCompanyDocumentTemplateSteps
} from "../../../Services/CompanyDocumentManagement/CompanyDocumentTemplateStepService";
import {
    getCompanyDocumentTemplateQuestions
} from "../../../Services/CompanyDocumentManagement/CompanyDocumentTemplateQuestionService";
import {useLocation, useNavigate} from "react-router-dom";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import Modules from "../../../Constants/Base/Modules";
import {useDispatch} from "react-redux";
import {BackButton} from "../../../Util/ImageUtil";
import TextFieldControl from "../../../Components/Controls/Inputs/TextFieldControl";
import {useForm} from "react-hook-form";
import DateTimeControl from "../../../Components/Controls/Inputs/DateTimeControl";
import AddCompanyDocumentForm
    from "../../../Components/Forms/Pages/CompanyDocument/CompanyDocument/AddCompanyDocumentForm";
import {addCompanyDocument} from "../../../Services/CompanyDocumentManagement/CompanyDocumentService";
import {dateToStringDatePickerFormat} from "../../../Util/DateUtil";
import {
    CompanyDocumentQuestionType,
    getCompanyDocumentQuestionTypes
} from "../../../Constants/CompanyDocumentManagement/CompanyDocumentItem/CompanyDocumentQuestionType";
import DateFormats from "../../../Constants/Company/DateFormats";
import store from "../../../store";
import {
    getCompanyDocumentTemplateQuestionOptions
} from "../../../Services/CompanyDocumentManagement/CompanyDocumentTemplateQuestionOptionService";
import SelectControl from "../../../Components/Controls/Inputs/SelectControl";
import {FormProvider} from "react-hook-form";
import RadioButtonControl from "../../../Components/Controls/Inputs/RadioButtonControl";


const formRules = {
    'required': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
};

const formRulesDocument = {
    'name': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
};


const CompanyDocumentsForms = ({template}) => {
    const dispatch = useDispatch();
    const [steps, setSteps] = useState([]);
    const [activeStepIndex, setActiveStepIndex] = useState(1);
    const [completedSteps, setCompletedSteps] = useState([]);
    const [allQuestions, setAllQuestions] = useState([]);
    const [stepQuestions, setStepQuestion] = useState([]);
    const [currentStepQuestions, setCurrentStepQuestion] = useState([]);
    const location = useLocation();
    const [handleShowModal, setHandleShowModal] = useState(true);
    const [defaultDate, setDefaultDate] = useState({});
    const navigate = useNavigate();
    const basicData = useRef({data: null});
    const [questionOptions, setQuestionOptions] = useState({});
    const [validationForm, setValidationForm] = useState([]);
    const [backDisabled, setBackDisabled] = useState(true);

    const form = useForm({
        defaultValues: defaultDate
    });

    const formDocument = useForm();

    const {data, control, handleSubmit, getValues, setValue, formState: {errors}, reset} = form;

    const {dataDocument, controlDocument, handleSubmit: handleSubmitDocument, getValuesDocument, setValueDocument, formState: {errors: errorsDocument}} = formDocument;

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.companyDocument.companyDocument.newCompanyDocument,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.DOCUMENTS},
                {label: strings.pages.companyDocument.companyDocument.companyDocumentList.pageTitle},
            ],
        }));
    }, [])

    useEffect(() => {
        fetchSteps()
    }, [])

    useEffect(() => {
        for(let question of currentStepQuestions){
            if(question.type === CompanyDocumentQuestionType.SELECT ||
               question.type === CompanyDocumentQuestionType.RADIO){
                fetchQuestionOptions(question)
            }
        }
    }, [currentStepQuestions])

    const transformFormData = (data) => {
        let transformedData = {...data};
        stepQuestions.forEach(question =>{
            if(Number(question.type) === CompanyDocumentQuestionType.DATE){
                const date = data[question.code]
                transformedData[question.code] = dateToStringDatePickerFormat(
                    new Date(date),
                    DateFormats.getOrDefault(store.getState()?.auth?.localisationSettings?.dateFormat));
            }
            if(question.type === CompanyDocumentQuestionType.SELECT){
                const selectValue = transformedData[question.code]?.option;
                transformedData[question.code] = selectValue;
            }
        })
        return {data: transformedData};
    }

    const submitForm = (submitedData) => {
        
        const transformedData = transformFormData(submitedData);
        const data = {...basicData.current.data,
            ...transformedData,
            companyDocumentCategoryId: location.state?.template.companyDocumentCategory.id,
            companyDocumentTemplateId: location.state?.template.id,
            folder: location.state?.folder}

        if(validate(form.getValues())) {
            addCompanyDocument(data).then(response => {
                const companyDocument = response.data.entity;
                navigate('/document-content', {state: {companyDocument}});
            })
        }
    }

    const fetchSteps = () => {
        const data = {'companyDocumentTemplateId': location.state?.template.id}
        getCompanyDocumentTemplateSteps(data).then(response => {
            setSteps(response.data.result)
            for(let step of response.data.result){
                fetchAllStepQuestions(step)
            }
            if(response.data.result.length > 0){
                fetchStepQuestions(response.data.result[activeStepIndex - 1])
            }
        })
    }

    const fetchAllStepQuestions = (step) => {
        const data = {'step': step.id}
        getCompanyDocumentTemplateQuestions(data).then(response => {
            for(let question of response.data.result){
                if(!stepQuestions.find(item => item.code === question.code)) {
                    setStepQuestion(stepQuestions => [...stepQuestions, question])
                }
            }
        })
    }

    const fetchStepQuestions = (step) => {
        const data = {'step': step.id}
        getCompanyDocumentTemplateQuestions(data).then(response => {
            setCurrentStepQuestion(response.data.result)
        })
    }
    const nextStep = () => {
        setBackDisabled(false);
        if(steps.length > activeStepIndex){
            setCompletedSteps([...completedSteps, activeStepIndex])
            setActiveStepIndex(active => active+1)
            fetchStepQuestions(steps[activeStepIndex])
            return
        }
        if(steps.length === activeStepIndex){
            handleSubmit(submitForm)();
        }
    }

    const backStep = () => {
        if(activeStepIndex <= 1) {return }
        if((activeStepIndex - 1) === 1) {
            setBackDisabled(true);
        }
        setCompletedSteps(completedSteps.splice(-1))
        setActiveStepIndex(active => active-1)
        fetchStepQuestions(steps[activeStepIndex-2])
    }

    const fetchQuestionOptions = (question) => {
        const data = {questionId: question.id}
        getCompanyDocumentTemplateQuestionOptions(data).then(response => {
            setQuestionOptions((questionOptions) => {
                return {
                    ...questionOptions,
                    [question.code]: response.data.result,
                };
            });
        })
    }

    const renderFormField = (question) => {
        if(question.type == CompanyDocumentQuestionType.TEXT){
            return (
              <div className="form-field" key={question.code}>
                  <TextFieldControl
                      name={question.code}
                      className='generated-form-field'
                      control={control}
                      error={Boolean(errors[question.code])}
                      helperText={errors[question.code] && errors[question.code].message}
                      label={question.question}
                  />
              </div>
            );
        }
        if(question.type == CompanyDocumentQuestionType.NUMBER){
            return (
                <div className="form-field" key={question.code}>
                    <TextFieldControl
                        name={question.code}
                        className='generated-form-field'
                        control={control}
                        type="number"
                        error={Boolean(errors[question.code])}
                        helperText={errors[question.code] && errors[question.code].message}
                        label={question.question}
                    />
                </div>
            );
        }
        if(question.type == CompanyDocumentQuestionType.DATE){
            return(
                <div className="form-field" key={question.code}>
                    <DateTimeControl
                        className="generated-form-field"
                        data={control}
                        label={question.question}
                        value={getValues(question.code)}
                        name={question.code}
                        error={Boolean(errors[question.code])}
                        helperText={errors[question.code] && errors[question.code].message}
                        setValue={setValue}
                    />
                </div>
            )
        }
        if(question.type == CompanyDocumentQuestionType.SELECT){
            return (
                <div className="form-field" key={question.code}>
                    <SelectControl
                        className='generated-form-field'
                        value={getValues(question.code)}
                        setValue={setValue}
                        control={control}
                        name={question.code}
                        error={Boolean(errors[question.code])}
                        helperText={errors[question.code] && errors[question.code].message}
                        label={question.question}
                        options={questionOptions[question.code]}
                        nameKey={'option'}
                        valueKey={'option'}
                    />
                </div>
            )
        }
        if(question.type == CompanyDocumentQuestionType.RADIO){
            return (
                <div className="form-field" key={question.code}>
                    <RadioButtonControl
                        className='generated-form-field'
                        value={getValues(question.code)}
                        setValue={setValue}
                        control={control}
                        name={question.code}
                        error={Boolean(errors[question.code])}
                        helperText={errors[question.code] && errors[question.code].message}
                        label={question.question}
                        options={questionOptions[question.code]}
                    />
                </div>
            )
        }
    }

    const createFormFields = () => {
        let formFields = [];

        for(let question of currentStepQuestions) {
            formFields.push(
                renderFormField(question)
            )
            if(question.type == CompanyDocumentQuestionType.DATE && !getValues(question.code)){
                const date = new Date();
                setValue(question.code, date)
            }
        }
        return formFields;
    }

    const renderForm = () => {
        return (
            createFormFields()
        )
    }

    const handleCloseModal = () => {
        navigate(-1);
        form.reset();
    }

    const submitHandler = (data) => {
        basicData.current.data = data;
        setHandleShowModal(false);
    }


    const validate = (data) => {
        let indexError = -1;

        stepQuestions.forEach((question) => {
            if (question.required && !data[question.code]) {
                form.setError(question.code, { type: "required", message: strings.forms.common.thisFieldIsRequired });
                const newIndexError = steps.findIndex(step => step.id === question.companyDocumentTemplateStep.id);
                indexError = (indexError === -1 || indexError > newIndexError) ? newIndexError : indexError;
            } else {
                form.clearErrors(question.code);
            }
        });
        if(indexError !== -1){
            onStepClick(indexError+1)
            return false;
        }
        return true;
    }

    const onStepClick = (index) => {
        if(index === 1){
            setBackDisabled(true);
        }else{
            setBackDisabled(false);
        }
        setActiveStepIndex(index);
        fetchStepQuestions(steps[index-1]);
        handleSubmit(nextStep);
    }

        return (
            <div className="form-page">
                <Paper sx={{ broderRadius: 10}} className="stepper">
                    <div className="template-stepper">
                        <Stepper activeStep={1} alternativeLabel={true}>
                            {steps.map((step, index) => {
                                index = index + 1;
                                return <Step key={step.name} onClick={() => onStepClick(index)} active={(index === activeStepIndex)} completed={(index < activeStepIndex)}>
                                    <StepLabel>{step.name}</StepLabel>
                                </Step>
                            })}
                        </Stepper>
                    </div>
                </Paper>
                <Paper className="form">
                    <div className="form-header">
                        <button className="back-button-btn" onClick={backStep} disabled={backDisabled}>
                        <div className="back-button" ><div className="back-button-icon"><BackButton></BackButton></div>
                            <div className="back-text">
                            {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateStep.back}
                            </div>
                        </div>
                        </button>
                        <div className="step-name"></div>
                    </div>
                    <div className="form-container">
                        <FormProvider {...form}>
                            <form className="generated-form" onSubmit={(e) => e.preventDefault()}>
                                {stepQuestions && renderForm()}
                            </form>
                        </FormProvider>
                    </div>
                    <div className="form-footer">
                        <IconButton className='next-button' onClick={nextStep}>
                            <div className='next-text'>
                                {(activeStepIndex !== steps.length) ?
                                    strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateStep.next
                                    : strings.pages.document.document.addDocument.submitDocumentForm}
                            </div>
                        </IconButton>
                    </div>
                </Paper>
                <div>
                    <Modal
                        open={Boolean(handleShowModal)}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        id='add-edit-template-modal'>
                        <Paper>
                            <img className='add-edit-template-modal-close' src='/images/close-issue-view.svg' onClick = {handleCloseModal} />
                            <div className='add-edit-template-modal-title'>{strings.pages.companyDocument.companyDocument.newCompanyDocument}</div>
                            <AddCompanyDocumentForm
                                formRules={formRulesDocument}
                                setValue={setValueDocument}
                                errors={errorsDocument} formData={dataDocument} form={formDocument}
                                onSubmit={handleSubmitDocument(submitHandler)}/>
                        </Paper>
                    </Modal>
                </div>
            </div>
        );
}

export default CompanyDocumentsForms;