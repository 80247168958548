import React, {useContext, useEffect} from "react";
import {useState} from "react";
import strings from "../../localization";
import {useDropzone} from "react-dropzone";
import SnackbarContext from "../../Context/SnackbarContext";
import { LoadingButton } from "@mui/lab";
import { getSizeDescription } from "../../Util/FileUtil";

const FileUploadComponent = ({formats:accept= null, maxSize= null, loading= false, files, setFiles, ...props}) => {

    const {acceptedFiles, getRootProps, getInputProps, fileRejections} = useDropzone({
        onDrop: files => onDrop(files),
        accept,
        maxSize,
    });
    const { showMessage } = useContext(SnackbarContext);


    useEffect(() => {
        if (fileRejections.length>0) {
            showMessage(strings.error.File[fileRejections[0].errors[0].code], strings.components.fileUploadComponent.error)
        }
    }, [fileRejections])

    const onDrop = (files) =>
    {
        setFiles(files)
        if (props.onDrop) {
            props.onDrop(files)
        }

    }

    const  canUpload = () =>
    {
        if(props.canUpload !== undefined &&
            props.canUpload !== null) {
            return props.canUpload && files && files.length > 0
        }

        return files && files.length > 0;
    }

    const renderFiles = () => {
        return files.map(file => (
            <div key={file.name} className="file-row">
                <div className="description">{`${file.name}  ${file.size ? getSizeDescription(file.size) : getSizeDescription(file.documentSize)}`}</div>
            </div>
        ));
    }

    return (
        <div className='file-upload-component'>
            <section className="drop-zone">
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>{strings.components.fileUploadComponent.dragDrop}</p>
                </div>
                { !props?.hideUploadButton &&
                    <div className='meta-data'>
                        <LoadingButton variant="contained" color="primary" className="rounded-button"
                                onClick={() => props.upload(files)}
                                disabled={!canUpload()}
                                loading={loading}>
                            <img src = {'/images/folder-upload.svg'} />
                            {strings.components.fileUploadComponent.upload}
                        </LoadingButton>
                        <aside>
                            <div className="uploaded-files">{renderFiles()}</div>
                        </aside>
                    </div>
                }
                { props?.showOnlyDescription &&
                    <div className='meta-data'>
                        <aside>
                            <div className="uploaded-files">{renderFiles()}</div>
                        </aside>
                    </div>
                }
            </section>
        </div>
    );

}
export default FileUploadComponent
