import {useForm} from "react-hook-form";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import LinearProgress from '@mui/material/LinearProgress';
import ProjectForm from "../../../Components/Forms/Pages/Project/Project/ProjectForm";
import {editProject, getAllProjectCategories} from "../../../Services/Project/ProjectService";
import SnackbarContext from "../../../Context/SnackbarContext";
import { getPartners } from "../../../Services/CRM/PartnerService";

const formRules = {
    'name': {required: true},
    'projectCategory': {required: true},
}

const EditProject = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? {
            ...props.data[0],
            startDate: props.data[0].startDate || NaN,
            endDate: props.data[0].endDate || NaN
        } : undefined
    });
    const {data, handleSubmit, getValues, setValue, trigger, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [projectCategories, setProjectCategories] = useState([]);
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        setDrawerTitle(strings.pages.project.project.editProject.pageTitle);
    });

    useEffect(() => {
        fetch();
        fetchPartners();
    }, [])

    const fetch = useCallback(() => {
        getAllProjectCategories({}).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setProjectCategories(response.data)
        });
    }, [])

    const fetchPartners = (term = '') => {
        getPartners({term}).then(response => {
            if(!response || !response.ok){
                return;
            }
            setPartners(response.data.result);
        })
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        editProject(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <ProjectForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue} trigger={trigger}
                projectCategories={projectCategories} onChangePartnerFilter={fetchPartners}
                errors={errors} data={data} form={form} partners={partners}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditProject;