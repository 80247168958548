import React from "react";
import { IconButton } from "@mui/material";
import { downloadTravelWarrant } from "../../../Services/HR/BusinessTripService";
import strings from "../../../localization";

const TravelWarrantList = ({ travelWarrant, onDelete }) => {
  const download = (id) => {
    downloadTravelWarrant(id).then((response) => {
      if (!response || !response.ok) {
        return;
      }
    });
  };

  return (
    <div className="business-trip-details">
      <div className="business-trip-item">
        <div className="business-trip-elements">
          <div className="business-trip-details">
            <strong>
              {strings.pages.hr.businessTrip.businessTripForm.documentName}
              {": "}
            </strong>
            {travelWarrant.business_trip.documentName}
          </div>

          <div className="icons-decision">
            <IconButton
              onClick={() => download(travelWarrant.business_trip.id)}
            >
              <img src="/images/download-pdf-blue.svg" />
            </IconButton>
            <IconButton onClick={() => onDelete()}>
              <img src="/images/delete-blue.svg" />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelWarrantList;
