import React from "react";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import PageState from "../../../Constants/Base/PageState";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import strings from "../../../localization";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import {changePageSizeState} from "../../../Slices/PageSlice";
import {deletePipeline, getPipelines} from "../../../Services/Company/PipelineService";
import AddPipeline from "./AddPipeline";
import {formatColumnConstant} from "../../../Components/DataGrid/ValueFormatter";
import PipelineType, {getPipelineTypeString} from "../../../Constants/Company/PipelineType";
import EditPipeline from "./EditPipeline";
import {LockOpen} from "@mui/icons-material";
import {useNavigate, useSearchParams} from "react-router-dom";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [
    {field: 'name', headerName: strings.pages.administration.pipeline.pipelineList.name, width: 250},
    {
        field: 'type',
        headerName: strings.pages.administration.pipeline.pipelineList.type,
        width: 250,
        valueFormatter: (params) => formatColumnConstant(params, getPipelineTypeString),
    }
];

const filters = []

const PipelineList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.PIPE_LINE;
    const permissionGroup = AppPermissions.PIPE_LINE.GROUP;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog, navigate,
        updatedFilter, setUpdatedFilter
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.administration.pipeline.pipelineList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.ADMINISTRATION},
                {label: strings.pages.administration.pipeline.pipelineList.pageTitle},
            ],        
        }));

        return () => {
            setTableData({})
        }
    }, []);

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter]);

    const additionalMenuItems = [
        {handleClick: id => handlePipelineStage(id), text: strings.pages.administration.pipeline.pipelineList.pipelineStages, src: '/images/table-page/pipeline-stages.svg'},
        {handleClick: (id,item) => handlePipelineAccesses(id, item), text: strings.pages.administration.pipeline.pipelineList.pipelineAccesses, src: '/images/table-page/pipeline-access.svg',
            hide: (pipeline) => !(pipeline.type === PipelineType.CRM || pipeline.type === PipelineType.TASK) },
    ]

    const handlePipelineStage = (id) => {
        navigate('/pipelines/' + id)
    }

    const handlePipelineAccesses = (id, pipeline) => {
        navigate('/pipeline-accesses', {state: {pipeline}})
    }

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        })

        getPipelines({
            ...filter,
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        });
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deletePipeline} tableDescription={columns}
                   tableData={tableData} filter={filter} filters={filters}
                   permissionGroup={permissionGroup}
                   additionalMenuItems={additionalMenuItems} referenceType={referenceType}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditPipeline data={selectedItems}
                                           onFinish={() => onFinish()} onCancel={() => onCancel()}/>}
                   addPage={<AddPipeline onFinish={() => onFinish()} onCancel={() => onCancel()}/>}
                   addButtonText={strings.components.tablePage.addButton.pipeline}
        />
    </TablePageContext.Provider>
}

export default PipelineList;
