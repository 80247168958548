import React, {useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {Checkbox, Grid, LinearProgress, Paper} from "@mui/material";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import {getProjectTaskCategory, updateProjectTaskCategory} from "../../../Services/Project/ProjectTaskCategoryService";
import {getTaskCategories} from "../../../Services/Project/TaskCategoryService";
import {Switch} from "@mui/material";
import { getClasses } from "../../../Util/SwitchUtil";
import Modules from "../../../Constants/Base/Modules";
import LoaderContext from "../../../Context/LoaderContext";

const ProjectTaskCategoryList = ({project = null}) => {

    const dispatch = useDispatch();

    const [taskCategories, setTaskCategories] = useState([])
    const [projectTaskCategories, setProjectTaskCategories] = useState([])
    const {isLoading, setLoading} = useContext(LoaderContext)

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.project.projectTaskCategory.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.PROJECTS},
                {label: strings.pages.project.details},
                {label: strings.pages.project.projectTaskCategory.pageTitle},
            ],        
        }));

        fetchProjectTaskCategories();
    }, [])

    useEffect(() => {
        fetchTaskCategories();
    }, [projectTaskCategories])

    const taskCategoryInProject = (taskCategoryId) => {
        let result = {allowed: false, billable: false};
        for(let projectTaskCategory of projectTaskCategories) {
            if(projectTaskCategory.taskCategory.id === taskCategoryId && projectTaskCategory.project) {
                result.allowed = projectTaskCategory.allowed;
                result.billable = projectTaskCategory.billable;
                break;
            }
        }
        return result;
    }

    const fetchTaskCategories = () => {
        getTaskCategories().then(response => {
            if(!response || !response.ok) {
                return;
            }
            let users = response.data.result.map(taskCategory => ({...taskCategory, ...taskCategoryInProject(taskCategory.id)}));
            setTaskCategories(users)
        });
    }

    const fetchProjectTaskCategories = () => {
        if(!project) {
            return;
        }
        setLoading(true);
        getProjectTaskCategory(project.id).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setProjectTaskCategories(response.data.result)
        }).finally(() => setLoading(false));
    }

    const changeAllowedOrBillable = (taskCategory, property) => {

        let value = false;

        for(let u of taskCategories) {
            if(u.id === taskCategory.id) {
                u[property] = !u[property];
                value = u[property];
            }
        }

        let data = {
            projectId: project.id,
            taskCategoryId: taskCategory.id,
            [property]: value
        }

        updateProjectTaskCategory(data).then(response =>{
            if(!response || !response.ok) {
                return;
            }
            let replaced = 0;
            let copyProjectTaskCategories = [...projectTaskCategories].map(a => {
                if (a.id === response.data.entity.id) {
                    a = response.data.entity;
                    replaced++;
                }
                return a;
            });
            if (!replaced) {
                copyProjectTaskCategories.push(response.data.entity);
            }
            setProjectTaskCategories(copyProjectTaskCategories);
        });
    }

    const renderElements = () => {
        let result = [];
        for(let taskCategory of taskCategories) {
            result.push(
                <>
                    <Grid item md={2} className='d-flex align-center'><label>{ taskCategory.name }</label></Grid>
                    <Grid item md={2}>
                        <Switch
                            className={`${getClasses(false, taskCategory.allowed)}`}
                            checked={taskCategory.allowed}
                            onChange={() => changeAllowedOrBillable(taskCategory, 'allowed')}
                        />
                    </Grid>
                    <Grid item md={8}>
                        <Switch
                            className={`${getClasses(false, taskCategory.billable)}`}
                            checked={taskCategory.billable}
                            onChange={() => changeAllowedOrBillable(taskCategory, 'billable')}
                            />
                    </Grid>
                </>
            )
        }
        return result;
    }



    return (
        <div className='page-form-container'>
            <Paper className='paper'>
                <div className='paper-checkbox'>
                    <div className='paper-checkbox-container'>
                        <div className='elements'>
                            <Grid container>
                                <Grid item md={2}><h3>{strings.pages.project.projectTaskCategory.taskCategory}</h3></Grid>
                                <Grid item md={2}><h3>{strings.pages.project.projectTaskCategory.allowed}</h3></Grid>
                                <Grid item md={8}><h3>{strings.pages.project.projectTaskCategory.billable}</h3></Grid>
                                { renderElements() }
                            </Grid>
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    );
}

export default ProjectTaskCategoryList;
