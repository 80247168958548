import { AppAccesses } from "../Constants/Permissions/Permissions";

export const isUserRole = (user, role) => {

    if(!user.roles || user.roles.length === 0) {
        return false;
    }

    for(let userRole of user.roles) {

        if(userRole === role) {
            return true;
        }
    }

    return false;
}

export const isUserOneOfRoles = (user, roles) => {

    for(let role of roles) {

        if(isUserRole(user, role)) {
            return true;
        }
    }

    return false;
}

const getUserWorkspaceAccesses = (workspaceAccesses) => {
    let accesses = {}
    workspaceAccesses.forEach((access) => accesses[access.workspace.id] = access.allowed);
    
    return accesses;
}

export const getUserAccesses = (accesses) => {
    let formatedAccesses = {}
    
    if(accesses[AppAccesses.WORKSPACES]){
        formatedAccesses[AppAccesses.WORKSPACES] = getUserWorkspaceAccesses(accesses[AppAccesses.WORKSPACES]);
    }
    
    return formatedAccesses;
}
