import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import LinearProgress from '@mui/material/LinearProgress';
import OrganisationUnitForm from "../../../Components/Forms/Pages/HR/OrganisationUnit/OrganisationUnitForm";
import { editOrganisationUnit, getOrganisationUnits } from "../../../Services/HR/OrganisationUnitService";
import { filterDropdownItem } from "../../../Util/DropdownUtil";
import SnackbarContext from "../../../Context/SnackbarContext";

const formRules = {
    'name': {required: true}
}

const EditOrganisationUnit = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? {...props.data[0], type:{id: props.data[0].type}} : undefined
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [parents, setParents] = useState([]);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.organisationUnit.editOrganisationUnit.pageTitle);
    });

    useEffect(() => {
        fetch();
    },[])

    const onSubmit = (data) => {
        setShowLoader(true);

        editOrganisationUnit(data).then(response => {
            setShowLoader(false);

            if(!response || !response.ok){
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    const fetch = (term = '') =>{
        getOrganisationUnits({term}).then(response => {
            if (!response || !response.ok){
                return;
            }
            setParents(response.data.result)
        })
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <OrganisationUnitForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                parents={parents} onChangeUserFilter={fetch}
                errors={errors} data={data} form={form} 
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}
            />
        </Paper>
    </div>

}

export default EditOrganisationUnit;