import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import TransactionList from "../Transactions/TransactionList";
import FileUploadComponent from "../../../Components/Controls/FileUploadComponent";
import {uploadBankReport} from "../../../Services/Finance/BankReportService";
import { getBanks } from "../../../Util/SiteDataUtil";
import ManageBankReportForm from "../../../Components/Forms/Pages/Finance/ManageBankReport/ManageBankReportForm";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import strings from "../../../localization";


const ManageBankReport = () => {

    const dispatch = useDispatch();
    const siteData = useSelector((state) => state.siteData);
    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [bankId, setBankId] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        dispatch(changeBreadcrumbs({
            title: strings.pages.finance.uploadBankReport.uploadBankReportList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.FINANCE},
                {label: strings.pages.finance.uploadBankReport.uploadBankReportList.pageTitle},
            ],        
        }));
    })

    const onDataChange = (item) => {
        if(item === null){
            setBankId(-1);
            return;
        }
        setBankId(item?.id);
    }

    const uploadFiles = (files) => {
        setLoading(true);
        let data = new FormData();

        for(let file of files) {
            data.append('bankReport[]', file);
        }

        data.append('bankId', bankId)

        uploadBankReport(data).then(response => {

        }).finally(()=> {
            setFiles([]);
            setLoading(false);
        });
    }

    const renderBankReportHeader = ()=>{
        return <div>
            <div style={{"width" : "300px"}}>
            <ManageBankReportForm
                values={getValues()}
                setValue={setValue}
                control={control}
                banks={getBanks(siteData)}
                onDataChange={onDataChange}
                errors={errors} data={data} form={form}
            />
        </div>

        <FileUploadComponent canUpload={bankId !== -1} upload={ uploadFiles } files = {files} setFiles = {setFiles} />
        </div>
    }
    return <React.Fragment>
        <TransactionList approved={false} hideActions={true} renderUploadBankReportHeader={renderBankReportHeader}/>
    </React.Fragment>
}

export default ManageBankReport;
