import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getTeamList(data) {
    return await request('/api/teams', data);
}

export async function getAllTeams() {
    return await request('/api/teams/all');
}

export async function getTeam(id) {
    return await request('/api/teams/' + id);
}

export async function addTeam(data) {
    return await request('/api/teams', transformRequestData(data), HttpMethod.POST);
}

export async function editTeam(data) {
    return await request('/api/teams/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteTeam(id) {
    return await request('/api/teams/' + id, {}, HttpMethod.DELETE);
}

export async function changeTeamLogo(id, data) {
    return await request(`/api/teams/${id}/logo`, data, HttpMethod.PATCH);
}

export async function getTeamLogo(id) {
    return await request(`/api/teams/${id}/logo`);
}


function transformRequestData(data) {

    return {
        ...data,
        organisationUnitId: data.organisationUnit ? data.organisationUnit.id : null,
    }
}
