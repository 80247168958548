import React, { Fragment, useEffect, useState } from 'react';
import { getEmployeeMinutesPerDay } from '../../../Services/User/UserService';
import strings from '../../../localization';
import SelectControl from '../../../Components/Controls/Inputs/SelectControl';
import {
  getMonths,
  getYears,
  getDaysOfMonthLabels,
} from '../../../Util/DateUtil';
import BarChart from '../../../Components/Charts/BarChart';

const options = {
  scalesShowValues: true,
  scales: {
    y: {
      ticks: {
        stepSize: 60,
        callback: (value) => {
          let hours = Math.floor(value / 60);
          return `${hours}h`;
        },
      },
    },
    x: {
      ticks: {
        autoSkip: false
      }
    }
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (context) => {
          let label = context.dataset.label;
          let currentValue = context.raw;
          let hours = Math.floor(currentValue / 60);
          let minutes = currentValue % 60;
          minutes = minutes > 9 ? minutes : `0${minutes}`;

          return `${label}: ${hours}:${minutes} `;
        },
      },
    },
    legend: {
      display: false,
    }
  },
};

const PresanceByDayChart = ({ employee }) => {
  const [hoursPerDay, setHoursPerDay] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  useEffect(() => {

    getEmployeeMinutesPerDay(employee.id, {
      month: selectedMonth,
      year: selectedYear,
    })
      .then((response) => {
        if (!response || !response.ok) {
          setHoursPerDay([]);
          return;
        }

        setHoursPerDay(response.data);
      });

  }, [employee, selectedMonth, selectedYear]);

  const yearChangeHandler = (selectValue) => {
    setSelectedYear(selectValue?.id);
  };
  const monthChangeHandler = (selectValue) => {
    setSelectedMonth(selectValue?.id);
  };
  
  return (
    <Fragment>
      <div id='chart-control'>
        <div className='header'>
          <div className = 'title'>{strings.pages.user.employeeStatistic.presenceByDay}</div>
          <div className={'filter-container right-filter'}>
            <div className={'search-container'}>
              <div className='filter-item'>
                <SelectControl
                  value={selectedYear}
                  setValue={(name, value) => yearChangeHandler(value)}
                  label={strings.pages.user.employeeStatistic.yearLabel}
                  options={getYears()}
                  nameKey={'value'}
                  valueKey={'id'}
                />
              </div>
              <div className='filter-item'>
                <SelectControl
                  value={selectedMonth}
                  setValue={(name, value) => monthChangeHandler(value)}
                  label={strings.pages.user.employeeStatistic.monthLabel}
                  options={getMonths()}
                  nameKey={'value'}
                  valueKey={'id'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <BarChart
        labels={getDaysOfMonthLabels(hoursPerDay.length, false)}
        options={options}
        inputData={[
          {
            label: strings.pages.user.employeeStatistic.hours,
            data: hoursPerDay,
          },
        ]}
      />
    </Fragment>
  );
};

export default PresanceByDayChart;
