import strings from "../../localization";



const JobPaymentFrequency = {
    MONTHLY: 1,
    WEEKLY: 2,
    DAILY: 3
}

export function getJobPaymentFrequency() {
    return[
        { id: JobPaymentFrequency.MONTHLY, name:getJobPaymentFrequencyString(JobPaymentFrequency.MONTHLY) },
        { id: JobPaymentFrequency.WEEKLY, name:getJobPaymentFrequencyString(JobPaymentFrequency.WEEKLY) },
        { id: JobPaymentFrequency.DAILY, name:getJobPaymentFrequencyString(JobPaymentFrequency.DAILY) },
    ];
}

export function getJobPaymentFrequencyString(jobPaymentFrequency) {
    switch(jobPaymentFrequency){
        case JobPaymentFrequency.MONTHLY:
            return strings.constants.hr.jobPaymentFrequency.MONTHLY;
        case JobPaymentFrequency.WEEKLY:
            return strings.constants.hr.jobPaymentFrequency.WEEKLY;
        case JobPaymentFrequency.DAILY:
            return strings.constants.hr.jobPaymentFrequency.DAILY;
    }
}

export default JobPaymentFrequency;