import { configureStore } from '@reduxjs/toolkit'
import navigationReducer from "./Slices/NavigationSlice";
import pageReducer from "./Slices/PageSlice";
import loaderSlice from "./Slices/LoaderSlice";
import authSlice from "./Slices/AuthSlice";
import headerSlice from "./Slices/BreadcrumbsSlice";
import siteDataSlice from "./Slices/SiteDataSlice";

export default configureStore({
    reducer: {
        navigation: navigationReducer,
        page: pageReducer,
        loader: loaderSlice,
        auth: authSlice,
        breadcrumbs: headerSlice,
        siteData: siteDataSlice
    },
})
