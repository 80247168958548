import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getProjectResources(data) {
    return await request('/api/project-resource', data);
}

export async function addProjectResources(data) {
    return await request('/api/project-resource', transformRequestData(data), HttpMethod.POST);
}

export async function editProjectResources(data) {
    return await request('/api/project-resource/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteProjectResources(id) {
    return await request('/api/project-resource/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        projectId: data.project ? data.project.id : null,
        currencyId: data.currency ? data.currency.id : null,
        projectResourceTypeId: data.projectResourceType ? data.projectResourceType.id : null,
        invoiceId: data.invoice ? data.invoice.id : null,
        type: data.type ? data.type.type : null,
    }
}
