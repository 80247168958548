import { useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import strings from "../../../../localization";
import Button from '@mui/material/Button';
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import SelectControl from "../../../Controls/Inputs/SelectControl";
import PageState from "../../../../Constants/Base/PageState";
import TablePageContext from "../../../../Context/TablePageContext";

const WorkspaceIssueTypeForm = (props) => {
    const {formRules, form, values, data, onSubmit, onCancel, errors, setValue, control, issueTypes} = props;

    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const isPageStateEdit = () => pageState === PageState.Edit;
    const {pageState} = useContext(TablePageContext);

    return (
        <FormProvider {...form}>
            <form className = 'form' action = '#'>
                <TextFieldControl
                    name = 'name'
                    className = 'mt-8 mui-shifted-label-input margin-shifted-label-first'
                    control = {data}
                    rules = {formRules['name']}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    error = {Boolean(errors.name)}
                    label = {strings.forms.issueType.name}
                    placeholder = {strings.forms.issueType.placeholder.name}
                    disabled = {isPageStateViewDetails()}
                />
                <SelectControl
                    name = 'issueType'
                    className='margin-shifted-label-select mui-shifted-label-input'
                    label = {strings.forms.issueType.type}
                    placeholder = {strings.forms.issueType.placeholder.type}
                    value = {values['issueType']}
                    setValue = {setValue}
                    nameKey = {'name'}
                    control = {control}
                    valueKey = {'type'}
                    disabled = {isPageStateViewDetails() || (isPageStateEdit() && values['issueType'].isDefault)}
                    options = {issueTypes}
                    rules = {formRules['issueType']}
                    error = {Boolean(errors.issueType)}
                    helperText={errors.issueType && strings.forms.common.thisFieldIsRequired}
                />
                {
                    !isPageStateViewDetails() &&
                    <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
};

export default WorkspaceIssueTypeForm;
