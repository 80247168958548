import React, {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import {formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import {LockOpen} from "@mui/icons-material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    deleteCompanyDocument, downloadCompanyDocumentPDF,
    getCompanyDocuments, showPdfDocument, viewCompanyDocumentPDF
} from "../../../Services/CompanyDocumentManagement/CompanyDocumentService";
import AddCompanyDocument from "./AddCompanyDocument";
import EditCompanyDocument from "./EditCompanyDocument";
import FillCompanyDocument from "./FillCompanyDocument";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import { hasPermission } from "../../../Util/PermissionUtil";
import CompanyDocumentFileFolder from "./CompanyDocumentFileFolder";
import PdfPreview from "../../../Components/Pdf/PdfPreview";
import ChooseTemplateModal from "./ChooseTemplateModal";


const nameFormat = params => formatColumnObject(params, 'name')

const tableDescription = [
    { field: 'name', headerName: strings.pages.companyDocument.companyDocument.companyDocumentList.name, width: 250},
    { field: 'companyDocumentCategory', headerName: strings.pages.companyDocument.companyDocument.companyDocumentList.category, valueFormatter: nameFormat, width: 250 },
    { field: 'companyDocumentTemplate', headerName: strings.pages.companyDocument.companyDocument.companyDocumentList.template, valueFormatter: nameFormat, width: 250 },
];

const filters = []

const CompanyDocumentList = (props) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const [customPage, setCustomPage] = useState(null);
    const referenceType = ReferenceType.COMPANY_DOCUMENT;
    const permissionGroup = AppPermissions.COMPANY_DOCUMENT.GROUP;
    const permissionFolderGroup = AppPermissions.COMPANY_DOCUMENT_FOLDER.GROUP;
    const [selectedFolder, setSelectedFolder] = useState(null)
    const [activePdf, setActivePdf] = useState(null);
    const [showModal, setShowModal] = useState(false);


    const navigate = useNavigate();

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    const additionalMenuItems = [
        {handleClick: (id, item) => handleContent(id, item), text: strings.pages.companyDocument.companyDocument.companyDocumentList.content, src: '/images/table-page/content.svg'},
        {handleClick: (id, item) => handleDownload(id, item), text: strings.pages.companyDocument.companyDocument.companyDocumentList.download, src: '/images/table-page/download-pdf.svg'},
    ]



    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.companyDocument.companyDocument.companyDocumentList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.DOCUMENTS},
                {label: strings.pages.companyDocument.companyDocument.companyDocumentList.pageTitle},
            ],
            submenu: true,
        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });


        if(selectedFolder) {
            getCompanyDocuments({
                ...filter,
                folder: selectedFolder,
            }).then(response => {
                if (!response || !response.ok) {
                    setTableData({
                        ...tableData,
                        loading: false
                    });
                    return;
                }

                setTableData({
                    loading: false,
                    data: response.data.result,
                    total: response.data.total
                });
            })
        }else{
            setTableData(TableDataDefaults)
        }
    }

    const handleContent = (id, companyDocument) => {
        navigate('/document-content', {state: {companyDocument}})
    }

    const handleDownload = (id, item) => {
        downloadCompanyDocumentPDF(item.id);
    }

    const handlePreview = (item) => {
        setPageState(PageState.Custom);
         showPdfDocument(item.id).then((response) => {
             setActivePdf(response)
         })
    }

    const selectedFolderChangeHandler = (folder) => {
        setTableData({
            ...tableData,
            loading: true
        });

        if(folder) {
            setSelectedFolder(folder)
            getCompanyDocuments({
                ...filter,
                folder: folder,
            }).then(response => {
                if (!response || !response.ok) {
                    return;
                }
                setTableData({
                    loading: false,
                    data: response.data.result,
                    total: response.data.total
                });
            })
        }else{
            setSelectedFolder(folder)
            setTableData({
                loading: false,
                data: []
            });
        }
    }

    const addDocumentHandler = () => {
        navigate('/company-documents/create-document', {state: selectedFolder});
    }

    const handleShowModal = () => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    return <TablePageContext.Provider value={value}>
        <div>
            <CompanyDocumentFileFolder permissionGroup={permissionFolderGroup} onSelectedFolderChange={selectedFolderChangeHandler}></CompanyDocumentFileFolder>
            <div id="documents">
            <div className="content">
                <div className="documents-list">
                    <TablePage onFinish={() => onFinish()} deleteItem={deleteCompanyDocument} tableDescription={columns}
                               tableData={tableData} filter={filter} filters={filters}
                               permissionGroup={permissionGroup} handleView={handlePreview}
                               additionalMenuItems={additionalMenuItems}
                               referenceType={referenceType}
                               editPage={<EditCompanyDocument data={ selectedItems }
                                                              onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                               handleAdd={handleShowModal}
                               customPage={<PdfPreview pdf={activePdf}/>}
                               addButtonText={strings.components.tablePage.addButton.document}
                               disabledAddButton={selectedFolder ? false : true}
                    />
                </div>
            </div>
        </div>
        </div>
        <ChooseTemplateModal showModal={showModal} closeModal={handleCloseModal} selectedFolder={selectedFolder}></ChooseTemplateModal>
    </TablePageContext.Provider>;
}

export default CompanyDocumentList;
