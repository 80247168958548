import { Popover } from "@mui/material";
import { useState } from "react";
import {Typography} from "@mui/material";

const Badge = ({
    amount = 1,
    className = '',
    src = '',
    message = "",
    top = 0,
    left = 0,
    height = 0,
    width = 0,
    parentRef = null,
    styled = false,
    messageStyled = false,
    onClick = ()=>{},
    popover = false,
})=>{
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null)
    };   
    
    let styledMessage = message.substring(0, (width * parentRef?.current.clientWidth / 1000));
    if(styledMessage.length !== message.length){
        styledMessage = styledMessage.concat("...");
    }
    const displayMessage = message? (messageStyled? styledMessage : message) : "";
    return <>
            {popover && <Popover className="hover-popover-badge"
                            sx={{pointerEvents: 'none',}}
                            anchorOrigin={{ vertical: 'top',horizontal: 'right', }}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleClose} 
                            disableAutoFocus>

                            <Typography>  {message} </Typography>
                        </Popover>}

            <div className={`calendar-badge${className? " " + className : ""}` }
                onClick={onClick}
                
                style={styled? {
                    "top": top + "%",
                    "left": left + "%",
                    "minHeight": height + "%",
                    "minWidth": width + "%",
                    "maxWidth": width + "%",
                    "background": "rgba(154, 246, 252, 0.4)",
                    } : {}}>

                {amount > 1 && <div className="event-number"> {amount} </div>}
                {src && (!styled || (styled && height * parentRef.current.clientHeight / 100 > 14)) && <img src={src}/>}
               
                <div onMouseOver={(event)=>{handleOpen(event)}}            
                     onMouseLeave={handleClose}
                     className="message">
                        {displayMessage}
                 </div> 
                 
            </div>
    </>
    
}

export default Badge;
