import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import strings from "../../../localization";
import {getCandidate} from "../../../Services/HR/CandidateService";
import {useNavigate, useParams} from "react-router-dom";
import {Grid, Paper, Tab, Tabs} from "@mui/material";
import {ic_alternate_email} from 'react-icons-kit/md/ic_alternate_email'
import {ic_call} from 'react-icons-kit/md/ic_call'
import {ic_location_on} from 'react-icons-kit/md/ic_location_on'
import {Icon} from "react-icons-kit";
import {dateToString} from "../../../Util/DateUtil";
import CandidateDetailsTab from "../../../Constants/HR/CandidateDetailsTab";
import DocumentList from "../../Document/Document/DocumentList";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import CandidateRecord from "./CandidateRecord";
import {hasPermission} from "../../../Util/PermissionUtil";
import AppPermissions from "../../../Constants/Permissions/Permissions";

const CandidateDetails = ({activeTab = CandidateDetailsTab.RECORD}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const {id: candidateId} = useParams();
    const [candidate, setCandidate] = useState(null);

    const changeActiveTab = (tab) => {
        if(tab === CandidateDetailsTab.RECORD){
            navigate(`/candidate-details/${candidateId}/record`);
        }

        if(tab === CandidateDetailsTab.FILES){
            navigate(`/candidate-details/${candidateId}/files/${ReferenceType.CANDIDATE}`);
        }
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        fetch();
    }, [])

    const fetch = () => {
        getCandidate(candidateId).then(response => {
            if (!response || !response.ok) {
                return;
            }

            setCandidate(response.data.entity);
        });
    }

    return <div id='candidate-details-page'>
        {
            candidate &&
            <Paper className='paper'>
                <Grid container>
                    <Grid item xs={6}>
                        <div className='base-info'>
                            <h1>{candidate.firstName} {candidate.lastName} {dateToString(new Date(candidate?.birthday), {prefix: '(', suffix: ')'})}</h1>
                            <p><Icon size={25} icon={ic_alternate_email}/>
                                <a target='_blank' href={'mailto:' + candidate.email}> {candidate.email}</a>
                                <Icon size={25} icon={ic_call}/>
                                <a target='_blank' href={'tel:' + candidate.phone}> {candidate.phone}</a>
                                <Icon size={25} icon={ic_location_on}/>
                                {candidate.location}
                            </p>
                            <div dangerouslySetInnerHTML={{ __html: candidate.description}}/>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className='additional-info'>
                            <p>
                                <span>{strings.pages.hr.candidate.candidateDetails.currentPosition}</span>: {candidate.currentPosition}
                            </p>
                            <p>
                                <span>{strings.pages.hr.candidate.candidateDetails.currentCompany}</span>: {candidate.currentCompany}
                            </p>
                            <p>
                                <span>{strings.pages.hr.candidate.candidateDetails.currentSalary}</span>: {candidate.currentSalary}
                            </p>
                            <p>
                                <span>{strings.pages.hr.candidate.candidateDetails.expectedSalary}</span>: {candidate.expectedSalary}
                            </p>
                            <p>
                                <span>{strings.pages.hr.candidate.candidateDetails.education}</span>: {candidate.education}
                            </p>
                            <p>
                                <span>{strings.pages.hr.candidate.candidateDetails.candidateSource}</span>: {candidate.candidateSource ? candidate.candidateSource.name : ''}
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        }

        <div id="hr-settings">
            <div className='tab-container'>
                <Paper className='paper'>
                    <Tabs variant="scrollable" value={activeTab} onChange={(e, tab) => changeActiveTab(tab)}>
                        <Tab label={strings.pages.hr.candidate.candidateDetails.record}
                            disabled={!hasPermission(auth.user, AppPermissions.CANDIDATE_RECORDS.GROUP,
                             AppPermissions.CANDIDATE_RECORDS.VIEW, auth.permissions)}
                        />
                        <Tab label={strings.pages.hr.candidate.candidateDetails.files}
                             disabled={!hasPermission(auth.user, AppPermissions.CANDIDATE.GROUP,
                                 AppPermissions.CANDIDATE.DOCUMENTS, auth.permissions)}
                        />
                    </Tabs>
                </Paper>
            </div>
        </div>

        {
            activeTab === CandidateDetailsTab.RECORD &&
            <CandidateRecord candidate={candidate}/>
        }
        {
            activeTab === CandidateDetailsTab.FILES &&
            <DocumentList />
        }
    </div>
}

export default CandidateDetails;
