import {createSlice} from "@reduxjs/toolkit";
import MenuState from "../Constants/Base/MenuState";

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        menuState: MenuState.SHORT,
        reloadImages: 0
    },
    reducers: {
        changeMenuState: (state, action) => {
            state.menuState = action.payload
        },
        changeImages: (state, action) => {
            state.reloadImages = state.reloadImages+1;
        }
    }
});

export const { changeMenuState, changeImages } = navigationSlice.actions

export default navigationSlice.reducer
