import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getCandidateSourceList(data) {
    return await request('/api/candidate-source', data);
}

export async function addCandidateSource(data) {
    return await request('/api/candidate-source', data, HttpMethod.POST);
}

export async function editCandidateSource(data) {
    return await request('/api/candidate-source/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteCandidateSource(id) {
    return await request('/api/candidate-source/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
    }
}