import { Grid, LinearProgress, Paper } from "@mui/material";
import LineChart from "../../../Components/Charts/LineChart";
import { monthShortLabels } from "../../../Util/ChartUtil";
import { isEqual } from 'lodash';
import React from "react";

const ProjectTimeLogStatistic = ({inputData}) => {
    return  <React.Fragment>
        <Grid item xs={10}>
            <div className="rounded-2 clear-paper statistic-chart-container">
            {   inputData.length === 0 &&
                <LinearProgress/>
            }
            {
                inputData.length > 0 &&
                <LineChart key={Math.random()} labels={monthShortLabels}
                        inputData={inputData}
                        options={{
                            maintainAspectRatio: false,
                        }}
                />
            }
            </div>
            
        </Grid>
    </React.Fragment>
}

export default React.memo(ProjectTimeLogStatistic, (prev, next) => isEqual(prev, next));
