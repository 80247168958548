import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllInventoryCategories() {
    return await request('/api/inventory-categories/all');
}

export async function getInventoryCategories(data) {
  return await request('/api/inventory-categories', data);
}

export async function getInventoryCategoryList(data) {
    return await request('/api/inventory-categories', data);
}

export async function getInventoryCategory(id) {
    return await request('/api/inventory-categories/' + id);
}

export async function deleteInventoryCategory(id) {
    return await request('/api/inventory-categories/' + id, {}, HttpMethod.DELETE);
}

export async function editInventoryCategory(data) {
    return await request('/api/inventory-categories/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addInventoryCategory(data) {
    return await request('/api/inventory-categories', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        parentId: data.parent ? data.parent.id : null,
    }
}
