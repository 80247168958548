import strings from "../../../../localization";

const CompanyDocumentReferenceUserValueType = {
    FIRST_NAME: 1,
    LAST_NAME: 2,
    FULL_NAME: 3
}

export function getCompanyDocumentReferenceUserValueTypes() {
    return [
        {
            value: CompanyDocumentReferenceUserValueType.FIRST_NAME,
            name: getCompanyDocumentReferenceUserValueTypeString(CompanyDocumentReferenceUserValueType.FIRST_NAME)
        },
        {
            value: CompanyDocumentReferenceUserValueType.LAST_NAME,
            name: getCompanyDocumentReferenceUserValueTypeString(CompanyDocumentReferenceUserValueType.LAST_NAME)
        },
        {
            value: CompanyDocumentReferenceUserValueType.FULL_NAME,
            name: getCompanyDocumentReferenceUserValueTypeString(CompanyDocumentReferenceUserValueType.FULL_NAME)
        }
    ];
}

export function getCompanyDocumentReferenceUserValueTypeString(contractType) {
    switch(contractType) {
        case CompanyDocumentReferenceUserValueType.FIRST_NAME: return strings.companyDocumentReferenceUserValueType.firstName;
        case CompanyDocumentReferenceUserValueType.LAST_NAME: return strings.companyDocumentReferenceUserValueType.lastName;
        case CompanyDocumentReferenceUserValueType.FULL_NAME: return strings.companyDocumentReferenceUserValueType.fullName;
    }
}

export default CompanyDocumentReferenceUserValueType;