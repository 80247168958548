import React from "react";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import {deleteBankReport, getBankReportList} from "../../../Services/Finance/BankReportService";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {formatColumnDate, formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import AddBankReport from "./AddBankReport";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import EditBankReport from "./EditBankReport";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {renderColumnNumber} from "../../../Components/DataGrid/ValueCellRender";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import { getBankAccounts, getBankAccount } from "../../../Services/Company/BankAccountService";
import { getCurrencies, getBanks } from '../../../Util/SiteDataUtil';
import {fetchOptions} from '../../../Services/Base/FilterOptionsService';
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";


const tableDescription = [

    {
        field: 'bank',
        headerName: strings.pages.finance.bankReport.bankReportList.bank,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 200
    },
    {
        field: 'bankAccount',
        headerName: strings.pages.finance.bankReport.bankReportList.bankAccount,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 200
    },
    {
        field: 'currency',
        headerName: strings.pages.finance.bankReport.bankReportList.currency,
        valueFormatter: (params) => formatColumnObject(params, 'code'),
        width: 90
    },
    {
        field: 'businessYear',
        headerName: strings.pages.finance.bankReport.bankReportList.businessYear,
        valueFormatter: (params) => formatColumnObject(params, 'year'),
        width: 130
    },
    {
        field: 'reportId', headerName: strings.pages.finance.bankReport.bankReportList.report,
        width: 100,
    },
    {
        field: 'reportNumber', headerName: strings.pages.finance.bankReport.bankReportList.reportNumber,
        width: 140,
    },
    {
        field: 'reportDate',
        headerName: strings.pages.finance.bankReport.bankReportList.reportDate,
        valueFormatter: (params) => formatColumnDate(params),
        width: 120
    },
    {
        field: 'amountBefore', headerName: strings.pages.finance.bankReport.bankReportList.amountBefore,
        width: 130,
        renderCell: (params) => renderColumnNumber(params)
    },
    {
        field: 'amountAfter', headerName: strings.pages.finance.bankReport.bankReportList.amountAfter,
        width: 120,
        renderCell: (params) => renderColumnNumber(params)
    },
    {
        field: 'income', headerName: strings.pages.finance.bankReport.bankReportList.income,
        width: 100,
        renderCell: (params) => renderColumnNumber(params)
    },
    {
        field: 'outcome', headerName: strings.pages.finance.bankReport.bankReportList.outcome,
        width: 100,
        renderCell: (params) => renderColumnNumber(params)
    },

];


const BankReportList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const siteData = useSelector((state) => state.siteData);
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const [filterOptions, setFilterOptions] = useState({});
    const referenceType = ReferenceType.BANK_REPORT;
    const permissionGroup = AppPermissions.BANK_REPORT.GROUP;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(()=>{
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.finance.bankReport.bankReportList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.FINANCE},
                {label: strings.pages.finance.bankReport.bankReportList.pageTitle},
            ],        
        }));
    },[])

    useEffect(()=>{
        if(!Object.keys(siteData.data).length){
            return;
        }

        fetchFilters();
    }, [siteData] )

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    },[filter, updatedFilter]);

    const fetchBankAccounts = async (term, filterFetch) => {
        return fetchOptions('bankAccount', searchParams, getBankAccounts, getBankAccount, setFilterOptions, term, filterFetch);
    }

    const fetchFilters = async() => {
        const bankAccounts = await fetchBankAccounts();

        setFilterOptions({
            bank: getBanks(siteData),
            currency: getCurrencies(siteData),    
            bankAccount: bankAccounts,
            fetched: 1,
        });
    }

    const filters = [
        {
            name: 'bank',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'bank',
            label: strings.pages.finance.bankReport.bankReportList.bank,
        },
        {
            name: 'bankAccount',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'bankAccount',
            label: strings.pages.finance.bankReport.bankReportList.bankAccount,
            onChange: (term) => fetchBankAccounts(term, true)
        },
        {
            name: 'currency',
            nameKey: 'code',
            valueKey: 'id',
            optionsName: 'currency',
            label: strings.pages.finance.bankReport.bankReportList.currency,
            showSelect: true
        },
    ];

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getBankReportList({
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteBankReport} tableDescription={columns}
                   tableData={tableData} 
                   filter={filter} 
                   filters={filters}
                   filterOptions={filterOptions}
                   permissionGroup={permissionGroup}
                   referenceType={referenceType}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditBankReport data={selectedItems} onFinish={() => onFinish()}
                                             onCancel={() => onCancel()}/>}
                   addPage={<AddBankReport onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addButtonText={strings.components.tablePage.addButton.bankReport}
        />
    </TablePageContext.Provider>;
}

export default BankReportList;
