import {Alert, Snackbar} from "@mui/material";
import React, {useState} from "react";
import SnackbarContext from "../Context/SnackbarContext";

const SnackbarWrapper = ({children}) => {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [autoHideDuration, setAutoHideDuration] = useState(3000);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackbar(false);
    };

    const showMessage = (message, alertType = 'success', autoHideDuration = 3000) => {
        setAlertType(alertType)
        setMessageSnackbar(message)
        setShowSnackbar(true);
        setAutoHideDuration(autoHideDuration);
    }

    const value = {showMessage}

    return <SnackbarContext.Provider value={value}>
        <Snackbar open={showSnackbar} autoHideDuration={autoHideDuration} onClose={handleCloseSnackbar}>
            <Alert severity={alertType} onClose={handleCloseSnackbar}>
                {messageSnackbar}
            </Alert>
        </Snackbar>

        {children}

    </SnackbarContext.Provider>
}

export default SnackbarWrapper;