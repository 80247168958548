import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import {Paper} from "@mui/material";
import SnackbarContext from "../../../Context/SnackbarContext";
import WorkspaceForm from '../../../Components/Forms/Pages/Workspaces/WorkspaceForm';
import {getPipelines} from "../../../Services/Company/PipelineService";
import { addWorkspace } from "../../../Services/Workspaces/WorkspacesService";
import { PipelineType } from '../../../Constants/Company/PipelineType';

const formRules = {
    'name': {required: true},
    'pipeline': {required: true},
}

const AddWorkspace = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const [showLoader, setShowLoader] = useState(false);
    const [pipelines, setPipelines] = useState([]);
    const form = useForm({
        defaultValues: 
        {
            name: '',
            description: '',
            pipeline: null,
        }
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}, control} = form;

    const fetchPipelines = (term = '') => {
        getPipelines({
            term: term,
            type: PipelineType.TASK,
        }).then(response => {
            console.log(response.data?.result)
            setPipelines(response.data?.result);
        });
    }

    useEffect(() => {
        setDrawerTitle(strings.pages.workspaces.addWorkspaces.pageTitle);
        fetchPipelines()
    },[]);


    const onSubmit = (data) => {
        setShowLoader(true);

        addWorkspace(data).then(response => {
            setShowLoader(false);

            if(!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    return (
        <div className = 'drawer-form'>
            <Paper className = 'paper'>
                {
                    showLoader &&
                        <LinearProgress />
                }
                <WorkspaceForm 
                    formRules = {formRules}
                    form = {form}
                    values = {getValues()}
                    setValue = {setValue}
                    data = {data}
                    errors = {errors}
                    onSubmit = {handleSubmit(onSubmit)}
                    onCancel = {() => props.onCancel()}
                    pipelines = {pipelines}
                    control = {control}
                    onChangePipelineFilter = {fetchPipelines}
                />
            </Paper>
        </div>
    );
}

export default AddWorkspace;
