import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import PageState from "../../../Constants/Base/PageState";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import strings from "../../../localization";
import {deleteNonWorkingDays, getNonWorkingDaysList} from "../../../Services/HR/NonWorkingDaysService";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import {changePageSizeState} from "../../../Slices/PageSlice";
import {formatColumnDate} from "../../../Components/DataGrid/ValueFormatter";
import AddNonWorkingDays from "./AddNonWorkingDays";
import EditNonWorkingDays from "./EditNonWorkingDays";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {useNavigate, useSearchParams} from "react-router-dom";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Modules from "../../../Constants/Base/Modules";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [
    {field: 'name', headerName: strings.pages.hr.nonWorkingDays.nonWorkingDaysList.name, width: 300},
    {
        field: 'fromDate', headerName: strings.pages.hr.nonWorkingDays.nonWorkingDaysList.fromDate, width: 300,
        valueFormatter: (params) => formatColumnDate(params),
    },
    {
        field: 'toDate', headerName: strings.pages.hr.nonWorkingDays.nonWorkingDaysList.toDate, width: 300,
        valueFormatter: (params) => formatColumnDate(params),
    },
    {field: 'type', headerName: strings.pages.hr.nonWorkingDays.nonWorkingDaysList.type, width: 300}
];

const filters = []

const NonWorkingDaysList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.NON_WORKING_DAY;
    const permissionGroup = AppPermissions.NON_WORKING_DAYS.GROUP;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(() => {

        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));

        dispatch(changeBreadcrumbs({
            title: strings.pages.hr.nonWorkingDays.nonWorkingDaysList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.pages.hr.settings.settings},
                {label: strings.pages.hr.nonWorkingDays.nonWorkingDaysList.pageTitle},
            ],        
        }));

        return () => {
            setTableData({})
        }
    }, []);

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])


    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getNonWorkingDaysList({
            ...filter
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        });
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteNonWorkingDays} tableDescription={columns}
                   tableData={tableData} filter={filter} filters={filters}
                   referenceType={referenceType}
                   permissionGroup={permissionGroup}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditNonWorkingDays data={selectedItems}
                                                 onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addPage={<AddNonWorkingDays onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   hideBreadcrumbs
                   addButtonText={strings.components.tablePage.addButton.nonWorkingDay}/>
    </TablePageContext.Provider>
}

export default NonWorkingDaysList;
