import {useForm} from "react-hook-form";
import BaseSettingsForm from "../../../Components/Forms/Pages/Company/BaseSettingsForm";
import strings from "../../../localization";
import {Grid} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import {getCities, getCountries, getCurrencies} from "../../../Util/SiteDataUtil";
import {getCompanyByName, getCompanyByRegistrationNumber, getCompanyByTaxNumber, getCurrentCompany, updateCompany} from "../../../Services/Company/CompanyService";
import {setCompany} from "../../../Slices/AuthSlice";
import SnackbarContext from "../../../Context/SnackbarContext";
import {getUserFromLocalStorage} from "../../../Base/HTTP";
import {setUserToLocalStorage} from "../../../Base/OAuth";
import formatCharacters from "../../../Util/FormatCharacters";
import {getEmployees} from "../../../Services/User/UserService";

const formRules = {
    'name': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'registrationNumber': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'taxNumber': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'zipCode': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'address': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'city': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'country': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'currency': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
}

const BaseSettings = () => {

    const dispatch = useDispatch();

    const form = useForm({mode: 'onTouched'}); // for live  validation

    const {handleSubmit, watch, getValues, data, setValue, reset, formState: {errors}, control, setError, clearErrors} = form;
    
    const siteData = useSelector((state) => state.siteData)

    const { showMessage } = useContext(SnackbarContext);
    const [showLoader, setShowLoader] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [employees, setEmployees] = useState([]);

    const watchName = watch('name');

    const defaultCountry = getCountries(siteData).find((country) => country.code === 'RS');
    const defaultCurrency = getCurrencies(siteData).find((currency) => currency.code === 'RSD');

    useEffect(() => {

        dispatch(changeBreadcrumbs({
            title: strings.pages.company.baseSettings.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.ADMINISTRATION},
                {label: strings.pages.company.company},
                {label: strings.pages.company.baseSettings.pageTitle},
            ],        
        }));
        
        fetch();
    }, []);

    useEffect(() => {
        if(watchName?.name){
            const data = getValues();

            for(const key in data){
                if(key !== 'id' && key !== 'city'){
                    if(watchName[key]){
                        setValue(key, watchName[key]);
                    }else if(Object.keys(watchName).length > 1){
                        setValue(key, '');
                    }
                }
                if(key === 'city') {
                    const city = getCities(siteData).find((city) => city.name === watchName[key]);
                    setValue(key, city);
                }
            }
        }
        if(watchName?.registrationNumber){
            setValue('currency', defaultCurrency);
            setValue('country', defaultCountry);
        }
        clearErrors();
    },[watchName]);

    fetch = () => {
        getCurrentCompany().then(response => {
            reset(response.data);
        })
        fetchEmployees();
    }

    const fetchCompanyData = (data) => {
        getCompanyByName(data).then(response => {
            if(!response || !response.ok){
                return;
            }

            const tmp = response.data.map((company, index) => (
                {...company,
                    id: index,
                    name: formatCharacters(company?.name),
                    address: formatCharacters(company?.address),
                }
            ));
            setCompanies(tmp);
        });
    }

    const fetchEmployees = (term = '') => {
        getEmployees({term}).then(response => {
            setEmployees(response.data.result.map(u => u.user));
        })
    }
    const save = (data) => {
        updateCompany(data).then(response => {
            if(!response || !response.ok) {
                showMessage(strings.commonMessages.errorSaving, 'error');
                return;
            }

            showMessage(strings.commonMessages.saved, 'success');

            let user = getUserFromLocalStorage();
            user.company = response.data.entity;
            setUserToLocalStorage(user);

            dispatch(setCompany(response.data.entity));
        });
    }

    const fetchRegistrationNumber = () => {
        const data = getValues();

        if(!data.registrationNumber) return;

        setShowLoader(true);

        getCompanyByRegistrationNumber(data.registrationNumber).then(response => {
            setShowLoader(false);

            if(!response || !response.ok){
                return;
            }

            if(response.data?.registrationNumber){
                setValue('currency', defaultCurrency);
                setValue('country', defaultCountry);
                clearErrors();
            } else {
                setError('registrationNumber', {message: strings.forms.company.baseSettings.error.registrationNumber} );
                return;
            }

            const company = {
                ...response.data,
                name: formatCharacters(response.data?.name),
                address: formatCharacters(response.data?.address),
            };
            
            const city = company.city ? getCities(siteData).find((city) => city.name === company.city) : undefined;
            setValue('city', city);

            for(const key in company){
                if(key !== 'account' && key !== 'city'){
                    setValue(key, company[key]);
                }
            }

        });
    }

    const fetchTaxNumber = () => {
        const data = getValues();
        if(!data.taxNumber) return;

        setShowLoader(true);

        getCompanyByTaxNumber(data.taxNumber).then(response => {
            setShowLoader(false);

            if(!response || !response.ok){
                return;
            }

            if(response.data?.taxNumber){
                setValue('currency', defaultCurrency);
                setValue('country', defaultCountry);                
                clearErrors();
            }else{
                setError('taxNumber', {message: strings.forms.company.baseSettings.error.taxNumber} );
                return;
            }

            const company = {
                ...response.data,
                name: formatCharacters(response.data?.name),
                address: formatCharacters(response.data?.address),
            };

            const city = company.city ? getCities(siteData).find((city) => city.name === company.city) : undefined;
            setValue('city', city);

            for(const key in company){
                if(key !== 'account' && key !== 'city'){
                    setValue(key, company[key]);
                }
            }
        });
    }

    return(
        <Grid 
            container 
            alignItems = 'center' 
            justifyContent = 'center' 
            className = 'company-form-container'
        >
            <Grid item xs = {9} className = 'form-container base-settings'>
            {
                showLoader &&
                <LinearProgress/>
            }
                <BaseSettingsForm
                    onSubmit={handleSubmit(save)}
                    control={control} data={data}
                    errors={errors} form={form} formRules={formRules}
                    setValue={setValue} values={getValues()}
                    currencies={getCurrencies(siteData)} countries={getCountries(siteData)}
                    cities={getCities(siteData)}
                    onRegistrationSearch = {fetchRegistrationNumber}
                    onTaxSearch = {fetchTaxNumber}
                    companies = {companies}
                    employees={employees}
                    onNameChange = {fetchCompanyData}
                />
            </Grid>
        </Grid>
    )
}

export default BaseSettings;
