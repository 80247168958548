import {createSlice} from "@reduxjs/toolkit";
import {setUserToLocalStorage} from "../Base/OAuth";
import DateFormats from "../Constants/Company/DateFormats";
import NumberFormats from "../Constants/Company/NumberFormats";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: undefined,
        permissions: [],
        company: undefined,
        companyS3Settings: undefined,
        localisationSettings: {dateFormat: DateFormats.default, numberFormat: NumberFormats.default},
        isCheckIn: undefined
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
            setUserToLocalStorage(action.payload)
        },
        setPermissions: (state, action) => {
            state.permissions = action.payload
        },
        setAuth: (state, action) => {
            state.user = action.payload
            state.permissions = action.payload ? action.payload.permissions : null
            state.company = action.payload ? action.payload.company : null
        },
        setCompany: (state, action) => {
            state.company = action.payload;
        },
        setCompanyS3Settings: (state, action) => {
            state.companyS3Settings = action.payload;
        },
        setLocalisationSettings: (state, action) => {
            if (action.payload) {
                state.localisationSettings = action.payload;
                return;
            }
            state.localisationSettings = {dateFormat: DateFormats.default, numberFormat: NumberFormats.default}
        },
        setIsCheckIn: (state, action) => {
            state.isCheckIn = action.payload;
        },
        setFavoriteBoards: (state, action) => {
            state.favoriteBoards = action.payload;
        }
    }
});

export const { setUser } = authSlice.actions
export const { setPermissions } = authSlice.actions
export const { setAuth } = authSlice.actions
export const { setCompany } = authSlice.actions
export const { setCompanyS3Settings } = authSlice.actions
export const { setLocalisationSettings } = authSlice.actions
export const { setIsCheckIn } = authSlice.actions;
export const { setFavoriteBoards } = authSlice.actions;

export default authSlice.reducer
