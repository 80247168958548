import { useEffect, useRef, useState } from "react";
import { Axios } from "../../Base/HTTP";
import LoaderContext from "../../Context/LoaderContext"
import { LinearProgress } from '@mui/material';

const ignoredCalls = [
    {
        method: "post",
        url: "/api/role-permissions",
    },
    {
        method: 'post',
        url: '/api/users/preference'
    },
    {
        method: 'put',
        url: '/api/board-issue-stage'
    }
]

const isCallIgnored = (call) =>{
    if(call.method === 'get'){
        return true;
    }

    const ignoredCall = ignoredCalls.find(ignored => ignored.method === call.method && call.url.startsWith(ignored.url));
    return Boolean(ignoredCall);
}

const LoaderWrapper = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [manualLoading, setManualLoading] = useState(false);
    const countRef = useRef(0)

    const value = {
        loading: manualLoading,
        setLoading: setManualLoading
    };

    useEffect(() => {
      if (countRef.current <= 0 ||!loading) {
          countRef.current = 0;
          setLoading(false);
      }
    }, [countRef.current])
    
    Axios.getInstance().interceptors.response.use(response => {
        if (response?.config?.method === 'get') {
            return response;
        }
        countRef.current = countRef.current-1;
        if (countRef.current <= 0) {
            setLoading(v => false);
        }
        return response;
    });
    Axios.getInstance().interceptors.request.use(request => {
        if (isCallIgnored(request)) {
            return request;
        }
        countRef.current = countRef.current+1;
        setLoading(v => true);
        return request;
    });
    return <LoaderContext.Provider value={value}>
        {(loading || manualLoading) &&
            <div className="global-loader">
                <LinearProgress className="linear-loader"/>
                <div className="circular-loader">
                    <img src="/images/managevio-brain.svg"/>
                </div>
            </div>        
        }
        {children}
    </LoaderContext.Provider>
}


export default LoaderWrapper;
