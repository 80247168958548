import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import PageState from "../../../Constants/Base/PageState";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import strings from "../../../localization";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import {changePageSizeState} from "../../../Slices/PageSlice";
import {returnColumnBoolean} from "../../../Components/DataGrid/ValueCellRender";
import {formatColumnDate, formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import {
    changeStateLeaveRequest,
    deleteLeaveRequest,
    getLeaveRequestList
} from "../../../Services/HR/LeaveRequestService";
import AddLeaveRequest from "./AddLeaveRequest";
import EditLeaveRequest from "./EditLeaveRequest";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {useNavigate, useSearchParams} from "react-router-dom";
import Button from "@mui/material/Button";
import SnackbarContext from "../../../Context/SnackbarContext";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {hasPermission} from "../../../Util/PermissionUtil";
import {fetchOptions} from '../../../Services/Base/FilterOptionsService';
import { getEmployees, getEmployeeByUser } from "../../../Services/User/UserService";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [
    {
        field: 'fromDate', headerName: strings.pages.hr.leaveRequest.leaveRequestList.fromDate, width: 100,
        valueFormatter: (params) => formatColumnDate(params),
    },
    {
        field: 'toDate', headerName: strings.pages.hr.leaveRequest.leaveRequestList.toDate, width: 100,
        valueFormatter: (params) => formatColumnDate(params),
    },
    {
        field: 'type', headerName: strings.pages.hr.leaveRequest.leaveRequestList.type, width: 100,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
    },
    {
        field: 'user', headerName: strings.pages.hr.leaveRequest.leaveRequestList.employee, width: 150,
        valueFormatter: (params) => formatColumnObject(params, 'fullName'),
    },
    {
        field: 'approved', headerName: strings.pages.hr.leaveRequest.leaveRequestList.approved, width: 90,
        renderCell: (params) => returnColumnBoolean(params),
        align: 'center'
    },
    {
        field: 'approvedBy', headerName: strings.pages.hr.leaveRequest.leaveRequestList.approvedBy, width: 150,
        valueFormatter: (params) => formatColumnObject(params, 'fullName'),
    },
];


const LeaveRequestList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const {showMessage} = useContext(SnackbarContext);
    const referenceType = ReferenceType.LEAVE_REQUEST;
    const permissionGroup = AppPermissions.LEAVE_REQUEST.GROUP;
    const [filterOptions, setFilterOptions] = useState({});
    const auth = useSelector((state) => state.auth)

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog,
        navigate,
        showMessage,
        updatedFilter,
        setUpdatedFilter
    }

    useEffect(() => {

        setColumns([controlColumn,...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));

        dispatch(changeBreadcrumbs({
            title: strings.pages.hr.leaveRequest.leaveRequestList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.pages.hr.leaveRequest.leaveRequestList.pageTitle},
            ],        
        }));

        fetchFilters();

        return () => {
            setTableData({})
        }
    }, []);

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter]);

    const fetchEmployees = async (term, filterFetch) => {
        return fetchOptions('employee', searchParams, getEmployees, getEmployeeByUser, setFilterOptions, term, filterFetch, 'user');
    }

    const fetchApprovedBy = async (term, filterFetch) => {
        return fetchOptions('approvedBy', searchParams, getEmployees, getEmployeeByUser, setFilterOptions, term, filterFetch, 'user');
    }

    const fetchFilters = async () => {
        const employees = await fetchEmployees();
        const approvedBy = await fetchApprovedBy();
        
        setFilterOptions({
            employee: employees,
            approvedBy,
            fetched: 1,
        });
    }


    const filters = [
        {
            name: 'employee',
            nameKey: 'fullName',
            valueKey: 'id',
            optionsName: 'employee',
            label: strings.pages.hr.leaveRequest.leaveRequestList.employee,
            onChange: (term) => fetchEmployees(term, true),
        },
        {
            name: 'approvedBy',
            nameKey: 'fullName',
            valueKey: 'id',
            optionsName: 'approvedBy',
            label: strings.pages.hr.leaveRequest.leaveRequestList.approvedBy,
            onChange: (term) => fetchApprovedBy(term, true),
        }
    ];

    const controlColumn = {
        field: 'control',
        headerName: strings.pages.hr.leaveRequest.leaveRequestList.controls,
        width: 200,
        renderCell: params => formatColumnControl(params),
        align: 'center'
    };

    const formatColumnControl = (params) => {

        if(!hasPermission(auth.user, permissionGroup, AppPermissions.LEAVE_REQUEST.RESOLVE, auth.permissions)) {
            return '';
        }

        if(params.row.approved !== null && params.row.approved !== undefined) {
            return ''
        }

        return <div className='submit-container'>
            <Button variant="contained" size="small" color="primary" className='table-button' onClick={() => changeState(params.row.id, true)}>
                {strings.pages.hr.leaveRequest.leaveRequestList.approve}
            </Button>
            <Button variant="contained" size="small" color="secondary" className='table-button' onClick={() => changeState(params.row.id, false)}>
                {strings.pages.hr.leaveRequest.leaveRequestList.decline}
            </Button>
        </div>
    }

    const changeState = (id, approved) => {

        changeStateLeaveRequest({
            id,
            approved
        }).then(response => {

            if(!response || !response.ok) {
                return;
            }

            showMessage(strings.commonMessages.saved)
            fetch();
        });
    }

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getLeaveRequestList({
            ...filter
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteLeaveRequest} tableDescription={columns}
                   tableData={tableData} filter={filter} filters={filters}
                   filterOptions={filterOptions} showDeletingError={true}
                   referenceType={referenceType}
                   permissionGroup={permissionGroup}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditLeaveRequest data={selectedItems}
                                               onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addPage={<AddLeaveRequest onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addButtonText={strings.components.tablePage.addButton.leaveRequest}/>

    </TablePageContext.Provider>
}

export default LeaveRequestList;
