import React, {useContext, useEffect} from "react";
import {useForm} from "react-hook-form";
import {Grid, Paper} from "@mui/material";
import strings from "../../localization";
import {useDispatch, useSelector} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import LockForm from "../../Components/Forms/Pages/User/LockForm";
import {login} from "../../Base/OAuth";
import {useNavigate} from "react-router-dom";
import {isCompanyValid} from "../../Util/CompanyUtil";
import SnackbarContext from "../../Context/SnackbarContext";
import { breadcrumbsDefault, changeBreadcrumbs } from "../../Slices/BreadcrumbsSlice";

const formRules = {
    'password': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
}

const Lock = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user)

    const form = useForm();
    const { data, handleSubmit, formState: { errors } } = form;
    const {showMessage} = useContext(SnackbarContext)

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.LOCK));
        dispatch(changeBreadcrumbs(breadcrumbsDefault))
    }, [])

    const onSubmit = (data) => {

        login(user.email, data.password).then(({response, responseLogin}) => {

            if (!responseLogin || !responseLogin.ok) {
                showMessage(strings.pages.lock.wrongPassword, 'error')
                return;
            }

            if(isCompanyValid(response.data.user.company)) {
                navigate('/');
                return;
            } 

            navigate('/company-settings');  
        });
    }

    return <Grid id='lock' className={'full-form-page'} container justifyContent={'center'}>
        <Grid item md={6} className='centered-flex'>
            <Paper className='paper lock-paper'>

                <h1 className="text-center">{ strings.pages.lock.lock }</h1>

                <LockForm formRules={formRules} errors={ errors } data={ data } form={ form } onSubmit={ handleSubmit(onSubmit) }/>
            </Paper>
        </Grid>
    </Grid>
};
export default Lock;
