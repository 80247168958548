import {Card, Drawer, IconButton, Paper, Tab, Tabs} from "@mui/material";
import React, {useEffect, useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useNavigate, useParams} from "react-router-dom";
import ViewType from "../../../Constants/Finance/ViewType";
import {deleteDeal, getDealStages, updateDealStage, getDealStagesBySlug} from "../../../Services/CRM/DealService";
import {useDispatch} from "react-redux";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import strings from "../../../localization";
import {changePageSizeState} from "../../../Slices/PageSlice";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import {reorder, reorderDoubleList, sortByColumnOrder} from "../../../Util/DnDUtil";
import DrawerContext from "../../../Context/DrawerContext";
import DrawerWrapper from "../../../Components/Layout/DrawerWrapper";
import PageState from "../../../Constants/Base/PageState";
import AddDeal from "./AddDeal";
import PipelineType from "../../../Constants/Company/PipelineType";
import Modules from "../../../Constants/Base/Modules";
import {getPipelineStageTypeClass, getPipelineStageTypeString} from "../../../Constants/Company/PaymentStageType";
import EditDeal from "./EditDeal";
import YesNoDialog from "../../../Components/Dialogs/YesNoDialog";
import {useContext} from "react";
import SnackbarContext from "../../../Context/SnackbarContext";
import {YesNoDialogResult} from "../../../Components/Dialogs/YesNoDialog";
import {renderToggleButtons} from "./DealList";
import LoaderContext from "../../../Context/LoaderContext";

const DealPipeline = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [viewType, setViewType] = useState(ViewType.PIPELINE);
    const [activeTab, setActiveTab] = useState(0);
    const [tabItem, setTabItem] = useState({});
    const [pageState, setPageState] = useState(PageState.View);
    const [drawerTitle, setDrawerTitle] = useState('');
    const [sortedDeals, setSortedDeals] = useState({});
    const [data, setData] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const {showMessage} = useContext(SnackbarContext);
    const {loading, setLoading} = useContext(LoaderContext);
    const {slug} = useParams();

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.crm.deal.dealPipelines.pageTitle,
            hierarchy: [
                {label: strings.navigation.managmentTag},
                {label: Modules.CRM},
                {label: strings.pages.crm.deal.dealPipelines.pageTitle},
            ],
        }));
        setDrawerTitle(strings.pages.crm.deal.addDeal.pageTitle);
    }, [])

    useEffect(() => {
        fetch();
    }, [tabItem])

    useEffect(() => {
        view();
    }, [viewType])

    const fetch = () => {
        setLoading(true);
        if (slug && Object.values(tabItem).length === 0) {
            getDealStagesBySlug({
                type: PipelineType.CRM,
                slug: slug
            }).then(response => {
                setLoading(false);
                if (!response || !response.ok || response.data.pipelines.length === 0) {
                    setData({});
                    return;
                }
                setSortedDeals(sortByColumnOrder(response.data.deals));
                setData(response.data);
                if (!tabItem?.id) {
                    setTabItem(response.data.pipeline[0])
                    setActiveTab(response.data.pipelines.findIndex(object => {
                        return object.id === response.data.pipeline[0].id;
                    }));
                    navigate(`/deal-pipelines/${response.data.pipeline[0].slug}`);
                }
            })
            return;
        }
        getDealStages({
            type: PipelineType.CRM,
            pipelineId: tabItem?.id
        }).then(response => {
            setLoading(false);
            if (!response || !response.ok) {
                setData({});
                return;
            }
            setSortedDeals(sortByColumnOrder(response.data.deals));
            setData(response.data);
            if (!tabItem?.id) {
                setTabItem(response.data.pipelines[0])
                navigate(`/deal-pipelines/${response.data.pipelines[0].slug}`);
            }
        })
    }

    const renderPipelineTabs = () => {
        return <div className='tab-container mx-4'>
            <Paper className='paper'>
                <Tabs variant="scrollable" value={activeTab} onChange={(e, tab) => {
                    setActiveTab(tab);
                }}>
                    {renderPipelineTab()}
                </Tabs>
            </Paper>
        </div>
    }

    const renderPipelineTab = () => {
        let result = [];

        if (data.pipelines) {
            for (let stage of data.pipelines) {
                result.push(
                    <Tab key={'stage-tab-' + stage.id} onClick={() => onTabClick(stage)} label={stage.name}/>
                );
            }
        }
        return result;
    }

    const onTabClick = (item) => {
        setTabItem(item);
        navigate(`/deal-pipelines/${item.slug}`);
    }

    const renderHeader = () => {
        return (
            <div className={'deal-pipeline-header mr-3'}>
                <div className={'filter-container right-filter'}>
                    {
                        renderToggleButtons(viewType, setViewType)
                    }
                    <IconButton className='add-button' onClick={() => setPageState(PageState.Add)}>
                        <img src='/images/table-page/add-cross.svg'/>
                        {strings.components.tablePage.addButton.deal}
                    </IconButton>
                </div>
            </div>
        );
    }

    const renderStages = () => {
        let result = [];
        if (data.stages) {
            for (let stage of data.stages) {
                result.push(
                    <Droppable key={'stage-' + stage.id} droppableId={stage.id + ''}>
                        {
                            (provided, snapshot) => (
                                <div
                                    className='stage-container'
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <div style={{backgroundColor: stage.color ? stage.color : 'black'}}
                                         className={'header'}>
                                        <div className="title">{stage.name}</div>
                                        <div className={`type ${getPipelineStageTypeClass(stage.type)}`}>
                                            {getPipelineStageTypeString(stage.type)}
                                        </div>
                                    </div>
                                    <div className='content'>
                                        {renderStage(stage)}
                                    </div>
                                    {provided.placeholder}
                                </div>
                            )}
                    </Droppable>
                );
            }
        }
        return result;
    }

    const renderStage = (stage) => {
        let result = [];

        for (let deal of sortedDeals) {
            if (!deal.pipeLineStage || stage.id !== deal.pipeLineStage.id) {
                continue;
            }
            result.push(
                <Draggable
                    key={"deal-" + deal.id}
                    draggableId={deal.id + ""}
                    index={result.length}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Card className='stage-card'>
                                <div className="name">{deal.name}</div>
                                <div className="description">{deal.description}</div>
                                <div className="control">
                                    <IconButton className='card-button' onClick={() => handleView(deal)}>
                                        <img src="/images/table-page/view-blue.svg"/>
                                    </IconButton>
                                    <IconButton className='card-button' onClick={() => handleEdit(deal)}>
                                        <img src="/images/edit-blue.svg"/>
                                    </IconButton>
                                    <IconButton className='card-button' onClick={() => handleDelete(deal)}>
                                        <img src="/images/delete-blue.svg"/>
                                    </IconButton>
                                </div>
                            </Card>
                        </div>
                    )}
                </Draggable>
            );
        }
        return result;
    }

    const onDragEnd = ({source, destination}) => {
        if (!destination || !source) return;

        const toStage = data.stages.find(s => s.id == destination.droppableId)
        const fromStage = data.stages.find(s => s.id == source.droppableId)

        let draggableDeals = [];

        for (let deal of sortedDeals) {
            if (!deal.pipeLineStage) {
                continue;
            }
            if (deal.pipeLineStage.id == source.droppableId) {
                draggableDeals.push(deal);
            }
        }

        if (source.droppableId === destination.droppableId) {
            let deals = reorder(draggableDeals, source.index, destination.index);

            let compareDeals = sortedDeals.filter(x => !deals.includes(x));

            let modifiedDeals = [...deals, ...compareDeals];

            updateDealStage({
                pipelineStageId: source.droppableId,
                deals
            }).then(response => {
                if (!response || !response.ok) {
                    return;
                }
                fetch();
            });
            setSortedDeals(modifiedDeals);

            return;
        }

        const sourceItems = [...draggableDeals];
        const destItems = sortedDeals.filter(x => x.pipeLineStage?.id == destination.droppableId);

        const otherItems = sortedDeals.filter(x => x.pipeLineStage?.id != toStage.id && x.pipeLineStage?.id != fromStage.id)

        let destinationList = reorderDoubleList(sourceItems, destItems, source.index, destination.index);
        destinationList.forEach(item => item.pipeLineStage = toStage);

        setSortedDeals([...sourceItems, ...destItems, ...otherItems])
        updateDealStage({
            pipelineStageId: destination.droppableId,
            deals: destinationList
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }
            fetch();
        });
    }

    const handleView = (deal) => {
        navigate(`/deal-details/${deal.id}/record`, {state: {deal: deal}})
    }
    const handleEdit = (deal) => {
        setPageState(PageState.Edit)
        setSelectedItems([deal])
    }
    const handleDelete = (deal) => {
        setSelectedItems([deal])
        setShowDeleteDialog(true)
    }

    const view = () => {
        if (viewType === ViewType.TABLE) {
            return handleNavigate();
        }
        return ViewType.PIPELINE
    }

    const handleNavigate = () => {
        navigate('/deals');
    }

    const isDrawerOpen = () => {
        return pageState === PageState.Add || pageState === PageState.Edit;
    }

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const handleDeleteDialogResult = (result) => {

        if (result === YesNoDialogResult.NO || result === YesNoDialogResult.CANCEL) {
            setShowDeleteDialog(false);
            return;
        }

        if (!selectedItems || selectedItems.length === 0) {
            setShowDeleteDialog(false);
            return;
        }

        deleteDeal(selectedItems[0].id).then(response => {
            if (!response || !response.ok) {
                showMessage(strings.components.tablePage.errorDeletingItem, 'error')
                setShowDeleteDialog(false);
                onFinish();
                return;
            }

            showMessage(strings.components.tablePage.itemDeleted, 'success')
            setShowDeleteDialog(false);
            onFinish();
        })
    }

    return <DrawerContext.Provider value={{drawerTitle, setDrawerTitle}}>
        <YesNoDialog show={showDeleteDialog}
                     handleResult={handleDeleteDialogResult}
                     title={strings.components.tablePage.confirmDelete}
                     text={strings.components.tablePage.confirmDeleteMessage}/>
        {renderPipelineTabs()}
        {renderHeader()}
        <div className='deal-pipeline-wrapper mx-4'>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className='stages-wrapper'>
                    {renderStages()}
                </div>
            </DragDropContext>
        </div>


        <Drawer id='drawer' anchor='right' open={isDrawerOpen()} onClose={() => setPageState(PageState.View)}>
            <DrawerWrapper onBack={() => setPageState(PageState.View)} title={drawerTitle}>
                {
                    pageState === PageState.Add &&
                    <AddDeal pipeline={tabItem} viewType={viewType} onCancel={() => onCancel()}
                             onFinish={() => onFinish()}/>
                }
                {
                    pageState === PageState.Edit &&
                    <EditDeal data={selectedItems}
                              onCancel={() => onCancel()} onFinish={() => onFinish()}/>
                }
            </DrawerWrapper>
        </Drawer>
    </DrawerContext.Provider>

}

export default DealPipeline;