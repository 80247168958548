import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {checkPagePermission, checkPath} from "../route";
import {getUserFromLocalStorage, isUserLocked, isUserLoggedIn} from "./OAuth";
import Login from "../Pages/User/Login";
import Lock from "../Pages/User/Lock";
import {isCompanyValid} from "../Util/CompanyUtil";
import {useDispatch, useSelector} from "react-redux";
import Forbidden from "../Pages/Error/Forbidden";
import {setAuth} from "../Slices/AuthSlice";
import {changeMenuState} from '../Slices/NavigationSlice';

const AuthWrapper = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate()
    const needAuth = checkPath(location.pathname);
    const auth = useSelector((state) => state.auth)
    dispatch(changeMenuState(auth?.user?.navigationState));

    useEffect(() => {
        let checkUserData = () => {
            const user = getUserFromLocalStorage();
            dispatch(setAuth(user));
            if (!user) {
                if (needAuth) {
                    navigate('');
                }
            }
        }
        checkUserData();
        window.addEventListener('storage', checkUserData)
        return () => {
            window.removeEventListener('storage', checkUserData)
        }
    }, [])

    const isRoot = () => location.pathname === '/' || location.pathname === '';

    const checkPermission = () => {
        if (needAuth && isUserLocked()) {
            return <Lock/>
        } else if (needAuth && !isUserLoggedIn()) {
            return <Login/>;
        } else if(isRoot() && !isCompanyValid(auth.company)) {
            navigate('/company-settings');
        }

        if(!checkPagePermission(location.pathname, auth.user, auth.permissions)) {
            return <Forbidden/>
        }

        return props.children;
    }

    return checkPermission();
};

export default AuthWrapper;
