import React, { useState } from "react";
import { Paper, Tab, Tabs } from "@mui/material";
import strings from "../localization";
import { HomeSettingsTab } from "../Constants/Home/HomeSettingsTab";
import Tasks from "./TaskManagement/Task/Tasks";
import { hasPermission } from "../Util/PermissionUtil";
import AppPermissions from "../Constants/Permissions/Permissions";
import { useSelector } from "react-redux";

const Home = () => {
    const [activeTab, setActiveTab] = useState(0);
    const permissionGroup = AppPermissions.MODULE.GROUP;
    const auth = useSelector((state) => state.auth);

    return (
        <React.Fragment>
            {
                // hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].HOME, auth.permissions)  &&
                // <div id="home-settings">
                //     <div className='tab-container'>
                //         <Paper className='paper'>
                //             <Tabs variant="scrollable" value={activeTab} onChange={(e, tab) => setActiveTab(tab)}>
                //                 <Tab label={strings.pages.home.settings.tasks} />
                //                 <Tab label={strings.pages.home.settings.taskTemplates} />
                //             </Tabs>
                //         </Paper>
                //     </div>
                //     {
                //         activeTab === HomeSettingsTab.TASK &&
                //         <Tasks />
                //     }
                //     {
                //         activeTab === HomeSettingsTab.TASK_TEMPLATE &&
                //         <TaskTemplates />
                //     }
                // </div>
            }
        </React.Fragment>
    )
};
export default Home;
