import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getTaskCategories(data){
    return await request('/api/task-category', data);
}

export async function getTaskCategory(id){
    return await request('/api/task-category/' + id);
}

export async function getAllTaskCategories(){
    return await request('/api/task-category/all');
}

export async function addTaskCategory(data){
    return await request('/api/task-category', transformRequestData(data), HttpMethod.POST);
}

export async function editTaskCategory(data){
    return await request('/api/task-category/' + data.id , transformRequestData(data), HttpMethod.PUT);
}

export async function deleteTaskCategory(id){
    return await request('/api/task-category/' + id , {}, HttpMethod.DELETE);
}

function transformRequestData(data){
    return {
        ...data,
    }
}
