import React, {useEffect, useState} from "react";
import {Grid, LinearProgress, Paper} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {getHRStatistics} from "../../Services/Statistic/StatisticService";
import {getCurrentYear} from "../../Util/DateUtil";
import strings from "../../localization";
import {renderNumber} from "../../Util/RenderDataUtil";
import {dataToListOfValues, monthShortLabels} from "../../Util/ChartUtil";
import BarChart from "../../Components/Charts/BarChart";
import LineChart from "../../Components/Charts/LineChart";
import { changeBreadcrumbs } from "../../Slices/BreadcrumbsSlice";
import MultipleSelectCheckmarks from "../../Components/Controls/Inputs/MultiSelectControl";
import {getAllUserCompanyAccess} from "../../Services/User/UserCompanyAccessService";
import {useSearchParams} from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const HRDashboard = () => {

    const dispatch = useDispatch();
    const [overview, setOverview] = useState(null);
    const auth = useSelector((state) => state.auth)

    const [year, setYear] = useState(getCurrentYear());

    const company = useSelector((state) => state.auth.company);
    const [companies, setCompanies] = useState([]);
    const [selected, setSelected] = React.useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isSelectAll, setIsSelectAll] =useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const [searchParams, setSearchParams] = useState('');

    useEffect(()=>{
        if(selectAll) {
            setSelected(companies);
        }else if(isSelectAll) {
            setSelected([]);
        }else {
            setSelected(selected);
        }
        setIsSelectAll(false)
    },[selectAll])

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));

        dispatch(changeBreadcrumbs({
            title: strings.pages.statistic.dashboard.hr,
            hierarchy:[
                {label: strings.navigation.homeTag},
                {label: strings.pages.statistic.dashboard.pageTitle},
                {label: strings.pages.statistic.dashboard.hr}
            ],        
        }));
        fetch();
    }, []);

    useEffect(()=>{
        setCompanies([]);
        fetchUserCompanies();
    },[searchParams])

    useEffect(() => {
        fetch();
    }, [year, selected]);

    const fetch = () => {
        const ids = [];

        if(selected.length !== 0){
            for (let company of selected) {
                ids.push(company?.id);
            }
        }

        if(firstLoad){
            ids.push(company?.id);
            setSelected([company]);
            setFirstLoad(false);
        }

        getHRStatistics({
            year: year,
            companiesId: ids,
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }

            setOverview({
                data: response.data
            });
        })
    }

    const averagePayment = (employeeCount, payments) => {

        let result = [];

        for(let i = 0; i < 12; i++) {

            let averagePayment = 0;

            if(employeeCount[i] && payments[i] && employeeCount[i].value !== 0) {
                averagePayment = payments[i].value / employeeCount[i].value;
            }

            result.push(averagePayment);
        }

        return result;
    }


    const handleChange = (event) => {
        const {
            target: {value},
        } = event;

        let duplicateRemoved = [];
        let isDuplicate = false;

        if(value.length!==0) {
            value.forEach((item) => {
                if (duplicateRemoved.findIndex((o) => o?.id === item?.id) >= 0) {
                    duplicateRemoved = duplicateRemoved.filter((x) => x?.id === item?.id);
                    isDuplicate = true;
                } else if(!item){
                    return;
                }else {
                    duplicateRemoved.push(item);
                }
            });
        }

        if(duplicateRemoved.length === companies.length){
            setSelectAll(true);
        }
        else {
            setSelectAll(false);
        }

        if(isDuplicate){
            setSelected([]);
            return;
        }

        setSelected(duplicateRemoved);
    };

    const fetchUserCompanies = () =>{
        if(!auth){
            return;
        }
        getAllUserCompanyAccess({userId: auth.id, term: searchParams}).then(response => {
            if(!response || !response.ok) {
                return;
            }
            let companiesList = [];
            let duplicateRemoved = [];

            for(let companyAccess of response.data){
                companiesList.push(companyAccess.company);
            }
            
            duplicateRemoved =  companiesList.map(e => e['id'])
                .map((e, i, final) => final.indexOf(e) === i && i)
                .filter((e) => companiesList[e]).map(e => companiesList[e]);

            setCompanies(duplicateRemoved);

            if(companiesList.length === 1){
                if(duplicateRemoved.length === companies.length){
                    setSelectAll(true);
                }
                else {
                    setSelectAll(false);
                }

                setSelected(duplicateRemoved);
            }
        })
    }

    return <div className='container hr-container'>

        {
            !overview &&
            <LinearProgress/>
        }

            <div className="year-flex">
                <div className='hr-year-flex'>
                <div className="year-select">
                    <img src={'/images/year-select-left.svg'}
                        className="control" onClick={() => setYear(year => --year)} loading={'lazy'}/>
                    <div className="value"> {year} </div>
                    <img src={'/images/year-select-right.svg'}
                            className="control" onClick={() => setYear(year => ++year)} loading={'lazy'}/>
                </div>
                </div>
            </div>
            <div className='company-flex'>
                <div className='company-select'>
                    <MultipleSelectCheckmarks
                        value={selected}
                        setValue={setCompanies}
                        name={'company'}
                        nameKey={'name'}
                        valueKey={'id'}
                        options={companies}
                        handleChange={handleChange}
                        selected={selected}
                        defaultValue={company}
                        type={'name'}
                        search={searchParams}
                        setSearch={setSearchParams}
                        selectAll={selectAll}
                        setSelectAll={setSelectAll}
                        setIsSelectAll={setIsSelectAll}
                        showSearch={true}
                        showAvatar={true}
                        className={'w-200'}
                        expandMoreIcon={ExpandMoreIcon}
                    />
                </div>
            </div>

        {
            overview &&
            <React.Fragment>
                <div id='statistic-overview'>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <div className='statistic-overview-item'>
                                <div className="title">{strings.pages.statistic.hrDashboard.employeeContractsNet}</div>
                                <div className="data">RSD: {renderNumber(overview.data.organisationUserContractNetTotalRSD ? overview.data.organisationUserContractNetTotalRSD.value : 0)}</div>
                                <div className="data">EUR: {renderNumber(overview.data.organisationUserContractNetTotalEUR ? overview.data.organisationUserContractNetTotalEUR.value : 0)}</div>
                                <div className="data">USD: {renderNumber(overview.data.organisationUserContractNetTotalUSD ? overview.data.organisationUserContractNetTotalUSD.value : 0)}</div>
                                <div className="data">CHF: {renderNumber(overview.data.organisationUserContractNetTotalCHF ? overview.data.organisationUserContractNetTotalCHF.value : 0)}</div>
                                <div className="data">GBP: {renderNumber(overview.data.organisationUserContractNetTotalGBP ? overview.data.organisationUserContractNetTotalGBP.value : 0)}</div>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className='statistic-overview-item'>
                                <div className="title">{strings.pages.statistic.hrDashboard.employeeContractsGross}</div>
                                <div className="data">RSD: {renderNumber(overview.data.organisationUserContractGrossTotalRSD ? overview.data.organisationUserContractGrossTotalRSD.value : 0)}</div>
                                <div className="data">EUR: {renderNumber(overview.data.organisationUserContractGrossTotalEUR ? overview.data.organisationUserContractGrossTotalEUR.value : 0)}</div>
                                <div className="data">USD: {renderNumber(overview.data.organisationUserContractGrossTotalUSD ? overview.data.organisationUserContractGrossTotalUSD.value : 0)}</div>
                                <div className="data">CHF: {renderNumber(overview.data.organisationUserContractGrossTotalCHF ? overview.data.organisationUserContractGrossTotalCHF.value : 0)}</div>
                                <div className="data">GBP: {renderNumber(overview.data.organisationUserContractGrossTotalGBP ? overview.data.organisationUserContractGrossTotalGBP.value : 0)}</div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className='graphs'>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.hrDashboard.employeeContractsNet}</div>
                            <BarChart labels={monthShortLabels}
                                      inputData={[
                                          {
                                              label: 'RSD',
                                              data: dataToListOfValues(overview.data.organisationUserContractNetDataRSD)
                                          },
                                          {
                                              label: 'EUR',
                                              data: dataToListOfValues(overview.data.organisationUserContractNetDataEUR)
                                          },
                                          {
                                              label: 'USD',
                                              data: dataToListOfValues(overview.data.organisationUserContractNetDataUSD)
                                          },
                                          {
                                              label: 'CHF',
                                              data: dataToListOfValues(overview.data.organisationUserContractNetDataCHF)
                                          },
                                          {
                                              label: 'GBP',
                                              data: dataToListOfValues(overview.data.organisationUserContractNetDataGBP)
                                          },
                                      ]}/>
                                </div>
                        </Grid>
                        <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.hrDashboard.employeeContractsGross}</div>
                            <BarChart labels={monthShortLabels}
                                      inputData={[
                                          {
                                              label: 'RSD',
                                              data: dataToListOfValues(overview.data.organisationUserContractGrossDataRSD)
                                          },
                                          {
                                              label: 'EUR',
                                              data: dataToListOfValues(overview.data.organisationUserContractGrossDataEUR)
                                          },
                                          {
                                              label: 'USD',
                                              data: dataToListOfValues(overview.data.organisationUserContractGrossDataUSD)
                                          },
                                          {
                                              label: 'CHF',
                                              data: dataToListOfValues(overview.data.organisationUserContractGrossDataCHF)
                                          },
                                          {
                                              label: 'GBP',
                                              data: dataToListOfValues(overview.data.organisationUserContractGrossDataGBP)
                                          },
                                      ]}/>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.hrDashboard.averagePaymentNet}</div>
                            <LineChart labels={monthShortLabels}
                                       inputData={[
                                           {
                                               label: 'RSD',
                                               data: averagePayment(overview.data.employees, overview.data.organisationUserContractNetDataRSD)
                                           },
                                           {
                                               label: 'EUR',
                                               data: averagePayment(overview.data.employees, overview.data.organisationUserContractNetDataEUR)
                                           },
                                           {
                                               label: 'USD',
                                               data: averagePayment(overview.data.employees, overview.data.organisationUserContractNetDataUSD)
                                           },
                                           {
                                               label: 'CHF',
                                               data: averagePayment(overview.data.employees, overview.data.organisationUserContractNetDataCHF)
                                           },
                                           {
                                               label: 'GBP',
                                               data: averagePayment(overview.data.employees, overview.data.organisationUserContractNetDataGBP)
                                           },
                                       ]}
                            />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.hrDashboard.averagePaymentGross}</div>
                            <LineChart labels={monthShortLabels}
                                       inputData={[
                                           {
                                               label: 'RSD',
                                               data: averagePayment(overview.data.employees, overview.data.organisationUserContractGrossDataRSD)
                                           },
                                           {
                                               label: 'EUR',
                                               data: averagePayment(overview.data.employees, overview.data.organisationUserContractGrossDataEUR)
                                           },
                                           {
                                               label: 'USD',
                                               data: averagePayment(overview.data.employees, overview.data.organisationUserContractGrossDataUSD)
                                           },
                                           {
                                               label: 'CHF',
                                               data: averagePayment(overview.data.employees, overview.data.organisationUserContractGrossDataCHF)
                                           },
                                           {
                                               label: 'GBP',
                                               data: averagePayment(overview.data.employees, overview.data.organisationUserContractGrossDataGBP)
                                           },
                                       ]}/>
                            </div>
                        </Grid>
                        <Grid item xs = {12}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.hrDashboard.employees}</div>
                            <LineChart labels={monthShortLabels}
                                       inputData={[
                                           {
                                               label: strings.pages.statistic.hrDashboard.employees,
                                               data: dataToListOfValues(overview.data.employees)
                                           }
                                       ]}
                                        options = {{
                                            aspectRatio: 3
                                        }}
                            />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.hrDashboard.nonWorkingDays}</div>
                            <LineChart labels={monthShortLabels}
                                       inputData={[
                                           {
                                               label: strings.pages.statistic.hrDashboard.nonWorkingDays,
                                               data: dataToListOfValues(overview.data.nonWorkingDays)
                                           }
                                       ]}/>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.hrDashboard.workingDays}</div>
                            <LineChart labels={monthShortLabels}
                                       inputData={[
                                           {
                                               label: strings.pages.statistic.hrDashboard.workingDays,
                                               data: dataToListOfValues(overview.data.workingDays)
                                           }
                                       ]}/>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.hrDashboard.totalWorkingDays}</div>
                            <LineChart labels={monthShortLabels}
                                       inputData={[
                                           {
                                               label: strings.pages.statistic.hrDashboard.totalWorkingDays,
                                               data: dataToListOfValues(overview.data.totalWorkingDays)
                                           },
                                           {
                                               label: strings.pages.statistic.hrDashboard.totalWorkingDaysCapacity,
                                               data: dataToListOfValues(overview.data.totalWorkingDaysCapacity)
                                           }
                                       ]}/>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                        <div className="graph">
                            <div className="title">{strings.pages.statistic.hrDashboard.totalWorkingHours}</div>
                            <LineChart labels={monthShortLabels}
                                       inputData={[
                                           {
                                               label: strings.pages.statistic.hrDashboard.totalWorkingHours,
                                               data: dataToListOfValues(overview.data.totalWorkingHours)
                                           },
                                           {
                                               label: strings.pages.statistic.hrDashboard.totalWorkingHoursCapacity,
                                               data: dataToListOfValues(overview.data.totalWorkingHoursCapacity)
                                           }
                                       ]}/>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        }


    </div>
}

export default HRDashboard;
