import {
  DataGrid,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useContext, useEffect, useState, useRef, useMemo } from "react";
import { Box, Drawer, IconButton, Paper } from "@mui/material";
import TextSearchControl from "../Controls/Inputs/TextSearchControl";
import { useForm } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import DrawerWrapper from "../Layout/DrawerWrapper";
import PageState from "../../Constants/Base/PageState";
import strings from "../../localization";
import DrawerContext from "../../Context/DrawerContext";
import TablePageContext, {
  FilterDefaults,
} from "../../Context/TablePageContext";
import { filterState } from "../../Context/TablePageContext";
import ActionCell from "./ActionCell";
import YesNoDialog, { YesNoDialogResult } from "../Dialogs/YesNoDialog";
import SelectControl from "../Controls/Inputs/SelectControl";
import { getMonths, getYears } from "../../Util/DateUtil";
import { formatQueryObject } from "../../Util/UrlUtil";
import SnackbarContext from "../../Context/SnackbarContext";
import { useSelector, useDispatch } from "react-redux";
import AppPermissions from "../../Constants/Permissions/Permissions";
import CompanyAccountType from "../../Constants/Permissions/CompanyAccountType";
import { hasPermission } from "../../Util/PermissionUtil";
import AutoCompleteControl from "../Controls/Inputs/AutoCompleteControl";
import { Icon, Popper, Select } from "@mui/material";
import LoaderContext from "../../Context/LoaderContext";
import { debounce } from "lodash";
import { useCallback } from "react";
import { changeUserPreference } from "../../Services/User/UserService";
import { setUser } from "../../Slices/AuthSlice";
import { useSearchParams } from "react-router-dom";
import TagCell from "./TagCell";
import { getAllTags } from "../../Services/DocumentManagement/TagService";
import MultipleSelectCheckmarks from "../Controls/Inputs/MultiSelectControl";
const DensityCompactIcon = () => (
  <Icon className="tp-icon density-icon">
    <img src="/images/table-page/compact-density.svg" />
  </Icon>
);
const DensityStandardIcon = () => (
  <Icon className="tp-icon density-icon">
    <img src="/images/table-page/standard-density.svg" />
  </Icon>
);
const DensityComfortableIcon = () => (
  <Icon className="tp-icon density-icon">
    <img src="/images/table-page/comfortable-density.svg" />
  </Icon>
);
const FilterClearButton = () => (
  <Icon className="tp-icon">
    {" "}
    <img src="/images/table-page/clear.svg" />
  </Icon>
);

const BasePopper = (props) => (
  <Popper {...props} className="data-grid-popper" />
);
const BaseSelect = (props) => (
  <Select {...props} className="data-grid-select" />
);

const TablePage = (props) => {
  const {
    tablePageOptions,
    setSelectedItems,
    navigate,
    selectionModel,
    setSelectionModel,
    pageState,
    setPageState,
    filter,
    setFilter,
    showDeleteDialog,
    setShowDeleteDialog,
    updatedFilter,
    setUpdatedFilter,
    filterDefaults,
  } = useContext(TablePageContext);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showMessage } = useContext(SnackbarContext);
  const [drawerTitle, setDrawerTitle] = useState("");
  const rowsPerPageOptions = JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE);
  const auth = useSelector((state) => state.auth);
  const form = useForm({
    defaultValues: {
      year: { id: filter?.year },
      month: { id: filter?.month },
    },
  });

  const { data, watch, setValue, getValues } = form;
  const { setLoading } = useContext(LoaderContext);
  const [areFiltersOpen, setAreFiltersOpen] = useState(
    auth.user?.tablePageFiltersState
  );
  const changeLocation = useRef(false);
  setLoading(props.tableData.loading);

  let watchValues = ["term", "year", "month"];

  if (props.filters) {
    for (let filter of props.filters) {
      watchValues.push(filter.name);
    }
  }

  watch(watchValues);

  const value = { drawerTitle, setDrawerTitle };

  useEffect(() => {
    if (props.resetForm) {
      form.reset();
      props.setResetForm(false);
    }
  }, [props.resetForm]);

  useEffect(() => {
    const subscription = watch((data) => {
      if (!updatedFilter) {
        return;
      }

      if (changeLocation.current) {
        return;
      }

      debounceSetFilter({
        ...filter,
        ...getFilterValues(data),
        term: data?.term,
        year: data.year ? data.year.id : undefined,
        month: data.month ? data.month.id : undefined,
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, updatedFilter, filter]);

  const debounceSetFilter = useCallback(
    debounce((newValue) => {
      setSearchParams(formatQueryObject(newValue));
      setFilter(newValue);
    }, 800),
    []
  );

  const populateFilters = (params) => {
    let searchParamsFilters = {};
    form.reset();
    for (const [key, value] of params.entries()) {
      if (key === "year" || key === "month") {
        setValue(key, { id: value });
        searchParamsFilters[key] = value;

        continue;
      }

      if (key === "term" || key === "perPage" || key === "page") {
        setValue(key, value);
        searchParamsFilters[key] = value;

        continue;
      }

      if (props.filterOptions) {
        const filterValue = props.filterOptions[key]?.find(
          (option) => option.id === parseInt(value)
        );
        if (filterValue) {
          setValue(key, filterValue);
          searchParamsFilters[key] = value;
        }
      }
    }

    changeLocation.current = false;
    setUpdatedFilter(true);

    return searchParamsFilters;
  };

  useEffect(() => {
    const fetchedFilterOptions = props.filterOptions
      ? Object.keys(props.filterOptions).length
      : true;
    if (!searchParams.toString() || !fetchedFilterOptions) {
      return;
    }

    if (props.filterOptions?.fetched && props.filterOptions.fetched !== 1) {
      return;
    }

    const searchParamsFilters = populateFilters(searchParams);
    setFilter({ ...filter, ...searchParamsFilters });
  }, [props.filterOptions]);

  useEffect(() => {
    const onLocationChange = () => {
      changeLocation.current = true;
      const searchParamsFilters = populateFilters(
        new URLSearchParams(window.location.search)
      );
      setFilter({ ...FilterDefaults, ...searchParamsFilters });
    };

    window.addEventListener("popstate", onLocationChange);

    return () => {
      window.removeEventListener("popstate", onLocationChange);
    };
  }, [props.filterOptions]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <div>
          <GridToolbarColumnsButton
            startIcon={<img src="/images/table-page/columns.svg" />}
          />
          <GridToolbarFilterButton
            componentsProps={{
              button: {
                startIcon: <img src="/images/table-page/filter.svg" />,
              },
            }}
          />
          <GridToolbarDensitySelector
            startIcon={<img src="/images/table-page/density.svg" />}
          />
          <GridToolbarExportContainer
            startIcon={<img src="/images/table-page/export.svg" />}
          >
            <div className="custom-export">
              <GridCsvExportMenuItem />
              <GridPrintExportMenuItem />
              <Icon className="tp-icon csv-icon">
                <img src="/images/table-page/download-csv.svg" />
              </Icon>
              <Icon className="tp-icon print-icon">
                <img src="/images/table-page/print.svg" />
              </Icon>
            </div>
          </GridToolbarExportContainer>
        </div>
        <div className="toolbar-custom-controls">
          {props.innerToolbarControls}
        </div>
      </GridToolbarContainer>
    );
  };

  const getFilterValues = (data) => {
    let result = {};

    for (let filter of props.filters) {
      result = {
        ...result,
        [filter.name]: data[filter.name]
          ? data[filter.name][filter.valueKey]
          : undefined,
      };
    }

    return result;
  };

  const onPageSizeChange = (perPage) => {
    setFilter({
      ...filter,
      perPage: perPage,
    });
  };

  const onPageChange = (page) => {
    setFilter({
      ...filter,
      page: page + 1,
    });
  };

  const isDrawerOpen = () => {
    return pageState !== PageState.View;
  };

  const deleteItem = (id) => {
    setShowDeleteDialog(true);
  };

  const handleAdd = () => {
    if (props.handleAdd) {
      props.handleAdd();
      return;
    }

    setPageState(PageState.Add);
  };

  const handleRefresh = () => {
    if (props.onReload) {
      props.onReload();
      return;
    }
    props.onFinish();
  };

  const handleDeleteDialogResult = (result) => {
    if (
      result === YesNoDialogResult.NO ||
      result === YesNoDialogResult.CANCEL
    ) {
      setShowDeleteDialog(false);
      return;
    }

    if (!props.deleteItem || !selectionModel || selectionModel.length === 0) {
      setShowDeleteDialog(false);
      return;
    }

    props.deleteItem(selectionModel[0]).then((response) => {
      if (!response || !response.ok) {
        if (props.showDeletingError) {
          showMessage(response.response.data.message, "error", 5000);
        } else {
          showMessage(strings.components.tablePage.errorDeletingItem, "error");
        }
        setShowDeleteDialog(false);
        setSelectionModel([]);
        props.onFinish();
        return;
      }

      showMessage(strings.components.tablePage.itemDeleted, "success");
      setShowDeleteDialog(false);
      setSelectionModel([]);
      props.onFinish();
    });
  };

  const handleDocuments = (id) => {
    navigate(`/documents/${id}/${props.referenceType}`);
  };

  const handleView = (id, item) => {
    if (props.handleView) {
      props.handleView(item);
      return;
    }

    setPageState(PageState.ViewDetails);
  };

  const handleEdit = (params) => {
    if (props.handleEdit) {
      props.handleEdit(params);
      return;
    }

    setPageState(PageState.Edit);
  };

  const handleTagView = (id, item) => {
    if (props.handleTagView) {
      props.handleTagView(item);
      return;
    }

    setPageState(PageState.Tag);
  };

  const getTableDescription = (tableDescription) => {
    const accountSettings = auth?.company?.accountSettings;
    const permissionGroup = "DOCUMENT";

    let actionCellItemsNumber = 4;

    const noDocumentAccess =
      (!accountSettings.documentModule &&
        accountSettings.accountType !== CompanyAccountType.TRIAL) ||
      !hasPermission(
        auth.user,
        permissionGroup,
        AppPermissions[permissionGroup].VIEW_LIST,
        auth.permissions
      ) ||
      !hasPermission(
        auth.user,
        props.permissionGroup,
        AppPermissions[props.permissionGroup].DOCUMENTS,
        auth.permissions
      );

    if (props.hideView) {
      actionCellItemsNumber--;
    }
    if (props.hideDocuments || noDocumentAccess) {
      actionCellItemsNumber--;
    }
    if (!tablePageOptions.showActionDelete || !props.deleteItem) {
      actionCellItemsNumber--;
    }

    actionCellItemsNumber += props.additionalMenuItems
      ? props.additionalMenuItems.length
      : 0;

    const actionCellWidth = actionCellItemsNumber * 42;

    if (!tableDescription || tableDescription.length === 0) {
      return tableDescription;
    }

    let result = tableDescription.map((cell) => {
      if (cell.valueFormatter) {
        const newCell = { ...cell, valueGetter: cell.valueFormatter };
        delete newCell.valueFormatter;
        return newCell;
      }
      return cell;
    });

    if (!props.hideActions) {
      result.unshift({
        field: "action",
        headerName: strings.components.tablePage.actions,
        width: actionCellWidth,
        renderCell: (params) => (
          <ActionCell
            params
            tablePageOptions={tablePageOptions}
            handleView={handleView}
            handleEdit={handleEdit}
            handleDelete={deleteItem}
            deleteItem={
              props.checkDelete ? params.row.canDelete : props.deleteItem
            }
            id={params.id}
            hideDocuments={props.hideDocuments || noDocumentAccess}
            hideView={props.hideView}
            editPage={props.editPage}
            handleDocuments={handleDocuments}
            referenceType={props.referenceType}
            item={params.row}
            permissionGroup={props.permissionGroup}
            additionalMenuItems={props.additionalMenuItems}
          />
        ),
      });
    }
    if (!props.hideTags) {
      result.unshift({
        field: "tag",
        headerName: strings.components.tablePage.tag,
        align: "right",
        width: 80,
        renderCell: (params) => (
          <TagCell
            params
            tablePageOptions={tablePageOptions}
            handleBookmark={handleTagView}
            id={params.id}
            referenceType={props.referenceType}
            item={params.row}
            permissionGroup={props.permissionGroup}
          />
        ),
      });
    }
    if (!props.hideDocuments) {
      result.push({
        field: "document_count",
        headerName: strings.components.tablePage.documents,
        align: "center",
        width: 100,
      });
    }

    return result;
  };

  const renderTableDescriptions = useMemo(
    () => getTableDescription(props.tableDescription),
    [props.tableDescription, props.additionalMenuItems]
  );

  const getItemById = (id, data) => {
    if (!id || !data) {
      return undefined;
    }

    return data.find((x) => x.id === id);
  };

  const getTableStyle = () => {
    let style = "table-paper";

    if (props.hideToolbar) style += " table-hide-toolbar";

    if (!props.noGreyRows) style += " table-grey-rows";

    if (props.tableData.total) style += " height-full";

    return style;
  };

  const handleSelectionChange = (newSelectionModel) => {
    setSelectionModel(newSelectionModel);

    if (!newSelectionModel || newSelectionModel.length === 0) {
      setSelectedItems([]);
    }

    let result = [];

    for (let id of newSelectionModel) {
      result.push(getItemById(id, props.tableData.data));
    }

    setSelectedItems(result);
  };

  const renderFilters = () => {
    let result = [];

    for (let filter of props.filters) {
      result.push(
        <div className="filter-item">
          {!filter.showSelect && (
            <AutoCompleteControl
              key={"table-filter-" + result.length}
              setValue={setValue}
              value={getValues()[filter.name]}
              name={filter.name}
              label={filter.label}
              options={props.filterOptions[filter.optionsName]}
              nameKey={filter.nameKey}
              valueKey={filter.valueKey}
              onChange={filter?.onChange}
              showClear={false}
              onOpen={filter?.onChange}
            />
          )}
          {filter.showSelect && (
            <SelectControl
              key={"table-filter-" + result.length}
              setValue={setValue}
              value={getValues()[filter.name]}
              name={filter.name}
              label={filter.label}
              options={props.filterOptions[filter.optionsName]}
              nameKey={filter.nameKey}
              valueKey={filter.valueKey}
            />
          )}
        </div>
      );
    }

    return result;
  };

  const handleClear = () => {
    form.reset();

    for (let filter of props.filters) {
      if (filter.onChange) {
        filter.onChange();
      }
    }
  };

  const CustomNoRowsOverlay = () => {
    return (
      <div className="table-page-no-data">
        {!props.tableData.loading && (
          <div>
            <img src="/images/table-page/no-data.svg" />
            <div className="no-data-title">
              {strings.components.tablePage.noData.title}
            </div>
            <div className="no-data-description">
              {strings.components.tablePage.noData.description}
            </div>
          </div>
        )}
      </div>
    );
  };

  const changeFiltersState = () => {
    let state = areFiltersOpen;
    if (areFiltersOpen === filterState.show) {
      setAreFiltersOpen(filterState.close);
      state = filterState.close;
    } else {
      setAreFiltersOpen(filterState.show);
      state = filterState.show;
    }

    changeUserPreference({ tablePageFiltersState: state }).then((response) => {
      if (!response || !response.ok) {
        return;
      }
      dispatch(setUser({ ...auth.user, ...response.data.user }));
    });
  };

  return (
    <DrawerContext.Provider value={value}>
      <YesNoDialog
        show={showDeleteDialog}
        payload={selectionModel}
        handleResult={handleDeleteDialogResult}
        title={strings.components.tablePage.confirmDelete}
        text={strings.components.tablePage.confirmDeleteMessage}
      />
      <div id={"table-page"}>
        {props.underBreadcrumbsSlot}
        <div className="header top-header">
          <div className={"filter-container left-filter"}>
            <div className={"search-container"}>
              <FormProvider {...form}>
                <div className="filter-item">
                  <TextSearchControl
                    name="term"
                    control={data}
                    defaultValue=""
                    margin="normal"
                    placeholder={"Search"}
                  />
                </div>
              </FormProvider>
            </div>
            <IconButton onClick={() => handleRefresh()}>
              <img src="/images/table-page/reload.svg" />
            </IconButton>
            {props.filters?.length > 0 && (
              <IconButton onClick={changeFiltersState}>
                {areFiltersOpen === filterState.show && (
                  <img src="/images/table-page/filters-toggle-shown.svg" />
                )}
                {areFiltersOpen === filterState.close && (
                  <img src="/images/table-page/filters-toggle-hidden.svg" />
                )}
              </IconButton>
            )}
          </div>
          <div className={"filter-container right-filter"}>
            {props.controls}
            {props.dealPage && props.renderToggleButtons()}
            {tablePageOptions.showActionAdd &&
              (hasPermission(
                auth.user,
                props.permissionGroup,
                AppPermissions[props.permissionGroup].ADD,
                auth.permissions
              ) ||
                hasPermission(
                  auth.user,
                  props.permissionGroup,
                  AppPermissions[props.permissionGroup].ADD_MY,
                  auth.permissions
                )) && (
                <IconButton
                  className="add-button"
                  onClick={() => handleAdd()}
                  disabled={
                    tablePageOptions.disabledActionAdd ||
                    props.disabledAddButton
                  }
                >
                  <img src="/images/table-page/add-cross.svg" />
                  {props.addButtonText}
                </IconButton>
              )}
          </div>
        </div>
        <div
          className={`header bottom-header ${
            areFiltersOpen === filterState.close ? "hidden" : ""
          }`}
        >
          <div className={"filter-container left-filter"}>
            <div className={"search-container"}>
              <FormProvider {...form}>
                {tablePageOptions.showYearFilter && (
                  <div className="filter-item">
                    <SelectControl
                      value={getValues().year}
                      setValue={setValue}
                      name="year"
                      label={strings.components.tablePage.year}
                      options={getYears(props.plusYears ? props.plusYears : 0)}
                      nameKey={"value"}
                      valueKey={"id"}
                    />
                  </div>
                )}
                {tablePageOptions.showMonthFilter && (
                  <div className="filter-item">
                    <SelectControl
                      value={getValues().month}
                      setValue={setValue}
                      name="month"
                      label={strings.components.tablePage.month}
                      options={getMonths()}
                      nameKey={"value"}
                      valueKey={"id"}
                    />
                  </div>
                )}
                {props.filters && props.filters.length > 0 && renderFilters()}

                {props.filters && props.filters.length > 0 && (
                  <IconButton onClick={handleClear}>
                    <img src="/images/table-page/clear.svg" />
                  </IconButton>
                )}
              </FormProvider>
            </div>
          </div>
        </div>

        <Paper className={getTableStyle()}>
          <DataGrid
            columns={renderTableDescriptions}
            rows={props.tableData.data}
            autoHeight
            rowHeight={55}
            headerHeight={55}
            onSelectionModelChange={(newSelectionModel) =>
              handleSelectionChange(newSelectionModel)
            }
            selectionModel={selectionModel}
            paginationMode="server"
            rowCount={props.tableData.total}
            onPageSizeChange={(perPage) => onPageSizeChange(perPage)}
            onPageChange={(page) => onPageChange(page)}
            pageSize={parseInt(filter.perPage)}
            rowsPerPageOptions={rowsPerPageOptions}
            checkboxSelection={props.checkboxSelection}
            components={{
              Toolbar: !props.hideToolbar && CustomToolbar,
              DensityComfortableIcon,
              DensityStandardIcon,
              DensityCompactIcon,
              FilterPanelDeleteIcon: FilterClearButton,
              BasePopper,
              BaseSelect,
              Footer:
                props.hideFooter &&
                (() => {
                  return null;
                }),
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            onCellClick={props?.onCellClick}
          />
        </Paper>

        <Drawer
          id="drawer"
          anchor="right"
          open={isDrawerOpen()}
          onClose={() => setPageState(PageState.View)}
        >
          <DrawerWrapper
            onBack={() => setPageState(PageState.View)}
            title={drawerTitle}
            viewDetails={pageState === PageState.ViewDetails}
          >
            {pageState === PageState.ViewDetails && props.editPage}
            {pageState === PageState.Add && props.addPage}
            {pageState === PageState.Edit && props.editPage}
            {pageState === PageState.Custom && props.customPage}
            {pageState === PageState.Tag && props.tagPage}
          </DrawerWrapper>
        </Drawer>
      </div>
    </DrawerContext.Provider>
  );
};

export default TablePage;
