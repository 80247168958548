import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import {getJobByPublicLink} from "../../../Services/HR/JobService";
import PageState from "../../../Constants/Base/PageState";
import strings from "../../../localization";
import {Drawer, IconButton} from "@mui/material";
import DrawerWrapper from "../../../Components/Layout/DrawerWrapper";
import AddApplication from "./AddApplication";
import {getCompanyLogo} from "../../../Services/Company/CompanyService";
import Button from "@mui/material/Button";

const JobApplication = () => {
    const form = useForm({
        defaultValues: {
            birthday: NaN,
            noPreviousExperience: false,
        }
    });
    const dispatch = useDispatch();
    const [pageState, setPageState] = useState(PageState.View);
    const {publicLink: jobPublicLink} = useParams();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [isRemote, setIsRemote] = useState('Non remote');
    const [drawerTitle, setDrawerTitle] = useState('');
    const [jobData, setJobData] = useState([]);
    const [companyLogo, setCompanyLogo] = useState();

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.job.addApplication.pageTitle);
    });

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.FULL_NO_HEADER));
    }, [])

    const fetch = () => {
        getJobByPublicLink(jobPublicLink).then(response => {
            if (!response || !response.ok) {
                return;
            }
            if (response.data.entity) {
                setValue('jobData', response.data.entity);
                setJobData(response.data.entity)
            }

            if (response.data.entity.isRemote) {
                setIsRemote('Remote')
            }
            getCompanyLogo(response.data.entity.user.company.id).then(response => {
                setCompanyLogo(response.data.logo);
            });
        });
    }


    const onSubmit = (data) => {
        setPageState(PageState.Add)
    }

    const onCancel = () => {
        setPageState(PageState.View);
    }
    const onFinish = () => {
        setPageState(PageState.View);
    }

    const isDrawerOpen = () => {
        return pageState !== PageState.View;
    }

    return <div>
        <div className='job-application-header'>
                <img className="logo-brain" src={'/images/brain.svg'}/>
                <img className="managevio" src={'/images/manageV!o.svg'}/>
                <img className="logo-text" src={'/images/foundation-for-future-growth.svg'}/>
        </div>
            <div className='left-side'>
                <div className='section'>
                    <div className='rectangle'></div>
                    <div className='ellipse'>
                        <img className='logo-img' src={companyLogo}/>
                    </div>
                    <div className='title pt-5 pb-5 pl-10'>{jobData?.user?.company?.name}</div>
                    <div className='pl-10 pr-10 font2'>Location</div>
                    <div className='pl-10 pr-10 mb-4'>{jobData?.user?.company?.city.name},{jobData?.user?.company?.country.name}</div>
                    <div className='pl-10 pr-10 font2'>About company</div>
                    <div className='pl-10 pr-10 pb-10 mb-4' dangerouslySetInnerHTML={{__html: jobData?.user?.company.about}}></div>
                </div>
                <div className='section pb'>
                    <div className='pl-10 pr-10 pt-10 font4'>Selection process</div>
                    <div className='pl-10 pr-10 pb-10 font1' dangerouslySetInnerHTML={{__html: jobData?.selectionProcess}}></div>
                </div>
                <div className='section'>
                    <div className='pl-5 pr-10 pt-5 font5'>Stay organized with
                        <div className='mb-2'>
                            <strong>
                                Managevio
                            </strong>
                        </div>
                    </div>
                    <div className='pl-5 pr-10 font3'>Choose a plan and get on board</div>
                    <div className='pl-5 pr-10 font3'>in minutes.</div>
                    <img className='pl-5 pr-10 pb-5 pt-5' src={'/images/icons/blue/vector.svg'}/>
                    <img className='beta-vio' src={'/images/beta-vio-figma.svg'} />
                </div>
            </div>
            <div className='right-side'>
               <div className='section mh-400'>
                   <div className='job-header'>
                       <div className='job-title pl-10 pr-10 pt-10 pb-10 title'>{jobData?.name}</div>
                       <Button variant="contained" color="primary" className='button' onClick={onSubmit}>Apply</Button>
                   </div>
                   {PageState.Add &&
                       <Drawer id='drawer' anchor='right' open={isDrawerOpen()} onClose={() => setPageState(PageState.View)}>
                           <DrawerWrapper onBack={() => setPageState(PageState.View)} title={drawerTitle}>
                               <AddApplication job={jobData} onCancel={() => onCancel()} onFinish={() => onFinish()}/>
                           </DrawerWrapper>
                       </Drawer>
                   }
                   <div className='job-data mb-10 ml-10'>
                       <div className='icon mr-2'>
                           <img src={'/images/icons/blue/notepad.svg'}/>
                       </div>
                       <div className='data'>
                           <div className='font2'>Job contract type</div>
                           <div className='font1'>{jobData?.jobContractType?.name}</div>
                       </div>
                   </div>
                   <div className='job-data mb-10 ml-10'>
                       <div className='icon mr-2'>
                           <img src={'/images/icons/blue/plugin.svg'}/>
                       </div>
                       <div className='data'>
                           <div className='font2'>Job type</div>
                           <div className='font1'>{isRemote}</div>
                       </div>
                   </div>
                   <div>
                       <div className='pl-10 pr-10 font2'>Description</div>
                       <div className='pl-10 pr-10 pb-10 font1' dangerouslySetInnerHTML={{__html: jobData?.description}}></div>
                   </div>
               </div>
            </div>
    </div>
}
export default JobApplication;