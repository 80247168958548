import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import PartnerForm from "../../../Components/Forms/Pages/CRM/Partner/PartnerForm";
import DrawerContext from "../../../Context/DrawerContext";
import {useSelector} from "react-redux";
import {getCountries, getCurrencies, getPartnerTypes} from "../../../Util/SiteDataUtil";
import {editPartner} from "../../../Services/CRM/PartnerService";
import LinearProgress from '@mui/material/LinearProgress';
import SnackbarContext from "../../../Context/SnackbarContext";
import {getPartnerLegalTypes} from "../../../Constants/CRM/PartnerLegalType";
import {getCompanyByName, getCompanyByRegistrationNumber, getCompanyByTaxNumber, getCompanyAccounts} from '../../../Services/Company/CompanyService';
import formatCharacters from '../../../Util/FormatCharacters';

const formRules = {
    'name': {required: true},
    'currency': {required: true},
    'country': {required: true},
    'partnerLegalType': {required: true},
}

const EditPartner = (props) => {
    const siteData = useSelector((state) => state.siteData)
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, watch, control, handleSubmit, getValues, setValue, setError, clearErrors, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const watchName = watch('name');
    const defaultCountry = getCountries(siteData).find((country) => country.code === 'RS');
    const defaultCurrency = getCurrencies(siteData).find((currency) => currency.code === 'RSD');

    const checkAccount = (accounts) => {
            const data = getValues();
            const hasOldAccount = accounts.find((account) => account.account === data.account.account || account.account === data.account);
            if(!hasOldAccount) setValue('account', '');
    }

    useEffect(() => {
        setDrawerTitle(strings.pages.crm.partner.editPartner.pageTitle);
    },[]);

    useEffect(() => {
        const subscription = watch((value) => {});
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if(watchName?.name){
            const data = getValues();
            for(const key in data){
                if(key !== 'name' && key !== 'id' && key !== 'account'){
                    if(watchName[key]){
                        setValue(key, watchName[key]);
                    }else if(Object.keys(watchName).length > 1){
                        setValue(key, '');
                    }
                }
            }
        }
        if(watchName?.registrationNumber){
            setValue('currency', defaultCurrency);
            setValue('country', defaultCountry);
            fetchCompanyAccounts(watchName.registrationNumber);
        }else{
            setBankAccounts([]);
        }
        clearErrors();
    },[watchName]);

    const fetchCompanyAccounts = (registrationNumber) => {
        getCompanyAccounts(registrationNumber).then( response => {
            if(!response || !response.ok){
                return;
            }
            checkAccount(response.data);
            setBankAccounts(response.data);
        });
    }

    const onSubmit = (data) => {
        setShowLoader(true);

        editPartner(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const fetchData = (data) => {
        getCompanyByName(data).then(response => {
            if(!response || !response.ok){
                return;
            }

            const tmp = response.data.map((company, index) => (
                {...company,
                    id: index,
                    name: formatCharacters(company?.name),
                    address: formatCharacters(company?.address),
                }
            ));
            setCompanies(tmp);
        });
    }

    const fetchRegistrationNumber = () => {
        const data = getValues();
        if(!data.registrationNumber) return;

        setShowLoader(true);

        getCompanyByRegistrationNumber(data.registrationNumber).then(response => {
            setShowLoader(false);

            if(!response || !response.ok){
                return;
            }

            if(response.data?.registrationNumber){
                setValue('currency', defaultCurrency);
                setValue('country', defaultCountry);
                clearErrors();
            }else{
                setError('registrationNumber');

                return;
            }

            const company = {
                ...response.data,
                name: formatCharacters(response.data?.name),
                address: formatCharacters(response.data?.address),
            };

            for(const key in company){
                if(key !== 'account'){
                    setValue(key, company[key]);
                }
            }

            checkAccount(company.account);
            setBankAccounts(company.account);
        });
    }

    const fetchTaxNumber = () => {
        const data = getValues();
        if(!data.taxNumber) return;

        setShowLoader(true);

        getCompanyByTaxNumber(data.taxNumber).then(response => {
            setShowLoader(false);

            if(!response || !response.ok){
                return;
            }

            if(response.data?.taxNumber){
                setValue('currency', defaultCurrency);
                setValue('country', defaultCountry);
                clearErrors();
            }else{
                setError('taxNumber');

                return;
            }

            let company = {
                ...response.data,
                name: formatCharacters(response.data?.name),
                address: formatCharacters(response.data?.address),
            };

            for(const key in company){
                if(key !== 'account'){
                    setValue(key, company[key]);
                }
            }

            checkAccount(company.account);
            setBankAccounts(company.account);
        });
    }

    const onCancel = () => {
        props.onCancel();
    }


    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <PartnerForm
                watch={watch}
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                currencies={getCurrencies(siteData)}
                countries={getCountries(siteData)}
                partnerTypes={getPartnerTypes(siteData)}
                partnerLegalTypes={getPartnerLegalTypes()}
                errors={errors}
                data={data}
                form={form}
                control={control}
                onSubmit={handleSubmit(onSubmit)}
                onCancel={() => onCancel()}
                onNameChange = {fetchData}
                companies = {companies}
                bankAccounts = {bankAccounts}
                onRegistrationSearch = {fetchRegistrationNumber}
                onTaxSearch = {fetchTaxNumber}
            />
        </Paper>
    </div>
}

export default EditPartner;
