import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";


export async function getCheckListTemplateQuestionOptions(data) {
    return await request('/api/check-list-template-question-options', data);
}

export async function addCheckListTemplateQuestionOption(data) {
    return await request('/api/check-list-template-question-option', data, HttpMethod.POST);
}

export async function deleteCheckListTemplateQuestionOption(id) {
    return await request('/api/check-list-template-question-option/' + id, {}, HttpMethod.DELETE);
}

export async function editCheckListTemplateQuestionOption(data) {
    return await request('/api/check-list-template-question-option/' + data.id, data, HttpMethod.PUT);
}

