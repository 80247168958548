import {Alert, Grid, Paper, Snackbar} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import FileUploadComponent from "../../../Components/Controls/FileUploadComponent";
import {changeLogo, getCompanyLogo} from "../../../Services/Company/CompanyService";
import {toBase64} from "../../../Util/ImageUtil";
import strings from "../../../localization";
import {useDispatch} from "react-redux";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import SnackbarContext from "../../../Context/SnackbarContext";
import {mbToBytes} from "../../../Util/FileUtil";
import { ImageFormats } from "../../../Constants/FileFormat";
import { changeImages } from "../../../Slices/NavigationSlice";


const Logo = ({company}) => {

    const dispatch = useDispatch();
    const [logo, setLogo] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const { showMessage } = useContext(SnackbarContext);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        dispatch(changeBreadcrumbs({
            title: strings.pages.company.logo.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.ADMINISTRATION},
                {label: strings.pages.company.company},
                {label: strings.pages.company.logo.pageTitle},
            ],        
        }));

        fetch();
    }, []);

    const fetch = () => {
        getCompanyLogo(company.id).then(response => {
            setLogo(response.data.logo)
        })
    }

    const uploadLogo = async (files) => {
        setLoading(true);
        const logo = await toBase64(files[0]);

        changeLogo({logo: logo}).then(response => {

            if(!response || !response.ok) {
                showMessage(strings.commonMessages.errorSaving, 'error');
                return;
            }

            setLogo(response.data.logo)
            showMessage(strings.commonMessages.saved, 'success');
            setFiles([]);
            dispatch(changeImages());
        }).finally(()=> setLoading(false));
    }

    return (
        <Grid 
            id={'logo-page'} 
            container 
            justifyContent="center"
            alignItems="center"
            className = 'company-logo-container'
        >
            <Grid item xs = {12} className = 'drop-container'>
                <FileUploadComponent upload={ uploadLogo } formats={ImageFormats} loading={loading} files = {files} setFiles = {setFiles} />
                {
                    logo &&
                    <img src={logo} loading={'lazy'}/>
                }
            </Grid>
        </Grid>
    )
}

export default Logo;
