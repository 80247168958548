const ProfileTab = {
    PROFILE: 0,
    CHANGE_PASSWORD: 1,
    SIGNATURE: 2,
    LOGO: 3,
    WORKING_HOURS: 4,
    PUBLIC_LINK: 5,

}

export default ProfileTab;