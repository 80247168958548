const ReferenceType = {
  NONE: 1,
  USER: 2,
  USER_POSITION: 3,
  JOB_STATUS: 4,
  JOB_CONTRACT_TYPE: 5,
  TEAM: 6,
  ORGANISATION_USER_CONTRACT: 7,
  PRODUCT_SERVICE: 8,
  PARTNER: 9,
  INVOICE: 10,
  CONTRACT: 11,
  TRANSACTION: 12,
  TRANSACTION_CATEGORY: 13,
  BANK_ACCOUNT: 14,
  BUSINESS_YEAR: 15,
  ROLE: 16,
  COMPANY_DOCUMENT_CATEGORY: 17,
  COMPANY_DOCUMENT_TEMPLATE: 18,
  COMPANY_DOCUMENT: 19,
  PROJECT: 20,
  PROJECT_CATEGORY: 21,
  DOCUMENT_CATEGORY: 22,
  CONTRACT_PART: 23,
  LEAVE_TYPE: 24,
  PROJECT_RESOURCE: 25,
  ADDITIONAL_HOURS: 26,
  COMPANY_DOCUMENT_TEMPLATE_ITEM: 27,
  DOCUMENT_STATUS: 28,
  BANK_REPORT: 29,
  CANDIDATE: 30,
  CANDIDATE_SOURCE: 31,
  JOB: 32,
  LEAVE: 33,
  LEAVE_REQUEST: 34,
  NON_WORKING_DAY: 35,
  ORGANISATION_USER_CONTRACT_PART: 36,
  EMPLOYEE: 37,
  CANDIDATE_STATUS: 38,
  ORGANISATION_UNIT: 39,
  BUSINESS_YEAR_START_VALUE: 40,
  TASK_CATEGORY: 41,
  ASSET: 42,
  ASSET_CATEGORY: 43,
  ASSET_MANAGEMENT_LOCATION: 44,
  ASSET_TYPE: 45,
  INVETORY: 46,
  INVETORY_CATEGORY: 47,
  INVETORY_TYPE: 48,
  STOCK_CHANGE: 49,
  VENDOR: 50,
  PARTNER_CONTACT: 51,
  PIPE_LINE: 52,
  DEAL_TYPE: 54,
  DEAL_PRIORITY: 55,
  DEAL: 56,
  TASK: 58,
  DEAL_SOURCE: 59,
  TASK_TEMPLATE: 60,
  PRESENCE: 61,
  CONTRACT_STATUS: 62,
  CONTRACT_PART_STATUS: 63,
  ISSUEFILE: 64,
  SPACES: 65,
  PRODUCTS_AND_SERVICES_CATEGORY: 66,
  COMPOSITE_PRODUCT: 67,
  ATTRIBUTES: 68,
  ATTRIBUTES_VALUE: 69,
  CHECK_LIST: 70,
  CHECK_LIST_SCHEDULED_CHECK: 71,
  WORKSPACES: 72,
  CONTRACT_PART_ITEM: 73,
  PROJECT_RESOURCE_TYPE: 74,
  BUSINESS_TRIP: 75,
};

export const HiddenReferenceType = {
  FILE_FOLDER: 53,
  PROJECT_TIME_LOG: 57,
};

export default ReferenceType;
