import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getDealSources(data){
    return await request('/api/deal-sources', data);
}

export async function addDealSource(data){
    return await request('/api/deal-sources', data, HttpMethod.POST);
}

export async function editDealSource(data){
    return await request('/api/deal-source/' + data.id, data, HttpMethod.PUT);
}

export async function deleteDealSource(id){
    return await request('/api/deal-source/' + id, {}, HttpMethod.DELETE);
}

