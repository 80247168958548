import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import CommentPageContext from "../../../Context/CommentPageContext";
import CommentPage from "../../../Components/DataGrid/CommentPage";
import {
    addEmployeeRecord,
    deleteEmployeeRecord,
    editEmployeeRecord,
    getAllEmployeeRecords
} from "../../../Services/HR/EmployeeRecordService";
import strings from "../../../localization";
import { changeBreadcrumbs } from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";

const EmployeeRecord = (props) => {

    const dispatch = useDispatch();
    const [selected, setSelected] = useState(null)
    const [editMode, setEditMode] = useState(null)
    const [employeeRecords, setEmployeeRecords] = useState([]);

    const permissionGroup = AppPermissions.EMPLOYEE_RECORDS.GROUP;

    const value = {
        selected, setSelected,
        editMode, setEditMode,
    }

    useEffect(() => {
        dispatch(changeBreadcrumbs({
            title: strings.pages.hr.employee.records,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.pages.user.employeeDetails.pageTitle},
                {label: strings.pages.hr.employee.records},
            ],        
        }));
    }, [])

    useEffect(() => {
        fetch();
    }, [props.employee])

    const fetch = () => {
        if(!props.employee) {
            return;
        }
        getAllEmployeeRecords(props.employee.id).then(response => {
            if(!response || !response.ok) {
                setEmployeeRecords([]);
                return;
            }
            setEmployeeRecords(response.data)
        })
    }

    const add = ({content}) => addEmployeeRecord({content, employeeId: props.employee.id});

    const edit = ({entity, content}) => editEmployeeRecord({...entity, content});


    return <CommentPageContext.Provider value={value}>
        <CommentPage permissionGroup={permissionGroup} entities={employeeRecords} deleteItem={deleteEmployeeRecord} onAdd={add} onFinish={fetch} onEdit={edit}/>
    </CommentPageContext.Provider>
}

export default EmployeeRecord;