import * as React from 'react';
import { useSelector} from "react-redux";
import {useContext, useRef, useState, useEffect} from "react";
import AppPermissions from "../../Constants/Permissions/Permissions";
import YesNoDialog, {YesNoDialogResult} from "../Dialogs/YesNoDialog";
import strings from "../../localization";
import {Button, Card, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper} from "@mui/material";
import { UserAvatar} from "../../Util/AvatarUtil";
import {dateToString} from "../../Util/DateUtil";
import MoreVert from "@mui/icons-material/MoreVert";
import {hasPermission} from "../../Util/PermissionUtil";
import {Edit} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import CommentPageContext from "../../Context/CommentPageContext";
import SnackbarContext from "../../Context/SnackbarContext";
import QuillEditor, { isEmpty } from '../Controls/Inputs/QuillEditor';
import { useDispatch } from 'react-redux';
import { changeBreadcrumbs } from '../../Slices/BreadcrumbsSlice';
import { changePageSizeState } from '../../Slices/PageSlice';
import PageSizeState from '../../Constants/Base/PageSizeState';

const CommentPage = ({permissionGroup, entities, onAdd, onEdit, deleteItem, onFinish}) => {
    const { showMessage } = useContext(SnackbarContext);
    const auth = useSelector((state) => state.auth)
    const {selected, setSelected, editMode, setEditMode} = useContext(CommentPageContext)
    const user = useSelector((state) => state.auth.user)
    const [anchorEl, setAnchorEl] = useState(null);
    const isSubmitting = useRef(false);
    const [editorData, setEditorData] = useState('');
    const [commentData, setCommentData] = useState('');
    const dispatch = useDispatch();


    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.document.document.documentList.documentComments,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: strings.pages.document.document.documentList.pageTitle},
                {label: strings.pages.document.document.documentList.documentComments},
            ],        
        }));
    }, [])

    const save = () => {
        if (onAdd) {
            isSubmitting.current = true;
            onAdd({content: editorData}).then(response => {
                if(!response || !response.ok) {
                    return;
                }
                resetForm();
                onFinish();
            }).finally(() => isSubmitting.current = false);
        }
    }
    const edit = (entity) => {
        if (onEdit) {
            isSubmitting.current = true;
            onEdit({content: commentData, entity}).then(response => {
                if(!response || !response.ok) {
                    return;
                }
                resetForm();
                onFinish();
            }).finally(() => isSubmitting.current = false);
        }
    }

    const editComment = () => {
        closeMenu();
        setEditMode(true);
    }

    const resetForm = () => {
        setEditorData('');
        setCommentData('');
        setEditMode(false);
        setSelected(null);
    }
    const deleteComment = () => {
        closeMenu();
        setShowDeleteDialog(true);
    }

    const handleDeleteDialogResult = (result, payload) => {
        if (result === YesNoDialogResult.NO || result === YesNoDialogResult.CANCEL) {
            setShowDeleteDialog(false);
            return;
        }
        if (!selected || selected.length === 0) {
            setShowDeleteDialog(false);
            return;
        }

        deleteItem(selected.id).then(response => {
            if (!response || !response.ok) {
                showMessage(strings.components.tablePage.errorDeletingItem, 'error')
                setShowDeleteDialog(false);
                setSelected(null);
                onFinish()
                return;
            }

            showMessage(strings.components.tablePage.itemDeleted, 'success')
            setShowDeleteDialog(false);
            setSelected(null);
            onFinish()
        })

    }

    const handleMenu = (event, comment) => {
        setSelected(comment);
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => setAnchorEl(null)

    const commentContent = (comment) => {
        if (selected?.id === comment.id && editMode) {
            
            return (<Paper className='paper'>
                <QuillEditor value={commentData} onChange={setCommentData} defaultValue={comment.content} />
                <Button variant="contained" color="primary" onClick={ () => edit(comment) } disabled={isEmpty(commentData) || isSubmitting.current}>
                    { strings.common.save }
                </Button>
                <Button variant="contained" color="secondary" onClick={ () => setEditMode(false) } >
                    { strings.common.cancel }
                </Button>
            </Paper>)
        }

        return (<div dangerouslySetInnerHTML={{ __html: comment.content }}/>);
    }

    const renderComments = () => {

        let result = [];

        for(let comment of entities) {
            result.push(
                <Card key={'record-' + result.length} className='record-card'>
                    <div className='header'>
                        <div className='header-user'>
                            <UserAvatar user={comment.user}/>
                            <h3>{comment.user.fullName}</h3>
                        </div>
                        <div>
                            <span>{dateToString(new Date(comment.dateCreated))}</span>
                            { user.id === comment.user.id &&
                            <span>
                            <IconButton size='small' onClick={(event) => handleMenu(event, comment)} disabled={editMode}
                                        aria-controls={"basic-menu-" + comment.id} aria-haspopup="true">
                                <MoreVert/>
                            </IconButton>
                            <Menu anchorEl={anchorEl} id={"basic-menu-" + comment.id} open={Boolean(anchorEl) && selected.id === comment.id} onClose={() => closeMenu()}>
                                    {
                                        hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].EDIT, auth.permissions) &&
                                        <MenuItem onClick={() => editComment(comment)}>
                                            <ListItemIcon>
                                                <Edit/>
                                            </ListItemIcon>
                                            <ListItemText>{strings.components.actionCell.edit}</ListItemText>
                                        </MenuItem>
                                    }
                                {
                                    hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].DELETE, auth.permissions) &&
                                    <MenuItem onClick={() => deleteComment(comment)}>
                                        <ListItemIcon>
                                            <DeleteIcon/>
                                        </ListItemIcon>
                                        <ListItemText>{strings.components.actionCell.delete}</ListItemText>
                                    </MenuItem>
                                }
                            </Menu>

                        </span>
                            }
                        </div>
                    </div>
                    {
                        commentContent(comment)
                    }
                </Card>
            )
        }

        return result;
    }
    return <Grid id='candidate-record' container justifyContent="center"
                 alignItems="center">
        <Grid item xs={8}>
            {
                hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].VIEW, auth.permissions) &&
                <Paper className='paper'>
                    {
                        hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].ADD, auth.permissions) &&
                        <QuillEditor className='large-editor' value={editorData} onChange={setEditorData}/>
                    }
                    {
                        hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].ADD, auth.permissions) &&
                        <Button variant="contained" color="primary" onClick={ () => save() } disabled={isEmpty(editorData) || isSubmitting.current}>
                            { strings.common.save }
                        </Button>
                    }

                    {
                        entities && entities.length > 0 &&
                        <div className='records-container'>
                            {renderComments()}
                        </div>
                    }

                </Paper>
            }
        </Grid>
        <YesNoDialog show={showDeleteDialog}
                     payload={selected}
                     handleResult={handleDeleteDialogResult}
                     title={strings.components.tablePage.confirmDelete}
                     text={strings.components.tablePage.confirmDeleteMessage}/>

    </Grid>


}

export default CommentPage;