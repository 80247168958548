import React, { useContext, useState, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import strings from "../../../../../localization";
import Button from "@mui/material/Button";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import EventDateTimeControl from "../../../../Controls/Inputs/EventDateTimeControl";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";

const BusinessTripCalculationForm = ({
  onSubmit,
  control,
  onCancel,
  form,
  values,
  setValue,
  formRules,
  errors,
  data,
  currencies,
}) => {
  const [accommodationCostInputs, setAccommodationCostInputs] = useState([
    {
      accommodationName_value: "",
      accommodationName: "accommodationName-1",
      accommodationCurrencyValue: "",
      accommodationCurrencyName: "accommodationCurrency-1",
      accommodationAmountValue: 0,
      accommodationAmountName: "accommodationAmount-1",
    },
  ]);

  const [transportationCostInputs, setTransportationCostInputs] = useState([
    {
      transportationNameValue: "",
      transportationName: "transportationName-1",
      transportationCurrencyValue: "",
      transportationCurrencyName: "transportationCurrency-1",
      transportatitonAmountValue: 0,
      transportationAmountName: "transportationAmount-1",
    },
  ]);

  const [otherCostInputs, setOtherCostInputs] = useState([
    {
      otherNameValue: "",
      otherName: "otherName-1",
      otherCurrencyValue: "",
      otherCurrencyName: "otherCurrency-1",
      otherAmountValue: 0,
      otherAmountName: "otherAmount-1",
    },
  ]);

  const [removedAccommodationCost, setRemovedAccommodationCost] = useState(0);
  const [removedTransportationCost, setRemovedTransportationCost] = useState(0);
  const [removedOtherCost, setRemovedOtherCost] = useState(0);
  const [startTrip, setStartTrip] = useState(values["startTrip"]);
  const [borderCrossingArrival, setBorderCrossingArrival] = useState(
    values["borderCrossingArrival"]
  );
  const [borderCrossingDeparture, setBorderCrossingDeparture] = useState(
    values["borderCrossingDeparture"]
  );
  const [endTrip, setEndTrip] = useState(values["endTrip"]);
  const [additionalRules, setAdditionalRules] = useState({});
  const [additionalRulesEndTrip, setAdditionalRulesEndTrip] = useState({});
  const [additionalRulesDeparture, setAdditionalRulesDeparture] = useState({});

  useEffect(() => {
    setStartTrip(values["startTrip"]);
  }, [values["startTrip"]]);
  useEffect(() => {
    setBorderCrossingArrival(values["borderCrossingArrival"]);
  }, [values["borderCrossingArrival"]]);
  useEffect(() => {
    setBorderCrossingDeparture(values["borderCrossingDeparture"]);
  }, [values["borderCrossingDeparture"]]);
  useEffect(() => {
    setEndTrip(values["endTrip"]);
  }, [values["endTrip"]]);

  useEffect(() => {
    changeAdditionalRules(startTrip);
  }, [startTrip]);
  useEffect(() => {
    changeAdditionalRulesEndTrip(borderCrossingArrival);
  }, [borderCrossingArrival]);
  useEffect(() => {
    changeAdditionalRulesDeparture(borderCrossingDeparture);
  }, [borderCrossingDeparture]);

  const changeStartTrip = (date) => {
    setValue("startTrip", date);
    setStartTrip(date);
  };
  const changeBorderCrossingDeparture = (date) => {
    setValue("borderCrossingDeparture", date);
    setBorderCrossingDeparture(date);
  };
  const changeBorderCrossingArrival = (date) => {
    setValue("borderCrossingArrival", date);
    setBorderCrossingArrival(date);
  };
  const changeEndTrip = (date) => {
    setValue("endTrip", date);
    setEndTrip(date);
  };

  const changeAdditionalRules = (date) => {
    if (!date) {
      setAdditionalRules({});
      return;
    }
    setAdditionalRules({
      min: {
        value: date,
        message: strings.pages.hr.businessTrip.addCalculation.dateTimeError,
      },
    });
  };

  const changeAdditionalRulesDeparture = (date) => {
    if (!date) {
      setAdditionalRulesDeparture({});
      return;
    }
    setAdditionalRulesDeparture({
      min: { value: date, message: "Invalid date" },
    });
  };

  const changeAdditionalRulesEndTrip = (date) => {
    if (!date) {
      setAdditionalRulesEndTrip({});
      return;
    }
    setAdditionalRulesEndTrip({
      min: { value: date, message: "Invalid date" },
    });
  };

  const plusAccommodationCostInput = () => {
    setAccommodationCostInputs([
      ...accommodationCostInputs,
      {
        accommodationNameValue: "",
        accommodationName: `accommodationName-${
          accommodationCostInputs.length + removedAccommodationCost + 1
        }`,
        accommodationCurrencyValue: "",
        accommodationCurrencyName: `accommodationCurrency-${
          accommodationCostInputs.length + removedAccommodationCost + 1
        }`,
        accommodationAmountValue: 0,
        accommodationAmountName: `accommodationAmount-${
          accommodationCostInputs.length + removedAccommodationCost + 1
        }`,
      },
    ]);
  };

  const plusTransportationCostInput = () => {
    setTransportationCostInputs([
      ...transportationCostInputs,
      {
        transportationNameValue: "",
        transportationName: `transportationName-${
          transportationCostInputs.length + removedTransportationCost + 1
        }`,
        transportationCurrencyValue: "",
        transportationCurrencyName: `transportationCurrency-${
          transportationCostInputs.length + removedTransportationCost + 1
        }`,
        transportationAmountValue: 0,
        transportationAmountName: `transportationAmount-${
          transportationCostInputs.length + removedTransportationCost + 1
        }`,
      },
    ]);
  };
  const plusOtherCostInput = () => {
    setOtherCostInputs([
      ...otherCostInputs,
      {
        otherName_value: "",
        otherName: `otherName-${otherCostInputs.length + removedOtherCost + 1}`,
        otherCurrencyValue: "",
        otherCurrencyName: `otherCurrency-${
          otherCostInputs.length + removedOtherCost + 1
        }`,
        otherAmountValue: 0,
        otherAmountName: `otherAmount-${
          otherCostInputs.length + removedOtherCost + 1
        }`,
      },
    ]);
  };

  const removeAccommodationCostInput = (e, element) => {
    setAccommodationCostInputs(
      accommodationCostInputs.filter(
        (item) =>
          item.accommodationCurrencyName !==
            element.accommodationCurrencyName &&
          item.accommodationAmountName !== element.accommodationAmountName &&
          item.accommodationName !== element.accommodationName
      )
    );
    setValue(`${element.accommodationCurrencyName}`, undefined);
    setValue(`${element.accommoationAmountName}`, undefined);
    setValue(`${element.accommodationName}`, undefined);
    setRemovedAccommodationCost(removedAccommodationCost + 1);
  };
  const removeTransportationCostInput = (e, element) => {
    setTransportationCostInputs(
      transportationCostInputs.filter(
        (item) =>
          item.transportationCurrencyName !==
            element.transportationCurrencyName &&
          item.transportationAmountName !== element.transportationAmountName &&
          item.transportationName !== element.transportationName
      )
    );
    setValue(`${element.transportationCurrencyName}`, undefined);
    setValue(`${element.transportationAmountName}`, undefined);
    setValue(`${element.transportationName}`, undefined);
    setRemovedTransportationCost(removedTransportationCost + 1);
  };
  const removeOtherCostInput = (e, element) => {
    setOtherCostInputs(
      otherCostInputs.filter(
        (item) =>
          item.otherCurrencyName !== element.otherCurrencyName &&
          item.otherAmountName !== element.otherAmountName &&
          item.otherName !== element.otherName
      )
    );
    setValue(`${element.otherCurrencyName}`, undefined);
    setValue(`${element.otherAmountName}`, undefined);
    setValue(`${element.otherAame}`, undefined);
    setRemovedOtherCost(removedOtherCost + 1);
  };

  const onAccommodationCostInputCurrency = (e, element) => {
    setValue(`${element.accommodationCurrencyName}`, e.target.value);
    element.accommodationCurrencyValue = e.target.value;
  };
  const onAccommodationCostInputAmount = (e, element) => {
    setValue(`${element.accommodationAmountName}`, e.target.value);
    element.accommodatioNamountValue = e.target.value;
  };
  const onAccommodationCostInputName = (e, element) => {
    setValue(`${element.accommodationName}`, e.target.value);
    element.accommodationNameValue = e.target.value;
  };

  const onTransportationCostInputCurrency = (e, element) => {
    setValue(`${element.transportationCurrencyName}`, e.target.value);
    element.transportationCurrencyValue = e.target.value;
  };
  const onTransportationCostInputAmount = (e, element) => {
    setValue(`${element.transportationAmountName}`, e.target.value);
    element.transportationAmountValue = e.target.value;
  };
  const onTransportationCostInputName = (e, element) => {
    setValue(`${element.transportationName}`, e.target.value);
    element.transportationNameValue = e.target.value;
  };

  const onOtherCostInputCurrency = (e, element) => {
    setValue(`${element.otherCurrencyName}`, e.target.value);
    element.transportationCurrencyValue = e.target.value;
  };
  const onOtherCostInputAmount = (e, element) => {
    setValue(`${element.otherAmountName}`, e.target.value);
    element.otherAmountValue = e.target.value;
  };
  const onOtherCostInputName = (e, element) => {
    setValue(`${element.otherName}`, e.target.value);
    element.otherNameValue = e.target.value;
  };

  const getAccommodationCostInputs = () => {
    return accommodationCostInputs.map((element, i) => {
      return (
        <div className="transaction-amount-part ">
          <TextFieldControl
            name={element.accommodationName}
            control={data}
            label={strings.pages.hr.businessTrip.calculationForm.name}
            className="amount costs-wrapper"
            defaultValue={element.value}
            onInput={(e) => onAccommodationCostInputName(e, element)}
          />
          <AutoCompleteControl
            className="costs-wrapper cost"
            value={values["currency"]}
            setValue={setValue}
            name={element.accommodationCurrencyName}
            options={currencies}
            nameKey={"code"}
            valueKey={"code"}
            onInput={(e) => onAccommodationCostInputCurrency(e, element)}
          />
          <TextFieldControl
            name={element.accommodationAmountName}
            type={"number"}
            label={strings.pages.hr.businessTrip.calculationForm.amount}
            control={data}
            className="amount costs-wrapper"
            defaultValue={element.value}
            onInput={(e) => onAccommodationCostInputAmount(e, element)}
          />

          <Button
            className="add-amount-button"
            onClick={(e) => removeAccommodationCostInput(e, element)}
          >
            <img src="/images/remove-icon-red.svg" />
          </Button>
        </div>
      );
    });
  };

  const getTransportationCostInputs = () => {
    return transportationCostInputs.map((element, i) => {
      return (
        <div className="transaction-amount-part">
          <TextFieldControl
            name={element.transportationName}
            control={data}
            label={strings.pages.hr.businessTrip.calculationForm.name}
            className="amount costs-wrapper"
            defaultValue={element.value}
            onInput={(e) => onTransportationCostInputName(e, element)}
          />
          <AutoCompleteControl
            className="costs-wrapper cost"
            value={values["currency"]}
            setValue={setValue}
            name={element.transportationCurrencyName}
            options={currencies}
            nameKey={"code"}
            valueKey={"code"}
            onInput={(e) => onTransportationCostInputCurrency(e, element)}
          />
          <TextFieldControl
            name={element.transportationAmountName}
            type={"number"}
            control={data}
            label={strings.pages.hr.businessTrip.calculationForm.amount}
            className="amount costs-wrapper"
            defaultValue={element.value}
            onInput={(e) => onTransportationCostInputAmount(e, element)}
          />
          <Button
            className="add-amount-button"
            onClick={(e) => removeTransportationCostInput(e, element)}
          >
            <img src="/images/remove-icon-red.svg" />
          </Button>
        </div>
      );
    });
  };

  const getOtherCostInputs = () => {
    return otherCostInputs.map((element, i) => {
      return (
        <div className="transaction-amount-part">
          <TextFieldControl
            name={element.otherName}
            control={data}
            label={strings.pages.hr.businessTrip.calculationForm.name}
            className="amount costs-wrapper"
            defaultValue={element.value}
            onInput={(e) => onOtherCostInputName(e, element)}
          />
          <AutoCompleteControl
            className="costs-wrapper cost"
            value={values["currency"]}
            setValue={setValue}
            name={element.otherCurrencyName}
            options={currencies}
            nameKey={"code"}
            valueKey={"code"}
            onInput={(e) => onOtherCostInputCurrency(e, element)}
          />
          <TextFieldControl
            name={element.otherAmountName}
            type={"number"}
            control={data}
            label={strings.pages.hr.businessTrip.calculationForm.amount}
            className="amount costs-wrapper"
            defaultValue={element.value}
            onInput={(e) => onOtherCostInputAmount(e, element)}
          />

          <Button
            className="add-amount-button"
            onClick={(e) => removeOtherCostInput(e, element)}
          >
            <img src="/images/remove-icon-red.svg" />
          </Button>
        </div>
      );
    });
  };

  return (
    <FormProvider {...form}>
      <form id="business-trip-list-page" className="form" action="#">
        <EventDateTimeControl
          data={data}
          form={form}
          rules={formRules["startTrip"]}
          label={strings.pages.hr.businessTrip.calculationForm.startTrip}
          name={"startTrip"}
          value={values["startTrip"]}
          setValue={(name, value) => changeStartTrip(value)}
          dateTime
          helperText={
            errors.startTrip && strings.forms.common.thisFieldIsRequired
          }
          error={Boolean(errors.startTrip)}
        />
        <EventDateTimeControl
          data={data}
          label={
            strings.pages.hr.businessTrip.calculationForm
              .borderCrossingDeparture
          }
          name={"borderCrossingDeparture"}
          value={values["borderCrossingDeparture"]}
          setValue={(name, value) => changeBorderCrossingDeparture(value)}
          dateTime
          rules={{
            ...formRules["borderCrossingDeparture"],
            ...additionalRules,
          }}
          helperText={
            errors.borderCrossingDeparture &&
            errors.borderCrossingDeparture.message
          }
          error={Boolean(errors.borderCrossingDeparture)}
          form={form}
          minDate={startTrip}
          minDateTime={startTrip}
        />
        <EventDateTimeControl
          data={data}
          label={
            strings.pages.hr.businessTrip.calculationForm.borderCrossingArrival
          }
          name={"borderCrossingArrival"}
          value={values["borderCrossingArrival"]}
          setValue={(name, value) => changeBorderCrossingArrival(value)}
          rules={{
            ...formRules["borderCrossingArrival"],
            ...additionalRules,
            ...additionalRulesDeparture,
          }}
          dateTime
          form={form}
          helperText={
            errors.borderCrossingArrival && errors.borderCrossingArrival.message
          }
          error={Boolean(errors.borderCrossingArrival)}
          minDate={
            borderCrossingDeparture ? borderCrossingDeparture : startTrip
          }
          minDateTime={
            borderCrossingDeparture ? borderCrossingDeparture : startTrip
          }
        />
        <EventDateTimeControl
          data={data}
          label={strings.pages.hr.businessTrip.calculationForm.endTrip}
          name={"endTrip"}
          value={values["endTrip"]}
          setValue={(name, value) => changeEndTrip(value)}
          rules={{
            ...formRules["endTrip"],
            ...additionalRules,
            ...additionalRulesDeparture,
            ...additionalRulesEndTrip,
          }}
          dateTime
          form={form}
          helperText={errors.endTrip && errors.endTrip.message}
          error={Boolean(errors.endTrip)}
          minDate={borderCrossingArrival ? borderCrossingArrival : startTrip}
          minDateTime={
            borderCrossingArrival
              ? borderCrossingArrival
              : borderCrossingDeparture
              ? borderCrossingDeparture
              : startTrip
          }
        />
        <div className="currencies">
          <AutoCompleteControl
            className="currency-wrapper"
            value={values["paidExpensesDomesticCurrency"]}
            setValue={setValue}
            name="paidExpensesDomesticCurrency"
            control={control}
            options={currencies}
            nameKey={"code"}
            valueKey={"id"}
            disabled={true}
          />
          <TextFieldControl
            name="paidExpensesViaBusinessCardDomesticCurrency"
            type="number"
            label={
              strings.pages.hr.businessTrip.calculationForm
                .paidExpensesViaBusinessCardDomesticCurrency
            }
          />
        </div>
        <div className="currencies">
          <AutoCompleteControl
            className="currency-wrapper"
            value={values["paidExpensesForeignCurrency"]}
            setValue={setValue}
            name="paidExpensesForeignCurrency"
            control={control}
            options={currencies}
            nameKey={"code"}
            valueKey={"id"}
            disabled={true}
          />
          <TextFieldControl
            name="paidExpensesViaBusinessCardInForeignCurrency"
            type="number"
            label={
              strings.pages.hr.businessTrip.calculationForm
                .paidExpensesViaBusinessCardInForeignCurrency
            }
          />
        </div>

        <div className="currencies">
          <AutoCompleteControl
            className="currency-wrapper"
            value={values["paidExpensesWithRemittancesCurrentAccount"]}
            setValue={setValue}
            name="paidExpensesWithRemittancesCurrentAccount"
            control={control}
            options={currencies}
            nameKey={"code"}
            valueKey={"id"}
            disabled={true}
          />
          <TextFieldControl
            name="paidExpensesWithRemittancesFromCurrentAccount"
            type="number"
            label={
              strings.pages.hr.businessTrip.calculationForm
                .paidExpensesWithRemittancesFromCurrentAccount
            }
          />
        </div>

        <div className="currencies">
          <AutoCompleteControl
            className="currency-wrapper"
            value={values["paidExpensesWithRemittancesForeignCurrencyAccount"]}
            setValue={setValue}
            name="paidExpensesWithRemittancesCurrentAccount"
            control={control}
            options={currencies}
            nameKey={"code"}
            valueKey={"id"}
            disabled={true}
          />
          <TextFieldControl
            name="paidExpensesWithRemittancesFromForeignCurrencyAccount"
            type="number"
            label={
              strings.pages.hr.businessTrip.calculationForm
                .paidExpensesWithRemittancesFromForeignCurrentAccount
            }
          />
        </div>
        <div className="currencies">
          <AutoCompleteControl
            className="currency-wrapper"
            value={values["otherAmountInDomesticCurrencyDeducted"]}
            setValue={setValue}
            name="otherAmountInDomesticCurrencyDeducted"
            control={control}
            options={currencies}
            nameKey={"code"}
            valueKey={"id"}
            disabled={true}
          />
          <TextFieldControl
            name="otherAmountDomesticCurrencyDeducted"
            type="number"
            label={
              strings.pages.hr.businessTrip.calculationForm
                .otherAmountInDomesticCurrencyDeducted
            }
          />
        </div>

        <div className="currencies">
          <AutoCompleteControl
            className="currency-wrapper"
            value={values["otherAmountForeignCurrencyDeducted"]}
            setValue={setValue}
            name="otherAmountForeignCurrencyDeducted"
            control={control}
            options={currencies}
            nameKey={"code"}
            valueKey={"id"}
            disabled={true}
          />
          <TextFieldControl
            name="otherAmountInForeignCurrencyDeducted"
            type="number"
            label={
              strings.pages.hr.businessTrip.calculationForm
                .otherAmountInForeignCurrencyDeducted
            }
          />
        </div>
        <TextFieldControl
          name="exchangeRate"
          type="number"
          label={strings.pages.hr.businessTrip.calculationForm.exchangeRate}
          rules={formRules["exchangeRate"]}
          helperText={
            errors.exchangeRate && strings.forms.common.thisFieldIsRequired
          }
          error={Boolean(errors.exchangeRate)}
        />

        {getTransportationCostInputs()}

        <div className="split-transaction">
          <Button
            className="split-button"
            onClick={plusTransportationCostInput}
          >
            <img src="/images/table-page/add-cross-blue.svg" />
            Add transportation costs
          </Button>
        </div>

        {getAccommodationCostInputs()}
        <div className="split-transaction">
          <Button className="split-button" onClick={plusAccommodationCostInput}>
            <img src="/images/table-page/add-cross-blue.svg" />
            Add accommodation costs
          </Button>
        </div>

        {getOtherCostInputs()}
        <div className="split-transaction">
          <Button className="split-button" onClick={plusOtherCostInput}>
            <img src="/images/table-page/add-cross-blue.svg" />
            Add other costs
          </Button>
        </div>
        <TextFieldControl
          name="description"
          label={strings.pages.hr.businessTrip.calculationForm.description}
        />

        <div className="submit-container">
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.forms.common.save}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.forms.common.cancel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default BusinessTripCalculationForm;
