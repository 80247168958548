import React from "react";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {Avatar, Chip, Tooltip} from "@mui/material";
import InvoiceStatus, {getInvoiceStatusString} from "../../Constants/Finance/InvoiceStatus";
import {renderNumber} from "../../Util/RenderDataUtil";
import strings from "../../localization";
import Icon from "react-icons-kit";
import {filePdfO} from 'react-icons-kit/fa/filePdfO';
import {fileImageO} from 'react-icons-kit/fa/fileImageO';
import {file} from 'react-icons-kit/fa/file';
import { downloadDocument } from "../../Services/DocumentManagement/DocumentService";
import { IconButton } from "@mui/material";
import PaymentType, {getPaymentTypeString} from "../../Constants/Finance/PaymentType";
import {dateToString} from "../../Util/DateUtil";
import EmployeeStatus, {getEmployeeStatusString} from "../../Constants/HR/EmployeeStatus";
import {UserAvatar, WorkspaceLogo, TeamLogo} from "../../Util/AvatarUtil";
import store from "../../store";
import {Link} from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';
import { WorkspacesIssueTypesNames, formatIssueName } from '../../Constants/Workspaces/WorkspacesIssueTypes';
import { BoardIssuePriorityNames } from '../../Constants/Workspaces/BoardIssuePriority';
import AdditionalHoursStatus, { getAdditionalHoursStatusString } from "../../Constants/HR/AdditionalHoursStatus";

export const returnColumnBoolean = (params) => {

    if(params.value === null || params.value === undefined) {
        return '';
    }

    return params.value ? <DoneIcon style={{color: '#069e0a'}}/> : <CloseIcon style={{color: '#d32f2f'}}/>;
}

export const renderColumnInvoiceStatus = (props) => {
    return <Chip label={getInvoiceStatusString(props.value)} className={'invoice-status-chip-' + props.value}/>
}

export const renderEColumnInvoiceStatus = (props) => {
    if(props.row.isInoInvoice){
        return '';
    }

    return <Chip label={props.value} className={'e-invoice-status-chip e-invoice-status-chip-' + props.value}/>
}

export const renderColumnColorChip = (params,key) => {
    return params.value && <Chip sx={{ border: '1px solid'+ params.value.color, bgcolor: hexToRGB(params.value.color, 0.6)}} alt={params.value} label={params.value[key]} className={'column-color-chip'} />
}

export const renderColumnNumber = (params) => {
    return renderNumber(params.value);
}

export const renderColumnPercent = (params) => {
    return renderNumber(params.value, '', '%');
}

export const renderColumnOverdue = (params) => {

    let diff = 0;


    if (params.row.lastPaymentDate) {
        diff = new Date(params.row.lastPaymentDate) - new Date(params.row.paymentDueDate);
    } else {
        diff = new Date() - new Date(params.row.paymentDueDate);
    }

    if (isNaN(diff) || diff <= 0) {
        return '';
    }

    const days = parseInt(diff / (24 * 60 * 60 * 1000));

    if (days <= 0) {
        return '';
    }

    let chipClass = ''

    if (days > 30) {
        chipClass = 'error'
    } else if (days > 15) {
        chipClass = 'warning'
    }

    return <Chip label={ days + ' ' + strings.common.days } className={'invoice-chip invoice-overdue-chip-' + chipClass}/>
}

export const renderColumnDocumentType = (params) => {
    if(params === DocumentType.IMAGE){
        return <Icon icon={fileImageO} />
    }

    if(params === DocumentType.PDF) {
        return <Icon icon={filePdfO} />
    }

    return <Icon icon={file} />
}

export const renderColumnControls = (item) => {
    const isDisabled = isDisabledDocument(item.row);
    return <IconButton onClick={ () => download(item)} disabled={isDisabled}>
        <DownloadIcon/>
    </IconButton>
}

const download = (item) =>{
    downloadDocument(item.row)
}

export const isDisabledDocument = (item) => {
    let { companyS3Settings } = store.getState().auth;
    return !item.referenceType || !companyS3Settings || Object.keys(companyS3Settings).find(key => companyS3Settings[key].length===0)
}

export const renderColumnPaymentType = (params) => {

    const className = params.value === PaymentType.INCOME ? 'transaction-payment-type income' : 'transaction-payment-type outcome';

    return <Chip className={className} label={getPaymentTypeString(params.value)} />
}

export const renderValueWithTooltip = (params) => {
    return <Tooltip title={params.value}>
        <span className={'cell-tooltip-content'}>{params.value}</span>
    </Tooltip>
}

export const renderColumnColorWithTooltip = (params,key) =>{
    if(!params.value){
        return '';
    }
    return <Tooltip title={params.row[key]}>
        <Avatar sx={{ bgcolor: params.value, width: 24, height: 24 }} alt={params.value} ><span className={'cell-tooltip-content'}></span> </Avatar>
    </Tooltip>
}

export const renderColumnAvatar = (params) => {

    return <UserAvatar user={params.row}/>
}

export const renderColumnWorkspaceLogo = (params) => {

    return <WorkspaceLogo workspace = {params.row}/>
}

export const renderColumnTeamLogo = (team) => {

    return <TeamLogo team = {team}/>
}

export const renderColumnNextRaise = (params) => {

    if(!params.value) {
        return '';
    }

    let diff = 0;

    diff = new Date(params.value) - new Date();


    if (isNaN(diff)) {
        return ''
    }

    const days = parseInt(diff / (24 * 60 * 60 * 1000));
    let chipClass = ''

    if (days <= 0) {
        chipClass = 'error';
    }
    else if (days > 0 && days < 30) {
        chipClass = 'warning'
    } else if (days > 15) {
        chipClass = 'success'
    }

    return <Chip label={dateToString(new Date(params.value))} className={'table-chip table-chip-' + chipClass}/>
}

export const renderColumnAdditionalHoursStatus = (params) => {

    let chipClass = 'success';

    if(params.value === getAdditionalHoursStatusString(AdditionalHoursStatus.REJECTED)) {
        chipClass = 'error';
    }
    else if(params.value === getAdditionalHoursStatusString(AdditionalHoursStatus.PENDING)) {
        chipClass = 'warning';
    }

    return <Chip label={params.value} className={'table-chip table-chip-' + chipClass}/>
}

export const renderColumnEmployeeStatus = (params) => {

    let chipClass = 'success';

    if(params.value === EmployeeStatus.EX_EMPLOYEE) {
        chipClass = 'error';
    }
    else if(params.value === EmployeeStatus.RESIGNATION) {
        chipClass = 'warning';
    }

    return <Chip label={getEmployeeStatusString(params.value)} className={'table-chip table-chip-' + chipClass}/>
}

export const renderColumnPipline = (params, hasPermission) => {

    return <Chip label={params.value.name} 
        className={hasPermission ? 
            'table-chip table-chip-pipline has-pipeline-permission' 
            : 
            'table-chip table-chip-pipline'} 
        />
}

const hexToRGB = (hex, alpha) => {
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export const renderColumnLink = (hasPermission, text, path, state = {}) => {

    if(!text) {
        return '';
    }

    return <Link to={path} state={state} className = {hasPermission ? '' : 'disabled-link'}>{text}</Link>
}

export const renderTypeIcon = (type) => {

    return(
        <div className = {`issue-icon-container ${WorkspacesIssueTypesNames[type]}-type`}>
            <img src = {`/images/${WorkspacesIssueTypesNames[type]}-issue-type.svg`} />
        </div>
    );
}

export const renderIssueTypeColumn = (issue) => {
    const typeName = formatIssueName(issue.type);
    return (
        <div className = 'issue-type-container'>
            <p>{typeName}</p>
            {renderTypeIcon(issue.type)}
        </div>
    );
}

export const renderPriorityIcon = (priority) => {

    return(
        <img src = {`/images/issue-priority-${BoardIssuePriorityNames[priority]}.svg`} />
    );
}

export const renderHtml = (params) => {
    return (<div dangerouslySetInnerHTML={{ __html: params.value}}/>)
}

export const renderLink = (params) => {
    return (<a target='_blank' href={process.env.REACT_APP_FRONTEND_BASE_URL+'job-application/'+params.value}>{process.env.REACT_APP_FRONTEND_BASE_URL}job-application/{params.value}</a>)
}
