import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import TransactionForm from "../../../Components/Forms/Pages/Finance/Transaction/TransactionForm";
import DrawerContext from "../../../Context/DrawerContext";
import LinearProgress from '@mui/material/LinearProgress';
import { editTransaction } from "../../../Services/Finance/TransactionService";
import SnackbarContext from "../../../Context/SnackbarContext";
import {getSiteData} from "../../../Services/Base/DefaultService";
import {getBankReportList} from "../../../Services/Finance/BankReportService";
import {getContractParts} from "../../../Services/Finance/ContractPartService";
import {getEmployees} from "../../../Services/User/UserService";
import {getInvoices} from "../../../Services/Finance/InvoiceService";
import {getTransactionCategoryList} from "../../../Services/Finance/TransactionCategoryService";
import {dateToString} from "../../../Util/DateUtil";
import {getTaxRates} from "../../../Util/SiteDataUtil";
import {getPartners} from "../../../Services/CRM/PartnerService";
import {getContracts} from "../../../Services/Finance/ContractService";
import {getInvoiceDirectionFromPaymentType} from "../../../Util/InvoiceUtil"

const formRules = {
    'bankReport': {required: true}, 'paymentType': {required: true}, 
    'partnerName': {required: true}, 'description': {required: true}, 
    'paymentDate': {required: true}, 'income': {required: true}, 
    'outcome': {required: true}, 'currency': {required: true}, 
}

const EditTransaction = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? {...props.data[0], paymentType:{id: props.data[0].paymentType }} : undefined
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    
    const [bankReports, setBankReports] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [partners, setPartners] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [contractParts, setContractParts] = useState([]);
    const [transactionCategories, setTransactionCategories] = useState([]);
    const [taxRates, setTaxRates] = useState([]);

    useEffect(() => {
        setDrawerTitle(strings.pages.finance.transactions.editTransaction.pageTitle);
    });

    useEffect(() => {
        fetch().then(() => setShowLoader(false));
    },[])

    const fetch = async () => {
        setShowLoader(true);
        const siteData = await getSiteData();

        setCurrencies(siteData.data.currencies);
        setTaxRates(getTaxRates(siteData));

        fetchBankReports();
        fetchPartners();
        fetchEmployees();
        fetchTransactionCategories();

    }

    const fetchBankReports = (term = '') => {
        getBankReportList({term}).then(response => {
            setBankReports(response.data.result);
        })
    }
    const fetchPartners = (term = '') => {
        getPartners({term}).then(response => {
            setPartners(response.data.result);
        })
    }
    const fetchContracts = (term = '') => {
        getContracts({term, partner: getValues()?.partner?.id}).then(response => {
            setContracts(response.data.result);
        })
    }
    const fetchContractParts = (term = '') => {
        getContractParts({term, contractId: getValues()?.contract?.id}).then(response => {
            setContractParts(response.data.result.map(cp => ({...cp, formatName: `${dateToString(new Date(cp.paymentDate))} (${cp.contract?.name})`})));
        })
    }
    const fetchInvoices = (term = '') => {
        getInvoices({term, partner: getValues()?.partner?.id, invoiceDirection: getInvoiceDirectionFromPaymentType(getValues()?.paymentType?.id)}).then(response => {
            setInvoices(response.data.result);
        })
    }
    const fetchEmployees = (term = '') => {
        getEmployees({term}).then(response => {
            setEmployees(response.data.result.map(u => u.user));
        })
    }
    const fetchTransactionCategories = (term = '') => {
        getTransactionCategoryList({term}).then(response => {
            setTransactionCategories(response.data.result);
        })
    }

    const onSubmit = (data) => {
        setShowLoader(true);

        editTransaction(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <TransactionForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                control={control}
                
                bankReports = {bankReports}
                currencies = {currencies}
                partners = {partners}
                contracts = {contracts}
                employees = {employees}
                invoices = {invoices}
                contractParts = {contractParts}
                transactionCategories = {transactionCategories}
                taxRates = {taxRates}

                onChangeTransactionCategoryFilter={fetchTransactionCategories}
                onChangePartnerFilter={fetchPartners}
                onChangeEmployeeFilter={fetchEmployees}
                onChangeBankReportFilter={fetchBankReports}
                onChangeInvoiceFilter={fetchInvoices}
                onChangeContractFilter={fetchContracts}
                onChangeContractPartFilter={fetchContractParts}

                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditTransaction;