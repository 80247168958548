import InvoiceDirection from "../../../../../Constants/Finance/InvoiceDirection";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import React, { useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import {Button} from "@mui/material";
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";
import ReferenceType from "../../../../../Constants/DocumentManagement/ReferenceType";
import {fetchExchangeRate} from "../../../../../Services/Finance/CurrencyService";

const formRules = {

    [InvoiceDirection.OUT]: {
        'quantity': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}, min: {value: 1, message: ''}},
        'productService': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    },
    [InvoiceDirection.IN]: {
        'quantity': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}, min: {value: 1, message: ''}},
        'name': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
        'tax': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
        'unit': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    },
    price:{required: {value: true, message: strings.forms.common.thisFieldIsRequired}, min: {value: 0.0001, message: ''}}
}

const InvoiceItemsForm = ({siteData, invoiceData, invoiceId, addItems, pageState, onChangeProductFilter}) => {
    const form = useForm({mode: 'onChange'});
    const {data, handleSubmit, watch, getValues, setValue, control, formState: {errors}, trigger} = form;
    const values = getValues();

    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    const filterProductServices = () => {
        // if(invoiceData.currency){
        //     let result = siteData.productServices.filter(x => x.currency?.code === invoiceData.currency?.code);
        //     return result;
        // }
        return siteData.productServices;
    }

    useEffect(() => {
        if(invoiceId){
            resetForm()
        }
    },[invoiceId])

    useEffect(() => {
        if(invoiceData.partner){
            resetForm();
        }
    },[invoiceData.partner]);

    const resetForm = () => {
        setValue('quantity', 0);
        trigger('quantity');
        if (invoiceData.invoiceDirection === InvoiceDirection.OUT) {
            setValue('productService', undefined)
            trigger('productService');

        } else if (invoiceData.invoiceDirection === InvoiceDirection.IN) {
            setValue('unit', undefined)
            setValue('tax', undefined)
            setValue('price', 0)
            setValue('name', '');
            trigger('name');
            trigger('price');
            trigger('unit');
            trigger('tax');
        }
    }

    const add = () => {

        if(!addItems) {
            return;
        }

        let data = getValues()

        if(data.productService && data.productService.currency.id !== invoiceData.currency.id) {
            fetchExchangeRate(data.productService.currency.code, invoiceData.currency.code, invoiceData.invoiceDate).then(response => {

                let rate = 1;

                if(response?.data?.middleRate) {
                    rate = response?.data?.middleRate
                }

                data = {
                    ...data,
                    productService: {
                        ...data.productService,
                        price: data.productService.price * rate
                    }
                }

                addItems({
                    ...data,
                    price: data.price * rate,
                    name: data.name
                });
                resetForm()
            });
        }
        else {
            addItems({
                ...data,
                name: data.name
            });
            resetForm()
        }
    }

    const changeProductService = (name, value) => {
        setValue(name, value);
        setValue('quantity', 0);
    }


    return (
        <FormProvider {...form}>
            <form id="invoice-items-form" action = "#">
                <div className='header-row'>
                    {
                        invoiceData.invoiceDirection === InvoiceDirection.OUT &&
                        <div className='header-row-item'>
                            <AutoCompleteControl
                                name='productService'
                                className='mui-shifted-label-input-invoice corrected-label-margin'
                                label={strings.forms.finance.invoice.invoiceItemsForm.productService}
                                nameKey={'name'}
                                valueKey={'id'}
                                rules={formRules[invoiceData?.invoiceDirection]['productService']}
                                setValue={changeProductService}
                                onChange={onChangeProductFilter}
                                onOpen={() =>onChangeProductFilter()}
                                control={control}
                                options={filterProductServices()}
                                value={values['productService']}
                                disabled={isPageStateViewDetails()}
                                placeholder={strings.forms.finance.invoice.invoiceItemsForm.placeholder.productService}
                                quickAdd={true}
                                referenceType={ReferenceType.PRODUCT_SERVICE}
                            />
                        </div>
                    }

                    {
                        invoiceData.invoiceDirection === InvoiceDirection.IN &&
                        <div className='header-row-item'>
                            <TextFieldControl
                                name='name'
                                className='mui-shifted-label-input-invoice corrected-label-margin'
                                rules={formRules[invoiceData?.invoiceDirection]['publicName']}
                                control={data}
                                label={strings.forms.finance.invoice.invoiceItemsForm.productService}
                                disabled={isPageStateViewDetails()}
                                placeholder={strings.forms.finance.invoice.invoiceItemsForm.placeholder.productService}
                            />
                        </div>
                    }

                    <div className='header-row-item'>
                        <TextFieldControl
                            name='quantity'
                            className='mui-shifted-label-input-invoice corrected-label-margin'
                            setValue={setValue}
                            defaultValue={0}
                            rules={formRules[invoiceData?.invoiceDirection]['quantity']}
                            type={'number'}
                            control={data}
                            label={strings.forms.finance.invoice.invoiceItemsForm.quantity}
                            disabled={isPageStateViewDetails()}
                        />
                    </div>

                    {
                        invoiceData.invoiceDirection === InvoiceDirection.IN &&
                        <React.Fragment>
                            <div className='header-row-item'>
                                <TextFieldControl
                                    className='mui-shifted-label-input-invoice corrected-label-margin'
                                    name='price'
                                    defaultValue={0}
                                    rules={formRules.price}
                                    type={'number'}
                                    control={data}
                                    label={strings.forms.finance.invoice.invoiceItemsForm.price}
                                    disabled={isPageStateViewDetails()}
                                />
                            </div>
                            <div className='header-row-item'>
                                <SelectControl
                                    className='mui-shifted-label-input-invoice'
                                    value={values['tax']}
                                    setValue={setValue}
                                    control={control}
                                    rules={formRules[invoiceData?.invoiceDirection]['tax']}
                                    name='tax'
                                    label={strings.forms.finance.invoice.invoiceItemsForm.tax}
                                    options={siteData.taxes.map(tax => ({...tax, valueText: `${tax.value}%`}))}
                                    nameKey={'valueText'}
                                    valueKey={'id'}
                                    disabled={isPageStateViewDetails()}
                                />
                            </div>
                            <div className='header-row-item'>
                                <SelectControl
                                    className='mui-shifted-label-input-invoice'
                                    value={values['unit']}
                                    setValue={setValue}
                                    control={control}
                                    name='unit'
                                    rules={formRules[invoiceData?.invoiceDirection]['unit']}
                                    label={strings.forms.finance.invoice.invoiceItemsForm.unit}
                                    options={siteData.units}
                                    nameKey={'name'}
                                    valueKey={'id'}
                                    disabled={isPageStateViewDetails()}
                                />
                            </div>
                        </React.Fragment>

                    }

                    {
                        !isPageStateViewDetails() && <div className='header-row-item'>
                            <Button variant="contained" color="primary" onClick={ () => add() } disabled={ !form.formState.isValid } className = 'invoice-items-add-btn'>
                                <img src='/images/table-page/add-cross.svg'/>
                                { strings.forms.finance.invoice.invoiceItemsForm.add }
                            </Button>
                        </div>
                    }
                </div>

            </form>
        </FormProvider>
    );
}

export default InvoiceItemsForm;
