import React, {useContext, useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import QuillComment from "../../../../Controls/Inputs/QuillComment";
import {IconButton, InputLabel} from "@mui/material";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import CheckBoxControl from "../../../../Controls/Inputs/CheckBoxControl";
import { CheckListQuestionType } from "../../../../../Constants/CheckList/CheckListQuestionType";
import { getCheckListQuestionTypes} from "../../../../../Constants/CheckList/CheckListQuestionType";
const AddQuestionForm = ({
                             onSubmit,
                             data,
                             form,
                             errors,
                             values,
                             setValue,
                             formRules,
                             control,
                             showOptionsPaper,
                             onTempSubmit
                         }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const [editorData, setEditorData] = useState('');
    const [editOptionsDisabled, setEditOptionsDisabled] = useState(true)

    const checkSelectedValue = (selectedValue) => {
        if(selectedValue === CheckListQuestionType.SELECT ||
            selectedValue === CheckListQuestionType.RADIO){
            return false;
        }
        return true;
    }

    const onChangeSelect = (selectedValue) => {
        setEditOptionsDisabled(checkSelectedValue(selectedValue))
    }

    useEffect(() => {
        setEditOptionsDisabled(checkSelectedValue(values['type']))
    }, [])

    const onEditOptionsClick = () => {
        onTempSubmit()
    }

    return (
        <FormProvider {...form}>
            <form id='question-add-form'
                  onSubmit={(e) => e.preventDefault()}
                  className='form' action="#">
                <div className="question-field">
                    <TextFieldControl
                        name='question'
                        className='margin-shifted-label mui-shifted-label-input'
                        rules={formRules['question']}
                        control={control}
                        error={Boolean(errors.question)}
                        helperText={errors.question && errors.question.message}
                        label={strings.forms.companyDocument.companyDocumentTemplateQuestion.question}
                        disabled={isPageStateViewDetails()}
                    />
                </div>

                <div id="quill-question-comment">
                    <div className="description-label">
                        <InputLabel>
                            {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateQuestion.explanation}
                        </InputLabel>
                    </div>
                    <QuillComment
                        className="comment-editor"
                        name='explanation'
                        form={form}
                        value={editorData}
                        onChange={setEditorData}
                    ></QuillComment>
                </div>

                <div className='select-question-type'>
                    <SelectControl
                        value={values['type']}
                        setValue={setValue}
                        name='type'
                        rules={formRules['type']}
                        control={control}
                        className='margin-shifted-label mui-shifted-label-input'
                        error={Boolean(errors.type)}
                        helperText={errors.type && errors.type.message}
                        label={strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateQuestion.questionType}
                        options={getCheckListQuestionTypes()}
                        nameKey={'name'}
                        valueKey={'value'}
                        disabled={isPageStateViewDetails()}
                        valueChangeHandler={onChangeSelect}
                    />
                </div>

                <div className='question-required'>
                    <CheckBoxControl
                        name={'required'}
                        control={control}
                        label = {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateQuestion.required}
                        value={values['required']}
                        setValue={setValue}
                        disabled={isPageStateViewDetails()}
                    />
                    <IconButton className='edit-options-button'
                                onClick={onEditOptionsClick}
                                disabled={editOptionsDisabled}>
                        <div className='new-text'>
                            {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateQuestion.editQuestionOptions}
                        </div>
                    </IconButton>
                </div>

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default AddQuestionForm;