import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import StockChangeForm from "../../../Components/Forms/Pages/AssetManagement/StockChange/StockChangeForm";
import DrawerContext from "../../../Context/DrawerContext";
import {
  addStockChange,
  getInventories,
} from '../../../Services/AssetManagement/StockChangeService';
import LinearProgress from '@mui/material/LinearProgress';
import SnackbarContext from "../../../Context/SnackbarContext";

const formRules = {
    'type': {required: true}, 'price': {required: true}, 'tax': {required: true}, 'stockChangeDate': {required: true},
    'quantity': {required: true}, 'invetory': {required: true}
}

const AddStockChange = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: {
            stockChangeDate: null,
        }
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [inventories, setInventories] = useState(0);

    useEffect(() => {
        setDrawerTitle(strings.pages.assetManagement.stockChange.addStockChange.pageTitle);
    });

    useEffect(() => {
        fetchInventories(); 
    },[])

    const fetchInventories = (term = "") => {
        getInventories({term}).then((response) => {
          if (!response || !response.ok) {
            return;
          }
          setInventories(response.data.result);
        });
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        addStockChange(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <StockChangeForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                inventories = {inventories}
                errors={errors} data={data} form={form} control={control}
                onInventoryChangeFilter={fetchInventories}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default AddStockChange;