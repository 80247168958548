import {dateToEpoch, getCurrentDate, getCurrentDay, getCurrentMonth, getCurrentYear, getDaysOfMonth, getMonthString} from "../../Util/DateUtil";
import CalendarContent from "./CalendarContent";
import { getNonWorkingDays } from "./CalendarContent";
import strings from "../../localization";


const generateDates = (month, year) => {

    let firstOfMonth = new Date(year, month - 1, 1)
    let dayOfTheWeek = firstOfMonth.getDay() - 1;
    let result = [];

    let lastMonth, lastYear, nextMonth, nextYear;

    if(month > 1) {
        lastMonth = month - 1;
        lastYear = year;
    }
    else {
        lastMonth = 12;
        lastYear = year - 1;
    }

    if(month < 12) {
        nextMonth = month + 1;
        nextYear = year;
    }
    else {
        nextMonth = 1;
        nextYear = year + 1;
    }

    if (dayOfTheWeek === -1) {
        dayOfTheWeek = 6;
    }

    const currentMonthDays = getDaysOfMonth(year, month).length
    const lastMonthDays = getDaysOfMonth(lastYear, lastMonth).length

    for(let i = dayOfTheWeek - 1; i >= 0 ; i--) {
        result.push({
            disabled: true,
            day: lastMonthDays - i,
            month: lastMonth,
            year: lastYear,
            date: new Date(lastYear, lastMonth - 1, lastMonthDays - i)
        });
    }

    for(let i = 1; i <= currentMonthDays; i++) {
        result.push({
            disabled: false,
            day: i,
            month: month,
            year: year,
            date: new Date(year, month - 1, i)
        });
    }

    for(let i = 1; result.length % 7 !== 0; i++) {
        result.push({
            disabled: true,
            day: i,
            month: nextMonth,
            year: nextYear,
            date: new Date(nextYear, nextMonth - 1, i)
        });
    }

    return result;
}

const CalendarByMonth = ({
    date,
    data,
    onDayClick,
}) => { 
    const {month : selectedMonth, year: selectedYear} = date;
    return <div className='calendar-by-month-container'>
                <div className='calendar-container-header'>
                    <div className='cell'>
                        {strings.components.calendar.weekDaysShort.monday}
                    </div>
                    <div className='cell'>
                        {strings.components.calendar.weekDaysShort.tuesday}
                    </div>
                    <div className='cell'>
                        {strings.components.calendar.weekDaysShort.wednesday}
                    </div>
                    <div className='cell'>
                        {strings.components.calendar.weekDaysShort.thursday}
                    </div>
                    <div className='cell'>
                        {strings.components.calendar.weekDaysShort.friday}
                    </div>
                    <div className='cell'>
                        {strings.components.calendar.weekDaysShort.saturday}
                    </div>
                    <div className='cell'>
                        {strings.components.calendar.weekDaysShort.sunday}
                    </div>
                </div>
    <div className='days'>
    {
        generateDates(selectedMonth, selectedYear).map( (day, index) => {
            const isWeekend = day.date.getDay() === 6 || day.date.getDay() === 0;

            let className = 'cell'

            if(day.disabled) {
                className += ' disabled'
            }

            if(isWeekend) {
                className += ' weekend'
            }

            if(day.date.getDay() === 1){
                className += ' monday' 
            }
            if(day.date.getDay() === 0){
                className += ' sunday' 
            }

            const nonWorkingDays = getNonWorkingDays(data, day);
            if(nonWorkingDays && nonWorkingDays.length > 0){
                className += ' non-working-day'
            }

            if(dateToEpoch(day.date) === dateToEpoch(getCurrentDate())) {
                className += ' current'
            }

            return (<div key={'cell-' + index} className={className} onClick={_event => onDayClick(day, data)}>
                        <div className={"cell-header"}>
                            <div className="header-day">
                                {day.day}
                            </div>                           
                        </div>                   
                        <div className='content'>
                            <CalendarContent  isWeekend={isWeekend}
                                              calendarData={data} 
                                              day={day} 
                                              currentMonth={selectedMonth}/>
                        </div>     
                    </div>)
        })
    }
    </div>
</div>
}

export default CalendarByMonth;