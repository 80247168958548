import React, {useEffect} from "react";
import {getRoutes} from "./route";
import {BrowserRouter as Router} from "react-router-dom";
import AuthWrapper from "./Base/AuthWrapper";
import BaseLayout from "./Base/BaseLayout";
import {useDispatch} from "react-redux";
import {setAuth, setCompanyS3Settings, setIsCheckIn, setLocalisationSettings} from "./Slices/AuthSlice";
import {getUserFromLocalStorage, getUserPreferenceFromLocalStorage} from "./Base/OAuth";
import {getSiteData} from "./Services/Base/DefaultService";
import {updateSiteData} from "./Slices/SiteDataSlice";
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@emotion/react";
import {
    getCompanyLocalisationSettings,
    getCompanyS3Settings,
} from "./Services/Company/CompanyService";
import store from "./store";
import SnackbarWrapper from "./Base/SnackbarWrapper";
import { getIsCheckIn } from "./Services/HR/PresenceService";
import LoaderWrapper from "./Components/Layout/LoaderWrapper";

const theme = createTheme({typography: {
        useNextVariants: true,
    },"palette":{"common":{"black":"#40B6BE","white":"#fff"},"background":{"paper":"#fff","default":"#fafafa"},"primary":{"light":"rgba(106, 114, 133, 1)","main":"#333","dark":"rgba(40, 47, 64, 1)","contrastText":"#fff"},"secondary":{"light":"rgba(245, 84, 112, 1)","main":"#f15d24","dark":"rgba(245, 39, 76, 1)","contrastText":"#fff"},"error":{"light":"#e57373","main":"#f44336","dark":"#d32f2f","contrastText":"#fff"},"text":{"primary":"rgba(0, 0, 0, 0.87)","secondary":"rgba(0, 0, 0, 0.54)","disabled":"rgba(0, 0, 0, 0.38)","hint":"rgba(0, 0, 0, 0.38)"}}});


const App = () => {

    const auth = store.getState().auth;
    const dispatch = useDispatch();
    dispatch(setAuth(getUserFromLocalStorage()));

    const loadSiteData = () => {
        if(window.location.pathname === '/500') {
            return;
        }
        getSiteData().then(response => {
            if(!response || !response.ok) {
                dispatch(updateSiteData({}));
                return;
            }
            dispatch(updateSiteData(response.data));
        });
    }

    const getS3Settings = () => {
        getCompanyS3Settings().then(response => {
            if (response?.ok) {
                dispatch(setCompanyS3Settings(response.data.entity))
            }
        })
    }

    const getLocalisationSettings = () => {
        getCompanyLocalisationSettings().then(response => {
            if (response?.ok) {
                dispatch(setLocalisationSettings(response.data.entity))
            }
        })
    }

    const loadSettings = () => {
        if(window.location.pathname === '/500') {
            return;
        }

        if (!auth.companyS3Settings) {
            getS3Settings();
            getLocalisationSettings();
        }
    }

    const isCheckIn = () => {
        getIsCheckIn().then(response => {
            if(!response || !response.ok){
                return;
            }
            dispatch(setIsCheckIn(response.data.entity))
        })
    }

    isCheckIn();

    loadSiteData();
    loadSettings()
    return (
        <Router>
            <LoaderWrapper>
                <SnackbarWrapper>
                    <AuthWrapper>
                        <ThemeProvider theme={theme}>
                            <BaseLayout>
                                {getRoutes()}
                            </BaseLayout>
                        </ThemeProvider>
                    </AuthWrapper>
                </SnackbarWrapper>
            </LoaderWrapper>
        </Router>
    );
}

export default App;
