import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllProjects(data) {
    return await request('/api/projects/all', data);
}

export async function getAllProjectCategories(data) {
    return await request('/api/project-categories/all', data);
}

export async function getProjectList(data) {
    return await request('/api/projects', data);
}

export async function getProject(id) {
    return await request('/api/projects/' + id);
}

export async function deleteProject(id) {
    return await request('/api/projects/' + id, {}, HttpMethod.DELETE);
}

export async function editProject(data) {
    return await request('/api/projects/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addProject(data) {
    return await request('/api/projects', transformRequestData(data), HttpMethod.POST);
}

export async function getProjectStatistic(id, year) {
    return await request('/api/projects/statistic/' + year + '/' + id);
}

function transformRequestData(data) {

    return {
        ...data,
        projectCategoryId: data.projectCategory ? data.projectCategory.id : null,
        partnerId: data.partner ? data.partner.id : null
    }
}
