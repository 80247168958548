import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";
import PartnerLegalType from "../../Constants/CRM/PartnerLegalType";

export async function getPartner(id) {
    return await request('/api/partners/' + id);
}

export async function getPartners(data) {
    return await request('/api/partners', data);
}

export async function addPartner(data) {
    return await request('/api/partners', transformRequestData(data), HttpMethod.POST);
}

export async function editPartner(data) {
    return await request('/api/partners/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deletePartner(id) {
    return await request('/api/partners/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        name: data.name?.name ? data.name.name : data.name,
        website: data.website ? data.website : '',
        currencyId: data.currency ? data.currency.id : null,
        countryId: data.country ? data.country.id : null,
        partnerTypeId: data.partnerType ? data.partnerType.id : null,
        account: data.account?.account ? data.account.account : data.account,
        partnerLegalType: data.partnerLegalType?.id ? data.partnerLegalType.id : PartnerLegalType.PRIVATE
    }
}
