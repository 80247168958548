let fetchCount = 0;

export const fetchOptions = async(filterName, searchParams, getList, getOne, setOptions, term = '', filterFetch = false, subentity = '') => {
    const entityId = fetchCount ? null : parseInt(searchParams.get(filterName));
    const response = await getList({term});

    let entities = subentity ? response.data.result.map((data) => data[subentity]) : response.data.result;

    const checkEntity = Boolean(entities.find((entity) => entity.id === entityId));

    if(!checkEntity && entityId){
        const oneEntityResponse = await getOne(entityId);
        const oneEntity = subentity ? oneEntityResponse.data.entity[subentity] : oneEntityResponse.data.entity;
        oneEntityResponse.data?.entity && entities.push(oneEntity);
        fetchCount++;
    }

    if(filterFetch){
        setOptions((prev) => ({...prev, [filterName]: entities, fetched: prev.fetched + 1}));

        return;
    }

    return entities;
}

