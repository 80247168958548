import React, {Fragment, useContext, useEffect, useState} from 'react';
import CompanyDocumentCategoryTemplateItem from "./CompanyDocumentCategoryTemplateItem";
import {getCompanyDocumentCategories} from "../../../Services/CompanyDocumentManagement/CompanyDocumentCategoryService";
import {getCompanyDocumentTemplates} from "../../../Services/CompanyDocumentManagement/CompanyDocumentTemplateService";
import {Accordion, AccordionDetails, AccordionSummary, Drawer, IconButton} from "@mui/material";
import PageState from "../../../Constants/Base/PageState";
import DrawerContext from "../../../Context/DrawerContext";
import DrawerWrapper from "../../../Components/Layout/DrawerWrapper";
import EditCompanyDocumentCategory from "../CompanyDocumentCategory/EditCompanyDocumentCategory";
import { useNavigate } from "react-router-dom";
import strings from "../../../localization";
import LoaderContext from "../../../Context/LoaderContext";



const CompanyDocumentCategoryTemplates = ({category, permissionGroup, filteredCategories, setHandleShowModal, onDeleteTemplate, onEditTemplate, changeTree, editState, setEditState, selectedCategory, dataFetchCategory, changeCategory, filterName, createDocument, onCreate}) => {

    const [categories, setCategories] = useState([])
    const [expand, setExpand] = useState(null)
    const [templates, setTemplates] = useState(null)
    const [filteredTemplates, setFilteredTemplates]= useState(null)
    const [searchParams, setSearchParams] = useState(null)
    const [editCategory, setEditCategory] = useState(null)
    const [pageState, setPageState] = useState(PageState.View);
    const [drawerTitle, setDrawerTitle] = useState('');
    const value = {drawerTitle, setDrawerTitle}
    const navigate = useNavigate();
    const [loadingData, setLoadingData] = useState(true);
    const {setLoading} = useContext(LoaderContext);


    useEffect(() => {
        setFilteredTemplates([])
        fetchSubCategories();
        filterName ? searchTemplates(filterName) : setFilteredTemplates(templates)
    }, [selectedCategory, filteredCategories, changeTree, editState])

    useEffect(() => {
        filterName ? searchTemplates(filterName) : setFilteredTemplates(templates)
    }, [filterName, templates])

    const fetchSubCategories = () => {
        setLoading(true);
        getCompanyDocumentCategories(selectedCategory?.id).then(response => {
            setExpand(dataFetchCategory ? dataFetchCategory.id : response.data[0]?.id)
            setCategories(response.data)
            if(response.data.length > 0){
                const data = { "companyDocumentCategoryId": dataFetchCategory ? dataFetchCategory.id : response.data[0]?.id}
                getCompanyDocumentTemplates(data).then(response => {
                    setTemplates(response.data.result);
                    setFilteredTemplates(response.data.result);
                    setLoading(false);
                })
            }
            setLoading(false)
        })
    }

    const expandHandler = (category) => {
        setTemplates(null)
        changeCategory(category)
        setExpand(category.id)
        const data = { "companyDocumentCategoryId": category.id}
        getCompanyDocumentTemplates(data).then(response => {
            setTemplates(response.data.result);
            setExpand(category.id)
            setLoading(false)
        })
    }

    const isDrawerOpen = () => {
        return pageState !== PageState.View;
    }

    const editCategoryHandler = (cat) => {
        setPageState(PageState.Edit)
        setEditCategory(cat)
    }

    const editCreateTemplate = (selectedTemplate) => {
        navigate('/company-document-templates/template-create', {state: selectedTemplate})
    }

    const searchTemplates = (name) => {
        let filteredTemplates = null;
        if(templates !== null) {
            filteredTemplates = templates.filter(
                (template) => template.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
            );
        }
        setSearchParams(name);
        setFilteredTemplates(filteredTemplates);
    }

    const onEditTemplateHandler = (template) => {
        onEditTemplate(template);
    }

    const onCreateHandler = (template) => {
        onCreate(template);
    }

    const renderTemplatesCategories = (categories) => {
        if(!Array.isArray(categories)){ return ''}

            return categories.map((category) =>
                <div className="templates-container-cart">
                    <Accordion
                        onChange={(e, expanded) => {
                            expandHandler(category);
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<img src="/images/double-blue-arrow-down.svg"/>}
                        >
                            <div  className='template-category-title'>{category.name}</div>
                            { !createDocument &&
                            <div onClick={e => {e.stopPropagation()}} className='header-edit-category-container'>
                            <IconButton className='button' onClick={event => editCategoryHandler(category)}>
                                <img src="/images/edit-blue.svg" />
                            </IconButton>
                            </div>
                            }
                        </AccordionSummary>
                        <AccordionDetails>
                            <CompanyDocumentCategoryTemplateItem
                                permissionGroup={permissionGroup}
                                data={(category.id === expand) ? filteredTemplates : null}
                                onSelected={editCreateTemplate}
                                onDelete={onDeleteTemplate}
                                onEdit={onEditTemplateHandler}
                                createDocument={createDocument}
                                onCreate={onCreateHandler}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
    }

    const renderNoDataPage = () => {
        return(
            <div className='table-page-no-data'>
                {
                <div>
                    <img src='/images/table-page/no-data.svg'/>
                    <div className='no-data-title'>{strings.components.tablePage.noData.title}</div>
                    <div className='no-data-description'>{strings.components.tablePage.noData.description}</div>
                </div>
                }
            </div>
        )
    }

    return (
        <DrawerContext.Provider value={value}>
            {(categories === null || categories.length === 0) ? renderNoDataPage() : renderTemplatesCategories(categories)}
            <Drawer id='drawer' anchor='right' open={isDrawerOpen()} onClose={() => setPageState(PageState.View)}>
                <DrawerWrapper onBack={() => setPageState(PageState.View)} title={drawerTitle}
                               viewDetails={pageState === PageState.ViewDetails}>
                    {
                        pageState === PageState.Edit && <EditCompanyDocumentCategory category={editCategory} onSubmit={setEditState}></EditCompanyDocumentCategory>
                    }
                </DrawerWrapper>
            </Drawer>
        </DrawerContext.Provider>
    );

};


export default CompanyDocumentCategoryTemplates;