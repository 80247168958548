import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";

const DealTypeForm = ({
                         onSubmit,
                         onCancel,
                         data,
                         form,
                         errors,
                         formRules
                     }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='deal-type-form' className='form' action="#">

                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.crm.dealType.name}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default DealTypeForm;