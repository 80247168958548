import React, {useEffect, useState, useContext} from "react";
import {useDispatch} from "react-redux";
import strings from "../../../localization";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import {useLocation} from "react-router-dom";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {LinearProgress, Paper, Switch} from "@mui/material";
import {getUserRoles, updateUserRole} from "../../../Services/User/UserRoleService";
import {getAllRoles} from "../../../Services/User/RoleService";
import { getClasses } from "../../../Util/SwitchUtil";
import LoaderContext from "../../../Context/LoaderContext";

const UserRoleList = (props) => {

    const {state} = useLocation();
    const dispatch = useDispatch();

    const [roles, setRoles] = useState([])
    const [userRoles, setUserRoles] = useState([])
    const {loading, setLoading} = useContext(LoaderContext);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.administration.user.userList.userRoles,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.ADMINISTRATION},
                {label: strings.pages.administration.user.userList.pageTitle},
                {label: strings.pages.administration.user.userList.userRoles},
            ],        
        }));

    }, [])

    useEffect(() => {
        fetchUserRoles();
    }, [])

    useEffect(() => {
        fetchRoles();
    }, [userRoles])

    const getUserRoleAllowed = (userId, roleId) => {
        let result = false;
        for(let userRole of userRoles) {
            if(userRole.roleId === roleId &&
                userRole.userId === userId) {
                result = userRole.allowed;
                break;
            }
        }
        return result;
    }

    const fetchRoles = () => {
        getAllRoles().then(response => {
            if(!response || !response.ok) {
                return;
            }
            let roles = [];
            for(let role of response.data.entities) {
                roles.push({
                    ...role,
                    allowed: getUserRoleAllowed(state.user.id, role.id),
                });
            }
            setRoles(roles)
        });
    }

    const fetchUserRoles = () => {
        if(!state.user) {
            return;
        }
        setLoading(true);
        getUserRoles(state.user.id).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setUserRoles(response.data.entities)
        }).finally(() => setLoading(false));
    }

    const roleChange = (userRole) => {

        let allowed = false;

        for(let element of roles) {
            if(element.id == userRole.id) {
                element.allowed = !element.allowed;
                allowed = element.allowed;
            }
        }

        let data = {
            userId: state.user.id,
            roleId: userRole.id,
            allowed: allowed
        }

        updateUserRole(data).then(response =>{
            if(!response || !response.ok) {
                return;
            }
            fetchUserRoles();
        });
    }

    const renderElements = (elements) => {
        let result = [];
        for(let element of elements) {
            result.push(
                <div className='element-container' key={'element-' + result.length }>
                    <label>{ element.name }</label>
                    <div className='checkbox-container'>
                        <Switch
                            className={getClasses(false, element.allowed)}
                            name='tos'
                            checked={element.allowed}
                            onChange={() => roleChange(element)}
                            inputProps={{
                                'aria-label': 'secondary checkbox',
                            }}
                        />
                    </div>
                </div>
            )
        }
        return result;
    }



    return (
        <div className='page-form-container'>
            <Paper className='paper'>
                <div className='paper-checkbox'>
                    <div className='paper-checkbox-container'>
                        <div className='elements'>
                            { renderElements(roles) }
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    );
}

export default UserRoleList;
