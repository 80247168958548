import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllEmployeeRecords(employeeId) {
    return await request('/api/employee-records/' + employeeId);
}

export async function addEmployeeRecord(data) {
    return await request('/api/employee-records', data, HttpMethod.POST);
}

export async function editEmployeeRecord(data) {
    return await request('/api/employee-records/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteEmployeeRecord(id) {
    return await request('/api/employee-records/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        employeeId: data?.employee?.id
    }
}