import React from 'react';
import CompanyDocumentTemplateList from "../CompanyDocumentTemplate/CompanyDocumentTemplateList";
import {useNavigate} from "react-router-dom";


const CreateCompanyDocument = (props) => {
    const navigate = useNavigate();


    const createHandler = (template) => {
        navigate('/company-documents/create-document-forms', {state: {template: template, folder: props.selectedFolder}})
    }

    return(
        <div className="templates-container">
            <CompanyDocumentTemplateList createDocument={true} onCreate={createHandler}></CompanyDocumentTemplateList>
        </div>
    );

}

export default CreateCompanyDocument;