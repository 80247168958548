import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllOrganisationUserContracts(data) {
    return await request('/api/organisation-user-contracts/all', data);
}

export async function getOrganisationUserContractPartList(data) {
    return await request('/api/organisation-user-contract-parts', data);
}

export async function addOrganisationUserContractPart(data) {
    return await request('/api/organisation-user-contract-parts', transformRequestData(data), HttpMethod.POST);
}

export async function editOrganisationUserContractPart(data) {
    return await request('/api/organisation-user-contract-parts/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteOrganisationUserContractPart(id) {
    return await request('/api/organisation-user-contract-parts/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {

    return {
        ...data,
        organisationUserContractId: data.organisationUserContract ? data.organisationUserContract.id : null,
        year: data.year && data.year.value ? data.year.value : data.year,
        month: data.month && data.month.id ? data.month.id : data.month
    }
}