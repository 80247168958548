import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Paper } from "@mui/material";
import { getCurrentCompany } from "../../../Services/Company/CompanyService";
import { getAllUserCompanyAccess } from "../../../Services/User/UserCompanyAccessService";
import { addDecision } from "../../../Services/HR/BusinessTripService";
import { useParams } from "react-router-dom";
import SnackbarContext from "../../../Context/SnackbarContext";
import strings from "../../../localization";
import BusinessTripTravelWarrantForm from "../../../Components/Forms/Pages/HR/BusinessTrip/BusinessTripTravelWarrantForm";
import LinearProgress from "@mui/material/LinearProgress";

const formRules = {
  amountOfDailyWageInCountry: {
    required: {
      value: true,
    },
  },
  domesticCurrency: {
    required: {
      value: true,
    },
  },
  amountOfDailyWageInCountry: {
    required: {
      value: true,
    },
  },
  freeAccommodationAndFood: {
    required: {
      value: true,
    },
  },
  meansOfTransport: {
    required: {
      value: true,
    },
  },
  reasonToTravel: {
    required: {
      value: true,
    },
  },
  travelDestination: {
    required: {
      value: true,
    },
  },
  tripDuration: {
    required: {
      value: true,
    },
  },
  companyUser: {
    required: {
      value: true,
    },
  },
};

const AddTravelWarrant = ({ travelWarrant, onFinish }) => {
  const { showMessage } = useContext(SnackbarContext);
  const form = useForm({
    defaultValues: {
      reasonToTravel: travelWarrant.reasonToTravel || undefined,
      tripDuration: travelWarrant.tripDuration || undefined,
      travelDestination: travelWarrant.travelDestination || undefined,
      dayOfDeparture: travelWarrant.dayOfDeparture || undefined,
      meansOfTransport: travelWarrant.meansOfTransport || undefined,
      freeAccommodationAndFood:
        travelWarrant.freeAccommodationAndFood || undefined,
      amountOfDailyWageInCountry:
        travelWarrant.amountOfDailyWageInCountry || undefined,
      amountOfDailyWageAbroad:
        travelWarrant.amountOfDailyWageAbroad || undefined,
      advancePaymentForeignCurrency:
        travelWarrant.advancePaymentForeignCurrency || undefined,
      advancePaymentDomesticCurrency:
        travelWarrant.advancePaymentDomesticCurrency || undefined,
      domesticCurrency: { code: travelWarrant.domesticCurrency } || undefined,
      foreignCurrency: { code: travelWarrant.foreignCurrency } || undefined,
      companyUser: travelWarrant.company_user || undefined,
    },
  });
  const {
    data,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  const [users, setUsers] = useState([]);
  const [company, setCompany] = useState();
  const businessTripId = useParams("id");
  const [currencies, setCurrencies] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const ID = parseInt(businessTripId.id);

  const fetchCompany = () => {
    getCurrentCompany().then((response) => {
      if (!response || !response.ok) {
        return;
      }
      setCompany(response.data.id);
    });
  };

  const fetchUsers = () => {
    getAllUserCompanyAccess(company).then((response) => {
      if (!response || !response.ok) {
        return;
      }
      response.data.map((data) => setUsers(data.user));
    });
  };

  useEffect(() => {
    fetchCompany();
    fetchUsers();
  }, []);

  const onSubmit = (data) => {
    setShowLoader(true);
    const { id, ...dataWithoutId } = data;
    const decision = {
      ...dataWithoutId,
      businessTrip: ID,
      domesticCurrency: data.domesticCurrency.code,
      foreignCurrency: data.foreignCurrency.code,
      documentCount: travelWarrant.document_count,
    };

    addDecision(decision).then((response) => {
      setShowLoader(false);
      if (!response || !response.ok) {
        showMessage(strings.commonMessages.errorAdding, "error");
        onFinish();
        return;
      }

      showMessage(strings.commonMessages.addingSuccessful, "success");
      onFinish();
    });
  };

  const onCancel = () => {
    onFinish();
  };

  return (
    <div className="drawer-form">
      <Paper className="paper">
        {showLoader && <LinearProgress />}
        <BusinessTripTravelWarrantForm
          values={getValues()}
          setValue={setValue}
          users={users}
          formRules={formRules}
          data={data}
          errors={errors}
          form={form}
          control={control}
          onSubmit={handleSubmit(onSubmit)}
          onCancel={() => onCancel()}
          currencies={currencies}
          fetchUsers={fetchUsers}
        />
      </Paper>
    </div>
  );
};

export default AddTravelWarrant;
