import React, {useEffect, useState} from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import IconButton from "@mui/material/IconButton";
import strings from '../../localization';
import WorkingHoursDays from '../../Constants/User/WorkingHoursDays';
import { Button, Popper, ClickAwayListener } from '@mui/material';
import WeekDays from '../../Constants/User/WeekDays';
import {
    addWorkingHoursAdditionalRange, changeDefault, copyWorkingHourRange,
    deleteWorkingHoursRange,
    getWorkingHoursRange, getWorkingHoursRangeByWeekDay, saveDefaultWorkingRange,
} from "../../Services/User/WorkingHoursRangeService";
import {getWokringHours} from "../../Services/User/WorkingHoursService";

const WorkingHoursRange = ({ dataRange, setDataRange, activeWorkingHours, setActiveWorkingHours, isDisabled, setIsDisabled}) => {
    const [anchor, setAnchor] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [checked, setChecked] = useState([]);
    const [weekDay, setWeekDay] = useState([]);
    const [newTimeRange, setNewTimeRange] = useState(null);

    const changeIsChecked = day => {
        setDataRange(dataRange.map(tmpDay => tmpDay.id === day.id ? { ...day, isChecked: !day.isChecked } : tmpDay));
        setDataRange(dataRange.map(tmpDay => tmpDay.weekDay === day.weekDay  ? { ...tmpDay, isChecked: !day.isChecked } : tmpDay));
        dataRange.filter(x => x.workingHours === activeWorkingHours);

    }

    useEffect(() => {
        if(!isOpen){
            setChecked([]);
        }
    }, [isOpen]);

    const fetch = (id) => {
        getWokringHours().then(response => {
            if (!response || !response.ok) {
                return;
            }
            setDataRange(response.data.result);
            if(id){
                const defaultHours = response.data.result.find(item => item.id===id);
                setActiveWorkingHours(defaultHours);
                getWorkingHoursRange(defaultHours.id).then(response => {
                    if (!response || !response.ok) {
                        return;
                    }
                    setDataRange(response.data.result);
                })

            }else{
                const defaultHours = response.data.result.find(item => item.isDefault);
                setActiveWorkingHours(defaultHours);
                getWorkingHoursRange(defaultHours.id).then(response => {
                    if (!response || !response.ok) {
                        return;
                    }
                    setDataRange(response.data.result);
                })
            }
        })
    }

    function addHours(date,hours){
        date.setHours(date.getHours() + hours);
        return date;
    }

    const addTimeRange = (day) => {
            const dateFrom = new Date(day.timeTo);
            const dateTo = new Date(day.timeTo);
            let newTimeFrom = addHours(dateFrom,1);
            let newTimeTo = addHours(dateTo, 2);

            getWorkingHoursRangeByWeekDay(activeWorkingHours.id, {weekDay: day.weekDay}).then(response =>{
                const listLength = (response.data.result).length
                const lastElement = response.data.result[listLength-1];
                if(lastElement){
                    const to = new Date (lastElement.timeTo);
                    const from = new Date(lastElement.timeTo);
                    addHours(from, 1);
                    addHours(to, 2);
                        dataRange = {
                            dateFrom: from,
                            dateTo: to,
                            isChecked: true,
                            isDefault: false,
                            weekDay: day.weekDay,
                            id: activeWorkingHours.id
                        }
                    }else{
                        dataRange = {
                            dateFrom: newTimeFrom,
                            dateTo: newTimeTo,
                            isChecked: true,
                            isDefault: false,
                            weekDay: day.weekDay,
                            id: activeWorkingHours.id
                        }
                    }

                    addWorkingHoursAdditionalRange(dataRange).then(response=>{
                        setNewTimeRange(response.data.entity);
                    })
                     fetch(activeWorkingHours.id);
                })
    }

    const renderAdditionalTimeLabels = (day, position) => {
        let range = [...dataRange];

        if(day.isChecked){
            addTimeRange(day);
                range.splice(position + 1, 0, {
                    id: Math.random(), isChecked: true, timeFrom: newTimeRange?.timeFrom,
                    timeTo: newTimeRange?.timeTo, parentId: day.id, weekDay: day.weekDay
                });
            setDataRange(range);
        }else{
            changeIsChecked(day);
            saveDefaultWorkingRange(day.id,range);
            return;
        }
        setDataRange(range);

    }

    const openPopup = (event, day) => {
        setAnchor(event.currentTarget);
        setIsOpen(true);
        setWeekDay(day);
    }

    const handleChange = (event, day) => {
        let temp = [...checked];

        if (event.target.checked) {
            temp = [...checked, day];
        }else{
            temp.splice(temp.indexOf(day), 1);
        }
        setChecked(temp);
    }

        const applyChanges = (day) => {
            copyWorkingHourRange({
                workingHours: activeWorkingHours.id,
                checked: checked,
                toCopyRange: day.weekDay,
            }).then(response => {
                setIsOpen(false);
                setChecked([]);
                fetch(activeWorkingHours.id)
            })
        }

    const openPopupMenu = () => {
        let result = [];

        for (let [i, day] of WeekDays.entries()) {
            result.push(<div key={`popper-button-${i}`} className='popper-buttons'>
                <span>{day}</span>
                <Checkbox className='checkbox-styles' onChange={(e) => handleChange(e, i)} />
            </div>)
        }
        result.push(<Button className='apply-button' onClick={() => applyChanges(weekDay)}>{strings.profile.workingHours.apply}</Button>);

        return result;
    }

    const isOverLapping = (start, end, range, day) => {
        for(let date of range){
            let startRange = new Date(date.timeFrom).getTime();
            let endRange = new Date(date.timeTo).getTime();
            if ((endRange >= start && startRange <= end && date.id!==day.id) || end <= start) {
                return true;
            }
        }
    }

    const changeStartTime = (newValue, day) => {
        let endDate = new Date(day.timeTo);
        let startDate = new Date();
        startDate.setFullYear(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
        startDate.setHours(newValue?.$d.getHours());
        startDate.setMinutes(newValue?.$d.getMinutes());
        let end = endDate.getTime();
        let start = startDate.getTime();

        getWorkingHoursRangeByWeekDay(activeWorkingHours.id, {weekDay: day.weekDay}).then(response=>{
            if(isOverLapping(start, end, response.data.result, day) || !newValue){
                theyOverlapStart(startDate, day)
            }else{
                dontOverlapStart(startDate, day)
            }
        })
    }

    const theyOverlapStart = (newValue, day) => {
        setIsDisabled(true)
        newValue = new Date(newValue?.$d).getTime();
        let timeTo = new Date(day.timeTo).getTime();
        let range = dataRange.map(tmpDay => {
            if (tmpDay.id === day.id || newValue >= timeTo) {
                tmpDay.timeFrom = newValue;
                return tmpDay;
            }
            return tmpDay;
        })
        setDataRange(range);
        return;
    }

    const theyOverlapEnd = (newValue, day) => {
        let timeFrom = new Date(day.timeFrom).getTime();
        newValue = new Date(newValue?.$d).getTime();
        setIsDisabled(true)
        let range = dataRange.map(tmpDay => {
            if (tmpDay.id === day.id || newValue<=timeFrom) {
                tmpDay.timeTo = newValue;
                return tmpDay;
            }
            return tmpDay;
        })
        setDataRange(range);
        return;
    }

    const dontOverlapStart = (newValue, day) => {
        setIsDisabled(false);
        let range = dataRange.map(tmpDay => {
            if (tmpDay.id === day.id) {
                tmpDay.timeFrom = newValue;

                const timeTo = new Date(tmpDay.timeTo);
                const timeFrom = new Date(newValue);
                const offset = timeTo.toISOString().substring(23);
                const formattedTimeFrom = timeFrom.toISOString().replace("+00:00", offset);

                tmpDay.timeFrom = formattedTimeFrom;
            }
            return tmpDay;
        });
        setDataRange(range);
    };

    const dontOverlapEnd = (newValue, day) => {
        setIsDisabled(false);
        let range = dataRange.map(tmpDay => {
            if (tmpDay.id === day.id) {
                if (newValue && newValue.$d) {
                    const value = new Date(newValue.$d);

                    const timeFrom = new Date(day.timeFrom);
                    const timeTo = new Date(value);
                    const formattedTimeTo = timeTo.toISOString();
                    const offset = timeFrom.toISOString().substring(23);
                    const formattedTimeToWithOffset = formattedTimeTo.replace("+00:00", offset);

                    tmpDay.timeTo = formattedTimeToWithOffset;
                }
            }
            return tmpDay;
        });
        setDataRange(range);
    };




    const changeEndTime = (newValue, day) => {
        let startDate = new Date(day.timeFrom);
        let endDate = new Date();
        endDate.setFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        endDate.setHours(newValue?.$d.getHours());
        endDate.setMinutes(newValue?.$d.getMinutes());

        let start = startDate.getTime();
        let end = endDate.getTime();

        getWorkingHoursRangeByWeekDay(activeWorkingHours.id, {weekDay: day.weekDay}).then(response=>{
            if(isOverLapping(start, end, response.data.result, day) || !newValue){
                theyOverlapEnd(new Date('aa'), day)
            }else{
                dontOverlapEnd(newValue, day)
            }
        })
    }

    const deleteTimeLabels = (day) => {
        setDataRange(el => el.filter(tmpDay => tmpDay.isDefault === false));
        if(!day.isDefault){
            deleteWorkingHoursRange(day.id,dataRange).then(response => {
            if (!response || !response.ok) {
                return;
            }
                setDataRange(dataRange.map(tmpDay => tmpDay.id === day.id ? { ...day, isChecked: !day.isChecked } : tmpDay));
                fetch(activeWorkingHours.id);
                return;
            })
        }else{
            getWorkingHoursRangeByWeekDay(activeWorkingHours.id, {weekDay: day.weekDay}).then(response =>{
                if((response.data.result).length <= 1){
                    setDataRange(dataRange.map(tmpDay => tmpDay.id === day.id ? { ...day, isChecked: !day.isChecked } : tmpDay));
                }else{
                    changeDefault(day.id, dataRange).then(response => {
                        setDataRange(dataRange.map(tmpDay => tmpDay.id === day.id ? { ...day, isChecked: !day.isChecked } : tmpDay));
                        fetch(activeWorkingHours.id);
                    return;
                    })
                }
            })
        }
    }

    const renderForm = () => {
        return dataRange.filter((item) => (item.isChecked || item.isDefault))?.map((day, idx) =>
            <div className='working-hours-schedule' key={`week_day_${idx}`}>
                {day.isDefault &&
                <Checkbox className='checkbox-styles' checked={day.isChecked} onClick={() => changeIsChecked(day)} />
                }
                <p className='working-hours-day'>{day.isDefault ? WorkingHoursDays[day['weekDay']] : null}</p>
                {day.isChecked ?
                    <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                ampm={false}
                                disableOpenPicker={true}
                                value={day.timeFrom}
                                helperText={strings.forms.common.thisFieldIsRequired}
                                onChange={(newValue) => changeStartTime(newValue, day)}
                                renderInput={(params) => <TextField
                                    {...params}   />}
                            />
                        </LocalizationProvider>
                        <p>-</p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                ampm={false}
                                disableOpenPicker={true}
                                value={day.timeTo}
                                helperText={strings.forms.common.thisFieldIsRequired}
                                onChange={(newValue) => changeEndTime(newValue, day)}
                                renderInput={(params) => <TextField
                                    {...params} />}
                            />
                        </LocalizationProvider>

                        {day.isChecked ? <IconButton onClick={() => deleteTimeLabels(day)}>
                                <img src='/images/schedule-delete.svg' />
                            </IconButton>
                            : undefined}
                    </>  : (day.isDefault) ? <p className='paragraph-unavailable'>{strings.profile.workingHours.unavailable}</p> : undefined
                }
                {day.isDefault ? <div className='add-duplicate-schedule-icon'>
                    <IconButton onClick={() => renderAdditionalTimeLabels(day, idx)}>
                        <img src='/images/add-schedule.svg' />
                    </IconButton>
                    <IconButton className='duplicate-icon' onClick={(e) => openPopup(e, day)}>
                        <img src='/images/duplicate-schedule.svg' />
                    </IconButton>
                </div> : undefined}
            </div >
        );
    }

    return <div>
        {renderForm()}
        {isOpen && <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <Popper placement='bottom-start' open={isOpen} anchorEl={anchor} className='popper-duplicate'>
                {openPopupMenu()}
            </Popper>
        </ClickAwayListener>}
    </div>
}

export default WorkingHoursRange;