import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import {
    connectAttribute,
    deleteAttribute,
    disconnectAttribute,
    getAttributesList
} from "../../../Services/Product/AttributeService";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {formatColumnConstant, formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import AddAttribute from "./AddAttribute";
import EditAttribute from "./EditAttribute";
import {renderHtml} from "../../../Components/DataGrid/ValueCellRender";
import {getWooCommerceSettings} from "../../../Services/Product/ProductSettingsService/WooCommerceSettingsService";


const tableDescription = [

    {
        field: 'name', headerName: strings.pages.product.productService.productServiceList.name,
        width: 200,
    },
    {   field: 'description', headerName: strings.pages.product.attribute.description, width: 200,
        renderCell: (params) => renderHtml(params)
    },
];

const filters = [];

const AttributeList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [filterOptions, setFilterOptions] = useState({});
    const siteData = useSelector((state) => state.siteData);
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.ATTRIBUTES;
    const permissionGroup = AppPermissions.ATTRIBUTES.GROUP;
    const [enabled, setEnabled] = useState(true);
    const auth = useSelector((state) => state.auth);

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    const additionalMenuItems = [
        {handleClick: (id,item) => handleItems(id, item.name), text: strings.pages.product.attributeValue.pageTitle, src: "/images/table-page/attribute-value.svg"},
        enabled && {
            handleClick: (id, item) => handleConnectDisconnectAttribute(id, item),
            text: (item) => renderText(item),
            src: (item) => renderIcon(item),
        }
    ]

    function renderText (item){
        const data = tableData.data.find((dataItem) => dataItem.id === item.id);
        if (!data) {
            return null;
        }
        if (data && data.external_id !== undefined && data.external_id !== null) {
            return strings.pages.product.productService.productServiceList.disconnect;
        }
        return strings.pages.product.productService.productServiceList.connect;
    }

    function renderIcon (item){
        const data = tableData.data.find((dataItem) => dataItem.id === item.id);
        if (!data) {
            return null;
        }
        if (data.external_id === undefined) {
            return "/images/table-page/WooCommerce/woo-commerce-connected.svg";
        }
        return "/images/table-page/WooCommerce/woo-commerce-disconnected.svg";
    }

    function handleConnectDisconnectAttribute(id, item) {
        if(item.external_id){
            disconnectAttribute(id).then(response => {
                console.log(response)
                fetch();
            })
        }
        connectAttribute(id).then(response => {
            fetch();
        })
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.product.attribute.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.PRODUCT},
                {label: strings.pages.product.attribute.pageTitle},
            ],
        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        fetch();
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    useEffect(() => {
        if(!Object.keys(siteData.data).length){
            return;
        }

    },[siteData])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {

        getWooCommerceSettings(auth.company.id).then(response => {
            if(!response || !response.ok){
                return;
            }
            setEnabled(response.data.entity.enabled);
        });

        setTableData({
            ...tableData,
            loading: true
        });

        getAttributesList({...filter}).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    const handleItems = (template, name) => {
        navigate(`/attribute-values/${template}/${name}`)
    }

    return <TablePageContext.Provider value={value}>
        <TablePage tableDescription={columns} deleteItem={deleteAttribute}
                   tableData={tableData} onFinish={onFinish}
                   filter={filter} filters={filters} filterOptions={filterOptions}
                   referenceType={referenceType}
                   permissionGroup={permissionGroup}
                   additionalMenuItems={additionalMenuItems}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditAttribute data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                   addPage={<AddAttribute onCancel={() => onCancel()} onFinish={() => onFinish() }
                   />}
                   addButtonText={strings.components.tablePage.addButton.attribute}
        />
    </TablePageContext.Provider>;
}

export default AttributeList;
