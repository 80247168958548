import React from "react";
import {useEffect, useState, useContext} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import {deleteContractPart, getContractParts, generateInvoice} from "../../../Services/Finance/ContractPartService";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {formatColumnDate, formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import AddContractPart from "./AddContractPart";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import EditContractPart from "./EditContractPart";
import {useParams, useNavigate, useSearchParams} from "react-router-dom";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import { renderColumnNumber, returnColumnBoolean } from "../../../Components/DataGrid/ValueCellRender";
import Modules from "../../../Constants/Base/Modules";
import { ContractType } from "../../../Constants/Finance/ContractType";
import SnackbarContext from "../../../Context/SnackbarContext";
import {getCurrentYear} from "../../../Util/DateUtil";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [

    {
        field: 'paid',
        headerName: strings.pages.finance.contractPart.contractPartList.paid,
        renderCell: (params) => returnColumnBoolean(params),
        width: 60
    },
    {
        field: 'description', 
        headerName: strings.pages.finance.contractPart.contractPartList.description,
        width: 200,
    },
    {
        field: 'startDate',
        headerName: strings.pages.finance.contractPart.contractPartList.startDate,
        valueFormatter: (params) => formatColumnDate(params),
        width: 200
    },
    {
        field: 'endDate',
        headerName: strings.pages.finance.contractPart.contractPartList.endDate,
        valueFormatter: (params) => formatColumnDate(params),
        width: 200
    },
    {
        field: 'paymentDate',
        headerName: strings.pages.finance.contractPart.contractPartList.paymentDate,
        valueFormatter: (params) => formatColumnDate(params),
        width: 200
    },
    {
        field: 'actualPaymentDate',
        headerName: strings.pages.finance.contractPart.contractPartList.actualPaymentDate,
        valueFormatter: (params) => formatColumnDate(params),
        width: 200
    },
    {
        field: 'invoiceDate',
        headerName: strings.pages.finance.contractPart.contractPartList.invoiceDate,
        valueFormatter: (params) => formatColumnDate(params),
        width: 200
    },
    {
        field: 'amount', 
        headerName: strings.pages.finance.contractPart.contractPartList.amount,
        renderCell: (params) => renderColumnNumber(params),
        width: 200,
    },
    {
        field: 'actualAmount', 
        headerName: strings.pages.finance.contractPart.contractPartList.actualAmount,
        renderCell: (params) => renderColumnNumber(params),
        width: 200,
    },
    {
        field: 'contractPartStatus',
        headerName: strings.pages.finance.contractPart.contractPartList.contractPartStatus,
        width: 200,
        valueFormatter: (params) => formatColumnObject(params, 'name')
    },
    
];

const filters = [];


const ContractPartList = () => {
    const navigate = useNavigate();
    const {id: contractId} = useParams();
    const {name: contractName} = useParams();
    const { showMessage } = useContext(SnackbarContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const dispatch = useDispatch();
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const [contractType, setContractType] = useState(null);
    const referenceType = ReferenceType.CONTRACT_PART;
    const permissionGroup = AppPermissions.CONTRACT_PART.GROUP;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.finance.contractPart.contractPartList.pageTitle + '(' + contractName + ')',
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.FINANCE},
                {label: strings.pages.finance.contract.contractList.pageTitle},
                {label: strings.pages.finance.contractPart.contractPartList.pageTitle},
            ],        
        }));
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter]);

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getContractParts({
            contractId: contractId,
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }
            if(response.data.result.length){
                setContractType(response.data.result[0].contract.contractType);
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }



    const handleContractPartItems = (id, item) => {
        navigate(`/contract-parts/${contractId}/contract-part-items/${item.id}/${contractName}`, {state: {currency: item.contract.currency}});
    }

    const handleGenerateInvoice = (id, item) => {
        generateInvoice(item).then((response) => {

            if(!response){
                showMessage(strings.commonMessages.errorAdding, 'error');
                return;
            }
            if (!response.ok) {
                showMessage(response.response.data.message, 'error', 5000);
                return;
            }

            navigate({
                pathname: '/out/invoices',
                search: `year=${getCurrentYear()}`
            });

        });
    }

    const getAdditionalMenuItems = () => {
        if(contractType === ContractType.CUSTOMER_CONTRACT){
            return [
                {
                    handleClick: (id, item) => handleContractPartItems(id, item),
                    text: strings.pages.finance.contractPart.contractPartList.contractPartItems,
                    src: "/images/table-page/items.svg"
                },
                {
                    handleClick: (id, item) => handleGenerateInvoice(id, item),
                    text: strings.pages.finance.contractPart.contractPartList.generateInvoice,
                    src: "/images/table-page/contract-generate-parts.svg"
                },
            ];
        }

        return [];

    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteContractPart} 
                   tableDescription={columns} tableData={tableData}
                   filter={filter} filters={filters}
                   permissionGroup={permissionGroup}
                   referenceType={referenceType}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditContractPart data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                   addPage={<AddContractPart contractId={contractId}   onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addButtonText={strings.components.tablePage.addButton.contractPart}
                   additionalMenuItems = {getAdditionalMenuItems()}
                   />
    </TablePageContext.Provider>;
}

export default ContractPartList;
