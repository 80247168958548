import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function uploadBankReport(data) {
    return await request('/api/bank-reports/load-bank-report', data, HttpMethod.POST);
}

export async function getBankReportList(data) {
    return await request('/api/bank-reports', data);
}

export async function getAllBankReports() {
    return await request('/api/bank-reports/all', {});
}

export async function getBankReport(id) {
    return await request('/api/bank-reports/' + id);
}

export async function deleteBankReport(id) {
    return await request('/api/bank-reports/' + id, {}, HttpMethod.DELETE);
}

export async function editBankReport(data) {
    return await request('/api/bank-reports/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addBankReport(data) {
    return await request('/api/bank-reports', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        bankId: data.bank ? data.bank.id : null,
        bankAccountId: data.bankAccount ? data.bankAccount.id : null,
        currencyId: data.currency ? data.currency.id : null,
        businessYearId: data.businessYear ? data.businessYear.id : null,
    }

}

