import React, {useState} from "react";
import { FormProvider } from "react-hook-form";
import strings from "../../../../../localization";
import Button from '@mui/material/Button';
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import QuillEditor from "../../../../Controls/Inputs/QuillEditor";
import FileUploadComponent from "../../../../Controls/FileUploadComponent";

const AddApplicationForm = ({
                           onSubmit,
                           onCancel,
                           data,
                           form,
                           errors,
                           values,
                           setValue,
                           formRules, jobApplication,
                       }) => {

    const [editorData, setEditorData] = useState('');
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [files, setFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const uploadImage = async (files) => {

        const uploaded = [...uploadedFiles];

        files.some((file)=> {
            uploaded.push(file);
        })
        setUploadedFiles(uploaded);
        setValue('files',uploaded);

        setDisabledSubmit(false);
    }


    return (
        <FormProvider {...form}>
            <form id='application-form' className='form' action="#">
                <TextFieldControl
                    name='firstName'
                    rules={formRules['firstName']}
                    control={data}
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.firstName}
                />
                <TextFieldControl
                    name='lastName'
                    rules={formRules['lastName']}
                    control={data}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.lastName}
                />
                <TextFieldControl
                    name='email'
                    rules={formRules['email']}
                    control={data}
                    error={Boolean(errors.email)}
                    helperText={errors.email && strings.forms.common.emailFormatError}
                    label={strings.forms.hr.candidate.email}
                />
                <TextFieldControl
                    name='location'
                    rules={formRules['location']}
                    control={data}
                    error={Boolean(errors.location)}
                    helperText={errors.location && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.location}
                />
                <TextFieldControl
                    name='phone'
                    rules={formRules['phone']}
                    control={data}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.phone}
                />
                <QuillEditor
                    name='description'
                    className='large-editor'
                    form={form}
                    value={editorData}
                    onChange={setEditorData}
                    placeholder={'Description'}
                />
                {!jobApplication &&
                    <TextFieldControl
                        name='currentPosition'
                        rules={formRules['currentPosition']}
                        control={data}
                        error={Boolean(errors.currentPosition)}
                        helperText={errors.currentPosition && strings.forms.common.thisFieldIsRequired}
                        label={strings.forms.hr.candidate.currentPosition}
                    /> &&
                    <TextFieldControl
                    name='currentCompany'
                    rules={formRules['currentCompany']}
                    control={data}
                    error={Boolean(errors.currentCompany)}
                    helperText={errors.currentCompany && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.currentCompany}
                    /> &&
                    <TextFieldControl
                    name='currentSalary'
                    rules={formRules['currentSalary']}
                    control={data}
                    type='number'
                    error={Boolean(errors.currentSalary)}
                    helperText={errors.currentSalary && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.currentSalary}
                    /> &&
                    <TextFieldControl
                    name='expectedSalary'
                    rules={formRules['expectedSalary']}
                    control={data}
                    type='number'
                    error={Boolean(errors.expectedSalary)}
                    helperText={errors.expectedSalary && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.expectedSalary}
                    />
                }
                <TextFieldControl
                    name='education'
                    rules={formRules['education']}
                    control={data}
                    error={Boolean(errors.education)}
                    helperText={errors.education && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.candidate.education}
                />
                <DateTimeControl
                    data={data}
                    label={strings.forms.hr.candidate.birthday}
                    name={'birthday'}
                    value={values['birthday']}
                    setValue={setValue}
                />
                <p className='font-description'>{strings.forms.hr.candidate.files}</p>
                <FileUploadComponent hideUploadButton={false} onDrop={uploadImage}  files = {uploadedFiles} setFiles = {setFiles} />
                <div className='submit-container'>
                    <Button variant="contained" color="primary" disabled={disabledSubmit} onClick={onSubmit}>
                        {strings.forms.common.send}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.forms.common.cancel}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
}

export default AddApplicationForm;