import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {LinearProgress, Paper} from "@mui/material";
import strings from "../../localization";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import {useNavigate, Link} from "react-router-dom";
import {acceptInviteUserCompanyAccess, userCompanyAccessGetByToken} from "../../Services/User/UserCompanyAccessService";
import {useQuery} from "../../Util/RouterUtil";
import AcceptCompanyInviteForm from "../../Components/Forms/Pages/User/AcceptCompanyInviteForm";
import {logout} from "../../Base/OAuth";

const formRules = {
    'password': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
}

const AcceptInvite = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = useQuery();
    const form = useForm();
    const {data, handleSubmit, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false)

    const [userCompanyAccess, setUserCompanyAccess] = useState({});

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.FULL_NO_HEADER));
        logout();
        fetch();
    }, [])

    fetch = () => {

        const token = query.get('token');

        if (!token || token.length === 0) {
            navigate('/');
        }

        userCompanyAccessGetByToken(token).then(response => {

            if(!response || !response.ok) {
                return;
            }

            setUserCompanyAccess(response.data.entity)
        });
    }

    const onSubmit = (data) => {
        setShowLoader(true);

        const token = query.get('token');

        acceptInviteUserCompanyAccess({
            ...data,
            token: token
        }).then(response => {
            setShowLoader(false);

            if(!response.ok) {
                return;
            }

            navigate('/');
        });
    }

    return <div id='accept-invite'>
        <div className="color-overlay"></div>

            <Paper>
                <div className="loader">
                    {showLoader && <div className="loader-background">
                        {<LinearProgress/>}
                    </div>}
                </div>           

                <h1>{strings.pages.user.acceptInvite.pageTitle}</h1>
                <AcceptCompanyInviteForm
                    user={userCompanyAccess?.user}
                    formRules={formRules} errors={errors} data={data} form={form} onSubmit={handleSubmit(onSubmit)}
                />
                <p>{strings.pages.user.acceptInvite.haveAnAccount}<Link to = '/'> {strings.pages.user.acceptInvite.logIn}</Link> </p>
            </Paper>
    </div>
};
export default AcceptInvite;
