import {isNullOrUndef} from "chart.js/helpers";
import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";
import publicLink from "../../Pages/HR/Settings/PublicLink";

export async function getAllJobs(data) {
    return await request('/api/job/all', data);
}

export async function getJobList(data) {
    return await request('/api/job', data);
}

export async function addJob(data) {
    return await request('/api/job', transformRequestData(data), HttpMethod.POST);
}

export async function getJobData(data) {
    return await request('/api/job-data',data)
}

export async function editJob(data) {
    return await request('/api/job/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteJob(id) {
    return await request('/api/job/' + id, {}, HttpMethod.DELETE);
}

export async function getJob(id) {
    return await request('/api/job/' + id);
}

export async function getJobByPublicLink(jobPublicLink){
    return await  request('/api/job/application/' + jobPublicLink);
}

function transformRequestData(data) {
    return {
        ...data,
        isRemote: isNullOrUndef(data.isRemote) ? false : data.isRemote,
        paymentFrequency: data.paymentFrequency ? data.paymentFrequency.id : null,
        currencyId: data.currency ? data.currency.id : null,
        jobContractTypeId: data.jobContractType ? data.jobContractType.id : null,
        jobStatusId: data.jobStatus ? data.jobStatus.id : null,
        partnerId: data.partner ? data.partner.id : null,
        userPositionId: data.userPosition ? data.userPosition.id : null,
        userId: data.user ? data.user.id : null,
        pipelineId: data.pipeline ? data.pipeline.id : null
    }

}
