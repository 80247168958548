import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getLeaveList(data) {
    return await request('/api/leave', data);
}

export async function getLeave(id) {
    return await request('/api/leave/' + id);
}

export async function deleteLeave(id) {
    return await request('/api/leave/' + id, {}, HttpMethod.DELETE);
}

export async function editLeave(data) {
    return await request('/api/leave/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addLeave(data) {
    return await request('/api/leave', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        leaveTypeId: data.leaveType ? data.leaveType.id : null,
        employeeId: data.employee ? data.employee.id : null,
        subtractDays: data.subtractDays ? data.subtractDays : null
    }
}

