import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getContractPartStatuses(data) {
    return await request('/api/contract-part-statuses', data);
}

export async function addContractPartStatus(data) {
    return await request('/api/contract-part-statuses', transformRequestData(data), HttpMethod.POST);
}

export async function editContractPartStatus(data) {
    return await request('/api/contract-part-statuses/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteContractPartStatus(id) {
    return await request('/api/contract-part-statuses/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
    }
}
