import {
    addDocument,
    deleteDocument,
    editDocument,
    getDocument,
    getDocuments
} from "../../../Services/DocumentManagement/DocumentService";
import React, {useEffect, useState, useCallback, useContext} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch, useSelector} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import {formatColumnDate, formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import {
    isDisabledDocument,
    renderColumnColorChip,
    renderColumnControls,
    renderColumnDocumentType
} from "../../../Components/DataGrid/ValueCellRender";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import AddDocument from "./AddDocument";
import { getDocumentStatuses, getDocumentStatus } from "../../../Services/DocumentManagement/DocumentStatusService";
import {useParams, useNavigate, useSearchParams} from "react-router-dom";
import SnackbarContext from "../../../Context/SnackbarContext";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import SelectControl from "../../../Components/Controls/Inputs/SelectControl";
import {getDocumentCategories, getDocumentCategory} from "../../../Services/DocumentManagement/DocumentCategoryService";
import EditDocument from "./EditDocument";
import DocumentType from "../../../Constants/DocumentManagement/DocumentType";
import PdfPreview from "../../../Components/Pdf/PdfPreview";
import Modules from "../../../Constants/Base/Modules";
import {fetchOptions} from '../../../Services/Base/FilterOptionsService';
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import { useDropzone } from "react-dropzone";
import { getSizeDescription } from "../../../Util/FileUtil";

const tableDescription = [
    {
        field: '', headerName: strings.pages.document.document.documentList.controls,
        renderCell: (params) => renderColumnControls(params),
        width: 100
    },
    {
        field: 'documentType', headerName: strings.pages.document.document.documentList.docType,
        renderCell: (params) => renderColumnDocumentType(params),
        width: 150
    },
    {
        field: 'name', headerName: strings.pages.document.document.documentList.name,
        width: 200
    },
    {
        field: 'dateCreated', headerName: strings.pages.document.document.documentList.dateCreated,
        valueFormatter: (params) => formatColumnDate(params),
        width: 150
    },
    {
        field: 'documentCategory', headerName: strings.pages.document.document.documentList.documentCategory,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 150
    },
]

const DocumentList = (props) => {
    const dispatch = useDispatch();
    const { showMessage } = useContext(SnackbarContext);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const {companyS3Settings} = useSelector(state => state.auth)
    const [tablePageOptions, setTablePageOptions] = useState({...TablePageOptionDefaults, disabledActionAdd: true});
    const [status, setStatus] = useState([]);
    const navigate = useNavigate();
    const {id: referenceId, referenceType} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const permissionGroup = AppPermissions.DOCUMENT.GROUP;
    const [filterOptions, setFilterOptions] = useState({});
    const [activePdf, setActivePdf] = useState(null);

    const onDrop = useCallback(acceptedFiles => {
		uploadFile(acceptedFiles);
	}, []);

	const {getRootProps, isDragActive} = useDropzone({onDrop})

    useEffect(() => {
        setTablePageOptions({...TablePageOptionDefaults, disabledActionAdd: !companyS3Settings})
    }, [companyS3Settings])

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        updatedFilter, setUpdatedFilter
    }

    const additionalMenuItems = [
        {handleClick: (id, item) => handleComments(id, item), text: strings.pages.document.document.documentList.comments, src: "/images/table-page/comment.svg"},
        {handleClick: (id, item) => handlePreview(id, item), text: strings.components.actionCell.view,  src: "/images/table-page/view.svg",
            hide: (item) => item.documentType !== DocumentType.PDF || isDisabledDocument(item)},
    ]

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.document.document.documentList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: strings.pages.document.document.documentList.pageTitle},
            ],        
        }));

        fetchFilters();
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter, props?.id, props?.referenceType]);

    useEffect(() => {

        if (pageState === PageState.View) {
            setActivePdf(null);
        }
    }, [pageState])

    const fetchDocumentCategories = async (term, filterFetch) => {
        return fetchOptions('documentCategory', searchParams, getDocumentCategories, getDocumentCategory, setFilterOptions, term, filterFetch);
    }

    const fetchDocumentStatuses = async (term, filterFetch) => {
        return fetchOptions('documentStatus', searchParams, getDocumentStatuses, getDocumentStatus, setFilterOptions, term, filterFetch);
    }

    const uploadFile = async (dragFiles) => {
        dragFiles.map((uploadedFile) => {
            const data = {file: uploadedFile, name: uploadedFile?.name, referenceId: getReferenceId() , referenceType: getReferenceType(), documentSize: getSizeDescription(uploadedFile.size).replace(/[()]/g,'')}
            addDocument(data).then(response => {
                if (!response || !response.ok) {
                    showMessage(strings.commonMessages.errorAdding, 'error');
                    if (strings.error.DocumentHandler[response.response.data.message]) {
                        showMessage(strings.error.DocumentHandler[response.response.data.message], 'error');
                    }
                    return;
                }
                fetch();
                showMessage(strings.commonMessages.addingSuccessful, 'success');
            });
        });
    }

    const fetchFilters = async() => {
        const documentCategories = await fetchDocumentCategories();
        const documentStatuses = await fetchDocumentStatuses();

        setFilterOptions({
            documentCategory: documentCategories,
            documentStatus: documentStatuses,
            fetched: 1,
        });
    }

    const filters = [
        {
            name: 'documentCategory',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'documentCategory',
            label: strings.pages.document.document.documentList.documentCategory,
            onChange: (term) => fetchDocumentCategories(term, true),
        },
        {
            name: 'documentStatus',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'documentStatus',
            label: strings.pages.document.document.documentList.documentStatus,
            onChange: (term) => fetchDocumentStatuses(term, true),
        },
    ]

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const getReferenceType = () => {
        if(referenceType){
            return referenceType;
        }

        return props.referenceType;
    }

    const getReferenceId = () => {
        if(referenceId){
            return referenceId;
        }

        return props.id;
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getDocuments({
            referenceType: getReferenceType(),
            referenceId: getReferenceId(),
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    };

    const tableDescriptionExtended = [
        ...tableDescription,
        {
            field: 'documentStatus', headerName: strings.pages.document.document.documentList.documentStatus,
            width: 200,
            renderCell: (params) => renderColumnColorChip(params,'name')
        },
        {
            field: 'commentCount', headerName: strings.pages.document.document.documentList.comments,
            width: 100
        }
    ]

    const handleComments = (id, document) => {
        navigate('/document-comments', {state: {document}})
    }

    const handlePreview = (id, document) => {
        setPageState(PageState.Custom);
        getDocument(document).then((response) => setActivePdf(response))
    }

    return <TablePageContext.Provider value={value}>
        <div id={'drag-and-drop-area'}
            {...getRootProps({
                onClick: event => event.stopPropagation()
            })} >

            { isDragActive && 
                <div>
                    <div className={'board-issue-drag-and-drop background'} /> 
                    <div className={'board-issue-drag-and-drop text'}>{strings.forms.board.boardIssue.dropFiles}</div>
                </div>
            }        
        </div>                      
        <TablePage onFinish={() => onFinish()} deleteItem={deleteDocument} tableDescription={tableDescriptionExtended}
                tableData={tableData} filter={filter} filters={filters} filterOptions={filterOptions}
                permissionGroup={permissionGroup} hideDocuments={true}
                additionalMenuItems={additionalMenuItems} hideView={true}
                referenceType={referenceType}
                addPage={<AddDocument referenceType={getReferenceType()} referenceId={getReferenceId()}
                                        onFinish={() => onFinish()} onCancel={() => onCancel()}/>}
                customPage={<PdfPreview pdf={activePdf}/>}
                tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                editPage={<EditDocument data={selectedItems}
                                        onFinish={() => onFinish()} onCancel={() => onCancel()} />}
                addButtonText={strings.components.tablePage.addButton.document} 
                hideBreadcrumbs 
        />

    </TablePageContext.Provider>;
}

export default DocumentList;
