import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllCandidateRecords(candidateId) {
  return await request("/api/candidate-records/" + candidateId);
}

export async function addCandidateRecord(data) {
  return await request("/api/candidate-records", data, HttpMethod.POST);
}

export async function editCandidateRecord(data) {
  return await request(
    "/api/candidate-records/" + data.id,
    transformRequestData(data),
    HttpMethod.PUT
  );
}

export async function deleteCandidateRecord(id) {
  return await request("/api/candidate-records/" + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
  return {
    ...data,
    candidateId: data?.candidate?.id,
  };
}
