import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import ContractPartItemForm from "../../../Components/Forms/Pages/Finance/ContractPartItem/ContractPartItemForm";
import DrawerContext from "../../../Context/DrawerContext";
import {editContractPartItem} from "../../../Services/Finance/ContractPartItemService";
import LinearProgress from '@mui/material/LinearProgress';
import SnackbarContext from "../../../Context/SnackbarContext";
import {getProductServiceList} from "../../../Services/Product/ProductServiceService";
import { useLocation } from 'react-router-dom';

const formRules = {
    'quantity': {required: true},
    'productService': {required: true},
}

const AddContractPartItem = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const { state } = useLocation();
    const [productServices, setProductServices] = useState([]);
    const form = useForm({
        defaultValues: {
            id: props.data[0].id,
            quantity: props.data[0].quantity,
            productService: props.data[0].productService,
        },
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    
    useEffect(() => {
        setDrawerTitle(strings.pages.finance.contractPartItems.addContractPartItem.pageTitle);
        fetchProductAndServices();
    },[]);

    const fetchProductAndServices = (term = '') => {
        getProductServiceList({
            term: term,
            currency: state?.currency.id
        }).then(response => {
            setProductServices(response.data?.result);
        });
    }

    const onSubmit = (data) => {
        setShowLoader(true);

        editContractPartItem(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            
            <ContractPartItemForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                control = {control}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}
                productServices = {productServices}
                onChangeProductServiceFilter = {fetchProductAndServices}
            />
        </Paper>
    </div>
}

export default AddContractPartItem;
