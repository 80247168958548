import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import JobForm from "../../../Components/Forms/Pages/HR/Job/JobForm";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import { addJob, getJobData } from "../../../Services/HR/JobService";
import {Paper} from "@mui/material";
import { useSelector } from "react-redux";
import { getHRSettings } from "../../../Services/HR/HRSettingsService";
import SnackbarContext from "../../../Context/SnackbarContext";
import PipelineType from '../../../Constants/Company/PipelineType';

const formRules = {
    'name': {required: true},
    'pipeline': {required: true}
}

const AddJob = (props) => {
    const auth = useSelector((state) => state.auth)
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: {
            isRemote: true,
        }
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [internalHrOnly,setInternalHrOnly] = useState(false);
    const [pipelines, setPipelines] = useState([]);
    const [partners, setPartners] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [userPositions, setUserPositions] = useState([]);
    const [jobStatuses, setJobStatuses] = useState([]);
    const [jobContractTypes, setJobContractTypes] = useState([]);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.job.addJob.pageTitle);
    });

    useEffect(() => {
        fetch();
    }, [])

    const fetch = () => {
        getJobData({pipelineType:PipelineType.HR}).then(response => {
            if (!response || !response.ok){
                return;
            }
            setPipelines(response.data.pipelines);
            setPartners(response.data.partners);
            setCurrencies(response.data.currencies);
            setUserPositions(response.data.userPositions);
            setJobStatuses(response.data.jobStatuses);
            setJobContractTypes(response.data.jobContractTypes);
        })

        getHRSettings(auth.company.id).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setInternalHrOnly(response.data.entity.internalHrOnly);
        })
    }

    const onSubmit = (data) => {
        setShowLoader(true);

        addJob(data).then(response => {
            setShowLoader(false);


            if (!response || !response.ok){
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress />
            }
            <JobForm
                control={control} formRules={formRules}
                values={getValues()} setValue={setValue}
                data={data} errors={errors} form={form}
                jobStatuses={jobStatuses} jobContractTypes={jobContractTypes}
                userPositions={userPositions} pipelines={pipelines}
                currencies={currencies} partners={partners}
                internalHrOnly={internalHrOnly}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}
            />
        </Paper>
    </div>
}

export default AddJob;