import {useForm} from "react-hook-form";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import LinearProgress from '@mui/material/LinearProgress';
import {getAllCompanyDocumentCategories} from "../../../Services/CompanyDocumentManagement/CompanyDocumentCategoryService";
import {getAllCompanyDocumentTemplates} from "../../../Services/CompanyDocumentManagement/CompanyDocumentTemplateService";
import {editCompanyDocument} from "../../../Services/CompanyDocumentManagement/CompanyDocumentService";
import CompanyDocumentForm from "../../../Components/Forms/Pages/CompanyDocument/CompanyDocument/CompanyDocumentForm";
import SnackbarContext from "../../../Context/SnackbarContext";

const formRules = {
    'name': {required: true},
    'companyDocumentCategory': {required: true},
    'companyDocumentTemplate': {required: true},
}

const EditCompanyDocument = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [companyDocumentCategories, setCompanyDocumentCategories] = useState([]);
    const [companyDocumentTemplates, setCompanyDocumentTemplates] = useState([]);

    useEffect(() => {
        setDrawerTitle(strings.pages.companyDocument.companyDocument.editCompanyDocument.pageTitle);
    });

    useEffect(() => {
        fetch()
    }, [])

    const fetch = useCallback(() => {
        getAllCompanyDocumentCategories({}).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setCompanyDocumentCategories(response.data)
        })
        getAllCompanyDocumentTemplates().then(response => {
            if (!response || !response.ok) {
                return;
            }
            setCompanyDocumentTemplates(response.data)
        })
    }, [])

    const onSubmit = (data) => {

        setShowLoader(true);

        editCompanyDocument(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <CompanyDocumentForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                companyDocumentCategories={companyDocumentCategories}
                companyDocumentTemplates={companyDocumentTemplates}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditCompanyDocument;