import React from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import {getContractPartItems, deleteContractPartItem} from "../../../Services/Finance/ContractPartItemService";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import AddContractPartItem from "./AddContractPartItem";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import EditContractPartItem from "./EditContractPartItem";
import {useParams, useNavigate, useSearchParams} from "react-router-dom";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Modules from "../../../Constants/Base/Modules";
import {getCurrencyNumber, getPercentNumber} from "../../../Util/RenderDataUtil";
import {getAmountWithDiscount, getAmountWithVAT} from "../../../Util/InvoiceUtil";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";


const tableDescription = [
    {
        field: 'name',
        headerName: strings.pages.finance.contractPartItems.contractPartItemsList.name,
        valueGetter: (params) => params.row.productService.name,
        width: 200
    },
    {
        field: 'unit', 
        headerName: strings.pages.finance.contractPartItems.contractPartItemsList.unit,
        renderCell: (params) => params.row.productService.unit.symbol,
        width: 50,
    },
    {
        field: 'quantity',
        headerName: strings.pages.finance.contractPartItems.contractPartItemsList.quantity,
        width: 150,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'price',
        headerName: strings.pages.finance.contractPartItems.contractPartItemsList.price,
        renderCell: (params) => getCurrencyNumber(params.row.productService.price, params.row.productService.currency),
        width: 200
    },
    {
        field: 'amount',
        headerName: strings.pages.finance.contractPartItems.contractPartItemsList.amount,
        renderCell: (params) => getCurrencyNumber(params.row.productService.price * params.row.quantity, params.row.productService.currency),
        width: 200
    },
    {
        field: 'discount',
        headerName: strings.pages.finance.contractPartItems.contractPartItemsList.discount,
        renderCell: (params) => getPercentNumber(params.row.productService.discount),
        width: 100
    },
    {
        field: 'amountWithDiscount',
        headerName: strings.pages.finance.contractPartItems.contractPartItemsList.amountWithDiscount,
        renderCell: (params) => getCurrencyNumber(getAmountWithDiscount({...params.row.productService, quantity: params.row.quantity}), params.row.productService.currency),
        width: 200
    },
    {
        field: 'vat', 
        headerName: strings.pages.finance.contractPartItems.contractPartItemsList.vat,
        renderCell: (params) => getPercentNumber(params.row.productService.tax),
        width: 150,
    },
    {
        field: 'amountWithVAT', 
        headerName: strings.pages.finance.contractPartItems.contractPartItemsList.amountWithVAT,
        renderCell: (params) => getCurrencyNumber(getAmountWithVAT({...params.row.productService, quantity: params.row.quantity}), params.row.productService.currency),
        width: 200,
    },

    
];

const filters = [];


const ContractPartItemsList = () => {
    const navigate = useNavigate();
    const {contractPartId} = useParams();
    const {contractName: contractName} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const dispatch = useDispatch();
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.CONTRACT_PART_ITEM;
    const permissionGroup = AppPermissions.CONTRACT_PART.GROUP;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.finance.contractPartItems.contractPartItemsList.pageTitle + '(' + contractName + ')',
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.FINANCE},
                {label: strings.pages.finance.contract.contractList.pageTitle},
                {label: strings.pages.finance.contractPart.contractPartList.pageTitle},
                {label: strings.pages.finance.contractPartItems.contractPartItemsList.pageTitle}
            ],        
        }));
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter]);

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getContractPartItems({
            contractPartId: contractPartId,
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }


    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteContractPartItem} 
                   tableDescription={columns} tableData={tableData}
                   filter={filter} filters={filters}
                   permissionGroup={permissionGroup}
                   referenceType={referenceType}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditContractPartItem data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                   addPage={<AddContractPartItem contractPartId={contractPartId}   onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addButtonText={strings.components.tablePage.addButton.contractPartItem}
                   hideDocuments
                   />
    </TablePageContext.Provider>;
}

export default ContractPartItemsList;
