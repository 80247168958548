import strings from '../localization';
import { format, parse, formatDistance } from 'date-fns'
import store from "../store";
import DateFormats from "../Constants/Company/DateFormats";


export const getDateDistance = (date) => {
    return formatDistance(new Date(date), new Date(), {addSuffix: true});
}

export const getYears = (plusYears = 0) => {

    let result = [];
    let currentYear = (new Date()).getFullYear();

    currentYear += plusYears;

    for(let i = currentYear; i >= currentYear - 99; i--) {

        result.push({
            id: i,
            value: i
        });
    }

    return result;
}

export const getDaysOfMonth = (year, month) => {

    let date = new Date(year, month - 1, 1);
    let days = [];

    while (date.getMonth() === month - 1) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return days;
}

export const getMonths = () => {

    return [
        {id: 1, value: strings.months.january},
        {id: 2, value: strings.months.february},
        {id: 3, value: strings.months.march},
        {id: 4, value: strings.months.april},
        {id: 5, value: strings.months.may},
        {id: 6, value: strings.months.june},
        {id: 7, value: strings.months.july},
        {id: 8, value: strings.months.august},
        {id: 9, value: strings.months.september},
        {id: 10, value: strings.months.october},
        {id: 11, value: strings.months.november},
        {id: 12, value: strings.months.december},
    ];
}

export const getMonthString = (month) => {
    switch (parseInt(month)) {
        case 1: return strings.months.january;
        case 2: return strings.months.february;
        case 3: return strings.months.march;
        case 4: return strings.months.april;
        case 5: return strings.months.may;
        case 6: return strings.months.june;
        case 7: return strings.months.july;
        case 8: return strings.months.august;
        case 9: return strings.months.september;
        case 10: return strings.months.october;
        case 11: return strings.months.november;
        case 12: return strings.months.december;
    }
}

export const leapYear = (year) => {
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

export const stringToDate = (date, formatString = 'DD-MM-YYYY') => {
    return parse(date, formatString, new Date());
}

export const dateToString = (date, options = {prefix: '', suffix: '', formatString: null}) => {
    let formatString = DateFormats.getOrDefault(options.formatString || store.getState()?.auth?.localisationSettings?.dateFormat);
    let prefix = options.prefix || '';
    let suffix = options.suffix || '';
    if (!date || isNaN(date)) {
        return '';
    }
    return `${prefix}${format(date, formatString)}${suffix}`;
}

export const dateToStringDatePickerFormat = (date, formatString = 'MM/DD/YYYY') => {
    return format(date, formatString);
}

export const dateTimeToString = (date, options = {prefix: '', suffix: '', formatString: null}) => {
    let formatString = DateFormats.getOrDefault(options.formatString || store.getState()?.auth?.localisationSettings?.dateFormat) + ' HH:mm';
    let prefix = options.prefix || '';
    let suffix = options.suffix || '';
    if (!date || isNaN(date)) {
        return '';
    }
    return `${prefix}${format(date, formatString)}${suffix}`;
}

export const getCurrentDate = () =>{
    return new Date();
}

export const getCurrentYear = () => {
    return new Date().getFullYear();
}

export const getCurrentMonth = () => {
    return new Date().getMonth() + 1;
}

export const getCurrentDay = () =>{
    return new Date().getDate();
}

export const dateToEpoch = (date) => {
    return date.setHours(0,0,0,0);
}

export const areEqualByDayAndMonth = (date1, date2) => {
    return date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth();
}

export const greaterOrEqual = (date1, date2) => {
    return dateToEpoch(date1) >= dateToEpoch(date2)
}

export const lowerOrEqual = (date1, date2) => {
    return dateToEpoch(date1) <= dateToEpoch(date2)
}

export const dateAsUTCDate = (date) => {
    if (!date) {
        return null;
    }
    date = new Date(date);
    return new Date(
        Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds()
        )
    );
}

export const getDaysOfMonthLabels = (daysInMonth, withDot = true)=>{
    return Array(daysInMonth)
      .fill()
      .map((v, i) => i + 1 + (withDot && '.'));
}

export const getDaysOfWeek = () =>{
    return{
       0: strings.components.calendar.weekDays.sunday,
       1: strings.components.calendar.weekDays.monday,
       2: strings.components.calendar.weekDays.tuesday,
       3: strings.components.calendar.weekDays.wednesday,
       4: strings.components.calendar.weekDays.thursday,
       5: strings.components.calendar.weekDays.friday,
       6: strings.components.calendar.weekDays.saturday,
    }  
}
export const getDaysOfWeekShort = () =>{
    return{
       0: strings.components.calendar.weekDaysShort.sunday,
       1: strings.components.calendar.weekDaysShort.monday,
       2: strings.components.calendar.weekDaysShort.tuesday,
       3: strings.components.calendar.weekDaysShort.wednesday,
       4: strings.components.calendar.weekDaysShort.thursday,
       5: strings.components.calendar.weekDaysShort.friday,
       6: strings.components.calendar.weekDaysShort.saturday,
    }  
}
