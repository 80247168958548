import React, {useContext, useEffect, useState} from "react";
import DrawerContext from "../../../Context/DrawerContext";
import strings from "../../../localization";
import {useForm} from "react-hook-form";
import SnackbarContext from "../../../Context/SnackbarContext";
import {Paper} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import CancelInvoiceForm from "../../../Components/Forms/Pages/Finance/Invoice/CancelInvoiceForm";
import StornoInvoiceForm from "../../../Components/Forms/Pages/Finance/Invoice/StornoInvoiceForm";
import {stornoOutEInvoice} from "../../../Services/Finance/EInvoiceService";

const formRules = {
    'cancelComments': {required: true},
}

const StornoInvoice = ({invoice, onFinish, onCancel}) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const {showMessage} = useContext(SnackbarContext);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setDrawerTitle(strings.pages.finance.invoice.stornoInvoice.pageTitle);
    });

    const onSubmit = (data) => {

        stornoOutEInvoice(invoice.id, data).then(response => {
            onFinish()
        })
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }

            <StornoInvoiceForm
                errors={errors} data={data} form={form}
                formRules={formRules}
                onCancel={onCancel}
                onSubmit={handleSubmit(onSubmit)}
            />
        </Paper>
    </div>
}

export default StornoInvoice