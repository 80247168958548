import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import TransactionSplitForm from "../../../Components/Forms/Pages/Finance/Transaction/TransactionSplitForm";
import DrawerContext from "../../../Context/DrawerContext";
import LinearProgress from '@mui/material/LinearProgress';
import { splitTransaction } from "../../../Services/Finance/TransactionService";
import PaymentType from "../../../Constants/Finance/PaymentType";
import SnackbarContext from "../../../Context/SnackbarContext";


const formRules = {
    'value': {required: true},
}

const TransactionSplit = (props) => {

    const siteData = useSelector((state) => state.siteData)
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    
    const [transactionItem, setTransactionItem] = useState(props.transactionItem);

    useEffect(() => {
        setDrawerTitle(strings.pages.finance.transactions.transactionSplit.pageTitle);
    });

    useEffect(() => {
        fetch(); 
    },[])

    const fetch = () => {

    }

    const onSubmit = (data) => {
        setShowLoader(true);

        let transactionItemId = transactionItem.id;
        let transactionItemPaymentType = transactionItem.paymentType;
        let transactionValue = 0;
        if( transactionItemPaymentType === PaymentType.INCOME ){
            transactionValue = transactionItem.income;
        }else if( transactionItemPaymentType === PaymentType.OUTCOME ){
            transactionValue = transactionItem.outcome;
        }

        let sumValue = Object.keys(data).filter(
            key => key.indexOf('value') !==-1
        ).map(
            key => Number(data[key])
        ).filter(item => item).reduce((a, b) => a+b, 0);

        let dataTemp = [];

        dataTemp = Object.values(data).filter(
            key => key!==undefined,
        );

        for(let value of dataTemp){
            if(value===0){
                showMessage(strings.pages.finance.transactions.transactionSplit.errorTransactionSplitAmountNull, 'error');
                setShowLoader(false);
                return;
            }
        }

        data["transactionAmountSplited"] = dataTemp;

        if(sumValue === parseFloat(transactionValue) &&  sumValue > 0 ){

            splitTransaction({
                ...data,
                id : transactionItem?.id,
                paymentTypeId : transactionItem.paymentType,
                income : transactionItem.income,
                outcome : transactionItem.outcome,
                partnerName : transactionItem.partnerName,
                description : transactionItem.description,
                paymentDate : transactionItem.paymentDate,
                approved : transactionItem.approved,
                bankReportId : transactionItem.bankReport?.id,
                currencyId : transactionItem.currency?.id,
                employeeId : transactionItem.employee?.id,
                partnerId : transactionItem.partner?.id,
                contractId : transactionItem.contract?.id,
                contractPartId : transactionItem.contractPart?.id,
                invoiceId : transactionItem.invoice?.id,
                transactionCategoryId : transactionItem.transactionCategory?.id,
                transactionTypeId : transactionItem.transactionType,
                tax : transactionItem.tax,
                canReturnTax : transactionItem.canReturnTax,
            }).then(response => {
                setShowLoader(false);

                if (!response || !response.ok) {
                    showMessage(strings.pages.finance.transactions.transactionSplit.transactionSplitError, 'error');
                    props.onFinish();
                    return;
                }

                showMessage(strings.pages.finance.transactions.transactionSplit.successTransactionSplit, 'success');
                props.onFinish();
            });

        }else{
            if(sumValue > 0 && sumValue !== transactionValue){
                showMessage(strings.pages.finance.transactions.transactionSplit.errorTransactionSplitSumAmount, 'error');
            }else if( sumValue === 0 ){
                showMessage(strings.pages.finance.transactions.transactionSplit.errorTransactionSplitSumAmountNull, 'error');
            }else{
                showMessage(strings.pages.finance.transactions.transactionSplit.transactionSplitError, 'error');
            }
            props.onFinish();
        }
    }

    const onCancel = () => {
        props.onCancel();
    }



    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <TransactionSplitForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                control={control}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default TransactionSplit;
