import strings from "../../../localization";

export const CompanyDocumentQuestionType = {
    TEXT: 1,
    NUMBER: 2,
    DATE: 3,
    SELECT: 4,
    RADIO: 5,
}

export function getCompanyDocumentQuestionTypes() {
    return [
        {
            value: CompanyDocumentQuestionType.TEXT,
            name: getCompanyDocumentQuestionTypeString(CompanyDocumentQuestionType.TEXT)
        },
        {
            value: CompanyDocumentQuestionType.NUMBER,
            name: getCompanyDocumentQuestionTypeString(CompanyDocumentQuestionType.NUMBER)
        },
        {
            value: CompanyDocumentQuestionType.DATE,
            name: getCompanyDocumentQuestionTypeString(CompanyDocumentQuestionType.DATE)
        },
        {
            value: CompanyDocumentQuestionType.SELECT,
            name: getCompanyDocumentQuestionTypeString(CompanyDocumentQuestionType.SELECT)
        },
        {
            value: CompanyDocumentQuestionType.RADIO,
            name: getCompanyDocumentQuestionTypeString(CompanyDocumentQuestionType.RADIO)
        }
    ];
}

export function getCompanyDocumentQuestionTypeString(questionType) {
    switch(Number(questionType)) {
        case CompanyDocumentQuestionType.TEXT: return strings.companyDocumentQuestionType.text;
        case CompanyDocumentQuestionType.NUMBER: return strings.companyDocumentQuestionType.number;
        case CompanyDocumentQuestionType.DATE: return strings.companyDocumentQuestionType.date;
        case CompanyDocumentQuestionType.SELECT: return strings.companyDocumentQuestionType.select;
        case CompanyDocumentQuestionType.RADIO: return strings.companyDocumentQuestionType.radio;
    }
}

export default CompanyDocumentQuestionType;