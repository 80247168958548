import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function getAllAssetManagementLocations() {
    return await request('/api/asset-management-locations/all');
}

export async function getAssetManagementLocations(data) {
    return await request('/api/asset-management-locations', data);
}

export async function getAssetManagementLocationList(data) {
    return await request('/api/asset-management-locations', data);
}

export async function getAssetManagementLocation(id) {
    return await request('/api/asset-management-locations/' + id);
}

export async function deleteAssetManagementLocation(id) {
    return await request('/api/asset-management-locations/' + id, {}, HttpMethod.DELETE);
}

export async function editAssetManagementLocation(data) {
    return await request('/api/asset-management-locations/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addAssetManagementLocation(data) {
    return await request('/api/asset-management-locations', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        parentId: data.parent ? data.parent.id : null,
    }
}
