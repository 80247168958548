import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getCheckListTemplate(id) {
    return await request('/api/check-list-templates/' + id);
}

export async function getCheckListTemplates(data) {
    return await request('/api/check-list-templates', data);
}

export async function getAllCheckListTemplates() {
    return await request('/api/check-list-templates/all');
}

export async function addCheckListTemplate(data) {
    return await request('/api/check-list-templates', transformRequestData(data), HttpMethod.POST);
}

export async function editCheckListTemplate(data) {
    return await request('/api/check-list-templates/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteCheckListTemplate(id) {
    return await request('/api/check-list-templates/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        checkListCategoryId: data.checkListCategory ? data.checkListCategory.id : null
    }
}