import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import AssetForm from "../../../Components/Forms/Pages/AssetManagement/Asset/AssetForm";
import DrawerContext from "../../../Context/DrawerContext";
import {
  addAsset,
  getAssetManagmentLocations,
  getAssetTypes,
} from '../../../Services/AssetManagement/AssetService';
import LinearProgress from '@mui/material/LinearProgress';
import SnackbarContext from "../../../Context/SnackbarContext";
import { getUserCompanyAccess } from "../../../Services/User/UserCompanyAccessService";

const formRules = {
    'serialNumber': {required: true}, 'price': {required: true}, 'tax': {required: true}, purchasedDate: {required: true},
    'warrantyEnd': {required: true}, 'location': {required: true}, 'type': {required: true}, 
}

const AddAsset = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: {
            purchasedDate: null,
        }
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [assetLocations, setAssetLocations] = useState(0);
    const [assetTypes, setAssetTypes] = useState(0);
    const [employees, setEmployees] = useState(0);

    useEffect(() => {
        setDrawerTitle(strings.pages.assetManagement.asset.addAsset.pageTitle);
    });

    useEffect(() => {
        fetchAssetManagmentLocations();
        fetchAssetTypes(); 
        fetchEmployees();
    },[])

    const fetchEmployees = (term = "") => {
        getUserCompanyAccess({term}).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setEmployees(response.data.result.map(company => company.user));
        });
    }

    const fetchAssetManagmentLocations = (term = "") =>{
        getAssetManagmentLocations({term}).then((response) => {
            if (!response || !response.ok) {
              return;
            }
            setAssetLocations(response.data.result);
          });
    }

    const fetchAssetTypes = (term = "") =>{
        getAssetTypes({term}).then((response) => {
            if (!response || !response.ok) {
              return;
            }
            setAssetTypes(response.data.result);
          });
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        addAsset(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <AssetForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                assetLocations = {assetLocations}
                assetTypes = {assetTypes}
                employees={employees}
                errors={errors} data={data} form={form} control={control}
                onLocationChangeFilter={fetchAssetManagmentLocations}
                onTypeChangeFilter={fetchAssetTypes}
                onEmployeeChangeFilter={fetchEmployees}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default AddAsset;