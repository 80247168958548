import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getCompanyDocumentTemplateContent(id) {
    return await request('/api/company-document-template-contents/' + id);
}

export async function getCompanyDocumentTemplateContents(data) {
    return await request('/api/company-document-template-contents', data);
}

export async function getAllCompanyDocumentTemplateContents(data) {
    return await request('/api/company-document-template-contents/all', data);
}

export async function addCompanyDocumentTemplateContent(data) {
    return await request('/api/company-document-template-contents', transformRequestData(data), HttpMethod.POST);
}

export async function editCompanyDocumentTemplateContent(data) {
    return await request('/api/company-document-template-contents/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteCompanyDocumentTemplateContent(id) {
    return await request('/api/company-document-template-contents/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
    }
}