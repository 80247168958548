import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getDocumentStatuses(data) {
    return await request('/api/document-status', data);
}

export async function getDocumentStatus(id) {
    return await request('/api/document-status/' + id);
}

export async function addDocumentStatus(data) {
    return await request('/api/document-status', transformRequestData(data), HttpMethod.POST);
}

export async function editDocumentStatus(data) {
    return await request('/api/document-status/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteDocumentStatus(id) {
    return await request('/api/document-status/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
    }
}
