import React, {useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import AutoCompleteControl from '../../../../Controls/Inputs/AutoCompleteControl';

const ProjectForm = ({
                         onSubmit,
                         onCancel,
                         data,
                         form,
                         errors,
                         projectCategories,
                         values,
                         setValue,
                         formRules,
                         partners,
                         onChangePartnerFilter,
                         trigger,
                     }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const [startDate, setStartDate] = useState(values['start']);
    const [additionalRules, setAdditionalRules] = useState({});

    useEffect(() => {
        if(form.formState.isSubmitted){
            trigger('endDate');
        }
    }, [additionalRules, values['endDate']])

    useEffect(() => {
        setStartDate(values['startDate']);
        setValue('endDate', new Date(values['endDate']));
    }, [values['startDate']])

    useEffect(() => {
        changeAdditionalRules(startDate);
    },[startDate])

    const changeStartDate = (date) => {
        setValue('startDate', date);
        setStartDate(date);
    }

    const changeAdditionalRules = (date) => {
        if(!date){
            setAdditionalRules({});
            return;
        }
        setAdditionalRules({min: {value: date, message: strings.forms.hr.presence.dateTimeErorr}});
    }

    return (
        <FormProvider {...form}>
            <form id='project-form' className='form' action="#">

                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.project.project.name}
                    disabled={isPageStateViewDetails()}
                />


                <TextFieldControl
                    name='description'
                    rules={formRules['description']}
                    control={data}
                    label={strings.forms.project.project.description}
                    disabled={isPageStateViewDetails()}
                />

                <DateTimeControl
                    data={data}
                    rules={formRules['startDate']}
                    form={form}
                    error={Boolean(errors.startDate)}
                    helperText={errors.startDate && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.project.project.startDate}
                    name={'startDate'}
                    value={startDate}
                    setValue={(name, value) => changeStartDate(value)}
                    disabled={isPageStateViewDetails()}
                />
                <DateTimeControl
                    data={data}
                    form={form}
                    rules={{...formRules['endDate'], ...additionalRules}}
                    error={Boolean(errors.endDate)}
                    helperText={errors.endDate && errors.endDate.message}
                    label={strings.forms.project.project.endDate}
                    name={'endDate'}
                    value={values['endDate']}
                    setValue={setValue}
                    minDate={startDate}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    value={values['projectCategory']}
                    setValue={setValue}
                    name='projectCategory'
                    control={data}
                    label={strings.forms.project.project.projectCategory}
                    options={projectCategories}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    value={values['partner']}
                    setValue={setValue}
                    name='partner'
                    control={data}
                    label={strings.forms.project.project.partner}
                    options={partners}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onChangePartnerFilter}
                    onOpen={() =>onChangePartnerFilter('')}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default ProjectForm;