import strings from "../../localization";

const InvoiceStatus = {
    ALL: -1,
    CREATED: 1,
    PAID: 2,
    PARTIAL_PAID: 3,
    OVERDUE: 4,
    OVERPAID: 5
}

export function getInvoiceStatuses() {
    return [
        {
            id: InvoiceStatus.CREATED,
            name: getInvoiceStatusString(InvoiceStatus.CREATED)
        },
        {
            id: InvoiceStatus.PAID,
            name: getInvoiceStatusString(InvoiceStatus.PAID)
        },
        {
            id: InvoiceStatus.PARTIAL_PAID,
            name: getInvoiceStatusString(InvoiceStatus.PARTIAL_PAID)
        },
        {
            id: InvoiceStatus.OVERDUE,
            name: getInvoiceStatusString(InvoiceStatus.OVERDUE)
        },
        {
            id: InvoiceStatus.OVERPAID,
            name: getInvoiceStatusString(InvoiceStatus.OVERPAID)
        }
    ];
}

export function getInvoiceStatusesWithAll() {
    return [
        {
            id: InvoiceStatus.ALL,
            name: getInvoiceStatusString(InvoiceStatus.ALL)
        },
        {
            id: InvoiceStatus.CREATED,
            name: getInvoiceStatusString(InvoiceStatus.CREATED)
        },
        {
            id: InvoiceStatus.PAID,
            name: getInvoiceStatusString(InvoiceStatus.PAID)
        },
        {
            id: InvoiceStatus.PARTIAL_PAID,
            name: getInvoiceStatusString(InvoiceStatus.PARTIAL_PAID)
        },
        {
            id: InvoiceStatus.OVERDUE,
            name: getInvoiceStatusString(InvoiceStatus.OVERDUE)
        },
        {
            id: InvoiceStatus.OVERPAID,
            name: getInvoiceStatusString(InvoiceStatus.OVERPAID)
        }
    ];
}

export function getInvoiceStatusString(paymentStatus) {
    switch(paymentStatus) {
        case InvoiceStatus.CREATED: return strings.constants.finance.invoiceStatus.created;
        case InvoiceStatus.PAID: return strings.constants.finance.invoiceStatus.paid;
        case InvoiceStatus.PARTIAL_PAID: return strings.constants.finance.invoiceStatus.partialPaid;
        case InvoiceStatus.OVERDUE: return strings.constants.finance.invoiceStatus.overdue;
        case InvoiceStatus.OVERPAID: return strings.constants.finance.invoiceStatus.overpaid;
        case InvoiceStatus.ALL: return strings.constants.finance.invoiceStatus.all;
    }
}

export default InvoiceStatus;