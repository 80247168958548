import React, {useContext} from 'react';
import { FormProvider } from 'react-hook-form';
import strings from '../../../../../localization';
import Button from '@mui/material/Button';
import TextFieldControl from '../../../../Controls/Inputs/TextFieldControl';
import TablePageContext from '../../../../../Context/TablePageContext';
import PageState from '../../../../../Constants/Base/PageState';
import CheckBoxControl from '../../../../Controls/Inputs/CheckBoxControl';

const SpaceForm = ({formRules, form, values, data, onSubmit, onCancel, errors, setValue, isOwner}) => {
 
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form className = 'form' action = '#'>
                <TextFieldControl
                    name = 'name'
                    className='mt-8 mui-shifted-label-input'
                    control = {data}
                    rules = {formRules['name']}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    error = {Boolean(errors.name)}
                    label = {strings.forms.document.spaces.name}
                    placeholder = {strings.forms.document.spaces.enterName}
                    disabled={isPageStateViewDetails()}
                    multiline
                />
                <CheckBoxControl
                    name={'visible'}
                    control={data}
                    label = {strings.forms.document.spaces.isPublic}
                    value={values['visible']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails() || !isOwner}
                />
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.forms.common.save}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.forms.common.cancel}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default SpaceForm;
