import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";


export async function getCheckListScheduledChecks(data) {
    return await request('/api/check-list-scheduled-check', data);
}

export async function getAllCheckListScheduledChecks(data) {
    return await request('/api/check-list-scheduled-check/all', data);
}

export async function addCheckListScheduledCheck(data) {
    return await request('/api/check-list-scheduled-check', transformRequestData(data), HttpMethod.POST);
}

export async function editCheckListScheduledCheck(data) {
    return await request('/api/check-list-scheduled-check/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteCheckListScheduledCheck(id) {
    return await request('/api/check-list-scheduled-check/' + id, {}, HttpMethod.DELETE);
}

export async function doCheckCheckListScheduledCheck(data) {
    return await request('/api/check-list-scheduled-check-do-check', data, HttpMethod.PUT);
}

export async function cancelCheckList(id) {
    return await request('/api/check-list-scheduled-check/cancel/' + id, {},HttpMethod.PUT)
}

function transformRequestData(data){

    return {
        ...data,
        partnerId: data.partner ? data.partner.id : null,
        employeeId: data.employee ? data.employee.id : null,
        checkListTemplateId: data.template ? data.template.id : null,
        locationId: data.location ? data.location.id : null
    }
}

