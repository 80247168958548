import React from "react";
import {useEffect, useState } from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteProjectTimeLog,
    downloadProjectTimeLogPDF,
    getProjectTimeLogList,
} from "../../../Services/Project/ProjectTimeLogService";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import { formatColumnObject, formatColumnDate } from "../../../Components/DataGrid/ValueFormatter";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import AddProjectTimeLog from "./AddProjectTimeLog";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import EditProjectTimeLog from "./EditProjectTimeLog";
import {HiddenReferenceType} from "../../../Constants/DocumentManagement/ReferenceType";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {getProjectList, getProject} from "../../../Services/Project/ProjectService";
import {getTaskCategories, getTaskCategory} from "../../../Services/Project/TaskCategoryService";
import { renderColumnColorChip, renderColumnNumber, returnColumnBoolean } from "../../../Components/DataGrid/ValueCellRender";
import { getBillableTypes } from "../../../Constants/Project/ProjectTimeLogBillableType";
import {renderNumber} from "../../../Util/RenderDataUtil";
import StatisticComponent from "../../../Components/DataGrid/StatisticComponent";
import { getCurrentMonth, getCurrentYear } from "../../../Util/DateUtil";
import {getProjectAccess} from "../../../Services/Project/ProjectAccessService";
import ProjectTimeLogStatistic from "./ProjectTimeLogStatistic";
import {Grid, IconButton} from "@mui/material";
import { getEmployees, getEmployeeByUser } from "../../../Services/User/UserService";
import {fetchOptions} from '../../../Services/Base/FilterOptionsService';
import {getProjectTaskCategory} from "../../../Services/Project/ProjectTaskCategoryService";
import { getProjectTimeLogStatistic } from "../../../Services/Project/ProjectTimeLogService";
import { hasPermission } from '../../../Util/PermissionUtil';
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";


const tableDescription = [
    {
        field: 'taskCategory',
        headerName: strings.pages.project.projectTimeLog.projectTimeLogList.taskCategory,
        width: 200,
        renderCell: (params) => renderColumnColorChip(params,'name')

    },
    {
        field: 'name', 
        headerName: strings.pages.project.projectTimeLog.projectTimeLogList.name,
        width: 200,
    },
    {
        field: 'dateLogged',
        headerName: strings.pages.project.projectTimeLog.projectTimeLogList.dateLogged,
        width: 200,
        valueFormatter: (params) => formatColumnDate(params)
    },
    {
        field: 'hourLogged', 
        headerName: strings.pages.project.projectTimeLog.projectTimeLogList.hoursLogged,
        renderCell: (params) => renderColumnNumber(params),
        width: 150,
    },
    {
        field: 'employeeUser',
        headerName: strings.pages.project.projectTimeLog.projectTimeLogList.employee,
        valueFormatter: (params) => formatColumnObject(params, 'fullName'),
        width: 250
    },
    {
        field: 'project',
        headerName: strings.pages.project.projectTimeLog.projectTimeLogList.project,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 250,
        hideOnProjectPage: true
    },
    {
        field: 'billable', 
        headerName: strings.pages.project.projectTimeLog.projectTimeLogList.billable,
        renderCell: (params) => returnColumnBoolean(params),
        width: 150,
    },
];


const ProjectTimeLogList = ({project = null, projectObject=null}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(false);
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState({...FilterDefaults, year: getCurrentYear(), month: getCurrentMonth(), project});
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState({
        ...TablePageOptionDefaults,
        showYearFilter: true,
        showMonthFilter: true
    });
    const [filterOptions, setFilterOptions] = useState({});
    const [chartData, setChartData] = useState([]);

    const referenceType = HiddenReferenceType.PROJECT_TIME_LOG;
    const permissionGroup = AppPermissions.PROJECT_TIME_LOG.GROUP;


    useEffect(() => {
        if(!project){
            return;
        }
        setFilter({...filter, project})
    }, [project])

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    const hasViewAll = () => {
        return hasPermission(auth.user, AppPermissions[permissionGroup].GROUP, AppPermissions[permissionGroup].VIEW_ALL, auth.permissions);
    }

    const fetchProjects = async (term, filterFetch) => {
        return fetchOptions('project', searchParams, getProjectList, getProject, setFilterOptions, term, filterFetch);
    }

    const fetchEmployees = async (term, filterFetch) => {
        return fetchOptions('employee', searchParams, getEmployees, getEmployeeByUser, setFilterOptions, term, filterFetch);
    }

    const fetchTaskCategories = async (term, filterFetch) => {
        return fetchOptions('taskCategory', searchParams, getTaskCategories, getTaskCategory, setFilterOptions, term, filterFetch);
    }

    const formatEmployees = async (employees) => {
        let formatedEmployees = [...employees];

        if (project) {
            const projectAccesses = await getProjectAccess(project);
            let availableUsers = {};
            projectAccesses.data.result.forEach(pa => availableUsers[pa.user?.id] = pa.allowed)
            formatedEmployees = employees.filter(e => e.owner || availableUsers[e.user.id])
        }

        return formatedEmployees.map((e) => ({...e.user, id: e.user.id}));
    }

    const formatFetchedEmployees = async (term) => {
        const employees = await fetchEmployees(term);
        const formatedEmployees = await formatEmployees(employees);

        setFilterOptions((prev) => ({...prev, employee: formatedEmployees, fetched: prev.fetched + 1}));
    }

    const fetchFilters = async () => {
        const projects = await fetchProjects();
        let taskCategories = await fetchTaskCategories();
        const employees = await fetchEmployees();

        const formatedEmployees = await formatEmployees(employees);

        if (project) {
            const projectCategories = await getProjectTaskCategory(project);
            let availableCategories = {};
            projectCategories.data.result.forEach(pa => availableCategories[pa.taskCategory?.id] = pa.allowed)
            taskCategories = taskCategories.filter(e => availableCategories[e.id])
        }

        setFilterOptions({
            project: projects,
            taskCategory: taskCategories,
            employee: formatedEmployees,
            billable: getBillableTypes(),
            fetched: 1,
        });
    }

    const filters = [
        {
            name: 'project',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'project',
            label: strings.pages.project.projectTimeLog.projectTimeLogList.project,
            hideOnProjectPage: true,
            onChange: (term) => fetchProjects(term, true),
        },
        {
            name: 'taskCategory',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'taskCategory',
            label: strings.pages.project.projectTimeLog.projectTimeLogList.taskCategory,
            onChange: (term) => fetchTaskCategories(term, true),
        },
        {
            name: 'employee',
            nameKey: 'fullName',
            valueKey: 'id',
            optionsName: 'employee',
            label: strings.pages.project.projectTimeLog.projectTimeLogList.employee,
            onChange: (term) => formatFetchedEmployees(term),
        },
        {
            name: 'billable',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'billable',
            label: strings.pages.project.projectTimeLog.projectTimeLogList.billable,
            showSelect: true
        },
    ];

    useEffect(()=>  {
        setTableData({
            ...tableData,
            loading: true
        })
    },[])

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT)); 

        if(!searchParams.toString()){
            const params = {year: getCurrentYear(), month: getCurrentMonth()};
            if(project){
                params.project = project
            }

            if(!hasViewAll()) {
                params.employee = auth.user.id;
            }

            setSearchParams(params, {replace: true});
            setFilter({...FilterDefaults, ...params});
            setSearchParams({year: getCurrentYear(), month: getCurrentMonth()}, {replace: true});
        }

        fetchFilters().then();

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter]);

    useEffect(()=>{
        const breadcrumbsObject = {
            title: strings.pages.project.projectTimeLog.projectTimeLogList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.PROJECTS},
                {label: strings.pages.project.projectTimeLog.projectTimeLogList.pageTitle},
            ],        
        }
        if(projectObject){
           breadcrumbsObject.hierarchy.splice(2,0, {label: strings.pages.project.details})
        }
        dispatch(changeBreadcrumbs(breadcrumbsObject));
    },[projectObject])


    const controls = [
        <IconButton onClick={ () => handleDownload()}>
            <img src="/images/table-page/view-blue.svg" loading="lazy"/>
        </IconButton>
        
    ]

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = (closeDrawer = true) => {
        fetch();
        if (closeDrawer) {
            setPageState(PageState.View);
        }
    }

    const fetch = () => {
        fetchChartData();

        setTableData({
            ...tableData,
            loading: true
        });

        getProjectTimeLogList({
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        });
    }

    const handleDownload = () => {
        downloadProjectTimeLogPDF(filter);
    }

    const calculateStatistics = (data) => {

        let hours = 0;
        let billableHours = 0;

        for(let item of data) {
            hours += parseFloat(item.hourLogged);

            if(item.billable) {
                billableHours += parseFloat(item.hourLogged);
            }
        }

        return [
            {
                title: 'Hours',
                size: 3,
                data: [
                    {value: renderNumber(hours)}
                ]
            },
            {
                title: 'Billable Hours',
                size: 3,
                data: [
                    {value: renderNumber(billableHours)}
                ]
            }
        ]
    }

    const hideOnProjectPage = ({hideOnProjectPage}) => {
        if (!project) {
            return true;
        }
        if (!hideOnProjectPage) {
            return true;
        }
        return false;
    }

    const fetchChartData = () => {
        getProjectTimeLogStatistic({...filter}).then(response => {
            if(!response || !response.ok){
                return;
            }
            setChartData([
                {
                        label: strings.pages.project.projectTimeLog.projectTimeLogStatistic.workingHours,
                        data: response.data.workingHours
                },
                {
                        label: strings.pages.project.projectTimeLog.projectTimeLogStatistic.timeLogged,
                        data: response.data.timeLogged
                },
                {
                        label: strings.pages.project.projectTimeLog.projectTimeLogStatistic.billableTimeLogged,
                        data: response.data.billableTimeLogged
                }
            ]);
        });
    }

    const renderStatistics = ()=>{
        return <Grid className="project-time-log-statistic ml-6 pr-6" container>
        <Grid item xs={3} className="statistic-data">
            <StatisticComponent  data={calculateStatistics(tableData.data)}/>
            </Grid>
        <Grid item xs={9} className="statistic-chart">{<ProjectTimeLogStatistic inputData={chartData}/>}</Grid>
    </Grid>
    }
    return <>     
        <TablePageContext.Provider value={value}>
            {
            renderStatistics()
            }
            <TablePage onFinish={() => onFinish()} deleteItem={deleteProjectTimeLog} tableDescription={columns.filter(hideOnProjectPage)}
                    tableData={tableData} showDeletingError={true}
                    filter={filter}
                    filters={filters.filter(hideOnProjectPage)}
                    filterOptions={filterOptions}
                    referenceType={referenceType} 
                    permissionGroup={permissionGroup}
                    tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                    editPage={<EditProjectTimeLog project={project} data={ selectedItems }
                    onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                    addPage={<AddProjectTimeLog projectObject={projectObject} project={project} onCancel={() => onCancel()} onFinish={(closeDrawer) => onFinish(closeDrawer)}/>}
                    hideBreadcrumbs
                    addButtonText={strings.components.tablePage.addButton.projectTimeLog}
                    innerToolbarControls = {controls}
                    />

        </TablePageContext.Provider>
    </>
   
}

export default ProjectTimeLogList;
