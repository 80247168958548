import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
    en: {
        error: {
            ContractHandler: {
                paymentDayError:
                    "Payment day must be defined and must be greater than 0 and less or equal than 31",
            },
            OrganisationUserContractHandler: {
                paymentDayError:
                    "Payment day must be defined and must be greater than 0 and less or equal than 31",
            },
            DocumentHandler: {
                invalidS3: "Please check the S3 configuration",
            },
            File: {
                "file-invalid-type": "Invalid type of file",
                "file-too-large": "Large file",
            },
            PresenceHandler: {
                checkoutError: "You need to checkout, before editing this presence",
            },
            VerifyEmailHandler: {
                userDoesNotExist: "User does not exist",
                tooManyRequests: "Please wait at least a minute since last request",
            },
            LeaveAndLeaveRequestHandler: {
                overlappingLeaveRequest:
                    "Leave request already exsist for selected date",
                overlappingLeave: "Leave already exsist for selected date",
            },
        },
        header: {
            startFreeTrial: "Start trial",
            profile: "Profile",
            lock: "Lock",
            logout: "Logout",
            checkIn: "Check in",
            checkOut: "Check out",
            companyProfiles: "Company profiles",
            favoriteBoards: "Favorite boards",
            workspace: "Workspace",
            noOptions: "No options",
        },
        profile: {
            userProfile: "User profile",
            editProfile: "Edit profile",
            email: "Email",
            firstName: "First name",
            lastName: "Last name",
            personalNumber: "Personal number",
            phoneNumber: "Phone number",
            birthDay: "Birthday",
            address: "Address",
            profile: "Profile",
            changePassword: "Change password",
            profileImage: "Profile image",
            passwordsDoNotMatch: "Passwords do not match",
            signature: "Signature",
            title: "Title",
            description: "Description",
            workingHours: {
                name: "Working Hours",
                btnName: "Name",
                btnSchedule: "New schedule",
                unavailable: "Unavailable",
                editWorkingHours: "Edit Working Hours",
                default: "Set as default",
                edit: "Edit Name",
                delete: "Delete",
                apply: "Apply",
                title: "New Working Hours Schedule",
                description: "This is description about your working hours schedule",
            },
            publicLink: {
                name: "Link",
                header:
                    "Create personal link that will be used for meeting and other functionalities",
                placeholder: "name",
                validationProblem: "There was problem with validation",
                collision: "There is another user with same public link",
                checking: "Checking is link unique",
                problemSaving: "There was problem saving public link",
                success: "Public link saved successfully",
            },
        },
        changeUserProfileImage: {
            pageTitle: "Change profile picture",
        },
        signatureForm: {
            save: "Save",
            clear: "Clear",
        },
        changePasswordForm: {
            oldPassword: "Old password",
            newPassword: "New password",
            newPasswordRepeat: "New password repeat",
            changePassword: "Change password",
        },
        userCompanyAccessStatus: {
            pending: "Pending",
            active: "Active",
            canceled: "Canceled",
        },
        companyDocumentItemType: {
            string: "String",
            integer: "Integer",
            double: "Double",
            date: "Date",
            reference: "Reference",
        },
        companyDocumentQuestionType: {
            text: "Text",
            number: "Number",
            date: "Date",
            select: "Select",
            radio: "Radio button",
        },
        companyDocumentReferenceUserValueType: {
            firstName: "First name",
            lastName: "Last name",
            fullName: "Full name",
        },
        companyDocumentReferenceType: {
            user: "User",
            partner: "Partner",
            company: "Company",
        },
        templateContent: {
            pageTitle: "Content",
            templateContentSaved: "TemplateContentSaved",
        },
        constants: {
            referenceTypes: {
                NONE: "None",
                USER: "User",
                USER_POSITION: "User position",
                JOB_STATUS: "Job status",
                JOB_CONTRACT_TYPE: "Job contract type",
                TEAM: "Team",
                ORGANISATION_USER_CONTRACT: "Employee contract",
                PRODUCT_SERVICE: "Product service",
                PARTNER: "Partner",
                INVOICE: "Invoice",
                CONTRACT: "Contract",
                TRANSACTION: "Transaction",
                TRANSACTION_CATEGORY: "Transaction category",
                BANK_ACCOUNT: "Bank account",
                BUSINESS_YEAR: "Business year",
                ROLE: "Role",
                COMPANY_DOCUMENT_CATEGORY: "Company document category",
                COMPANY_DOCUMENT_TEMPLATE: "Company document template",
                COMPANY_DOCUMENT: "Company document",
                PROJECT: "Project",
                PROJECT_CATEGORY: "Project category",
                DOCUMENT_CATEGORY: "Document category",
                CONTRACT_PART: "Contract part",
                LEAVE_TYPE: "Leave type",
                PROJECT_RESOURCE: "Project resource",
                ADDITIONAL_HOURS: "Additional hours",
                COMPANY_DOCUMENT_TEMPLATE_ITEM: "Company document template item",
                DOCUMENT_STATUS: "Document status",
                BANK_REPORT: "Bank report",
                CANDIDATE: "Candidate",
                CANDIDATE_SOURCE: "Candidate source",
                JOB: "Job",
                LEAVE: "Leave",
                LEAVE_REQUEST: "Leave request",
                NON_WORKING_DAY: "Non working day",
                ORGANISATION_USER_CONTRACT_PART: "Employee contract part",
                EMPLOYEE: "Employee",
                CANDIDATE_STATUS: "Candidate status",
                ORGANISATION_UNIT: "Organisation unit",
                BUSINESS_YEAR_START_VALUE: "Business year start value",
                TASK_CATEGORY: "Task category",
                ASSET: "Asset",
                ASSET_CATEGORY: "Asset category",
                ASSET_MANAGEMENT_LOCATION: "Location",
                ASSET_TYPE: "Asset type",
                INVETORY: "Invetory",
                INVETORY_CATEGORY: "Invetory category",
                INVETORY_TYPE: "Invetory type",
                STOCK_CHANGE: "Stock change",
                VENDOR: "Vendor",
                PARTNER_CONTACT: "Partner contract",
                PIPE_LINE: "Pipeline",
                DEAL_TYPE: "Deal type",
                DEAL_PRIORITY: "Deal prioirty",
                DEAL_SOURCE: "Deal source",
                DEAL: "Deal",
                PROJECT_TIME_LOG: "Project time log",
                TASK: "Task",
                TASK_TEMPLATE: "Task template",
                PRESENCE: "Presence",
            },
            finance: {
                transactionType: {
                    transaction: "Transaction",
                    taxPayment: "Tax payment",
                    taxReturn: "Tax return",
                },
                paymentType: {
                    income: "Income",
                    outcome: "Outcome",
                },
                invoiceStatus: {
                    created: "Created",
                    paid: "Paid",
                    partialPaid: "Partial paid",
                    overdue: "Overdue",
                    overpaid: "Overpaid",
                    all: "Invoice status",
                },
                invoiceType: {
                    invoice: "Invoice",
                    all: "Invoice type",
                    proInvoice: "Pro invoice",
                    advanceInvoice: "Advance invoice",
                    storno: "Storno",
                },
                contractType: {
                    customerContract: "Customer Contract",
                    supplierContract: "Supplier Contract",
                },
                productServiceType: {
                    product: "Product",
                    service: "Service",
                },
            },
            hr: {
                employeeStatus: {
                    exEmployee: "Ex employee",
                    current: "Current",
                    resignation: "Resignation",
                },
                organisationUserContractType: {
                    CONTRACT: "Contract",
                    ANNEX: "Annex",
                    BONUS: "Bonus",
                    TRANSPORTATION_COSTS: "Transportation Costs",
                    BUSINESS_TRIP: "Business Trip",
                },
                paymentMonth: {
                    LAST_MONTH: "Last month",
                    CURRENT_MONTH: "Current month",
                    NEXT_MONTH: "Next month",
                },
                jobPaymentFrequency: {
                    MONTHLY: "Monthly",
                    WEEKLY: "Weekly",
                    DAILY: "Daily",
                },
                additionalHoursStatus: {
                    PENDING: "Pending",
                    APPROVED: "Approved",
                    REJECTED: "Rejected",
                },
                presence: {
                    changeRequestedType: {
                        notRequested: "Not requested",
                        requested: "Requested",
                    },
                },
            },
            company: {
                pipelineType: {
                    hr: "HR",
                    crm: "CRM",
                    task: "TASK",
                    production: "PRODUCTION",
                },
                pipelineStageType: {
                    todo: "To do",
                    inProgress: "In progress",
                    done: "Done",
                },
            },
            project: {
                projectTimeLog: {
                    notBillable: "Not Billable",
                    billable: "Billable",
                },
            },
            permissions: {
                codes: {
                    VIEW_LIST: "View list",
                    VIEW: "View",
                    VIEW_ALL: "View all",
                    VIEW_MY: "View my",
                    ADD: "Add",
                    ADD_MY: "Add my",
                    EDIT: "Edit",
                    EDIT_MY: "Edit my",
                    DELETE: "Delete",
                    DELETE_MY: "Delete my",
                    DOCUMENTS: "Documents",
                    COMPANY_DOCUMENT: "Documents",
                    RESOLVE: "Resolve",
                    DASHBOARD: "Dashboard",
                    CALENDAR: "Calendar",
                    HR: "HR",
                    PRODUCT: "Products",
                    FINANCE: "Finance",
                    CRM: "CRM",
                    ADMINISTRATION: "Administration",
                    PROJECT_MANAGEMENT: "Project",
                    DOCUMENT: "Files",
                    ASSET_MANAGEMENT: "Assets",
                    ACCESS: "Access",
                    TASK_CATEGORY: "Task category",
                    STATISTIC: "Statistic",
                    HOME: "Home",
                    WORKSPACES: "Workspaces",
                    SPACES: "Spaces",
                    BOOKMARK: "Bookmark",
                    CHECK_LIST: "Check list",
                },
                groups: {
                    HR_SETTINGS: "HR settings",
                    PRODUCT: "Product",
                    DASHBOARD: "Dashboard",
                    TEAM: "Team",
                    ORGANISATION_UNIT: "Organisation unit",
                    EMPLOYEE: "Employee",
                    ORGANISATION_USER_CONTRACT: "Organisation user contract",
                    ORGANISATION_USER_CONTRACT_PART: "Organisation user contract part",
                    JOB: "Job",
                    CANDIDATE: "Candidate",
                    ADDITIONAL_HOURS: "Additional hours",
                    LEAVE_REQUEST: "Leave request",
                    LEAVE: "Leave",
                    CANDIDATE_SOURCE: "Candidate source",
                    CANDIDATE_STATUS: "Candidate status",
                    JOB_CONTRACT_TYPE: "Job contract type",
                    JOB_STATUS: "Job status",
                    NON_WORKING_DAYS: "Non working days",
                    USER_POSITION: "User position",
                    LEAVE_TYPE: "Leave type",
                    FINANCE_SETTINGS: "Finance settings",
                    PRODUCTS_AND_SERVICES: "Product / services",
                    PRODUCT_SETTINGS: "Product settings",
                    PRODUCTS_AND_SERVICES_CATEGORY: "Product / service category",
                    ATTRIBUTES: "Attributes",
                    ATTRIBUTES_VALUE: "Attributes value",
                    INVOICE: "Invoice",
                    INVOICE_SETTINGS: "Invoice settings",
                    CASH_FLOW: "Cash flow",
                    CONTRACT: "Contract",
                    CONTRACT_PART: "Contract part",
                    BANK_REPORT: "Bank report",
                    BILL: "Bills",
                    UPLOAD_BANK_REPORT: "Upload bank report",
                    BANK_ACCOUNT: "Bank account",
                    TRANSACTION: "Transcation",
                    TRANSACTION_CATEGORY: "Transaction category",
                    BUSINESS_YEAR: "Business year",
                    BUSINESS_YEAR_START_VALUE: "Business year start value",
                    CRM_SETTINGS: "CRM settings",
                    PARTNER: "Partner",
                    PARTNER_CONTACT: "Partner contact",
                    DEAL: "Deal",
                    CUSTOMER: "Customer",
                    DEAL_TYPE: "Deal type",
                    DEAL_SOURCE: "Deal source",
                    DEAL_PRIORITY: "Deal priority",
                    USER: "User",
                    PIPE_LINE: "Pipeline",
                    PIPE_LINE_STAGE: "Pipeline stage",
                    COMPANY_DOCUMENT: "Company document",
                    COMPANY_DOCUMENT_FOLDER: "Company document folder",
                    COMPANY_DOCUMENT_CATEGORY: "Company document category",
                    COMPANY_DOCUMENT_TEMPLATE: "Company document template",
                    COMPANY_DOCUMENT_TEMPLATE_ITEM: "Company document template item",
                    DOCUMENT: "Document",
                    DOCUMENT_CATEGORY: "Document category",
                    DOCUMENT_STATUS: "Document status",
                    DOCUMENT_COMMENT: "Document comment",
                    ASSET: "Asset",
                    ASSET_CATEGORY: "Asset category",
                    ASSET_LOCATION: "Locations",
                    ASSET_TYPE: "Asset type",
                    INVENTORY: "Inventory",
                    INVENTORY_CATEGORY: "Inventory category",
                    INVENTORY_TYPE: "Inventory type",
                    STOCK_CHANGE: "Stock change",
                    VENDOR: "Vendor",
                    PROJECT_SETTINGS: "Project settings",
                    PROJECT: "Project",
                    PROJECT_CATEGORY: "Project category",
                    PROJECT_RESOURCE: "Project resource",
                    PROJECT_RESOURCE_TYPE: "Project resource type",
                    PROJECT_TIME_LOG: "Project time log",
                    TASK_CATEGORY: "Task category",
                    FILE_FOLDER: "File folder",
                    MODULE: "Module",
                    COMPANY_SETTINGS: "Company settings",
                    EMPLOYEE_RECORDS: "Employee record",
                    CANDIDATE_RECORDS: "Candidate record",
                    DEAL_RECORDS: "Deal record",
                    PROJECT_RECORDS: "Project record",
                    PRESENCE: "Presence",
                    WORKSPACES: "Workspaces",
                    BOARDS: "Boards",
                    ROLE: "Roles",
                    ROLE_PERMISSIONS: "Role permissions",
                    CONTRACT_STATUS: "Contract status",
                    SPACES: "Spaces",
                    SPACE_FOLDERS: "Space folders",
                    CHECK_LIST: "Check list",
                    CHECK_LIST_TEMPLATE: "Check list template",
                    CHECK_LIST_CATEGORY: "Check list category",
                    CHECK_LIST_TEMPLATE_QUESTION: "Check list template question",
                    CHECK_LIST_SCHEDULED_CHECK: "Check list scheduled check",
                    COMPOSITE_PRODUCT: "Composite product",
                },
            },
            crm: {
                partnerLegalType: {
                    private: "Private",
                    public: "Public",
                },
            },
            workspaces: {
                linkedIssueType: {
                    isBlockedBy: "is blocked by",
                    blocks: "blocks",
                    isClonedBy: "is cloned by",
                    clones: "clones",
                    isDuplicatedBy: "is duplicated by",
                    duplicates: "duplicates",
                    relatesTo: "relates to",
                    archived: "Archived",
                    notArchived: "Not archived",
                },
            },
        },
        pages: {
            home: {
                settings: {
                    tasks: "Tasks",
                    taskTemplates: "Task templates",
                },
            },
            company: {
                company: "Company",
                settings: {
                    settings: "Settings",
                    bankAccounts: "Bank accounts",
                    logo: "Logo",
                    errorUpdateCompany: "Error update company",
                    companyEdited: "Company edited",
                    errorGettingCompany: "Error getting company",
                    pageTitle: "Company settings",
                    invoiceSettings: "Invoice Settings",
                    s3settings: "S3 Settings",
                    localisationSettings: "Localisation Settings",
                },
                baseSettings: {
                    pageTitle: "Settings",
                },
                logo: {
                    pageTitle: "Logo",
                },
            },
            user: {
                userList: {
                    lastRaise: "Last raise",
                    nextRaise: "Next raise",
                    phoneNumber: "Phone number",
                    address: "Address",
                    personalNumber: "Personal number",
                    dateStarted: "Date started",
                    lastPaymentNet: "Last payment net",
                    lastPaymentGross: "Last payment gross",
                    birthDate: "Birthdate",
                    image: "",
                    pageTitle: "Users",
                    email: "Email",
                    firstName: "First name",
                    lastName: "Last name",
                    userPosition: "User position",
                    roles: "Roles",
                    status: "Status",
                    owner: "Owner",
                    organisationUser: "Organisation user",
                    team: "Team",
                    details: "View details",
                    bankAccount: "Bank account",
                },
                employeeDetails: {
                    pageTitle: "Employee",
                    record: "Record",
                    files: "Files",
                    statistic: "Statistic",
                },
                employeeStatistic: {
                    yearLabel: "Year",
                    monthLabel: "Month",
                    hours: "Hours",
                    totalWorkingDays: "Working days",
                    totalWorkingHours: "Working hours",
                    net: "Net",
                    gross: "Gross",
                    payment: "Payment",
                    paymentPerHour: "Payment per hour",
                    hourRatePerMonth: "Hour rate per month",
                    hourRateAverageOfHourRatePerYear:
                        "Hour rate average of hour rate per year",
                    timeLogged: "Time logged",
                    billableTimeLogged: "Billable time logged",
                    presence: "Presence (hours)",
                    presenceByDay: "Presence (hours per day)",
                    presenceByMonth: "Hours per month",
                    presenceByMonthAveragePerMonth: "Average hours per month",
                    presenceByMonthAveragePerYear: "Average hours per year",
                },
                registration: {
                    signUp: "Sign Up",
                    registration: "Registration",
                    userAlreadyExists: "User already exists",
                },
                acceptInvite: {
                    pageTitle: "Accept invite",
                    logIn: "Log In",
                    haveAnAccount: "Do you have an account?",
                },
                startTrial: {
                    startTrial: "Start trial",
                    start: "Start",
                    errorMessage: "Set company settings before starting a trial",
                },
                verifyEmail: {
                    header: "Verify your email to activate your account",
                    description: "All steps to activate the email have been sent to",
                    goToMail: "Go to mail",
                    didNotRecive: "You did not receive the email?",
                    sendAgain: "Send again",
                    checkEmail: "Check your email!",
                    error: "There was a problem while trying to send a email",
                    success: "Verification email was sent successfully",
                },
            },
            product: {
                attribute: {
                    pageTitle: "Attributes",
                    addAttribute: "Add attribute",
                    editAttribute: "Edit attribute",
                    description: "Description",
                    addProductService: {
                        pageTitle: "Attribute",
                        errorAddingAttribute: "Error adding attribute",
                        attributeAdded: "Attribute added",
                    },
                },
                attributeValue: {
                    pageTitle: "Attribute values",
                    addAttributeValue: "Add attribute value",
                    editAttributeValue: "Edit attribute value",
                },
                productService: {
                    productServiceList: {
                        sku: "SKU",
                        name: "Name",
                        publicName: "Public name",
                        description: "Description",
                        price: "Price",
                        tax: "Tax",
                        taxValue: "Tax value",
                        priceWithTax: "Price with tax",
                        currency: "Currency",
                        unit: "Unit",
                        pageTitle: "Product and services",
                        productServiceDeleted: "Product or service deleted",
                        publicInoName: "Public ino name",
                        type: "Type",
                        productType: "Product type",
                        productServiceCategory: "Product service category",
                        compositeProduct: "Composite product",
                        connect: "Connect",
                        disconnect: "Disconnect",
                        createWooCommerce: "Create on WooCommerce",
                    },
                    editProductService: {
                        pageTitle: "Edit product or service",
                        errorEditingProductService: "Error editing product or service",
                        productServiceEdited: "Product or service edited",
                    },
                    addProductService: {
                        pageTitle: "Product and services",
                        errorAddingProductService: "Error adding product or service",
                        productServiceAdded: "Product or service added",
                    },
                },
                productServiceCategory: {
                    productServiceCategoryList: {
                        name: "Name",
                        description: "Description",
                        parent: "Parent",
                        pageTitle: "Product services category",
                    },

                    editProductServiceCategory: {
                        pageTitle: "Edit product service category",
                        errorEditingProductService:
                            "Error editing product service category",
                        productServiceEdited: "Product service category edited",
                    },

                    addProductServiceCategory: {
                        pageTitle: "Product services category",
                        errorAddingProductService: "Error adding product service category",
                        productServiceAdded: "Product service category added",
                    },
                },
                productType: {
                    material: "Material",
                    product: "Product",
                    semiFinished: "Semi-finished product",
                },
                compositeProduct: {
                    compositeProductList: {
                        quantity: "Quantity",
                        product: "Product",
                        pageTitle: "Composite product",
                    },
                    addCompositeProductList: {
                        pageTitle: "Add composite product",
                    },
                    editCompositeProductList: {
                        pageTitle: "Edit composite product",
                    },
                },
                productCatalog: {
                    title: "Product catalog",
                    addProduct: "New product",
                },
                wooCommerce: {
                    productSettings: "Settings",
                    wooCommerceSettings: "Woo Commerce Settings",
                },
            },

            crm: {
                partner: {
                    partnerList: {
                        partnerLegalType: "Legal type",
                        pageTitle: "Partners",
                        name: "Name",
                        description: "Description",
                        registrationNumber: "Registration number",
                        taxNumber: "Tax number",
                        partnerType: "Partner type",
                        country: "Country",
                        city: "City",
                        currency: "Currency",
                        partnerDeleted: "Partner deleted",
                        account: "Account",
                        website: "Website",
                    },
                    addPartner: {
                        pageTitle: "Add partner",
                    },
                    editPartner: {
                        pageTitle: "Edit partner",
                    },
                },
                partnerContact: {
                    partnerContactList: {
                        pageTitle: "Partner Contact",
                        firstName: "First Name",
                        lastName: "Last Name",
                        email: "Email",
                        phone: "Phone",
                        partner: "Partner",
                        skype: "Skype",
                        linkedin: "Linkedin",
                        partnerContactDeleted: "Partner Contact deleted",
                    },

                    editPartnerContact: {
                        pageTitle: "Edit Partner Contact",
                        errorEditingPartnerContact: "Error editing Partner Contact",
                        partnerContactEdited: "Partner Contact edited",
                    },

                    addPartnerContact: {
                        pageTitle: "Partner Contact",
                        errorAddingPartnerContact: "Error adding Partner Contact",
                        partnerContactAdded: "Partner Contact added",
                    },
                },
                dealType: {
                    dealTypeList: {
                        pageTitle: "Deal types",
                        name: "Name",
                    },
                    editDealType: {
                        pageTitle: "Edit Deal type",
                        errorEditingDealType: "Error editing Deal type",
                        dealTypeEdited: "Deal type edited",
                    },
                    addDealType: {
                        pageTitle: "Add Deal type",
                        errorAddingDealType: "Error adding Deal type",
                        dealTypeAdded: "Deal type added",
                    },
                },
                dealSource: {
                    dealSourceList: {
                        pageTitle: "Deal sources",
                        name: "Name",
                    },
                    editDealSource: {
                        pageTitle: "Edit Deal source",
                        errorEditingDealType: "Error editing Deal source",
                        dealTypeEdited: "Deal source edited",
                    },
                    addDealSource: {
                        pageTitle: "Add Deal source",
                        errorAddingDealType: "Error adding Deal source",
                        dealTypeAdded: "Deal source added",
                    },
                },
                dealPriority: {
                    dealPriorityList: {
                        pageTitle: "Deal priority",
                        name: "Name",
                        color: "Color",
                    },
                    editDealPriority: {
                        pageTitle: "Edit Deal priority",
                        errorEditingDealPriority: "Error editing Deal priority",
                        dealPriorityEdited: "Deal priority edited",
                    },
                    addDealPriority: {
                        pageTitle: "Add Deal priority",
                        errorAddingDealType: "Error adding Deal priority",
                        dealPriorityAdded: "Deal priority added",
                    },
                },
                deal: {
                    dealList: {
                        pageTitle: "Deals",
                        name: "Name",
                        description: "Description",
                        amount: "Amount",
                        closeDate: "Close date",
                        pipeLine: "Pipeline",
                        pipeLineStage: "Pipeline stage",
                        currency: "Currency",
                        partner: "Partner",
                        dealType: "Deal type",
                        dealPriority: "Deal priority",
                        dealOwner: "Deal owner",
                        records: "Records",
                        dealSource: "Deal source",
                    },
                    editDeal: {
                        pageTitle: "Edit Deal",
                        errorEditingDeal: "Error editing Deal",
                        dealEdited: "Deal edited",
                    },
                    addDeal: {
                        pageTitle: "Add Deal",
                        errorAddingDeal: "Error adding Deal",
                        dealAdded: "Deal added",
                    },
                    dealDetails: {
                        pageTitle: "Deal details",
                        record: "Record",
                        files: "Files",
                        contacts: "Contacts",
                    },
                    dealPipelines: {
                        pageTitle: "Deal pipelines",
                    },
                },
                customer: {
                    customerList: {
                        pageTitle: "Customers",
                        firstName: "First name",
                        lastName: "Last name",
                        email: "Email",
                    },
                    addCustomer: {
                        pageTitle: "Add customer",
                    },
                    editCustomer: {
                        pageTitle: "Edit customer",
                    },
                },
                settings: {
                    settings: "Settings",
                    dealType: "Deal type",
                    dealPriority: "Deal priority",
                    dealSource: "Deal source",
                },
            },
            hr: {
                settings: {
                    settings: "Settings",
                    baseSettings: "Base settings",
                    internalHrOnly: "Internal HR only",
                    userPosition: "User position",
                    jobStatus: "Job status",
                    JobContractType: "Job contract type",
                    candidateSource: "Candidate source",
                    leaveType: "Leave type",
                    nonWorkingDays: "Non working days",
                    candidateStatus: "Candidate status",
                    businessTrip: "Business trip",
                },
                team: {
                    teamList: {
                        pageTitle: "Teams",
                        name: "Name",
                        description: "Description",
                        organisationUnit: "Organisation unit",
                        teamSettings: "Settings",
                        image: "",
                    },
                    addTeam: {
                        pageTitle: "Add team",
                    },
                    editTeam: {
                        pageTitle: "Edit team",
                    },
                    teamSettings: {
                        pageTitle: "Team Settings",
                        logo: "Change team logo",
                    },
                },
                leaveType: {
                    leaveTypeList: {
                        days: "Days",
                        pageTitle: "Leave types",
                        name: "Name",
                        subtractDays: "Subtract days",
                    },
                    addLeaveType: {
                        pageTitle: "Add leave type",
                    },
                    editLeaveType: {
                        pageTitle: "Edit leave type",
                    },
                },
                jobContractType: {
                    jobContractTypeList: {
                        pageTitle: "Job contract types",
                        name: "Name",
                    },
                    addJobContractType: {
                        pageTitle: "Add job contract type",
                    },
                    editJobContractType: {
                        pageTitle: "Edit job contract type",
                    },
                },
                jobStatus: {
                    jobStatusList: {
                        pageTitle: "Job status",
                        name: "Name",
                        icon: "Icon",
                        color: "Color",
                    },
                    addJobStatus: {
                        pageTitle: "Add job status",
                    },
                    editJobStatus: {
                        pageTitle: "Edit job status",
                    },
                },
                candidateSource: {
                    candidateSourceList: {
                        pageTitle: "Candidate sources",
                        name: "Name",
                    },
                    addCandidateSource: {
                        pageTitle: "Add candidate source",
                    },
                    editCandidateSource: {
                        pageTitle: "Edit candidate source",
                    },
                },
                nonWorkingDays: {
                    nonWorkingDaysList: {
                        pageTitle: "Non working days",
                        name: "Name",
                        fromDate: "From date",
                        toDate: "To date",
                        type: "Type",
                    },
                    addNonWorkingDay: {
                        pageTitle: "Add non working day",
                    },
                    editNonWorkingDay: {
                        pageTitle: "Edit non working day",
                    },
                },
                leaveRequest: {
                    leaveRequestList: {
                        type: "Type",
                        approve: "Approve",
                        decline: "Decline",
                        controls: "Controls",
                        employee: "Employee",
                        approvedBy: "Approved by",
                        pageTitle: "Leave requests",
                        approved: "Approved",
                        fromDate: "From date",
                        toDate: "To date",
                    },
                    addLeaveRequest: {
                        pageTitle: "Add leave request",
                    },
                    editLeaveRequest: {
                        pageTitle: "Edit leave request",
                    },
                },
                organisationUserContract: {
                    organisationUserContractList: {
                        pageTitle: "Employee contracts",
                        name: "Name",
                        type: "Type",
                        startDate: "Start date",
                        endDate: "End date",
                        net: "Net",
                        gross: "Gross",
                        user: "User",
                        employee: "Employee",
                        contractParts: "Contract parts",
                        generateParts: "Generate parts",
                        currency: "Currency",
                    },
                    addOrganisationUserContract: {
                        pageTitle: "Add employee contract",
                    },
                    editOrganisationUserContract: {
                        pageTitle: "Edit employee contract",
                    },
                },
                leave: {
                    leaveList: {
                        type: "Type",
                        pageTitle: "Leaves",
                        fromDate: "From date",
                        toDate: "To date",
                        employeeUser: "Employee",
                    },
                    addLeave: {
                        pageTitle: "Add leave",
                    },
                    editLeave: {
                        pageTitle: "Edit leave",
                    },
                },

                job: {
                    jobCandidates: {
                        pageTitle: "Job candidates",
                        remote: "Remote",
                        salary: "Salary",
                        min: "Min",
                        max: "Max",
                        jobType: "Job type",
                        notRemote: "From office",
                        salaryMin: "Salary min",
                        salaryMax: "Salary max",
                    },
                    jobList: {
                        jobCandidates: "Job candidates",
                        pipeline: "Pipeline",
                        pageTitle: "Jobs",
                        name: "Name",
                        description: "Description",
                        headCount: "Head count",
                        isRemote: "Is remote",
                        location: "Location",
                        minNetSalary: "Min neto salary",
                        minGrossSalary: "Min gross salary",
                        maxNetSalary: "Max neto salary",
                        maxGrossSalary: "Max gross salary",
                        paymentFrequency: "Payment frequency",
                        user: "User",
                        jobStatus: "Job status",
                        jobContractType: "Job contract type",
                        userPosition: "User position",
                        currency: "Currency",
                        partner: "Partner",
                        selectionProcess: "Selection process",
                        afterSubmit: "After submit",
                        link: "Link",
                    },
                    addJob: {
                        pageTitle: "Add job",
                    },
                    editJob: {
                        pageTitle: "Edit job",
                    },
                    addApplication: {
                        pageTitle: "Apply for position",
                        alreadySubmitted:
                            "You already submitted your application for this job",
                        fileRequired: "You need to attach at least one file",
                    },
                },
                userPosition: {
                    userPositionList: {
                        pageTitle: "User positions",
                        name: "Name",
                        parent: "Parent Position",
                    },
                    addUserPosition: {
                        pageTitle: "Add user position",
                    },
                    editUserPosition: {
                        pageTitle: "Edit user position",
                    },
                },
                organisationUserContractPart: {
                    organisationUserContractPartList: {
                        pageTitle: "Employee contract parts",
                        year: "Year",
                        month: "Month",
                        net: "Net",
                        gross: "Gross",
                        paymentDate: "Payment date",
                        user: "User",
                        organisationUserContract: "Organisation user contract",
                        currency: "Currency",
                    },
                    editOrganisationUserContractPart: {
                        pageTitle: "Edit organisation user contract part",
                    },
                    addOrganisationUserContractPart: {
                        pageTitle: "Add orgsanisation user contract part",
                    },
                },
                candidate: {
                    candidateRecord: {
                        pageTitle: "Candidate record",
                    },
                    candidateDetails: {
                        pageTitle: "Candidate details",
                        currentCompany: "Current company",
                        currentSalary: "Current salary",
                        expectedSalary: "Expected salary",
                        education: "Education",
                        candidateSource: "Source",
                        currentPosition: "Current position",
                        record: "Record",
                        files: "Files",
                    },
                    candidateList: {
                        alreadyApplied: "Already applied",
                        pageTitle: "Candidates",
                        firstName: "First name",
                        lastName: "Last name",
                        email: "Email",
                        location: "Location",
                        phone: "Phone",
                        description: "Description",
                        currentPosition: "Current position",
                        currentCompany: "Current comapny",
                        currentSalary: "Current salary",
                        expectedSalary: "Expected salary",
                        birthday: "Birthday",
                        education: "Education",
                        job: "Job",
                        candidateSource: "Candidate source",
                        responsibleHrManager: "Responsible HR Manager",
                        pipelineStage: "Pipeline Stage",
                        dateCreated: "Date Applied",
                        candidateStatus: "Candidate status",
                        records: "Records",
                        noPreviousExperience: "No previous experience",
                    },
                    addCandidate: {
                        pageTitle: "Add candidate",
                    },
                    editCandidate: {
                        pageTitle: "Edit candidate",
                    },
                },
                additionalHours: {
                    additionalHoursList: {
                        pageTitle: "Additional hours",
                        startDate: "Start date",
                        endDate: "End date",
                        status: "Status",
                        hours: "Hours",
                        user: "User",
                        organisationUserContract: "Organisation user contract",
                        controls: "Controls",
                        approve: "Approve",
                        rejecte: "Rejecte",
                    },
                    addAdditionalHours: {
                        pageTitle: "Add additional hours",
                    },
                    editAdditionalHours: {
                        pageTitle: "Edit additional hours",
                    },
                },
                businessTrip: {
                    businessTrip: {
                        pageTitle: "Business trip",
                    },
                    businessTripInformation: {
                        decision: "Decision",
                        travelWarrant: "Travel Warrant",
                        calculation: "Calculation",
                    },
                    businessTripForm: {
                        pageTitle: "Add document",
                        documentName: "Document name",
                        foreignDailyWageCurrency: "Foreign daily wage currency",
                        domesticDailyWage: "Domestic daily wage",
                        foreignDailyWage: "Foreign daily wage",
                    },
                    addBusinessTrip: {
                        pageTitle: "Add business trip",
                    },
                    decisionForm: {
                        reasonToTravel: "Reason to travel",
                        travelDestination: "Travel destination",
                        dayOfDeparture: "Day of departure",
                        tripDuration: "Trip duration",
                        meansOfTransport: "Means of transport",
                        freeAccommodationAndFood: "Free accommodation and food",
                        amountOfDailyWageInCountry: "Amount of daily wage in country",
                        amountOfDailyWageAbroad: "Amount of daily wage abroad",
                        advancePaymentInDomesticCurrency:
                            "Advance payment in domestic currency",
                        advancePaymentInForeignCurrency:
                            "Advance payment in foreign currency",
                        employee: "Employee",
                    },
                    travelWarrantForm: {
                        reasonToTravel: "Reason to travel",
                        travelDestination: "Travel destination",
                        dayOfDeparture: "Day of departure",
                        tripDuration: "Trip duration",
                        meansOfTransport: "Means of transport",
                        freeAccommodationAndFood: "Free accommodation and food",
                        amountOfDailyWageInCountry: "Amount of daily wage in country",
                        amountOfDailyWageAbroad: "Amount of daily wage abroad",
                        advancePaymentInDomesticCurrency:
                            "Advance payment in domestic currency",
                        advancePaymentInForeignCurrency:
                            "Advance payment in foreign currency",
                        employee: "Employee",
                    },
                    addDecision: {
                        pageTitle: "Add decision",
                    },
                    editDecision: {pageTitle: "Edit decision"},
                    addTravelWarrant: {
                        pageTitle: "Add travel warrant",
                    },
                    editTravelWarrant: {pageTitle: "Edit travel warrant"},
                    calculationForm: {
                        name: "Name",
                        amount: "Amount",
                        startTrip: "Start trip",
                        borderCrossingDeparture: "Border crossing departure",
                        borderCrossingArrival: "Border crossing arrival",
                        endTrip: "End trip",
                        paidExpensesViaBusinessCardDomesticCurrency:
                            "Paid expenses via business card with domestic currency",
                        paidExpensesViaBusinessCardInForeignCurrency:
                            "Paid expenses via business card in foreign currency",
                        paidExpensesWithRemittancesFromCurrentAccount:
                            "Paid expenses with remittances from current account",
                        paidExpensesWithRemittancesFromForeignCurrentAccount:
                            "Paid expenses with remittances from foreign currency account",
                        otherAmountInDomesticCurrencyDeducted:
                            "Other amount in domestic currency deducted",
                        otherAmountInForeignCurrencyDeducted:
                            "Other amount in foreign currency deducted",
                        exchangeRate: "Exchange rate",
                        description: "Description",
                    },

                    addCalculation: {
                        pageTitle: "Add calculation",
                        dateTimeError: "Enter a valid date",
                    },
                    editCalculation: {pageTitle: "Edit calculation"},
                },

                candidateStatus: {
                    candidateStatusList: {
                        pageTitle: "Candidate status",
                        name: "Name",
                        color: "Color",
                    },
                    addCandidateStatus: {
                        pageTitle: "Add candidate status",
                    },
                    editCandidateStatus: {
                        pageTitle: "Edit candidte status",
                    },
                },
                organisationUnit: {
                    organisationUnitList: {
                        pageTitle: "Organisation units",
                        name: "Name",
                        parent: "Parent",
                    },
                    addOrganisationUnit: {
                        pageTitle: "Add organisation unit",
                    },
                    editOrganisationUnit: {
                        pageTitle: "Edit organisation unit",
                    },
                },
                employee: {
                    records: "Records",
                    statistic: "Statistic",
                    editEmployee: {
                        pageTitle: "Edit employee",
                    },
                },
                presence: {
                    presenceList: {
                        pageTitle: "Presence",
                        start: "Start",
                        end: "End",
                        changeRequested: "Change requested",
                        changeApproved: "Change approved",
                        newStart: "New start",
                        newEnd: "New end",
                        newDeleted: "New deleted",
                        employee: "Employee",
                        controls: "Controls",
                    },
                    addPresence: {
                        pageTitle: "Add Presence",
                    },
                    editPresence: {
                        pageTitle: "Edit Presence",
                    },
                },
            },
            workspaces: {
                workspacesList: {
                    name: "Name",
                    type: "Type",
                    description: "Description",
                    pipline: "Pipeline",
                    image: "",
                    workspaceSettings: "Settings",
                    pageTitle: "Workspaces",
                },

                editWorkspaces: {
                    pageTitle: "Edit Workspace",
                },
                addWorkspaces: {
                    pageTitle: "Add Workspace",
                },
                workspacesSettings: {
                    pageTitle: "Workspace settings",
                    logo: "Change workspace logo",
                    issueType: "Issue types",
                    changeLogo: "Change logo",
                    access: "Workspace access",
                },
                addWorkspaceIssueType: {
                    pageTitle: "Add Issue Type",
                },
                editWorkspaceIssueType: {
                    pageTitle: "Edit Issue Type",
                },
                workspaceAccess: {
                    pageTitle: "Access",
                },
            },
            boards: {
                boardsList: {
                    deleteDialogMessage: "Are you sure you want to delete this board?",
                    addButtonText: "Add Board",
                },
                addBoards: {
                    pageTitle: "Add Board",
                },
                editBoards: {
                    pageTitle: "Edit Board",
                },
                changeBoardLogo: {
                    pageTitle: "Change logo",
                },
                boardView: {
                    createIssues: "Create Issue",
                    deleteDialogMessage: "Are you sure you want to delete this issue?",
                    label: {
                        assignee: "Assignee",
                        issueType: "Issue Type",
                        isArchived: "Is Archived",
                    },
                    placeholder: {
                        assignee: "Select",
                        issueType: "Select",
                        isArchived: "Select",
                    },
                    addBoardIssue: {
                        pageTitle: "Create Issue",
                    },
                    editBoardIssue: {
                        pageTitle: "Edit Issue",
                    },
                    issueView: {
                        description: "Description",
                        details: "Details",
                        assignee: "Assignee",
                        priority: "Priority",
                        reporter: "Reporter",
                        created: "Created",
                        updated: "Updated",
                        files: "Files",
                        project: "Project:",
                        linkedIssues: "Linked Issues",
                        searchForIssues: "Search for issues",
                        createLinkedIssue: "Create linked issue",
                    },
                    activity: {
                        activity: "Activity",
                        comments: "Comments",
                        timeLog: "Time log",
                        addProjectTimeLog: "Add project time log",
                        goToTimeLog: "Go to time log",
                        pdfPreview: "PDF Preview",
                    },
                },
            },
            statistic: {
                dashboard: {
                    pageTitle: "Dashboard",
                    finance: "Finance",
                    hr: "HR",
                },
                hrDashboard: {
                    employeeContractsNet: "Employee contracts(Net)",
                    employeeContractsGross: "Employee contracts(Gross)",
                    employees: "Employees",
                    workingDays: "Working days",
                    nonWorkingDays: "Non working days",
                    averagePaymentNet: "Average payment - net",
                    averagePaymentGross: "Average payment - gross",
                    totalWorkingDays: "Total working days",
                    totalWorkingDaysCapacity: "Total working days capacity",
                    totalWorkingHours: "Total working hours",
                    totalWorkingHoursCapacity: "Total working hours capacity",
                },
                financeDashboard: {
                    invoicesPerCurrency: "Invoices - per currency",
                    invoicesInDefaultCurrency: "Invoices - default currency",
                    billsPerCurrency: "Bills - per currency",
                    billsDefaultCurrency: "Bills - default currency",
                    invoices: "Invoices",
                    bills: "Bills",
                    income: "Income",
                    outcome: "Outcome",
                    contractAmountCustomer: "Contract amount customer",
                    contractAmountSupplier: "Contract amount supplier",
                    contractActualAmountCustomer: "Contract actual amount customer",
                    contractActualAmountSupplier: "Contract actual amount supplier",
                    invoicesLast12Months: "Invoice (12 months)",
                    employeeContractsNet: "Employee contracts(Net)",
                    employeeContractsGross: "Employee contracts(Gross)",
                    tax: "Tax",
                    in: "In",
                    out: "Out",
                    taxOut: "Tax - out",
                    taxIn: "Tax - in",
                },
            },
            finance: {
                settings: {
                    settings: "Settings",
                    bankAccounts: "Bank accounts",
                    invoiceSettings: "Invoice settings",
                    transactionCategories: "Transaction categories",
                    businessYear: "Business year",
                    businessYearStartValue: {
                        pageTitle: "Start values",
                    },
                    contractStatus: "Contract status",
                    contractPartStatus: "Contract part status",
                },
                invoice: {
                    acceptRejectInvoice: {
                        pageTitleAccept: "Accept",
                        pageTitleReject: "Reject",
                    },
                    stornoInvoice: {
                        pageTitle: "Storno invoice",
                    },
                    cancelInvoice: {
                        pageTitle: "Cancel invoice",
                    },
                    invoiceList: {
                        storno: "Storno",
                        cancel: "Cancel",
                        send: "Send",
                        reject: "Reject",
                        accept: "Accept",
                        eInvoiceControls: "E-Invoice controls",
                        eInvoiceStatus: "E-Invoice status",
                        syncEInvoice: "Sync E-Invoice",
                        paymentOverdue: "Payment overdue",
                        invoices: "Invoices",
                        bills: "Bills",
                        invoiceNumber: "Invoice number",
                        invoiceStatus: "Invoice status",
                        paidAmount: "Paid amount",
                        invoiceType: "Invoice type",
                        partner: "Partner",
                        invoiceDate: "Invoice date",
                        paymentDueDate: "Payment due date",
                        lastPaymentDate: "Last payment date",
                        amount: "Amount",
                        taxAmount: "Tax amount",
                        total: "Total",
                        currency: "Currency",
                        duplicate: "Duplicate",
                        balance: "Balance",
                        project: "Project",
                        exchangeRate: "Exchange rate",
                        download: "Download",
                    },
                    createInvoice: {
                        invoice: "Invoice",
                        address: "Address",
                        city: "City",
                        taxNumber: "Tax number",
                        registrationNumber: "Registration number",
                        bankAccount: "Bank account",
                        swift: "SWIFT",
                        phone: "Phone",
                        email: "Email",
                        website: "Website",
                        invoiceNumber: "Invoice number",
                        invoiceDate: "Invoice date",
                        deliveryDate: "Delivery date",
                        invoicePlace: "Invoice place",
                        dueDate: "Due date",
                        errorSaveInvoice: "Error saving invoice",
                        errorAddingInvoice: "Error adding invoice",
                        invoiceAdded: "Invoice added",
                        invoiceSaved: "Invoice saved",
                        saved: "Saved",
                        errorSaving: "Error saving",
                        domesticBillTo: "Bill to:",
                        billTo: "Bill to:",
                        showMore: "More",
                        showLess: "Less",
                        noRows: "No rows",
                    },
                    editInvoiceItem: {
                        pageTitle: "Edit invoice item",
                    },
                },
                transactionCategory: {
                    transactionCategoryList: {
                        pageTitle: "Transaction Category",
                        name: "Name",
                        parent: "Parent",
                        company: "Company",
                    },
                    addTransactionCategory: {
                        pageTitle: "Add Transaction Category",
                    },
                    editTransactionCategory: {
                        pageTitle: "Edit Transaction Category",
                    },
                },
                bankReport: {
                    bankReportList: {
                        bank: "Bank",
                        bankAccount: "Bank Account",
                        currency: "Currency",
                        businessYear: "Business Year",
                        report: "Report ID",
                        reportNumber: "Report Number",
                        reportDate: "Report Date",
                        amountBefore: "Amount Before",
                        amountAfter: "Amount After",
                        income: "Income",
                        outcome: "Outcome",
                        company: "Company",
                        bankReportDeleted: "Bank Report Is Deleted",
                        pageTitle: "Bank report",
                    },

                    editBankReport: {
                        pageTitle: "Edit Bank Report",
                        errorEditingBankReport: "Error editing Bank Report",
                        bankReportEdited: "Bank Report edited",
                    },

                    addBankReport: {
                        pageTitle: "Add Bank Report",
                        bankReportAdded: "Bank Report Added",
                        errorAddingBankReport: "Error adding Bank Report",
                    },
                },
                cashFlow: {
                    cashFlow: "Cash flow",
                    description: "Description",
                    recalculate: "Recalculate",
                    currentValues: "Current values",
                    pageTitle: "Cash flow",
                },
                contract: {
                    contractList: {
                        contractNumber: "Contract number",
                        contractParts: "Contract parts",
                        pageTitle: "Contracts",
                        name: "Name",
                        description: "Description",
                        startDate: "Start date",
                        endDate: "End date",
                        contractType: "Contract type",
                        owner: "Owner",
                        currency: "Currency",
                        contractDeleted: "Contract deleted",
                        amount: "Amount",
                        amountWithTax: "Amount with tax",
                        actualAmount: "Actual amount",
                        canReturnTax: "Can return tax",
                        tax: "Tax",
                        transactionCategory: "Transaction category",
                        contractPartCount: "Contract part count",
                        contractPartAmount: "Contract part amount",
                        partner: "Partner",
                        project: "Project",
                        contractStatus: "Contract status",
                        generateInvoice: "Generate invoice",
                    },
                    editContract: {
                        pageTitle: "Edit Contract",
                        errorEditingContract: "Error editing contract",
                        contractEdited: "Contract edited",
                    },
                    addContract: {
                        pageTitle: "Add Contract",
                        errorAddingContract: "Error adding contract",
                        contractAdded: "Contract added",
                    },
                    generateInvoice: {
                        pageTitle: "Generate invoice",
                    },
                },

                contractPart: {
                    contractPartList: {
                        pageTitle: "Contract parts",
                        description: "Description",
                        startDate: "Start date",
                        endDate: "End date",
                        paymentDate: "Payment date",
                        actualPaymentDate: "Actual payment date",
                        contractPart: "Contract Part",
                        generateParts: "Generate Parts",
                        contractType: "Contract Type",
                        amount: "Amount",
                        amountWithTax: "Amount With Tax",
                        actualAmount: "Actual Amount",
                        actualAmountWithTax: "Actual Amount With Tax",
                        invoiceDate: "Invoice date",
                        contractPartDeleted: "Contract part deleted",
                        paid: "Paid",
                        contractPartItems: "Items",
                        generateInvoice: "Generate invoice",
                        contractPartStatus: "Contract part status",
                    },
                    editContractPart: {
                        errorEditingContractPart: "Error editing contract part",
                        contractPartEdited: "Contract part edited",
                        pageTitle: "Edit contract part",
                    },
                    addContractPart: {
                        pageTitle: "Add contract part",
                        errorAddingContractPart: "Error adding contract part",
                        contractPartAdded: "Contract part added",
                    },
                },

                contractPartItems: {
                    contractPartItemsList: {
                        pageTitle: "Contract part items",
                        name: "Name",
                        unit: "Unit",
                        quantity: "Quantity",
                        price: "Price",
                        amount: "Amount",
                        discount: "Discount",
                        amountWithDiscount: "Amount with discount",
                        vat: "VAT",
                        amountWithVAT: "Amount with VAT",
                    },
                    editContractPart: {
                        errorEditingContractPart: "Error editing contract part item",
                        contractPartEdited: "Contract part item edited",
                        pageTitle: "Edit contract part item",
                    },
                    addContractPartItem: {
                        pageTitle: "Add contract part item",
                        errorAddingContractPart: "Error adding contract part item",
                        contractPartAdded: "Contract part item added",
                    },
                },

                bankAccount: {
                    bankAccountList: {
                        account: "Account",
                        bank: "Bank",
                        name: "Name",
                        swift: "SWIFT",
                        iban: "IBAN",
                        currency: "Currency",
                    },
                    addBankAccount: {
                        pageTitle: "Add bank account",
                    },
                    editBankAccount: {
                        pageTitle: "Edit bank account",
                    },
                },
                contractStatus: {
                    contractStatusList: {
                        name: "Name",
                    },
                    addContractStatus: {
                        pageTitle: "Add contract status",
                    },
                    editContractStatus: {
                        pageTitle: "Edit contract status",
                    },
                },
                contractPartStatus: {
                    contractPartStatusList: {
                        name: "Name",
                    },
                    addContractPartStatus: {
                        pageTitle: "Add contract part status",
                    },
                    editContractPartStatus: {
                        pageTitle: "Edit contract part status",
                    },
                },
                transactions: {
                    transactionList: {
                        approve: "Approve",
                        bankReport: "Bank report",
                        pageTitle: "Transactions",
                        paymentType: "Payment type",
                        partnerName: "Partner name",
                        description: "Description",
                        paymentDate: "Payment date",
                        income: "Income",
                        outcome: "Outcome",
                        currency: "Currency",
                        employee: "Employee",
                        partner: "Partner",
                        contract: "Contract",
                        contractPart: "Contract part",
                        invoice: "Invoice",
                        transactionCategory: "Transaction category",
                        transactionType: "Transaction type",
                        tax: "Tax",
                        canReturnTax: "Can return tax",
                        transactionSplit: "Transaction Split",
                        parent: "Parent",
                        bank: "Bank",
                    },
                    editTransaction: {
                        pageTitle: "Edit Transaction",
                        errorEditingTransaction: "Error editing Transaction",
                        transactionEdited: "Transaction edited",
                    },
                    addTransaction: {
                        pageTitle: "Add Transaction",
                        errorAddingTransaction: "Error adding Transaction",
                        transactionAdded: "Transaction added",
                    },
                    transactionSplit: {
                        pageTitle: "Transaction Split",
                        errorTransactionSplitSumAmount:
                            "Transaction Split Amount Sum, is not equal with transaction amount",
                        errorTransactionSplitSumAmountNull:
                            "Transaction Split Amount Sum, must greater then null",
                        transactionSplitError: "Transaction Split Error",
                        successTransactionSplit: "Transaction Split Success",
                        errorTransactionSplitAmountNull:
                            "Transaction Split Amount, must be greater then null",
                    },
                },
                uploadBankReport: {
                    uploadBankReportList: {
                        pageTitle: "Upload bank report",
                    },
                },
            },
            project: {
                projectSettings: "Project settings",
                settings: "Settings",
                details: "Details",
                projectStatistic: {
                    year: "Year",
                    pageTitle: "Project statistic",
                    income: "Income: ",
                    outcome: "Outcome: ",
                    byAverageHourRate: "By hour average rate: ",
                    byHourRate: "By hour rate: ",
                    profitByAverageHourRate: "Profit by average hour rate",
                    profitByHourRate: "Profit by hour rate",
                    profitByAverageHourRateTotal: "Profit by average hour rate (total)",
                    profitByHourRateTotal: "Profit by hour rate (total)",
                    profit: "Profit",
                    invoicesAndBills: "Invoices and bills",
                    invoices: "Invoices",
                    bills: "Bills",
                    costByAverageHourRate: "Cost by average hour rate",
                    costByHourRate: "Cost by hour rate",
                    billableHours: "Billable hours",
                    hours: "Time logged",
                    total: "Total: ",
                    billable: "Billable: ",
                    notBillable: "Not billable: ",
                    timeCost: "Time cost",
                    hourRatePerMonth: "Hour rate per month",
                    hourRateAverageOfHourRatePerYear:
                        "Hour rate average of hour rate per year",
                    totalProfit: "Total profit: ",
                },
                project: {
                    projectList: {
                        pageTitle: "Projects",
                        name: "Project Name",
                        startDate: "Start date",
                        endDate: "End date",
                        description: "Description",
                        projectCategory: "Project Category",
                        company: "Company",
                        projectDeleted: "Project - deleted",
                        partner: "Partner",
                    },
                    projectDetails: {
                        pageTitle: "Project details",
                        currentCompany: "Current company",
                        projectCategory: "Project category",
                        projectResource: "Project resource",
                        projectResourceType: "Project resource type",
                        taskCategory: "Task category",
                        record: "Record",
                        files: "Files",
                        statistic: "Statistic",
                        timeLog: "Time Log",
                        projectAccess: "Access",
                        projectTaskCategories: "Task categories",
                    },
                    addProject: {
                        pageTitle: "Add Project",
                    },
                    editProject: {
                        pageTitle: "Edit Project",
                    },
                },
                projectResource: {
                    projectResourceList: {
                        pageTitle: "Project Resources",
                        name: "Resource Name",
                        description: "Description",
                        price: "Price",
                        date: "Date",
                        currency: "Currency",
                        project: "Project",
                        projectResourceType: "Project Resource Type",
                        type: "Resource Type",
                        invoice: "Invoice number",
                    },
                    addProjectResource: {
                        pageTitle: "Add Project Resource",
                    },
                    editProjectResource: {
                        pageTitle: "Edit Project Resource",
                    },
                },
                projectCategory: {
                    projectCategoryList: {
                        pageTitle: "Project categories",
                        name: "Category Title",
                        parent: "Parent",
                    },
                    addProjectCategory: {
                        pageTitle: "Add Project Category",
                    },
                    editProjectCategory: {
                        pageTitle: "Edit Project Category Name",
                    },
                },
                projectResourceType: {
                    projectResourceTypeList: {
                        pageTitle: "Resource Types",
                        name: "Resource Title",
                        parent: "Parent",
                    },
                    addProjectResourceType: {
                        pageTitle: "Add Resource Type",
                    },
                    editProjectResourceType: {
                        pageTitle: "Edit Resource Type Name",
                    },
                },
                taskCategory: {
                    taskCategoryList: {
                        pageTitle: "Task categories",
                        name: "Name",
                        color: "Color",
                    },
                    addTaskCategory: {
                        pageTitle: "Add task category",
                    },
                    editTaskCategory: {
                        pageTitle: "Edit task category",
                    },
                },
                projectRecord: {
                    pageTitle: "Project record",
                },
                projectAccess: {
                    pageTitle: "Project accesses",
                },
                projectTaskCategory: {
                    pageTitle: "Project task categories",
                    billable: "Billable",
                    allowed: "Allowed",
                    taskCategory: "Task category",
                },
                projectTimeLog: {
                    projectTimeLogList: {
                        pageTitle: "Project time log",
                        dateLogged: "Date Logged",
                        hoursLogged: "Hours Logged",
                        employee: "Employee",
                        project: "Project",
                        projectTimeLogDeleted: "Project Time Log - deleted",
                        taskCategory: "Task Category",
                        billable: "Billable",
                        name: "Description",
                    },
                    addProjectTimeLog: {
                        pageTitle: "Add Project Time Log",
                        projectTimeLogAdded: "Project Time Log Added",
                        errorAddingProjectTimeLog: "Error adding Project Time Log",
                    },
                    editProjectTimeLog: {
                        pageTitle: "Edit Project Time Log",
                        errorEditingProjectTimeLog: "Error editing Project Time Log",
                        projectTimeLogEdited: "Project Time Log edited",
                    },
                    projectTimeLogStatistic: {
                        workingHours: "Working hours",
                        timeLogged: "Time logged",
                        billableTimeLogged: "Billable time logged",
                    },
                },
            },
            assetManagement: {
                vendor: {
                    vendorList: {
                        pageTitle: "Vendors",
                        name: "Vendor name",
                    },
                    addVendor: {
                        pageTitle: "Add vendor",
                    },
                    editVendor: {
                        pageTitle: "Edit vendor",
                    },
                },
                assetType: {
                    assetTypeList: {
                        pageTitle: "Asset types",
                        name: "Name",
                        price: "Price",
                        vendor: "Vendor",
                        category: "Category",
                        tax: "Tax",
                    },
                    addAssetType: {
                        pageTitle: "Add type",
                    },
                    editAssetType: {
                        pageTitle: "Edit type",
                    },
                },
                assetCategory: {
                    assetCategoryList: {
                        pageTitle: "Asset categories",
                        name: "Category name",
                        parent: "Parent",
                    },
                    addAssetCategory: {
                        pageTitle: "Add category",
                    },
                    editAssetCategory: {
                        pageTitle: "Edit category",
                    },
                },
                inventoryCategory: {
                    inventoryCategoryList: {
                        pageTitle: "Inventory Category",
                        name: "Name",
                        parent: "Parent",
                    },

                    editInventoryCategory: {
                        pageTitle: "Edit Inventory Category",
                        errorEditingInventoryCategory: "Error editing Inventory Category",
                        inventoryCategoryEdited: "Inventory Category edited",
                    },

                    addInventoryCategory: {
                        pageTitle: "Add Inventory Category",
                        errorAddingInventoryCategory: "Error adding Inventory Category",
                        inventoryCategoryAdded: "Inventory Category added",
                    },
                },
                asset: {
                    assetList: {
                        pageTitle: "Assets",
                        serialNumber: "Serial Number",
                        price: "Price",
                        tax: "Tax",
                        purchasedDate: "Purchased Date",
                        warrantyEnd: "Warranty End",
                        location: "Asset Location",
                        type: "Asset Type",
                        user: "Employee",
                        company: "Company",
                    },

                    editAsset: {
                        pageTitle: "Edit Asset",
                        errorEditingAsset: "Error editing Asset",
                        assetEdited: "Asset edited",
                    },

                    addAsset: {
                        pageTitle: "Add Asset",
                        errorAddingAsset: "Error adding Asset",
                        assetAdded: "Asset added",
                    },
                },

                inventoryType: {
                    inventoryTypeList: {
                        pageTitle: "Inventory Type",
                        name: "Name",
                        description: "Description",
                        image: "Image",
                        canReturnTax: "Can Return Tax",
                        category: "Category",
                        company: "Company",
                    },

                    editInventoryType: {
                        pageTitle: "Edit Inventory Type",
                        errorEditingInventoryType: "Error editing Inventory Type",
                        inventoryTypeEdited: "Inventory Type edited",
                    },

                    addInventoryType: {
                        pageTitle: "Add Inventory Type",
                        errorAddingInventoryType: "Error adding Inventory Type",
                        inventoryTypeAdded: "Inventory Type added",
                    },
                },

                inventory: {
                    inventoryList: {
                        pageTitle: "Inventory",
                        value: "Value",
                        quantity: "Quantity",
                        lowStockTrashold: "Low Stock Trashold",
                        location: "Location",
                        type: "Type",
                        company: "Company",
                    },

                    editInventory: {
                        pageTitle: "Edit Inventory",
                        errorEditingInventory: "Error editing Inventory",
                        inventoryEdited: "Inventory edited",
                    },

                    addInventory: {
                        pageTitle: "Add Inventory",
                        errorAddingInventory: "Error adding Inventory",
                        inventoryAdded: "Inventory added",
                    },
                },

                stockChange: {
                    stockChangeList: {
                        pageTitle: "Stock Change",
                        type: "Type",
                        price: "Price",
                        tax: "Tax",
                        quantity: "Quantity",
                        stockChangeDate: "Stock Change Date",
                        inventory: "Inventory",
                        company: "Company",
                    },

                    editStockChange: {
                        pageTitle: "Edit Stock Change",
                        errorEditingStockChange: "Error editing Stock Change",
                        stockChangeEdited: "Stock Change edited",
                    },

                    addStockChange: {
                        pageTitle: "Add Stock Change",
                        errorAddingStockChange: "Error adding Stock Change",
                        stockChangeAdded: "Stock Change added",
                    },
                },
            },
            space: {
                pageTitle: "Spaces",
                name: "Name",
                visible: "Visible",
                addDocument: {
                    pageTitle: "Add document",
                },
                editDocument: {
                    pageTitle: "Edit document",
                },
                copyLink: {
                    copyLink: "Copy link",
                },
            },
            document: {
                documentStatus: {
                    documentStatusList: {
                        pageTitle: "File statuses",
                        name: "Name",
                        color: "Color",
                    },
                    addDocumentStatus: {
                        pageTitle: "Add File Status",
                    },
                    editDocumentStatus: {
                        pageTitle: "Edit File Status",
                    },
                },
                documentCategory: {
                    documentCategoryList: {
                        pageTitle: "Document categories",
                        name: "Document category name",
                    },
                    addDocumentCategory: {
                        pageTitle: "Add File Category",
                    },
                    editDocumentCategory: {
                        pageTitle: "Edit File Category",
                    },
                },
                document: {
                    documentList: {
                        pageTitle: "Files",
                        docType: "File type",
                        name: "Name",
                        dateCreated: "Date created",
                        documentDeleted: "File deleted",
                        controls: "Controls",
                        documentStatus: "File status",
                        documentCategory: "File category",
                        comments: "Comments",
                        documentComments: "Document comments",
                    },
                    addDocument: {
                        pageTitle: "Add File",
                        submitDocumentForm: "Submit",
                        fileRequired: "You need to attach at least one file",
                    },
                    editDocument: {
                        pageTitle: "Edit File",
                    },
                    pdfPreview: {
                        pageTitle: "Document preview",
                        download: "Download",
                        zoomIn: "Zoom in",
                        zoomOut: "Zoom out",
                    },
                },
            },
            checkList: {
                title: "Check list",
                checkListTemplate: {
                    pageTitle: "Check list templates",
                },
                scheduleCheck: {
                    pageTitle: "Schedule Check",
                    scheduledCheckStatus: {
                        pending: "Pending",
                        inProgress: "In progress",
                        done: "Done",
                        canceled: "Canceled",
                    },
                    scheduleCheckList: {
                        cancel: "Cancel",
                    },
                },
                table: {
                    name: "Name",
                    employee: "Employee",
                    partner: "Partner",
                    location: "Location",
                    checkListTemplate: "Template",
                    dateFrom: "Date from",
                    dateTo: "Date to",
                    status: "Status",
                    doCheck: "Do check",
                    map: 'Map'
                },
                doCheck: {
                    doCheck: "Do check",
                    scheduledCheck: "Scheduled check",
                },
                viewCheckAnswers: {
                    next: "Next",
                    submit: "Submit",
                },
            },
            companyDocument: {
                companyDocumentCategory: {
                    companyDocumentCategoryList: {
                        pageTitle: "Company document categories",
                        name: "Category name",
                        parent: "Parent",
                    },
                    addCompanyDocumentCategory: {
                        pageTitle: "Add Company document category",
                    },
                    editCompanyDocumentCategory: {
                        pageTitle: "Edit Company document category",
                    },
                    createNewCategory: "Create new category",
                    addCategory: "Add category",
                },
                companyDocumentTemplate: {
                    newTemplate: "New template",
                    templateDetails: "Template details",
                    companyDocumentTemplateList: {
                        pageTitle: "Company document templates",
                        name: "Template name",
                        companyDocumentCategory: "Category name",
                        content: "Content",
                        items: "Items",
                    },
                    addCompanyDocumentTemplate: {
                        pageTitle: "Add Company document template",
                    },
                    editCompanyDocumentTemplate: {
                        pageTitle: "Edit template name",
                    },
                    companyDocumentTemplateQuestion: {
                        noQuestions: "no questions created",
                        newQuestion: "New question",
                        question: "Question",
                        explanation: "Explanation",
                        code: "Code",
                        questionType: "Question type",
                        required: "Required",
                        placeholders: {
                            question: "Enter question",
                            explanation: "Enter comment",
                            search: "Search",
                        },
                        addQuestionOptions: "Question options",
                        addQuestionOption: "Add option",
                        editQuestionOption: "Edit option",
                        newQuestionOption: "New option",
                        newQuestionOptions: "Add options",
                        editQuestionOptions: "Edit options",
                    },
                    companyDocumentTemplateStep: {
                        back: "Back",
                        newStep: "New Step",
                        next: "Next",
                    },
                    companyDocumentTemplateContent: {
                        save: "Save",
                    },
                },
                companyDocumentTemplateItem: {
                    companyDocumentTemplateItemList: {
                        pageTitle: "Company document template items",
                        name: "Name",
                        code: "Code",
                        type: "Type",
                        referenceValueType: "Reference value type",
                        referenceType: "Reference type",
                    },
                    addCompanyDocumentTemplateItem: {
                        pageTitle: "Add Company document template item",
                    },
                    editCompanyDocumentTemplateItem: {
                        pageTitle: "Edit template item name",
                    },
                },
                companyDocument: {
                    companyDocumentList: {
                        name: "Name",
                        description: "Description",
                        pageTitle: "Company documents",
                        category: "Category",
                        template: "Template",
                        fill: "Fill",
                        view: "View",
                        download: "Download",
                        content: "Content",
                    },
                    newCompanyDocument: "Create new document",
                    addCompanyDocument: {
                        pageTitle: "Add Company document",
                    },
                    editCompanyDocument: {
                        pageTitle: "Edit company document",
                    },
                    fillCompanyDocument: {
                        pageTitle: "Fill template",
                    },
                    companyDocumentFolders: {
                        addFolder: "Add folder",
                    },
                },
            },
            administration: {
                location: {
                    locationList: {
                        pageTitle: "Locations",
                        name: "Name",
                        description: "Description",
                        parent: "Parent",
                    },

                    editLocation: {
                        pageTitle: "Edit Location",
                        errorEditingLocation: "Error editing Location",
                        locationEdited: "Location edited",
                    },

                    addLocation: {
                        pageTitle: "Add Location",
                        errorAddingLocation: "Error adding Location",
                        locationAdded: "Location added",
                    },
                },
                locationList: {
                    pageTitle: "Location",
                    name: "Name",
                    description: "Description",
                    parent: "Parent",
                },

                editLocation: {
                    pageTitle: "Edit Location",
                    errorEditingLocation: "Error editing Location",
                    locationEdited: "Location edited",
                },

                addLocation: {
                    pageTitle: "Add Location",
                    errorAddingLocation: "Error adding Location",
                    locationAdded: "Location added",
                },

                pipelineStage: {
                    pipelineStageList: {
                        pageTitle: "Pipeline stages",
                        addPipelineStage: "Add pipeline stage",
                        editPipelineStage: "Edit pipeline stage",
                        add: "Add",
                    },
                    addPipelineStage: {
                        pageTitle: "Add pipeline stage",
                    },
                },
                pipeline: {
                    addPipeline: {
                        pageTitle: "Add pipeline",
                    },
                    editPipeline: {
                        pageTitle: "Edit pipeline",
                    },
                    pipelineList: {
                        name: "Name",
                        type: "Type",
                        pageTitle: "Pipelines",
                        pipelineStages: "Pipeline stages",
                        pipelineAccesses: "Pipeline accesses",
                    },
                    pipelineAccesses: {
                        pageTitle: "Pipeline accesses",
                    },
                },
                businessYear: {
                    businessYearList: {
                        active: "Active",
                        pageTitle: "Business years",
                        year: "Year",
                        startDate: "Start date",
                        endDate: "End date",
                        businessYearStartValue: "Business year start value",
                    },
                    addBusinessYear: {
                        pageTitle: "Add Business year",
                    },
                    editBusinessYear: {
                        pageTitle: "Edit business year",
                    },
                },
                role: {
                    roleList: {
                        pageTitle: "Roles",
                        name: "Name",
                        permissions: "Permissions",
                    },
                    addRole: {
                        pageTitle: "Add Role",
                    },
                    editRole: {
                        pageTitle: "Edit role name",
                    },
                    permissions: {
                        selectAll: "Select all",
                        selectAllPermissions: "Select all permissions",
                    },
                },
                user: {
                    userList: {
                        pageTitle: "Users",
                        email: "Email",
                        firstName: "First name",
                        lastName: "Last name",
                        status: "Status",
                        userPosition: "User Position",
                        team: "Team",
                        organisationUser: "Organisation user",
                        owner: "Owner",
                        roles: "Roles",
                        userRoles: "User roles",
                        invite: "Invite",
                        billableHours: "Billable hours",
                        candidate: "Candidate",
                    },
                    addUser: {
                        pageTitle: "Add user",
                    },
                    editRole: {
                        pageTitle: "Edit user",
                    },
                },
                bussinesYearStartValue: {
                    bussinesYearStartValueList: {
                        pageTitle: "Business year start values",
                        businessYear: "Business year",
                        bankAccount: "Bank account",
                        amount: "Amount",
                    },
                    editBussinesYearStartValue: {
                        pageTitle: "Edit bussines year start value",
                    },
                    addBussinesYearStartValue: {
                        pageTitle: "Add bussines year start value",
                    },
                },
            },
            taskManagement: {
                tasks: {
                    taskView: {
                        pageTitle: "Tasks",
                    },
                    addTask: {
                        pageTitle: "Add task",
                    },
                    editTask: {
                        pageTitle: "Edit task",
                    },
                    taskDetails: {
                        files: "Files",
                    },
                },
                taskTemplates: {
                    taskTemplateView: {
                        pageTitle: "Task templates",
                    },
                    addTaskTemplate: {
                        pageTitle: "Add task template",
                    },
                    editTaskTemplate: {
                        pageTitle: "Edit task template",
                    },
                    taskTemplateDetails: {
                        files: "Files",
                    },
                },
            },
            files: {
                fileFolder: {
                    fileFolderList: {
                        pageTitle: "File folders",
                        name: "Name",
                        editFolder: "Edit folder",
                        deleteFolder: "Delete folder",
                        addFolder: "Add folder",
                    },
                    addFolder: {
                        pageTitle: "Add folder",
                    },
                    editFolder: {
                        pageTitle: "Edit folder name",
                    },
                },
                fileCategory: {
                    fileCategoryList: {
                        pageTitle: "File categories",
                        name: "Name",
                    },
                    addFileCategory: {
                        pageTitle: "Add file category",
                    },
                    editFileCategory: {
                        pageTitle: "Edit file category",
                    },
                },
            },
            login: {
                login: "Log In",
                wrongUsernameOrPassword: "Wrong username or password",
            },
            lock: {
                lock: "Lock",
                wrongPassword: "Wrong password",
            },
            resetPassword: {
                resetPassword: "Reset password",
            },
            resetPasswordRequest: {
                resetPassword: "Reset password",
            },
            errors: {
                internalServerError: "Internal server error",
                dashboard: "Dashboard",
                badRequest: "Bad request",
                forbidden: "Forbidden",
                notFound: "Not found",
                unauthorized: "Unauthorized",
            },
        },
        forms: {
            calendar: {
                addEvent: {
                    name: "Name",
                    description: "Description",
                    startDate: "Start date",
                    endDate: "End date",
                    allDay: "All-day",
                    location: "Location",
                    url: "URL",
                    addInvites: "Add invites",
                    upload: "Upload",
                    title: "Add event",
                    users: "Users",
                    eventDeleted: "Event deleted",
                    pageTitle: "Add event",
                },
                editEvent: {
                    pageTitle: "Edit event",
                },
            },
            company: {
                baseSettings: {
                    name: "Name",
                    enterName: "Enter name",
                    registrationNumber: "Registration number",
                    taxNumber: "Tax number",
                    currency: "Currency",
                    country: "Country",
                    city: "City",
                    zipCode: "Zip code",
                    address: "Address",
                    activity: "Activity",
                    activityCode: "Activity code",
                    phone: "Phone",
                    email: "Email",
                    website: "Website",
                    about: "About",
                    responsiblePerson: "Responsible person",
                    error: {
                        registrationNumber: "Wrong registration number",
                        taxNumber: "Wrong tax number",
                    },
                },
                s3Settings: {
                    version: "Version",
                    region: "Region",
                    s3key: "S3 key",
                    secret: "Secret",
                    bucket: "Bucket",
                    url: "URL",
                },
                localisationSettings: {
                    dateFormat: "Date format",
                    numberFormat: "Number format",
                },
                invoiceSettings: {
                    invoiceNumberPattern: "Invoice number pattern",
                    invoicePlace: "Invoice place",
                    comment: "Comment",
                    inoComment: "Ino comment",
                    account: "Account",
                    inoAccount: "Ino account",
                    deadline: "Deadline",
                    eInvoiceApiKey: "E invoice API key",
                },
                bankAccount: {
                    name: "Name",
                    account: "Account",
                    iban: "IBAN",
                    currency: "Currency",
                    bank: "Bank",
                },
            },
            user: {
                acceptCompanyInvite: {
                    password: "Password",
                    passwordError: "Password error",
                    accept: "Accept",
                    passwordPlaceholder: "Enter password",
                },
                registration: {
                    emailPlaceholder: "Enter email",
                    firstNamePlaceholder: "Enter first name",
                    lastNamePlaceholder: "Enter last name",
                    passwordPlaceholder: "Enter password",
                    haveAnAccount: "Do you have an account?",
                    signUp: "Sign Up",
                    register: "Register",
                    email: "Email",
                    password: "Password",
                    firstName: "First name",
                    lastName: "Last name",
                    passwordRepeat: "Password repeat",
                    login: "Login",
                },
            },
            crm: {
                partner: {
                    jbkjs: "JBKJS",
                    name: "Name",
                    partnerLegalType: "Partner legal types",
                    description: "Description",
                    partnerType: "Partner type",
                    account: "Account",
                    registrationNumber: "Registration number",
                    taxNumber: "Tax number",
                    currency: "Currency",
                    country: "Country",
                    city: "City",
                    address: "Address",
                    zipCode: "Zip code",
                    website: "Website",
                    placeholder: {
                        jbkjs: "JBKJS",
                        name: "Enter name",
                        number: "Enter number",
                        description: "Enter description",
                        account: "Enter account",
                        country: "Select country",
                        city: "Enter city",
                        address: "Enter address",
                        zipCode: "Enter zip code",
                        website: "Enter website",
                    },
                    error: {
                        registrationNumber: "Wrong registration number",
                        taxNumber: "Wrong tax number",
                    },
                },
                partnerContactForm: {
                    firstName: "First Name",
                    lastName: "Last Name",
                    email: "Email",
                    phone: "Phone",
                    partner: "Partner",
                    skype: "Skype",
                    linkedin: "Linkedin",
                },
                dealType: {
                    name: "Name",
                },
                dealSource: {
                    name: "Name",
                },
                dealPriority: {
                    name: "Name",
                    color: "Color",
                },
                deal: {
                    name: "Name",
                    description: "Description",
                    amount: "Amount",
                    closeDate: "Close date",
                    pipeLine: "Pipeline",
                    pipeLineStage: "Pipeline stage",
                    currency: "Currency",
                    partner: "Partner",
                    dealType: "Deal type",
                    dealPriority: "Deal priority",
                    dealOwner: "Deal owner",
                    dealSource: "Deal source",
                },
                customer: {
                    firstName: "First name",
                    lastName: "Last name",
                    email: "Email",
                },
                tags: {
                    name: "Name",
                    enterName: "Enter name",
                },
            },
            hr: {
                team: {
                    name: "Name",
                    description: "Description",
                    organisationUnit: "Organisation unit",
                },
                leaveType: {
                    name: "Name",
                    subtractDays: "Subtract days",
                    range: "Subtract days must be 0 or 1",
                },
                jobContractType: {
                    name: "Name",
                },
                jobStatus: {
                    name: "Name",
                    icon: "Icon",
                    color: "Color",
                },
                candidateSource: {
                    name: "Name",
                },
                nonWorkingDay: {
                    name: "Name",
                    fromDate: "From date",
                    toDate: "To date",
                    type: "Type",
                },
                leaveRequest: {
                    fromDate: "From date",
                    toDate: "To date",
                    approved: "Approved",
                    dateError: "End date shouldn't be less than start date",
                },
                organisationUserContract: {
                    employee: "Employee",
                    name: "Name",
                    type: "Type",
                    startDate: "Start date",
                    endDate: "End date",
                    net: "Net",
                    gross: "Gross",
                    user: "User",
                    currency: "Currency",
                    paymentDay: "Payment day",
                    paymentMonth: "Payment month",
                    amount: "Amount",
                },
                leave: {
                    days: "Days",
                    employee: "Employee",
                    leaveType: "Leave type",
                    fromDate: "From date",
                    toDate: "To date",
                    employeeUser: "Employee user",
                    dateTimeErorr: "End date shouldn't be less than start date",
                },
                job: {
                    name: "Name",
                    description: "Description",
                    headCount: "Head count",
                    isRemote: "Is remote",
                    location: "Location",
                    minNetSalary: "Min neto salary",
                    minGrossSalary: "Min gross salary",
                    maxNetSalary: "Max neto salary",
                    maxGrossSalary: "Max gross salary",
                    paymentFrequency: "Payment frequency",
                    user: "User",
                    jobStatus: "Job status",
                    jobContractType: "Job contract type",
                    userPosition: "User position",
                    currency: "Currency",
                    partner: "Partner",
                    pipeline: "Pipeline",
                    leaveType: "Leave type",
                },

                userPosition: {
                    name: "Name",
                    parent: "Parent Position",
                },
                organisationUserContractPart: {
                    year: "Year",
                    month: "Month",
                    net: "Net",
                    gross: "Gross",
                    paymentDate: "Payment date",
                    user: "User",
                    organisationUserContract: "Organisation user contract",
                    currency: "Currency",
                },
                candidate: {
                    firstName: "First name",
                    lastName: "Last name",
                    email: "Email",
                    location: "Location",
                    phone: "Phone",
                    description: "Description",
                    currentPosition: "Current position",
                    currentCompany: "Current comapny",
                    currentSalary: "Current salary",
                    expectedSalary: "Expected salary",
                    birthday: "Birthday",
                    education: "Education",
                    job: "Job",
                    candidateSource: "Candidate source",
                    responsibleHrManager: "Responsible HR Manager",
                    noPreviousExperience: "No previous experience",
                    candidateStatus: "Candidate status",
                    files: "CV and other files",
                },
                additionalHours: {
                    startDate: "Start date",
                    endDate: "End date",
                    status: "Status",
                    hours: "Hours",
                    user: "User",
                    organisationUserContract: "Organisation user contract",
                },
                candidateStatus: {
                    name: "Name",
                    color: "Color",
                },
                organisationUnit: {
                    name: "Name",
                    parent: "Parent",
                },
                employee: {
                    firstName: "First name",
                    lastName: "Last name",
                    email: "Email",
                    userPosition: "User position",
                    team: "Team",
                    nextRaise: "Next raise",
                    lastRaise: "Last raise",
                    phoneNumber: "Phone number",
                    address: "Address",
                    birthDate: "Birthdate",
                    employeeStatus: "Employee status",
                    billableHours: "Billable hours",
                    personalNumber: "Personal number",
                    bankAccount: "Bank account",
                },
                baseSettings: {
                    workingDayHours: "Working day hours",
                    internalHrOnly: "Internal HR only",
                    vacationDaysPerYear: "Vacation days per year",
                },
                presence: {
                    start: "Start",
                    end: "End",
                    dateTimeErorr: "End date shouldn't be less than start date",
                },
                businessTrip: {
                    foreignDailyWageCurrency: "Foreign daily wage currency",
                    foreignDailyWage: "Foreign daily wage",
                    domesticDailyWage: "Domestic daily wage",
                },
            },
            workspaces: {
                name: "Name",
                description: "Description",
                pipeline: "Pipeline",
                boardKey: "Key",
            },
            issueType: {
                name: "Name",
                type: "Type",
                placeholder: {
                    name: "Enter name",
                    type: "Select type",
                },
            },
            board: {
                project: "Project",
                boardKeyExists: "Board key already exists",
                cantUseSpecialChar: "Cannot use special characters for the key",
                boardIssue: {
                    name: "Name",
                    description: "Description",
                    assignee: "Assignee",
                    issueType: "Issue Type",
                    priority: "Priority",
                    assignToMe: "Assign to me",
                    placeholder: {
                        name: "Enter name",
                        description: "Enter description",
                        issueType: "Select type",
                        assignee: "Select assignee",
                    },
                    dropFiles: "Drop files here",
                },
            },
            finance: {
                invoice: {
                    acceptRejectInvoiceFrom: {
                        comment: "Comment",
                    },
                    stornoInvoiceForm: {
                        stornoComment: "Comment",
                        stornoNumber: "Storno number",
                    },
                    cancelInvoiceForm: {
                        cancelComments: "Comment",
                    },
                    invoiceHeaderForm: {
                        invoiceType: "Invoice type",
                        partner: "Partner",
                        account: "Account",
                        invoiceNumber: "Invoice number",
                        invoicePlace: "Invoice place",
                        exchangeRate: "Exchange rate",
                        contract: "Contract",
                        contractPart: "Contract part",
                        invoiceDate: "Invoice date",
                        deliveryDate: "Delivery date",
                        deadlineDate: "Deadline date",
                        exchangeRateDate: "Exchange rate date",
                        project: "Project",
                        currency: "Currency",
                        add: "Add",
                        edit: "Edit",
                        placeholder: {
                            partner: "Partner",
                            account: "Account",
                            invoiceNumber: "Invoice number",
                            invoicePlace: "Invoice place",
                            exchangeRate: "Exchange rate",
                            contract: "Contract",
                            project: "Project",
                            contractPart: "Contract part",
                        },
                    },
                    invoiceItemsForm: {
                        productService: "Product or service",
                        quantity: "Quantity",
                        price: "Price",
                        tax: "Tax",
                        unit: "Unit",
                        add: "Add",
                        placeholder: {
                            productService: "Product or service",
                        },
                    },
                    invoiceItemsTable: {
                        no: "No",
                        name: "Name",
                        unit: "Unit",
                        quantity: "Quantity",
                        price: "Price",
                        amount: "Amount",
                        discount: "Discount",
                        amountWithDiscount: "Amount with discount",
                        vat: "VAT",
                        amountWithVat: "Amount with VAT",
                        placeholder: {
                            name: "Enter name",
                            quantity: "Enter quantity",
                            price: "Enter price",
                            discount: "Enter discount",
                            vat: "Enter VAT",
                        },
                    },
                    invoiceTotal: {
                        total: "Total",
                        VAT: "VAT",
                        totalWithVAT: "Total with VAT",
                    },
                },
                transactionForm: {
                    bankReport: "Bank report",
                    pageTitle: "Transactions",
                    paymentType: "Payment type",
                    partnerName: "Partner name",
                    description: "Description",
                    paymentDate: "Payment date",
                    income: "Income",
                    outcome: "Outcome",
                    currency: "Currency",
                    employee: "Employee",
                    partner: "Partner",
                    contract: "Contract",
                    contractPart: "Contract part",
                    invoice: "Invoice",
                    transactionCategory: "Transaction category",
                    transactionType: "Transaction type",
                    tax: "Tax",
                    approved: "Approved",
                    bank: "Bank",
                    placeholder: {
                        employee: "Employee",
                        partner: "Partner",
                        contract: "Contract",
                        contractPart: "Contract part",
                        invoice: "Invoice",
                        transactionCategory: "Transaction category",
                    },
                },
                transactionSplitForm: {
                    pageTitle: "Transaction Split",
                    transactionAmountInput: "Transaction Amount Part",
                    addButton: "Add Transaction Amount Part",
                },
                transactionCategory: {
                    pageTitle: "Transaction Category",
                    name: "Name",
                    parent: "Parent",
                    company: "Company",
                },
                productService: {
                    productServiceForm: {
                        sku: "SKU",
                        name: "Name",
                        publicName: "Public name",
                        publicInoName: "Public ino name",
                        description: "Description",
                        price: "Price",
                        tax: "Tax",
                        currency: "Currency",
                        unit: "Unit",
                        discount: "Discount",
                        type: "Type",
                    },
                },
                bankReport: {
                    bank: "Bank",
                    bankAccount: "Bank Account",
                    currency: "Currency",
                    businessYear: "Business Year",
                    report: "Report ID",
                    reportNumber: "Report Number",
                    reportDate: "Report Date",
                    amountBefore: "Amount Before",
                    amountAfter: "Amount After",
                    income: "Income",
                    outcome: "Outcome",
                    company: "Company",
                },
                contract: {
                    contractForm: {
                        contractNumber: "Contract number",
                        name: "Name",
                        contract: "Contract",
                        description: "Description",
                        startDate: "Start date",
                        endDate: "End date",
                        contractType: "Contract type",
                        partner: "Partner",
                        owner: "Owner",
                        currency: "Currency",
                        canReturnTax: "Can return tax",
                        tax: "Tax",
                        transactionCategory: "Transaction category",
                        project: "Project",
                        contractStatus: "Contract status",
                        contractPart: "Contract part",
                    },
                },
                contractStatus: {
                    name: "Name",
                },
                contractPartStatus: {
                    name: "Name",
                },
                contractPart: {
                    contractPartForm: {
                        description: "Description",
                        startDate: "Start date",
                        endDate: "End date",
                        paymentDate: "Payment date",
                        actualPaymentDate: "Actual payment date",
                        amount: "Amount",
                        amountWithTax: "Amount with tax",
                        actualAmount: "Actual amount",
                        actualAmountWithTax: "Actual amount with tax",
                        paid: "Paid",
                        invoiceDate: "Invoice date",
                        contractPartStatus: "Contract part status",
                    },
                },
                contractPartItem: {
                    contractPartItemForm: {
                        quantity: "Quantity",
                        productService: "Product or service",
                        placeholder: {
                            quantity: "Enter quantity",
                            productService: "Enter product or service",
                        },
                    },
                },
            },
            checkList: {
                scheduleCheckFrom: {
                    name: "Name",
                    employee: "Employee",
                    partner: "Partner",
                    location: "Location",
                    dateFrom: "Date from",
                    dateTo: "Date to",
                    template: "Check list template",
                },
            },
            product: {
                productServiceCategory: {
                    productServiceCategoryForm: {
                        name: "Name",
                        description: "Description",
                        parent: "Parent",
                    },
                },
                compositeProduct: {
                    compositeProductForm: {
                        quantity: "Quantity",
                        product: "Product",
                    },
                },
                wooCommerceSettings: {
                    wooCommerceSettingsFrom: {
                        apiKey: "Api key",
                        apiSecret: "Api secret",
                        apiUrl: "Api url",
                        enabled: "Enabled",
                    },
                },
            },
            project: {
                projectCategory: {
                    name: "Category Title",
                    parent: "Parent",
                },
                projectResourceType: {
                    name: "Resource Type Title",
                    parent: "Parent",
                },
                projectResource: {
                    name: "Resource Name",
                    description: "Description",
                    price: "Price",
                    date: "Date",
                    currency: "Currency",
                    project: "Project",
                    projectResourceType: "Project Resource Type",
                    invoice: "Invoice",
                    type: "Type",
                },
                project: {
                    name: "Project Name",
                    description: "Description",
                    projectCategory: "Project Category",
                    contract: "Contract",
                    partner: "Partner",
                },
                taskCategory: {
                    name: "Task category name",
                    color: "Color",
                },
                projectTimeLog: {
                    projectTimeLogForm: {
                        dateLogged: "Date",
                        hoursLogged: "Hour",
                        employee: "Employee",
                        project: "Project",
                        name: "Description",
                        taskCategory: "Task category",
                        billable: "Billable",
                    },
                },
            },
            document: {
                documentStatus: {
                    name: "File status name",
                    color: "Color",
                },
                documentCategory: {
                    name: "File category name",
                },
                document: {
                    name: "File name",
                    documentStatus: "File status",
                    documentCategory: "File category",
                },
                spaces: {
                    name: "Name",
                    enterName: "Enter name",
                    isPublic: "Is public",
                },
            },
            companyDocument: {
                companyDocumentCategory: {
                    name: "Company Document Category name",
                    parent: "Parent",
                },
                companyDocumentTemplate: {
                    name: "Company Document template name",
                    companyDocumentCategory: "Category name",
                },
                companyDocumentTemplateQuestion: {
                    question: "Question",
                    enterQuestion: "Enter question",
                    explanation: "Explanation",
                    comment: "Enter comment",
                    code: "Code",
                    questionType: "Question type",
                    required: "Required",
                },
                companyDocumentTemplateItem: {
                    name: "Name",
                    code: "Code",
                    type: "Type",
                    referenceValueType: "Reference value type",
                    referenceType: "Reference type",
                },
                companyDocument: {
                    name: "Company Document name",
                    description: "Company Document description",
                    companyDocumentCategory: "Category name",
                    companyDocumentTemplate: "Template name",
                },
            },
            administration: {
                pipelineStage: {
                    name: "Name",
                    type: "Type",
                    description: "Description",
                },
                pipeline: {
                    name: "Name",
                    type: "Type",
                },
                businessYear: {
                    year: "Year",
                    startDate: "Start date",
                    endDate: "End date",
                },
                businessYearStartValue: {
                    amount: "Amount",
                    bankAccount: "Bank account",
                },
                role: {
                    name: "Name",
                },
                user: {
                    email: "Email",
                    firstName: "First name",
                    lastName: "Last name",
                    organisationUser: "Organisation user",
                    userPosition: "User position",
                    team: "Team",
                    candidate: "Candidate",
                },
                locationForm: {
                    name: "Name",
                    description: "Description",
                    parent: "Parent Location",
                },
            },
            assetManagement: {
                inventoryCategoryForm: {
                    name: "Name",
                    parent: "Parent",
                },
                vendor: {
                    name: "Vendor name",
                },
                assetType: {
                    name: "Type name",
                    description: "Description",
                    price: "Price",
                    tax: "Tax",
                    canReturnTax: "Can return tax",
                    vendor: "Vendor",
                    assetCategory: "Category",
                    image: "Image",
                },
                assetCategory: {
                    name: "Category name",
                    parent: "Parent",
                },
                assetForm: {
                    serialNumber: "Serial Number",
                    price: "Price",
                    tax: "Tax",
                    purchasedDate: "Purchased Date",
                    warrantyEnd: "Warranty End",
                    location: "Asset Location",
                    type: "Asset Type",
                    employee: "Employee",
                },
                inventoryTypeForm: {
                    name: "Name",
                    description: "Description",
                    image: "Image",
                    canReturnTax: "Can Return Tax",
                    category: "Category",
                },
                inventoryForm: {
                    value: "Value",
                    quantity: "Quantity",
                    lowStockTrashold: "Low Stock Trashold",
                    location: "Location",
                    type: "Type",
                },
                stockChangeForm: {
                    type: "Type",
                    price: "Price",
                    tax: "Tax",
                    quantity: "Quantity",
                    stockChangeDate: "Stock Change Date",
                    inventory: "Inventory",
                },
            },
            taskManagement: {
                taskForm: {
                    name: "Name",
                    description: "Description",
                    pipeline: "Pipeline",
                    pipelineStage: "Pipeline stage",
                },
                taskTemplateForm: {
                    name: "Name",
                    description: "Description",
                    pipeline: "Pipeline",
                    pipelineStage: "Pipeline stage",
                },
            },
            files: {
                fileFolderForm: {
                    name: "Folder name",
                },
            },
            lock: {
                password: "Password",
                unlock: "Unlock",
            },
            login: {
                haveAnAccountQuestion: "Don't have an account?",
                signUp: "Sign up",
                forgotPassword: "Forgot password?",
                email: "Email",
                password: "Password",
                login: "Log In",
                emailError: "Please enter valid email",
                passwordError: "Please enter valid password",
                emailPlaceholder: "Enter email",
                passwordPlaceholder: "Enter password",
            },
            resetPassword: {
                newPassword: "New password",
                reEnterNewPassword: "Re-enter Your New Password",
                haveAnAccount: "Do you have an account?",
                login: "Login",
                password: "Password",
                passwordRepeat: "Password repeat",
                resetPassword: "Reset password",
                passwordRepeatError: "Password do not match",
                passwordError: "Password do not match",
                passwordPlaceholder: "Enter password",
            },
            resetPasswordRequest: {
                emailPlaceholder: "Enter email",
                email: "Email",
                send: "Send",
                resetPasswordRequest: "Email is required",
                haveAnAccount: "Do you have and account?",
                login: "Login",
            },
            searchForm: {
                search: "Search",
            },
            common: {
                save: "Save",
                saveChanges: "Save changes",
                saveAndInvite: "Save and invite",
                saveAndClose: "Save and close",
                cancel: "Cancel",
                thisFieldIsRequired: "This field is required",
                invalidPattern: "Only numbers, lowercase letters and dashes allowed",
                emailFormatError: "Please enter correct email format",
                send: "Send",
            },
            treeViewList: {
                nameExists: "Name already exists in this folder",
            },
        },
        navigation: {
            homeTag: "Home",
            startTrial: "Start trial",
            managmentTag: "Management",
            home: "Home",
            calendar: "Calendar",
            workspaces: "Workspaces",
            boards: "Boards",
            crm: "CRM",
            assetManagement: "Assets",
            vendors: "Vendors",
            assetTypes: "Asset types",
            assetCategories: "Asset categories",
            documentTemplates: "Templates",
            documentCategory: "Document categories",
            files: "Files",
            fileCategory: "File categories",
            fileStatus: "File statuses",
            fileFolders: "File folders",
            documents: "Documents",
            uploadBankReport: "Upload bank reports",
            settings: "Settings",
            administration: "Administration",
            finance: "Finance",
            dashboard: "Dashboard",
            hr: "HR",
            product: "Products",
            productSettings: "Settings",
            cashFlow: "Cash flow",
            logout: "Logout",
            company: "Company",
            userPosition: "User positions",
            user: "Users",
            role: "Roles",
            productServices: "Product / Services",
            productServicesCategory: "Product / Services category",
            partners: "Partners",
            deals: "Deals",
            invoices: "Invoices",
            bills: "Bills",
            createInvoice: "Create invoice",
            contracts: "Contracts",
            bankReport: "Bank reports",
            businessYear: "Business years",
            transactionCategory: "Transaction Categories",
            transactions: "Transactions",
            employees: "Employees",
            companyDocumentCategory: "Company document categories",
            companyDocumentTemplate: "Company document templates",
            companyDocument: "Company documents",
            documentManagement: "Document management",
            project: "Projects",
            projectCategory: "Project Categories",
            projectResource: "Project Resources",
            team: "Teams",
            organisationUnit: "Organisation units",
            jobContractType: "Job contract types",
            organisationUserContract: "Organisation User Contracts",
            organisationUserContractPart: "Organisation User Contract Parts",
            employeeContracts: "Employee contracts",
            leaveType: "Leave types",
            jobStatus: "Job statuses",
            job: "Jobs",
            projectResourceType: "Project Resource Types",
            candidateSource: "Candidate sources",
            candidate: "Candidates",
            nonWorkingDays: "Non Working Days",
            documentStatus: "Document Status",
            leave: "Leaves",
            leaveRequest: "Leave Requests",
            additionalHours: "Additional Hours",
            projectTimeLog: "Project Time Logs",
            pipeline: "Pipelines",
            pipelineStage: "Pipeline stages",
            contractPart: "Contract Parts",
            location: "Locations",
            inventoryCategory: "Inventory Categories",
            assets: "Assets",
            taskCategory: "Task Categories",
            inventoryType: "Inventory Types",
            inventory: "Inventories",
            stockChange: "Stock Changes",
            partnerContacts: "Partner Contacts",
            presence: "Presence",
            userProfile: "User Profile",
            spaces: "Spaces",
            attribute: "Attributes",
            checkList: "Check List",
            checkListTemplate: "Templates",
            checkListScheduledCheks: "Scheduled checks",
            productCatalog: "Product catalog",
            customer: "Customer",
            businessTrip: "Business trip",
        },
        components: {
            calendar: {
                pageTitle: "Calendar",
                today: "Today",
                day: "Day",
                week: "Week",
                month: "Month",
                allDay: "all-day",
                weekDaysShort: {
                    monday: "Mon",
                    tuesday: "Tue",
                    wednesday: "Wed",
                    thursday: "Thu",
                    friday: "Fri",
                    saturday: "Sat",
                    sunday: "Sun",
                },
                weekDays: {
                    monday: "Monday",
                    tuesday: "Tuesday",
                    wednesday: "Wednesday",
                    thursday: "Thursday",
                    friday: "Friday",
                    saturday: "Saturday",
                    sunday: "Sunday",
                },
                noData: {
                    title: "No Data",
                    text: "Sorry, there is no data to display yet. Once they are filled in, will be presented here. In the meantime, you can add your important events.",
                },
            },
            calendarEvent: {
                noEvents: "No events for this days",
                partOf: "part of",
                net: "Net",
                gross: "Gross",
                paid: "paid",
                notPaid: "not paid",
                birthday: "Birthdays",
                nonWorkingDays: "Non working days",
                leaves: "Leaves",
                organisationUserContracts: "Organisation user contracts",
                organisationUserContractParts: "Organisation user contract parts",
                contracts: "Contracts",
                contractParts: "Contract parts",
                projects: "Projects",
                paymentDate: "Payment date:",
                customEvents: "Custom events",
                ScheduleChecks: "Schedule checks",
                ScheduleChecksPending: "Schedule checks pending",
                ScheduleChecksInProgress: "Schedule checks in progress",
                ScheduleChecksDone: "Schedule checks done",
                ScheduleChecksCancelled: "Schedule checks cancelled",
            },
            fileUploadComponent: {
                upload: "Upload",
                files: "Files",
                dragDrop: "Drag 'n' drop some files here, or click to select files",
            },
            dialogs: {
                yesNoDialog: {
                    no: "No",
                    yes: "Yes",
                },
            },
            treeViewList: {
                createNewFolder: "Create new folder",
                addFolder: "Add folder",
                editFolder: "Edit folder",
                createNewCategory: "Create new category",
                editCategory: "Edit category",
            },
            tablePage: {
                tag: "Tag",
                documents: "Documents",
                actions: "Actions",
                edit: "Edit",
                view: "View",
                delete: "Delete",
                restore: "Restore",
                search: "Search",
                confirmDelete: "Confirm delete",
                confirmCancel: "Confirm cancel",
                confirmDeleteMessage: "Are you sure you want to delete this record?",
                confirmDeleteEventMessage:
                    "Are you sure you want to delete this event?",
                confirmCancelMessage: "Are you sure you want to cancel this check?",
                errorDeletingItem: "Error deleting item",
                confirmSendInvite: "Confirm send",
                confirmSendInviteMessage: "Are you sure you want to send invite?",
                itemDeleted: "Item deleted",
                checkListCancelled: "Check cancelled",
                send: "Send",
                cancel: "Cancel",
                year: "Year",
                month: "Month",
                popover: {
                    edit: "Edit",
                    view: "View",
                    delete: "Delete",
                    files: "Files",
                    bookmark: "Bookmark",
                    tags: "Tags",
                },
                noData: {
                    title: "No Data",
                    description:
                        "Sorry, there is no data to display yet. Once they are filled in, will be presented here.",
                },
                addButton: {
                    presance: "Add presence",
                    employee: "Add employee",
                    employeeContract: "Add employee contract",
                    team: "Add team",
                    employeeContractPart: "Add employee contract part",
                    organisationUnit: "Add organisation unit",
                    job: "Add job",
                    cantidate: "Add candidate",
                    additionalHours: "Add additional hours",
                    leaveRequest: "Add leave request",
                    leave: "Add leave",
                    userPosition: "Add user position",
                    jobStatus: "Add job status",
                    jobContractType: "Add job contract type",
                    candidateSource: "Add candidate source",
                    leaveType: "Add leave type",
                    nonWorkingDay: "Add non working day",
                    candidateStatus: "Add candidate status",
                    addEvent: "Add event",

                    productService: "Add product and service",
                    productServiceCategory: "Add product service category",
                    compositeProduct: "Add composite product",
                    invoice: "Add invoice",
                    bill: "Add bill",
                    contract: "Add contract",
                    contractPart: "Add contract part",
                    contractPartItem: "Add contract part item",
                    uploadBankReport: "Add transaction",
                    bankReport: "Add bank report",
                    transaction: "Add transaction",
                    bankAccount: "Add bank account",
                    transactionCategory: "Add transaction category",
                    businessYear: "Add business year",
                    businessYearStartValue: "Add business year start value",
                    partner: "Add partner",
                    contractStatus: "Add contract status",
                    contractPartStatus: "Add contract part status",

                    partnerContact: "Add partner contact",
                    dealType: "Add deal type",
                    dealPriority: "Add deal priority",
                    dealSource: "Add deal source",
                    deal: "Add deal",
                    customer: "Add customer",

                    user: "Add user",
                    role: "Add role",
                    pipeline: "Add pipeline",

                    document: "Add document",
                    companyDocumentTemplate: "Add company document template",
                    companyDocumentTemplateItem: "Add company document template item",
                    companyDocumentCategory: "Add company document category",

                    project: "Add project",
                    projectResource: "Add project resource",
                    projectTimeLog: "Add project time log",
                    projectCategory: "Add project category",
                    resourceType: "Add resource type",
                    taskCategory: "Add task category",

                    fileCategory: "Add file category",
                    fileStatus: "Add file status",
                    file: "Add file",

                    asset: "Add asset",
                    assetType: "Add asset type",
                    assetCategory: "Add asset category",
                    inventory: "Add inventory",
                    stockChange: "Add stock change",
                    inventoryType: "Add inventory type",
                    inventoryCategory: "Add inventory category",
                    vendor: "Add vendor",
                    location: "Add location",

                    workspaces: "Add Workspace",
                    issueType: "Add Issue Type",

                    attribute: "Add attribute",
                    attributeValue: "Add attribute value",

                    scheduleCheck: "Schedule check",
                },
            },
            actionCell: {
                view: "View",
                edit: "Edit",
                delete: "Delete",
                documents: "Files",
            },
            autoComplete: {
                noOptions: "No options",
            },
            tags: {
                tagsTitle: "Tags",
                addTag: "Add tag",
                deleteAllTags: "Delete all tags",
            },
            spaces: {
                exportToPDF: "Export to PDF",
            },
        },
        months: {
            january: "January",
            february: "February",
            march: "March",
            april: "April",
            may: "May",
            june: "June",
            july: "July",
            august: "August",
            september: "September",
            october: "October",
            november: "November",
            december: "December",
        },
        monthsShort: {
            january: "Jan",
            february: "Feb",
            march: "Mar",
            april: "Apr",
            may: "May",
            june: "Jun",
            july: "Jul",
            august: "Aug",
            september: "Sep",
            october: "Oct",
            november: "Nov",
            december: "Dec",
        },
        commonMessages: {
            addingSuccessful: "Adding successful",
            errorAdding: "Error adding",
            editingSuccessful: "Editing successful",
            errorEditing: "Error editing",
            deleteSuccessful: "Delete successful",
            errorDeleting: "Error deleting",
            saved: "Saved",
            errorSaving: "Error saving",
            sent: "Sent",
            errorSent: "Error sent",
            inviteSuccesful: "Invite is sent",
            errorInvite: "Invite eror",
            errorGenerating: "Error generating",
            copied: "Copied!",
            failToCopy: "Failed to copy!",
            errorCancel: "Error canceling",
        },
        checkInOutMessages: {
            checkIn: "Check in",
            checkOut: "Check out",
            errorCheckIn: "Error checking in",
            errorCheckOut: "Error checking out",
        },
        common: {
            upload: "Upload",
            save: "Save",
            cancel: "Cancel",
            edit: "Edit",
            view: "View",
            download: "Download",
            viewIno: "View (ino)",
            downloadIno: "Download (ino)",
            year: "Year",
            new: "New",
            days: "Days",
        },
    },
});

export default strings;
