import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import ContractForm from "../../../Components/Forms/Pages/Finance/Contract/ContractForm";
import DrawerContext from "../../../Context/DrawerContext";
import {addContract} from "../../../Services/Finance/ContractService";
import LinearProgress from '@mui/material/LinearProgress';
import { getCurrencies, getTaxRates } from '../../../Util/SiteDataUtil';
import { getPartners } from '../../../Services/CRM/PartnerService';
import {
    getTransactionCategoryList
} from "../../../Services/Finance/TransactionCategoryService";
import SnackbarContext from "../../../Context/SnackbarContext";
import {getProjectList} from "../../../Services/Project/ProjectService";
import {getContractStatuses} from "../../../Services/Finance/ContractStatusService";

const formRules = {
    'name': {required: true}, 'startDate': {required: true}, 'endDate': {required: true},
    'contractType': {required: true}, 'partner': {required: true},
    'tax': {required: true},
    'paymentDay': {min: 1, max: 31, required: true},
    'currency': {required: true},
    'paymentMonth': {required: true},
}

const AddContract = (props) => {

    const siteData = useSelector((state) => state.siteData);
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: {
            canReturnTax: false,
            startDate: new Date(),
            endDate: new Date(),
        }
    });
    const {data, control, handleSubmit, getValues, setValue, trigger, formState: {errors}, watch} = form;
    const [showLoader, setShowLoader] = useState(false);

    const [partnersData, setPartnersData] = useState(0);
    const [transactionCategoriesData, setTransactionCategories] = useState(0);
    const [projects, setProjects] = useState(0);
    const [contractStatuses, setContractStatuses] = useState(0);

    useEffect(() => {
        setDrawerTitle(strings.pages.finance.contract.addContract.pageTitle);
    });

    useEffect(() => {
        fetch(); 
    },[])

    const fetch = () => {

        fetchPartners();
        fetchTransactionCategories();
        fetchProjects();
        fetchContractStatuses();
    }

    const fetchPartners = (term = '') => {
       if(!props.partner){
            getPartners({term}).then(response => {
                if (!response || !response.ok) {
                    return;
                }
                setPartnersData(response.data.result);
            });
       }
       else {
           setPartnersData([props.partner])
           setValue('partner', props.partner)
        }
    }

    const fetchTransactionCategories = (term = '') => {
        getTransactionCategoryList({term}).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setTransactionCategories(response.data.result);
        });
    }
    const fetchProjects = (term = '') => {
        getProjectList({term}).then(response => {
            if(!response || !response.ok){
                return;
            }
            setProjects(response.data.result);
        })
    }

    const fetchContractStatuses = (term = '') => {
        getContractStatuses({term}).then(response => {
            if(!response || !response.ok){
                return;
            }
            setContractStatuses(response.data.result);
        })
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        addContract(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish(response.data.entity);
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <ContractForm
                formRules={formRules}
                control={control}
                values={getValues()}
                setValue={setValue}
                currenciesData={getCurrencies(siteData)}
                partnersData = {partnersData}
                transactionCategoriesData = {transactionCategoriesData}
                taxes={getTaxRates(siteData)}
                projects={projects}
                contractStatuses={contractStatuses}
                onChangePartnerFilter={fetchPartners}
                onChangeTransactionCategoryFilter={fetchTransactionCategories}
                onChangeProjectFilter={fetchProjects}
                onChangeContractStatusFilter={fetchContractStatuses}
                errors={errors} data={data} form={form} trigger={trigger}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default AddContract;