import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";
import {GetObjectCommand, S3Client} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import store from "../../store";

export async function getProductImage(imageData) {
    let { companyS3Settings } = store.getState().auth;

    const config ={
        apiVersion: companyS3Settings.version,
        region: companyS3Settings.region,
        endpoint: companyS3Settings.url,
        credentials: {
            accessKeyId: companyS3Settings.s3key,
            secretAccessKey: companyS3Settings.secret
        }
    };


    const client = new S3Client(config);
    const fullPath = `${imageData.key}`;
    const command = new GetObjectCommand({Bucket: companyS3Settings.bucket, Key: fullPath});
    return await getSignedUrl(client, command, {expiresIn: 3600})
}

export async function getProductService(id) {
    return await request('/api/product-services/' + id);
}

export async function getProductServiceList(data) {
    return await request('/api/product-services', data);
}

export async function addProductService(data) {
    return await request('/api/product-services', transformRequestData(data), HttpMethod.POST);
}

export async function editProductService(data) {
    return await request('/api/product-services/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteProductService(id) {    
    return await request('/api/product-services/' + id, {}, HttpMethod.DELETE);
}

export async function connectProductToWooCommerce(id){
    return await request('/api/product-services/' +id, {},HttpMethod.POST);
}

export async function disconnectProductToWooCommerce(id){
    return await request('/api/product-services/' +id, {},HttpMethod.POST);
}

export async function connectProduct(data) {
    return await request('api/product-services/connect/' + data?.id,  transformRequestData(data), HttpMethod.PATCH)
}

export async function disconnectProduct(data){
    return await request('api/product-services/disconnect/' + data?.id, transformRequestData(data), HttpMethod.PATCH)
}

function transformRequestData(data) {
    return {
        ...data,
        unitId: data.unit ? data.unit.id : null,
        currencyId: data.currency ? data.currency.id : null,
        tax: data && data.tax ? data.tax.value : 0,
        type: data.type ? data.type.value : 0,
        productType: data.productType ? data.productType.value : 0,
        productServiceCategoryId: data.productServiceCategory ? data.productServiceCategory.id : null,
    }
}