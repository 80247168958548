import React, { useEffect, useState, useContext } from "react";
import { IconButton } from "@mui/material";
import {
  getAccommodationCosts,
  getTransportationCosts,
  getOtherCosts,
  deleteAccommodationCost,
  deleteOtherCost,
  deleteCalculation,
  deleteTransportationCost,
  downloadCalculation,
} from "../../../Services/HR/BusinessTripService";
import strings from "../../../localization";
import SnackbarContext from "../../../Context/SnackbarContext";

const download = (id) => {
  downloadCalculation(id).then((response) => {
    if (!response || !response.ok) {
      return;
    }
  });
};

const CalculationList = ({ calculation, onDelete }) => {
  const { showMessage } = useContext(SnackbarContext);
  const [accommodationCost, setAccommodationCost] = useState([]);
  const [transportationCost, setTransportationCost] = useState([]);
  const [otherCost, setOtherCost] = useState([]);

  useEffect(() => {
    getAccommodationCosts(calculation.id).then((response) => {
      setAccommodationCost(response.data);
    });
    getTransportationCosts(calculation.id).then((response) => {
      setTransportationCost(response.data);
    });
    getOtherCosts(calculation.id).then((response) => {
      setOtherCost(response.data);
    });
  }, []);

  const onDeleteCalculation = (id) => {
    otherCost.map((other) => {
      deleteOtherCost(other.id);
    });
    accommodationCost.map((acc) => {
      deleteAccommodationCost(acc.id);
    });
    transportationCost.map((tr) => {
      deleteTransportationCost(tr.id);
    });
    deleteCalculation(id).then((response) => {
      if (!response || !response.ok) {
        showMessage(strings.commonMessages.errorDeleting, "error");
        return;
      }
      onDelete();
      showMessage(strings.commonMessages.deleteSuccessful, "success");
    });
  };

  return (
    <div className="business-trip-details">
      <div className="business-trip-item">
        <div className="business-trip-elements">
          <div className="business-trip-details">
            <strong>
              {strings.pages.hr.businessTrip.businessTripForm.documentName}
              {": "}
            </strong>
            {calculation.business_trip.documentName}
          </div>

          <div className="icons-decision">
            <IconButton onClick={() => download(calculation.business_trip.id)}>
              <img src="/images/download-pdf-blue.svg" />
            </IconButton>
            <IconButton onClick={() => onDeleteCalculation(calculation.id)}>
              <img src="/images/delete-blue.svg" />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculationList;
