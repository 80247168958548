import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import LinearProgress from '@mui/material/LinearProgress';
import SnackbarContext from "../../../Context/SnackbarContext";
import CompositeProductForm from "../../../Components/Forms/Pages/Product/CompositeProductForm";
import {getProductServiceList} from "../../../Services/Product/ProductServiceService";
import {addCompositeProduct} from "../../../Services/Product/CompositeProductService";

const formRules = {
    'quantity': {required: true},
    'product': {required: true},
}

const AddCompositeProduct = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm();
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [products, setProducts] = useState(0);


    useEffect(() => {
        setDrawerTitle(strings.pages.product.compositeProduct.addCompositeProductList.pageTitle);
    });

    useEffect(()=>{
        fetchProducts();
    },[])

    const fetchProducts = (term = "") => {
        getProductServiceList({term}).then((response) => {
            if (!response || !response.ok) {
                return;
            }
            setProducts(response.data.result.filter(r => r && r.id != props.productId));
        });
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        data = {...data, 'parentId': props.productId};

        addCompositeProduct(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish(response.data.entity);
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <CompositeProductForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                products={products}
                errors={errors} data={data} form={form} control={control}
                onProductChangeFilter={fetchProducts}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default AddCompositeProduct;