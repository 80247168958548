import React, {useCallback, useContext, useEffect, useState} from 'react'
import strings from "../../../localization";
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import {Alert, Button, Grid, Paper, Snackbar} from "@mui/material";
import {
    editCompanyDocumentTemplateContent,
    getCompanyDocumentTemplateContent
} from "../../../Services/CompanyDocumentManagement/CompanyDocumentTemplateContentService";
import {getAllCompanyDocumentTemplateItems} from "../../../Services/CompanyDocumentManagement/CompanyDocumentTemplateItemService";
import {useLocation} from "react-router-dom";
import SnackbarContext from "../../../Context/SnackbarContext";
import QuillEditor from '../../../Components/Controls/Inputs/QuillEditor';

const toolbarConfig = {
    items: [
        'heading', '|',
        'fontfamily', 'fontsize', '|',
        'alignment', '|',
        'fontColor', 'fontBackgroundColor', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
        'link', '|',
        'outdent', 'indent', '|',
        'bulletedList', 'numberedList', 'todoList', '|',
        'code', 'codeBlock', '|',
        'insertTable', '|',
        'uploadImage', 'blockQuote', '|',
        'undo', 'redo'
    ],
    shouldNotGroupWhenFull: true
}

const TemplateContent = (props) => {

    const { state } = useLocation();

    const [data, setData] = useState({content: ''})
    const [content, setContent] = useState('');
    const [items, setItems] = useState([])
    const [editor, setEditor] = useState(null)
    const [template, setTemplate] = useState(state?.template)
    const [templateContent, setTemplateContent] = useState(state?.template?.activeVersion)
    const { showMessage } = useContext(SnackbarContext);

    useEffect(() => {
        setTemplate(state?.template)
        setTemplateContent(state?.template?.activeVersion)
    })

    useEffect(() => {
        fetchData()
    }, [template])

    const fetchData = useCallback(() => {
        getCompanyDocumentTemplateContent(templateContent?.id).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setContent(response.data.entity.content);
        })

        getAllCompanyDocumentTemplateItems({companyDocumentTemplateId: template?.id}).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setItems(response.data)
        });
    }, [])

    const submit = () => {
        if(!content) {
            return;
        }

        editCompanyDocumentTemplateContent({
            id: templateContent.id,
            content: content
        }).then(response => {
            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.templateContent.templateContentSaved, 'success');
            setTemplateContent(response.data.entity)
            setContent(response.data.entity.content);
        });
    }

    const addTemplateItem = (item) => {
        setContent(content + item.code);
    }

    const renderTemplateItems = () => {
        let result = [];
        for(let item of items) {
            result.push(
                <Button size={'small'} variant="contained" color="primary" className = 'rounded-input' onClick={ () => addTemplateItem(item) } >
                    { item.name }
                </Button>
            )
        }
        return result;
    }

    return (
        <Grid className='template-content' item md={ 12 }>
            <div className='editor-container'>
                <div className='header'>
                    <h1>{ strings.templateContent.pageTitle }</h1>
                    <Button variant="contained" color="primary" className='rounded-input centered-flex-row py-3 px-2 mb-3 ml-5' onClick={ () => submit() } >
                        <img className = 'mr-2' src = '/images/disk.svg' />
                        { strings.common.save }
                    </Button>
                </div>
                <Paper className='items-container'>
                    {renderTemplateItems()}
                </Paper>
                <Paper className='paper'>
                     <QuillEditor className='large-editor' value={content} onChange={setContent}/>
                </Paper>
            </div>
        </Grid>
    );

}

export default TemplateContent;
