import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function addTransaction(data) {
    return await request('/api/transactions', transformRequestData(data), HttpMethod.POST);
}

export async function editTransaction(data) {
    return await request('/api/transactions/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function getTransactions(data) {
    return await request('/api/transactions', data);
}

export async function getAllTransactions() {
    return await request('/api/transactions/all', {});
}

export async function approveTransactions(data) {
    return await request('/api/transactions/approve', data, HttpMethod.POST);
}

export async function connectTransactions(data) {
    return await request('/api/transactions/connect', data, HttpMethod.PUT);
}

export async function splitTransaction(data) {
    return await request('/api/transactions/split/', data, HttpMethod.POST);
}

export async function deleteTransaction(id){
    return await request('/api/transactions/' + id , {}, HttpMethod.DELETE)
}


function transformRequestData(data) {

    return {
        ...data,
        bankReportId: data.bankReport ? data.bankReport.id : null,
        currencyId: data.currency ? data.currency.id : null,
        employeeId: data.employee ? data.employee.id : null,
        partnerId: data.partner ? data.partner.id : null,
        contractId: data.contract ? data.contract.id : null,
        contractPartId: data.contractPart ? data.contractPart.id : null,
        invoiceId: data.invoice ? data.invoice.id : null,
        transactionCategoryId: data.transactionCategory ? data.transactionCategory.id : null,
        paymentTypeId: data.paymentType ? data.paymentType.id : null,
        transactionTypeId: data.transactionType ? data.transactionType.id : null,
        tax: data?.tax?.value
    }

}