import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Paper } from "@mui/material";
import {
  addAccommodationCost,
  addCalculation,
  addOtherCost,
  addTransportationCost,
  patchBusinessTrip,
} from "../../../Services/HR/BusinessTripService";
import { getAllCurrencies } from "../../../Services/Base/CurrencyService";
import { useParams } from "react-router-dom";
import SnackbarContext from "../../../Context/SnackbarContext";
import strings from "../../../localization";
import BusinessTripCalculationForm from "../../../Components/Forms/Pages/HR/BusinessTrip/BusinessTripCalculationForm";
import LinearProgress from "@mui/material/LinearProgress";

const formRules = {
  startTrip: {
    required: {
      value: true,
      message: strings.forms.common.thisFieldIsRequired,
    },
  },
  endTrip: {
    required: {
      value: true,
      message: strings.forms.common.thisFieldIsRequired,
    },
  },
  exchangeRate: {
    required: {
      value: true,
    },
  },
};
const AddCalculation = ({ travelWarrant, onFinish }) => {
  const { showMessage } = useContext(SnackbarContext);
  const [showLoader, setShowLoader] = useState(false);

  const form = useForm({
    defaultValues: {
      startTrip: new Date(),
      borderCrossingDeparture: "",
      borderCrossingArrival: "",
      endTrip: new Date(),
      paidExpensesDomesticCurrency: { code: travelWarrant.domesticCurrency },
      paidExpensesForeignCurrency: { code: travelWarrant.foreignCurrency },
      paidExpensesWithRemittancesCurrentAccount: {
        code: travelWarrant.domesticCurrency,
      },
      paidExpensesWithRemittancesForeignCurrencyAccount: {
        code: travelWarrant.foreignCurrency,
      },
      otherAmountDomesticCurrency: { code: travelWarrant.domesticCurrency },
      otherAmountForeignCurrency: { code: travelWarrant.foreignCurrency },
      otherAmountInDomesticCurrencyDeducted: {
        code: travelWarrant.domesticCurrency,
      },
      otherAmountForeignCurrencyDeducted: {
        code: travelWarrant.foreignCurrency,
      },
      paidExpensesViaBusinessCardDomesticCurrency: 0.0,
      paidExpensesViaBusinessCardInForeignCurrency: 0.0,
      paidExpensesWithRemittancesFromCurrentAccount: 0.0,
      paidExpensesWithRemittancesFromForeignCurrencyAccount: 0.0,
      otherAmountDomesticCurrencyDeducted: 0.0,
      otherAmountInForeignCurrencyDeducted: 0.0,
    },
  });
  const {
    data,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  const [currencies, setCurrencies] = useState([]);
  const businessTripId = useParams("id");
  const id = parseInt(businessTripId.id);

  useEffect(() => {
    getAllCurrencies().then((response) => {
      const filteredCurrencies = response.data.result.filter(
        (data) =>
          data.code == travelWarrant.domesticCurrency ||
          data.code == travelWarrant.foreignCurrency
      );

      setCurrencies(filteredCurrencies);
    });
  }, []);

  const removeTimeZoneOffset = (dateTime) => {
    if (dateTime) {
      return new Date(
        dateTime.getTime() - dateTime.getTimezoneOffset() * 60 * 1000
      );
    }
  };

  const onSubmit = (data) => {
    setShowLoader(true);
    const {
      startTrip,
      endTrip,
      borderCrossingArrival,
      borderCrossingDeparture,
      ...calc
    } = data;
    const calculation = {
      ...calc,
      startTrip: removeTimeZoneOffset(startTrip),
      endTrip: removeTimeZoneOffset(endTrip),
      borderCrossingArrival: removeTimeZoneOffset(borderCrossingArrival),
      borderCrossingDeparture: removeTimeZoneOffset(borderCrossingDeparture),
      businessTrip: id,
    };

    addCalculation(calculation)
      .then((response) => {
        setShowLoader(false);
        if (!response || !response.ok) {
          showMessage(strings.commonMessages.errorAdding, "error");
          onFinish();
          return;
        }

        const calculationID = response.data.entity.id;
        const accommodationPromises = [];
        const transportationPromises = [];
        const otherPromises = [];

        for (const key in calculation) {
          if (key.startsWith("accommodationName-") && calculation[key]) {
            const index = key.substring(18);
            const amountKey = `accommodationAmount-${index}`;
            const currencyKey = `accommodationCurrency-${index}`;

            if (calculation[amountKey]) {
              const name = calculation[key];
              const amount = calculation[amountKey];
              const currency = calculation[currencyKey];
              const accommodationData = {
                accommodationName: name,
                amount: amount,
                currency: currency.code,
                calculation: calculationID,
              };
              if (
                accommodationData.amount &&
                accommodationData.accommodationName &&
                accommodationData.currency
              ) {
                accommodationPromises.push(
                  addAccommodationCost(accommodationData)
                );
              }
            }
          }

          if (key.startsWith("transportationName-") && calculation[key]) {
            const index = key.substring(19);
            const amountKey = `transportationAmount-${index}`;
            const currencyKey = `transportationCurrency-${index}`;

            if (calculation[amountKey]) {
              const name = calculation[key];
              const amount = calculation[amountKey];
              const currency = calculation[currencyKey];
              const transportationData = {
                transportName: name,
                amount: amount,
                currency: currency.code,
                calculation: calculationID,
              };
              if (
                transportationData.amount &&
                transportationData.transportName &&
                transportationData.currency
              ) {
                transportationPromises.push(
                  addTransportationCost(transportationData)
                );
              }
            }
          }

          if (key.startsWith("otherName-") && calculation[key]) {
            const index = key.substring(10);
            const amountKey = `otherAmount-${index}`;
            const currencyKey = `otherCurrency-${index}`;

            if (calculation[amountKey]) {
              const name = calculation[key];
              const amount = calculation[amountKey];
              const currency = calculation[currencyKey];
              const otherData = {
                nameOfOtherCost: name,
                amount: amount,
                currency: currency.code,
                calculation: calculationID,
              };
              if (
                otherData.amount &&
                otherData.nameOfOtherCost &&
                otherData.currency
              ) {
                otherPromises.push(addOtherCost(otherData));
              }
            }
          }
        }

        Promise.all([
          ...accommodationPromises,
          ...transportationPromises,
          ...otherPromises,
        ])
          .then(() => {
            patchBusinessTrip(id)
              .then((response) => {
                if (!response || !response.ok) {
                  showMessage(strings.commonMessages.errorAdding, "error");
                  onFinish();
                  return;
                }
                showMessage(strings.commonMessages.addingSuccessful, "success");
                onFinish();
              })
              .catch((error) => {
                showMessage(strings.commonMessages.errorAdding, "error");
                onFinish();
              });
          })
          .catch((error) => {
            showMessage(strings.commonMessages.errorAdding, "error");
            onFinish();
          });
      })
      .catch((error) => {
        showMessage(strings.commonMessages.errorAdding, "error");
        onFinish();
      });
  };

  const onCancel = () => {
    onFinish();
  };

  return (
    <div className="drawer-form">
      <Paper className="paper">
      {showLoader && <LinearProgress />}
        <BusinessTripCalculationForm
          values={getValues()}
          setValue={setValue}
          formRules={formRules}
          data={data}
          errors={errors}
          form={form}
          control={control}
          onSubmit={handleSubmit(onSubmit)}
          onCancel={() => onCancel()}
          currencies={currencies}
        />
      </Paper>
    </div>
  );
};

export default AddCalculation;
