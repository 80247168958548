import { request } from "../../Base/HTTP";
import { saveAs } from "file-saver";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getBusinessTripByCompany(company) {
  return await request("/api/business-trip/" + company);
}

export async function getDecisionByBusinessTrip(id) {
  return await request("/api/business-trip-decision/" + id);
}

export async function getCalculationByBusinessTrip(id) {
  return await request("/api/business-trip-calculation/" + id);
}

export async function getBusinessTrip(id) {
  return await request("/api/business-trip/" + id);
}
export async function getTransportationCosts(id) {
  return await request("/api/business-trip-transportation-costs/" + id);
}
export async function getAccommodationCosts(id) {
  return await request("/api/business-trip-accommodation-costs/" + id);
}
export async function getOtherCosts(id) {
  return await request("/api/business-trip-other-costs/" + id);
}
export async function downloadDecision(id) {
  request("/api/decision/download/" + id, {}, HttpMethod.GET, {
    responseType: "blob",
  }).then((res) => {
    const newBlob = new Blob([res.data], { type: "application/pdf" });
    const downloadUrl = window.URL.createObjectURL(newBlob);
    saveAs(downloadUrl, res.headers["file-name"]);
  });
}
export async function downloadCalculation(id) {
  request("/api/calculation/download/" + id, {}, HttpMethod.GET, {
    responseType: "blob",
  }).then((res) => {
    const newBlob = new Blob([res.data], { type: "application/pdf" });
    const downloadUrl = window.URL.createObjectURL(newBlob);
    saveAs(downloadUrl, res.headers["file-name"]);
  });
}
export async function downloadTravelWarrant(id) {
  request("/api/travelWarrant/download/" + id, {}, HttpMethod.GET, {
    responseType: "blob",
  }).then((res) => {
    const newBlob = new Blob([res.data], { type: "application/pdf" });
    const downloadUrl = window.URL.createObjectURL(newBlob);
    saveAs(downloadUrl, res.headers["file-name"]);
  });
}

export async function addBusinessTrip(data) {
  return await request(
    "/api/business-trip",
    transforRequestData(data),
    HttpMethod.POST
  );
}

export async function addCalculation(data) {
  return await request(
    "/api/business-trip-calculation",
    transformRequestDataCalculation(data),
    HttpMethod.POST
  );
}

export async function addTransportationCost(data) {
  return await request(
    "/api/business-trip-transportation-cost",
    transforRequestDataCost(data),
    HttpMethod.POST
  );
}
export async function addAccommodationCost(data) {
  return await request(
    "/api/business-trip-accommodation-cost",
    transforRequestDataCost(data),
    HttpMethod.POST
  );
}
export async function addOtherCost(data) {
  return await request(
    "/api/business-trip-other-cost",
    transforRequestDataCost(data),
    HttpMethod.POST
  );
}

export async function addDecision(data) {
  return await request(
    "/api/business-trip-decision",
    transforRequestDataDecision(data),
    HttpMethod.POST
  );
}

export async function updateBusinessTrip(data) {
  return await request(
    "/api/business-trip/" + data.id,
    transforRequestData(data),
    HttpMethod.PUT
  );
}

export async function deleteBusinessTrip(id) {
  return await request("/api/business-trip/" + id, {}, HttpMethod.DELETE);
}

export async function deleteTransportationCost(id) {
  return await request(
    "/api/business-trip-transportation-cost/" + id,
    {},
    HttpMethod.DELETE
  );
}
export async function deleteAccommodationCost(id) {
  return await request(
    "/api/business-trip-accommodation-cost/" + id,
    {},
    HttpMethod.DELETE
  );
}
export async function deleteOtherCost(id) {
  return await request(
    "/api/business-trip-other-cost/" + id,
    {},
    HttpMethod.DELETE
  );
}

export async function deleteDecision(id) {
  return await request(
    "/api/business-trip-decision/" + id,
    {},
    HttpMethod.DELETE
  );
}

export async function deleteCalculation(id) {
  return await request(
    "/api/business-trip-calculation/" + id,
    {},
    HttpMethod.DELETE
  );
}

export async function updateDecision(data) {
  return await request(
    "/api/business-trip-decision/" + data.id,
    transforRequestDataDecision(data),
    HttpMethod.PUT
  );
}

export async function patchBusinessTrip(id) {
  return await request("/api/business-trip/" + id, {}, HttpMethod.PATCH);
}

export async function getBusinessTripDailyWages(id) {
  return await request("/api/business-trip-daily-wages/{id}" + id);
}

export async function updateBusinessTripDailyWages(data) {
  return await request(
    "/api/business-trip-daily-wages/{id}",
    transforRequestDataDailyWages(data),
    HttpMethod.PUT
  );
}

function transforRequestDataDailyWages(data) {
  return {
    ...data,
    foreignDailyWageCurrencyId: data.foreignDailyWageCurrency
      ? data.foreignDailyWageCurrency.id
      : null,
  };
}

function transformRequestDataCalculation(data) {
  return {
    ...data,
  };
}

function transforRequestData(data) {
  return {
    ...data,
    company: data.company ? data.company.id : null,
  };
}

function transforRequestDataDecision(data) {
  return {
    ...data,
    companyUser: data.companyUser ? data.companyUser.id : null,
  };
}

function transforRequestDataCost(data) {
  return {
    ...data,
  };
}
