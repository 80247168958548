import React from "react";
import {useEffect, useState} from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import {deleteRole, getRoles} from "../../../Services/User/RoleService";
import EditRole from "./EditRole";
import AddRole from "./AddRole";
import {LockOpen} from "@mui/icons-material";
import {useNavigate, useSearchParams} from "react-router-dom";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [
    {field: 'name', headerName: strings.pages.administration.role.roleList.name, width: 250},
];

const filters = []

const RoleList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.ROLE;
    const permissionGroup = AppPermissions.ROLE.GROUP;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog, navigate,
        updatedFilter, setUpdatedFilter
    }

    const additionalMenuItems = [
        {
            handleClick: (id, item) => handlePermissions(id, item),
            text: strings.pages.administration.role.roleList.permissions,
            src: "/images/table-page/permissions.svg"
        },
    ]

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.administration.role.roleList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.ADMINISTRATION},
                {label: strings.pages.administration.role.roleList.pageTitle},
            ],        
        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getRoles({
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    const handlePermissions = (id, role) => {

        navigate('/role-permissions', {state: {id, role}})
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteRole} tableDescription={columns}
                   tableData={tableData} filter={filter} filters={filters}
                   additionalMenuItems={additionalMenuItems} referenceType={referenceType}
                   permissionGroup={permissionGroup}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditRole data={selectedItems}
                                       onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addPage={<AddRole onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addButtonText={strings.components.tablePage.addButton.role}/>
    </TablePageContext.Provider>;
}

export default RoleList;
