import React from 'react';
import strings from '../../../../localization';
import Button from '@mui/material/Button';
import {FormProvider } from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";

const LoginForm = ({
                       onSubmit,
                       data,
                       form,
                       errors,
                       formRules
                   }) => (
    <FormProvider {...form}>
        <form id='lock-form' className='form' onSubmit={onSubmit} action = "#">

            <TextFieldControl
                name='password'
                type='password'
                control={data}
                defaultValue=''
                rules={formRules['password']}
                fullWidth
                margin="normal"
                error={ Boolean(errors.password) }
                label={strings.forms.lock.password}
                helperText={errors.password && errors.password.message}
                placeholder={strings.forms.login.passwordPlaceholder}
            />

            <div className='submit-container'>
                <Button type='submit' variant="contained" color="primary" className='unlock-btn'>
                    { strings.forms.lock.unlock }
                </Button>
            </div>
        </form>
    </FormProvider>

);

export default LoginForm;
