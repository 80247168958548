import React from "react";
import {useSelector} from "react-redux";
import {WooCommerceSettingsTab} from "../../../Constants/Products/WooCommerceSettingsTab";
import {useNavigate} from "react-router-dom";
import {Paper} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import strings from "../../../localization";
import WooCommerceSettings from "./WooCommerceSettings";

const ProductSettings = ({activeTab = WooCommerceSettingsTab.WOO_COMMERCE}) => {
    const auth = useSelector((state)=>state.auth);
    const navigate = useNavigate();

    const changeActiveTab = (tab) =>{
        if(tab === WooCommerceSettingsTab.WOO_COMMERCE){
            navigate(`/woo-commerce-settings`)
        }
    }

    return (
        <div id="woo-commerce-settings">
            <div className='tab-container mb-12 mx-3'>
                <Paper className='paper'>
                    <Tabs variant="scrollable" value={activeTab} onChange={(e,tab) =>changeActiveTab(tab)}>
                        <Tab label={strings.pages.product.wooCommerce.wooCommerceSettings}/>
                    </Tabs>
                </Paper>
            </div>

            {
                activeTab === WooCommerceSettingsTab.WOO_COMMERCE &&
                <WooCommerceSettings/>
            }
        </div>
    )
}
export default ProductSettings;