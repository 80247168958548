import { useEffect, useState } from 'react';

const TimeElapsedCounter = ({date, stop}) => {
    const [time, setTime] = useState({h:'00', m:'00' , s:'00'});
    const [timeInterval, setTimeInterval] = useState(null);

    useEffect(() => {
        if(stop && timeInterval){
            clearInterval(timeInterval);
            setTimeInterval(null);
            setTime({h:'00', m:'00' , s:'00'});
        }
    },[stop, timeInterval]);


    useEffect(() => {
        if(!date || stop){
            return;
        }

        if(!timeInterval){
            updateClock();
            setTimeInterval(setInterval(updateClock, 1000))
        }   

        return () => {
            clearInterval(timeInterval);
        }
    },[date, timeInterval, stop]);

    let startDateTime = new Date(date);

    let startStamp = startDateTime.getTime();
    let newDate = new Date();
    let newStamp = newDate.getTime();

    const updateClock = () =>  {
        newDate = new Date();
        newStamp = newDate.getTime();
        let diff = Math.round((newStamp-startStamp)/1000);
        let d = Math.floor(diff/(24*60*60));
        diff = diff-(d*24*60*60);
        let h = Math.floor(diff/(60*60));
        diff = diff-(h*60*60);
        let m = Math.floor(diff/(60));
        diff = diff-(m*60);
        let s = diff;

        if(h < 10){
            h = '0' + h
        }

        if(m < 10){
            m = '0' + m
        }

        if(s < 10){
            s = '0' + s
        }

        setTime({d,h,m,s});
    }
    
    return <label>{`${time.h}:${time.m}:${time.s}`}</label>
}

export default TimeElapsedCounter;
