import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Paper } from "@mui/material";
import { addDecision } from "../../../Services/HR/BusinessTripService";
import { useParams } from "react-router-dom";
import { getAllCurrencies } from "../../../Services/Base/CurrencyService";
import BusinessTripDecisionForm from "../../../Components/Forms/Pages/HR/BusinessTrip/BusinessTripDecisionForm";
import SnackbarContext from "../../../Context/SnackbarContext";
import strings from "../../../localization";
import LinearProgress from "@mui/material/LinearProgress";

const formRules = {
  amountOfDailyWageInCountry: {
    required: {
      value: true,
    },
  },
  domesticCurrency: {
    required: {
      value: true,
    },
  },
  foreignCurrency: {
    required: {
      value: true,
    },
  },
  amountOfDailyWageInCountry: {
    required: {
      value: true,
    },
  },
  freeAccommodationAndFood: {
    required: {
      value: true,
    },
  },
  meansOfTransport: {
    required: {
      value: true,
    },
  },
  reasonToTravel: {
    required: {
      value: true,
    },
  },
  travelDestination: {
    required: {
      value: true,
    },
  },
  tripDuration: {
    required: {
      value: true,
    },
  },
  companyUser: {
    required: {
      value: true,
    },
  },
};

const AddDecision = ({
  domesticCurrency,
  onFinish,
  users,
  fetchUsers,
  foreignDailyWage,
  domesticDailyWage,
  foreignDailyWageCurrency,
}) => {
  const { showMessage } = useContext(SnackbarContext);
  const businessTripId = useParams("id");
  const id = parseInt(businessTripId.id);
  const currentDate = new Date();
  const currentDateString = currentDate.toISOString().split("T")[0];
  const [currencies, setCurrencies] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const form = useForm({
    defaultValues: {
      amountOfDailyWageInCountry: domesticDailyWage,
      amountOfDailyWageAbroad: foreignDailyWage,
      advancePaymentDomesticCurrency: 0.0,
      advancePaymentForeignCurrency: 0.0,
      domesticCurrency: domesticCurrency,
      foreignCurrency: foreignDailyWageCurrency,
      dayOfDeparture: currentDateString,
    },
  });

  const {
    data,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  useEffect(() => {
    getAllCurrencies().then((response) => {
      setCurrencies(response.data.result);
    });
  }, []);

  const onSubmit = (data) => {
    setShowLoader(true);
    const decision = {
      ...data,
      businessTrip: id,
      domesticCurrency: data.domesticCurrency.code,
      foreignCurrency: data.foreignCurrency.code,
    };

    addDecision(decision).then((response) => {
      setShowLoader(false);
      if (!response || !response.ok) {
        showMessage(strings.commonMessages.errorAdding, "error");
        onFinish();
        return;
      }

      showMessage(strings.commonMessages.addingSuccessful, "success");
      onFinish();
    });
  };

  const onCancel = () => {
    onFinish();
  };

  return (
    <div className="drawer-form">
      <Paper className="paper">
      {showLoader && <LinearProgress />}
        <BusinessTripDecisionForm
          values={getValues()}
          formRules={formRules}
          setValue={setValue}
          users={users}
          data={data}
          errors={errors}
          form={form}
          control={control}
          onSubmit={handleSubmit(onSubmit)}
          onCancel={() => onCancel()}
          fetchUsers={fetchUsers}
          currencies={currencies}
        />
      </Paper>
    </div>
  );
};

export default AddDecision;
