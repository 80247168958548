import React, {useContext, useState} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import CheckBoxControl from "../../../../Controls/Inputs/CheckBoxControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import FileUploadComponent from "../../../../Controls/FileUploadComponent";
import {toBase64} from "../../../../../Util/ImageUtil";
import AutoCompleteControl from '../../../../Controls/Inputs/AutoCompleteControl';
import { mbToBytes } from '../../../../../Util/FileUtil';
import {ImageFormats} from "../../../../../Constants/FileFormat";

const InventoryTypeForm = ({
                         onSubmit,
                         onCancel,
                         data,
                         form,
                         errors,
                         inventoryCategories,
                         control,
                         values,
                         setValue,
                         formRules,
                         onCategoryChangeFilter,
                     }) => {

    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [imagePreview, setImagePreview] = useState(values['image']);
    const [files, setFiles] = useState([]);
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    const uploadImage = async (files) => {
        setDisabledSubmit(true)
        const logo = await toBase64(files[0]);
        setValue('image', logo);
        setImagePreview(logo)
        setDisabledSubmit(false)
    }

    return (
        <FormProvider {...form}>
            <form id='inventory-type-form' className='form' action="#">

                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.assetManagement.inventoryTypeForm.name}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='description'
                    rules={formRules['description']}
                    control={data}
                    label={strings.forms.assetManagement.inventoryTypeForm.description}
                    disabled={isPageStateViewDetails()}
                />

                <CheckBoxControl
                    control={control}
                    name={'canReturnTax'}
                    label={strings.forms.assetManagement.inventoryTypeForm.canReturnTax}
                    value={values['canReturnTax']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    name='category'
                    label={strings.forms.assetManagement.inventoryTypeForm.category}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={setValue}
                    control={control}
                    options={inventoryCategories}
                    value={values['category']}
                    rules={formRules['category']}
                    error={Boolean(errors.category)}
                    helperText={errors.category && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                    onChange={onCategoryChangeFilter}
                    onOpen={() =>onCategoryChangeFilter('')}
                />

                {!isPageStateViewDetails() && <FileUploadComponent hideUploadButton={true} onDrop={uploadImage} formats={ImageFormats} files = {files} setFiles = {setFiles} />}
                {
                    <div className='logo'>
                        {imagePreview && <img src={imagePreview} width={200}/>}
                    </div>
                }


                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }

            </form>
        </FormProvider>
    );
}

export default InventoryTypeForm;
