import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import LinearProgress from '@mui/material/LinearProgress';
import {Paper} from "@mui/material";
import DrawerContext from "../../../Context/DrawerContext";
import strings from "../../../localization";
import { addDocument } from "../../../Services/DocumentManagement/DocumentService";
import { getDocumentCategories } from "../../../Services/DocumentManagement/DocumentCategoryService";
import { getDocumentStatuses } from "../../../Services/DocumentManagement/DocumentStatusService";
import DocumentForm from "../../../Components/Forms/Pages/Document/Document/DocumentForm";
import SnackbarContext from "../../../Context/SnackbarContext";
import { getSizeDescription } from "../../../Util/FileUtil";

const formRules = {
    'name': {required: true}
}

const AddDocument = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm();
    const {data,handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [documentStatus, setDocumentStatus] = useState([]);
    const [documentCategory, setDocumentCategory] = useState([]);

    useEffect(() => {
        setDrawerTitle(strings.pages.document.document.addDocument.pageTitle);
    });

    useEffect(() => {
        fetchDocumentCategories();
        fetchDocumentStatuses();
    }, [])

    const onSubmit = (data) => {
        setShowLoader(true);

        console.log("Data: ", data)


        if(!data.file){
            showMessage(strings.pages.document.document.addDocument.fileRequired, 'error');
            setShowLoader(false);
            return;
        }

        data = {...data, referenceId: props.referenceId, referenceType:props.referenceType, documentSize: getSizeDescription(data.file.size).replace(/[()]/g,'')}

        addDocument(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                if (strings.error.DocumentHandler[response.response.data.message]) {
                    showMessage(strings.error.DocumentHandler[response.response.data.message], 'error');
                }
                props.onFinish();
                return response;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const fetchDocumentCategories = (term = '') => {
        getDocumentCategories({term}).then(response => {
            if (!response || !response.ok){
                return;
            }

            setDocumentCategory(response.data.result);
        })
    }

    const fetchDocumentStatuses = (term = '') => {
        getDocumentStatuses({term}).then(response => {
            if (!response || !response.ok){
                return;
            }

            setDocumentStatus(response.data.result);
        })
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <DocumentForm
                formRules={formRules} form={form}
                errors={errors} data={data}
                values={getValues()} onChangeDocumentCategoryFilter={fetchDocumentCategories}
                setValue={setValue} onChangeDocumentStatusFilter={fetchDocumentStatuses}
                documentCategories={documentCategory} documentStatuses={documentStatus}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}
                />
        </Paper>
    </div>
}

export default AddDocument;