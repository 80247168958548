import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";

export async function addTagReference(data) {
    return await request('/api/tag_reference', transformRequestData(data), HttpMethod.POST);
}

export async function editTagReference(data) {
    return await request('/api/tag_reference/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteTagReference(id) {
    return await request('/api/tag_reference/' + id, {}, HttpMethod.DELETE);
}

export async function getTagByReferenceTypeAndReferenceId(data) {
    return await request('/api/tag_reference/getByReferenceTypeAndReferenceId/' + data.referenceType + "/" + data.referenceId);

}

function transformRequestData(data) {
    return {
        ...data,
        name: data && data.name ? data.name : null,
        color: data && data.color ? data.color : null,
        referenceType: data && data.referenceType ? data.referenceType : -1,
        referenceId: data && data.referenceId ? data.referenceId: -1
    }
}