import React, {useState} from 'react';
import MenuState from "../Constants/Base/MenuState";
import Navigation from "../Components/Layout/Navigation";
import Header from "../Components/Layout/Header";
import Footer from "../Components/Layout/Footer";
import {useSelector} from "react-redux";
import PageSizeState from "../Constants/Base/PageSizeState";
import { useLocation } from 'react-router-dom';
import BreadcrumbsNavigation from '../Components/Layout/BreadcrumbsNavigation'

const noPaddingLocations = [
    '/start-trial',
]

const getContentClass = (state, pageSizeState) => {

    if(pageSizeState === PageSizeState.FULL) {
        return 'content-container short';
    }
    if(pageSizeState === PageSizeState.FULL_NO_HEADER) {
        return 'content-container short no-header';
    }

    if(pageSizeState === PageSizeState.LOCK) {
        return 'content-container short no-header lock';
    }

    if(state === MenuState.SHORT) {
        return 'content-container short';
    }

    return 'content-container';
}

const getMainContainerClass = (state) => {

    if(state === PageSizeState.SHORT) {
        return 'short';
    }

    return 'full';
}

const getMainContainerPadding = (path) =>{
    if(!path){
        return ''
    }

    if(noPaddingLocations.find(x => x === path)){
        return 'no-padding'
    }

    return '';
}

const BaseLayout = (props) => {

    const menuState = useSelector((state) => state.navigation.menuState)
    const pageSizeState = useSelector((state) => state.page.pageSizeState)
    const location = useLocation();

    return <React.Fragment>
            <div id='main-container' className={ getMainContainerClass(pageSizeState) }>
                <div className='navigation-container'>
                    <Navigation/>
                </div>
                <div className={ `${getContentClass(menuState, pageSizeState)} ${getMainContainerPadding(location?.pathname)}` }>
                    <div className={'header-container'}>
                        <Header/>
                    </div>
                    <BreadcrumbsNavigation/>
                    { props.children }
                    <div className={'footer-container'}>
                        <Footer/>
                    </div>
                </div>
            </div>

    </React.Fragment>
};

export default BaseLayout;
