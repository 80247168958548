import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import ProjectTimeLogForm from "../../../Components/Forms/Pages/Project/ProjectTimeLog/ProjectTimeLogForm";
import DrawerContext from "../../../Context/DrawerContext";
import {addProjectTimeLog} from "../../../Services/Project/ProjectTimeLogService";
import LinearProgress from '@mui/material/LinearProgress';
import SnackbarContext from "../../../Context/SnackbarContext";
import { getProjectList } from "../../../Services/Project/ProjectService";
import { getProjectTaskCategories, getProjectTaskCategory } from "../../../Services/Project/ProjectTaskCategoryService";
import {useSelector} from "react-redux";
import {getEmployees} from "../../../Services/User/UserService";

const formRules = {
    'dateLogged': {required: true},'hourLogged': {required: true},
    'project': {required: true}, 'name': {required: true},
}

const AddProjectTimeLog = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: {
            dateLogged: new Date(),
            project: props ? props.projectObject : null
        }
    });
    const {data, control, handleSubmit, getValues, watch, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [projects, setProjects] = useState([]);
    const [taskCategories, setTaskCategories] = useState([]);
    const [projectTaskCategories, setProjectTaskCategories] = useState([]);
    const [billable, setBillable] = useState(false);
    const [employees, setEmployees] = useState([]);
    const auth = useSelector((state) => state.auth.user)

    watch('taskCategory');

    useEffect(() => {
        setDrawerTitle(strings.pages.project.projectTimeLog.addProjectTimeLog.pageTitle);
    });

    useEffect(() => {
        fetch(); 
        fetchProjects();
        fetchEmployees();
    },[])

    const fetch = () => {
        if(props.project){
            getProjectTaskCategories(props.project).then(response => {
                if (!response || !response.ok) {
                    return;
                }

                setTaskCategories(response.data.result);
            });

            getProjectTaskCategory(props.project).then(response => {
                if (!response || !response.ok){
                    return;
                }
                setProjectTaskCategories(response.data.result)
    
            })
        }
    }

    const fetchProjects = (term = '') => {
        getProjectList({term}).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setProjects(response.data.result);
        })
    }

    const fetchEmployees = (term = '') => {

        if(auth.isOwner){
            setValue('employeeUser', auth);
        }

        getEmployees({term}).then(response => {
            setEmployees(response.data.result.map(u => u.user));
        })
    }


    const onSubmit = (data) => {

        setShowLoader(true);

        addProjectTimeLog(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onSave = (data) => {

        setShowLoader(true);

        addProjectTimeLog(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish(false);
                return;
            }

            setValue('name', '');
            setValue('hourLogged', '');

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish(false);

        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    const handleProjectChange = (newValue) => {
        setValue('project', newValue);

        if(!newValue){
            setValue('taskCategory', null);
            setValue('billable', false);
            setBillable(false);
            setTaskCategories([]);
        }
    
        getProjectTaskCategories(newValue?.id).then(response => {
            if (!response || !response.ok) {
                return;
            }

            setTaskCategories(response.data.result);
        });
        
        if(getValues()['taskCategory']){
            getProjectTaskCategory(getValues()['project'].id).then(response => {
                if (!response || !response.ok){
                    return;
                }
                setProjectTaskCategories(response.data.result);                

                updateBillableField(getValues()['taskCategory'], response.data.result);
            });
        }
    }

    const handleTaskCategoryChange = (newValue) => {
        if(!newValue){
            setValue('billable', false);
            setBillable(false);
        }

        if(!props.project){
            getProjectTaskCategory(getValues()['project'].id).then(response => {
                if (!response || !response.ok){
                    return;
                }
                setProjectTaskCategories(response.data.result);
                
                updateBillableField(newValue, response.data.result);
            })
            return;
        }

        updateBillableField(newValue, projectTaskCategories)
    }
    
    const updateBillableField = (newValue, projectTaskCategories) => {
        let matchTaskCategory = projectTaskCategories.filter(p => p.taskCategory.id == newValue?.id);

        setBillable(undefined);

        setValue('taskCategory', newValue);
        setValue('billable', matchTaskCategory[0]?.billable);
        
        setBillable(matchTaskCategory[0]?.billable);
    }

    const changeEmployee = (employee) => {
        setValue('employeeUser', employee);
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <ProjectTimeLogForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                control={control}
                projects = {projects} onChangeProjectsFilter={fetchProjects}
                onChangeEmployeeFilter={fetchEmployees}
                errors={errors} data={data} form={form}
                taskCategories={taskCategories} employees={employees} isOwner={auth.isOwner}
                handleProjectChange={(newValue) => handleProjectChange(newValue)} changeEmployee={changeEmployee}
                handleTaskCategoryChange={ newValue => handleTaskCategoryChange(newValue)} billable={billable}
                onSubmit={handleSubmit(onSubmit)} handleSubmitSave={handleSubmit(onSave)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default AddProjectTimeLog;