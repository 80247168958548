import React, {useEffect, useState} from "react";
import {Paper, Tab, Tabs} from "@mui/material";
import strings from "../../../localization";
import {useDispatch, useSelector} from "react-redux";
import { hasPermission } from "../../../Util/PermissionUtil";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {ProjectSettingsTab} from "../../../Constants/Project/ProjectSettingsTab";
import ProjectCategoryList from "../ProjectCategory/ProjectCategoryList";
import ProjectResourceTypeList from "../ProjectResourceType/ProjectResourceTypeList";
import TaskCategoryList from "../TaskCategory/TaskCategoryList";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import {changePageSizeState} from "../../../Slices/PageSlice";
import { useNavigate } from 'react-router-dom';

const ProjectSettings = ({activeTab = ProjectSettingsTab.PROJECT_CATEGORY}) => {
    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const changeActiveTab = (tab) => {
        if(tab === ProjectSettingsTab.PROJECT_CATEGORY){
            navigate('/project-settings/project-categories');
        }

        if(tab === ProjectSettingsTab.PROJECT_RESOURCE_TYPE){
            navigate('/project-settings/resource-types');
        }

        if(tab === ProjectSettingsTab.TASK_CATEGORY){
            navigate('/project-settings/task-categories');
        }
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: hasPermission(auth.user, AppPermissions.PROJECT_CATEGORY.GROUP, AppPermissions.PROJECT_CATEGORY.VIEW_LIST, auth.permissions) ? strings.pages.project.projectCategory.projectCategoryList.pageTitle : strings.pages.project.settings, 
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.PROJECTS},
                {label: strings.pages.project.settings},
                {label: strings.pages.project.projectCategory.projectCategoryList.pageTitle},
            ],        
        }));
    }, [])

    return (
        <div id="project-settings">
            <div className='tab-container ml-3'>
                <Paper className='paper'>
                    <Tabs variant="scrollable" value={activeTab} onChange={(e, tab) => changeActiveTab(tab)}>
                        <Tab label={strings.pages.project.projectCategory.projectCategoryList.pageTitle}
                             disabled={!hasPermission(auth.user, AppPermissions.PROJECT_CATEGORY.GROUP,
                                 AppPermissions.PROJECT_CATEGORY.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.project.projectResourceType.projectResourceTypeList.pageTitle}
                             disabled={!hasPermission(auth.user, AppPermissions.PROJECT_RESOURCE_TYPE.GROUP,
                                 AppPermissions.PROJECT_RESOURCE_TYPE.VIEW_LIST, auth.permissions)}
                        />
                        <Tab label={strings.pages.project.taskCategory.taskCategoryList.pageTitle}
                             disabled={!hasPermission(auth.user, AppPermissions.TASK_CATEGORY.GROUP,
                                 AppPermissions.TASK_CATEGORY.VIEW_LIST, auth.permissions)}
                        />
                    </Tabs>
                </Paper>
            </div>

            {
                activeTab === ProjectSettingsTab.PROJECT_CATEGORY &&
                <ProjectCategoryList/>
            }
            {
                activeTab === ProjectSettingsTab.PROJECT_RESOURCE_TYPE &&
                <ProjectResourceTypeList/>
            }
            {
                activeTab === ProjectSettingsTab.TASK_CATEGORY &&
                <TaskCategoryList/>
            }
        </div>
    );
}

export default ProjectSettings;
