import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllProjectCategories(data) {
    return await request('/api/project-categories/all', data);
}

export async function getProjectCategoryList(data) {
    return await request('/api/project-categories', data);
}

export async function getProjectCategory(id) {
    return await request('/api/project-category/' + id);
}

export async function deleteProjectCategory(id) {
    return await request('/api/project-category/' + id, {}, HttpMethod.DELETE);
}

export async function editProjectCategory(data) {
    return await request('/api/project-category/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function addProjectCategory(data) {
    return await request('/api/project-category', transformRequestData(data), HttpMethod.POST);
}

function transformRequestData(data) {

    return {
        ...data,
        parentId: data.parent ? data.parent.id : null,
    }
}
