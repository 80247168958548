import React from 'react';
import strings from '../../../../localization';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";

const AcceptCompanyInviteForm = ({
                                     onSubmit,
                                     form,
                                     errors,
                                     formRules,
                                     user
                                 }) => (
    <FormProvider {...form}>
        <form id='accept-company-invite-form' className='form'>

            {
                !user || !user.enabled &&
                <TextFieldControl
                    name='password'
                    defaultValue=''
                    rules={formRules['password']}
                    fullWidth
                    type='password'
                    margin="normal"
                    error={Boolean(errors.password)}
                    helperText={errors.password && strings.forms.user.acceptCompanyInvite.passwordError}
                    label={strings.forms.user.acceptCompanyInvite.password}
                    placeholder={strings.forms.user.acceptCompanyInvite.passwordPlaceholder}
                />
            }

            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    {strings.forms.user.acceptCompanyInvite.accept}
                </Button>
            </div>
        </form>
    </FormProvider>
);

export default AcceptCompanyInviteForm;
