import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getCandidateStatus(data){
    return await request('/api/candidate-status', data);
}

export async function addCandidateStatus(data){
    return await request('/api/candidate-status', transformRequestData(data), HttpMethod.POST);
}

export async function editCandidateStatus(data){
    return await request('/api/candidate-status/' + data.id , transformRequestData(data), HttpMethod.PUT);
}

export async function deleteCandidateStatus(id){
    return await request('/api/candidate-status/' + id , {}, HttpMethod.DELETE);
}

function transformRequestData(data){
    return {
        ...data,
    }
}