import React, {useEffect, useState } from "react";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import {deletePartnerContact, getPartnerContactList} from "../../../Services/CRM/PartnerContactService";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import { formatColumnObject } from "../../../Components/DataGrid/ValueFormatter";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import EditPartnerContact from "../PartnerContact/EditPartnerContact";
import AddPartnerContact from "../PartnerContact/AddPartnerContact";

const tableDescription = [
    {
        field: 'firstName', headerName: strings.pages.crm.partnerContact.partnerContactList.firstName,
        width: 200,
    },
    {
        field: 'lastName', headerName: strings.pages.crm.partnerContact.partnerContactList.lastName,
        width: 200,
    },
    {
        field: 'email', headerName: strings.pages.crm.partnerContact.partnerContactList.email,
        width: 200,
    },
    {
        field: 'phone', headerName: strings.pages.crm.partnerContact.partnerContactList.phone,
        width: 200,
    },
    {
        field: 'skype', headerName: strings.pages.crm.partnerContact.partnerContactList.skype,
        width: 200,
    },
    {
        field: 'linkedin', headerName: strings.pages.crm.partnerContact.partnerContactList.linkedin,
        width: 200,
    },
    {
        field: 'partner',
        headerName: strings.pages.crm.partnerContact.partnerContactList.partner,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 250
    }
];

const PartnerContacts = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const {partnerId: partnerId} = useParams();
    const [filter, setFilter] = useState({...FilterDefaults, partner : partnerId});
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.PARTNER_CONTACT;
    const permissionGroup = AppPermissions.PARTNER_CONTACT.GROUP;
    const [filterOptions, setFilterOptions] = useState({});

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    const filters = [];

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.crm.partnerContact.partnerContactList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.CRM},
                {label: strings.pages.crm.deal.dealList.pageTitle},
                {label: strings.pages.crm.deal.dealDetails.pageTitle},
                {label: strings.pages.crm.deal.dealDetails.contacts},
            ],
        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getPartnerContactList({
            ...filter
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deletePartnerContact} tableDescription={columns}
                   tableData={tableData} referenceType={referenceType}
                   filter={filter} filters={filters} filterOptions={filterOptions}
                   permissionGroup={permissionGroup}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditPartnerContact data={ selectedItems }
                                                 onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addPage={<AddPartnerContact onCancel={() => onCancel()} onFinish={() => onFinish()} partner={props?.deal?.partner}/>}
                   addButtonText={strings.components.tablePage.addButton.partnerContact}
        />
    </TablePageContext.Provider>;
}

export default PartnerContacts;
