import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";

const AcceptRejectInvoiceFrom = ({
                                     onSubmit,
                                     onCancel,
                                     data,
                                     form,
                                     formRules,
                                     errors,
                                 }) => {

    return (
        <FormProvider {...form}>
            <form id="accept-reject-invoice-form" className='form' action="#">

                <TextFieldControl
                    name='comment'
                    control={data}
                    multiline={true}
                    rows={4}
                    maxRows={4}
                    error={Boolean(errors.comment)}
                    helperText={errors.comment && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.invoice.acceptRejectInvoiceFrom.comment}
                />

                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        {strings.forms.common.save}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={onCancel}>
                        {strings.forms.common.cancel}
                    </Button>
                </div>

            </form>
        </FormProvider>
    );
}

export default AcceptRejectInvoiceFrom;