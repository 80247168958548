import React, {useContext, useState} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import AutoCompleteControl from "../../../Controls/Inputs/AutoCompleteControl";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import TablePageContext from "../../../../Context/TablePageContext";
import PageState from "../../../../Constants/Base/PageState";

const ProductServiceCategoryForm = ({
                                onSubmit,
                                onCancel,
                                data,
                                form,
                                errors, parent,
                                values,
                                setValue,
                                formRules,
                                control, onParentChangeFilter
                            }) => {
    const {pageState} = useContext(TablePageContext);

    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='product-service-category-form' className='form' action="#">
                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.product.productServiceCategory.productServiceCategoryForm.name}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='description'
                    rules={formRules['description']}
                    control={data}
                    error={Boolean(errors.description)}
                    helperText={errors.description && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.product.productServiceCategory.productServiceCategoryForm.description}
                    disabled={isPageStateViewDetails()}
                />
                <AutoCompleteControl
                    value={values['parent']}
                    setValue={setValue}
                    control={control}
                    name='parent'
                    label={strings.forms.product.productServiceCategory.productServiceCategoryForm.parent}
                    options={parent}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onParentChangeFilter}
                    onOpen={() => onParentChangeFilter('')}
                />


                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default ProductServiceCategoryForm;
