import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getTaskStages(data){
    return await request('/api/task/stages', data);
}

export async function addTask(data){
    return await request('/api/tasks', transformRequestData(data), HttpMethod.POST);
}

export async function updateTaskStage(data){
    return await request('api/task/stages', data, HttpMethod.PUT);
}

export async function editTask(data){
    return await request('api/tasks/' + data.id , transformRequestData(data), HttpMethod.PUT);
}

export async function getAllTasks(){
    return await request('api/tasks/all', {});
}

export async function deleteTask(id){
    return await request('api/tasks/' + id, {}, HttpMethod.DELETE)
}

function transformRequestData(data){
    return{
        ...data,
        pipelineId: data.pipeline ? data.pipeline.id : null,
        pipelineStageId: data.pipelineStage ? data.pipelineStage.id : null
    }
}