import React, {useState, useEffect} from 'react';
import {Paper, Modal, Box} from "@mui/material";
import AddQuestionForm from "../../../Components/Forms/Pages/CheckList/CheckListTemplateQuestion/AddQuestionForm";
import { useForm } from "react-hook-form";
import strings from "../../../localization";
import AddQuestionOptionsModal from "./AddQuestionOptionsModal";
import {
    addCheckListTemplateQuestion,
    deleteCheckListTemplateQuestion
} from "../../../Services/CheckList/CheckListTemplateQuestionService";

const formRules = {
    'question': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
    'type': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
};
const AddCheckListTemplateQuestionModal = ({showModal, closeModal, submit, submitEdit, question, stepId}) => {

    const [showAddOptionsModal, setShowAddOptionsModal] = useState(false);

    const [tempQuestion, setTempQuestion] = useState(null)

    const form = useForm({
        defaultValues: {
            question: question?.question,
            explanation: question?.description,
            code: question?.code,
            type: question?.type,
            required: question?.required
        }
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;

    const submitHandler = (data) => {
        if(tempQuestion){
            submitEdit({id: tempQuestion.id, ...data});
            setTempQuestion(null);
            form.reset();
            closeModal();
            return;
        }
        if(question){
            submit({id: question.id, ...data})
            form.reset();
            return;
        }
        submit(data);
        form.reset();
    }

    const handleCloseModal = (prop) => {
        if(tempQuestion){
            deleteCheckListTemplateQuestion(tempQuestion?.id).then(
                response => {
                }
            )
        }
        setTempQuestion(null)
        closeModal(prop)
        form.reset()

    }

    const tempSubmitHandler = (data) => {
        if((tempQuestion === null) && (question === null)) {
            const requestData = {...data, 'checkListTemplateStepId': stepId}
            addCheckListTemplateQuestion(requestData).then(response => {
                const question = response.data.entity
                setTempQuestion(question);
                setShowAddOptionsModal(true);
            })
            return
        }
        setShowAddOptionsModal(true)
    }



    const handleCloseOptionsModal = () => {
        setShowAddOptionsModal(false)
    }

    return(
        <Modal
            open={Boolean(showModal)}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            id='add-edit-question-modal'>
            <div className={"paper-space"}>
                <Paper className="question-paper">
                    <img className='add-edit-question-modal-close' src='/images/close-issue-view.svg' onClick = {handleCloseModal} />
                    <div className='add-edit-question-modal-title'>
                        {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateQuestion.newQuestion}
                    </div>
                    <AddQuestionForm
                        control={control}
                        data={data}
                        formRules={formRules}
                        values={getValues()}
                        setValue={setValue}
                        errors={errors} form={form}
                        onTempSubmit={handleSubmit(tempSubmitHandler)}
                        onSubmit={handleSubmit(submitHandler)}/>
                    { (!(tempQuestion === null) || !(question === null)) && <AddQuestionOptionsModal showModal={showAddOptionsModal} closeModal={handleCloseOptionsModal} question={tempQuestion ? tempQuestion : question}></AddQuestionOptionsModal>}
                </Paper>
            </div>
        </Modal>
    );

}


export default AddCheckListTemplateQuestionModal;