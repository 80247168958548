import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import AutoCompleteControl from '../../../../Controls/Inputs/AutoCompleteControl';

const AssetForm = ({
                         onSubmit,
                         onCancel,
                         data,
                         form,
                         errors,
                         assetLocations,
                         assetTypes,
                         values,
                         setValue,
                         control,
                         employees,
                         formRules,
                         onLocationChangeFilter,
                         onTypeChangeFilter,
                         onEmployeeChangeFilter
                     }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    const changeUser = (user) => {
        setValue('user', user);
    }

    return (
        <FormProvider {...form}>
            <form id='asset-form' className='form' action="#">

                <TextFieldControl
                    name='serialNumber'
                    rules={formRules['serialNumber']}
                    control={data}
                    error={Boolean(errors.serialNumber)}
                    helperText={errors.serialNumber && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.assetManagement.assetForm.serialNumber}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='price'
                    rules={formRules['price']}
                    control={data}
                    type="number"
                    error={Boolean(errors.price)}
                    helperText={errors.price && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.assetManagement.assetForm.price}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='tax'
                    rules={formRules['tax']}
                    type="number"
                    control={data}
                    error={Boolean(errors.tax)}
                    helperText={errors.tax && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.assetManagement.assetForm.tax}
                    disabled={isPageStateViewDetails()}
                />

                <DateTimeControl
                    data={data}
                    rules={formRules['purchasedDate']}
                    form={form}
                    error={Boolean(errors.purchasedDate)}
                    helperText={errors.purchasedDate && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.assetManagement.assetForm.purchasedDate}
                    name={'purchasedDate'}
                    value={values['purchasedDate']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />
                
                <TextFieldControl
                    name='warrantyEnd'
                    rules={formRules['warrantyEnd']}
                    control={data}
                    error={Boolean(errors.warrantyEnd)}
                    helperText={errors.warrantyEnd && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.assetManagement.assetForm.warrantyEnd}
                    disabled={isPageStateViewDetails()}
                />


                <AutoCompleteControl
                    value={values['location']}
                    setValue={setValue}
                    rules={formRules['location']}
                    name='location'
                    control={control}
                    error={Boolean(errors.location)}
                    helperText={errors.location && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.assetManagement.assetForm.location}
                    options={assetLocations}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onLocationChangeFilter}
                    onOpen={() =>onLocationChangeFilter('')}
                />

                <AutoCompleteControl
                    value={values['type']}
                    rules={formRules['type']}
                    setValue={setValue}
                    name='type'
                    control={control}
                    error={Boolean(errors.type)}
                    helperText={errors.type && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.assetManagement.assetForm.type}
                    options={assetTypes}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onTypeChangeFilter}
                    onOpen={() =>onTypeChangeFilter('')}
                />

                <AutoCompleteControl
                    name='user'
                    label={strings.forms.assetManagement.assetForm.employee}
                    rules={formRules['user']}
                    nameKey={'fullName'}
                    valueKey={'id'}
                    setValue={(name, value) => changeUser(value)}
                    onChange={onEmployeeChangeFilter}
                    onOpen={() =>onEmployeeChangeFilter('')}
                    options={employees}
                    value={values['user']}
                    error={Boolean(errors.user)}
                    helperText={errors.user && strings.forms.common.thisFieldIsRequired}
                    control={control}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }

            </form>
        </FormProvider>
    );
}

export default AssetForm;