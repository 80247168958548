import React, {useEffect} from "react";
import {Paper, Tab, Tabs} from "@mui/material";
import {useState} from "react";
import CompanySettingsTab from "../../../Constants/Company/CompanySettingsTab";
import strings from "../../../localization";
import BaseSettings from "./BaseSettings";
import Logo from "./Logo";
import S3Settings from "./S3Settings";
import {useDispatch, useSelector} from "react-redux";
import {canAccess, hasPermission} from "../../../Util/PermissionUtil";
import ModulePermission from "../../../Constants/Permissions/ModulePermission";
import {isCompanyValid} from "../../../Util/CompanyUtil";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import LocalisationSettings from "./LocalisationSettings";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import { useNavigate } from 'react-router-dom';

const CompanySettings = ({activeTab = CompanySettingsTab.SETTINGS}) => {

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const permissionGroup = AppPermissions.COMPANY_SETTINGS.GROUP;
    const navigate = useNavigate();

    const changeActiveTab = (tab) =>{
        if(tab === CompanySettingsTab.SETTINGS){
            navigate('/company-settings')
        }
        if(tab === CompanySettingsTab.LOGO){
            navigate('/company-settings/logo')
        }
        if(tab === CompanySettingsTab.S3_SETTINGS){
            navigate('/company-settings/s3-settings')
        }
        if(tab === CompanySettingsTab.LOCALISATION_SETTINGS){
            navigate('/company-settings/localisation-settings')
        }
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
    }, [])


    return ( 
        hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].VIEW, auth.permissions) &&
        <div id="company-settings">
            <div className='tab-container mx-3'>
                <Paper className='paper'>
                    <Tabs variant="scrollable" value={activeTab} onChange={(e, tab) => changeActiveTab(tab)}>
                        <Tab label={strings.pages.company.settings.settings}/>
                        <Tab label={strings.pages.company.settings.logo} disabled={!isCompanyValid(auth.company)}/>
                        {
                            canAccess(auth.company, ModulePermission.DOCUMENT) &&
                            <Tab label={strings.pages.company.settings.s3settings} disabled={!isCompanyValid(auth.company)}/>
                        }
                        {
                            canAccess(auth.company, ModulePermission.DOCUMENT) &&
                            <Tab label={strings.pages.company.settings.localisationSettings} disabled={!isCompanyValid(auth.company)}/>
                        }
                    </Tabs>
                </Paper>
            </div>

            {
                activeTab === CompanySettingsTab.SETTINGS &&
                <BaseSettings/>
            }
            {
                activeTab === CompanySettingsTab.LOGO &&
                <Logo company={auth.company}/>
            }
            {
                activeTab === CompanySettingsTab.S3_SETTINGS &&
                <S3Settings/>
            }
            {
                activeTab === CompanySettingsTab.LOCALISATION_SETTINGS &&
                <LocalisationSettings/>
            }
        </div>
    );
}
export default CompanySettings;
