import React, {useContext, useState} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import SelectControl from "../../../Controls/Inputs/SelectControl";
import TablePageContext from "../../../../Context/TablePageContext";
import PageState from "../../../../Constants/Base/PageState";
import {getProductServiceTypes} from "../../../../Constants/Products/ProductServiceType";
import {getProductTypes} from "../../../../Constants/Products/ProductType";
import productServiceType from "../../../../Constants/Products/ProductServiceType";
import AutoCompleteControl from "../../../Controls/Inputs/AutoCompleteControl";
import {getProductServiceCategoryList} from "../../../../Services/Product/ProductServiceCategoryService";
import FileUploadComponent from "../../../Controls/FileUploadComponent";
import {ImageFormats} from "../../../../Constants/FileFormat";
import {toBase64} from "../../../../Util/ImageUtil";
import {uploadBankReport} from "../../../../Services/Finance/BankReportService";
import CheckBoxControl from "../../../Controls/Inputs/CheckBoxControl";


const ProductServiceForm = ({
                                onSubmit,
                                onCancel,
                                data,
                                taxesOptions,
                                form,
                                errors,
                                currencies,
                                units,
                                values,
                                setValue,
                                formRules,
                                control,
                                watch,
                                productServiceCategory,
                                connect,
                                setConnect,
                            }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    const watchType = watch("type")?.value ? watch("type").value : watch("type");

    const uploadImage = async (files) => {
        setLoading(true);
        let data = new FormData();

        for(let file of files) {
            data.append('images', file);
        }
        setValue('images', data)
        setLoading(false);
    }

    return (
        <FormProvider {...form}>
            <form id='product-service-form' className='form' action="#">

                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.productService.productServiceForm.name}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='publicName'
                    rules={formRules['publicName']}
                    control={data}
                    error={Boolean(errors.publicName)}
                    helperText={errors.publicName && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.productService.productServiceForm.publicName}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='sku'
                    rules={formRules['sku']}
                    control={data}
                    error={Boolean(errors.sku)}
                    helperText={errors.sku && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.productService.productServiceForm.sku}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='publicInoName'
                    control={data}
                    label={strings.forms.finance.productService.productServiceForm.publicInoName}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='description'
                    rules={formRules['description']}
                    control={data}
                    error={Boolean(errors.description)}
                    helperText={errors.description && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.productService.productServiceForm.description}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='price'
                    rules={formRules['price']}
                    control={data}
                    type="number"
                    error={Boolean(errors.price)}
                    helperText={errors.price && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.productService.productServiceForm.price}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={parseInt(values['tax'])}
                    setValue={setValue}
                    rules={formRules['tax']}
                    name='tax'
                    error={Boolean(errors.tax)}
                    helperText={errors.tax && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.productService.productServiceForm.tax}
                    options={taxesOptions}
                    nameKey={'valueText'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='discount'
                    rules={formRules['discount']}
                    control={data}
                    type="number"
                    error={Boolean(errors.discount)}
                    helperText={errors.discount && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.productService.productServiceForm.discount}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={values['currency']}
                    setValue={setValue}
                    rules={formRules['currency']}
                    name='currency'
                    control={control}
                    error={Boolean(errors.currency)}
                    helperText={errors.currency && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.productService.productServiceForm.currency}
                    options={currencies}
                    nameKey={'code'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={values['unit']}
                    setValue={setValue}
                    rules={formRules['unit']}
                    name='unit'
                    control={control}
                    error={Boolean(errors.unit)}
                    helperText={errors.unit && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.productService.productServiceForm.unit}
                    options={units}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    className = 'mt-4'
                />

                <SelectControl
                    value={values['type']}
                    setValue={setValue}
                    rules={formRules['type']}
                    name='type'
                    control={control}
                    error={Boolean(errors.type)}
                    helperText={errors.type && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.productService.productServiceForm.type}
                    options={getProductServiceTypes()}
                    nameKey={'name'}
                    valueKey={'value'}
                    disabled={isPageStateViewDetails()}
                    className = 'mt-4'
                />

                { watchType === productServiceType.PRODUCT &&
                    <SelectControl
                        value={values['productType']}
                        setValue={setValue}
                        rules={formRules['productType']}
                        name='productType'
                        control={control}
                        error={Boolean(errors.productType)}
                        helperText={errors.productType && strings.forms.common.thisFieldIsRequired}
                        label={strings.pages.product.productService.productServiceList.productType}
                        options={getProductTypes()}
                        nameKey={'name'}
                        valueKey={'value'}
                        disabled={isPageStateViewDetails()}
                        className = 'mt-4'
                    />
                }
                <AutoCompleteControl
                    value ={values['productServiceCategory']}
                    setValue={setValue}
                    name='productServiceCategory'
                    control={control}
                    label={strings.pages.product.productService.productServiceList.productServiceCategory}
                    options={productServiceCategory}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    className = 'mt-4'
                />
                <CheckBoxControl
                    name={'connect'}
                    control={data}
                    label={strings.pages.product.productService.productServiceList.createWooCommerce}
                    value={values['connect']}
                    setValue={setConnect}
                    disabled={isPageStateViewDetails()}
                />
                {!isPageStateViewDetails() && <FileUploadComponent formats="image/*" hideUploadButton={true} showOnlyDescription={true} onDrop={uploadImage} files = {files} setFiles = {setFiles} />
                }

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default ProductServiceForm;
