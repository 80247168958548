import strings from "../../localization";

const ChangeRequestedType = {
    NOTREQUESTED: 1,
    REQUESTED: 2
}

export function getChangeRequestedTypes() {
    return [
        {
            id: ChangeRequestedType.NOTREQUESTED,
            name: getChangeRequestedTypeString(ChangeRequestedType.NOTREQUESTED)
        },
        {
            id: ChangeRequestedType.REQUESTED,
            name: getChangeRequestedTypeString(ChangeRequestedType.REQUESTED)
        },
        
    ];
}

export function getChangeRequestedTypeString(changeRequestedType) {
    switch(changeRequestedType) {
        case ChangeRequestedType.NOTREQUESTED: return strings.constants.hr.presence.changeRequestedType.notRequested;
        case ChangeRequestedType.REQUESTED: return  strings.constants.hr.presence.changeRequestedType.requested;
    }
}

export default ChangeRequestedType;