import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllPipelines(data) {
    return await request('/api/pipelines/all', data);
}

export async function getPipeline(id) {
    return await request('/api/pipelines/' + id);
}

export async function getPipelines(data) {
    return await request('/api/pipelines', data);
}

export async function addPipeline(data) {
    return await request('/api/pipelines', transformRequestData(data), HttpMethod.POST);
}

export async function editPipeline(data) {
    return await request('/api/pipelines/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deletePipeline(id) {
    return await request('/api/pipelines/' + id, {}, HttpMethod.DELETE);
}

export async function getPipelinesByType(data){
    return await request('/api/pipelines-type', data);
}

function transformRequestData(data) {
    return {
        ...data,
        type: data.type ? data.type.id : null
    }
}