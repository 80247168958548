import {Controller} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {Box, FormControl, FormHelperText, InputLabel, RadioGroup, FormControlLabel, FormLabel, Radio} from "@mui/material";

const RadioButtonControl = (props) => {

    const [value, setValue] = useState(props.value ? props.value : -1)

    const changeValue = (newValue) => {
        props.setValue(props.name, newValue)
        setValue(newValue);
    }

    useEffect(() => {
        if((props.value)) {
            setValue(props.value)
            return;
        }

        setValue(props.value ? props.value : -1)
    }, [props.value])


    const getRadioButtonOptions = (disabled) => {
        if(!props?.options){ return}
        let result = [];
        for(let option of props.options) {
            result.push(
                <FormControlLabel label={option.option} control={<Radio/>} value={option.option} disabled={disabled}/>
            )
        }
        return result;
    }


    return <Box sx={{ minWidth: 120 }} className={'select-radio ' + props.className}>
        <FormControl fullWidth>
            <InputLabel id="radio-label" error={props.error}>{props.label}</InputLabel>
                <Controller
                    rules={props.rules}
                    name={props.name}
                    render={({field}) =>
                        <RadioGroup
                            value={value}
                            label={props.label}
                            error={props.error}
                            onClose = {props.onClose}
                            onChange={e => {
                                if (field?.onChange) {
                                    field.onChange(() => changeValue(e.target.value));
                                    return
                                }
                                changeValue(e.target.value);
                            }}
                        >
                            {getRadioButtonOptions(props.disabled)}
                        </RadioGroup>

                }
                />
            {
                props.error &&
                <FormHelperText>{props.helperText}</FormHelperText>
            }
        </FormControl>
    </Box>
}

export default RadioButtonControl;
