import React from "react";
import {useEffect, useState} from "react";
import {
    renderColumnColorChip,
    renderColumnNumber,
    renderHtml, renderLink,
    returnColumnBoolean
} from "../../../Components/DataGrid/ValueCellRender";
import {
    formatColumnConstant,
    formatColumnObject
} from "../../../Components/DataGrid/ValueFormatter";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import strings from "../../../localization"
import {useDispatch} from "react-redux";
import TablePage from "../../../Components/DataGrid/TablePage";
import {deleteJob, getJobList} from "../../../Services/HR/JobService";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import {changePageSizeState} from "../../../Slices/PageSlice";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import AddJob from "./AddJob";
import EditJob from "./EditJob";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getJobStatus, getJobStatusId } from "../../../Services/HR/JobStatusService";
import {getUserPositions, getUserPosition} from "../../../Services/Company/UserPositionService";
import { getJobContractTypes, getJobContractType } from '../../../Services/HR/JobContractTypeService';
import {getJobPaymentFrequencyString} from "../../../Constants/HR/JobPaymentFrequency";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {fetchOptions} from '../../../Services/Base/FilterOptionsService';
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [
    {field: 'name', headerName: strings.pages.hr.job.jobList.name, width: 200},
    {field: 'publicLink', headerName: strings.pages.hr.job.jobList.link,
     renderCell: (params) => renderLink(params, 'publicLink'), width: 200},
    {
        field: 'userPosition', headerName: strings.pages.hr.job.jobList.userPosition,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 160
    },
    {   field: 'description', headerName: strings.pages.hr.job.jobList.description, width: 200,
        renderCell: (params) => renderHtml(params)
    },
    {
        field: 'jobStatus', headerName: strings.pages.hr.job.jobList.jobStatus,
        renderCell: (params) => renderColumnColorChip(params, 'name'),
        width: 200
    },
    {field: 'headCount', headerName: strings.pages.hr.job.jobList.headCount, width: 120},
    {field: 'location', headerName: strings.pages.hr.job.jobList.location, width: 180},
    {
        field: 'isRemote', headerName: strings.pages.hr.job.jobList.isRemote,
        renderCell: (params) => returnColumnBoolean(params),
        width: 100
    },
    {
        field: 'minGrossSalary', headerName: strings.pages.hr.job.jobList.minGrossSalary, width: 140,
        renderCell: (params) => renderColumnNumber(params)
    },
    {
        field: 'minNetSalary',
        headerName: strings.pages.hr.job.jobList.minNetSalary,
        width: 140,
        renderCell: (params) => renderColumnNumber(params)
    },
    {
        field: 'maxGrossSalary',
        headerName: strings.pages.hr.job.jobList.maxGrossSalary,
        width: 140,
        renderCell: (params) => renderColumnNumber(params)
    },
    {
        field: 'maxNetSalary',
        headerName: strings.pages.hr.job.jobList.maxNetSalary,
        width: 140,
        renderCell: (params) => renderColumnNumber(params)
    },
    {
        field: 'paymentFrequency', headerName: strings.pages.hr.job.jobList.paymentFrequency,
        valueFormatter: (params) => formatColumnConstant(params, getJobPaymentFrequencyString),
        width: 160
    },
    {
        field: 'jobContractType', headerName: strings.pages.hr.job.jobList.jobContractType,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 200
    },
    {
        field: 'currency', headerName: strings.pages.hr.job.jobList.currency,
        valueFormatter: (params) => formatColumnObject(params, 'code'),
        width: 90
    },
    {
        field: 'partner', headerName: strings.pages.hr.job.jobList.partner,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 180
    }
    ,
    {
        field: 'pipeline', headerName: strings.pages.hr.job.jobList.pipeline,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 180
    },
    {
        field: 'user', headerName: strings.pages.hr.job.jobList.user,
        valueFormatter: (params) => formatColumnObject(params, 'fullName'),
        width: 200
    },
    {   field: 'selectionProcess', headerName: strings.pages.hr.job.jobList.selectionProcess, width: 200,
        renderCell: (params) => renderHtml(params)
    },
    {   field: 'afterSubmit', headerName: strings.pages.hr.job.jobList.afterSubmit, width: 200,
        renderCell: (params) => renderHtml(params)
    },
];


const JobList = (props) => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.JOB;
    const [filterOptions, setFilterOptions] = useState({});
    const permissionGroup = AppPermissions.JOB.GROUP;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(() => {

        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));

        dispatch(changeBreadcrumbs({
            title: strings.pages.hr.job.jobList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.pages.hr.job.jobList.pageTitle},
            ],        
        }));

        fetchFilters();

        return () => {
            setTableData({})
        }
    }, []);

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    const fetchJobStatuses = async (term, filterFetch) => {
        return fetchOptions('jobStatus', searchParams, getJobStatus, getJobStatusId, setFilterOptions, term, filterFetch);
    }

    const fetchJobContractTypes =  async (term, filterFetch) => {
        return fetchOptions('jobContractType', searchParams, getJobContractTypes, getJobContractType, setFilterOptions, term, filterFetch);
    }

    const fetchUserPositions = async (term, filterFetch) => {
        return fetchOptions('userPosition', searchParams, getUserPositions, getUserPosition, setFilterOptions, term, filterFetch);
    }

    const fetchFilters = async () => {
        const jobStatuses = await fetchJobStatuses();
        const jobContractTypes = await fetchJobContractTypes();
        const userPositions = await fetchUserPositions();
        
        setFilterOptions({
            jobStatus: jobStatuses,
            jobContractType: jobContractTypes,
            userPosition: userPositions,
            fetched: 1,
        });
    }

    const filters = [
        {
            name: 'jobStatus',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'jobStatus',
            label: strings.pages.hr.job.jobList.jobStatus,
            onChange: (term) => fetchJobStatuses(term, true),
        },
        {
            name: 'jobContractType',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'jobContractType',
            label: strings.pages.hr.job.jobList.jobContractType,
            onChange: (term) => fetchJobContractTypes(term, true),
        },
        {
            name: 'userPosition',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'userPosition',
            label: strings.pages.hr.job.jobList.userPosition,
            onChange: (term) => fetchUserPositions(term, true),
        },
    ]
    

    const additionalMenuItems = [
        {
            handleClick: id => handleJobCandidates(id),
            text: strings.pages.hr.job.jobList.jobCandidates,
            src: '/images/table-page/job-candidates.svg'
        },
    ];

    const handleJobCandidates = (id) => {
        navigate('/job-candidates', {state: {jobId: id}})
    }

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getJobList({
            ...filter
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        });
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} deleteItem={deleteJob} tableDescription={columns}
                   tableData={tableData}
                   filter={filter}
                   filters={filters}
                   permissionGroup={permissionGroup}
                   filterOptions={filterOptions}
                   referenceType={referenceType}
                   additionalMenuItems={additionalMenuItems}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditJob data={selectedItems}
                                      onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addPage={<AddJob onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                   addButtonText={strings.components.tablePage.addButton.job}/>
    </TablePageContext.Provider>
}

export default JobList;
