import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";
import { getLinkedIssuesRelations } from "../../Constants/Workspaces/LinkedIssueType";

export async function getLinkedIssue(data){
    return await request('/api/linked-issue', data);
}

export async function addLinkedIssue(data) {
  return await request('/api/linked-issue', transformRequestData(data), HttpMethod.POST);
}

export async function deleteLinkedIssue(id) {
    return await request('/api/linked-issue/'+ id, {}, HttpMethod.DELETE);
  }

function transformRequestData(data) {

    return {
        ...data,
        primaryLinkedIssueType: data.linkedIssueType ? getLinkedIssuesRelations(data.linkedIssueType.type).primaryLinkedIssueType : null,
        foreignLinkedIssueType: data.linkedIssueType ? getLinkedIssuesRelations(data.linkedIssueType.type).foreignLinkedIssueType : null,
        issue: data.issue ? data.issue.id : null,
        linkedIssue: data.linkedIssue ? data.linkedIssue.id : null,
    }
}
