import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import {Paper} from "@mui/material";
import { getJobList } from "../../../Services/HR/JobService";
import { editCandidate, getAllUsers } from "../../../Services/HR/CandidateService";
import CandidateForm from "../../../Components/Forms/Pages/HR/Candidate/CandidateForm";
import ValidationPatters from "../../../Constants/Base/ValidationPatters";
import { getCandidateStatus } from "../../../Services/HR/CandidateStatusService";
import SnackbarContext from "../../../Context/SnackbarContext";
import { getCandidateSourceList } from '../../../Services/HR/CandidateSourceService';

const formRules = {
    'firstName': {required: true},
    'lastName': {required: true},
    'email': {required: {value: true}, pattern: { value: ValidationPatters.EMAIL}},
}

const EditCandidate = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const {showMessage} = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? {...props.data[0], birthday: props.data[0].birthday || NaN} : undefined
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [candidateSources, setCandidateSources] = useState([]);
    const [users, setUsers] = useState(0);
    const [candidateStatus, setCandidateStatus] = useState([]);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.candidate.editCandidate.pageTitle);
    })

    useEffect(() => {
        fetch();
        fetchJobs();
        fetchCandidateSources();
        fetchCandidateStatuses();
    }, [])

    const fetchJobs = () => {
        getJobList().then(response => {
            if (!response || !response.ok){
                return;
            }

            setJobs(response.data.result);
        });
    }

    const fetchCandidateSources = (term = '') => {
        getCandidateSourceList({term}).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setCandidateSources(response.data.result)
        });
    }

    const fetchCandidateStatuses = (term = '') => {
        getCandidateStatus({term}).then(response =>{
            if (!response || !response.ok){
                return;
            }
            setCandidateStatus(response.data.result)
        });
    }

    const fetch = () => {
        getAllUsers().then(response => {
            if (!response || !response.ok) {
                return;
            }
            setUsers(response.data)
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    const onSubmit = (data) => {
        setShowLoader(true);

        editCandidate(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok){
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }
            
            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress />
            }
            <CandidateForm
                formRules={formRules} onChangeCandidateStatuses={fetchCandidateStatuses}
                values={getValues()} setValue={setValue} candidateStatuses={candidateStatus}
                data={data} errors={errors} form={form} control={control}
                jobs={jobs} candidateSources={candidateSources} users={users}
                onChangeCandidateSource={fetchCandidateSources} onChangeJobFilter={fetchJobs}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}
            />
        </Paper>
    </div>
}

export default EditCandidate;