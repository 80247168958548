import {Autocomplete, Box, CircularProgress, Drawer, FormControl, TextField} from "@mui/material"
import {useCallback, useEffect, useState,} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import strings from "../../../localization";
import {debounce} from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import Button from "@mui/material/Button";
import DrawerWrapper from "../../Layout/DrawerWrapper";
import AddPartner from "../../../Pages/CRM/Partner/AddPartner";
import DrawerContext from "../../../Context/DrawerContext";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {hasPermission} from "../../../Util/PermissionUtil";
import {useSelector} from "react-redux";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import AddBankAccount from "../../../Pages/Finance/BankAccount/AddBankAccount";
import AddContract from "../../../Pages/Finance/Contract/AddContract";
import AddContractPart from "../../../Pages/Finance/ContractPart/AddContractPart";
import AddProject from "../../../Pages/Project/Project/AddProject";
import AddProductService from "../../../Pages/Products/ProductService/AddProductService";

const AutoCompleteControl = (props) => {
    const [value, setValue] = useState(null);

    const [data, setData] = useState({options: [], loading: !props.options || props.options.length === 0});

    useEffect(() => {
        setValue(props.value || null);
    }, [props.value])

    useEffect(() => {
        setData(value => ({...value, loading: true, options: []}));
        debouncedOptions(props.options);
    }, [props.options])

    const debouncedSave = useCallback(
        debounce((newValue) => {
            if (props.onChange) {
                props?.onChange(newValue)
            }
        }, 800),
        []);

    const debouncedOpen = useCallback(
        debounce((newValue) => {

            if (!newValue) {
                return
            }

            if (props?.onOpen) {
                setData(value => ({...value, loading: true}));
                Promise.resolve(props?.onOpen()).then();
            }
        }),
        []);

    const debouncedOptions = useCallback(
        debounce((newValue) => {
            setData(value => ({loading: false, options: newValue?.length >= 0 ? newValue : []}));
        }, 1500),
        []);

    const updateValue = (newValue) => {
        debouncedSave(newValue);
    };

    const changeValue = (value) => {
        setValue(value);
        props.setValue(props.name, value)
    }

    const onOpen = () => {
        debouncedOpen()
    }

    return <Box sx={{minWidth: 120}} className={'select-box ' + props.className}>
        {props.control &&
            <FormControl fullWidth>

                <Controller
                    rules={props.rules}
                    name={props.name}
                    control={props.control}
                    render={({field}) => AutocompleteComponent({
                        props,
                        field,
                        changeValue,
                        updateValue,
                        options: data.options,
                        value,
                        onOpen,
                        loading: data.loading
                    })
                    }
                />
            </FormControl>
        }

        {
            !props.control &&
            AutocompleteComponent({
                props,
                field: {},
                changeValue,
                updateValue,
                options: data.options,
                value,
                onOpen,
                loading: data.loading
            })
        }
    </Box>
}

export default AutoCompleteControl;

const AutocompleteComponent = ({props, field, changeValue, updateValue, options, value, onOpen, loading}) => {

    const [showAddDialog, setShowAddDialog] = useState(false)
    const [drawerTitle, setDrawerTitle] = useState('');
    const auth = useSelector((state) => state.auth)
    const drawerValue = {drawerTitle, setDrawerTitle}

    const onFinish = (item) => {
        options.unshift(item);
        onChange(field, item)
        setShowAddDialog(false)
    }

    const onChange = (field, value) => {
        if (field?.onChange) {
            field.onChange(() => changeValue(value));
            return
        }
        changeValue(value);
    }

    const hasAddPermission = (referenceType) => {
        if (referenceType === ReferenceType.PARTNER) {
            return hasPermission(auth.user, AppPermissions.PARTNER.GROUP,
                AppPermissions.PARTNER.ADD, auth.permissions)
        } else if (referenceType === ReferenceType.BANK_ACCOUNT) {
            return hasPermission(auth.user, AppPermissions.BANK_ACCOUNT.GROUP,
                AppPermissions.BANK_ACCOUNT.ADD, auth.permissions)
        } else if (referenceType === ReferenceType.CONTRACT) {
            return hasPermission(auth.user, AppPermissions.CONTRACT.GROUP,
                AppPermissions.CONTRACT.ADD, auth.permissions)
        } else if (referenceType === ReferenceType.CONTRACT_PART) {
            return hasPermission(auth.user, AppPermissions.CONTRACT_PART.GROUP,
                AppPermissions.CONTRACT_PART.ADD, auth.permissions)
        } else if (referenceType === ReferenceType.PROJECT) {
            return hasPermission(auth.user, AppPermissions.PROJECT.GROUP,
                AppPermissions.PROJECT.ADD, auth.permissions)
        } else if (referenceType === ReferenceType.PRODUCT_SERVICE) {
            return hasPermission(auth.user, AppPermissions.PRODUCTS_AND_SERVICES.GROUP,
                AppPermissions.PRODUCTS_AND_SERVICES.ADD, auth.permissions)
        }

        return false
    }

    const getDrawerTitle = (referenceType) => {
        if (referenceType === ReferenceType.PARTNER) {
            return strings.pages.crm.partner.addPartner.pageTitle
        } else if (referenceType === ReferenceType.BANK_ACCOUNT) {
            return strings.pages.finance.bankAccount.addBankAccount.pageTitle
        } else if (referenceType === ReferenceType.CONTRACT) {
            return strings.pages.finance.contract.addContract.pageTitle
        } else if (referenceType === ReferenceType.CONTRACT_PART) {
            return strings.pages.finance.contractPart.addContractPart.pageTitle
        } else if (referenceType === ReferenceType.PROJECT) {
            return strings.pages.project.project.addProject.pageTitle
        } else if (referenceType === ReferenceType.PRODUCT_SERVICE) {
            return strings.pages.product.productService.addProductService.pageTitle
        }

        return ''
    }

    return <div className={'autocomplete-wrapper'}>
        <DrawerContext.Provider value={drawerValue}>
            <Drawer id='drawer' anchor='right' open={showAddDialog} onClose={() => setShowAddDialog(false)}>
                <DrawerWrapper onBack={() => setShowAddDialog(false)} title={getDrawerTitle(props.referenceType)}>
                    {
                        props.referenceType === ReferenceType.PARTNER &&
                        <AddPartner onCancel={() => setShowAddDialog(false)} onFinish={onFinish}/>
                    }
                    {
                        props.referenceType === ReferenceType.BANK_ACCOUNT &&
                        <AddBankAccount onCancel={() => setShowAddDialog(false)} onFinish={onFinish}/>
                    }
                    {
                        props.referenceType === ReferenceType.CONTRACT &&
                        <AddContract onCancel={() => setShowAddDialog(false)} onFinish={onFinish} partner={props.partner}/>
                    }
                    {
                        props.referenceType === ReferenceType.CONTRACT_PART &&
                        <AddContractPart onCancel={() => setShowAddDialog(false)} onFinish={onFinish} contractId={props.contractId}/>
                    }
                    {
                        props.referenceType === ReferenceType.PROJECT &&
                        <AddProject onCancel={() => setShowAddDialog(false)} onFinish={onFinish}/>
                    }
                    {
                        props.referenceType === ReferenceType.PRODUCT_SERVICE &&
                        <AddProductService onCancel={() => setShowAddDialog(false)} onFinish={onFinish}/>
                    }
                </DrawerWrapper>
            </Drawer>
        </DrawerContext.Provider>

        <Autocomplete {...field}
                      loading={loading}
                      className={'autocomplete'}
                      disabled={props.disabled}
                      openOnFocus={props.openOnFocus}
                      size={props.size ? props.size : 'small'}
                      options={loading ? [] : options}
                      getOptionLabel={(option) => option[props.nameKey]}
                      renderInput={params => <TextField
                          {...params}
                          error={props.error}
                          label={props.label}
                          onKeyDown={props?.onKeyDown}
                          onChange={e => updateValue(e.target.value)}
                          helperText={props.helperText}
                          inputProps={{...params.inputProps}}
                          placeholder={props.placeholder}
                          InputLabelProps={props.placeholder ? {shrink: true} : {}}
                          autoFocus={props.openOnFocus}
                      />}
                      onChange={(event, value) => onChange(field, value)}
                      onOpen={onOpen}
                      value={value}
                      clearIcon={props.showClear && <ClearIcon fontSize="small"/>}
                      isOptionEqualToValue={(option, value) =>
                          option && value && option[props.valueKey || 'name'] === value[props.valueKey || 'name']
                      }
                      noOptionsText={strings.components.autoComplete.noOptions}
                      onClose={props.onClose}
        />
        {
            props.quickAdd && hasAddPermission(props.referenceType) &&
            <Button className={'add-button'} onClick={() => setShowAddDialog(true)}>
                <img src={'/images/plus.png'}/>
            </Button>
        }
    </div>

}
