import React from "react";
import { FormProvider } from "react-hook-form";
import strings from "../../../../../localization";
import CheckBoxControl from "../../../../Controls/Inputs/CheckBoxControl";
import Button from '@mui/material/Button';
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";

const HRBaseSettingsForm = ({
                    onSubmit,
                    data,
                    form,
                    values,
                    setValue,
                }) => {
    return (
        <FormProvider {...form}>
            <form id="base-settings-form" className='form px-12 mui-shifted-label-input' action="#">
                <TextFieldControl
                    name='workingDayHours'
                    control={data}
                    type='number'
                    label={strings.forms.hr.baseSettings.workingDayHours}
                    className="mt-18 mb-1"
                />
                <TextFieldControl
                    name='vacationDaysPerYear'
                    control={data}
                    type='number'
                    label={strings.forms.hr.baseSettings.vacationDaysPerYear}
                    className="mt-10 mb-1"
                />
                
                <CheckBoxControl
                    name={'internalHrOnly'}
                    control={data}
                    label={strings.forms.hr.baseSettings.internalHrOnly}
                    value={values['internalHrOnly']}
                    setValue={setValue}
                />

                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={onSubmit} className="mt-1 mb-12">
                        {strings.forms.common.save}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
}

export default HRBaseSettingsForm;