import React from 'react';
import { Grid } from '@mui/material';
import strings from '../../../localization';
import ValidationInputControll from '../../../Components/Controls/Inputs/ValidationInputControl';
import { useForm, FormProvider } from "react-hook-form";
import {Button} from '@mui/material';
import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback, useContext } from 'react';
import ValidationPatters from '../../../Constants/Base/ValidationPatters';
import { validatePublicLink, savePublicLink } from '../../../Services/User/UserCompanyAccessService'
import { debounce } from 'lodash';
import SnackbarContext from '../../../Context/SnackbarContext';
import { getCurrentEmployee } from '../../../Services/User/UserCompanyAccessService';


const formRules = {
    'customName': {
        pattern: { value: ValidationPatters.PUBLIC_LINK, message: strings.forms.common.invalidPattern },
        required: {value: true, message: strings.forms.common.thisFieldIsRequired}
    },
}

const PublicLink = (props) => {
    const form = useForm({
        mode: "onTouched",
        defaultValues:{
            customName: '',
        }
    });
    const {control, handleSubmit, formState: {errors}, getValues, setValue, trigger, setError, clearErrors} = form;
    const company = useSelector((state) => state.auth.company);
    const [companyPublicLink, setCompanyPublicLink] = useState('');
    const [checked, setChecked] = useState(true);
    const {showMessage} = useContext(SnackbarContext);
    const fieldError = Boolean(errors && (errors.customName || errors.collision))

    useEffect(() => {
      if(!company?.publicLink){
        return;
      }
      setCompanyPublicLink(company.publicLink.concat('/'));
      fetchCustomName();
     
    }, [company])

    const fetchCustomName = () =>{
        getCurrentEmployee().then(response=>{
            if(!response || !response.ok){
                return;
            }
            setValue('customName', response.data.publicLink);
          })
    }
    
    
    const submit = (data)=>{
        savePublicLink(data?.customName).then(response=>{
            if(!response || !response.ok){
                showMessage(strings.profile.publicLink.problemSaving, 'error')
                return;
            }
            showMessage(strings.profile.publicLink.success, 'success')
        })
        
       
    }

    const onChange = (newVal) =>{
        setValue('customName', newVal);
        setChecked(null);
        validate(newVal);
        trigger();
    }

    const validate = (publicLink) =>{
        if(!publicLink){
            return;
        }
        debouncedVerify(publicLink)
    }

    const debouncedVerify = useCallback(
        debounce(publicLink => {
            if(!publicLink.match(ValidationPatters.PUBLIC_LINK)){
                return
            }
            validatePublicLink(publicLink).then(response =>{
                if (!response || !response.ok) {
                   setChecked(false);
                   setError('collision', { type: 'custom', message: strings.profile.publicLink.validationProblem });
                   return;
                }
                if(response.data.collision){
                    setError('collision', { type: 'custom', message: strings.profile.publicLink.collision });
                    setChecked(false);
                    return
                }
                clearErrors("collision");
                setChecked(true)
            });
        }, 500),
    []);

    return (
        <Grid 
            container 
            alignItems = 'center' 
            justifyContent = 'center' 
            className = 'company-form-container mt-0'
        >
        <Grid item xs = {5} className = 'form-container public-link pt-6 pb-6'>
            <div className='header font-16 centered-flex base-color  mb-10 mt-6'>
            {strings.profile.publicLink.header}
            </div>          
                <FormProvider {...form}>
                    <form id="base-settings-form" className='form mui-shifted-label-input flex-column' style={{ "height": "150px",}} action="#">
                        <div className='input-section flex-start'>
                            <div className='font-16 mr-3 no-wrap pt-5'>
                                {companyPublicLink}
                            </div>
                            <div className='input-field w-100'>
                                <ValidationInputControll
                                    name='customName'
                                    control={control}
                                    rules={formRules['customName']}
                                    error={fieldError}
                                    helperText={errors.collision && errors.collision.message || errors.customName && errors.customName.message || !checked && strings.profile.publicLink.checking}
                                    placeholder={strings.profile.publicLink.placeholder}
                                    value={getValues("customName")}
                                    onChange={onChange}
                                    validInput={!fieldError && checked}/>
                            </div>
                        </div>                 
                        <div className='submit-container mb-0' style={{"marginTop": 'auto'}}>
                            <Button variant="contained" color="primary" onClick={handleSubmit(submit)} disabled={Boolean(errors && (errors.customName || errors.collision)) || !checked}>
                                {strings.forms.common.saveChanges}
                            </Button>
                        </div>                    
                    </form>
                </FormProvider>                
            </Grid>
        </Grid>
    );
}

export default PublicLink;