import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import {useSelector} from "react-redux";
import {getCurrencies} from "../../../Util/SiteDataUtil";
import TablePageContext from "../../../Context/TablePageContext";
import LinearProgress from '@mui/material/LinearProgress';
import { addOrganisationUserContract, getAllUsers } from "../../../Services/HR/OrganisationUserContractService";
import OrganisationUserContractForm from "../../../Components/Forms/Pages/HR/OrganisationUserContract/OrganisationUserContractForm";
import SnackbarContext from "../../../Context/SnackbarContext";

const formRules = {
    'name': {required: true},
    'gross': {required: true},
    'type': {required: true},
    'startDate': {required: true},
    'paymentDay': {min: 1, max: 31, required: true},
    'user': {required: true},
    'net': {required: true},
    'currency': { required: true }
}

const AddOrganisationUserContract = (props) => {
    const siteData = useSelector((state) => state.siteData)
    const {setDrawerTitle} = useContext(DrawerContext);
    const {showMessage} = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: {
            startDate: null,
            endDate: NaN
        }
    });
    const {data, control, handleSubmit, getValues, setValue, trigger, setError, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [users, setUsers] = useState(0);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.organisationUserContract.addOrganisationUserContract.pageTitle);
    });

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        getAllUsers().then(response =>{
            if (!response || !response.ok){
                return;
            }
            setUsers(response.data);
        });
    }

    const onSubmit = (data) => {
        setShowLoader(true);

        addOrganisationUserContract(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok){
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <OrganisationUserContractForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                currencies={getCurrencies(siteData)}
                errors={errors} data={data} form={form}
                users={users} control={control}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}
                trigger={trigger}
            />
        </Paper>
    </div>
};

export default AddOrganisationUserContract;