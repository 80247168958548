import React from "react";
import { dateTimeToString, dateToString, getMonthString} from "../../Util/DateUtil";
import {Avatar} from "@mui/material";

export const formatColumnObject = (params, key) => {
    return params.value && params.value[key] ? params.value[key] : ''
}

export const formatColumnConstant = (params, toSting) => {
    if (!params.value){
        return '';
    }

    return toSting(params.value);
}

export const formatColumnDate = (params) => {
    if(!params.value) {
        return '';
    }

    return dateToString(new Date(params.value));
}

export const formatColumnGeoMap = (params) => {

    if(!params?.row) {
        return ''
    }

    if(!params?.row?.latitude || !params?.row?.longitude) {
        return ''
    }

    return  <a href={`https://www.google.com/maps?q=${params?.row?.latitude},${params?.row?.longitude}&hl=en`} target={'_blank'}>Map</a>
}

export const formatColumnDateTime = (params) => {
    if(!params.value) {
        return '';
    }
    let date = new Date(params.value);

    return dateTimeToString(date);
}

export const renderColumnColor = params => {
    if (!params.value){
        return '';
    }
    return (<Avatar sx={{ bgcolor: params.value, width: 24, height: 24 }} alt={params.value} > </Avatar>)
}

export const formatColumnMonth = (params) => {
    return getMonthString(params.value);
}