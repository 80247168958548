import React, { Fragment, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";

const TagForm = ({
                        onSubmit,
                        onCancel,
                        data,
                        form,
                        errors,
                        values,
                        setValue,
                        formRules,
                        defaultValues
                     }) => {

    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>

            <form id='tag-form' className='form' action="#">

                <div className='tag-forma-container'>

                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.crm.tags.name}
                    disabled={isPageStateViewDetails()}
                    placeholder={strings.forms.crm.tags.enterName}
                    control={data}
                />

                <div className='tag-select-color'>
                    <div className='tag-select-color-input-description'>
                        <div id='display_color' className='tag-select-color-display' style={{backgroundColor: values['color']}}/>
                        <div className='tag-select-color-text'>
                            Select color
                            <img src = '/images/arrow-down.svg'/>
                        </div>
                    </div>

                    <TextFieldControl
                        name='color'
                        rules={formRules['color']}
                        control={data}
                        error={Boolean(errors.color)}
                        helperText={errors.color && strings.forms.common.thisFieldIsRequired}
                        disabled={isPageStateViewDetails()}
                        type={'color'}
                        className={'tag-select-color-input'}
                    />

                </div>

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
                </div>

            </form> 
        </FormProvider>
    );
}

export default TagForm;