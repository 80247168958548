import strings from "../../../localization";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Alert, Grid, Paper, Snackbar} from "@mui/material";
import InvoiceSettingsForm from "../../../Components/Forms/Pages/Company/InvoiceSettingsForm";
import {getAllBankAccounts} from "../../../Services/Company/BankAccountService";
import {getInvoiceSettings, updateInvoiceSettings} from "../../../Services/Company/CompanyService";
import SnackbarContext from "../../../Context/SnackbarContext";
import Modules from "../../../Constants/Base/Modules";
import { changeBreadcrumbs } from "../../../Slices/BreadcrumbsSlice";

const formRules = {
    'version': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
}

const InvoiceSettings = () => {

    const [accounts, setAccounts] = useState([]);
    const dispatch = useDispatch();
    const form = useForm();
    const {handleSubmit, setValue, getValues, reset, formState: {errors}} = form;
    const { showMessage } = useContext(SnackbarContext);

    useEffect(() => {
        dispatch(changeBreadcrumbs({
            title: strings.pages.company.settings.invoiceSettings,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.FINANCE},
                {label: strings.pages.finance.settings.settings},
                {label: strings.pages.company.settings.invoiceSettings},
            ],        
        }));
        fetch();
    }, []);

    const fetch = () => {
        fetchAccounts();

        getInvoiceSettings().then(response => {
            if(!response || !response.ok) {
                return
            }

            reset({
                ...response.data.entity,
                deadline: {
                    id: response.data.entity.deadline
                }
            })
        })
    }

    const fetchAccounts = (term = '') => {
        getAllBankAccounts({term}).then(response => {
            setAccounts(response.data);
        });
    }

    const save = (data) => {
        updateInvoiceSettings(data).then(response => {
            if(!response || !response.ok) {
                showMessage(strings.commonMessages.errorSaving, 'error');
                return;
            }

            showMessage(strings.commonMessages.saved, 'success');
        })
    }

    return <Grid container justifyContent="center"
                 alignItems="center">
        <Grid item xs={6}>
            <Paper className='paper'>
                <InvoiceSettingsForm
                    onSubmit={handleSubmit(save)}
                    errors={errors} form={form} formRules={formRules}
                    values={getValues()} setValue={setValue}
                    accounts={accounts} onChangeAccountFilter={fetchAccounts}
                />
            </Paper>
        </Grid>
    </Grid>
}

export default InvoiceSettings;