import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import AutoCompleteControl from '../../../../Controls/Inputs/AutoCompleteControl';

const StockChangeForm = ({
                         onSubmit,
                         onCancel,
                         data,
                         form,
                         errors,
                         inventories,
                         values,
                         setValue,
                         formRules,
                         control,
                         onInventoryChangeFilter
                     }) => {

    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='stock-change-form' className='form' action="#">

                <TextFieldControl
                    name='type'
                    rules={formRules['type']}
                    control={data}
                    error={Boolean(errors.type)}
                    helperText={errors.type && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.assetManagement.stockChangeForm.type}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='price'
                    rules={formRules['price']}
                    control={data}
                    type="number"
                    error={Boolean(errors.price)}
                    helperText={errors.price && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.assetManagement.stockChangeForm.price}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='tax'
                    rules={formRules['tax']}
                    control={data}
                    type="number"
                    error={Boolean(errors.tax)}
                    helperText={errors.tax && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.assetManagement.stockChangeForm.tax}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='quantity'
                    rules={formRules['quantity']}
                    control={data}
                    type="number"
                    error={Boolean(errors.quantity)}
                    helperText={errors.quantity && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.assetManagement.stockChangeForm.quantity}
                    disabled={isPageStateViewDetails()}
                />

                <div className='header-row-item'>
                    <DateTimeControl
                        data={data}
                        label={strings.forms.assetManagement.stockChangeForm.stockChangeDate}
                        name={'stockChangeDate'}
                        rules={formRules['stockChangeDate']}
                        form={form}
                        error={Boolean(errors.stockChangeDate)}
                        helperText={errors.endDate && strings.forms.common.thisFieldIsRequired}
                        value={values['stockChangeDate']}
                        setValue={setValue}
                        disabled={isPageStateViewDetails()}
                    />
                </div>

                <AutoCompleteControl
                    value={values['inventory']}
                    rules={formRules['invetory']}
                    setValue={setValue}
                    name='inventory'
                    control={control}
                    error={Boolean(errors.inventory)}
                    helperText={errors.inventory && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.assetManagement.stockChangeForm.inventory}
                    options={inventories}
                    nameKey={'id'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onInventoryChangeFilter}
                    onOpen={() =>onInventoryChangeFilter('')}
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }

            </form>
        </FormProvider>
    );
}

export default StockChangeForm;