import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllUsers(data) {
    return await request('/api/users/employees/all', data);
}

export async function getOrganisationUserContractList(data) {
    return await request('/api/organisation-user-contracts', data);
}
export async function getOrganisationUserContract(id) {
    return await request('/api/organisation-user-contracts/' + id);
}

export async function addOrganisationUserContract(data) {
    return await request('/api/organisation-user-contracts', transformRequestData(data), HttpMethod.POST);
}

export async function generateOrganisationUserContractParts(data) {
    return await request(`/api/organisation-user-contracts/${data.id}/generate-parts`, data, HttpMethod.POST);
}

export async function editOrganisationUserContract(data) {
    return await request('/api/organisation-user-contracts/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteOrganisationUserContract(id) {
    return await request('/api/organisation-user-contracts/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {

    return {
        ...data,
        userId: data.user ? data.user.id : null,
        currencyId: data.currency ? data.currency.id : null,
        paymentMonth: data.paymentMonth ? data.paymentMonth.value : null,
        type: data.type ? data.type.id : null
    }
}