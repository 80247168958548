const invalidCharacters = {
    "!": "Ć",
    "]": "Ć",
    "[": "Š",
    "�": "Š",
    "›": "Š",
    "#": "Š",
    "@": "Ž",
    "\\": "Đ",
    "^": "Č",
    "$": "Č",
}

const formatCharacters = (string) => {
    let result = '';
    for(let i = 0; i < string.length; i++) {
        let invalidChar = invalidCharacters[string[i]];
        let char = string[i];
        if(invalidChar){
            result += invalidChar;
            continue;
        }

        result += char; 
    }

    return result;
}

export default formatCharacters;
