export const HRSettingsTab = {
    BASE_SETTINGS: 0,
    USER_POSITION: 1,
    JOB_STATUS: 2,
    JOB_CONTRACT_TYPE: 3,
    CANDIDATE_SOURCE: 4,
    LEAVE_TYPE: 5,
    NON_WORKING_DAY: 6,
    CANDIDATE_STATUS: 7,
    BUSINESS_TRIP: 8,
}