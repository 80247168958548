import React, {useContext, useEffect, useRef, useState} from "react";
import strings from "../../../localization";
import {formatColumnDate, formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import {useDispatch, useSelector} from "react-redux";
import PageState from "../../../Constants/Base/PageState";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import TablePage from "../../../Components/DataGrid/TablePage";
import AddTransaction from "../../Finance/Transactions/AddTransaction";
import EditTransaction from "../../Finance/Transactions/EditTransaction";
import {approveTransactions, connectTransactions, deleteTransaction, getTransactions} from "../../../Services/Finance/TransactionService";
import {
    renderColumnNumber,
    renderColumnPaymentType,
    renderValueWithTooltip, returnColumnBoolean
} from "../../../Components/DataGrid/ValueCellRender";
import SelectControl from "../../../Components/Controls/Inputs/SelectControl";
import TransactionRelationType from "../../../Constants/Finance/TransactionRelationType";
import {getTransactionTypeByPaymentType} from "../../../Constants/Finance/TransactionType";
import PaymentType from "../../../Constants/Finance/PaymentType";
import { getTaxRates, getBanks } from "../../../Util/SiteDataUtil";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import { LockOpen } from "@mui/icons-material";
import TransactionSplit from "../../Finance/Transactions/TransactionSplit";
import SnackbarContext from "../../../Context/SnackbarContext";
import Button from "@mui/material/Button";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {getContractParts} from "../../../Services/Finance/ContractPartService";
import { getEmployees} from "../../../Services/User/UserService";
import { getInvoices} from "../../../Services/Finance/InvoiceService";
import {
    getTransactionCategoryList
} from "../../../Services/Finance/TransactionCategoryService";
import AutoCompleteControl from "../../../Components/Controls/Inputs/AutoCompleteControl";
import {useForm} from "react-hook-form";
import {FormProvider} from "react-hook-form";
import {getPartners, getPartner} from "../../../Services/CRM/PartnerService";
import {getContracts} from "../../../Services/Finance/ContractService";
import {getInvoiceDirectionFromPaymentType} from "../../../Util/InvoiceUtil"
import {fetchOptions} from '../../../Services/Base/FilterOptionsService';
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const tableDescription = [
    {
        field: 'bankReport',
        headerName: strings.pages.finance.transactions.transactionList.bankReport,
        width: 150,
        valueFormatter: (params) => formatColumnObject(params, 'reportNumber'),
    },
    {
        field: 'parent',
        headerName: strings.pages.finance.transactions.transactionList.parent,
        width: 100,
        valueFormatter: (params) => formatColumnObject(params, 'id'),
    },
    {
        field: 'paymentType',
        headerName: strings.pages.finance.transactions.transactionList.paymentType,
        width: 150,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => renderColumnPaymentType(params)
    },
    {
        field: 'partnerName',
        headerName: strings.pages.finance.transactions.transactionList.partnerName,
        width: 200,
        renderCell: (params) => renderValueWithTooltip(params)
    },
    {
        field: 'description',
        headerName: strings.pages.finance.transactions.transactionList.description,
        width: 400,
        renderCell: (params) => renderValueWithTooltip(params)
    },
    {
        field: 'paymentDate',
        headerName: strings.pages.finance.transactions.transactionList.paymentDate,
        width: 150,
        valueFormatter: (params) => formatColumnDate(params)
    },
    {
        field: 'income',
        headerName: strings.pages.finance.transactions.transactionList.income,
        width: 150,
        renderCell: (params) => renderColumnNumber(params)
    },
    {
        field: 'outcome',
        headerName: strings.pages.finance.transactions.transactionList.outcome,
        width: 150,
        renderCell: (params) => renderColumnNumber(params)
    },
    {
        field: 'currency',
        headerName: strings.pages.finance.transactions.transactionList.currency,
        width: 150,
        valueFormatter: (params) => formatColumnObject(params, 'code'),
    },
];

const TransactionList = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const siteData = useSelector((state) => state.siteData);
    const { showMessage } = useContext(SnackbarContext);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const form = useForm();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [tablePageOptions, setTablePageOptions] = useState({
        ...TablePageOptionDefaults,
        showYearFilter: true,
        showMonthFilter: true,
        showActionDelete: true
    });
    const [filterOptions, setFilterOptions] = useState({});

    const [columns, setColumns] = useState([]);
    const referenceType = ReferenceType.TRANSACTION;
    const [customPage, setCustomPage] = useState(null);
    const permissionGroup = AppPermissions.TRANSACTION.GROUP;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    const additionalMenuItems = [
        {handleClick: (id, item) => handleTransactionSplit(id,item), text: strings.pages.finance.transactions.transactionList.transactionSplit, src: '/images/table-page/duplicate.svg'},
    ]

    const handleTransactionSplit = (id, item) => {
        setCustomPage(<TransactionSplit   transactionItem={item} onCancel={() => onCancel()} onFinish={() => onFinish()}/>)
        setPageState(PageState.Custom);
    }

    useEffect(() => {
        setColumns([...tableDescription, ...generateColumns()]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.finance.transactions.transactionList.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.FINANCE},
                {label: strings.pages.finance.transactions.transactionList.pageTitle},
            ],        
        }));
    }, [])

    const fetchPartners = async (term, filterFetch) => {
        return fetchOptions('partner', searchParams, getPartners, getPartner, setFilterOptions, term, filterFetch);
    }

    const fetchFilters = async() => {
        const partners = await fetchPartners();

        setFilterOptions({
            partner: partners,
            bank: getBanks(siteData),
            fetched: 1,
        });
    }

    const filters = [
        {
            name: 'partner',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'partner',
            label: strings.pages.finance.transactions.transactionList.partner,
            onChange: (term) => fetchPartners(term, true),
        },
        {
            name: 'bank',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'bank',
            label: strings.pages.finance.transactions.transactionList.bank
        },
    ]

    useEffect(() => {
        if(!Object.keys(siteData.data).length){
            return;
        }
        fetchFilters();
    },[siteData])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    const generateColumns = () => {
        return [
            {
                field: 'employee',
                headerName: strings.pages.finance.transactions.transactionList.employee,
                width: 200,
                renderCell: params => RenderColumnEmployee({params})
            },
            {
                field: 'partner',
                headerName: strings.pages.finance.transactions.transactionList.partner,
                width: 200,
                renderCell: params => RenderColumnPartner({params})
            },
            {
                field: 'contract',
                headerName: strings.pages.finance.transactions.transactionList.contract,
                width: 200,
                renderCell: params => RenderColumnContract({params})
            },
            {
                field: 'contractPart',
                headerName: strings.pages.finance.transactions.transactionList.contractPart,
                width: 200,
                renderCell: params => RenderColumnContractPart({params})
            },
            {
                field: 'invoice',
                headerName: strings.pages.finance.transactions.transactionList.invoice,
                width: 200,
                renderCell: params => RenderColumnInvoice({params})
            },
            {
                field: 'transactionCategory',
                headerName: strings.pages.finance.transactions.transactionList.transactionCategory,
                width: 200,
                renderCell: params => RenderColumnTransactionCategory({params})
            },
            {
                field: 'transactionType',
                headerName: strings.pages.finance.transactions.transactionList.transactionType,
                width: 200,
                renderCell: renderColumnTransactionType
            },
            {
                field: 'tax',
                headerName: strings.pages.finance.transactions.transactionList.tax,
                width: 200,
                renderCell: renderColumnTax
            },
            {
                field: 'canReturnTax',
                headerName: strings.pages.finance.transactions.transactionList.canReturnTax,
                width: 150,
                headerAlign: 'center',
                align: 'center',
                renderCell: renderColumnCanReturnTax
            }
        ]
    }


    const RenderColumnEmployee = ({params}) => {
        return <RenderAutocompleteColumn disabled={!!params.row.partner} name={'employee'} params={params}
                                         nameKey={'fullName'}
                                        placeholder={strings.forms.finance.transactionForm.placeholder.employee}
                                         getFunction={async (data) => {
                                             const response = await getEmployees(data);
                                             response.data.result = response.data.result.map(employee => employee.user);
                                             return response;
                                         }} onSelectChange={(name, value) => onSelectChange(TransactionRelationType.EMPLOYEE, value, params)}/>
    }

    const RenderColumnPartner = ({params}) => {
        return <RenderAutocompleteColumn 
                    disabled={!!params.row.employee} 
                    name={'partner'} 
                    params={params}
                    placeholder={strings.forms.finance.transactionForm.placeholder.partner}
                    getFunction={getPartners} 
                    onSelectChange={(name, value) => onSelectChange(TransactionRelationType.PARTNER, value, params)}
                />
    }


    const RenderColumnContract = ({params}) => {
        return <RenderAutocompleteColumn 
                    disabled={!params.row.partner} 
                    name={'contract'} 
                    params={params}
                    placeholder={strings.forms.finance.transactionForm.placeholder.contract}
                    getFunction={data => getContracts({...data,partner: params.row?.partner?.id})}
                    onSelectChange={(name, value) => onSelectChange(TransactionRelationType.CONTRACT, value, params)}
                />
    }

    const RenderColumnContractPart = ({params}) => {
        return <RenderAutocompleteColumn 
                    disabled={!params.row.partner || !params.row.contract} 
                    name={'contract-part'} 
                    params={params} 
                    nameKey={'description'}
                    placeholder={strings.forms.finance.transactionForm.placeholder.contractPart}
                    getFunction={data => getContractParts({...data,contractId: params.row?.contract?.id})}
                    onSelectChange={(name, value) => onSelectChange(TransactionRelationType.CONTRACT_PART, value, params)}/>

    }

    const RenderColumnInvoice = ({params}) => {
        return <RenderAutocompleteColumn 
                    disabled={!params.row.partner} 
                    name={'invoice'} 
                    params={params} 
                    nameKey={'invoiceNumber'}
                    placeholder={strings.forms.finance.transactionForm.placeholder.invoice}
                    getFunction={data => getInvoices({...data,partner: params.row?.partner?.id, invoiceDirection: getInvoiceDirectionFromPaymentType(params.row?.paymentType)})}
                    onSelectChange={(name, value) => onSelectChange(TransactionRelationType.INVOICE, value, params)}
                />
    }

    const RenderColumnTransactionCategory = ({params}) => {
        return <RenderAutocompleteColumn 
                    name={'transaction-category'} 
                    params={params}
                    getFunction={getTransactionCategoryList}
                    placeholder={strings.forms.finance.transactionForm.placeholder.transactionCategory}
                    onSelectChange={(name, value) => onSelectChange(TransactionRelationType.TRANSACTION_CATEGORY, value, params)}
                />
    }

    const renderColumnTransactionType = (params) => {
        const transactionTypes = getTransactionTypeByPaymentType(params.row.paymentType);
        return <SelectControl
            className={'table-select'}
            value={params.value}
            options={transactionTypes}
            name={`transactionType-${params.row.id}`}
            valueKey={'id'}
            nameKey={'name'}
            setValue={(name, value) => onSelectChange(TransactionRelationType.TRANSACTION_TYPE, value, params)}
        />
    }

    const renderColumnTax = (params) => {
        params.value = getTaxRates(siteData).find(x => x.value == params.value);
        return <SelectControl
            className={'table-select'}
            disabled={params.row.contract || params.row.employee || params.row.paymentType === PaymentType.INCOME}
            value={params.value}
            options={getTaxRates(siteData)}
            name={'tax'}
            valueKey={'id'}
            nameKey={'valueText'}
            setValue={(name, value) => onSelectChange(TransactionRelationType.TAX, value, params)}
        />
    }

    const renderColumnCanReturnTax = (params) => {
        return <Checkbox
            checked={params.row.canReturnTax}
            onChange={(e) => onSelectChange(TransactionRelationType.CAN_RETURN_TAX, e.target.checked, params)}
        />
    }

    const onSelectChange = (relationType, newValue, params) => {

        let value = newValue ? newValue.id : -1;

        if(relationType === TransactionRelationType.CAN_RETURN_TAX) {
            value = Boolean(newValue);
        }

        connectTransactions({
            id: params.id,
            value,
            relationType
        }).then(response => {
            if(!response || !response.ok) {
                showMessage(strings.commonMessages.errorSaving, 'error');
                return;
            }

            showMessage(strings.commonMessages.saved, 'success');
            fetch();
        });
    }

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        });

        getTransactions({
            ...filter,
            approved: props.approved
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total,
            });
        });
    }

    const approve = () => {

        setTableData({
            ...tableData,
            loading: true
        });

        let transactions = [];

        for(let item of selectedItems) {
            transactions.push(item.id);
        }

        approveTransactions({
            transactions: transactions
        }).then(response => {

            if(!response || !response.ok) {
                showMessage(strings.commonMessages.errorSaving, 'error');
                return;
            }

            showMessage(strings.commonMessages.saved);

            setTimeout(() => {
                fetch();
            }, 2000);
        });
    }

    const discoverControls = () => {
        if(props.approved) {
            return [];
        }
        return [
            <Button className="approve" variant="contained" color="primary" onClick={approve} disabled={selectedItems?.length===0}>
                {strings.pages.finance.transactions.transactionList.approve}
            </Button>
        ]
    }

    return <TablePageContext.Provider value={value}>
        <FormProvider {...form}>
                <div className={'transactions-page'}>
                    <TablePage onFinish={() => onFinish()} tableDescription={columns} deleteItem={deleteTransaction}
                               tableData={tableData} filter={filter} filters={filters}
                               filterOptions={filterOptions}
                               additionalMenuItems = {additionalMenuItems}
                               permissionGroup={permissionGroup}
                               referenceType={referenceType}
                               customPage={customPage}
                               checkboxSelection={!props.approved}
                               innerToolbarControls={discoverControls()}
                               tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                               addPage={<AddTransaction onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                               editPage={<EditTransaction data={selectedItems} onCancel={() => onCancel()} onFinish={() => onFinish()}/>}

                               underBreadcrumbsSlot={props.renderUploadBankReportHeader? props.renderUploadBankReportHeader():<></>}
                               addButtonText={strings.components.tablePage.addButton.transaction}/>
                               
                </div>
        </FormProvider>

    </TablePageContext.Provider>;
}

export default TransactionList;


const RenderAutocompleteColumn = ({params,
                                    getFunction = async (data) => {},
                                    onSelectChange = (name, value) => {},
                                    disabled= false,
                                    nameKey='name',
                                    valueKey='id',
                                    name= '',
                                    placeholder=''}) => {
    const [values, setValues] = useState([]);
    const fetchNewValues = (term = '') => {
        if (getFunction) {
            getFunction({term}).then((response) => {
                setValues(response.data.result);
            });
        }
    }
    return <AutoCompleteControl
        onKeyDown={(e) => e.stopPropagation()}
        className={'table-autocomplete'}
        name={`${name}-${params.row.id}`}
        nameKey={nameKey}
        valueKey={valueKey}
        setValue={onSelectChange}
        onChange={fetchNewValues}
        onOpen={() =>fetchNewValues()}
        options={values}
        value={params.value}
        disabled={disabled}
        placeholder={placeholder} />
}

