import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";


export async function getCompanyDocumentCategories(parent) {
    return await request('/api/company-document-categories', transformParentRequest(parent));
}

export async function getAllCompanyDocumentCategories(data) {
    return await request('/api/company-document-categories/all', data);
}

export async function addCompanyDocumentCategory(data) {
    return await request('/api/company-document-categories', transformRequestData(data), HttpMethod.POST);
}

export async function editCompanyDocumentCategory(data) {
    return await request('/api/company-document-categories/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteCompanyDocumentCategory(id) {
    return await request('/api/company-document-categories/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        parentId: data.parent ? data.parent.id : null
    }
}

function transformParentRequest(parent){
    return {
        parent: parent ? parent : null
    }
}