import HttpMethod from "../../Constants/Base/HttpMethod";
import {request} from "../../Base/HTTP";


export async function getAllContracts() {
    return await request('/api/contracts/all');
}

export async function getContractPart(id) {
    return await request('/api/contract-part/' + id);
}

export async function getContractParts(data) {
    return await request('/api/contract-parts', data);
}

export async function getAllContractParts() {
    return await request('/api/contract-parts/all', {});
}

export async function addContractPart(data) {
    return await request('/api/contract-parts/' + data.contract, transformRequestData(data), HttpMethod.POST);
}

export async function editContractPart(data) {
    return await request('/api/contract-parts/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteContractPart(id) {
    return await request('/api/contract-parts/' + id, {}, HttpMethod.DELETE);
}

export async function generateInvoice(data) {
    return await request('/api/contract-parts-generate-invoice', transformGenerateInvoiceData(data), HttpMethod.POST);
}

export async function getAllForContract(){
    return await request('/api/contract-parts/',{});
}

function transformRequestData(data) {
    return {
        ...data,
        contractPartStatusId: data.contractPartStatus ? data.contractPartStatus.id : undefined,
    }
}

function transformGenerateInvoiceData(data) {
    return {
        ...data,
        contractId: data.contract ? data.contract.id : undefined,
    }
}
