import React, {useContext, useEffect, useState} from "react";
import {getProjectStatistic} from "../../../Services/Project/ProjectService";
import {Grid, LinearProgress, Paper} from "@mui/material";
import strings from "../../../localization";
import LineChart from "../../../Components/Charts/LineChart";
import PieChart from "../../../Components/Charts/PieChart";
import {monthShortLabels} from "../../../Util/ChartUtil";
import StatisticComponent from "../../../Components/DataGrid/StatisticComponent";
import {renderNumber, renderNumberWithLabel} from "../../../Util/RenderDataUtil";
import {useDispatch} from "react-redux";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import {pieBackgroundColors, pieBorderColors} from "../../../Util/ChartUtil";
import LoaderContext from "../../../Context/LoaderContext";
import SelectControl from "../../../Components/Controls/Inputs/SelectControl";
import {getCurrentYear, getYears} from "../../../Util/DateUtil";

const ProjectStatistic = ({project}) => {

    const [overview, setOverview] = useState(null);
    const dispatch = useDispatch();
    const pieLabels = [strings.constants.project.projectTimeLog.billable, strings.constants.project.projectTimeLog.notBillable];
    const {loading, setLoading} = useContext(LoaderContext)
    const [selectedYear, setSelectedYear] = useState(getCurrentYear());

    useEffect(() => {
        dispatch(changeBreadcrumbs({
            title: strings.pages.project.projectStatistic.pageTitle,
            hierarchy: [
                {label: strings.navigation.managmentTag},
                {label: Modules.PROJECTS},
                {label: strings.pages.project.details},
                {label: strings.pages.project.projectStatistic.pageTitle},
            ],
        }));
    }, [])

    useEffect(() => {
        setLoading(true);
        getProjectStatistic(project.id, selectedYear).then(response => {

            if (!response || !response.ok) {
                return ''
            }

            setOverview({
                data: {
                    ...response.data,
                    ...calculateProfit(response.data)
                },
            });
        }).finally(() => setLoading(false));
    }, [project, selectedYear]);

    const yearChangeHandler = (year) => {

        if (!year) {
            return
        }

        setSelectedYear(year)
    }

    const calculateProfit = (data) => {
        let profitByHourRate = [];
        let profitByAverageHourRate = [];
        let profitByHourRateTotal = [];
        let profitByAverageHourRateTotal = [];
        let profitByHourRateTotalSum = 0;
        let profitByAverageHourRateTotalSum = 0;

        for (let month = 0; month < 12; month++) {
            let currentProfitByHourRate = data.projectInvoices[month] - data.projectCostByHourRate[month] - data.projectBills[month];
            let currentProfitByAverageHourRate = data.projectInvoices[month] - data.projectCostByAverageHourRate[month] - data.projectBills[month]

            profitByHourRateTotalSum += currentProfitByHourRate;
            profitByAverageHourRateTotalSum += currentProfitByAverageHourRate;

            profitByHourRate.push(currentProfitByHourRate)
            profitByAverageHourRate.push(currentProfitByAverageHourRate)

            profitByHourRateTotal.push(profitByHourRateTotalSum)
            profitByAverageHourRateTotal.push(profitByAverageHourRateTotalSum);
        }

        return {
            profitByHourRate: profitByHourRate,
            profitByAverageHourRate: profitByAverageHourRate,
            profitByHourRateTotal: profitByHourRateTotal,
            profitByAverageHourRateTotal: profitByAverageHourRateTotal
        }
    }

    const calculateStatistics = (data) => {

        let timeLogged = 0;
        let billableTimeLogged = 0;
        let timeCostByHourRate = 0;
        let timeCostByAverageHourRate = 0;
        let profitByHourRate = 0;
        let profitByAverageHourRate = 0;
        let income = 0;
        let outcome = 0;
        let projectProfit = 0;

        for (let item of data.timeLogged) {
            timeLogged += item;
        }

        for (let item of data.billableTimeLogged) {
            billableTimeLogged += item;
        }

        for (let item of data.projectCostByHourRate) {
            timeCostByHourRate += item;
        }

        for (let item of data.projectCostByAverageHourRate) {
            timeCostByAverageHourRate += item;
        }

        for (let item of data.projectInvoices) {
            income += item;
        }

        for (let item of data.projectBills) {
            outcome += item;
        }

        if (data.profitByHourRateTotal) {
            profitByHourRate = data.profitByHourRateTotal[11];
        }

        if (data.profitByAverageHourRateTotal) {
            profitByAverageHourRate = data.profitByAverageHourRateTotal[11];
        }

        if (income > outcome) {

            projectProfit = (outcome / income) * 100;

            if (outcome == 0) {
                projectProfit = 100;
            }

        }

        return [
            {
                title: strings.pages.project.projectStatistic.hours,
                size: 3,
                data: [
                    {value: renderNumberWithLabel(parseFloat(timeLogged).toFixed(2), strings.pages.project.projectStatistic.total, ' h')},
                    {value: renderNumberWithLabel(parseFloat(billableTimeLogged).toFixed(2), strings.pages.project.projectStatistic.billable, ' h')},
                    {value: renderNumberWithLabel(parseFloat(timeLogged).toFixed(2) - parseFloat(billableTimeLogged).toFixed(2), strings.pages.project.projectStatistic.notBillable, ' h')}
                ]
            },
            {
                title: strings.pages.project.projectStatistic.timeCost,
                size: 3,
                data: [
                    {value: renderNumberWithLabel(timeCostByHourRate, strings.pages.project.projectStatistic.byHourRate, ' RSD')},
                    {value: renderNumberWithLabel(timeCostByAverageHourRate, strings.pages.project.projectStatistic.byAverageHourRate, ' RSD')},
                ]
            },
            {
                title: strings.pages.project.projectStatistic.invoicesAndBills,
                size: 3,
                data: [
                    {value: renderNumberWithLabel(income, strings.pages.project.projectStatistic.income, ' RSD')},
                    {value: renderNumberWithLabel(outcome, strings.pages.project.projectStatistic.outcome, ' RSD')},
                ]
            },
            {
                title: strings.pages.project.projectStatistic.profit,
                size: 3,
                data: [
                    {value: renderNumberWithLabel(profitByHourRate, strings.pages.project.projectStatistic.byHourRate, ' RSD')},
                    {value: renderNumberWithLabel(profitByAverageHourRate, strings.pages.project.projectStatistic.byAverageHourRate, ' RSD')},
                    {value: renderNumberWithLabel(projectProfit, strings.pages.project.projectStatistic.totalProfit, ' %')},
                ]
            }
        ]
    }

    return <div className='container'>
        {
            overview &&
            <React.Fragment>
                <Grid container>
                    <Grid item xs={3}>
                        <div className={'filter-container'}>
                            <SelectControl
                                defaultValue={selectedYear}
                                value={selectedYear}
                                setValue={(name, value) => yearChangeHandler(value?.id)}
                                label={strings.pages.project.projectStatistic.year}
                                options={getYears()}
                                nameKey={'value'}
                                valueKey={'id'}
                            />
                        </div>
                    </Grid>
                </Grid>

                <StatisticComponent data={calculateStatistics(overview.data)} className='m-0'/>
                <div className='graphs'>
                    <Grid container>
                        <Grid item xs={6}>
                            <div className='graph'>
                                <div className='title'>{strings.pages.project.projectStatistic.hours}</div>
                                {
                                    // https://chartjs-plugin-datalabels.netlify.app/guide/getting-started.html#integration
                                }
                                <PieChart
                                    inputData={{
                                        labels: pieLabels,
                                        datasets: [
                                            {
                                                data: overview.data?.timeLogValues,
                                                backgroundColor: pieBackgroundColors,
                                                borderColor: pieBorderColors,
                                                borderWidth: 0,
                                            }
                                        ],
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className='graph'>
                                <div className='title'>{strings.pages.project.projectStatistic.hours}</div>
                                <LineChart labels={monthShortLabels}
                                           inputData={[
                                               {
                                                   label: strings.pages.project.projectStatistic.hours,
                                                   data: overview.data.timeLogged
                                               },
                                               {
                                                   label: strings.pages.project.projectStatistic.billableHours,
                                                   data: overview.data.billableTimeLogged
                                               }
                                           ]}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className='graph'>
                                <div className='title'>{strings.pages.project.projectStatistic.timeCost}</div>
                                <LineChart labels={monthShortLabels}
                                           inputData={[
                                               {
                                                   label: strings.pages.project.projectStatistic.costByHourRate,
                                                   data: overview.data.projectCostByHourRate
                                               },
                                               {
                                                   label: strings.pages.project.projectStatistic.costByAverageHourRate,
                                                   data: overview.data.projectCostByAverageHourRate
                                               }
                                           ]}/>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className='graph'>
                                <div className='title'>{strings.pages.project.projectStatistic.invoicesAndBills}</div>
                                <LineChart labels={monthShortLabels}
                                           inputData={[
                                               {
                                                   label: strings.pages.project.projectStatistic.invoices,
                                                   data: overview.data.projectInvoices
                                               },
                                               {
                                                   label: strings.pages.project.projectStatistic.bills,
                                                   data: overview.data.projectBills
                                               }
                                           ]}/>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className='graph'>
                                <div className='title'>{strings.pages.project.projectStatistic.profit}</div>
                                <LineChart labels={monthShortLabels}
                                           inputData={[
                                               {
                                                   label: strings.pages.project.projectStatistic.profitByHourRate,
                                                   data: overview.data.profitByHourRate
                                               },
                                               {
                                                   label: strings.pages.project.projectStatistic.profitByAverageHourRate,
                                                   data: overview.data.profitByAverageHourRate
                                               },
                                               {
                                                   label: strings.pages.project.projectStatistic.profitByHourRateTotal,
                                                   data: overview.data.profitByHourRateTotal
                                               },
                                               {
                                                   label: strings.pages.project.projectStatistic.profitByAverageHourRateTotal,
                                                   data: overview.data.profitByAverageHourRateTotal
                                               }
                                           ]}/>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        }
    </div>;
}

export default ProjectStatistic;
