import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {useDispatch} from "react-redux";
import {
    connectAttributeValue,
    deleteAttributeValue, disconnectAttributeValue,
    getAttributeValuesList
} from "../../../Services/Product/AttributeValueService";
import TablePage from "../../../Components/DataGrid/TablePage";
import strings from "../../../localization";
import {formatColumnConstant, formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import PageState from "../../../Constants/Base/PageState";
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";
import AddAttributeValue from "./AddAttributeValue";
import EditAttributeValue from "./EditAttributeValue";
import {getWooCommerceSettings} from "../../../Services/Product/ProductSettingsService/WooCommerceSettingsService";
import {getAttributes} from "../../../Services/Product/AttributeService";


const tableDescription = [

    {
        field: 'name', headerName: strings.pages.product.productService.productServiceList.name,
        width: 200,
    }
];

const filters = [];

const AttributeValueList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const [filterOptions, setFilterOptions] = useState({});
    const siteData = useSelector((state) => state.siteData);
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [filter, setFilter] = useState(FilterDefaults);
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState(TablePageOptionDefaults);
    const referenceType = ReferenceType.ATTRIBUTES_VALUE;
    const permissionGroup = AppPermissions.ATTRIBUTES_VALUE.GROUP;
    const {id: attributeId} = useParams();
    const {name: attributeName} = useParams();
    const [enabled, setEnabled] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const auth = useSelector((state) => state.auth);

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(() => {
        setColumns([...tableDescription]);
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.product.attributeValue.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.PRODUCT},
                {label: strings.pages.product.attributeValue.pageTitle},
            ],
        }));

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        fetch();
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter])

    useEffect(() => {
        if(!Object.keys(siteData.data).length){
            return;
        }

    },[siteData])

    const additionalMenuItems = [
        enabled && isConnected && {
            handleClick: (id, item) => handleConnectDisconnectAttributeValue(id, item),
            text: (item) => renderText(item),
            src: (item) => renderIcon(item),
        }
    ]

    function renderText (item){
        const data = tableData.data.find((dataItem) => dataItem.id === item.id);
        if (!data) {
            return null;
        }
        if (data && data.external_id !== undefined && data.external_id !== null) {
            return strings.pages.product.productService.productServiceList.disconnect;
        }
        return strings.pages.product.productService.productServiceList.connect;
    }

    function renderIcon (item){
        const data = tableData.data.find((dataItem) => dataItem.id === item.id);
        if (!data) {
            return null;
        }
        if (data.external_id === undefined) {
            return "/images/table-page/WooCommerce/woo-commerce-connected.svg";
        }
        return "/images/table-page/WooCommerce/woo-commerce-disconnected.svg";
    }

    function handleConnectDisconnectAttributeValue(id, item) {
        if(item.external_id){
            disconnectAttributeValue(id).then(response => {
                fetch();
            })
            return;
        }
        connectAttributeValue(id).then(response => {
            fetch();
        })
    }

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const fetch = () => {
        getWooCommerceSettings(auth.company.id).then(response => {
            if(!response || !response.ok){
                return;
            }
            setEnabled(response.data.entity.enabled);
        });

        getAttributes(attributeId).then(response => {
            let attribute = response.data.entity;
            if(attribute.external_id === undefined){
                setIsConnected(false);
                return;
            }
            setIsConnected(true);
        })

        setTableData({
            ...tableData,
            loading: true
        });

        getAttributeValuesList({
            ...filter,
            attributeId: attributeId,
        }).then(response => {

            if (!response || !response.ok) {
                return;
            }

            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        })
    }



    return <TablePageContext.Provider value={value}>
        <TablePage tableDescription={columns} deleteItem={deleteAttributeValue}
                   tableData={tableData} onFinish={onFinish}
                   filter={filter} filters={filters} filterOptions={filterOptions}
                   referenceType={referenceType}
                   additionalMenuItems={additionalMenuItems}
                   permissionGroup={permissionGroup}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditAttributeValue attributeId={attributeId} data={ selectedItems } onFinish={() => onFinish()} onCancel = {() => onCancel()}/> }
                   addPage={<AddAttributeValue attributeId={attributeId} onCancel={() => onCancel()} onFinish={() => onFinish() }
                   />}
                   addButtonText={strings.components.tablePage.addButton.attributeValue}
        />
    </TablePageContext.Provider>;
}

export default AttributeValueList;
