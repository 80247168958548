export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isFloat(n) {
    return !isNaN(parseFloat(n));
}

export function isInt(n) {
    return isFinite(n)
}

export function isLink(link) {

    let regularExpretion = /^(ftp|http|https):\/\/[^ "]+$/;

    return regularExpretion.test(link);
}

export function isNumberFloat(type, value) {
    if(isNaN(value) || type === 'undefined') {
        return false;
    }

    if(type === 'number' && !Number.isInteger(value)) {
        return true;
    }
    return false;
}