import React, {useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import CheckBoxControl from "../../../../Controls/Inputs/CheckBoxControl";
import { getContractTypes } from '../../../../../Constants/Finance/ContractType';
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import {getPaymentMonths} from "../../../../../Constants/HR/PaymentMonth";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";
import PartnerLegalType from "../../../../../Constants/CRM/PartnerLegalType";

const ContractForm = ({
                                onSubmit,
                                onCancel,
                                data,
                                currenciesData,
                                partnersData,
                                transactionCategoriesData,
                                taxes,
                                form,
                                values,
                                setValue,
                                control,
                                formRules,
                                errors,
                                projects,
                                contractStatuses,
                                onChangePartnerFilter,
                                onChangeTransactionCategoryFilter,
                                onChangeProjectFilter,
                                onChangeContractStatusFilter, trigger,
                            }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const [startDate, setStartDate] = useState(values['startDate']);
    const [additionalRules, setAdditionalRules] = useState({});


    useEffect(() => {
        if(form.formState.isSubmitted){
            trigger('endDate');
        }
    }, [additionalRules, values['endDate']])

    useEffect(() => {
        setStartDate(values['startDate']);
    }, [values['startDate']])

    useEffect(() => {
        changeAdditionalRules(startDate);
    },[startDate])

    const changeStartDate = (date) => {
        setValue('startDate', date);
        setStartDate(date);
    }

    const changeAdditionalRules = (date) => {
        if(!date){
            setAdditionalRules({});
            return;
        }
        setAdditionalRules({min: {value: date, message: strings.forms.hr.presence.dateTimeErorr}});
    }

    return (
        <FormProvider {...form}>
            <form id="contract-form" className='form' action = "#">

                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    control={data}
                    error={Boolean(errors.name)}
                    helperText={errors.name && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.contract.contractForm.name}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='description'
                    rules={formRules['description']}
                    control={data}
                    error={Boolean(errors.description)}
                    helperText={errors.description && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.contract.contractForm.description}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='contractNumber'
                    rules={{required: form.watch('partner')?.partnerLegalType === PartnerLegalType.PUBLIC}}
                    control={data}
                    error={Boolean(errors.contractNumber)}
                    helperText={errors.contractNumber && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.contract.contractForm.contractNumber}
                    disabled={isPageStateViewDetails()}
                />

                <div className='header-row'>
                    <div className='header-row-item'>
                        <DateTimeControl
                            data={data}
                            rules={formRules['startDate']}
                            form={form}
                            error={Boolean(errors.startDate)}
                            helperText={errors.startDate && strings.forms.common.thisFieldIsRequired}
                            label={strings.forms.finance.contract.contractForm.startDate}
                            name={'startDate'}
                            value={startDate}
                            disabled={isPageStateViewDetails()}
                            setValue={(name, value) => changeStartDate(value)}
                        />
                    </div>

                    <div className='header-row-item'>
                        <DateTimeControl
                            data={data}
                            rules={{...formRules['endDate'], ...additionalRules}}
                            form={form}
                            error={Boolean(errors.endDate)}
                            helperText={errors.endDate && errors.endDate.message}
                            label={strings.forms.finance.contract.contractForm.endDate}
                            name={'endDate'}
                            value={values['endDate']}
                            setValue={setValue}
                            minDate={startDate}
                            disabled={isPageStateViewDetails()}
                        />
                    </div>
                </div>

                <SelectControl
                    value={values['contractType']}
                    setValue={setValue}
                    rules={formRules['contractType']}
                    name='contractType'
                    control={control}
                    error={Boolean(errors.contractType)}
                    helperText={errors.contractType && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.contract.contractForm.contractType}
                    options={getContractTypes()}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    name='partner'
                    label={strings.forms.finance.contract.contractForm.partner}
                    nameKey={'name'}
                    valueKey={'id'}
                    rules={formRules['partner']}
                    error={Boolean(errors.partner)}
                    helperText={errors.partner && strings.forms.common.thisFieldIsRequired}
                    setValue={setValue}
                    onChange={onChangePartnerFilter}
                    onOpen={() =>onChangePartnerFilter('')}
                    control={control}
                    options={partnersData}
                    value={values['partner']}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    name='transactionCategory'
                    label={strings.forms.finance.contract.contractForm.transactionCategory}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={setValue}
                    onChange={onChangeTransactionCategoryFilter}
                    onOpen={() =>onChangeTransactionCategoryFilter('')}
                    control={control}
                    options={transactionCategoriesData}
                    value={values['transactionCategory']}
                    rules={formRules['transactionCategory']}
                    error={Boolean(errors.transactionCategory)}
                    helperText={errors.transactionCategory && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                />


                <SelectControl
                    value={parseInt(values['tax'])}
                    setValue={setValue}
                    rules={formRules['tax']}
                    name='tax'
                    control={control}
                    error={Boolean(errors.tax)}
                    helperText={errors.tax && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.contract.contractForm.tax}
                    options={taxes.map(tax => ({...tax, valueText: `${tax.value}%`}))}
                    nameKey={'valueText'}
                    valueKey={'value'}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={values['currency']}
                    setValue={setValue}
                    rules={formRules['currency']}
                    name='currency'
                    control={control}
                    error={Boolean(errors.currency)}
                    helperText={errors.currency && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.contract.contractForm.currency}
                    options={currenciesData}
                    nameKey={'code'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                <CheckBoxControl
                    name={'canReturnTax'}
                    control={data}
                    label={strings.forms.finance.contract.contractForm.canReturnTax}
                    value={values['canReturnTax']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='paymentDay'
                    rules={formRules['paymentDay']}
                    control={data}
                    type='number'
                    error={Boolean(errors.paymentDay)}
                    helperText={errors.paymentDay && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.organisationUserContract.paymentDay}
                    disabled={isPageStateViewDetails()}
                />
                <TextFieldControl
                    name='amount'
                    rules={formRules['amount']}
                    control={data}
                    type='number'
                    error={Boolean(errors.amount)}
                    helperText={errors.amount && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.organisationUserContract.amount}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={values['paymentMonth']}
                    rules={formRules['paymentMonth']}
                    setValue={setValue}
                    name='paymentMonth'
                    control={control}
                    error={Boolean(errors.paymentMonth)}
                    helperText={errors.paymentMonth && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.hr.organisationUserContract.paymentMonth}
                    options={getPaymentMonths()}
                    nameKey={'key'}
                    valueKey={'value'}
                    disabled={isPageStateViewDetails()}
                />
                <AutoCompleteControl
                    name='project'
                    label={strings.forms.finance.contract.contractForm.project}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={setValue}
                    onChange={onChangeProjectFilter}
                    onOpen={() =>onChangeProjectFilter('')}
                    control={control}
                    options={projects}
                    value={values['project']}
                    rules={formRules['project']}
                    error={Boolean(errors.project)}
                    helperText={errors.project && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                />
                <AutoCompleteControl
                    name='contractStatus'
                    label={strings.forms.finance.contract.contractForm.contractStatus}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={setValue}
                    onChange={onChangeContractStatusFilter}
                    onOpen={() =>onChangeContractStatusFilter('')}
                    control={control}
                    options={contractStatuses}
                    value={values['contractStatus']}
                    rules={formRules['contractStatus']}
                    error={Boolean(errors.contractStatus)}
                    helperText={errors.contractStatus && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() &&
                    <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={ onSubmit }>
                            { strings.forms.common.save }
                        </Button>
                        <Button variant="contained" color="secondary" onClick={ onCancel }>
                            { strings.forms.common.cancel }
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default ContractForm;