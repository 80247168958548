import { TreeView } from "@mui/lab";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Modal, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import strings from "../../localization";
import { ShowLess, ShowMore } from "./StyledTreeItem";
import { useForm } from "react-hook-form";
import TreeViewAddEditForm from "../Forms/Pages/Document/Spaces/TreeViewAddEditForm";
import StyledTreeItem from "./StyledTreeItem";
import { useSelector } from "react-redux";
import { hasPermission } from "../../Util/PermissionUtil";
import AppPermissions from "../../Constants/Permissions/Permissions";
import LoaderContext from "../../Context/LoaderContext";
import YesNoDialog, { YesNoDialogResult } from "../Dialogs/YesNoDialog";

const formRules = {
    'name': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
};

const TreeViewList = ({onExpand, folderIcon, childIcon, getFolders, addFolder, permissionGroup, addStringButton, addStringModal, editFolder, deleteFolder, changeState = () => {}, editOutside = false, addItemString, editItemString, tableFormatResponse}) => {

    const [data, setData] = useState([]);

    const auth = useSelector((state) => state.auth)

    const [expanded, setExpanded] = useState([]);
    const { innerHeight: height } = window;
	const [handleShowModal, setHandleShowModal] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(-1)
    
	const [folderToEdit, setFolderToEdit] = useState(null);
    const [folderAnchor, setFolderAnchor] = useState(null);

    const form = useForm({});
    const {formData, handleSubmit, getValues, setValue, setError, formState: {errors}} = form;

    const {loading, setLoading} = useContext(LoaderContext)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [changeTreeData, setChangeTreeData] = useState(false);

    const expandOptionsIcon = () => <img src='/images/more-vertical.svg'/>;

    const createFolderTreeData = (response) => {
        const treeData = createTreeNodes(response)
        return treeData;
    };

    const fetchFoldersWithoutParent = () => {
        getFolders().then(response => {
            const treeData = createFolderTreeData(tableFormatResponse ? response.data.result : response.data);
            setData(treeData);
            setLoading(false);
        });
    };

    useEffect(() => {
        setLoading(true);
        fetchFoldersWithoutParent();
    }, [changeTreeData, editOutside]);

    const findAndUpdateChild = (id, children, objects) => {
        objects.map(function(object) {
            if (object.id === id) {
                object["children"] = createTreeNodes(children)
            }
            if (object.children) {
                findAndUpdateChild(id, children, object.children);
            }
        });
        return objects;
    };

    const updateChildren = (id, children, data) => {
        const array = [...data];
        const parentIndex = array.findIndex((parentObject) => parentObject.id === id);
        let newData = [...data];

        if(parentIndex !== -1) {
            newData[parentIndex]["children"] = createTreeNodes(children);
            setData(newData);
        } else {
            findAndUpdateChild(id, children, newData);
            setData(newData);
        }
        return newData;
    };

    const expandHandler = (node, selectedFolder, {data, expanded}) => {
        if(expanded.indexOf(node.id) === -1) {
            onExpand(node);
            if(node.childCount > 0) {
                setExpanded([...expanded, node.id]);
            }else{
                if(selectedFolder?.id === node?.id){
                    setSelectedFolder(null);
                    onExpand(null);
                    return
                }
            }
            setSelectedFolder(node);
            let newData = [...data]
            getFolders(node.id).then(response => {
                if(response.data){
                    newData = updateChildren(node.id, tableFormatResponse ? response.data.result : response.data, data)
                    updateExpandedChildrenRecursionTest(expanded, createTreeNodes(tableFormatResponse ? response.data.result : response.data), newData)
                }
            });
        } else {
            setSelectedFolder(null);
            onExpand(null);
            const index = expanded.indexOf(node.id);
            const newExpandedArray = [...expanded]
            newExpandedArray.splice(index, 1);
            setExpanded(newExpandedArray);
        }
    }

    const updateExpandedChildrenRecursionTest = async (expanded, children, data) => {
        let treeData = [...data];
        for(const child of children){
            if(expanded.indexOf(child.id) !== -1){
                const response = await getFolders(child.id);
                if(response.data){
                    treeData = updateChildren(child.id, tableFormatResponse ? response.data.result : response.data, data)
                    updateExpandedChildrenRecursionTest(expanded, createTreeNodes(tableFormatResponse ? response.data.result : response.data), treeData);
                }
            }
        }
    };

    const updateExpandedChildren = async (expandedArray, data) => {
        let treeData = [...data];

        for(const parentId of expandedArray) {
            const response = await getFolders(parentId)
            treeData = updateChildren(parentId, tableFormatResponse ? response.data.result : response.data, treeData);
            updateExpandedChildrenRecursionTest(expanded, createTreeNodes(tableFormatResponse ? response.data.result : response.data), treeData);
        };
    }

    const createTreeNodes = (objectsArray) => {
        let treeData = [];
        objectsArray.map((node) => {
            const treeNode = {
                id: node.id,
                name: node.name,
                children: [],
                childCount: node.child_count,
                parent: node.parent,
                endIcon: node.child_count > 0 ? <ShowMore /> : null,
                collapseIcon: node.child_count > 0 ? <ShowLess /> : null,
                onClick: expandHandler
            }
            treeData.push(treeNode);
        });
        return treeData;
    }

    const folderIsFound = (folderData) => {
        let isFound = false;
        if(folderData.parent?.name) {
            isFound = selectedFolder?.children.some(child => {
                if (child.name == folderData.name) {
                    setError('name', {message: strings.forms.treeViewList.nameExists} );
                    return true;
                }
                return false;
            });
        }  
        else if (folderToEdit?.name === folderData?.name) {
            return false;
        }
        else {
            isFound = data.some(child => {
                if (child.name === folderData.name) {
                    setError('name', {message: strings.forms.treeViewList.nameExists} );
                    return true;
                }
                return false;
            });
        }
        return isFound;
    };

    const updateSelectedTreeItem = (treeData) => {
        if(!selectedFolder) { return; }

        treeData.map(function (parent) {
                if (parent.id === selectedFolder.id) {
                    setSelectedFolder(parent)
                    return
                }
                if (parent.children) {
                    updateSelectedTreeItem(parent.children);
                }
            });
    }
    
    const submitHandler = (folderData) => {
        folderData = {...folderData, "parent": selectedFolder?.id ? selectedFolder : null}

        if(!folderIsFound(folderData)) {

            handleCloseModul();

            if(folderToEdit) {
                editFolder({...folderData, id: folderToEdit.id}).then((response) => {
                    changeState(response.data);
                    fetchUpdatedFolders();
                    if(folderToEdit.id === selectedFolder?.id) {
                        let newSelectedFolderData = {...selectedFolder, name: folderData.name};
                        setSelectedFolder(newSelectedFolderData);
                        onExpand(newSelectedFolderData);
                    }
                });
                return;
            }
            addFolder(folderData).then((response) => {
                changeState(response.data);
                fetchUpdatedFolders();
            });
        };
    };

    const fetchUpdatedFolders = () => {
        getFolders().then(response => {
            const treeData = createFolderTreeData(tableFormatResponse ? response.data.result : response.data);
            updateSelectedTreeItem(treeData);
            setData(treeData);
            updateExpandedChildren(expanded, treeData);
        });
    }

    const editHandler = (e) => {
        setHandleShowModal(e);

        form.setValue('name', folderToEdit.name);

        setFolderAnchor(null);
    };

    const handleDeleteDialogResult = (result, payload) => {
        if (result === YesNoDialogResult.YES) {
            deleteFolder(folderToEdit.id).then((response) => {
                if(folderToEdit.id === selectedFolder?.id) {
                    setSelectedFolder(null);
                    onExpand(null);
                }
                changeState(response.data);
                fetchUpdatedFolders();
                setFolderToEdit(null);
            });
        };

        setShowDeleteDialog(false);
        setFolderToEdit(null);
    }

    const handleShowDeleteDialog = () => {
        setShowDeleteDialog(true);
        setFolderAnchor(null);
    }

    const handleOpenFolderOptions = (event, data) => {
		event.stopPropagation();
        setFolderAnchor(event.currentTarget);
        setFolderToEdit(data);
    }

    const renderTree = (nodes) => {
        if(Array.isArray(nodes)) {
            return nodes.map((node) =>
                <div>
                    <StyledTreeItem
                        key={node.id}
                        nodeId={node.id}
                        labelText={node.name}
                        labelIcon={folderIcon}
                        onClick={() => node.onClick(node, selectedFolder, {data, expanded})}
                        endIcon={node.endIcon}
                        expandOptionsIcon={(editFolder || deleteFolder) && expandOptionsIcon }
                        expandIcon={node.endIcon}
                        collapseIcon={node.collapseIcon}
                        className={node.id === selectedFolder?.id ? 'tree-items selected' : 'tree-items'}
                        openMenu={node.openMenu}
                        onEdit={(e) => handleOpenFolderOptions(e, node)}
                    >
                        { Array.isArray(node.children) ? renderTree(node.children) : null }
                    </StyledTreeItem>
                    <Menu className = 'board-dropdown-options' anchorEl={folderAnchor} id={"basic-menu-" + node.id} open={Boolean(folderAnchor)} onClose={() => {setFolderAnchor(null); setFolderToEdit(null)}}>
                        {
                            ( hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].EDIT, auth.permissions) && editFolder ) &&
                            <MenuItem onClick = {(e) => editHandler(e)}>
                                        <ListItemIcon>
                                            <img src="/images/table-page/edit.svg" />
                                        </ListItemIcon>
                                        <ListItemText>{strings.components.actionCell.edit}</ListItemText>
                                </MenuItem>
                        }
                        {
                            ( hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].DELETE, auth.permissions) && deleteFolder ) &&
                            <MenuItem onClick={handleShowDeleteDialog}>
                                        <ListItemIcon>
                                            <img src="/images/delete-red.svg" />
                                        </ListItemIcon>
                                        <ListItemText>{strings.components.actionCell.delete}</ListItemText>
                                </MenuItem>
                        }
                    </Menu>
                </div>
            )
        }
    };

    const handleCloseModul = () => {
		setHandleShowModal(null);
        setFolderToEdit(null);
        form.reset();
	}

    return (
        <div id={'tree-list-container'}>
            <YesNoDialog show={showDeleteDialog}
                payload={folderToEdit}
                handleResult={handleDeleteDialogResult}
                title={strings.components.tablePage.confirmDelete}
                text={strings.components.tablePage.confirmDeleteMessage}/>
            {  hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].VIEW, auth.permissions) &&
                <Paper>
                {  hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].ADD, auth.permissions) &&
                        <IconButton className='add-button' onClick={(e) => { setHandleShowModal(e); }}>
                            <img src='/images/table-page/add-cross-blue.svg' className="document-folder-icon" />
                            <div className={'add-folder-text'}>{addStringButton}</div>
                        </IconButton>
                    }
                    <TreeView
                        className="tree-view-list-item"
                        aria-label="rich object"
                        expanded={expanded}
                        sx={{ flexGrow: 1, width: '100%',height: height - 55, overflowY: 'auto' , overflowX: 'hidden' }}>
                        
                        { data && renderTree(data) }
                    </TreeView>
                </Paper>
            }
            <Modal
                open={Boolean(handleShowModal)}
                onClose={handleCloseModul}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id='add-edit-folder-modal'>
                <Paper>
                    <img className='add-edit-folder-modal-close' src='/images/close-issue-view.svg' onClick = {handleCloseModul} />
                    <div className='add-edit-folder-modal-title'>{folderToEdit ? editItemString : addItemString}</div> 
                    { !folderToEdit &&
                        <TreeViewAddEditForm
                            formRules={formRules}
                            values={getValues()}
                            setValue={setValue}
                            errors={errors} formData={formData} form={form}
                            onSubmit={handleSubmit(submitHandler)}
                        />
                    }
                    { folderToEdit && 
                        <TreeViewAddEditForm
                            formRules={formRules}
                            values={getValues()}
                            setValue={setValue}
                            errors={errors} formData={formData} form={form}
                            onSubmit={handleSubmit(submitHandler)}
                    />
                    }
                </Paper>
            </Modal>
        </div>
    );
}

export default TreeViewList;

