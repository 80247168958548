import React, {useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";
import CheckBoxControl from "../../../../Controls/Inputs/CheckBoxControl";
import TransactionType, { getTransactionTypes } from '../../../../../Constants/Finance/TransactionType';
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import { getPaymentTypes } from '../../../../../Constants/Finance/PaymentType';
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";
import { getTransactionTypeByPaymentType } from '../../../../../Constants/Finance/TransactionType';


const TransactionForm = ({
                                onSubmit,
                                onCancel,
                                data,
                                
                                bankReports,
                                currencies,
                                partners,
                                contracts,
                                employees,
                                invoices,
                                contractParts,
                                transactionCategories,
                                taxRates,

                                form,
                                values,
                                setValue,
                                control,
                                formRules,
                                errors,

                                onChangeBankReportFilter,
                                onChangeEmployeeFilter,
                                onChangePartnerFilter,
                                onChangeContractFilter,
                                onChangeContractPartFilter,
                                onChangeTransactionCategoryFilter,
                                onChangeInvoiceFilter,
                            }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    const [selectedInvoice, setSelectedInvoice] = useState(values['invoice']);
    const [selectedContract, setSelectedContract] = useState(values['contract']);
    const [selectedContractPart, setSelectedContractPart] = useState(values['contractPart']);
    const [selectedEmployee, setSelectedEmployee] = useState(values['employee']);
    const [selectedPartner, setSelectedPartner] = useState(values['partner']);
    const [selectedPaymentType, setSelectedPaymentType] = useState(values["paymentType"]);
    const [selectedTransactionType, setSelectedTransactionType] = useState(values["transactionType"])
    const [transactionTypeOptions, setTransactionTypeOptions] = useState(getTransactionTypeByPaymentType(values["paymentType"]?.id));

    useEffect(() => {
        setSelectedContractPart(values['contractPart'])
    }, [values['contractPart']])

    useEffect(() => {
        setSelectedContract(values['contract'])
    }, [values['contract']])

    useEffect(() => {
        setSelectedPartner(values['partner'])
    }, [values['partner']])

    useEffect(() => {
        setSelectedEmployee(values['employee'])
    }, [values['employee']])

    useEffect(() => {
        setSelectedInvoice(values['invoice'])
    }, [values['invoice']])

    useEffect(() => {
        setSelectedPaymentType(values['paymentType']);
    }, [values['paymentType']])

    useEffect(() => {
        setSelectedTransactionType(values['transactionType']);
    }, [values['transactionType']])

    const changeInvoice = (invoice) => {
        setValue('invoice', invoice);
        setSelectedInvoice(invoice);
    }

    const changeContract = (contract) => {
        setValue('contract', contract);
        setSelectedContract(contract);
        changeContractPart(null)
    }

    const changeEmployee = (employee) => {
        setValue('employee', employee);
        setSelectedEmployee(employee)
    }

    const changePartner = (partner) => {
        setValue('partner', partner);
        setSelectedPartner(partner);
        changeContract(null);
        changeInvoice(null);
        onChangeInvoiceFilter();
    }

    const changeContractPart = (contractPart) => {
        setValue('contractPart', contractPart);
        setSelectedContractPart(contractPart)
    }

    const changePaymentType = (paymentType) => {
        setValue("paymentType", paymentType);
        setSelectedPaymentType(paymentType);

        changeInvoice(null);
        onChangeInvoiceFilter();

        setTransactionTypeOptions(getTransactionTypeByPaymentType(paymentType?.id));
        if(!paymentType || selectedTransactionType?.id !== TransactionType.TRANSACTION){
            changeTransactionType(null);      
        }
    }

    const changeTransactionType = (transactionType) => {
        setValue('transactionType', transactionType);
        setSelectedTransactionType(transactionType);
    }

    return (
        <FormProvider {...form}>
            <form id="transaction-form" className='form' action = "#">

                <AutoCompleteControl
                    name='bankReport'
                    rules={formRules['bankReport']}
                    label={strings.forms.finance.transactionForm.bankReport}
                    nameKey={'reportNumber'}
                    valueKey={'id'}
                    setValue={setValue}
                    onChange={onChangeBankReportFilter}
                    onOpen={() =>onChangeBankReportFilter('')}
                    options={bankReports}
                    value={values['bankReport']}
                    control={control}
                    error={Boolean(errors.bankReport)}
                    helperText={errors.bankReport && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={values['paymentType']}
                    setValue={(name, value) => changePaymentType(value)}
                    rules={formRules['paymentType']}
                    name='paymentType'
                    control={control}
                    error={Boolean(errors.paymentType)}
                    helperText={errors.paymentType && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.transactionForm.paymentType}
                    options={getPaymentTypes()}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='partnerName'
                    rules={formRules['partnerName']}
                    control={data}
                    error={Boolean(errors.partnerName)}
                    helperText={errors.partnerName && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.transactionForm.partnerName}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='description'
                    rules={formRules['description']}
                    control={data}
                    error={Boolean(errors.description)}
                    helperText={errors.description && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.transactionForm.description}
                    disabled={isPageStateViewDetails()}
                />
                
                <div className='header-row-item'>
                    <DateTimeControl
                        data={data}
                        label={strings.forms.finance.transactionForm.paymentDate}
                        name={'paymentDate'}
                        value={values['paymentDate']}
                        setValue={setValue}
                        disabled={isPageStateViewDetails()}
                    />
                </div>

                <TextFieldControl
                    name='income'
                    type={'number'}
                    rules={formRules['income']}
                    control={data}
                    error={Boolean(errors.income)}
                    helperText={errors.income && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.transactionForm.income}
                    disabled={isPageStateViewDetails()}
                />

                <TextFieldControl
                    name='outcome'
                    type={'number'}
                    rules={formRules['outcome']}
                    control={data}
                    error={Boolean(errors.outcome)}
                    helperText={errors.outcome && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.transactionForm.outcome}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={values['currency']}
                    setValue={setValue}
                    rules={formRules['currency']}
                    name='currency'
                    control={control}
                    error={Boolean(errors.currency)}
                    helperText={errors.currency && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.transactionForm.currency}
                    options={currencies}
                    nameKey={'code'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                />

                <AutoCompleteControl
                    name='employee'
                    label={strings.forms.finance.transactionForm.employee}
                    rules={formRules['employee']}
                    nameKey={'fullName'}
                    valueKey={'id'}
                    setValue={(name, value) => changeEmployee(value)}
                    onChange={onChangeEmployeeFilter}
                    onOpen={() =>onChangeEmployeeFilter('')}
                    options={employees}
                    value={values['employee']}
                    error={Boolean(errors.employee)}
                    helperText={errors.employee && strings.forms.common.thisFieldIsRequired}
                    control={control}
                    disabled={isPageStateViewDetails() || !!selectedPartner?.id}
                />

                <AutoCompleteControl
                    name='partner'
                    label={strings.forms.finance.transactionForm.partner}
                    rules={formRules['partner']}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={(name, value) => changePartner(value)}
                    onChange={onChangePartnerFilter}
                    onOpen={() =>onChangePartnerFilter('')}
                    options={partners}
                    value={values['partner']}
                    control={control}
                    error={Boolean(errors.partner)}
                    helperText={errors.partner && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails() || !!selectedEmployee?.id}
                />

                <AutoCompleteControl
                    name='contract'
                    label={strings.forms.finance.transactionForm.contract}
                    rules={formRules['contract']}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={(name,value) => changeContract(value)}
                    onChange={onChangeContractFilter}
                    onOpen={() => onChangeContractFilter('')}
                    options={contracts}
                    value={selectedContract}
                    control={control}
                    error={Boolean(errors.contract)}
                    helperText={errors.contract && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails() || !selectedPartner?.id}
                />

                <AutoCompleteControl
                    name='contractPart'
                    label={strings.forms.finance.transactionForm.contractPart}
                    rules={formRules['contractPart']}
                    nameKey={'description'}
                    valueKey={'id'}
                    setValue={(name, value) => changeContractPart(value)}
                    onChange={onChangeContractPartFilter}
                    onOpen={() => onChangeContractPartFilter('')}
                    options={contractParts}
                    value={selectedContractPart}
                    control={control}
                    error={Boolean(errors.contractPart)}
                    helperText={errors.contractPart && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails() || !selectedContract?.id}
                />

                <AutoCompleteControl
                    name='invoice'
                    label={strings.forms.finance.transactionForm.invoice}
                    rules={formRules['invoice']}
                    nameKey={'invoiceNumber'}
                    valueKey={'id'}
                    setValue={(name,value) => changeInvoice(value)}
                    onChange={onChangeInvoiceFilter}
                    onOpen={() => onChangeInvoiceFilter('')}
                    options={invoices}
                    value={selectedInvoice}
                    control={control}
                    error={Boolean(errors.invoice)}
                    helperText={errors.invoice && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails() || !selectedPartner?.id || !selectedPaymentType}
                />

                <AutoCompleteControl
                    name='transactionCategory'
                    label={strings.forms.finance.transactionForm.transactionCategory}
                    rules={formRules['transactionCategory']}
                    nameKey={'name'}
                    valueKey={'id'}
                    setValue={setValue}
                    onChange={onChangeTransactionCategoryFilter}
                    onOpen={() =>onChangeTransactionCategoryFilter('')}
                    control={control}
                    options={transactionCategories}
                    value={values['transactionCategory']}
                    error={Boolean(errors.transactionCategory)}
                    helperText={errors.transactionCategory && strings.forms.common.thisFieldIsRequired}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    value={values['tax']}
                    setValue={setValue}
                    control={data}
                    name='tax'
                    label={strings.forms.finance.transactionForm.tax}
                    options={taxRates}
                    nameKey={'valueText'}
                    valueKey={'value'}
                    disabled={isPageStateViewDetails()}
                />

                <SelectControl
                    className="transactionType"
                    value={selectedTransactionType}
                    setValue={(name,value) => changeTransactionType(value)}
                    rules={formRules['transactionType']}
                    name='transactionType'
                    control={control}
                    error={Boolean(errors.transactionType)}
                    helperText={errors.transactionType && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.finance.transactionForm.transactionType}
                    options={transactionTypeOptions}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails() || !selectedPaymentType}
                />


                <CheckBoxControl
                    name={'approved'}
                    control={control}
                    label={strings.forms.finance.transactionForm.approved}
                    value={values['approved']}
                    setValue={setValue}
                    disabled={isPageStateViewDetails()}
                />

                {
                    !isPageStateViewDetails() &&
                    <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={ onSubmit }>
                            { strings.forms.common.save }
                        </Button>
                        <Button variant="contained" color="secondary" onClick={ onCancel }>
                            { strings.forms.common.cancel }
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default TransactionForm;