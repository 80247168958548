import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getWorkspacesList(data){
    return await request('/api/workspaces', data);
}

export async function getWorkspaceLogo(id, data = {}) {
  return await request(`/api/workspaces-logo/${id}`, data);
}

export async function addWorkspace(data) {
  return await request('/api/workspaces', transformRequestData(data), HttpMethod.POST);
}

export async function editWorkspace(data) {
    return await request('/api/workspaces/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function changeWorkspaceLogo(id, data) {
    return await request(`/api/workspaces-logo/${id}`, data, HttpMethod.PATCH);
}

export async function deleteWorkspace(id) {
  return await request('/api/workspaces/'+ id, {}, HttpMethod.DELETE);
}


function transformRequestData(data) {

    return {
        ...data,
        pipelineId: data.pipeline ? data.pipeline.id : null,
    }
}
