export const BoardIssuePriorityNames = {
    1: 'lowest',
    2: 'low',
    3: 'medium',
    4: 'high',
    5: 'highest',
}

export const BoardIssuePriorityValues = {
    'LOWEST': 1,
    'LOW': 2,
    'MEDIUM': 3,
    'HIGH': 4,
    'HIGHEST': 5,
}

export function formatPriorityName(priority) {
    const priorityName = BoardIssuePriorityNames[priority].charAt(0).toUpperCase() + BoardIssuePriorityNames[priority].slice(1);

    return priorityName;
}

export const IssuePriorities = [
    {
        name: 'Highest',
        type: BoardIssuePriorityValues.HIGHEST,
    },
    {
        name: 'High',
        type: BoardIssuePriorityValues.HIGH,
    },
    {
        name: 'Medium',
        type: BoardIssuePriorityValues.MEDIUM,
    },
    {
        name: 'Low',
        type: BoardIssuePriorityValues.LOW,
    },
    {
        name: 'Lowest',
        type: BoardIssuePriorityValues.LOWEST,
    },
];
