export const WorkspacesIssueTypesNames = {
    1 : 'task',
    2 : 'story',
    3 : 'bug',
    4 : 'epic',
    5 : 'custom',
}

export const WorkspacesIssueTypesValues = {
    'TASK': 1,
    'STORY': 2,
    'BUG': 3,
    'EPIC': 4,
    'CUSTOM': 5,
}

export function formatIssueName(type) {
    const typeName = WorkspacesIssueTypesNames[type].charAt(0).toUpperCase() + WorkspacesIssueTypesNames[type].slice(1);

    return typeName;
}
