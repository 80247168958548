export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const reorderDoubleList = (sourceList, destinationList, startIndex, endIndex) => {
    const [removed] = sourceList.splice(startIndex, 1);
    destinationList.splice(endIndex, 0 , removed);

    return destinationList;
}

export const sortByColumnOrder = (list) => {
    return list.sort((a, b) => (a.columnOrder > b.columnOrder) ? 1 : -1)
}