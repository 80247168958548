import {areEqualByDayAndMonth, greaterOrEqual, lowerOrEqual} from "../../Util/DateUtil";
import React, {useContext, useState, useEffect} from "react";
import Badge from "./Badge";
import {CalendarContentClass, CalendarContentSource} from "../../Constants/Company/CalendarContent";
import strings from "../../localization";
import {IconButton} from "@mui/material";


export const getBirthdays = (calendarData, day) => {
    let result = [];

    if (!calendarData || !calendarData.birthdays) {
        return [];
    }

    for (let user of calendarData.birthdays) {
        if (areEqualByDayAndMonth(day.date, (new Date(user.birthDate)))) {
            result.push(user);
        }
    }

    return result;
}
export const renderBirthdayBadge = (amount) => {
    return <Badge className={CalendarContentClass.BIRTHDAY}
                  amount={amount}
                  src={CalendarContentSource.BIRTHDAY}/>
}
export const getBirthdayDescription = (birthday) => {
    return birthday.fullName;
}

export const getNonWorkingDays = (calendarData, day) => {

    let result = [];

    if (!calendarData || !calendarData.nonWorkingDays) {
        return [];
    }

    for (let nonWorkingDay of calendarData.nonWorkingDays) {
        if (greaterOrEqual(day.date, (new Date(nonWorkingDay.fromDate))) && lowerOrEqual(day.date, (new Date(nonWorkingDay.toDate)))) {
            result.push(nonWorkingDay);
        }
    }

    return result;
}
export const renderNonWorkingDayBadge = (amount) => {
    return <Badge className={CalendarContentClass.NON_WORKING_DAY}
                  amount={amount}
                  src={CalendarContentSource.NON_WORKING_DAY}/>
}
export const getNonWorkingDayDescription = (nonWorkingDay) => {
    return nonWorkingDay.name;
}

export const getLeaves = (calendarData, day, isWeekend) => {

    let result = [];

    if (!calendarData || !calendarData.leaves || isWeekend) {
        return result;
    }

    for (let leave of calendarData.leaves) {
        if (greaterOrEqual(day.date, (new Date(leave.fromDate))) && lowerOrEqual(day.date, (new Date(leave.toDate)))) {
            result.push(leave);
        }
    }

    return result;
}
export const renderLeaveBadge = (amount) => {
    return <Badge className={CalendarContentClass.LEAVE}
                  amount={amount}
                  src={CalendarContentSource.LEAVE}/>
}
export const getLeaveDesription = (leave) => {
    return leave.employeeUser.fullName;
}

export const getOrganisationUserContract = (calendarData, day) => {
    let result = [];

    if (!calendarData || !calendarData.organisationUserContracts) {
        return [];
    }

    for (let organisationUserContract of calendarData.organisationUserContracts) {
        if (areEqualByDayAndMonth(day.date, (new Date(organisationUserContract.endDate)))) {
            result.push(organisationUserContract)
        }
    }
    return result;
}
export const renderOrganisationUserContractBadge = (amount) => {
    return <Badge className={CalendarContentClass.ORGANISATION_CONTRACT}
                  amount={amount}
                  src={CalendarContentSource.ORGANISATION_CONTRACT}/>
}
export const getOrganisationUserContractDescription = (contract) => {
    return contract.name;
}

export const getOrganisationUserContractParts = (calendarData, day) => {

    let result = [];

    if (!calendarData || !calendarData.organisationUserContractParts) {
        return [];
    }

    for (let organisationUserContractPart of calendarData.organisationUserContractParts) {
        if (areEqualByDayAndMonth(day.date, (new Date(organisationUserContractPart.paymentDate)))) {
            result.push(organisationUserContractPart)
        }
    }
    return result;
}
export const renderOrganisationUserContractPartBadge = (amount) => {
    return <Badge className={CalendarContentClass.ORGANISATION_CONTRACT_PART}
                  amount={amount}
                  src={CalendarContentSource.ORGANISATION_CONTRACT_PART}/>
}
export const getOrganisationUserContractPartDescription = (contract) => {
    return `${strings.components.calendarEvent.net}: ${contract.net} ${contract.organisationUserContract.currency.code} -  "${contract.organisationUserContract.name}"`;
}
export const getContracts = (calendarData, day) => {

    let result = [];

    if (!calendarData || !calendarData.contracts) {
        return [];
    }

    for (let contract of calendarData.contracts) {
        if (areEqualByDayAndMonth(day.date, (new Date(contract.endDate)))) {
            result.push(contract)
        }
    }
    return result;
}
export const renderContractBadge = (amount) => {
    return <Badge className={CalendarContentClass.CONTRACT}
                  amount={amount}
                  src={CalendarContentSource.CONTRACT}/>
}
export const getContractDescription = (contract) => {
    return contract.description ? `${contract.name} - ${contract.description}` : contract.name
}

export const getContractParts = (calendarData, day) => {

    let result = [];

    if (!calendarData || !calendarData.contractParts) {
        return [];
    }

    for (let contractPart of calendarData.contractParts) {
        if (areEqualByDayAndMonth(day.date, (new Date(contractPart.paymentDate)))) {
            result.push(contractPart)
        }
    }
    return result;
}
export const renderContractPartBadge = (amount) => {
    return <Badge className={CalendarContentClass.CONTRACT_PART}
                  amount={amount}
                  src={CalendarContentSource.CONTRACT_PART}/>
}
export const getContractPartsDescription = (contract) => {
    return `"${contract.description}" - "${contract.contract.name}"`
}
export const getContractPartsDescriptionExtra = (contract) => {
    return `${Number(contract.amount).toFixed(2)} ${contract.contract.currency.code} - ${contract.paid ? strings.components.calendarEvent.paid : strings.components.calendarEvent.notPaid}`
}

export const getProjects = (calendarData, day) => {

    let result = [];

    if (!calendarData || !calendarData.projects) {
        return [];
    }

    for (let project of calendarData.projects) {
        if (areEqualByDayAndMonth(day.date, (new Date(project.startDate))) || areEqualByDayAndMonth(day.date, (new Date(project.endDate)))) {
            for (let project of calendarData.projects) {
                if (areEqualByDayAndMonth(day.date, (new Date(project.startDate))) || areEqualByDayAndMonth(day.date, (new Date(project.endDate)))) {
                    result.push(project);
                }
            }
            return result;
        }
    }
}

export const getFullDayCustomEvent = (calendarData, day) => {

    let result = [];

    if (!calendarData || !calendarData.customEvents) {
        return [];
    }
    for (let customEvent of calendarData.customEvents) {
        if(customEvent.allDay === true) {
            if (areEqualByDayAndMonth(day.date, (new Date(customEvent.startDate))) || areEqualByDayAndMonth(day.date, (new Date(customEvent.endDate)))) {
                result.push(customEvent);
            }
        }
    }
    return result;
}


export const getPartDayCustomEvent = (calendarData) => {
    let partDays = [];


    if(!calendarData){
        return [];
    }

    for (let customEvent of calendarData){
        if(!customEvent.allDay){
            partDays.push(customEvent);
        }
    }
    return partDays;
}

export const getCustomEvents = (calendarData, day) => {
    let result = [];

    if (!calendarData || !calendarData.customEvents) {
        return [];
    }
    for (let customEvent of calendarData.customEvents) {
        if (areEqualByDayAndMonth(day.date, (new Date(customEvent.startDate))) || areEqualByDayAndMonth(day.date, (new Date(customEvent.endDate)))) {
            result.push(customEvent);
        }
    }
    return result;
}

export const getScheduledChecks = (calendarData, day) => {
    let result = [];

    if (!calendarData || !calendarData.scheduledChecks) {
        return [];
    }
    for (let leave of calendarData.scheduledChecks) {
        if (greaterOrEqual(day.date, (new Date(leave.dateFrom))) && lowerOrEqual(day.date, (new Date(leave.dateTo)))) {
            result.push(leave);
        }
    }
    return result;
}

export const getScheduledChecksPending = (calendarData, day) => {
    let result = [];

    if (!calendarData || !calendarData.scheduledChecks) {
        return [];
    }
    for (let leave of calendarData.scheduledChecks) {
        if (greaterOrEqual(day.date, (new Date(leave.dateFrom))) && lowerOrEqual(day.date, (new Date(leave.dateTo))) && leave.status == 1) {
            result.push(leave);
        }
    }
    return result;
}
export const getScheduledChecksInProgress = (calendarData, day) => {
    let result = [];

    if (!calendarData || !calendarData.scheduledChecks) {
        return [];
    }
    for (let leave of calendarData.scheduledChecks) {
        if (greaterOrEqual(day.date, (new Date(leave.dateFrom))) && lowerOrEqual(day.date, (new Date(leave.dateTo))) && leave.status == 2) {
            result.push(leave);
        }
    }
    return result;
}

export const getScheduledChecksDone = (calendarData, day) => {
    let result = [];

    if (!calendarData || !calendarData.scheduledChecks) {
        return [];
    }
    for (let leave of calendarData.scheduledChecks) {
        if (greaterOrEqual(day.date, (new Date(leave.dateFrom))) && lowerOrEqual(day.date, (new Date(leave.dateTo))) && leave.status == 3) {
            result.push(leave);
        }
    }
    return result;
}

export const getScheduledChecksCancelled = (calendarData, day) => {
    let result = [];

    if (!calendarData || !calendarData.scheduledChecks) {
        return [];
    }
    for (let leave of calendarData.scheduledChecks) {
        if (greaterOrEqual(day.date, (new Date(leave.dateFrom))) && lowerOrEqual(day.date, (new Date(leave.dateTo))) && leave.status == 4) {
            result.push(leave);
        }
    }
    return result;
}

export const renderScheduledCheckBadgePending = (amount, scheduledChecksPending) => {

    return <Badge className={CalendarContentClass.SCHEDULED_CHECK_PENDING}
                  src={CalendarContentSource.SCHEDULED_CHECK_PENDING}
                  amount={amount}/>
}

export const renderScheduledCheckInProgress = (amount, scheduledChecksInProgress) => {
    return<Badge
        className={CalendarContentClass.SCHEDULED_CHECK_IN_PROGRESS}
        src={CalendarContentSource.SCHEDULED_CHECK_IN_PROGRESS}
        amount={amount}
    />
}
export const renderScheduledCheckDone = (amount, scheduledChecksDone) => {
    return<Badge
        className={CalendarContentClass.SCHEDULED_CHECK_DONE}
        src={CalendarContentSource.SCHEDULED_CHECK_DONE}
        amount={amount}
    />
}
export const renderScheduledCheckCancelled = (amount, scheduledChecksCancelled) => {
    return<Badge
        className={CalendarContentClass.SCHEDULED_CHECK_CANCELLED}
        src={CalendarContentSource.SCHEDULED_CHECK_CANCELLED}
        amount={amount}
    />
}

export const renderProjectBadge = (amount) => {
    return <Badge className={CalendarContentClass.PROJECT}
                  src={CalendarContentSource.PROJECT}
                  amount={amount}/>
}

export const getProjectDescription = (project) => {
    return `${project.name} ${project.description ? "- " + project.description : ""}`
}

export const renderCustomEventBadge = (amount) => {
    return <Badge className={CalendarContentClass.CUSTOM_EVENT}
                  src={CalendarContentSource.CUSTOM_EVENT}
                  amount={amount}/>
}

export const getCustomEventDescriptionDay = (customEvent) => {
    return `${customEvent.name} ${customEvent.description ? "-" + customEvent.description : ""}`
}

export const getCustomEventDescription = (customEvent, setCustomEvent, customEventForDelete,setCustomEventForDelete) => {
    return (
        <div className='description-container'>
            <div className='mw-350'>{customEvent.name} - {customEvent.description}</div>
            <IconButton className='add-button' onClick={() => setCustomEvent(customEvent)}>
                <img src='/images/table-page/edit.svg'/>
                {strings.components.tablePage.addButton.edit}
            </IconButton>
            <IconButton className='add-button' onClick={() => setCustomEventForDelete(customEvent)}>
                <img src='/images/table-page/delete.svg'/>
                {strings.components.tablePage.addButton.delete}
            </IconButton>
        </div>
    )
}

export const getScheduledCheckDescription = (scheduledCheck) => {
    return `${scheduledCheck.name}`
}
const CalendarContent = ({calendarData, day, isWeekend, currentMonth}) => {

    const birthdays = getBirthdays(calendarData, day);
    const nonWorkingDays = getNonWorkingDays(calendarData, day)
    const leaves = getLeaves(calendarData, day, isWeekend);
    const organisationUserContracts = getOrganisationUserContract(calendarData, day);
    const contracts = getContracts(calendarData, day);
    const contractParts = getContractParts(calendarData, day);
    const organisationUserContractParts = getOrganisationUserContractParts(calendarData, day);
    const projects = getProjects(calendarData, day);
    const customEvents = getCustomEvents(calendarData, day);
    const scheduledChecksPending = getScheduledChecksPending(calendarData, day);
    const scheduledChecksInProgress = getScheduledChecksInProgress(calendarData, day);
    const scheduledChecksDone = getScheduledChecksDone(calendarData, day);
    const scheduledChecksCancelled = getScheduledChecksCancelled(calendarData, day);
    return <>
        {
            currentMonth === day.month &&
            <div className='badges'>
                {
                    birthdays && birthdays.length > 0 && renderBirthdayBadge(birthdays.length)
                }
                {
                    nonWorkingDays && nonWorkingDays.length > 0 && renderNonWorkingDayBadge(nonWorkingDays.length)
                }
                {
                    leaves && leaves.length > 0 && renderLeaveBadge(leaves.length)
                }
                {
                    organisationUserContracts && organisationUserContracts.length > 0 && renderOrganisationUserContractBadge(organisationUserContracts.length)
                }
                {
                    organisationUserContractParts && organisationUserContractParts.length > 0 && renderOrganisationUserContractPartBadge(organisationUserContractParts.length)
                }
                {
                    contracts && contracts.length > 0 && renderContractBadge(contracts.length)
                }
                {
                    contractParts && contractParts.length > 0 && renderContractPartBadge(contractParts.length)
                }
                {
                    projects && projects.length > 0 && renderProjectBadge(projects.length)
                }
                {
                    customEvents && customEvents.length > 0 && renderCustomEventBadge(customEvents.length)
                }
                {
                    scheduledChecksPending && scheduledChecksPending.length > 0 && renderScheduledCheckBadgePending(scheduledChecksPending.length)
                }
                {
                    scheduledChecksInProgress && scheduledChecksInProgress.length > 0 && renderScheduledCheckInProgress(scheduledChecksInProgress.length)
                }
                {
                    scheduledChecksDone && scheduledChecksDone.length > 0 && renderScheduledCheckDone(scheduledChecksDone.length)
                }
                {
                    scheduledChecksCancelled && scheduledChecksCancelled.length > 0 && renderScheduledCheckCancelled(scheduledChecksCancelled.length)
                }
            </div>
        }
    </>
}


export default CalendarContent;