import { IconButton } from '@mui/material';
import React, { Fragment} from 'react';
import { useSelector } from 'react-redux';
import AppPermissions from '../../../Constants/Permissions/Permissions';
import { hasPermission} from "../../../Util/PermissionUtil";

const CompanyDocumentCategoryTemplateItem = ({data, onEdit, onDelete, onSelected, permissionGroup, createDocument, onCreate}) => {

    const auth = useSelector((state) => state.auth)

    const renderTemplateData = () => {
        let result = [];
        for(let object of data){

            result.push(
                <div className='template-item-component'>
                    <div className='template-label-line-left'/>
                    { hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].EDIT, auth.permissions) &&
                        <div className='template-info'  onClick={() => { if(!createDocument) {onSelected(object)}}}>
                        <div className='template-name'>{object.name}</div>
                        <div className='create-template-update-info'>
                            <div className='text-template'>{object.description}</div>
                        </div>
                    </div>
                    }
                    {!createDocument &&
                    <div className='template-button-container'>
                        { hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].EDIT, auth.permissions) &&
                            <IconButton className='button' onClick={() => onEdit(object)}>
                                <img src="/images/edit-blue.svg" />
                            </IconButton>
                        }
                        { hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].DELETE, auth.permissions) &&
                            <IconButton className='button' onClick={() => onDelete(object.id)}>
                                <img src="/images/delete-blue.svg" />
                            </IconButton>
                        }
                    </div> }
                    { createDocument &&
                        <div className='template-button-container-create-document'>
                            { hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].EDIT, auth.permissions) &&
                                <IconButton className='create-button' onClick={() => onCreate(object)}>
                                    <span className="button-text">Create</span>
                                </IconButton>
                            }
                        </div>
                    }
                </div>
            )
        }
        return result;
    }

    return (
        <Fragment>
            { data && renderTemplateData() }
        </Fragment>
    );
};

export default CompanyDocumentCategoryTemplateItem;
