import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import InventoryCategoryForm from "../../../Components/Forms/Pages/AssetManagement/InventoryCategory/InventoryCategoryForm";
import DrawerContext from "../../../Context/DrawerContext";
import {
  editInventoryCategory,
  getInventoryCategories,
} from '../../../Services/AssetManagement/InventoryCategoryService';
import TablePageContext from "../../../Context/TablePageContext";
import LinearProgress from '@mui/material/LinearProgress';
import { TableDataDefaults } from "../../../Context/TablePageContext";
import SnackbarContext from "../../../Context/SnackbarContext";
import { filterDropdownItem } from "../../../Util/DropdownUtil";


const formRules = {
    'name': {required: true}
}

const EditInventoryCategory = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [inventoryCategories, setInventoryCategories] = useState(0);

    useEffect(() => {
        setDrawerTitle(strings.pages.assetManagement.inventoryCategory.editInventoryCategory.pageTitle);
    });

    useEffect(() => {
        fetchInventoryCategories(); 
    },[])

    const fetchInventoryCategories = (term = "") => {
        getInventoryCategories({term}).then((response) => {
          if (!response || !response.ok) {
            return;
          }
          
          setInventoryCategories(
            filterDropdownItem(response.data.result, props.data[0]?.id)
          );
        });
    }

    const onSubmit = (data) => {

        setShowLoader(true);

        editInventoryCategory(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <InventoryCategoryForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                inventoryCategories = {inventoryCategories}
                errors={errors} data={data} form={form}
                onParentChangeFilter={fetchInventoryCategories}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditInventoryCategory;