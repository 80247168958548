import React, {useEffect, useState, useContext} from 'react';
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import Modules from "../../../Constants/Base/Modules";
import {useDispatch, useSelector} from "react-redux";
import {Grid, Paper, IconButton, Stepper, Step, StepLabel, StepConnector, InputBase, Modal} from "@mui/material";
import {BackButton} from "../../../Util/ImageUtil";
import TemplateQuillEditor from "../../../Components/Controls/Inputs/TemplateQuillEditor";
import TreeViewAddEditForm from "../../../Components/Forms/Pages/Document/Spaces/TreeViewAddEditForm";
import { useForm } from "react-hook-form";
import {useLocation, useNavigate} from 'react-router-dom';
import {getCheckListTemplateSteps, addCheckListTemplateStep} from "../../../Services/CheckList/CheckListTemplateStepService";
import {getCheckListTemplateQuestions, addCheckListTemplateQuestion, editCheckListTemplateQuestion} from "../../../Services/CheckList/CheckListTemplateQuestionService";
import CheckListTemplateQuestion from "./CheckListTemplateQuestion";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import {deleteCheckListTemplate, editCheckListTemplate, getCheckListTemplate, getCheckListTemplates} from "../../../Services/CheckList/CheckListTemplateService";
import { deleteCheckListTemplateQuestion} from "../../../Services/CheckList/CheckListTemplateQuestionService";
import AddCheckListTemplateQuestionModal from "../CheckListQuestion/AddCheckListTemplateQuestionModal";
import YesNoDialog, {YesNoDialogResult} from "../../../Components/Dialogs/YesNoDialog";
import SnackbarContext from "../../../Context/SnackbarContext";
import EditCheckListTemplate from "./EditCheckListTemplate";
import {hasPermission} from "../../../Util/PermissionUtil";
import { CompanyDocumentQuestionType } from "../../../Constants/CompanyDocumentManagement/CompanyDocumentItem/CompanyDocumentQuestionType";

const formRules = {
    'question': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
    'type': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
};

const CreateCheckListTemplate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { innerHeight: height } = window;
    const [steps, setSteps] = useState([])
    const [activeStepIndex, setActiveStepIndex] = useState(1)
    const [completedSteps, setCompletedSteps] = useState([])
    const [nextDisabled, setNextDisabled] = useState(false)
    const [backDisabled, setBackDisabled] = useState(true)
    const [newDisabled, setNewDisabled] = useState(false)
    const [stepQuestions, setStepQuestion] = useState([])
    const [filteredQuestions, setFilteredQuestions] = useState([])
    const [filterQuestion, setFilterQuestion] = useState(null)
    const permissionGroupTemplate = AppPermissions.COMPANY_DOCUMENT_TEMPLATE.GROUP;
    const permissionGroupItem = AppPermissions.COMPANY_DOCUMENT_TEMPLATE_ITEM.GROUP;
    const [content, setContent] = useState('')
    const [currentContent, setCurrentContent] = useState();
    const [handleShowAddModal, setHandleShowAddModal] = useState(null);
    const [handleShowEditModal, setHandleShowEditModal] = useState(null);
    const [editQuestion, setEditQuestion] = useState(null)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const {showMessage} = useContext(SnackbarContext);
    const [selectedEditTemplate, setSelectedEditTemplate] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentTemplate, setCurrentTemplate] = useState(location?.state)
    const auth = useSelector((state) => state.auth)

    const [handleShowAddQuestionOptionsModal, setHandleShowAddQuestionOptionsModal] = useState(true);

    const fetchCurrentDocumentTemplateContent = () => {
        getCheckListTemplate(location.state?.id).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setContent(response.data.entity?.activeVersion.content);
            setCurrentContent(response.data.entity?.activeVersion);
        })
    }

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.companyDocument.companyDocumentTemplate.newTemplate,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.DOCUMENTS},
                {label: strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateList.pageTitle},
            ],
        }));
    }, [])

    useEffect(() => {
        filterQuestion ? searchQuestions(filterQuestion) : setFilteredQuestions(stepQuestions)
    }, [filterQuestion, stepQuestions])

    const fetchSteps = () => {
        const data = {'checkListTemplateId': location.state?.id}
        getCheckListTemplateSteps(data).then(response => {
            setSteps(response.data.result)
            if(response.data.result.length > 0){
                fetchStepQuestions(response.data.result[activeStepIndex - 1])
            }
        })
    }

    const fetchStepQuestions = (step) => {
        const data = {'step': step.id}
        getCheckListTemplateQuestions(data).then(response => {
            setStepQuestion(response.data.result)
        })
    }

    useEffect(() => {
        fetchSteps();
        fetchCurrentDocumentTemplateContent();
    }, [])

    const addNewStep = () => {
        if(steps.length === 9){
            setNewDisabled(true)
        }
        const data = {'checkListTemplateId': location.state?.id, name: 'Step ' + (steps.length + 1).toString()}
        addCheckListTemplateStep(data).then(response => {
            fetchSteps()
        })
        setNextDisabled(false)
    }

    const nextStep = () => {
        if(steps.length < activeStepIndex){ return }
        setBackDisabled(false);
        setCompletedSteps([...completedSteps, activeStepIndex])
        setActiveStepIndex(active => active+1)
        fetchStepQuestions(steps[activeStepIndex])
        if(steps.length === activeStepIndex+1){
            setNextDisabled(true)
        }
    }

    const backStep = () => {
        if(activeStepIndex <= 1){return}
        if((activeStepIndex - 1) === 1){
            setBackDisabled(true);
            setNextDisabled(false);
        }
        setCompletedSteps(completedSteps.splice(-1))
        setActiveStepIndex(active => active-1)
        fetchStepQuestions(steps[activeStepIndex-2])
        if(steps.length > 1){
            setNextDisabled(false)
        }
    }

    const handleCloseAddModal = () => {
        setHandleShowAddModal(null);
    }

    const handleCloseEditModal = () => {
        setHandleShowEditModal(null);
        setEditQuestion(null);
    }

    const handleCloseAddQuestionOptionsModal = () => {
        setHandleShowAddQuestionOptionsModal(null);
    }

    const submitAddHandler = (data) => {
        const requestData = {...data, 'checkListTemplateStepId': steps[activeStepIndex-1]?.id}
        addCheckListTemplateQuestion(requestData).then(response => {
            const question = response.data.entity
            fetchStepQuestions(steps[activeStepIndex-1])
        })
        handleCloseAddModal();
    }

    const submitEditHandler = (data) => {
        editCheckListTemplateQuestion(data).then(response => {
            fetchStepQuestions(steps[activeStepIndex-1]);
        })
        handleCloseEditModal();
        setEditQuestion(null);
    }

    const editQuestionHandler = (data) => {
        setEditQuestion(data);
        setHandleShowEditModal(true)
    }

    const deleteQuestionHandler = (questionId) => {
        setShowDeleteDialog(true);
        setSelectedQuestion(questionId);
    }


    const searchQuestions = (questionParam) => {
        const filteredQuestions = stepQuestions.filter(
            (question) => question.question?.toLowerCase().indexOf(questionParam.toLowerCase()) !== -1);

        setFilteredQuestions(filteredQuestions);
    }

    const handleDeleteDialogResult = (result, questionId) => {
        if (result === YesNoDialogResult.NO || result === YesNoDialogResult.CANCEL) {
            setShowDeleteDialog(false);
            return;
        }
        if(questionId) {
            deleteCheckListTemplateQuestion(questionId).then(response => {
                if (!response || !response.ok) {
                    return;
                }
                fetchStepQuestions(steps[activeStepIndex-1])
            })

            showMessage(strings.components.tablePage.itemDeleted, 'success')
            setShowDeleteDialog(false);
            setSelectedQuestion(null);
        }
    }

    const onEditTemplate = () => {
        setSelectedEditTemplate(currentTemplate);
        setShowEditModal(true);
    }

    const submitEditTemplateHandler = (data) => {
        setSelectedEditTemplate(null);
        editCheckListTemplate(data).then(response => {
            setCurrentTemplate(response.data.entity)
        })
    }

    const onStepClick = (index) => {
        setActiveStepIndex(index);
        fetchStepQuestions(steps[index-1])
        if(steps.length === activeStepIndex+1){
            setNextDisabled(true);
            setBackDisabled(false);
            return;
        }
        if(index > 1 && index < steps.length){
            setNextDisabled(false);
            setBackDisabled(false);
            return;
        }
        if(index === 1){
            setNextDisabled(false);
            setBackDisabled(true);
            return;
        }
    }

    return (
        <div>
            <Grid container={true} spacing={2} className="check-list-create-template">
                <Grid item xs={6}>
                    <div id="create-template-check-list" className="ml-4">
                        <Paper sx={{height: height - height/4, borderRadius: 3}}>
                            <div className="create-template-header">
                                <div className="back-button" onClick={() => navigate(-1)}><div className="back-button-icon"><BackButton></BackButton></div><div className="back-text">
                                    {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateStep.back}
                                </div>
                                </div>
                                <div className="template-name">{currentTemplate?.name}</div>
                                { hasPermission(auth.user, permissionGroupTemplate, AppPermissions[permissionGroupTemplate].EDIT, auth.permissions) &&

                                    <div className="edit-template" onClick={onEditTemplate}><IconButton><img src="/images/edit-blue.svg" /></IconButton></div>
                                }
                            </div>
                            <div className="template-stepper">
                                <Stepper activeStep={1} alternativeLabel={true}>
                                    {steps.map((step, index) => {
                                        index = index + 1;
                                        return <Step key={step.name} onClick={() => onStepClick(index)} active={(index === activeStepIndex)} completed={(index < activeStepIndex)}>
                                            <StepLabel>{step.name}</StepLabel>
                                        </Step>
                                    })}
                                </Stepper>
                            </div>
                            <div className="question-search-add">
                                { hasPermission(auth.user, permissionGroupItem, AppPermissions[permissionGroupItem].EDIT, auth.permissions) &&
                                    <div className='searchfield'>
                                        <Paper>
                                            <IconButton>
                                                <img src = '/images/search.svg'/>
                                            </IconButton>
                                            <InputBase
                                                onChange={(e) => setFilterQuestion(e.target.value)}
                                                placeholder = {
                                                    strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateQuestion.placeholders.search
                                                }
                                            />
                                        </Paper>
                                    </div>
                                }
                                <div>
                                    { hasPermission(auth.user, permissionGroupItem, AppPermissions[permissionGroupItem].EDIT, auth.permissions) &&
                                        <IconButton className='new-question-button' onClick={(e) => { setHandleShowAddModal(e); }}>
                                            <img src='/images/new-step-icon.svg'/>
                                            <div className='new-text'>
                                                {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateQuestion.newQuestion}
                                            </div>
                                        </IconButton>
                                    }
                                </div>
                            </div>
                            { hasPermission(auth.user, permissionGroupItem, AppPermissions[permissionGroupItem].EDIT, auth.permissions) &&

                                <div className="question-container">
                                    {filteredQuestions.length > 0 ? <CheckListTemplateQuestion data={filteredQuestions} permissionGroup={permissionGroupItem} onEdit={editQuestionHandler} onDelete={deleteQuestionHandler}></CheckListTemplateQuestion>
                                        : <div className={"no-question-label"}><span className={"span"}>{strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateQuestion.noQuestions}</span></div>
                                    }
                                </div>
                            }
                            <div className="template-create-footer">
                                <div className="back-step-button">
                                    <IconButton className='back-button' onClick={(e) => { backStep(e) }} disabled={backDisabled}>
                                        <img src='/images/back-step.svg'/>
                                        <div className='back-text'>
                                            {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateStep.back}
                                        </div>
                                    </IconButton>
                                </div>
                                <div className="new-step-button">
                                    <IconButton className='new-button' onClick={(e) => { addNewStep(e) }} disabled={newDisabled}>
                                        <img src='/images/new-step-icon.svg'/>
                                        <div className='new-text'>
                                            {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateStep.newStep}
                                        </div>
                                    </IconButton>
                                </div>
                                <div className="next-step-button">
                                    <IconButton className='next-button' onClick={(e) => { nextStep(e) }} disabled={nextDisabled}>
                                        <div className='next-text'>
                                            {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateStep.next}
                                        </div>
                                        <img src='/images/next-step.svg'/>
                                    </IconButton>
                                </div>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <AddCheckListTemplateQuestionModal
                showModal={handleShowAddModal}
                closeModal={handleCloseAddModal}
                submit={submitAddHandler}
                submitEdit={submitEditHandler}
                question={null}
                stepId={steps[activeStepIndex-1]?.id}>
            </AddCheckListTemplateQuestionModal>
            {editQuestion && <AddCheckListTemplateQuestionModal showModal={handleShowEditModal} closeModal={handleCloseEditModal} submit={submitEditHandler} question={editQuestion}></AddCheckListTemplateQuestionModal>}
            <YesNoDialog show={showDeleteDialog}
                         payload={selectedQuestion}
                         handleResult={handleDeleteDialogResult}
                         title={strings.components.tablePage.confirmDelete}
                         text={strings.components.tablePage.confirmDeleteMessage}/>
            {selectedEditTemplate && <EditCheckListTemplate onSubmit={submitEditTemplateHandler} showModal={showEditModal} closeModal={() => setShowEditModal(false)} template={selectedEditTemplate}></EditCheckListTemplate>}
        </div>
    );
}


export default CreateCheckListTemplate;