import {useEffect, useState, useCallback, useContext, useRef } from 'react';
import strings from "../../../localization";
import {changePageSizeState} from "../../../Slices/PageSlice";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import PageState from "../../../Constants/Base/PageState";
import {useDispatch, useSelector} from "react-redux";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import DrawerContext from "../../../Context/DrawerContext";
import YesNoDialog, {YesNoDialogResult} from "../../../Components/Dialogs/YesNoDialog";
import {useForm} from "react-hook-form";
import {FormProvider} from "react-hook-form";
import TextSearchControl from "../../../Components/Controls/Inputs/TextSearchControl";
import {IconButton, Drawer } from "@mui/material";
import { debounce } from 'lodash';
import DrawerWrapper from "../../../Components/Layout/DrawerWrapper";
import AppPermissions from '../../../Constants/Permissions/Permissions';
import { hasPermission } from '../../../Util/PermissionUtil';
import AddBoardIssue from './AddBoardIssue';
import BoardIssueView from './BoardIssueView';
import EditBoardIssue from './EditBoardIssue';
import { useLocation, useNavigate, useSearchParams, useParams } from 'react-router-dom';
import LoaderContext from "../../../Context/LoaderContext";
import SnackbarContext from '../../../Context/SnackbarContext';
import {filterState} from '../../../Context/TablePageContext';
import { setUser } from "../../../Slices/AuthSlice";
import {changeUserPreference} from '../../../Services/User/UserService';
import { getIssueTypeList} from "../../../Services/Workspaces/IssueTypeService";
import { getUsersWithWorkspaceAccess } from "../../../Services/Workspaces/WorkspaceAccessService";
import {reorder,reorderDoubleList,sortByColumnOrder} from "../../../Util/DnDUtil";
import { UserAvatarGroupContainer, UserAvatar } from '../../../Util/AvatarUtil';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {
	getBoardIssues,
	updateIssueStage,
	deleteBoardIssue,
	archiveIssue
} from "../../../Services/Workspaces/BoardIssueService";
import { getBoard } from "../../../Services/Workspaces/BoardsService";
import { renderTypeIcon, renderPriorityIcon } from '../../../Components/DataGrid/ValueCellRender';
import AutoCompleteControl from '../../../Components/Controls/Inputs/AutoCompleteControl';
import IsArchivedType, {getIsArchivedTypes} from "../../../Constants/Workspaces/IsArchived";


const BoardView = () => {
	const { state, pathname } = useLocation();
	const {id, boardId} = useParams();
	const [board, setBoard] = useState(state?.board);
	const [searchParams, setSearchParams] = useSearchParams();
	const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
	const changeLocation = useRef(false);
	const {setLoading} = useContext(LoaderContext);
	const { showMessage } = useContext(SnackbarContext);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);
	const [drawerTitle, setDrawerTitle] = useState('');
	const value = {drawerTitle, setDrawerTitle}
	const [showDelete, setShowDelete] = useState(false);
	const [openSelectedIssue, setOpenSelectedIssue] = useState(null);
	const [selectedIssue, setSelectedIssue] = useState(null);
	const [pageState, setPageState] = useState(0);
	const [issueTypes, setIssueTypes] = useState([]);
	const [isArchived, setIsArchived] = useState([]);
	const [assignees, setAssignees] = useState([]);
	const [pipelineStages, setPipelineStages] = useState([]);
	const [pipelineStage, setPipelineStage] = useState(null);
	const [boardIssues, setBoardIssues] = useState([]);
	const [areFiltersOpen, setAreFiltersOpen] = useState(auth.user?.tablePageFiltersState);
	const archivedTypes = getIsArchivedTypes();
	const form = useForm({
		defaultValues: {
			term: '',
			issueType: null,
			assignee: state?.myIssues ? auth.user : null,
			isArchived: archivedTypes[1],
		}
	});
	const {data, watch, setValue, getValues, control} = form;
	const [filter, setFilter] = useState({isArchived: archivedTypes[1].id});
	let watchValue = ['term', 'issueType','assignee', 'isArchived'];
	watch(watchValue);
	const permissionGroupWorkspaces = AppPermissions.WORKSPACES;
	const changeBoard = useRef(false);
	const firstRender = useRef(true);

	useEffect(() => {
		const subscription = watch((data) => {
			if(!updatedFilter){
				return;
			}

			if(changeLocation.current){
				return;
			}

			if(changeBoard.current){
				return;
			}

			debounceSetFilter({
				...filter,
				term: data.term,
				issueType: data.issueType ? data.issueType.id : undefined,
				assignee: data.assignee ? data.assignee.id : undefined,
				isArchived: data.isArchived ? data.isArchived.id : undefined
			})
		});
	}, [watch, filter, updatedFilter]);

	const debounceSetFilter = useCallback(
		debounce((newValue) =>{
			setFilter(newValue)
		}, 800),
	[]);

	useEffect(() => {
		if(firstRender.current){
			firstRender.current = false;

			return;
		}

		if(changeBoard.current){
			changeBoard.current = false;

			return;
		}

	},[filter]);

	const populateFilters = (params) => {
		let searchParamsFilters = {};
		form.reset();
		for(const [key, value] of params.entries()){
			if(key === 'term'){
				setValue(key, value)
				searchParamsFilters[key] = value;

				continue;
			}

			if(key === 'assignee'){
				const filterValue = assignees.find((option) => option.id === parseInt(value));
				if(filterValue){
						setValue(key, filterValue);
						searchParamsFilters[key] = value;

						continue;
				}

				params.delete('assignee');
				setSearchParams(params);
			}

			if(key === 'issueType'){
				const filterValue = issueTypes.find((option) => option.id === parseInt(value));
				if(filterValue){
						setValue(key, filterValue);
						searchParamsFilters[key] = value;

						continue;
				}

				params.delete('issueType');
				setSearchParams(params);
			}
			if(key === 'isArchived'){
				const filterValue = isArchived.find((option) => option.id === parseInt(value));
				if(filterValue){
					setValue(key, filterValue);
					searchParamsFilters[key] = value;

					continue;
				}

				params.delete('isArchived');
				setSearchParams(params);
			}
		}

		changeLocation.current = false;
		setUpdatedFilter(true);

		return searchParamsFilters;
	}

	useEffect(() => {
		if(!searchParams.toString() || !assignees.length || !issueTypes.length || !isArchived.length){
				return;
		}

		const searchParamsFilters = populateFilters(searchParams);
		setFilter({...searchParamsFilters});
	},[assignees, issueTypes, isArchived]);

	useEffect(() => {
		const onLocationChange = () => {
				changeLocation.current = true;
				const searchParamsFilters = populateFilters(new URLSearchParams(window.location.search));
				setFilter({...searchParamsFilters});
		}

		window.addEventListener('popstate', onLocationChange);

		return () => {
				window.removeEventListener('popstate', onLocationChange);
		}
	},[assignees, issueTypes,isArchived]);

	useEffect(() => {
		dispatch(changePageSizeState(PageSizeState.SHORT));
		dispatch(changeBreadcrumbs({
			title: board?.name,
			hierarchy:[
				{label: strings.navigation.homeTag},
				{label: strings.navigation.workspaces, route: '/workspaces'},
				{label: strings.navigation.boards, route: `/workspaces/${id}/boards`},
				{label: board?.name},
			],
		}));

		if(board && board.id === parseInt(boardId)){
			fetchIssueTypes();
			fetchAssignees();
			fetchIssues();

			return;
		}

		fetchBoard();
	}, [board, boardId]);

	useEffect(() => {
		if(!updatedFilter || !board){
			return;
		}

		fetchIssues();
	},[filter, board, updatedFilter]);

	useEffect(() => {
		if(board && board.id !== parseInt(boardId)){
			changeBoard.current = true;
			setFilter({});
			form.reset();
		}

	},[boardId]);

	const fetchBoard = () => {
		setLoading(true);
		getBoard(boardId).then(response => {
			if(!response || !response.ok){
				return;
			}

			setBoard(response.data.entity);
		});
	}


	const changeFiltersState = () => {
		let state = areFiltersOpen;
		if(areFiltersOpen === filterState.show){
			setAreFiltersOpen(filterState.close);
			state = filterState.close;
		}else{
			setAreFiltersOpen(filterState.show);
			state = filterState.show;
		}

		changeUserPreference({tablePageFiltersState: state}).then(response => {
			if(!response || !response.ok){
				return;
			}
			dispatch(setUser({...auth.user, ...response.data.user}));
		});
	}

	const fetchIssueTypes = () => {
		getIssueTypeList(board.workspace.id).then(response => {
			if (!response || !response.ok) {
				return;
			}

		setIssueTypes(response.data.result);
		});
	}

	const fetchIssues = () => {
		setLoading(true);
		getBoardIssues({
			...filter,
			boardId: boardId,
		}).then(response => {
			setLoading(false);
			if (!response || !response.ok) {
				setPipelineStages([]);
				return;
			}
			setBoardIssues(sortByColumnOrder(response.data.issues.result));
			setPipelineStages(sortByColumnOrder(response.data.stages));
		});
	};

	const fetchAssignees = () => {
		setLoading(true);
		getUsersWithWorkspaceAccess(board.workspace.id).then(response => {
			setLoading(false);
			if (!response || !response.ok) {
				return;
			}
			setAssignees([{id: -1, firstName: 'Unassigned', lastName: '', fullName: 'Unassigned'}, ...response.data]);
		});
	}

	const handleCreateIssue = (stage) => {
		setPipelineStage(stage);
		setPageState(PageState.Add);
	}


	const onDragEnd = ({source, destination}) => {
		if(!destination || !source) return;

		const toStage = pipelineStages.find(stage => stage.id === parseInt(destination.droppableId));
		const fromStage = pipelineStages.find(stage => stage.id === parseInt(source.droppableId));

		let draggableIssues = [];
		for(let issue of boardIssues){
			if(issue.pipelineStage.id === parseInt(source.droppableId)){
				draggableIssues.push(issue);
			}
		}

		if(source.droppableId === destination.droppableId){
			let issues = reorder(draggableIssues, source.index, destination.index);

			let compareIssues = boardIssues.filter(issue => !issues.includes(issue));

			let modifiedIssues = [...issues, ...compareIssues];

			updateIssueStage({
				stageId: source.droppableId,
				issues,
			}).then(response => {
				if (!response || !response.ok){
					return;
				}
			});
			setBoardIssues(modifiedIssues);

			return;
		}
		const sourceItems = [...draggableIssues];
		const destItems = boardIssues.filter(issue => issue.pipelineStage.id === parseInt(destination.droppableId));
		const otherItems = boardIssues.filter(issue => issue.pipelineStage.id !== toStage.id && issue.pipelineStage.id !== fromStage.id);

		let destinationList = reorderDoubleList(sourceItems, destItems, source.index, destination.index);
		destinationList.forEach(item => item.pipelineStage = toStage);

		updateIssueStage({
			stageId: destination.droppableId,
			issues: destinationList,
		}).then(response => {
			if (!response || !response.ok){
				return;
			}
		});
		setBoardIssues([...sourceItems, ...destItems, ...otherItems]);
	}

	const renderPipelineStageContainers = () => {

		return (
			<div className = 'board-view-stages'>
				<DragDropContext onDragEnd = {onDragEnd}>
					{pipelineStages.map((pipelineStage) =>
						<div
							className = 'pipeline-stage-container'
							key = {'board-view-stage-' + pipelineStage.id}
						>
							<div className = 'pipeline-stage-header'>
								<p className = {'pipeline-stage-header-name'}>{pipelineStage?.name}</p>
								<div className = 'create-btn-container'>
									<IconButton onClick = {() => handleCreateIssue(pipelineStage)}>
										<img src = '/images/create-issue.svg' />
										<p>{strings.pages.boards.boardView.createIssues}</p>
									</IconButton>
								</div>
							</div>
							<Droppable key = {`pipeline-stage-${pipelineStage.id}`} droppableId = {'' + pipelineStage.id}>
								{(provided, snapshot) => (
									<div
										className = 'pipeline-stage-content'
										ref={provided.innerRef}
										{...provided.droppableProps}
									>
										{renderPipelineStage(pipelineStage)}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</div>
					)}
				</DragDropContext>
			</div>
		);
	}

	const handleEdit = (issue) => {
		setPageState(PageState.Edit);
		setSelectedIssue(issue);
	}

	const handleDelete = (issue) => {
		setSelectedIssue(issue);
		setShowDelete(true);
	}

	const handleArchiveIssue = (issue) => {
		archiveIssue(issue.id, issue).then(response => {
			fetchIssues();
		});
		setSelectedIssue(null);
	}

	const handleDeleteDialogResult = (result) => {
		if(result === YesNoDialogResult.NO){
			setShowDelete(false);
			setSelectedIssue(null);

			return;
		}

		if(result === YesNoDialogResult.YES){
			setShowDelete(false);

			deleteBoardIssue(selectedIssue.id).then(response => {
				if (!response || !response.ok) {
					showMessage(strings.components.tablePage.errorDeletingItem, 'error')
					setShowDelete(false);
					setSelectedIssue(null);
					onFinish();

					return;
				}

				showMessage(strings.components.tablePage.itemDeleted, 'success')
				setShowDelete(false);
				setSelectedIssue(null);
				onFinish();
			});

			return;
		}
	}

	const openIssue = (e, issue) => {
		e.stopPropagation();
		if(e.target.id === 'issue-edit' || e.target.id === 'issue-add' || e.target.id === 'issue-archive') return;

		if(!issue.assignee){
			issue.assignee = {'id': -1, 'fullName': 'Unassigned'}
		}

		setOpenSelectedIssue(issue);
	}

	const renderPipelineStage = (stage) => {
		let result = [];

		if(boardIssues.length){
			for(let issue of boardIssues) {
				if(issue.pipelineStage.id === stage.id){
					result.push(
						<Draggable
							key={"board-issue-" + issue.id}
							draggableId={'' + issue.id}
							index={result.length}
						>
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
									className = 'issue-card'
									onClick = {(e) => openIssue(e, issue)}
								>
									<div
										className = 'issue-name-assignee'
									>
										<p className='issue-id'>{issue.issueId}</p>
										<p>{issue.name}</p>
										<div className='avatar' >
											{issue?.assignee?.id!==-1 && <UserAvatar user = {issue?.assignee} size = {30} />}
										</div>
									</div>
									<div className = 'issue-card-footer'>
										<div className = 'issue-type-container'>
											{renderTypeIcon(issue.issueType.type)}
											<p>{issue.issueType.name}</p>
											{renderPriorityIcon(issue.priority)}
										</div>
										<div className= 'issue-btn-container'>
											{
												<div onClick = {() => handleArchiveIssue(issue)} id = 'issue-archive' className='btn-cursor'>
													<img src='/images/board-archive.svg' loading = 'lazy'/>
												</div>
											}
											{
												<div onClick = {() => handleEdit(issue)} id = 'issue-edit' className='btn-cursor'>
													<img src='/images/board-edit.svg' loading = 'lazy'/>
												</div>
											}
											{
												<div onClick = {() => handleDelete(issue)} id = 'issue-add' className='btn-cursor'>
													<img src='/images/board-delete.svg' loading = 'lazy'/>
												</div>
											}
										</div>
									</div>
								</div>
							)}
						</Draggable>
					);
				}
			}
		}

		return result;
	}

	const goToWorkspaceAccess = () => {
		navigate(`/workspace-settings/${state?.board?.workspace.id}/workspace-access`);
		navigate('/workspace-settings/' + board?.workspace.id, {state: {access: true}});
		navigate(`/workspace-settings/${board.workspace.id}/workspace-access`);
	}

	const isDrawerOpen = () => {
		return pageState !== PageState.View;
	}

	const onFinish = () => {
		setPageState(PageState.View);
		fetchIssues();
	}

	const onCancel = () => {
		setPageState(PageState.View);
	}

	const handleClearFilters = () => {
		form.reset();
		const newState = {
			board: board
		}
		window.history.replaceState({...window.history.state, usr: newState}, pathname)
	}

	const formatAssignees = (id) => {
		return assignees.filter((assignee) => assignee.id !== id);
	}


	return(
		<DrawerContext.Provider value = {value}>
			<YesNoDialog 
				show={showDelete}
				payload={selectedIssue}
				handleResult={handleDeleteDialogResult}
				title={strings.components.tablePage.confirmDelete}
				text={strings.pages.boards.boardView.deleteDialogMessage}
			/>
			<div className={'boards-page-header'}>
				<div className='header top-header'>
					<div className={'filter-container left-filter'}>
						<div className={'search-container'}>
								<FormProvider {...form}>                          
										<div className='filter-item'>
												<TextSearchControl
													name='term'
													control={data}
													defaultValue=''
													margin="normal"
													placeholder = {'Search'}
												/>
										</div>
								</FormProvider>
						</div>
						<IconButton onClick={() => fetchIssues()}>
							<img src='/images/table-page/reload.svg' />
						</IconButton>
						<IconButton onClick={changeFiltersState}>
							{
								areFiltersOpen === filterState.show && <img src='/images/table-page/filters-toggle-shown.svg' />                
							}
							{
								areFiltersOpen === filterState.close && <img src='/images/table-page/filters-toggle-hidden.svg' />                
							}
						</IconButton>
					</div>
				</div>
				<div className={`header bottom-header mt-6 ${areFiltersOpen === filterState.close ? 'hidden':''}`}>
					<div className={'filter-container left-filter'}>
						<div className={'search-container'}>
							<FormProvider {...form}>                          
								<div className = 'filter-item'>
									<AutoCompleteControl
										name = 'issueType'
										className='mui-shifted-label-input'
										setValue={setValue}
										label = {strings.pages.boards.boardView.label.issueType}
										value = {getValues('issueType')}
										placeholder = {strings.pages.boards.boardView.placeholder.issueType}
										valueKey = {'id'}
										nameKey = {'name'}
										options = {issueTypes}
										control = {control}
									/>
								</div>
								<div className = 'filter-item expand-auto-complete'>
									<AutoCompleteControl
										className=' mui-shifted-label-input'
										setValue={setValue}
										value = {getValues('assignee')}
										label = {strings.pages.boards.boardView.label.assignee}
										placeholder = {strings.pages.boards.boardView.placeholder.assignee}
										options = {assignees}
										control = {control}
										valueKey = {'id'}
										nameKey = {'fullName'}
										name = 'assignee'
									/>
								</div>
								<div className = 'filter-item'>
									<AutoCompleteControl
										name = 'isArchived'
										className='mui-shifted-label-input'
										setValue={setValue}
										label = {strings.pages.boards.boardView.label.isArchived}
										value = {getValues('isArchived')}
										placeholder = {strings.pages.boards.boardView.placeholder.isArchived}
										valueKey = {'value'}
										nameKey = {'name'}
										options = {getIsArchivedTypes()}
										control = {control}
									/>
								</div>
							</FormProvider>
							<IconButton onClick={() => handleClearFilters()} >
								<img src='/images/table-page/clear.svg' />
							</IconButton>
							{assignees &&
								<UserAvatarGroupContainer
									users = {formatAssignees(-1)}
									max = {3}
									onClickMore = {goToWorkspaceAccess}
									hasPermission = {hasPermission(auth.user, permissionGroupWorkspaces.GROUP, permissionGroupWorkspaces.EDIT, auth.permissions)}
								/>
							}
						</div>
					</div>
				</div>
			</div>
			{
				pipelineStages && 
				renderPipelineStageContainers()
			}
			{
				openSelectedIssue &&
				<BoardIssueView 
					openSelectedIssue = {openSelectedIssue} 
					setOpenSelectedIssue = {setOpenSelectedIssue}
					assignees = {formatAssignees(auth.user.id)}
					issueTypes={issueTypes}
					user = {auth.user}
					pipelineStages = {pipelineStages}
					fetchIssues = {fetchIssues}
					board = {board}
					onFinish = {() => fetchBoard()}
					boardIssues = {boardIssues}
					isArchived = {getValues('isArchived')}
				/>
			}

			<Drawer id='drawer' anchor='right' open={isDrawerOpen()} onClose={() => setPageState(PageState.View)}>
				<DrawerWrapper onBack={() => setPageState(PageState.View)} title={drawerTitle} viewDetails={pageState === PageState.ViewDetails}>
					{
						pageState === PageState.Add && 
							<AddBoardIssue 
								onFinish = {onFinish} 
								onCancel = {onCancel} 
								issueTypes = {issueTypes}
								assignees = {formatAssignees(auth.user.id)}
								pipelineStage = {pipelineStage}
								isArchived = {isArchived}
							/>
					}
					{
						pageState === PageState.Edit && 
							<EditBoardIssue 
								issue = {selectedIssue}
								onFinish = {onFinish} 
								onCancel = {onCancel} 
								issueTypes = {issueTypes}
								assignees = {formatAssignees(auth.user.id)}
								pipelineStage = {pipelineStage}
								isArchived = {isArchived}
							/>
					}
				</DrawerWrapper>
			</Drawer>
		</DrawerContext.Provider>
	);
}

export default BoardView;
