import React, {useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {Checkbox, LinearProgress, Paper, Switch} from "@mui/material";
import {getProjectAccess, updateProjectAccess} from "../../../Services/Project/ProjectAccessService";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import strings from "../../../localization";
import {getAllCompanyAccess} from "../../../Services/User/UserCompanyAccessService";
import { getClasses } from "../../../Util/SwitchUtil";
import Modules from "../../../Constants/Base/Modules";
import LoaderContext from "../../../Context/LoaderContext";

const ProjectAccessList = ({project = null}) => {

    const dispatch = useDispatch();

    const [users, setUsers] = useState([])
    const [projectAccesses, setProjectAccesses] = useState([]);
    const {loading, setLoading} = useContext(LoaderContext);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.project.projectAccess.pageTitle,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.PROJECTS},
                {label: strings.pages.project.details},
                {label: strings.pages.project.projectAccess.pageTitle},
            ],        
        }));

        fetchProjectAccesses();
    }, [])

    useEffect(() => {
        fetchUsers();
    }, [projectAccesses])

    const getProjectAccessAllowed = (userId) => {
        let result = false;
        for(let projectAccess of projectAccesses) {
            if(projectAccess.user?.id === userId) {
                result = projectAccess.allowed;
                break;
            }
        }
        return result;
    }

    const fetchUsers = () => {
        setLoading(true);
        getAllCompanyAccess().then(response => {
            if(!response || !response.ok) {
                return;
            }
            let users = response.data.filter(employee => !employee.owner).map(employee => ({...employee.user, allowed: getProjectAccessAllowed(employee.user.id)}));
            setUsers(users)
        }).finally(setLoading(false));
    }

    const fetchProjectAccesses = () => {

        if(!project) {
            return;
        }
        setLoading(true);
        getProjectAccess(project.id).then(response => {
            if(!response || !response.ok) {
                return;
            }
            setProjectAccesses(response.data.result)
        }).finally(() => setLoading(false));
    }

    const accessChange = (user) => {
        let allowed = false;
        for(let u of users) {
            if(u.id == user.id) {
                u.allowed = !u.allowed;
                allowed = u.allowed;
            }
        }

        let data = {
            projectId: project.id,
            userId: user.id,
            allowed
        }

        updateProjectAccess(data).then(response =>{
            if(!response || !response.ok) {
                return;
            }
            let replaced = 0;
            let copyAccesses = [...projectAccesses].map(a => {
                if (a.id === response.data.entity.id) {
                    a = response.data.entity;
                    replaced++;
                }
                return a;
            });
            if (!replaced) {
                copyAccesses.push(response.data.entity);
            }
            setProjectAccesses(copyAccesses);
        });
    }
    
    const renderElements = () => {
        let result = [];
        for(let user of users) {
            result.push(
                <div className='element-container' key={'element-' + result.length }>
                    <label>{ user.fullName }</label>
                    <div className='checkbox-container'>
                          <Switch
                            className={`${getClasses(false, user.allowed)} mb-1`}
                            checked={user.allowed}
                            onChange={() => accessChange(user)}
                        />
                    </div>
                </div>
            )
        }
        return result;
    }
    return (
        <div className='page-form-container'>
            <Paper className='paper rounded-2'>
                <div className='paper-checkbox'>
                    <div className='paper-checkbox-container'>
                        <div className='elements'>
                            { renderElements() }
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    );
}

export default ProjectAccessList;
