import HttpMethod from "../../../Constants/Base/HttpMethod";
import {request} from "../../../Base/HTTP";


export async function getWooCommerceSettings(id) {
    return await request('/api/woo_commerce_settings/' + id);
}

export async function updateWooCommentSettings(data) {
    return await request('/api/woo_commerce_settings/' + data.id, data, HttpMethod.PUT);
}