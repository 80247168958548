import strings from "../localization";

export const monthLabels = [
    strings.months.january,
    strings.months.february,
    strings.months.march,
    strings.months.april,
    strings.months.may,
    strings.months.june,
    strings.months.july,
    strings.months.august,
    strings.months.september,
    strings.months.october,
    strings.months.november,
    strings.months.december,
];

export const monthShortLabels = [
    strings.monthsShort.january,
    strings.monthsShort.february,
    strings.monthsShort.march,
    strings.monthsShort.april,
    strings.monthsShort.may,
    strings.monthsShort.june,
    strings.monthsShort.july,
    strings.monthsShort.august,
    strings.monthsShort.september,
    strings.monthsShort.october,
    strings.monthsShort.november,
    strings.monthsShort.december,
];

export const lineBackgroundColors = [
    'rgba(64, 182, 190, 1)',
    'rgba(134, 40, 142, 1)',
    'rgba(255, 191, 28, 1)',
    'rgba(221, 41, 134, 1)',


];

export const lineBorderColors = [
    'rgba(64, 182, 190, 1)',
    'rgba(134, 40, 142, 1)',
    'rgba(255, 191, 28, 1)',
    'rgba(221, 41, 134, 1)',
];

export const lineBaseOptions = {
    scales: {
        y: {
            beginAtZero: false,
            grid: {
                drawBorder: false,
            },
            ticks: {
                padding: 10,
            },
        },
        x: {
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                padding: 5,
            }
        }
    },
    plugins: {
        legend: {
            display: false,
        }
    }
}

export const barBackgroundColors = [
    'rgba(64, 182, 190, 0.3)',
    'rgba(134, 40, 142, 0.3)',
    'rgba(255, 191, 28, 0.3)',
    'rgba(245, 51, 51, 0.3)',
    'rgba(51, 73, 245, 0.3)',
];

export const barBorderColors = [
    'rgba(64, 182, 190, 1)',
    'rgba(134, 40, 142, 1)',
    'rgba(255, 191, 28, 1)',
    'rgba(245, 51, 51, 1)',
    'rgba(51, 73, 245, 1)',
];

export const barBaseOptions = {
    scales: {
        y: {
            beginAtZero: false,
            grid: {
                drawBorder: false,
            },
            ticks: {
                padding: 10,
            },
        },
        x: {
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                padding: 5,
            }
        }
    },
    plugins: {
        legend: {
            display: false,
        }
    }
}

export const pieBackgroundColors=[
    'rgba(255, 191, 28, 1)',
    'rgba(64, 182, 190, 1)',
    'rgba(134, 40, 142, 1)',
]

export const pieBorderColors=[
    'rgba(255, 191, 28, 1)',
    'rgba(64, 182, 190, 1)',
    'rgba(134, 40, 142, 1)',
]

export const dataToListOfValues = (data) => {
    return data.map(({value}) => (value))
}

