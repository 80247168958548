export function getCountries(siteData) {
    return siteData && siteData.data.countries ? siteData.data.countries : []
}

export function getCities(siteData) {
    return siteData && siteData.data.cities ? siteData.data.cities : []
}

export function getBanks(siteData) {
    return siteData && siteData.data.banks ? siteData.data.banks : []
}

export function getCurrencies(siteData) {
    return siteData && siteData.data.currencies ? siteData.data.currencies : []
}

export function getUnits(siteData) {
    return siteData && siteData.data.units ? siteData.data.units : []
}

export function getUserPositions(siteData) {
    return siteData && siteData.data.userPositions ? siteData.data.userPositions : []
}

export function getPartnerTypes(siteData) {
    return siteData && siteData.data.partnerTypes ? siteData.data.partnerTypes : []
}

export function getTaxRates(siteData) {
    return siteData && siteData.data.taxRates ? siteData.data.taxRates.map(tax => ({...tax, valueText: `${tax.value}%`})) : []
}

export function getTaxRatesSelectOptions(siteData){
   return getTaxRates(siteData).map(tax => ({...tax, id: tax.value, valueText: `${tax.value}%`}))
}