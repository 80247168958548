import React, {useState, useEffect, useContext, useCallback} from "react";
import { addSpaceFolder, deleteSpaceFolder, editSpaceFolder, getSpaceFoldersList } from "../../../Services/DocumentManagement/SpaceFolderService";
import TreeViewList from "../../../Components/Tree/TreeViewList";
import { changePageSizeState } from "../../../Slices/PageSlice";
import { changeBreadcrumbs } from "../../../Slices/BreadcrumbsSlice";
import {useDispatch, useSelector } from "react-redux";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import strings from "../../../localization";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import SpaceCart from "../../../Components/Spaces/SpaceCart";
import {
    deleteSpace,
    downloadSpacePDF,
    editSpace,
    getSpaceByPublicLink,
    getSpaces
} from "../../../Services/DocumentManagement/SpaceService";
import { IconButton, Paper, Modal, Button, Drawer, InputBase } from "@mui/material";
import { hasPermission } from "../../../Util/PermissionUtil";
import { EditorState } from "draft-js";
import DrawerContext from "../../../Context/DrawerContext";
import DrawerWrapper from "../../../Components/Layout/DrawerWrapper";
import PageState from "../../../Constants/Base/PageState";
import AddSpace from "./AddSpace";
import EditSpace from "./EditSpace";
import SnackbarContext from "../../../Context/SnackbarContext";
import QuillEditor from "../../../Components/Controls/Inputs/QuillEditor";
import {useParams} from "react-router-dom";

const SpacesList = (props) =>  {

    const dispatch = useDispatch();

    const [drawerTitle, setDrawerTitle] = useState('');
    const value = {drawerTitle, setDrawerTitle}

    const { showMessage } = useContext(SnackbarContext);

    const [parentFolder, setParentFolder] = useState({});
    const [spaces, setSpaces] = useState([]);
    const [filteredSpaces, setFilteredSpaces] = useState([]);
	const [selectedSpace, setSelectedSpace] = useState(null);

    const [handleShowModal, setHandleShowModal] = useState(null);
	const [editor, setEditor] = useState(EditorState.createEmpty());

    const [pageState, setPageState] = useState(0);

    const [descriptionData, setDescriptionData] = useState('');

    const auth = useSelector((state) => state.auth)
    const permissionGroup = AppPermissions.SPACES.GROUP;

    const [searchParams, setSearchParams] = useState();

    const {publicLink: spacePublicLink} = useParams();

    const folderIcon = () => <img src="/images/folder-icon.svg" className="document-folder-icon" />;

    const handleCloseModal = () => {
		setHandleShowModal(null);
	}

    useEffect(()=>{
        if(spacePublicLink){
            fetchSpace();
        }
    },[])

    useEffect(() => {
        searchParams ? searchSpace(searchParams) : setFilteredSpaces(spaces);
    }, [spaces]);

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.space.pageTitle,
            hierarchy:[
                {label: strings.navigation.home},
                {label: strings.navigation.spaces},
                {label: parentFolder?.name ? parentFolder.name : "ROOT"}
            ],
            submenu: true,
        }));
        fetchSpaces(parentFolder)
    }, [parentFolder])

    useEffect(() => {
		setDescriptionData(handleShowModal?.description);
	}, [handleShowModal?.description]);

    const onExpand = (data) => {    
        if(data) {
            setParentFolder(data);
            fetchSpaces(data);
            return;
        };

        setSpaces([]);
        setParentFolder({});
    };

    const fetchSpace = () =>{
        getSpaceByPublicLink(spacePublicLink).then(response => {
           setHandleShowModal(response.data.entity)
            setParentFolder(response.data.parent)
        })
    }

    const fetchSpaces = (data) => {
        getSpaces({parent: data.id}).then(response => {
            if (!response || !response.ok) {
                setSpaces([]);
                return;
            }
            setSpaces(response.data);
        });
    };

    const onSubmitModal = () => {
        if(handleShowModal?.id) {
            let data = {id: handleShowModal.id ? handleShowModal.id : null, description: descriptionData}

            editSpace(data).then(response => {
                if(!response || !response.ok) {
                    return;
                };
    
                setPageState(PageState.View);
                fetchSpaces(parentFolder);
                handleCloseModal();
            });
            return;
        }
	};

    const onFinish = () => {
		setPageState(PageState.View);
		fetchSpaces(parentFolder);
	}

    const handleCreateSpace = () => {
        setPageState(PageState.Add);
    };

    const onEditSpace = (space) => {
        setPageState(PageState.Edit);
		setSelectedSpace(space);
    };

    const onExportSpace = (space) => {
        downloadSpacePDF(space.id);
    }

    const onDeleteSpace = (id) => {
        deleteSpace(id).then(response => {
            if(!response || !response.ok) {
                showMessage(strings.commonMessages.errorDeleting, 'error');
                return;
            }
            
            showMessage(strings.commonMessages.deleteSuccessful, 'success');
            onFinish();
        });
    };

    const onCancel = () => {
		setPageState(PageState.View);
	}

    const isDrawerOpen = () => {
		return pageState !== PageState.View;
	}

    const searchSpace = (name) => {
        const filteredSpaces = spaces.filter(
            (space) => space.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
        );
        setSearchParams(name);
        setFilteredSpaces(filteredSpaces);
    };
    
    return (
		<DrawerContext.Provider value = {value}>
            <TreeViewList
                onExpand={onExpand}
                folderIcon={folderIcon}
                getFolders={getSpaceFoldersList}
                addFolder={addSpaceFolder}
                addStringModal={strings.components.treeViewList.createNewFolder}
                addStringButton={strings.components.treeViewList.addFolder}
                editFolder={editSpaceFolder}
                deleteFolder={deleteSpaceFolder}
                permissionGroup={AppPermissions.SPACE_FOLDERS.GROUP}
                addItemString={strings.components.treeViewList.createNewFolder}
                editItemString={strings.components.treeViewList.editFolder}>
            </TreeViewList>
            <div id={'spaces'}>
                <div className="content">
                { (parentFolder?.name &&
                    hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].VIEW, auth.permissions)) &&
                <div className="spaces-list">
                    <div className='header'>
                        <div className={'filter-container'}>
                            <div id = 'searchfield-container'>
                                <Paper>
                                    <IconButton>
                                        <img src = '/images/search.svg'/>
                                    </IconButton>
                                    <InputBase
                                        placeholder = {"Search"}
                                        onChange={(e) => searchSpace(e.target.value)}

                                    />
                                </Paper>
                            </div>
                        </div>
                    <div className="w-100 d-flex justify-end">
                        {
                            hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].ADD, auth.permissions) &&
                            <IconButton className='plus-button mui-rounded-button mb-1' onClick={() => handleCreateSpace()}>
                                <img src='/images/table-page/add-cross.svg'/>
                                <div className="font-15">
                                    {strings.pages.space.addDocument.pageTitle}
                                </div>
                            </IconButton>
                        }
                    </div>
                </div>
                    <div className="spaces-container-cart">
                        <div className='space-folder-title'>Selected {parentFolder.name}</div>
                            <SpaceCart
                                permissionGroup={permissionGroup}
                                data={filteredSpaces}
                                onSelected={(e) => setHandleShowModal(e)}
                                onDelete={onDeleteSpace}
                                onEdit={onEditSpace}
                                onExport={onExportSpace}
                            />
                        </div>
                    </div> }
                </div>
            </div>
            <Modal
                open={Boolean(handleShowModal)}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id='add-edit-modal'>
                <Paper>
                    <div className='modal-toolbar-content'>
                        <QuillEditor
                            value={descriptionData}
                            onChange={setDescriptionData}
                            defaultValue={handleShowModal?.description}
                        />
                        <div className='buttons-container'>
                            <Button variant="contained" color="secondary" className="rounded-input button" onClick={handleCloseModal} >
                                { strings.common.cancel }
                            </Button>
                            { hasPermission(auth.user, permissionGroup, AppPermissions[permissionGroup].EDIT, auth.permissions) &&
                                <Button variant="contained" color="primary" className="rounded-input ml-5 centered-flex-row pl-3 py-2 button" onClick={onSubmitModal}>
                                    <img src="/images/disk.svg" className="pr-2"/>
                                    { strings.common.save }
                                </Button>
                            }
                        </div>
                    </div>
                </Paper>
            </Modal>
            <Drawer id='drawer' anchor='right' open={isDrawerOpen()} onClose={() => setPageState(PageState.View)}>
                <DrawerWrapper onBack={() => setPageState(PageState.View)} title={drawerTitle} viewDetails={pageState === PageState.ViewDetails}>
                    {
                        pageState === PageState.Add &&
                        <AddSpace
                            parent = {parentFolder}
                            onFinish = {onFinish}
                            onCancel = {onCancel}
                        />
                    }
                    {
                        pageState === PageState.Edit &&
                        <EditSpace
                            space={selectedSpace}
                            onFinish = {onFinish}
                            onCancel = {onCancel}
                        />
                    }
				</DrawerWrapper>
			</Drawer>
         </DrawerContext.Provider>
    );
}

export default SpacesList;