import LinearProgress from '@mui/material/LinearProgress';
import {Paper} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import JobContractTypeForm from "../../../Components/Forms/Pages/HR/JobContractType/JobContractTypeForm";
import DrawerContext from "../../../Context/DrawerContext"
import strings from "../../../localization";
import { editJobContractType } from "../../../Services/HR/JobContractTypeService";
import TablePageContext from "../../../Context/TablePageContext";
import { useForm } from "react-hook-form";
import SnackbarContext from '../../../Context/SnackbarContext';

const formRules = {
    'name': {required: true}
}

const EditJobContractType = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? props.data[0] : undefined
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.jobContractType.editJobContractType.pageTitle);
    });

    const onSubmit = (data) => {
        setShowLoader(true);
        
        editJobContractType(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok){
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <JobContractTypeForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()} />
        </Paper>
    </div>
}

export default EditJobContractType;