
const NumberFormats = {
    '000.000.000,00': {format: '###.###.###,##', thousand: '.', decimal: ','},
    '000,000,000.00': {format: '###,###,###.##', thousand: ',', decimal: '.'},
}

const getOrDefault = (key) => {
    if (key && NumberFormats[key]) {
        return NumberFormats[key];
    }
    return NumberFormats['000.000.000,00'];
}

export default {
    ...NumberFormats,
    toSelect: Object.keys(NumberFormats).map(key => ({key, value: key})),
    default: NumberFormats['000.000.000,00'],
    getOrDefault
}