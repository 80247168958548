import strings from "../../localization";

export const PipelineType = {
    HR: 1,
    CRM: 2,
    TASK: 3,
    PRODUCTION: 4,
}

export function getPipelineTypes() {
    return [
        {
            id: PipelineType.HR,
            name: getPipelineTypeString(PipelineType.HR)
        },
        {
            id: PipelineType.CRM,
            name: getPipelineTypeString(PipelineType.CRM)
        },
        {
            id: PipelineType.TASK,
            name: getPipelineTypeString(PipelineType.TASK)
        },
        {
            id: PipelineType.PRODUCTION,
            name: getPipelineTypeString(PipelineType.PRODUCTION)
        }
    ];
}

export function getPipelineTypeString(pipelineType) {
    switch(pipelineType) {
        case PipelineType.HR: return strings.constants.company.pipelineType.hr;
        case PipelineType.CRM: return strings.constants.company.pipelineType.crm;
        case PipelineType.TASK: return strings.constants.company.pipelineType.task;
        case PipelineType.PRODUCTION: return strings.constants.company.pipelineType.production;
    }
}

export default PipelineType;
