import strings from "../../../localization";
import {formatColumnDate, formatColumnObject} from "../../../Components/DataGrid/ValueFormatter";
import {changePageSizeState} from "../../../Slices/PageSlice";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import Modules from "../../../Constants/Base/Modules";
import PageState from "../../../Constants/Base/PageState";
import {getEmployees} from "../../../Services/User/UserService";
import React, {useEffect, useState} from "react";
import TablePage from "../../../Components/DataGrid/TablePage";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import TablePageContext, {
    FilterDefaults,
    TableDataDefaults,
    TablePageOptionDefaults
} from "../../../Context/TablePageContext";
import {useDispatch} from "react-redux";
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReferenceType from "../../../Constants/DocumentManagement/ReferenceType";
import {
    renderColumnAvatar,
    renderColumnEmployeeStatus,
    renderColumnNextRaise, renderColumnNumber,
    returnColumnBoolean
} from "../../../Components/DataGrid/ValueCellRender";
import {getUserPositions, getUserPosition} from "../../../Services/Company/UserPositionService";
import {getTeamList, getTeam} from "../../../Services/HR/TeamService";
import { getEmployeeStatuses, EmployeeStatus } from "../../../Constants/HR/EmployeeStatus";
import AppPermissions from "../../../Constants/Permissions/Permissions";
import EditEmployee from "./EditEmpoyee";
import {
    renderColumnTeamLogo,
} from "../../../Components/DataGrid/ValueCellRender";
import {fetchOptions} from '../../../Services/Base/FilterOptionsService';
import Tag from "../../../Components/Forms/Pages/Document/Tag/Tag";

const userValues = (params, key) => {return params.row.user[key]};

const tableDescription = [

    {
        field: 'employeeStatus', headerName: strings.pages.user.userList.status, width: 100,
        renderCell: (params) => renderColumnEmployeeStatus(params),
        align: 'center'
    },
    {
        field: 'userProfileImage', headerName: strings.pages.user.userList.image, width: 80,
        renderCell: (params) => renderColumnAvatar({
            ...params,
            row: params.row.user
        }),
        align: 'center'
    },
    {field: 'firstName', valueGetter: params => userValues(params, 'firstName'), headerName: strings.pages.user.userList.firstName, width: 160},
    {field: 'lastName', valueGetter: params => userValues(params, 'lastName'), headerName: strings.pages.user.userList.lastName, width: 160},
    {field: 'email', valueGetter: params => userValues(params, 'email'), headerName: strings.pages.user.userList.email, width: 200},
    {
        field: 'userPosition', headerName: strings.pages.user.userList.userPosition,
        valueFormatter: params => formatColumnObject(params, 'name'),
        width: 200
    },
    {
        field: 'team', headerName: strings.pages.user.userList.team,
        valueFormatter: (params) => formatColumnObject(params, 'name'),
        width: 100
    },
    {
        field: 'teamLogo',  
        headerName: strings.pages.hr.team.teamList.image, 
        width: 100,
        renderCell: (params) => renderColumnTeamLogo(params.row.team),
        align: 'center'
    },
    {
        field: 'bankAccount',
        headerName: strings.pages.user.userList.bankAccount,
        width: 120,
    },
    {
        field: 'lastRaise',
        headerName: strings.pages.user.userList.lastRaise,
        width: 120,
        valueGetter: (params) => formatColumnDate(params)
    },
    {
        field: 'nextRaise',
        headerName: strings.pages.user.userList.nextRaise,
        width: 120,
        renderCell: (params) => renderColumnNextRaise(params)
    },
    {field: 'phoneNumber', valueGetter: params => userValues(params, 'phoneNumber'), headerName: strings.pages.user.userList.phoneNumber, width: 150},
    {field: 'address', valueGetter: params => userValues(params, 'address'), headerName: strings.pages.user.userList.address, width: 200},
    {
        field: 'birthDate',
        headerName: strings.pages.user.userList.birthDate,
        width: 120,
        valueGetter: (params) => formatColumnDate({
            ...params,
            value: params.row.user.birthDate
        })
    },
    {
        field: 'personalNumber',
        headerName: strings.pages.user.userList.personalNumber,
        width: 200,
        valueGetter: params => userValues(params, 'personalNumber')
    },
    {
        field: 'dateStarted',
        headerName: strings.pages.user.userList.dateStarted,
        width: 200,
        valueGetter: (params) => formatColumnDate(params)
    },
    {
        field: 'lastPaymentNet',
        headerName: strings.pages.user.userList.lastPaymentNet,
        width: 200,
        renderCell: params => renderColumnNumber(params),

    },
    {
        field: 'lastPaymentGross',
        headerName: strings.pages.user.userList.lastPaymentGross,
        width: 200,
        renderCell: params => renderColumnNumber(params)
    },
    {field: 'billableHours', headerName: strings.pages.administration.user.userList.billableHours, renderCell: params => returnColumnBoolean(params), width: 120, align: 'center'},
]


const EmployeeList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(false);
    const [columns, setColumns] = useState([]);
    const [pageState, setPageState] = useState(PageState.View);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [filter, setFilter] = useState({...FilterDefaults, employeeStatus: EmployeeStatus.CURRENT});
    const [tableData, setTableData] = useState(TableDataDefaults);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [tablePageOptions, setTablePageOptions] = useState({
        ...TablePageOptionDefaults,
        showActionAdd: false,
    });
    const referenceType = ReferenceType.USER;
    const [filterOptions, setFilterOptions] = useState({});
    const permissionGroup = AppPermissions.EMPLOYEE.GROUP;

    const value = {
        tablePageOptions, setTablePageOptions,
        selectionModel, setSelectionModel,
        selectedItems, setSelectedItems,
        filter, setFilter, pageState, setPageState, showSnackbar, setShowSnackbar,
        messageSnackbar, setMessageSnackbar, alertType, setAlertType, showDeleteDialog, setShowDeleteDialog,
        navigate, updatedFilter, setUpdatedFilter
    }

    useEffect(()=>  {
        setTableData({
            ...tableData,
            loading: true
        })
    },[])


    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.navigation.employees,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.HR},
                {label: strings.navigation.employees},
            ],        
        }));

        setColumns([...tableDescription]);

        if(!searchParams.toString()){
            setSearchParams({employeeStatus: EmployeeStatus.CURRENT}, {replace: true});
        }
        
        fetchFilters();

        return () => {
            setTableData({})
        }
    }, [])

    useEffect(() => {
        if(!updatedFilter){
            return;
        }

        fetch();
    }, [filter, updatedFilter]);

    const onFinish = () => {
        fetch();
        setPageState(PageState.View);
    }

    const onCancel = () => {
        setPageState(PageState.View);
    }

    const fetchUserPositions = async (term, filterFetch) => {
        return fetchOptions('userPosition', searchParams, getUserPositions, getUserPosition, setFilterOptions, term, filterFetch);
    }

    const fetchTeams = async (term, filterFetch) => {
        return fetchOptions('team', searchParams, getTeamList, getTeam, setFilterOptions, term, filterFetch);
    }

    const fetchFilters = async () => {
        const userPositions = await fetchUserPositions();
        const teams = await fetchTeams();
        const statuses = getEmployeeStatuses();

        setFilterOptions({
            userPosition: userPositions,
            team: teams,
            employeeStatus: statuses,
            fetched: 1,
        });
    }

    const filters = [
        {
            name: 'employeeStatus',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'employeeStatus',
            label: strings.pages.user.userList.status,
            showSelect: true
        },
        {
            name: 'userPosition',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'userPosition',
            label: strings.pages.user.userList.userPosition,
            onChange: (term) => fetchUserPositions(term, true),
        },
        {
            name: 'team',
            nameKey: 'name',
            valueKey: 'id',
            optionsName: 'team',
            label: strings.pages.user.userList.team,
            onChange: (term) => fetchTeams(term, true),
        }
    ]

    const fetch = () => {
        setTableData({
            ...tableData,
            loading: true
        })

        getEmployees({
            ...filter,
        }).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setTableData({
                loading: false,
                data: response.data.result,
                total: response.data.total
            });
        });
    }

    const handleViewDetails = (employee) => {
        navigate(`/employee/${employee.id}/record`);
    }

    return <TablePageContext.Provider value={value}>
        <TablePage onFinish={() => onFinish()} tableDescription={columns}
                   referenceType={referenceType} handleView={handleViewDetails}
                   tableData={tableData}
                   permissionGroup={permissionGroup}
                   filters = {filters}
                   filterOptions = {filterOptions}
                   tagPage={<Tag referenceType={ referenceType } data={ selectedItems } /> }
                   editPage={<EditEmployee data={selectedItems}
                                            onCancel={() => onCancel()} onFinish={() => onFinish()}/>}
                    addButtonText={strings.components.tablePage.addButton.employee}
                  />
    </TablePageContext.Provider>
}

export default EmployeeList;
