import React, {useContext} from "react";
import TablePageContext from "../../../../../Context/TablePageContext";
import PageState from "../../../../../Constants/Base/PageState";
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../../localization";
import AutoCompleteControl from "../../../../Controls/Inputs/AutoCompleteControl";
import SelectControl from "../../../../Controls/Inputs/SelectControl";
import Button from "@mui/material/Button";

const ContractStatusForm = ({
                             form,
                             formRules,
                             errors,
                             onSubmit, onCancel,
                             values,
                             setValue,
                             banks,
                             currencies,
                             control
                         }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='s3-settings-form' className='form' action="#">
                <TextFieldControl
                    name='name'
                    rules={formRules['name']}
                    error={Boolean(errors && errors.name)}
                    helperText={errors.name && errors.name.message}
                    label={strings.forms.finance.contractStatus.name}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() &&
                    <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={ onSubmit }>
                            { strings.forms.common.save }
                        </Button>
                        <Button variant="contained" color="secondary" onClick={ onCancel }>
                            { strings.forms.common.cancel }
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    )
}


export default ContractStatusForm;