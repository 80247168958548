import LinearProgress from '@mui/material/LinearProgress';
import { Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DrawerContext from "../../../Context/DrawerContext"
import strings from "../../../localization";
import { addLeaveType } from '../../../Services/HR/LeaveTypeService';
import LeaveTypeForm from "../../../Components/Forms/Pages/HR/LeaveType/LeaveTypeForm";
import SnackbarContext from '../../../Context/SnackbarContext';

const formRules = {
    'name': { required: true },
    'subtractDays': { required: { value: true, message: strings.forms.common.thisFieldIsRequired }, min: { value: 0, message: strings.forms.hr.leaveType.range }, max: { value: 1, message: strings.forms.hr.leaveType.range } }
}

const AddLeaveType = (props) => {
    const { setDrawerTitle } = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm();
    const { data, handleSubmit, getValues, setValue, formState: { errors } } = form;
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setDrawerTitle(strings.pages.hr.leaveType.addLeaveType.pageTitle);
    });

    const onSubmit = (data) => {
        setShowLoader(true);

        addLeaveType(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress />
            }
            <LeaveTypeForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()} />
        </Paper>
    </div>
}

export default AddLeaveType;