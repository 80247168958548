import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getAllUserPositions(data) {
    return await request('/api/user-positions/all', data );
}

export async function getUserPosition(id) {
    return await request('/api/user-positions/' + id);
}

export async function getUserPositions(data) {
    return await request('/api/user-positions', data);
}

export async function addUserPosition(data) {
    return await request('/api/user-positions', transformRequestData(data), HttpMethod.POST);
}

export async function editUserPosition(data) {
    return await request('/api/user-positions/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteUserPosition(id) {
    return await request('/api/user-positions/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
        parentId: data.parent ? data.parent.id : null,
    }
}