import React from "react";
import Home from "./Pages/Home";
import { Route, Routes, matchPath } from "react-router-dom";
import Login from "./Pages/User/Login";
import InternalServerError from "./Pages/Error/InternalServerError";
import Lock from "./Pages/User/Lock";
import NotFound from "./Pages/Error/NotFound";
import BadRequest from "./Pages/Error/BadRequest";
import Forbidden from "./Pages/Error/Forbidden";
import Unauthorized from "./Pages/Error/Unauthorized";
import ResetPassword from "./Pages/User/ResetPassword";
import ResetPasswordRequest from "./Pages/User/ResetPasswordRequest";
import { isUserLoggedIn } from "./Base/OAuth";
import PartnerList from "./Pages/CRM/Partner/PartnerList";
import Dashboard from "./Pages/Statistic/Dashboard";
import InvoiceDirection from "./Constants/Finance/InvoiceDirection";
import InvoiceList from "./Pages/Finance/Invoice/InvoiceList";
import CreateInvoice from "./Pages/Finance/Invoice/CreateInvoice";
import ProductServiceList from "./Pages/Products/ProductService/ProductServiceList";
import BankReportList from "./Pages/Finance/BankReport/BankReportList";
import TeamList from "./Pages/HR/Team/TeamList";
import LeaveRequestList from "./Pages/HR/LeaveRequest/LeaveRequestList";
import OrganisationUserContractList from "./Pages/HR/OrganisationUserContract/OrganisationUserContractList";
import Registration from "./Pages/User/Registration";
import ActivateUser from "./Pages/User/ActivateUser";
import LeaveList from "./Pages/HR/Leave/LeaveList";
import JobList from "./Pages/HR/Job/JobList";
import ProjectList from "./Pages/Project/Project/ProjectList";
import ProjectCategoryList from "./Pages/Project/ProjectCategory/ProjectCategoryList";
import ContractList from "./Pages/Finance/Contract/ContractList";
import CashFlow from "./Pages/Finance/Cashflow/CashFlow";
import ProjectResourceTypeList from "./Pages/Project/ProjectResourceType/ProjectResourceTypeList";
import CandidateList from "./Pages/HR/Candidate/CandidateList";
import CompanySettings from "./Pages/Company/Settings/CompanySettings";
import DocumentStatusList from "./Pages/Document/DocumentStatus/DocumentStatusList";
import ProjectResourceList from "./Pages/Project/ProjectResource/ProjectResourceList";
import DocumentCategoryList from "./Pages/Document/DocumentCategory/DocumentCategoryList";
import ContractPartList from "./Pages/Finance/ContractPart/ContractPartList";
import ContractPartItemsList from "./Pages/Finance/ContractPartItems/ContractPartItemsList";
import CompanyDocumentTemplateItemList from "./Pages/CompanyDocument/CompanyDocumentTemplateItem/CompanyDocumentTemplateItemList";
import CompanyDocumentTemplateList from "./Pages/CompanyDocument/CompanyDocumentTemplate/CompanyDocumentTemplateList";
import TemplateContent from "./Pages/CompanyDocument/CompanyDocumentTemplate/TemplateContent";
import CompanyDocumentCategoryList from "./Pages/CompanyDocument/CompanyDocumentCategory/CompanyDocumentCategoryList";
import OrganisationUserContractPartList from "./Pages/HR/OrganisationUserContractPart/OragnisationUserContractPartList";
import AdditionalHoursList from "./Pages/HR/AdditionalHours/AdditionalHoursList";
import DocumentList from "./Pages/Document/Document/DocumentList";
import EmployeeList from "./Pages/HR/Employee/EmployeeList";
import RoleList from "./Pages/Administartion/Role/RoleList";
import RolePermissionList from "./Pages/Administartion/Role/RolePermissionList";
import TransactionList from "./Pages/Finance/Transactions/TransactionList";
import CompanyDocumentList from "./Pages/CompanyDocument/CompanyDocument/CompanyDocumentList";
import DocumentContent from "./Pages/CompanyDocument/CompanyDocument/DocumentContent";
import Profile from "./Pages/User/Profile";
import PipelineList from "./Pages/Administartion/Pipeline/PipelineList";
import UserList from "./Pages/Administartion/User/UserList";
import UserRoleList from "./Pages/Administartion/Role/UserRoleList";
import PipelineStageList from "./Pages/Administartion/PipelineStage/PipelineStageList";
import JobCandidates from "./Pages/HR/Job/JobCandidates";
import HRSettings from "./Pages/HR/Settings/HRSettings";
import FinanceSettings from "./Pages/Finance/Settings/FinanceSettings";
import CandidateDetails from "./Pages/HR/Candidate/CandidateDetails";
import BusinessYearStartValue from "./Pages/Administartion/BussinesYearStartValue/BusinessYearStartValueList";
import TeamSettings from "./Pages/HR/Team/TeamSettings";
import OrganisationUnitList from "./Pages/HR/OrganisationUnit/OrganisationUnitList";
import EmployeeDetails from "./Pages/HR/Employee/EmployeeDetails";
import AssetManagementLocationList from "./Pages/Administartion/Location/AssetManagementLocationList";
import VendorList from "./Pages/AssetManagement/Vendor/VendorList";
import AssetTypeList from "./Pages/AssetManagement/AssetType/AssetTypeList";
import AssetCategoryList from "./Pages/AssetManagement/AssetCategory/AssetCategoryList";
import InventoryCategoryList from "./Pages/AssetManagement/InventoryCategory/InventoryCategoryList";
import AssetList from "./Pages/AssetManagement/Asset/AssetList";
import TaskCategoryList from "./Pages/Project/TaskCategory/TaskCategoryList";
import InventoryTypeList from "./Pages/AssetManagement/InventoryType/InventoryTypeList";
import InventoryList from "./Pages/AssetManagement/Inventory/InventoryList";
import ProjectDetails from "./Pages/Project/Project/ProjectDetails";
import StockChangeList from "./Pages/AssetManagement/StockChange/StockChangeList";
import DocumentComment from "./Pages/Document/DocumentComment/DocumentComment";
import FileFolder from "./Pages/Files/FileFolder/FileFolder";
import PartnerContactList from "./Pages/CRM/PartnerContact/PartnerContactList";
import ProjectTimeLogList from "./Pages/Project/ProjectTimeLog/ProjectTimeLogList";
import ManageBankReport from "./Pages/Finance/BankReport/ManageBankReport";
import ProjectSettings from "./Pages/Project/Settings/ProjectSettings";
import AcceptInvite from "./Pages/User/AcceptInvite";
import CRMSettings from "./Pages/CRM/Settings/CRMSettings";
import DealList from "./Pages/CRM/Deal/DealList";
import Calendar from "./Components/Calendar/Calendar";
import DealDetails from "./Pages/CRM/Deal/DealDetails";
import DealPipeline from "./Pages/CRM/Deal/DealPipeline";
import AppPermissions from "./Constants/Permissions/Permissions";
import { AppAccesses } from "./Constants/Permissions/Permissions";
import { canAccess } from "./Util/PermissionUtil";
import TaskDetails from "./Pages/TaskManagement/Task/TaskDetails";
import Default from "./Pages/Default";
import PipelineAccessList from "./Pages/Administartion/Pipeline/PipelineAccessList";
import TaskTemplateDetails from "./Pages/TaskManagement/TaskTemplate/TaskTemplateDetails";
import PresenceList from "./Pages/HR/Presence/PresenceList";
import StartTrial from "./Pages/User/StartTrial";
import VerifyEmail from "./Pages/User/VerifyEmail";
import EmployeeTab from "./Pages/HR/Employee/EmployeeTab";
import { FinanceSettingsTab } from "./Constants/Finance/FinanceSettingsTab";
import { HRSettingsTab } from "./Constants/HR/HRSettingsTab";
import { CRMSettingsTab } from "./Constants/CRM/CRMSettingsTab";
import ProfileTab from "./Constants/User/ProfileTab";
import CompanySettingsTab from "./Constants/Company/CompanySettingsTab";
import WorkspacesSettingsTab from "./Constants/Workspaces/WorkspacesSettingsTab";
import { ProjectSettingsTab } from "./Constants/Project/ProjectSettingsTab";
import ProjectDetailsTab from "./Constants/Project/ProjectDetailsTab";
import CandidateDetailsTab from "./Constants/HR/CandidateDetailsTab";
import DealDetailsTab from "./Constants/CRM/DealDetailsTab";
import WorkspacesList from "./Pages/Workspaces/Workspaces/WorkspacesList";
import WorkspaceSettings from "./Pages/Workspaces/Workspaces/WorkspaceSettings";
import ModulePermission from "./Constants/Permissions/ModulePermission";
import BoardsList from "./Pages/Workspaces/Boards/BoardsList";
import BoardsChangeLogo from "./Pages/Workspaces/Boards/BoardsChangeLogo";
import BoardView from "./Pages/Workspaces/Boards/BoardView";
import EditCustomEvent from "./Pages/Calendar/EditCustomEvent";
import JobApplication from "./Pages/HR/Job/JobApplication";
import SpacesList from "./Pages/Document/Spaces/SpacesList";
import CreateCompanyDocumentTemplate from "./Pages/CompanyDocument/CompanyDocumentTemplate/CreateCompanyDocumentTemplate";
import CreateCompanyDocument from "./Pages/CompanyDocument/CompanyDocument/CreateCompanyDocument";
import CompanyDocumentsForms from "./Pages/CompanyDocument/CompanyDocument/CompanyDocumentsForms";
import CheckListTemplateList from "./Pages/CheckList/CheckListTemplate/CheckListTemplateList";
import CreateCheckListTemplate from "./Pages/CheckList/CheckListTemplate/CreateCheckListTemplate";
import ProductServiceCategoryList from "./Pages/Products/ProductServiceCategory/ProductServiceCategoryList";
import CheckListScheduledCheckList from "./Pages/CheckList/CheckListScheduledCheck/CheckListScheduledCheckList";
import SpaceCart from "./Components/Spaces/SpaceCart";
import AttributeList from "./Pages/Products/Attribute/AttributeList";
import AttributeValueList from "./Pages/Products/AttributeValue/AttributeValueList";
import CompositeProductList from "./Pages/Products/CompositeProduct/CompositeProductList";
import CheckListForms from "./Pages/CheckList/CheckListDoCheck/CheckListForms";
import ProductCatalog from "./Pages/Products/ProductService/ProductCatalog";
import BusinessTripList from "./Pages/HR/BusinessTrip/BusinessTripList";
import BusinessTripInformation from "./Pages/HR/BusinessTrip/BusinessTripInformation";
import ProductSettings from "./Pages/Products/ProductSettings/ProductSettings";
import WooCommerceSettings from "./Pages/Products/ProductSettings/WooCommerceSettings";
import CustomerList from "./Pages/CRM/Customer/CustomerList";

const userPages = {
  Login: {
    path: "/login",
    component: <Login />,
    auth: false,
    exact: true,
  },
  Registration: {
    path: "/registration",
    component: <Registration />,
    auth: false,
    exact: true,
  },
  VerifyEmail: {
    path: "/verify-email",
    component: <VerifyEmail />,
    auth: false,
    exact: true,
  },
  ActivateUser: {
    path: "/user/activate",
    component: <ActivateUser />,
    auth: false,
    exact: true,
  },
  Lock: {
    path: "/lock",
    component: <Lock />,
    auth: false,
    exact: true,
  },
  ResetPassword: {
    path: "/reset-password",
    component: <ResetPassword />,
    auth: false,
    exact: true,
  },
  ResetPasswordRequest: {
    path: "/reset-password-request",
    component: <ResetPasswordRequest />,
    auth: false,
    exact: true,
  },
  Profile: {
    path: "/profile",
    component: <Profile />,
    auth: false,
    exact: true,
  },

  ProfileChangePassword: {
    path: "/profile/change-password",
    component: <Profile activeTab={ProfileTab.CHANGE_PASSWORD} />,
    auth: false,
    exact: true,
  },

  ProfileSignature: {
    path: "/profile/signature",
    component: <Profile activeTab={ProfileTab.SIGNATURE} />,
    auth: false,
    exact: true,
  },

  ProfileProfileImage: {
    path: "/profile/profile-image",
    component: <Profile activeTab={ProfileTab.LOGO} />,
    auth: false,
    exact: true,
  },

  ProfileWorkingHours: {
    path: "/profile/working-hours",
    component: <Profile activeTab={ProfileTab.WORKING_HOURS} />,
    auth: false,
    exact: true,
  },

  ProfilePublicLink: {
    path: "/profile/public-link",
    component: <Profile activeTab={ProfileTab.PUBLIC_LINK} />,
    auth: false,
    exact: true,
  },
  AcceptCompanyInvite: {
    path: "/company-invite/accept",
    component: <AcceptInvite />,
    auth: false,
    exact: true,
  },
  StartTrial: {
    path: "/start-trial",
    component: <StartTrial />,
    auth: false,
    exact: false,
  },
};

const crmPages = {
    PartnerList: {
        path: '/partners',
        component: <PartnerList/>,
        auth: true,
        exact: true,
        permissionGroup: AppPermissions.PARTNER.GROUP,
        permission: AppPermissions.PARTNER.VIEW_LIST
    },
    PartnerContactList: {
        path: '/partner-contacts',
        component: <PartnerContactList/>,
        auth: true,
        exact: true,
        permissionGroup: AppPermissions.PARTNER_CONTACT.GROUP,
        permission: AppPermissions.PARTNER_CONTACT.VIEW_LIST
    },
    Customer: {
        path: '/customer',
        component: <CustomerList/>,
        auth: true,
        exact: true,
        permissionGroup: AppPermissions.CUSTOMER.GROUP,
        permission: AppPermissions.CUSTOMER.VIEW_LIST,
    },
    CRMSettings: {
        path: '/crm-settings/deal-type',
        component: <CRMSettings/>,
        auth: true,
        exact: true,
        permissionGroup: AppPermissions.DEAL_TYPE.GROUP,
        permission: AppPermissions.DEAL_TYPE.VIEW_LIST
    },

  CRMSettingsDealPriority: {
    path: "/crm-settings/deal-priority",
    component: <CRMSettings activeTab={CRMSettingsTab.DEAL_PRIORITY} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.DEAL_PRIORITY.GROUP,
    permission: AppPermissions.DEAL_PRIORITY.VIEW_LIST,
  },

  CRMSettingsDealSource: {
    path: "/crm-settings/deal-source",
    component: <CRMSettings activeTab={CRMSettingsTab.DEAL_SOURCE} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.DEAL_SOURCE.GROUP,
    permission: AppPermissions.DEAL_SOURCE.VIEW_LIST,
  },

  Deal: {
    path: "/deals",
    component: <DealList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.DEAL.GROUP,
    permission: AppPermissions.DEAL.VIEW_LIST,
  },
  DealDetailsRecords: {
    path: "/deal-details/:id/record",
    component: <DealDetails />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.DEAL_RECORDS.GROUP,
    permission: AppPermissions.DEAL_RECORDS.VIEW,
  },

  DealDetailsFiles: {
    path: "/deal-details/:id/files/:referenceType",
    component: <DealDetails activeTab={DealDetailsTab.FILES} />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.DEAL.GROUP,
    permission: AppPermissions.DEAL.DOCUMENTS,
  },

  DealDetailsContacts: {
    path: "/deal-details/:id/contacts/:partnerId",
    component: <DealDetails activeTab={DealDetailsTab.CONTACT} />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.PARTNER_CONTACT.GROUP,
    permission: AppPermissions.PARTNER_CONTACT.VIEW_LIST,
  },

  DealPipelines: {
    path: "/deal-pipelines",
    component: <DealPipeline />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.DEAL.GROUP,
    permission: AppPermissions.DEAL.VIEW_LIST,
  },

  DealPipelinesSlug: {
    path: "/deal-pipelines/:slug",
    component: <DealPipeline />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.DEAL.GROUP,
    permission: AppPermissions.DEAL.VIEW_LIST,
  },
};

const hrPages = {
  TeamList: {
    path: "/teams",
    component: <TeamList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.TEAM.GROUP,
    permission: AppPermissions.TEAM.VIEW_LIST,
  },
  TeamSettings: {
    path: "/team-settings/:id",
    component: <TeamSettings />,
    auth: true,
    exact: false,
  },
  LeaveRequestList: {
    path: "/leave-requests",
    component: <LeaveRequestList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.LEAVE_REQUEST.GROUP,
    permission: AppPermissions.LEAVE_REQUEST.VIEW_LIST,
  },
  OrganisationUserContractList: {
    path: "/organisation-user-contracts",
    component: <OrganisationUserContractList />,
    permissionGroup: AppPermissions.ORGANISATION_USER_CONTRACT.GROUP,
    permission: AppPermissions.ORGANISATION_USER_CONTRACT.VIEW_LIST,
  },
  LeaveList: {
    path: "/leaves",
    component: <LeaveList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.LEAVE.GROUP,
    permission: AppPermissions.LEAVE.VIEW_LIST,
  },
  JobList: {
    path: "/jobs",
    component: <JobList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.JOB.GROUP,
    permission: AppPermissions.JOB.VIEW_LIST,
  },
  JobApplication: {
    path: "/job-application/:publicLink",
    component: <JobApplication />,
    auth: false,
    exact: false,
  },
  OrganisationUserContractPartList: {
    path: "/organisation-user-contract/:id/parts",
    component: <OrganisationUserContractPartList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.ORGANISATION_USER_CONTRACT_PART.GROUP,
    permission: AppPermissions.ORGANISATION_USER_CONTRACT_PART.VIEW_LIST,
  },
  CandidateList: {
    path: "/candidates",
    component: <CandidateList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.CANDIDATE.GROUP,
    permission: AppPermissions.CANDIDATE.VIEW_LIST,
  },
  AdditionalHoursList: {
    path: "/additional-hours",
    component: <AdditionalHoursList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.ADDITIONAL_HOURS.GROUP,
    permission: AppPermissions.ADDITIONAL_HOURS.VIEW_LIST,
  },
  EmployeeList: {
    path: "/employees",
    component: <EmployeeList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.EMPLOYEE.GROUP,
    permission: AppPermissions.EMPLOYEE.VIEW_LIST,
  },
  EmployeeDetails: {
    path: "/employee/:id/record",
    component: <EmployeeDetails activeTab={EmployeeTab.RECORD} />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.EMPLOYEE_RECORDS.GROUP,
    permission: AppPermissions.EMPLOYEE_RECORDS.VIEW,
  },
  EmployeeDetailsFiles: {
    path: "/employee/:id/files/:referenceType",
    component: <EmployeeDetails activeTab={EmployeeTab.FILES} />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.EMPLOYEE.GROUP,
    permission: AppPermissions.EMPLOYEE.DOCUMENTS,
  },
  EmployeeDetailsStatistic: {
    path: "/employee/:id/statistic",
    component: <EmployeeDetails activeTab={EmployeeTab.STATISTIC} />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.EMPLOYEE.GROUP,
    permission: AppPermissions.EMPLOYEE.VIEW,
  },
  JobCandidates: {
    path: "/job-candidates",
    component: <JobCandidates />,
    auth: true,
    exact: true,
  },
  BusinessTrip: {
    path: "/hr-businessTrip",
    component: <BusinessTripList />,
    auth: false,
    exact: true,
  },
  BusinessTripDetails: {
    path: "/business-trip-details/:id",
    component: <BusinessTripInformation />,
    auth: false,
    exact: true,
  },
  HRSettings: {
    path: "/hr-settings",
    component: <HRSettings />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.HR_SETTINGS.GROUP,
    permission: AppPermissions.HR_SETTINGS.VIEW_LIST,
  },
  HRSettingsUserPosition: {
    path: "/hr-settings/user-position",
    component: <HRSettings activeTab={HRSettingsTab.USER_POSITION} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.USER_POSITION.GROUP,
    permission: AppPermissions.USER_POSITION.VIEW_LIST,
  },
  HRSettingsJobStatuses: {
    path: "/hr-settings/job-statuses",
    component: <HRSettings activeTab={HRSettingsTab.JOB_STATUS} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.JOB_STATUS.GROUP,
    permission: AppPermissions.JOB_STATUS.VIEW_LIST,
  },
  HRSettingsJobContractType: {
    path: "/hr-settings/job-contract-types",
    component: <HRSettings activeTab={HRSettingsTab.JOB_CONTRACT_TYPE} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.JOB_CONTRACT_TYPE.GROUP,
    permission: AppPermissions.JOB_CONTRACT_TYPE.VIEW_LIST,
  },
  HRSettingsCandidateSource: {
    path: "/hr-settings/candidate-sources",
    component: <HRSettings activeTab={HRSettingsTab.CANDIDATE_SOURCE} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.CANDIDATE_SOURCE.GROUP,
    permission: AppPermissions.CANDIDATE_SOURCE.VIEW_LIST,
  },
  HRSettingsLeaveType: {
    path: "/hr-settings/leave-types",
    component: <HRSettings activeTab={HRSettingsTab.LEAVE_TYPE} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.LEAVE_TYPE.GROUP,
    permission: AppPermissions.LEAVE_TYPE.VIEW_LIST,
  },
  HRSettingsNonWorkingDay: {
    path: "/hr-settings/non-working-days",
    component: <HRSettings activeTab={HRSettingsTab.NON_WORKING_DAY} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.NON_WORKING_DAYS.GROUP,
    permission: AppPermissions.NON_WORKING_DAYS.VIEW_LIST,
  },
  HRSettingsCandidateStatus: {
    path: "/hr-settings/candidate-statuses",
    component: <HRSettings activeTab={HRSettingsTab.CANDIDATE_STATUS} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.CANDIDATE_STATUS.GROUP,
    permission: AppPermissions.CANDIDATE_STATUS.VIEW_LIST,
  },
  HRSettingsBusinessTrip: {
    path: "/hr-settings/business-trip",
    component: <HRSettings activeTab={HRSettingsTab.BUSINESS_TRIP} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.BUSINESS_TRIP.GROUP,
    permission: AppPermissions.BUSINESS_TRIP.VIEW_LIST,
  },
  CandidateDetailsRecord: {
    path: "/candidate-details/:id/record",
    component: <CandidateDetails />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.CANDIDATE_RECORDS.GROUP,
    permission: AppPermissions.CANDIDATE_RECORDS.VIEW,
  },

  CandidateDetailsFiles: {
    path: "/candidate-details/:id/files/:referenceType",
    component: <CandidateDetails activeTab={CandidateDetailsTab.FILES} />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.CANDIDATE.GROUP,
    permission: AppPermissions.CANDIDATE.DOCUMENTS,
  },

  OrganisationUnitList: {
    path: "/organisation-units",
    component: <OrganisationUnitList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.ORGANISATION_UNIT.GROUP,
    permission: AppPermissions.ORGANISATION_UNIT.VIEW_LIST,
  },
  PresenceList: {
    path: "/presences",
    component: <PresenceList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.PRESENCE.GROUP,
    permission: AppPermissions.PRESENCE.VIEW_LIST,
  },
};
const productPages = {
    ProductServiceList: {
        path: '/product-services',
        component: <ProductServiceList/>,
        auth: true,
        exact: true,
        permissionGroup: AppPermissions.PRODUCTS_AND_SERVICES.GROUP,
        permission: AppPermissions.PRODUCTS_AND_SERVICES.VIEW_LIST
    },
    ProductServiceCategoryList: {
        path: '/product-services-category',
        component: <ProductServiceCategoryList/>,
        auth: true,
        exact: true,
        permissionGroup: AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.GROUP,
        permission: AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.VIEW_LIST
    },
    ProductCatalog: {
        path: '/product-catalog',
        component: <ProductCatalog/>,
        auth: true,
        exact: true,
        permissionGroup: AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.GROUP,
        permission: AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.VIEW_LIST
},
    ProductAttributeList: {
        path: '/attributes',
        component: <AttributeList/>,
        auth: true,
        exact: true,
        permissionGroup: AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.GROUP,
        permission: AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.VIEW_LIST
    },
    ProductAttributeValuesList: {
        path: '/attribute-values/:id/:name',
        component: <AttributeValueList/>,
        auth: true,
        exact: true,
        permissionGroup: AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.GROUP,
        permission: AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.VIEW_LIST
    },
    ProductSettings: {
        path: '/product-settings',
        component: <ProductSettings/>,
        auth: true,
        exact: true,
        permissionGroup: AppPermissions.PRODUCT_SETTINGS.GROUP,
        permission: AppPermissions.PRODUCT_SETTINGS.VIEW_LIST
    },
    WooCommerceSettings: {
        path: '/woo-commerce-settings',
        component: <WooCommerceSettings/>,
        auth: true,
        exact: true,
        permissionGroup: AppPermissions.PRODUCT_SETTINGS.GROUP,
        permission: AppPermissions.PRODUCT_SETTINGS.VIEW_LIST
    },
}

const statisticPages = {
  Dashboard: {
    path: "/dashboard",
    component: <Dashboard />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.DASHBOARD.GROUP,
    permission: AppPermissions.DASHBOARD.VIEW,
  },
};

const financePages = {
  InvoiceListOut: {
    path: "/out/invoices",
    component: <InvoiceList invoiceDirection={InvoiceDirection.OUT} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.INVOICE.GROUP,
    permission: AppPermissions.INVOICE.VIEW_LIST,
  },
  InvoiceListIn: {
    path: "/in/invoices",
    component: <InvoiceList invoiceDirection={InvoiceDirection.IN} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.INVOICE.GROUP,
    permission: AppPermissions.INVOICE.VIEW_LIST,
  },
  CreateInvoice: {
    path: "/create-invoice",
    component: <CreateInvoice />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.INVOICE.GROUP,
    permission: AppPermissions.INVOICE.VIEW_LIST,
  },
  CashFlow: {
    path: "/cash-flow",
    component: <CashFlow />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.CASH_FLOW.GROUP,
    permission: AppPermissions.CASH_FLOW.VIEW_LIST,
  },
  Transactions: {
    path: "/transactions",
    component: <TransactionList approved={true} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.TRANSACTION.GROUP,
    permission: AppPermissions.TRANSACTION.VIEW_LIST,
  },
  ManageBankReports: {
    path: "/manage-bank-reports",
    component: <ManageBankReport />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.BANK_REPORT.GROUP,
    permission: AppPermissions.BANK_REPORT.VIEW_LIST,
  },
  FinanceSettings: {
    path: "/finance-settings/bank-accounts",
    component: <FinanceSettings />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.BANK_ACCOUNT.GROUP,
    permission: AppPermissions.BANK_ACCOUNT.VIEW_LIST,
  },
  FinanceSettingsInvoiceSettings: {
    path: "/finance-settings/invoice-settings",
    component: (
      <FinanceSettings activeTab={FinanceSettingsTab.INVOICE_SETTINGS} />
    ),
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.INVOICE_SETTINGS.GROUP,
    permission: AppPermissions.INVOICE_SETTINGS.VIEW_LIST,
  },

  FinanceSettingsTransactionCategories: {
    path: "/finance-settings/transaction-categories",
    component: (
      <FinanceSettings activeTab={FinanceSettingsTab.TRANSACTION_CATEGORY} />
    ),
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.TRANSACTION_CATEGORY.GROUP,
    permission: AppPermissions.TRANSACTION_CATEGORY.VIEW_LIST,
  },

  FinanceSettingsBusinessYears: {
    path: "/finance-settings/business-years",
    component: <FinanceSettings activeTab={FinanceSettingsTab.BUSINESS_YEAR} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.BUSINESS_YEAR.GROUP,
    permission: AppPermissions.BUSINESS_YEAR.VIEW_LIST,
  },
  FinanceSettingsContractStatus: {
    path: "/finance-settings/contract-status",
    component: (
      <FinanceSettings activeTab={FinanceSettingsTab.CONTRACT_STATUS} />
    ),
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.CONTRACT_STATUS.GROUP,
    permission: AppPermissions.CONTRACT_STATUS.VIEW_LIST,
  },
  FinanceSettingsContractPartStatus: {
    path: "/finance-settings/contract-part-status",
    component: (
      <FinanceSettings activeTab={FinanceSettingsTab.CONTRACT_PART_STATUS} />
    ),
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.CONTRACT_PART_STATUS.GROUP,
    permission: AppPermissions.CONTRACT_PART_STATUS.VIEW_LIST,
  },
  Calendar: {
    path: "/calendar",
    component: <Calendar />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.CALENDAR.GROUP,
  },
};

const spacesPages = {
  SpacesList: {
    path: "/spaces",
    component: <SpacesList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.SPACES.GROUP,
  },
  Space: {
    path: "/space/:publicLink",
    component: <SpacesList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.SPACES.GROUP,
  },
};

const checkListPages = {
  TemplateList: {
    path: "/check-list-template",
    component: <CheckListTemplateList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.CHECK_LIST.GROUP,
  },
  TemplateCreateCheckList: {
    path: "/check-list-templates/template-create",
    component: <CreateCheckListTemplate />,
    exact: true,
    auth: true,
    permissionGroup: AppPermissions.CHECK_LIST.GROUP,
  },
  ScheduledCheckList: {
    path: "/check-list-scheduled-checks",
    component: <CheckListScheduledCheckList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.CHECK_LIST.GROUP,
  },
  CheckListForms: {
    path: "/check-list-forms",
    component: <CheckListForms />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.CHECK_LIST.GROUP,
  },
};

const workspacesPages = {
  WorkspacesList: {
    path: "/workspaces",
    component: <WorkspacesList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.WORKSPACES.GROUP,
    permission: AppPermissions.WORKSPACES.VIEW_LIST,
  },

  WorkspaceSettingsIssueTypes: {
    path: "/workspace-settings/:id/issue-types",
    component: <WorkspaceSettings />,
    auth: true,
    exact: false,
    access: AppAccesses.WORKSPACES,
    permissionGroup: AppPermissions.WORKSPACES.GROUP,
    permission: AppPermissions.WORKSPACES.EDIT,
  },

  WorkspaceSettingsLogo: {
    path: "/workspace-settings/:id/logo",
    component: <WorkspaceSettings activeTab={WorkspacesSettingsTab.LOGO} />,
    auth: true,
    exact: false,
    access: AppAccesses.WORKSPACES,
    permissionGroup: AppPermissions.WORKSPACES.GROUP,
    permission: AppPermissions.WORKSPACES.EDIT,
  },

  WorkspaceSettingsAccess: {
    path: "/workspace-settings/:id/workspace-access",
    component: <WorkspaceSettings activeTab={WorkspacesSettingsTab.ACCESS} />,
    auth: true,
    exact: false,
    access: AppAccesses.WORKSPACES,
    permissionGroup: AppPermissions.WORKSPACES.GROUP,
    permission: AppPermissions.WORKSPACES.EDIT,
  },

  Boards: {
    path: "/workspaces/:id/boards",
    component: <BoardsList />,
    auth: true,
    exact: false,
    access: AppAccesses.WORKSPACES,
    permissionGroup: AppPermissions.BOARDS.GROUP,
    permission: AppPermissions.BOARDS.VIEW_LIST,
  },
  BoardsChangeLogo: {
    path: "/workspaces/:id/boards/:boardId/change-logo",
    component: <BoardsChangeLogo />,
    auth: true,
    exact: false,
    access: AppAccesses.WORKSPACES,
    permissionGroup: AppPermissions.BOARDS.GROUP,
    permission: AppPermissions.BOARDS.EDIT,
  },

  BoardView: {
    path: "/workspaces/:id/boards/:boardId",
    component: <BoardView />,
    auth: true,
    exact: false,
    access: AppAccesses.WORKSPACES,
    permissionGroup: AppPermissions.BOARDS.GROUP,
    permission: AppPermissions.BOARDS.VIEW,
  },
};

const projectPages = {
  Project: {
    path: "/project",
    component: <ProjectList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.PROJECT.GROUP,
    permission: AppPermissions.PROJECT.VIEW_LIST,
  },
  ProjectCategory: {
    path: "/project-categories",
    component: <ProjectCategoryList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.PROJECT_CATEGORY.GROUP,
    permission: AppPermissions.PROJECT_CATEGORY.VIEW_LIST,
  },
  ProjectResourceType: {
    path: "/project-resource-type",
    component: <ProjectResourceTypeList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.PROJECT_RESOURCE_TYPE.GROUP,
    permission: AppPermissions.PROJECT_RESOURCE_TYPE.VIEW_LIST,
  },
  ProjectResource: {
    path: "/project-resource",
    component: <ProjectResourceList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.PROJECT_RESOURCE.GROUP,
    permission: AppPermissions.PROJECT_RESOURCE.VIEW_LIST,
  },
  TaskCategory: {
    path: "/task-categories",
    component: <TaskCategoryList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.TASK_CATEGORY.GROUP,
    permission: AppPermissions.TASK_CATEGORY.VIEW_LIST,
  },

  ProjectDetailsTimeLog: {
    path: "/project-details/:id/project-time-log",
    component: <ProjectDetails />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.PROJECT_TIME_LOG.GROUP,
    permission: AppPermissions.PROJECT_TIME_LOG.VIEW_LIST,
  },

  ProjectDetailsRecord: {
    path: "/project-details/:id/project-record",
    component: <ProjectDetails activeTab={ProjectDetailsTab.RECORD} />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.PROJECT_RECORDS.GROUP,
    permission: AppPermissions.PROJECT_RECORDS.VIEW_LIST,
  },

  ProjectDetailsFiles: {
    path: "/project-details/:id/project-files/:referenceType",
    component: <ProjectDetails activeTab={ProjectDetailsTab.FILES} />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.PROJECT.GROUP,
    permission: AppPermissions.PROJECT.DOCUMENTS,
  },

  ProjectDetailsAccess: {
    path: "/project-details/:id/project-access",
    component: <ProjectDetails activeTab={ProjectDetailsTab.PROJECT_ACCESS} />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.PROJECT.GROUP,
    permission: AppPermissions.PROJECT.ACCESS,
  },

  ProjectDetailsStatistic: {
    path: "/project-details/:id/project-statistic",
    component: <ProjectDetails activeTab={ProjectDetailsTab.STATISTIC} />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.PROJECT.GROUP,
    permission: AppPermissions.PROJECT.STATISTIC,
  },

  ProjectDetailsTaskCategories: {
    path: "/project-details/:id/task-categories",
    component: (
      <ProjectDetails activeTab={ProjectDetailsTab.PROJECT_TASK_CATEGORIES} />
    ),
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.PROJECT.GROUP,
    permission: AppPermissions.PROJECT.TASK_CATEGORY,
  },

  ProjectSettingsProjectCategories: {
    path: "/project-settings/project-categories",
    component: <ProjectSettings />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.PROJECT_CATEGORY.GROUP,
    permission: AppPermissions.PROJECT_CATEGORY.VIEW_LIST,
  },

  ProjectSettingsResourceTypes: {
    path: "/project-settings/resource-types",
    component: (
      <ProjectSettings activeTab={ProjectSettingsTab.PROJECT_RESOURCE_TYPE} />
    ),
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.PROJECT_RESOURCE_TYPE.GROUP,
    permission: AppPermissions.PROJECT_RESOURCE_TYPE.VIEW_LIST,
  },

  ProjectSettingsTaskCategories: {
    path: "/project-settings/task-categories",
    component: <ProjectSettings activeTab={ProjectSettingsTab.TASK_CATEGORY} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.TASK_CATEGORY.GROUP,
    permission: AppPermissions.TASK_CATEGORY.VIEW_LIST,
  },
};

const documentPages = {
  DocumentStatus: {
    path: "/document-status",
    component: <DocumentStatusList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.DOCUMENT_STATUS.GROUP,
    permission: AppPermissions.DOCUMENT_STATUS.VIEW_LIST,
  },
  DocumentCategory: {
    path: "/document-category",
    component: <DocumentCategoryList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.DOCUMENT_CATEGORY.GROUP,
    permission: AppPermissions.DOCUMENT_CATEGORY.VIEW_LIST,
  },
  Document: {
    path: "/documents/:id/:referenceType",
    component: <DocumentList />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.DOCUMENT.GROUP,
    permission: AppPermissions.DOCUMENT.VIEW_LIST,
  },
  DocumentComment: {
    path: "/document-comments",
    component: <DocumentComment />,
    auth: true,
    exact: true,
  },
};

const companyDocumentPages = {
  CompanyDocumentCategory: {
    path: "/company-document-categories",
    component: <CompanyDocumentCategoryList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.COMPANY_DOCUMENT_CATEGORY.GROUP,
    permission: AppPermissions.COMPANY_DOCUMENT_CATEGORY.VIEW_LIST,
  },
  CompanyDocumentTemplateList: {
    path: "/company-document-templates",
    component: <CompanyDocumentTemplateList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.COMPANY_DOCUMENT_TEMPLATE.GROUP,
    permission: AppPermissions.COMPANY_DOCUMENT_TEMPLATE.VIEW_LIST,
  },
  CompanyDocumentTemplateItemList: {
    path: "/template-items/:id",
    component: <CompanyDocumentTemplateItemList />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.COMPANY_DOCUMENT_TEMPLATE_ITEM.GROUP,
    permission: AppPermissions.COMPANY_DOCUMENT_TEMPLATE_ITEM.VIEW_LIST,
  },
  TemplateContent: {
    path: "/template-content",
    component: <TemplateContent />,
    exact: true,
    auth: true,
  },
  TemplateCreate: {
    path: "/company-document-templates/template-create",
    component: <CreateCompanyDocumentTemplate />,
    exact: true,
    auth: true,
  },
  CompanyDocuments: {
    path: "/company-documents",
    component: <CompanyDocumentList />,
    exact: true,
    auth: true,
    permissionGroup: AppPermissions.COMPANY_DOCUMENT.GROUP,
    permission: AppPermissions.COMPANY_DOCUMENT.VIEW_LIST,
  },
  CompanyDocumentsCreate: {
    path: "/company-documents/create-document",
    component: <CreateCompanyDocument />,
    exact: true,
    auth: true,
    permissionGroup: AppPermissions.COMPANY_DOCUMENT.GROUP,
    permission: AppPermissions.COMPANY_DOCUMENT.VIEW_LIST,
  },
  CompanyDocumentsForms: {
    path: "/company-documents/create-document-forms",
    component: <CompanyDocumentsForms />,
    exact: true,
    auth: true,
    permissionGroup: AppPermissions.COMPANY_DOCUMENT.GROUP,
    permission: AppPermissions.COMPANY_DOCUMENT.VIEW_LIST,
  },
  CompanyDocumentContent: {
    path: "/document-content",
    component: <DocumentContent />,
    exact: true,
    auth: true,
  },
};

const Files = {
  FileFolders: {
    path: "/file-folders",
    component: <FileFolder />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.FILE_FOLDER.GROUP,
    permission: AppPermissions.FILE_FOLDER.VIEW_LIST,
  },
};

const administration = {
  Role: {
    path: "/roles",
    component: <RoleList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.ROLE.GROUP,
    permission: AppPermissions.ROLE.VIEW_LIST,
  },
  RolePermission: {
    path: "/role-permissions",
    component: <RolePermissionList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.ROLE_PERMISSIONS.GROUP,
    permission: AppPermissions.ROLE_PERMISSIONS.VIEW_LIST,
  },
  Pipeline: {
    path: "/pipelines",
    component: <PipelineList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.PIPE_LINE.GROUP,
    permission: AppPermissions.PIPE_LINE.VIEW_LIST,
  },
  PipelineStage: {
    path: "/pipelines/:id",
    component: <PipelineStageList />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.PIPE_LINE_STAGE.GROUP,
    permission: AppPermissions.PIPE_LINE_STAGE.VIEW_LIST,
  },
  PipelineAccesses: {
    path: "/pipeline-accesses",
    component: <PipelineAccessList />,
    auth: true,
    exact: true,
  },
  User: {
    path: "/users",
    component: <UserList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.USER.GROUP,
    permission: AppPermissions.USER.VIEW_LIST,
  },
  UserRole: {
    path: "/user-roles",
    component: <UserRoleList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.ROLE.GROUP,
    permission: AppPermissions.ROLE.VIEW_LIST,
  },
  BusinessYearStartValue: {
    path: "/business-year-start-values/:id",
    component: <BusinessYearStartValue />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.BUSINESS_YEAR_START_VALUE.GROUP,
    permission: AppPermissions.BUSINESS_YEAR_START_VALUE.VIEW_LIST,
  },
};

const errorPages = {
  InternalServerError: {
    path: "/500",
    component: <InternalServerError />,
    auth: false,
    exact: true,
  },
  NotFound: {
    path: "/404",
    component: <NotFound />,
    auth: false,
    exact: true,
  },
  BadRequest: {
    path: "/400",
    component: <BadRequest />,
    auth: false,
    exact: true,
  },
  Forbidden: {
    path: "/403",
    component: <Forbidden />,
    auth: false,
    exact: true,
  },
  Unauthorized: {
    path: "/401",
    component: <Unauthorized />,
    auth: false,
    exact: true,
  },
};

const companyPages = {
  CompanySettings: {
    path: "/company-settings",
    component: <CompanySettings />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.COMPANY_SETTINGS.GROUP,
    permission: AppPermissions.COMPANY_SETTINGS.VIEW_LIST,
  },

  CompanySettingsLogo: {
    path: "/company-settings/logo",
    component: <CompanySettings activeTab={CompanySettingsTab.LOGO} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.COMPANY_SETTINGS.GROUP,
    permission: AppPermissions.COMPANY_SETTINGS.VIEW_LIST,
  },

  CompanySettingsS3Settings: {
    path: "/company-settings/s3-settings",
    component: <CompanySettings activeTab={CompanySettingsTab.S3_SETTINGS} />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.COMPANY_SETTINGS.GROUP,
    permission: AppPermissions.COMPANY_SETTINGS.VIEW_LIST,
  },

  CompanySettingsLocalisationSettings: {
    path: "/company-settings/localisation-settings",
    component: (
      <CompanySettings activeTab={CompanySettingsTab.LOCALISATION_SETTINGS} />
    ),
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.COMPANY_SETTINGS.GROUP,
    permission: AppPermissions.COMPANY_SETTINGS.VIEW_LIST,
  },
};

const assetManagementPages = {
  Vendors: {
    path: "/vendors",
    component: <VendorList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.VENDOR.GROUP,
    permission: AppPermissions.VENDOR.VIEW_LIST,
  },
  AssetTypes: {
    path: "/asset-types",
    component: <AssetTypeList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.ASSET_TYPE.GROUP,
    permission: AppPermissions.ASSET_TYPE.VIEW_LIST,
  },
  AssetCategory: {
    path: "/asset-categories",
    component: <AssetCategoryList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.ASSET_CATEGORY.GROUP,
    permission: AppPermissions.ASSET_CATEGORY.VIEW_LIST,
  },
};

const taskManagementPages = {
  TaskDetails: {
    path: "/task-details",
    component: <TaskDetails />,
    auth: true,
    exact: true,
  },
  TaskTemplateDetails: {
    path: "/task-template-details",
    component: <TaskTemplateDetails />,
    auth: true,
    exact: true,
  },
};

let ROUTES = {
  Default: {
    path: "/",
    component: <Default />,
    auth: true,
    exact: true,
  },
  Home: {
    path: "/home",
    component: <Home />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.MODULE.GROUP,
    permission: AppPermissions.MODULE.HOME,
  },
  ProductServiceList: {
    path: "/product-services",
    component: <ProductServiceList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.PRODUCTS_AND_SERVICES.GROUP,
    permission: AppPermissions.PRODUCTS_AND_SERVICES.VIEW_LIST,
  },
  ProductServiceCategoryList: {
    path: "/product-services-category",
    component: <ProductServiceCategoryList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.GROUP,
    permission: AppPermissions.PRODUCTS_AND_SERVICES_CATEGORY.VIEW_LIST,
  },
  CompositeProduct: {
    path: "/composite-product/:id",
    component: <CompositeProductList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.COMPOSITE_PRODUCT.GROUP,
    permission: AppPermissions.COMPOSITE_PRODUCT.VIEW_LIST,
  },
  BankReportList: {
    path: "/bank-reports",
    component: <BankReportList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.BANK_REPORT.GROUP,
    permission: AppPermissions.BANK_REPORT.VIEW_LIST,
  },
  ContractList: {
    path: "/contracts",
    component: <ContractList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.CONTRACT.GROUP,
    permission: AppPermissions.CONTRACT.VIEW_LIST,
  },
  ContractPartList: {
    path: "/contract-parts/:id/:name",
    component: <ContractPartList />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.CONTRACT_PART.GROUP,
    permission: AppPermissions.CONTRACT_PART.VIEW_LIST,
  },
  ContractPartListItems: {
    path: "/contract-parts/:id/contract-part-items/:contractPartId/:contractName",
    component: <ContractPartItemsList />,
    auth: true,
    exact: false,
    permissionGroup: AppPermissions.CONTRACT_PART.GROUP,
    permission: AppPermissions.CONTRACT_PART.VIEW_LIST,
  },
  AssetManagementLocationList: {
    path: "/asset-management-locations",
    component: <AssetManagementLocationList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.ASSET_LOCATION.GROUP,
    permission: AppPermissions.ASSET_LOCATION.VIEW_LIST,
  },
  InventoryCategoryList: {
    path: "/inventory-categories",
    component: <InventoryCategoryList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.INVENTORY_CATEGORY.GROUP,
    permission: AppPermissions.INVENTORY_CATEGORY.VIEW_LIST,
  },
  AssetList: {
    path: "/assets",
    component: <AssetList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.ASSET.GROUP,
    permission: AppPermissions.ASSET.VIEW_LIST,
  },
  InventoryTypeList: {
    path: "/inventory-types",
    component: <InventoryTypeList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.INVENTORY_TYPE.GROUP,
    permission: AppPermissions.INVENTORY_TYPE.VIEW_LIST,
  },
  InventoryList: {
    path: "/inventories",
    component: <InventoryList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.INVENTORY.GROUP,
    permission: AppPermissions.INVENTORY.VIEW_LIST,
  },
  StockChangeList: {
    path: "/stock-changes",
    component: <StockChangeList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.STOCK_CHANGE.GROUP,
    permission: AppPermissions.STOCK_CHANGE.VIEW_LIST,
  },
  ProjectTimeLogList: {
    path: "/project-time-log",
    component: <ProjectTimeLogList />,
    auth: true,
    exact: true,
    permissionGroup: AppPermissions.PROJECT_TIME_LOG.GROUP,
    permission: AppPermissions.PROJECT_TIME_LOG.VIEW_LIST,
  },
};

Object.assign(ROUTES, ROUTES, userPages);
Object.assign(ROUTES, ROUTES, errorPages);
Object.assign(ROUTES, ROUTES, crmPages);
Object.assign(ROUTES, ROUTES, hrPages);
Object.assign(ROUTES, ROUTES, statisticPages);
Object.assign(ROUTES, ROUTES, productPages);
Object.assign(ROUTES, ROUTES, financePages);
Object.assign(ROUTES, ROUTES, projectPages);
Object.assign(ROUTES, ROUTES, documentPages);
Object.assign(ROUTES, ROUTES, companyPages);
Object.assign(ROUTES, ROUTES, companyDocumentPages);
Object.assign(ROUTES, ROUTES, administration);
Object.assign(ROUTES, ROUTES, assetManagementPages);
Object.assign(ROUTES, ROUTES, Files);
Object.assign(ROUTES, ROUTES, taskManagementPages);
Object.assign(ROUTES, ROUTES, workspacesPages);
Object.assign(ROUTES, ROUTES, spacesPages);
Object.assign(ROUTES, ROUTES, checkListPages);

export function getRoutes() {
  let result = [];

  for (const [key, value] of Object.entries(ROUTES)) {
    result.push(
      <Route key={"route-" + key} path={value.path} element={value.component} />
    );
  }

  return <Routes>{result}</Routes>;
}

function getRoute(path) {
  for (const [key, value] of Object.entries(ROUTES)) {
    const match = matchPath(
      {
        path: value.path,
        exact: value.exact,
        strict: false,
      },
      path
    );

    if (match) {
      return value;
    }
  }

  return null;
}

export function checkPath(path) {
  let pathObject = getRoute(path);

  if (!pathObject) {
    return true;
  }

  if (pathObject.auth) {
    return !isUserLoggedIn();
  }

  return false;
}

function getRouteParams(pathObject, path) {
  const { params } = matchPath({ path: pathObject.path }, path);

  return params?.id;
}

export const checkPagePermission = (path, user, permissions) => {
  let pathObject = getRoute(path);
  let company = user?.company;

  if (!pathObject) {
    return false;
  }

  if (!pathObject.permission || !pathObject.permissionGroup) {
    return true;
  }

  if (
    pathObject.permissionGroup === "COMPANY_SETTINGS" &&
    !company?.accountSettings.trialUsed &&
    user?.isOwner
  ) {
    return true;
  }

  const accessId = getRouteParams(pathObject, path);
  const module =
    ModulePermission[AppPermissions[pathObject.permissionGroup].MODULE];

  return canAccess(
    company,
    module,
    user,
    pathObject.permissionGroup,
    pathObject.permission,
    permissions,
    pathObject.access,
    accessId
  );
};
