import CompanyDocumentReferenceType from "./CompanyDocumentReferenceType";
import {
    getCompanyDocumentReferenceUserValueTypes,
    getCompanyDocumentReferenceUserValueTypeString
} from "./CompanyDocumentReferenceValueTypes/CompanyDocumentReferenceUserValueType";

export function getCompanyDocumentReferenceValueTypes(referenceType) {
    switch (referenceType) {
        case CompanyDocumentReferenceType.USER: return getCompanyDocumentReferenceUserValueTypes()
        default: return []
    }
}

export function getCompanyDocumentReferenceValueTypeString(referenceType, referenceValueType) {
    switch(referenceType) {
        case CompanyDocumentReferenceType.USER: return getCompanyDocumentReferenceUserValueTypeString(referenceValueType);
        default: return ''
    }
}