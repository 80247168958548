import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import AutoCompleteControl from "../../../Controls/Inputs/AutoCompleteControl";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import strings from "../../../../localization";
import TablePageContext from "../../../../Context/TablePageContext";
import PageState from "../../../../Constants/Base/PageState";

const ProductServiceCategoryForm = ({
                                        onSubmit,
                                        onCancel,
                                        data,
                                        form,
                                        errors, products,
                                        values,
                                        setValue,
                                        formRules,
                                        control, onProductChangeFilter
                                    }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;

    return (
        <FormProvider {...form}>
            <form id='product-service-category-form' className='form' action="#">
                <AutoCompleteControl
                    value={values['product']}
                    rules={formRules['product']}
                    setValue={setValue}
                    control={control}
                    name='product'
                    error={Boolean(errors.product)}
                    helperText={errors.product && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.product.compositeProduct.compositeProductForm.product}
                    options={products}
                    nameKey={'name'}
                    valueKey={'id'}
                    disabled={isPageStateViewDetails()}
                    onChange={onProductChangeFilter}
                    onOpen={() => onProductChangeFilter('')}
                />
                <TextFieldControl
                    name='quantity'
                    rules={formRules['quantity']}
                    control={data}
                    type="number"
                    error={Boolean(errors.quantity)}
                    helperText={errors.quantity && strings.forms.common.thisFieldIsRequired}
                    label={strings.forms.product.compositeProduct.compositeProductForm.quantity}
                    disabled={isPageStateViewDetails()}
                />
                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.common.save}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onCancel}>
                            {strings.forms.common.cancel}
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default ProductServiceCategoryForm;
