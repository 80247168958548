import React, { useEffect } from 'react';
import {Table, TableCell, TableContainer, TableRow} from "@mui/material";
import strings from "../../../../../localization";
import {getCurrencyNumber} from "../../../../../Util/RenderDataUtil";
import {getTotal, getTotalWithVAT, getVAT} from "../../../../../Util/InvoiceUtil";

const InvoiceTotal = ({
                       invoiceData,
                       invoiceId
                   }) => {
    const items = invoiceData ? invoiceData.items : [];
    const currency = invoiceData.currency ? invoiceData.currency : undefined;

    useEffect(() => {
        if(invoiceId === -1){
            invoiceData.items = [];
        }
    }
    ,[invoiceId]);

    return (<div id="invoice-items-total">

        <TableContainer>
            <Table>
                <TableRow>
                    <TableCell>
                        <b>{ strings.forms.finance.invoice.invoiceTotal.total }</b>
                    </TableCell>
                    <TableCell>
                        { getCurrencyNumber(getTotal(items), currency) }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <b>{ strings.forms.finance.invoice.invoiceTotal.VAT }</b>
                    </TableCell>
                    <TableCell>
                        { getCurrencyNumber(getVAT(items), currency) }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <b>{ strings.forms.finance.invoice.invoiceTotal.totalWithVAT }</b>
                    </TableCell>
                    <TableCell>
                        { getCurrencyNumber(getTotalWithVAT(items), currency) }
                    </TableCell>
                </TableRow>
            </Table>
        </TableContainer>
        
    </div>
)};

export default InvoiceTotal;
