import React, {useContext, useEffect, useState, useCallback, useRef} from "react";
import {useDispatch} from "react-redux";
import strings from "../../../localization";
import {changeBreadcrumbs} from "../../../Slices/BreadcrumbsSlice";
import {getRolePermissions, updateRolePermissions, updateAllRolePermissions} from "../../../Services/User/RolePermissionService";
import { useLocation, useSearchParams } from 'react-router-dom';
import {changePageSizeState} from "../../../Slices/PageSlice";
import PageSizeState from "../../../Constants/Base/PageSizeState";
import {getPermissions} from "../../../Services/User/PermissionService";
import {Accordion, AccordionDetails, AccordionSummary, Switch} from "@mui/material";
import Modules from "../../../Constants/Base/Modules";
import { getClasses } from "../../../Util/SwitchUtil";
import LoaderContext from "../../../Context/LoaderContext";
import {useForm, FormProvider} from "react-hook-form";
import TextSearchControl from "../../../Components/Controls/Inputs/TextSearchControl";
import { debounce } from 'lodash';
import {formatQueryObject} from "../../../Util/UrlUtil";
import {ModulePermissions} from "../../../Constants/Permissions/Permissions";


const RolePermissionList = () => {
    const {state, search} = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedFilter, setUpdatedFilter] = useState(!Boolean(searchParams.toString()));
    const changeLocation = useRef(false);
    const dispatch = useDispatch();
    const {loading, setLoading} = useContext(LoaderContext);
    const [rolePermissions, setRolePermissions] = useState(null)
    const [permissions, setPermissions] = useState({})
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [filter, setFilter] = useState({});
    const form = useForm({
        defaultValues: {
            term: '',
        }
    });
    const {data, watch, setValue, getValues} = form;
    let watchValues = ['term'];

    useEffect(() => {
        dispatch(changePageSizeState(PageSizeState.SHORT));
        dispatch(changeBreadcrumbs({
            title: strings.pages.administration.role.roleList.permissions,
            hierarchy:[
                {label: strings.navigation.managmentTag},
                {label: Modules.ADMINISTRATION},
                {label: strings.pages.administration.role.roleList.pageTitle},
                {label: strings.pages.administration.role.roleList.permissions},
            ],        
        }));  
        setLoading(true)
    }, [])

    useEffect(() => {
        const subscription = watch((data) => {
            if(!updatedFilter){
                return;
            }

            if(changeLocation.current){
                return;
            }

            debounceSetFilter({term: data.term})
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [watch, updatedFilter]);

    const debounceSetFilter = useCallback(
        debounce((newValue) =>{
            setSearchParams(formatQueryObject(newValue), {state: state});
            setFilter(newValue)
        }, 800),
    []);

    const populateFilters = (params) => {
        form.reset();
        setValue('term', params.term);
        setFilter({term: params.term});

        changeLocation.current = false;
    }

    useEffect(() => {
        if(!searchParams.toString()){
            return;
        }

        populateFilters({term: searchParams.get('term')});
        setUpdatedFilter(true);
    },[]);

    useEffect(() => {
        if(!updatedFilter || !rolePermissions){
            return;
        }

        fetchPermissions();
    },[filter, updatedFilter, rolePermissions]);

    useEffect(()=>{
        if(!state){
            return;
        }
        fetchRolePermissions(); 
    }, [state.id])

    useEffect(() => {
        const onLocationChange = () => {
            changeLocation.current = true;
            populateFilters({term: new URLSearchParams(window.location.search).get('term')});
        }

        window.addEventListener('popstate', onLocationChange);

        return () => {
            window.removeEventListener('popstate', onLocationChange);
        }
    },[]);

    const fetchRolePermissions = () => {
        setLoading(true);
        getRolePermissions(state.id).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setRolePermissions(response.data.entities)
        })
    }

    const fetchPermissions = () => {
        setLoading(true)
        getPermissions({...filter, roleId: state.id}).then(response => {
            if (!response || !response.ok) {
                return;
            }
            const modules = Object.keys(ModulePermissions);
            const modulesArray = new Array(modules.length).fill({});
            const modulePermissionGroup = {};
            let allChecked = Boolean(response.data.entities?.length);
            for (let permission of response.data.entities) {
                const moduleIndex = modules.indexOf(permission.module);
                const permissionGroupObject = moduleIndex !== -1 ? {...modulesArray[moduleIndex]} : modulePermissionGroup;

                if (!permissionGroupObject[permission.group]) {
                    permissionGroupObject[permission.group] = [];
                }
                permissionGroupObject[permission.group].push({
                    code: permission.code,
                    allowed: getRolePermissionAllowed(permission.id),
                    permisionId: permission.id,
                    module: permission.module
                });

                if (allChecked) {
                    allChecked = getRolePermissionAllowed(permission.id);
                }

                if (moduleIndex !== -1) {
                    modulesArray[moduleIndex] = {...permissionGroupObject};
                }
            }
            const sortedPermissions = modulesArray.reduce((acc, curr) => ({...acc, ...curr}), {});

            setLoading(false);
            setPermissions({...modulePermissionGroup, ...sortedPermissions});
            setIsAllChecked(allChecked);
        })
    }

    const getRolePermissionAllowed = (permissionId) => {
        const permission = rolePermissions?.find(permission => permission.permissionId === permissionId);
        if(!permission?.allowed) return false;

        return permission.allowed;
    }
    
    const permissionChange = (group, rolePermission) => {
        let elements = permissions[group];
        let allowed = false;
        for(let element of elements) {
            if(element.code === rolePermission.code) {
                element.allowed = !element.allowed;
                allowed = element.allowed;
            }
        }
        permissions[group] = elements;

        const data = {
            permissions:[
                {
                    roleId: state.role.id,
                    permissionId: rolePermission.permisionId,
                    allowed: allowed,
                }
            ],
            roleId: state.role.id,
        };
        setLoading(true);
        updateRolePermissions(data).then(response =>{
            if(!response || !response.ok) {
                return;
            }
            fetchRolePermissions()
        });
    }

    const renderExpandedElements = (group, elements) => {
        let result = [];

        for(let element of elements) {         
          
            result.push(
                <div className='element-container' key={'element-' + result.length }>
                    <label className="font-14">{ strings.constants.permissions.codes[element.code] }</label>
                    <div className='checkbox-container'>
                        <Switch
                            className={getClasses(false, element.allowed)}
                            checked={element.allowed}
                            onChange={() => permissionChange(group, element)}
                        />
                    </div>
                </div>
            )
        }
        return result;
    }
    const handleGroupSwitch = (elements, checked) =>{

        const data = {
            permissions:[],
            roleId: state.role.id,
        };

        for(const element of elements){
            data.permissions.push({
                permissionId: element.permisionId,
                allowed: !checked,
            });
            element.allowed = !checked;
        }

        setLoading(true);
        updateRolePermissions(data).then(response =>{
            if(!response || !response.ok) {
                return;
            }
            fetchRolePermissions()
        });     
    }

    const isGroupChecked = (elements) =>{
        for(const element of elements){
            if(!element.allowed){
                return false;
            }
        }
        return true;
    }

    const renderExpandGroup = (group, elements, index) => {
        const groupChecked = isGroupChecked(elements);
        return (
            <div className='accordion-container' key = {'select-all-group-' + index}>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<img src="/images/double-blue-arrow-down.svg" />}
                        aria-controls={group}
                        id={group}>
                            <h3>{ strings.constants.permissions.groups[group] }</h3>
                            <div onClick={e => {e.stopPropagation()}} className='header-checkbox-container'>
                                {strings.pages.administration.role.permissions.selectAll}
                                <Switch
                                    className={getClasses(false, groupChecked)}
                                    checked={groupChecked}
                                    onChange={() => handleGroupSwitch(elements, groupChecked)}
                                />
                            </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='elements'>
                            { renderExpandedElements(group, elements) }
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        );

    }

    const selectAllPermissions = () => {
        setIsAllChecked((prev) => !prev);
        updateAllRolePermissions({roleId: state.role.id, select: !isAllChecked}).then(response =>{
            if(!response || !response.ok) {
                return;
            }
            fetchRolePermissions()
        });     
    }

    const renderExpandGroups = () => {
        let result = [];
        if(permissions['MODULE']){
            const tmp_home = permissions['MODULE'][10];
            const tmp_workspaces = permissions['MODULE'][11];
            let tmp_permissions = permissions['MODULE'].filter(p => p.code !== 'HOME' && p.code !== 'WORKSPACES');

            tmp_permissions.splice(0,0, tmp_home);
            tmp_permissions.splice(3,0, tmp_workspaces);

            result.push(renderExpandGroup('MODULE', tmp_permissions));
        }
        for(let [index, group] of Object.keys(permissions).entries()) {
            if(group === 'MODULE'){
                continue;
            }
            result.push(
                renderExpandGroup(group, permissions[group], index)
            )
        }

        return result;
    }


    return (
        <div className='expand-group mx-3'>
            <div className = 'permissions-search-container'>
                <div className='header top-header'>
                    <div className={'filter-container left-filter'}>
                        <div className={'search-container'}>
                            <FormProvider {...form}>                          
                                <div className='filter-item'>
                                    <TextSearchControl
                                        name='term'
                                        control={data}
                                        defaultValue=''
                                        margin="normal"
                                        placeholder = {'Search'}
                                    />
                                </div>
                            </FormProvider>
                        </div>
                    </div>
                    <div className={'filter-container right-filter'}>
                        <div onClick={e => {e.stopPropagation()}} className='header-checkbox-container'>
                                {strings.pages.administration.role.permissions.selectAllPermissions}
                            <Switch
                                className={getClasses(false, isAllChecked)}
                                checked={isAllChecked}
                                onChange={() => selectAllPermissions()}
                            />
                        </div>
                    </div>
                </div>
            </div>
            { renderExpandGroups() }
        </div>
    );
}

export default RolePermissionList;


