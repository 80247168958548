import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";



export const getFileUrlFromAws = async(clientParams, imageKey ) => {
    const CREDENTIAL = {
        accessKeyId: clientParams.s3key,
        secretAccessKey: clientParams.secret,
    };
    const client = new S3Client({ 
        region: clientParams.region, 
        credentials: CREDENTIAL,
        version: clientParams.version,
        endpoint: clientParams.url,
        bucket: clientParams.bucket,
    });
    const params = {Bucket: clientParams.bucket, Key: imageKey};
    const command = new GetObjectCommand(params);
    const url = await getSignedUrl(client, command);

    return url;
}



