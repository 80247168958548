import { request } from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";

export async function getJobContractTypes(data) {
    return await request('/api/job-contract-types', data);
}

export async function getJobContractType(id) {
    return await request('/api/job-contract-types/' + id);
}

export async function addJobContractType(data) {
    return await request('/api/job-contract-types', transformRequestData(data), HttpMethod.POST);
}

export async function editJobContractType(data) {
    return await request('/api/job-contract-types/' + data.id, transformRequestData(data), HttpMethod.PUT);
}

export async function deleteJobContractType(id) {
    return await request('/api/job-contract-types/' + id, {}, HttpMethod.DELETE);
}

function transformRequestData(data) {
    return {
        ...data,
    }
}
