import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import PageState from "../../../../../Constants/Base/PageState";
import TablePageContext from "../../../../../Context/TablePageContext";
import strings from "../../../../../localization";
import DateTimeControl from "../../../../Controls/Inputs/DateTimeControl";

const PresenceForm = ({
                       onSubmit,
                       onCancel,
                       data,
                       setValue,
                       values,
                       form,
                       errors,
                       formRules,
                       trigger
                    }) => {
    const {pageState} = useContext(TablePageContext);
    const isPageStateViewDetails = () => pageState === PageState.ViewDetails;
    const [startDate, setStartDate] = useState(values['start']);
    const [additionalRules, setAdditionalRules] = useState({});

    useEffect(() => {
        if(form.formState.isSubmitted){
            trigger('end');
        }
    }, [additionalRules, values['end']])
    
    useEffect(() => {
        setStartDate(values['start']);
    }, [values['start']])

    useEffect(() => {
        changeAdditionalRules(startDate);
    },[startDate])

    const changeStartDate = (date) => {
        setValue('start', date);
        setStartDate(date);
    }

    const changeAdditionalRules = (date) => {
        if(!date){
            setAdditionalRules({});
            return; 
        }
        setAdditionalRules({min: {value: date, message: strings.forms.hr.presence.dateTimeErorr}});
    }

    return (
        <FormProvider {...form}>
            <form id="presence-form" className='form' action = "#">
                <DateTimeControl
                        data={data}
                        rules={formRules['start']}
                        form={form}
                        error={Boolean(errors.start)}
                        helperText={errors.start && strings.forms.common.thisFieldIsRequired}
                        label={strings.forms.hr.presence.start}
                        name={'start'}
                        value={startDate}
                        setValue={(name, value) => changeStartDate(value)}
                        disabled={isPageStateViewDetails()}
                        dateTime
                        disableFuture
                />
                <DateTimeControl
                        data={data}
                        rules={{...formRules['end'], ...additionalRules}}
                        form={form}
                        error={Boolean(errors.end)}
                        helperText={errors.end && errors.end.message}
                        label={strings.forms.hr.presence.end}
                        name={'end'}
                        minDateTime={startDate}
                        value={values['end']}
                        setValue={setValue}
                        disabled={isPageStateViewDetails()}
                        dateTime
                        disableFuture
                />

                {
                    !isPageStateViewDetails() && <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={ onSubmit }>
                            { strings.forms.common.save }
                        </Button>
                        <Button variant="contained" color="secondary" onClick={ onCancel }>
                            { strings.forms.common.cancel }
                        </Button>
                    </div>
                }
            </form>
        </FormProvider>
    );
}

export default PresenceForm;