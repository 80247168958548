import React, {useState} from 'react';
import {Paper, Modal} from "@mui/material";
import EditQuestionForm from "../../../Components/Forms/Pages/CompanyDocument/CompanyDocumentTemplateQuestion/EditQuestionForm";
import { useForm } from "react-hook-form";
import strings from "../../../localization";
import AddQuestionOptionsModal from './AddQuestionOptionsModal';


const formRules = {
    'question': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
    'type': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
};
const EditCompanyDocumentTemplateQuestionModal = ({showModal, closeModal, submit, question}) => {

    const [showAddOptionsPaper, setShowAddOptionsPaper] = useState(false);
    const [handleShowAddQuestionOptionsModal, setHandleShowAddQuestionOptionsModal] = useState(false);

    const form = useForm({
        defaultValues: {
            question: question?.question,
            explanation: question?.description,
            code: question?.code,
            type: question?.type,
            required: true,
        }
    });
    const {data, control, handleSubmit, getValues, setValue, formState: {errors}} = form;

    const submitHandler = (data) => {
        const editData = {...data, id: question.id}
        submit(editData);
    }

    const handleCloseModal = (prop) => {
        closeModal(prop)
        form.reset()
    }

    const showOptionsHandler = (showOptions) => {
        setShowAddOptionsPaper(showOptions);
    }


    return(
        <Modal
            open={Boolean(showModal)}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            id='add-edit-question-modal'>
            <div className={showAddOptionsPaper ? "papers-space" : "paper-space"}>
                <Paper className="question-paper">
                    <img className='add-edit-question-modal-close' src='/images/close-issue-view.svg' onClick = {handleCloseModal} />
                    <div className='add-edit-question-modal-title'>
                        {strings.pages.companyDocument.companyDocumentTemplate.companyDocumentTemplateQuestion.newQuestion}
                    </div>
                    <EditQuestionForm
                        control={control}
                        data={data}
                        formRules={formRules}
                        values={getValues()}
                        setValue={setValue}
                        errors={errors} form={form}
                        onSubmit={handleSubmit(submitHandler)}
                        showOptionsPaper={showOptionsHandler}/>
                </Paper>
                {showAddOptionsPaper &&
                <AddQuestionOptionsModal></AddQuestionOptionsModal>}
            </div>
        </Modal>
    );

}


export default EditCompanyDocumentTemplateQuestionModal;