import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import {Paper} from "@mui/material";
import SnackbarContext from "../../../Context/SnackbarContext";
import {getPipelines} from "../../../Services/Company/PipelineService";
import { getProjectList } from '../../../Services/Project/ProjectService';
import BoardForm from '../../../Components/Forms/Pages/Boards/BoardForm';
import { PipelineType } from '../../../Constants/Company/PipelineType';
import { useParams } from 'react-router-dom';
import { addBoard, getBoardKeyExists } from "../../../Services/Workspaces/BoardsService";
import { isSpecialCharacter } from '../../../Util/Validation';

const formRules = {
    'name': {required: true},
    'pipeline': {required: true},
	'boardKey': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
}

const AddBoard = (props) => {
	const {id: workspaceId} = useParams();
	const {setDrawerTitle} = useContext(DrawerContext);
	const { showMessage } = useContext(SnackbarContext);
	const [showLoader, setShowLoader] = useState(false);
	const [pipelines, setPipelines] = useState([]);
	const [projects, setProjects] = useState([]);
	const form = useForm({
			defaultValues: 
			{
					name: '',
					description: '',
					pipeline: props.pipeline,
					workspaceId: parseInt(workspaceId),
					boardKey: '',
			}
	});
	const {data, handleSubmit, getValues, setValue, setError, formState: {errors}, control} = form;

	useEffect(() => {
			setDrawerTitle(strings.pages.boards.addBoards.pageTitle);
	},[]);

	const onSubmit = (data) => {

		if (!isSpecialCharacter(data.boardKey)) {
			setError('boardKey', {message: strings.forms.board.cantUseSpecialChar} );
			return;
		}

		setShowLoader(true);

		getBoardKeyExists(data).then((response) => {

			if(!response || !response.ok) {
				showMessage(strings.commonMessages.errorEditing, 'error');
				setError('boardKey', {message: strings.forms.board.boardKeyExists} );
				setShowLoader(false);
				return;
			}

			addBoard(data).then(response => {
				setShowLoader(false);

				if(!response || !response.ok) {
						showMessage(strings.commonMessages.errorAdding, 'error');
						props.onFinish();
						return;
				}

				showMessage(strings.commonMessages.addingSuccessful, 'success');
				props.onFinish();
			});
		});

	}

	const fetchPipelines = (term = '') => {
			getPipelines({
				term: term,
				type: PipelineType.TASK,
			}).then(response => {
					setPipelines(response.data?.result);
			});
	}

	const fetchProjects = (term = '') => {
		getProjectList({
			term
		}).then( response => {
			setProjects(response.data?.result);
		});
	}

	return (
			<div className = 'drawer-form'>
					<Paper className = 'paper'>
							{
									showLoader &&
											<LinearProgress />
							}
							<BoardForm 
									formRules = {formRules}
									form = {form}
									values = {getValues()}
									setValue = {setValue}
									data = {data}
									errors = {errors}
									onSubmit = {handleSubmit(onSubmit)}
									onCancel = {() => props.onCancel()}
									pipelines = {pipelines}
								  projects = {projects}
									control = {control}
									onChangePipelineFilter = {fetchPipelines}
									onChangeProjectFilter = {fetchProjects}
							/>
					</Paper>
			</div>
	);

}

export default AddBoard;
