import React from 'react';
import strings from '../../../../localization';
import Button from '@mui/material/Button';
import {FormProvider} from "react-hook-form";
import TextFieldControl from "../../../Controls/Inputs/TextFieldControl";
import {Link} from "react-router-dom";
import { useState } from 'react';
import { InputAdornment } from '@mui/material';

const RegistrationForm = ({
                              onSubmit,
                              data,
                              form,
                              errors,
                              formRules
                          }) => 
{
    const [passwordShown, setPasswordShown] = useState(false);
    const [repeatPasswordShown, setrepeatPasswordShown] = useState(false);

    const passwordAdornment = {
        endAdornment: (
            <InputAdornment position="end" onClick={()=>setPasswordShown(prevValue=>!prevValue)}>
            {passwordShown? <img src={'/images/eye-cross-icon.svg'} loading={'lazy'} /> : <img src={'/images/eye-open-icon.svg'} loading={'lazy'} />}
            </InputAdornment>
        ),
    }
    const repeatPasswordAdornment = {
        endAdornment: (
            <InputAdornment position="end" onClick={()=>setrepeatPasswordShown(prevValue=>!prevValue)}>
            {repeatPasswordShown? <img src={'/images/eye-cross-icon.svg'} loading={'lazy'} /> : <img src={'/images/eye-open-icon.svg'} loading={'lazy'} />}
            </InputAdornment>
        ),
    }

    return <FormProvider {...form}>
                <form id='registration-form' className='form' action="#">

                    <TextFieldControl
                        name='email'
                        control={data}
                        defaultValue=''
                        rules={formRules['email']}
                        fullWidth
                        margin="normal"
                        type={'email'}
                        error={Boolean(errors.email)}
                        helperText={errors.email && errors.email.message}
                        label={strings.forms.user.registration.email}
                        placeholder={strings.forms.user.registration.emailPlaceholder}
                    />

                    <TextFieldControl
                        name='firstName'
                        control={data}
                        defaultValue=''
                        rules={formRules['firstName']}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.firstName)}
                        helperText={errors.firstName && errors.firstName.message}
                        label={strings.forms.user.registration.firstName}
                        placeholder={strings.forms.user.registration.firstNamePlaceholder}
                    />

                    <TextFieldControl
                        name='lastName'
                        control={data}
                        defaultValue=''
                        rules={formRules['lastName']}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.lastName)}
                        helperText={errors.lastName && errors.lastName.message}
                        label={strings.forms.user.registration.lastName}
                        placeholder={strings.forms.user.registration.lastNamePlaceholder}
                    />
                    <div className='password-input'>
                        <TextFieldControl
                            name='password'
                            control={data}
                            defaultValue=''
                            rules={formRules['password']}
                            fullWidth
                            type={passwordShown? "text":"password"}
                            margin="normal"
                            error={Boolean(errors.password)}
                            helperText={errors.password && errors.password.message}
                            label={strings.forms.user.registration.password}
                            inputProps={passwordAdornment}
                            placeholder={strings.forms.user.registration.passwordPlaceholder}
                        />
                    </div>

                    <div className='password-input'>
                        <TextFieldControl
                            name='passwordRepeat'
                            control={data}
                            defaultValue=''
                            rules={formRules['passwordRepeat']}
                            fullWidth
                            type={repeatPasswordShown? "text":"password"}
                            margin="normal"
                            error={Boolean(errors.passwordRepeat)}
                            helperText={errors.passwordRepeat && errors.passwordRepeat.message}
                            label={strings.forms.user.registration.passwordRepeat}
                            inputProps={repeatPasswordAdornment}
                            placeholder={strings.forms.user.registration.passwordPlaceholder}
                        />
                    </div>

                    <div className='submit'>
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            {strings.forms.user.registration.signUp}
                        </Button>
                    </div>

                    <div className='link-to'>
                        <div className='text'>{strings.forms.user.registration.haveAnAccount}</div>
                        <Link to={'/login'}>{strings.forms.user.registration.login}</Link>
                    </div>

                </form>
            </FormProvider>
}

export default RegistrationForm;
