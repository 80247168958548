import React, {useContext} from "react";
import {useForm} from "react-hook-form";
import {Grid, Paper} from "@mui/material";
import strings from "../../localization";
import {useDispatch} from "react-redux";
import {changePageSizeState} from "../../Slices/PageSlice";
import PageSizeState from "../../Constants/Base/PageSizeState";
import ResetPasswordForm from "../../Components/Forms/Pages/User/ResetPasswordForm";
import {useNavigate, useSearchParams} from "react-router-dom";
import {confirmUserPassword} from "../../Services/User/UserService";
import SnackbarContext from "../../Context/SnackbarContext";

const formRules = {
    'password': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
    'passwordRepeat': {required: { value: true, message: strings.forms.common.thisFieldIsRequired}},
}
const ResetPassword = (props) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const form = useForm();
    const { data, handleSubmit, formState: { errors }, setError } = form;
    const { showMessage } = useContext(SnackbarContext);
    const navigate = useNavigate();


    const dispatch = useDispatch();
    dispatch(changePageSizeState(PageSizeState.FULL_NO_HEADER));

    const onSubmit = (data) => {
        if(data.password !== data.passwordRepeat) {
            setError('passwordRepeat', {message: strings.profile.passwordsDoNotMatch} );
            return;
        }

        const token = searchParams.get('token');
        confirmUserPassword({token, password: data.password}).then(response => {
            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorSaving, 'error');
                return;
            }
            showMessage(strings.commonMessages.saved, 'success');
            navigate('/login');
        });

    }


    return <div id='reset-password-page'>
        <div className="color-overlay"></div>
       
            <Paper >
                <h5>{ strings.pages.resetPassword.resetPassword }</h5>
                <ResetPasswordForm formRules={formRules} errors={ errors } data={ data } form={ form } onSubmit={ handleSubmit(onSubmit) }/>
            </Paper>
    </div>
};
export default ResetPassword;
