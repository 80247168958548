import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from "react";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import {useForm} from "react-hook-form";
import {Paper} from "@mui/material";
import SnackbarContext from "../../../Context/SnackbarContext";
import WorkspaceIssueTypeForm from '../../../Components/Forms/Pages/Workspaces/WorkspaceIssueTypeForm';
import { WorkspacesIssueTypesValues } from '../../../Constants/Workspaces/WorkspacesIssueTypes';
import { editIssueType} from "../../../Services/Workspaces/IssueTypeService";

const formRules = {
    'issueType': {required: true},
}

const IssueTypes = [
    {
        name: 'Task',
        type: WorkspacesIssueTypesValues.TASK,
    },
    {
        name: 'Story',
        type: WorkspacesIssueTypesValues.STORY,
    },
    {
        name: 'Bug',
        type: WorkspacesIssueTypesValues.BUG,
    },
    {
        name: 'Epic',
        type: WorkspacesIssueTypesValues.EPIC,
    },
    {
        name: 'Custom',
        type: WorkspacesIssueTypesValues.CUSTOM,
    },
];

const EditWorkspaceIssueType = (props) => {
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const [showLoader, setShowLoader] = useState(false);
    const form = useForm({
        defaultValues: {
            id: props.data[0]?.id,
            name: props.data[0]?.name,
            issueType: props.data[0],
        }
    });
    const {data, handleSubmit, getValues, setValue, formState: {errors}, control} = form;

    useEffect(() => {
        setDrawerTitle(strings.pages.workspaces.editWorkspaceIssueType.pageTitle);
    },[]);


    const onSubmit = (data) => {
        setShowLoader(true);

        editIssueType(data.id, data).then(response => {
            setShowLoader(false);

            if(!response || !response.ok) {
                showMessage(strings.commonMessages.errorAdding, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.addingSuccessful, 'success');
            props.onFinish();
        });
    }

    return (
        <div className = 'drawer-form'>
            <Paper className = 'paper'>
                {
                    showLoader &&
                        <LinearProgress />
                }
                <WorkspaceIssueTypeForm 
                    formRules = {formRules}
                    form = {form}
                    values = {getValues()}
                    setValue = {setValue}
                    data = {data}
                    errors = {errors}
                    onSubmit = {handleSubmit(onSubmit)}
                    onCancel = {() => props.onCancel()}
                    control = {control}
                    issueTypes = {IssueTypes}
                />
            </Paper>
        </div>
    );
}

export default EditWorkspaceIssueType;
