import React, { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller } from 'react-hook-form';
import {Switch} from '@mui/material';
import { getClasses } from '../../../Util/SwitchUtil';

const CheckBoxControl = ({value, setValue, name, label, control, disabled}) => {
    const [checked, setChecked] = useState(value);

    useEffect(() => {
      setChecked(value)
    }, [value])

    const handleChange = (event) => {
        setChecked(event.target.checked);
        setValue(name, event.target.checked)
      };

    return <Controller
            name={name}
            control={control}
            render={({ field: props }) => (
              <FormControlLabel
              label={label}
              control={
                <Switch
                className={getClasses(disabled, checked)}
                disabled={disabled}
                checked={checked}
                {...props}
                onChange={(e) => handleChange(e)}
              />
              }
              />
            )}
          />
}

export default CheckBoxControl;