import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import ContractForm from "../../../Components/Forms/Pages/Finance/Contract/ContractForm";
import DrawerContext from "../../../Context/DrawerContext";
import {editContract} from "../../../Services/Finance/ContractService";
import LinearProgress from '@mui/material/LinearProgress';
import { getCurrencies, getTaxRates } from '../../../Util/SiteDataUtil';
import { getPartners } from '../../../Services/CRM/PartnerService';
import {
    getAllTransactionCategories,
    getTransactionCategoryList
} from "../../../Services/Finance/TransactionCategoryService";
import SnackbarContext from "../../../Context/SnackbarContext";
import {getAllProjects, getProjectList} from "../../../Services/Project/ProjectService";
import {getContractStatuses} from "../../../Services/Finance/ContractStatusService";


const formRules = {
    'name': {required: true}, 'startDate': {required: true}, 'endDate': {required: {value: true, message: strings.forms.common.thisFieldIsRequired}},
    'contractType': {required: true}, 'partner': {required: true},
    'paymentDay': {min: 1, max: 31, required: true},
    'tax': {required: true},
    'currency': {required: true}, 
    'paymentMonth': {required: true},
}

const EditContract = (props) => {

    const siteData = useSelector((state) => state.siteData)
    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? {
            ...props.data[0],
            paymentMonth:{value: props.data[0].paymentMonth},
        } : undefined
    });
    const {data, control, handleSubmit, getValues, setValue, trigger, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    
    const [partnersData, setPartnersData] = useState(0);
    const [transactionCategoriesData, setTransactionCategories] = useState(0);
    const [projects, setProjects] = useState(0);
    const [contractStatuses, setContractStatuses] = useState(0);

    useEffect(() => {
        setDrawerTitle(strings.pages.finance.contract.editContract.pageTitle);
    });

    useEffect(() => {
        fetch(); 
    },[])

    const fetch = () => {

        fetchPartners();
        fetchTransactionCategories();
        fetchProjects();
        fetchContractStatuses();
    }

    const fetchPartners = (term = '') => {
        getPartners({term}).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setPartnersData(response.data.result);
        });
    }

    const fetchTransactionCategories = (term = '') => {
        getTransactionCategoryList({term}).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setTransactionCategories(response.data.result);
        });
    }
    const fetchProjects = (term = '') => {
        getProjectList({term}).then(response => {
            if(!response || !response.ok){
                return;
            }
            setProjects(response.data.result);
        })
    }
    const fetchContractStatuses = (term = '') => {
        getContractStatuses({term}).then(response => {
            if(!response || !response.ok){
                return;
            }
            setContractStatuses(response.data.result);
        })
    }
    const onSubmit = (data) => {

        setShowLoader(true);

        editContract(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <ContractForm
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                currenciesData={getCurrencies(siteData)}
                partnersData = {partnersData}
                transactionCategoriesData = {transactionCategoriesData}
                taxes={getTaxRates(siteData)}
                projects={projects}
                contractStatuses={contractStatuses}
                onChangeProjectFilter={fetchProjects}
                onChangeTransactionCategoryFilter={fetchTransactionCategories}
                onChangePartnerFilter={fetchPartners}
                onChangeContractStatusFilter={fetchContractStatuses}
                errors={errors} data={data} form={form}
                control={control} trigger={trigger}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditContract;