import {request} from "../../Base/HTTP";
import HttpMethod from "../../Constants/Base/HttpMethod";
import InvoiceDirection from "../../Constants/Finance/InvoiceDirection";
import { saveAs } from 'file-saver';

export async function getInvoice(id) {
    return await request('/api/invoices/' + id);
}

export async function getInvoices(data) {
    return await request('/api/invoices', data);
}

export async function getAllInvoices() {
    return await request('/api/invoices/all', {});
}

export async function getInvoiceCreateData() {
    return await request('/api/invoices/create-data');
}

export async function saveInvoice(data) {
    return await request('/api/invoices', transformRequestData(data), HttpMethod.POST);
}

export async function deleteInvoice(id) {
    return await request('/api/invoices/' + id, {}, HttpMethod.DELETE);
}

export function viewInvoice(id) {
    request('/api/invoices/view/' + id, {}, HttpMethod.GET, {responseType: 'blob'}).then(res => {
        const newBlob = new Blob([res.data], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(newBlob);
        window.open(downloadUrl);
    });
}

export function viewInoInvoice(id) {
    request('/api/invoices/ino/view/' + id, {}, HttpMethod.GET, {responseType: 'blob'}).then(res => {
        const newBlob = new Blob([res.data], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(newBlob);
        window.open(downloadUrl);
    });
}

export function downloadInvoice(id) {
    request('/api/invoices/download/' + id, {}, HttpMethod.GET, {responseType: 'blob'}).then(res => {
        const newBlob = new Blob([res.data], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(newBlob);
        saveAs(downloadUrl, res.headers['file-name'])
    });
}

export function downloadInoInvoice(id) {
    request('/api/invoices/ino/download/' + id, {}, HttpMethod.GET, {responseType: 'blob'}).then(res => {
        const newBlob = new Blob([res.data], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(newBlob);
        saveAs(downloadUrl, res.headers['file-name'])
    });
}

export async function duplicateInvoice(data) {
    return await request('/api/invoices/duplicate/' + data.id, {}, HttpMethod.POST);
}


function transformRequestData(data) {
    let items = [];

    for (let item of data.items) {

        if (data.invoiceDirection === InvoiceDirection.OUT) {
            items.push({
                ...item,
                productService: {
                    ...item.productService,
                    unitId: item.productService.unit ? item.productService.unit.id : -1,
                }
            })
        } else {
            let tax = item.tax && item.tax.id ? item.tax.value : item.tax;
            items.push({
                ...item,
                unitId: item.unit ? item.unit.id : -1,
                tax: tax,
                discount: 0,
                price: item.price,
                name: item.name,
            })
        }
    }

    return {
        ...data,
        items: items,
        companyId: data && data.company ? data.company.id : -1,
        partnerId: data && data.partner ? data.partner.id : -1,
        accountId: data && data.account ? data.account.id : -1,
        invoiceType: data && data.invoiceType && data.invoiceType.id ? data.invoiceType.id : data.invoiceType,
        currencyId: data && data.currency ? data.currency.id : null,
        contractId: data && data.contract ? data.contract.id : -1,
        contractPartId: data && data.contractPart ? data.contractPart.id : -1,
        projectId: data && data.project ? data.project.id : -1
    }
}
