import {useForm} from "react-hook-form";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {Paper} from "@mui/material";
import strings from "../../../localization";
import DrawerContext from "../../../Context/DrawerContext";
import TablePageContext from "../../../Context/TablePageContext";
import LinearProgress from '@mui/material/LinearProgress';
import {getAllUserPositions} from "../../../Services/Company/UserPositionService";
import {editUserManagment} from "../../../Services/User/UserService";
import UserForm from "../../../Components/Forms/Pages/Administration/User/UserForm";
import {getAllTeams} from "../../../Services/HR/TeamService";
import ValidationPatters from "../../../Constants/Base/ValidationPatters";
import SnackbarContext from "../../../Context/SnackbarContext";
import {getCandidates} from "../../../Services/HR/CandidateService";

const formRules = {
    'email': {required: { value: true},
        pattern: { value: ValidationPatters.EMAIL}},
    'firstName': {required: true},
    'lastName': {required: true}
}

const EditUser = (props) => {

    const {setDrawerTitle} = useContext(DrawerContext);
    const { showMessage } = useContext(SnackbarContext);
    const form = useForm({
        defaultValues: props.data && props.data.length > 0 ? {
            ...props.data[0],
            ...props.data[0].user,
        } : undefined
    });
    const {data, watch, handleSubmit, getValues, setValue, formState: {errors}} = form;
    const [showLoader, setShowLoader] = useState(false);
    const [userPositions, setUserPositions] = useState([]);
    const [teams, setTeams] = useState([]);
    const [siteData, setSiteData] = useState({ candidates: []});


    useEffect(() => {
        setDrawerTitle(strings.pages.administration.user.editRole.pageTitle);
    });

    useEffect(()=>{
        fetchFilters();
    },[data])

    useEffect(() => {
        fetch()
    }, [])


    const fetch = useCallback(() => {
        getAllUserPositions({}).then(response => {
            if (!response || !response.ok) {
                return;
            }
            setUserPositions(response.data)
        })

        getAllTeams().then(response => {
            if(!response || !response.ok) {
                return;
            }

            setTeams(response.data)
        })

        fetchCandidates();
    }, [])

    const fetchForAutocomplete = async (term = '', getFunction = async (data) => {}, keyInState,updateState = false) => {
        setSiteData(currentSiteData => ({
            ...currentSiteData,
            [keyInState]: []
        }));
        let response = await getFunction({term});
        if (updateState) {
            setSiteData(currentSiteData => ({
                ...currentSiteData,
                [keyInState]: response.data.result
            }));
        }
        return response.data.result;
    }

    const fetchCandidates = async (term = '', updateState = true) => {
        return await fetchForAutocomplete(term, getCandidates, 'candidates', updateState);
    }

    const fetchFilters = async () => {
        const promises = [fetchCandidates('', false)]

        const responses = await Promise.all(promises);
        const candidates = responses[0];
        const invoiceDataResponse = responses[1];


        if (!invoiceDataResponse || !invoiceDataResponse.ok) {
            return;
        }

        setSiteData(currentSiteData => ({
            ...currentSiteData,
            candidates,
        }));
        return data;
    }


    const onSubmit = (data) => {

        setShowLoader(true);

        editUserManagment(data).then(response => {
            setShowLoader(false);

            if (!response || !response.ok) {
                showMessage(strings.commonMessages.errorEditing, 'error');
                props.onFinish();
                return;
            }

            showMessage(strings.commonMessages.editingSuccessful, 'success');
            props.onFinish();
        });
    }

    const onCancel = () => {
        props.onCancel();
    }

    return <div className='drawer-form'>
        <Paper className='paper'>
            {
                showLoader &&
                <LinearProgress/>
            }
            <UserForm
                watch={watch}
                formRules={formRules}
                values={getValues()}
                setValue={setValue}
                userPositions={userPositions}
                teams={teams}
                candidates={siteData.candidates}
                onCandidateChange={fetchCandidates}
                errors={errors} data={data} form={form}
                onSubmit={handleSubmit(onSubmit)} onCancel={() => onCancel()}/>
        </Paper>
    </div>
}

export default EditUser;